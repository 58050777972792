<div class="language-settings-container">
    <div class="form-group">
        <div class="d-flex justify-content-start flex-column w-75 frequency">
            <label for="en" class="pointer pr-5" (click)="setLan('en')">
                <input type="radio" [(ngModel)]="lang" name="repeat" id="en" value="en" />
                <span class="ml-3">English</span>
            </label>
            <label for="hi" class="pointer pr-5" (click)="setLan('hi')">
                <input type="radio" [(ngModel)]="lang" name="repeat" id="hi" value="hi" />
                <span class="ml-3">हिंदी</span>
            </label>
        </div>
    </div>
</div>
