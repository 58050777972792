<div *ngIf="isNewLayout" class="emojis" [class.emoji-full-container]="isNewLayout">
    <div class="search-inp">
        <span class="search-icon"><img src="assets/images/search-icon.svg" /></span>
        <input type="text" [(ngModel)]="emojiSearch" class="emoji-sear-inp" placeholder="Search" />
        <span *ngIf="emojiSearch" class="clear-icon" (click)="clearSearch()"></span>
    </div>
    <div class="em" [class.window-em]="isWindows">
        <ng-container *ngFor="let emojiGroup of categorysArray">
            <span
                [class.activeCategory]="selectedCategoryType == emojiGroup"
                class="category-item"
                (click)="selectCategory(emojiGroup)"
            >
                {{ ModifiedChar[emojiGroup] }}
            </span>
        </ng-container>
    </div>
    <div [class.newEmoLayout]="isNewLayout" class="emoji-container">
        <ng-container
            *ngFor="
                let emoji of (emojiSearch ? emojis : showArrayEmojiList) | JSONFILTER: emojiSearch:'name';
                let idx = index
            "
        >
            <!-- <ng-container *ngIf="!emojiSearch && (idx ==0 || (idx > 0 && emojis[idx-1].group !==  emojis[idx].group) )">
                <span id="{{emoji.group}}" class="title-place">{{emoji.group}}</span>
            </ng-container> -->
            <span
                [class.newEmojiItem]="isNewLayout"
                class="chat-emoji"
                title="{{ emoji.name }}"
                [class.heart-of-fire]="emoji.codes === heartOnFireCode"
                (click)="selectEmoji(emoji)"
                >{{ emoji.char }}</span
            >
        </ng-container>
        <span *ngIf="emojis.length" class="no-result-found">No search results found</span>
    </div>
</div>
<div class="emojis" *ngIf="!isNewLayout">
    <span *ngFor="let emoji of emojis" title="{{ emoji.name }}" (click)="selectEmoji(emoji)">{{ emoji.char }}</span>
</div>
