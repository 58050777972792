import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { APP_EVENTS, SOCKET_EVENTS, MEDIA_ENGINE_EVENTS, APP_AUDIO, APP_CONSTANT } from 'src/app/constants';
import {
    EventEmitterService,
    RtcService,
    UserService,
    AppService,
    AuthService,
    GuestAuthService,
    UtilService,
    VideoWrapperService,
    SystemService,
    MeetingService,
    SessionStorageService
} from 'src/app/core';
import { RoomConnectionService } from 'src/app/core/services/room-connection.service';
import { TranslateService } from '@ngx-translate/core';
import { PollsService } from 'src/app/core/services/polls.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { FloatingReactionService } from 'src/app/shared/services/floating-reaction.service';
import { WebinarConferenceService } from 'src/app/conference/services';
import { WebinarAttendeeService } from 'src/app/webinar-attendee/index';
import * as _ from 'lodash';
import { EventsPlusService } from '../services/events-plus.service';

@Injectable({
    providedIn: 'root'
})
export class CallStateManagerService {
    joinDetails: any;
    currentUser: any;
    isAuthenticated: boolean;
    isFetching: boolean;
    waitingRoomDetails: any;
    listener: any;
    subscriptions: Subscription[] = [];
    meetingObj: any;
    breakoutRoomInfo: any;
    currentMeetingObject$: BehaviorSubject<any> = new BehaviorSubject(null);
    userCallState$: Subject<any> = new Subject();
    currentMeeting: {
        topic: any;
        startTime: Date;
        endTime: Date;
        url: any;
        jiomeetId: any;
        pin: any;
        addToJmtUrl: any;
        legacyConnect: any;
        mediaEngine: any;
    };
    callPlaced: any;
    roomStatus: any;
    isUserInCall: any = null;
    isSessionEnabled: any;
    preloaderTitle: string;
    isCallScreenFullTab: boolean = false;
    parentEventRoomDetails: any;
    networkingRoom: any;
    parentWebinarDetailsObj: any;
    sessionId;
    isBreak;
    selectedSessionInfo: any;
    sessionsInfo = [];
    isSession: number = 0;
    autoJoin: number = 0;
    parentMeetingId: any;
    waitingRoomInterval: any;
    networkingRoomsClosureStatus: any;
    eventStats = {
        viewsCount: 0,
        likesCount: 0,
        likeOptions: []
    };
    attendeeConferenceInfo: any;
    recorderToken;

    constructor(
        private appService: AppService,
        private userService: UserService,
        public rtcService: RtcService,
        private eventEmitterService: EventEmitterService,
        private toasterService: ToastrService,
        private authService: AuthService,
        private guestAuthService: GuestAuthService,
        private router: Router,
        private roomConnectionService: RoomConnectionService,
        private utilService: UtilService,
        private guestUserService: GuestAuthService,
        private videoWrapperService: VideoWrapperService,
        private systemService: SystemService,
        private translateService: TranslateService,
        private webinarConfService: WebinarConferenceService,
        private pollsService: PollsService,
        private appLoggerService: AppLoggerService,
        private floatingService: FloatingReactionService,
        private meetingService: MeetingService,
        private webinarAttendeeService: WebinarAttendeeService,
        private sessionStorageService: SessionStorageService,
        private eventsPlusService: EventsPlusService
    ) {}

    setSessionId(id) {
        this.sessionId = id;
    }

    updateSessionInfo(info) {
        this.selectedSessionInfo = info;
    }

    getSessionInfo() {
        return this.selectedSessionInfo || {};
    }

    setSessionsInfo(info) {
        this.sessionsInfo = info;
    }

    getSessionsInfo() {
        return this.sessionsInfo || [];
    }

    prepareAndPlaceCall() {
        if (!this.rtcService.getConferenceInfo()) {
            location.href = this.appService.getBaseUrl() + 'dashboard';
        } else {
            const {
                isInitiater,
                joiningName,
                room,
                ownerDetails,
                hideMoreCallControls,
                hideAllCallControls,
                isFullScreenShare,
                meetingInfo,
                incomingCall,
                webinar,
                meetingId,
                webinarOptions,
                qnaTimeLimit,
                meetingUrl,
                currentUserInfo // this contains the info whether user is invited user or not.
            } = this.rtcService.getConferenceInfo();
            this.rtcService.setRoomInfo(room);

            setTimeout(() => {
                this.isSessionEnabled = webinar?.sessions?.enabled ?? false;
                this.webinarConfService.setSessionEnabled(this.isSessionEnabled);
                this.webinarConfService.setStudioEnabled(webinarOptions.customization?.studioTemplateId);
                this.preloaderTitle = `Joining ${this.isSessionEnabled ? 'Session' : 'Event'}....`;
            });

            const meetingObj: any = {
                callurl: room.room_url || room.roomurl || room.roomUrl,
                room: room.room_key || room.roomkey || room.roomKey,
                room_id: room.roomID.toString(),
                meetingUrl: room?.meetingUrl || meetingUrl,
                roomPIN: room.vpin || room.roomPIN || room.room_pin,
                userPIN: room.roomPIN || room.room_pin,
                gateway_ip: room.gateway_ip,
                jiomeetId: room.jiomeetId,
                guest_name: joiningName,
                owner_name: ownerDetails.name,
                owner_lname: ownerDetails.lname,
                owner_id: ownerDetails._id,
                owner_emailId: ownerDetails.email,
                owner_tenantId: ownerDetails.tenantId,
                isHostRight: ownerDetails.isHostRight,
                isInitiater,
                participantCount: 0,
                isJoinMeeting: true,
                title: room.topic || '',
                topic: this.meetingObj?.topic || room.topic || '',
                group_id: '0',
                users: [],
                is_disconnect: 'false',
                hideMoreCallControls,
                hideAllCallControls,
                isFullScreenShare,
                participantEndPointType: 'web',
                participantEndPointName: 'web',
                machineIp: this.systemService.getDeviceUUID(),
                historyId: this.getHistoryID(),
                meetingInfo,
                incomingCall,
                webinar,
                meetingId,
                webinarOptions,
                qnaTimeLimit,
                ytlivestream: room?.ytlivestream,
                fblivestream: room?.fblivestream,
                customlivestream: room?.customlivestream,
                restrictedEvent: webinar?.registration?.inviteOnly || false,
                isInvited: currentUserInfo?.isInvited
            };
            if (
                meetingObj?.meetingInfo?.ytlivestream?.userId === this.userService.getUserSync()?._id ||
                ownerDetails.isHostRight
            ) {
                meetingObj.ytlivestream = { ...meetingObj?.meetingInfo?.ytlivestream };
            }
            if (
                meetingObj?.meetingInfo?.fblivestream?.userId === this.userService.getUserSync()?._id ||
                ownerDetails.isHostRight
            ) {
                meetingObj.fblivestream = { ...meetingObj?.meetingInfo?.fblivestream };
            }
            if (
                meetingObj?.meetingInfo?.customlivestream?.userId === this.userService.getUserSync()?._id ||
                ownerDetails.isHostRight
            ) {
                meetingObj.customlivestream = { ...meetingObj?.meetingInfo?.customlivestream };
            }
            meetingObj.historyId = this.getHistoryID();
            this.setCurrentMeeting(meetingObj, room);

            if (this.authService.getAuthInfo()) {
                meetingObj.token = this.authService.getAuthInfo().jwt;
                meetingObj.users = [{ userId: this.userService.getUserSync()?._id }];
                this.joinCall(meetingObj, false);
            } else if (this.guestAuthService.getAuthInfo()) {
                meetingObj.token = this.guestAuthService.getAuthInfo().jwt;
                this.joinCall(meetingObj, true);
            } else {
                meetingObj.token = this.webinarAttendeeService.getGuestAttendeeToken()?.jwt;
                this.joinCall(meetingObj, true);
            }
        }
    }

    joinCall(meetingObj, guest) {
        this.currentMeetingObject$.next(meetingObj);
        let payload = { ...meetingObj };
        if (guest) {
            payload.meetingId = null;
        }
        if (this.recorderToken) payload.recorderToken = this.recorderToken;
        if (this.utilService.isLiveStreaming) payload.isLiveStreaming = this.utilService.isLiveStreaming;

        // empty user array and remove un neccary information from payload
        // payload.users = [];
        payload = _.omit(payload, [
            'webinar',
            'webinarOptions',
            'meetingInfo',
            'fblivestream',
            'customlivestream',
            'ytlivestream'
        ]);

        this.rtcService
            .joinCall(
                {
                    data: payload
                },
                guest
            )
            .subscribe(
                (res: any) => {
                    meetingObj.historyId = res.historyId;
                    meetingObj.topic = res.topic;
                    this.appService.setHistoryId(res.historyId);
                    this.placeCall({ data: meetingObj });
                },
                (err) => {
                    this.placeCall({ data: meetingObj });
                }
            );
    }

    placeCall(event) {
        if (this.callPlaced) {
            return;
        }
        this.meetingObj = event.data;
        this.appLoggerService.setMeetingInfo(this.meetingObj);
        this.roomConnectionService.setMeetingInfo(this.meetingObj);
        this.currentUser = this.userService.getUserSync();
        this.rtcService.setIsInitiater(this.meetingObj.isInitiater);
        // this.setReactions(); // yaha kaun lagata h bhai ye function

        setTimeout(() => {
            const conference = this.rtcService.getConferenceInfo();
            if (conference.ownerDetails.isHostRight && conference.room.roomStatus === 'Locked') {
                this.joinRoom({ unlockAndJoin: true });
            } else {
                this.joinRoom();
            }
        }, 50);
    }

    joinRoom({ joinRoom = false, unlockAndJoin = false } = {}) {
        const { micPrivacy, cameraPrivacy } = this.getPreferences();
        this.eventEmitterService.emit({ type: APP_EVENTS.HIDE_CALL_OPTIONS, data: null }); // Close call-options on room switching
        this.videoWrapperService.getVideoService().setJoinData({
            host: this.meetingObj.callurl,
            displayName: this.meetingObj.guest_name,
            roomKey: this.meetingObj.room,
            roomPin: this.meetingObj.roomPIN,
            micPrivacy,
            cameraPrivacy,
            isInitiater: this.meetingObj.isInitiater,
            unlockAndJoin,
            meetingObj: {
                room_id: this.meetingObj?.room_id?.toString(),
                meetingId: this.meetingObj?.meetingId,
                historyId: this.meetingObj?.historyId
            },
            userId: this.userID()
        });
        this.pollsService.updateMeetingId(this.meetingObj?.meetingId);
        if (this.currentMeeting?.mediaEngine === 'pexip') {
            this.rtcService.setIsPexipMeeting(true);
            this.videoWrapperService.getVideoService().initializeVidyoConnector();
        } else {
            this.videoWrapperService.getVideoService().joinRoom({
                host: this.meetingObj.callurl,
                displayName: this.meetingObj.guest_name,
                roomKey: this.meetingObj.room,
                roomPin: this.meetingObj.roomPIN,
                micPrivacy,
                cameraPrivacy
            });
        }
        // if (joinRoom) {

        // } else {
        //     this.videoWrapperService.getVideoService().initializeVidyoConnector('networking');
        // }
        this.setUserCallState(this.joinDetails?.callType);
        if (this.isUserInCall === 'networking') {
            this.eventEmitterService.emit({ type: APP_EVENTS.ATTENDEE.NETWORKING_ROOM_REFRESH, data: null });
        }
        this.eventEmitterService.emit({ type: 'fetchComplete', data: null });
    }

    userID() {
        return this.currentUser?._id || this.guestUserService.getAuthInfo()?.guestUserId;
    }

    getPreferences() {
        // Switch Room Case
        const localParticipant = this.videoWrapperService.getVideoService().getLocalParticipant();

        // TODO: Check camera mic preferences at all entry points

        // if (typeof localParticipant !== 'undefined' && Object.keys(localParticipant)?.length) {
        //     return {
        //         micPrivacy: !localParticipant.microphoneMute,
        //         cameraPrivacy: !localParticipant.cameraMute
        //     };
        // }

        // if (Object.keys(this.roomStatus || {})?.length) {
        //     return {
        //         micPrivacy: this.roomStatus?.localParticipant?.micStatus,
        //         cameraPrivacy: this.roomStatus?.localParticipant?.cameraStatus
        //     };
        // }

        if (this.meetingObj.isAudioMode) {
            return { micPrivacy: true, cameraPrivacy: false };
        }
        const userPreference = this.rtcService.getCameraMicPreference();
        if (this.meetingObj.incomingCall) {
            return {
                micPrivacy: false,
                cameraPrivacy: false
            };
        }
        if (this.meetingObj.isInitiater) {
            //for host
            return userPreference;
        }
        //for participants
        const room = this.rtcService.getRoomInfo();
        if (room.advancedOptions) {
            if (room.advancedOptions.participantAudio === false) {
                userPreference.micPrivacy = false;
            }
            if (room.advancedOptions.participantVideo === false) {
                userPreference.cameraPrivacy = false;
            }
        }
        return userPreference;
    }

    setCurrentMeeting(meeting, room) {
        const shareUrlInfo = this.rtcService.getShareUrlInfo();
        this.currentMeeting = {
            topic:
                room.topic ||
                `${meeting.owner_name}${meeting.owner_lname ? ` ${meeting.owner_lname}` : ''}'s JioMeet Meeting`,
            startTime: meeting.meetingInfo ? new Date(meeting.meetingInfo.startTime) : null,
            endTime: meeting.meetingInfo ? new Date(meeting.meetingInfo.endTime) : null,
            url: shareUrlInfo ? shareUrlInfo.url : '',
            jiomeetId: room.jiomeetId,
            pin: room.roomPIN || '',
            addToJmtUrl: meeting.meetingInfo ? meeting.meetingInfo.addToJmtUrl : null,
            legacyConnect: room.legacyConnect,
            mediaEngine: room.mediaEngine
        };
        this.appLoggerService.setMeetingInfo(this.currentMeeting);
    }

    getHistoryID() {
        return this.meetingObj?.historyId || this.breakoutRoomInfo?.historyId;
    }

    getCurrentMeetingObjectObs() {
        return this.currentMeetingObject$;
    }

    getCurrentMeetingObject() {
        if (this.meetingObj) {
            if (this.meetingObj.meetingInfo) {
                return this.meetingObj;
            } else {
                this.meetingObj.meetingInfo = { ...this.meetingObj };
                return this.meetingObj;
            }
        }
    }

    getCurrentMeetingId() {
        return this.meetingObj?.meetingId || this.meetingObj?._id;
    }

    sendMeetingObjectUpdate() {
        this.setReactions();
        this.currentMeetingObject$.next(this.meetingObj);
    }

    addListener(listener) {
        this.listener = listener;
    }

    getRoomDetailsForMeeting() {
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.isFetching = true;
        this.rtcService
            .getRoomDetailsGuest({
                extension: this.joinDetails.meetingId,
                pin: this.joinDetails.pin,
                hash: this.joinDetails.hash,
                userId: this.currentUser._id,
                isAuthenticated: this.isAuthenticated,
                memberName: this.joinDetails.fullName,
                recorderToken: this.recorderToken,
                isLiveStreaming: this.utilService.isLiveStreaming
            })
            .subscribe(
                (res: any) => {
                    // this.eventEmitterService.emit({ type: 'fetchComplete', data: null });
                    if (!res.roomDetails && res.waitingRoom) {
                        this.waitingRoomDetails = res;
                        // TODO handle waiting case
                        this.setUserCallState(null);
                        this.waitUntillHostAllows(res);
                    } else {
                        this.setUserCallState(this.joinDetails.callType);
                        if (this.networkingRoom && res?.speakerCount >= this.appService.getMaxParticipantInCall(res)) {
                            this.eventEmitterService.emit({
                                type: APP_EVENTS.LEAVE,
                                data: null
                            });
                            this.toasterService.error('The networking room you’re trying join is already full');
                            return;
                        }
                        this.joinAs(res);
                    }
                },
                (err) => {
                    // TODO log error an handle
                    this.meetingObj = null;
                    this.eventEmitterService.emit({ type: APP_EVENTS.JOIN_ERROR, data: err });
                    this.appLoggerService.error(err);
                }
            );
        // this.subscriptions.map((s) => s.unsubscribe());
    }

    async fetchRoomDetailsAndJoin(roomJoinDetails) {
        this.setUserCallState('joining');
        this.joinDetails = roomJoinDetails;
        if (!this.currentUser) {
            this.currentUser = this.userService.getUserSync();
        }
        await this.getRoomDetailsForMeeting();
        // if(!this.isFetching) {
        //     await this.prepareAndPlaceCall();
        // }
    }

    waitUntillHostAllows(res) {
        this.eventEmitterService.emit({ type: APP_EVENTS.WAITING_ROOM.ENTER, data: res });
        this.waitingRoomInterval = setInterval(() => {
            // this.joining = true;
            this.rtcService
                .getRoomDetailsGuest({
                    extension: this.joinDetails.meetingId,
                    pin: this.joinDetails.pin,
                    hash: this.joinDetails.hash,
                    userId: this.currentUser._id,
                    isAuthenticated: this.isAuthenticated,
                    memberName: this.joinDetails.fullName
                })
                .subscribe(
                    (res: any) => {
                        if (res.waitingRoomId) {
                            this.waitingRoomDetails.waitingRoomId = res.waitingRoomId;
                        }
                        if (res.roomDetails) {
                            this.setUserCallState(this.joinDetails.callType);
                            this.clearWaitingRoomInterval();
                            this.joinAs(res);
                        }
                    },
                    (err) => {
                        if (err.error.customCode === 467) {
                            this.clearWaitingRoomInterval();
                            this.waitingRoomDetails = null;
                            this.translateService.instant(
                                'joinmeeting_page_or_joincall.errors.host_removed_from_waiting_room'
                            );
                            this.eventEmitterService.emit({ type: APP_EVENTS.WAITING_ROOM.EXIT, data: null });
                            // this.errors.global = err.error.errors || this.translations?.errors?.request_rejected;
                            // this.joining = false;
                            return;
                        }
                        if (err.error.customCode === 464) {
                            this.clearWaitingRoomInterval();
                            this.waitingRoomDetails = null;
                            this.translateService.instant('joinmeeting_page_or_joincall.errors.request_rejected');
                            this.eventEmitterService.emit({ type: APP_EVENTS.WAITING_ROOM.EXIT, data: null });
                            // this.errors.global = this.translations?.errors?.request_rejected;
                            // this.joining = false;
                            return;
                        }

                        this.meetingObj = null;
                        this.eventEmitterService.emit({ type: APP_EVENTS.JOIN_ERROR, data: err });
                        this.appLoggerService.error(err);
                    }
                );
        }, this.appService.getConfigVariable('REQUEST_POLLING_INTERVAL'));
    }

    leaveWaitingRoom() {
        if (this.waitingRoomInterval) {
            this.clearWaitingRoomInterval();
            this.userService
                .stopWaitingWithWaitingRoomID({
                    waitingRoomId: this.waitingRoomDetails.waitingRoomId,
                    jiomeetId: this.waitingRoomDetails.jiomeetId
                })
                .subscribe(
                    (res) => {
                        this.clearWaitingRoomInterval();
                        // this.waitingRoomDetails = null;
                        // this.joining = false;
                        this.eventEmitterService.emit({ type: APP_EVENTS.WAITING_ROOM.EXIT, data: res });
                    },
                    (err) => {}
                );
        } else {
            this.eventEmitterService.emit({ type: APP_EVENTS.JOIN_BEFORE_HOST.EXIT, data: null });
        }
    }

    joinAs(room) {
        this.subscriptions.push(
            (!this.joinDetails.isAuthenticated ? of({}) : this.rtcService.getCallShareUrl(room.roomDetails)).subscribe(
                (res: any) => {
                    this.initialiseCallJoin(res, room);
                },
                (err) => {
                    this.appLoggerService.error(err);
                    const res = {
                        url: '',
                        pin: ''
                    };
                    this.initialiseCallJoin(res, room);
                }
            )
        );
        // this.subscriptions.map((s) => s.unsubscribe());
    }

    initialiseCallJoin(res, room) {
        this.rtcService.setShareUrlInfo(res);
        const isSessionInfo = this.rtcService.getWebinarIsSessionInfo();
        this.isSession = +(isSessionInfo?.sessions?.enabled ?? 0);
        // this.autoJoin = +this.activatedRoute.parent.snapshot.queryParams.autoJoin ?? 0;
        // this.parentMeetingId = this.meetingParams.parentMeetingId;
        this.parentMeetingId = isSessionInfo.meetingId;
        res.webinar = { sessions: { enabled: this.isSession } };
        res.parentMeetingId = this.parentMeetingId;
        res = { ...room.roomDetails, ...res };
        if (
            this.joinDetails.isAuthenticated &&
            this.currentUser &&
            room.ownerDetails.userId === this.currentUser._id &&
            room.roomDetails.meetingId
        ) {
            this.subscriptions.push(
                this.meetingService.getMeetingDetails(room.roomDetails.meetingId).subscribe((res) => {
                    this.joinAsHelper(room, res);
                    this.eventsPlusService.setCustomLayoutDetails(res?.customLayout);
                })
            );
            // this.subscriptions.map((s) => s.unsubscribe());
        } else {
            this.joinAsHelper(room, res);
        }
    }

    getLatestMeetingObj() {
        return new Promise((resolve, reject) => {
            this.meetingService.getMeetingDetails(this.meetingObj.meetingId || this.meetingObj._id).subscribe(
                (res) => {
                    this.meetingObj = res;
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    async updateMeetingObj(meetingId) {
        try {
            this.meetingObj = await this.meetingService.getMeetingDetails(meetingId).toPromise();
            return this.meetingObj;
        } catch (err) {
            this.appLoggerService.error('error updating meeting obj ' + err);
        }
    }

    async joinAsHelper(room, meetingInfo?) {
        const isSessionEnabled = meetingInfo?.webinar?.sessions?.enabled ?? false;
        if (isSessionEnabled && meetingInfo?.parentMeetingId) {
            const sessionsInfo = await this.webinarConfService.getSessionsInfo(meetingInfo?.parentMeetingId);
            const sessionsList = sessionsInfo?.sessions || [];
            const sessionSpeakers = sessionsInfo?.speakers || [];
            // set sessions info into conference obj
            meetingInfo = {
                ...meetingInfo,
                sessionsList: sessionsList,
                sessionSpeakers: sessionSpeakers
            };
        }
        if (this.joinDetails.isAuthenticated && this.currentUser) {
            this.handleJoinForLoggedInUser(room, meetingInfo);
        } else {
            this.handleJoinForGuestUser(room, meetingInfo);
        }
    }

    handleJoinForGuestUser(room, meetingInfo) {
        const name = this.joinDetails.fullName;
        const emailRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        const isEmail = emailRegexp.test(this.joinDetails.userIdentity);
        this.subscriptions.push(
            this.guestAuthService
                .login({
                    jiomeetId: room.roomDetails.jiomeetId,
                    roomID: room.roomDetails.roomID,
                    roomPin: room.roomDetails.roomPIN,
                    name: this.joinDetails.fname?.trim(),
                    lname: this.joinDetails.lname?.trim(),
                    phoneNo: !isEmail ? this.joinDetails.userIdentity?.trim() : '',
                    email: isEmail ? this.joinDetails.userIdentity?.trim() : '',
                    recorderToken: this.recorderToken,
                    isLiveStreaming: this.utilService.isLiveStreaming
                })
                .subscribe(
                    (res) => {
                        this.userService.isGuestLogin(true);
                        this.userService.setGuestUser(name);

                        const commonConferenceInfo = {
                            isInitiater: false,
                            joiningName: name.trim(),
                            room: room.roomDetails,
                            ownerDetails: room.ownerDetails,
                            hideMoreCallControls: this.joinDetails.hideMoreCallControls === 'true',
                            hideAllCallControls: this.joinDetails.hideAllCallControls === 'true',
                            isFullScreenShare: this.joinDetails.isFullScreenShare === 'true',
                            meetingInfo: meetingInfo,
                            webinar: { ...room.roomDetails.webinar, sessions: this.isSession },
                            webinarOptions: room?.roomDetails?.webinarOptions,
                            qnaTimeLimit: room?.roomDetails?.qnaTimeLimit,
                            meetingId: room?.roomDetails?.meetingId
                        };
                        var useHd = meetingInfo?.webinar?.attendeeFeed === 'rtmp';
                        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(
                            room,
                            true,
                            useHd
                        );
                        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                        this.rtcService.setConferenceInfo(conferenceInfo);
                        this.isFetching = false;
                        this.eventEmitterService.emit({ type: 'fetchComplete', data: null });
                        this.prepareAndPlaceCall();
                    },
                    (err) => {
                        this.appLoggerService.error(err);
                    }
                )
        );
    }

    handleJoinForLoggedInUser(room, meetingInfo?) {
        if (
            room.ownerDetails.userId === this.currentUser._id &&
            this.currentUser.jiomeetId === this.joinDetails.meetingId
        ) {
            // host a meeting case
        }

        const commonConferenceInfo = {
            isInitiater: room.ownerDetails.userId === this.currentUser._id,
            joiningName: this.joinDetails.fullName,
            room: room.roomDetails,
            ownerDetails: room.ownerDetails,
            hideMoreCallControls: this.joinDetails.hideMoreCallControls === 'true',
            hideAllCallControls: this.joinDetails.hideAllCallControls === 'true',
            isFullScreenShare: this.joinDetails.isFullScreenShare === 'true',
            meetingInfo,
            webinar: { ...room.roomDetails.webinar, sessions: meetingInfo?.webinar?.sessions },
            webinarOptions: room?.roomDetails?.webinarOptions,
            qnaTimeLimit: room?.roomDetails?.qnaTimeLimit,
            meetingId: room?.roomDetails?.meetingId
        };
        var useHd = meetingInfo?.webinar?.attendeeFeed === 'rtmp';
        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, true, useHd);
        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
        this.rtcService.setConferenceInfo(conferenceInfo);
        this.isFetching = false;
        this.eventEmitterService.emit({ type: 'fetchComplete', data: null });
        setTimeout(() => {
            this.prepareAndPlaceCall();
        }, 2000);
    }

    sendParticipantLeftEvent() {
        const data = {
            userId: this.currentUser._id
        };
        this.rtcService.sendEvent({
            event: 'participantLeft',
            data
        });
    }

    sendDisconnectEventToHost() {
        const data = {
            userId: this.meetingObj.owner_id,
            name: this.currentUser.name,
            phoneNo: this.currentUser.phoneNo,
            ownId: this.currentUser._id,
            roomKey: this.meetingObj.room,
            eventType: SOCKET_EVENTS.CALL_DROP_TOAST_NOTIFICATION
        };
        this.rtcService.sendEvent({
            event: 'generic',
            data
        });
    }

    sendDisconnectEventToParticipants() {
        (this.meetingObj.users || []).forEach((user) => {
            const data = {
                userId: user.userId,
                is_disconnect: 'true',
                owner: this.meetingObj.owner,
                owner_id: this.meetingObj.owner_id,
                owner_name: this.meetingObj.owner_name,
                room: this.meetingObj.room,
                title: this.meetingObj.title,
                callurl: this.meetingObj.callurl
            };
            this.rtcService.sendEvent({
                event: 'generic',
                data
            });
        });
    }

    sendCallDropEventToParticipants() {
        (this.meetingObj.users || []).forEach((user) => {
            const data = {
                userId: user.userId,
                name: this.currentUser.name,
                phoneNo: this.currentUser.phoneNo,
                ownId: this.currentUser._id,
                roomKey: this.meetingObj.room,
                eventType: SOCKET_EVENTS.CALL_DROP_TOAST_NOTIFICATION
            };
            this.rtcService.sendEvent({
                event: 'generic',
                data
            });
        });
    }

    getRoomConnectionStatus() {
        return this.roomConnectionService
            .getMeetingParticipants(this.meetingObj.jiomeetId, this.meetingObj.room_id, this.meetingObj.userPIN)
            .toPromise();
    }

    goToThankYou() {
        // if (this.networkingRoom) {
        //     this.eventEmitterService.emit({ type: APP_EVENTS.NETWORKING.LEAVE, data: this.networkingRoom });
        //     return;
        // }
        this.eventEmitterService.emit({ type: APP_EVENTS.NAVIGATE_TO_LANDING_PAGE, data: null });
        return;

        if (!this.authService.getIsAuthenticated()) {
            this.router.navigate(['conference/thankyou-webinar']);
            return;
        }
        location.href = this.appService.getBaseUrl() + 'dashboard';
    }

    exitCall() {
        this.setUserCallState(null);
        this.meetingObj = null;
    }

    setCallFullScreenTab(type) {
        this.isCallScreenFullTab = type;
    }

    updateCallStateAndHomeTab(type = null) {
        this.setUserCallState(type);
        // this.eventEmitterService.emit({ type: APP_EVENTS.NAVIGATE_TO_LANDING_PAGE, data: null });
    }

    setUserCallState(type) {
        this.isUserInCall = type;
        if (type == 'attendeestage') this.meetingObj = null;
        this.userCallState$.next(this.isUserInCall);
    }

    getUserCallState() {
        return this.isUserInCall;
    }

    getUserCallStateInfo() {
        return this.userCallState$;
    }

    setNetworkingRoom(room) {
        this.networkingRoom = room;
    }

    getNetworkingRoom() {
        return this.networkingRoom;
    }

    setParentEventRoomDetails(details) {
        this.parentEventRoomDetails = details;
    }

    getParentEventRoomDetails() {
        return this.parentEventRoomDetails;
    }

    setParentWebinarDetails(details) {
        // joindetails api
        this.parentWebinarDetailsObj = details;
    }

    getParentWebinarDetails() {
        return this.parentWebinarDetailsObj;
    }

    getParentMeetingId() {
        return this.parentWebinarDetailsObj?.meetingId || this.parentWebinarDetailsObj._id;
    }

    updateNetworkingRoomsClosureState(value) {
        this.networkingRoomsClosureStatus = value;
    }

    clearWaitingRoomInterval() {
        clearInterval(this.waitingRoomInterval);
        this.waitingRoomInterval = null;
    }

    //Not in Use
    moveToSessionBackStage(sessionIdToJoin, userType = 'attendee') {
        this.sessionStorageService.addItem(`sessionIdToAutoJoin`, sessionIdToJoin);
        const autoJoinInfo = this.utilService.getAutoJoinInfo();
        this.sessionStorageService.addItem('autoJoinInfo', {
            ...autoJoinInfo,
            autoJoin: true
        });
        if (userType === 'attendee') this.sessionStorageService.addItem('attendeeAutoJoinInProgress', true);
        window.location.reload();
    }
    updateEventViewCount(data) {
        // var webinarId =
        //     _.get(this.webinarDetails, 'sessionsEnabled') || this.sessionId ? this.sessionId : this.webinarId;
        if (this.meetingObj.meetingId === data.meetingId) {
            // || webinarId === data.meetingId
            this.eventStats.viewsCount = this.utilService.convertIntegerToShortFormat(data.viewsCount);
            // this.eventEmitterService.emit({
            //     type: WEBINAR_CONFERENCE_EVENTS.VIEW_COUNT,
            //     data: this.eventStats.viewsCount
            // });
        }
    }

    updateEventReactionsCount(data) {
        // var webinarId =
        //     _.get(this.webinarDetails, 'sessionsEnabled') || this.sessionId ? this.sessionId : this.webinarId;
        if (this.meetingObj.meetingId === data.meetingId) {
            // || webinarId === data.meetingId
            this.eventStats.likesCount = this.utilService.convertIntegerToShortFormat(data.likesCount);
            // this.eventEmitterService.emit({
            //     type: WEBINAR_CONFERENCE_EVENTS.VIEW_COUNT,
            //     data: this.eventStats.viewsCount
            // });
        }
    }

    setReactions() {
        this.eventStats.likeOptions = [];
        this.meetingObj?.webinarOptions?.likes?.reactionType.forEach((reaction) => {
            this.eventStats?.likeOptions.push({ type: reaction, count: 0 });
        });
    }

    getLikeDetails(event = null) {
        if (!document.getElementsByClassName('likes-menu collapse show')[0]) {
            this.webinarConfService.getLikeDetails(this.meetingObj.meetingId).subscribe((res: any) => {
                this.floatingService.setReactionsDetails(res);
                let totalReactionCount = 0;
                this.eventStats.likeOptions = this.eventStats?.likeOptions.map((option: any) => {
                    return {
                        ...option,
                        count: res.filter((o) => o._id === option.type)[0]
                            ? res.filter((o) => o._id === option.type)[0].count
                            : 0
                    };
                });
                this.eventStats?.likeOptions.forEach((op) => (totalReactionCount += op.count));
                this.eventStats.likesCount = this.utilService.convertIntegerToShortFormat(totalReactionCount);
            });
        }
    }

    checkParticipantType(message) {
        // Only for Agora Event
        const localParticipant = this.videoWrapperService.getVideoService().localParticipant;
        if (localParticipant?.participantType === 'audience') {
            this.toasterService.clear();
            if (!message) {
                this.toasterService.info(this.translateService.instant('tostrs.made_audience'), '', {
                    disableTimeOut: false,
                    closeButton: true,
                    toastClass: 'ngx-toastr audiencemode-toast',
                    enableHtml: true
                });
            } else {
                this.toasterService.info(this.translateService.instant(`${message}`), '', {
                    disableTimeOut: false,
                    closeButton: true,
                    toastClass: 'ngx-toastr audiencemode-toast',
                    enableHtml: true
                });
            }
            return true;
        }
    }

    setAttendeeConferenceInfo(info) {
        this.attendeeConferenceInfo = info;
    }
}
