import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AddonsService, AppService, GuestAuthService, RestService, RtcService, UserService } from 'src/app/core';
import { EventUserRole } from 'src/app/constants/webinar-enum';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class WebinarService {
    private eventControlsPopupSubject = new BehaviorSubject<boolean>(false);
    eventControlsPopup$ = this.eventControlsPopupSubject.asObservable();
    downloadInterval$;
    platinumSponsorLogos: any = [];
    goldSponsorLogos: any = [];
    silverSponsorLogos: any = [];
    sponsorsLogos: any = [];
    customCategoryNames: any = [];
    scheduleWebinarDetails = null;
    promotedAttendeesLoggedninUidMap: any = {};
    disablePromoteAttendees: any = {};
    attendeeShortenUrl: any;

    predefinedThemes = [
        {
            color: '#58DAC5',
            lightShade: false
        },
        {
            color: '#4DE5EF',
            lightShade: false
        },
        {
            color: '#6789F4',
            lightShade: false
        },
        {
            color: '#8BD21A',
            lightShade: false
        },
        {
            color: '#F48F3E',
            lightShade: false
        },
        {
            color: '#E02934',
            lightShade: false
        },
        {
            color: '#FF6DCC',
            lightShade: false
        },
        {
            color: '#A680FF',
            lightShade: false
        }
    ];
    #sideNavLinks = [];
    #activeNav;
    #activeChildNav;
    dropDownOptions;
    maskedUrl: string;
    constructor(
        private restService: RestService,
        private router: Router,
        private appService: AppService,
        private userService: UserService,
        private addOnService: AddonsService,
        private guestUserService: GuestAuthService,
        private rtcService: RtcService
    ) {}

    setEventControlsPopupFlag(sponsorFlag): void {
        this.eventControlsPopupSubject.next(sponsorFlag);
    }

    getWebinarId() {
        return this.router.url.split('/').filter((url) => {
            return url.includes('sm-');
        })[0];
    }

    getWebinars({ userId, from, to, searchQuery = '' }) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${userId}?type=webinar&from=${from}&to=${to}&keyword=${searchQuery}`
        );
    }

    getPastWebinars(date, searchQuery = '') {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/dashboardmeetings/webinars/v1?date=${date}&keyword=${searchQuery}`
        );
    }

    getWebinarReplayDetails(meetingId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/publishEvent`);
    }

    enableWebinarReplay(meetingId, replayDetails) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/publishEvent`,
            replayDetails
        );
    }

    editWebinarReplay(meetingId, replayDetails) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/publishEvent`,
            replayDetails
        );
    }

    disableWebinarReplay(meetingId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/publishEvent`
        );
    }

    scheduleWebinar(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/meeting`, payload);
    }

    updateWebinar(meetingId, payload, webinarDetails) {
        // keeping the below code commented for future reference
        payload = {
            ...payload,
            repeatOptions:
                payload.repeatOptions || webinarDetails.repeatOptions
                    ? _.omitBy(payload.repeatOptions || webinarDetails.repeatOptions, _.isNil)
                    : {
                          repeat: 'none'
                      }
        };
        payload.webinar.options.customization =
            payload.webinar.options?.customization || webinarDetails.webinar.options.customization;

        if (payload.webinar.options?.thankYouMsg?.enabled && payload.webinar.options?.thankYouMsg?.text === null) {
            payload.webinar.options.thankYouMsg.text = '';
        }
        return this.restService
            .put(`${this.appService.getEnvVariable('BASE_URL')}/meeting/meetingDetails/${meetingId}`, payload)
            .pipe(
                tap((details) => {
                    this.scheduleWebinarDetails = details;
                })
            );
    }

    generateImage(meetingId, payload): Observable<any> {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/ai/generateImages`, payload).pipe(
            tap((details) => {
                console.log(details);
            })
        );
    }

    updateThankYouMessage(webinarId, text) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/thankyoumessage`,
            { enabled: true, text, messageType: 'web' }
        );
    }

    streamInUrl(channelId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/rtmp/${channelId}/getStreamUrl`);
    }

    cancelWebinar(meetingId, payload) {
        // return this.restService.post(
        //   `${this.appService.getEnvVariable('BASE_URL')}/meeting/cancel/${meetingId}?type=webinar`,
        //   payload
        // );
    }

    getShortendAttendeetUrl(webinarId, attendeeLink, inputValue) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/generateShortUrl`,
            {
                url: attendeeLink,
                key: inputValue
            }
        );
    }

    setShortenUrl(shortenUrlInput) {
        this.attendeeShortenUrl = shortenUrlInput;
    }

    getShortenUrl() {
        return this.attendeeShortenUrl;
    }

    getStreamInEnabledState(meetingId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.guestUserService.getAuthInfo()?.guestUserId.includes('gu')) {
                this.webinarAttendeePageDetails(meetingId).subscribe(
                    (res) => {
                        resolve(res?.customization?.streamIn?.presentingInEvent || false);
                    },
                    (err) => {
                        reject(err);
                    }
                );
            } else {
                try {
                    const result =
                        this.scheduleWebinarDetails?.webinar?.options?.customization?.streamIn?.presentingInEvent ||
                        false;
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            }
        });
    }

    getWebinarDetails(meetingId, update = false) {
        if (this.scheduleWebinarDetails && !update) {
            return of(this.scheduleWebinarDetails);
        } else {
            return this.restService
                .get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/meetingDetails/${meetingId}?source=webrtc`)
                .pipe(
                    map((details) => (Array.isArray(details) ? details[0] : details)),
                    tap((webinar) => {
                        this.scheduleWebinarDetails = webinar;
                    }),
                    catchError((error) => {
                        console.error('Error occurred while fetching webinar details:', error);
                        return throwError('An error occurred while fetching webinar details. Please try again later.');
                    })
                );
        }
    }

    getAllSessions(webinarId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/sessions/${webinarId}`);
    }

    deleteSession(webinarId, sessionId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${webinarId}/${sessionId}`
        );
    }
    getSponsorsDetails(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/sponsors`
        );
    }
    setSponsors(webinarId, sponsorDetails) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/sponsors`,

            sponsorDetails
        );
    }
    updateSponsors(webinarId, sponsorDetails) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/sponsors`,

            sponsorDetails
        );
    }
    deleteSponsors(webinarId, sponsorDetails) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/sponsors`,

            sponsorDetails
        );
    }
    getCanvasCustomImages(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/canvas`
        );
    }
    setCanvasCustomImages(webinarId, canvasCustomImagesDetails) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/canvas`,

            canvasCustomImagesDetails
        );
    }
    updateCanvasCustomImages(webinarId, canvasCustomImagesDetails) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/canvas`,

            canvasCustomImagesDetails
        );
    }
    getScheduleWebinarDetails() {
        return this.scheduleWebinarDetails;
    }

    setScheduleWebinarDetails(webinar) {
        this.scheduleWebinarDetails = webinar;
    }

    getAttendeesList(webinarId, { offset = 0, status, keyword, limit }) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/attendees?offset=${offset}&limit=${limit}&status=${status}&keyword=${keyword}`
        );
    }

    getInvitedAttendeesList(webinarId, { offset = 0, keyword, limit, openinvite }) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/attendees?offset=${offset}&limit=${limit}&keyword=${keyword}`
        );
    }

    getOpenInvitedAttendeesList(webinarId, { offset = 0, keyword, limit, openinvite }) {
        const baseUrl = this.appService.getEnvVariable('BASE_URL');
        const apiUrl = `${baseUrl}/meeting/${webinarId}/webinar/attendees?offset=${offset}&limit=${limit}&keyword=${keyword}`;

        // Conditionally add openinvite parameter if openinvite is true
        const finalApiUrl = openinvite ? `${apiUrl}&invite=true` : apiUrl;

        return this.restService.get(finalApiUrl);
    }

    approveAttendee(webinarId, attendeeId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/approve`,
            {
                attendeeId
            }
        );
    }
    approveAttendee_V1(webinarId, attendeeIds) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/approve/v1`,
            {
                attendeeIds
            }
        );
    }

    sessionApproveAttendee(webinarId, attendeeDetails) {
        return this.restService.post(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/attendees/sessionRegistration/approve`,
            {
                attendeeDetails: attendeeDetails
            }
        );
    }

    sessionRejectAttendee(webinarId, attendeeDetails) {
        return this.restService.post(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/attendees/sessionRegistration/reject`,
            {
                attendeeDetails: attendeeDetails
            }
        );
    }

    rejectAttendee(webinarId, attendeeId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/reject`,
            {
                attendeeId
            }
        );
    }

    rejectAttendee_V1(webinarId, attendeeIds, rejectAll = false) {
        if (rejectAll) {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/reject/all`,
                {}
            );
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/reject/v1`,
            {
                attendeeIds
            }
        );
    }

    resendAttendeeLink(webinarId, attendeeId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/resendEventLink`,
            {
                attendeeId
            }
        );
    }

    uploadBanner(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadbanner`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }
    uploadLogo(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadlogo`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }
    sponsorLogo(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/sponsors/sponsorLogo`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }
    uploadCanvasImage(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/canvas/canvasImage`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }
    uploadWelcomeSlate(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadwelcomescreen`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    uploadWelcomeLogo(webinarId, file, payload = {}): Observable<any> {
        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadwelcomelogo`,
                formData,
                {
                    observe: 'events',
                    reportProgress: true
                }
            );
        } else {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadwelcomelogo`,
                payload,
                {
                    observe: 'events',
                    reportProgress: true
                }
            );
        }
    }
    uploadWelcomeBanner(webinarId, file, payload = {}): Observable<any> {
        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadwelcomebanner`,
                formData,
                {
                    observe: 'events',
                    reportProgress: true
                }
            );
        } else {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadwelcomebanner`,
                payload,
                {
                    observe: 'events',
                    reportProgress: true
                }
            );
        }
    }
    uploadThumbnailImage(webinarId, file, payload = {}): Observable<any> {
        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadthumbnailimage`,
                formData,
                {
                    observe: 'events',
                    reportProgress: true
                }
            );
        } else {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadthumbnailimage`,
                payload,
                {
                    observe: 'events',
                    reportProgress: true
                }
            );
        }
    }

    uploadWelcomeBannerVideo(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/media?meetingid=${webinarId}&type=welcomebanner`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    async checkIfUserDetailsExist(): Promise<boolean> {
        try {
            const user = await this.userService.getUser(true).toPromise();
            let userDetails: any;
            userDetails = await this.addOnService.getExistingUserDetails(user?._id).toPromise();
            const userDetailsExists =
                userDetails?.data?.attributes?.profilePicture.data || userDetails?.data?.attributes?.funFact;
            return !!userDetailsExists;
        } catch (err) {
            return true; //user should directly go inside attendee landing if data doesn't exists
        }
    }
    uploadWelcomeScreenVideo(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/media?meetingid=${webinarId}&type=welcomescreen`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    uploadThankYouBanner(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadthankyoubanner`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    uploadEmailTemplateLogo(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/registrationTemplate/uploadbanner`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    deleteThankYouBanner(webinarId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadthankyoubanner`
        );
    }

    deleteBanner(webinarId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadbanner`
        );
    }
    deleteLogo(webinarId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadlogo`
        );
    }
    deleteWelcomeScreen(webinarId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadwelcomescreen`
        );
    }

    getBrand(webinarId): Observable<any> {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/brand`);
    }

    getWelcomeScreen(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/welcomescreen`,
            { responseType: 'blob' }
        );
    }

    updateBrand(webinarId, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/updateBrand`,
            payload
        );
    }

    updateRegTemplate(webinarId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/registrationTemplate`,
            payload
        );
    }

    updateRegRemTemplate(webinarId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/reminderTemplate`,
            payload
        );
    }

    updateSmsTemplate(webinarId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/registrationTemplate/sms`,
            payload
        );
    }

    updateReminder(webinarId, payload): Observable<any> {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/updateBrand`,
            payload
        );
    }

    getDropdown() {
        return this.dropDownOptions;
    }

    setDropdown(response) {
        this.dropDownOptions = response;
    }

    updateSmsRemTemplate(webinarId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/reminderTemplate/sms`,
            payload
        );
    }

    getBanner(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/banner.png?rnd=${Math.random()}`
        );
    }

    getLogo(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/logo.png?rnd=${Math.random()}`
        );
    }

    uploadThankYouBackground(webinarId, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadthankyoubackground`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    deleteThankYouBackground(webinarId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/uploadthankyoubackground`
        );
    }

    updateLiveStream(webinarId, livestreamType, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/livestream/${livestreamType}`,
            payload
        );
    }

    getMediaList(webinarId, source) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/media?meetingid=${webinarId}&source=${source}`
        );
    }

    uploadMediaFile(file, meetingId): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/media?meetingid=${meetingId}`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    getMediaLink(mediaId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/media/${mediaId}`);
    }

    deleteMediaFile(mediaId) {
        return this.restService.delete(`${this.appService.getEnvVariable('BASE_URL')}/media`, {
            body: { mediaId }
        });
    }

    getDownloadAttendees(webinarId, startTime) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/download${
                startTime ? `?date=${startTime}` : ''
            }`
        );
    }

    getDownloadRegisteredAttendees(webinarId, startTime?) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/attendees/download?registered=true${startTime ? `&date=${startTime}` : ''}`
        );
    }
    getInvitedAttendees(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/download?invited=true`
        );
    }

    getDownloadQnA(webinarId, startTime) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/qnas/download${
                startTime ? `?date=${startTime}` : ''
            }`
        );
    }

    getDownloadFeedback(webinarId, startTime) {
        const baseUrl = window.location.host;
        return this.restService.get(
            `https://${baseUrl}/api/meeting/${webinarId}/webinar/feedback/download/v1${
                startTime ? `?date=${startTime}` : ''
            }`
        );
    }

    getDownloadComments(webinarId, startTime) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/comments/download${
                startTime ? `?date=${startTime}` : ''
            }`
        );
    }

    getFile(webinarId, fileName) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/download/${fileName}`
        );
    }

    webinarAttendeePageDetails(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/joindetails`
        );
    }

    setRegistrationFields(meetingId, fields) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/registrationfields`,
            { fields }
        );
    }

    getRegistrationFields(meetingId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/registrationfields/list`
        );
    }

    getWebinarStats(webinarId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/stats`);
    }

    getVideo(jiomeetId, startTime = '', endTime = '') {
        const params = {
            ...(startTime && { startTime }),
            ...(endTime && { endTime })
        };
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/recording/bymeeting/${jiomeetId}`, {
                params: params
            })
            .pipe(catchError((err) => of({ isError: true, error: err })));
    }

    getDownloadPollsReport(meetingId, date) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls/download${
                date ? `?date=${date}` : ''
            }`
        );
    }

    getNetworkingLoungeReport(meetingId) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${meetingId}/webinar/networkingRoomLoungeDetails/download`
        );
    }

    downloadInvitedAttendees(webinarId) {
        if (!this.downloadInterval$) {
            const intervalDuration = this.appService.getDownloadFileInterval();
            this.downloadInterval$ = interval(intervalDuration).subscribe(() => {
                this.getInvitedAttendees(webinarId).subscribe((res) => {
                    if (res.downloadUrl) {
                        this.downloadFile(res.downloadUrl);
                        this.downloadInterval$.unsubscribe();
                        this.downloadInterval$ = null;
                    }
                });
            });
        }
    }
    downloadFile(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Invited_Attendees.csv';
        a.click();
    }

    getWebinarAddedProfiles(meetingId, search = '') {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/addedProfiles`
        );
    }

    checkForCoHost(participants: any[], currentUser: any = {}) {
        const currentUserAsParticipant = participants.find((participant) => participant.userId === currentUser._id);
        return (
            currentUserAsParticipant?.isCoHost ||
            (currentUserAsParticipant &&
                currentUserAsParticipant?.role &&
                currentUserAsParticipant?.role?.indexOf(EventUserRole.COHOST) !== -1) ||
            false
        );
    }

    checkForHostCoHost(participants: any[], currentUser: any = {}) {
        const currentUserAsParticipant = participants.find((participant) => participant.userId === currentUser._id);
        return (
            (currentUserAsParticipant && this.checkForCoHost(participants, currentUser)) ||
            currentUserAsParticipant?.isHost ||
            false
        );
    }

    checkForAdHocCoHost(participants: any[], currentUser: any = {}) {
        const currentUserAsParticipant = participants.find((participant) => participant.userId === currentUser._id);
        return (
            (currentUserAsParticipant &&
                currentUserAsParticipant?.role &&
                currentUserAsParticipant?.role?.indexOf(EventUserRole.SESSION_COHOST) !== -1) ||
            false
        );
    }

    buildRedirectUrlWithRtmParameters(sponsor) {
        var url;
        if (sponsor?.redirectUrl.indexOf('http://') == 0) {
            url = sponsor?.redirectUrl.slice('http://'.length);
        } else if (sponsor?.redirectUrl.indexOf('https://') == 0) {
            url = sponsor?.redirectUrl.slice('https://'.length);
        } else {
            url = sponsor?.redirectUrl;
        }
        url =
            'https://' +
            url +
            (_.get(sponsor.utmParameters, 'campaignSource')
                ? '?utm_source=' + _.get(sponsor.utmParameters, 'campaignSource')
                : '') +
            (_.get(sponsor.utmParameters, 'campaignMedium')
                ? '&utm_medium=' + _.get(sponsor.utmParameters, 'campaignMedium')
                : '') +
            (_.get(sponsor.utmParameters, 'campaignName')
                ? '&utm_campaign=' + _.get(sponsor.utmParameters, 'campaignName')
                : '');
        return url;
    }
    getAllSponsorsData(sponsor, category) {
        this.sponsorsLogos.push({
            category: category,
            name: sponsor.name,
            logo: sponsor.logo,
            redirectUrl: this.buildRedirectUrlWithRtmParameters(sponsor)
        });
    }

    getSponsorsData(sponsors) {
        this.platinumSponsorLogos = [];
        this.goldSponsorLogos = [];
        this.silverSponsorLogos = [];
        this.sponsorsLogos = [];
        this.customCategoryNames = [];
        var self = this;
        _.forEach(sponsors, function (category) {
            if (!_.isEmpty(_.get(category, 'sponsors'))) {
                _.forEach(category.sponsors, function (sponsor) {
                    if (sponsor.logo !== '') {
                        if (category.category === 'Platinum') {
                            self.customCategoryNames[0] = category.title || category.category;
                            self.platinumSponsorLogos.push({
                                name: sponsor.name,
                                logo: sponsor.logo,
                                redirectUrl: self.buildRedirectUrlWithRtmParameters(sponsor)
                            });
                            self.getAllSponsorsData(sponsor, self.customCategoryNames[0]);
                        } else if (category.category === 'Gold') {
                            self.customCategoryNames[1] = category.title || category.category;
                            self.goldSponsorLogos.push({
                                name: sponsor.name,
                                logo: sponsor.logo,
                                redirectUrl: self.buildRedirectUrlWithRtmParameters(sponsor)
                            });
                            self.getAllSponsorsData(sponsor, self.customCategoryNames[1]);
                        } else {
                            self.customCategoryNames[2] = category.title || category.category;
                            self.silverSponsorLogos.push({
                                name: sponsor.name,
                                logo: sponsor.logo,
                                redirectUrl: self.buildRedirectUrlWithRtmParameters(sponsor)
                            });
                            self.getAllSponsorsData(sponsor, self.customCategoryNames[2]);
                        }
                    }
                });
            }
        });
        return {
            sponsorsLogos: self.sponsorsLogos,
            platinumSponsorLogos: self.platinumSponsorLogos,
            goldSponsorLogos: self.goldSponsorLogos,
            silverSponsorLogos: self.silverSponsorLogos,
            customCategoryNames: self.customCategoryNames
        };
    }

    getWebinarSessionsInfo(webinarId, guest = false) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${webinarId}${guest ? '/sessionsDetails' : ''}`
        );
    }
    getSessionDetailsByDateRange(webinarId, startTime, endTime, guest = false) {
        return this.restService.get(
            `/api/sessions/${webinarId}${guest ? '/sessionsDetails' : ''}?from=${startTime}&to=${endTime}`
        );
    }

    toggleLowerAttendeeHandStatus(meetingId, participant) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/lowerattendeehand`,
            { attendeeUserIds: [participant.userId] }
        );
    }

    promoteOrDemote(meetingId, participant, toPromote = false) {
        var idsToSend = [participant.userId];
        if (!toPromote) {
            // get the loggedin user id as well and push to the array
            var loggedInId = this.promotedAttendeesLoggedninUidMap[participant.userId];
            if (loggedInId) {
                this.disablePromoteAttendees[participant.userId] = true;
                idsToSend.push(loggedInId);
            }
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/${
                toPromote ? 'promotetospeaker' : participant.isPromoted ? 'movetoattendee' : 'promotetospeaker'
            }`,
            { userIds: idsToSend }
        );
    }

    setWeinarSideNavs(sideNavs, activeNav, activeChildNav) {
        this.#sideNavLinks = sideNavs;
        this.#activeChildNav = activeChildNav;
        this.#activeNav = activeNav;
    }

    getWebinarSideNavs() {
        return this.#sideNavLinks;
    }

    navigateToNextRoute(activatedRoute, webinarId) {
        // Check if any child route exists for the current active parent route
        let currentChildRouteIndex = -1;
        if (this.#activeNav?.child) {
            // Find the index of the current active child route
            currentChildRouteIndex = this.#activeNav.child.findIndex(
                (child) => child.route === this.#activeChildNav.route
            );
        }

        // Find the index of the next active child route that is not disabled
        let nextChildIndex = currentChildRouteIndex + 1;
        while (nextChildIndex < this.#activeNav?.child.length && this.#activeNav?.child[nextChildIndex].disabled) {
            nextChildIndex++; // Skip disabled child routes
        }

        // If no more active child routes found, navigate to the next parent route
        if (nextChildIndex === -1 || nextChildIndex >= this.#activeNav?.child.length) {
            let currentParentNavIndex = this.#sideNavLinks.findIndex(
                (sideNav) => sideNav.route === this.#activeNav.route
            );
            const nextParentRoute = this.#sideNavLinks[++currentParentNavIndex];
            if (nextParentRoute) {
                this.router.navigate([`../../../${nextParentRoute.route}/${webinarId}`], {
                    relativeTo: activatedRoute
                });
            }
        } else {
            // If the next active child route is found, navigate to it
            const nextChildRoute = this.#activeNav?.child[nextChildIndex];
            if (nextChildRoute) {
                this.router.navigate([`../${nextChildRoute.route}`], { relativeTo: activatedRoute });
            }
        }
    }

    uploadPreRecordedVideo(file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/media?prerecorded=true`, formData, {
            observe: 'events',
            reportProgress: true
        });
    }

    getWebinarRecordingList(query) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/recording/webinar?skip=${query.skip}&limit=${query.limit}`
        );
    }

    uploadRecordingVideo(data) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/media/recording`, data);
    }

    allowStart(meeting) {
        const currentUser = this.userService.getUserSync();
        const isCoHost = this.checkForCoHost(meeting?.participants || [], currentUser);
        const isAdHocCoHost = this.checkForAdHocCoHost(meeting?.participants || [], currentUser);
        return (meeting.userId || meeting.hostInfo?._id) === currentUser._id || isCoHost || isAdHocCoHost;
    }

    getStreamInUrl(channelId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/rtmp/${channelId}/getStreamUrl`);
    }

    updateStreamIn(channelId, data) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/rtmp/${channelId}/updateStreamIn`,
            data
        );
    }

    generateRtmpStreamInKeys(data) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/rtmp/meeting/generateRtmpUrl`,
            data
        );
    }

    getPinAndId(sh) {
        return new Promise((resolve, reject) => {
            this.rtcService.getRoomPinAndId(sh).subscribe(
                (res) => {
                    resolve(res);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    setMaskedUrlForSpeaker(url) {
        this.maskedUrl = url;
    }

    getMaskedUrlForSpeaker() {
        return this.maskedUrl;
    }
}
