<div class="comments-list" scrollEndRoot>
    <div
        *ngFor="let comment of commentList; let i = index"
        class="post-comment"
        [class.noBorder]="commentList.length === i + 1"
    >
        <div class="comment-creator d-flex flex-row">
            <div class="participant-profile-pic">
                <div class="profile-wrap">
                    <div
                        *ngIf="!isAuthenticated || !comment.profilePicPath"
                        class="card-profile rounded-circle"
                        appAvatarWithInitials
                        [name]="comment?.creator?.creatorName"
                        [userId]="comment?.creator?.userId"
                    ></div>
                    <img
                        *ngIf="isAuthenticated && comment.profilePicPath"
                        class="card-profile"
                        [src]="comment?.profilePicPath"
                        alt=""
                    />
                    {{ comment?.profilePicPath }}
                </div>
            </div>
            <div class="comment-creator-container d-flex align-items-center">
                <div class="comment-creator-name">
                    {{ comment?.creator?.creatorName }}
                </div>
                <span class="comment-pointer">.</span>
                <div class="comment-created-time">
                    {{ comment.timeTobeDisplayed }}
                </div>
            </div>
            <div
                (click)="showDeleteDialog(comment)"
                class="delete-comment"
                *ngIf="comment?.creator?.userId === currentUser._id && !isEnded"
                pTooltip="Delete"
                tooltipPosition="top"
            >
                <img src="assets/img/delete-icn.svg" alt="" />
            </div>
        </div>
        <div class="comment-text" [innerHtml]="comment.attributes.comment | linkify"></div>
    </div>
    <div (scrollEnd)="loadComments()" rootMargin="0px 0px 100px 0px" class="scroll-end" scrollEnd></div>
    <div class="delete-entry-popup" *ngIf="showDeleteCommentPopup" [class.mobile]="isMobile">
        <div class="bg"></div>
        <div class="content">
            <div class="header d-flex justify-content-end">
                <span class="close pointer">
                    <img src="assets/img/revamp/pop_cross.svg" alt="" (click)="showDeleteCommentPopup = false" />
                </span>
            </div>
            <div class="body">
                <div class="title">{{ dialogData.title }}</div>
                <div class="subtitle">{{ dialogData.message }}</div>
            </div>
            <div class="footer">
                <div class="options d-flex justify-content-end">
                    <button class="btn custom-button bg-secondary" (click)="showDeleteCommentPopup = false">
                        Cancel
                    </button>
                    <button class="btn custom-button bg-primary" (click)="deleteComment(dialogData?.commentId)">
                        {{ dialogData.buttonText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
