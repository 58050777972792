<div [id]="id || 'event-invitation'" class="event-invite-container" style="line-height: 20px">
    <div *ngIf="invitationType === 'speaker'">
        <p style="font-weight: bold">Hi,</p>
        <div>
            To <span style="font-weight: bold">Join "{{ meeting?.topic }}"</span> as a
            <span style="font-weight: bold">Speaker</span> follow below instructions
        </div>
        <br />
        <div>
            <div style="font-weight: bold">
                <span>Event Title:</span>
                <span> {{ meeting?.topic }}</span>
            </div>
            <div>
                <span style="font-weight: bold">Host Name:</span>
                <span> {{ meeting?.hostInfo?.name }} {{ meeting?.hostInfo?.lname }}</span>
            </div>
            <div>
                <span style="font-weight: bold">Date & Time:</span>
                <span>
                    {{ meeting.startTime || meeting?.meetingInfo?.startTime | date: 'EEEE dd-MM-yyyy, HH:mm:ss ' }}
                    (IST)</span
                >
                | <span *ngIf="!meeting?.isMultiDay">{{ durationString }} </span>
                <span *ngIf="meeting?.isMultiDay">
                    <span>
                        {{ meeting.endTime || meeting?.meetingInfo?.endTime | date: 'EEEE dd-MM-yyyy, HH:mm:ss ' }}
                        (IST)</span
                    ></span
                >
            </div>
        </div>
        <br />
        <p style="margin: 0px">
            <a class="pointer" target="_blank" href="{{ speakerUrl }}">
                <button
                    style="
                        outline: none !important;
                        border: none !important;
                        background-color: none !important;
                        border-radius: 20px !important;
                        height: 30px !important;
                        padding: 0 38px !important;
                        user-select: none;
                        transition: background-color 0.3s ease;
                        background: #4372c4;
                        color: white;
                    "
                >
                    Speaker Link
                </button>
            </a>
        </p>
        <br />
        <div>
            <div style="font-weight: bold">More Ways to Join-</div>
            <br />
            <p style="font-weight: bold; margin-bottom: 0">Join Using Speaker Link:</p>
            <p style="font-weight: bold; margin-bottom: 0">
                <a class="pointer" target="_blank" href="{{ speakerUrl }}">{{ speakerUrl }}</a>
            </p>
        </div>
        <ng-container *ngIf="!maskedUrl">
            <br />
            <div style="font-weight: bold">Join Using Event Number:</div>
            <br />
            <div>
                <span style="font-weight: bold">Event ID: </span><span>{{ this.meeting?.jiomeetId }}</span>
            </div>
            <div>
                <span style="font-weight: bold">Event Password: </span><span>{{ this.meeting?.pin }}</span>
            </div>
            <div style="font-weight: bold">Join using JioMeet Mobile, Desktop application and use above details.</div>
            <br />
        </ng-container>
        <div>
            <ng-container
                *ngIf="
                    meeting.legacyConnect?.SIP ||
                    (meeting?.legacyConnect?.settings?.internetIp?.value &&
                        !meeting?.legacyConnect?.settings?.internetIp?.hide) ||
                    meeting.legacyConnect?.SIP ||
                    (meeting?.legacyConnect?.settings?.intranetIp?.value &&
                        !meeting?.legacyConnect?.settings?.intranetIp?.hide)
                "
            >
                <div style="font-weight: bold">Join from Video System or SIP Devices:</div>
                <div
                    *ngIf="
                        meeting?.legacyConnect?.settings?.intranetIp?.value &&
                        !meeting?.legacyConnect?.settings?.intranetIp?.hide
                    "
                >
                    Dial
                    <span style="font-weight: bold"
                        >{{ meeting?.legacyConnect?.settings?.intranetIp?.value }} (Intranet)</span
                    >
                </div>
                <div
                    *ngIf="
                        meeting?.legacyConnect?.settings?.internetIp?.value &&
                        !meeting?.legacyConnect?.settings?.internetIp?.hide
                    "
                >
                    <span
                        *ngIf="
                            !meeting?.legacyConnect?.settings?.intranetIp?.value ||
                            meeting?.legacyConnect?.settings?.intranetIp?.hide
                        "
                        >Dial</span
                    >
                    <span
                        *ngIf="
                            meeting?.legacyConnect?.settings?.intranetIp?.value &&
                            !meeting?.legacyConnect?.settings?.intranetIp?.hide
                        "
                        >&nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span style="font-weight: bold">
                        {{ meeting?.legacyConnect?.settings?.internetIp?.value }} (Internet)</span
                    >
                </div>
                <div
                    *ngIf="
                        (meeting?.legacyConnect?.settings?.internetIp?.value &&
                            !meeting?.legacyConnect?.settings?.internetIp?.hide) ||
                        (meeting?.legacyConnect?.settings?.intranetIp?.value &&
                            !meeting?.legacyConnect?.settings?.intranetIp?.hide)
                    "
                >
                    and enter Speaker details.
                </div>
                <ng-container
                    *ngIf="
                        (meeting?.legacyConnect?.settings?.internetIp?.value &&
                            !meeting?.legacyConnect?.settings?.internetIp?.hide) ||
                        (meeting?.legacyConnect?.settings?.intranetIp?.value &&
                            !meeting?.legacyConnect?.settings?.intranetIp?.hide)
                    "
                >
                    <p style="font-weight: bold; margin-bottom: 0">
                        <span>Meeting ID: </span><span>{{ this.meeting?.jiomeetId }}</span>
                    </p>
                    <p style="font-weight: bold; margin-bottom: 0">
                        <span>Meeting Passcode: </span><span>{{ this.meeting?.pin }}</span>
                    </p>
                </ng-container>

                <p
                    style="font-weight: bold; margin-bottom: 0"
                    *ngIf="
                        (meeting?.legacyConnect?.SIP &&
                            meeting?.legacyConnect?.settings?.internetIp?.value &&
                            !meeting?.legacyConnect?.settings?.internetIp?.hide) ||
                        (meeting?.legacyConnect?.SIP &&
                            meeting?.legacyConnect?.settings?.intranetIp?.value &&
                            !meeting?.legacyConnect?.settings?.intranetIp?.hide)
                    "
                >
                    Or
                </p>

                <ng-container *ngIf="meeting.legacyConnect?.SIP">
                    <p style="font-weight: bold; margin-bottom: 0" *ngIf="meeting?.legacyConnect?.SIP?.url">
                        You can also dial
                        <a [href]="sipUrl | safe">{{ meeting?.legacyConnect?.SIP?.url }}</a>
                    </p>
                </ng-container>
            </ng-container>
        </div>
        <br />
        <div style="font-weight: bold">
            Note: For better experience, Join using Mobile/Desktop/Laptop with Chrome/Safari Browser
        </div>
        <br />
        <p style="font-weight: bold; margin-bottom: 0">Regards,</p>
        <p style="font-weight: bold">Team JioEvents</p>
    </div>
    <div *ngIf="invitationType === 'attendee'">
        <p style="font-weight: bold">Hi,</p>
        <div>
            To <span style="font-weight: bold">Join "{{ meeting?.topic }}"</span> as an
            <span style="font-weight: bold">Attendee</span> follow below instructions
        </div>
        <br />
        <div>
            <div style="font-weight: bold">
                <span>Event Title:</span>
                <span> {{ meeting?.topic }}</span>
            </div>
            <div>
                <span style="font-weight: bold">Host Name:</span>
                <span> {{ meeting?.hostInfo?.name }} {{ meeting?.hostInfo?.lname }}</span>
            </div>
            <div>
                <span style="font-weight: bold">Date & Time:</span>
                <span>
                    {{ meeting.startTime || meeting?.meetingInfo?.startTime | date: 'EEEE dd-MM-yyyy, HH:mm:ss ' }}
                    (IST)</span
                >
                | <span *ngIf="!meeting?.isMultiDay">{{ durationString }} </span>
                <span *ngIf="meeting?.isMultiDay">
                    <span>
                        {{ meeting.endTime || meeting?.meetingInfo?.endTime | date: 'EEEE dd-MM-yyyy, HH:mm:ss ' }}
                        (IST)</span
                    ></span
                >
            </div>
        </div>
        <br />
        <div>
            <a
                class="pointer"
                target="_blank"
                href="{{ meeting?.webinar?.activation?.url || meeting?.webinar?.registration?.url }}"
            >
                <button
                    style="
                        outline: none !important;
                        border: none !important;
                        background-color: none !important;
                        border-radius: 20px !important;
                        height: 30px !important;
                        padding: 0 38px !important;
                        user-select: none;
                        transition: background-color 0.3s ease;
                        background: #4372c4;
                        color: white;
                    "
                >
                    Attendee Link
                </button>
            </a>
        </div>
        <br />
        <div style="font-weight: bold">
            Note: For better experience, Join using Mobile/Desktop/Laptop with Chrome/Safari Browser
        </div>
        <br />
        <p style="font-weight: bold; margin-bottom: 0">Regards,</p>
        <p style="font-weight: bold">Team JioEvents</p>
    </div>
</div>
