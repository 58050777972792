import {
    Component,
    Input,
    OnInit,
    Renderer2,
    NgZone,
    OnDestroy,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    HostListener,
    AfterViewInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService, UtilService, AuthService, UserService } from 'src/app/core';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SwiperOptions } from 'swiper';
import { APP_CSS_SIZE_CONSTANT } from 'src/app/constants/css-size-constants';
import { SponsorsComponent } from 'src/app/shared/components';

@Component({
    selector: 'app-post-session-screen',
    templateUrl: './post-session-screen.component.html',
    styleUrls: ['./post-session-screen.component.scss']
})
export class PostSessionPreviewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('playerOuterContainer') playerOuterContainer: ElementRef;
    @ViewChild('platinumSponsorsContainer') platinumSponsorsContainer: ElementRef;
    @ViewChild('platinumSponsorsContainerRight') platinumSponsorsContainerRight: ElementRef;
    @ViewChild('platinumSponsorsContainerRightChild') platinumSponsorsContainerRightChild: ElementRef;
    @ViewChild('otherSponsorsContainer') otherSponsorsContainer: ElementRef;
    @ViewChild('eventCenterView') eventCenterView: ElementRef;
    @ViewChild('conferencePlayer') conferencePlayer: ElementRef;
    @ViewChild('rightControlsView') rightControlsView: ElementRef;
    @ViewChild('eventTitleView') eventTitleView: ElementRef;
    @ViewChild('mobile-common-view') mobileCommonView: ElementRef;

    @Input() webinarId;
    @Input() isPreviewScreen;
    @Input() isIFrameAutoJoinAttendee;
    loading: boolean = true;
    webinarDetails;
    thankYouMsg = '';
    defaultUrl = '';
    poweredByLogoUrl = '';
    enableMarkdownForm = false;
    guestUser;
    useGenericPostSession: boolean = this.appService.getConfigVariable('useGenericPostSession');
    isAuthenticated: boolean = false;
    isPotrait: boolean = false;
    isLandscape: boolean = false;
    showMobileView: boolean = false;
    layoutSwitch: boolean = false;
    isMobile;
    showSwiper = true;
    isMobileOS;
    subscriptions: Subscription[] = [];
    platinumSponsorLogos: any = [];
    goldSponsorLogos: any = [];
    silverSponsorLogos: any = [];
    sponsorsLogos: any = [];
    customCategoryNames: any = [];
    brandDetails;
    isPlatinumSponsorsAtTopEnabled: boolean;
    currentSponsorCategory = '';
    fromBrandingScreen: boolean = false;
    overallSponsorsLength = 0;
    public config: SwiperOptions = {
        speed: 1200,
        loop: true,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false
        },
        spaceBetween: 30,
        pagination: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 40
            },

            1200: {
                slidesPerView: 1
            }
        }
    };

    playerResizeObserver: ResizeObserver;
    pageLogoUrl: any;
    showThankyouContainer: boolean = false;
    constructor(
        private webinarService: WebinarService,
        private utilService: UtilService,
        private appService: AppService,
        private router: Router,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private userService: UserService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private appLoggerService: AppLoggerService,
        private render: Renderer2
    ) {
        this.poweredByLogoUrl = this.appService.getDefaultWebsiteLogo('white', true);
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.isPlatinumSponsorsAtTopEnabled =
            this.appService.getConfigVariable('ENABLE_TOP_BOTTOM_SPONSORS_LAYOUT') || false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setupInitialPlayerAndSponsorsUI();
        console.log(`>>>window resize`);

        this.checkIfMobileOS();
    }

    checkIfMobileOS() {
        if (window.innerWidth < 900) {
            this.isMobileOS = true;
        } else {
            this.isMobileOS = false;
        }
    }

    ngOnInit(): void {
        this.checkIfMobileOS();
        this.playerResizeObserver = new ResizeObserver((entries, observer) => {
            this.setupInitialPlayerAndSponsorsUI();
        });
        this.getWebinarInfo();
        this.checkIfPreviewScreen();
        this.getBrandDetails();
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.guestUser = this.userService.getGuestUser() || false;
        this.activatedRoute.queryParams.subscribe((param) => {
            this.fromBrandingScreen = param.fromBranding === 'true' ? true : false;
            this.isMobile = param.isMobile === 'true' ? true : false;
            this.showMobileView = this.isMobile;
            this.isPotrait = param.isPotrait === 'true' && this.isPlatinumSponsorsAtTopEnabled ? true : false;
            this.isLandscape = param.isLandscape === 'true' ? true : false;
            this.setupInitialPlayerAndSponsorsUI();
        });
        this.enableMarkdownForm = this.appService.getConfigVariable('ENABLE_MARKDOWN_FOR_FORM') || false;
        setTimeout(() => {
            this.showThankyouContainer = true;
        }, 10000);
    }
    getWebinarInfo() {
        this.webinarService.webinarAttendeePageDetails(this.webinarId).subscribe((res) => {
            this.webinarDetails = res;
            this.webinarDetails.eventType = encodeURIComponent(this.webinarDetails?.eventType) || '';
            this.defaultUrl = `${this.appService.getEnvVariable('CONF_URL')}assets/img/jioconf/background-images/${
                this.webinarDetails?.eventType
            }.png`;
            if (this.webinarDetails.thankYouMsg?.enabled && this.webinarDetails.thankYouMsg?.text) {
                this.thankYouMsg = this.utilService.sanitizeAndConvertEncodedHTML(
                    this.webinarDetails?.thankYouMsg?.text
                );
            }
            this.loading = false;
            this.setupObserver();
        });
    }

    ngAfterViewInit(): void {}

    @HostListener('click', ['$event.target']) onClick(event) {
        this.render.removeClass(event.parentElement, 'show');
    }

    checkIfPreviewScreen() {
        this.isPreviewScreen = window.location.href.indexOf('/preview') > -1;
    }
    getCategoryForSponsors() {
        if (this.overallSponsorsLength === 1) {
            return this.platinumSponsorLogos.length > 0
                ? 'Platinum'
                : this.goldSponsorLogos.length > 0
                ? 'Gold'
                : 'Silver';
        }
        return this.platinumSponsorLogos.length > 0 && this.goldSponsorLogos.length > 0 ? 'Gold' : 'Silver';
    }
    navToSignup() {
        // this.router.navigate(['/login']);
        window.open(`${this.appService.getEnvVariable('CONF_URL')}/login`, '_self');
    }
    navigateToThumbnailPreview() {
        this.router.navigate([`/events/thumbnail/preview/${this.webinarId}`], {
            queryParams: { isMobile: this.isMobile, fromBranding: this.fromBrandingScreen }
        });
    }
    navigateToWelcomePagePreview() {
        this.router.navigate([`/events/welcome-page/preview/${this.webinarId}`], {
            queryParams: { isMobile: this.isMobile, fromBranding: this.fromBrandingScreen }
        });
    }
    navigateToInSessionPreview() {
        this.router.navigate([`/events/in-session/preview/${this.webinarId}`], {
            queryParams: {
                isMobile: this.isMobile,
                isPotrait: this.isPotrait,
                isLandscape: this.isLandscape,
                fromBranding: this.fromBrandingScreen
            }
        });
    }
    switchLayout(type) {
        this.isPotrait = type === 'potrait' && this.isPlatinumSponsorsAtTopEnabled ? true : false;
        this.isLandscape = type === 'landscape' ? true : false;
        this.setupInitialPlayerAndSponsorsUI();
    }
    toggleLayoutMode(type) {
        this.layoutSwitch = true;
        this.switchLayout(type);
    }
    togglePreview(type) {
        this.showMobileView = type === 'mobile' ? true : false;
        this.isMobile = this.showMobileView;
        this.cd.detectChanges();
        // window.dispatchEvent(new Event('resize'));
        if (this.isMobile) {
            this.showSwiper = false;
            setTimeout(() => {
                this.showSwiper = true;
            }, 500);
        }
        this.setupInitialPlayerAndSponsorsUI();
    }

    onMobileSwiperSlideChange(currentSlideIndex) {
        var currentSponsor = this.sponsorsLogos[currentSlideIndex];
        this.currentSponsorCategory = currentSponsor?.category;
        if (this.brandDetails?.sponsors?.length == 1) {
            this.config.pagination = false;
            this.config.loop = false;
        }
    }
    getBrandDetails() {
        this.webinarService.getBrand(this.webinarId).subscribe(
            (res: any) => {
                this.brandDetails = res;
                var layout = _.cloneDeep(this.brandDetails?.sponsorsLayout);
                this.brandDetails.sponsors = this.brandDetails?.sponsors.filter((sp) => sp.sponsors.length > 0);
                if (!this.isPotrait && !this.isLandscape) this.switchLayout(layout?.thankYouPage);
                var sponsors = this.webinarService.getSponsorsData(this.brandDetails?.sponsors);
                this.overallSponsorsLength = this.brandDetails?.sponsors?.filter((sp) => sp.sponsors.length > 0).length;
                this.sponsorsLogos = sponsors?.sponsorsLogos;
                this.platinumSponsorLogos = sponsors?.platinumSponsorLogos;
                this.goldSponsorLogos = sponsors?.goldSponsorLogos;
                this.silverSponsorLogos = sponsors?.silverSponsorLogos;
                this.customCategoryNames = sponsors?.customCategoryNames;
                this.currentSponsorCategory = this.customCategoryNames.length > 0 ? this.customCategoryNames[0] : '';
                this.pageLogoUrl = this.brandDetails?.welcomeLogo
                    ? this.brandDetails?.welcomeLogo
                    : this.appService.getDefaultWebsiteLogo('white', false);
                this.setupInitialPlayerAndSponsorsUI();
            },
            (err) => {
                this.appLoggerService.error('Error while fetching the brand details', err);
            }
        );
    }
    setInSessionLayout(isLandscape) {
        var layout = _.cloneDeep(this.brandDetails.sponsorsLayout);
        layout.thankYouPage = isLandscape ? 'landscape' : 'potrait';
        this.webinarService.updateBrand(this.webinarId, { sponsorsLayout: layout }).subscribe(
            (res) => {
                this.switchLayout(_.get(res, 'sponsorsLayout.thankYouPage'));
                this.toastrService.info('The selected layout will be visible on the attendee screens.');
                this.layoutSwitch = false;
            },
            (err) => {
                this.appLoggerService.error('Error while updating the brand details', err);
            }
        );
    }

    setupObserver() {
        if (this.playerOuterContainer) {
            this.playerResizeObserver.observe(this.playerOuterContainer?.nativeElement);
        } else {
            setTimeout(() => {
                this.setupObserver();
            }, 500);
        }
    }

    setupInitialPlayerAndSponsorsUI() {
        setTimeout(() => {
            let playerWidth = this.conferencePlayer?.nativeElement?.clientWidth;

            if (this.isMobileOS && this.conferencePlayer) {
                this.conferencePlayer.nativeElement.style.width = `100%`;
                this.conferencePlayer.nativeElement.style.maxWidth = `100%`;
                this.conferencePlayer.nativeElement.style.height = `100%`;
                this.conferencePlayer.nativeElement.style.maxHeight = `100%`;
                this.eventTitleView.nativeElement.style.width = `100%`;
                this.eventTitleView.nativeElement.style.maxWidth = `100%`;
                return;
            }
            if (!this.conferencePlayer) {
                return;
            }

            if (playerWidth > 0 && !this.isMobile) {
                let availableHeight = this.eventCenterView.nativeElement.clientHeight - 200;
                if (this.eventTitleView) {
                    this.eventTitleView.nativeElement.style.width = `${playerWidth}px`;
                    this.eventTitleView.nativeElement.style.maxWidth = `${playerWidth}px`;
                }
                // let widthNeedsToBeReduced =
                //     this.rightControlsView?.nativeElement?.clientWidth +
                //     this.leftSponsorsContainer?.nativeElement.clientWidth +
                //     this.rightSponsorsContainer?.nativeElement.clientWidth +
                //     28; //paddings

                let widthNeedsToBeReduced =
                    APP_CSS_SIZE_CONSTANT.ATTENDEE_CONFERENCE_RIGHT_CONTROLS_WIDTH +
                    APP_CSS_SIZE_CONSTANT.LEFT_SPONSORS_WIDTH +
                    APP_CSS_SIZE_CONSTANT.RIGHT_SPONSORS_WIDTH +
                    100; //paddings
                let availableWidth = window.innerWidth - widthNeedsToBeReduced;
                let calcWidth = availableHeight * 1.77;
                if (availableWidth > calcWidth) {
                    this.conferencePlayer.nativeElement.style.width = `${calcWidth}px`;
                    this.conferencePlayer.nativeElement.style.maxWidth = `${calcWidth}px`;
                    this.conferencePlayer.nativeElement.style.height = `${availableHeight}px`;
                    this.conferencePlayer.nativeElement.style.maxHeight = `${availableHeight}px`;
                } else {
                    this.conferencePlayer.nativeElement.style.width = `${availableWidth}px`;
                    this.conferencePlayer.nativeElement.style.maxWidth = `${availableWidth}px`;
                    this.conferencePlayer.nativeElement.style.height = `${availableWidth * 0.5625}px`;
                    this.conferencePlayer.nativeElement.style.maxHeight = `${availableWidth * 0.5625}px`;
                }
            } else {
                this.conferencePlayer.nativeElement.style.width = `100%`;
                this.conferencePlayer.nativeElement.style.maxWidth = `100%`;
                this.conferencePlayer.nativeElement.style.height = `100%`;
                this.conferencePlayer.nativeElement.style.maxHeight = `100%`;
            }
        }, 50);
    }

    ngOnDestroy() {
        this.subscriptions.map((s) => s.unsubscribe());
    }
}
