import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AppService } from './app.service';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { UtilService } from './util.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JiocloudAuthService } from './jiocloud-auth.service';

@Injectable({
    providedIn: 'root'
})
export class JiocloudService {
    constructor(
        private restService: RestService,
        private appService: AppService,
        private userService: UserService,
        private utilService: UtilService,
        private jiocloudAuthService: JiocloudAuthService
    ) {}

    singleFileUpload({ file, fileMetadata, meetingId }): Observable<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);
        const formData = new FormData();
        formData.append('metadataString', JSON.stringify(fileMetadata));
        formData.append('file', file);

        return this.restService.post(
            `${this.appService.getEnvVariable('JIO_CLOUD').HOST_UPLOAD}/upload/files`,
            formData,
            {
                headers,
                reportProgress: true,
                observe: 'events'
            }
        );
    }

    initiateUpload({ fileMetadata, meetingId }) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);

        return this.restService.post(
            `${this.appService.getEnvVariable('JIO_CLOUD').HOST_UPLOAD}/upload/files/chunked/initiate`,
            fileMetadata,
            {
                headers,
                observe: 'events',
                reportProgress: true
            }
        );
    }

    uploadChunk(chunk, currentOffset, chunkChecksum, transactionId, meetingId) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);

        headers = headers.set('X-Offset', `${currentOffset}`);
        headers = headers.set('Content-MD5', ` ${chunkChecksum}`);
        headers = headers.set('Content-Type', 'application/octet-stream');
        headers = headers.set('processData', 'false');

        return this.restService.put(
            `${this.appService.getEnvVariable('JIO_CLOUD').HOST_UPLOAD}/upload/files/chunked?uploadId=${transactionId}`,
            chunk,
            {
                headers,
                observe: 'events',
                reportProgress: true
            }
        );
    }

    downloadFile(fileName, downloadUrl, meetingId) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);

        if (this.appService.getEnvVariable('local')) {
            const url = new URL(downloadUrl);
            url.hostname = `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
            downloadUrl = url.href;
        }
        return this.restService.get(downloadUrl, {
            headers,
            responseType: 'blob'
        });
        // .pipe(map((val) => downloadUrl));
    }

    openPreviewForDocs(url, meetingId, fileName) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);
        headers = headers.set('Content-Type', 'application/pdf');

        if (this.appService.getEnvVariable('local')) {
            const tempUrl = new URL(url);
            tempUrl.hostname = `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
            url = tempUrl.href;
        }
        return this.restService
            .get(url, {
                headers,
                responseType: 'arraybuffer',
                dataType: 'blob'
            })
            .pipe(map((val) => val));
    }

    createShareLinkForTheUploadedFile(meetingId) {
        const payload = {
            objectKey: '07351e7628564484b957ebc46341bd61'
        };
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);
        return this.restService.post(
            `${this.appService.getEnvVariable('JIO_CLOUD').HOST}/share/meeting/links`,
            payload,
            {
                headers
            }
        );
    }

    getThumbNailUrl(meetingImageTranscodeUrl, meetingId, size, mimeType) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);
        if (this.appService.getEnvVariable('local')) {
            const url = new URL(meetingImageTranscodeUrl);
            url.hostname = `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
            meetingImageTranscodeUrl = url.href;
        }
        return this.restService
            .get(`${meetingImageTranscodeUrl}${size}`, {
                headers,
                responseType: 'blob'
            })
            .pipe(map((val) => this.utilService.bypassSecurityTrustResourceUrl(URL.createObjectURL(val))));
    }

    getPlaybackSourceUrl(meetingPlaybackUrl, meetingId) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);
        if (this.appService.getEnvVariable('local')) {
            const url = new URL(meetingPlaybackUrl);
            url.hostname = `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
            meetingPlaybackUrl = url.href;
        }
        return this.restService.get(meetingPlaybackUrl, {
            headers,
            responseType: 'text'
        });
    }

    deleteAttachment(objectKey, meetingId) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.getCommonHeaders(headers, meetingId);
        headers = headers.set('X-Client-Details', 'clientType:web;appVersion:1.0.0');
        // let deleteUrl = this.appService.getEnvVariable('JIO_CLOUD').DELETE_URL;

        let deleteUrl = `${this.appService.getEnvVariable('JIO_CLOUD').HOST}/nms/meeting/trash`;
        const payload = {
            objectKey
        };
        return this.restService.post(deleteUrl, payload, { headers });
    }

    private getCommonHeaders(headers: HttpHeaders, meetingId) {
        headers = headers.set('X-Meeting-Id', meetingId);
        return headers;
    }
}
