<div
    class="ticker-component-wrapper"
    [class.mini]="mini"
    [class.fixtobottom]="fixtobottom"
    [class.isMobileOS]="isMobileOS"
    [class.withoutSponsors]="!withSponsors"
>
    <div class="small-parallelogram"></div>
    <div class="small-parallelogram2"></div>
    <div class="ticker-wrap" [class.attendee-conference]="isAttendee" tabindex="0" aria-label="" aria-hidden="true">
        <div class="ticker" id="ticker" [class.mini]="mini" [style.animation-duration]="animationDuration">
            <div class="ticker__item" *ngFor="let ticker of tickerText">
                <div class="ticker-text ml-2" [innerHTML]="ticker | sanitizeHtml"></div>
            </div>
        </div>
    </div>
</div>
