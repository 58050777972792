import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RestService } from './rest.service';

import * as _ from 'lodash-es';

const NA = 'NA';
const ES_CONTENT_TYPE = 'application/x-ndjson';
const DEFAULT_ERROR_MESSAGE = 'Error without a message';
const API_KEY = 'ApiKey';
const STRING_TYPE = 'string';

export enum LogLevel {
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4
}

@Injectable({
    providedIn: 'root'
})
export class AppLoggerService {
    public logsEnabled = false;
    private userInfo: any;
    private currentMeetingInfo: any;
    private currentWebinarInfo: any;
    private currentAgoraInfo: any;
    private uniqueBrowserId: any;
    private deviceUId: any;
    private guestUserInfo: any;
    private attendeeHash: string;
    private cloudLoggingConfig: any;
    private enableCloudLogging: boolean;
    private enableConsoleLogging: boolean = true;
    private logBuffer = [];
    private isUploading = false;
    private originalConsoleLog;
    private originalConsoleDebug;
    private originalConsoleAssert;
    private originalConsoleInfo;
    private originalConsoleError;
    private originalConsoleWarn;
    private originalConsoleFns;

    constructor(private appService: AppService, private restService: RestService) {
        this.originalConsoleFns = {
            log: console.log,
            warn: console.warn,
            error: console.error,
            debug: console.debug,
            assert: console.assert,
            info: console.info
        };
    }

    initialize() {
        this.cloudLoggingConfig = this.appService.getConfigVariable('cloudLogging');
        this.enableCloudLogging = this.cloudLoggingConfig?.enable;
        this.enableConsoleLogging = !!this.appService.getConfigVariable('LOGS_ENABLED');
        if (this.enableCloudLogging) {
            setInterval(this.uploadLogs.bind(this), this.cloudLoggingConfig.timeout);
        }
        this.checkAndAssignConsoleFunctions(this.enableConsoleLogging);
        this.overrideConsoleFunctions();
    }

    updateConsoleLogging(enable) {
        this.enableConsoleLogging = enable;
        this.checkAndAssignConsoleFunctions(enable);
    }

    checkAndAssignConsoleFunctions(enable) {
        if (enable) {
            this.originalConsoleAssert = this.originalConsoleFns.assert;
            this.originalConsoleDebug = this.originalConsoleFns.debug;
            this.originalConsoleInfo = this.originalConsoleFns.info;
            this.originalConsoleLog = this.originalConsoleFns.log;
            this.originalConsoleError = this.originalConsoleFns.error;
            this.originalConsoleWarn = this.originalConsoleFns.warn;
        }
    }

    overrideConsoleFunctions() {
        console.log = this.log.bind(this);
        console.debug = this.debug.bind(this);
        console.info = this.info.bind(this);
        console.warn = this.warn.bind(this);
        console.error = this.error.bind(this);
        console.assert = this.assert.bind(this);
    }

    setGuestUserInfo(guestUser: any) {
        this.guestUserInfo = guestUser;
    }

    setAttendeeHash(hash: string) {
        this.attendeeHash = hash;
    }

    setUserInfo(userInfo) {
        this.userInfo = userInfo;
    }

    setMeetingInfo(meetingInfo) {
        // TODO format meeting id by removing spaces?
        this.currentMeetingInfo = meetingInfo;
    }

    setWebinarInfo(webinarInfo) {
        this.currentWebinarInfo = webinarInfo;
    }

    setAgoraInfo(agoraInfo) {
        this.currentAgoraInfo = agoraInfo;
    }

    setDeviceUID(deviceUid) {
        this.deviceUId = deviceUid;
    }

    setBrowserUID(uniqueBrowserId) {
        this.uniqueBrowserId = uniqueBrowserId;
    }

    isCloudLoggingUrl(url: string): boolean {
        return this.cloudLoggingConfig && url.indexOf(this.cloudLoggingConfig.url) > -1;
    }

    queueLogs(level: LogLevel, message, args?) {
        if (level >= this.cloudLoggingConfig.level) {
            var data = {};
            var error;
            if (!_.isEmpty(args)) {
                var head = _.head(args);
                if (head instanceof Error) {
                    // incase of error as JSON.stringify won't work build the object with stack
                    error = {
                        stack: head.stack
                    };
                } else if (_.isObject(head) && !_.isArray(head)) {
                    // consider only one argument and drop it if it is not an object
                    data = head;
                }
            }
            var logDocument = {
                '@timestamp': new Date().toISOString(),
                level: level,
                identity:
                    this.userInfo?.email ||
                    this.userInfo?.phoneNo ||
                    this.guestUserInfo?.emailId ||
                    this.guestUserInfo?.phoneNo ||
                    NA,
                userId: this.userInfo?._id || NA, // guest user id ?
                name: this.guestUserInfo?.name || this.guestUserInfo?.firstName || this.userInfo?.name || NA,
                lname: this.guestUserInfo?.lname || this.guestUserInfo?.lastName || this.userInfo?.lname || NA,
                meetingId: this.currentMeetingInfo?.jiomeetId || NA,
                webinarId: this.currentWebinarInfo?.webinarId || NA,
                isBroadcasting: this.currentMeetingInfo?.webinar?.broadcasting,
                message: typeof message === STRING_TYPE ? message.toString() : JSON.stringify(message),
                attendeeHash: this.attendeeHash,
                agoraInfo: {
                    channel: this.currentAgoraInfo?.channel,
                    uid: this.currentAgoraInfo?.agoraUid
                },
                deviceInfo: {
                    uniqueBrowserId: this.uniqueBrowserId,
                    deviceUid: this.deviceUId
                },
                userAgent: window.navigator.userAgent,
                data,
                error
            };
            this.logBuffer.push(logDocument);
            if (this.logBuffer.length >= this.cloudLoggingConfig.batchSize) {
                this.uploadLogs();
            }
        }
    }

    async uploadLogs() {
        try {
            if (!this.isUploading && !_.isEmpty(this.logBuffer)) {
                this.isUploading = true;
                let body = '';
                this.logBuffer.forEach((doc) => {
                    try {
                        body += `${JSON.stringify({ index: { _index: this.cloudLoggingConfig.index } })}\n`;
                        body += `${JSON.stringify(doc)}\n`;
                    } catch (err) {
                        this.logInternalError(err);
                    }
                });
                body += '\n';
                var tempBuffer = this.logBuffer;
                const bulkResponse: any = await this.restService
                    .post(this.cloudLoggingConfig.url, body, {
                        headers: {
                            Authorization: `${API_KEY} ${this.cloudLoggingConfig.apiKey}`,
                            'Content-Type': ES_CONTENT_TYPE
                        }
                    })
                    .toPromise();
                this.logBuffer = [];
                if (bulkResponse.errors) {
                    const erroredDocuments = [];
                    // The items array has the same order of the dataset we just indexed.
                    // The presence of the `error` key indicates that the operation
                    // that we did for the document has failed.
                    bulkResponse.items.forEach((action, i) => {
                        const operation = Object.keys(action)[0];
                        // If the status is 429 it means that you can retry the document,
                        // otherwise it's very likely a mapping error, and you should
                        // fix the document before to try it again.
                        if (action[operation].error && action[operation].status === 429) {
                            this.logBuffer.push(tempBuffer[i]);
                        }
                    });
                }
                this.isUploading = false;
            }
        } catch (error) {
            this.logInternalError(error);
            this.logBuffer = [];
        } finally {
            if (!this.logBuffer) {
                this.logBuffer = [];
            }
            this.isUploading = false;
        }
    }

    logInternalError(error) {
        if (this.originalConsoleError) {
            this.originalConsoleError(error);
        } else {
            console.error(error);
        }
    }

    log(...args) {
        // anything passed without a level goes to debug
        if (this.enableCloudLogging) {
            var logArg = args[0];
            if (logArg instanceof Error) {
                var error = logArg;
                this.queueLogs.call(this, LogLevel.ERROR, error.message || DEFAULT_ERROR_MESSAGE, [error]);
            } else {
                this.queueLogs.call(this, LogLevel.DEBUG, args[0], _.tail(args));
            }
        }

        if (this.enableConsoleLogging) {
            if (args.length && args[0] instanceof Error) {
                this.originalConsoleError(...args);
            } else {
                this.originalConsoleLog(...args);
            }
        }
    }

    debug(...args) {
        // anything passed without a level goes to debug
        if (this.enableCloudLogging) {
            this.queueLogs.call(this, LogLevel.DEBUG, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleDebug(...args);
        }
    }

    info(...args) {
        if (this.enableCloudLogging) {
            this.queueLogs.call(this, LogLevel.INFO, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleInfo(...args);
        }
    }

    assert(...args) {
        if (this.enableCloudLogging) {
            this.queueLogs.call(this, LogLevel.DEBUG, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleAssert(...args);
        }
    }

    error(...args) {
        if (this.enableCloudLogging) {
            this.queueLogs.call(this, LogLevel.ERROR, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleError(...args);
        }
    }

    warn(...args) {
        if (this.enableCloudLogging) {
            this.queueLogs.call(this, LogLevel.WARN, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleWarn(...args);
        }
    }

    group(groupName, ...rest) {
        if (this.enableConsoleLogging) {
        }
    }

    groupEnd() {
        if (this.enableConsoleLogging) {
        }
    }

    handleError(error) {
        if (this.enableCloudLogging) {
            // add meeting context ?
            this.queueLogs.call(this, LogLevel.ERROR, error.message || DEFAULT_ERROR_MESSAGE, [error]);
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleError ? this.originalConsoleError(error) : console.error(error);
        }
    }
}
