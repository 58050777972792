import { Component, OnInit } from '@angular/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
@Component({
    selector: 'app-home-page-event',
    templateUrl: './home-page-event.component.html',
    styleUrls: ['./home-page-event.component.scss']
})
export class HomePageEventComponent implements OnInit {
    constructor(private googleTagManager: GoogleTagMangerService) {}

    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.EVENTS_PAGE_REVAMP_VIEW);
    }
}
