import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appDropdownToggle]'
})
export class DropdownToggleDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    @HostListener('click', ['$event'])
    toggleDropdown(event: Event): void {
        const clickedElement = this.el.nativeElement;
        const elements = clickedElement.querySelector('.rotate');
        const element = clickedElement.querySelector('.color-element');

        if (elements?.classList.contains('down')) {
            this.renderer.removeClass(elements, 'down');
        } else {
            this.renderer.addClass(elements, 'down');
        }

        if (element?.classList.contains('text-color-decorator')) {
            this.renderer.removeClass(element, 'text-color-decorator');
            this.renderer.addClass(element, 'text-animation');
        } else {
            this.renderer.addClass(element, 'text-color-decorator');
            this.renderer.removeClass(element, 'text-animation');
        }

        event.stopPropagation();
    }

    resetDropdown(): void {
        const elements = this.el.nativeElement.querySelector('.rotate');
        if (elements?.classList.contains('down')) {
            this.renderer.removeClass(elements, 'down');
        }

        const element = this.el.nativeElement.querySelector('.color-element');
        if (element?.classList.contains('text-color-decorator')) {
            this.renderer.removeClass(element, 'text-color-decorator');
            this.renderer.addClass(element, 'text-animation');
        }
    }

    @HostListener('document:click', ['$event'])
    resetToggleDropdown(event: Event): void {
        const clickedElement = event.target as HTMLElement;
        if (!this.el.nativeElement.contains(clickedElement)) {
            this.resetDropdown();
        }
    }
}
