import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';

import { AuthService, EventEmitterService, UserService, UtilService } from 'src/app/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    email;
    hash;
    changePasswordForm: FormGroup;
    errors: any = {};
    toggleUI = true;
    showPassword = false;
    validPassword = false;
    txParams = {
        characters: { value: 8 },
        alphabets: { value: 1 },
        numbers: { value: 1 }
    };

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private userService: UserService,
        private gaService: GaDiagnosticService,
        private authService: AuthService,
        private eventEmitterService: EventEmitterService,
        private translateService: TranslateService,
        private utilService: UtilService
    ) {}

    ngOnInit() {
        this.logout();
        this.activatedRoute.queryParams.subscribe((params) => {
            this.email = params.email;
            this.hash = params.hash;
        });
        this.changePasswordForm = this.fb.group({
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });
        this.changePasswordForm.get('password').valueChanges.subscribe(() => {
            this.validPassword = this.validatePassword(this.changePasswordForm.get('password').value);
        });
        this.gaService.sendPageView({
            page_title: 'reset_password_page',
            page_path: '/reset-password'
        });
    }

    logout() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.LOGOUT,
            data: { navigateToLogin: false }
        });
        this.authService.cleanup();
    }

    validate() {
        this.errors = {};
        const password = (this.changePasswordForm.get('password').value || '').trim();
        const confirmPassword = (this.changePasswordForm.get('confirmPassword').value || '').trim();
        if (password !== confirmPassword) {
            this.errors.confirmPassword = this.translateService.instant('tostrs.passwords_do_not_match_please_reenter');
            return false;
        }
        return true;
    }

    submit() {
        this.errors = {};
        if (!this.changePasswordForm.valid || !this.validPassword) {
            return;
        }
        if (!this.validate()) {
            return;
        }
        this.userService.resetPassword(this.email, this.changePasswordForm.get('password').value, this.hash).subscribe(
            (res) => {
                this.toggleUI = !this.toggleUI;
            },
            (err) => {
                this.errors.global = err.error?.errors || 'Something went wrong. Please try again.';
            }
        );
    }

    navigateToMeetings() {
        this.router.navigate(['/login']);
    }
    validatePassword(password) {
        this.errors.password = this.utilService.validatePassword(password);
        return !!this.errors.password ? false : true;
    }

    togglePassword() {
        this.showPassword = !this.showPassword;
    }
}
