<div class="page container-fluid" *ngIf="loading">
    <app-loader></app-loader>
</div>
<div class="page container-fluid" *ngIf="!mailSent && !loading">
    <div class="row h-100">
        <div class="left-content col-xl-6 col-lg-7 col-md-12 col-sm-12">
            <div class="img-wrap">
                <app-lottie-renderer *ngIf="!ottLogin" path="assets/lottie/Page Animation.json"></app-lottie-renderer>
                <app-lottie-renderer
                    *ngIf="ottLogin"
                    path="assets/lottie/Wrong OTP animation.json"
                ></app-lottie-renderer>
            </div>
        </div>
        <div class="right-content col-xl-6 col-lg-5 col-md-12 col-sm-12 py-5">
            <div class="component-wrap pb-3">
                <div class="form-wrap pb-5">
                    <section>
                        <h3 *ngIf="!ottLogin">{{ 'signup_page.title' | translate }}</h3>
                        <h3 *ngIf="ottLogin">
                            <div *ngIf="!newOTPSent">{{ 'signup_page.otpsent' | translate }}</div>
                            <div *ngIf="newOTPSent">{{ 'signup_page.newotpsent' | translate }}</div>
                            <div>"{{ signupForm.value.emailId }}"</div>
                        </h3>
                        <div class="signup-info text-center" *ngIf="alreadyRegistered">
                            {{ 'signup_page.allreadyregistered' | translate }}
                        </div>
                        <form [formGroup]="signupForm" *ngIf="!ottLogin" class="custom-form">
                            <div class="form-group">
                                <label for="name" class="m-0 mb-2">{{ 'signup_page.firstname' | translate }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    formControlName="name"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.name"
                                    minlength="1"
                                    maxlength="25"
                                    placeholder="{{ 'signup_page.firstname' | translate }}"
                                />
                                <span class="text-error" *ngIf="errors.name">{{ errors.name }}</span>
                            </div>
                            <div class="form-group">
                                <label for="lname" class="m-0 mb-2">{{ 'signup_page.lastname' | translate }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    formControlName="lname"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.lname"
                                    minlength="1"
                                    maxlength="25"
                                    placeholder="{{ 'signup_page.lastname' | translate }}"
                                />
                                <span class="text-error" *ngIf="errors.lname">{{ errors.lname }}</span>
                            </div>
                            <div class="form-group">
                                <label for="email" class="m-0 mb-2">{{
                                    'signup_page.your_emailid/mobile' | translate
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    formControlName="emailId"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.emailId"
                                    placeholder="{{ 'signup_page.emailid/mobile' | translate }}"
                                />
                                <span class="text-error" *ngIf="errors.emailId">{{ errors.emailId }}</span>
                            </div>
                            <span class="text-error" *ngIf="errors.global">{{
                                errors.global || errors.maxOTPLimitReached
                            }}</span>
                            <div class="col p-0 w-100 mt-4">
                                <button
                                    type="submit"
                                    class="custom-button bg-primary"
                                    [class.disabled]="!signupForm.valid || signingUp"
                                    (click)="submit()"
                                >
                                    {{ (signingUp ? 'signup_page.please_wait' : 'signup_page.submit') | translate }}
                                </button>
                            </div>
                        </form>
                        <form *ngIf="ottLogin" [formGroup]="ottForm" (submit)="verifyOTP()" class="custom-form">
                            <!-- <div class="form-group position-relative">
                <label for="email" class="m-0 mb-2">Your Mobile no.</label>
                <input type="text" class="form-control" id="email" formControlName="phoneNo" aria-describedby="phoneNoHelp" [class.is-invalid]="errors.phoneNo" placeholder="Mobile no." disabled />
                <div class="text pointer pt-4" (click)="changePhoneNo()">
                  change
                </div>
                <span class="invalid-feedback">{{ errors.phoneNo }}</span>
              </div> -->
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12" id="passwordfield">
                                        <label for="otp" class="m-0 mb-2">{{
                                            'signup_page.enterotp' | translate
                                        }}</label>
                                        <input
                                            type="password"
                                            class="form-control"
                                            formControlName="otp"
                                            id="password"
                                            #password
                                            [class.is-invalid]="errors.otp"
                                            placeholder="{{ 'signup_page.enterotp' | translate }}"
                                            maxlength="6"
                                        />
                                        <!-- <div class="text pointer pt-4 mt-1" (click)="getOTP()">
                      Resend OTP
                    </div> -->
                                        <span class="invalid-feedback">{{ errors.otp }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 w-100 mt-3">
                                <span class="text-error" *ngIf="errors.global">{{ errors.global }}</span>
                                <span class="text-error" *ngIf="errors.maxOTPLimitReached">{{
                                    errors.maxOTPLimitReached
                                }}</span>
                                <button
                                    type="submit"
                                    class="custom-button bg-primary mt-3"
                                    [class.disabled]="!ottForm.valid || verifyingOTP || disableOttLoginForm()"
                                >
                                    {{ 'signup_page.proceed' | translate }}
                                </button>
                            </div>
                        </form>
                        <div class="sign-up-text mt-2 text-center" *ngIf="!ottLogin">
                            <span class="acc-info mr-1">{{ 'signup_page.allready_have_an_account' | translate }}</span>
                            <span class="link">
                                <a class="text-primary" (click)="navigateToLogin()">{{
                                    'signup_page.signin' | translate
                                }}</a>
                            </span>
                        </div>
                        <div class="sign-up-text mt-3 text-center" *ngIf="ottLogin">
                            <span>{{ 'signup_page.haventreceived' | translate }} </span>
                            <span
                                ><a
                                    class="pointer blue d-inline"
                                    (click)="getOTP(true)"
                                    [class.disabled]="errors.maxOTPLimitReached"
                                    >Resend</a
                                ></span
                            >
                            <span> {{ 'signup_page.or' | translate }} </span>
                            <span
                                ><a class="pointer blue d-inline" (click)="userAnotherEmail()">{{
                                    'signup_page.usedifferent' | translate
                                }}</a></span
                            >
                        </div>
                    </section>
                    <section class="social-login mt-4">
                        <div class="separator"></div>
                        <div class="social-login-btns">
                            <div class="social-login-btn pointer" routerLink="/sso" style="display: none">
                                <div class="icon-wrap">
                                    <img src="assets/img/key_icn.png" />
                                </div>
                                <div class="text">Sign in with Company domain</div>
                            </div>
                            <div class="social-login-btn pointer" (click)="loginWithSSO('facebook')">
                                <div class="icon-wrap facebook"></div>
                                <div class="text">{{ 'signup_page.facebook' | translate }}</div>
                            </div>
                            <div class="social-login-btn pointer" (click)="loginWithSSO('google')">
                                <div class="icon-wrap google"></div>
                                <div class="text">{{ 'signup_page.google' | translate }}</div>
                            </div>
                        </div>
                        <div class="disclaimer text-center">
                            {{ 'signup_page.disclaimer_signup' | translate }}
                            <a class="pointer blue" (click)="launchPrivacy()">{{
                                'signup_page.disclaimer_privacypolicy' | translate
                            }}</a>
                            {{ 'signup_page.disclaimer_and' | translate }}
                            <a class="pointer blue" (click)="launchTnc()">{{
                                'signup_page.disclaimer_service' | translate
                            }}</a>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page container-fluid scrollable" *ngIf="mailSent && !loading">
    <div class="signup-component post-email">
        <div class="header" *ngIf="!unVerifiedAccount">{{ 'email_verification.check' | translate }}</div>
        <div class="header" *ngIf="unVerifiedAccount">{{ 'email_verification.waiting' | translate }}</div>
        <div class="img-wrap">
            <img src="assets/img/signup_mail_icn.png" />
        </div>
        <div class="top-text py-4">
            <div>
                <span *ngIf="unVerifiedAccount">{{ 'email_verification.looks_like' | translate }}</span>
                <span>{{ 'email_verification.sent' | translate }} {{ signupForm.get('emailId').value }},</span>
            </div>
            <div>{{ 'email_verification.click' | translate }}</div>
        </div>
        <div class="bottom-text">
            <div class="mail-bottom-text">
                {{ 'email_verification.havent' | translate }}
                <a class="pointer blue d-inline" (click)="resendEmail()">{{
                    'email_verification.resend' | translate
                }}</a>
            </div>
        </div>
        <div class="sign-in">
            <a class="pointer blue underline" (click)="navigateToLogin()">{{
                'email_verification.signin' | translate
            }}</a>
        </div>
    </div>
</div>
