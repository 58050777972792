<ng-container *ngIf="!isWebinarSettings">
    <div [id]="boothIndex" [ngClass]="{ 'booth-tile': !isMobile, 'booth-tile-mobile': isMobile }">
        <div class="booth-container">
            <div class="booth-background">
                <img
                    *ngIf="isImageCheck"
                    class="booth-background-img"
                    [src]="
                        !!boothData?.attributes?.thumbnailBanner?.data[0]?.attributes?.url
                            ? boothData?.attributes?.thumbnailBanner?.data[0]?.attributes?.url
                            : 'assets/img/default-booth-thumbnail.svg'
                    "
                />
                <video
                    *ngIf="!isImageCheck"
                    class="booth-background-video"
                    autoplay
                    loop
                    [muted]="'muted'"
                    [src]="boothData?.attributes?.banner?.data?.attributes?.url"
                ></video>
            </div>
            <div class="booth-tile-content">
                <div class="booth-tile-logo-container">
                    <img
                        [src]="
                            !!boothData?.attributes?.logo?.data?.attributes?.url
                                ? boothData?.attributes?.logo?.data?.attributes?.url
                                : 'assets/img/default-booth-logo.svg'
                        "
                        class="booth-logo"
                    />
                </div>
                <div class="booth-title">{{ boothData?.attributes?.title }}</div>
                <div class="tile-social-icon">
                    <a
                        *ngIf="!!boothData?.attributes?.socialLinks?.facebook"
                        [href]="boothData?.attributes?.socialLinks?.facebook"
                        target="_blank"
                        ><img src="assets/img/fb_icon.svg"
                    /></a>
                    <a
                        *ngIf="!!boothData?.attributes?.socialLinks?.linkedin"
                        [href]="boothData?.attributes?.socialLinks?.linkedin"
                        target="_blank"
                        ><img src="assets/img/linkedin_2_icon.svg"
                    /></a>
                    <a
                        *ngIf="!!boothData?.attributes?.socialLinks?.twitter"
                        [href]="boothData?.attributes?.socialLinks?.twitter"
                        target="_blank"
                        ><img src="assets/img/booth_twitter_icon.svg"
                    /></a>
                    <a
                        *ngIf="!!boothData?.attributes?.socialLinks?.instagram"
                        [href]="boothData?.attributes?.socialLinks?.instagram"
                        target="_blank"
                        ><img src="assets/img/insta_icon.svg"
                    /></a>
                </div>
            </div>
            <div class="visit-booth">
                <button
                    [id]="boothData?.id"
                    class="custom-button-revamp btn-primary xsm fit mr-0 interested-btn visit-booth-btn"
                    (click)="sendSelectedBoothIndex(boothData?.id)"
                >
                    Visit Booth <img src="assets/img/booth_right_arrow.svg" *ngIf="!isMobile" />
                </button>
            </div>
        </div>
    </div>
</ng-container>

<!-- For Webinar Setting -->
<div class="booth-tile-webinar-setting" *ngIf="isWebinarSettings" style="background: orange">
    <div class="booth-image-wrapper"></div>
    <div class="webinar-setting-booth-header">
        <div><img src="assets/img/reliance_retail_logo.svg" alt="" class="webinar-setting-booth-logo" /></div>
        <div>Reliance Retail</div>
    </div>
    <div class="webinar-setting-booth-header webinar-seeting-booth-footer">
        <div class="booth-analytics-section">
            <button class="custom-button bg-primary booth-analytics-btn" (click)="openAnalyticsPopup()">
                {{ 'Analytics' }}
            </button>
        </div>
        <div class="pointer" tabindex="1">
            <div class="dropdown">
                <button
                    class="booth-more-btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <img src="assets/images/speaker-call-wrapper/qna_action_dropdown_icon.svg" />
                </button>
                <div class="dropdown-items dropdown-menu dropdown-menu-revamp">
                    <div class="dropdown-item pointer" tabindex="1" (click)="editBooth()">
                        {{ 'Edit' }}
                    </div>
                    <div class="dropdown-item pointer" tabindex="1">
                        {{ 'delete' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <p-sidebar
    [(visible)]="viewBoothAnalyticsPopup"
    position="right"
    appendTo="body"
    baseZIndex="1500"
    styleClass="networking-room-create-sidebar sidebar-revamp legacy-styling"
>
    <app-booth-analytics-popup></app-booth-analytics-popup>
</p-sidebar> -->
