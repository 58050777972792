import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterFeedPosts'
})
export class FilterFeedPostsPipe implements PipeTransform {
    transform(allPosts: any[], filter, userId): any {
        switch (filter) {
            case 0:
                return allPosts;
            case 1:
                return allPosts.filter((post) => {
                    return post.creator.userId == userId;
                });
        }
    }
}
