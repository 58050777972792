<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
<section class="section-views" *ngIf="!formSubmited">
    <div class="background-container">
        <div class="header-view-wrapper" [@bottomToTopAnimations]>
            <ng-container *ngIf="userFrom == 'default'">
                <h1 class="jiotype-black-white-64px d-flex justify-content-center contact-us-text">Contact Us</h1>
                <p class="jiotype-medium-white-18px-2 d-flex justify-content-center contact-custom-text-wrapper">
                    We are here to attend your queries to create immersive virtual, hybrid, and in-person event
                    experiences for your audience on JioEvents
                </p>
            </ng-container>
        </div>
        <div class="image-content d-flex">
            <img src="assets/img/revamp/contactus_pre.svg" alt="" class="image-container" />
            <div class="input-form">
                <div class="contact-form-area col-12">
                    <form class="form-area" #userForm="ngForm">
                        <div class="row">
                            <div class="form-group col-md-6 input-container input-flex">
                                <div class="group">
                                    <input
                                        type="text"
                                        autocomplete="off"
                                        name="firstName"
                                        [(ngModel)]="formInfo.name"
                                        required="required"
                                    /><span class="highlight"></span><span class="bar"></span>
                                    <label class="jiotype-medium-white-16px req">Enter Name</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 input-container input-flex">
                                <div class="group">
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        id="email"
                                        name="email"
                                        [(ngModel)]="formInfo.email"
                                        required="required"
                                    /><span class="highlight"></span><span class="bar"></span>
                                    <label class="jiotype-medium-white-16px req">Enter Work Email</label>
                                </div>
                            </div>
                        </div>
                        <div class="row container-wrap">
                            <div class="form-group col-md-6 input-container form-view-input">
                                <div class="group select-country-code d-flex">
                                    <select
                                        name="countryCode"
                                        id="countryCode"
                                        [(ngModel)]="selectedOption"
                                        required="required"
                                        class="select-country-code"
                                    >
                                        <ng-container *ngFor="let range of countryCode; let i = index">
                                            <option
                                                [value]="range.value"
                                                [selected]="selectedOption === range.value"
                                                [ngClass]="{ 'windows-style': currentOS === 'Windows' }"
                                            >
                                                <span *ngIf="selectedOption !== range.value" class="country-flag">
                                                    {{ range.name }} {{ range.flagEmoji }} {{ range.value }}
                                                </span>
                                                <span *ngIf="selectedOption === range.value">
                                                    {{ range.flagEmoji }} {{ range.value }}
                                                </span>
                                            </option>
                                        </ng-container>
                                    </select>
                                    <label class="jiotype-medium-white-16px select-country-code custom-label">
                                        Country
                                    </label>
                                    <img
                                        alt=""
                                        class="drop-down-icon"
                                        src="assets/img/revamp/contact_us_dropdown.svg"
                                    />
                                </div>
                            </div>
                            <div class="form-group col-md-6 input-container view-toggle-input custom-form-view">
                                <div class="group">
                                    <input
                                        type="text"
                                        autocomplete="off"
                                        name="phoneNumber"
                                        [(ngModel)]="formInfo.phone"
                                        required="required"
                                        oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                                        maxlength="10"
                                        class="enter-number"
                                    /><span class="highlight"></span><span class="bar enter-number"></span>
                                    <label class="jiotype-medium-white-16px req">Enter Number</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12 container">
                                <div class="group d-flex">
                                    <select
                                        name="compamySize"
                                        id="compamySize"
                                        [(ngModel)]="selectedAttendeeNumber"
                                        class="form-select"
                                    >
                                        <option
                                            *ngFor="let range of companySizes"
                                            [value]="range.value"
                                            [ngClass]="{ 'windows-style': currentOS === 'Windows' }"
                                        >
                                            {{ range.name }}
                                        </option>
                                    </select>
                                    <label class="jiotype-medium-white-16px custom-label">
                                        Expected Attendee Count
                                    </label>
                                    <img
                                        alt=""
                                        class="drop-down-icon"
                                        src="assets/img/revamp/contact_us_dropdown.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="selectedAttendeeNumber === 'Custom'">
                            <div class="form-group col-md-12 input-container">
                                <div class="group d-flex">
                                    <input
                                        type="text"
                                        autocomplete="off"
                                        required="required"
                                        id="customAttendee"
                                        [(ngModel)]="customAttendeeNumber"
                                    />
                                    <label class="jiotype-medium-white-16px req">Custom Attendee Count</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 input-container input-flex">
                                <div class="group d-flex">
                                    <div class="text-area-control">
                                        <textarea
                                            id="description"
                                            name="description"
                                            [(ngModel)]="formInfo.description"
                                            class="jiotype-medium-white-16px text-area-content"
                                            for="power"
                                        >
                                        </textarea>
                                        <label class="jiotype-medium-white-16px"> Brief Your Requirements</label>
                                        <img
                                            src="assets/img/revamp/text_area_icon.svg"
                                            alt=""
                                            class="text-toggle-icon"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="terms-text-wrapper-in-mobile">
                            <div class="square-checkbox">
                                <span type="checkbox" (click)="toggleCheckbox()">
                                    <span class="checkmark" [ngStyle]="{ border: isChecked ? 'none' : '' }">
                                        <span *ngIf="isChecked">
                                            <img
                                                class="image-view-wrapper"
                                                src="{{
                                                    isMobileOS
                                                        ? 'assets/img/revamp/checkbox_mobile.png'
                                                        : 'assets/img/revamp/check_box_contactus.svg'
                                                }}"
                                                alt=""
                                            />
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <p class="inline-checkbox">
                                &nbsp;&nbsp;I accept the &nbsp;
                                <a class="policy-wrapper" (click)="navigateToTnC()">{{ 'terms & condition' }}</a>
                                &nbsp;and &nbsp;
                                <a class="policy-wrapper" (click)="navigateToPrivacy()">{{ 'privacy policy*' }}</a>
                            </p>
                        </div>
                        <button
                            [disabled]="!userForm.valid || !isChecked"
                            class="custom-button-revamp btn-primary message-text-wrapper"
                            (click)="onSubmit(userForm)"
                            type="button"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="mail-section-view" *ngIf="!formSubmited">
    <div class="card-background-in-mobile">
        <div class="contact-text-wrapper">
            <div id="contactMailSection" class="data-scroll-sub-header" [class.in-view]="showMailContext">
                <h3 class="boujee-text text-wrapper text-mobile-view">Contact Emails</h3>
                <p class="jiotype-medium-white-16px-2 text-wrapper text-mobile-wrapper">
                    Got questions or need assistance? Feel free to contact us – we're here to help!
                </p>
            </div>
        </div>
        <div class="card-row w-100" #elementToObserve>
            <div
                class="price-card card-mobile-view"
                [class.data-scroll-header]="!isMobileOS"
                *ngFor="let items of cardList; let i = index"
                [class.card-list]="i == 1"
                [class.in-view]="isElementInView"
            >
                <div class="card-content-icon">
                    <div class="card-icon-placement">
                        <img src="assets/img/jioevents/dark_theme/contact_us_testi.svg" alt="" />
                        <img
                            src="assets/img/jioevents/dark_theme/messgae_icon.svg"
                            alt=""
                            class="theme-icon-placement"
                        />
                    </div>
                    <p class="support-text">{{ items.email }}</p>
                    <p class="support-text support-text-wrapper">
                        {{ items.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-views">
    <div *ngIf="formSubmited" class="create-view col-12">
        <div class="create-event-view">
            <div class="view-control">
                <div class="content-view-container">
                    <h1 class="contact_thanks_page"><span>Thank you for your interest</span></h1>
                    <p class="thanks_sub_text">Our sales team will get in touch with you in 3 days.</p>
                </div>
                <div class="image-view-container">
                    <img src="assets/img/website/sale.png" class="thanks_contact_img" alt="contact_image" />
                </div>
            </div>
        </div>
    </div>
</section>
