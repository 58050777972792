import { Component, OnInit } from '@angular/core';
import { AuthService, UtilService, AppService, UserService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-download-recordings',
    templateUrl: './download-recordings.component.html',
    styleUrls: ['./download-recordings.component.scss']
})
export class DownloadRecordingsComponent implements OnInit {
    redirectURL;

    private timeoutIds = [];

    constructor(
        private authService: AuthService,
        private toastrService: ToastrService,
        private router: Router,
        private utilService: UtilService,
        private appService: AppService,
        private userService: UserService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.redirectURL = decodeURIComponent(this.utilService.getParameterByName('redirectURL'));
        if (this.authService.getIsAuthenticated()) {
            this.redirectToFinalURL();
        } else {
            this.timeoutIds.push(
                setTimeout(() => {
                    if (this.router.routerState.snapshot.url.includes('mediaplayer')) {
                        this.toastrService.info(this.translateService.instant('tostrs.sign_in_with_enterprize'));
                    } else {
                        this.toastrService.info(this.translateService.instant('tostrs.sign_in_to_download'));
                    }
                    this.router.navigate(['login'], {
                        queryParams: {
                            returnUrl: encodeURIComponent(this.router.routerState.snapshot.url)
                        }
                    });
                }, 1000)
            );
        }
    }

    redirectToFinalURL() {
        this.userService.getFinalTempAuthUrl({ finalURL: this.redirectURL }).subscribe(
            (res: any) => {
                window.location.href = res.finalURL;
            },
            (err) => {
                if (err.error.message === 'URL_EXPIRED') {
                    this.toastrService.error(err.error.errors);
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    }
}
