import { addMinutes, isBefore } from 'date-fns';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InsideEventUtilService {
    constructor() {}
    isSettingEditable(startTime) {
        const now = new Date();
        const cDate = new Date(startTime);
        const editUntil0Mins = addMinutes(now, 0);
        return isBefore(cDate, editUntil0Mins);
    }
}
