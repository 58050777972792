import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CallStateManagerService } from '../classes/call-state-manager.service';
import { AppLoggerService } from './app-logger.service';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { CallViewStateManagerService } from './call-view-state-manager.service';
import { RestService } from './rest.service';
import { UserService } from './user.service';
import { VideoWrapperService } from './video-wrapper.service';
import * as _ from 'lodash-es';
import { UtilService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class LeaderboardService {
    isAuthenticated: boolean = false;
    leaderboardObj;
    publishResultsTimeOut: any;
    scoresList: any;
    localUserRank = -1;
    achievedPrizeIndex = -1;
    canEnableLeaderboard: boolean = true;
    localUserLatestScore = 0;
    constructor(
        private restService: RestService,
        private router: Router,
        private appService: AppService,
        private authService: AuthService,
        private appLoggerService: AppLoggerService,
        private callViewStateManagerService: CallViewStateManagerService,
        private callStateManagerService: CallStateManagerService,
        private videoWrapperService: VideoWrapperService,
        private userService: UserService,
        private utilService: UtilService
    ) {
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
    }

    getLeaderboardInfo(meetingId) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/leaderboard`)
            .pipe(
                map((res) => {
                    if (res instanceof Array) res = res[0];
                    this.leaderboardObj = res;
                    return res;
                })
            );
    }

    getLeaderboardInfoObj(webinarId) {
        return new Promise((resolve, reject) => {
            this.getLeaderboardInfo(webinarId).subscribe(
                (res) => {
                    // this.leaderboardObj = res;
                    res.prizes = _.orderBy(res.prizes, ['prizeIndex'], ['asc']);
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    this.appLoggerService.error('Something went wrong while fetching Leaderboard');
                }
            );
        });
    }

    toggleLeaderboardFeature(meetingId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/leaderboard/enable`,
            payload
        );
    }

    publishResults(meetingId: string, publishStatus = true) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/leaderboard/publish`,
            { publishStatus }
        );
    }

    getScore(meetingId) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/leaderboard/scores`)
            .pipe(
                map((res) => {
                    // if (res instanceof Array) res = res[0];
                    // this.leaderboardObj = res;
                    // return res;
                    this.scoresList = res;
                    return res;
                })
            );
    }

    handleLeaderboardOverlay(meetingId, display) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/leaderboard/overlay`,
            {
                display
            }
        );
    }

    getScoreDistributionByUser(meetingId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/leaderboard/score-distribution`
        );
    }

    hideLeaderboardOverlay(meetingId) {
        this.handleLeaderboardOverlay(meetingId, false).subscribe(
            (res) => {},
            (err) => {
                this.appLoggerService.log('Failed to hide leaderboard overlay by host', err);
            }
        );
    }

    uploadDisplayImage(meetingId: string, file, idx): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meetings/${meetingId}/leaderboard/customprize?prizeIndex=${idx}`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    startLeaderboardAccumulation(meetingId) {
        this.startLeaderboard(meetingId).subscribe(
            (res) => {},
            (err) => {
                this.appLoggerService.log('Failed to start leaderboard by host', err);
            }
        );
    }

    deletePrize(meetingId, prizeIndex) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/leaderboard/prize/${prizeIndex}`
        );
    }

    changeActionsPoints(meetingId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/leaderboard/rewardpoints`,
            payload
        );
    }

    resetLeaderboard(meetingId, payload = { scores: true, points: false }) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/leaderboard/reset`,
            payload
        );
    }

    startLeaderboard(meetingId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/leaderboard/start`,
            {}
        );
    }

    getActionScores(meetingId) {}

    async handleGetLeaderboardScores(meetingId) {
        return new Promise((resolve, reject) => {
            this.getScore(meetingId).subscribe(
                (res) => {
                    this.scoresList = res;
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    getfilteredRewardActions(leaderboardObj, webinarDetailsSrc = null) {
        const filteredRewardActions = [];
        const webinarDetails = webinarDetailsSrc ?? {
            ...this.callStateManagerService.parentWebinarDetailsObj,
            ...this.callViewStateManagerService.eventUpdatesObj
        };
        leaderboardObj.rewardActions?.forEach((action) => {
            switch (action.title) {
                case 'Event Entry': {
                    filteredRewardActions.push(action);
                    break;
                }
                case 'Comments': {
                    if (webinarDetails?.webinar?.options?.comments || webinarDetails?.commentsEnabled) {
                        filteredRewardActions.push(action);
                    }
                    break;
                }
                case 'Q&A Participation': {
                    if (webinarDetails?.webinar?.options?.quesAns || webinarDetails?.qnaEnabled) {
                        filteredRewardActions.push(action);
                    }
                    break;
                }
                case 'Poll Responses': {
                    filteredRewardActions.push(action);
                    break;
                }
                case 'Reactions': {
                    if (webinarDetails?.webinar?.options?.likes?.enabled || webinarDetails?.likes?.enabled) {
                        filteredRewardActions.push(action);
                    }
                    break;
                }
                case 'Networking Lounge Participation': {
                    if (webinarDetails?.webinar?.networking?.enabled || webinarDetails?.networking?.enabled) {
                        filteredRewardActions.push(action);
                    }
                    break;
                }
                default: {
                    filteredRewardActions.push(action);
                    break;
                }
            }
        });
        return filteredRewardActions;
    }

    hideOverlayOnShareMedia() {
        if (!this.callViewStateManagerService.eventUpdatesObj.displayLeaderboardResults) return;
        if (this.callViewStateManagerService.isHost) {
            this.handleLeaderboardOverlay(this.callStateManagerService.getParentMeetingId(), false).subscribe(
                (res) => {
                    this.callViewStateManagerService.eventUpdatesObj.displayLeaderboardResults = false;
                },
                (err) => {
                    this.appLoggerService.log('Failed to hide leaderboard overlay by host', err);
                }
            );
        } else {
            this.videoWrapperService.getVideoService().sendChatMessage({
                type: 'PublicChat',
                message: 'REQUEST_HIDE_LEADERBOARD_BY_HOST'
            });
        }
    }

    checkLeaderboardAccess() {
        const enablePaymentFeature = this.utilService.enablePaymentFeature(this.userService.getUserSync()?.isOTT)
            ? this.userService.getUserSync()?.eventsFeaturesEnabled?.enableLeaderboard
            : this.appService.getConfigVariable('enableLeaderboardForAll') ||
              this.userService.getUserSync()?.webinarBetaAccess?.enableLeaderboard;
        this.canEnableLeaderboard =
            enablePaymentFeature && !this.appService.getConfigVariable('disableLeaderboardFeature');
        return this.canEnableLeaderboard;
    }
}
