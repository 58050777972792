import { AfterViewInit, Component, OnInit } from '@angular/core';
import { JMRTCTest } from '@jiomeet/jm-test-rtc';
import { ifError } from 'assert';
import { DevicesManagerService, PreCallTestingService } from '../../services';

@Component({
    selector: 'app-test-container',
    templateUrl: './test-container.component.html',
    styleUrls: ['./test-container.component.scss']
})
export class TestContainerComponent implements OnInit {
    isDeviceLoaded = false;
    performanceResult;
    constructor(
        public preCallTestingService: PreCallTestingService,
        public deviceManagerService: DevicesManagerService
    ) {}

    ngOnInit(): void {
        this.startRTCTest();
        this.proceedToTest();
    }

    async startRTCTest() {
        this.preCallTestingService.startBitrateTest();
        await this.preCallTestingService.startRTCTest();
    }
    proceedToTest() {
        this.preCallTestingService.isOnboardingScreenLoaded = true;
    }

    delay(milliseconds) {
        return new Promise((resolve) => {
            setTimeout(resolve, milliseconds);
        });
    }
}
