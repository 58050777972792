import { ErrorHandler, Injectable } from '@angular/core';
import { AppLoggerService } from '../services/app-logger.service';

@Injectable({
    providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {
    constructor(private logService: AppLoggerService) {}
    handleError(error, errHeading = '') {
        this.logService.error(errHeading, error);
    }
}
