import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { SessionStorageService } from './session-storage.service';
import { APP_CONSTANT } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class AddonsService {
    apiUrl: any;
    token: any;

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private sessionStorage: SessionStorageService
    ) {
        this.apiUrl = this.appService.getConfigVariableWithDefault(
            'ADDONS_API_BASE_URL',
            'https://jioevents.com/api/addons'
        );
        //need to get it from appservice (server)
        //TODO aruna uncomment this line
        this.token = this.sessionStorage.getItem(APP_CONSTANT.ADDONS_AUTH_TOKEN) ?? '';
    }

    // update HttpHeaders with Authorization header
    private getHeaders(): HttpHeaders {
        return new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    }

    // Example GET request
    getBooths(webinarId: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get<any>(`${this.apiUrl}/beta-booths?populate=*&filters[eventId]=${webinarId}`, { headers });
    }

    // Example GET request for contest
    getContests(webinarId: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get<any>(`${this.apiUrl}/contests?populate=*&filters[eventId]=${webinarId}`, { headers });
    }

    getFaq() {
        return this.http.get<any>(`${this.apiUrl}/faqs?populate=*&sort[order]=asc`);
    }

    // Example GET request for contest
    getAllContestEntriesByContestId(
        contestId: any,
        webinarId: any,
        pageNumber: number,
        pageSize: number,
        timeStamp: string | number
    ): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get<any>(
            `${this.apiUrl}/contest-entries?populate=*&filters[contest]=${contestId}&filters[eventId]=${webinarId}&pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&sort[0]=publishedAt:desc&time=${timeStamp}`,
            {
                headers
            }
        );
    }

    getUserEntriesForContest(
        contestId: any,
        userId: any,
        pageNumber: number,
        pageSize: number,
        timeStamp: string | number
    ): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get<any>(
            `${this.apiUrl}/contest-entries?populate=*&filters[contest]=${contestId}&filters[userId]=${userId}&pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&sort[0]=publishedAt:desc&time=${timeStamp}`,
            {
                headers
            }
        );
    }

    //TO get shortlisted contest entries
    getWinnerEntries(contestId: any) {
        const headers = this.getHeaders();
        return this.http.get<any>(
            `${this.apiUrl}/contest-entries?populate=*&filters[contest]=${contestId}&filters[winner]=true`,
            {
                headers
            }
        );
    }
    updateUserDetails(formData: FormData, userId) {
        // const phone = formData.get('phoneNo');
        return this.http.put(`/api/addons/know-your-collegues/${userId}`, formData);
    }
    postUserDetails(formData: FormData) {
        // const phone = formData.get('phoneNo');
        return this.http.post(`/api/addons/know-your-collegues`, formData);
    }

    getExistingUserDetails(userId) {
        // const phoneNo = phone.replace(/^\+91/, '');
        const headers = this.getHeaders();
        return this.http.get(`/api/addons/know-your-collegues/${userId}`, {
            headers
        });
    }

    //TO get user participated contests for the event
    getUserParticipatedEntriesForContest(userId: any, eventId: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get<any>(
            `${this.apiUrl}/participated-contests?populate=*&filters[eventId]=${eventId}&filters[userId]=${userId}`,
            {
                headers
            }
        );
    }

    // Example POST request
    addEntryToContest(data: any): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post<any>(`${this.apiUrl}/contest-entries`, data, { headers });
    }

    deleteEntryOfContest(entryId: any, contestId: any) {
        const headers = this.getHeaders();
        return this.http.delete<any>(`${this.apiUrl}/contest-entries/${entryId}?contestId=${contestId}`, { headers });
    }

    getContestEntry(contestId: any) {
        const headers = this.getHeaders();
        return this.http.get<any>(`${this.apiUrl}/contest-entries/${contestId}?populate=*`, {
            headers
        });
    }

    getLikesOfContestEntry(contestId: any) {
        const headers = this.getHeaders();
        return this.http.get<any>(
            `${this.apiUrl}/contest-entry-likes?populate=*&filters[contest_entry][id]=${contestId}`,
            {
                headers
            }
        );
    }

    updateLikeOrUnlikeOfContestEntry(data: { data: { userId: any; like: boolean; contest_entry: any } }) {
        const headers = this.getHeaders();
        return this.http.post<any>(`${this.apiUrl}/contest-entry-likes`, data, { headers });
    }

    getCommentsOfContestEntry(contestId: any, timeStamp: number) {
        const headers = this.getHeaders();
        return this.http.get<any>(
            `${this.apiUrl}/contest-entry-comments?populate=*&filters[contest_entry][id]=${contestId}&sort[0]=publishedAt:desc&time=${timeStamp}`,
            {
                headers
            }
        );
    }

    postCommentForContestEntry(data: { data: { userId: any; comment: any; contest_entry: any } }) {
        const headers = this.getHeaders();
        return this.http.post<any>(`${this.apiUrl}/contest-entry-comments`, data, { headers });
    }

    deleteCommentOfContestEntry(commentId: any, contestId: any) {
        const headers = this.getHeaders();
        return this.http.delete<any>(`${this.apiUrl}/contest-entry-comments/${commentId}?contestId=${contestId}`, {
            headers
        });
    }

    getKnowYourColleagueData(userId) {
        const headers = this.getHeaders();
        return this.http.get<any>(`${this.apiUrl}/know-your-collegues?populate=*&filters[userId]=${userId}`, {
            headers
        });
    }

    getFilterData(functionalUnit, location, name, pageNumber, pageSize) {
        const headers = this.getHeaders();
        return this.http.get<any>(
            `${this.apiUrl}/know-your-collegues?populate=*&filters[name]=${name}&filters[functionalUnit]=${functionalUnit}&filters[location]=${location}&pagination[offset]=${pageNumber}&pagination[limit]=${pageSize}`,
            {
                headers
            }
        );
    }
    getHomeBanner() {
        return this.http.get<any>(
            `${this.apiUrl}/website-home-banners?populate[limit]=&populate[start]=&populate[webBannerUrl][limit]=&populate[webBannerUrl][start]=&populate[mobileBannerUrl][limit]=&populate[mobileBannerUrl][start]=&populate[event_speakers][populate][profilePic][limit]=&populate[event_speakers][populate][profilePic][start]=&sort=order:asc`
        );
    }
}
