import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'newline' })
export class NewLinePipe implements PipeTransform {
    transform(value: any) {
        if (value && value.includes('\n')) {
            let spiltStr = value.split('\n');
            let finaltext = '';
            spiltStr.forEach((el) => {
                if (el) {
                    finaltext += `<p>${el}</p>`;
                }
            });
            return finaltext;
        }
        return value;
    }
}
