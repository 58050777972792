<div class="page" [class.jio-pc-page]="isJioPc">
    <div class="d-flex flex-column onboarding-container mx-auto">
        <div class="layout">
            <div class="limit-width">
                <div class="white-bold d-flex justify-content-center">
                    {{ 'activate_component.welcome' | translate }}
                </div>
                <div class="d-flex justify-content-center fs-16 mb-3 text-center" style="color: white">
                    {{ 'activate_component.need_few_more_info' | translate }}
                </div>
                <!-- <div class="status-bar d-flex justify-content-center"></div> -->
                <div class="progress">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        [style.width]="!showInterestSection ? '66%' : '100%'"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>
            <section *ngIf="showInterestSection" class="interest-section">
                <div class="white-opaque d-flex justify-content-center mb-3">
                    {{ 'onboarding.what_interest_areas' | translate }}
                </div>
                <div class="grid-container">
                    <div
                        class="interest-buttons"
                        *ngFor="let interest of interestList"
                        (click)="selectInterest(interest)"
                        [class.selected]="selectedInterests.includes(interest)"
                    >
                        <img src="assets/img/jioconf/new-interest-icons/{{ interest }}.svg" alt="{{ interest }}" />
                        <span class="title">{{ interest }}</span>
                    </div>
                </div>
                <div class="action-button">
                    <button
                        type="button"
                        id="proceedButton"
                        class="custom-button bg-login mt-5"
                        [class.disabled]="!form.valid"
                        (click)="updateProfile()"
                    >
                        <!-- {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.proceed')|translate }} -->
                        {{ 'onboarding.lets_get_started' | translate }}
                    </button>
                    <button
                        type="button"
                        id="proceedButton"
                        class="custom-button skipBtn my-3"
                        [class.disabled]="!form.valid"
                        (click)="updateProfile()"
                    >
                        <!-- {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.proceed')|translate }} -->
                        {{ 'onboarding.skip' | translate }}
                    </button>
                </div>
            </section>

            <section *ngIf="!showInterestSection" class="limit-width">
                <form [formGroup]="form" class="custom-form login-details">
                    <div class="form-group">
                        <!-- <label>{{'signin_page.emailid/mobile' | translate}}</label> -->
                        <label>{{ 'onboarding.full_name' | translate }}</label>
                        <input
                            type="text"
                            class="form-control"
                            id="fullName"
                            formControlName="name"
                            aria-describedby="usernameHelp"
                            [class.is-invalid]="false"
                            placeholder="Full Name"
                            maxlength="50"
                        />
                        <span class="text-error" *ngIf="errors?.fullName">{{ errors?.fullName }}</span>
                    </div>
                    <div class="form-group">
                        <!-- <label>{{'signin_page.emailid/mobile' | translate}}</label> -->
                        <label>{{ 'onboarding.designation' | translate }}</label>
                        <input
                            type="text"
                            class="form-control"
                            id="jobTitle"
                            formControlName="jobTitle"
                            aria-describedby="usernameHelp"
                            [class.is-invalid]="false"
                            placeholder="Designation (Optional)"
                            maxlength="50"
                        />
                        <span class="text-error" *ngIf="errors?.jobTitle">{{ errors?.jobTitle }}</span>
                    </div>
                    <div class="form-group">
                        <!-- <label>{{'signin_page.emailid/mobile' | translate}}</label> -->
                        <label>{{ 'onboarding.organisation_name' | translate }}</label>
                        <input
                            type="text"
                            class="form-control"
                            id="department"
                            formControlName="department"
                            aria-describedby="usernameHelp"
                            [class.is-invalid]="false"
                            placeholder="Organisation Name (Optional)"
                            maxlength="50"
                        />
                        <span class="text-error" *ngIf="errors?.department">{{ errors?.department }}</span>
                    </div>
                    <div class="proceed-button desktop" *ngIf="!showInterestSection">
                        <ng-container [ngTemplateOutlet]="proceedBtnTemplate"> </ng-container>
                    </div>
                </form>
            </section>
        </div>
        <div class="proceed-button mobile" *ngIf="!showInterestSection">
            <ng-container [ngTemplateOutlet]="proceedBtnTemplate"> </ng-container>
        </div>
    </div>
</div>

<ng-template #proceedBtnTemplate>
    <button
        type="button"
        id="proceedButton"
        class="custom-button bg-login my-3"
        [class.disabled]="!form.valid || errors?.fullName || errors?.jobTitle || errors?.department"
        (click)="updateProfile()"
    >
        <!-- {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.proceed')|translate }} -->
        {{ 'signin_page.proceed' | translate }}
    </button>
</ng-template>
