<div class="join-meeting-component">
    <div id="content" class="container">
        <div class="layout">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-12">
                    <section
                        *ngIf="
                            !loader &&
                            !CollegueOnly &&
                            !loggedInOnly &&
                            !waitingRoomDetails?.waitingRoom &&
                            !joinBeforeHost
                        "
                    >
                        <h2 class="text-center guest-form-title text-color">
                            Please enter your
                            <span *ngIf="isPinEnabled"> meeting Password and </span> name to join the meeting
                        </h2>
                        <p class="text-center" *ngIf="isPinEnabled">
                            You can find the meeting Password in the email invitation
                        </p>

                        <form class="meeting-form col-sm-12 col-lg-6" [formGroup]="namePinForm">
                            <div class="form-group" *ngIf="isPinEnabled">
                                <label for="pin">Meeting Password</label>
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    formControlName="pin"
                                    maxlength="5"
                                    placeholder="Meeting Password"
                                />
                                <span class="invalid-error" *ngIf="errors.pin">
                                    <div>
                                        {{ errors.pin }}
                                    </div>
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="name">Your Name</label>
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    formControlName="name"
                                    placeholder="Your Name"
                                    [appInputMaxLength]="50"
                                    [attr.disabled]="currentUser?.name"
                                    [class.disabled]="currentUser?.name"
                                />
                            </div>
                            <ng-container *ngIf="showCaptcha">
                                <div class="captcha-container d-flex flex-column mt-3">
                                    <div class="text-center title">
                                        As a protection against automated spam and join the meeting, you will need to
                                        type in the words that appear in this image.
                                    </div>
                                    <div
                                        class="
                                            d-flex
                                            mt-3
                                            captcha-area
                                            justify-content-around
                                            align-items-center
                                            flex-column
                                        "
                                    >
                                        <div class="captcha-box d-flex align-items-center">
                                            <img [src]="captchaDetails?.captcha" class="captcha-img" alt="" />
                                            <div class="reload-btn-section">
                                                <span
                                                    ><img
                                                        src="assets/img/reload_icn.svg"
                                                        alt=""
                                                        (click)="joinMeeting()"
                                                /></span>
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Captcha Code"
                                            formControlName="captchaText"
                                            maxlength="6"
                                        />
                                    </div>
                                </div>
                            </ng-container>
                            <span class="invalid-error" *ngIf="errors?.global">{{ errors.global }}</span>
                            <div class="col p-0 w-100 mt-5">
                                <button
                                    type="submit"
                                    class="btn primary button-color"
                                    (click)="joinMeeting()"
                                    *ngIf="!working"
                                >
                                    Join
                                </button>
                                <button
                                    type="submit"
                                    class="btn primary button-color"
                                    [class.disabled]="true"
                                    *ngIf="working"
                                >
                                    Please wait...
                                </button>
                            </div>
                        </form>
                    </section>
                    <section *ngIf="loggedInOnly">
                        <h2 class="text-center mb-2 text-color">Please login.</h2>
                        <span class="d-flex justify-content-center sub-title mt-3">
                            Host has restricted only logged-in users to join this meeting
                        </span>
                        <div class="col p-0 w-100 mt-5 d-flex justify-content-center">
                            <button class="btn primary w-50 button-color" (click)="navigateToLogin()">
                                <a class="text-white">Sign In</a>
                            </button>
                        </div>
                    </section>
                    <section *ngIf="CollegueOnly">
                        <h2 class="text-center mb-2 text-color">Sorry, you cannot join this meeting.</h2>
                        <span class="d-flex justify-content-center sub-title mt-3">
                            This meeting is restricted only for participants from the host’s organization
                        </span>
                        <div class="col p-0 w-100 mt-5 d-flex justify-content-center">
                            <button class="btn primary w-50 button-color" routerLink="/home">
                                <a class="text-white">Go to Home page</a>
                            </button>
                        </div>
                    </section>
                    <section *ngIf="joinBeforeHost">
                        <h2 class="text-center mb-2 mt-5 text-color">The meeting has not started</h2>
                        <span class="d-flex justify-content-center sub-title mt-3">
                            The page will refresh in 5 seconds, or
                            <span class="text-blue mx-1" (click)="handleManualPageRefresh()"> reload the page</span>
                            now.
                        </span>
                    </section>

                    <section>
                        <ng-container *ngIf="false">
                            <h2 class="text-center text-color">Join a Meeting</h2>
                            <form class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label class="mt-2" for="name">Meeting ID or Personal Link Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="email"
                                        aria-describedby="emailHelp"
                                        placeholder="Meeting ID or Personal Link Name"
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="mt-2" for="name"></label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="name"
                                        aria-describedby="emailHelp"
                                        placeholder="Your Name"
                                    />
                                </div>
                                <div class="captcha-container d-flex flex-column mt-3">
                                    <div class="text-center title">
                                        As a protection against automated spam and join the meeting, you will need to
                                        type in the words that appear in this image.
                                    </div>
                                    <div class="d-flex mt-3 captcha-area justify-content-around">
                                        <input type="text" placeholder="Captcha Code" />
                                        <input type="text" value="A213DD" />
                                    </div>
                                </div>
                                <div class="col p-0 w-100 mt-3">
                                    <button type="submit" class="btn primary button-color">Join</button>
                                </div>
                            </form>
                        </ng-container>
                    </section>
                    <div *ngIf="waitingRoomDetails?.waitingRoom" class="waiting-room-section">
                        <div class="details">
                            <div class="info">
                                <!-- <span class="mr-3">Participant ID: {{waitingRoomDetails?.userId}}</span>
                <span>Conference
                  ID: {{waitingRoomDetails?.meetingId}}</span> -->
                            </div>
                            <h2 class="text-color">Please wait while the host admits you…</h2>
                            <div class="sub-title pb-3">{{ waitingRoomDetails?.topic }}</div>
                            <div class="text-center">
                                <button class="btn btn-danger" (click)="leaveWaitingRoom()">Leave</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loader *ngIf="loader"></app-loader>

<app-restricted-meeting-join
    *ngIf="showRestrictedMeetingJoinWarning"
    (close)="toggleRestrictedMeetingJoinWarning()"
></app-restricted-meeting-join>
