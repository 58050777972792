import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MarketplaceService } from '../core/services/marketplace.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddonsService, AppService, LocalStorageService, UserService, UtilService } from '../core/services';
import { LocalService } from '../integrations/modules/myjio/local.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-get-user-details',
    templateUrl: './get-user-details.component.html',
    styleUrls: ['./get-user-details.component.scss']
})
export class GetUserDetailsComponent implements OnInit {
    userDetailsForm: FormGroup;
    captchaImage: string = '';
    captchaSolution: string = ''; // Store the correct answer here
    captchaValid: boolean = false;
    captchaError: boolean = false;
    isMobile = false;
    isIosMobile = false;
    imageSizeLimit: any;
    submittedForm = false;
    profilePictureBase64: any;
    profileDetails: any;
    funFactChar: any;
    hobbiesChar: any;
    businessUnitChar: any;
    collegeNameChar: any;
    workLocationChar: any;
    userDetailsStrapi: any;
    isFormSubmitted: boolean = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        public addOnService: AddonsService,
        private utilService: UtilService,
        private userService: UserService,
        private localService: LocalService,
        private toastrService: ToastrService,
        private localStorageService: LocalStorageService,
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private toasterService: ToastrService
    ) {
        this.generateCaptcha();
    }

    ngOnInit(): void {
        this.getUserDetails();
        this.initForm();
        const charLimit = this.appService.getConfigVariable('GET_Event');
        this.funFactChar = charLimit?.kycFunfactCharLimit || 150;
        this.hobbiesChar = charLimit?.kycHobbiesCharLimit || 100;
        this.businessUnitChar = charLimit?.businessUnitChar || 100;
        this.collegeNameChar = charLimit?.collegeNameChar || 100;
        this.workLocationChar = charLimit?.workLocationChar || 100;
        this.imageSizeLimit = charLimit?.imageSizeLimit || 2 * 1024 * 1024;
        this.isIosMobile = this.utilService.isMobileIOSBrowser();

        this.isMobile = this.utilService.isMobileBrowser() || this.utilService.isIpad();
        if (window.matchMedia('(orientation: landscape)').matches && this.isMobile) {
            this.toasterService.info('Use portrait mode for better user experience');
        }
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
        if (window.matchMedia('(orientation: landscape)').matches && this.isMobile) {
            this.toasterService.info('Use portrait mode for better user experience');
        }
    }

    getDataFromStrapi(user) {
        this.userDetailsStrapi = this.addOnService.getExistingUserDetails(user?._id).toPromise();
        return this.userDetailsStrapi;
    }

    async getUserDetails() {
        this.userService.getUserData(this.localService.getUserToken).subscribe(async (user) => {
            this.userDetailsStrapi = await this.getDataFromStrapi(user);
            this.profileDetails = user;
            const phoneWithoutCountryCode = user.phoneNo.replace(/^\+91/, '');
            this.userDetailsForm.patchValue({
                name: `${user?.name} ${user?.lname}` || this.userDetailsStrapi?.data?.attributes?.name,
                phoneNo: phoneWithoutCountryCode || this.userDetailsStrapi?.data?.attributes?.phoneNo,
                email: user?.email || this.userDetailsStrapi?.data?.attributes?.email,
                location: this.userDetailsStrapi?.data?.attributes?.location,
                businessName: this.userDetailsStrapi?.data?.attributes?.businessName,
                collegeName: this.userDetailsStrapi?.data?.attributes?.collegeName
            });
            // this.phoneNumber = user.phoneNo;
            // this.firstName = user.name;
            // this.lastName = user.lname;
            // this.email=user.email;
        });
    }

    initForm() {
        this.userDetailsForm = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(2)]],
            phoneNo: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
            email: ['', [Validators.required, Validators.email]],
            location: ['', [Validators.required]],
            businessName: ['', [Validators.required]],
            collegeName: ['', [Validators.required]],
            funFact: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
            linkedinProfile: ['', [Validators.pattern('(https?://)?(www.)?linkedin.com/.*')]],
            hobbies: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9, ]*$')]],
            profilePicture: [null, Validators.required],
            captchaInput: ['', Validators.required]
        });
    }

    generateCaptcha() {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let captcha = '';
        for (let i = 0; i < 5; i++) {
            captcha += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        this.captchaSolution = captcha;
        this.captchaImage = `https://dummyimage.com/120x40/000/fff&text=${captcha}`;
    }

    onInputFunfact(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const funfactCleanedValue = inputElement.value.replace(/[^a-zA-Z0-9 ]/g, '');
        this.userDetailsForm.get('funFact').setValue(funfactCleanedValue, { emitEvent: false });
    }

    onInputHobbies(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const hobbiesCleanedValue = inputElement.value.replace(/[^a-zA-Z0-9, ]/g, '');
        this.userDetailsForm.get('hobbies').setValue(hobbiesCleanedValue, { emitEvent: false });
    }

    refreshCaptcha() {
        this.generateCaptcha();
        this.userDetailsForm.get('captchaInput').setValue(null); // Reset CAPTCHA input
        this.captchaError = false; // Clear CAPTCHA error flag
    }

    onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validFileTypes.includes(fileType)) {
                this.toastrService.error('Kindly upload an image in JPEG, JPG, or PNG format.');
                this.userDetailsForm.get('profilePicture').setErrors({ invalidFileType: true });
                this.userDetailsForm.get('profilePicture').markAsTouched();
                return;
            }
            if (!validFileTypes.includes(fileType) || file.size > this.imageSizeLimit) {
                this.userDetailsForm.get('profilePicture').setErrors({ invalidFile: true });
                this.userDetailsForm.get('profilePicture').markAsTouched();
                return;
            }

            if (file.size <= this.imageSizeLimit) {
                this.userDetailsForm.patchValue({
                    profilePicture: file
                });
            } else {
                this.userDetailsForm.get('profilePicture').setErrors({ invalidFile: true });
            }
        }
    }

    rilEmailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const email = control.value;
            return email && email.endsWith('@ril.com') ? null : { rilEmail: true };
        };
    }

    checkCaptcha() {
        const captchaInputValue = this.userDetailsForm.get('captchaInput').value;
        if (captchaInputValue && captchaInputValue.toLowerCase() === this.captchaSolution.toLowerCase()) {
            this.captchaValid = true;
            this.captchaError = false;
        } else {
            this.captchaValid = false;
            this.captchaError = true;
        }
    }

    putData() {
        const formData = new FormData();
        formData.append('name', this.userDetailsForm.get('name').value);
        formData.append('phoneNo', this.userDetailsForm.get('phoneNo').value);
        formData.append('email', this.userDetailsForm.get('email').value);
        formData.append('location', this.userDetailsForm.get('location').value);
        formData.append('businessName', this.userDetailsForm.get('businessName').value);
        formData.append('collegeName', this.userDetailsForm.get('collegeName').value);
        formData.append('funFact', this.userDetailsForm.get('funFact').value);
        formData.append('linkedinProfile', this.userDetailsForm.get('linkedinProfile').value);
        formData.append('hobbies', this.userDetailsForm.get('hobbies').value);

        const profilePictureFile = this.userDetailsForm.get('profilePicture').value;
        if (profilePictureFile) {
            formData.append('profilePicture', profilePictureFile);
        }

        this.addOnService.updateUserDetails(formData, this.profileDetails._id).subscribe(
            async () => {
                const profileformData = new FormData();
                profileformData.append('profilePicture', formData.get('profilePicture'));
                try {
                    await this.uploadImage(profileformData);
                } catch (error) {
                    this.isFormSubmitted = false;
                    console.error('Error uploading image', error);
                }
                const attendeeUrl = this.localStorageService.getItem('attendeeUrl');
                window.open(attendeeUrl, '_self');
            },
            (err) => {
                this.isFormSubmitted = false;
            }
        );
    }

    uploadImage(image): Promise<any> {
        return this.userService.uploadProfilePicture(image).toPromise();
    }

    onSubmit() {
        // Check if the form is valid (including CAPTCHA)
        if (this.userDetailsForm.valid && this.captchaValid) {
            if (!this.isFormSubmitted) {
                this.putData();
            }
            this.isFormSubmitted = true;
            console.log('Form submitted successfully:');
            // Handle successful form submission (e.g., send data to server)
        } else {
            // Mark all form fields as touched to trigger validation messages
            this.userDetailsForm.markAllAsTouched();
        }
    }
}
