import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class OauthService {
    constructor(private restService: RestService, private appService: AppService) {}

    getClient(oauthclientId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/oauth/${oauthclientId}`);
    }

    checkAuthentication(oauthclientId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/oauth/${oauthclientId}/hasauthorized`,
            {}
        );
    }

    saveUserConsent(oauthclientId, redirect_uri) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/oauth/${oauthclientId}/authorize`, {
            redirect_uri
        });
    }
}
