import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class FlashcardService {
    public showCardPreview = true;
    public userInfo;
    constructor(
        private restService: RestService,
        private appService: AppService,
        private authService: AuthService,
        private userService: UserService
    ) {
        this.userInfo = this.userService.getUserSync();
    }
    getFlashCards(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/flashcard`
        );
    }

    createFlashCard(webinarId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/flashcard`,
            payload
        );
    }

    toggleFlashCard(webinarId, enabled) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/flashcard/toggleStatus`,
            { enabled }
        );
    }

    updateFlashCard(webinarId, flashcardId, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/flashcard/update/${flashcardId}`,
            payload
        );
    }

    deleteFlashCard(webinarId, flashcardId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/flashcard/${flashcardId}`
        );
    }

    uploadFlashCardImage(meetingId: string, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/uploadflashcardimage`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    publishFlashCard(webinarId, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/flashcard/publish`,
            payload
        );
    }

    unpublishFlashCard(webinarId, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/flashcard/unpublish`,
            payload
        );
    }

    toggleFlashCardPreview() {
        this.showCardPreview = !this.showCardPreview;
    }

    checkFlashCardAccess() {
        return (
            this.appService.getConfigVariable('ENABLE_FLASH_CARDS_FEATURE') ||
            this.userInfo?.webinarBetaAccess?.enableFlashCardsFeature
        );
    }

    maxFlashCardsAllowed() {
        return (
            this.userInfo?.webinarBetaAccess?.maxFlashCardCount ||
            this.appService.getConfigVariable('MAX_FLASH_CARDS_COUNT')
        );
    }
}
