import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';

@Pipe({
    name: 'sanitizeText'
})
export class SanitizeTextPipe implements PipeTransform {
    constructor(private utilService: UtilService) {}
    transform(data): any {
        return this.utilService.sanitizeAndConvertEncodedHTML(data);
    }
}
