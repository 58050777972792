<section id="explore" class="explore section-bg">
    <div class="container">
        <div class="row customer-list-page">
            <div
                class="
                    col-lg-7 col-md-6
                    d-flex
                    flex-column
                    sec-one-textarea
                    explore-left-area
                    pt-4 pt-lg-0
                    order-1 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1 class="jiotype-black-white-88px Support-main-txt">Companies and professionals love us</h1>
                </div>
                <div class="cus-point">
                    <!-- <span tabindex="0" aria-label="Companies and professionals love us, Get started Now"> -->
                    <button
                        tabindex="0"
                        aria-roledescription="Companies and professionals love us"
                        role="button"
                        (click)="getStartedNow()"
                        (keyup.enter)="getStartedNow()"
                        class="support-btn"
                    >
                        <span class="support-btn-txt">Schedule A Demo</span>
                    </button>
                </div>
            </div>
            <div
                class="col-lg-5 col-md-6 order-2 order-md-2 order-lg-2 explore-img-container aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img src="assets/img/website/group-139252-1@2x.png" class="img-fluid explore-img" alt="" />
            </div>
        </div>
    </div>
</section>
<section id="clients" class="clients section-bg">
    <div class="container">
        <div class="row aos-init aos-animate" data-aos="zoom-in">
            <div class="col-lg-6 col-md-12 col-12 d-flex align-items-center justify-content-center">
                <h1
                    tabindex="0"
                    aria-roledescription="10,000+ events hosted."
                    role="button"
                    class="jiotype-black-white-40px"
                >
                    2 Lakh+ Events Hosted.
                </h1>
            </div>
            <div class="col-lg-2 col-md-4 col-12 d-flex justify-content-center">
                <div class="count-box">
                    <div class="icon-circle">
                        <img src="assets/img/website/swap-me-26@2x.png" class="img-fluid icon-area" alt="" />
                    </div>
                    <div>
                        <span class="jiotype-black-white-24px">5M+</span>
                        <p class="jiotype-bold-white-16px">Attendees</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-4 col-12 d-flex justify-content-center">
                <div class="count-box">
                    <div class="icon-circle">
                        <img src="assets/img/website/swap-me-27@2x.png" class="img-fluid icon-area" alt="" />
                    </div>
                    <div>
                        <span class="jiotype-black-white-24px">90Mins</span>
                        <p class="jiotype-bold-white-16px">Avg Event Duration</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-4 col-12 d-flex justify-content-center">
                <div class="count-box">
                    <div class="icon-circle">
                        <img src="assets/img/website/swap-me-28@2x.png" class="img-fluid icon-area" alt="" />
                    </div>
                    <div>
                        <span class="jiotype-black-white-24px">400M+</span>
                        <p class="jiotype-bold-white-16px">Attendee Duration</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="services" class="services section-bg">
    <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title jiotype-black-white-64px annual-txt">
            <p tabindex="0" aria-label="Annual General Meetings">Annual General Meetings</p>
        </div>

        <div class="row">
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init annual-img aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <img
                    tabindex="0"
                    aria-roledescription="Relience Industries limited growth is life"
                    role="button"
                    src="assets/img/website/mask-group-40@2x.png"
                />
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init annual-img aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <img
                    tabindex="0"
                    aria-roledescription="News 18 network"
                    role="button"
                    src="assets/img/website/mask-group-36@2x.png"
                />
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init annual-img aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <img
                    tabindex="0"
                    aria-roledescription="Justdial tm anything antytime anywhere"
                    role="button"
                    src="assets/img/website/mask-group-41@2x.png"
                />
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init annual-img aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <img
                    tabindex="0"
                    aria-roledescription="BKT"
                    role="button"
                    src="assets/img/website/mask-group-37@2x.png"
                />
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init annual-img aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <img
                    tabindex="0"
                    aria-roledescription="Digital Entertainment network"
                    role="button"
                    src="assets/img/website/mask-group-39@2x.png"
                />
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init annual-img aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <img
                    tabindex="0"
                    aria-roledescription="Hatway"
                    role="button"
                    src="assets/img/website/mask-group-38@2x.png"
                />
            </div>
        </div>
    </div>
</section>
<section id="parampar-series" class="parampar-series section-bg">
    <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title jiotype-black-white-64px">
            <p tabindex="0" aria-label="Parampara Series Launch" class="jiotype-black-white-40px">
                Parampara Series Launch
            </p>
        </div>
        <div class="col-12 parampar-img">
            <img
                tabindex="0"
                aria-roledescription="Honble Vice President of India"
                role="button"
                src="assets/img/website/Parampara_img.png"
            />
        </div>
    </div>
</section>
<section id="quotes-area" class="quotes-area section-bg">
    <div class="container">
        <div class="row support-call-explore-left-area">
            <div
                class="col-lg-12 d-flex flex-column pt-4 pt-lg-0 order-2 order-lg-1 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="support-call-explore-main-txt">
                    <!-- virtual and hybrid events provide by jioevents -->
                    <h1
                        tabindex="0"
                        aria-roledescription="“Virtual and Hybrid events powered by JioEvents”"
                        role="button"
                        class="custom-text-wrapper"
                    >
                        “Delightful Virtual & Hybrid Events Powered by JioEvents”
                    </h1>
                    <!-- <p class="jiotype-medium-white-18px-2">  A common name, fancy position, fancy company </p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section id="brands" class="brands-area section-bg">
    <div class="container">
        <div class="col-12">
            <h1 class="jiotype-black-white-40px client-text-one">Loved by Top Brands</h1>
        </div>
        <app-event-clients></app-event-clients>
        <div class="row">
            <div class="col-12 client-btn-area cus-point">
                <label
                    tabindex="0"
                    aria-label="Loved by Top Brands,Get Started"
                    (click)="getStartedBottom()"
                    (keyup.enter)="getStartedBottom()"
                    class="btn-get-started scrollto jiotype-bold-te-papa-green-16px cursor-pointer"
                    >Schedule A Demo</label
                >
            </div>
        </div>
    </div>
</section>
<section id="customer-final" class="customer-final section-bg">
    <div class="container">
        <div class="row explore-left-area">
            <div
                class="col-lg-8 col-md-6 col-12 d-flex flex-column pt-4 pt-lg-0 order-2 order-lg-1 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1 class="jiotype-black-white-40px digit-join-text">
                        Join the digital revolution and start your journey with JioEvents!
                    </h1>
                </div>
                <div class="cus-point">
                    <label
                        tabindex="0"
                        aria-label="Join the digital revolution and start your journey with JioEvents!,Get Started"
                        (click)="tryForFree()"
                        (keyup.enter)="tryForFree()"
                        class="btn-sign-up scrollto jiotype-bold-te-papa-green-16px support-btn-txt cursor-pointer"
                        >Schedule A Demo</label
                    >
                </div>
            </div>
            <div
                class="
                    col-lg-4 col-md-6 col-12
                    d-flex
                    flex-column
                    join-speaker-img
                    pt-4 pt-lg-0
                    order-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <img src="assets/img/website/mask-group-48@2x.png" />
            </div>
        </div>
    </div>
</section>
