import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { AppService, VideoWrapperService } from 'src/app/core';
import * as _ from 'lodash';
@Component({
    selector: 'app-canvas-backdrop',
    templateUrl: './canvas.component.html',
    styleUrls: ['./canvas.component.scss']
})
export class CanvasBackdropComponent implements OnInit, OnDestroy {
    defaultSrc;
    customSrc = 'assets/images/custom-unselected.svg';

    selectedImageElement: any;
    selectedDefaultImageElement: any;
    selectedImageIndex: number;
    backdropOptions = [];
    @Input() webinarId;
    @Output() selectedCanvas = new EventEmitter();

    public images: any[];

    imageChangedEvent: any = '';
    imagePath: any;
    url: string | ArrayBuffer;
    localImageSet = [];
    MAX_CUSTOM_TAGS;
    videoService;
    customCanvasSet: any = [];
    maxFileSize = 2;

    @ViewChild('videoPreview') video: ElementRef;
    constructor(
        private toastrService: ToastrService,
        private appService: AppService,
        private translateService: TranslateService,
        private videoWrapperService: VideoWrapperService,
        private webinarService: WebinarService,
        private appLoggerService: AppLoggerService
    ) {
        this.videoService = this.videoWrapperService?.getVideoServiceForPrepScreen();
        const canvasDetails = this.appService.getConfigVariable('canvas');
        this.images = canvasDetails?.defaultBackgrounds;
        this.MAX_CUSTOM_TAGS = canvasDetails?.maxCustomTags;
        this.defaultSrc = canvasDetails.default || 'https://images.jiomeet.com/default-event-canvas/2.png';
    }

    ngOnInit(): void {
        this.webinarService.getCanvasCustomImages(this.webinarId).subscribe(
            (res) => {
                this.customCanvasSet = res[0];
                this.localImageSet = res[0]?.customCanvasList;
                this.backdropOptions = _.cloneDeep(this.images);
                this.backdropOptions.splice(0, this.localImageSet?.length);
            },
            (err) => {
                this.appLoggerService.error('Error while fetching custom canvas', err);
                this.translateService.instant('tostrs.something_went_wrong_try_again_later');
            }
        );
        this.getBrandDetails();
    }
    getBrandDetails() {
        this.webinarService.getBrand(this.webinarId).subscribe((res: any) => {
            if (res?.canvas) {
                this.url = res?.canvas;
            } else {
                this.url = this.defaultSrc;
                this.updateBrand(null);
            }
        });
    }
    updateBrand(eventlabel) {
        this.webinarService.updateBrand(this.webinarId, { canvas: this.url }).subscribe((res) => {
            let data = {
                url: this.url,
                eventlabel
            };
            this.selectedCanvas.emit(data);
        });
    }
    ngAfterViewInit() {}

    updateCanvasCustomImages(imageSet) {
        this.customCanvasSet['customCanvasList'] = imageSet;
        this.webinarService.updateCanvasCustomImages(this.webinarId, this.customCanvasSet).subscribe(
            (res) => {
                this.customCanvasSet = res[0];
            },
            (err) => {
                this.appLoggerService.error('Error while updating canvas', err);
                this.translateService.instant('tostrs.something_went_wrong_try_again_later');
            }
        );
    }
    addCanvasCustomImages() {
        if (this.customCanvasSet === undefined) this.customCanvasSet = [];
        if (this.customCanvasSet?.length === 0) {
            this.webinarService.setCanvasCustomImages(this.webinarId, this.localImageSet).subscribe(
                (res) => {
                    this.customCanvasSet = res[0];
                },
                (err) => {
                    this.appLoggerService.error('Error while creating custom canvas', err);
                    this.translateService.instant('tostrs.something_went_wrong_try_again_later');
                }
            );
        } else {
            this.updateCanvasCustomImages(this.localImageSet);
        }
    }

    customCanvas(file) {
        this.webinarService.uploadCanvasImage(this.webinarId, file).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                case HttpEventType.Response:
                    if (this.localImageSet === undefined) this.localImageSet = [];
                    this.url = event?.body?.canvasUrl;
                    this.updateBrand(null);
                    this.localImageSet.push(event?.body?.canvasUrl);
                    this.backdropOptions = _.cloneDeep(this.images);
                    this.backdropOptions.splice(0, this.localImageSet?.length);
                    this.addCanvasCustomImages();
                    break;
            }
        });
        (err) => {
            this.appLoggerService.info('Error while uploading backdrop', err);
            this.translateService.instant('tostrs.something_went_wrong_try_again_later');
        };
    }

    fileChangeEvent(event: any): void {
        if (!event.target.files?.length) {
            return;
        }
        if (this.localImageSet?.length >= this.MAX_CUSTOM_TAGS) {
            this.toastrService.error(
                `Max. ${this.MAX_CUSTOM_TAGS} Backdrops are allowed. Please remove the existing Backdrop to add new one`
            );
            return;
        }
        if (
            event.target.files[0] &&
            !(
                event.target.files[0].type === 'image/jpeg' ||
                event.target.files[0].type === 'image/gif' ||
                event.target.files[0].type === 'image/png'
            )
        ) {
            this.toastrService.error(this.translateService.instant('Only jpeg, gif, png file types are supported'));
            return;
        }
        var size = Math.round(event.target.files[0].size / 1024);
        if (event.target.files[0] && 500 > size) {
            this.toastrService.error(this.translateService.instant('Min. 500 KB file size is allowed'));
            return;
        } else if (event.target.files[0] && size > 2048) {
            this.toastrService.error(this.translateService.instant('Max. 2 MB file size is allowed'));
            return;
        }

        this.imageChangedEvent = event;

        const files = event.target.files;
        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);

        reader.onload = (_event) => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                this.url = reader.result;
                this.customCanvas(files[0]);
            };
        };
        reader.onerror = () => {
            this.toastrService.error('Only jpeg, gif, png file types are supported');
        };
    }

    onSelect(event: any, defaultImage: string, imageIndex = null): void {
        this.url = event.target.src;
        event.target.value = '';
        this.selectedDefaultImageElement = defaultImage === 'default';
        this.selectedImageElement = event.target;
        this.selectedImageIndex = imageIndex;
        let event_label;
        if (defaultImage == 'default' || defaultImage == 'defaultOptions') {
            if (imageIndex == null) {
                event_label = 'None';
            } else if (imageIndex == 0) {
                event_label = 'default_backdrop_1';
            } else {
                event_label = `default_backdrop_${imageIndex + 1}`;
            }
        } else {
            event_label = `Custom backdrop`;
        }

        if (defaultImage != 'customClicked') {
            this.updateBrand(event_label);
        }
    }

    deleteCustomImage(imgUri) {
        this.localImageSet.splice(this.localImageSet.indexOf(imgUri), 1);
        this.backdropOptions = _.cloneDeep(this.images);
        this.backdropOptions.splice(0, this.localImageSet?.length);
        this.url = this.defaultSrc;
        this.updateBrand(null);
        this.updateCanvasCustomImages(this.localImageSet);
    }

    ngOnDestroy() {}
}
