import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebinarConferenceService } from 'src/app/conference/services';
import { APP_CONSTANT, POPUP } from 'src/app/constants';
import { AppService, DiagnosticService, RtcService, UtilService } from 'src/app/core';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { UserService, AuthService } from 'src/app/core';

@Component({
    selector: 'app-share-event-popup',
    templateUrl: './share-event-popup.component.html',
    styleUrls: ['./share-event-popup.component.scss']
})
export class ShareEventPopupComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() back: EventEmitter<any> = new EventEmitter();

    @Input() eventDetails;
    @Input() showBackButton: boolean = false;
    @Input() insideCall = false;
    @Input() useRevampStyling: boolean = true;

    @ViewChild('url_text') url_text: ElementRef;
    @ViewChild('url_attendee') url_attendee: ElementRef;
    @ViewChild('attendee_short_text') attendee_short_text: ElementRef;
    @ViewChild('invitation_attendee') invitation_attendee: ElementRef;
    @ViewChild('invitation_text') invitation_text: ElementRef;
    @ViewChild('copyLinkIcon0') copyLinkIcon0: ElementRef;
    @ViewChild('copyLinkIcon1') copyLinkIcon1: ElementRef;
    @ViewChild('attendeeLinkIcon0') attendeeLinkIcon0: ElementRef;
    @ViewChild('attendeeLinkIcon1') attendeeLinkIcon1: ElementRef;
    @ViewChild('attendeeShortLinkIcon0') attendeeShortLinkIcon0: ElementRef;

    invitationType: 'speaker' | 'attendee';

    private timeoutId;
    enableMarkdown = false;
    showAttendeeCopiedTooltip: boolean = false;
    showSpeakerCopiedTooltip: boolean = false;
    enableSocialShare = false;
    attendeeInviteLink = '';
    isRestrictedEvent: boolean = false;
    isSessionEnabled = false;
    isPreRecorded: boolean = false;
    isHostCoHost: boolean = false;
    currentUser;
    shareLinsArray: any = [];
    isLoggedInSpeaker: any;
    speakerLink: any;
    attendeeShortLink: any;
    isAuthenticated: boolean;
    attendeeInvitationActive: boolean = false;
    speakerInvitationActive: boolean = false;
    attendeeShortUrlExists: boolean;
    isMaskingEnabled: boolean = false;
    maskedUrl: string;
    speakerUrl: any;
    constructor(
        private utilService: UtilService,
        private diagnosticService: DiagnosticService,
        private appService: AppService,
        private rtcService: RtcService,
        private webinarConfService: WebinarConferenceService,
        private googleTagMangerService: GoogleTagMangerService,
        public callViewStateManagerService: CallViewStateManagerService,
        private translate: TranslateService,
        private webinarService: WebinarService,
        private userService: UserService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        if (!this.webinarService?.getMaskedUrlForSpeaker()) {
            this.getShareUrl();
        }
        this.enableMarkdown = this.appService.getConfigVariable('ENABLE_MARKDOWN_FOR_FORM') || false;
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.isLoggedInSpeaker = this.callViewStateManagerService.isSpeaker && this.isAuthenticated;
        this.enableSocialShare = this.appService.getConfigVariableWithDefault(
            APP_CONSTANT.WEBINAR_HOST_SHARE_EVENT_SOCIAL,
            false
        );
        this.speakerLink = this.eventDetails?.meetingUrl;
        this.attendeeInviteLink =
            this.eventDetails?.webinar?.activation?.url || this.eventDetails?.webinar?.registration?.url;
        this.isRestrictedEvent =
            this.eventDetails?.restrictedEvent || this.eventDetails?.webinar?.registration?.inviteOnly;
        this.currentUser = this.userService.getUserSync();
        this.attendeeShortUrlExists =
            this.eventDetails?.webinar?.shortUrls?.attendee ||
            this.eventDetails?.meetingInfo?.webinar?.shortUrls?.attendee?.url ||
            this.webinarService.getShortenUrl()
                ? true
                : false;
        this.attendeeShortLink =
            this.eventDetails?.webinar?.shortUrls?.attendee?.url ||
            this.eventDetails?.meetingInfo?.webinar?.shortUrls?.attendee?.url ||
            this.webinarService.getShortenUrl();
        this.isHostCoHost =
            this.webinarService.checkForHostCoHost(this.eventDetails?.participants || [], this.currentUser) ||
            this.callViewStateManagerService.roomStatus?.localParticipant?.isHost ||
            this.callViewStateManagerService?.isEventCoHost;

        //eventDetails?.restrictedEvent --> inside call, eventDetails?.webinar?.registration?.inviteOnly --> outside call
        this.isSessionEnabled = this.webinarConfService.getSessionEnabled();
        this.isPreRecorded = this.eventDetails?.webinar?.preRecordedVideo?.enabled
            ? this.eventDetails?.webinar?.preRecordedVideo?.enabled
            : false;
        if (this.isRestrictedEvent) {
            this.isHostCoHost
                ? (this.shareLinsArray = this.isPreRecorded ? [] : [0, 1])
                : this.isLoggedInSpeaker
                ? (this.shareLinsArray = this.isPreRecorded ? [] : [0])
                : (this.shareLinsArray = []);
        } else {
            this.shareLinsArray = this.isPreRecorded ? [1] : [0, 1];
        }
    }

    copy() {
        // this.googleTagManager.pushGoogleTagData(this.mergeObject(INSIDE_EVENT.SPEAKER_LINK));
        if (this.url_text && this.url_text.nativeElement) {
            this.url_text.nativeElement.textContent = this.translate.instant('Copied link');
            this.copyLinkIcon0.nativeElement.src = 'assets/images/share-event-popup/copy-link-success.svg';
        }

        setTimeout(() => {
            if (this.url_text && this.url_text.nativeElement) {
                this.url_text.nativeElement.textContent = this.translate.instant('inside_call.call_info.copy_link');
                this.copyLinkIcon0.nativeElement.src = 'assets/images/share-event-popup/copy-link.svg';
            }
        }, 3000);
        this.maskedUrl = this.webinarService.getMaskedUrlForSpeaker();
        this.speakerUrl =
            this.maskedUrl ??
            this.eventDetails?.maskedUrl ??
            this.eventDetails?.meetingInfo?.maskedUrl ??
            this.eventDetails?.meetingUrl;
        this.utilService.copy(this.speakerUrl);
    }

    copyAttendeeLink() {
        // this.googleTagManager.pushGoogleTagData(this.mergeObject(INSIDE_EVENT.ATTENDEE_LINK));
        if (this.url_attendee && this.url_attendee.nativeElement) {
            this.url_attendee.nativeElement.textContent = this.translate.instant('Copied link');
            this.copyLinkIcon1.nativeElement.src = 'assets/images/share-event-popup/copy-link-success.svg';
        }

        setTimeout(() => {
            if (this.url_attendee && this.url_attendee.nativeElement) {
                this.url_attendee.nativeElement.textContent = this.translate.instant('inside_call.call_info.copy_link');
                this.copyLinkIcon1.nativeElement.src = 'assets/images/share-event-popup/copy-link.svg';
            }
        }, 3000);
        this.utilService.copy(
            this.eventDetails.webinar?.activation?.url || this.eventDetails?.webinar?.registration?.url
        );
    }

    copyAttendeeShortenedLink() {
        if (this.attendee_short_text && this.attendee_short_text.nativeElement) {
            this.attendee_short_text.nativeElement.textContent = this.translate.instant('Copied Shortened Link');
            this.attendeeShortLinkIcon0.nativeElement.src = 'assets/images/share-event-popup/copy-link-success.svg';
        }

        setTimeout(() => {
            if (this.attendee_short_text && this.attendee_short_text.nativeElement) {
                this.attendee_short_text.nativeElement.textContent = this.translate.instant('Copy Shortened Link');
                this.attendeeShortLinkIcon0.nativeElement.src = 'assets/images/share-event-popup/copy-link.svg';
            }
        }, 3000);
        this.utilService.copy(this.attendeeShortLink);
    }

    copyInvitation(invitationType) {
        this.invitationType = invitationType;
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Jioconf Share Event',
                eventAction: `${this.invitationType === 'attendee' ? 'Attendee Link' : 'Speaker Link'}`,
                eventType: 'app_event',
                endpoint: '',
                status: 'success'
            })
            .subscribe();
        this.timeoutId = setTimeout(() => {
            if (this.isMobile && navigator?.share) {
                const invitationData = this.utilService.getTextFromHTMLElement('event-invitation');
                navigator
                    .share({
                        title: this.eventDetails.title,
                        text: invitationData
                    })
                    .then(() => {})
                    .catch(console.error);
            } else {
                this.utilService.copyToClipboardFromHTMLElement('event-invitation');
            }
        }, 200);
        if (invitationType == 'attendee') {
            this.googleTagMangerService.pushGoogleTagData(POPUP.COPY_ATTENDEE_LINK);
            if (this.invitation_attendee && this.invitation_attendee.nativeElement) {
                this.invitation_attendee.nativeElement.textContent = this.translate.instant('Copied Invitation');
                this.attendeeLinkIcon1.nativeElement.src =
                    'assets/images/share-event-popup/copy-link-success-light.svg';
                this.attendeeInvitationActive = true;
            }
            setTimeout(() => {
                if (this.invitation_attendee && this.invitation_attendee.nativeElement) {
                    this.invitation_attendee.nativeElement.textContent = this.translate.instant(
                        'inside_call.call_info.copy_invitation'
                    );
                    this.attendeeLinkIcon1.nativeElement.src = 'assets/images/share-event-popup/copied_dark_green.svg';
                }
                this.attendeeInvitationActive = false;
            }, 3000);
        }
        if (invitationType == 'speaker') {
            this.googleTagMangerService.pushGoogleTagData(POPUP.COPY_SPEAKER_LINK);
            if (this.invitation_text && this.invitation_text.nativeElement) {
                this.invitation_text.nativeElement.textContent = this.translate.instant('Copied Invitation');
                this.attendeeLinkIcon0.nativeElement.src =
                    'assets/images/share-event-popup/copy-link-success-light.svg';
                this.speakerInvitationActive = true;
            }
            setTimeout(() => {
                if (this.invitation_text && this.invitation_text.nativeElement) {
                    this.invitation_text.nativeElement.textContent = this.translate.instant(
                        'inside_call.call_info.copy_invitation'
                    );
                    this.attendeeLinkIcon0.nativeElement.src = 'assets/images/share-event-popup/copied_dark_green.svg';
                    this.speakerInvitationActive = false;
                }
            }, 3000);
        }
    }

    getShareUrl() {
        const room = this.rtcService?.getRoomInfo();
        if (room) {
            this.rtcService.getCallShareUrl(room).subscribe((res: any) => {
                this.rtcService.setShareUrlInfo(res);
                this.webinarService.setMaskedUrlForSpeaker(res?.url);
            });
        }
    }

    cancel() {
        this.callViewStateManagerService.showShareEventSocialPopup = false;
        this.close.emit();
    }

    goBack() {
        this.back.emit();
    }

    public get isMobile() {
        return window.innerWidth < 768;
    }

    sanitizeText(message) {
        return this.utilService.sanitizeAndConvertEncodedHTML(message);
    }

    shareOnFacebook() {
        window.open(
            `https://www.facebook.com/sharer.php?u=${this.attendeeInviteLink}&quote=${this.eventDetails?.topic} `,
            '_blank'
        );
        this.googleTagMangerService.pushGoogleTagData(POPUP.FACEBOOK);
    }

    shareOnTwitter() {
        window.open(`http://twitter.com/share?url=${this.attendeeInviteLink}`, '_blank');
        this.googleTagMangerService.pushGoogleTagData(POPUP.TWITTER);
    }

    shareOnLinkedin() {
        window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${this.attendeeInviteLink}&title=${this.eventDetails?.topic}`,
            '_blank'
        );
        this.googleTagMangerService.pushGoogleTagData(POPUP.LINKEDIN);
    }

    shareOnMail() {
        const body = encodeURIComponent(this.attendeeInviteLink);
        window.open(`mailto:?body=${body}`);
        this.googleTagMangerService.pushGoogleTagData(POPUP.EMAIL);
    }

    shareOnWhatsapp() {
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.attendeeInviteLink)}`, '_blank');
        this.googleTagMangerService.pushGoogleTagData(POPUP.WHATSAPP);
    }

    ngOnDestroy() {
        clearTimeout(this.timeoutId);
    }
}
