import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private appService: AppService, private restService: RestService) {}

    getNotifications() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/notifications?offset=0&limit=100`);
    }

    markNotificationsAsRead(notificationIds) {
        notificationIds = Array.isArray(notificationIds) ? notificationIds : [notificationIds];
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/notifications`, {
            ids: notificationIds
        });
    }
}
