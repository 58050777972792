<app-loader [position]="'relative'" *ngIf="!preCallTestingService.performanceResult"></app-loader>
<section *ngIf="!preCallTestingService.performanceResult">
    <app-result-loader></app-result-loader>
</section>
<div class="connectivity-test-container" *ngIf="preCallTestingService.performanceResult">
    <div class="connectivity-info-section">
        <div class="connectivity-test-heading">
            <div class="img-container">
                <img src="assets/images/pre-call-testing/connectivity.svg" alt="" />
            </div>
            <div class="title">Connection Details</div>
        </div>
        <div class="connectivity-test-subheading">Below are the details of Connectivity to JioMeet API Servers</div>
    </div>
    <div class="connectivity-result-container">
        <div class="result-parameter">
            <div class="parameter-left">
                <div class="parameter-name">UDP (Port 443)</div>
            </div>
            <div class="parameter-right">
                <div class="parameter-img">
                    <img
                        [src]="'assets/images/pre-call-testing/' + (isUdpAvailable ? 'success.svg' : 'failed.svg')"
                        alt=""
                    />
                </div>
                <div class="parameter-value">
                    {{ isUdpAvailable ? 'Open' : 'Closed' }}
                </div>
            </div>
        </div>
        <div class="result-parameter">
            <div class="parameter-left">
                <div class="parameter-name">TCP (Port 443)</div>
            </div>
            <div class="parameter-right">
                <div class="parameter-img">
                    <img
                        [src]="'assets/images/pre-call-testing/' + (isTcpAvailable ? 'success.svg' : 'failed.svg')"
                        alt=""
                    />
                </div>
                <div class="parameter-value">
                    {{ isTcpAvailable ? 'Open' : 'Closed' }}
                </div>
            </div>
        </div>
    </div>
</div>
