import { Component, OnInit } from '@angular/core';
import { UtilService, AppService, UserService, AuthService, MeetingService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-add-to-jiomeet',
    templateUrl: './add-to-jiomeet.component.html',
    styleUrls: ['./add-to-jiomeet.component.scss']
})
export class AddToJiomeetComponent implements OnInit {
    supported = false;
    currentOS: 'Windows' | 'Mac OS' | 'Other';
    params = '';
    meetingId;
    hash;
    isMobileOS;
    isIOS;
    isAuthenticated = false;
    iosMacDeeplink;
    pwd;
    constructor(
        private utilService: UtilService,
        private appService: AppService,
        private activatedRoute: ActivatedRoute,
        private route: Router,
        private authService: AuthService,
        private meetingService: MeetingService,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.supported = this.utilService.isRTCSupportedBrowser();
        this.setOS();
        this.fetchParams();
        this.launch();
    }

    setOS() {
        const os = this.utilService.getOS();
        switch (os) {
            case 'Windows':
                this.currentOS = 'Windows';
                break;
            case 'Mac OS':
                this.currentOS = 'Mac OS';
                break;
            default:
                this.currentOS = 'Other';
        }
    }

    download(os) {
        switch (os) {
            case 'Windows':
                window.open(this.utilService.getWindowsClient());
                break;
            case 'Mac OS':
                window.open(this.appService.getConfigVariable('MAC_INSTALLER_URL'), '_blank');
                break;
        }
    }

    gotoStore() {
        if (this.utilService.detectBrowser() === 'android') {
            this.navigateToPlayStore();
            return;
        }
        if (this.utilService.detectBrowser() === 'ipad' || this.utilService.detectBrowser() === 'iphone') {
            this.navigateToAppStore();
            return;
        }
    }

    launch() {
        const os = this.utilService.getOS();
        switch (os) {
            case 'Windows':
                location.href =
                    'jiomeet:' + this.appService.getEnvVariable('HOST_URL') + 'addtojiomeet' + this.fetchParams();
                break;
            case 'Mac OS':
                this.iosMacDeeplink =
                    'jhb://' + this.appService.getEnvVariable('HOST_URL') + 'addtojiomeet' + this.fetchParams();
                // location.href =
                //  'jhb://' +
                //  this.appService.getEnvVariable('HOST_URL') +
                //  'addtojiomeet' +
                //  this.fetchParams();
                break;
            case 'iOS':
                if (this.utilService.isEdgeBrowser()) {
                    if (this.utilService.isIpad()) {
                        this.openAppOrNavigateToStore();
                    } else {
                        // for iphone
                        this.iosMacDeeplink =
                            'jhb://' + this.appService.getEnvVariable('HOST_URL') + 'addtojiomeet' + this.fetchParams();
                    }
                } else {
                    const a = document.createElement('a');
                    a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                    a.download = 'Download';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                this.isMobileOS = true;
                break;
            case 'Android':
                // this.navigateToPlayStore();
                this.openJiomeetOrPlaystore();
                this.isMobileOS = true;
                break;
            default:
                if (this.utilService.detectBrowser() === 'android') {
                    // this.navigateToPlayStore();
                    this.openJiomeetOrPlaystore();
                    this.isMobileOS = true;
                    return;
                }
                if (this.utilService.detectBrowser() === 'ipad' || this.utilService.detectBrowser() === 'iphone') {
                    const a = document.createElement('a');
                    a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                    a.download = 'Download';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.isMobileOS = true;
                    return;
                }
                break;
        }
    }

    openJiomeetOrPlaystore() {
        window.location.href = `intent://${this.appService
            .getEnvVariable('HOST_URL')
            .slice(
                'https://'.length
            )}addtojiomeet${this.fetchParams()}#Intent;package=com.jio.rilconferences;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=https;end`;

        setTimeout(() => {
            window.location.href = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
        }, 1000);
    }

    fetchParams() {
        this.isIOS = this.activatedRoute.snapshot.queryParams.ios;
        this.meetingId = this.activatedRoute.snapshot.queryParams.meetingId;
        this.hash = this.activatedRoute.snapshot.queryParams.hash;
        this.pwd = this.activatedRoute.snapshot.queryParams.pwd;
        let param = '';
        if (this.meetingId) {
            param = `?meetingId=${this.meetingId}`;
        } else if (this.hash) {
            param = `?hash=${this.hash}`;
        }
        param = `${param}${this.pwd ? '&pwd=' + this.pwd : ''}`;
        return param;
    }

    openAppOrNavigateToStore() {
        if (this.isIOS !== 'true') {
            window.location.href = 'jhb://' + window.location.href;
            // this.iosMacDeeplink = 'jhb://' + window.location.href;
            setTimeout(() => {
                window.location.replace(window.location.href + '&ios=true');
            }, 2000);
        } else {
            const a = document.createElement('a');
            a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
            a.download = 'Download';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    navigateToPlayStore() {
        const a = document.createElement('a');
        a.href = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
        a.download = 'Download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    navigateToAppStore() {
        const a = document.createElement('a');
        a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
        a.download = 'Download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    addToJiomeet() {
        this.meetingService.addToJiomeet({ hash: this.hash, extension: this.meetingId, pin: this.pwd }).subscribe(
            (res) => {
                this.route.navigate(['meetings'], {
                    queryParams: { addtojiomeetSuccess: true }
                });
            },
            (err) => {
                if (err.error.message === 'MEETING_ALREADY_ADDED' || err.error.message === 'MEETING_ENDED') {
                    this.toastrService.error(err.error.errors);
                }
            }
        );
    }

    gotoHome() {
        this.route.navigate(['home']);
    }
}
