<!-- modal.component.html -->
<div class="info-modal-overlay">
    <div class="info-modal-container">
        <div class="info-modal-header">
            <div class="heading-container">
                <span class="heading-img">
                    <img [src]="'assets/images/pre-call-testing/' + iconName + '.svg'" alt="" />
                </span>
                <span class="heading-text">{{ modalHeading }}</span>
            </div>
            <div class="close-container">
                <span class="close-icon" (click)="closeModal()"
                    ><img src="assets/images/pre-call-testing/cross.svg" alt=""
                /></span>
            </div>
        </div>
        <div class="info-modal-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
