import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppService, MeetingService, TagsService, UtilService, ValidationService, UserService } from 'src/app/core';
import { NetworkingRoomsService } from 'src/app/core/services/networking-rooms.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { NETWORKING_LOUNGE } from 'src/app/constants';

@Component({
    selector: 'app-networking-room-details-popup',
    templateUrl: './networking-room-details-popup.component.html',
    styleUrls: ['./networking-room-details-popup.component.scss']
})
export class NetworkingRoomDetailsPopupComponent implements OnInit {
    @Input() webinarId: string;
    @Input() networkingRoom: any;
    @Input() networkingRooms = [];
    @Input() showRoomDetailsPopup: boolean = false;
    @Input() parentMeetingDetails;
    @Input() isWebinarSettings: boolean = false;
    @Output() close = new EventEmitter();
    @Output() updateRoomEmitter = new EventEmitter();
    enableEscapeRooms: boolean = false;
    isEscapeRoom: boolean = true;
    categories = [];
    category: string;
    name: string;
    errors: any = {};
    maxFileSize: any;
    roomLogoUrl: any = {};
    file: File;
    errorMessage = '';
    isError = false;
    tooltip = 'You can use the private room for closed conversations with people you add. Others don’t see this room.';
    isModerated: boolean;
    fileUploadSuccess: boolean = false;
    fileUpload;
    showProgressBar: boolean = false;
    progress: number;
    blobURLref;
    enableCategoryOptionForNetworkingRoom: boolean = false;
    networkingRoomDetailsForm: any;
    isUpdateDisabled: boolean = true;
    networkingRoomsConfigLimit = this.appService.getConfigVariable('max_networking_rooms_limit') || 25;
    showRoomNameCount: any = false;
    disableCreateRoom: boolean = false;
    currentUser;
    constructor(
        private tagsService: TagsService,
        private toastrService: ToastrService,
        private appService: AppService,
        public networkingRoomsService: NetworkingRoomsService,
        private translateService: TranslateService,
        private appLoggerService: AppLoggerService,
        private utilService: UtilService,
        private webinarService: WebinarService,
        private sanitizer: DomSanitizer,
        private fb: FormBuilder,
        private validationService: ValidationService,
        private googleTagMangerService: GoogleTagMangerService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.currentUser = this.userService.getUserSync();
        this.maxFileSize = this.appService.getConfigVariable('jioConfConfig')?.maxImageUploadSize || 10;
        this.tagsService.getTagsCollection().subscribe((res) => {
            this.categories = Object.keys(res);
        });
        this.isEscapeRoom = this.networkingRoom?.networking?.isEscapeRoom;
        this.setForm();
    }

    ngOnChanges(change) {
        // if(!this.networkingRoom) {
        //     // reset form
        // }
        if (change?.networkingRoom) {
            this.setForm();
        }
    }

    setForm() {
        this.networkingRoomDetailsForm = this.fb.group({
            name: [
                this.networkingRoom?.topic || '',
                [
                    Validators.required,
                    this.validationService.noWhitespaceValidator,
                    Validators.pattern('^[a-zA-Z0-9 _-]*$')
                ]
            ],
            category: [this.networkingRoom?.jioConf?.eventType || ''],
            roomLogoUrl: [this.networkingRoom?.networking?.imageUrl || '']
        });
        this.isEscapeRoom = this.networkingRoom?.networking?.isEscapeRoom ?? false;
        this.enableEscapeRooms = this.utilService.enablePaymentFeature(this.currentUser?.isOTT)
            ? this.currentUser?.eventsFeaturesEnabled?.enableEscapeRooms
            : true;
        this.isModerated = this.networkingRoom?.networking?.isModerated || '';
        this.blobURLref = null;
        this.fileUploadSuccess = false;
        this.file = null;
        this.isError = false;
        this.showProgressBar = false;
        this.disableCreateRoom = false;
        // this.name = this.networkingRoom?.topic || '';
        // this.category = this.networkingRoom?.jioConf.eventType || '';
        this.roomLogoUrl = this.networkingRoom?.networking?.imageUrl || '';
    }

    async submitNetworkingRoomForm() {
        if ((!this.networkingRoom && !this.canCreateRequestedRoom()) || !this.checkIsDuplicateName()) {
            this.googleTagMangerService.pushGoogleTagData(NETWORKING_LOUNGE.CONFIRM_CREATE_ROOM);
            return;
        }
        this.disableCreateRoom = true;
        if (this.blobURLref && !this.roomLogoUrl && this.networkingRoomDetailsForm.controls['roomLogoUrl'].dirty) {
            this.uploadDisplayImage(true);
        } else {
            this.sendCreateOrEditRoomRequest();
        }
    }

    canCreateRequestedRoom() {
        if (
            this.utilService.enablePaymentFeature(this.currentUser?.isOTT) &&
            this.networkingRooms?.length + 1 > this.currentUser?.eventsFeaturesEnabled?.networkingRoomsCount
        ) {
            this.toastrService.error('Networking Rooms Limit Reached');
            return false;
        } else if (
            !this.utilService.enablePaymentFeature(this.currentUser?.isOTT) &&
            this.networkingRooms?.length + 1 > this.networkingRoomsConfigLimit
        ) {
            this.toastrService.error('Networking Rooms Limit Reached');
            return false;
        }
        return true;
    }

    checkIsDuplicateName() {
        if (this.isDuplicateName()) {
            this.isError = true;
            this.errorMessage = 'A room with this name already exists, please try a different name';
            return false;
        }
        return true;
    }

    isDuplicateName() {
        let duplicateIndex = this.networkingRooms.findIndex(
            (r) => r.topic?.trim().toLowerCase() === this.networkingRoomDetailsForm.value.name.trim().toLowerCase()
        );
        console.log(duplicateIndex);
        if (duplicateIndex !== -1) {
            return true;
        }
        return false;
    }

    async sendCreateOrEditRoomRequest() {
        if (this.networkingRoom) {
            const payload = this.networkingRoomsService.getUpdateRoomPayload(this.networkingRoom);
            // const payload = this.networkingRoom;
            payload.topic = this.networkingRoomDetailsForm.value.name;
            payload.jioConf.eventType = this.networkingRoomDetailsForm.value.category;
            let evenDataEdit = NETWORKING_LOUNGE.EDIT_ROOM;
            this.googleTagMangerService.pushGoogleTagData(evenDataEdit);
            this.networkingRoomsService
                .editRoom(
                    this.networkingRoom.networking.parentId,
                    payload,
                    this.networkingRoomDetailsForm.value.roomLogoUrl,
                    this.networkingRoom.networking._id
                )
                .subscribe(
                    (res) => {
                        this.toastrService.success((this.isEscapeRoom ? 'Escape' : 'Networking') + ' room updated');
                        this.disableCreateRoom = false;
                        this.closeFormSidePanel();
                    },
                    (err) => {
                        if (err.error.message === 'DUPLICATE_NETWORKING_ROOM_NAME') {
                            this.errorMessage = err.error.errors;
                            this.isError = true;
                        } else {
                            this.appLoggerService.error('Error while creating networking room', err);
                            this.toastrService.error(err.error.errors || 'Something went wrong.Please try again');
                        }
                        this.disableCreateRoom = false;
                    }
                );
            // this.webinarService
            //     .updateWebinar(
            //         this.networkingRoom._id,
            //         Object.assign(payload, {
            //             hostInfo: this.networkingRoom.hostInfo,
            //             privateIp: this.networkingRoom.privateIp,
            //             url: this.networkingRoom.url,
            //             vpin: this.networkingRoom.vpin,
            //             gatewayIp: this.networkingRoom.gatewayIp,
            //             participants: []
            //         })
            //     )
            //     .subscribe(
            //         (res) => {
            //             this.toastrService.success('Networking room updated');
            //             this.close.emit({ success: true });
            //         },
            //         (err) => {
            //             this.appLoggerService.error('Error while updating networking room', err);
            //             this.toastrService.error(err.error.errors || 'Something went wrong.Please try again');
            //         }
            //     );
        } else {
            this.createRoomWithImage();
            let evenDataCreate = NETWORKING_LOUNGE.CONFIRM_CREATE_ROOM;
            this.googleTagMangerService.pushGoogleTagData(evenDataCreate);
        }
    }

    async uploadDisplayImage(createRoom) {
        this.networkingRoomsService.uploadDisplayImage(this.webinarId, this.file).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    this.showProgressBar = true;
                    this.progress = Math.round((event.loaded / event.total) * 100);
                    break;
                case HttpEventType.Response:
                    // this.fileUploadSuccess = true;
                    this.showProgressBar = false;
                    this.networkingRoomDetailsForm.get('roomLogoUrl').setValue(event.body.networkingRoomLogoUrl);
                    this.sendCreateOrEditRoomRequest();
                    break;
            }
        });
    }

    listenToFormValueChanges() {
        this.networkingRoomDetailsForm.valueChanges.subscribe((v) => {
            this.isUpdateDisabled = !this.networkingRoomDetailsForm.valid;
        });
    }
    onFocus() {
        this.isError = false; // set the field
    }

    toggleEscapeRoomSetting() {
        this.isEscapeRoom = !this.isEscapeRoom;
    }

    createRoomWithImage() {
        this.networkingRoomsService
            .createRoom(
                this.webinarId,
                this.networkingRoomsService.getCreateRoomPayload(
                    this.networkingRoomDetailsForm.value.name,
                    this.networkingRoomDetailsForm.value.category,
                    this.networkingRoomDetailsForm.value.roomLogoUrl,
                    1,
                    this.isEscapeRoom,
                    this.parentMeetingDetails.startTime,
                    this.parentMeetingDetails.endTime
                )
            )
            .subscribe(
                (res) => {
                    this.toastrService.success((this.isEscapeRoom ? 'Escape' : 'Networking') + ' room created');
                    this.disableWaitingRoomForNwRoom();
                    this.networkingRoom = null;
                    this.setForm();
                    // TODO close
                    this.disableCreateRoom = false;
                    this.closeFormSidePanel();
                },
                (err) => {
                    //error if same room name exists
                    if (err.error.message === 'DUPLICATE_NETWORKING_ROOM_NAME') {
                        this.errorMessage = err.error.errors;
                        this.isError = true;
                    } else {
                        this.appLoggerService.error('Error while creating networking room', err);
                        this.toastrService.error(err.error.errors || 'Something went wrong.Please try again');
                    }
                    this.disableCreateRoom = false;
                }
            );
    }

    disableWaitingRoomForNwRoom() {
        /**
         * TODO (BACKEND)
         * This should be handled at backend, where we disable waiting room while creation itself
         */

        if (this.shouldUpdateNwRoomOnCreation(this.parentMeetingDetails?.advancedOptions)) {
            this.updateRoomEmitter.emit({
                event: 'udpateNetworkingRoom',
                name: this.networkingRoomDetailsForm?.value?.name
            });
            // this.sendCreateOrEditRoomRequest();
        }
    }

    shouldUpdateNwRoomOnCreation(opts) {
        return !opts?.joinBeforeHost || opts?.waitingRoom || opts?.loggedInOnly;
    }

    toggleSessionType() {}

    toggleRoomPrivacy() {
        if (!this.networkingRoom) {
            this.isModerated = !this.isModerated;
        } else {
            this.networkingRoom.networking.isModerated = !this.isModerated;
            this.networkingRoomsService.updateRoomPrivacy(this.webinarId, this.networkingRoom.networking).subscribe(
                (res) => {
                    this.toastrService.info('Networking Room Privacy Updated');
                    this.isModerated = this.networkingRoom.networking.isModerated;
                },
                (err) => {
                    this.toastrService.error('Failed to update Room Privacy. Please try again');
                    this.networkingRoom.networking.isModerated = this.isModerated;
                }
            );
        }
    }

    fileChangeEvent(event) {
        this.handleDropFileEvent(event.target.files);
    }

    handleDropFileEvent(event) {
        var isFileValid = this.validateFile(event[0]);
        if (isFileValid) {
            this.file = event[0];
            // this.fileUploadStats.display = false;
            this.errors.image = '';
            // this.uploadLogo(event[0]);
            this.createBlobUrl();
            this.googleTagMangerService.pushGoogleTagData(NETWORKING_LOUNGE.UPLOAD_DISPLAY_IMAGE);
        }
    }

    discardFile() {
        this.roomLogoUrl = null;
        this.blobURLref = null;
        this.networkingRoomDetailsForm.get('roomLogoUrl').setValue('');
        this.fileUploadSuccess = false;
        this.markLogoUrlControlAsDirty();
        this.showProgressBar = false;
        this.disableCreateRoom = false;
    }

    createBlobUrl() {
        // URL.revokeObjectURL()
        // THis should be run when the url is no longer in use

        this.blobURLref = URL.createObjectURL(this.file);
        this.fileUploadSuccess = true;
        this.markLogoUrlControlAsDirty();
    }

    markLogoUrlControlAsDirty() {
        this.networkingRoomDetailsForm.controls['roomLogoUrl'].markAsDirty();
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    closeFormSidePanel() {
        this.close.emit({ success: true });
    }

    // TODO: Move this function to utils service and errors
    validateFile(file) {
        // this.fileUploadStats = {
        //     display: false,
        //     success: 0,
        //     failed: 0
        // };
        // this.isUpdateDisabled = false;
        if (
            !(
                file.type === 'image/jpeg' ||
                file.type === 'image/jpg' ||
                file.type === 'image/png' ||
                file.type === 'image/gif'
            )
        ) {
            this.errors.image = this.translateService.instant('FILE_UPLOAD_ERRORS.INCORRECT_FILE_IMG');
            return false;
        }
        var size = Math.round(file.size / 1024);
        if (20 > size) {
            this.errors.image = this.translateService.instant('FILE_UPLOAD_ERRORS.MIN_ERROR_IMG');
            this.toastrService.error(this.translateService.instant('FILE_UPLOAD_ERRORS.MIN_ERROR_IMG'));
            return false;
        } else if (size > 2048) {
            this.errors.image = this.translateService.instant('FILE_UPLOAD_ERRORS.MAX_ERROR_IMG');
            this.toastrService.error(this.translateService.instant('FILE_UPLOAD_ERRORS.MAX_ERROR_IMG'));
            return false;
        }
        return true;
    }

    clearFieldRoomname() {
        this.networkingRoomDetailsForm.get('name').setValue('');
    }

    escapeRoomSettingToggled() {
        this.networkingRoomDetailsForm.markAsDirty();
    }
}
