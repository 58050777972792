import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { LocalStorageService } from './local-storage.service';
import { AppService } from './app.service';
import { tap } from 'rxjs/operators';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService } from './event-emitter.service';
import { AppLoggerService } from './app-logger.service';

@Injectable({
    providedIn: 'root'
})
export class GuestAuthService {
    guestAuthInfo;
    constructor(
        private restService: RestService,
        private localstorageService: LocalStorageService,
        private appService: AppService,
        private eventEmitterService: EventEmitterService,
        private appLoggerService: AppLoggerService
    ) {}

    login({ jiomeetId, roomID, roomPin, name, lname, phoneNo, email, recorderToken = '', isLiveStreaming = false }) {
        const payload = {
            jiomeetId,
            roomID,
            roomPin,
            name,
            lName: lname,
            phoneNo,
            email,
            ...(recorderToken && { recorderToken }),
            ...(isLiveStreaming && { isLiveStreaming }),
            machineIp: '1.1.1.1',
            participantEndPointName: 'web',
            participantEndPointType: 'web'
        };
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/login`, payload)
            .pipe(
                tap((authInfo) => {
                    this.setAuthInfo(authInfo);
                })
            );
    }

    loginWithHostToken({ jiomeetId, roomID, roomPin, name, phoneNo, emailId, hostToken }) {
        const payload = {
            jiomeetId,
            roomID,
            roomPin,
            name,
            phoneNo,
            emailId,
            machineIp: '1.1.1.1',
            participantEndPointName: 'web',
            participantEndPointType: 'web',
            hostToken
        };
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/login`, payload)
            .pipe(
                tap((authInfo) => {
                    this.setAuthInfo(authInfo);
                    this.setAuthInfoForHost(authInfo);
                })
            );
    }

    setAuthInfoForHost(authInfo) {
        this.localstorageService.addItem('userInfo', authInfo);
    }

    setAuthInfo(authInfo) {
        // this.localstorageService.addItem('guestAuthInfo', authInfo);
        this.guestAuthInfo = authInfo;
        this.eventEmitterService.emit({
            type: APP_EVENTS.AUTHORIZED,
            data: null
        });
    }

    getAuthInfo() {
        return this.guestAuthInfo || this.localstorageService.getItem('guestAuthInfo');
    }

    isGuestRequest(request) {
        return [
            '/guest',
            '/leaderboard',
            // '/chat/getThreadForChat/postmessage/guest',
            // '/chat/getThreadForChat/messages/guest',
            // '/chat/getThreadForChat/guest',
            '/chat/thread',
            '/leave',
            '/getshareurlhash',
            '/webinar/stats',
            '/livechat/getmessages',
            'livechat/messages'
        ].filter((path) => request.url.indexOf(path) > -1).length;
    }

    cleanup() {
        this.guestAuthInfo = null;
        this.localstorageService.removeItem('guestAuthInfo');
    }
}
