import { AuthGuard } from './auth.guard';
import { CanCallGuard } from './can-call.guard';
import { CheckForServerDownGuard } from './check-for-server-down.guard';
import { CheckWebrtcEnabledGuard } from './check-webrtc-enabled.guard';
import { IncomingCallGuard } from './incoming-call.guard';
import { OnboardingGuard } from './onboarding.guard';
import { CheckForSpeakerEntryGuard } from './check-for-speaker-entry.guard';
import { NavigationGuard } from './navigation.guard';
import { WebinarSettingGuard } from './webinar-setting.guard';

export * from './auth.guard';
export * from './navigation.guard';
export * from './check-webrtc-enabled.guard';
export * from './check-for-server-down.guard';
export * from './can-call.guard';
export * from './onboarding.guard';
export * from './incoming-call.guard';
export * from './check-for-speaker-entry.guard';
export * from './webinar-setting.guard';

export const guards = [
    AuthGuard,
    CheckWebrtcEnabledGuard,
    CheckForServerDownGuard,
    CanCallGuard,
    OnboardingGuard,
    IncomingCallGuard,
    CheckForSpeakerEntryGuard,
    NavigationGuard,
    WebinarSettingGuard
];
