import { imageElement } from '../models/imageElement';
import { fabric } from 'fabric';
import { imageAssetType } from '../models/elementTypesEnum';

export class imageElementView implements imageElement {
    // base model properties
    id: number;
    name: string;
    type: string;
    visible: boolean;
    zIndex: number;
    position: { left: number; top: number };
    dimension: { height: number; width: number };
    rotation: number;
    transitions: { onAdding: string; onMove: string; onRemove: string };

    // image element properties
    imageUrl: string;
    placeholderImageUrl: string;
    imageAssetType: imageAssetType;
    fabricObject: Object;
    assetId: number;
    urlList: [];
    isSlideShow = false;
    isBackground: boolean = false;

    constructor(props: imageElement) {
        this.id = props.id;
        this.name = props.name;
        this.type = props.type;
        this.visible = props.visible;
        this.zIndex = props.zIndex;
        this.position = props.position;
        this.dimension = props.dimension;
        this.transitions = props.transitions;
        this.imageUrl = props.imageUrl;
        this.urlList = props.urlList;
        this.isSlideShow = props.isSlideShow;
        this.placeholderImageUrl = props.placeholderImageUrl;
        this.imageAssetType = props.imageAssetType;
        this.assetId = props.assetId;
        this.isBackground = props.isBackground;
    }

    async createFabricElement() {
        return new Promise((resolve, reject) => {
            let img = document.createElement('img');
            img.onload = async (img) => {
                const imageObj = await new fabric.Image(img.target, {});
                imageObj.setControlsVisibility({ mtr: false, mt: false, ml: false, mr: false, mb: false });
                imageObj.scaleToWidth(this.percentageToPixel(this.dimension.width, 1920), false);
                imageObj.top =
                    this.imageAssetType === 'background' ? 0 : this.percentageToPixel(this.position.top, 1080);
                imageObj.left =
                    this.imageAssetType === 'background' ? 0 : this.percentageToPixel(this.position.left, 1920);
                imageObj.cornerSize = 25;
                imageObj.id = this.id;
                imageObj.visible = this.visible;
                resolve(imageObj);
                this.fabricObject = imageObj;
            };
            img.src = this.imageUrl;
        });
    }

    pixelToPercentage(pixelLength, parentLength) {
        return (pixelLength / parentLength) * 100;
    }

    percentageToPixel(percentage, parentLength) {
        return (percentage * parentLength) / 100;
    }
}
