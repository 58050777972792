import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userInfo'
})
export class UserInfoPipe implements PipeTransform {
    transform(user: any, infoType: string): string {
        if (!user) return '';
        if (user.isOTT) {
            if (infoType === 'name') {
                return `${user.name || ''} ${user.lname || ''}` || user?.displayPhoneNo;
            } else if (infoType === 'email') {
                return user?.displayPhoneNo || user?.email;
            }
        } else {
            if (infoType === 'name') {
                return `${user.name || ''} ${user.lname || ''}`;
            } else if (infoType === 'email') {
                return user?.email;
            }
        }

        return null;
    }
}
