<div class="popup-component">
    <div class="bg"></div>
    <div class="popup">
        <div class="header">
            {{ this.singleEdit ? 'Edit Recurrence' : 'Set Recurrence' }}
            <div class="close pointer" (click)="closePopup()">
                <img src="assets/img/close_icon.svg" />
            </div>
        </div>
        <div class="singleEditMessage mt-3" *ngIf="singleEdit">
            This change will only affect only the selected one occurrence and not the entire series.
        </div>
        <div class="body">
            <form [formGroup]="recurringForm" *ngIf="!singleEdit">
                <div class="form-group">
                    <label>Repeats Every</label>
                    <p-dropdown
                        [options]="recurringTypeOptions"
                        placeholder="Recurring Type"
                        formControlName="repeat"
                        panelStyleClass="attendee-count"
                    >
                    </p-dropdown>
                </div>
                <div class="form-group" *ngIf="selectedRecurringType === 'weekly'">
                    <div class="days">
                        <div *ngFor="let item of weekDays; let j = index" class>
                            <div class="checkbox-wrapper mr-3 mb-0">
                                <div>
                                    <input
                                        type="button"
                                        [class.selected]="selectedDaysOfWeek[j] === true"
                                        value="{{ item }}"
                                        (click)="selectedDays(j)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group monthly-form" *ngIf="selectedRecurringType === 'monthly'">
                    <div class="monthly-form-types">
                        <input
                            type="radio"
                            formControlName="monthlyType"
                            name="monthlyType"
                            id="monthWeek"
                            value="monthWeek"
                        />
                        <label class="month-label">On The</label>
                        <p-dropdown
                            [options]="monthWeekOptions"
                            formControlName="monthWeekType"
                            panelStyleClass="attendee-count"
                            [class.disabled]="selectedMonthType !== 'monthWeek'"
                        >
                        </p-dropdown>
                        <p-dropdown
                            [options]="monthDayOptions"
                            formControlName="monthDayType"
                            panelStyleClass="attendee-count"
                            [class.disabled]="selectedMonthType !== 'monthWeek'"
                        >
                        </p-dropdown>
                    </div>
                    <div class="monthly-form-types">
                        <input
                            type="radio"
                            formControlName="monthlyType"
                            name="monthlyType"
                            id="monthDate"
                            value="monthDate"
                        />
                        <label class="month-label">On Date</label>
                        <div class="month-date" [class.disabled]="selectedMonthType !== 'monthDate'">
                            {{
                                previousForm && previousForm.dateInMonth !== 0
                                    ? previousForm.dateInMonth
                                    : (startTime | date: 'dd')
                            }}
                        </div>
                    </div>
                </div>
                <div class="form-group calendar-end-date">
                    <label>Repeat Until</label>
                    <p-calendar
                        formControlName="endDate"
                        dateFormat="D, dd M yy"
                        [minDate]="meetingStartTime"
                        [showOtherMonths]="false"
                        [showIcon]="true"
                        icon="pi pi-angle-down"
                        [appendTo]="'body'"
                        [readonlyInput]="true"
                        (onHide)="onHideCalendar()"
                    >
                    </p-calendar>
                </div>
            </form>
            <div class="mt-4" *ngIf="!singleEdit">
                <span>{{ displayMessage(this.recurringForm?.value.repeat) }}</span>
                <span *ngIf="selectedRecurringType === 'daily'">{{
                    this.recurringForm.value.endDate | date: 'EEEE, dd MMM yyyy'
                }}</span>
                <span *ngIf="selectedRecurringType !== 'daily'">{{
                    this.recurringForm.value.endDate | date: 'd/M/yy'
                }}</span>
            </div>
            <div *ngIf="singleEdit">
                <form [formGroup]="singleEditForm">
                    <div class="start-time mb-3">
                        <label class="label-required mb-0">Start Date</label>
                        <div class="date-input">
                            <p-calendar
                                #calendar
                                formControlName="startTime"
                                dateFormat="D, M dd, yy"
                                [minDate]="meetingStartTime"
                                [showOtherMonths]="false"
                                [showIcon]="true"
                                icon="pi pi-angle-down"
                                [appendTo]="'body'"
                                [readonlyInput]="true"
                                (onHide)="onHideCalendar()"
                            >
                            </p-calendar>
                        </div>
                    </div>
                    <div formGroupName="duration">
                        <div class="time-input mb-3">
                            <label class="label-required mb-0">Start Time</label>
                            <div class="dropdown startTimeDropdown show-dropdown-arrow" id="timeInputDropdown">
                                <input
                                    type="text"
                                    readonly
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    placeholder="Select Time"
                                    [value]="startTimeString"
                                />
                                <div #dropdownClose class="dropdown-menu">
                                    <div class="d-flex justify-content-center flex-column">
                                        <div class="time-selection-wrap d-flex justify-content-center">
                                            <div class="select-wrap">
                                                <p-dropdown
                                                    [options]="hours"
                                                    formControlName="hour"
                                                    optionValue="value"
                                                    panelStyleClass="time-panel"
                                                >
                                                    <ng-template pTemplate="selectedItem" let-item>
                                                        {{ item?.value }}
                                                    </ng-template>
                                                    <ng-template let-item pTemplate="item">
                                                        {{ item?.value }}
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="separator">:</div>
                                            <div class="select-wrap">
                                                <p-dropdown
                                                    [options]="minutes"
                                                    formControlName="minutes"
                                                    optionValue="value"
                                                    panelStyleClass="time-panel"
                                                >
                                                    <ng-template pTemplate="selectedItem" let-item>
                                                        {{ item?.value }}
                                                    </ng-template>
                                                    <ng-template let-item pTemplate="item">
                                                        {{ item?.value }}
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-center align-items-center mt-3">
                                            <label for="meridiemAM" class="pointer mb-0 meridiem-label d-flex">
                                                <input
                                                    type="radio"
                                                    name="meridiem"
                                                    id="meridiemAM"
                                                    value="am"
                                                    formControlName="meridiem"
                                                />
                                                <span class="d-flex align-items-center">AM</span>
                                            </label>
                                            <div>
                                                <label for="meridiemPM" class="ml-3 pointer mb-0 meridiem-label d-flex">
                                                    <input
                                                        type="radio"
                                                        name="meridiem"
                                                        id="meridiemPM"
                                                        value="pm"
                                                        formControlName="meridiem"
                                                    />
                                                    <span class="d-flex align-items-center">PM</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="actions text-center mt-3">
                                        <button
                                            type="button"
                                            class="custom-button bg-primary"
                                            (click)="setEndDate(this.singleEditForm.value.startTime)"
                                        >
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="time-input mb-3">
                            <label class="label-required mb-0">Duration</label>
                            <div class="dropdown startTimeDropdown show-dropdown-arrow" id="durationDropdown">
                                <input
                                    type="text"
                                    readonly
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    placeholder="Select Duration"
                                    [value]="durationString"
                                />
                                <div class="dropdown-menu">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="select-wrap">
                                            <p-dropdown
                                                [options]="durationHours"
                                                formControlName="durationInHours"
                                                optionValue="value"
                                                panelStyleClass="time-panel"
                                            >
                                                <ng-template pTemplate="selectedItem" let-item>
                                                    {{ item?.value }}
                                                </ng-template>
                                                <ng-template let-item pTemplate="item">
                                                    {{ item?.value }}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="separator">:</div>
                                        <div class="select-wrap">
                                            <p-dropdown
                                                [options]="durationMinutes"
                                                formControlName="durationInMinutes"
                                                optionValue="value"
                                                panelStyleClass="time-panel"
                                            >
                                                <ng-template pTemplate="selectedItem" let-item>
                                                    {{ item?.value }}
                                                </ng-template>
                                                <ng-template let-item pTemplate="item">
                                                    {{ item?.value }}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="actions text-center mt-4">
                                        <button
                                            type="button"
                                            class="custom-button bg-primary"
                                            (click)="setEndDate(this.singleEditForm.value.startTime)"
                                        >
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="actions-end d-flex justify-content-end">
            <button class="custom-button bg-secondary mr-3 light-theme" (click)="closePopup()">Cancel</button>
            <button
                class="custom-button bg-primary light-theme"
                (click)="singleEdit ? submitSingleEditForm() : submit()"
            >
                Save
            </button>
        </div>
    </div>
</div>
