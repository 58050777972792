<div
    class="calendar d-flex justify-content-center"
    [class.isMobile]="utilservice.isMobileView"
    [ngClass]="{ 'multiday-cal': joinDetail?.isMultiDay }"
>
    <span class="year" [ngClass]="{ 'multiday-year': joinDetail?.isMultiDay }">
        {{ joinDetail?.startTime | date: 'LLL' }}
    </span>
    <span class="date" *ngIf="!joinDetail?.isMultiDay">
        {{ joinDetail?.startTime | date: 'dd' }}
    </span>
    <span class="date" *ngIf="joinDetail?.isMultiDay">
        {{ joinDetail?.startTime | date: 'dd' }} - {{ joinDetail?.endTime | date: 'dd' }}
    </span>
</div>
