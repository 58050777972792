import { Injectable, NgZone } from '@angular/core';

import { RestService } from './rest.service';
import { AppService } from './app.service';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import {
    AuthService,
    DiagnosticService,
    EventEmitterService,
    SocketEvent,
    SocketService,
    UserService,
    UtilService
} from '.';
import { ToastrService } from 'ngx-toastr';
import { WEBINAR_CONFERENCE_EVENTS } from 'src/app/conference/constants';
import { WEBINAR_ATTENDEE_EVENTS } from 'src/app/webinar-attendee/constants';
import { APP_EVENTS } from 'src/app/constants';
import { WEBINAR_CONFERENCE_SOCKET_EVENTS } from 'src/app/conference/constants/webinar-conference-socket-events';

@Injectable({
    providedIn: 'root'
})
export class PollsService {
    meetingId: any;
    isAuthenticated: boolean;
    isSpeaker: boolean = false;
    subscriptions = {};
    polls = [];
    pollStats: any = {}; // { deletedLivePollIds, draftPollIds, endedPollIds, livePollIds }
    pollsInterval$: any;
    userAnswers = {};
    unansweredLivePollCount = 0;
    private pollsStatus$: Subject<any> = new Subject();
    private pollsNotificationCount$: BehaviorSubject<any> = new BehaviorSubject(0);
    localUserId: any;
    didFetchAllPolls: boolean = false;
    isMobileOS: boolean = false;
    alreadyAnsweredPollId: any;
    constructor(
        private restService: RestService,
        private appService: AppService,
        private authService: AuthService,
        private toastrService: ToastrService,
        private eventEmitterService: EventEmitterService,
        private zone: NgZone,
        private socketService: SocketService,
        private userService: UserService,
        private utilService: UtilService,
        private diagnosticService: DiagnosticService
    ) {
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.isMobileOS = this.utilService?.isResponsiveMobileView(769);
    }

    initiatePollsFetchInterval(meetingId, isSpeaker) {
        this.isSpeaker = isSpeaker;
        this.meetingId = meetingId;
        this.localUserId = this.userService.getUserSync()._id || '';
        if (isSpeaker) {
            this.subscriptions[new Date().valueOf()] = this.socketService.dataEvents$.subscribe(
                this.handleSocketEvents.bind(this)
            );
        }
        this.setupPolls();
        if (this.pollsInterval$) {
            this.pollsInterval$?.unsubscribe();
        }
        this.pollsInterval$ = interval(
            this.appService.getConfigVariable('POLLING_INTERVAL_FOR_POLLS') || 10000
        ).subscribe(() => {
            this.setupPolls();
        });
    }

    setupPolls() {
        this.getPollStats(this.meetingId);
        this.listenToLivePollUpdates();
    }

    getPollStats(meetingId) {
        const endpoint = this.isSpeaker
            ? `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/pollStats${
                  this.isAuthenticated ? '' : '/guest'
              }`
            : `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendees/pollStats${
                  this.isAuthenticated ? '' : '/guest'
              }`;
        const timestamp_pollStats = new Date().valueOf();
        this.subscriptions[timestamp_pollStats] = this.restService.get(endpoint).subscribe((ps) => {
            this.handleStatsResponse(ps);
            this.subscriptions[timestamp_pollStats].unsubscribe();
        });
    }

    handleStatsResponse(ps) {
        if (!Object.keys(this.pollStats)?.length) {
            this.pollStats = ps;
            this.fetchAllPolls();
            return;
        }

        // handling live Polls
        let latestAddedLivePolls = this.getPollIdsWithNewStatus(this.pollStats.livePollIds, ps.livePollIds);
        if (latestAddedLivePolls?.length) {
            const timestamp_livePolls = new Date().valueOf();
            this.subscriptions[timestamp_livePolls] = this.getPollById(this.meetingId, {
                pollIds: latestAddedLivePolls
            }).subscribe((res: any) => {
                res?.polls?.forEach((p) => {
                    let tempPollIndex = this.polls?.findIndex((pollVar) => p._id === pollVar._id);
                    if (tempPollIndex === -1) {
                        this.polls.push(p);
                    } else {
                        this.polls[tempPollIndex] = p;
                    }
                });
                this.sendPollsStatus();
                this.emitLivePollNotification();
                this.subscriptions[timestamp_livePolls].unsubscribe();
            });
        }

        // handling draft polls
        if (this.isSpeaker) {
            let latestAddedDraftPolls = this.getPollIdsWithNewStatus(this.pollStats.draftPollIds, ps.draftPollIds);
            if (latestAddedDraftPolls?.length) {
                const timestamp_draftPolls = new Date().valueOf();
                this.subscriptions[timestamp_draftPolls] = this.getPollById(this.meetingId, {
                    pollIds: latestAddedDraftPolls
                }).subscribe((res: any) => {
                    res?.polls?.forEach((p) => {
                        this.polls.push(p);
                    });
                    this.sendPollsStatus();
                    this.subscriptions[timestamp_draftPolls].unsubscribe();
                });
            }
        }

        // handling Ended Polls
        if (this.pollStats.endedPollIds.length < ps.endedPollIds.length) {
            let latestEndedPolls = this.getPollIdsWithNewStatus(this.pollStats.endedPollIds, ps.endedPollIds, true);
            if (latestEndedPolls?.length) {
                const timestamp_endedPolls = new Date().valueOf();
                this.subscriptions[timestamp_endedPolls] = this.getPollById(this.meetingId, {
                    pollIds: latestEndedPolls
                }).subscribe((res: any) => {
                    res.polls.forEach((p) => {
                        let index = this.polls.findIndex((poll) => poll._id == p._id);
                        p.userAnswer = this.userAnswers[p?._id] || [];
                        index === -1 ? this.polls.push(p) : (this.polls[index] = p);
                    });
                    this.sendPollsStatus();
                    this.subscriptions[timestamp_endedPolls].unsubscribe();
                });
            }
        }

        // handling deleted Polls
        if (this.pollStats.deletedLivePollIds.length < ps.deletedLivePollIds.length) {
            let latestDeletedPolls = this.getPollIdsWithNewStatus(
                this.pollStats.deletedLivePollIds,
                ps.deletedLivePollIds,
                true
            );
            this.polls = this.polls.filter((p) => {
                return !latestDeletedPolls?.includes(p?._id);
            });
            this.sendPollsStatus();
        }

        this.pollStats = ps;
    }

    listenToLivePollUpdates() {
        if (this.pollStats?.livePollIds?.length == 0) return;
        const timestamp_pollById = new Date().valueOf();
        this.subscriptions[timestamp_pollById] = this.getPollById(this.meetingId, {
            pollIds: this.pollStats?.livePollIds
        }).subscribe((res: any) => {
            res.polls.forEach((p) => {
                if (p) {
                    let idx = this.polls.findIndex((poll) => poll?._id == p?._id);
                    p.userAnswer = this.userAnswers[p?._id] || [];
                    if (idx !== -1) {
                        this.polls[idx] = p;
                    }
                }
            });
            this.sendPollsStatus();
            this.subscriptions[timestamp_pollById].unsubscribe();
        });
    }

    getPollIdsWithNewStatus(oldArray, newArray, compareOldArrayOnly = false) {
        let pollIds = this.polls?.map((p) => p._id);
        let updatedArray = compareOldArrayOnly
            ? newArray?.filter((x) => !oldArray?.includes(x))
            : newArray?.filter((x) => !(pollIds?.includes(x) || oldArray?.includes(x)));
        return updatedArray;
    }

    getTotalPollStatsCount(ps: any) {
        return ps.livePollIds?.length + ps.endedPollIds?.length + ps.deletedLivePollIds?.length;
    }

    fetchAllPolls(fetchLatest = false) {
        if (this.didFetchAllPolls && !fetchLatest) {
            this.sendPollsStatus();
            return;
        }
        const endpoint = this.isSpeaker
            ? `${this.appService.getEnvVariable('BASE_URL')}/meeting/${this.meetingId}/webinar/polls${
                  this.isAuthenticated ? '' : '/guest'
              }?filterBy=all`
            : `${this.appService.getEnvVariable('BASE_URL')}/meeting/${this.meetingId}/webinar/attendees/polls${
                  this.isAuthenticated ? '' : '/guest'
              }`;

        const timestamp_allPolls = new Date().valueOf();
        this.subscriptions[timestamp_allPolls] = this.restService.get(endpoint).subscribe((res) => {
            this.polls = res.polls;
            this.polls.forEach((p) => {
                p.userAnswer = p.questions[0]?.userAnswer || [];
                this.userAnswers[p._id] = p.userAnswer;
            });
            this.getPollStats(this.meetingId);
            this.sendPollsStatus();
            this.didFetchAllPolls = true;
            this.subscriptions[timestamp_allPolls].unsubscribe();
        });
    }

    createPoll(meetingId, payload, publish = false) {
        const timestamp_createPoll = new Date().valueOf();
        this.subscriptions[timestamp_createPoll] = this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls`, payload)
            .subscribe(
                (res: any) => {
                    let idx = this.polls.findIndex((p) => p._id === res._id);
                    if (idx === -1) {
                        this.polls.push(res);
                        this.getPollStats(this.meetingId);
                    }
                    this.sendPollsStatus();

                    if (publish) {
                        this.publishPoll(this.meetingId, res?._id);
                    } else this.sendPollsStatus({ closeCreateForm: true, submitting: true });
                    this.subscriptions[timestamp_createPoll].unsubscribe();
                },
                (err) => {
                    this.toastrService.error(err?.error?.errors || 'Unable to create the poll. Please try again later');
                    this.sendPollsStatus({ submitting: false });
                    this.subscriptions[timestamp_createPoll].unsubscribe();
                }
            );
    }

    editPoll(meetingId, payload, pollId, publish = false) {
        const timestamp_editPoll = new Date().valueOf();
        this.subscriptions[timestamp_editPoll] = this.restService
            .put(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls/${pollId}`, payload)
            .subscribe(
                (res) => {
                    let index = this.polls.findIndex((poll) => poll._id == pollId);
                    index === -1 ? this.polls.push(res) : (this.polls[index] = res);
                    if (publish) {
                        this.publishPoll(this.meetingId, pollId);
                    } else this.sendPollsStatus({ closeCreateForm: true, submitting: true });
                    this.subscriptions[timestamp_editPoll].unsubscribe();
                },
                (err) => {
                    this.toastrService.error(err?.error?.errors || 'Unable to edit the poll. Please try again later');
                    this.sendPollsStatus({ submitting: false });
                    this.subscriptions[timestamp_editPoll].unsubscribe();
                }
            );
    }

    deletePoll(meetingId, pollId) {
        const timestamp_deletePoll = new Date().valueOf();
        this.subscriptions[timestamp_deletePoll] = this.restService
            .delete(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls/${pollId}`)
            .subscribe(
                (res: any) => {
                    if (res?.success) {
                        this.toastrService.success('Poll deleted successfully');
                        let pollIndexToDelete = this.polls.findIndex((p) => p._id === pollId);
                        if (pollIndexToDelete !== -1) {
                            this.polls.splice(pollIndexToDelete, 1);
                        }
                        if (!this.pollStats?.deletedLivePollIds?.includes(pollId)) {
                            this.pollStats?.deletedLivePollIds?.push(pollId);
                        }
                        if (this.pollStats?.livePollIds?.includes(pollId)) {
                            this.pollStats?.livePollIds?.splice(
                                this.pollStats?.livePollIds?.findIndex((p) => p === pollId),
                                1
                            );
                        } else if (this.pollStats?.draftPollIds?.includes(pollId)) {
                            this.pollStats?.draftPollIds?.splice(
                                this.pollStats?.draftPollIds?.findIndex((p) => p === pollId),
                                1
                            );
                        }
                        this.sendPollsStatus();
                    } else {
                        this.toastrService.error('Unable to delete the poll. Please try again later');
                    }
                    this.subscriptions[timestamp_deletePoll].unsubscribe();
                },
                (err) => {
                    this.toastrService.error(err?.error?.errors || 'Unable to delete the poll. Please try again later');
                    this.subscriptions[timestamp_deletePoll].unsubscribe();
                }
            );
    }

    publishPoll(meetingId, pollId) {
        const timestamp_publishPoll = new Date().valueOf();
        this.subscriptions[timestamp_publishPoll] = this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls/${pollId}/launch`,
                {}
            )
            .subscribe(
                (res: any) => {
                    if (!res?.success) {
                        this.toastrService.error('Unable to publish the poll. Please try again later');
                        return;
                    }
                    // this.getPollStats(this.meetingId);
                    const index = this.polls.findIndex((p) => p._id === pollId);
                    if (index === -1) {
                        return;
                    }
                    this.polls[index].status = 'live';
                    this.toastrService.success('Poll published successfully');
                    if (this.pollStats?.livePollIds?.findIndex((p) => p === pollId) === -1) {
                        this.pollStats.livePollIds.push(pollId);
                    }
                    this.sendPollsStatus({ closeCreateForm: true, submitting: true });
                    this.sendGaEvent('publish', 'success', this.polls[index]?.questions[0]?.type);
                    this.subscriptions[timestamp_publishPoll].unsubscribe();
                },
                (err) => {
                    this.toastrService.error(
                        err?.error?.errors || 'Unable to publish the poll. Please try again later'
                    );
                    let poll_index = this.polls.findIndex((p) => p._id === pollId);
                    this.sendGaEvent('publish', 'failed', this.polls[poll_index]?.questions[0]?.type);
                    this.sendPollsStatus({ submitting: false });
                    this.subscriptions[timestamp_publishPoll].unsubscribe();
                }
            );
    }

    answerPoll(meetingId, pollId, payload) {
        const endpoint = this.isSpeaker
            ? `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls/${pollId}/answer${
                  this.isAuthenticated ? '' : '/guest'
              }`
            : `${this.appService.getEnvVariable(
                  'BASE_URL'
              )}/meeting/${meetingId}/webinar/attendees/polls/${pollId}/answer${this.isAuthenticated ? '' : '/guest'}`;

        const timestamp_answerPoll = new Date().valueOf();
        this.subscriptions[timestamp_answerPoll] = this.restService.post(endpoint, payload).subscribe(
            (res: any) => {
                if (res?.success) {
                    this.updateLivePollCount();
                    const index = this.polls.findIndex((p) => p._id === pollId);
                    if (index === -1) {
                        return;
                    }
                    this.getPollById(meetingId, { pollIds: [pollId] }).subscribe((res: any) => {
                        this.polls[index] = res?.polls[0];
                        this.polls[index].userAnswer = payload?.answers[0]?.options;
                        this.userAnswers[pollId] = payload?.answers[0]?.options;
                        this.sendPollsStatus();
                    });
                    this.polls[index].userAnswer = payload?.answers[0]?.options;
                    this.userAnswers[pollId] = payload?.answers[0]?.options;
                    this.sendPollsStatus();
                    this.sendGaEvent('submit', 'success');
                    this.toastrService.success('Poll answered successfully');
                } else {
                    this.toastrService.error('Unable to answer the poll. Please try again later');
                }
                this.subscriptions[timestamp_answerPoll].unsubscribe();
            },
            (err) => {
                if (err?.error?.errors === 'Poll is not live') {
                    this.toastrService.error('Oops! A poll has been deleted.');
                } else {
                    this.alreadyAnsweredPollId = pollId;
                    if (err?.error?.errors === 'You have already answered poll question') this.fetchAllPolls(true);
                    this.toastrService.error(err?.error?.errors || 'Unable to answer the poll. Please try again later');
                }
                this.sendGaEvent('submit', 'failed');
                this.subscriptions[timestamp_answerPoll].unsubscribe();
            }
        );
    }

    endPoll(meetingId, id) {
        const timestamp_endPoll = new Date().valueOf();
        this.subscriptions[timestamp_endPoll] = this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls/${id}/end`, {})
            .subscribe(
                (res: any) => {
                    const index = this.polls.findIndex((p) => p._id === id);
                    if (index === -1) {
                        return;
                    }
                    this.getPollStats(this.meetingId);
                    this.polls[index] = res;
                    this.polls[index].userAnswer = this.userAnswers[id] || [];
                    this.toastrService.success('Poll ended successfully');
                    this.getPollStats(this.meetingId);
                    this.sendPollsStatus();
                    this.subscriptions[timestamp_endPoll].unsubscribe();
                },
                (err) => {
                    this.toastrService.error(err?.error?.errors || 'Unable to end the poll. Please try again later');
                    this.subscriptions[timestamp_endPoll].unsubscribe();
                }
            );
    }

    getPollById(meetingId = this.meetingId, pollIdObject) {
        pollIdObject = { pollIds: this.removeDuplicatePollIds(pollIdObject?.pollIds) };
        const endpoint = this.isSpeaker
            ? `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/polls/byid${
                  this.isAuthenticated ? '' : '/guest'
              }`
            : `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendees/polls/byid${
                  this.isAuthenticated ? '' : '/guest'
              }`;
        return this.restService.post(endpoint, pollIdObject);
    }

    private sendPollsStatus(updates: any = {}) {
        this.pollsStatus$.next({
            polls: this.polls,
            closeCreateForm: updates?.closeCreateForm,
            liveAndDraftPollsCount: this.pollStats?.livePollIds?.length + this.pollStats?.draftPollIds?.length,
            submitting: updates?.submitting,
            refreshPoll: this.alreadyAnsweredPollId
        });
        if (this.alreadyAnsweredPollId) this.alreadyAnsweredPollId = null;
        this.updateLivePollCount();
    }

    getPolls() {
        this.sendPollsStatus();
    }

    getPollStatus$() {
        return this.pollsStatus$;
    }

    updateLivePollCount() {
        const pollsCount = this.getUnansweredLivePolls();
        if (pollsCount === this.unansweredLivePollCount) return;
        this.unansweredLivePollCount = pollsCount;
        this.pollsNotificationCount$.next(this.unansweredLivePollCount);
    }

    getPollsBadgeCount$() {
        return this.pollsNotificationCount$;
    }

    emitLivePollNotification() {
        if (this.isMobileOS && this.isSpeaker) {
            return;
        }
        this.toastrService.clear();
        this.toastrService.info('Poll is Live');
        if (this.isSpeaker) {
            this.eventEmitterService.emit({
                type: WEBINAR_CONFERENCE_EVENTS.SHOW_POLLS_PANEL,
                data: null
            });
        } else {
            this.eventEmitterService.emit({
                type: WEBINAR_ATTENDEE_EVENTS.SHOW_POLL,
                data: { isFromEvent: true }
            });
        }
    }

    handleSocketEvents(event: SocketEvent) {
        this.zone.run(() => {
            let pollData = event.data;
            let idx;
            if (event?.data?.meetingId === this.meetingId) {
                switch (event.event) {
                    case WEBINAR_CONFERENCE_SOCKET_EVENTS.POLL_CREATED:
                        idx = this.polls.findIndex((p) => p._id === pollData._id);
                        if (
                            idx === -1 &&
                            !this.pollStats?.livePollIds?.includes(pollData._id) &&
                            !this.pollStats?.draftPollIds?.includes(pollData._id)
                        ) {
                            pollData.userAnswer = this.userAnswers[pollData._id] || [];
                            this.polls.push(pollData);
                            this.pollStats?.draftPollIds?.push(pollData._id);
                        }
                        this.sendPollsStatus();
                        break;

                    case WEBINAR_CONFERENCE_SOCKET_EVENTS.POLL_EDITED:
                        idx = this.polls.findIndex((p) => p._id === pollData._id);
                        pollData.userAnswer = this.userAnswers[pollData._id] || [];
                        idx !== -1 ? (this.polls[idx] = pollData) : this.polls.push(pollData);
                        this.sendPollsStatus();
                        break;

                    case WEBINAR_CONFERENCE_SOCKET_EVENTS.POLL_DELETED:
                        this.polls = this.polls.filter((p) => p._id !== pollData.pollId);
                        this.pollStats?.deletedLivePollIds?.push(pollData.pollId);

                        if (this.pollStats?.livePollIds?.includes(pollData.pollId)) {
                            this.pollStats?.livePollIds?.splice(
                                this.pollStats?.livePollIds?.findIndex((p) => p === pollData.pollId),
                                1
                            );
                        } else if (this.pollStats?.draftPollIds?.includes(pollData.pollId)) {
                            this.pollStats?.draftPollIds?.splice(
                                this.pollStats?.draftPollIds?.findIndex((p) => p === pollData.pollId),
                                1
                            );
                        }
                        this.sendPollsStatus();
                        break;

                    case WEBINAR_CONFERENCE_SOCKET_EVENTS.POLL_PUBLISHED:
                        idx = this.polls.findIndex((p) => p._id === pollData._id);
                        pollData.userAnswer = this.userAnswers[pollData._id] || [];
                        idx !== -1 ? (this.polls[idx] = pollData) : this.polls.push(pollData);
                        if (this.pollStats?.draftPollIds?.includes(pollData._id)) {
                            this.pollStats?.draftPollIds?.splice(
                                this.pollStats?.draftPollIds?.findIndex((p) => p === pollData._id),
                                1
                            );
                        }
                        if (this.pollStats?.livePollIds?.findIndex((p) => p === pollData._id) === -1) {
                            this.pollStats.livePollIds.push(pollData._id);
                        }
                        this.sendPollsStatus();
                        if (this.localUserId !== pollData.userId) {
                            this.emitLivePollNotification();
                        }
                        break;

                    case WEBINAR_CONFERENCE_SOCKET_EVENTS.POLL_ENDED:
                        idx = this.polls.findIndex((p) => p._id === pollData._id);
                        pollData.userAnswer = this.userAnswers[pollData._id] || [];
                        idx !== -1 ? (this.polls[idx] = pollData) : this.polls.push(pollData);
                        this.pollStats?.livePollIds?.splice(
                            this.pollStats?.livePollIds?.findIndex((p) => p === pollData._id),
                            1
                        );
                        this.pollStats.endedPollIds.push(pollData._id);
                        this.sendPollsStatus();
                        break;
                }
            }
        });
    }

    getUnansweredLivePolls() {
        let count = 0;
        this.polls?.forEach((poll) => {
            if (
                poll.status === 'live' &&
                (!this.userAnswers[poll._id] || this.userAnswers[poll._id]?.length == 0) &&
                poll.userId !== this.localUserId
            ) {
                count++;
            }
        });
        return count;
    }

    clearSubscriptions() {
        if (this.pollsInterval$) {
            this.polls = [];
            this.pollsInterval$?.unsubscribe();
            this.pollsInterval$ = null;
        }
        Object.keys(this.subscriptions).map((s) => {
            this.subscriptions[s].unsubscribe();
        });
    }

    clearPollSubscription() {
        if (this.pollsInterval$) {
            this.pollsInterval$?.unsubscribe();
        }
    }

    removeDuplicatePollIds(arr) {
        return (arr || [])?.filter((Id, index) => arr?.indexOf(Id) === index);
    }

    sendGaEvent(action, actionStatus, pollType = null) {
        if (action === 'publish') {
            this.diagnosticService
                .sendEvent({
                    eventAction: `Publish with ${pollType === 'singlechoice' ? 'Single' : 'Multiple'} choice`,
                    eventCategory: 'JioEvents - Polls',
                    eventType: 'app_event',
                    status: actionStatus
                })
                .subscribe();
        } else if (action === 'submit') {
            this.diagnosticService
                .sendEvent({
                    eventAction: 'Submit a Poll',
                    eventCategory: 'JioEvents - Polls',
                    eventType: 'app_event',
                    status: 'success'
                })
                .subscribe();
        }
    }

    ngOnDestroy() {
        console.log('ngOnDestroy: cleaning up...');
        this.pollsInterval$?.unsubscribe();
    }
    updateMeetingId(meetingId) {
        this.meetingId = meetingId;
        this.didFetchAllPolls = false;
        this.fetchAllPolls();
    }
}
