<div class="faq-question-style">
    <div class="heading-section">
        <h2 id="selectedlink" class="link-heading">{{ selectedLink }}</h2>
    </div>

    <div class="faq-item" *ngFor="let faq of faqs; let i = index">
        <div class="faq-accordion" [class.active]="faq.isActive" (click)="toggleAccordion(i)">
            <div class="faq-question">
                <div>
                    {{ faq.question }}
                </div>
                <img
                    class="img-style"
                    [src]="faq.isActive ? 'assets/img/revamp/ic_minus.svg' : 'assets/img/revamp/ic_add.svg'"
                />
            </div>

            <div
                class="faq-answer"
                [style.max-height]="faq.isActive ? 'none' : '0'"
                [style.opacity]="faq.isActive ? '1' : '0'"
            >
                <p [innerHTML]="faq.answer"></p>
            </div>
        </div>
    </div>
</div>
