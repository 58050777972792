import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

import { RtcService } from '../services';

@Injectable()
export class CanCallGuard implements CanActivate {
    constructor(private confirmationService: ConfirmationService, private rtcService: RtcService) {}

    canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
        return new Observable((observer) => {
            if (this.rtcService.getIncomingCallMeeting()) {
                this.confirmationService.confirm({
                    message: 'To start/join this meeting the incoming call will be declined. Do you want to continue?',
                    header: 'Warning',
                    acceptLabel: 'Yes',
                    rejectLabel: 'No',
                    acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
                    rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
                    accept: () => {
                        this.rtcService.sendCallRejectNotification().subscribe();
                        observer.next(true);
                        observer.complete();
                    },
                    reject: () => {
                        observer.next(false);
                        observer.complete();
                    }
                });
            } else {
                observer.next(true);
                observer.complete();
            }
        });
    }
}
