<nav
    class="navbar main-header navbar-expand-md my-element"
    [class.hide-header-for-event-controls]="isEventControls"
    (outside)="onOutsideClick($event)"
    id="navbar1"
    [class.isMobile]="utilService.isMobileView"
    [class.occupyFull]="showNavigationList"
    [class.hide-header]="utilService.hideHeaderView"
    [class.show-header-view]="!utilService.hideHeaderView"
    [class.is-dashboard]="isDashboard"
    [class.is-ipad]="isIpad"
    [class.studioHeader]="isStudioHeader"
>
    <header class="header-sec d-flex align-items-center" *ngIf="isDashboard" [class.w-100]="isMobile">
        <span
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            (click)="toggleMenu()"
        >
            <span class="navbar-toggler-icon">
                <img
                    class="icon-show"
                    src="{{ showMenu ? 'assets/img/close_dashboard_menu.svg' : 'assets/img/website/swap-me@2x.png' }}"
                />
            </span>
        </span>
        <a
            class="navbar-brand cursor-pointer"
            (click)="toggleVersionClick()"
            aria-roledescription="Jioevents"
            role="button"
            tabindex="0"
        >
            <div class="brand-logo">
                <div class="new-logo">
                    <img
                        *ngIf="utilService.isMobileView"
                        src="assets/img/website/events-logo.svg"
                        class="jio-text-img logo-mobile"
                    />
                    <img
                        *ngIf="!utilService.isMobileView"
                        src="assets/img/website/JioEvents-Prod-Logo.svg"
                        class="jio-text-img"
                    />
                </div>
            </div>
        </a>
        <div class="nav-item freeTrailButton freeTrailButtonMobile" *ngIf="showInMobile && !isAuthenticated">
            <a
                (click)="closeToggle(headerEvents.SIGN_IN, $event); redirectToContactUs()"
                class="nav-link jiotype-bold-te-papa-green-16px nav-btn-text"
                >Demo</a
            >
        </div>
        <div *ngIf="isAuthenticated">
            <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
        </div>
    </header>
    <div class="logo-sandwich-wrapper" *ngIf="!isDashboard">
        <a class="navbar-brand" (click)="navigateTohome()">
            <img [src]="pageLogoUrl" alt="" />
        </a>
        <div class="right-view-icon">
            <button
                *ngIf="!isAuthenticated && utilService.isMobileView && !contactPage"
                class="custom-button-revamp btn-primary w-auto request-button"
                (click)="redirectToContactUs()"
            >
                {{ buttonText }}
            </button>
            <div *ngIf="isAuthenticated" class="show-in-mobile">
                <div
                    class="nav-link pro-img-cir pro-img-view"
                    id="navbarUserDropdown"
                    role="button"
                    (click)="openProfile(); activeColorState = false; activeIconState = false"
                >
                    <img
                        [src]="profilePicPath"
                        alt=""
                        *ngIf="profilePicPath"
                        class="pro-avatar"
                        [class.profile-view-icon]="showNavigationList"
                    />
                </div>
                <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarUserDropdown">
                    <div class="view-profile d-flex">
                        <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" class="pro-img-cir pro-img" />
                        <div class="details pointer">
                            <div class="login-name" *ngIf="!currentUser?.isOTT">
                                {{ currentUser?.name || '' }} {{ currentUser?.lname || '' }}
                            </div>

                            <div class="login-name" *ngIf="currentUser?.isOTT">
                                {{
                                    currentUser?.name + ' ' + (currentUser?.lname || '') || currentUser?.displayPhoneNo
                                }}
                            </div>
                            <div class="email" *ngIf="!currentUser?.isOTT">
                                {{ currentUser?.email }}
                            </div>
                            <div class="email" *ngIf="currentUser?.isOTT">
                                {{ currentUser?.displayPhoneNo || currentUser?.email }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="view-profile pointer dashboard-wrapper"
                        (click)="closeToggle(headerEvents.PROFILE); openProfile()"
                    >
                        <img class="icon-wrapper-view" src="assets/img/revamp/view_profile.svg" alt="" />
                        View Profile
                    </div>
                    <div
                        class="view-profile pointer dashboard-wrapper"
                        (click)="
                            closeToggle(headerEvents.GOTODASHBOARD);
                            navigateToDashboard();
                            showNavigationList = false;
                            activeColorState = false
                        "
                    >
                        <img class="icon-wrapper-view" src="assets/img/revamp/dashboard_Icon.svg" alt="" />
                        Dashboard
                    </div>
                    <div class="pointer" (click)="logout()">
                        <div class="logout view-profile dashboard-wrapper">
                            <img class="icon-wrapper-view" src="assets/img/revamp/sign_out_icon.svg" alt="" />
                            {{ 'landing_or_home_page.header.sign_out' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <span
                class="navbar-toggler toggle-padding"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                (click)="handleHeaderHambergerClick(); activeColorState = false; activeIconState = false"
            >
                <div class="hamburger" id="hamburger-1" [class.is-active]="showNavigationList">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </div>
            </span>
        </div>
    </div>

    <div
        class="navigation-list-wrapper collapse navbar-collapse mobile-collapse header-position"
        [ngClass]="{ staticPageHeader: !isStaticPage }"
        id="navbarCollapse"
        *ngIf="!isMobile && !showInMobile"
        [class.show-view]="!showNavigationList"
        [class.isMobileLandscapeMode]="isMobileLandscapeMode"
    >
        <div>
            <ul class="navbar-nav mr-auto nav-view">
                <ng-container *ngIf="isStaticPage">
                    <li class="nav-item product-list-item text-alignment">
                        <span
                            id="navbarDropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            routerLinkActive="active-link"
                            class="nav-link d-flex"
                            (click)="toggleDropDown('product'); activeColorState = false"
                        >
                            <span class="text-animation" [class.active-link]="activeIconState">Products</span>
                            <img
                                class="rotate"
                                [class.down]="activeIconState"
                                src="assets/img/revamp/product_drop_down.svg"
                                alt=""
                            />
                        </span>
                        <div
                            class="dropdown-menu sub-menu-container w-100"
                            aria-labelledby="navbarDropdown"
                            (click)="activeColorState = false; activeIconState = false"
                        >
                            <div class="column-container">
                                <a
                                    routerLink="/Solutions"
                                    (click)="closeToggle(headerEvents.SOLUTIONS)"
                                    (click)="
                                        showNavigationList = false; activeColorState = false; activeIconState = false
                                    "
                                >
                                    <div class="menu-item">
                                        <div class="flex items-center submenu-mr">
                                            <div class="sub-menu-content">
                                                <div class="sub-menu-head jiotype-bold-white-16px">
                                                    <img
                                                        src="assets/img/revamp/product_drop_down_Icon.svg"
                                                        alt=""
                                                        class="image-view-content"
                                                    />
                                                    <span class="add-hover">JioEvents Platform</span>
                                                </div>

                                                <div
                                                    class="
                                                        jiotype-medium-white-14px-2
                                                        text-wrapper-hide text-warpper-view
                                                    "
                                                >
                                                    Where imagination meets reality, crafting lightning-fast &
                                                    delightful virtual & hybrid experiences.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="column-container">
                                <a appDropdownToggle (click)="activeColorState = false; activeColorState = false">
                                    <div class="menu-item">
                                        <div class="flex items-center submenu-mr">
                                            <div class="sub-menu-content">
                                                <div class="sub-menu-head jiotype-bold-white-16px">
                                                    <img
                                                        src="assets/img/revamp/pre_recorded_Icon.svg"
                                                        alt=""
                                                        class="image-view-content"
                                                    />
                                                    <span class="add-hover">JioEvents Studio </span>
                                                    <span class="text-view-container">
                                                        <span class="custom-txt-view">Coming Soon</span>
                                                    </span>
                                                </div>
                                                <div
                                                    class="
                                                        sub-menu-desc
                                                        jiotype-medium-white-14px-2
                                                        text-wrapper-hide text-warpper-view
                                                    "
                                                >
                                                    Unleash the power of virtual production: Transforming Ideas to
                                                    Reality
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item list-view">
                        <span
                            routerLink="/solutions"
                            (click)="closeToggle(headerEvents.SOLUTIONS)"
                            routerLinkActive="active-link"
                            class="nav-link text-animation"
                            (click)="showNavigationList = false; activeIconState = false; activeColorState = false"
                        >
                            Solutions
                        </span>
                    </li>
                    <li class="nav-item list-view">
                        <span
                            routerLink="/explore-events"
                            (click)="closeToggle(headerEvents.EXPLORE_EVENT)"
                            routerLinkActive="active-link"
                            class="nav-link text-animation"
                            (click)="showNavigationList = false; activeIconState = false; activeColorState = false"
                        >
                            Events
                        </span>
                    </li>

                    <li class="nav-item list-view">
                        <span
                            routerLink="/pricing"
                            (click)="closeToggle(headerEvents.PRICING)"
                            routerLinkActive="active-link"
                            class="nav-link text-animation"
                            href="#"
                            (click)="showNavigationList = false; activeIconState = false; activeColorState = false"
                            >Pricing</span
                        >
                    </li>
                    <li class="nav-item product-list-item text-alignment">
                        <span
                            id="navbarDropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            routerLinkActive="active-link"
                            class="nav-link text-animation d-flex color-element"
                            (click)="toggleDropDown('resource'); activeIconState = false"
                        >
                            <span class="text-animation" [class.active-link]="activeColorState">Resources</span>
                            <img
                                class="rotate"
                                [class.down]="activeColorState"
                                src="assets/img/revamp/Resources_drop_down.svg"
                                alt=""
                            />
                        </span>
                        <div
                            class="dropdown-menu sub-menu-container w-100"
                            aria-labelledby="navbarDropdown"
                            (click)="activeColorState = false; activeIconState = false"
                        >
                            <div class="d-flex">
                                <div class="column-container">
                                    <!-- What's New -->
                                    <a
                                        (click)="redirectToWhatsNew()"
                                        (click)="
                                            showNavigationList = false;
                                            activeColorState = false;
                                            activeColorState = false
                                        "
                                    >
                                        <div class="menu-item">
                                            <div class="flex items-center submenu-mr">
                                                <div class="sub-menu-content">
                                                    <div class="sub-menu-head jiotype-bold-white-16px">
                                                        <img
                                                            src="assets/img/revamp/ic_bulb.svg"
                                                            alt=""
                                                            class="image-view-content"
                                                        />
                                                        <span class="add-hover">What's New</span>
                                                    </div>
                                                    <div
                                                        class="
                                                            jiotype-medium-white-14px-2
                                                            text-wrapper-hide
                                                            resources-text-warpper-view
                                                        "
                                                    >
                                                        All about latest features and product offerings
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column-container">
                                    <a
                                        (click)="redirectToBlog()"
                                        (click)="
                                            showNavigationList = false;
                                            activeColorState = false;
                                            activeColorState = false
                                        "
                                    >
                                        <div class="menu-item">
                                            <div class="flex items-center submenu-mr">
                                                <div class="sub-menu-content">
                                                    <div class="sub-menu-head jiotype-bold-white-16px">
                                                        <img
                                                            src="assets/img/revamp/ic_document.svg"
                                                            alt=""
                                                            class="image-view-content"
                                                        />
                                                        <span class="add-hover">Blogs</span>
                                                    </div>

                                                    <div
                                                        class="
                                                            jiotype-medium-white-14px-2
                                                            text-wrapper-hide
                                                            resources-text-warpper-view
                                                        "
                                                    >
                                                        Your resource for platform and event insights
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column-container">
                                    <a
                                        (click)="redirectToCaseStudies()"
                                        (click)="
                                            showNavigationList = false;
                                            activeColorState = false;
                                            activeColorState = false
                                        "
                                    >
                                        <div class="menu-item">
                                            <div class="flex items-center submenu-mr">
                                                <div class="sub-menu-content">
                                                    <div class="sub-menu-head jiotype-bold-white-16px">
                                                        <img
                                                            src="assets/img/revamp/ic_e_book_reader.svg"
                                                            alt=""
                                                            class="image-view-content"
                                                        />
                                                        <span class="add-hover">Case Studies</span>
                                                        <!-- <span class="text-view-container">
                                                        <span class="custom-txt-view">Coming Soon</span>
                                                    </span> -->
                                                    </div>
                                                    <div
                                                        class="
                                                            sub-menu-desc
                                                            jiotype-medium-white-14px-2
                                                            text-wrapper-hide
                                                            resources-text-warpper-view
                                                        "
                                                    >
                                                        Real-world success narratives powered by JioEvents
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column-container">
                                    <a (click)="navigateToFaqs()" href="#">
                                        <div class="menu-item">
                                            <div class="flex items-center submenu-mr">
                                                <div class="sub-menu-content">
                                                    <div class="sub-menu-head jiotype-bold-white-16px">
                                                        <img
                                                            src="assets/img/revamp/ic_faq.svg"
                                                            alt=""
                                                            class="image-view-content"
                                                        />
                                                        <span class="add-hover">FAQ's</span>
                                                    </div>
                                                    <div
                                                        class="
                                                            sub-menu-desc
                                                            jiotype-medium-white-14px-2
                                                            text-wrapper-hide
                                                            resources-text-warpper-view
                                                        "
                                                    >
                                                        Explore our FAQs section for quick answers to all your questions
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item list-view">
                        <span
                            routerLink="/customer"
                            (click)="closeToggle(headerEvents.CUSTOMERS)"
                            routerLinkActive="active-link"
                            class="nav-link text-animation"
                            href="#"
                            (click)="showNavigationList = false; activeIconState = false; activeColorState = false"
                            >Customers</span
                        >
                    </li>
                </ng-container>
            </ul>
        </div>
        <div *ngIf="!isDashboard" class="form-inline main-header-right mt-2 mt-md-0 show-in-desktop">
            <ng-container *ngIf="isAuthenticated">
                <li class="nav-item dropdown">
                    <a
                        class="nav-link pro-img-cir"
                        href="#"
                        id="navbarUserDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        (click)="activeColorState = false; activeIconState = false"
                    >
                        <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" class="pro-avatar" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarUserDropdown">
                        <div class="view-profile d-flex mobile_profile_wrapper">
                            <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" class="pro-img-cir pro-img" />
                            <div class="details pointer" (click)="navigateToDashboard()">
                                <div class="login-name" *ngIf="!currentUser?.isOTT">
                                    {{ currentUser?.name || '' }} {{ currentUser?.lname || '' }}
                                </div>
                                <div class="login-name" *ngIf="currentUser?.isOTT">
                                    {{
                                        currentUser?.name + ' ' + (currentUser?.lname || '') ||
                                            currentUser?.displayPhoneNo
                                    }}
                                </div>
                                <div class="email" *ngIf="!currentUser?.isOTT">
                                    {{ currentUser?.email }}
                                </div>
                                <div class="email" *ngIf="currentUser?.isOTT">
                                    {{ currentUser?.displayPhoneNo || currentUser?.email }}
                                </div>
                            </div>
                        </div>
                        <div class="view-profile pointer dashboard-wrapper" (click)="openProfile()">
                            <img src="assets/img/revamp/view_profile.svg" alt="" class="icon-wrapper-view" />
                            View Profile
                        </div>
                        <div
                            class="view-profile pointer dashboard-wrapper"
                            (click)="
                                closeToggle(headerEvents.GOTODASHBOARD);
                                navigateToDashboard();
                                showNavigationList = false;
                                activeColorState = false
                            "
                        >
                            <img class="icon-wrapper-view" src="assets/img/revamp/dashboard_Icon.svg" alt="" />
                            Dashboard
                        </div>
                        <div class="pointer" (click)="logout()">
                            <div class="logout view-profile dashboard-wrapper">
                                <img class="icon-wrapper-view" src="assets/img/revamp/sign_out_icon.svg" alt="" />
                                {{ 'landing_or_home_page.header.sign_out' | translate }}
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="!isAuthenticated">
                <li class="nav-item" *ngIf="!showInMobile" (click)="activeColorState = false; activeIconState = false">
                    <span
                        (click)="closeToggle(headerEvents.SIGN_IN); redirectToLogin()"
                        class="jiotype-bold-te-papa-green-18px login-view cursor-pointer"
                    >
                        Login</span
                    >
                </li>
            </ng-container>
            <li
                class="nav-item btn-view-wrapper"
                *ngIf="!showInMobile"
                (click)="activeColorState = false; activeIconState = false"
            >
                <button
                    *ngIf="!isAuthenticated"
                    class="custom-button-revamp btn-primary w-auto btn-height"
                    (click)="redirectToContactUs()"
                >
                    Schedule A Demo
                </button>
            </li>
        </div>
    </div>
    <div [class.bottom-border-view]="showNavigationList"></div>
    <div
        class="navigation-list-wrapper collapse nb-collapse mobile-collapse header-position"
        [ngClass]="{ staticPageHeader: !isStaticPage }"
        id="navbarCollapse"
        *ngIf="isMobile || showInMobile"
        [class.show-view]="!showNavigationList"
        [class.isMobileLandscapeMode]="isMobileLandscapeMode"
    >
        <div class="view-list-container">
            <ul class="navbar-nav mr-auto nav-view">
                <ng-container *ngIf="isAuthenticated && showNavigationList">
                    <li
                        class="nav-item show-in-mobile pro-card"
                        (click)="activeColorState = false; activeIconState = false"
                    >
                        <div
                            id="navbarUserDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            (click)="activeColorState = false; activeIconState = false"
                        >
                            <div class="view-profile d-flex" (outside)="onOutsideClick($event)">
                                <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" class="pro-img-cir pro-img" />
                                <div class="placement-icon-view">
                                    <div class="details pointer">
                                        <div class="login-name" *ngIf="!currentUser?.isOTT">
                                            {{ currentUser?.name || '' }} {{ currentUser?.lname || '' }}
                                        </div>
                                        <div class="login-name" *ngIf="currentUser?.isOTT">
                                            {{
                                                currentUser?.name + ' ' + (currentUser?.lname || '') ||
                                                    currentUser?.displayPhoneNo
                                            }}
                                        </div>
                                        <div class="email" *ngIf="!currentUser?.isOTT">
                                            {{ currentUser?.email }}
                                        </div>
                                        <div class="email" *ngIf="currentUser?.isOTT">
                                            {{ currentUser?.displayPhoneNo || currentUser?.email }}
                                        </div>
                                    </div>
                                    <div>
                                        <img src="assets/img/revamp/profile_dropdown.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="dropdown-menu sub-menu-container card-dropdown"
                            aria-labelledby="navbarDropdown"
                            (click)="activeColorState = false; activeIconState = false"
                        >
                            <div class="view-profile pointer dashboard-wrapper" (click)="openProfile()">
                                <img class="icon-wrapper-view" src="assets/img/revamp/view_profile.svg" alt="" />
                                View Profile
                            </div>
                            <div
                                class="view-profile pointer dashboard-wrapper"
                                (click)="
                                    closeToggle(headerEvents.GOTODASHBOARD);
                                    navigateToDashboard();
                                    showNavigationList = false;
                                    activeColorState = false
                                "
                            >
                                <img class="icon-wrapper-view" src="assets/img/revamp/dashboard_Icon.svg" alt="" />
                                Dashboard
                            </div>
                            <div class="pointer" (click)="logout()">
                                <div class="logout view-profile dashboard-wrapper">
                                    <img src="assets/img/revamp/sign_out_icon.svg" alt="" class="icon-wrapper-view" />
                                    {{ 'landing_or_home_page.header.sign_out' | translate }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="isStaticPage">
                    <li class="nav-item product-list-item">
                        <div
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            class="nav-link"
                            (click)="toggleDropDown('product'); activeColorState = false"
                        >
                            <div class="product-view d-flex" [class.text-animation]="activeIconState">
                                <span class="txt-wrapper text-white-view">Products</span>
                                <div class="rotate" [class.down]="activeIconState">
                                    <img src="assets/img/revamp/product_drop_down.svg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div
                            class="dropdown-menu sub-menu-container"
                            aria-labelledby="navbarDropdown"
                            (click)="activeColorState = false; activeIconState = false"
                        >
                            <div
                                class="column-container"
                                (click)="showNavigationList = false; activeColorState = false; activeIconState = false"
                            >
                                <a routerLink="/solutions" (click)="closeToggle(headerEvents.SOLUTIONS)">
                                    <div class="menu-item">
                                        <div class="flex items-center submenu-mr">
                                            <div class="sub-menu-content">
                                                <div class="sub-menu-head jiotype-bold-white-16px">
                                                    <img
                                                        src="assets/img/revamp/product_drop_down_Icon.svg"
                                                        alt=""
                                                        class="image-view-content"
                                                    />
                                                    <span class="add-hover">JioEvents Platform</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div
                                class="column-container"
                                (click)="showNavigationList = false; activeColorState = false; activeIconState = false"
                            >
                                <div class="menu-item">
                                    <div class="flex items-center submenu-mr">
                                        <div class="sub-menu-content">
                                            <div class="sub-menu-head jiotype-bold-white-16px">
                                                <img
                                                    src="assets/img/revamp/pre_recorded_Icon.svg"
                                                    alt=""
                                                    class="image-view-content"
                                                />
                                                <span class="add-hover studio-view-wrapper">JioEvents Studio</span>
                                                <span class="text-view-container">
                                                    <span class="custom-txt-view">Coming Soon</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        class="nav-item list-view"
                        (click)="
                            closeToggle(headerEvents.SOLUTIONS);
                            showNavigationList = false;
                            activeColorState = false;
                            activeIconState = false
                        "
                    >
                        <span
                            routerLink="/solutions"
                            routerLinkActive="active-link"
                            class="nav-link txt-wrapper text-white-view text-animation"
                            >Solutions
                        </span>
                    </li>
                    <li
                        class="nav-item list-view"
                        (click)="
                            closeToggle(headerEvents.EXPLORE_EVENT);
                            showNavigationList = false;
                            activeColorState = false;
                            activeIconState = false
                        "
                    >
                        <a
                            routerLink="/explore-events"
                            routerLinkActive="active-link"
                            class="nav-link txt-wrapper text-white-view text-animation"
                            >Events</a
                        >
                    </li>

                    <li
                        class="nav-item list-view"
                        (click)="
                            closeToggle(headerEvents.PRICING);
                            showNavigationList = false;
                            activeColorState = false;
                            activeIconState = false
                        "
                    >
                        <span
                            routerLink="/pricing"
                            routerLinkActive="active-link"
                            class="nav-link txt-wrapper text-white-view text-animation"
                            href="#"
                            >Pricing</span
                        >
                    </li>
                    <!-- <li class="nav-item list-view">
                        <span
                            (click)="redirectToBlog()"
                            class="nav-link txt-wrapper text-white-view text-animation"
                            (click)="showNavigationList = false"
                           
                            >Blogs
                        </span>
                    </li> -->
                    <li class="nav-item product-list-item">
                        <div
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            class="nav-link"
                            (click)="toggleDropDown('resource'); activeIconState = false"
                        >
                            <div class="resources-view d-flex" [class.text-animation]="activeColorState">
                                <span class="txt-wrapper color-element text-white-view">Resources </span>
                                <div>
                                    <img
                                        class="rotate"
                                        [class.down]="activeColorState"
                                        src="assets/img/revamp/Resources_drop_down.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="dropdown-menu sub-menu-container" aria-labelledby="navbarDropdown">
                            <div
                                class="column-container"
                                (click)="
                                    redirectToWhatsNew();
                                    showNavigationList = false;
                                    activeColorState = false;
                                    activeIconState = false
                                "
                            >
                                <div>
                                    <div class="menu-item">
                                        <div class="flex items-center submenu-mr">
                                            <div class="sub-menu-content">
                                                <div class="sub-menu-head jiotype-bold-white-16px">
                                                    <img
                                                        src="assets/img/revamp/ic_bulb.svg"
                                                        alt=""
                                                        class="image-view-content"
                                                    />
                                                    <span class="add-hover studio-view-wrapper">What's New</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="column-container"
                                (click)="
                                    redirectToBlog();
                                    showNavigationList = false;
                                    activeColorState = false;
                                    activeIconState = false
                                "
                            >
                                <div>
                                    <div class="menu-item">
                                        <div class="flex items-center submenu-mr">
                                            <div class="sub-menu-content">
                                                <div class="sub-menu-head jiotype-bold-white-16px">
                                                    <img
                                                        src="assets/img/revamp/ic_document.svg"
                                                        alt=""
                                                        class="image-view-content"
                                                    />
                                                    <span class="add-hover">Blogs</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="column-container"
                                (click)="
                                    redirectToCaseStudies();
                                    showNavigationList = false;
                                    activeColorState = false;
                                    activeIconState = false
                                "
                            >
                                <div>
                                    <div class="menu-item">
                                        <div class="flex items-center submenu-mr">
                                            <div class="sub-menu-content">
                                                <div class="sub-menu-head jiotype-bold-white-16px">
                                                    <img
                                                        src="assets/img/revamp/ic_e_book_reader.svg"
                                                        alt=""
                                                        class="image-view-content"
                                                    />
                                                    <span class="add-hover studio-view-wrapper">Case Studies</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="column-container"
                                (click)="
                                    navigateToFaqs();
                                    showNavigationList = false;
                                    activeColorState = false;
                                    activeIconState = false
                                "
                            >
                                <div>
                                    <div class="menu-item">
                                        <div class="flex items-center submenu-mr">
                                            <div class="sub-menu-content">
                                                <div class="sub-menu-head jiotype-bold-white-16px">
                                                    <img
                                                        src="assets/img/revamp/ic_faq.svg"
                                                        alt=""
                                                        class="image-view-content"
                                                    />
                                                    <span class="add-hover studio-view-wrapper">FAQ's</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        class="nav-item list-view"
                        (click)="
                            closeToggle(headerEvents.CUSTOMERS); showNavigationList = false; activeColorState = false
                        "
                    >
                        <span
                            routerLink="/customer"
                            routerLinkActive="active-link"
                            class="nav-link txt-wrapper text-white-view text-animation"
                            href="#"
                            >Customers</span
                        >
                    </li>
                </ng-container>
            </ul>
        </div>
        <div *ngIf="!isAuthenticated && showInMobile" class="collapse-divider">
            <button
                *ngIf="!isAuthenticated"
                class="custom-button-revamp btn-primary w-auto btn-height"
                (click)="showNavigationList = false; redirectToContactUs()"
            >
                Schedule A Demo
            </button>
            <button
                (click)="closeToggle(headerEvents.SIGN_IN); redirectToLogin()"
                class="custom-button-revamp login-btn w-auto btn-height login-view"
                (click)="showNavigationList = false"
            >
                Login
            </button>
        </div>
        <div *ngIf="!isDashboard" class="form-inline main-header-right mt-2 mt-md-0 show-in-desktop">
            <ng-container *ngIf="isAuthenticated">
                <li class="nav-item dropdown" (click)="activeColorState = false; activeIconState = false">
                    <a
                        class="nav-link pro-img-cir"
                        href="#"
                        id="navbarUserDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" class="pro-avatar" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarUserDropdown">
                        <div class="view-profile d-flex view-wrapper">
                            <img
                                [src]="profilePicPath"
                                alt=""
                                *ngIf="profilePicPath"
                                class="pro-img-cir pro-img"
                                style="margin-right: 12px"
                            />
                            <div class="details pointer" (click)="navigateToDashboard()">
                                <div class="login-name login_wrapper" *ngIf="!currentUser?.isOTT">
                                    {{ currentUser?.name || '' }} {{ currentUser?.lname || '' }}
                                </div>
                                <div class="login-name login_wrapper" *ngIf="currentUser?.isOTT">
                                    {{
                                        currentUser?.name + ' ' + (currentUser?.lname || '') ||
                                            currentUser?.displayPhoneNo
                                    }}
                                </div>
                                <div class="email email_wrapper" *ngIf="!currentUser?.isOTT">
                                    {{ currentUser?.email }}
                                </div>
                                <div class="email email_wrapper" *ngIf="currentUser?.isOTT">
                                    {{ currentUser?.displayPhoneNo || currentUser?.email }}
                                </div>
                            </div>
                        </div>
                        <div class="view-profile pointer logout_wrapper" (click)="openProfile()">
                            <img src="assets/img/revamp/view_profile.svg" alt="" class="sign_out_wrapper" />
                            View Profile
                        </div>
                        <div
                            class="view-profile pointer logout_wrapper"
                            (click)="navigateToProfile()"
                            (click)="
                                closeToggle(headerEvents.GOTODASHBOARD);
                                navigateToDashboard(null);
                                showNavigationList = false;
                                activeColorState = false
                            "
                        >
                            <img src="assets/img/revamp/dashboard_Icon.svg" alt="" class="sign_out_wrapper" />
                            Dashboard
                        </div>
                        <div class="pointer" (click)="logout()">
                            <div class="logout view-profile logout_wrapper">
                                <img src="assets/img/revamp/sign_out_icon.svg" alt="" class="sign_out_wrapper" />
                                {{ 'landing_or_home_page.header.sign_out' | translate }}
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="!isAuthenticated">
                <li class="nav-item" *ngIf="!showInMobile" (click)="activeColorState = false; activeIconState = false">
                    <a
                        (click)="closeToggle(headerEvents.SIGN_IN); redirectToLogin()"
                        (keyup.enter)="closeToggle(headerEvents.SIGN_IN); redirectToLogin()"
                        class="nav-link txt-wrapper cursor-pointer nav-btn-text custom-color-view"
                        >Login</a
                    >
                </li>
                <li class="nav-item" *ngIf="!showInMobile" (click)="activeColorState = false; activeIconState = false">
                    <button
                        (click)="closeToggle(headerEvents.SIGN_IN); redirectToContactUs()"
                        class="nav-link txt-wrapper nav-btn-text custom-button-revamp w-auto btn-primary landscape-view"
                    >
                        Schedule A Demo
                    </button>
                </li>
            </ng-container>
        </div>
    </div>
</nav>

<ng-template #dropdownTemplate>
    <li class="custom-dropdown-menu" outside (outside)="isDropdownOpen = false">
        <a
            class="nav-link pro-img-cir mobile-only"
            href="#"
            id="navbarUserDropdown_home"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="toggleDropdown()"
        >
            <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" class="pro-avatar" />
        </a>
        <div class="dropdown-menu-footer p-0" [class.show]="isDropdownOpen" aria-labelledby="navbarUserDropdown">
            <div *ngIf="utilService.isMobileView">
                <button class="close-icon" id="closeDropdownMobile" (click)="toggleDropdown()">
                    <img src="assets/img/jioconf/close-icon.svg" alt="" />
                </button>
            </div>
            <div class="profile-container">
                <div class="user-profile-details">
                    <div class="img-container">
                        <img class="profile-img" [src]="profilePicPath" alt="" *ngIf="profilePicPath" />
                    </div>
                    <div class="profile-details">
                        <div class="details pointer" (click)="navigateToDashboard()">
                            <div
                                class="login-name text-truncate"
                                pTooltip="{{ currentUser | userInfo: 'name' }}"
                                tooltipPosition="bottom"
                            >
                                {{ currentUser | userInfo: 'name' }}
                            </div>
                            <!-- <div class="login-name text-truncate" *ngIf="currentUser?.isOTT">
                                {{
                                    currentUser?.name + ' ' + (currentUser?.lname || '') || currentUser?.displayPhoneNo
                                }}
                            </div> -->
                            <div
                                class="email text-truncate"
                                pTooltip="{{ currentUser | userInfo: 'email' }}"
                                pTooltip="{{ currentUser | userInfo: 'email' }}"
                                tooltipPosition="bottom"
                            >
                                {{ currentUser | userInfo: 'email' }}
                            </div>
                            <!-- <div class="email text-truncate" *ngIf="currentUser?.isOTT">
                                {{ currentUser?.displayPhoneNo || currentUser?.email }}
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="buttons d-flex">
                    <div class="pointer w-100">
                        <button class="custom-button profile-view" id="openProfileButtonHome" (click)="openProfile()">
                            View Profile
                        </button>
                    </div>
                    <div class="dropdown-footer pointer w-100" (click)="logout()">
                        <button class="logout w-100" id="signOutButtonHome">
                            <img class="mr-3" alt="sign_out" src="assets/img/sign_out.svg" />{{
                                'landing_or_home_page.header.sign_out' | translate
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </li>
</ng-template>

<div class="background-overlay" *ngIf="isDropdownOpen && isMobile"></div>
<app-profile-details
    *ngIf="showProfile"
    [isDashboard]="isDashboard"
    (showprofiledetails)="closeProfile()"
></app-profile-details>
