import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { AuthService, UserService, AppService, MeetingService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class WebinarSettingGuard implements CanActivate {
    currentUser;
    constructor(
        private router: Router,
        private authService: AuthService,
        private meetingService: MeetingService,
        private toastrService: ToastrService,
        private webinarService: WebinarService,
        private userService: UserService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authService.getIsAuthenticated()) {
            // only allow host or cohost of the meeting to access this route
            const webinarId = route?.params?.id;
            return this.meetingService.getMeetingDetails(webinarId).pipe(
                map((res: any) => {
                    this.currentUser = this.userService.getUserSync();
                    const isAdHocCoHost = this.webinarService.checkForAdHocCoHost(
                        res?.speakers || [],
                        this.currentUser
                    );
                    if (isAdHocCoHost && !state.url.includes('sessions')) {
                        this.router.navigate([`events/details/${webinarId}/sessions`]);
                    }
                    return true;
                }),
                catchError((err, caught): Observable<boolean> => {
                    this.toastrService.error(err.error.errors);
                    this.router.navigate(['dashboard']);
                    return of(false);
                })
            );
        } else {
            return of(false);
        }
    }
}
