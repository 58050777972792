import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-dropdown-options',
    templateUrl: './dropdown-options.component.html',
    styleUrls: ['./dropdown-options.component.scss']
})
export class DropdownOptionsComponent implements OnInit {
    @Input() options: any[] = [];
    @Input() isMultiDay: boolean = false;
    @Input() isOngoing: any[] = [];
    @Output() onSelectedMenu: EventEmitter<any> = new EventEmitter();
    @Input()
    eventId;
    @Input() isAnalyticsEnabled: boolean = false;

    isMobile: boolean;

    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isResponsiveMobileView();
        const analyticsOptionExists = this.options.some((opt) => opt.value === 'eventAnalytics');
        if (this.isMultiDay && !analyticsOptionExists && this.isAnalyticsEnabled) {
            this.options.push({ label: 'View Analytics', value: 'eventAnalytics', disabled: false });
        }
        this.updateOptions();
    }

    updateOptions(): void {
        const analyticsOption = this.options.find((opt) => opt.value === 'eventAnalytics');
        if (analyticsOption) {
            analyticsOption.disabled = !this.isOngoing;
        }
    }

    handleSelectedMenu($event, option: any) {
        this.onSelectedMenu.emit({ event: $event, option });
    }
}
