import { Component, Input, OnInit, ElementRef, Renderer2, HostListener } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-faq-questions',
    templateUrl: './faq-questions.component.html',
    styleUrls: ['./faq-questions.component.scss']
})
export class FaqQuestionsComponent implements OnInit {
    @Input() faqs: any[] = [];
    @Input() selectedLink: string = '';
    isMobile = false;

    constructor(private utilService: UtilService, private renderer: Renderer2, private el: ElementRef) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isResponsiveMobileView();
        this.faqs.forEach((faq) => (faq.isActive = false));
    }

    toggleAccordion(index: number) {
        this.closeAllExcept(index);
        this.faqs[index].isActive = !this.faqs[index].isActive;
        const accordionElement = this.el.nativeElement.querySelectorAll('.faq-accordion')[index];
        const answerElement = accordionElement.querySelector('.faq-answer');

        if (this.faqs[index].isActive) {
            this.renderer.setStyle(answerElement, 'max-height', 'none');
            this.renderer.setStyle(answerElement, 'opacity', '1');
        } else {
            this.renderer.setStyle(answerElement, 'max-height', '0');
            this.renderer.setStyle(answerElement, 'opacity', '0');
        }
    }

    closeAllExcept(exceptIndex: number = -1) {
        this.faqs.forEach((faq, i) => {
            if (i !== exceptIndex && faq.isActive) {
                faq.isActive = false;
                const accordionElement = this.el.nativeElement.querySelectorAll('.faq-accordion')[i];
                const answerElement = accordionElement.querySelector('.faq-answer');
                this.renderer.setStyle(answerElement, 'max-height', '0');
                this.renderer.setStyle(answerElement, 'opacity', '0');
            }
        });
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        const clickedElement = event.target as HTMLElement;
        if (!clickedElement.closest('.faq-accordion')) {
            this.closeAllExcept();
        }
    }
}
