<div class="live-chat-container">
    <div class="chat-header">
        <div class="chat-header-title">Live Chat</div>
        <div class="chat-close-btn" (click)="closeLiveChat()"></div>
    </div>
    <div class="top-divider"></div>
    <ng-container *ngIf="liveMessages.length">
        <div class="pin-chat-container" *ngIf="!showPinedArea && pinedMessages.length && !isMobileOS">
            <app-chat-bubble
                [isHost]="currentParticipant?.isHost || currentParticipant?.isCoHost"
                [pinnedMessageLength]="pinedMessages.length"
                (moreOptionEvent)="moreOptionAction($event)"
                [ownMsg]="currentParticipant?.userId == pinedMessages[0]?.createdBy"
                [messageData]="pinedMessages[0]"
                [meetingId]="meetingObj?.meetingId"
                [isPined]="true"
                (click)="showAllPinedMessages()"
            ></app-chat-bubble>
        </div>
        <div class="pin-chat-container all-pin-chat-area" *ngIf="showPinedArea">
            <div (click)="hideAllPinedMessages()" class="back-btn-pin">
                <img src="assets/images/event-call-options/pin_area_back.svg" />Back
            </div>
            <ng-container *ngFor="let msg of pinedMessages; trackBy: trackByFn">
                <app-chat-bubble
                    [isHost]="currentParticipant?.isHost || currentParticipant?.isCoHost"
                    (moreOptionEvent)="moreOptionAction($event)"
                    [ownMsg]="currentParticipant?.userId == msg.createdBy"
                    [messageData]="msg"
                    [meetingId]="meetingObj?.meetingId"
                    [isPined]="true"
                ></app-chat-bubble>
            </ng-container>
        </div>
        <div
            class="main-chat-container"
            #livechatContainer
            id="chat-room-area"
            *ngIf="!showPinedArea"
            (scroll)="listScroll($event)"
        >
            <ng-container *ngIf="isDataSourceEnabled">
                <div
                    *ngIf="dataSourceFirstSeq > 1"
                    class="load_old_Messages"
                    [class.isLoading]="loadingOldMessages"
                    (click)="loadOldmessages()"
                >
                    <span>{{ loadingOldMessages ? 'Loading....' : 'Load Previous Messages' }}</span>
                </div>
                <ng-container *uiScroll="let msg of datasource; let idx = index">
                    <div
                        class="long-pressed-chat-backdrop"
                        [class.safari-long-press-backdrop]="isSafari"
                        *ngIf="longPressedChatId == msg._id"
                    ></div>
                    <ng-container *ngIf="!deletedMessages.hasOwnProperty(msg._id)">
                        <app-chat-bubble
                            (outside)="clearDeleteSelection(msg._id)"
                            [isHost]="currentParticipant?.isHost || currentParticipant?.isCoHost"
                            (moreOptionEvent)="moreOptionAction($event)"
                            [ownMsg]="currentParticipant?.userId == msg.createdBy"
                            [messageData]="msg"
                            [isLongPressedSelected]="longPressedChatId == msg._id"
                            [pinnedMessageLength]="pinedMessages.length"
                            [meetingId]="meetingObj?.meetingId"
                            [class.longPressedChat]="longPressedChatId == msg._id"
                            [class.safari-longpressedChat]="longPressedChatId == msg._id && isSafari"
                            [id]="'chat-' + msg._id"
                        ></app-chat-bubble>
                        <span
                            [id]="msg._id"
                            [class.safari-chat-bottom]="isSafari && longPressedChatId == msg._id && isSafari"
                            [class.longPressedChatBottom]="longPressedChatId == msg._id"
                        ></span>
                        <div *ngIf="showLastUnreadLabel && lastMessageId == msg._id" class="lasr-read text">
                            <div class="last-msg-txt-wrapper">New Messages</div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!isDataSourceEnabled">
                <div
                    *ngIf="dataSourceFirstSeq > 1"
                    class="load_old_Messages"
                    [class.isLoading]="loadingOldMessages"
                    (click)="loadOldmessages()"
                >
                    <span>{{ loadingOldMessages ? 'Loading....' : 'Load Previous Messages' }}</span>
                </div>
                <ng-container *ngFor="let msg of liveMessages; let idx = index">
                    <div
                        class="long-pressed-chat-backdrop"
                        [class.safari-long-press-backdrop]="isSafari"
                        *ngIf="longPressedChatId == msg._id"
                    ></div>
                    <ng-container *ngIf="!deletedMessages.hasOwnProperty(msg._id)">
                        <app-chat-bubble
                            (outside)="clearDeleteSelection(msg._id)"
                            [isHost]="currentParticipant?.isHost || currentParticipant?.isCoHost"
                            (moreOptionEvent)="moreOptionAction($event)"
                            [ownMsg]="currentParticipant?.userId == msg.createdBy"
                            [messageData]="msg"
                            [isLongPressedSelected]="longPressedChatId == msg._id"
                            [pinnedMessageLength]="pinedMessages.length"
                            [meetingId]="meetingObj?.meetingId"
                            [class.longPressedChat]="longPressedChatId == msg._id"
                            [class.safari-longpressedChat]="longPressedChatId == msg._id && isSafari"
                            [id]="'chat-' + msg._id"
                        ></app-chat-bubble>
                        <span
                            [id]="msg._id"
                            [class.safari-chat-bottom]="isSafari && longPressedChatId == msg._id && isSafari"
                            [class.longPressedChatBottom]="longPressedChatId == msg._id"
                        ></span>
                        <div *ngIf="showLastUnreadLabel && lastMessageId == msg._id" class="lasr-read text">
                            <div class="last-msg-txt-wrapper">New Messages</div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div id="userMessageLoader-container" *ngIf="userOwnMsg.length">
                <div class="msgloader">
                    <div class="msgloader--dot"></div>
                    <div class="msgloader--dot"></div>
                    <div class="msgloader--dot"></div>
                    <div class="msgloader--dot"></div>
                    <div class="msgloader--dot"></div>
                    <div class="msgloader--dot"></div>
                </div>
            </div>
            <ng-container *ngFor="let msg of userOwnMsg">
                <app-user-empty-message [messageData]="msg"></app-user-empty-message>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="!liveMessages.length">
        <div class="main-chat-container center-content">
            <div class="empty-chat-container">
                <img src="assets/images/empty_chat_panel.svg" alt="" />
                <span class="empty-chat-text">Interact with the speakers and attendees in the live chat</span>
            </div>
        </div>
    </ng-container>
    <div
        *ngIf="showFloatNewMessage && !loadingOldMessages"
        class="newMessageFloatbtn"
        (click)="newMessageFloatingBtnClick()"
    >
        <img src="assets/images/livechat_new_msg.svg" class="new_msg" />
        <span>New Messages</span>
    </div>
    <div class="main-chat-footer" *ngIf="!showPinedArea" (outside)="emojiOutsideClick()">
        <div *ngIf="selectedEmoji" class="emoji-container">
            <app-emoji-box [isNewLayout]="true" (onEmojiSelect)="insertEmoji($event)"></app-emoji-box>
        </div>

        <div class="chat-input-area" (click)="hideEmojiPanel()">
            <app-chat-markdown [showMarkdown]="showMarkdown" [isusedLiveChat]="true" (sendmessage)="sendMessage()">
            </app-chat-markdown>
        </div>
        <div class="chat-input-controls chat-options-area">
            <label for="emoji" class="pointer ml-3 emoji-switch" [class.selected]="selectedEmoji" *ngIf="showEmojiBar">
                <img
                    src="assets/images/event-call-options/toolbar_emoji_icon.svg"
                    id="emoji-btn"
                    (click)="toggleEmojis($event)"
                />
            </label>
            <label for="count-text" class="text-count-area">
                <div *ngIf="this.chatMarkdownComponent?.quill" class="text-right limit">
                    {{ this.chatMarkdownComponent?.quill.getText().replace('\n', '')?.length }}/{{ maxLength }}
                </div>
            </label>
        </div>
    </div>
</div>
