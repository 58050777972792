export enum EventDataSource {
    WEBSOCKET = 'WEBSOCKET',
    POLLING = 'POLLING'
}

export enum EventUserRole {
    SPEAKER = 'Speaker',
    COHOST = 'Co-Host',
    SESSION_SPEAKER = 'Session Speaker',
    SESSION_COHOST = 'Session Co-Host',
    HOST = 'Host',
    ATTENDEE = 'Attendee'
}

export enum EventStatus {
    ONGOING = 'Ongoing',
    COMPLETED = 'Completed',
    UPCOMING = 'Upcoming',
    ABOUT_TO_START = 'About to start',
    ENDED = 'ended',
    CANCELLED = 'cancelled',
    ACTIVE = 'active'
}

export enum EventViewSection {
    MYEVENTS = 'my-events',
    ATTENDING = 'attending',
    PAST = 'past',
    DASHBOARD = 'dashboard',
    EXPLORE = 'explore',
    UPCOMING = 'upcoming',
    CALENDARVIEW = 'calendarview'
}
export enum ConfirmPopUpActionEnum {
    CROSS_BTN_CLICK = 2,
    REJECT_BTN_CLICK = 1
}
