import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-booth-tile',
    templateUrl: './booth-tile.component.html',
    styleUrls: ['./booth-tile.component.scss']
})
export class BoothTileComponent implements OnInit, AfterViewInit {
    @Input() usageArea;
    @Input() boothData;
    @Input() boothIndex;
    @Output() selectedBoothIndex = new EventEmitter();
    @Output() boothTileAction = new EventEmitter();

    showBoothInfo = false;
    isWebinarSettings: boolean = true;
    viewBoothAnalyticsPopup = false;
    socialLink: string;
    isImageCheck = true;
    isMobile = false;
    boothId: any;

    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isWebinarSettings = this.usageArea === 'settings';
        if (this.utilService.isMobileBrowser()) {
            this.isMobile = true;
        }
        this.isImageOrVideo();
    }

    ngAfterViewInit(): void {
        const currentUrl = new URL(window.location.href);
        const params = new URLSearchParams(currentUrl.search);
        this.boothId = params.get('boothId');
        if (this.boothId) {
            document.getElementById(this.boothId).click();
        }
    }

    sendSelectedBoothIndex(id) {
        this.selectedBoothIndex.emit(this.boothIndex);
        let currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('boothId', id);
        window.history.replaceState({}, '', currentUrl.toString());
    }

    openAnalyticsPopup() {
        this.viewBoothAnalyticsPopup = true;
    }

    editBooth() {
        this.emitBoothTileAction({
            event: 'editBooth',
            data: null
        });
    }

    emitBoothTileAction(event = null) {
        this.boothTileAction.emit(event);
    }

    isImageOrVideo() {
        if (this.boothData?.attributes?.banner?.data?.attributes?.url.includes('mp4')) {
            this.isImageCheck = false;
        }
    }
}
