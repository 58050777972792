import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { AddonsService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class UserDetailsGuard implements CanActivate {
    constructor(private userService: UserService, private addOnService: AddonsService, private router: Router) {}

    async canActivate(): Promise<boolean> {
        try {
            const user = await this.userService.getUser(true).toPromise();
            let userDetails: any;
            userDetails = await this.addOnService.getExistingUserDetails(user?._id).toPromise();
            const hasDetails = !!(
                userDetails?.data?.attributes?.profilePicture?.data || userDetails?.data?.attributes?.funFact
            );

            if (hasDetails) {
                this.router.navigate(['/dashboard']); // Redirect to dashboard if user details exist
                return false; // Prevent navigation to the original route
            }
            return true; // Allow navigation if user details do not exist
        } catch (err) {
            return true; // Allow navigation if there is an error fetching user details
        }
    }
}
