<div class="card-panel" *ngIf="!showMiniBar">
    <div class="call-info-header">
        <div>
            <div>Upload and present video</div>
            <div>Upload videos to play anytime during the event</div>
        </div>
        <img
            class="pointer"
            [class.invert-icon]="!showMiniBar"
            src="assets/images/speaker-call-wrapper/chevron_up_arrow_call_controls.svg"
            (click)="toggleMiniPreview()"
            id="toggleInvertIocn"
        />
        <img
            class="pointer"
            src="assets/images/close_icon_opaque.svg"
            (click)="closeModal($event)"
            id="toggleCloseTabBtn"
        />
    </div>
    <section class="content">
        <div
            class="upload-section pointer"
            appDragDrop
            (filesDropped)="handleDropFileEvent($event)"
            (click)="fileUpload.click()"
            id="toggleUploadBtnControl"
        >
            <img src="assets/images/share-media/upload_cloud_icon.svg" alt="Upload Image " />
            <span>
                <input
                    #fileUpload
                    type="file"
                    (click)="fileUpload.value = null"
                    style="display: none"
                    accept="video/mp4"
                    (change)="fileChangeEvent($event)"
                />
                <div class="upload-btn-text">
                    {{ 'webinar-content.upload' | translate }}
                </div>
            </span>
        </div>
        <span class="d-flex">
            <div class="note">Recommended file type/resolution</div>
            <div class="d-flex align-items-center ml-2">
                <img
                    src="assets/img/vector-filled-info-icon.svg"
                    class="icon_info"
                    [pTooltip]="videoToolTipMessage"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-style"
                />
            </div>
        </span>
        <div>&nbsp;</div>
        <div>
            <div>Or add a video URL to present video</div>
            <div style="display: flex; margin-top: 10px">
                <input
                    type="text"
                    [(ngModel)]="videoUrl"
                    (ngModelChange)="validateUrl()"
                    class="input-style"
                    [ngClass]="{ 'invalid-url': invalidUrl && showErrorMessage }"
                    placeholder=" Paste video URL here"
                />
                <button (click)="addVideo()" [disabled]="buttonDisabled || !videoUrl" class="bg-login btn-style">
                    Add
                </button>
            </div>
            <div *ngIf="showErrorMessage" class="warning-error">
                <img style="object-fit: contain" class="pointer" src="assets/images/warning_icon.svg" />
                <div style="color: #f50031; margin-left: 11px; width: 82%">{{ invalidUrlError }}</div>
            </div>
        </div>
        <div class="video-url-list">
            <div class="video-list mt-2" *ngIf="mediaFiles?.length">
                <ng-container *ngFor="let media of mediaFiles; let i = index">
                    <div class="video" [class.show-top-border]="i !== 0">
                        <div class="progress" *ngIf="!media.uploadSuccess">
                            <span class="progress-left">
                                <span
                                    class="progress-bar border-danger"
                                    style="--first-rot: {{ media.progress < 50 ? 0 : (media.progress - 50) * 3.6 }}deg"
                                ></span>
                            </span>
                            <span class="progress-right">
                                <span
                                    class="progress-bar border-danger"
                                    style="--second-rot: {{ media.progress < 50 ? media.progress * 3.6 : '180' }}deg"
                                ></span>
                            </span>
                        </div>
                        <img
                            src="assets/images/share-media/green_tick_icon.svg"
                            *ngIf="media.uploadSuccess || media.status"
                        />
                        <!-- <img src="assets/images/share-media/cross_red_icon.svg"> -->
                        <div class="ml-3 mr-auto text-truncate video-title">
                            {{ media?.originalName }}
                        </div>
                        <div class="cloud-video-actions">
                            <img
                                class="pointer"
                                src="assets/images/green_close_icon.svg"
                                alt="Cancel Icon"
                                *ngIf="!media.uploadSuccess"
                                (click)="confirmCancelUpload(media)"
                                id="CancelUploadVideoBtn"
                            />
                            <button
                                class="bg-login"
                                (click)="confirmShareMediaRequest(media.mediaId)"
                                *ngIf="media.uploadSuccess"
                                id="togglePresentBtn"
                            >
                                <img
                                    class="pointer"
                                    src="assets/images/share-media/present_cloud_play.svg"
                                    alt="Present-icon"
                                    *ngIf="media.uploadSuccess"
                                />Present
                            </button>
                            <img
                                class="pointer"
                                src="assets/images/share-media/delete_cloudvideo_icon.svg"
                                alt="Delete-icon"
                                *ngIf="media.uploadSuccess"
                                (click)="deleteMediaFile(media)"
                                id="toggleDeleteBtnControl"
                            />
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="video-list mt-2" *ngIf="videoUrlFile?.length && uploadVideo">
                <ng-container *ngFor="let video of videoUrlFile; let i = index">
                    <div class="video" [class.show-top-border]="i !== 0 || mediaFiles?.length > 0">
                        <img src="assets/images/share-media/green_tick_icon.svg" *ngIf="uploadVideo" />
                        <div class="ml-3 mr-auto text-truncate video-title">{{ video.url }}</div>
                        <div class="cloud-video-actions">
                            <button
                                class="bg-login"
                                (click)="confirmShareMediaRequest(video.mediaId)"
                                id="togglePresentBtn"
                            >
                                <img
                                    class="pointer"
                                    src="assets/images/share-media/present_cloud_play.svg"
                                    alt="Present-icon"
                                />Present
                            </button>
                            <img
                                class="pointer"
                                src="assets/images/share-media/delete_cloudvideo_icon.svg"
                                alt="Delete-icon"
                                (click)="deleteVideoUrl(video.mediaId)"
                                id="DeleteBtnControl"
                            />
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- <div class="video-list text-center py-3" *ngIf="!mediaFiles?.length">No Videos to Select</div> -->
    </section>
</div>

<div class="card-panel mini-bar" *ngIf="showMiniBar">
    <div class="d-flex align-items-center">
        <span class="uploading-title">{{
            uploadingFiles.length === 1
                ? uploadingFiles[0].originalName
                : 'Uploading ' + uploadingFiles.length + 'of ' + mediaFiles.length
        }}</span>
        <span>{{ uploadingFiles[0].progress }}%</span>
        <img class="pointer" [class.invert-icon]="!showMiniBar" pointer invert-icon (click)="toggleMiniPreview()" />
    </div>
    <div class="progress-horizontal" *ngIf="!uploadingFiles[0].uploadSuccess">
        <div class="progress-bar" role="progressbar" [style.width.%]="uploadingFiles[0].progress || 0"></div>
    </div>
</div>

<!-- <div class="popup-component">
    <div class="bg"></div>
    <div class="popup">
        <div class="popup-header position-relative">
            <div class="title">Upload Video</div>
            <div class="close" (click)="closeModal($event)">
                <img src="assets/images/close_icon_opaque.svg" alt="" />
            </div>
        </div>
        <section class="content">
            <div class="upload-section" appDragDrop (filesDropped)="handleDropFileEvent($event)">
                <div class="wrapper">
                    <span>
                        <img src="assets/img/webinar/side-nav/Upload-drag&Drop.svg" alt="Upload Image " />
                    </span>
                    <span class="ml-2 dnd">
                        {{ 'webinar-content.drag_drop' | translate }}
                    </span>
                    <span class="video-note">
                        {{ 'webinar-content.only_mp4' | translate }}
                    </span>
                    <div class="separator"></div>
                    <span class="mx-5 separator" *ngIf="mediaFiles?.length">
                        {{ 'webinar-content.or' | translate }}
                    </span>
                    <span>
                        <input
                            #fileUpload
                            type="file"
                            (click)="fileUpload.value = null"
                            style="display: none"
                            accept="video/mp4"
                            (change)="fileChangeEvent($event)"
                        />
                        <button class="custom-button bg-secondary btn-upload" (click)="fileUpload.click()">
                            {{ 'webinar-content.upload' | translate }}
                        </button>
                    </span>
                </div>
            </div>
            <span class="note"
                >• File Type: .mp4 video files • Video Resolution: Upto 1280*720p • File Size: Upto
                {{ cloudVideoMaxSize }}MB</span
            >
            <div class="pt-2">Your videos list (Choose and click Share to play)</div>
            <div class="video-list mt-2" *ngIf="mediaFiles?.length">
                <div *ngFor="let media of mediaFiles" class="video">
                    <label for="media_{{ media.mediaId }}">
                        <input
                            id="media_{{ media.mediaId }}"
                            [value]="media.mediaId"
                            type="radio"
                            name="media"
                            [(ngModel)]="mediaSelected"
                            *ngIf="media.uploadSuccess"
                            class="ml-1"
                        />
                        <div class="ml-3 text-truncate">
                            {{ media?.originalName }}
                        </div>
                    </label>
                    <div class="progress-wrapper">
                        <div class="progress" *ngIf="!media.uploadSuccess">
                            <div class="progress-bar" role="progressbar" [style.width.%]="media.progress || 0"></div>
                        </div>
                    </div>
                    <div class="img-wrapper">
                        <img
                            class="pointer"
                            src="assets/img/webinar/side-nav/icon-Delete.svg"
                            alt="Delete-icon"
                            *ngIf="media.uploadSuccess"
                            (click)="deleteMediaFile(media)"
                        />
                        <img
                            class="pointer"
                            src="assets/img/webinar/close-circle-large.svg"
                            alt="Cancel Icon"
                            *ngIf="!media.uploadSuccess"
                            (click)="cancelUpload(media)"
                        />
                    </div>
                </div>
            </div>

            <div class="video-list text-center py-3" *ngIf="!mediaFiles?.length">No Videos to Select</div>
        </section>
        <section class="footer px-5 mt-3">
            <div class="footer-btn-grp">
                <button class="custom-button bg-login-invert mr-3" (click)="closeModal($event)">Cancel</button>
                <button
                    type="button"
                    class="custom-button bg-login"
                    [class.disabled]="!mediaSelected"
                    (click)="shareMedia()"
                >
                    Share
                </button>
            </div>
        </section>
    </div>
</div> -->
