import { Injectable } from '@angular/core';

interface ICSEvent {
    scheduledStartTime: Date;
    scheduledEndTime?: Date;
    topic: string;
    description?: string;
    location?: string;
    url?: string;
}
@Injectable({
    providedIn: 'root'
})
export class IcsGeneratorService {
    VCALENDAR = `BEGIN:VCALENDAR
  PRODID:-//Events Calendar//HSHSOFT 1.0//DE
  VERSION:2.0`;

    constructor() {}

    buildEventData(events: ICSEvent[]) {
        for (const event of events) {
            const timeStamp = this.formatDate(new Date());
            const uuid = `${timeStamp}Z-uid@hshsoft.de`;
            /**
             * Don't ever format this string template!!!
             */
            const EVENT = `BEGIN:VEVENT
  DTSTAMP:${timeStamp}Z
  DTSTART:${this.formatDate(event.scheduledStartTime)}
  DTEND:${this.formatDate(event.scheduledEndTime)}
  SUMMARY:${event.topic}
  DESCRIPTION:${event.description}
  LOCATION:${event.location}
  URL:${event.url}
  UID:${uuid}
  END:VEVENT`;
            this.VCALENDAR += `${EVENT}
  `;
        }
        this.VCALENDAR += `END:VCALENDAR`;
        return this.VCALENDAR;
    }

    formatDate(date: Date): string {
        if (!date) {
            return '';
        }
        // don't use date.toISOString() here, it will be always one day off (cause of the timezone)
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return `${year}${month}${day}T${hour}${minutes}${seconds}`;
    }

    DownloadIcs(events: ICSEvent[], filename: string) {
        // build ICS DATA
        this.buildEventData(events);
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.VCALENDAR));
        element.setAttribute('download', filename);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        element.click();
    }
}
