import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EventUserRole } from 'src/app/constants/webinar-enum';
import { RestService, AppService, ProfileService } from '.';

@Injectable({
    providedIn: 'root'
})
export class MeetingInviteService {
    constructor(
        private restService: RestService,
        private appService: AppService,
        public profileService: ProfileService
    ) {}

    getMeetingInvites(meetingId, { offset = 0, keyword = '', limit = 100, role = '' }) {
        let adHocRole = '';
        if (role)
            adHocRole = role === EventUserRole.COHOST ? EventUserRole.SESSION_COHOST : EventUserRole.SESSION_SPEAKER;

        let queryParamString = `offset=${offset}&limit=${limit}&keyword=${keyword}`;
        if (role) {
            queryParamString += `&role=${role}&role=${adHocRole}`;
        }
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/${meetingId}?${queryParamString}`)
            .pipe(
                map((res) => {
                    res.invitesList.forEach((user) => {
                        user.name = this.profileService.getFullName(user.firstName, user.lastName);
                        user.identity = user?.emailId || user?.phoneNo;
                    });
                    return {
                        invitesCount: res.invitesCount,
                        invitesList: res.invitesList
                    };
                })
            );
    }

    getDefaultTemplates() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/sessions/break/defaulttemplates`);
    }

    createMeetingInvite(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/meetingInvite`, payload);
    }

    updateMeetingInvite(meetingInviteId, payload, hardUpdate = false) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/${meetingInviteId}?hardupdate=${hardUpdate}`,
            payload
        );
    }

    deleteMeetingInvite(meetingId, meetingInviteIds) {
        return this.restService.delete(`${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/${meetingId}`, {
            body: { meetingInviteIds }
        });
    }

    downloadMeetingInvites(meetingId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/${meetingId}/downloadlist`
        );
    }

    uploadInviteeProfilePic(meetingInviteId, file): Observable<any> {
        const formData = new FormData();
        formData.append('image', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/${meetingInviteId}/uploadinviteeprofilepic`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    resendAttendeeLink(meetingInviteId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/${meetingInviteId}/sendInvitation`,
            {}
        );
    }

    createSession(payload, meetingId) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/sessions/${meetingId}`, payload);
    }

    createBreak(payload, meetingId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${meetingId}/break`,
            payload
        );
    }

    updateSession(payload, meetingId, sessionId) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${meetingId}/${sessionId}`,
            payload
        );
    }

    updateBreak(payload, meetingId, sessionId) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${meetingId}/break/${sessionId}`,
            payload
        );
    }

    uploadSessionBgTemplate(meetingId, file): Observable<any> {
        const formData = new FormData();
        formData.append('image', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${meetingId}/break/uploadbackgroundtemplate`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    checkSessionOverlap(meetingId: string, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${meetingId}/sessionOverlap`,
            payload
        );
    }
}
