import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-read-more',
    templateUrl: './read-more.component.html',
    styleUrls: ['./read-more.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReadMoreComponent implements OnInit {
    @ViewChild('overflowContainer', { static: false }) overflowContainer: ElementRef<HTMLElement>;
    //set these to false to get the height of the expended container
    public isCollapsed: boolean = false;
    public isCollapsable: boolean = false;
    isProcessed = false;
    viewMoreHeight = 'auto';
    viewLessHeight = 'auto';
    @Input() noOfLines: number;
    @Input() lineHeight: number;
    @Input() defaultMinWebHeight = 60; // in pixel
    @Input() defaultMinMobHeight = 65; // in pixel
    @Input() isMyJio;
    isMobileOS;
    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobileOS = this.utilService.isResponsiveMobileView(1024) || this.utilService.isIpad();
        // this.handleMaxDefaultHeight()
        setTimeout(() => {
            this.handleMaxDefaultHeight();
        }, 0);
    }

    handleMaxDefaultHeight() {
        const element = (this.overflowContainer.nativeElement as HTMLElement).querySelector('.ql-editor');
        if (!element) {
            this.isCollapsable = false;
            return;
        }
        let defaultHeight = this.isMobileOS ? this.defaultMinMobHeight : this.defaultMinWebHeight;
        // if noOfLines & line height given
        if (this.noOfLines && this.lineHeight) {
            defaultHeight = this.noOfLines * this.lineHeight;
        }
        if (element.scrollHeight > defaultHeight) {
            this.isCollapsable = true;
            this.viewMoreHeight = `${element.scrollHeight}px`;
            this.viewLessHeight = `${defaultHeight}px`;
            this.isCollapsed = true;
            // your element has overflow and truncated
            // show read more / read less button
        } else {
            this.isCollapsable = false;
            this.viewLessHeight = `${element.scrollHeight}px`;
            // your element doesn't overflow (not truncated)
        }

        this.isProcessed = true;
    }
}
