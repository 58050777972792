import { retryBackoff } from 'backoff-rxjs';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class JioMeetApiRetryPipe {
    public static addResponseBasedRetry<T>(source$: Observable<T>): Observable<T> {
        return source$.pipe(
            switchMap((res: any) => {
                if (res.success) {
                    return of(res);
                } else {
                    return throwError(new Error(res));
                }
            }),
            retryBackoff({
                initialInterval: 1000,
                maxRetries: 4,
                maxInterval: 5000,
                resetOnSuccess: true
            })
        );
    }
}
