import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService, UserService } from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { AddonsService } from 'src/app/core/services/addons.service';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-feed-comment-section',
    templateUrl: './feed-comment-section.component.html',
    styleUrls: ['./feed-comment-section.component.scss']
})
export class FeedCommentSectionComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();
    localParticipant;
    isAuthenticated = false;
    profilePicPath;
    commentList = [];
    @Input() parentData;
    @Input() webinarId;
    @Input() currentUser;
    @Input() contestId;
    @Input() isEnded;
    @Output() hideComments: EventEmitter<any> = new EventEmitter();
    localUserName: string;
    showDeleteCommentPopup: boolean = false;
    dialogData: any = {};
    constructor(
        private authService: AuthService,
        private addonsService: AddonsService,
        private appLoggerService: AppLoggerService,
        private userService: UserService,
        private toastrService: ToastrService
    ) {}

    ngOnInit(): void {
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.localParticipant = this.userService.getUserSync();
        this.localUserName = this.localParticipant.name + ' ' + this.localParticipant.lname;
        if (this.parentData.attributes.contest_entry_comments.data.length) {
            _.forEach(this.parentData.attributes.contest_entry_comments.data, (comment) => {
                comment['timeTobeDisplayed'] = this.setTimePassedForPost(comment.attributes.createdAt);
                comment['profilePicPath'] = this.userService.getProfilePicPath(comment.attributes.userId);
            });
        }
        this.commentList = this.parentData.attributes.contest_entry_comments.data;
    }

    loadComments(getCommentsAgain = false) {
        this.addonsService.getCommentsOfContestEntry(this.parentData.id, Date.now()).subscribe(
            (res) => {
                if (res.messages) {
                    this.commentList = this.commentList.concat(...res.data);
                    if (getCommentsAgain) {
                        this.loadComments();
                    }
                }
            },
            (err) => {
                this.appLoggerService.error(err);
            }
        );
    }

    setTimePassedForPost(postedTime) {
        const curTime = moment();
        const timeDiff = moment.duration(moment(curTime).diff(postedTime));
        const dayDiff = Math.floor(timeDiff.asDays());
        const hourDiff = Math.floor(timeDiff.asHours());
        const minutesDiff = Math.floor(timeDiff.asMinutes());
        var timeTobeDisplayed = `${
            dayDiff > 0 ? dayDiff + ' days' : hourDiff > 0 ? hourDiff + ' hours' : minutesDiff + ' mins'
        } ago`;

        if (minutesDiff <= 0) {
            timeTobeDisplayed = 'Just Now';
        }
        return timeTobeDisplayed;
    }

    showDeleteDialog(comment) {
        this.showDeleteCommentPopup = true;
        this.dialogData = {
            title: 'Delete this comment?',
            message: 'Are you sure you want to delete this comment?',
            buttonText: 'Delete',
            commentId: comment?.id
        };
    }

    deleteComment(commentId) {
        this.addonsService.deleteCommentOfContestEntry(commentId, this.contestId).subscribe(
            (data) => {
                console.log(`contest entry delete.....${data.data}`);
                this.hideComments.emit(true);
                this.toastrService.success('Comment deleted successfully');
            },
            (err) => {
                console.log('entry delete err', err);
                this.toastrService.error(
                    err?.error?.errors || 'Error while deleting comment. Please try after sometime.'
                );
            }
        );
    }
}
