<div class="browser-test-container">
    <div class="browser-info-section">
        <div class="browser-test-heading">
            <div class="img-container">
                <img src="assets/images/pre-call-testing/browser_compatibility.svg" alt="" />
            </div>
            <div class="title">Browser Compatibility</div>
        </div>
        <div class="browser-test-subheading">
            <div class="browser-status-img">
                <img
                    [src]="'assets/images/pre-call-testing/' + (isBrowserSupported ? 'success' : 'warning') + '.svg'"
                    alt=""
                />
            </div>
            <div class="browser-status-text">
                {{
                    isBrowserSupported
                        ? 'Google Chrome is supported in JioEvents.'
                        : ' Your browser is not supported. For the best JioEvents Experience, please use Google Chrome or Safari.'
                }}
            </div>
        </div>
    </div>
</div>
