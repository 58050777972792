import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { APP_EVENTS } from 'src/app/constants';
import { EXPLORE_EVENTS } from 'src/app/constants/gtm-events';
import { EventViewSection } from 'src/app/constants/webinar-enum';
import { AppService, AuthService, EventData, EventEmitterService, UtilService } from 'src/app/core';
import { ExploreEventsService } from 'src/app/core/services/explore-events.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

@Component({
    selector: 'app-viewall',
    templateUrl: './viewall.component.html',
    styleUrls: ['./viewall.component.scss']
})
export class ViewallComponent implements OnInit {
    readonly EventViewSection = EventViewSection;
    @Input() dashboard;

    eventInfo: any;
    @ViewChild('eventTypeList') eventTypeListRef: ElementRef;
    @ViewChild('filterForm') eventTypeFiltertRef: ElementRef;

    @Output() DropDownFooter = new EventEmitter<any>();
    @Output() FilterTrigger = new EventEmitter<any>();
    @HostListener('document:click', ['$event'])
    /*  @ViewChild('mySelect') selectElementRef: ElementRef; */
    cardContent: boolean = false;
    filters = false;
    allEvents = false;
    eventDay = 'all';
    publishType = 'all';
    eventDayState = 'all';
    publishTypeState = 'all';
    eventType = 'all';
    favourites = false;
    publishTypeFilters = [
        {
            name: 'Public',
            gtmValue: 'filter_public'
        },
        {
            name: 'Organisation',
            gtmValue: 'filter_organisation'
        }
    ];
    eventDayFilters = [
        {
            name: 'Today',
            gtmValue: 'filter_today'
        },
        {
            name: 'This Month',
            gtmValue: 'filter_month'
        }
    ];
    eventTypeFilters = [
        {
            name: 'Technology',
            gtmValue: 'filter_technology'
        },
        {
            name: 'Education',
            gtmValue: 'filter_education'
        },
        {
            name: 'Career Fair',
            gtmValue: 'filter_career_fair'
        },
        {
            name: 'Professional Development',
            gtmValue: 'filter_professional_development'
        },
        {
            name: 'Health & Lifestyle',
            gtmValue: 'filter_health_and_lifestyle'
        },
        {
            name: 'Business & Entrepreneurship',
            gtmValue: 'filter_business_and_entrepreneurship'
        },
        {
            name: 'Marketing & Advertising',
            gtmValue: 'filter_marketing_and_advertising'
        },
        {
            name: 'Entertainment',
            gtmValue: 'filter_entertainment'
        },
        {
            name: 'Community & Spirituality',
            gtmValue: 'filter_community_and_spirituality'
        },
        {
            name: 'Judiciary'
        },
        {
            name: 'Travel & Tourism'
        },
        {
            name: 'Custom'
        }
    ];
    isFavouritesLoading = false;
    trendingEventsLength;
    recommendedEventsLength;
    ongoingEventsLength;
    upcomingEventsLength;
    replayEventsLength;
    favouriteEventsList = [];
    showNoEventsMessage: boolean = false;
    searchQueryEvents = '';
    search = '';
    searchModelChanged: Subject<string> = new Subject<string>();
    isLoading = true;
    isMobile = false;
    isAuthenticated;
    currentPage: any = 'explore_events_page';
    filergtmLabel: any = {
        eventDay: '',
        eventType: ''
    };
    scroll: number;
    filterOptions = [];
    filterOptionsObject: any;
    currentRoute: string = '/dashboard';
    headerRoute: string = '/explore-events';
    dashboardData = [];
    currentMeeting = null;
    showCalendar = false;
    isSafariBrowser = false;
    exploreEventType: any;
    sliderHeading: string;
    showViewAllContainer: boolean = true;
    moreAvailable: boolean = false;
    pageSize: any;
    currentPageIndex: number = 0;
    newEventsLoading: boolean = false;
    constructor(
        private exploreEventsService: ExploreEventsService,
        public utilService: UtilService,
        public AppService: AppService,
        private authservice: AuthService,
        private googleTagManager: GoogleTagMangerService,
        private router: Router,
        private eventEmitterService: EventEmitterService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.data.subscribe((route) => {
            this.currentRoute = this.activatedRoute['_routerState'].snapshot.url.split('?')[0];
            this.dashboard = false;
        });
        this.activatedRoute.data.subscribe((route) => {
            this.headerRoute = this.activatedRoute['_routerState'].snapshot.url.split('?')[0];
            this.dashboard = false;
        });
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.exploreEventType = params['Type'];
        });
        this.pageSize = this.AppService.getConfigVariable('paginationValidationLimit') - 1;
        this.sliderHeading = this.exploreEventType.charAt(0).toUpperCase() + this.exploreEventType.slice(1) + ' Events';
        this.isMobile = this.utilService.isResponsiveMobileView(1079);
        this.isSafariBrowser = this.utilService.isSafariBrowser();
        this.isAuthenticated = this.authservice.getIsAuthenticated();
        this.searchModelChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((model) => {
            this.handleSearchAction(model);
        });
        this.getEvents();
        this.subscribeToEventEmitter();
    }

    getEvents() {
        this.exploreEventsService
            .getEventsList(
                this.exploreEventType,
                'all',
                'all',
                'all',
                this.searchQueryEvents,
                this.currentPageIndex * this.pageSize,
                this.pageSize
            )
            .subscribe(
                (responseList) => {
                    if (responseList?.resultMeetingDetails && responseList.resultMeetingDetails.length > 0) {
                        if (!this.eventInfo) {
                            this.eventInfo = [];
                        }
                        responseList.resultMeetingDetails.forEach((event) => {
                            this.eventInfo.push(event);
                        });
                    }
                    this.moreAvailable = responseList.moreAvailable;
                    this.newEventsLoading = false;
                    this.isLoading = false;
                },
                (error) => {
                    console.error('Error fetching events:', error);
                    this.newEventsLoading = false;
                    this.isLoading = false;
                }
            );
    }

    getMoreEvents() {
        if (!this.moreAvailable || this.newEventsLoading) return;
        this.currentPageIndex++;
        this.newEventsLoading = true;
        this.getEvents();
    }

    subscribeToEventEmitter() {
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.OPEN_ADD_TO_CAL) {
                this.currentMeeting = event.data?.meeting;
                this.showCalendar = true;
            } else if (event.type === APP_EVENTS.CLOSE_ADD_TO_CAL_CLICK) {
                this.currentMeeting = null;
                this.showCalendar = false;
            }
        });
    }

    handleSearchAction(model: string) {
        this.changeCurrentPageForGA4(Object.assign(EXPLORE_EVENTS.EXPLORE_SEARCH, { current_page: this.currentPage }));
        this.searchQueryEvents = model;
        this.search = model;
        this.currentPageIndex = 0;
        this.eventInfo = [];
        this.callListApi();
    }

    callListApi() {
        this.filters = false;
        this.allEvents = false;
        this.isLoading = true;

        if (!this.favourites) {
            this.exploreEventsService
                .getEventsList(
                    this.exploreEventType,
                    this.eventType,
                    this.publishType,
                    this.eventDay,
                    this.searchQueryEvents,
                    this.currentPageIndex * this.pageSize,
                    this.pageSize
                )
                .subscribe(
                    (response) => {
                        this.sendData(response);
                        this.moreAvailable = response.moreAvailable;
                        this.showViewAllContainer = response.totalCount > 0;
                        this.isLoading = false;
                        this.cdr.detectChanges();
                    },
                    (error) => {
                        console.error('Error fetching events:', error);
                        this.isLoading = false;
                        this.showNoEventsMessage = true;
                    }
                );
        } else {
            this.exploreEventsService
                .getEventsList('favourites', this.eventType, this.publishType, this.eventDay, this.searchQueryEvents)
                .subscribe((data) => {
                    this.favouriteEventsList = data.resultMeetingDetails;
                    this.isLoading = false;
                    this.cdr.detectChanges();
                });
        }
    }

    sendData(response) {
        this.eventInfo = response;
        if (response?.resultMeetingDetails) {
            this.eventInfo = response.resultMeetingDetails;
        } else {
            this.eventInfo = [];
        }

        if (this.currentRoute == '/dashboard/viewall') {
            this.dashboardData = this.eventInfo;
        }
    }

    searchQueryHandler(event) {
        this.searchModelChanged.next(event);
    }

    closeSearchQueryEvents() {
        this.searchQueryEvents = '';
        this.searchModelChanged.next('');
        this.callListApi();
    }

    mobileFilter(name, type, gtmLable = null) {
        if (gtmLable) {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER1;
            eventData.clicked_text = gtmLable;
            this.changeCurrentPageForGA4(eventData);
        }
        if (type == 'publishType') this.publishType = name;
        else this.eventDay = name;
        this.callListApi();
    }

    favouritesFilter() {
        let eventData = EXPLORE_EVENTS.EXPLORE_FILTER2;
        eventData.clicked_text = 'filter_my_favourites';
        this.changeCurrentPageForGA4(eventData);
        this.allEvents = false;
        if (!this.isAuthenticated) {
            this.router.navigate(['login', 'v1']);
            return;
        }
        this.favourites = true;
        this.eventType = 'all';
        this.currentPageIndex = 0;
        this.eventInfo = [];
        this.callListApi();
    }

    shouldShowNoEventsMessage(): boolean {
        return (
            !this.eventInfo || !this.eventInfo.resultMeetingDetails || this.eventInfo.resultMeetingDetails.length === 0
        );
    }

    markFavourite(event) {
        this.isFavouritesLoading = true;
        if (event.post) {
            this.exploreEventsService.postFavourite(event.id).subscribe((data: any) => {
                this.exploreEventsService.favouriteDetails.next({
                    ...data,
                    index: event.index,
                    sliderName: event.sliderName
                });
                this.isFavouritesLoading = false;
            });
        } else {
            this.exploreEventsService.deleteFavourite(event.id).subscribe(() => {
                this.isFavouritesLoading = false;
                if (this.favourites) this.callListApi();
            });
        }
    }

    clickOutside(name) {
        if (this.isMobile) return;
        if (name == 'filter') {
            this.filters = false;
        }
        if (name == 'allEvents') {
            this.allEvents = false;
        }
    }

    toggleFilter(e) {
        e.stopPropagation();
        if (this.allEvents) this.allEvents = false;
        this.filters = !this.filters;
        if (this.filters) {
            this.eventDayState = this.eventDay;
            this.publishTypeState = this.publishType;
            this.FilterTrigger.emit(this.eventTypeFiltertRef);
        }
    }

    closeAllEvents() {
        this.allEvents = false;
    }

    navigateToExplore() {
        this.router.navigate(['/dashboard/explore']);
    }

    toggleAllEvents() {
        if (this.filters) this.filters = false;
        this.allEvents = !this.allEvents;
        if (this.utilService.isMobileView) {
            this.DropDownFooter.emit(this.eventTypeListRef);
        } else {
            this.DropDownFooter.emit(null);
        }
    }

    filterItems(name, type, gtmLable = null) {
        if (type == 'eventDay') {
            this.eventDayState = this.eventDayState == name ? 'all' : name;
            this.filergtmLabel.eventDay = gtmLable;
        } else {
            this.publishTypeState = this.publishTypeState == name ? 'all' : name;
            this.filergtmLabel.eventType = gtmLable;
        }
    }

    removeAppliedFilter(type) {
        if (type === 'Organisation' || type === 'Public') {
            this.publishType = 'all';
            this.filergtmLabel.eventDay = '';
        } else {
            this.eventDay = 'all';
            this.filergtmLabel.eventType = '';
        }
        this.filters = false;
        this.callListApi();
        this.filterOptions = this.filterOptions.filter((value) => value !== type);
        //API CALL
    }

    applyFilters() {
        if (this.eventDayState == 'all' && this.publishTypeState == 'all') return;
        this.eventDay = this.eventDayState;
        this.publishType = this.publishTypeState;
        this.filterEventType();
        if (this.filergtmLabel.eventDay && this.eventDayState != 'all') {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER1;
            eventData.clicked_text = this.filergtmLabel.eventDay;
            this.changeCurrentPageForGA4(eventData);
        }
        if (this.filergtmLabel.eventType && this.publishTypeState != 'all') {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER1;
            eventData.clicked_text = this.filergtmLabel.eventType;
            this.changeCurrentPageForGA4(eventData);
        }
        this.filters = false;
        this.callListApi();
    }

    filterEventType() {
        this.filterOptionsObject = this.exploreEventsService.getFilerObject() || { publishType: '', eventDay: '' };
        this.resetFilterEvent(this.publishType, 'publishType');
        this.resetFilterEvent(this.eventDay, 'eventDay');
        this.exploreEventsService.setFilterObject(this.filterOptionsObject);
        this.filterOptions = Object.values(this.filterOptionsObject);
    }

    resetFilterEvent(inputValue, inputKey) {
        if (inputValue !== 'all') {
            if (!Object.values(this.filterOptionsObject).includes(inputValue)) {
                delete this.filterOptionsObject[inputKey];
                this.filterOptionsObject = { ...this.filterOptionsObject, [inputKey]: inputValue };
            }
        } else {
            delete this.filterOptionsObject[inputKey];
        }
    }

    resetFilters() {
        this.publishType = 'all';
        this.filergtmLabel.eventDay = '';
        this.eventDay = 'all';
        this.filergtmLabel.eventType = '';
        this.filters = false;
    }

    allEventsItems(name, gtmLable = null) {
        if (gtmLable) {
            let eventData = EXPLORE_EVENTS.EXPLORE_FILTER2;
            eventData.clicked_text = gtmLable;
            this.changeCurrentPageForGA4(eventData);
        }
        this.allEvents = false;
        if (name === 'My Favourites') {
            this.favouritesFilter();
        } else {
            this.favourites = false;
            this.eventType = name === 'all' ? 'all' : name;
            this.eventInfo = [];
            this.currentPageIndex = 0;
            this.callListApi();
        }
    }

    changeCurrentPageForGA4(event) {
        this.googleTagManager.pushGoogleTagData(Object.assign(event, { current_page: this.currentPage }));
    }

    eventClicked(event, i) {
        window.open(event.link);
    }

    createEvent($event) {
        this.router.navigate(['/dashboard'], {
            relativeTo: this.activatedRoute,
            queryParams: { create: true }
        });
    }

    createPreRecord($event) {
        this.router.navigate(['/dashboard'], {
            relativeTo: this.activatedRoute,
            queryParams: { prerecorded: true }
        });
    }

    cardEntered(event: KeyboardEvent) {
        if (event.key === 'enter') this.cardContent = true;
    }
    handleEnterEvent() {
        console.log('Enter key pressed!');
    }
}
