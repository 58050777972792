import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';

import { APP_CONSTANT, APP_EVENTS } from 'src/app/constants';
import {
    UtilService,
    MeetingService,
    EventEmitterService,
    AppService,
    ExternalInterfaceService,
    UserService,
    AddonsService,
    LocalStorageService
} from 'src/app/core';
import { EventStatus, EventUserRole } from 'src/app/constants/webinar-enum';
import { GA_EVENTS } from 'src/app/integrations/modules/myjio/ga-events';
import { ActivatedRoute, Router } from '@angular/router';
import { isSameDay, isToday } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { ATTENDEE_LANDING } from 'src/app/constants';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-event-session-info',
    templateUrl: './event-session-info.component.html',
    styleUrls: ['./event-session-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventSessionInfoComponent implements OnInit, OnChanges, AfterViewInit {
    showAllSpeakers = false;
    /* enterPressed: boolean = false; */
    joinNowBtnLabel: string = 'join now';
    maxNumberOfVisibleSpeakers = APP_CONSTANT.MAX_VISIBLE_SPEAKERS_WEB_VIEW;
    showCompleteDescription = false;
    @Input() status: string = '';
    @Input() session;
    @Input() webinarDetails;
    @Input() isInvalidMeeting;
    @Input() sessionsToRemind;
    @Input() selectedDate;
    @Input() brandDetails;
    @Input() replayDetails;
    @Input() userHasRegistered = false;
    @Input() enableJoin = false;
    @Input() isBroadcasting;
    @Input() isMyJio = false;
    @Input() userRole: EventUserRole = EventUserRole.ATTENDEE;
    @Output() joinClick: EventEmitter<any> = new EventEmitter();
    isMobileOS;
    isMobileView = false; // this to check if the view is mobile or ipad
    defaultMaxVisibleSpeakers = APP_CONSTANT.MAX_VISIBLE_SPEAKERS_WEB_VIEW;
    showRemindMe: boolean;
    meetingIdFromParams;
    setInvalidMeeting: boolean = false;
    isSessionInReminderList: boolean;
    showRegister: boolean;
    useSessionJoinState: boolean;
    isSessionOnSameDay: boolean;
    isRecurring = false;
    hideViewMoreFlag;
    webinarId;
    sessionNumber = 0;
    isEmbedInMobile = false;
    isSpectrum: boolean;
    isPaymentEnabled: boolean;
    constructor(
        private utilService: UtilService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private meetingService: MeetingService,
        private webinarAttendeeService: WebinarAttendeeService,
        private eventEmitterService: EventEmitterService,
        private toastrService: ToastrService,
        private externalInterfaceService: ExternalInterfaceService,
        private appService: AppService,
        private callStateManagerService: CallStateManagerService,
        private userService: UserService,
        private webinarService: WebinarService,
        private addOnService: AddonsService,
        private localStorageService: LocalStorageService,
        private googleTagMangerService: GoogleTagMangerService
    ) {
        this.webinarId = this.activatedRoute.parent.snapshot.params.shID;
    }
    ngAfterViewInit(): void {
        this.setSessionStatus();
    }

    ngOnInit(): void {
        this.isPaymentEnabled = this.webinarDetails?.customization?.paymentInfo?.enable;
        this.evaluateDeviceType();
        // check if broadcasted status is coming true then set session ongoing even though it's in future
        if (this.webinarDetails) {
            this.setSessionStatus();
        }
        var eventFeatures = this.appService.getConfigVariable('eventFeatures') || {};
        if (eventFeatures[this.webinarId]?.hideViewMore === true) {
            this.hideViewMoreFlag = true;
        }

        // generate meeting url
        if (this.userRole === EventUserRole.ATTENDEE) {
            const joinHash = this.activatedRoute.snapshot.params.hash;
            this.session.meetingUrl = this.utilService.generateAttendeeJoinLink(this.session.meetingId, joinHash);
        }

        this.activatedRoute.queryParams.subscribe((params) => {
            this.isEmbedInMobile = params.isEmbedInMobile === 'true' ? true : false;
            // if (this.checkForCustomEventSessionSpecificLink() && params.meetingId) {
            //     this.meetingIdFromParams = params.meetingId;
            //     this.checkReplayDetails(this.session);
            // }
        });
    }

    checkForCustomEventSessionSpecificLink() {
        return this.webinarDetails.customization?.customEvent === 'spectrum';
    }

    evaluateDeviceType() {
        this.isMobileOS = this.utilService.isResponsiveMobileView(1024) || this.utilService.isIpad();
        this.isMobileView = this.utilService.isResponsiveMobileView() || this.utilService.isIpad();
        if (this.isMobileView) {
            this.maxNumberOfVisibleSpeakers = APP_CONSTANT.MAX_VISIBLE_SPEAKERS_MOBILE_VIEW;
            this.defaultMaxVisibleSpeakers = APP_CONSTANT.MAX_VISIBLE_SPEAKERS_MOBILE_VIEW;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.evaluateDeviceType();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            this.webinarDetails &&
            ((changes['session'] && !changes['session'].firstChange) ||
                changes['sessionsToRemind'] ||
                changes['selectedDate'] ||
                changes['userHasRegistered'])
        ) {
            this.setSessionStatus();
        }
        if (changes.isInvalidMeeting) {
            this.webinarAttendeeService.setIsInvalidMeeting(this.isInvalidMeeting);
            this.setInvalidMeeting = changes.isInvalidMeeting.currentValue;
        }
    }

    setSessionStatus() {
        // const repeatOptions = ['daily', 'weekly', 'monthly'];
        // const isRecurring = repeatOptions.includes(this.webinarDetails?.repeatOptions?.repeat);
        const isRecurring =
            this.webinarDetails?.repeatOptions?.repeat === 'daily' ||
            this.webinarDetails?.repeatOptions?.repeat === 'weekly' ||
            this.webinarDetails?.repeatOptions?.repeat === 'monthly';
        this.isRecurring = isRecurring;
        this.isSessionInReminderList =
            this.sessionsToRemind &&
            this.sessionsToRemind.find((sInfo) => {
                if (isRecurring) {
                    var selectedDayDate = this.selectedDate ? new Date(this.selectedDate) : new Date();
                    const isSameDate = new Date(sInfo.on).getDate() - selectedDayDate.getDate() === 0;
                    return isSameDate && sInfo.id === this.session._id;
                }
                return sInfo.id === this.session._id;
            });
        // for recurring webinars, only current day's sessions are listed so show only remind option for future dates
        const sessionLevelRegistration = this.webinarDetails?.sessionRegistrationEnabled;
        var needsRegistration =
            this.webinarDetails?.registrationEnabled &&
            !this.session.isRegistered &&
            !this.webinarDetails?.inviteOnly &&
            sessionLevelRegistration;

        // session can be on same day or can be the first one in the future date in both cases we need to enable join
        // so for non recurring sessions always use session join state
        // for recurring sessions check if a date is selected then check if it is same as session day or else use session join state
        var isDateSelected = !!this.selectedDate;
        this.isSessionOnSameDay = isDateSelected && isSameDay(this.selectedDate, new Date(this.session.startTime));
        this.isSpectrum = this.webinarDetails?.customization?.customEvent === 'spectrum';
        this.useSessionJoinState = isRecurring ? (isDateSelected ? this.isSessionOnSameDay : true) : true;
        if (this.isMyJio) {
            this.useSessionJoinState =
                new Date(this.session.startTime).getTime() <= new Date().getTime() || this.isBroadcasting
                    ? true
                    : false;
        }
        if (isRecurring) {
            this.status = '';
            this.showRemindMe = this.webinarDetails.customization?.sessionReminders && !this.isSessionInReminderList;
            if (this.useSessionJoinState && this.session.enableJoin) {
                this.showRemindMe = false;
            }
            this.showRegister =
                !this.showRemindMe && this.webinarDetails?.sessionRegistrationEnabled
                    ? this.webinarDetails?.registrationEnabled &&
                      !this.session?.isRegistered &&
                      !this.webinarDetails?.inviteOnly
                    : needsRegistration;
            if (this.isSessionOnSameDay) {
                this.status = this.meetingService.getEventStatus(this.session, this.webinarDetails);
            }
        } else {
            this.status = this.meetingService.getEventStatus(this.session, this.webinarDetails);
            if (this.webinarDetails.customization?.sessionReminders) {
                if (this.status === '') {
                    this.showRemindMe = !this.isSessionInReminderList;
                } else if (this.status === EventStatus.ONGOING || this.status === EventStatus.ABOUT_TO_START) {
                    this.showRemindMe = this.checkForCustomEventSessionSpecificLink()
                        ? !this.userHasRegistered
                            ? true
                            : false
                        : false;
                }
            } else {
                this.showRegister =
                    !this.session.enableJoin && this.webinarDetails?.sessionRegistrationEnabled
                        ? this.webinarDetails?.registrationEnabled &&
                          !this.session?.isRegistered &&
                          !this.webinarDetails?.inviteOnly
                        : needsRegistration;
            }
        }
    }

    toggleSpeakerList(expand) {
        if (expand) {
            this.maxNumberOfVisibleSpeakers = this.session?.speakers?.length;
        } else {
            this.maxNumberOfVisibleSpeakers = !this.isMobileView
                ? APP_CONSTANT.MAX_VISIBLE_SPEAKERS_WEB_VIEW
                : APP_CONSTANT.MAX_VISIBLE_SPEAKERS_MOBILE_VIEW;
        }
    }

    sanitizeText(message) {
        return this.utilService.sanitizeAndConvertEncodedHTML(message);
    }

    async navigateToWebinarSession(status = '', session = null) {
        // if it's attendee side then only proceed with ongoing session
        try {
            // Wait for the first value from the invalidMeeting$ observable
            const isInvalid = await this.webinarAttendeeService.invalidMeeting$.pipe(first()).toPromise();

            if (this.isInvalidMeeting[0] || isInvalid[0] || this.setInvalidMeeting[0] || status === 'cancelled') {
                this.toastrService.info('The event has been cancelled.');
                this.webinarAttendeeService.setTabForCancelledEvent(true);
                return;
            }

            // Proceed with the remaining logic for the session navigation
            // For example, you might call another service method or navigate
            console.log('Proceeding with session navigation');
        } catch (error) {
            console.error('Error while handling the webinar session:', error);
        }

        let userDetailsExist;
        if (this.webinarDetails?.customization?.isGetEvent) {
            userDetailsExist = await this.webinarService.checkIfUserDetailsExist();
        }
        const isAttendee = this.localStorageService.getItem('isAttendee');
        if (!userDetailsExist && this.webinarDetails?.customization?.isGetEvent && isAttendee) {
            this.router.navigate(['/get-user-details']);
            return;
        }
        if (
            this.userRole === EventUserRole.ATTENDEE &&
            (this.checkForCustomEventSessionSpecificLink() ? false : !this.enableJoin) &&
            !this.isPaymentEnabled
        ) {
            return;
        }
        // await this.eventEmitterService.emit({ type: 'UPDATE_MEETING_PARAMS', data: this.session });
        // setTimeout(() => {
        // if(this.session?.preRecordedVideo?.enabled){
        //     this.eventEmitterService.emit({ type: APP_EVENTS.NAVIGATE_TO_MAINSTAGE_ON_PRERECORD_SESSION_SELECT, data: this.session });
        //     return;
        // }
        this.eventEmitterService.emit({ type: APP_EVENTS.NAVIGATE_TO_MAINSTAGE_ON_SESSION_SELECT, data: this.session });
        const event_data = ATTENDEE_LANDING.JOIN_BUTTON;
        event_data.meeting_id = this.webinarId;
        event_data.clicked_text = this.webinarDetails?.topic;
        this.googleTagMangerService.pushGoogleTagData(event_data);
        // }, 100);
        return;
        // this.joinClick.emit(this.session);
    }

    async handleRemindMe() {
        // TODO publish app event to show registration popup
        try {
            const isInvalid = await this.webinarAttendeeService.invalidMeeting$.pipe(first()).toPromise();

            if (this.isInvalidMeeting[0] || isInvalid[0] || this.setInvalidMeeting[0]) {
                this.toastrService.info('The event has been cancelled.');
                this.webinarAttendeeService.setTabForCancelledEvent(true);
                return;
            }
        } catch (error) {
            console.error('Error while handling the webinar session:', error);
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.HANDLE_REMIND_SESSIONS,
            data: { ...this.session, date: this.selectedDate || new Date() }
        });
    }
    toggleSessionDetails(session) {
        if (!session?.showDetails) {
            this.googleTagMangerService.pushGoogleTagData(GA_EVENTS.VIEW_DETAILS);
        }
        session['showDetails'] = session?.showDetails ? !session?.showDetails : true;
    }

    checkReplayDetails(session) {
        if (this.replayDetails) this.navigateToSessionReplay(session);
        else {
            this.webinarAttendeeService.getEventReplayDetails(this.webinarDetails.meetingId).subscribe((res) => {
                this.replayDetails = res;
                this.navigateToSessionReplay(session);
            });
        }
    }
    navigateToSessionReplay(session) {
        if (this.checkIfRecordingsAvailable()) {
            if (!this.isMyJio) {
                let replayLink = this.router.serializeUrl(
                    this.router.createUrlTree(['media-recordings', this.webinarDetails.meetingId], {
                        queryParams: {
                            sessionMeetingId: session.meetingId
                        }
                    })
                );
                // if (this.checkForCustomEventSessionSpecificLink() && this.meetingIdFromParams) {
                //     //check if input session is same as sessionID open only that session link
                //     if (session.meetingId === this.meetingIdFromParams) window.open(replayLink);
                // } else {
                window.open(replayLink);
                // }
            } else {
                let queryParams = this.activatedRoute.snapshot.queryParams;
                const url = `${this.appService.getMyjioUrl()}/integrations/myjio/videoplayer?public=${
                    this.replayDetails.publishType == 'Public' ? true : false
                }&src=videoplayer&url=${encodeURIComponent(this.router.url)}&title=${queryParams.title}${
                    queryParams.section === 'channels' ? '&section=channels' : ''
                }${queryParams.fav === 'true' ? '&fav=true' : ''}&type=${queryParams.type}&isMyJio=true&webinarId=${
                    this.webinarId
                }${
                    session.meetingId ? `&sessionId=${session.meetingId}` : '' // Add this line
                }`;
                const data = {
                    state: {
                        recordings: this.getSessionRecording(session.meetingId)
                    }
                };

                window.open(url, '_self');

                // Post the data to the opened window using window.postMessage
                window.postMessage(data, '*');
            }
        } else {
            // if (this.checkForCustomEventSessionSpecificLink()) {
            //     if (
            //         this.meetingIdFromParams === this.callStateManagerService.getSessionInfo()?.meetingId &&
            //         this.callStateManagerService.getSessionInfo()?.status === 'ended'
            //     )
            //         this.toastrService.info('No recordings available');
            // } else {
            this.toastrService.info('No recordings available');
            // }
        }
    }

    getSessionRecording(sessionId) {
        for (const session of this.replayDetails.sessionDetails) {
            if (session.meetingId === sessionId) {
                return session.recordingDetails[0];
            }
        }
    }

    checkIfRecordingsAvailable() {
        this.sessionNumber = this.replayDetails.sessionDetails.findIndex((rec) => {
            return rec.meetingId == this.session.meetingId;
        });
        if (this.replayDetails?.sessionDetails[this.sessionNumber]?.recordingDetails?.length) return true;
        else return false;
    }

    trackByFunction(item) {
        item.userId;
    }
}
