import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appDraggable]'
})
export class DraggableDirective {
    @Input() containerId: string;
    @Input() isCustomConfig: boolean = false;

    private container;
    private elWidth;
    private elHeight;
    private containerWidth;
    private containerHeight;
    private diffX;
    private diffY;
    private isMoving = false;
    private x_left;
    private y_top;

    constructor(private el: ElementRef<HTMLElement>) {}

    @HostListener('mousedown', ['$event'])
    start(event) {
        event = event || window.event;

        this.container = document.getElementById(this.containerId)
            ? document.getElementById(this.containerId)
            : document.body;

        const el = this.el.nativeElement;
        const posX = event.screenX;
        const posY = event.screenY;

        const elRect = el.getBoundingClientRect();
        const containerRect = this.container.getBoundingClientRect();

        (this.containerWidth = containerRect.width), (this.containerHeight = containerRect.height);

        this.container.style.cursor = 'move';

        const elTop = elRect.top;
        const elLeft = elRect.left;

        if (!this.isCustomConfig) {
            this.diffX = posX - elLeft;
            this.diffY = posY - elTop;
            this.x_left = 0;
            this.y_top = 0;
            this.elWidth = elRect.width;
            this.elHeight = elRect.height;
        }
        if (this.isCustomConfig) {
            this.diffX = 0.5 * this.elWidth;
            this.diffY = 3 * this.elHeight;
            this.x_left = this.elWidth;
            this.y_top = this.elHeight;
            this.elWidth = elRect.width / 2;
            this.elHeight = elRect.height / 2;
        }

        this.isMoving = true;
    }

    @HostListener('document:mousemove', ['$event'])
    move(event) {
        if (this.isMoving) {
            event = event || window.event;

            const posX = event.screenX;
            const posY = event.screenY;

            let x = posX - this.diffX;
            let y = posY - this.diffY;

            if (x < this.x_left) x = this.x_left;
            if (y < this.y_top) y = this.y_top;
            if (x + this.elWidth > this.containerWidth) {
                x = this.containerWidth - this.elWidth;
            }
            if (y + this.elHeight > this.containerHeight) {
                y = this.containerHeight - this.elHeight;
            }

            this.el.nativeElement.style.left = x + 'px';
            this.el.nativeElement.style.top = y + 'px';
            // this.el.nativeElement.style.transform = 'translate(0, 0)';
        }
    }

    @HostListener('document:mouseup', ['$event'])
    @HostListener('mouseup', ['$event'])
    stop() {
        this.container.style.cursor = 'default';
        this.isMoving = false;
    }
}
