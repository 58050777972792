import { Component, OnInit } from '@angular/core';
import { PreCallTestingService, TEST_STATUS } from '../../services';

@Component({
    selector: 'app-connectivity-test',
    templateUrl: './connectivity-test.component.html',
    styleUrls: ['./connectivity-test.component.scss']
})
export class ConnectivityTestComponent implements OnInit {
    isUdpAvailable = false;
    isTcpAvailable = false;
    testInterval;

    constructor(public preCallTestingService: PreCallTestingService) {
        this.findAvailableProtocols();
    }

    ngOnInit(): void {}
    findAvailableProtocols() {
        this.testInterval = setInterval(this.performanceInterval.bind(this), 1000);
    }

    performanceInterval() {
        if (!this.preCallTestingService.performanceResult) return;
        if (this.preCallTestingService.performanceResult.availableProtocols.includes('udp')) {
            this.preCallTestingService.udpTestStatus = TEST_STATUS.PASS;
            this.preCallTestingService.tcpTestStatus = TEST_STATUS.PASS;
            this.isUdpAvailable = true;
            this.isTcpAvailable = true;
        } else if (this.preCallTestingService.performanceResult.availableProtocols.includes('tls')) {
            this.preCallTestingService.tcpTestStatus = TEST_STATUS.PASS;
            this.isTcpAvailable = true;
        }
        clearInterval(this.testInterval);
    }
}
