import { Injectable } from '@angular/core';

declare const Office;

@Injectable({
    providedIn: 'root'
})
export class OutlookAddinService {
    constructor() {}

    getOfficeContext() {
        if (!Office) return null;
        return Office.context;
    }

    officeOnReady() {
        return Office.onReady();
    }

    addLocationChangedEvent(handler) {
        if (Office.context.mailbox.item.addHandlerAsync) {
            Office.context.mailbox.item.addHandlerAsync(Office.EventType.EnhancedLocationsChanged, handler);
        }
    }

    addRecurrenceChangedEvent(handler) {
        if (Office.context.mailbox.item.addHandlerAsync) {
            Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecurrenceChanged, handler);
        }
    }

    saveCustomProperties(meetingId, createdBy) {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
                if (!asyncResult || asyncResult.status === Office.AsyncResultStatus.Failed) {
                    return reject(asyncResult);
                }
                let customProps = asyncResult.value;
                customProps.set('myProp', 'value');
                customProps.set('meetingId', meetingId);
                customProps.set('createdBy', createdBy);
                customProps.saveAsync((asyncRes) => {
                    if (asyncRes.status === Office.AsyncResultStatus.Failed) {
                        reject(asyncResult.error.message);
                    } else {
                        resolve('');
                    }
                });
            });
        });
    }

    saveCustomPropertiesAsync(meetingId, createdBy, callback) {
        Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
            if (!asyncResult || asyncResult.status === Office.AsyncResultStatus.Failed) {
                return 'FAILED';
            }
            let customProps = asyncResult.value;
            customProps.set('myProp', 'value');
            customProps.set('meetingId', meetingId);
            customProps.set('createdBy', createdBy);
            customProps.saveAsync((asyncRes) => {
                if (asyncRes.status === Office.AsyncResultStatus.Failed) {
                    return 'FAILED';
                } else {
                    callback();
                }
            });
        });
    }

    loadCustomProperties() {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
                if (!asyncResult || asyncResult.status === Office.AsyncResultStatus.Failed) {
                    return reject(asyncResult);
                }
                return resolve(asyncResult);
            });
        });
    }

    saveItemPure() {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.saveAsync((result) => {
                resolve(result.value);
            });
        });
    }

    saveItemAsync(callback) {
        Office.context.mailbox.item.saveAsync((result) => {
            callback;
        });
    }

    saveItem(meetingId, createdBy) {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
                if (!asyncResult || asyncResult.status === Office.AsyncResultStatus.Failed) {
                    return reject(asyncResult);
                }
                let customProps = asyncResult.value;
                // Set a custom property to filter the events created by addIn
                customProps.set('myProp', 'value');
                customProps.set('meetingId', meetingId);
                customProps.set('createdBy', createdBy);
                customProps.saveAsync((result) => {
                    if (result.status === 'succeeded') {
                        Office.context.mailbox.item.saveAsync((result) => {
                            resolve(result.value);
                        });
                    }
                });
            });
        });
    }

    getGraphAPIAccessToken() {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function (result) {
                if (result.status === 'succeeded') {
                    let accessToken = result.value;
                    resolve({
                        accessToken,
                        restURL: Office.context.mailbox.restUrl,
                        userEmail: Office.context.mailbox.initialData.userEmailAddress
                    });
                } else {
                    reject(result);
                }
            });
        });
    }

    setEventLocationSubjectBodyAndNotificationSync(location, subject, body, shouldSendNotification) {
        // Adding callbacks because promisifying these doesnt work
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.subject.setAsync(subject, () => {
                Office.context.mailbox.item.body.setAsync(
                    body,
                    { asyncContext: 'asyncContext', coercionType: 'html' },
                    (_setBodyResult) => {
                        Office.context.mailbox.item.location.setAsync(location, () => {
                            if (shouldSendNotification) {
                                Office.context.mailbox.item.notificationMessages.removeAsync('NoSend', () => {
                                    Office.context.mailbox.item.notificationMessages.addAsync(
                                        'NoSend',
                                        {
                                            type: 'informationalMessage',
                                            message:
                                                'Your meeting has been created successfully! Please use settings to Update or Delete your meeting.',
                                            icon: 'icon-16',
                                            persistent: true
                                        },
                                        () => {
                                            resolve('');
                                        }
                                    );
                                });
                            }
                        });
                    }
                );
            });
        });
    }

    setEventBody(body) {
        if (body === '') {
            Office.context.mailbox.item.body.setAsync(
                body,
                { asyncContext: 'asyncContext', coercionType: 'html' },
                function (setBodyResult) {}
            );
        } else {
            Office.context.mailbox.item.body.getAsync('text', { asynContext: 'asyncContext' }, (data) => {
                const doesBodyIncludesInvitation =
                    data.value.includes('Click to Join the JioMeet Meeting') &&
                    data.value.includes(
                        'Option 1: Download JioMeet Mobile or Desktop App and use above Meeting ID and Password to join the meeting'
                    );
                let value = doesBodyIncludesInvitation ? body : data.value + body;
                Office.context.mailbox.item.body.setAsync(
                    value,
                    { asyncContext: 'asyncContext', coercionType: 'html' },
                    function (setBodyResult) {}
                );
            });
        }
    }

    setEventLocation(location) {
        console.error(location);
        Office.context.mailbox.item.location.setAsync(location, console.error);
    }

    setEventSubject(subject) {
        Office.context.mailbox.item.subject.setAsync(subject);
    }

    getEventSubject(): Promise<String> {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.subject.getAsync((data) => {
                resolve(data.value);
            });
        });
    }

    getEventLocation(): Promise<String> {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.location.getAsync((data) => {
                resolve(data.value);
            });
        });
    }

    getEventBody(): Promise<String> {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.body.getAsync('html', { asynContext: 'asyncContext' }, (data) => {
                resolve(data.value);
            });
        });
    }

    getEventRecurrence(): Promise<any> {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.item.recurrence.getAsync((data) => {
                resolve(data.value);
            });
        });
    }

    getStartAndEndTime(): Promise<any> {
        return new Promise((resolve, _reject) => {
            Office.context.mailbox.item.start.getAsync((startContext) => {
                Office.context.mailbox.item.end.getAsync((endContext) => {
                    resolve({
                        startTime: startContext.value,
                        endTime: endContext.value
                    });
                });
            });
        });
    }

    sendErrorNotification(errorMessage, event) {
        Office.context.mailbox.item.notificationMessages.removeAsync('NoSend', () => {
            Office.context.mailbox.item.notificationMessages.addAsync(
                'NoSend',
                { type: 'errorMessage', message: errorMessage },
                () => {
                    if (event) {
                        event.completed();
                    }
                }
            );
        });
    }

    sendSuccessNotification(errorMessage) {
        Office.context.mailbox.item.notificationMessages.removeAsync('NoSend', () => {
            Office.context.mailbox.item.notificationMessages.addAsync('NoSend', {
                type: 'informationalMessage',
                message: errorMessage,
                icon: 'icon-16',
                persistent: true
            });
        });
    }

    setEventStartTime(time) {
        Office.context.mailbox.item.start.setAsync(new Date(time));
    }

    setEventEndTime(time) {
        Office.context.mailbox.item.end.setAsync(new Date(time));
    }

    sendSuccessNotificationWithPayload(errorMessage, callback) {
        Office.context.mailbox.item.notificationMessages.removeAsync('NoSend', () => {
            Office.context.mailbox.item.notificationMessages.addAsync(
                'NoSend',
                {
                    type: 'informationalMessage',
                    message: errorMessage,
                    icon: 'icon-16',
                    persistent: true
                },
                () => {
                    setTimeout(() => {
                        callback();
                    }, 1500);
                }
            );
        });
    }

    sendWarningNotification(errorMessage) {
        Office.context.mailbox.item.notificationMessages.removeAsync('Warning', () => {
            Office.context.mailbox.item.notificationMessages.addAsync('Warning', {
                type: 'informationalMessage',
                message: errorMessage,
                icon: 'icon-16',
                persistent: true
            });
        });
    }

    sendCreateMeetingNotification(event) {
        Office.context.mailbox.item.notificationMessages.removeAsync('NoSend', () => {
            Office.context.mailbox.item.notificationMessages.addAsync(
                'NoSend',
                {
                    type: 'informationalMessage',
                    message:
                        'Your meeting has been created successfully! Please use settings to Update or Delete your meeting.',
                    icon: 'icon-16',
                    persistent: true
                },
                () => {
                    if (event) {
                        event.completed();
                    }
                }
            );
        });
    }

    sendCreateMeetingNotificationLite(event) {
        Office.context.mailbox.item.notificationMessages.addAsync(
            'Created',
            {
                type: 'informationalMessage',
                message:
                    'Your meeting has been created successfully! Please use settings to Update or Delete your meeting.',
                icon: 'icon-16',
                persistent: false
            },
            {
                asyncContext: event
            },
            (asyncResult) => {
                if (asyncResult && asyncResult.asyncContext) {
                    asyncResult.asyncContext.completed();
                }
            }
        );
    }

    displayDialog(url, options, handler) {
        Office.context.ui.displayDialogAsync(url, options, handler);
    }

    addEventHandlerToDialog(dialog, handler) {
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, handler);
    }

    addGlobalEventHandlerToDialog(dialog, handler) {
        dialog.addEventHandler(Office.EventType.DialogEventReceived, handler);
    }

    messageParentDialog(message) {
        if (typeof message !== 'string') message = JSON.stringify(message);
        Office.context.ui.messageParent(message);
    }
}
