<div class="page container-fluid" *ngIf="loading">
    <app-loader></app-loader>
</div>
<div class="page container-fluid" *ngIf="!mailSent">
    <div class="row h-100">
        <div class="right-content col-sm-12 p-5">
            <div class="component-wrap pb-3">
                <div class="form-wrap" *ngIf="!loading && !samlLoginInProgress">
                    <section>
                        <a class="d-flex justify-content-around" routerLink="/home">
                            <div class="ml-3 jioevent-text text-capitalize">
                                {{ 'forgot-password.forgot_password' | translate }}
                            </div>
                        </a>
                        <div class="close" (click)="close()">
                            <img src="assets/img/close_icon.svg" />
                        </div>
                        <div class="note mt-3">{{ 'forgot-password.enter_mail_to_get_started' | translate }}</div>
                        <form [formGroup]="resetpasswordForm" class="custom-form login-details">
                            <div class="form-group">
                                <label *ngIf="!!resetpasswordForm.value.email">Email ID </label>
                                <input
                                    #emailOrMobileNumberInput
                                    type="text"
                                    class="form-control"
                                    id="username"
                                    formControlName="email"
                                    aria-describedby="usernameHelp"
                                    placeholder="Enter your Email ID"
                                    (keyup.enter)="submitUsername()"
                                />
                                <span class="d-inline-block mt-2 invalid-feedback" *ngIf="errors.username">{{
                                    errors.username
                                }}</span>
                                <span class="d-inline-block mt-2 text-error isinvalid" *ngIf="errors.global">{{
                                    errors.global
                                }}</span>
                            </div>
                            <button
                                type="button"
                                id="proceedButton"
                                class="custom-button bg-login my-3"
                                [class.disabled]="!enableProceedBtn"
                                (click)="submitUsername()"
                            >
                                {{ 'signin_page.proceed' | translate }}
                            </button>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page container-fluid scrollable" *ngIf="mailSent && !loading">
    <div class="signup-component post-email">
        <div class="header" *ngIf="!unVerifiedAccount">
            {{ 'forgot-password.email_sent' | translate }}
        </div>
        <div class="header" *ngIf="unVerifiedAccount">{{ 'email_verification.waiting' | translate }}</div>
        <div class="img-wrap" style="padding-top: 40px">
            <img src="assets/img/verify_mail_sent.svg" />
        </div>
        <div class="top-text py-4">
            <div class="d-flex flex-wrap align-items-center justify-content-around">
                <span class="note" style="font-size: 16px">{{ 'forgot-password.email_sent_full' | translate }}</span>
                <div class="align-items-center email-n-edit ml-2">
                    <span class="email-text">{{ resetpasswordForm.get('email').value }}</span>
                    <span class="ml-4 pointer edit-action-btn" (click)="editEmail()">
                        <img src="assets/img/edit_riptid_icon.svg" />
                        <span class="ml-2 riptide-btn font-weight-normal">{{ 'signin_page.edit' | translate }}</span>
                    </span>
                </div>
            </div>
            <div class="note mx-auto" style="font-size: 16px">
                {{ 'forgot-password.follow_instructions_reset_password' | translate }}
            </div>
        </div>
        <div class="bottom-text mt-5">
            <div class="mail-bottom-text">
                {{ 'email_verification.havent' | translate }}
                <a class="pointer riptide-btn d-inline ml-2 no-select" (click)="sendEmail()">{{
                    'email_verification.resend' | translate
                }}</a>
            </div>
        </div>
        <div class="sign-in">
            <a class="pointer riptide-btn" (click)="navigateToSignIn()">{{
                'email_verification.signin' | translate
            }}</a>
        </div>
    </div>
</div>
