import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UtilService } from 'src/app/core';

declare var $: any;
declare const document: any;
declare const Quill: any;
@Component({
    selector: 'app-chat-markdown',
    templateUrl: './chat-markdown.component.html',
    styleUrls: ['./chat-markdown.component.scss']
})
export class ChatMarkdownComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('content') content: ElementRef;
    @Input() selectedEmoji;
    @Input() showMarkdown;
    @Output() sendmessage = new EventEmitter();
    @Input() insideChatPanel: boolean = false;
    @Input() initalText: string = '';
    @Input() isusedLiveChat: boolean = false;
    public quill: any;
    isMobileDevice;

    constructor(private translate: TranslateService, private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobileDevice = this.utilService.isMobileBrowser();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.initializeQuillEditor();
        }, 200);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.showMarkdown) {
            const nodes = document.querySelectorAll('.ql-toolbar');
            nodes.forEach((toolBar) => {
                if (toolBar) {
                    toolBar.style.display = this.showMarkdown ? 'block' : 'none';
                }
            });
        }
    }

    initializeQuillEditor() {
        /** Change here for custom toolbar icons */
        // var icons = Quill.import('ui/icons');
        // icons['bold'] = '<i class="fa fa-bold" aria-hidden="true"></i>';
        // icons['italic'] = '<i class="fa fa-italic" aria-hidden="true"></i>';
        // icons['underline'] = '<i class="fa fa-underline" aria-hidden="true"></i>';
        // icons['image'] = '<i class="fa fa-picture-o" aria-hidden="true"></i>';
        // icons['code'] = '<i class="fa fa-code" aria-hidden="true"></i>';
        var options = {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }]
                ]
            },
            placeholder: this.translate.instant('inside_call.chat.type_here'),
            theme: 'snow',
            formats: ['bold', 'header', 'italic', 'strike', 'link', 'list', 'blockquote', 'indent']
        };
        this.quill = new Quill(this.content.nativeElement, options);
        this.appendSubmitButton();
        if (this.initalText) {
            const caretPosition = this.quill.getSelection(true);
            this.quill.insertText(caretPosition, this.initalText);
        }
        this.quill.keyboard.bindings[13].unshift({
            key: 13,
            handler: (range, context) => {
                if (!this.showMarkdown && !this.isMobileDevice) {
                    this.sendTheMessage();
                    return false;
                }
                return true;
            }
        });
        this.quill.focus();
    }

    appendSubmitButton() {
        let submitBtnNode = document.createElement('img');
        submitBtnNode.className = 'd-flex ml-1 pointer send-img';
        submitBtnNode.height = 18;
        submitBtnNode.width = 18;
        submitBtnNode.alt = '';
        submitBtnNode.id = 'send-chat-btn';
        submitBtnNode.src = 'assets/img/jioevents/send_btn_dark_theme.svg';
        this.content.nativeElement.appendChild(submitBtnNode);
        submitBtnNode.addEventListener('click', (e) => {
            this.sendTheMessage();
        });
    }

    sendTheMessage() {
        this.sendmessage.next(true);
    }

    onDrop(event) {
        event.preventDefault();
        return false;
    }

    insertEmoji(event) {
        this.quill.focus();
        const caretPosition = this.quill.getSelection(true);
        this.quill.insertText(caretPosition, event.char);
    }

    clearMeessage() {
        this.quill.setContents([]);
        this.quill.setText('');
    }
}
