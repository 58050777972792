import { AfterViewInit, Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventDataSource } from 'src/app/constants/webinar-enum';
import { AppService, SocketEvent, SocketService } from 'src/app/core';
import { WEBINAR_ATTENDEE_SOCKET_EVENTS } from '../../../webinar-attendee/constants/webinar-attendee-socket-events';
import * as _ from 'lodash';
import { WEBINAR_CONFERENCE_SOCKET_EVENTS } from 'src/app/conference/constants/webinar-conference-socket-events';

@Component({
    selector: 'app-stopwatch-timer',
    templateUrl: './stopwatch-timer.component.html',
    styleUrls: ['./stopwatch-timer.component.scss']
})
export class StopwatchTimerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    stopwatchTimer = {
        minute: 0,
        second: 0,
        active: false
    };
    stopwatchInterval;
    @Input() isAgoraWebinar;
    @Input() timerPollingData;
    @Input() enablePipMode;
    @Input() mobileSpeaker = false;
    prevTimerPollingData;
    constructor(
        private socketService: SocketService,
        private zone: NgZone,
        private toastrService: ToastrService,
        private appService: AppService
    ) {}

    ngOnInit(): void {
        console.log('1', 'stopwatchtimer');
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['enablePipMode']) {
            return;
        }
        //  if (changes['timerPollingData']?.currentValue) {
        const currentTimerPayload = changes['timerPollingData']?.currentValue;
        // call timer event only when state is different from prev state
        if (_.isEqual(this.prevTimerPollingData, currentTimerPayload)) {
            return;
        }
        if (currentTimerPayload) {
            const timerPayload: SocketEvent = {
                event: WEBINAR_ATTENDEE_SOCKET_EVENTS.TIMER,
                data: currentTimerPayload || {}
            };
            this.prevTimerPollingData = currentTimerPayload;
            this.handleEventStateUpdate(timerPayload, EventDataSource.POLLING);
        } else {
            // stop the time
            this.stopTimer();
        }
        // }
    }

    ngAfterViewInit() {
        this.socketService.dataEvents$.subscribe(this.handleEventStateUpdate.bind(this));
    }

    handleEventStateUpdate(event: SocketEvent, source = EventDataSource.WEBSOCKET) {
        var selfRef = this;
        this.zone.run(() => {
            if (
                event?.event == WEBINAR_ATTENDEE_SOCKET_EVENTS.TIMER ||
                (this.mobileSpeaker && event?.event == WEBINAR_CONFERENCE_SOCKET_EVENTS.TIMER)
            )
                this.toastrService.clear();
            this.prevTimerPollingData = event.data;
            let setTime = event?.data?.setTime;
            let timeInSecondsTotal = setTime?.includes('-')
                ? -1 * (Number(Math.abs(setTime?.split(':')[0] * 60)) + Number(setTime?.split(':')[1]))
                : Number(Math.abs(setTime?.split(':')[0] * 60)) + Number(setTime?.split(':')[1]); // timer set by host
            let timeInSeconds = timeInSecondsTotal;
            if (source === EventDataSource.POLLING) {
                // calculate the remaining timer time
                const timeDiffFromSet = (+new Date() - +new Date(event.data.eventTime)) / 1000; // time which is lapsed till now
                timeInSeconds = event?.data?.eventTime ? timeInSecondsTotal - timeDiffFromSet : timeInSecondsTotal;
            }
            // time which is remaining for which to run timer
            setTimeout(
                () => {
                    this.toastrService.clear();
                    switch (event.data.state) {
                        case 'start':
                            this.toastrService.info(
                                `${
                                    event.data?.refresh
                                        ? 'Timer has refreshed '
                                        : event.data?.update
                                        ? 'Timer has updated'
                                        : 'Timer has started  '
                                }`
                            );
                            if (this.stopwatchTimer?.active) {
                                clearInterval(this.stopwatchInterval);
                            }
                            this.stopwatchInterval = setInterval(() => {
                                if (selfRef.timerPollingData) {
                                    const timeDiffFromSet = (+new Date() - +new Date(event.data.eventTime)) / 1000; // time which is lapsed till now
                                    let timeInSeconds2 = event?.data?.eventTime
                                        ? timeInSecondsTotal - timeDiffFromSet
                                        : timeInSecondsTotal;
                                    this.stopwatchTimer.minute =
                                        timeInSeconds2 > 0
                                            ? Math.floor(timeInSeconds2 / 60) || 0
                                            : Math.ceil(timeInSeconds2 / 60) || 0;
                                    this.stopwatchTimer.second = Math.floor(timeInSeconds2 % 60) || 0;
                                    if (this.stopwatchTimer.second === 0) {
                                        this.toastrService.info(`Timer has elapsed`);
                                    }
                                    timeInSeconds--;
                                    this.stopwatchTimer.active = true;
                                } else {
                                    if (this.stopwatchTimer.active) {
                                        this.toastrService.info(`Timer has stopped`);
                                        this.stopwatchTimer.active = false;
                                    }
                                    clearInterval(this.stopwatchInterval);
                                    this.prevTimerPollingData = null;
                                }
                            }, 1000);
                            break;
                        case 'pause':
                            // check if it's a negative timer
                            const timerPrefix = setTime?.includes('-') ? '-' : '';
                            const splitTimer = setTime?.split(':');
                            this.stopwatchTimer.minute = +splitTimer[0];

                            this.stopwatchTimer.second = +`${timerPrefix}${splitTimer[1]}`;
                            this.stopwatchTimer.active = true;
                            this.toastrService.info(`Timer has paused`);
                            clearInterval(this.stopwatchInterval);
                            break;
                        case 'stop':
                            this.stopTimer();
                            break;
                    }
                },
                this.isAgoraWebinar ? 0 : 1 * 1000 || 0 //changing this because that 'attendee_offset' config has 7secs delay
            );
        });
    }
    formatTimer(time) {
        let isBaseNegative = time?.second < 0 || time?.minute < 0 ? true : false;
        return (
            (isBaseNegative ? '+' : '') +
            (Math?.abs(time?.minute) < 10 ? '0' + Math?.abs(time?.minute) : Math?.abs(time?.minute)) +
            ':' +
            (Math?.abs(time?.second) < 10 ? '0' + Math?.abs(time?.second) : Math?.abs(time?.second))
        );
    }

    stopTimer() {
        if (this.stopwatchTimer.active) {
            this.toastrService.info(`Timer has stopped`);
            this.stopwatchTimer.active = false;
            clearInterval(this.stopwatchInterval);
        }
    }

    ngOnDestroy() {
        clearInterval(this.stopwatchInterval);
    }
}
