<div class="text-editor-wrapper my-2" [class.use-new-styling]="applyRevampStyles">
    <div #content class="content article demo"></div>
</div>
<div class="text-right limit" *ngIf="quill">{{ quill.getText().replace('\n', '')?.length }}/{{ maxLength }}</div>
<button
    class="custom-button bg-login mt-3 btn-save"
    (click)="saveBtnClickChanges()"
    [class.disabled]="!quill.getText().replace('\n', '')?.length"
    *ngIf="quill && addSubmitBtn"
    id="publishTickerBtn"
>
    {{ submitString }}
</button>
<app-add-link-popup
    *ngIf="showLinkPopup"
    (close)="toggleLinkPopup()"
    (hyperLink)="receiveMessage($event)"
    (removeLink)="removeLink($event)"
    [selectionContent]="selectionContent"
    [wordCount]="quill.getText().replace('\n', '')?.length - selectionContent?.text?.length"
    [maxLength]="maxLength"
    [editorContent]="quill?.container?.firstElementChild?.innerHTML"
    [insideCall]="insideCall"
>
</app-add-link-popup>
