<div class="reaction-controls-wrapper-component">
    <div class="moreOptionMenu" (click)="reactionBartoggle()" (outside)="reactionbarOutside()" id="toggleReactionBtn">
        <img
            class="reactions-toggle-icon"
            [class.nwRoom]="callStateManagerService?.networkingRoom"
            [class.active]="reactionBarStatus"
            pTooltip="Reactions"
            tooltipPosition="top"
            tooltipStyleClass="tooltip-revamp"
            [tooltipDisabled]="!!reactionBarStatus"
            *ngIf="!likeStatus.state"
        />
        <img
            class="reacted-icon"
            [class.nwRoom]="callStateManagerService?.networkingRoom"
            [class.active]="reactionBarStatus"
            pTooltip="Reactions"
            tooltipPosition="top"
            tooltipStyleClass="tooltip-revamp"
            [tooltipDisabled]="!!reactionBarStatus"
            *ngIf="likeStatus.state"
            src="assets/images/attendee_emojis/new_icons/{{ likeStatus.type }}.svg"
        />
        <div
            role="menu"
            class="reactions-menu"
            id="reaction-bar"
            aria-labelledby="likeButton"
            *ngIf="reactionBarStatus"
        >
            <ng-container *ngFor="let options of reactions; let i = index">
                <div
                    (click)="updateReactedEmoji(options)"
                    class="emoji-wrapper mr-2 img-wrap"
                    pTooltip="{{ reactions[i] | titlecase }}"
                    tooltipPosition="top"
                    tooltipStyleClass="secondary-bg reaction-tooltip"
                >
                    <img class="static-reac" src="assets/images/attendee_emojis/new_icons/{{ options }}.svg" />
                    <img
                        class="anim-img {{ options }}"
                        src="assets/images/attendee_emojis/animate/{{ options }}.webp"
                    />
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="user-reactions-area" id="user-floating-area">
    <div class="inside-area"></div>
</div>
