// Reference https://github.com/riteshgandhi/ng-star-rating

import {
    Component,
    OnInit,
    EventEmitter,
    ViewChild,
    ElementRef,
    Output,
    ViewEncapsulation,
    Input
} from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class RatingComponent implements OnInit {
    @ViewChild('ratingContainer', { static: true }) container: ElementRef;

    @Input() private value = 0;
    @Output() rate: EventEmitter<any> = new EventEmitter();

    private stars: Array<Element> = [];

    ngOnInit() {
        this.setStars();
        this.generateRating();
        this.addEvents();
    }

    private setStars() {
        let starContainer: HTMLDivElement = this.container.nativeElement;
        let maxStars = [...Array(5).keys()];
        this.stars.length = 0;
        starContainer.innerHTML = '';
        maxStars.forEach((starNumber) => {
            let starElement: HTMLSpanElement = document.createElement('span');
            starElement.dataset.index = (starNumber + 1).toString();
            starElement.title = starElement.dataset.index;
            starElement.setAttribute('tabindex', '0');
            starElement.setAttribute('aria-label', 'star');
            starElement.setAttribute('role', 'button');
            starContainer.appendChild(starElement);
            this.stars.push(starElement);
        });
    }

    private addEvents() {
        if (!this.container) return;
        this.container.nativeElement.addEventListener('mouseleave', this.offStar.bind(this));
        this.container.nativeElement.style.cursor = 'pointer';
        this.container.nativeElement.title = this.value;
        this.stars.forEach((star: any) => {
            star.addEventListener('click', this.onRate.bind(this));
            star.addEventListener('mouseenter', this.onStar.bind(this));
            star.addEventListener('click', this.onStar.bind(this));
            star.style.cursor = 'pointer';
            star.title = star.dataset.index;
        });
    }

    private onRate(event: MouseEvent) {
        let star: HTMLElement = <HTMLElement>event.srcElement;
        let oldValue = this.value;
        this.value = parseInt(star.dataset.index);
        let rateValues = { oldValue: oldValue, newValue: this.value, starRating: this };
        this.rate.emit(rateValues);
    }

    private onStar(event: MouseEvent) {
        let star: HTMLElement = <HTMLElement>event.srcElement;
        let currentIndex = parseInt(star.dataset.index);

        for (let index = 0; index < currentIndex; index++) {
            this.stars[index].className = '';
            this.addDefaultClass(this.stars[index]);
            if (currentIndex <= 3) {
                this.addLowClass(this.stars[index]);
            } else {
                this.addCheckedStarClass(this.stars[index]);
            }
        }

        for (let index = currentIndex; index < this.stars.length; index++) {
            this.stars[index].className = '';
            this.addDefaultClass(this.stars[index]);
        }
    }

    private offStar(event: MouseEvent) {
        this.generateRating();
    }

    private addDefaultClass(star: any) {
        star.classList.add('star');
    }

    private addCheckedStarClass(star: any) {
        star.classList.add('on');
    }

    private addLowClass(star: any) {
        star.classList.add('low');
    }

    private generateRating(forceGenerate: boolean = false) {
        if (!this.container) return;

        //if (this.value >= 0) {
        this.stars.length == 0 && this.setStars();
        this.container.nativeElement.title = this.value;

        let hasDecimals: boolean = (Number.parseFloat(this.value.toString()) % 1).toString().substring(3, 2)
            ? true
            : false;

        let i = 1;
        this.stars.forEach((star: any) => {
            star.className = '';
            this.addDefaultClass(star);

            if (this.value >= i) {
                // star on
                if (this.value <= 3) {
                    this.addLowClass(star);
                } else {
                    this.addCheckedStarClass(star);
                }
            } else {
                // half star
                // if (hasDecimals) {
                //   this.addHalfStarClass(star);
                //   hasDecimals = false;
                // }
            }
            i++;
        });
        //}
    }
}
