<div class="page container-fluid">
    <div class="row h-100">
        <div class="right-content d-flex justify-content-center w-100">
            <div class="component-wrap">
                <div class="white-bold d-flex justify-content-center mb-5 text-center">Reset Password</div>
                <div class="d-flex justify-content-center lets-reset text-center">
                    Let’s reset your account password
                </div>

                <div class="form-wrap">
                    <section>
                        <form [formGroup]="changePasswordForm" (submit)="submit()" class="custom-form">
                            <div class="form-group">
                                <label class="m-0" *ngIf="!!changePasswordForm.value.password">{{
                                    'activate_component.password' | translate
                                }}</label>
                                <div class="input-wrap d-flex justify-content-between">
                                    <input
                                        [type]="showPassword ? 'text' : 'password'"
                                        class="form-control"
                                        id="password"
                                        formControlName="password"
                                        [class.is-invalid]="errors.lname"
                                        minlength="1"
                                        maxlength="25"
                                        placeholder="New Password"
                                    />
                                    <img
                                        class="pointer"
                                        src="{{
                                            showPassword
                                                ? 'assets/img/password_view_icon.svg'
                                                : 'assets/img/hide_password_icon.svg'
                                        }}"
                                        (click)="showPassword = !showPassword"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="m-0" *ngIf="!!changePasswordForm.value.confirmPassword">{{
                                    'activate_component.confirmpassword' | translate
                                }}</label>
                                <div
                                    class="input-wrap d-flex justify-content-between"
                                    [class.no-pass-match]="
                                        changePasswordForm.value.password !==
                                            changePasswordForm.value.confirmPassword && isConfirmingPassword
                                    "
                                >
                                    <input
                                        [type]="showConfirmPassword ? 'text' : 'password'"
                                        class="form-control"
                                        id="confirmPassword"
                                        formControlName="confirmPassword"
                                        [class.is-invalid]="errors.lname"
                                        minlength="1"
                                        maxlength="25"
                                        placeholder="Confirm New Password"
                                    />
                                    <img
                                        class="pointer"
                                        src="{{
                                            showConfirmPassword
                                                ? 'assets/img/password_view_icon.svg'
                                                : 'assets/img/hide_password_icon.svg'
                                        }}"
                                        (click)="showConfirmPassword = !showConfirmPassword"
                                    />
                                </div>
                                <div
                                    class="mt-2 text-error d-flex align-items-center"
                                    *ngIf="
                                        changePasswordForm.value.password !==
                                            changePasswordForm.value.confirmPassword && isConfirmingPassword
                                    "
                                    style="height: 16px"
                                >
                                    <img class="mr-2" src="assets/img/wrong_red_icon.svg" width="15" height="15" />
                                    {{ 'password_criteria.password_no_match' | translate }}
                                </div>
                            </div>

                            <ng-container *ngIf="changePasswordForm.value.password">
                                <div class="password-hint">
                                    <p class="password-info">{{ 'password_criteria.must_contain' | translate }}</p>
                                    <div>
                                        <div class="criteria-row">
                                            <img
                                                src="{{
                                                    !errors?.password?.includes('lengthError')
                                                        ? 'assets/img/jioconf/ul_green_tick_icon.svg'
                                                        : isConfirmingPassword
                                                        ? 'assets/img/password_error_icon.svg'
                                                        : 'assets/img/ul_info_icon.svg'
                                                }}"
                                            />
                                            <span>{{
                                                'password_criteria.characters' | translate: txParams?.characters
                                            }}</span>
                                        </div>
                                        <div class="criteria-row">
                                            <img
                                                src="{{
                                                    !errors?.password?.includes('dualError')
                                                        ? 'assets/img/jioconf/ul_green_tick_icon.svg'
                                                        : isConfirmingPassword
                                                        ? 'assets/img/password_error_icon.svg'
                                                        : 'assets/img/ul_info_icon.svg'
                                                }}"
                                            />
                                            <span>{{ 'password_criteria.case' | translate }}</span>
                                        </div>
                                        <div class="criteria-row">
                                            <img
                                                src="{{
                                                    !errors?.password?.includes('numberError')
                                                        ? 'assets/img/jioconf/ul_green_tick_icon.svg'
                                                        : isConfirmingPassword
                                                        ? 'assets/img/password_error_icon.svg'
                                                        : 'assets/img/ul_info_icon.svg'
                                                }}"
                                            />
                                            <span>{{ 'password_criteria.number' | translate: txParams?.numbers }}</span>
                                        </div>
                                        <div class="criteria-row">
                                            <img
                                                src="{{
                                                    !errors?.password?.includes('specialCharsError')
                                                        ? 'assets/img/jioconf/ul_green_tick_icon.svg'
                                                        : isConfirmingPassword
                                                        ? 'assets/img/password_error_icon.svg'
                                                        : 'assets/img/ul_info_icon.svg'
                                                }}"
                                            />
                                            <span>{{ 'password_criteria.special_character' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="mt-2 text-error" *ngIf="errors.global">{{ errors.global }}</div>
                            <div class="col p-0 w-100 mt-4" style="margin-bottom: 60px">
                                <button
                                    type="button"
                                    class="custom-button bg-login my-3"
                                    [class.disabled]="!validPassword || !changePasswordForm.valid"
                                    (click)="submit()"
                                >
                                    {{ 'reset-password.reset_password' | translate }}
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
