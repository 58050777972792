import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { ToastrService } from 'ngx-toastr';
import { MEDIA_ENGINE_EVENTS } from 'src/app/constants/media-engine-events';
import {
    AppService,
    DiagnosticService,
    RoomConnectionService,
    RtcService,
    UtilService,
    VideoWrapperService
} from 'src/app/core';
import { WebinarConferenceService } from '../../../conference/services';
import { ConfirmationService } from 'primeng/api';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { forkJoin } from 'rxjs';
import { MediaOrchestratorService } from 'src/app/shared/services/media-orchestrator.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

import { INSIDE_EVENT } from '../../../constants';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { LeaderboardService } from 'src/app/core/services/leaderboard.service';

@Component({
    selector: 'app-cloud-video-preview',
    templateUrl: './cloud-video-preview.component.html',
    styleUrls: ['./cloud-video-preview.component.scss']
})
export class CloudVideoPreviewComponent implements OnInit {
    @Input() meetingObj;
    @Output() afterClose = new EventEmitter();
    @Input() cloudPlayerConfig;

    mediaFiles = [];
    videoUrlFile = [];
    mediaId: string;
    loading: boolean = false;
    uploadVideo: boolean = false;
    videoUrl: string = '';
    latestVideoUrl: string = '';
    mediaSelected;
    buttonDisabled: boolean = true;
    invalidUrlError: string = '';
    showErrorMessage: boolean = false;
    invalidUrl: boolean = false;
    private videoService;
    cloudVideoMaxSize;
    uploadingFiles$: any = {};
    gtmMeetingInfo: any = {};
    firstRotationAngle = 0;
    secondRotationAngle = 90;
    showMiniBar: boolean = false;
    uploadInProgress: boolean = false;
    uploadingFiles = [];
    displayFileSize: any = '10GB';
    videoduration: string;
    videoToolTipMessage: string;
    constructor(
        private webinarConfService: WebinarConferenceService,
        private activatedRoute: ActivatedRoute,
        private webinarService: WebinarService,
        private videoWrapperService: VideoWrapperService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private appService: AppService,
        private roomConnectionService: RoomConnectionService,
        private confirmationService: ConfirmationService,
        private diagnosticService: DiagnosticService,
        private appLoggerService: AppLoggerService,
        private mediaOrchestratorService: MediaOrchestratorService,
        private googleTagManager: GoogleTagMangerService,
        private callStateManagerService: CallStateManagerService,
        private utilService: UtilService,
        private leaderboardService: LeaderboardService,
        private rtcservice: RtcService
    ) {
        this.videoService = this.videoWrapperService.getVideoService();
        this.cloudVideoMaxSize = this.appService.getConfigVariable('jioConfConfig')?.cloudVideoMaxSize || 10240;
        if (this.cloudVideoMaxSize) {
            this.displayFileSize = this.utilService.fileSizeToText(this.cloudVideoMaxSize);
        }
    }

    ngOnInit(): void {
        this.meetingObj = this.callStateManagerService.getCurrentMeetingObject();
        this.getMediaFiles();
        this.videoToolTipMessage =
            `• File Type: .mp4, File Size: Upto ${this.displayFileSize}\n` +
            '• Resolution: Recommended 1280x720p, 16:9 Ratio\n' +
            '• Note: Above the 720p video resolution files, attendees can experience a buffer problem.';

        this.gtmMeetingInfo = {
            meeting_Id: this.meetingObj?.meetingInfo._id,
            Category: this.meetingObj?.meetingInfo?.jioConf?.eventType,
            expected_attendee_count: this.meetingObj?.meetingInfo?.jioConf?.expectedAttendeeCount,
            tags: this.meetingObj?.meetingInfo?.jioConf?.tags
        };
    }

    validateUrl(): void {
        // const trimmedUrl = this.videoUrl.trim();
        this.buttonDisabled = false;
        this.showErrorMessage = false;
    }

    setInvalidUrl(errorMessage) {
        this.invalidUrl = true;
        this.invalidUrlError = errorMessage;
        this.showErrorMessage = true;
        this.buttonDisabled = true;
    }

    addVideo() {
        if (this.buttonDisabled) return;
        const currentMeetingId = this.callStateManagerService.getCurrentMeetingId();
        this.invalidUrl = false;
        this.showErrorMessage = false;
        this.buttonDisabled = true;
        this.webinarConfService.videoUrl(this.videoUrl, currentMeetingId).subscribe(
            (res: any) => {
                console.log(res);
                const mediaId = res.mediaId;
                this.videoUrlFile.push({ url: this.videoUrl, mediaId });
                this.uploadVideo = true;
                this.videoUrl = '';
                this.buttonDisabled = false;
            },
            (error) => {
                this.setInvalidUrl(
                    'URL must be available in public domain with valid content, HTTPS protocol and .mp4 format'
                );
            }
        );
        // };
        this.invalidUrlError = '';
    }

    toggleMiniPreview() {
        if (this.uploadingFiles.length === 0) {
            this.closeModal(null);
            return;
        }
        this.showMiniBar = !this.showMiniBar;
    }

    confirmShareMediaRequest(selectedMediaId) {
        this.confirmationService.confirm({
            message: `
            <span class="uplod-cancel-container">
             <span class="cancelfile-header">Confirm Play?</span>
             <span class="cancel-txt">Are you sure you want to play this video?</span>
            </span>
            `,
            header: '',
            acceptLabel: 'Yes',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg cloud-video-accept',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            accept: () => {
                this.shareMedia(selectedMediaId);
            }
        });
    }

    getMediaFiles() {
        const parentMeetingId = this.meetingObj?.meetingInfo?.parentMeetingId; // this only happens when this is a session
        const currentMeetingId = this.callStateManagerService.getCurrentMeetingId();
        const fetchMediaObservers = [this.webinarConfService.getMediaList(currentMeetingId)];

        if (parentMeetingId) {
            fetchMediaObservers.push(this.webinarConfService.getMediaList(parentMeetingId));
        }

        forkJoin(fetchMediaObservers).subscribe(
            ([currentMeeting, parentMeeting = { list: [] }]) => {
                const totalMedia = [...currentMeeting.list, ...parentMeeting.list];

                this.mediaFiles = totalMedia.map((file) => {
                    file.originalName = decodeURI(file.originalName);
                    return { ...file, uploadSuccess: true };
                });
                this.loading = false;
            },
            (err) => {
                this.appLoggerService.error(err);
            }
        );
    }

    async shareMedia(selectedMediaId) {
        this.leaderboardService.hideOverlayOnShareMedia();
        this.mediaOrchestratorService.sendHostStopShareRtmMessage();
        await this.utilService.holdExecution(1000);
        const localVidyoUser = this.videoService.getLocalParticipant();
        const payload = {
            action: 'start',
            mediaId: selectedMediaId ?? this.mediaSelected,
            jiomeetId: this.meetingObj.jiomeetId,
            participantUri: localVidyoUser.id
        };
        this.cloudPlayerConfig.playWithoutSeek = true;
        this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.SHARE_CLOUD_PLAYER_START, this.gtmMeetingInfo);
        try {
            await this.webinarConfService.shareMedia(payload).toPromise();
            this.diagnosticService
                .sendEvent({
                    eventCategory: 'Jioconf Cloud video Inside Event',
                    eventAction: `Share`,
                    eventType: 'app_event',
                    endpoint: 'media/share',
                    status: 'success'
                })
                .subscribe();
            this.mediaOrchestratorService.softMuteAll(this.meetingObj, 'cloud-preview');
            this.videoService.typeOfVideoShare = 'cloud';
            if (this.rtcservice.isJMMeeting)
                this.webinarConfService
                    .mediaplaySync({
                        mediaId: selectedMediaId ?? this.mediaSelected,
                        jiomeetId: this.meetingObj.jiomeetId,
                        seek: '',
                        paused: false
                    })
                    .subscribe();
            this.closeModal(null);
        } catch (error) {
            console.error('Error sharing the media:', error);
        }
    }

    closeModal(event: MouseEvent) {
        if (event) {
            event.stopPropagation();
        }
        if (this.uploadInProgress) {
            this.confirmationService.confirm({
                message: `
                <span class="uplod-cancel-container">
                 <span class="cancelfile-header">Uploading in progress</span>
                 <span class="cancel-txt">Closing this window will stop the current upload. Do you still want to close the window?</span>
                </span>
                `,
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
                rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
                accept: () => {
                    this.uploadingFiles?.forEach((f) => {
                        this.cancelUpload(f);
                    });
                    this.closeModal(null);
                }
            });
            return;
        }
        this.showMiniBar = false;
        this.afterClose.emit();
    }

    fileChangeEvent(event) {
        this.validateFileResolution(event.target.files[0]);
    }

    handleDropFileEvent(event) {
        if (!event[0]?.name) {
            this.toastrService.error(this.translateService.instant('webinar-tostrs.try_uploading_msg'));
            return;
        }
        this.validateFileResolution(event[0]);
    }

    validateFileResolution(file) {
        if (file?.size / 1024 / 1024 > this.cloudVideoMaxSize) {
            this.toastrService.error(`File size cannot exceed  ${this.displayFileSize}`);
            return;
        }
        if (file && !(file.type === 'video/mp4')) {
            this.toastrService.error('Only .mp4 video files are allowed ');
            return;
        }
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = (e) => {
            window.URL.revokeObjectURL(video.src);
            this.uploadMedia(file);
        };
        video.src = URL.createObjectURL(file);
    }

    uploadMedia(file) {
        this.uploadInProgress = true;
        let uploadedFile = {
            unique: new Date().valueOf(),
            originalName: file.name,
            uploadSuccess: false
        };
        this.uploadingFiles.push(uploadedFile);
        this.mediaFiles = [uploadedFile, ...this.mediaFiles];
        (this.uploadingFiles$[uploadedFile.unique] = this.webinarConfService
            .uploadMediaFile(file, this.meetingObj.meetingId || this.meetingObj._id)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.mediaFiles[this.mediaFiles.findIndex((f) => f.unique === uploadedFile.unique)].progress =
                            Math.round((event.loaded / event.total) * 100);
                        break;
                    case HttpEventType.Response:
                        this.uploadInProgress = false;
                        this.discardFromUploadingFiles(uploadedFile);
                        this.showMiniBar = false;
                        this.mediaFiles[this.mediaFiles.findIndex((f) => f.unique === uploadedFile.unique)].mediaId =
                            event.body.id;
                        this.mediaFiles[
                            this.mediaFiles.findIndex((f) => f.unique === uploadedFile.unique)
                        ].uploadSuccess = true;
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Jioconf Cloud video Inside Event',
                                eventAction: `Upload`,
                                eventType: 'app_event',
                                endpoint: 'media',
                                status: 'success'
                            })
                            .subscribe();
                        break;
                }
            })),
            (err) => {
                this.uploadInProgress = false;
                this.toastrService.error(
                    err.error.errors || this.translateService.instant('webinar-tostrs.went_wrong_msg')
                );
            };
    }

    discardFromUploadingFiles(uploadedFile) {
        this.uploadingFiles = this.uploadingFiles.filter((f) => f.unique !== uploadedFile.unique);
    }

    confirmCancelUpload(file) {
        this.confirmationService.confirm({
            message: `
            <span class="uplod-cancel-container">
             <span class="cancelfile-header">Uploading in progress</span>
             <span class="cancel-txt">Closing this window will stop the current upload. Do you still want to close the window?</span>
            </span>
            `,
            // header: 'Cancel Upload',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            acceptLabel: 'Yes',
            rejectLabel: `No`,
            accept: () => {
                this.cancelUpload(file);
            }
        });
    }

    cancelUpload(file) {
        if (!this.uploadInProgress) {
            this.makeRequestToDeleteMedia(file);
            this.discardFromUploadingFiles(file);
            return;
        }
        this.uploadInProgress = false;
        this.uploadingFiles$[file.unique].unsubscribe();
        this.mediaFiles.splice(
            this.mediaFiles.findIndex((f) => file.unique === f.unique),
            1
        );
        this.uploadingFiles.splice(
            this.uploadingFiles.findIndex((f) => f.unique === file.unique),
            1
        );
    }
    makeRequestToDeleteMedia(file) {
        this.webinarConfService.deleteMediaFile(file.mediaId).subscribe(
            (res) => {
                this.mediaFiles.splice(
                    this.mediaFiles.findIndex((f) => file.mediaId === f.mediaId),
                    1
                );
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Jioconf Cloud video Inside Event',
                        eventAction: `Delete`,
                        eventType: 'app_event',
                        endpoint: 'media',
                        status: 'success'
                    })
                    .subscribe();
            },
            (err) => {
                this.toastrService.error(
                    err.error.errors || this.translateService.instant('webinar-tostrs.went_wrong_msg')
                );
            }
        );
    }
    makeRequestToDeleteUrl(mediaId) {
        this.webinarConfService.deleteMediaFile(mediaId).subscribe(
            (res) => {
                this.videoUrlFile.splice(
                    this.videoUrlFile.findIndex((urlfile) => mediaId === urlfile.mediaId),
                    1
                );
                console.log(this.videoUrlFile);
            },
            (err) => {
                this.toastrService.error(
                    err.error.errors || this.translateService.instant('webinar-tostrs.went_wrong_msg')
                );
            }
        );
    }

    deleteMediaFile(file) {
        //file = this.mediaFileDelete;
        this.confirmationService.confirm({
            header: 'Delete Video?',
            message: 'Are you sure you want to delete the video?',
            acceptLabel: 'Delete',
            rejectLabel: "Don't Delete",
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            accept: () => {
                this.makeRequestToDeleteMedia(file);
            }
        });
    }

    deleteVideoUrl(mediaId) {
        //file = this.mediaFileDelete;
        this.confirmationService.confirm({
            header: 'Delete Video Url?',
            message: 'Are you sure you want to delete the video ?',
            acceptLabel: 'Delete',
            rejectLabel: "Don't Delete",
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            accept: () => {
                this.makeRequestToDeleteUrl(mediaId);
            }
        });
    }
    ngOnDestroy() {
        Object.keys(this.uploadingFiles$).map((s) => {
            this.uploadingFiles$[s].unsubscribe();
        });
    }
}
