import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'JSONFILTER' })
export class ObjectFilterPipe implements PipeTransform {
    transform(value: any, searchValue: String, key: string) {
        if (searchValue) {
            searchValue = searchValue.toLocaleLowerCase();
        }
        if (key && key.length > 0 && searchValue && searchValue.length > 0) {
            return value.filter((p) => p[key].includes(searchValue));
        } else {
            return value;
        }
    }
}
