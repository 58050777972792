<div class="participant-tile-wrapper">
    <div
        class="speaker-initials"
        pTooltip="{{ participantMeta.name }}"
        tooltipPosition="top"
        tooltipStyleClass="secondary-bg"
        [ngStyle]="{
            'background-color': participantMeta.bgColor
        }"
    >
        <span class="text-initials" *ngIf="!participantMeta?.image">{{ participantMeta.initials }}</span>
        <img class="profile-image" [src]="participantMeta?.image" *ngIf="participantMeta?.image" />
    </div>
    <video class="video-container" [id]="videoContainerId"></video>
    <div class="tile-top-right overlay-element"></div>
    <div class="tile-bottom-left overlay-element">
        <div class="participant-name-ctn" pTooltip="{{ participantMeta.name }}" tooltipPosition="top">
            {{ participantMeta.name }}
        </div>
    </div>
    <div class="tile-bottom-right overlay-element"></div>
</div>
