import { Component, Input, OnInit, EventEmitter, SimpleChange, ViewChild, ElementRef, Output } from '@angular/core';
import moment from 'moment-timezone';
import { AppService, UserService, ValidationService } from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddonsService } from 'src/app/core/services/addons.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { WebinarConferenceService } from 'src/app/conference/services';
@Component({
    selector: 'app-feed-card',
    templateUrl: './feed-card.component.html',
    styleUrls: ['./feed-card.component.scss']
})
export class FeedCardComponent implements OnInit {
    @ViewChild('commentTextarea') commentTextarea: ElementRef;
    @Input() postDetails;
    @Input() webinarId;
    @Input() isWinner;
    @Input() rank;
    @Input() showOverlay;
    @Input() isMobile;
    @Input() attendeesList;
    @Input() currentUser;
    @Input() contestId;
    @Input() isEnded;
    @Input() userIds;
    @Input() totalWinners = 0;
    @Input() isGetEvent;
    @Output() refresh: EventEmitter<any> = new EventEmitter();
    @Output() commentClick: EventEmitter<any> = new EventEmitter();
    @Output() toggleMedia = new EventEmitter();
    postCreator;
    showAllUploadedMedia = false;
    displayMedia = false;
    mediaUrlTobeDisplayed = '';
    mediaTypeToBeDisplyed = '';
    showCommentSection = false;
    commentProfilePicPath: any;
    postLiked = false;
    likesCount: Number = 0;
    messageComponent: any;
    mediaUrlList: any;
    timeTobeDisplayed: any;
    isPlaying = true;
    hostUrl;
    isPostIdUpdated: any;
    profilePicPath;
    commentsForm: FormGroup;
    inputRows: any;
    maxCharLimit = 256;
    hasLikedAlready: boolean = false;
    showDeletePopup: boolean = false;
    dialogData: any;
    commentList = [];
    colleagueDetailsStrapi;
    businessUnit;
    location;
    constructor(
        private appService: AppService,
        private userService: UserService,
        private fb: FormBuilder,
        private addonsService: AddonsService,
        private toastrService: ToastrService,
        private validationService: ValidationService,
        private webinarConferenceService: WebinarConferenceService,
        private addOnService: AddonsService
    ) {}

    ngOnInit(): void {
        if (this.isGetEvent && this.isWinner) {
            this.getColleagueDataFromStrapi(this.postDetails.creator);
        }
        this.postCreator = this.postDetails.creator;
        this.messageComponent = this.postDetails.attributes;
        this.mediaUrlList = this.messageComponent.attachments.data || [];
        if (this.mediaUrlList?.length > 1) {
            this.mediaUrlList.splice(0, 1);
        }
        this.hostUrl = this.appService.getEnvVariable('HOST_URL');
        this.setTimePassedForPost(this.messageComponent.createdAt);
        this.commentsForm = this.fb.group({
            comments: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
        });
        this.profilePicPath = _.get(this.currentUser, 'isGuest')
            ? ''
            : this.userService.getProfilePicPath(this.postCreator.userId);
        this.postCreator.profilePicPath = this.profilePicPath;
        this.commentProfilePicPath = this.userService.getProfilePicPath(this.currentUser._id);
        var likedEntry = _.filter(this.messageComponent.contest_entry_likes.data, [
            'attributes.userId',
            this.currentUser._id
        ]);
        if (likedEntry?.length > 0) {
            this.hasLikedAlready = true;
            this.postLiked = true;
        }
    }

    getColleagueDataFromStrapi(user) {
        this.colleagueDetailsStrapi = [];
        this.addOnService.getKnowYourColleagueData(user?.userId).subscribe(
            (res) => {
                this.colleagueDetailsStrapi = res;
                if (this.colleagueDetailsStrapi) {
                    this.businessUnit = this.colleagueDetailsStrapi?.data[0]?.attributes?.businessName;
                    this.location = this.colleagueDetailsStrapi?.data[0]?.attributes?.location;
                }
            },
            (err) => {
                this.toastrService.error(err?.error?.error?.message);
            }
        );
    }

    updateAttendeeList() {
        this.webinarConferenceService.getAttendeesByIds(this.webinarId, true, Object.keys(this.userIds)).subscribe(
            (res: any) => {
                this.attendeesList = res;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    setTimePassedForPost(postedTime) {
        const curTime = moment();
        const timeDiff = moment.duration(moment(curTime).diff(postedTime));
        const dayDiff = Math.floor(timeDiff.asDays());
        const hourDiff = Math.floor(timeDiff.asHours());
        const minutesDiff = Math.floor(timeDiff.asMinutes());
        this.timeTobeDisplayed = `${
            dayDiff > 0 ? dayDiff + ' days' : hourDiff > 0 ? hourDiff + ' hours' : minutesDiff + ' minutes'
        } ago`;

        if (minutesDiff <= 0) {
            this.timeTobeDisplayed = 'Just Now';
        }
    }

    ngOnchanges(changes: SimpleChange) {
        this.setTimePassedForPost(this.messageComponent.createdAt);
    }

    toggleDisplayMedia(media, event?) {
        if (!event?.target?.paused) {
            event?.target.pause();
        }
        // this.likesCount = this.postDetails.messageComponent.reactions.length();
        this.mediaTypeToBeDisplyed = media.attributes.mediaType;
        this.mediaUrlTobeDisplayed = media.attributes.url;
        this.displayMedia = true;
        this.toggleMedia.emit({
            mediaType: this.mediaTypeToBeDisplyed,
            url: this.mediaUrlTobeDisplayed
        });
    }

    toggleLike() {
        if (!this.postLiked && !this.isEnded) {
            const payload = {
                data: {
                    userId: this.currentUser._id,
                    like: this.postLiked,
                    contest_entry: this.postDetails.id
                }
            };
            this.addonsService.updateLikeOrUnlikeOfContestEntry(payload).subscribe(
                (data) => {
                    console.log(`contest like submit.....${data.data}`);
                    this.postLiked = true;
                    this.getContest();
                },
                (err) => {
                    console.log('like update err', err);
                }
            );
        }
    }

    getComments(showLatestComments) {
        this.addonsService.getCommentsOfContestEntry(this.postDetails.id, Date.now()).subscribe(
            (res) => {
                if (res.data) {
                    this.commentList = res.data;
                    this.messageComponent['contest_entry_comments']['data'] = this.commentList;
                    if (this.messageComponent?.contest_entry_comments.data?.length > 0) {
                        _.forEach(this.messageComponent.contest_entry_comments.data, (comment) => {
                            var commentor = _.filter(this.attendeesList, ['userId', comment?.attributes?.userId]);
                            comment['creator'] = {
                                userId: comment?.attributes?.userId,
                                creatorName:
                                    commentor.length && _.get(commentor[0], 'firstName')
                                        ? `${commentor[0].firstName} ${commentor[0].lastName}`
                                        : 'Jio Events'
                            };
                        });
                    }
                    if (this.messageComponent?.attachments?.data?.length) {
                        _.forEach(this.messageComponent.attachments.data, (attachment) => {
                            attachment.attributes['mediaType'] = _.includes(attachment.attributes.mime, 'video')
                                ? 'video'
                                : 'image';
                        });
                    }
                    if (showLatestComments) {
                        this.postDetails.attributes = this.messageComponent;
                        this.showCommentSection = !this.showCommentSection;
                        this.commentClick.emit(this.showCommentSection);
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getContest(showLatestComments?) {
        this.addonsService.getContestEntry(this.postDetails.id).subscribe(
            async (data) => {
                this.messageComponent = data.data.attributes;
                await this.getComments(showLatestComments);
            },
            (err) => {
                console.log('get contest data err', err);
            }
        );
    }

    submitComment(event?) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        if (this.commentsForm.valid && this.commentsForm?.value?.comments) {
            let message = this.commentsForm?.value?.comments.replace(/\n{2,}/g, '');
            const payload = {
                data: {
                    userId: this.currentUser._id,
                    comment: message,
                    contest_entry: this.postDetails.id
                }
            };
            this.addonsService.postCommentForContestEntry(payload).subscribe(
                async (data) => {
                    await this.updateAttendeeList();
                    console.log(`contest comment submit.....${data.data}`);
                    this.commentsForm.get('comments').patchValue('');
                    var textarea = document.getElementById('commentTextarea') as HTMLTextAreaElement | null;
                    if (textarea) {
                        this.inputRows = 0;
                        textarea.setAttribute('style', '');
                        textarea.rows = 1;
                    }
                    this.showCommentSection = false;
                    await this.getContest(false);
                    this.commentClick.emit(this.showCommentSection);
                    this.toastrService.success('Comment submitted successfully');
                },
                (err) => {
                    console.log('comment update err', err);
                }
            );
        }
    }

    async toggleCommentsSection(ev?) {
        this.getContest(true);
    }

    checkCharLimit(event) {
        this.inputRows = this.commentTextarea.nativeElement.rows;
        //hack for mobile browsers max length
        if (window.innerWidth > 768) {
            return;
        }
        if (event.target.value.length >= this.maxCharLimit) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.commentsForm
                .get('comments')
                .setValue(this.commentsForm.value.comments.substring(0, this.maxCharLimit));
            return false;
        }
        this.commentClick.emit(this.showCommentSection);
    }

    commentsEditorFocusChanged() {
        this.commentClick.emit(this.showCommentSection);
    }

    deleteEntry(type?) {
        if (type === 'entry') {
            var entryId = this.postDetails.id;
            this.addonsService.deleteEntryOfContest(entryId, this.contestId).subscribe(
                (data) => {
                    console.log(`contest entry delete.....${data.data}`);
                    this.toastrService.success('Post deleted successfully');
                    this.refresh.emit(true);
                },
                (err) => {
                    console.log('entry delete err', err);
                    this.toastrService.error(
                        err?.error?.errors || 'Error while deleting post. Please try after sometime.'
                    );
                }
            );
        } else {
            this.showDeletePopup = false;
            this.commentsForm.get('comments').patchValue('');
            var textarea = document.getElementById('commentTextarea') as HTMLTextAreaElement | null;
            if (textarea) {
                this.inputRows = 0;
                textarea.setAttribute('style', '');
                textarea.rows = 1;
            }
            this.commentClick.emit(this.showCommentSection);
        }
    }

    showDeleteDialog() {
        this.showDeletePopup = true;
        this.dialogData = {
            title: 'Delete this entry?',
            message: 'Are you sure you want to delete this entry?',
            buttonText: 'Delete',
            type: 'entry',
            id: this.postDetails.id
        };
    }

    showDeleteDialogForComment() {
        this.showDeletePopup = true;
        this.dialogData = {
            title: 'Discard this comment?',
            message: 'Are you sure you want to discard this comment?',
            buttonText: 'Discard',
            type: 'comment'
        };
    }
}
