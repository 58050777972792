import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

const CONFERENCE_INFO_KEY = 'conferenceInfo';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private prefix = 'jmc';
    private onSubject = new Subject<{ key: string; value: any }>();
    public changes = this.onSubject.asObservable().pipe(share());
    // in-memory storage for use when inside an iframe
    private localInMemoryStorage = {};
    // flag to check if the current window is inside an iframe
    private isInisideIframe = false;

    constructor() {
        // check if the current window is inside an iframe
        this.isInisideIframe = window.self !== window.top;
        this.start();
    }

    ngOnDestroy() {
        this.stop();
    }

    private start(): void {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            let v;
            try {
                v = JSON.parse(event.newValue);
            } catch (e) {
                v = event.newValue;
            }
            this.onSubject.next({ key: event.key, value: v });
        }
    }

    private stop(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
        this.onSubject.complete();
    }

    addItem(key: string, value: any) {
        try {
            // construct the storage key by adding the prefix to the provided key
            const storageKey = `${this.prefix}.${key}`;
            // check if the window is inside an iframe
            if (!this.isInisideIframe) {
                // use regular local storage if not inside an iframe
                localStorage.setItem(storageKey, JSON.stringify(value));
            } else {
                // store in-memory if inside an iframe
                this.localInMemoryStorage[storageKey] = JSON.stringify(value);
            }
        } catch (error) {
            console.error('Error adding item to local storage', error);
        }
    }

    removeItem(key: string) {
        try {
            // construct the storage key by adding the prefix to the provided key
            const storageKey = `${this.prefix}.${key}`;
            if (!this.isInisideIframe) {
                // use regular local storage if not inside an iframe
                localStorage.removeItem(storageKey);
            } else {
                // remove from in-memory storage if inside an iframe
                delete this.localInMemoryStorage[storageKey];
            }
        } catch (error) {
            console.error('Error removing item from local storage', error);
        }
    }

    getItem(key: string) {
        try {
            // construct the storage key by adding the prefix to the provided key
            const storageKey = `${this.prefix}.${key}`;
            if (!this.isInisideIframe) {
                // use regular local storage if not inside an iframe
                const item = localStorage.getItem(storageKey);
                return item ? JSON.parse(item) : null;
            } else {
                // get from in-memory storage if inside an iframe
                const item = this.localInMemoryStorage[storageKey];
                return item ? JSON.parse(item) : null;
            }
        } catch (error) {
            console.error('Error getting item from local storage', error);
            // return null if an error occurs to prevent the code from throwing an error
            return null;
        }
    }

    clear() {
        try {
            if (this.isInisideIframe) {
                // clear in-memory storage if inside an iframe
                this.localInMemoryStorage = {};
            } else {
                // use regular local storage if not inside an iframe
                localStorage.clear();
            }
        } catch (error) {
            console.error('Error clearing local storage', error);
        }
    }

    storeConferenceInfo(conferenceInfo: any) {
        this.addItem(CONFERENCE_INFO_KEY, conferenceInfo);
    }

    getConferenceInfo() {
        return this.getItem(CONFERENCE_INFO_KEY);
    }
}
