import { ElementRef, ViewChild, Input, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import sanitizeHtml from 'sanitize-html';
import { WEBINAR_CONFERENCE_EVENTS } from 'src/app/conference/constants/webinar-conference-events';
import { EventData, EventEmitterService, UtilService } from 'src/app/core';
declare const Quill: any;

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit {
    @ViewChild('content') content: ElementRef;
    @Output() textChanged = new EventEmitter();
    @Output() updateChanges = new EventEmitter();
    @Output() submitBtnClick = new EventEmitter();

    @Input() text;
    @Input() defaultPlaceholder = '';
    @Input() blockEmojis: boolean = false;
    @Input() disableEnter: boolean = false;
    @Input() disableShiftEnter: boolean = false;
    @Input() maxLength = 256;
    @Input() addSubmitBtn: false;
    @Input() submitString = 'Save';
    @Input() addAlignment: false;
    @Input() addFontBgColor: false;
    @Input() addFontSize: false;
    @Input() addClearFormat: false;
    @Input() addBullets: false;
    @Input() addFontStyle: false;
    @Input() addFontColor: false;
    @Input() disableFormatForClipboardText: boolean = false;
    @Input() applyRevampStyles: boolean = false;
    @Input() insideCall: boolean = false;
    @Input() fromCustomisation;

    subscriptions: Subscription[] = [];
    selectionContent = { text: '', link: '', format: {} };
    range;
    public quill: any;
    showLinkPopup: boolean = false;
    IsTouched: boolean = false;

    private timeouts = [];
    debouncer: Subject<string> = new Subject<string>();

    constructor(
        private toastrService: ToastrService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService
    ) {}

    ngOnInit(): void {
        this.timeouts.push(
            setTimeout(() => {
                this.initializeQuillEditor();
            })
        );

        this.debouncer.pipe(debounceTime(500)).subscribe((val) => {
            let htmlparsed = this.getHtmlParsedContent();
            let text = this.quill.getText();
            this.updateChanges.emit({
                htmlparsed,
                text
            });
        });
        this.subscriptions.push(
            this.eventEmitterService.subscribe((event: EventData) => {
                switch (event.type) {
                    case WEBINAR_CONFERENCE_EVENTS.ADD_HYPERLINK:
                        this.receiveMessage(event.data);
                        break;
                    case WEBINAR_CONFERENCE_EVENTS.REMOVE_HYPERLINK:
                        this.removeLink(event.data);
                        break;
                }
            })
        );
    }
    initializeQuillEditor() {
        let colorOptions = [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466'
        ];
        let toolbarOptions: any = ['bold', 'italic', 'underline'];
        if (this.addFontBgColor) toolbarOptions.push({ background: colorOptions });
        if (this.addFontColor) toolbarOptions.push({ color: colorOptions });
        let headerOptions = [1, 2, 3, 4, 5, 6, false];
        if (this.addFontSize) toolbarOptions.push({ header: headerOptions });
        if (this.addFontStyle) toolbarOptions.push({ font: [] });
        if (this.addClearFormat) toolbarOptions.push('clean');
        if (this.addAlignment) toolbarOptions.push({ align: [] });
        if (this.addBullets) toolbarOptions.push({ list: 'bullet' });
        if (this.addBullets) toolbarOptions.push({ list: 'ordered' });
        toolbarOptions.push('link');
        if (this.fromCustomisation) {
            var optionsCustomisation = {
                placeholder: this.defaultPlaceholder ? this.defaultPlaceholder : 'Type message here',
                // theme: 'snow',
                bounds: 'document.body'
            };
        } else {
            var options = {
                modules: {
                    toolbar: toolbarOptions
                },
                placeholder: this.defaultPlaceholder ? this.defaultPlaceholder : 'Type message here',
                theme: 'snow',
                bounds: 'document.body'
            };
        }

        this.quill = new Quill(this.content.nativeElement, this.fromCustomisation ? optionsCustomisation : options);
        const linkElement = this.quill?.container?.querySelector('.ql-toolbar .ql-link');
        if (linkElement) {
            linkElement.setAttribute('id', 'ticker-hyperlink-btn');
        }
        this.quill?.container?.querySelector('.ql-editor')?.focus();
        if (!this.fromCustomisation) {
            const toolbar = this.quill.getModule('toolbar');
            toolbar.addHandler('link', this.insertLink.bind(this));
        }
        const delta = this.quill.clipboard.convert(this.text);
        console.log('textjgsjbg', this.text, delta);
        this.quill.setContents(delta, 'silent');
        this.registerAnchorTagEvent();
        this.registerTextChangeEvent();
        if (this.disableEnter) {
            this.blockEnterButton();
        }

        if (this.disableShiftEnter) {
            this.blockShiftEnterButton();
        }
    }

    toggleLinkPopup() {
        if (this.insideCall) {
            this.showLinkPopup = false;
        } else this.showLinkPopup = !this.showLinkPopup;
    }
    insertLink() {
        this.range = this.quill.getSelection(false);
        if (this.range.length) {
            var text = this.quill.getText(this.range.index, this.range.length);
            var format = this.quill.getFormat(this.range.index, this.range.length);
            var link = this.quill.getContents(this.range.index, this.range.length);
        }
        this.selectionContent.text = text ? text : '';
        this.selectionContent.link = link?.ops[0].attributes?.link || '';
        this.selectionContent.format = format ? format : {};
        if (this.insideCall) {
            this.eventEmitterService.emit({
                type: WEBINAR_CONFERENCE_EVENTS.SHOW_ADD_LINK_POPUP,
                data: {
                    selectionContent: this.selectionContent,
                    wordCount: this.quill.getText().replace('\n', '')?.length - this.selectionContent?.text?.length,
                    maxLength: this.maxLength,
                    editorContent: this.quill?.container?.firstElementChild?.innerHTML
                }
            });
        } else this.showLinkPopup = true;
    }

    receiveMessage(event) {
        this.addLink(this.quill, this.range.index, event.text, event.link);
        this.showLinkPopup = false;
    }

    addLink(quill, index, text, url) {
        if (this.selectionContent.text) {
            quill.deleteText(index, this.selectionContent.text.length);
        }

        quill.insertText(index, text, 'user');
        quill.setSelection(index, text.length);
        quill.formatText(index, text.length, this.selectionContent.format);
        quill.theme.tooltip.edit('link', url);
        quill.theme.tooltip.save();
        quill?.container?.querySelector('.ql-clipboard')?.focus();
    }

    removeLink(text) {
        this.quill.deleteText(this.range.index, text.length);
        this.quill.insertText(this.range.index, text, 'user');
        if (this.selectionContent.format.hasOwnProperty('link')) {
            delete this.selectionContent.format['link'];
        }
        this.quill.formatText(this.range.index, text.length, this.selectionContent.format);
        this.toggleLinkPopup();
    }

    registerAnchorTagEvent() {
        let currentLink;
        this.quill.container.addEventListener('mouseover', (evt) => {
            if (evt.target.tagName === 'A') {
                currentLink = evt.target;
                currentLink.setAttribute('contenteditable', false);
            } else if (currentLink) {
                currentLink.removeAttribute('contenteditable');
                currentLink = null;
            }
        });
    }

    registerTextChangeEvent() {
        this.quill.on('text-change', (delta, oldContents) => {
            const emojisRegex = new RegExp(
                /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
            );
            if (this.blockEmojis && emojisRegex.test(delta?.ops[0]?.insert || delta?.ops[1]?.insert)) {
                //for ios mobile browsers showing the toast in bottom center
                if (this.utilService.isMobileIOSBrowser()) {
                    this.toastrService.error('Emojis are not allowed', '', {
                        positionClass: 'toast-center-center'
                    });
                } else {
                    this.toastrService.error('Emojis are not allowed');
                }
                this.quill.setContents(oldContents);
                return;
            }
            let length = this.quill.getText().replace('\n', '').length;
            if (this.maxLength < length) {
                this.quill.setContents(oldContents);
            }
            if (!this.IsTouched) {
                this.textChanged.emit(true);
                this.IsTouched = true;
            }
            this.debouncer.next();
        });
        //clearing the formats, when text copied and pasted in less options markdown editors
        if (this.disableFormatForClipboardText) {
            this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
                var plaintext = node.innerText;
                var Delta = Quill.import('delta');
                return new Delta().insert(plaintext);
            });
        }
        //disabling image pasting for quill editors
        this.quill.clipboard.addMatcher('IMG', (node, delta) => {
            const Delta = Quill.import('delta');
            return new Delta().insert('');
        });
        this.quill.clipboard.addMatcher('PICTURE', (node, delta) => {
            const Delta = Quill.import('delta');
            return new Delta().insert('');
        });
    }

    saveChanges() {
        this.quill.container.firstElementChild.innerHTML = sanitizeHtml(
            this.quill.container.firstElementChild.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
        );
        console.log('quill.getText()', this.quill.getText());
        this.updateChanges.emit();
    }
    saveBtnClickChanges() {
        // Just to make sure that updateChange event is being trigged before savebutton click
        this.updateChanges.emit();
        this.quill.container.firstElementChild.innerHTML = sanitizeHtml(
            this.quill.container.firstElementChild.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
        );

        this.submitBtnClick.emit();
        this.quill.setContents([]);
    }

    blockEnterButton() {
        this.quill.keyboard.bindings[13].unshift({
            key: 13,
            handler: () => {
                return false;
            }
        });
    }

    blockShiftEnterButton() {
        this.quill.keyboard.bindings[13].unshift({
            key: 13,
            shiftKey: true,
            handler: () => {
                return false;
            }
        });
    }

    ngOnDestroy() {
        this.timeouts.forEach((id) => clearTimeout(id));
        this.quill = null;
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    check() {
        return !this.quill.getText().replace('\n', '')?.length ? true : false;
    }

    getHtmlParsedContent() {
        let message = this.quill?.container.innerText.trim().length ? this.quill.container.innerHTML : '';
        let parsedContent = new DOMParser().parseFromString(message, 'text/html').documentElement.innerHTML;
        if (!parsedContent.includes('<html>')) {
            parsedContent = `<html>${parsedContent}</html>`;
        }
        return parsedContent;
    }

    getHTMLContent() {
        return this.quill?.container.firstElementChild.innerHTML;
    }
}
