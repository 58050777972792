<div class="social-share-container d-flex">
    <ng-container [ngTemplateOutlet]="socialShareOptions"></ng-container>
</div>

<ng-template #socialShareOptions>
    <div class="pointer mail" (click)="onShare('mail')"></div>
    <div class="pointer whatsapp" (click)="onShare('whatsapp')"></div>
    <div class="pointer fb" (click)="onShare('facebook')"></div>
    <div class="pointer twitter" (click)="onShare('twitter')"></div>
    <div class="pointer linkedin" (click)="onShare('linkedin')"></div>
</ng-template>
