import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class UsernameService {
    constructor(private localStorageService: LocalStorageService) {}

    storeDetails(details) {
        this.localStorageService.addItem('userDetails', details);
    }

    getFirstName(currentUser) {
        return currentUser?.name || this.localStorageService.getItem('userDetails')?.firstName || '';
    }

    getLastName(currentUser) {
        return currentUser?.lname || this.localStorageService.getItem('userDetails')?.lastName || '';
    }

    getEmailId(currentUser) {
        return currentUser?.email || this.localStorageService.getItem('userDetails')?.emailId || '';
    }

    getPhoneNo(currentUser) {
        return currentUser?.phoneNo || this.localStorageService.getItem('userDetails')?.phoneNo || '';
    }
}
