import { Injectable } from '@angular/core';
import { APP_EVENTS } from 'src/app/constants';
import { AppService } from '.';
import { VirtualBackgroundScripts } from '../helpers/virtual-background-scripts';
import { timerWorkerScript } from '../workers/virtual-background.worker';
declare let SelfieSegmentation;
declare let Camera;
@Injectable({
    providedIn: 'root'
})
export class VirtualBackgroundService {
    videoElement: any;
    canvasElement: any;
    imageElement: any;
    canvasCtx: any;
    camera: any;
    selfieSegmentation: any;
    isCameraRunning: boolean = false;
    isSegmentationLoaded: boolean = false;
    backgroundConfig = {
        type: 'none',
        imageUrl: undefined
    };
    virtualBackgroundSize = {
        width: 840,
        height: 480
    };
    canvasElementSize = {
        width: 840,
        height: 480
    };
    scriptsLoaded = false;
    virtualBackgroundScripts;
    customImageArray = [];
    backgroundLoadingSource = false;
    isProcessing: boolean = false;
    worker;
    enableWebWorker: boolean = true;
    maxFPS = 22;
    isVbInitialized: any;
    constructor(private appService: AppService) {
        this.canvasElement = document.createElement('canvas');
        this.canvasElement.width = this.canvasElementSize.width;
        this.canvasElement.height = this.canvasElementSize.height;
        this.canvasElement.style.display = 'none';
        this.canvasElement.id = 'video-canvas';
        this.canvasCtx = this.canvasElement.getContext('2d');

        const videoEle = document.createElement('video');
        videoEle.style.display = 'none';
        const imageEle = document.createElement('img');
        this.imageElement = imageEle;
        this.imageElement.style.display = 'none';
        this.videoElement = videoEle;
        this.maxFPS = this.appService.getConfigVariable('MAX_FPS_FOR_VB') || 22;
        this.appService.webConfigurableStatus.subscribe((data) => {
            if (data != 'default') {
                this.setFrameData();
            }
        });
    }

    setFrameData() {
        this.virtualBackgroundSize = this.appService.getConfigVariable('VIRTUAL_BACKGROUND_FRAME')
            ? this.appService.getConfigVariable('VIRTUAL_BACKGROUND_FRAME')
            : this.virtualBackgroundSize;
        this.canvasElementSize = this.appService.getConfigVariable('CANVAS_ELEMENT_SIZE')
            ? this.appService.getConfigVariable('CANVAS_ELEMENT_SIZE')
            : this.canvasElementSize;
        this.canvasElement.width = this.canvasElementSize.width;
        this.canvasElement.height = this.canvasElementSize.height;
    }

    initializeVirtualBackgroundService(runCameraOnLoad = false) {
        this.virtualBackgroundScripts = VirtualBackgroundScripts;
        this.virtualBackgroundScripts.forEach((script) => {
            this.loadScripts(script);
        });
        this.isVbInitialized = true;
    }

    loadScripts(s) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = s?.src;
        script.crossOrigin = 'anonymous';
        script.onload = () => {
            this.virtualBackgroundScripts[this.virtualBackgroundScripts.indexOf(s)].loaded = true;
            if (this.virtualBackgroundScripts.every((s) => s.loaded)) {
                this.scriptsLoaded = true;
            }
        };
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    setBgConfig(config) {
        if (config.type == 'image') {
            if (this.imageElement) {
                this.backgroundLoadingSource = true;
                this.imageElement.src = config.imageUrl;
                this.imageElement.onload = () => {
                    this.backgroundLoadingSource = false;
                };
                this.backgroundConfig = config;
            }
        } else {
            this.backgroundConfig = config;
            this.backgroundLoadingSource = false;
        }
    }

    async runCamera() {
        if (!this.isVbInitialized) {
            const delay = (ms) => new Promise((res) => setTimeout(res, ms));
            this.initializeVirtualBackgroundService();
            await delay(1000);
        }
        this.registerWebWorker();
        if (!this.isCameraRunning) {
            this.virtualBackgroundSize = this.appService.getConfigVariable('VIRTUAL_BACKGROUND_FRAME')
                ? this.appService.getConfigVariable('VIRTUAL_BACKGROUND_FRAME')
                : this.virtualBackgroundSize;
            this.camera = new Camera(this.videoElement, {
                width: this.virtualBackgroundSize.width,
                height: this.virtualBackgroundSize.height
            });
            await this.camera.start();
            this.startSegmentation();
            this.postWorkerMessage({
                action: APP_EVENTS.VB_WORKER_STATES.START_TIMEOUT,
                maxFPS: this.maxFPS
            });
            this.isCameraRunning = true;
        }
    }

    async segmentFrames() {
        if (!this.isSegmentPossible() || this.isProcessing) {
            return;
        }

        if (this.backgroundConfig.type == 'none') {
            this.drawVideoOnCanvas();
        } else {
            try {
                this.isProcessing = true;
                await this.selfieSegmentation.send({ image: this.videoElement });
            } catch (err) {
                console.log('couldnt process vb, please try again', err);
            }
        }
    }

    drawVideoOnCanvas() {
        // scale and mutliply factor of -1 in width is used mirror the canvas which is rendered in the video frame
        this.canvasCtx.save();
        // this.canvasCtx.scale(-1, 1);
        this.canvasCtx.drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
        this.canvasCtx.restore();
        this.isProcessing = false;
    }

    onResults(results) {
        this.canvasCtx.save();
        this.canvasCtx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        this.canvasCtx.drawImage(results.segmentationMask, 0, 0, this.canvasElement.width, this.canvasElement.height);
        if (this.backgroundConfig.type === 'image') {
            this.drawImageBackground(results);
        } else if (this.backgroundConfig.type === 'blur') {
            this.drawBlurBackground(results);
        } else {
            // simply draw this video
            this.drawVideoOnCanvas();
        }

        this.canvasCtx.restore();
        this.isProcessing = false;
    }

    startSegmentation() {
        if (!this.isSegmentationLoaded) {
            this.selfieSegmentation = new SelfieSegmentation({
                locateFile: (file) => {
                    return `${
                        this.appService.getConfigVariable('SELFIE_SEGMENATION_URL') ||
                        'https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation'
                    }/${file}`;
                }
            });
            //modelSelection 0 : General ; 1: Landscape
            this.selfieSegmentation.setOptions({ modelSelection: 0 });
            this.selfieSegmentation.onResults(this.onResults.bind(this));

            this.isSegmentationLoaded = true;
        }
    }

    drawBlurBackground(results: any) {
        this.canvasCtx.globalCompositeOperation = 'source-out';
        this.canvasCtx.filter = `blur(10px)`;
        this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);

        this.canvasCtx.globalCompositeOperation = 'destination-atop';
        this.canvasCtx.filter = 'none';
        this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);
    }

    drawImageBackground(results: any) {
        this.canvasCtx.globalCompositeOperation = 'source-out';
        this.canvasCtx.drawImage(this.imageElement, 0, 0, this.canvasElement.width, this.canvasElement.height);

        this.canvasCtx.globalCompositeOperation = 'destination-atop';
        this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);
    }

    isSegmentPossible() {
        if (this.videoElement.videoWidth <= 0 || this.videoElement.videoHeight <= 0 || this.backgroundLoadingSource) {
            return false;
        }
        return true;
    }

    closeSession() {
        this.camera?.stop();
        this.isCameraRunning = false;
        this.camera = null;
        this.terminateWorker();
    }

    terminateWorker() {
        if (this.worker !== undefined) {
            this.worker?.terminate();
            this.worker = undefined;
        }
    }

    updateCustomImages(list) {
        this.customImageArray = list;
    }

    getBackgroundConfig() {
        return {
            type: this.backgroundConfig,
            imageUrl: this.imageElement?.src || '',
            customImageArray: this.customImageArray || []
        };
    }

    startCapture() {
        const canvasStream = this.canvasElement.captureStream(this.maxFPS);
        return canvasStream;
    }

    registerWebWorker() {
        // worker is used to process frames when tab is in background.
        if (typeof Worker !== 'undefined' && this.worker === undefined) {
            this.worker = new Worker(timerWorkerScript);

            this.worker.onmessage = ({ data }: any) => {
                if (data.action === APP_EVENTS.VB_WORKER_STATES.TIMEOUT_TICK) {
                    this.segmentFrames();
                }
            };
        } else {
            // Web workers are not supported in this environment.
            // You should add a fallback so that your program still executes correctly.
        }
    }

    postWorkerMessage(data) {
        if (this.worker !== undefined) {
            this.worker.postMessage(data);
        }
    }
}
