import { Pipe, PipeTransform } from '@angular/core';
import { AppService, UserService, UtilService, RestService, SystemService } from 'src/app/core';
import { HttpHeaders } from '@angular/common/http';
import { JiocloudAuthService } from 'src/app/core/services/jiocloud-auth.service';

@Pipe({
    name: 'jioCloudIageAuth'
})
export class JioCloudIageAuthPipe implements PipeTransform {
    constructor(
        private appService: AppService,
        private userService: UserService,
        private utilService: UtilService,
        private jiocloudAuthService: JiocloudAuthService,
        private restService: RestService,
        private systemService: SystemService
    ) {}

    async transform(src: string, jiomeetId: string, size: string): Promise<string> {
        if (!src) {
            return null;
        }
        const meetingId = jiomeetId;
        const currentTimeInMills = +new Date() - +new Date('1970-01-01');
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('X-User-Id', this.userService.getUserSync().jcData.jcId);
        headers = headers.set('X-Device-Key', this.systemService.getDeviceUUID());
        headers = headers.set(
            'X-Request-Hash',
            this.utilService.getSHA256(
                `${this.userService.getUserSync().jcData.jcId}#${meetingId}#${currentTimeInMills}`,
                this.jiocloudAuthService.getAuthInfo().authToken.sessionToken
            )
        );
        headers = headers.set('X-Meeting-Id', meetingId);
        headers = headers.set('X-Current-Timestamp', `${currentTimeInMills}`);
        headers = headers.set(
            'Authorization',
            `Basic ${window.btoa(this.jiocloudAuthService.getAuthInfo().authToken.accessToken)}`
        );
        headers = headers.set('X-API-Key', `${this.appService.getEnvVariable('JIO_CLOUD').API_KEY}`);
        headers = headers.set('X-App-Secret', `${this.appService.getEnvVariable('JIO_CLOUD').APP_SECRET}`);

        if (this.appService.getEnvVariable('local')) {
            const url = new URL(src);
            url.hostname = `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
            src = url.href;
        }

        const imageBlob = await this.restService
            .get(`${src}${'?size='}${size}`, { headers, responseType: 'blob' })
            .toPromise();
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(imageBlob);
        });
    }
}
