<div class="stats-component-wrapper" [class.mobile-stats]="mobile" [class.iosbrowser]="isIosMobileBrowser">
    <div class="time-container text-center">
        <span class="d-flex align-items-center justify-content-center">
            <img
                class="mr-2 mb-1"
                [class.ml-2]="!mobile"
                src="assets/img/jioevents/dark_theme/timer_icon.svg"
                id="timerIcon"
                height="18"
                width="18"
                alt=""
            />
            <!-- {{ duration?.hour | pad }}:{{ duration?.minute | pad }}:{{ duration?.second | pad }} -->
            <!-- This has impact on attendee side, need to send duration correctly -->
            <app-call-duration [callJoinTime]="callJoinTime"></app-call-duration>
        </span>
    </div>
    <ng-container *ngIf="!callStateManagerService?.networkingRoom">
        <span class="vertical-divider" *ngIf="webinarOptions?.views?.showCount"></span>
        <div
            class="like-counter d-flex justify-content-center"
            pTooltip="{{ callViewStateManagerService?.eventStats?.viewsCount }} Views"
            tooltipPosition="top"
            tooltipStyleClass="tooltip-revamp"
            *ngIf="webinarOptions?.views?.showCount"
        >
            <span class="d-flex" id="viewIcon">
                <img
                    class="ml-2 mr-2"
                    src="assets/images/speaker-call-wrapper/views_count_icon.svg"
                    height="18"
                    width="18"
                    alt=""
                />{{ callViewStateManagerService?.eventStats?.viewsCount }} {{ mobile ? 'views' : '' }}
            </span>
        </div>
        <span
            class="vertical-divider"
            *ngIf="!mobile && webinarOptions?.likes?.enabled && webinarOptions?.likes?.showCount"
        ></span>
        <div
            id="likeCounter"
            class="like-counter pointer d-flex justify-content-center reaction-center"
            (click)="getLikeDetails($event); viewReactionClick()"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle="dropdown"
            pTooltip="{{ callViewStateManagerService?.eventStats?.likesCount }} Reactions"
            tooltipPosition="top"
            tooltipStyleClass="tooltip-revamp"
            *ngIf="webinarOptions?.likes?.enabled && webinarOptions?.likes?.showCount"
        >
            <span class="justify-content-center">
                <img class="mr-1" src="assets/images/reactions_icon.svg" alt="" id="reactionIcon" />

                {{ callViewStateManagerService?.eventStats?.likesCount }}
                <img
                    class="ml-1 up-arrow"
                    src="assets/img/jioevents/popup_arrow_up.svg"
                    height="12"
                    width="12"
                    alt=""
                />
            </span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <div
                [class.both-count]="webinarOptions?.views?.showCount && webinarOptions?.likes?.showCount"
                *ngIf="webinarOptions?.likes?.showCount || webinarOptions?.views?.showCount"
                role="menu"
                style="background: rgba(0, 0, 0, 0.8); top: -10px; min-width: 60px; padding: 8px; border-radius: 6px"
                class="dropdown-menu option-menu view-reactions"
                (click)="getLikeDetails($event)"
                aria-labelledby="likeCounter"
                (outside)="clickOutside()"
                pTooltip="{{ likesViewsTooltipText }}"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-revamp"
            >
                <div class="likeCounter" [class.d-flex]="mobile">
                    <div
                        class="category"
                        *ngFor="let options of callViewStateManagerService?.eventStats?.likeOptions; let i = index"
                    >
                        <div
                            class="d-flex justify-content-between emoji-box"
                            [class.bottom-border]="i < callViewStateManagerService?.eventStats?.likeOptions?.length - 1"
                        >
                            <span [class.mr-1]="mobile"
                                ><img
                                    src="assets/images/attendee_emojis/new_icons/{{ options.type }}.svg"
                                    height="{{ mobile ? '24' : '18' }}"
                                    width="{{ mobile ? '24' : '18' }}"
                                    alt=""
                            /></span>
                            <span class="mt-1 mb-1 vertical-divider"></span>
                            <span [class.ml-1]="mobile">{{ options?.count }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span *ngIf="!mobile && !isPreRecordedSession" class="ml-4 vertical-divider"></span>
    </ng-container>
</div>
