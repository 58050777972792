import { Component, Input, OnInit } from '@angular/core';
import { ATTENDEE_LANDING } from 'src/app/constants';
import { GA_EVENTS } from 'src/app/integrations/modules/myjio/ga-events';
import { ActivatedRoute } from '@angular/router';
import { GtmIntegrationService } from 'src/app/core/services/gtm-integration.service';
import { ExternalInterfaceService } from 'src/app/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

@Component({
    selector: 'app-social-share',
    templateUrl: './social-share.component.html',
    styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
    @Input() joinDetail;
    isMyJio;
    isEmbedInMobile = false;
    constructor(
        private googleTagManager: GtmIntegrationService,
        private externalInterfaceService: ExternalInterfaceService,
        private activatedRoute: ActivatedRoute,
        private googleTagMangerService: GoogleTagMangerService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.isMyJio = params?.isMyJio ? true : false;
            this.isEmbedInMobile = params.isEmbedInMobile === 'true' ? true : false;
        });
    }

    ngOnInit(): void {}

    onShare(media) {
        let url;
        let jioEventUrl;
        switch (media) {
            case 'facebook':
                if (this.isMyJio || this.isEmbedInMobile) {
                    url = `https://facebook.com/sharer.php?u=${window.location.href}`;
                    this.externalInterfaceService.launchBrowser(url);
                    this.googleTagManager.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_FB);
                } else {
                    jioEventUrl = window.location.href.split('?')[0];
                    url = `https://facebook.com/sharer.php?u=${jioEventUrl}&quote=${this.joinDetail?.topic}`;
                    this.googleTagMangerService.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_FB);
                    window.open(url, '_blank', 'noopener');
                }
                break;
            case 'twitter':
                if (this.isMyJio || this.isEmbedInMobile) {
                    url = `https://twitter.com/intent/tweet?url=${window.location.href}`;
                    this.externalInterfaceService.launchBrowser(url);
                    this.googleTagManager.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_TWITTER);
                } else {
                    url = `http://twitter.com/share?url=${window.location.href}`;
                    this.googleTagMangerService.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_TWITTER);
                    window.open(url, '_blank', 'noopener');
                }
                break;
            case 'linkedin':
                if (this.isMyJio || this.isEmbedInMobile) {
                    url = `https://linkedin.com/shareArticle?mini=true&url=${window.location.href}`;
                    this.externalInterfaceService.launchBrowser(url);
                    this.googleTagManager.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_LINKEDIN);
                } else {
                    jioEventUrl = window.location.href.split('?')[0];
                    url = `https://linkedin.com/shareArticle?mini=true&url=${jioEventUrl}&title=${this.joinDetail?.topic}`;
                    this.googleTagMangerService.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_LINKEDIN);
                    window.open(url, '_blank', 'noopener');
                }
                break;
            case 'mail':
                if (this.isMyJio || this.isEmbedInMobile) {
                    const emailBody = encodeURIComponent(`${window.location.href}`);
                    const url = `mailto:?body=${emailBody}`;
                    this.externalInterfaceService.launchBrowser(url);
                } else {
                    const emailBody = encodeURIComponent(window.location.href);
                    const url = `mailto:?body=${emailBody}`;
                    this.googleTagMangerService.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_MAIL);
                    window.open(url, '_blank');
                }
                break;
            case 'whatsapp':
                if (this.isMyJio || this.isEmbedInMobile) {
                    const whatsappMessage = `${window.location.href}`;
                    url = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;
                    this.externalInterfaceService.launchBrowser(url);
                } else {
                    const whatsappMessage = `${window.location.href}`;
                    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(whatsappMessage)}`;
                    this.googleTagMangerService.pushGoogleTagData(GA_EVENTS.REGISTER_SHARE_WA);
                    window.open(whatsappUrl, '_blank');
                }
                break;
        }
    }
}
