<div class="page container-fluid">
    <div class="back pointer pt-3 px-3" (click)="navigateToDashboard()">
        <div class="img-wrap mr-2">
            <div class="img"></div>
        </div>
        {{ 'host_meeting.back' | translate }}
    </div>
    <div class="row h-100">
        <div class="left-content col-xl-5 col-lg-6 col-md-12 col-sm-12 py-5">
            <div class="call-preview">
                <app-call-preview (showsettings)="openSettingsPopup($event)" [hostMeeting]="true"></app-call-preview>
            </div>
        </div>
        <div class="right-content col-xl-7 col-lg-6 col-md-12 col-sm-12 py-5">
            <div class="component-wrap">
                <div class="form-wrap">
                    <h3>{{ 'host_meeting.youre_about' | translate }}</h3>
                    <div class="meeting-options">
                        <label for="personal">
                            <input
                                type="radio"
                                class="mr-3"
                                name="option"
                                id="personal"
                                value="personal"
                                [(ngModel)]="roomType"
                            />
                            <span
                                >{{ 'host_meeting.pmi' | translate }} {{ currentUser?.jiomeetId | meetingidFormat }}
                                <img
                                    src="assets/img/icon_info.svg"
                                    class="icon_info"
                                    pTooltip="{{ 'host_meeting.pmitooltip' | translate }}"
                                    tooltipPosition="right"
                                    tooltipStyleClass="white-bg"
                            /></span>
                        </label>
                        <label for="dynamic">
                            <input
                                type="radio"
                                class="mr-3"
                                name="option"
                                id="dynamic"
                                value="dynamic"
                                [(ngModel)]="roomType"
                            />
                            <span>{{ 'host_meeting.onetime' | translate }}</span>
                        </label>
                    </div>
                    <div class="action-btns">
                        <button
                            type="button"
                            class="custom-button bg-secondary"
                            (click)="shareInvite()"
                            *ngIf="roomType === 'personal'"
                        >
                            {{ 'host_meeting.share' | translate }}
                        </button>
                        <button type="button" class="custom-button bg-primary" (click)="hostMeeting()">
                            {{ 'host_meeting.start' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template viewHost></ng-template>

<div class="invitation-area" id="invite-email" *ngIf="shareInfo">
    <app-meeting-invitation [host]="currentUser" [meeting]="shareInfo" id="meeting-invitation"></app-meeting-invitation>
</div>

<div class="popup-component invitation-popup" *ngIf="showMeetingDetailsPopup">
    <div class="bg" (click)="toggleMeetingDetailsPopup()"></div>
    <div class="popup">
        <div class="popup-header">
            <h3>{{ 'host_meeting.share_meeting_details' | translate }}</h3>
            <button class="close simplemodal-close" aria-label="close" (click)="toggleMeetingDetailsPopup()">×</button>
        </div>
        <div class="popup-body">
            <div class="block">
                <label>{{ 'host_meeting.topic' | translate }}</label>
                <div class="value">{{ shareInfo.topic }}</div>
            </div>
            <div class="block d-flex">
                <div class="inline">
                    <label>{{ 'host_meeting.meetingid' | translate }}</label>
                    <div class="value">{{ shareInfo.jiomeetId | meetingidFormat }}</div>
                </div>
                <div>
                    <label>{{ 'host_meeting.password' | translate }}</label>
                    <div class="value">{{ shareInfo.pin }}</div>
                </div>
            </div>
            <div class="block">
                <label>{{ 'host_meeting.meetinglink' | translate }}</label>
                <div class="value">
                    <a target="_blank" [href]="shareInfo.url"> {{ shareInfo.url }}</a>
                </div>
            </div>
            <div class="block">
                <label>{{ 'host_meeting.sharevia' | translate }}</label>
                <div class="value d-flex">
                    <button class="custom-button bg-secondary copy-option" (click)="sendOutlookInvite()">
                        <div class="img-wrap mr-2">
                            <img src="assets/img/outlook_icn_small.png" />
                        </div>
                        {{ 'host_meeting.outlook' | translate }}
                    </button>
                    <button class="custom-button bg-secondary copy-option gmail" (click)="sendGmailInvite()">
                        <div class="img-wrap mr-2">
                            <img src="assets/img/gmail.svg" />
                        </div>
                        {{ 'host_meeting.gmail' | translate }}
                    </button>
                    <button class="custom-button bg-secondary copy-option copy" (click)="copyInvitation()">
                        <div class="img-wrap mr-2">
                            <!-- <img src="assets/img/revamp/copy-invitation-icon.svg"> -->
                        </div>
                        {{
                            (copiedInviteToClipboard ? 'host_meeting.copied' : 'host_meeting.copy_to_clipboard')
                                | translate
                        }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-permission-denied *ngIf="showPermissionUI" [showSkipOption]="false"></app-permission-denied>
