<nav
    id="app-header"
    class="navbar navbar-expand-lg navbar-light fixed-top"
    (outside)="onOutsideClick($event)"
    [ngClass]="{ isDarkTheme: headerSettings.isDarkTheme, hasTransparentHeader: hasTransparentHeader }"
    id="landingHeaderWrap"
>
    <a
        routerLink="/JioEvents"
        tabindex="0"
        aria-roledescription="jioevents"
        role="button"
        class="navbar-brand"
        (click)="navigateToHome()"
        (keyup.enter)="navigateToHome()"
    >
        <div class="left-section pointer">
            <div
                class=" {{ isJioEvents ? 'jioevents-image-wrapper' : 'jioconf-image-wrapper' }}"
                [class.isDarkTheme]="headerSettings?.isDarkTheme || hasTransparentHeader"
                id="jeHeaderLogoWrap"
            ></div>
        </div>
    </a>
    <div *ngIf="isAuthenticated" class="navbar-toggler">
        <div class="btn-wrap" [class.active]="showMenu">
            <button type="button" (click)="toggleMenu()"></button>
        </div>
    </div>
    <div class="collapse navbar-collapse" [class.show]="showMenu" id="navbarTogglerDemo02">
        <ul class="navbar-nav ml-auto my-2 my-lg-0">
            <li class="nav-item dropdown show-in-mobile">
                <div class="login-name" *ngIf="!currentUser?.isOTT">
                    {{ currentUser?.name || '' }} {{ currentUser?.lname || '' }}
                </div>
                <div class="login-name" *ngIf="currentUser?.isOTT">
                    {{ currentUser?.name + ' ' + (currentUser?.lname || '') || currentUser?.displayPhoneNo }}
                </div>
                <div class="login-email" *ngIf="!currentUser?.isOTT">
                    {{ currentUser?.email }}
                </div>
                <div class="login-email" *ngIf="currentUser?.isOTT">
                    {{ currentUser?.email || currentUser?.displayPhoneNo }}
                </div>
                <div class="view my-2 pointer" (click)="navigateToProfile()">View Profile</div>
            </li>
            <li class="nav-item dropdown show-in-mobile" *ngIf="isAuthenticated">
                <a class="pointer" id="myDashboardButton" (click)="navigateToDashboard()">{{
                    'landing_or_home_page.header.my_dashboard' | translate
                }}</a>
            </li>
            <li class="nav-item" *ngIf="!isAuthenticated && headerSettings.showUseCase" (click)="navigateToUseCases()">
                <a class="pointer">Use cases</a>
            </li>
            <!-- (keydown.enter)="toggleDropdown()" tabindex="0" -->
            <li id="desktop-create-btn" class="nav-item dropdown profile show-in-large-screen" *ngIf="isAuthenticated">
                <div
                    class="pointer"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="navbarUserDropdown"
                    *ngIf="isAuthenticated && headerSettings.showCreateEvent"
                    [pTooltip]="!canCreateWebinar && !enableNavigateToContactUs ? tooltip : ''"
                    tooltipPosition="bottom"
                >
                    <span tabindex="0" (keyup.enter)="toggleDropdown()" aria-label="create event">
                        <button
                            id="eventCreate"
                            (keydown.enter)="toggleDropdown()"
                            (keyup.enter)="toggleDropdown()"
                            tabindex="0"
                            aria-label="create event"
                            class="custom-button bg-primary bg-gradient"
                            [class.disabled]="!canCreateWebinar && !enableNavigateToContactUs"
                        >
                            Create Event
                        </button></span
                    >
                </div>
                <!-- *ngIf="isDropdownOpen" -->
                <div
                    [class.show]="isDropdownOpen"
                    class="dropdown1"
                    class="dropdown-menu dropdown-menu-right event-type-dropdown p-0"
                    aria-labelledby="navbarUserDropdown"
                    *ngIf="canCreateWebinar || enableNavigateToContactUs"
                >
                    <div
                        id="desktop-create-virtual"
                        tabindex="0"
                        aria-label="Virtual Event"
                        class="view p-3 pointer eventTypeItem"
                        (click)="createEvent()"
                        (keyup.enter)="createEvent()"
                    >
                        <img class="eventTypeItemIcon" src="assets/img/website/virtual_event_icon.svg" />
                        <span class="eventTypeItemText"> Virtual Event</span>
                    </div>
                    <div
                        id="desktop-create-prerecorded"
                        tabindex="0"
                        *ngIf="preRecordedFeatureEnabled"
                        aria-label="Pre Recorded Virtual Event"
                        class="view p-3 pointer eventTypeItem"
                        (click)="createPreRecordedEvent()"
                        (keyup.enter)="createPreRecordedEvent()"
                    >
                        <img class="eventTypeItemIcon" src="assets/img/website/pre_record_event.svg" />
                        <span class="eventTypeItemText"> Pre Recorded Virtual Event</span>
                    </div>
                </div>
            </li>
            <li
                id="mobile-create-btn"
                class="nav-item profile show-in-mobile"
                [class.dropdown]="canCreateWebinar || enableNavigateToContactUs"
                *ngIf="isAuthenticated"
            >
                <div
                    class="pointer"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="navbarUserDropdown"
                    *ngIf="isAuthenticated && headerSettings.showCreateEvent"
                    [pTooltip]="!canCreateWebinar && !enableNavigateToContactUs ? tooltip : ''"
                    tooltipPosition="bottom"
                >
                    <button
                        id="createNewEvent"
                        tabindex="0"
                        aria-label="create event"
                        class="custom-button bg-primary bg-gradient"
                        [class.disabled]="!canCreateWebinar && !enableNavigateToContactUs"
                    >
                        Create Event
                    </button>
                </div>
                <div
                    class="dropdown-menu dropdown-menu-right event-type-dropdown p-0"
                    aria-labelledby="navbarUserDropdown"
                    *ngIf="canCreateWebinar || enableNavigateToContactUs"
                >
                    <div
                        id="mobile-create-virtual"
                        tabindex="0"
                        aria-label="virtual event"
                        class="view p-3 pointer eventTypeItem"
                        (click)="createEvent()"
                        (keyup.enter)="createEvent()"
                    >
                        <img class="eventTypeItemIcon" src="assets/img/website/virtual_event_icon.svg" />
                        <span class="eventTypeItemText"> Virtual Event</span>
                    </div>
                    <div
                        id="mobile-create-prerecorded"
                        tabindex="0"
                        aria-label="Pre Recorded Virtual Event"
                        class="view p-3 pointer eventTypeItem"
                        (click)="createPreRecordedEvent()"
                        (keyup.enter)="createPreRecordedEvent()"
                    >
                        <img class="eventTypeItemIcon" src="assets/img/website/pre_record_event.svg" />
                        <span class="eventTypeItemText"> Pre Recorded Virtual Event</span>
                    </div>
                </div>
            </li>
            <li class="nav-item" *ngIf="!isAuthenticated && headerSettings.showSignIn">
                <a class="pointer" id="headerSignInButton" (click)="navigateToSignIn()">{{
                    'landing_or_home_page.header.sign_in' | translate
                }}</a>
            </li>
            <li class="nav-item dropdown show-in-mobile" *ngIf="isAuthenticated">
                <a class="pointer" id="logoutButton" (click)="logout()">{{
                    'landing_or_home_page.header.sign_out' | translate
                }}</a>
            </li>
            <li class="nav-item dropdown profile" *ngIf="isAuthenticated">
                <!-- <div class="whats-new">What's New?</div> -->
                <div
                    class="img-wrap pointer"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="navbarUserDropdown_Dashboard"
                >
                    <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" />
                </div>
                <div class="div"></div>
                <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarUserDropdown">
                    <div class="details pointer" (click)="navigateToDashboard()">
                        <div class="profile">
                            <div
                                class="img-wrap pointer"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="navbarUserDropdown"
                            >
                                <img [src]="profilePicPath" alt="" *ngIf="profilePicPath" />
                            </div>
                            <div class="username">
                                <div
                                    class="dashboard-link text-truncate"
                                    pTooltip="{{ currentUser | userInfo: 'name' }}"
                                    tooltipPosition="bottom"
                                >
                                    {{ currentUser | userInfo: 'name' }}
                                </div>
                                <div
                                    class="email text-truncate"
                                    pTooltip="{{ currentUser | userInfo: 'email' }}"
                                    tooltipPosition="bottom"
                                >
                                    {{ currentUser | userInfo: 'email' }}
                                </div>
                                <!-- <div class="email" *ngIf="currentUser?.isOTT">
                                    {{ currentUser?.displayPhoneNo || currentUser?.email }}
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="view pointer">
                        <button
                            (click)="openDetails()"
                            [id]="'profileDetailButton'"
                            class="
                                Profile-btn
                                d-flex
                                flex-row
                                justify-content-center
                                align-items-center
                                custom-button
                                bg-primary
                                light-theme
                            "
                        >
                            My Profile
                        </button>
                    </div>
                    <div class="dropdown-footer pointer" [id]="'signOutButton'" (click)="logout()">
                        <div class="logout">
                            <img alt="sign_out" src="assets/img/sign_out.svg" />
                            {{ 'landing_or_home_page.header.sign_out' | translate }}
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>
<div class="popup-component" *ngIf="showprofiledetails">
    <div class="bg"></div>
    <div class="profile-detail-wrapper">
        <app-profile-details *ngIf="showprofiledetails" (showprofiledetails)="closeProfile()"></app-profile-details>
    </div>
</div>

<p-confirmDialog [transitionOptions]="'300ms'" acceptIcon="null" rejectIcon="null"></p-confirmDialog>
