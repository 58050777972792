import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit, OnInit } from '@angular/core';

import { RestService, AppService, EventEmitterService, EventData } from 'src/app/core';
import { RC_EVENTS } from 'src/app/constants';

@Component({
    selector: 'app-chat-frame',
    templateUrl: './chat-frame.component.html',
    styleUrls: ['./chat-frame.component.scss']
})
export class ChatFrameComponent implements OnInit, OnDestroy {
    @ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;

    public loading = true;

    private chatUrl;
    private config = null;
    private typeMap = {
        d: 'direct',
        c: 'channel',
        p: 'group'
    };

    constructor(
        private restService: RestService,
        private appService: AppService,
        private eventEmitterService: EventEmitterService
    ) {}

    ngOnInit() {
        this.eventEmitterService.subscribe(this.handleEvents.bind(this));
    }

    handleEvents(event: EventData) {
        switch (event.type) {
            case RC_EVENTS.RC_INIT_CONFIG:
                this.initialize(event.data);
                break;
        }
    }

    public initialize(config) {
        // {
        //    token: token,
        //    embedded: true,
        //    threadId: res.threadName,
        //    type: res.type
        // }
        this.config = config;
        this.chatUrl = this.appService.getConfigVariable('CHAT_URL');
        this.chatUrl += `${
            !(config.type && config.threadId) ? 'home' : this.typeMap[config.type] + '/' + config.threadId + '/'
        }`;
        this.chatUrl += `${!config.embedded ? '' : '?layout=embedded'}`;
        this.registerIframeListeners();
        this.setIframeSrc();
    }

    private registerIframeListeners() {
        this.iframe.nativeElement.addEventListener('load', this.handleLoadFinish.bind(this));
    }

    private setIframeSrc() {
        this.loading = true;
        this.iframe.nativeElement.src = this.chatUrl;
        // this.restService
        //   .get(`${this.appService.getEnvVariable('BASE_URL')}/rc/login`)
        //   .subscribe(res => {
        //     this.token = res.loginToken;
        //     this.iframe.nativeElement.src = this.chatUrl;
        //   });
    }

    private loginToRocketChat() {
        this.iframe.nativeElement.contentWindow.postMessage(
            {
                externalCommand: 'login-with-token',
                token: this.config.token
            },
            this.chatUrl
        );
    }

    private handleLoadFinish() {
        this.loading = false;
        this.loginToRocketChat();
    }

    ngOnDestroy() {
        this.iframe.nativeElement.removeEventListener('load', this.handleLoadFinish.bind(this));
    }
}
