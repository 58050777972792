import { KeyValue, TitleCasePipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, interval, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { SESSIONS } from 'src/app/constants';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

@Component({
    selector: 'app-auto-complete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutoCompleteComponent),
            multi: true
        },
        TitleCasePipe
    ]
})
export class AutoCompleteComponent implements OnInit, AfterViewInit {
    @Input() suggestions;
    @Input() placeHolder = '';
    @Input() role = '';
    @Input() isAdHocUser = false;
    @Input() items = new Map();
    @Input() disabled = false;
    @Output() onSearch: EventEmitter<any> = new EventEmitter();
    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();
    @Output() addMember: EventEmitter<any> = new EventEmitter();
    @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
    loading = false;
    dataCollection: any = {};
    initialItemsSuggestion: any = [];
    dataSuggestions: any = [];
    searchTerm = '';
    selectedSuggestions = new Map();
    openSelectMenu: boolean = false;
    itemSelectionInProgress: boolean = false;

    itemsSuggestion: any = [];
    private onChange: any = () => {};
    private onTouch: any = () => {};
    searchTermChanged: Subject<string> = new Subject<string>();
    noDataFound = false;
    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };
    searchInputInterval$;
    searchHandler$ = null;

    constructor(private titleCase: TitleCasePipe, private googleTagMangerService: GoogleTagMangerService) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.searchInputInterval$ = interval(1 * 1000).subscribe(() => {
            if (this.input) {
                this.searchInputInterval$.unsubscribe();
                this.attachKeyUpEvent();
            }
        });
    }

    attachKeyUpEvent() {
        if (!this.searchHandler$) {
            this.searchHandler$ = fromEvent(this.input?.nativeElement, 'keyup')
                .pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    map((event: KeyboardEvent) => (event.target as HTMLInputElement).value)
                )
                .subscribe((searchTerm) => {
                    this.loading = true;
                    this.noDataFound = false;
                    this.onSearch.emit(searchTerm);
                });
        }
    }

    resetSuggestions() {}

    ngOnChanges(changes: SimpleChanges) {
        console.log(this.items);
        if (changes.suggestions?.currentValue) {
            this.itemsSuggestion = changes.suggestions.currentValue;
            this.loading = false;
            this.input?.nativeElement?.focus();
            //  this.toggleMenu(true);
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    toggleMenu(state, focus = false) {
        if (this.itemSelectionInProgress && !state) {
            this.itemSelectionInProgress = false;
            return;
        }
        this.openSelectMenu = state;

        if (!state) {
            this.searchTerm = '';
        }
        if (state && focus) {
            this.onSearch.emit(this.searchTerm);
        }
    }

    removeItem(event, item) {
        this.itemSelectionInProgress = true;
        this.input?.nativeElement?.focus();
        event.stopPropagation();
        this.items.delete(item.id);
        this.onRemove.emit(item);
        this.onUpdate.emit(this.items);

        if (this.role == 'Speaker') {
            this.googleTagMangerService.pushGoogleTagData(SESSIONS.REMOVE_SPEAKER);
        }
        if (this.role == 'Co-Host') {
            this.googleTagMangerService.pushGoogleTagData(SESSIONS.REMOVE_CO_HOST);
        }
    }

    selectItem(item) {
        this.itemSelectionInProgress = true;
        if (this.disabled) return;
        this.searchTerm = '';
        if (!this.items.has(item.id)) {
            this.items.set(item.id, item);
            //   this.value = this.items;

            this.onSelect.emit(item);
            this.onUpdate.emit(this.items);
            this.onSearch.emit(this.searchTerm);
        }
        this.input?.nativeElement?.focus();
        if (this.role == 'Speaker') {
            this.googleTagMangerService.pushGoogleTagData(SESSIONS.ASSIGN_SPEAKER);
        }
        if (this.role == 'Co-Host') {
            this.googleTagMangerService.pushGoogleTagData(SESSIONS.ASSIGN_CO_HOST);
        }
    }

    handleToggleMenu() {
        this.itemSelectionInProgress = false;
        setTimeout(() => {
            this.openSelectMenu = this.itemSelectionInProgress;
        }, 300);
    }

    addNewMember() {
        this.addMember.emit({ searchTerm: this.searchTerm });
        this.googleTagMangerService.pushGoogleTagData(SESSIONS.ADD_MEMBERS);
    }

    handleCheckBoxToggle(event, item) {
        event.target.checked ? this.selectItem(item) : this.removeItem(event, item);
    }

    trackByFunction(index, item) {
        return item.id;
    }

    onFocus() {
        this.openSelectMenu = true;
        this.onSearch.emit(this.searchTerm);
    }

    onBlur() {
        this.handleToggleMenu();
    }
}
