<div class="chat-bubble-container right-aligned">
    <div class="msg-info-contanier flex-end">
        <div class="sender-info">
            <span class="sender-name">You</span>
        </div>
        <div class="msg-time">• {{ messageData?.cOn | date: 'HH:mm' | convert24to12format }}</div>
    </div>
    <div class="msg-option-container ownmsg-option-container">
        <div class="msg-content own-msg" #msg_container>
            <div class="msg-text" [innerHTML]="messageData?.messageComponent?.text?.trim() | linkify | newline"></div>
        </div>
    </div>
</div>
