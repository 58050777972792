<div class="info-modal-overlay">
    <div class="info-modal-container">
        <div class="info-modal-header">
            <div class="heading-container">
                <span class="heading-img">
                    <img src="assets/images/pre-call-testing/cross_red.svg" alt="" />
                </span>
                <span class="heading-text">Unable to complete test</span>
            </div>
            <div class="close-container">
                <span class="close-icon" (click)="closeModal()"
                    ><img src="assets/images/pre-call-testing/cross.svg" alt=""
                /></span>
            </div>
        </div>
        <div class="info-modal-content">
            <div class="result-container">
                <div class="browser-test-subheading">
                    <div class="browser-status-text">
                        We are unable to connect to the test server. It could be due to a firewall blocking the
                        connection or server issues. Please try again later..
                    </div>
                    <div class="button-container">
                        <div class="test-again-button" (click)="restartTest()">Restart Test</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
