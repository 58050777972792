import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService, MeetingService, SessionStorageService, UtilService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class CheckForCohostEntryGuard implements CanActivate {
    constructor(
        private meetingService: MeetingService,
        private authService: AuthService,
        private router: Router,
        private toastrService: ToastrService,
        private sessionStorageService: SessionStorageService,
        private utilService: UtilService
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const meetingInviteHash = route?.params?.hash;
        // check if url is being open in mobile browser then redirect to mobile app
        if (this.utilService.isMobileBrowser()) {
            return of(true);
        }
        if (this.authService.getIsAuthenticated()) {
            return this.meetingService.checkInviteLinkJoinHash(meetingInviteHash).pipe(
                map((res: any) => {
                    return this.navigateToJoin(res);
                }),
                catchError((err, caught): Observable<boolean> => {
                    return this.handleError(err);
                })
            );
        } else {
            return this.meetingService.verifyInviteLinkHash(meetingInviteHash).pipe(
                map((res: any) => {
                    if (res?.roomDetails?.loggedInOnly) {
                        this.router.navigate(['login'], {
                            queryParams: {
                                returnUrl: state.url
                            }
                        });
                        return false;
                    } else {
                        return this.navigateToJoin(res);
                    }
                }),
                catchError((err, caught): Observable<boolean> => {
                    return this.handleError(err);
                })
            );
        }
    }

    navigateToJoin(meetingInfo) {
        // save the data to session storage if user is guest user and speaker to prefill the data in guest form
        this.sessionStorageService.addItem('invitedUser', {
            ...meetingInfo?.userData,
            alreadyInCall: meetingInfo.alreadyInCall
        });

        this.router.navigate(['shortener'], {
            queryParams: {
                meetingId: meetingInfo?.roomDetails?.jiomeetId,
                pwd: meetingInfo?.roomDetails?.pin,
                sh: meetingInfo?.maskedHash
            }
        });
        return true;
    }

    handleError(err) {
        this.toastrService.error(err.error.errors);
        this.router.navigate(['']);
        return of(true);
    }
}
