<div
    class="post-preview-section"
    [class.post-preview-mobile]="showMobileView"
    [class.post-preview-screen]="isPreviewScreen && !showMobileView"
    *ngIf="!loading && (!useGenericPostSession || isPreviewScreen || isAuthenticated || !showThankyouContainer)"
>
    <div class="layout" [class.isMobileOS]="isMobileOS || isMobile">
        <div class="header" *ngIf="isPreviewScreen">
            <div class="header-logo">
                <div class="client-logo">
                    <img [src]="pageLogoUrl" alt="brand-logo" />
                </div>
            </div>
        </div>

        <div class="background-image" [class.isMobilePreview]="isMobile" [class.isMobileOS]="isMobileOS || isMobile">
            <img
                class="background-img"
                *ngIf="webinarDetails?.thankYouBackground"
                [src]="webinarDetails?.thankYouBackground ? webinarDetails?.thankYouBackground : ''"
                [class.isMobileOS]="isMobileOS || isMobile"
                alt=""
            />
        </div>

        <div
            class="conference-sponsors-container"
            [class.isMobileOS]="isMobileOS"
            [class.conference-sponsors-container-with-sponsors]="
                sponsorsLogos?.length > 0 && isPotrait && isPlatinumSponsorsAtTopEnabled
            "
        >
            <div class="platinum-sponsors h-100" *ngIf="!showMobileView && !isMobileOS">
                <app-sponsors
                    [brandDetails]="brandDetails"
                    [category]="
                        platinumSponsorLogos.length > 0 ? 'Platinum' : goldSponsorLogos.length > 0 ? 'Gold' : 'Silver'
                    "
                ></app-sponsors>
            </div>
            <div
                class="event-center-view"
                [class.fullscreenMode]="fullscreenMode"
                [class.isMobileOS]="isMobileOS"
                [class.isMobilePreview]="isMobile"
                #eventCenterView
            >
                <div class="event-title" #eventTitleView *ngIf="!isPreviewScreen && !isMobile">
                    <div class="title-text">
                        <span> {{ webinarDetails?.topic }}</span>
                    </div>
                </div>
                <div class="conference-player" #conferencePlayer [class.isMobilePreview]="isMobile">
                    <div #playerOuterContainer class="aspect-outer-container">
                        <div class="viewing-section" [class.isMobilePreview]="isMobile">
                            <div class="player">
                                <div class="foreground-image-wrapper" [class.isMobilePreview]="isMobile">
                                    <img
                                        [src]="
                                            webinarDetails?.thankYouBanner
                                                ? webinarDetails?.thankYouBanner
                                                : 'assets/img/jioconf/welcome-slates-plain/' +
                                                  webinarDetails?.eventType +
                                                  '.png'
                                        "
                                        alt=""
                                        class="default-foreground-img"
                                    />
                                    <div
                                        [ngClass]="enableMarkdownForm ? 'overlay-text-markdown' : 'overlay-text'"
                                        *ngIf="thankYouMsg && thankYouMsg.trim() !== ''"
                                    >
                                        <div
                                            [innerHTML]="thankYouMsg | sanitizeHtml"
                                            *ngIf="thankYouMsg"
                                            class="text-wrap"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-sponsors-wrapper" *ngIf="!showMobileView && !isMobileOS">
                <div
                    class="right-top-sponsors right-sponsor"
                    [ngClass]="brandDetails?.sponsors?.length === 3 ? 'right-top-sponsors-width' : 'right-top-sponsors'"
                >
                    <app-sponsors
                        [brandDetails]="brandDetails"
                        [category]="getCategoryForSponsors()"
                        [shouldBeInGrid]="false"
                    ></app-sponsors>
                </div>
                <div
                    class="right-bottom-sponsors right-sponsor"
                    *ngIf="brandDetails?.sponsors?.length === 3 && goldSponsorLogos.length != 0"
                >
                    <app-sponsors
                        [brandDetails]="brandDetails"
                        [category]="'Silver'"
                        [shouldBeInGrid]="true"
                    ></app-sponsors>
                </div>
            </div>
        </div>
        <div class="mobile-common-view" *ngIf="(isMobileOS || isMobile) && !isPreviewScreen">
            <div class="default-mobile-body" *ngIf="sponsorsLogos?.length == 0">
                <img class="mb-4" height="28" src="assets/img/jioevents/dark_theme/turn_ur_phone.svg" alt="" />
                Go to full screen mode for better experience
            </div>
            <div class="mobile-sponsors-view" *ngIf="sponsorsLogos?.length > 0 && showSwiper">
                <swiper
                    id="sponsors-section"
                    class="swiper-container sponsors-section"
                    [config]="config"
                    (indexChange)="onMobileSwiperSlideChange($event)"
                >
                    <div
                        class="swiper-slide sponsor-bar d-flex flex-column justify-content-center"
                        *ngFor="let sponsors of brandDetails?.sponsors"
                    >
                        <div *ngIf="!callOptionsEnabled && !fullscreenMode && sponsors.sponsors.length > 0">
                            <app-sponsors
                                [brandDetails]="brandDetails"
                                [isMobileOS]="true"
                                [shouldBeInGrid]="sponsors?.category === 'Silver'"
                                [category]="sponsors?.category ? sponsors?.category : ''"
                            ></app-sponsors>
                        </div>
                    </div>
                </swiper>
            </div>
            <!-- <div class="mobile-sponsors-view" *ngIf="sponsorsLogos?.length > 0">
                <swiper
                    id="sponsors-section"
                    class="swiper-container sponsors-section"
                    [config]="config"
                    (indexChange)="onMobileSwiperSlideChange($event)"
                >
                    <div
                        class="swiper-slide sponsor-bar d-flex flex-column justify-content-center"
                        *ngFor="let sponsors of brandDetails?.sponsors"
                    >
                  
                        <div  *ngIf="!callOptionsEnabled && !fullscreenMode && sponsors.sponsors.length > 0">
                            <app-sponsors
                                [brandDetails]="brandDetails"
                                [isMobileOS]="true"
                                [shouldBeInGrid]="sponsors?.category === 'Silver'"
                                [category]="sponsors?.category ? sponsors?.category : ''"
                            ></app-sponsors>
                        </div>
                    </div>
                </swiper>
            </div> -->
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<div
    class="old-preview-section"
    *ngIf="!loading && !!useGenericPostSession && !isPreviewScreen && !isAuthenticated && showThankyouContainer"
>
    <div class="layout" [class.isMobileOS]="isMobileOS">
        <div class="defaultBanner">
            <img src="assets/img/jioevents/dark_theme/guest_attendee_thank_u.svg" alt="post_session" />
            <div class="thank-you-text d-flex align-items-center justify-content-center">
                <div [innerHTML]="thankYouMsg" class="text-wrapper"></div>
            </div>
        </div>
        <div class="signup-banner d-flex flex-column" *ngIf="!isAuthenticated">
            <span class="more-excitement">{{ 'post-session-screen.for_more_excitement_signup' | translate }}</span>
            <button
                type="submit"
                id="verifyButton"
                class="custom-button bg-login my-4 mx-auto w-40"
                (click)="navToSignup()"
                *ngIf="!isIFrameAutoJoinAttendee"
            >
                {{ 'post-session-screen.signup_with_jioevents' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="footer" [class.preview-footer]="showMobileView" *ngIf="isPreviewScreen && isAuthenticated">
    <div class="attendee-layout-btn content d-flex justify-content-center">
        <div class="d-flex align-items-center">
            <div class="more pointer" tabindex="1">
                <div class="dropdown" dropdown>
                    <button
                        class="preview btn"
                        id="dropdownMenuButton"
                        dropdownToggle
                        data-toggle="dropdown"
                        aria-controls="dropdown-autoclose1"
                        aria-haspopup="true"
                        aria-expanded="false"
                        *ngIf="!showMobileView"
                    >
                        Desktop <img src="assets/img/arrow_up_icn_blue.svg" class="up-arrow" />
                    </button>
                    <button
                        class="preview btn"
                        id="dropdownMenuButton"
                        dropdownToggle
                        data-toggle="dropdown"
                        aria-controls="dropdown-autoclose1"
                        aria-haspopup="true"
                        aria-expanded="false"
                        *ngIf="showMobileView"
                    >
                        Mobile <img src="assets/img/arrow_up_icn_blue.svg" class="up-arrow" />
                    </button>
                    <div class="dropdown-menu items" aria-labelledby="button-autoclose1">
                        <div class="dropdown-item" tabindex="1" (click)="togglePreview('desktop')">Desktop</div>
                        <div class="dropdown-item" tabindex="1" (click)="togglePreview('mobile')">Mobile</div>
                    </div>
                </div>
            </div>
            <div class="thumbnail-page">
                <button class="custom-button" (click)="navigateToThumbnailPreview()">Thumbnail Page</button>
            </div>
            <div class="welcome-page">
                <button class="custom-button" (click)="navigateToWelcomePagePreview()">Welcome Page</button>
            </div>
            <div class="inside-event-page">
                <button class="custom-button" (click)="navigateToInSessionPreview()">Inside Event Page</button>
            </div>
            <div class="thank-you-page">
                <button class="custom-button">Thank You Page</button>
            </div>
        </div>
    </div>
</div>
