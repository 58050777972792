import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-sessions-popup',
    templateUrl: './sessions-popup.component.html',
    styleUrls: ['./sessions-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SessionsPopupComponent implements OnInit, AfterViewInit {
    @Output() close = new EventEmitter();
    @Input() type;
    @Input() topic;
    @Input() headerText;
    constructor() {}

    ngOnInit(): void {}
    ngAfterViewInit() {
        setTimeout(() => {
            this.cancel();
        }, 5000);
    }

    cancel() {
        this.close.emit();
    }
}
