<div class="d-container">
    <div class="mobile">
        <div class="text-center py-4">
            <!-- <img src="assets/img/d-logo.svg" /> -->
        </div>
        <p class="text-center header-txt">Add To JioMeet</p>
        <div class="text-center py-2">
            <img src="assets/img/middleware_image_mobile.svg" />
        </div>
        <p class="text-center fotter-txt my-4">Don’t have JioMeet app</p>
        <div class="text-center">
            <button class="btn btn-primary btn-lg w-75 pointer" (click)="gotoStore()">Download JioMeet App</button>
        </div>

        <div class="text-center">
            <p class="jm-txt my-4">
                Already have JioMeet?
                <span class="text-primary pointer" (click)="launch()">Luanch it now</span>
            </p>
            <p class="jm-txt my-4" *ngIf="isAuthenticated">
                <span class="text-primary pointer" (click)="addToJiomeet()">Add to JioMeet</span>
            </p>
        </div>
    </div>
    <div class="desktop">
        <div class="text-left px-4 py-4 logo-container">
            <!-- <img class="pointer" (click)="gotoHome()" src="assets/img/logo/jm-logo-blue.svg"> -->
        </div>

        <div class="text-center">
            <!-- <img src="assets/img/d-jm-desktopview.png" *ngIf="supported" />
      <img src="assets/img/no_support_browser.svg" *ngIf="!supported" /> -->
            <img src="assets/img/middleware_image.svg" />
        </div>
        <!-- <p class="text-center fotter-txt my-4" *ngIf="!supported">
      Your browser doesn’t support JioMeet
    </p> -->
        <p class="text-center header-txt mt-5 mb-4">Experience the best of JioMeet with the desktop app</p>

        <div class="text-center mx-4 my-4" *ngIf="currentOS === 'Windows'">
            <button class="btn btn-primary btn-lg pointer" (click)="download(currentOS)">
                Download the JioMeet Windows App
            </button>
        </div>
        <div class="text-center mx-4 my-4" *ngIf="currentOS === 'Mac OS'">
            <button class="btn btn-primary btn-lg pointer" (click)="download(currentOS)">
                Download the JioMeet macOS App
            </button>
        </div>
        <div class="text-center">
            <p class="jm-txt mt-2" *ngIf="currentOS === 'Windows' || currentOS === 'Mac OS'">
                Already have the JioMeet app?
                <span class="text-primary pointer" (click)="launch()">Launch it now</span>
            </p>
            <p class="jm-txt mt-2" *ngIf="isAuthenticated">
                <span class="text-primary pointer" (click)="addToJiomeet()">Add to JioMeet</span>
            </p>
        </div>
    </div>
</div>

<div *ngIf="iosMacDeeplink">
    <iframe ref="iframe" [src]="iosMacDeeplink | safe"></iframe>
</div>
