import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppService, AuthService, RtcService, VideoWrapperService } from '..';

@Injectable({
    providedIn: 'root'
})
export class IncomingCallGuard implements CanActivate {
    constructor(
        private rtcService: RtcService,
        private router: Router,
        private videoWrapperService: VideoWrapperService,
        private authService: AuthService,
        private appService: AppService
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (route.queryParams?.incoming === 'true') {
            // TODO: Work on new Event URLs
            const incomingCallInfo = JSON.parse(atob(route?.queryParams?.hash));
            // const commonConferenceInfo = incomingCallInfo?.commonConferenceInfo;
            this.authService.setAuthInfo({ jwt: incomingCallInfo?.jwt });
            const roomInfo = incomingCallInfo?.roomInfo;
            // var useHd = roomInfo?.webinarDetails?.attendeeFeed === 'rtmp';
            // const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(
            //     roomInfo,
            //     false,
            //     useHd
            // );
            // additionalConferenceInfo.ownerDetails = commonConferenceInfo.ownerDetails;
            // const conferenceInfo = {
            //     ...commonConferenceInfo,
            //     ...additionalConferenceInfo,
            //     meetingId: roomInfo?.meetingId,
            //     parentMeetingId: roomInfo?.parentMeetingId,
            //     webinar: roomInfo?.webinarDetails,
            //     webinarOptions: roomInfo?.webinarDetails?.options,
            //     meetingUrl: roomInfo?.meetingUrl
            //         ? roomInfo?.meetingUrl
            //         : `${this.appService.getJioEventsBaseUrl()}shortener?meetingId=${roomInfo?.jiomeetId}&pwd=${
            //               roomInfo?.roomPIN || roomInfo?.roomPin
            //           }`,
            //     currentUserInfo: roomInfo?.currentUserInfo,
            //     meetingInfo: { parentMeetingId: roomInfo?.parentMeetingId, webinar: roomInfo?.webinarDetails }
            // };

            // this.rtcService.setConferenceInfo(conferenceInfo);
            // Remove query params
            this.router.navigate([`attendee/${roomInfo?.jiomeetId}/join/${roomInfo?.roomPIN || roomInfo?.roomPin}`], {
                queryParams: {
                    incoming: null,
                    hash: null
                },
                queryParamsHandling: 'merge'
            });
        }
        return of(true);
    }
}
