import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { EXPLORE_EVENTS } from 'src/app/constants/gtm-events';
import { EventViewSection } from 'src/app/constants/webinar-enum';
import { AppService, UtilService } from 'src/app/core';
import { ExploreEventsService } from 'src/app/core/services/explore-events.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { SwiperOptions } from 'swiper';

@Component({
    selector: 'app-event-slider',
    templateUrl: './event-slider.component.html',
    styleUrls: ['./event-slider.component.scss']
})
export class EventSliderComponent implements OnInit, OnDestroy {
    constructor(
        private exploreEventsService: ExploreEventsService,
        public utilservice: UtilService,
        private googleTagManager: GoogleTagMangerService,
        private appService: AppService
    ) {}
    readonly EventViewSection = EventViewSection;
    @Input() sliderName: string;
    @Input() dashboard;
    @Input() eventsList = [];
    @Input() sliderHeading: string;
    @Input() id;
    @Input() isLoading = false;
    @Input() hasCalIcon = false;
    @Input() hasFavIcon = false;
    @Output() viewall: EventEmitter<any> = new EventEmitter();
    @Output() favouriteEvent: EventEmitter<any> = new EventEmitter();
    @Output() getEvents: EventEmitter<any> = new EventEmitter();
    @ViewChild(CdkVirtualScrollViewport) eventsScrollViewport: CdkVirtualScrollViewport;
    viewAll = false;
    isMobile;

    nextButton;
    prevButton;

    firstLoading = true;
    newEventsLoading = false;
    offset = 0;
    moreAvailable = false;
    subscriptions = [];

    public config: SwiperOptions;
    currentPage: string = 'explore_events_page';
    ngOnInit(): void {
        this.sliderHeading = this.sliderName.charAt(0).toUpperCase() + this.sliderName.slice(1) + ' Events';
        this.setSliderHeading();
        this.subscribeToExploreEventsSubjects();
        this.nextButton = 'next-' + this.sliderName;
        this.prevButton = 'prev-' + this.sliderName;
        this.setSwiperConfig();
        this.isMobile = this.utilservice.isMobileBrowser();
        if (this.dashboard) {
            this.currentPage = 'dashboard_explore_events';
        }
    }

    subscribeToExploreEventsSubjects() {
        if (this.exploreEventsService[this.sliderName + 'Object']) {
            this.subscriptions.push(
                this.exploreEventsService[this.sliderName + 'Object'].subscribe((data) => {
                    if (data.add) {
                        this.eventsList.push(...data.resultMeetingDetails);
                        this.newEventsLoading = false;
                    } else this.eventsList = data.resultMeetingDetails;
                    this.moreAvailable = data.moreAvailable;
                    this.isLoading = false;
                })
            );
        }
        if (this.exploreEventsService[this.sliderName + 'Object']) {
            this.subscriptions.push(
                this.exploreEventsService[this.sliderName + 'Object'].subscribe((data) => {
                    if (data.add) {
                        this.eventsList.push(...data.resultMeetingDetails);
                        this.newEventsLoading = false;
                    } else this.eventsList = data.resultMeetingDetails;
                    this.moreAvailable = data.moreAvailable;
                    this.isLoading = false;
                })
            );
        }
        if (this.exploreEventsService.favouriteDetails) {
            this.subscriptions.push(
                this.exploreEventsService.favouriteDetails.subscribe(
                    (data) => {
                        if (data.sliderName == this.sliderName) {
                            this.eventsList[data.index].favouriteId = data._id;
                        }
                    },
                    (err) => {
                        console.log('Error occurred slider Name');
                    }
                )
            );
        }
    }

    setSliderHeading() {
        switch (this.sliderName) {
            case 'replay':
                this.sliderHeading =
                    this.appService.getConfigVariable('dashboard_category_titles').category_title_watch_now;
                break;
            case 'recommended':
                this.sliderHeading =
                    this.appService.getConfigVariable('dashboard_category_titles').category_title_recommended;
                break;
            case 'ongoing':
                this.sliderHeading =
                    this.appService.getConfigVariable('dashboard_category_titles').category_title_ongoing;
                break;
            case 'trending':
                this.sliderHeading =
                    this.appService.getConfigVariable('dashboard_category_titles').category_title_trending;
                break;
            case 'upcoming':
                this.sliderHeading =
                    this.appService.getConfigVariable('dashboard_category_titles').category_title_upcoming;
                break;
        }
    }

    setSwiperConfig() {
        this.config = {
            speed: 600,
            observer: true,
            observeParents: true,
            loop: false,
            autoplay: false,
            slidesPerView: 'auto',
            pagination: false,
            allowTouchMove: false,
            navigation: {
                nextEl: '.' + this.nextButton,
                prevEl: '.' + this.prevButton,
                disabledClass: 'button-disabled'
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 16,
                    freeMode: true,
                    allowTouchMove: true
                },
                300: {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    freeMode: true,
                    allowTouchMove: true
                },
                440: {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    freeMode: true,
                    allowTouchMove: true
                },
                720: {
                    slidesPerView: 'auto',
                    spaceBetween: 0
                },

                1080: {
                    slidesPerView: 'auto',
                    spaceBetween: 0
                }
            }
        };
    }

    viewAllEvents() {
        this.isLoading = this.sliderName !== 'all' ? true : false;
        this.viewall.emit(this.sliderName);
        this.viewAll = !this.viewAll;
        if (!this.viewAll) this.offset = 0;
        let evenType;
        switch (this.sliderHeading) {
            case 'Trending Events':
                evenType = EXPLORE_EVENTS.TRENDING_VIEWALL_CLICK;
                break;
            case 'Recommended Events':
                evenType = EXPLORE_EVENTS.RECOMMENDED_VIEWALL_CLICK;
                break;
            case 'Upcoming Events':
                evenType = EXPLORE_EVENTS.UPCOMING_VIEWALL_CLICK;
                break;
            case 'Ongoing Events':
                evenType = EXPLORE_EVENTS.ONGOING_VIEWALL_CLICK;
                break;
            case 'public':
                evenType = EXPLORE_EVENTS.PUBLIC_VIEWALL_CLICK;
                break;
            case 'all':
                evenType = EXPLORE_EVENTS.ALL_VIEWALL_CLICK;
                break;
            default:
                evenType = EXPLORE_EVENTS.CUSTOM_VIEWALL_CLICK;
                let lowerCaseString = this.sliderHeading.toLowerCase();
                let modifiedString = lowerCaseString.replace(/ /g, '_');
                let finalString = 'view_all_' + modifiedString;
                evenType.clicked_text = finalString;
        }
        if (!evenType) return;
        if (this.viewAll) {
            this.googleTagManager.pushGoogleTagData(Object.assign(evenType, { current_page: this.currentPage }));
        }
    }

    getMoreEvents() {
        if (!this.moreAvailable) return;
        this.newEventsLoading = true;
        this.offset += 9;
        this.getEvents.emit({
            name: this.sliderName,
            offset: this.offset
        });
    }
    eventClicked(event, i) {
        window.open(event.link);
        let position = i + 1;
        let evenType;
        switch (this.sliderHeading) {
            case 'Trending Events':
                evenType = EXPLORE_EVENTS.TRENDING_EVENT_CLICK;
                break;
            case 'Recommended Events':
                evenType = EXPLORE_EVENTS.RECOMMENDED_EVENT_CLICK;
                break;
            case 'Upcoming Events':
            case 'My':
                evenType = EXPLORE_EVENTS.UPCOMING_EVENT_CLICK;
                break;
            case 'Ongoing Events':
                evenType = EXPLORE_EVENTS.ONGOING_EVENT_CLICK;
                break;
            case 'Public':
                evenType = EXPLORE_EVENTS.PUBLIC_VIEWALL_CLICK;
                break;
            default:
                evenType = EXPLORE_EVENTS.CUSTOM_EVENT_CLICK;
                let lowerCaseString = this.sliderHeading.toLowerCase();
                let modifiedString = lowerCaseString.replace(/ /g, '_');
                let finalString = modifiedString + '_event_visit';
                evenType.event = finalString;
        }
        if (!evenType) return;
        evenType.position_id = position;
        evenType.clicked_text = event.name;
        this.googleTagManager.pushGoogleTagData(Object.assign(evenType, { current_page: this.currentPage }));
    }

    markFavourite(event, i) {
        this.favouriteEvent.emit({
            ...event,
            index: i,
            sliderName: this.sliderName
        });
    }

    trackByFunction(index, item) {
        return item.eventId;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
