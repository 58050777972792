import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';

import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
import { SOLUTIONS } from '../../../constants/gtm-events';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { UtilService } from 'src/app/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {
    loginPage: any = '/login';
    constructor(
        private appService: AppService,
        public utilService: UtilService,
        private router: Router,
        private googleTagManager: GoogleTagMangerService
    ) {
        this.loginPage = this.appService.getLoginPageUrl();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.utilService.isOldVersion = !this.isBetaRoute(event.url);
        });
    }
    private isBetaRoute(url: string): boolean {
        return (
            url.includes('/home') ||
            url.includes('/pricing') ||
            url.includes('/contactus') ||
            url.includes('/explore-events') ||
            url.includes('/solutions') ||
            url.includes('/customer')
        );
    }
    solutionsArray: any = [
        {
            title: 'Virtual/Hybrid Events',
            description: 'Cutting-edge solutions for Virtual/Hybrid Events that engage audiences like never before',
            image: 'swap-me-30@2x.png',
            eventKey: 'VIRTUAL_HYBRID_EVENTS'
        },
        {
            title: 'Automated Events',
            description: 'Experience seamless event management with Automated Events solution',
            image: 'virtual_events.png',
            eventKey: 'AUTOMATED_EVENTS'
        },
        {
            title: 'White Glove Services',
            description: 'Elevate your events to the next level with White Glove Services for a premium experience.',
            image: 'whitegloves.png',
            eventKey: 'WHITE_GLOVE_SERVICE'
        }
    ];

    reports: any = [
        {
            title: 'Sponsor Branding Report',
            description: 'Tracking reports for sponsors',
            image: 'swap-me-40@2x.png',
            eventKey: 'BRANDING'
        },
        {
            title: 'Booth reports',
            description: 'Booth summary, Booth visitor report',
            image: 'swap-me-33@2x.png',
            eventKey: 'BOOTHS_REPORTS'
        },
        {
            title: 'Analytics and reports',
            description:
                'Real Time Analytics, Event summary, Total attendees and engagement data, Session-wise attendee data & Chat, Q&A, and polls report',
            image: 'swap-me-44@2x.png',
            eventKey: 'ANALYTICS_REPORTS'
        }
    ];
    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.SOLUTIONS_PAGE_VIEW);
    }

    redirectToLogin() {
        this.router.navigate(['/login']);
    }
    tryForFree() {
        this.googleTagManager.pushGoogleTagData(SOLUTIONS.TRY_FOR_FREE);
        this.redirectToContactUs();
    }
    redirectToContactUs() {
        this.router.navigate(['/contactus']);
    }
    solutionClick(solution) {
        this.googleTagManager.pushGoogleTagData(SOLUTIONS[solution.eventKey]);
    }
    reportsCliked(report) {
        this.googleTagManager.pushGoogleTagData(SOLUTIONS[report.eventKey]);
    }
    exploreJioevents() {
        this.googleTagManager.pushGoogleTagData(SOLUTIONS.EXPLORE_JIOEVENTS);
        this.redirectToLogin();
    }
}
