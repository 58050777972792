<div class="dropdown-options" [ngClass]="{ 'mobile-styles': isMobile }">
    <ng-container *ngFor="let option of options">
        <a
            class="dropdown-item"
            [id]="'dropdown_item_' + option.label + '_' + eventId"
            (click)="handleSelectedMenu($event, option)"
            *ngIf="!option.disabled; else disableActionTemplate"
            >{{ option.label }}</a
        >
        <ng-template #disableActionTemplate>
            <a class="dropdown-item disabled" [id]="'dropdown_item_' + option.label + '_' + eventId">{{
                option.label
            }}</a>
        </ng-template>
    </ng-container>
</div>
