import { Component, OnInit } from '@angular/core';
import { PreCallTestingService } from '../../services';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
    constructor(public preCallTestingService: PreCallTestingService) {}

    ngOnInit(): void {}

    restartTest() {
        const targetSection = document.getElementById('section-1');
        const headerHeight = 60;
        if (targetSection) {
            const offsetTop = targetSection.offsetTop - headerHeight;
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        }
        this.preCallTestingService.restartTest();
    }
}
