import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService, UtilService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { LeaderboardService } from 'src/app/core/services/leaderboard.service';

@Component({
    selector: 'app-leaderboard-winners-overlay',
    templateUrl: './leaderboard-winners-overlay.component.html',
    styleUrls: ['./leaderboard-winners-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LeaderboardWinnersOverlayComponent implements OnInit {
    @Input() isSourceTable: boolean = false;
    scoresList;
    winners: any = [];
    randomNumber = '1234';
    loading: boolean;
    isMobileOS: boolean = false;
    constructor(
        private appService: AppService,
        private leaderboardService: LeaderboardService,
        private callViewStateManagerService: CallViewStateManagerService,
        private callStateManagerService: CallStateManagerService,
        private utilService: UtilService
    ) {}

    async ngOnInit() {
        this.isMobileOS = this.utilService.isMobileBrowser();
        // await this.utilService.holdExecution(4000);
        // this.loading = true;
        await this.getScoresForWinners();
        // this.loading = false;
    }

    async getScoresForWinners() {
        if (!this.isSourceTable || !this.leaderboardService.scoresList) {
            await this.leaderboardService.handleGetLeaderboardScores(this.callStateManagerService.getParentMeetingId());
            this.scoresList = this.leaderboardService.scoresList;
        } else {
            this.scoresList = this.leaderboardService.scoresList;
        }
        try {
            this.winners = this.scoresList?.slice(0, 3);
        } catch (er) {
            console.log(er);
        }
    }

    getProfilePic(userId) {
        return `${this.appService.getEnvVariable('BASE_URL')}/profilepic/${userId}/profile.png?random=${
            this.randomNumber
        }`;
    }
}
