import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {
    AppService,
    AuthService,
    SessionStorageService,
    BillingService,
    UserService,
    LocalStorageService,
    ValidationService
} from 'src/app/core';
import { SwiperOptions } from 'swiper';
import { LocalService } from 'src/app/integrations/modules/myjio/local.service';
import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
import { PRICING } from '../../../constants/gtm-events';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
    loginPage: any = '/login';
    contactUsPage: any = '/contactus';
    profilePage: any = '/profile/my_profile';
    successPage: any = '/pricing?isSuccess=true';
    individual = true;
    monthly = true;
    quarterly = false;
    yearly = false;
    count = 1000;
    pricingPlans: any;
    paymentPlans: any;
    pricePlanType: any = {
        lite: 'Lite',
        standard: 'Standard',
        premium: 'Premium'
    };
    isAuthenticated = false;
    isLoading: boolean = false;
    isSuccess: boolean = false;
    isFailure: boolean = false;
    isShowActivePlan: boolean = false;
    invalidPincode: boolean = false;
    paymentButtonDisabled: boolean = false;
    selectedPlan;
    accountName;
    currentUser;
    city: string = '';
    state: string = '';
    pincode: string = '';
    gstAddress: string = '';
    enablePaymentFeature = false;
    successInterval$: any;
    showPopup;
    userName: string = '';
    selectedPlanType;
    userPrimaryIdentity: any;
    buttonText: string;
    pinCodeFetched: boolean;
    gstin: any;
    isSezAccount: boolean = false;
    isGstinValid: boolean = false;
    payload = {};
    userOrg: any;
    isPrimaryEmail: any;
    userSecondaryIdentity: any;
    otpSent: boolean;
    otp: any;
    otpRequired: boolean;
    constructor(
        private appService: AppService,
        private googleTagManager: GoogleTagMangerService,
        private router: Router,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private sessionStorageService: SessionStorageService,
        private validationService: ValidationService,
        private utilService: UtilService,
        private billingService: BillingService,
        private userService: UserService,
        private localService: LocalService,
        private toastrService: ToastrService,
        private localStorageSession: LocalStorageService,
        private appLoggerService: AppLoggerService
    ) {
        this.loginPage = this.appService.getLoginPageUrl();
    }

    public config: SwiperOptions = {
        speed: 6000,
        loop: false,
        autoplay: {
            delay: 50,
            disableOnInteraction: true,
            reverseDirection: true
        },
        slidesPerView: 'auto',
        pagination: false,
        breakpoints: {
            320: {
                slidesPerView: 3.5,
                spaceBetween: 16
            },

            600: {
                slidesPerView: 4.25,
                spaceBetween: 16
            }
        }
    };

    @ViewChild('videoPlayer') videoElement: ElementRef;

    playVideo() {
        const video = this.videoElement.nativeElement as HTMLVideoElement;
        video.play();
    }

    time: number = 6;
    display;
    interval;

    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.PRICING_PAGE_VIEW);
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.currentUser = this.userService.getUserSync();
        this.pricingPlans = this.appService.getConfigVariable('pricingPlans');
        this.checkForAdditionalPlans();
        this.pricePlanType = this.appService.getConfigVariable('PRICE_PLAN_TYPE');
        this.enablePaymentFeature = this.appService.getConfigVariable('enablePaymentFeature');
        this.monthly = true;
        this.toggleActivePlan();
        this.activatedRoute.queryParams.subscribe(
            (queryParams) => (
                (this.isLoading = queryParams.isLoading),
                (this.isFailure = queryParams.isFailure),
                (this.isSuccess = queryParams.isSuccess)
            )
        );

        if (this.isSuccess) {
            this.interval = setInterval(() => {
                if (this.time === 0) {
                    this.redirectDashboardPage();
                } else {
                    this.time--;
                }
                this.display = this.time;
            }, 1000);
            this.localStorageSession.removeItem('Plan');
            this.localStorageSession.removeItem('Monthly');
            this.localStorageSession.removeItem('Quarterly');
        }

        if (this.isFailure || this.isSuccess) {
            this.getUserDetails();
        }

        this.getChageItPricingPlans();
        this.userName = this.currentUser.name + ' ' + (this.currentUser.lname ? this.currentUser.lname : '');
        this.userPrimaryIdentity =
            this.currentUser.email === undefined ? this.currentUser.phoneNo : this.currentUser.email;
        if (this.currentUser.department) this.userOrg = this.currentUser.department;
        this.isPrimaryEmail = this.validationService.isEmail(this.userPrimaryIdentity);
        this.pincode = this.currentUser.pincode;
        this.city = this.currentUser.city;
        this.state = this.currentUser.state;
        this.udpateSecondaryInfoAndOtpRequired();
        this.updateButtonText();
        this.pinCodeFetched = this.currentUser.pincode && this.currentUser.city && this.currentUser.state;
    }

    getChageItPricingPlans() {
        /**
         * Pricing plans from ChargeIt are only used in case of payment
         * Moving this behind the enable payment feature flag
         */
        if (!this.enablePaymentFeature) return;
        this.billingService.getPricingPlansList().subscribe(
            (res: any) => {
                if (res.length > 0) {
                    this.paymentPlans = res;
                }
            },
            (err) => {
                this.appLoggerService.log('Error in getChageItPricingPlans', err);
            }
        );
    }

    checkForAdditionalPlans() {
        try {
            const { enablePaymentFeature, additionalPlans } = _.get(this.currentUser, 'webinarBetaAccess', {});
            if (!enablePaymentFeature || !additionalPlans) return;
            this.pricingPlans.individualPlans = [...this.pricingPlans.individualPlans, ...additionalPlans];
        } catch (err) {
            this.appLoggerService.log('Error in checkForAdditionalPlans', err);
        }
    }

    udpateSecondaryInfoAndOtpRequired(updateFromUserInfo = true, updatedValue = null) {
        const secondaryFromUserInfo = this.isPrimaryEmail
            ? this.currentUser.phoneNo || this.currentUser.secondary?.phoneNo
            : this.currentUser.email || this.currentUser.secondary?.email;
        if (updateFromUserInfo) {
            this.userSecondaryIdentity = secondaryFromUserInfo;
        }
        if (!updatedValue) updatedValue = this.userSecondaryIdentity;
        this.otpRequired = !secondaryFromUserInfo || !updatedValue || updatedValue !== secondaryFromUserInfo;
    }

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return minutes + ':' + (value - minutes * 60);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.updateButtonText();
    }

    updateButtonText() {
        this.buttonText = window.innerWidth < 460 ? 'Proceed' : 'Proceed To Payment';
    }

    updateOrgName(userOrg: string) {
        if (this.currentUser.organisation === userOrg) return;
        if (!userOrg || this.userOrg.length < 1) return;
        // User Org for Profile will updated on form submission
    }

    onInputPincode(pincode: string, event?: KeyboardEvent) {
        this.invalidPincode = false;
        const pincodeRegex = /^\d{6}$/;

        if (event) {
            // Handle Backspace and Delete key events
            if (event.key === 'Backspace' || event.key === 'Delete') {
                if (pincode.length > 0) {
                    pincode = pincode.slice(0, -1);
                    this.resetPincodeData();
                }
                if (pincode.length === 0) {
                    this.resetPincodeData(true);
                }
                return;
            }
            // Handle Enter key event
            if (event.key === 'Enter') {
                this.invalidPincode = true;
                return;
            }
        }

        if (pincode.length < 6) {
            this.invalidPincode = false;
            return;
        }

        if (!pincodeRegex.test(pincode)) {
            this.invalidPincode = true;
            this.resetPincodeData();
            return;
        }

        this.pincode = pincode;
        this.getAddress(this.pincode);
    }

    getAddress(pincode) {
        this.utilService.getAddress(pincode).subscribe(
            (address: any) => {
                this.city = address.city;
                this.state = address.state;
                this.invalidPincode = !this.city && !this.state;
                this.paymentButtonDisabled = this.invalidPincode;
                this.pinCodeFetched = !this.invalidPincode;
            },
            (error) => {
                console.error('invalid pincode error', error);
                this.invalidPincode = true;
            }
        );
    }

    resetPincodeData(resetFetched: boolean = false) {
        if (resetFetched) {
            this.pinCodeFetched = false;
        }
        this.city = '';
        this.state = '';
        this.paymentButtonDisabled = true;
    }

    onGstinInput(gstin: string, event?: KeyboardEvent) {
        if (event) {
            // Handle Backspace and Delete key events
            if (event.key === 'Backspace' || event.key === 'Delete') {
                this.resetGstinData();
                return;
            }
        }

        if (gstin.length !== 15) {
            return;
        }

        const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (!gstinPattern.test(gstin)) {
            console.error('Invalid GSTIN');
            this.resetGstinData();
            return;
        }

        this.gstin = gstin;
        this.billingService.getGstinDetails(gstin).subscribe(
            async (response: any) => {
                const data = response;
                this.gstAddress = `${data.address}, ${data.pincode}`;
                this.userOrg = data.organizationName ?? this.userOrg;
                this.isSezAccount = data.taxpayerType.includes('SEZ');
                this.isGstinValid = true;
                this.onInputPincode(data.pincode);
                if (this.isGstinValid && !this.isSezAccount) {
                    this.scrollPaymentFormToBottom();
                    /**
                     * Organisation Name comes into picture only if they input GST (Non-sez)
                     */
                    await this.updateOrgName(this.userOrg);
                    // If there is secondary already, then auto fill user extra info and no need for verification
                    // Also give option here to update the extra info (Like editable and we request for otp)
                }
            },
            (error) => {
                console.error('Error fetching GST details:', error);
                this.resetGstinData();
            }
        );
    }

    async scrollPaymentFormToBottom() {
        await this.utilService.holdExecution(0);
        document.getElementById('paymentFormBody').scrollTop = document.getElementById('paymentFormBody').scrollHeight;
    }

    resetGstinData() {
        this.gstAddress = '';
        this.isSezAccount = false;
        this.isGstinValid = false;
    }

    onSecondaryIdentityChange(userSecondaryIdentityVal) {
        this.udpateSecondaryInfoAndOtpRequired(false, userSecondaryIdentityVal);
    }

    isValidSecondaryIdentity() {
        let isValid = true;
        // check if primary identity is mobile or email and show according error.
        if (this.isPrimaryEmail && !this.userSecondaryIdentity.match(/^\d{10}$/)) {
            this.toastrService.error('Please enter valid 10 digit Mobile no.');
            isValid = false;
        } else if (!this.isPrimaryEmail && !this.validationService.isEmail(this.userSecondaryIdentity)) {
            this.toastrService.error('Please enter valid email.');
            isValid = false;
        }
        return isValid;
    }

    async sendOtp() {
        if (!this.isValidSecondaryIdentity()) return;
        try {
            const payload = {
                ...(this.isPrimaryEmail && { phoneNo: this.userSecondaryIdentity }),
                ...(!this.isPrimaryEmail && this.currentUser.phoneNo && { email: this.userSecondaryIdentity })
            };
            const getOtpRes = await this.billingService.getOtp(payload).toPromise();
            if (!(getOtpRes as any).success) {
                throw 'Please try again after sometime';
            }
            this.otpSent = true;
            this.toastrService.success('OTP sent successfully');
            this.scrollPaymentFormToBottom();
        } catch (err) {
            if (err.error.customCode === 400) {
                this.toastrService.info('Please try again after 15 minutes.');
                return;
            } else if (err.error.message === 'Validation Error' && err?.error?.errorsArray[0]?.property) {
                this.toastrService.error(
                    `Please enter valid ${err?.error?.errorsArray[0]?.property || 'email or phoneNo.'}`
                );
            } else if (err.error.message === 'INVALID_PHONE_NO') {
                this.toastrService.error('Please enter valid email or phoneNo.');
            } else {
                this.toastrService.error(err?.error?.errors || 'Something went wrong, please try again later.');
            }
        }
    }

    async verifyOTP() {
        if (!(this.isGstinValid && !this.isSezAccount) || !this.otp) return;
        try {
            const payload = {
                ...(this.userSecondaryIdentity && { identity: this.userSecondaryIdentity }),
                otp: this.otp
            };
            const verifyOtpRes = (await this.billingService.verifyOtp(payload).toPromise()) as any;
            if (!verifyOtpRes.success) {
                throw { error: verifyOtpRes };
            }
        } catch (err) {
            this.toastrService.error(
                err.error.message === 'OTPAUTHERROR'
                    ? 'Incorrect OTP'
                    : err.error.message === 'LIMITEXCEEDED'
                    ? 'Too many attempts'
                    : err.error.message === 'GENERATE_OTP_FOR_IDENTITY'
                    ? 'OTP has expired. Please try again with a new OTP.'
                    : err.error.errors || err
            );
            throw new Error('OTP_ERROR');
        }
    }

    togglePlan(plan: 'monthly' | 'quarterly' | 'yearly') {
        this.monthly = plan === 'monthly';
        this.quarterly = plan === 'quarterly';
        this.yearly = plan === 'yearly';
        this.toggleActivePlan();
    }

    onNumberClick(attendees: any) {
        this.count = attendees;
    }

    navigateToContactUs(type: string) {
        if (this.shouldNavigateToContactUs(type)) {
            this.router.navigateByUrl('/contactus');
        } else {
            this.prepareForPayment(type);
        }
    }

    updateUserProfile() {
        const updateUserPayload = {
            pincode: this.pincode,
            city: this.city,
            state: this.state,
            organisation: this.userOrg,
            department: this.userOrg
        };
        _.omitBy(updateUserPayload, _.isNil);
        return this.userService.updateProfile(updateUserPayload).toPromise();
    }

    shouldNavigateToContactUs(type: string) {
        return (
            this.currentUser.eventsProStatus ||
            this.currentUser.eventsFeaturesEnabled ||
            type === 'custom_plan' ||
            !this.enablePaymentFeature
        );
    }

    async prepareForPayment(type: string) {
        try {
            await this.verifyOTP();
            await this.updateUserProfile();
            this.selectedPlan = type;
            this.resetPlanData();
            this.localStorageSession.addItem('Plan', this.selectedPlan);
            this.localStorageSession.addItem('Monthly', this.monthly);
            this.localStorageSession.addItem('Quarterly', this.quarterly);

            const planId = this.paymentPlans?.find((plan) => plan.name === this.selectedPlan)?.chargeitPlanId;

            if (!this.isAuthenticated) {
                this.handleUnauthenticatedUser();
            } else {
                this.initiatePayment(planId);
            }
        } catch (err) {
            this.appLoggerService.log('Failed to prepare for payment', err);
        }
    }

    handleUnauthenticatedUser() {
        this.pinCodeFetched = false;
        this.sessionStorageService.addItem('returnUrl', '/pricing');
        const returnUrl = '/pricing';
        this.router.navigate(['login'], { queryParams: { returnUrl } });
    }

    initiatePayment(planId: string) {
        this.isLoading = true;
        const os = this.utilService.getOS();
        const osVersion = this.utilService.getOsVersion();
        this.payload = this.createPayload(planId, os, osVersion);

        this.billingService.createOrder(this.payload).subscribe(
            (res: any) => this.handlePaymentResponse(res),
            (err) => this.handlePaymentError(err)
        );
    }

    createPayload(planId: string, os: string, osVersion: string) {
        const payload = {
            planId: planId,
            callbackUrl: `${window.location.origin}${this.successPage},${window.location.origin}/pricing?isFailure=true`,
            paymentGateway: this.appService.getConfigVariable('paymentGateway'),
            country: 'IN',
            paymentMode: 'UPI',
            source: os,
            platform: {
                os: os,
                version: osVersion
            },
            isRecurringSupported: false,
            paymentFlow: 'seamless',
            productName: 'jioevents',
            billingFrequency: this.getBillingFrequency()
        };

        if (this.gstin) {
            return { ...payload, gstin: this.gstin };
        }

        return payload;
    }

    getBillingFrequency() {
        if (this.selectedPlanType == 'Trial') {
            return 'daily';
        }
        if (this.monthly) return 'monthly';
        if (this.quarterly) return 'quarterly';
        return 'yearly';
    }

    handlePaymentResponse(res: any) {
        if (res.success) {
            window.location.href = res.paymentUrl;
        }
        setTimeout(() => {
            this.isLoading = false;
        }, 9000);
    }

    handlePaymentError(err: any) {
        if (err?.error?.errorsArray?.length) {
            this.toastrService.error(err.error.errorsArray[0]?.message);
        } else if (err?.error?.errors) {
            this.toastrService.error(err.error.errors);
        }
        this.isLoading = false;
    }

    showPopupDetails(planType) {
        this.userOrg = this.userOrg ?? this.currentUser.organisation ?? this.currentUser.department;
        if (this.navigateToLogin()) {
            return;
        }

        if (this.navigateToContact(planType)) {
            this.router.navigateByUrl('/contactus');
        } else {
            this.showPopup = true;
            this.selectedPlanType = planType;
        }
    }

    navigateToLogin() {
        if (!this.isAuthenticated) {
            this.sessionStorageService.addItem('returnUrl', '/pricing');
            const returnUrl = '/pricing';
            this.router.navigate(['login'], { queryParams: { returnUrl } });
            return true;
        }
        return false;
    }

    navigateToContact(plantype: string) {
        return (
            this.currentUser.eventsProStatus ||
            this.currentUser.eventsFeaturesEnabled ||
            plantype === 'custom_plan' ||
            !this.enablePaymentFeature
        );
    }

    closePopup() {
        this.showPopup = false;
    }

    // TODO
    // need to add translations
    redirectToLogin() {
        window.location.href = this.loginPage;
    }

    redirectDashboardPage() {
        clearInterval(this.interval);
        // this.eventEmitterService.emit({ type: 'gotoAccount', data: null });
        this.router.navigateByUrl('/dashboard');
    }

    closeFailure() {
        this.isFailure = false;
        //window.location.href = window.location.origin + '/pricing';
    }

    getStartedNow() {
        this.googleTagManager.pushGoogleTagData(PRICING.GET_STARTED_FREE);
        this.redirectToLogin();
    }
    exploreNow() {
        this.googleTagManager.pushGoogleTagData(PRICING.EXPLORE_NOW);
        this.redirectToLogin();
    }

    getUserDetails() {
        this.userService.getUserData(this.localService.getUserToken).subscribe((user) => {
            this.currentUser = user;
            this.accountName = user.name + ' ' + user.lname;
            this.toggleActivePlan();
        });
    }

    resetPlanData() {
        if (this.isFailure) {
            this.selectedPlan = this.localStorageSession.getItem('Plan');
            this.monthly = this.localStorageSession.getItem('Monthly');
            this.quarterly = this.localStorageSession.getItem('Quarterly');
        }
    }

    toggleActivePlan() {
        switch (this.currentUser.eventsSubscriptionInfo?.billingFrequency.toLowerCase()) {
            case 'monthly':
                this.isShowActivePlan = this.monthly;
                break;
            case 'quarterly':
                this.isShowActivePlan = this.quarterly;
                break;
            case 'yearly':
                this.isShowActivePlan = this.yearly;
                break;
            default:
                this.isShowActivePlan = false;
                break;
        }
    }
}
