<app-loader [position]="'relative'" *ngIf="!showImagePanel"></app-loader>
<div class="background-settings-container" [class.hide]="!showImagePanel">
    <div id="preview-section">
        <div id="video-preview" class="video-section"></div>
    </div>
    <div class="bottom-section" *ngIf="showImagePanel">
        <div class="image-list-container">
            <div class="image-container">
                <div>
                    <img
                        [src]="noneSrc"
                        alt=""
                        (click)="clearBackground(); onSelect($event, 'none')"
                        class="image-icon"
                    />
                </div>
                <div>
                    <img
                        [src]="blurSrc"
                        alt=""
                        (click)="blurBackground(); onSelect($event, 'blur')"
                        class="image-icon"
                    />
                </div>
                <div>
                    <label for="file"
                        ><img
                            [src]="customSrc"
                            alt=""
                            class="image-icon"
                            (click)="onSelect($event, 'customClicked')"
                            pTooltip="File type supported .jpg/.png. File should be less than 5mb"
                            tooltipPosition="bottom"
                    /></label>
                    <input type="file" id="file" (change)="fileChangeEvent($event)" hidden accept="image/*" />
                </div>

                <div *ngFor="let img of localImageSet" class="custom-image-holder">
                    <img
                        src="{{ img }}"
                        alt="bg-image"
                        class="image-icon"
                        (click)="onSelect($event, 'customApplied')"
                    />
                    <span (click)="deleteCustomImage(img)" class="delete-icn-wrapper">
                        <img src="assets/images/attendee_emojis/new_icons/close_filled_icon_green.svg" alt="" />
                    </span>
                </div>
                <div *ngFor="let img of images; let i = index">
                    <img
                        src="{{ img.src }}"
                        alt="bg-image"
                        class="image-icon"
                        (click)="onSelect($event, 'default', i)"
                    />
                </div>
            </div>
        </div>
        <div class="vb-list-finshed"></div>
    </div>
</div>
