import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';

@Component({
    selector: 'app-add-link-popup',
    templateUrl: './add-link-popup.component.html',
    styleUrls: ['./add-link-popup.component.scss']
})
export class AddLinkPopupComponent implements OnInit, AfterViewInit {
    @Output() close = new EventEmitter();
    @Output() hyperLink = new EventEmitter();
    @Output() removeLink = new EventEmitter();
    @Input() selectionContent;
    @Input() wordCount;
    @Input() maxLength;
    @Input() editorContent;
    @Input() insideCall: boolean = false;

    form;
    editMode;
    errors: any = {};

    constructor(
        private fb: FormBuilder,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private callStateManagerService: CallStateManagerService
    ) {}

    ngOnInit(): void {
        this.insideCall = !!this.callStateManagerService?.isUserInCall;
        this.form = this.fb.group({
            text: ['', [Validators.required]],
            link: ['', [Validators.required]]
        });
        if (this.selectionContent.text) {
            this.form.get('text').setValue(this.selectionContent.text);
            this.editMode = true;
        }

        if (this.selectionContent.link) {
            this.form.get('link').setValue(this.selectionContent.link?.replace('https://', ''));
            this.editMode = true;
        }
    }
    ngAfterViewInit() {
        (document.getElementsByClassName('form-control')[0] as HTMLElement)?.focus();
    }

    cancel() {
        this.close.emit();
    }

    sendMessage() {
        this.form.value.link = this.form.value.link.trim();
        if (this.form.value.link.length != 0 && !String(this.form.value.link).includes('https://')) {
            this.form.value.link = `https://${this.form.value.link}`;
        }
        if (!this.validate()) {
            return;
        }
        this.errors = {};
        this.hyperLink.emit(this.form.value);
    }

    validate() {
        if (
            !/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
                this.form.value.link
            )
        ) {
            this.errors.link = this.translateService.instant('webinar-settings-post-session.add-link-popup.valid_link');
            return false;
        }
        var root = document.createElement('div');
        root.id = 'add-link-div';
        root.innerHTML = this.editorContent;
        var texts = [].map.call(root.querySelectorAll('a'), function (v) {
            return v.textContent || v.innerText || '';
        });

        if (this.editMode) {
            texts = texts.filter((t) => t !== this.selectionContent?.text);
        }

        if (texts.includes(this.form.value.text)) {
            this.toastrService.error('Hyperlink already exists for the entered text. Please enter the unique text.');
            document.getElementById('text-input').focus();
            return false;
        }

        return true;
    }
    remove() {
        this.removeLink.emit(this.form.value.text);
    }
    clear(control) {
        this.form.get(control).setValue('');
    }

    isDisabled() {
        if (this.form.value.text.trim() === '' || this.form.value.link.trim() === '') {
            return true;
        }
        return this.editMode
            ? !this.form?.valid ||
                  (this.selectionContent.text === this.form.value.text &&
                      this.selectionContent.link === this.form.value.link)
            : !this.form?.valid;
    }
}
