import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { RtcService, UserService, UtilService } from 'src/app/core';
import { WebinarService } from 'src/app/dashboard-webinars/services';

@Component({
    selector: 'app-event-invitation',
    templateUrl: './event-invitation.component.html',
    styleUrls: ['./event-invitation.component.scss']
})
export class EventInvitationComponent implements OnInit {
    @Input() id;
    @Input() meeting;
    @Input() invitationType: 'speaker' | 'attendee';
    speakerInviteInfo;
    durationString: any = '';
    sipUrl;
    speakerUrl: string;
    maskedUrl: string;
    constructor(
        private rtcService: RtcService,
        private utilService: UtilService,
        private webinarService: WebinarService
    ) {}

    ngOnInit() {
        this.maskedUrl =
            this.webinarService.getMaskedUrlForSpeaker() ??
            this.meeting?.maskedUrl ??
            this.meeting?.meetingInfo?.maskedUrl;
        this.speakerInviteInfo = this.meeting;
        this.speakerUrl =
            this.maskedUrl ??
            this.speakerInviteInfo?.url ??
            this.meeting?.meetingUrl ??
            this.meeting?.meetingInfo?.meetingUrl;
        this.getEventDuration();
        if (this.meeting?.legacyConnect?.SIP && this.meeting?.legacyConnect?.SIP?.url) {
            this.sipUrl = `sip:${this.meeting?.legacyConnect?.SIP?.url}`;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.invitationType?.currentValue) {
            this.invitationType = changes?.invitationType?.currentValue;
            if (this.invitationType === 'speaker') {
                this.speakerInviteInfo = this.rtcService.getShareUrlInfo();
            }
        }
    }

    getEventDuration() {
        let diffInSeconds = new Date(this.meeting?.endTime).getTime() - new Date(this.meeting?.startTime).getTime();
        let { hour, minutes } = this.utilService.convertMillseconds(diffInSeconds);
        let days;
        this.durationString = `${+hour > 0 ? hour + ' Hr ' : ''}${minutes} Min`;
    }
}
