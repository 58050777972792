export const WEBINAR_CONFERENCE_SOCKET_EVENTS = {
    NEW_QUES: 'NEW_QUES',
    TICKER: 'ticker',
    FLASH_CARD: 'flashcard',
    FLASH_CARDS: 'flashcards',
    QNA: 'qna',
    COMMENT: 'comment',
    QNASUNANS: 'qnasunans',
    SERVER_MEDIA_SHARE: 'servermediashare',
    MEDIA_SHARE_SYNC: 'servermediashareSync',
    LIKES_COUNT: 'likesCount',
    VIEWS_COUNT: 'viewsCount',
    WEBINAR_STARTED: 'webinarstarted',
    WEBINAR_STOPPED: 'webinarstopped',
    WEBINAR_ENDED: 'webinarended',
    WEBINAR_PAUSED: 'webinarpaused',
    WEBINAR_RESUMED: 'webinarresumed',
    WEBINAR_BROADCAST_ERROR: 'webinarbroadcasterror',
    YT_LIVESTREAM: 'ytlivestream',
    YT_LIVESTREAM_ERROR: 'ytlivestreamerror',
    FB_LIVESTREAM: 'fblivestream',
    FB_LIVESTREAM_ERROR: 'fblivestreamerror',
    CUSTOM_LIVESTREAM: 'customlivestream',
    CUSTOM_LIVESTREAM_ERROR: 'customlivestreamerror',
    RECORDING_STATUS: 'recordingStatusUpdate',
    QUES_ANSWERED: 'quesAnswered',
    QUES_REJECTED: 'quesRejected',
    QUES_ANSWER_LIVE: 'quesAnswerLive',
    TIMER: 'timer',
    QNA_TIME_LIMIT_CHANGE: 'qnaTimeLimitChange',
    YTLIVESTREAMDETAILSUPDATE: 'ytlivestreamdetailsupdate',
    FBLIVESTREAMDETAILSUPDATE: 'fblivestreamdetailsupdate',
    CUSTOMLIVESTREAMDETAILSUPDATE: 'customlivestreamdetailsupdate',
    POLL_CREATED: 'pollcreated',
    POLL_EDITED: 'polledited',
    POLL_DELETED: 'polldeleted',
    POLL_PUBLISHED: 'pollislive',
    POLL_ENDED: 'pollended',
    CLOSE_TIME: 'closeTime'
};
