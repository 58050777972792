import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ProfileService, RoomConnectionService, UtilService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { MediaStreamRetrieverService } from 'src/app/core/services/media-stream-retriever.service';

@Component({
    selector: 'app-gallery-participant-tile',
    templateUrl: './gallery-participant-tile.component.html',
    styleUrls: ['./gallery-participant-tile.component.scss']
})
export class GalleryParticipantTileComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() participantData;
    @Output() participantTileUpdates = new EventEmitter();
    videoContainerId;
    participantMeta = {
        initials: '',
        bgColor: '',
        name: '',
        image: null
    };
    subscriptions = [];
    rcsRetryCount: number = 0;

    constructor(
        private mediaStreamRetrieverService: MediaStreamRetrieverService,
        private profileService: ProfileService,
        private roomConnectionService: RoomConnectionService,
        private callStateManager: CallStateManagerService,
        private appLoggerService: AppLoggerService,
        private utilService: UtilService
    ) {}

    ngOnInit(): void {
        /**
         * Subscribe to audio stream (Being done in agora service itself)
         * Subscribe to video stream
         * Then get meta data from room connection status
         * Assign Video Container Id based on uid
         * Get a color for initials based on UID and User name
         * Make sure no two adjacent name have the same color
         * Set Participant Initials
         */
        this.assignVideoContainerId();
        this.getParticipantMetaFromRCS();
        this.subscribeToParticipantUpdates();
    }

    ngOnChanges() {
        // if (this.participantData.hasVideo) {
        //     this.subscribeToVideo();
        // }
    }

    ngAfterViewInit() {
        this.appLoggerService.log('ngAfterViewInit called, gallery participant tile', this.participantData.uid);
        this.subscribeToVideo();
    }

    subscribeToParticipantUpdates() {
        this.subscriptions.push(
            this.mediaStreamRetrieverService.getGalleryViewUpdatesEmitter().subscribe((event: any) => {
                if (event.type === 'participantUpdate') {
                    this.handleParticpantUpdate(event.data);
                }
            })
        );
    }

    handleParticpantUpdate(event) {
        switch (event.type) {
            case 'userStreamPublished':
                this.appLoggerService.log('userStreamPublished called in gallery participant tile', event);
                // this.handleUserStreamPublished(event);
                if (event.data.uid === this.participantData.uid) {
                    this.participantData = event.data;
                    this.subscribeToVideo();
                }
                break;
            case 'videoOff':
                this.appLoggerService.log('VideoOff called in gallery participant tile', event);
                if (event.data.uid === this.participantData.uid) {
                    this.participantData = event.data;
                    this.handleVideoOff();
                }
                break;
        }
    }

    handleVideoOff() {
        const videoElement = document.getElementById(this.videoContainerId) as HTMLVideoElement;
        if (!videoElement) {
            this.appLoggerService.log('No video element present, gallery participant tile component');
            return;
        }
        videoElement.srcObject = null;
    }

    async subscribeToVideo() {
        this.appLoggerService.log('subscribe to video called', this.participantData);
        if (!this.participantData.hasVideo && !this.participantData._video_enabled_) return;
        const videoStream = await this.mediaStreamRetrieverService.getOrCreateMediaStreamClone(
            this.participantData.uid
        );
        if (!videoStream) {
            this.appLoggerService.log('No video stream present, gallery participant tile component');
            return;
        }
        const videoElement = document.getElementById(this.videoContainerId) as HTMLVideoElement;
        if (!videoElement) {
            this.appLoggerService.log('No video element present, gallery participant tile component');
            return;
        }
        videoElement.srcObject = videoStream;
        videoElement.play();
    }

    async getParticipantMetaFromRCS() {
        const participantMeta = this.roomConnectionService.getParticipantByParticipantId(this.participantData.uid);
        if (!participantMeta && this.rcsRetryCount < 5) {
            this.rcsRetryCount += 1;
            await this.utilService.holdExecution(2000);
            await this.callStateManager.getRoomConnectionStatus();
            this.getParticipantMetaFromRCS();
            return;
        } else if (!participantMeta && this.rcsRetryCount >= 5) {
            this.appLoggerService.log(
                'No participant meta, and removeParticipant emitted',
                this.rcsRetryCount,
                participantMeta
            );
            this.participantTileUpdates.emit({
                type: 'removeParticipant',
                participantId: this.participantData.uid
            });
            return;
        }
        this.setParticipantInitials(participantMeta);
    }

    assignVideoContainerId() {
        this.videoContainerId = `video-${this.participantData.uid}`;
    }

    getColorForInitials() {}

    setParticipantInitials(participantMeta) {
        this.participantMeta = {
            initials: this.profileService.getInitials(participantMeta.participantName, participantMeta.userId),
            bgColor: this.profileService.getColorForInitials(participantMeta.participantName, participantMeta.userId)
                .bg,
            name: participantMeta.participantName,
            image: participantMeta?.image
        };
    }

    ngOnDestroy() {
        this.appLoggerService.log('ngOnDestroy called, gallery participant tile', this.participantData.uid);
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
