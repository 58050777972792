<div
    class="scroll explore-events-not-dashboard"
    [class.isMobileView]="utilService.isMobileView"
    [class.dashboard]="dashboard"
    *ngIf="currentRoute !== '/dashboard'"
>
    <section
        class="events"
        [class.isMobile]="utilService.isMobileView"
        [class.events-view]="utilService?.showRibbon"
        [class.noResult]="
            trendingEventsLength == 0 &&
            recommendedEventsLength == 0 &&
            ongoingEventsLength == 0 &&
            upcomingEventsLength == 0 &&
            replayEventsLength == 0 &&
            (search != '' || eventType != 'all' || publishType != 'all' || eventDay != 'all')
        "
        [class.isOverFlowAuto]="!isSafariBrowser"
    >
        <div class="viewAllContainer">
            <div class="menubar justify-content-between" *ngIf="!utilService.isMobileView">
                <div *ngIf="dashboard" class="appliedfiltersarea desktop-l-view">
                    <div *ngIf="publishType != 'all'" class="appliedfilters filter_button">
                        {{ publishType == 'Limited' ? 'Organization' : publishType }}
                        <img
                            (click)="removeAppliedFilter('publishType')"
                            src="assets/img/website/cross_icon_dark.svg"
                            alt=""
                        />
                    </div>
                    <div *ngIf="eventDay != 'all'" class="appliedfilters filter_button">
                        {{ eventDay }}
                        <img (click)="removeAppliedFilter('eventDay')" src="assets/img/website/cross_icon_dark.svg" />
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="searchBarMenu"></ng-container>
                <div (outside)="clickOutside('filter')" class="filterarea desktop-view">
                    <div
                        class="filter filter_button"
                        (click)="toggleFilter($event)"
                        id="toggleFilterButton"
                        [class.filter-active]="filters"
                        tabindex="0"
                        aria-label="Filter"
                    >
                        <span *ngIf="!dashboard">Filters</span>
                        <span *ngIf="dashboard">Apply Filters</span>
                        <svg
                            *ngIf="!dashboard"
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14 6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H14C14.2652 8 14.5196 7.89464 14.7071 7.70711C14.8946 7.51957 15 7.26522 15 7C15 6.73478 14.8946 6.48043 14.7071 6.29289C14.5196 6.10536 14.2652 6 14 6ZM11 12H7C6.73478 12 6.48043 12.1054 6.29289 12.2929C6.10536 12.4804 6 12.7348 6 13C6 13.2652 6.10536 13.5196 6.29289 13.7071C6.48043 13.8946 6.73478 14 7 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13C12 12.7348 11.8946 12.4804 11.7071 12.2929C11.5196 12.1054 11.2652 12 11 12ZM17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H17C17.2652 2 17.5196 1.89464 17.7071 1.70711C17.8946 1.51957 18 1.26522 18 1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0Z"
                                fill="currentColor"
                            />
                        </svg>
                        <img *ngIf="dashboard" src="assets/img/filter_icon_dashboard.svg" />
                    </div>
                    <div *ngIf="filters" class="hidden-area">
                        <ng-container [ngTemplateOutlet]="filterForm"></ng-container>
                    </div>
                </div>
                <!-- #mySelect (keydown.enter)="openSelect()" -->
                <div (outside)="clickOutside('allEvents')" class="allEventsarea desktop-view">
                    <ng-container [ngTemplateOutlet]="eventTypeFilter"></ng-container>
                    <ng-container [ngTemplateOutlet]="eventTypeList"></ng-container>
                </div>

                <ng-container
                    *ngIf="headerRoute !== '/explore-events'"
                    [ngTemplateOutlet]="createEventMenu"
                ></ng-container>
            </div>
            <div class="menubar flex-column align-items-sm-stretch" *ngIf="utilService.isMobileView">
                <div class="d-flex justify-content-between" style="column-gap: 10px; width: 100%">
                    <ng-container [ngTemplateOutlet]="searchBarMenu"></ng-container>
                    <div (outside)="clickOutside('filter')" *ngIf="utilService.isMobileView">
                        <div class="alleventsmobile mobile-view btn_width_48_mob" (click)="toggleFilter($event)">
                            <svg
                                *ngIf="!dashboard"
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14 6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H14C14.2652 8 14.5196 7.89464 14.7071 7.70711C14.8946 7.51957 15 7.26522 15 7C15 6.73478 14.8946 6.48043 14.7071 6.29289C14.5196 6.10536 14.2652 6 14 6ZM11 12H7C6.73478 12 6.48043 12.1054 6.29289 12.2929C6.10536 12.4804 6 12.7348 6 13C6 13.2652 6.10536 13.5196 6.29289 13.7071C6.48043 13.8946 6.73478 14 7 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13C12 12.7348 11.8946 12.4804 11.7071 12.2929C11.5196 12.1054 11.2652 12 11 12ZM17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H17C17.2652 2 17.5196 1.89464 17.7071 1.70711C17.8946 1.51957 18 1.26522 18 1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <img *ngIf="dashboard" src="assets/img/filter_icon_dashboard.svg" width="25px" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between" style="gap: 12px; width: 100%">
                    <div
                        class="filter-mob-wrapper topic-overflow"
                        (outside)="clickOutside('allEvents')"
                        *ngIf="utilService.isMobileView"
                    >
                        <ng-container [ngTemplateOutlet]="eventTypeFilter"></ng-container>
                        <!-- <ng-container [ngTemplateOutlet]="eventTypeList"></ng-container> -->
                    </div>
                    <ng-container
                        *ngIf="headerRoute !== '/explore-events'"
                        [ngTemplateOutlet]="createEventMenu"
                    ></ng-container>
                </div>
            </div>
            <div *ngIf="!dashboard && (publishType != 'all' || eventDay != 'all')" class="appliedfiltersarea">
                <ng-container *ngFor="let filterValue of filterOptions">
                    <div *ngIf="publishType != 'all' || eventDay != 'all'" class="appliedfilters filter_button">
                        {{ filterValue }}
                        <img (click)="removeAppliedFilter(filterValue)" src="assets/img/website/cross_icon_dark.svg" />
                    </div>
                </ng-container>
            </div>
            <div *ngIf="dashboard" class="appliedfiltersarea desktop-s-view">
                <ng-container *ngFor="let filterValue of filterOptions">
                    <div *ngIf="publishType != 'all' || eventDay != 'all'" class="appliedfilters filter_button">
                        {{ filterValue }}
                        <img
                            (click)="removeAppliedFilter('filterValue')"
                            src="assets/img/website/cross_icon_dark.svg"
                        />
                    </div>
                </ng-container>
            </div>
            <div class="all_events" [class.empty-state-enabled]="eventInfo?.length === 0" [hidden]="favourites">
                <div class="slider-header">
                    <div id="slidePrevious" class="prev-button" data-navigate-to-explore (click)="navigateToExplore()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM12.21 13.29C12.3037 13.383 12.3781 13.4936 12.4289 13.6154C12.4797 13.7373 12.5058 13.868 12.5058 14C12.5058 14.132 12.4797 14.2627 12.4289 14.3846C12.3781 14.5064 12.3037 14.617 12.21 14.71C12.117 14.8037 12.0064 14.8781 11.8846 14.9289C11.7627 14.9797 11.632 15.0058 11.5 15.0058C11.368 15.0058 11.2373 14.9797 11.1154 14.9289C10.9936 14.8781 10.883 14.8037 10.79 14.71L6.79 10.71C6.69628 10.617 6.62188 10.5064 6.57111 10.3846C6.52034 10.2627 6.49421 10.132 6.49421 10C6.49421 9.86799 6.52034 9.73728 6.57111 9.61542C6.62188 9.49356 6.69628 9.38296 6.79 9.29L10.79 5.29C10.8832 5.19676 10.9939 5.1228 11.1158 5.07234C11.2376 5.02188 11.3681 4.99591 11.5 4.99591C11.6319 4.99591 11.7624 5.02188 11.8843 5.07234C12.0061 5.1228 12.1168 5.19676 12.21 5.29C12.3032 5.38324 12.3772 5.49393 12.4277 5.61575C12.4781 5.73757 12.5041 5.86814 12.5041 6C12.5041 6.13186 12.4781 6.26243 12.4277 6.38425C12.3772 6.50607 12.3032 6.61676 12.21 6.71L8.91 10L12.21 13.29Z"
                                fill="#58DAC5"
                            />
                        </svg>
                    </div>
                    <h4>{{ sliderHeading }}</h4>
                </div>

                <app-infinite-scroll class="view_all" (scrolled)="getMoreEvents()">
                    <ng-container *ngFor="let event of eventInfo; let i = index; trackBy: trackByFunction">
                        <app-event-card
                            [eventinfo]="event"
                            [dashboard]="dashboard"
                            class="ecard"
                            (eventClick)="eventClicked($event, i)"
                            [hasCalIcon]="true"
                            [hasFavIcon]="true"
                            (favouriteEvent)="markFavourite($event)"
                            [id]="'sliderHeading'"
                        ></app-event-card>
                    </ng-container>
                    <div *ngIf="newEventsLoading" class="d-flex justify-content-center w-100">
                        <app-loader [position]="'relative'"></app-loader>
                    </div>
                </app-infinite-scroll>
            </div>

            <ng-container *ngIf="!favourites">
                <ng-container *ngIf="!isLoading">
                    <ng-container *ngIf="eventInfo?.length === 0">
                        <div class="message" [ngClass]="{ 'search-data-container': search === '' }">
                            <ng-container
                                *ngIf="
                                    search !== '' || eventType !== 'all' || publishType !== 'all' || eventDay !== 'all';
                                    else noEvents
                                "
                            >
                                <ng-container
                                    *ngIf="search !== ''; then noSearchData; else noFilterData"
                                ></ng-container>
                            </ng-container>
                            <ng-template #noEvents>
                                <app-no-dashboard-data
                                    *ngIf="showNoEventsMessage && !isLoading"
                                    imgPath="assets/img/no_events_icon.svg"
                                    msg="No events available"
                                    class="no-data-wrapper d-flex align-items-center"
                                ></app-no-dashboard-data>
                            </ng-template>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <div *ngIf="favourites" class="sliderarea">
                <ng-container *ngIf="!isFavouritesLoading && !isLoading">
                    <div
                        *ngIf="
                            favouriteEventsList.length ||
                            (favouriteEventsList.length == 0 &&
                                publishType == 'all' &&
                                eventDay == 'all' &&
                                search == '')
                        "
                    >
                        <div class="slider-header">
                            <h4>My Favourites</h4>
                        </div>
                        <div class="slider-container">
                            <div *ngIf="favouriteEventsList.length">
                                <div class="view_all">
                                    <ng-container *ngFor="let cardnumber of favouriteEventsList">
                                        <app-event-card
                                            [eventinfo]="cardnumber"
                                            [dashboard]="dashboard"
                                            (favouriteEvent)="markFavourite($event, i)"
                                            class="ecard"
                                            (eventClick)="eventClicked($event, i)"
                                            [eventViewSection]="EventViewSection.DASHBOARD"
                                            [hasCalIcon]="true"
                                            [hasFavIcon]="true"
                                        ></app-event-card>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="message empty-state-for-favourite" *ngIf="!favouriteEventsList.length">
                                <ng-container [ngTemplateOutlet]="noFavouriteData"></ng-container>
                            </div>
                        </div>
                        <ng-container
                            [ngTemplateOutlet]="addToCal"
                            *ngIf="showCalendar && utilService.isMobileView"
                        ></ng-container>
                    </div>
                    <div
                        class="message"
                        *ngIf="
                            favouriteEventsList.length == 0 &&
                            (publishType != 'all' || eventDay != 'all' || search != '')
                        "
                    >
                        <ng-container [ngTemplateOutlet]="noSearchData" *ngIf="search != ''"></ng-container>
                        <ng-container [ngTemplateOutlet]="noFilterData" *ngIf="search === ''"></ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
    <div class="background-overlay" *ngIf="(filters || allEvents) && utilService.isMobileView"></div>
    <ng-container [ngTemplateOutlet]="eventTypeList" *ngIf="utilService.isMobileView"></ng-container>
    <div *ngIf="filters && utilService.isMobileView" class="mobile-bottom-dropdown">
        <ng-container [ngTemplateOutlet]="filterForm"></ng-container>
    </div>
</div>

<div class="scroll" [class.dashboard]="dashboard" *ngIf="currentRoute === '/dashboard'">
    <section class="" [class.isMobile]="utilService.isMobileView">
        <ng-container
            [ngTemplateOutlet]="eventSliderView"
            [ngTemplateOutletContext]="{ data: { count: dashboardData.length, eventsType: 'all' } }"
        ></ng-container>
    </section>
</div>

<ng-template #noSearchData>
    <app-no-dashboard-data
        imgPath="{{ 'assets/images/explore_no_search_result_mob.svg' }}"
        msg="No search results found"
        class="no-data-wrapper d-flex justify-content-center align-items-center"
    ></app-no-dashboard-data>
</ng-template>

<ng-template #noFilterData>
    <app-no-dashboard-data
        imgPath="assets/img/no_events_icon.svg"
        msg=""
        class="no-data-wrapper d-flex align-items-center no-filter-data"
    >
        <div class="no-data-text d-flex flex-column">
            <div
                class="no_events_text_1 d-flex flex-column"
                [class.align-items-center]="utilService.isMobileView"
                [class.align-items-start]="!utilService.isMobileView"
            >
                <span>No events available for the</span> <span>selected filter</span>
            </div>
            <div class="no_events_text_2 text-center">Please visit here after a while</div>
        </div>
    </app-no-dashboard-data>
</ng-template>

<ng-template #noFavouriteData>
    <app-no-dashboard-data
        imgPath="assets/img/no_events_icon.svg"
        msg=""
        class="no-data-wrapper d-flex align-items-center no-favourite-data"
    >
        <div class="no-data-text d-flex flex-column">
            <div class="no_events_text_1 d-flex flex-column">
                <span>No events have been marked as favourite </span> <span>by you yet.</span>
            </div>
            <div class="no_events_text_2">Events marked as favourites will be shown here.</div>
        </div>
    </app-no-dashboard-data>
</ng-template>

<ng-template #filterForm>
    <div class="filter-select" [ngClass]="{ 'filter-css': !isMobile || !utilService.isMobileView }">
        <ng-container *ngIf="utilService.isMobileView">
            <div class="text-end">
                <img src="assets/img/cross_white_bg.svg" alt="" id="close_filter" (click)="toggleFilter($event)" />
            </div>
        </ng-container>
        <div class="filter-list">
            <div>
                <div class="Event_type_filter_title">Event Type</div>
                <div class="Event_type_filter filter-item-wrapper">
                    <ng-container *ngFor="let item of publishTypeFilters.slice().reverse()">
                        <div
                            class="filter_button filters"
                            [id]="'filter_button_' + item.name"
                            (click)="filterItems(item.name, 'publishType', item.gtmValue)"
                            [class.filter_items-active]="publishTypeState == item.name"
                        >
                            {{ item.name }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="Event_day_filter">
                <div class="Event_type_filter_title">Event Day</div>
                <div class="day_filters filter-item-wrapper">
                    <ng-container *ngFor="let item of eventDayFilters">
                        <div
                            class="filter_button filters"
                            [id]="'filter_button_' + item.name"
                            (click)="filterItems(item.name, 'eventDay', item.gtmValue)"
                            [class.filter_items-active]="eventDayState == item.name"
                        >
                            {{ item.name }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="action-wrapper d-flex justify-content-between" style="column-gap: 16px">
                <div
                    class="filter_button cancel_btn flex-grow-1"
                    id="applyFilterCancelButton"
                    [class.apply_btn-active]="eventDayState != 'all' || publishTypeState != 'all'"
                    (click)="resetFilters()"
                    *ngIf="utilService.isMobileView"
                >
                    Cancel
                </div>
                <div
                    class="apply_btn filter_button flex-grow-1"
                    id="applyFilterButton"
                    [class.apply_btn-active]="eventDayState != 'all' || publishTypeState != 'all'"
                    (click)="applyFilters()"
                >
                    Apply
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #eventTypeList>
    <div
        *ngIf="allEvents"
        class="events-category-list mobile-bottom-dropdown"
        [class.desktop-view]="!utilService.isMobileView"
        [ngClass]="{
            'mobile-bottom-dropdown': utilService.isMobileView,
            'events_dropdown desktop-view': !utilService.isMobileView
        }"
    >
        <ng-container *ngIf="utilService.isMobileView">
            <div class="text-end">
                <img
                    src="assets/img/cross_white_bg.svg"
                    alt=""
                    id="close_events_dropdown"
                    (click)="toggleAllEvents()"
                />
            </div>
            <div class="label">Events</div>
        </ng-container>
        <div class="filter-options">
            <div class="events_favourites">
                <div
                    class="event_items"
                    id="event_items_allEvents"
                    (click)="allEventsItems('all', 'filter_all_events')"
                    [class.event_items-active]="eventType == 'all' && favourites == false"
                >
                    All Events
                </div>
                <div
                    class="event_items"
                    id="event_items_myFavourites"
                    (click)="favouritesFilter()"
                    [class.event_items-active]="favourites"
                >
                    My Favourites
                </div>
            </div>
            <div class="divider" *ngIf="!isMobile"></div>
            <div class="event_categories">
                <ng-container *ngFor="let item of eventTypeFilters; let i = index">
                    <div
                        (click)="allEventsItems(item.name, item.gtmValue)"
                        [class.event_items-active]="eventType == item.name"
                        class="event_items"
                        [id]="'event_categories_' + i"
                    >
                        {{ item.name }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #eventTypeFilter>
    <div
        [ngClass]="{
            'alleventsmobile mobile-view': utilService.isMobileView,
            allevents_btn: !utilService.isMobileView
        }"
        class="filter_button"
        id="toggleAllEventsMobile"
        (click)="toggleAllEvents()"
        [class.events-active]="allEvents && !utilService.isMobileView"
        tabindex="0"
    >
        <span *ngIf="!favourites" class="text-truncate">{{ eventType == 'all' ? 'All Events' : eventType }}</span>
        <span *ngIf="favourites" class="text-truncate">My Favourites</span>
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.50019 7.00019C5.36858 7.00095 5.23812 6.97572 5.11628 6.92596C4.99444 6.87619 4.88363 6.80287 4.79019 6.71019L0.790185 2.71019C0.601882 2.52188 0.496094 2.26649 0.496094 2.00019C0.496094 1.73388 0.601882 1.47849 0.790185 1.29018C0.978489 1.10188 1.23388 0.996094 1.50019 0.996094C1.76649 0.996094 2.02188 1.10188 2.21019 1.29018L5.50019 4.59019L8.79019 1.29018C8.97849 1.10188 9.23388 0.996094 9.50019 0.996094C9.76649 0.996094 10.0219 1.10188 10.2102 1.29018C10.3985 1.47849 10.5043 1.73388 10.5043 2.00019C10.5043 2.26649 10.3985 2.52188 10.2102 2.71019L6.21019 6.71019C6.11675 6.80287 6.00593 6.87619 5.88409 6.92596C5.76225 6.97572 5.63179 7.00095 5.50019 7.00019Z"
                fill="CurrentColor"
            />
        </svg>
    </div>
</ng-template>

<ng-template #searchBarMenu>
    <div class="searcharea" *ngIf="!dashboard" aria-roledescription="Search Event" role="button">
        <app-search-filter [id]="'search_explore_events'" (onSearch)="handleSearchAction($event)"></app-search-filter>
    </div>
</ng-template>
<ng-template #createEventMenu>
    <div class="create_event">
        <app-create-event-options
            [id]="'createEvent'"
            (virtualEvt)="createEvent($event)"
            (preRecordEvt)="createPreRecord($event)"
            btnCustomClass="bg-primary dark-theme"
        ></app-create-event-options>
    </div>
</ng-template>
<ng-template #eventListMenu></ng-template>

<div
    class="add-to-cal-wrapper mobile-bottom-dropdown"
    *ngIf="showCalendar && utilService.isMobileView"
    (outside)="showCalendar = false"
>
    <app-add-to-calendar
        class=""
        [joinDetail]="currentMeeting"
        [showAddToCalBtn]="false"
        (click)="showCalendar = false; $event.stopPropagation()"
    ></app-add-to-calendar>
</div>
<div class="loader" *ngIf="isLoading">
    <img src="assets/img/jioevents-loader.gif" width="100px" height="100px" alt="" />
</div>
