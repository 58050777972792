<app-info-modal
    [modalHeading]="'Video Output Results'"
    [iconName]="'camera-blue'"
    (closeModalEvent)="closeVideoResultModal()"
>
    <div class="result-container">
        <div class="single-info">
            <div class="info-heading">Test Duration</div>
            <div class="info-result">{{ cameraResult.testTiming.duration / 1000 }} Seconds</div>
        </div>
        <div class="single-info">
            <div class="info-heading">Device ID</div>
            <div class="info-result">
                {{ cameraResult.deviceId }}
            </div>
        </div>
        <div class="single-info">
            <div class="info-heading">Supported Resolution</div>
            <div class="info-result">
                <span>{{ cameraResult.supportedResolution.label }}</span>
                <span class="dot">.</span>
                <span>W:{{ cameraResult.supportedResolution.width }},</span>
                <span>H:{{ cameraResult.supportedResolution.height }}</span>
                <span class="dot">.</span>
                <span>Ratio:{{ cameraResult.supportedResolution.ratio }}</span>
            </div>
        </div>
        <div class="single-info">
            <div class="info-heading">Not Supported Resolution</div>
            <div class="info-result result-custom">
                <div class="info-result-item" *ngFor="let item of cameraResult.notSupportedResolution">
                    <span>{{ item.label }}</span>
                    <span class="dot">.</span>
                    <span>W:{{ item.width }},</span>
                    <span>H:{{ item.height }}</span>
                    <span class="dot">.</span>
                    <span>Ratio:{{ item.ratio }}</span>
                </div>
            </div>
        </div>
    </div>
</app-info-modal>
