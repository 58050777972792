import { Injectable } from '@angular/core';
import { JIO_CLOUD, FILE_FORMATS } from 'src/app/constants/jio-cloud';

import * as SparkMD5 from 'spark-md5';

@Injectable({
    providedIn: 'root'
})
export class JioCloudUtilService {
    constructor() {}

    async getChunkCheckSum(chunk) {
        return new Promise((resolve, reject) => {
            let spark = new SparkMD5.ArrayBuffer();
            spark.append(chunk);
            let chunkChecksum = spark.end();
            spark.destroy();
            if (chunkChecksum) {
                resolve(chunkChecksum);
            }
        });
    }

    getFileHash(file) {
        return new Promise((resolve, reject) => {
            let currentChunk = 0;
            let hash;
            const chunkSize = JIO_CLOUD.CHECKSUM_CALC_CHUNK_SIZE;
            const blobSlice = File.prototype.slice;
            const chunks = Math.ceil(file.size / chunkSize);
            file.fileReader = new FileReader();
            const hasher = new SparkMD5.ArrayBuffer();

            file.fileReader.onload = (evt) => {
                hasher.append(evt.target.result);
                currentChunk++;
                if (currentChunk < chunks) {
                    const currentOffset = chunkSize * currentChunk;
                    console.log('reaading chunk', currentChunk, 'out of ', chunks);
                    loadNext();
                } else {
                    hash = hasher.end();
                    resolve(hash);
                    hasher.destroy();
                }
            };

            file.fileReader.onerror = () => {
                reject('Error in reading file.');
            };

            function loadNext() {
                const start = currentChunk * chunkSize;
                const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
                file.fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
            }
            loadNext();
        });
    }

    getChunkSize(chunkUploadTime, currentChunkSize, fileSize, currentOffset) {
        var remainingBytes = fileSize - currentOffset;
        var maxChunkSizeInMB = JIO_CLOUD.MAX_CHUNK_SIZE_IN_MB;
        if (chunkUploadTime < 30 * 1000 && remainingBytes > maxChunkSizeInMB * 1024 * 1024) {
            currentChunkSize =
                currentChunkSize * 2 < maxChunkSizeInMB * 1024 * 1024
                    ? currentChunkSize * 2
                    : maxChunkSizeInMB * 1024 * 1024;
        } else if (
            chunkUploadTime >= 30 * 1000 &&
            chunkUploadTime < 60 * 1000 &&
            remainingBytes > maxChunkSizeInMB * 1024 * 1024
        ) {
            currentChunkSize =
                currentChunkSize * 1 < maxChunkSizeInMB * 1024 * 1024
                    ? currentChunkSize * 1
                    : maxChunkSizeInMB * 1024 * 1024;
        } else if (chunkUploadTime >= 60 * 1000 && remainingBytes > maxChunkSizeInMB * 1024 * 1024) {
            currentChunkSize = 128 * 1024;
        } else if (remainingBytes < maxChunkSizeInMB * 1024 * 1024) {
            currentChunkSize = 512 * 1024;
        }
        return currentChunkSize;
    }

    setMeataDataForUploadedFile(file) {
        function getObjectKind() {
            if (file.format === 'Document' || file.format === 'SpreadSheet' || file.format === 'Presentation') {
                return 'Miscrosoft' + ' ( ' + file.format + ' )';
            } else if (file.format === 'Folder') {
                return 'Folder';
            } else if (file.format === 'PDF') {
                return 'Adobe' + '  ( ' + file.format + ' )';
            } else if (file.format === 'text') {
                return 'text' + ' ( ' + file.mimeSubType + ' )';
            } else {
                return file.mimeType + ' ( ' + file.mimeSubType + ' )';
            }
        }

        function calculateSize() {
            if (
                file.objectType === 'FE' ||
                file.objectType === 'BE' ||
                file.objectType === 'DE' ||
                file.objectType === 'BB' ||
                file.objectType === 'SE'
            ) {
                const B = parseInt(file.sizeInBytes, 10);
                const KB = B / 1024;
                const MB = KB / 1024;
                const GB = MB / 1024;
                if (MB >= 1024) {
                    return GB.toFixed(2) + ' GB';
                } else if (KB >= 1024) {
                    return MB.toFixed(2) + ' MB';
                } else if (B >= 1024) {
                    return KB.toFixed(2) + ' KB';
                } else {
                    return B.toFixed(2) + ' B';
                }
            } else {
                return '-';
            }
        }

        if (file.objectType === 'FR') {
            file.format = 'Folder';
        } else {
            const shortName = function getShortName() {
                const extns = file.objectName.split('.');
                if (file.objectName.length > 20) {
                    return file.objectName.slice(0, 14) + '...' + extns[extns.length - 1];
                } else {
                    return file.objectName;
                }
            };

            const getFormat = function getFileFormat() {
                if (file.mimeType === 'image' || file.mimeType === 'audio' || file.mimeType === 'video') {
                    return file.mimeType;
                } else {
                    const extns = file.objectName.split('.');
                    const format = extns[extns.length - 1];
                    if (format === 'docx' || format === 'doc') {
                        return 'Document';
                    } else if (format === 'xlsx' || format === 'xls' || format === 'csv' || format === 'ods') {
                        return 'SpreadSheet';
                    } else if (format === 'pdf') {
                        return 'PDF';
                    } else if (format === 'pptx' || format === 'ppt' || format === 'odp') {
                        return 'Presentation';
                    } else if (
                        format === 'txt' ||
                        format === 'rtf' ||
                        format === 'html' ||
                        format === 'md' ||
                        format === 'less'
                    ) {
                        return 'text';
                    } else {
                        return 'application';
                    }
                }
            };
            file.format = getFormat();
            file.objectShortName = shortName();
        }
        file.objectKind = getObjectKind();
        file.objectSize = calculateSize();

        return file;
    }

    calculateSize(sizeInBytes) {
        const B = parseInt(sizeInBytes, 10);
        const KB = B / 1024;
        const MB = KB / 1024;
        const GB = MB / 1024;
        if (MB >= 1024) {
            return GB.toFixed(2) + ' GB';
        } else if (KB >= 1024) {
            return MB.toFixed(2) + ' MB';
        } else if (B >= 1024) {
            return KB.toFixed(2) + ' KB';
        } else {
            return B.toFixed(2) + ' B';
        }
    }

    getFileFormat(fileName) {
        const tempArray = (fileName || '').split('.');
        const extesnion = `.${tempArray[tempArray.length - 1]}`.toLowerCase();
        if (FILE_FORMATS.IMAGE.includes(extesnion)) {
            return 'Image';
        }
        if (FILE_FORMATS.VIDEO.includes(extesnion)) {
            return 'Video';
        }
        if (FILE_FORMATS.AUDIO.includes(extesnion)) {
            return 'Audio';
        }
        if (
            FILE_FORMATS.DOCUMENT.includes(extesnion) ||
            FILE_FORMATS.EXCEL.includes(extesnion) ||
            FILE_FORMATS.PPT.includes(extesnion) ||
            FILE_FORMATS.PDF.includes(extesnion) ||
            FILE_FORMATS.TXT.includes(extesnion)
        ) {
            return 'Doc';
        }

        return 'None';
    }

    getDefaultThumbnailForFile(fileType) {
        switch (fileType) {
            case 'Image':
                return 'assets/images/default-img.svg';
            case 'Video':
                return 'assets/images/defaultVideoIcon.svg';
            case 'Audio':
                return 'assets/images/defaultAudioIcon.svg';
            case 'Doc':
                return 'assets/images/defaultDocsIcon.svg';
            default:
                return 'assets/images/default-img.svg';
        }
    }

    getDocType(fileName) {
        const tempArray = (fileName || '').split('.');
        const extesnion = `.${tempArray[tempArray.length - 1]}`;
        if (FILE_FORMATS.DOCUMENT.includes(extesnion)) {
            return 'Word';
        }
        if (FILE_FORMATS.EXCEL.includes(extesnion)) {
            return 'Excel';
        }
        if (FILE_FORMATS.PPT.includes(extesnion)) {
            return 'PPT';
        }
        if (FILE_FORMATS.PDF.includes(extesnion)) {
            return 'PDF';
        }
        if (FILE_FORMATS.TXT.includes(extesnion)) {
            return 'TXT';
        }
        return 'Other';
    }
}
