export const GA_EVENTS = {
    PROFILE_EXPLORE_CLICK: {
        event: 'button_click',
        clicked_text: 'explore_jioevents',
        current_page: 'profile',
        outcome: 'succeeded'
    },
    PROFILE_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'profile',
        outcome: 'succeeded'
    },
    MYEVENTS_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'my_events',
        outcome: 'succeeded'
    },
    MYEVENTS_PAGE_CLICK: {
        event: 'my_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'my_events',
        outcome: 'succeeded'
    },
    MYEVENTS_PROFILE_EXPLORE: {
        event: 'button_click',
        clicked_text: 'explore_all_events',
        current_page: 'my_events',
        outcome: 'succeeded'
    },
    HOME_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    CAROUSEL_BANNER_CLICK: {
        event: 'banner_click',
        position_id: '<input = position of the banner in the carousel>',
        clicked_text: '<input = banner name>',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    TRENDING_VIEW_ALL: {
        event: 'button_click',
        clicked_text: 'view_all_trending_events',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    RECOMMENDED_VIEW_ALL: {
        event: 'button_click',
        clicked_text: 'view_all_recommended_events',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    UPCOMING_VIEW_ALL: {
        event: 'button_click',
        clicked_text: 'view_all_upcoming_events',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    ONGOING_VIEW_ALL: {
        event: 'button_click',
        clicked_text: 'view_all_ongoing_events',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    HOME_CLICK: {
        event: 'button_click',
        clicked_text: 'home',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    MYEVENTS_CLICK: {
        event: 'button_click',
        clicked_text: 'my_events',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    PROFILE_CLICK: {
        event: 'button_click',
        clicked_text: 'profile',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    TRENDING_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'trending_page',
        outcome: 'succeeded'
    },
    TRENDING_BACK_BTN: {
        event: 'button_click',
        clicked_text: 'back',
        current_page: 'trending_page',
        outcome: 'succeeded'
    },
    RECOMMENDED_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'recommended_page',
        outcome: 'succeeded'
    },
    RECOMMENDED_BACK_BTN: {
        event: 'button_click',
        clicked_text: 'back',
        current_page: 'recommended_page',
        outcome: 'succeeded'
    },
    UPCOMING_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'upcoming_page',
        outcome: 'succeeded'
    },
    UPCOMING_BACK_BTN: {
        event: 'button_click',
        clicked_text: 'back',
        current_page: 'upcoming_page',
        outcome: 'succeeded'
    },
    ONGOING_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'ongoing_page',
        outcome: 'succeeded'
    },
    ONGOING_BACK_BTN: {
        event: 'button_click',
        clicked_text: 'back',
        current_page: 'ongoing_page',
        outcome: 'succeeded'
    },
    TRENDING_SECTION_CLICK: {
        event: 'trending_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    ONGOING_SECTION_CLICK: {
        event: 'live_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    RECOMMENDED_SECTION_CLICK: {
        event: 'recommended_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    UPCOMING_SECTION_CLICK: {
        event: 'upcoming_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'home_page',
        outcome: 'succeeded'
    },
    TRENDING_PAGE_CLICK: {
        event: 'trending_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'trending_page',
        outcome: 'succeeded'
    },
    ONGOING_PAGE_CLICK: {
        event: 'live_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'ongoing_page',
        outcome: 'succeeded'
    },

    RECOMMENDED_PAGE_CLICK: {
        event: 'recommended_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'recommended_page',
        outcome: 'succeeded'
    },
    UPCOMING_PAGE_CLICK: {
        event: 'upcoming_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: 'upcoming_page',
        outcome: 'succeeded'
    },
    PAST_EVENT_VISIT: {
        event: 'past_event_visit',
        position_id: '<input = position of the event in the widget>',
        clicked_text: '<input = event name>',
        current_page: '<input = from whichever page the user joins the event>',
        outcome: 'succeeded'
    },
    VIDEO_BACK_CLICK: {
        event: 'button_click',
        clicked_text: 'exit',
        current_page: 'inside_event',
        outcome: 'succeeded'
    },
    VIDEO_PLAY_CLICK: {
        event: 'button_click',
        clicked_text: 'play',
        current_page: 'inside_event',
        outcome: 'succeeded'
    },
    VIDEO_PAUSE_CLICK: {
        event: 'button_click',
        clicked_text: 'pause',
        current_page: 'inside_event',
        outcome: 'succeeded'
    },
    REGISTER_PAGE_LOAD: {
        event: 'pageload',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_BACK_CLICK: {
        event: 'button_click',
        clicked_text: 'back',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_CALENDAR_CLICK: {
        event: 'button_click',
        clicked_text: 'add_to_calendar',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_CALENDAR: {
        event: 'button_click',
        clicked_text: 'add_to_calendar',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    VIEW_DETAILS: {
        event: 'button_click',
        clicked_text: 'view_details',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTRATION_FORM_LOAD: {
        event: 'pageload',
        current_page: 'registration_form',
        outcome: 'succeeded'
    },
    CANCEL_REGISTRATION_FORM: {
        event: 'button_click',
        clicked_text: 'close',
        current_page: 'registration_form',
        outcome: 'succeeded'
    },
    DATA_FILLED_NO_REGISTER: {
        event: 'user_not_submitting_registration_form',
        clicked_text: '<input = event name>',
        current_page: 'registration_form',
        outcome: 'succeeded'
    },
    REGISTER_FINAL_CLICK: {
        event: 'register',
        clicked_text: '<input = event name>',
        current_page: 'registration_form',
        outcome: 'succeeded'
    },
    DONE_CLICK: {
        event: 'button_click',
        clicked_text: 'done',
        current_page: 'resgitration_confirmation_popup',
        outcome: 'succeeded'
    },
    JOIN_EVENT_LOAD: {
        event: 'pageload',
        current_page: 'join_event',
        outcome: 'succeeded'
    },
    JOIN_EVENT_BACK: {
        event: 'button_click',
        clicked_text: 'back',
        current_page: 'join_event',
        outcome: 'succeeded'
    },
    JOIN_EVENT_CLICK: {
        event: 'join',
        clicked_text: '<input = event name>',
        current_page: 'join_event',
        outcome: 'succeeded'
    },
    // JOIN_NOW: {
    //     event: 'join_now',
    //     clicked_text: '<input = event name>',
    //     current_page: 'register_event',
    //     outcome: 'succeeded'
    // },
    REGISTER_SHARE_FB: {
        event: 'button_click',
        clicked_text: 'share_through_facebook',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_SHARE_TWITTER: {
        event: 'button_click',
        clicked_text: 'share_through_twitter',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_SHARE_LINKEDIN: {
        event: 'button_click',
        clicked_text: 'share_through_linkedin',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_SHARE_WA: {
        event: 'button_click',
        clicked_text: 'share_through_whatsapp',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_SHARE_MAIL: {
        event: 'button_click',
        clicked_text: 'share_through_mail',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    REGISTER_NOW_CLICK: {
        event: 'register_now',
        clicked_text: '<input = event name>',
        current_page: 'register_event',
        outcome: 'succeeded'
    },
    SEARCH_CLICK: {
        event: 'button_click',
        clicked_text: 'search_event',
        current_page: 'search_page',
        outcome: 'succeded'
    },
    SEARCH_BACK_CLICK: {
        event: 'button_click',
        clicked_text: 'back',
        current_page: 'search_page',
        outcome: 'succeded'
    },
    OTP_EVENT_CLICK: {
        event: 'button_click',
        clicked_text: '',
        current_page: 'attendee_register_page',
        outcome: 'succeded'
    }
};
