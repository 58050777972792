<section id="explore" class="explore section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1 class="jiotype-black-white-88px Support-main-txt">We are here to help you!</h1>
                </div>
                <div>
                    <!-- <span routerLinkActive="active-link" routerLink="/solutions">  -->
                    <button
                        tabindex="0"
                        aria-label="We are here to help you!, Search our support centre"
                        (click)="supportCentre()"
                        (keyup.enter)="supportCentre()"
                        class="support-btn"
                    >
                        <span class="support-btn-icon"><img src="assets/img/website/swap-me-45@2x.png" /></span
                        ><span class="support-btn-txt cursor-pointer">Search our support centre</span>
                    </button>
                </div>
            </div>
            <div
                class="col-lg-6 order-1 order-lg-2 explore-img-container aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img src="assets/img/website/mask-group-49@2x.png" class="img-fluid explore-img" alt="" />
            </div>
        </div>
    </div>
</section>
<section id="support-list" class="support-list section-bg">
    <div class="container">
        <div class="row">
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <a
                    tabindex="0"
                    aria-roledescription="Getting started"
                    role="button"
                    class="icon-box"
                    (click)="featureBoxClick(SUPPORT.GETTING_STARTED)"
                    (keyup.enter)="featureBoxClick(SUPPORT.GETTING_STARTED)"
                >
                    <div class="icon icon-circle">
                        <img class="icon-feature-img" src="assets/img/website/ic-enterpreneurial@2x.png" />
                    </div>
                    <h4 class="support-type-text">
                        <span class="jiotype-bold-white-18px cursor-pointer">Getting started</span>
                    </h4>
                </a>
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <label
                    tabindex="0"
                    aria-label="chan with us"
                    class="icon-box"
                    (click)="featureBoxClick(SUPPORT.CHAT_WITH_US)"
                    (keyup.enter)="featureBoxClick(SUPPORT.CHAT_WITH_US)"
                >
                    <div class="icon icon-circle">
                        <img class="icon-feature-img" src="assets/img/website/ic-chat-2@2x.png" />
                    </div>
                    <h4 class="support-type-text"><span class="jiotype-bold-white-18px">Chat with us</span></h4>
                </label>
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <label
                    tabindex="0"
                    aria-label="Faq"
                    class="icon-box"
                    (click)="featureBoxClick(SUPPORT.FAQ)"
                    (keyup.enter)="featureBoxClick(SUPPORT.FAQ)"
                >
                    <div class="icon icon-circle">
                        <img class="icon-feature-img" src="assets/img/website/ic-faq-1@2x.png" />
                    </div>
                    <h4 class="support-type-text"><span class="jiotype-bold-white-18px">FAQ</span></h4>
                </label>
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <label
                    tabindex="0"
                    aria-label="PRICE & SUBSCRIPTION"
                    class="icon-box"
                    (click)="featureBoxClick(SUPPORT.PRICE_SUBSCRIPTION)"
                    (keyup.enter)="featureBoxClick(SUPPORT.PRICE_SUBSCRIPTION)"
                >
                    <div class="icon icon-circle">
                        <img class="icon-feature-img" src="assets/img/website/ic-rupee-coin@2x.png" />
                    </div>
                    <h4 class="support-type-text"><span class="jiotype-bold-white-18px">Price & Subscription</span></h4>
                </label>
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <label
                    tabindex="0"
                    aria-label="TALK_TO_US"
                    class="icon-box"
                    (click)="featureBoxClick(SUPPORT.TALK_TO_US)"
                    (keyup.enter)="featureBoxClick(SUPPORT.TALK_TO_US)"
                >
                    <div class="icon icon-circle">
                        <img class="icon-feature-img" src="assets/img/website/ic-phone-number-landline@2x.png" />
                    </div>
                    <h4 class="support-type-text">
                        <span class="jiotype-bold-white-18px cursor-pointer">Talk to us</span>
                    </h4>
                </label>
            </div>
            <div
                class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <label
                    tabindex="0"
                    aria-label="White glove support"
                    class="icon-box"
                    (click)="featureBoxClick(SUPPORT.WHITE_GLOVE_SUPPORT)"
                    (keyup.enter)="featureBoxClick(SUPPORT.WHITE_GLOVE_SUPPORT)"
                >
                    <div class="icon icon-circle">
                        <img class="icon-feature-img" src="assets/img/website/ic-prime-content@2x.png" />
                    </div>
                    <h4 class="support-type-text">
                        <span class="jiotype-bold-white-18px cursor-pointer">White glove support</span>
                    </h4>
                </label>
            </div>
        </div>
    </div>
</section>
