import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class TagsService {
    tagsCollections: any = {};
    constructor(private restService: RestService, private appService: AppService) {}

    getEventTypes() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/jioconf/eventTypes`);
    }

    getTags() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/jioconf/tags`);
    }

    getTagsCollection() {
        return Object.keys(this.tagsCollections).length
            ? of(this.tagsCollections)
            : forkJoin([this.getEventTypes(), this.getTags()]).pipe(
                  map(([res1, res2]) => {
                      res1.eventTypes.map((data) => {
                          if (data.code > 0) {
                              this.tagsCollections[data.displayName] = res2.eventTags[data.code].tags;
                          }
                      });
                      this.tagsCollections = this.tagsCollections;
                      return this.tagsCollections;
                  })
              );
    }
}
