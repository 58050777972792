<div
    class="read-more-container d-flex"
    [class.visibility-hidden]="!isProcessed"
    [ngStyle]="{ 'row-gap': !isCollapsed && isCollapsable && isMyJio ? '0px' : '13px' }"
>
    <div
        tabindex="0"
        aria-label=""
        [class.overflow-hidden]="isCollapsable && isCollapsed"
        #overflowContainer
        [ngStyle]="{
            height: !isCollapsed && isCollapsable ? viewMoreHeight : viewLessHeight
        }"
        [class.mt-3]="!isMobileOS"
    >
        <ng-content></ng-content>
    </div>
    <a *ngIf="isCollapsable" (click)="isCollapsed = !isCollapsed" class="pointer view-more"
        >{{ isCollapsed ? 'View More' : 'View Less' }}
    </a>
</div>
