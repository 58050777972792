import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterAnimations } from '../../landing-page/router.animation';
import { SwiperOptions } from 'swiper';
import { AppService } from 'src/app/core/services/app.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
import { CUSTOMERS } from '../../../constants/gtm-events';
import { UtilService } from 'src/app/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
    animations: [RouterAnimations.routeSlide]
})
export class CustomerComponent implements OnInit {
    loginPage: any = '/login';
    constructor(
        private appService: AppService,
        private router: Router,
        private googleTagManger: GoogleTagMangerService,
        public utilService: UtilService
    ) {
        this.loginPage = this.appService.getLoginPageUrl();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.utilService.isOldVersion = !this.isBetaRoute(event.url);
        });
    }
    private isBetaRoute(url: string): boolean {
        return (
            url.includes('/home') ||
            url.includes('/pricing') ||
            url.includes('/contactus') ||
            url.includes('/explore-events') ||
            url.includes('/solutions') ||
            url.includes('/customer')
        );
    }
    ngOnInit(): void {
        this.googleTagManger.pushGoogleTagData(GA_PAGEVIEW.CUSTOMERS_PAGE_VIEW);
    }
    public slides = ['First slide', 'Second slide', 'Third slide', 'Fourth slide', 'Fifth slide', 'Sixth slide'];

    public config: SwiperOptions = {
        speed: 600,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 40
            },

            1200: {
                slidesPerView: 3
            }
        }
    };

    public onIndexChange(index: number): void {
        console.log('Swiper index: ', index);
    }

    public onSwiperEvent(event: string): void {
        console.log('Swiper event: ', event);
    }
    redirectToLogin() {
        this.router.navigate(['/login']);
    }
    redirectToContactUs() {
        this.router.navigate(['/contactus']);
    }
    getStartedNow() {
        this.googleTagManger.pushGoogleTagData(CUSTOMERS.GET_STARTED);
        this.redirectToContactUs();
    }
    getStartedBottom() {
        this.googleTagManger.pushGoogleTagData(CUSTOMERS.GET_STARTED_BOTTOM);
        this.redirectToLogin();
    }
    tryForFree() {
        this.googleTagManger.pushGoogleTagData(CUSTOMERS.TRY_FOR_FREE);
        this.redirectToLogin();
    }
}
