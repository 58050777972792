<div class="performance-test-container">
    <div class="performance-info-section">
        <div class="performance-test-heading">
            <div class="img-container">
                <img src="assets/images/pre-call-testing/performance_details.svg" alt="" />
            </div>
            <div class="title">Performance Details</div>
        </div>
        <div class="performance-test-subheading">Detailed audio and video quality information.</div>
    </div>
    <app-loader [position]="'relative'" *ngIf="!preCallTestingService.performanceResult"></app-loader>

    <div class="test-result-container" *ngIf="preCallTestingService.performanceResult">
        <div class="result-parameter">
            <div class="parameter-left">
                <div class="parameter-name">RTT (ms) avg/max</div>
            </div>
            <div class="parameter-right">
                <div class="parameter-img">
                    <img
                        [src]="getImageSource(preCallTestingService.performanceResult.mediaStats.rtt.score)"
                        alt="Quality Image"
                    />
                </div>
                <div class="parameter-value">
                    {{ preCallTestingService.performanceResult.mediaStats.rtt.average }}/{{
                        preCallTestingService.performanceResult.mediaStats.rtt.max
                    }}
                    ({{ preCallTestingService.performanceResult.mediaStats.rtt.score }})
                </div>
            </div>
        </div>
        <div class="result-parameter">
            <div class="parameter-left">
                <div class="parameter-name">Jitter (ms) avg/max</div>
            </div>

            <div class="parameter-right">
                <div class="parameter-img">
                    <img
                        [src]="getImageSource(preCallTestingService.performanceResult.mediaStats.jitter.average)"
                        alt="Quality Image"
                    />
                </div>
                <div class="parameter-value">
                    {{ preCallTestingService.performanceResult.mediaStats.jitter.average }}/{{
                        preCallTestingService.performanceResult.mediaStats.jitter.max
                    }}
                    ({{ preCallTestingService.performanceResult.mediaStats.jitter.score }})
                </div>
            </div>
        </div>
        <div class="result-parameter">
            <div class="parameter-left">
                <div class="parameter-name">Packet Loss avg/max</div>
            </div>
            <div class="parameter-right">
                <div class="parameter-img">
                    <img
                        [src]="
                            getImageSource(
                                preCallTestingService.performanceResult.mediaStats.packetsLostPercentage.score
                            )
                        "
                        alt="Quality Image"
                    />
                </div>
                <div class="parameter-value">
                    {{ preCallTestingService.performanceResult.mediaStats.packetsLostPercentage.average }}%/{{
                        preCallTestingService.performanceResult.mediaStats.packetsLostPercentage.max
                    }}% ({{ preCallTestingService.performanceResult.mediaStats.packetsLostPercentage.score }})
                </div>
            </div>
        </div>
        <div class="result-parameter" *ngIf="preCallTestingService.bitrateResult">
            <div class="parameter-left">
                <div class="parameter-name">Bitrate (kbps) avg/max</div>
            </div>

            <div class="parameter-right">
                <div class="parameter-img">
                    <img
                        [src]="getImageSource(preCallTestingService.bitrateResult.bitrate?.score)"
                        alt="Quality Image"
                    />
                </div>
                <div class="parameter-value">
                    {{ preCallTestingService.bitrateResult.bitrate?.average }}/{{
                        preCallTestingService.bitrateResult.bitrate?.max
                    }}
                    ({{ preCallTestingService.bitrateResult.bitrate?.score }})
                </div>
            </div>
        </div>
    </div>
    <div class="performance-info-section addBorder">
        <div class="performance-test-heading" style="margin-top: 20px">
            <div class="img-container">
                <img src="assets/images/pre-call-testing/call_quality.svg" alt="" />
            </div>
            <div class="title">Expected Call Quality</div>
        </div>
        <div class="expected-quality">
            <div class="img-container">
                <img src="assets/images/pre-call-testing/success.svg" alt="" />
            </div>
            <div class="quality-sub-heading">Excellent</div>
        </div>
    </div>
</div>
