import { Router } from '@angular/router';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    NgZone,
    ViewChild,
    ElementRef,
    AfterViewInit
} from '@angular/core';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import {
    RtcService,
    SocketService,
    UserService,
    SocketEvent,
    EventEmitterService,
    EventData,
    VideoWrapperService,
    MeetingService
} from 'src/app/core';
import { SOCKET_EVENTS, APP_EVENTS } from 'src/app/constants';

@Component({
    selector: 'app-incoming-call-popup',
    templateUrl: './incoming-call-popup.component.html',
    styleUrls: ['./incoming-call-popup.component.scss']
})
export class IncomingCallPopupComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('incomingCallPopup') popup: ElementRef<HTMLDivElement>;

    @Output() close: EventEmitter<any> = new EventEmitter();

    meeting: any = {};

    private currentUser;
    private audio: HTMLAudioElement = new Audio('./assets/audio/call.mp3');
    private incomingCallTimeoutId;
    private subscriptions: Subscription[] = [];

    constructor(
        private zone: NgZone,
        private router: Router,
        private toastrService: ToastrService,
        private rtcService: RtcService,
        private userService: UserService,
        private socketService: SocketService,
        private eventEmitterService: EventEmitterService,
        private videoWrapperService: VideoWrapperService,
        private meetingService: MeetingService
    ) {}

    ngOnInit() {
        this.meeting = this.rtcService.getIncomingCallMeeting();
        this.currentUser = this.userService.getUserSync();
        this.audio.loop = true;
        this.audio.play();

        this.incomingCallTimeoutId = setTimeout(() => {
            this.rtcService.sendCallNotAnsweredNotification().subscribe();
            this.cancel();
        }, 40 * 1000);

        this.subscriptions.push(
            this.socketService.dataEvents$.subscribe(this.handleSocketDataEvents.bind(this)),
            this.eventEmitterService.subscribe((event: EventData) => {
                if (event.type === APP_EVENTS.SESSION_EXPIRED || event.type === APP_EVENTS.LOGOUT) {
                    clearTimeout(this.incomingCallTimeoutId);
                    this.cancel();
                }
            })
        );
    }

    ngAfterViewInit() {
        this.positionItself();
    }

    handleSocketDataEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case SOCKET_EVENTS.CALL_ACCEPT:
                    if (event.data.historyId === this.meeting.historyId && event.data.userId === this.currentUser._id) {
                        this.cancel();
                    }
                    break;
                case SOCKET_EVENTS.GENERIC:
                    if (event.data.historyId === this.meeting.historyId && event.data.ownId === this.currentUser._id) {
                        if (
                            [
                                SOCKET_EVENTS.CALL_CUT_NOTIFICATION,
                                SOCKET_EVENTS.CALL_NOT_ANSWERED_NOTIFICATION,
                                SOCKET_EVENTS.IN_ANOTHER_CALL_NOTIFICATION
                            ].includes(event.data.eventType)
                        ) {
                            this.cancel();
                        }
                    }
                    break;
                case SOCKET_EVENTS.CALL_FINISH:
                    // {
                    //   data: {
                    //     dropExisting: false
                    //     host: "u-bff7ab2e-e6fb-418c-ba3b-23bd3680d2e0"
                    //     isFinished: true
                    //     journalId: "jrnl-7f2e96dc-7040-4330-8388-a5577d9bb14c"
                    //     meetingId: "1706742173"
                    //     roomKey: "ajQuxMFPCd"
                    //   }
                    //   event: "callFinish"
                    // }
                    if (this.meeting.jiomeetId === event.data.meetingId && this.meeting.room === event.data.roomKey) {
                        this.toastrService.info('Host has ended the meeting');
                        this.cancel();
                    }
            }
        });
    }

    declineCall() {
        if (!navigator.onLine) {
            this.toastrService.error('Oops! Please check your internet connection');
            this.cancel();
            return;
        }
        this.rtcService.sendCallRejectNotification().subscribe();
        this.cancel();
    }

    acceptCall() {
        if (!navigator.onLine) {
            this.toastrService.error('Oops! Please check your internet connection');
            this.cancel();
            return;
        }
        this.rtcService.sendCallAcceptNotification().subscribe();
        this.meetingService.getMeetingDetails(this.meeting?.meetingId).subscribe((res: any) => {
            this.prepareConferenceInfo(res);
        });
    }

    prepareConferenceInfo(meetingInfo) {
        const commonConferenceInfo = {
            isInitiater: false,
            joiningName: this.currentUser.name,
            room: {
                room_url: this.meeting.callurl,
                room_key: this.meeting.room,
                roomID: this.meeting.room_id,
                vpin: this.meeting.vpin,
                roomPIN: this.meeting.roomPIN,
                gateway_ip: '',
                jiomeetId: this.meeting.jiomeetId,
                topic: this.meeting.topic || this.meeting.title,
                advancedOptions: {
                    isClassroomMode: this.meeting.isClassroomMode
                }
            },
            ownerDetails: {
                name: this.meeting.owner,
                lname: '',
                _id: this.meeting.owner_id,
                email: this.meeting.owner_emailId,
                tenantId: this.meeting.owner_tenantId
            },
            hideMoreCallControls: false,
            hideAllCallControls: false,
            isFullScreenShare: false,
            meetingInfo: meetingInfo,
            incomingCall: true,
            meetingId: this.meeting?.meetingId,
            webinar: this.meeting?.webinarDetails,
            qnaTimeLimit: this.meeting?.webinarDetails?.qnaTimeLimit,
            webinarOptions: this.meeting?.webinarDetails?.options
        };
        this.cancel();
        var useHd = meetingInfo?.webinar?.attendeeFeed === 'rtmp';
        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(
            this.meeting,
            false,
            useHd
        );
        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
        this.rtcService.setConferenceInfo(conferenceInfo);
        this.router.navigate(['conference/call']);
    }

    cancel() {
        this.rtcService.setIncomingCallMeeting(null);
        this.audio.pause();
        this.close.emit();
    }

    positionItself() {
        const popup = this.popup.nativeElement;
        const body = document.body;
        const rect = popup.getBoundingClientRect();
        // popup.style.left = body.clientWidth / 2 - rect.width / 2 + 'px';
        // popup.style.top = body.clientHeight / 2 - rect.height / 2 + 'px';
        popup.style.left = body.clientWidth - rect.width - 40 + 'px';
        popup.style.top = 10 + 'px';
    }

    ngOnDestroy() {
        clearTimeout(this.incomingCallTimeoutId);
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
