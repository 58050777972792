import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppService, AuthService, RestService, UserService, UtilService } from 'src/app/core';
//import * as Clappr from 'clappr';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { Subscription, forkJoin } from 'rxjs';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
import moment from 'moment-timezone';
import { roundToNearest } from 'angular-calendar/modules/common/util';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, AfterViewInit {
    constructor(
        private appService: AppService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private utilService: UtilService,
        private webinarAttendeeService: WebinarAttendeeService,
        private authService: AuthService,
        private restService: RestService,
        private toasterService: ToastrService,
        private userService: UserService
    ) {}
    loading = true;
    webinarId;
    recordingDetails = [];
    videoUrls = [];
    player;
    isSafariMobile = false;
    authToken;
    public = false;
    sessionMeetingId = '';
    sessionNumber = 0;
    subscription2;
    showEventReplayPopup = false;
    newPopup;
    currentRecording = 0;
    totalRecordings = 0;
    showPlaylist = false;
    thumbnailurls = [];
    hideTimer;
    playlistHover = false;
    ishideadded = true;
    playlistImg;
    currentUser;
    ngOnInit(): void {
        this.currentUser = this.userService.getUserSync();
        this.loading = true;
        this.isSafariMobile = this.utilService.isMobileBrowser() && this.utilService.isSafariBrowser();
        this.authToken = this.authService.getIsAuthenticated() ? this.authService.getAuthInfo()?.jwt : null;
        this.webinarId = this.activatedRoute.snapshot.params.id;
        this.sessionMeetingId = this.activatedRoute.snapshot.queryParams.sessionMeetingId;
        if (history.state?.meetingId == this.webinarId) {
            this.setRecordingDetails(history.state);
        } else {
            this.webinarAttendeeService.getEventReplayDetails(this.webinarId).subscribe(
                (res) => {
                    this.setRecordingDetails(res);
                },
                (err) => {
                    this.toasterService.error(err.error.errors);
                }
            );
        }
        if (history.state?.past) {
            let subscription = this.router.events.subscribe((event: any) => {
                if (event.navigationTrigger === 'popstate') {
                    this.router.navigate(['dashboard'], {
                        queryParams: {
                            past: true
                        }
                    });
                } else {
                    subscription.unsubscribe();
                }
            });
        }
        // this.handleBrowserBackEvent();
    }

    setRecordingDetails(res) {
        if (res.recordingDetails) this.recordingDetails = res.recordingDetails;
        else {
            if (this.sessionMeetingId) {
                this.setSessionNumber(res.sessionDetails);
                this.recordingDetails = res.sessionDetails[this.sessionNumber]?.recordingDetails;
            } else {
                this.addRecordingDetails(res.sessionDetails);
            }
        }
        if (!this.recordingDetails.length) {
            this.toasterService.warning('No recordings available');
            this.router.navigate(['dashboard']);
        }
        this.public = res.publishType == 'Public';
        this.playlistImg = `assets/img/jioconf/welcome-slates-plain/${res.eventType}.png`;
        this.createMediaUrlArray();
    }

    async playVideoMedia(sourceUrl) {
        this.totalRecordings = this.recordingDetails.length;
        this.recordingDetails.forEach((element) => {
            const duration = moment.duration(element.duration);
            element.duration = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
        });
        await import('clappr').then(async (m: any) => {
            this.player = new m.default.Player({
                source: sourceUrl[0],
                persistConfig: true, // added
                plugins: [],
                allowUserInteraction: true,
                maxBufferLength: 5,
                mimeType: 'video/mp4 ',
                exitFullscreenOnEnd: false,
                playInline: true,
                playbackNotSupportedMessage: 'Please try on a different browser',
                mute: false,
                autoPlay: true,
                loop: false,
                events: {
                    onEnded: () => {
                        this.currentRecording++;
                        if (this.currentRecording < sourceUrl.length)
                            this.player.load(sourceUrl[this.currentRecording], 'video/mp4', true);
                        else {
                            this.currentRecording = 0;
                            this.player.load(sourceUrl[this.currentRecording], 'video/mp4', false);
                        }
                    },
                    onTimeUpdate: (event) => {
                        if (
                            event.total &&
                            (!this.recordingDetails[this.currentRecording].newDuration ||
                                this.recordingDetails[this.currentRecording].newDuration == '00:00:00')
                        ) {
                            const duration = moment.duration(event.total * 1000);
                            this.recordingDetails[this.currentRecording].newDuration = moment
                                .utc(duration.as('milliseconds'))
                                .format('HH:mm:ss');
                        }
                    },
                    onError: (error) => {
                        console.log(error);
                    }
                }
            });
            //getting the popup div and removing it and maintain it in newpopup and adding it to player
            if (this.totalRecordings > 1) {
                this.player.listenToOnce(this.player, m.default.Events.PLAYER_READY, () => {
                    const dataPlayer = document.querySelectorAll('[data-player]')[0];
                    var popup = document.getElementsByClassName('playlist-container')[0];
                    this.newPopup = popup;
                    popup.remove();
                    dataPlayer.appendChild(this.newPopup);
                    const mediaControl = document.getElementsByClassName('media-control')[0];
                    let observer = new MutationObserver((mutationsList, observer) => {
                        for (let mutation of mutationsList) {
                            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                                if (mediaControl.classList.contains('media-control-hide')) {
                                    if (!this.playlistHover) this.showPlaylist = false;
                                    this.newPopup.classList.add('hide');
                                    this.ishideadded = true;
                                } else {
                                    this.newPopup.classList.remove('hide');
                                    this.ishideadded = false;
                                }
                            }
                        }
                    });
                    observer.observe(mediaControl, {
                        attributes: true,
                        attributeFilter: ['class']
                    });
                });
            }
        });
        this.loading = false;

        const videoPlayer = document.getElementById('video-media');
        this.player.attachTo(videoPlayer);

        const videoAttribute = document.querySelector('#video-media video');
        videoAttribute.setAttribute('name', 'media');
        videoAttribute.classList.add('video-media');
    }

    ngAfterViewInit(): void {
        const videoPlayer = document.getElementById('video-media');
        videoPlayer.addEventListener('fullscreenchange', (event) => {
            if (document.fullscreenElement) screen.orientation.lock('landscape');
        });
    }

    setHover(data) {
        this.playlistHover = data;
        if (this.ishideadded && !data) this.showPlaylist = false;
    }

    addRecordingDetails(recordings) {
        let recordingArray = [];
        recordings.forEach((element) => {
            recordingArray.push(...element.recordingDetails);
        });
        this.recordingDetails = recordingArray;
    }

    playVideo(i) {
        this.currentRecording = i;
        this.player.load(this.videoUrls[this.currentRecording], 'video/mp4', true);
        this.showPlaylist = false;
    }

    togglePlaylist() {
        this.showPlaylist = !this.showPlaylist;
    }

    setSessionNumber(recordings) {
        this.sessionNumber = recordings.findIndex((rec) => {
            return rec.meetingId == this.sessionMeetingId;
        });
    }

    async createMediaUrlArray() {
        let getStreamurlArray = [];
        const updatedRecording = [];
        this.recordingDetails.forEach((recs) => {
            if (recs?.processed == true) {
                updatedRecording.push(recs);
            }
        });
        this.recordingDetails =
            updatedRecording?.length > 0 && this.currentUser?.webinarBetaAccess?.videoProcessingEnabled == true
                ? updatedRecording
                : this.recordingDetails;
        for (let i = 0; i < this.recordingDetails.length; i++) {
            let hash = this.utilService.getParameterByName('hash', this.recordingDetails[i].getStream);
            getStreamurlArray.push(
                this.restService.get(
                    `${this.appService.getEnvVariable('BASE_URL')}/shorturl/getstreamurl?hash=${hash}&public=${
                        this.public
                    }&source=jioevents`
                )
            );
        }
        await forkJoin(getStreamurlArray)
            .toPromise()
            .then(
                (res: any) => {
                    for (let i = 0; i < res.length; i++) {
                        if (res[i].success)
                            this.videoUrls.push(
                                res[i].uri + `&tempAuth=${this.public ? null : this.authToken}&source=jioevents`
                            );
                        else {
                            if (!this.authToken) {
                                this.toasterService.info('Please Sign in');
                                this.router.navigate(['login'], {
                                    queryParams: {
                                        returnUrl: this.router.routerState.snapshot.url
                                    }
                                });
                            } else {
                                this.toasterService.info(
                                    res.errors ==
                                        'Kindly sign in with Enterprise account to access the meeting recording'
                                        ? 'Only authorized users are allowed to access this link'
                                        : res.errors
                                );
                                this.router.navigate(['dashboard']);
                            }
                        }
                    }
                },
                (err) => {
                    this.toasterService.error(
                        err.error.errors == 'Kindly sign in with Enterprise account to access the meeting recording'
                            ? 'Only authorized users are allowed to access this link'
                            : err.error.errors
                    );
                    this.router.navigate(['dashboard']);
                }
            );
        await this.playVideoMedia(this.videoUrls);
    }
    // handleBrowserBackEvent() {
    //     history.pushState(null, document.title, location.href);
    //     window.addEventListener('popstate', (event) => {
    //         const leavePage = confirm('You want to leave this page?');
    //         if (leavePage) {
    //             location.href = this.appService.getBaseUrl() + '/dashboard/my-events';
    //         }
    //     });
    // }
}
