import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService, UserService, UtilService } from 'src/app/core';

@Component({
    selector: 'app-create-event-options',
    templateUrl: './create-event-options.component.html',
    styleUrls: ['./create-event-options.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateEventOptionsComponent implements OnInit {
    @Input() id;
    @Output() virtualEvt: EventEmitter<any> = new EventEmitter();
    @Output() preRecordEvt: EventEmitter<any> = new EventEmitter();
    @Input() btnCustomClass = '';
    isMobile;
    popupOpen: boolean = false;
    showMeetingForm: boolean = false;
    showRecordedForm: boolean = false;
    currentUser;
    preRecordedFeatureEnabled: boolean = false;
    constructor(
        public utilService: UtilService,
        private userService: UserService,
        private localstoreService: LocalStorageService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.currentUser = this.userService.getUserSync();
        this.isMobile = this.utilService.isResponsiveMobileView();
        this.preRecordedFeatureEnabled = this.utilService.enablePaymentFeature(this.currentUser?.isOTT)
            ? this.currentUser?.eventsFeaturesEnabled?.preRecordedFeatureEnabled
            : true;
    }

    createEvent($event) {
        if (this.canCreateWebinar()) {
            // this.router.navigate(['dashboard/createVirtual']);
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { create: true },
                queryParamsHandling: 'merge'
            });
        }
    }
    createPreRecordedEvent($event) {
        if (this.canCreateWebinar()) {
            //  this.router.navigate(['dashboard/createPrerecorded']);
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { prerecorded: true },
                queryParamsHandling: 'merge'
            });
        }
    }
    togglePopup(e) {
        e.preventDefault();
        e.stopPropagation();
        this.popupOpen = !this.popupOpen;
    }

    onOutSideClick(e) {
        this.popupOpen = false;
    }

    canCreateWebinar() {
        if (!this.currentUser.canCreateWebinar) {
            this.localstoreService.addItem('userFrom', 'dashboard');
            this.router.navigate(['/contactus']);
            return;
        }
        return true;
    }
}
