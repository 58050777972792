import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddParticipantsService } from 'src/app/conference/services';
import { RtcService } from 'src/app/core';

@Component({
    selector: 'app-vc-rooms-dialog',
    templateUrl: './vc-rooms-dialog.component.html',
    styleUrls: ['./vc-rooms-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VcRoomsDialogComponent implements OnInit {
    @Input() enableVCDialog: boolean = false;
    @Output() close = new EventEmitter();
    isAgoraMeeting = false;
    isJMMeeting = false;
    VCInviteForm: FormGroup;
    constructor(
        private addParticipantService: AddParticipantsService,
        private fb: FormBuilder,
        private rtcService: RtcService
    ) {}

    ngOnInit(): void {
        this.isAgoraMeeting = this.rtcService.getIsAgoraMeeting();
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        this.VCInviteForm = this.fb.group({
            VCOption: ['SIP'],
            IPAddress: ['', Validators.required]
        });
    }

    onVCAdded() {
        this.addParticipantService.addVC({
            type: this.VCInviteForm.get('VCOption').value,
            ipaddress: this.VCInviteForm.get('IPAddress').value.split(' ').join('')
        });
        this.close.emit(true);
        this.VCInviteForm.get('IPAddress').setValue('');
    }

    closeVCDialog() {
        this.close.emit(true);
    }
}
