export const IFRAME_EVENTS = {
    TOGGLE_AUDIO: 'TOGGLE_AUDIO',
    TOGGLE_VIDEO: 'TOGGLE_VIDEO',
    TOGGLE_PARTICIPANT_LIST: 'TOGGLE_PARTICIPANT_LIST',
    TOGGLE_CHAT: 'TOGGLE_CHAT',
    START_SCREEN_SHARE: 'START_SCREEN_SHARE',
    START_WHITEBOARD_SHARE: 'START_WHITEBOARD_SHARE',
    STOP_SHARING: 'STOP_SHARING',
    TOGGLE_HAND: 'TOGGLE_HAND',
    LEAVE_MEETING: 'LEAVE_MEETING',
    MAKE_AUDIENCE: 'MAKE_AUDIENCE',
    MAKE_SPEAKER: 'MAKE_SPEAKER'
};
