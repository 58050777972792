import { Injectable } from '@angular/core';
import { AppService } from './app.service';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(private restService: RestService, private appService: AppService) {}

    search(keyword) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/search/v1?keyword=${keyword}`);
    }
}
