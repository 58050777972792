import { APP_EVENTS } from 'src/app/constants';
import { VirtualBackgroundScripts } from '../helpers/virtual-background-scripts';
import { timerWorkerScript } from '../workers/virtual-background.worker';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';
import { CUSTOM_STREAM_CONSTANT } from 'src/app/constants';

export class MultiStreamsMixer {
    videos: Array<any>;
    isStopDrawingFrames: boolean;
    canvas: any;
    context: CanvasRenderingContext2D;
    disableLogs: boolean;
    frameInterval: number;
    useGainNode: boolean;
    arrayOfMediaStreams: Array<MediaStream>;
    elementClass: string;
    /********************************************/
    audioContext: any;
    audioDestination: any;
    audioSources: Array<any>;
    gainNode: GainNode;
    numberOfDraws: number;
    layoutOptions: Array<String> = ['both', 'screen', 'camera'];
    selectedLayoutIndex: any = 0;
    /**
     * Currently there are three layouts
     * Indexed 1, represents the layout with Screen Share occupying the fullcanvas, and the camera tile as pip
     * Index 2, Only Screen share
     * Index 3, Only Camera Tile
     */
    pptSlidesImageArray = [];
    selectedBgSlideIndex = 0;
    streamMixingType: 'ppt' | 'screen' = 'screen';
    worker: any;
    maxFPS: any = 60;
    bgSlideDimesions: any = {
        width: 1280,
        height: 720
    };
    /**
     * Currently setting the default canvas size to 1280 * 720
     */
    canvasWidth: number = 1280;
    canvasHeight: number = 720;

    cameraPipConfig;

    constructor(_arrayOfMediaStreams, elementClass = 'multi-streams-mixer') {
        // requires: chrome://flags/#enable-experimental-web-platform-features
        this.arrayOfMediaStreams = _arrayOfMediaStreams;
        this.cameraPipConfig = CUSTOM_STREAM_CONSTANT.cameraPipConfig;
        /**
         * Stream object will have key "sourceOfStream"
         * Takes value "screen" | "camera"
         */
        this.elementClass = elementClass;
        this.videos = new Array<any>();
        this.isStopDrawingFrames = false;
        this.canvas = document.createElement('canvas');
        // this.canvas.transferControlToOffscreen();
        this.context = this.canvas.getContext('2d');
        this.canvas.style =
            'opacity:0;position:absolute;z-index:-1;top: -100000000;left:-1000000000; margin-top:-1000000000;margin-left:-1000000000;';
        this.canvas.className = this.elementClass;
        (document.body || document.documentElement).appendChild(this.canvas);
        this.disableLogs = false;
        this.frameInterval = 42;
        this.useGainNode = true;
        this.audioContext = undefined;
    }

    private isPureAudio() {
        for (let i = 0; i < this.arrayOfMediaStreams.length; i++) {
            if (
                this.arrayOfMediaStreams[i].getTracks().filter(function (t) {
                    return t.kind === 'video';
                }).length > 0
            )
                return false;
        }
        return true;
    }

    getAudioContext(): AudioContext {
        if (typeof AudioContext !== 'undefined') {
            return new AudioContext();
        } else if (typeof (<any>window).webkitAudioContext !== 'undefined') {
            return new (<any>window).webkitAudioContext();
        } else if (typeof (<any>window).mozAudioContext !== 'undefined') {
            return new (<any>window).mozAudioContext();
        }
    }

    /**************************************************/

    private setSrcObject(stream, element) {
        var URL = window.URL || (<any>window).webkitURL;
        if ('srcObject' in element) {
            element.srcObject = stream;
        } else if ('mozSrcObject' in element) {
            element.mozSrcObject = stream;
        } else if ('createObjectURL' in URL) {
            element.src = URL.createObjectURL(stream);
        } else {
            alert('createObjectURL/srcObject both are not supported.');
        }
    }

    changeCameraPipDimensions(width, height) {
        this.cameraPipConfig.width = width;
        this.cameraPipConfig.height = height;
        this.changeLayout(this.selectedLayoutIndex);
    }

    changeCameraPipPosition(newPosition) {
        this.cameraPipConfig.top = newPosition.y;
        this.cameraPipConfig.left = newPosition.x;
        this.changeLayout(this.selectedLayoutIndex);
        return { x: newPosition.x, y: newPosition.y };
    }

    changeLayout(idx = null) {
        this.isStopDrawingFrames = true;
        this.selectedLayoutIndex = idx ?? (this.selectedLayoutIndex + 1) % 3;
        // this.selectedLayoutIndex = idx;
        if (this.streamMixingType === 'screen') {
            this.changeLayoutForScreenShareStream();
        } else if (this.streamMixingType === 'ppt') {
            this.changeLayoutForPresentationStream();
        }
        this.startDrawingFrames();
    }

    changeLayoutForPresentationStream() {
        if (this.selectedLayoutIndex === 0) {
            this.bgSlideDimesions = {
                width: this.canvasWidth,
                height: this.canvasHeight
            };
            this.videos[0].stream.width = this.canvasWidth * 0.3;
            this.videos[0].stream.height = this.canvasHeight * 0.3;
            this.videos[0].stream.top = this.canvasHeight * 0.7;
            this.videos[0].stream.left = this.canvasWidth * 0.7;
        } else if (this.selectedLayoutIndex === 1) {
            this.videos[0].stream.width = 0;
            this.videos[0].stream.height = 0;
            this.videos[0].stream.top = 0;
            this.videos[0].stream.left = 0;
            this.bgSlideDimesions = {
                width: this.canvasWidth,
                height: this.canvasHeight
            };
        } else {
            this.videos[0].stream.width = this.canvasWidth;
            this.videos[0].stream.height = this.canvasHeight;
            this.videos[0].stream.top = 0;
            this.videos[0].stream.left = 0;
            this.bgSlideDimesions = {
                width: 0,
                height: 0
            };
        }
    }

    changeLayoutForScreenShareStream() {
        if (this.selectedLayoutIndex === 0) {
            this.videos.forEach((v) => {
                if (v.stream.sourceOfStream === 'screen') {
                    v.stream.fullcanvas = true;
                    v.stream.width = this.canvasWidth;
                    v.stream.height = this.canvasHeight;
                    v.stream.top = 0;
                    v.stream.left = 0;
                } else {
                    v.stream.fullcanvas = false;
                    v.stream.width = this.cameraPipConfig.width * this.canvasWidth;
                    v.stream.height = this.cameraPipConfig.height * this.canvasHeight;
                    v.stream.top = this.cameraPipConfig.top * this.canvasHeight;
                    v.stream.left = this.cameraPipConfig.left * this.canvasWidth;
                }
            });
        } else if (this.selectedLayoutIndex === 3) {
        } else {
            this.videos.forEach((v) => {
                v.stream.fullcanvas = v.stream.sourceOfStream === this.layoutOptions[this.selectedLayoutIndex];
                if (v.stream.fullcanvas) {
                    v.stream.width = this.canvasWidth;
                    v.stream.height = this.canvasHeight;
                    v.stream.top = 0;
                    v.stream.left = 0;
                } else {
                    v.stream.width = 0;
                    v.stream.height = 0;
                    v.stream.top = this.cameraPipConfig.top;
                    v.stream.left = this.cameraPipConfig.left;
                }
            });
        }
    }

    changeLayoutBgSlide() {
        this.isStopDrawingFrames = true;
        this.selectedBgSlideIndex = (this.selectedBgSlideIndex + 1) % this.pptSlidesImageArray?.length;
        this.startDrawingFrames();
        // this.drawBackgroundToVideo();
    }

    public async startDrawingFrames() {
        this.numberOfDraws = 0;
        this.isStopDrawingFrames = false;
        // await this.registerWebWorker();
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        // await delay(1000);
        // this.postWorkerMessage({
        //     action: APP_EVENTS.VB_WORKER_STATES.START_TIMEOUT,
        //     maxFPS: this.maxFPS
        // });
        this.drawFrames();
    }

    drawFrames() {
        if (this.streamMixingType === 'screen') {
            this.drawVideosToCanvas();
        } else {
            this.drawBackgroundToVideo();
        }
    }

    private drawBackgroundToVideo() {
        if (this.isStopDrawingFrames) {
            return;
        }
        let fullcanvas = undefined;
        fullcanvas = this.videos[0];
        this.canvas.width = this.canvasWidth;
        this.canvas.height = this.canvasHeight;

        if (fullcanvas && this.pptSlidesImageArray.length) {
            this.drawImage(
                this.pptSlidesImageArray[this.selectedBgSlideIndex].changingThisBreaksApplicationSecurity,
                0,
                true
            );
            this.drawImage(this.videos[0], 1);
        } else if (!this.pptSlidesImageArray.length) {
            this.videos[0].stream.width = this.canvas.width;
            this.videos[0].stream.height = this.canvas.height;
            this.videos[0].stream.top = 0;
            this.videos[0].stream.left = 0;
            this.drawImage(this.videos[0], 0);
        }

        // remaining.forEach((video, idx) => {
        // this.drawImage(this.videos[0], 1);
        // });

        setTimeout(this.drawBackgroundToVideo.bind(this), this.frameInterval);
    }

    private drawVideosToCanvas() {
        if (this.isStopDrawingFrames) {
            return;
        }
        let videosLength = this.videos.length;
        let fullcanvas = undefined;
        let remaining = [];
        this.videos.forEach((video) => {
            if (!video.stream) {
                video.stream = {};
            }
            if (video.stream.fullcanvas) {
                fullcanvas = video;
            } else if (this.selectedLayoutIndex === 0) {
                remaining.push(video);
            }
        });

        this.canvas.width = this.canvasWidth;
        this.canvas.height = this.canvasHeight;

        if (fullcanvas && fullcanvas instanceof HTMLVideoElement) {
            this.drawImage(fullcanvas, 0);
        }

        remaining.forEach((video, idx) => {
            this.drawImage(video, idx);
        });

        setTimeout(this.drawVideosToCanvas.bind(this), this.frameInterval);
    }

    private drawImage(video, idx, isImage = false) {
        if (this.isStopDrawingFrames) {
            return;
        }
        if (isImage) {
            let img = new Image();
            img.src = video;
            this.context.drawImage(img, 0, 0, this.bgSlideDimesions.width, this.bgSlideDimesions.height);
            return;
        }
        if (video.stream.width === 0) return;

        var x = video.stream.left;
        var y = video.stream.top;
        var width = video.stream.width;
        var height = video.stream.height;

        if (!video.stream.fullcanvas) {
            this.context.save();
            this.roundedImage(this.context, x, y, width, height, 10);
            this.context.clip();
            this.mirrorPipImage(x, y, width, height);
            this.context.drawImage(video, -width / 2, -height / 2, width, height);
            this.context.restore();
        } else {
            if (video.stream.sourceOfStream === 'camera') {
                this.mirrorPipImage(x, y, width, height);
                this.context.drawImage(video, -width / 2, -height / 2, width, height);
            } else {
                this.context.drawImage(video, x, y, width, height);
            }
        }
        if (typeof video.stream.onRender === 'function') {
            video.stream.onRender(this.context, x, y, width, height, idx);
        }
    }

    mirrorPipImage(x, y, width, height) {
        // Set the origin to the center of the image
        this.context.translate(x + width / 2, y + height / 2);
        this.context.scale(-1, 1);
    }

    getMixedStream() {
        this.isStopDrawingFrames = false;
        let mixedAudioStream = this.getMixedAudioStream();
        let mixedVideoStream = this.isPureAudio() ? undefined : this.getMixedVideoStream();
        if (mixedVideoStream == undefined) {
            return mixedAudioStream;
        } else {
            if (mixedAudioStream) {
                mixedAudioStream
                    .getTracks()
                    .filter(function (t) {
                        return t.kind === 'audio';
                    })
                    .forEach((track) => {
                        mixedVideoStream.addTrack(track);
                    });
            }
            return mixedVideoStream;
        }
    }

    private getMixedVideoStream() {
        this.resetVideoStreams(this.arrayOfMediaStreams, true);
        var capturedStream = this.canvas.captureStream(60) || this.canvas.mozCaptureStream(60);
        var videoStream = new MediaStream();
        capturedStream
            .getTracks()
            .filter(function (t) {
                return t.kind === 'video';
            })
            .forEach((track) => {
                videoStream.addTrack(track);
            });
        this.canvas.stream = videoStream;
        return videoStream;
    }

    private getMixedAudioStream() {
        // via: @pehrsons
        if (this.audioContext == undefined) this.audioContext = this.getAudioContext();
        this.audioSources = new Array<any>();
        if (this.useGainNode === true) {
            this.gainNode = this.audioContext.createGain();
            this.gainNode.connect(this.audioContext.destination);
            this.gainNode.gain.value = 0; // don't hear self
        }

        let audioTracksLength = 0;
        this.arrayOfMediaStreams.forEach((stream) => {
            if (
                !stream.getTracks()?.filter(function (t) {
                    return t.kind === 'audio';
                }).length
            ) {
                return;
            }
            audioTracksLength++;
            let _audioSource = this.audioContext.createMediaStreamSource(stream);
            if (this.useGainNode === true) {
                _audioSource.connect(this.gainNode);
            }
            this.audioSources.push(_audioSource);
        });

        if (!audioTracksLength) {
            return undefined;
        }
        this.audioDestination = this.audioContext.createMediaStreamDestination();
        this.audioSources.forEach((_audioSource) => {
            _audioSource.connect(this.audioDestination);
        });
        return this.audioDestination.stream;
    }

    private getVideo(stream) {
        var video = document.createElement('video');
        this.setSrcObject(stream, video);
        video.className = this.elementClass;
        video.muted = true;
        video.volume = 0;
        video.width = stream.width || this.canvasWidth || 360;
        video.height = stream.height || this.canvasHeight || 240;
        video.play();
        return video;
    }

    appendStreams(streams) {
        if (!streams) {
            throw 'First parameter is required.';
        }

        if (!(streams instanceof Array)) {
            streams = [streams];
        }

        this.arrayOfMediaStreams.concat(streams);
        streams.forEach((stream) => {
            if (
                stream.getTracks().filter(function (t) {
                    return t.kind === 'video';
                }).length
            ) {
                var video = this.getVideo(stream);
                video['stream'] = stream;
                this.videos.push(video);
            }

            if (
                stream.getTracks().filter(function (t) {
                    return t.kind === 'audio';
                }).length &&
                this.audioContext
            ) {
                var audioSource = this.audioContext.createMediaStreamSource(stream);
                audioSource.connect(this.audioDestination);
                this.audioSources.push(audioSource);
            }
        });
    }

    stopExistingMediaStreams(exlcudeScreenShare = false) {
        this.arrayOfMediaStreams.forEach((track: any) => {
            if (exlcudeScreenShare && track.sourceOfStream === 'screen') return;
            track.getTracks().forEach((t) => t.stop());
        });
        this.isStopDrawingFrames = true;
    }

    releaseStreams() {
        this.videos = [];
        this.isStopDrawingFrames = true;

        if (this.gainNode) {
            this.gainNode.disconnect();
            this.gainNode = null;
        }

        if (this.audioSources?.length) {
            this.audioSources.forEach((source) => {
                source.disconnect();
            });
            this.audioSources = [];
        }

        if (this.audioDestination) {
            this.audioDestination.disconnect();
            this.audioDestination = null;
        }

        if (this.audioContext) {
            this.audioContext.close();
        }

        this.audioContext = null;

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        if (this.canvas?.stream && this.canvas.stream.stop) {
            this.canvas.stream.stop();
        }
        this.canvas.stream = null;
        this.terminateWorker();
    }

    resetVideoStreams(streams?: any, resetOnGetStream: boolean = false) {
        if (streams && !(streams instanceof Array)) {
            streams = [streams];
        } else if (!streams) {
            streams = this.arrayOfMediaStreams;
        }
        if (resetOnGetStream && this.videos.length) return;
        this.arrayOfMediaStreams = streams;
        this._resetVideoStreams(streams);
    }

    private _resetVideoStreams(streams) {
        this.videos = [];
        streams.forEach((stream) => {
            if (
                !stream.getTracks().filter(function (t) {
                    return t.kind === 'video';
                }).length
            ) {
                return;
            }
            let tempVideo = this.getVideo(stream);
            tempVideo['stream'] = stream;
            this.videos.push(tempVideo);
        });
    }

    registerWebWorker() {
        // worker is used to process frames when tab is in background.
        if (typeof Worker !== 'undefined' && this.worker === undefined) {
            this.worker = new Worker(timerWorkerScript);

            this.worker.onmessage = ({ data }: any) => {
                if (data.action === APP_EVENTS.VB_WORKER_STATES.TIMEOUT_TICK) {
                    this.drawFrames();
                }
            };
        } else {
            // Web workers are not supported in this environment.
            // You should add a fallback so that your program still executes correctly.
        }
    }

    postWorkerMessage(data) {
        if (this.worker !== undefined) {
            this.worker.postMessage(data);
        }
    }

    terminateWorker() {
        if (this.worker !== undefined) {
            this.worker?.terminate();
            this.worker = undefined;
        }
    }

    // original: https://stackoverflow.com/a/19593950
    roundedImage(ctx, x, y, width, height, radius) {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
    }
}
