import { Pipe, PipeTransform } from '@angular/core';

import { UtilService } from 'src/app/core';

@Pipe({
    name: 'dateLabel'
})
export class DateLabelPipe implements PipeTransform {
    constructor(private utilService: UtilService) {}

    transform(value: any, args?: any): any {
        if (!value) {
            return null;
        }
        return this.utilService.isToday(new Date(value))
            ? 'Today'
            : this.utilService.isYesterday(new Date(value))
            ? 'Yesterday'
            : value;
    }
}
