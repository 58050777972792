<div
    class="stopwatch-timer"
    *ngIf="stopwatchTimer?.active && !enablePipMode"
    [ngClass]="{
        success: stopwatchTimer?.minute > 1,
        warn: stopwatchTimer?.minute < 1 && stopwatchTimer?.second >= 10,
        danger: stopwatchTimer?.minute < 1 && stopwatchTimer?.second < 10
    }"
>
    <label
        class="d-flex justify-content-center align-items-center timer_bg"
        tabindex="0"
        [attr.aria-label]="'Timer' + formatTimer(stopwatchTimer)"
    >
        {{ formatTimer(stopwatchTimer) }}
    </label>
</div>
