import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-info-icon',
    templateUrl: './info-icon.component.html',
    styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent implements OnInit {
    @Input() tooltip;
    @Input() dropright = false;
    @Input() isFromEditEvent = false;
    @Input() isRevampIcon: boolean = false;

    isMobile = false;

    constructor(private utilService: UtilService) {
        this.isMobile = this.utilService.isMobileBrowser();
    }

    ngOnInit(): void {}
}
