<div class="recording-player" [ngClass]="{ 'mobile-device': isMobileOS, 'web-version': !isMobileOS }">
    <div class="recording-container">
        <div class="recording-info" *ngIf="recordingDetailsAvailable">
            <div class="info-container">
                <div class="meeting-details item">
                    <div class="meeting-title">
                        <span>{{ recordingDetails?.title }}</span>
                    </div>
                    <div class="meeting-time">
                        <div class="date">{{ getFormattedDate(recordingDetails?.startTime, true) }}</div>
                        <div class="time">
                            {{ recordingDetails?.startTime | date: 'shortTime' }} -
                            {{ recordingDetails?.endTime | date: 'shortTime' }}
                        </div>
                    </div>
                </div>
                <div class="host-details item">
                    <div class="title">{{ 'website_recording_page.host' | translate }}</div>
                    <div class="host-name details">
                        {{ recordingDetails?.hostInfo.name }} {{ recordingDetails?.hostInfo.lname }}
                    </div>
                </div>
                <div class="participants-details item">
                    <div class="title">{{ 'meetingcard.participants' | translate }}</div>
                    <div class="participants details">
                        <div class="participant-list">
                            <ng-container *ngFor="let participant of recordingDetails?.participants; let i = index">
                                <span>
                                    {{ participant.name }} {{ participant.lname
                                    }}{{
                                        i < recordingDetails?.participants.length - 1 &&
                                        recordingDetails?.participants[i + 1].name
                                            ? ', '
                                            : ''
                                    }}
                                </span>
                            </ng-container>
                        </div>
                        <span *ngIf="recordingDetails?.participants.length > 3">
                            +{{ recordingDetails?.participants.length - 3 }}</span
                        >
                    </div>
                </div>
                <div class="recording-duration item">
                    <div class="title">{{ 'website_recording_page.recording_duration' | translate }}</div>
                    <div class="duration details">
                        {{ recordingDetails?.recordingDetails.duration | timestampToDuration }}
                    </div>
                </div>
                <div class="file-size-details item">
                    <div class="title">{{ 'website_recording_page.file_size' | translate }}</div>
                    <div class="file-size details">
                        {{ recordingDetails?.recordingDetails.fileSize | filesizeConverter }}
                    </div>
                </div>
                <div class="recording-control item">
                    <div
                        class="download-link control-item"
                        (click)="downloadRecording(recordingDetails?.recordingDetails.url)"
                    >
                        <div class="control-continer">
                            <span class="link-title">{{
                                'website_recording_page.download_recording' | translate
                            }}</span>
                            <span class="icon">
                                <img alt="" class="img" src="assets/images/download_icn11.svg" />
                            </span>
                        </div>
                    </div>
                    <div
                        class="copy-link control-item"
                        (click)="copyRecordingLink(recordingDetails?.recordingDetails.streamingURL)"
                    >
                        <div class="control-continer">
                            <span #url_text class="link-title">{{ 'invitepopup.copy_url' | translate }}</span>
                            <span class="icon">
                                <img alt="" class="img" src="assets/images/Copy_link_new.svg" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="recording-video">
            <app-videojs-player [setupDone]="updateUrl"></app-videojs-player>
        </div>
    </div>
</div>
