import { Component, Input, OnInit, EventEmitter, Output, ViewEncapsulation, ViewChild } from '@angular/core';
import { EventEmitterService, UserService } from 'src/app/core';
import { EventUserRole } from 'src/app/constants/webinar-enum';
import { ToastrService } from 'ngx-toastr';
import { WebinarConferenceService } from 'src/app/conference/services';
import { WebinarAttendeeService } from '../../../../app/webinar-attendee/services';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-people-tab',
    templateUrl: './people-tab.component.html',
    styleUrls: ['./people-tab.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PeopleTabComponent implements OnInit {
    @ViewChild(CdkVirtualScrollViewport) public attendeesScrollViewport: CdkVirtualScrollViewport;
    eventSpeakers = [];
    eventAttendees = [];
    attendeesCount = 0;
    showSpeakers: boolean = true;
    pageNumber = 0;
    pageSize = 200;
    loading: boolean = true;
    @Input() webinarDetails;
    @Input() webinarId;
    @Input() isSpeaker;
    @Input() isAuthenticated;
    @Input() userForm;
    @Input() userHasRegistered;
    @Output() showUserFormPopUp: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private eventEmitterService: EventEmitterService,
        private webinarAttendeeService: WebinarAttendeeService,
        private toastrService: ToastrService,
        private userService: UserService,
        private webinarConferenceService: WebinarConferenceService
    ) {}

    ngOnInit(): void {
        this.getAttendeeMembers();
        this.getSpeakersDetails();
    }

    prepareSpeakersDetails() {
        this.eventSpeakers = [];
        this.webinarDetails?.speakers?.forEach((speaker) => {
            if (speaker.role && speaker.role?.indexOf(EventUserRole.SPEAKER) !== -1) {
                this.eventSpeakers.push(speaker);
            }
        });
    }

    switchFromSpeakersView() {
        this.showSpeakers = !this.showSpeakers;
        if (this.showSpeakers) {
            this.pageNumber = 0;
            this.getSpeakersDetails();
        } else {
            if (this.webinarDetails?.registrationEnabled && !this.userHasRegistered) {
                this.eventAttendees = [];
                this.toastrService.info('Please register for the event');
            } else if (
                !this.isAuthenticated &&
                !this.userForm.valid &&
                !this.webinarAttendeeService.getGuestAttendeeToken() &&
                !this.userService.getGuestUser()
            ) {
                this.eventAttendees = [];
                this.showUserFormPopUp.emit(true);
            } else {
                this.getAttendeeMembers();
            }
        }
    }
    getSpeakersDetails() {
        this.webinarAttendeeService.joinAttendeeDetails(this.webinarId).subscribe((res) => {
            if (this.eventSpeakers?.length !== res?.speakers?.length) {
                this.loading = false;
                this.webinarDetails.speakers = res?.speakers;
                this.prepareSpeakersDetails();
            }
        });
    }

    getAttendeeMembers() {
        if (!this.eventAttendees) {
            this.eventAttendees = [];
        }
        this.loading = this.eventAttendees?.length === 0 ? true : false;
        this.webinarConferenceService
            .getAllAttendeeParticipants(this.webinarId, !this.isSpeaker ? true : false, this.pageNumber, this.pageSize)
            .subscribe(
                (res: any) => {
                    this.loading = false;
                    if (this.eventAttendees?.length !== res?.attendeesList?.length) {
                        this.eventAttendees = res?.attendeesList;
                        this.attendeesCount = res?.attendeesCount;
                    }
                },
                (err) => {
                    this.loading = false;
                    console.log(err);
                }
            );
    }

    nextAttendeesList(e) {
        const end = this.attendeesScrollViewport?.getRenderedRange().end;
        const total = this.attendeesScrollViewport?.getDataLength();
        if (end === total) {
            this.pageNumber = this.pageNumber + this.pageSize;
            this.webinarConferenceService
                .getAllAttendeeParticipants(
                    this.webinarId,
                    !this.isSpeaker ? true : false,
                    this.pageNumber,
                    this.pageSize
                )
                .subscribe((res: any) => {
                    if (this.eventAttendees.every((a) => a._id !== res?.attendeesList[0]?._id)) {
                        this.loading = false;
                        this.eventAttendees = this.eventAttendees.concat(res?.attendeesList);
                        this.attendeesCount = res?.attendeesCount;
                    }
                });
        }
    }
}
