import { Pipe, PipeTransform } from '@angular/core';

import filter from 'lodash-es/filter';
import values from 'lodash-es/values';
import pick from 'lodash-es/pick';

@Pipe({
    name: 'filterContactByKey'
})
export class FilterContactByKeyPipe implements PipeTransform {
    transform(input: any[], searchQuery: string, key: string | Array<string>): any {
        const keys = Array.isArray(key) ? key : [key];
        return filter(
            input,
            (val) =>
                !!filter(values(pick(val, keys)), (str) => str.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1)
                    .length
        );
    }
}
