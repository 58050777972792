<section id="pricing" class="pricing background-container-individual-wrapper">
    <div class="aos-init aos-animate w-100" data-aos="fade-up">
        <div class="section-title" [@bottomToTopAnimations]>
            <h2 class="pricing-title">Simple Pricing for your events</h2>
            <h4 class="pricing-subtitle">Making your event planning simple and cost-effective</h4>
        </div>
        <div class="select-area">
            <div tabindex="0" aria-roledescription="Individual,Bundles" role="button" class="btn-container">
                <button
                    id="monthlyPricingBtn"
                    tabindex="0"
                    aria-roledescription="Individual"
                    class="select-btn"
                    [ngClass]="{ active: monthly }"
                    (click)="togglePlan('monthly')"
                    (keyup.enter)="togglePlan('monthly')"
                >
                    <span class="select-btn-view">Monthly</span>
                </button>
                <button
                    id="quarterlyPricingBtn"
                    tabindex="0"
                    aria-roledescription="Bundles"
                    class="select-btn"
                    [ngClass]="{ active: quarterly }"
                    (click)="togglePlan('quarterly')"
                    (keyup.enter)="togglePlan('quarterly')"
                >
                    <span class="select-btn-view">Quarterly</span>
                </button>
                <button
                    id="yearlyPricingBtn"
                    tabindex="0"
                    aria-roledescription="Bundles"
                    class="select-btn"
                    [ngClass]="{ active: yearly }"
                    (click)="togglePlan('yearly')"
                    (keyup.enter)="togglePlan('yearly')"
                >
                    <div class="select-view-alignment">
                        <span class="select-btn-view">Yearly</span>
                        <button
                            id="discountPricingBtn"
                            class="discoutn-button discount-view"
                            style="white-space: nowrap"
                        >
                            {{ 'upto 10% off' }}
                        </button>
                    </div>
                </button>
            </div>
        </div>

        <ng-template
            #individualtemplate
            let-plantype="plan_type"
            let-planprice="plan_price"
            let-planPricePeriod="plan_price_period"
            let-plancount="plan_count"
            let-planpoints="plan_points"
            let-planevent="plan_event"
            let-planplus="plan_plus"
            let-planfeature="plan_feature"
        >
            <div class="mt-lg-0 aos-init aos-animate mobile-wrapper price-type-container" data-aos="fade-up">
                <div
                    class="box"
                    [ngClass]="{ featured: count === plancount }"
                    [class.add-on-plus]="plantype == 'Plus'"
                    [class.add-on-height]="plantype == 'Premium'"
                    [class.add-on-plus]="plantype == 'Plus'"
                    [class.premium-padding]="
                        (isShowActivePlan && plantype == currentUser.eventsPlanName) ||
                        (plantype == 'Plus' && !currentUser.eventsProStatus) ||
                        (plantype == 'Pro' && !currentUser.eventsProStatus)
                    "
                    [class.plan-padding]="
                        (plantype != currentUser.eventsPlanName && plantype != 'Plus' && plantype != 'Pro') ||
                        (!isShowActivePlan && plantype == currentUser.eventsPlanName)
                    "
                    [class.plus-padding]="
                        (plantype == 'Plus' && currentUser.eventsProStatus && currentUser.eventsPlanName != 'Plus') ||
                        (plantype == 'Pro' && currentUser.eventsProStatus && currentUser.eventsPlanName != 'Pro')
                    "
                >
                    <div class="plan-area">
                        <button
                            id="bestValuePricingBtn"
                            *ngIf="plantype == 'Plus' && !currentUser.eventsProStatus"
                            class="best-value best-value-view"
                        >
                            Best Value
                        </button>
                        <button
                            id="recommendedPricingBtn"
                            *ngIf="plantype == 'Pro' && !currentUser.eventsProStatus"
                            class="best-value"
                        >
                            Recommended
                        </button>
                        <button
                            *ngIf="
                                currentUser.eventsProStatus &&
                                plantype == currentUser.eventsPlanName &&
                                isShowActivePlan
                            "
                            class="best-value"
                        >
                            Active plan
                        </button>

                        <h3 class="plan-type">
                            {{ plantype }}
                        </h3>
                        <div>
                            <ng-container *ngIf="plantype !== 'Trial'">
                                <h3 *ngIf="monthly" class="plan-price lite-price">&#8377; {{ planprice.monthly }}</h3>
                                <h3 *ngIf="monthly" class="plan-Duration lite-price">/Monthly</h3>
                                <h3 *ngIf="quarterly" class="plan-price lite-price">
                                    &#8377; {{ planprice.quarterly }}
                                </h3>
                                <h3 *ngIf="quarterly" class="plan-Duration lite-price">/Quarterly</h3>
                                <h3 *ngIf="yearly" class="plan-price lite-price">&#8377; {{ planprice.yearly }}</h3>
                                <h3 *ngIf="yearly" class="plan-Duration lite-price">/Yearly</h3>
                            </ng-container>
                            <ng-container *ngIf="plantype === 'Trial'">
                                <h3 class="plan-price lite-price">&#8377; {{ planprice.daily }}</h3>
                                <h3 class="plan-Duration lite-price">/Daily</h3>
                            </ng-container>
                            <h3 class="plan-gst">+GST*</h3>
                        </div>
                        <p class="plan-number">Upto {{ plancount }} attendees</p>
                    </div>
                    <button
                        id="contactSalesPricingBtn"
                        class="custom-button-revamp btn-primary buy-now-btn-control"
                        (click)="showPopupDetails(plantype)"
                    >
                        {{
                            currentUser.eventsProStatus == true ||
                            this.currentUser.eventsFeaturesEnabled ||
                            !enablePaymentFeature
                                ? 'Contact Sales'
                                : 'Buy Now'
                        }}
                    </button>
                    <div class="event-feature">
                        <div class="plan-event">
                            <span>{{ planevent }}</span>
                        </div>
                        <div *ngIf="planplus" class="plan-feature">
                            <span>{{ planfeature }}</span>
                        </div>
                    </div>
                    <div class="points-area">
                        <ul>
                            <ng-container *ngFor="let item of planpoints; let i = index">
                                <li><img class="list-wrapper" alt="" />{{ item }}</li>
                                <div
                                    [class.custom-class]="i === planpoints.length - 1"
                                    class="custom-class-wrapper"
                                ></div>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-container>
            <div class="row background-containers">
                <ng-container *ngFor="let plan of pricingPlans.individualPlans">
                    <ng-container *ngTemplateOutlet="individualtemplate; context: plan"> </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <div class="gst-notice">*18% GST applicable on all plans</div>
        <div class="customTextarea">
            <span class="customText">Looking for custom plan?</span
            ><button
                id="contactPricingBtn"
                (click)="navigateToContactUs('custom_plan')"
                class="custom-button-revamp btn-primary contact-us"
            >
                Contact us
            </button>
        </div>
        <div [ngClass]="'overlay_1'"></div>
    </div>
</section>

<section class="section-wrapper">
    <div class="loader-overlay" *ngIf="isLoading">
        <div class="loader">
            <img alt="" src="assets/img/Payment_Logo.svg" />
            <div class="spinner-div">
                <div class="loading-spinner"></div>
            </div>
            <div class="loader-footer">
                <div class="loader-footer-text-top">Processing your payment</div>
                <div class="loader-footer-text-bottom">Please do not press the back button or close this web page</div>
            </div>
        </div>
    </div>
    <div class="loader-overlay" *ngIf="isSuccess">
        <div class="loader-success">
            <img alt="" src="assets/img/Payment_Logo.svg" />
            <div class="section-1">
                <div class="left-section" *ngIf="isSuccess">
                    <div class="left-section-1">{{ currentUser?.eventsPlanName }}</div>
                    <div>
                        <div class="left-section-2">
                            &#8377;{{ currentUser.eventsSubscriptionInfo?.totalAmount }} for 1
                            {{ currentUser.eventsSubscriptionInfo?.billingFrequency.replace('ly', '') }}
                        </div>
                        <div class="left-section-3">
                            Valid till {{ currentUser.eventsSubscriptionInfo?.expiresOn | date: 'd MMMM y' }}
                        </div>
                    </div>
                </div>
                <div class="circle-Success" *ngIf="isSuccess">
                    <img
                        src="{{ isSuccess ? 'assets/img/Payment_Success.svg' : 'assets/img/Payment_Failure.svg' }}"
                        class="jio-text-img"
                    />
                </div>
            </div>
            <div class="section-2" *ngIf="isSuccess">{{ isSuccess ? 'Payment Successful' : 'Payment Failed' }}</div>
            <div class="section-3">
                {{
                    isSuccess
                        ? 'Your transaction has been successfully processed and plan benefits will reflect in your account
                shortly'
                        : 'Your transaction has been failed because of [technical issue from UPI or relevant message from
                bank/UPI]'
                }}
            </div>
            <div class="section-4">{{ this.currentUser.eventsSubscriptionInfo?.cOn | date: 'd MMM, y h:mm a' }}</div>
            <div class="section-3" *ngIf="isSuccess">Details</div>
            <hr class="Details" *ngIf="isSuccess" />
            <div class="section-9" *ngIf="isSuccess">
                <div class="details-left-section">
                    <div class="details-left-content">Account</div>
                </div>
                <div class="details-right-section">
                    <div class="details-right-content">{{ accountName }}</div>
                </div>
            </div>
            <div class="section-9" *ngIf="isSuccess">
                <div class="details-left-section">
                    <div class="details-left-content">Reference number</div>
                </div>
                <div class="details-right-section">
                    <div class="details-right-content">{{ currentUser.eventsSubscriptionInfo?.referenceId }}</div>
                </div>
            </div>
            <div class="section-9" *ngIf="isSuccess">
                <div class="details-left-section">
                    <div class="details-left-content">Transaction ID</div>
                </div>
                <div class="details-right-section">
                    <div class="details-right-content">{{ currentUser.eventsSubscriptionInfo?.transactionId }}</div>
                </div>
            </div>
            <div class="section-9" *ngIf="isSuccess">
                <div class="details-left-section">
                    <div class="details-left-content">Payment mode</div>
                </div>
                <div class="details-right-section">
                    <div class="details-right-content">{{ currentUser.eventsSubscriptionInfo?.paymentMode }}</div>
                </div>
            </div>
            <div class="section-5" *ngIf="isSuccess">
                Note: You will receive a detailed invoice of this transaction in the inbox of your email address as well
                as in your mobile number, if exists
            </div>
            <div class="section-6" *ngIf="isSuccess">You will redirected to My Accounts in {{ display }}s</div>
            <div class="section-7" *ngIf="isSuccess">
                <div class="btn-area" (click)="redirectDashboardPage()">
                    <button
                        id="backToAccountPricingBtn"
                        class="custom-button-revamp btn-primary contact-us"
                        [class.standard-price]="plantype == 'Standard'"
                        [class.premium-price]="plantype == 'Premium'"
                    >
                        Go to Dashboard
                        <i class="fa fa-1px fa-chevron-right my-account-icon"></i>
                    </button>
                </div>
            </div>
            <div class="section-8" *ngIf="isFailure">
                <button id="retryPricingBtn" class="failure-button" (click)="navigateToContactUs(plantype)">
                    Retry
                </button>
                <button id="closePricingBtn" class="failure-button" (click)="closeFailure()">Close</button>
            </div>
        </div>
    </div>
</section>

<section class="section-wrapper">
    <div class="loader-overlay" *ngIf="isFailure">
        <div class="loader-failure">
            <img alt="" src="assets/img/Payment_Logo.svg" />
            <div class="section-1">
                <div class="left-section">
                    <div class="payment-Failed">
                        <div>
                            <div class="section-2">Payment Failed</div>
                            <div class="section-34">
                                Hey there, something went wrong and your payment couldn't be processed. If money is
                                deducted from your account, it'll be credited back.
                            </div>
                        </div>
                        <div class="circle-Failed">
                            <img src="assets/img/Payment_Failure.svg" class="failure-img" />
                        </div>
                    </div>
                    <div class="section-35">
                        <span class="heading">Please try again or </span>
                        <span class="viewEdit pointer" (click)="navigateToContactUs('custom_plan')">Contact Us </span>
                        <span class="heading">if you're facing an issue.</span>
                    </div>
                </div>
            </div>
            <div class="section-8">
                <button id="tryBackPricingBtn" class="failure-button" (click)="navigateToContactUs(plantype)">
                    Retry
                </button>
                <button id="closeAllPricingBtn" class="failure-button" (click)="closeFailure()">Close</button>
            </div>
        </div>
    </div>
</section>

<div class="loader-overlays" *ngIf="showPopup">
    <div class="address-loader">
        <div class="payment-form-header d-flex justify-content-between">
            <img alt="" src="assets/img/website/website_logo_header.svg" />
            <img class="pointer" alt="" src="assets/img/website/cross_button.svg" (click)="closePopup()" />
        </div>
        <div class="payment-form-body" id="paymentFormBody">
            <div class="userDetails">
                <div class="autoFillNameDiv">
                    <div class="form-group firstname">
                        <label class="label label-required">Name</label>
                        <input
                            type="text"
                            class="text custom-border"
                            placeholder="Enter Your Name"
                            [(ngModel)]="userName"
                            disabled
                        />
                    </div>
                    <!-- <div class="form-group lastname">
                    <label class="label">Last Name</label>
                    <input type="text" class="text custom-border" [(ngModel)]="currentUser.lname" />
                </div> -->
                </div>
                <div class="autoFillDiv">
                    <div class="form-group email">
                        <label class="label label-required">Email ID/Mobile No.</label>
                        <input
                            type="text"
                            class="emailtext custom-border"
                            id="secondaryUserIdentity"
                            placeholder="Enter Email ID/Mobile No."
                            [(ngModel)]="userPrimaryIdentity"
                            disabled
                        />
                    </div>
                </div>
                <div class="fillDiv address-wrapper">
                    <div class="form-group pincode">
                        <label class="label label-required">Pincode</label>
                        <input
                            type="text"
                            class="text custom-border"
                            maxlength="6"
                            autofocus
                            placeholder="Pincode"
                            [(ngModel)]="pincode"
                            (blur)="onInputPincode($event.target.value)"
                            (keyup)="onInputPincode($event.target.value, $event)"
                            [disabled]="isGstinValid && !!pincode"
                        />
                    </div>
                    <div class="form-group city">
                        <label class="label label-required">City</label>
                        <input
                            type="text"
                            class="text custom-border"
                            placeholder="City"
                            [(ngModel)]="city"
                            [disabled]="pinCodeFetched"
                        />
                    </div>
                    <div class="form-group state">
                        <label class="label label-required">State</label>
                        <input
                            type="text"
                            class="text custom-border"
                            placeholder="State"
                            [(ngModel)]="state"
                            [disabled]="pinCodeFetched"
                        />
                    </div>
                </div>
                <div class="autoFillDiv">
                    <div class="form-group email">
                        <label class="label">GST Number (Applicable for business users only)</label>
                        <input
                            type="text"
                            class="emailtext custom-border"
                            placeholder="Enter GST No."
                            [(ngModel)]="gstin"
                            maxlength="15"
                            (keyup)="onGstinInput($event.target.value, $event)"
                        />
                        <div class="address" *ngIf="isGstinValid">
                            Address: <span>{{ gstAddress }}</span>
                        </div>
                        <div class="d-flex sez" *ngIf="isSezAccount">
                            <img alt="" src="assets/img/sez_warning.svg" />
                            <div class="disclaimer">
                                Tax benefits for businesses under SEZ are not included, your account will considered as
                                a B2C account.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="autoFillDiv" *ngIf="isGstinValid && !isSezAccount">
                    <div class="form-group email">
                        <label class="label label-required">Organisation</label>
                        <input
                            type="text"
                            class="emailtext custom-border"
                            placeholder="Enter Organisation Name"
                            [(ngModel)]="userOrg"
                            (change)="onOrgChange($event.target.value)"
                            [disabled]="!!userOrg"
                        />
                    </div>
                </div>
                <div class="autoFillDiv" *ngIf="isGstinValid && !isSezAccount">
                    <div class="form-group email">
                        <label class="label label-required">
                            {{
                                isPrimaryEmail
                                    ? 'To avail GST benefits, please provide your Mobile No.'
                                    : 'To avail GST benefits, please provide your Email ID'
                            }}</label
                        >
                        <input
                            type="text"
                            class="emailtext custom-border"
                            placeholder="{{ isPrimaryEmail ? 'Enter your Mobile No.' : 'Enter your Email ID' }}"
                            [(ngModel)]="userSecondaryIdentity"
                            maxlength="{{ isPrimaryEmail ? 10 : 50 }}"
                            (keyup)="onSecondaryIdentityChange($event.target.value)"
                        />
                        <div
                            class="verifyButton d-flex"
                            *ngIf="otpRequired && !otpSent"
                            [class.disable-verify]="!userSecondaryIdentity"
                            (click)="sendOtp()"
                        >
                            <img src="assets/img/verifyIcon.svg" />
                            <div class="verifyText">Verify {{ isPrimaryEmail ? 'Mobile Number' : 'Email' }}</div>
                        </div>
                        <div class="otpSend" *ngIf="otpRequired && otpSent" (click)="sendOtp()">
                            To verify Mobile No, enter OTP sent to above Mobile No. Didn’t receive OTP?
                            <span>Resend</span>
                        </div>
                        <div *ngIf="otpRequired && otpSent" class="form-group email">
                            <label class="label label-required">OTP</label>
                            <input
                                type="text"
                                maxlength="6"
                                class="emailtext custom-border"
                                placeholder="Enter OTP"
                                [(ngModel)]="otp"
                            />
                        </div>
                    </div>
                </div>
                <div *ngIf="invalidPincode" class="label error-pincode">
                    {{ 'payment-page.invalid_pincode' | translate }}
                </div>
            </div>
        </div>
        <div class="payment-form-footer">
            <button class="cancel-button" (click)="closePopup()">Cancel</button>
            <button
                [ngClass]="
                    paymentButtonDisabled ||
                    !currentUser.name ||
                    !userPrimaryIdentity ||
                    (!userOrg && isGstinValid && !isSezAccount) ||
                    !pincode ||
                    !city ||
                    !state ||
                    (isGstinValid &&
                        !isSezAccount &&
                        (!userSecondaryIdentity ||
                            userSecondaryIdentity?.length == 0 ||
                            (otpRequired && (!otp || otp?.length < 6))))
                        ? 'payment-button-disabled'
                        : 'payment-button-enabled'
                "
                (click)="navigateToContactUs(selectedPlanType)"
                [disabled]="
                    !currentUser.name ||
                    !userPrimaryIdentity ||
                    !pincode ||
                    !city ||
                    !state ||
                    (!userOrg && isGstinValid && !isSezAccount) ||
                    (isGstinValid &&
                        !isSezAccount &&
                        (userSecondaryIdentity?.length == 0 || (otpRequired && otp?.length < 0)))
                "
            >
                {{ buttonText }}
            </button>
        </div>
    </div>
</div>
