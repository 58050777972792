import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService, AuthService } from 'src/app/core';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    isAuthenticated: boolean = false;
    constructor(private authService: AuthService, private router: Router, private appService: AppService) {}

    ngOnInit(): void {
        if (this.appService.getConfigVariable('USE_OLD_HEADER_VERSION')) {
            this.router.navigate(['/home3']);
            return;
        }
        this.isAuthenticated = this.authService.getIsAuthenticated();
    }
    navigateToSignIn() {
        this.router.navigate(['/login']);
    }
}
