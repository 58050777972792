/**
 * The following code is needed as string to create a URL from a Blob.
 * The URL is then passed to a WebWorker. Reason for this is to enable
 * use of setInterval that is not throttled when tab is inactive.
 *
 * https://github.com/jitsi/jitsi-meet/blob/09441c2632b8ff92819d2fbd40d3dc449679d83c/react/features/stream-effects/virtual-background/TimerWorker.js
 */
const code = `
      let maxFPS;
      let lastTimestamp = 0;
      let timestep = 0; // ms for each frame
      let interval;
      addEventListener('message', ({ data }) => {
        switch (data.action) {
          case 'start':
            maxFPS = data.maxFPS;
            timestep = 1000 / maxFPS
            if(interval){
              clearInterval(interval);
            }
            interval = setInterval(() => {
              postMessage({
                action: 'tick'
              });
            }, timestep);
            break;
          case 'stop':
            if (interval) {
              clearInterval(interval);
            }
            break;
        }
      });
      `;

export const timerWorkerScript = URL.createObjectURL(new Blob([code], { type: 'application/javascript' }));
