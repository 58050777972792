<div
    class="scroll explore-events-not-dashboard"
    [class.isMobileView]="utilService.isMobileView"
    [class.dashboard]="dashboard"
    *ngIf="currentRoute !== '/dashboard'"
>
    <section
        class="events"
        [ngClass]="{ 'events-header': headerRoute == '/explore-events' }"
        [class.isMobile]="utilService.isMobileView"
        [class.events-view]="utilService?.showRibbon"
        [class.noResult]="
            trendingEventsLength == 0 &&
            recommendedEventsLength == 0 &&
            ongoingEventsLength == 0 &&
            upcomingEventsLength == 0 &&
            replayEventsLength == 0 &&
            (search != '' || eventType != 'all' || publishType != 'all' || eventDay != 'all')
        "
        [class.isOverFlowAuto]="!isSafariBrowser"
    >
        <div class="container">
            <div class="menubar justify-content-between" *ngIf="!utilService.isMobileView">
                <div *ngIf="dashboard" class="appliedfiltersarea desktop-l-view">
                    <div *ngIf="publishType != 'all'" class="appliedfilters filter_button">
                        {{ publishType == 'Limited' ? 'Organization' : publishType }}
                        <img
                            (click)="removeAppliedFilter('publishType')"
                            src="assets/img/website/cross_icon_dark.svg"
                            alt=""
                        />
                    </div>
                    <div *ngIf="eventDay != 'all'" class="appliedfilters filter_button">
                        {{ eventDay }}
                        <img (click)="removeAppliedFilter('eventDay')" src="assets/img/website/cross_icon_dark.svg" />
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="searchBarMenu"></ng-container>
                <div (outside)="clickOutside('filter')" class="filterarea desktop-view">
                    <div
                        class="filter filter_button"
                        (click)="toggleFilter($event)"
                        id="toggleFilterButton"
                        [class.filter-active]="filters"
                        tabindex="0"
                        aria-label="Filter"
                    >
                        <span *ngIf="!dashboard">Filters</span>
                        <span *ngIf="dashboard">Apply Filters</span>
                        <svg
                            *ngIf="!dashboard"
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14 6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H14C14.2652 8 14.5196 7.89464 14.7071 7.70711C14.8946 7.51957 15 7.26522 15 7C15 6.73478 14.8946 6.48043 14.7071 6.29289C14.5196 6.10536 14.2652 6 14 6ZM11 12H7C6.73478 12 6.48043 12.1054 6.29289 12.2929C6.10536 12.4804 6 12.7348 6 13C6 13.2652 6.10536 13.5196 6.29289 13.7071C6.48043 13.8946 6.73478 14 7 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13C12 12.7348 11.8946 12.4804 11.7071 12.2929C11.5196 12.1054 11.2652 12 11 12ZM17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H17C17.2652 2 17.5196 1.89464 17.7071 1.70711C17.8946 1.51957 18 1.26522 18 1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0Z"
                                fill="currentColor"
                            />
                        </svg>
                        <img *ngIf="dashboard" src="assets/img/filter_icon_dashboard.svg" />
                    </div>
                    <div *ngIf="filters" class="hidden-area">
                        <ng-container [ngTemplateOutlet]="filterForm"></ng-container>
                    </div>
                </div>
                <!-- #mySelect (keydown.enter)="openSelect()" -->
                <div (outside)="clickOutside('allEvents')" class="allEventsarea desktop-view">
                    <ng-container [ngTemplateOutlet]="eventTypeFilter"></ng-container>
                    <ng-container [ngTemplateOutlet]="eventTypeList"></ng-container>
                </div>

                <ng-container
                    *ngIf="headerRoute !== '/explore-events'"
                    [ngTemplateOutlet]="createEventMenu"
                ></ng-container>
            </div>
            <div class="menubar flex-column align-items-sm-stretch" *ngIf="utilService.isMobileView">
                <div class="d-flex justify-content-between" style="column-gap: 10px">
                    <ng-container [ngTemplateOutlet]="searchBarMenu"></ng-container>
                    <div (outside)="clickOutside('filter')" *ngIf="utilService.isMobileView">
                        <div class="alleventsmobile mobile-view btn_width_48_mob" (click)="toggleFilter($event)">
                            <svg
                                *ngIf="!dashboard"
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14 6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H14C14.2652 8 14.5196 7.89464 14.7071 7.70711C14.8946 7.51957 15 7.26522 15 7C15 6.73478 14.8946 6.48043 14.7071 6.29289C14.5196 6.10536 14.2652 6 14 6ZM11 12H7C6.73478 12 6.48043 12.1054 6.29289 12.2929C6.10536 12.4804 6 12.7348 6 13C6 13.2652 6.10536 13.5196 6.29289 13.7071C6.48043 13.8946 6.73478 14 7 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13C12 12.7348 11.8946 12.4804 11.7071 12.2929C11.5196 12.1054 11.2652 12 11 12ZM17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H17C17.2652 2 17.5196 1.89464 17.7071 1.70711C17.8946 1.51957 18 1.26522 18 1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <img *ngIf="dashboard" src="assets/img/filter_icon_dashboard.svg" width="25px" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between" style="gap: 12px; width: 100%">
                    <div
                        class="filter-mob-wrapper topic-overflow"
                        aria-label="All_events"
                        (outside)="clickOutside('allEvents')"
                        *ngIf="utilService.isMobileView"
                    >
                        <ng-container [ngTemplateOutlet]="eventTypeFilter"></ng-container>
                        <!-- <ng-container [ngTemplateOutlet]="eventTypeList"></ng-container> -->
                    </div>
                    <ng-container
                        *ngIf="headerRoute !== '/explore-events'"
                        [ngTemplateOutlet]="createEventMenu"
                    ></ng-container>
                </div>
            </div>
            <div *ngIf="!dashboard && (publishType != 'all' || eventDay != 'all')" class="appliedfiltersarea">
                <ng-container *ngFor="let filterValue of filterOptions">
                    <div *ngIf="publishType != 'all' || eventDay != 'all'" class="appliedfilters filter_button">
                        {{ filterValue }}
                        <img (click)="removeAppliedFilter(filterValue)" src="assets/img/website/cross_icon_dark.svg" />
                    </div>
                </ng-container>
            </div>
            <div *ngIf="dashboard" class="appliedfiltersarea desktop-s-view">
                <ng-container *ngFor="let filterValue of filterOptions">
                    <div *ngIf="publishType != 'all' || eventDay != 'all'" class="appliedfilters filter_button">
                        {{ filterValue }}
                        <img
                            (click)="removeAppliedFilter('filterValue')"
                            src="assets/img/website/cross_icon_dark.svg"
                        />
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="!favourites">
                <ng-container *ngIf="!isLoading">
                    <ng-container
                        [ngTemplateOutlet]="eventSliderView"
                        [ngTemplateOutletContext]="{ data: { count: trendingEventsLength, eventsType: 'trending' } }"
                    ></ng-container>
                    <!-- <app-event-slider
                        *ngIf="
                            !(
                                trendingEventsLength == 0 &&
                                (eventType != 'all' || publishType != 'all' || eventDay != 'all' || search != '')
                            )
                        "
                        [dashboard]="dashboard"
                        [sliderName]="'trending'"
                        (viewall)="viewAllEvents($event)"
                        (favouriteEvent)="markFavourite($event)"
                        (getEvents)="getEvents($event)"
                        (eventClick)="eventClicked($event, i)"
                    ></app-event-slider> -->

                    <ng-container
                        [ngTemplateOutlet]="eventSliderView"
                        [ngTemplateOutletContext]="{
                            data: { count: recommendedEventsLength, eventsType: 'recommended' }
                        }"
                    ></ng-container>
                    <!-- <app-event-slider
                        *ngIf="
                            !(
                                recommendedEventsLength == 0 &&
                                (eventType != 'all' || publishType != 'all' || eventDay != 'all' || search != '')
                            )
                        "
                        [dashboard]="dashboard"
                        [sliderName]="'recommended'"
                        (viewall)="viewAllEvents($event)"
                        (getEvents)="getEvents($event)"
                        (favouriteEvent)="markFavourite($event)"
                    ></app-event-slider> -->
                    <ng-container
                        [ngTemplateOutlet]="eventSliderView"
                        [ngTemplateOutletContext]="{ data: { count: ongoingEventsLength, eventsType: 'ongoing' } }"
                    ></ng-container>
                    <!-- <app-event-slider
                        *ngIf="!(ongoingEventsLength == 0)"
                        [dashboard]="dashboard"
                        [sliderName]="'ongoing'"
                        (viewall)="viewAllEvents($event)"
                        (getEvents)="getEvents($event)"
                        (favouriteEvent)="markFavourite($event)"
                    ></app-event-slider> -->
                    <ng-container
                        [ngTemplateOutlet]="eventSliderView"
                        [ngTemplateOutletContext]="{ data: { count: upcomingEventsLength, eventsType: 'upcoming' } }"
                    ></ng-container>

                    <ng-container
                        [ngTemplateOutlet]="eventSliderView"
                        [ngTemplateOutletContext]="{ data: { count: replayEventsLength, eventsType: 'replay' } }"
                    ></ng-container>

                    <!-- <app-event-slider
                        *ngIf="
                            !(
                                upcomingEventsLength == 0 &&
                                (eventType != 'all' || publishType != 'all' || eventDay != 'all' || search != '')
                            )
                        "
                        [dashboard]="dashboard"
                        [sliderName]="'upcoming'"
                        (viewall)="viewAllEvents($event)"
                        (getEvents)="getEvents($event)"
                        (favouriteEvent)="markFavourite($event)"
                    ></app-event-slider>
                    <app-event-slider
                        *ngIf="
                            !(
                                replayEventsLength == 0 &&
                                (eventType != 'all' || publishType != 'all' || eventDay != 'all' || search != '')
                            )
                        "
                        [dashboard]="dashboard"
                        [sliderName]="'replay'"
                        (viewall)="viewAllEvents($event)"
                        (getEvents)="getEvents($event)"
                        (favouriteEvent)="markFavourite($event)"
                    ></app-event-slider> -->
                    <div
                        class="message"
                        *ngIf="
                            trendingEventsLength == 0 &&
                            recommendedEventsLength == 0 &&
                            ongoingEventsLength == 0 &&
                            upcomingEventsLength == 0 &&
                            replayEventsLength == 0 &&
                            (search != '' || eventType != 'all' || publishType != 'all' || eventDay != 'all')
                        "
                        [ngClass]="{ 'search-data-container': search === '' }"
                    >
                        <ng-container [ngTemplateOutlet]="noSearchData" *ngIf="search != ''"></ng-container>
                        <ng-container [ngTemplateOutlet]="noFilterData" *ngIf="search === ''"></ng-container>
                    </div>
                </ng-container>
                <div class="loader" *ngIf="isLoading">
                    <img src="assets/img/jioevents-loader.gif" width="100px" height="100px" alt="" />
                </div>
            </ng-container>
            <div *ngIf="favourites" class="sliderarea">
                <ng-container *ngIf="!isFavouritesLoading && !isLoading">
                    <div
                        *ngIf="
                            favouriteEventsList.length ||
                            (favouriteEventsList.length == 0 &&
                                publishType == 'all' &&
                                eventDay == 'all' &&
                                search == '')
                        "
                    >
                        <div class="slider-header">
                            <h4>My Favourites</h4>
                        </div>
                        <div class="slider-container">
                            <div *ngIf="favouriteEventsList.length">
                                <div class="view_all">
                                    <ng-container *ngFor="let cardnumber of favouriteEventsList">
                                        <app-event-card
                                            [eventinfo]="cardnumber"
                                            [dashboard]="dashboard"
                                            (favouriteEvent)="markFavourite($event, i)"
                                            class="ecard"
                                            (eventClick)="eventClicked($event, i)"
                                            [eventViewSection]="EventViewSection.DASHBOARD"
                                            [hasCalIcon]="true"
                                            [hasFavIcon]="true"
                                        ></app-event-card>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="message empty-state-for-favourite" *ngIf="!favouriteEventsList.length">
                                <ng-container [ngTemplateOutlet]="noFavouriteData"></ng-container>
                            </div>
                        </div>
                        <ng-container
                            [ngTemplateOutlet]="addToCal"
                            *ngIf="showCalendar && utilService.isMobileView"
                        ></ng-container>
                    </div>
                    <div
                        class="message"
                        *ngIf="
                            favouriteEventsList.length == 0 &&
                            (publishType != 'all' || eventDay != 'all' || search != '')
                        "
                    >
                        <ng-container [ngTemplateOutlet]="noSearchData" *ngIf="search != ''"></ng-container>
                        <ng-container [ngTemplateOutlet]="noFilterData" *ngIf="search === ''"></ng-container>
                    </div>
                </ng-container>
                <div class="loader-wrapper" *ngIf="isLoading || isFavouritesLoading">
                    <app-loader
                        [position]="'relative'"
                        class="h-100 d-flex justify-content-center align-items-center"
                    ></app-loader>
                </div>
            </div>
        </div>
    </section>
    <div class="background-overlay" *ngIf="(filters || allEvents) && utilService.isMobileView"></div>
    <ng-container [ngTemplateOutlet]="eventTypeList" *ngIf="utilService.isMobileView"></ng-container>
    <div *ngIf="filters && utilService.isMobileView" class="mobile-bottom-dropdown">
        <ng-container [ngTemplateOutlet]="filterForm"></ng-container>
    </div>
</div>

<div class="scroll" [class.dashboard]="dashboard" *ngIf="currentRoute === '/dashboard'">
    <section class="" [class.isMobile]="utilService.isMobileView">
        <ng-container
            [ngTemplateOutlet]="eventSliderView"
            [ngTemplateOutletContext]="{ data: { count: dashboardData.length, eventsType: 'all' } }"
        ></ng-container>
    </section>
</div>

<ng-template #eventSliderView let-data="data">
    <app-event-slider
        *ngIf="
            !(
                data.count == 0 &&
                (eventType != 'all' ||
                    publishType != 'all' ||
                    eventDay != 'all' ||
                    search != '' ||
                    data.eventsType === 'ongoing')
            )
        "
        [dashboard]="dashboard"
        [sliderName]="data.eventsType"
        (viewall)="viewAllEvents($event)"
        (getEvents)="getEvents($event)"
        (favouriteEvent)="markFavourite($event)"
        (eventClick)="eventClicked($event, i, data.eventsType)"
        [eventsList]="dashboardData"
        [id]="data.eventType"
        [isLoading]="data.eventsType === 'all' ? isLoading : false"
        [hasCalIcon]="true"
        [hasFavIcon]="true"
    ></app-event-slider>
</ng-template>

<ng-template #noSearchData>
    <app-no-dashboard-data
        imgPath="{{ 'assets/images/explore_no_search_result_mob.svg' }}"
        msg="No search results found"
        class="no-data-wrapper d-flex justify-content-center align-items-center"
    ></app-no-dashboard-data>
</ng-template>

<ng-template #noFilterData>
    <app-no-dashboard-data
        imgPath="assets/img/no_events_icon.svg"
        msg=""
        class="no-data-wrapper d-flex align-items-center no-filter-data"
    >
        <div class="no-data-text d-flex flex-column">
            <div
                class="no_events_text_1 d-flex flex-column"
                [class.align-items-center]="utilService.isMobileView"
                [class.align-items-start]="!utilService.isMobileView"
            >
                <span>No events available for the</span> <span>selected filter</span>
            </div>
            <div class="no_events_text_2 text-center">Please visit here after a while</div>
        </div>
    </app-no-dashboard-data>
</ng-template>

<ng-template #noFavouriteData>
    <app-no-dashboard-data
        imgPath="assets/img/no_events_icon.svg"
        msg=""
        class="no-data-wrapper d-flex align-items-center no-favourite-data"
    >
        <div class="no-data-text d-flex flex-column">
            <div class="no_events_text_1 d-flex flex-column">
                <span>No events have been marked as favourite </span> <span>by you yet.</span>
            </div>
            <div class="no_events_text_2">Events marked as favourites will be shown here.</div>
        </div>
    </app-no-dashboard-data>
</ng-template>

<ng-template #filterForm>
    <div class="filter-select" [ngClass]="{ 'filter-css': !isMobile || !utilService.isMobileView }">
        <ng-container *ngIf="utilService.isMobileView">
            <div class="text-end">
                <img src="assets/img/cross_white_bg.svg" alt="" id="close_filter" (click)="toggleFilter($event)" />
            </div>
        </ng-container>
        <div class="filter-list">
            <div>
                <div class="Event_type_filter_title">Event Type</div>
                <div class="Event_type_filter filter-item-wrapper">
                    <ng-container *ngFor="let item of publishTypeFilters.slice().reverse()">
                        <div
                            class="filter_button filters"
                            [id]="'filter_button_' + item.name"
                            (click)="filterItems(item.name, 'publishType', item.gtmValue)"
                            [class.filter_items-active]="publishTypeState == item.name"
                        >
                            {{ item.name }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="Event_day_filter">
                <div class="Event_type_filter_title">Event Day</div>
                <div class="day_filters filter-item-wrapper">
                    <ng-container *ngFor="let item of eventDayFilters">
                        <div
                            class="filter_button filters"
                            [id]="'filter_button_' + item.name"
                            (click)="filterItems(item.name, 'eventDay', item.gtmValue)"
                            [class.filter_items-active]="eventDayState == item.name"
                        >
                            {{ item.name }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="action-wrapper d-flex justify-content-between" style="column-gap: 16px">
                <div
                    class="filter_button cancel_btn flex-grow-1"
                    id="applyFilterCancelButton"
                    [class.apply_btn-active]="eventDayState != 'all' || publishTypeState != 'all'"
                    (click)="resetFilters()"
                    *ngIf="utilService.isMobileView"
                >
                    Cancel
                </div>
                <div
                    class="apply_btn filter_button flex-grow-1"
                    id="applyFilterButton"
                    [class.apply_btn-active]="eventDayState != 'all' || publishTypeState != 'all'"
                    (click)="applyFilters()"
                >
                    Apply
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #eventTypeList>
    <div
        *ngIf="allEvents"
        class="events-category-list mobile-bottom-dropdown"
        [class.desktop-view]="!utilService.isMobileView"
        [ngClass]="{
            'mobile-bottom-dropdown': utilService.isMobileView,
            'events_dropdown desktop-view': !utilService.isMobileView
        }"
    >
        <ng-container *ngIf="utilService.isMobileView">
            <div class="text-end">
                <img
                    src="assets/img/cross_white_bg.svg"
                    alt=""
                    id="close_events_dropdown"
                    (click)="toggleAllEvents()"
                />
            </div>
            <div class="label">Events</div>
        </ng-container>
        <div class="filter-options">
            <div class="events_favourites">
                <div
                    class="event_items"
                    id="event_items_allEvents"
                    (click)="allEventsItems('all', 'filter_all_events')"
                    [class.event_items-active]="eventType == 'all' && favourites == false"
                >
                    All Events
                </div>
                <div
                    class="event_items"
                    id="event_items_myFavourites"
                    (click)="favouritesFilter()"
                    [class.event_items-active]="favourites"
                >
                    My Favourites
                </div>
            </div>
            <div class="divider" *ngIf="!isMobile"></div>
            <div class="event_categories">
                <ng-container *ngFor="let item of eventTypeFilters; let i = index">
                    <div
                        (click)="allEventsItems(item.name, item.gtmValue)"
                        [class.event_items-active]="eventType == item.name"
                        class="event_items"
                        [id]="'event_categories_' + i"
                    >
                        {{ item.name }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #eventTypeFilter>
    <div
        [ngClass]="{
            'alleventsmobile mobile-view': utilService.isMobileView,
            allevents_btn: !utilService.isMobileView
        }"
        class="filter_button"
        id="toggleAllEventsMobile"
        (click)="toggleAllEvents()"
        [class.events-active]="allEvents && !utilService.isMobileView"
        tabindex="0"
        aria-label="All_events"
    >
        <span *ngIf="!favourites" class="text-truncate">{{ eventType == 'all' ? 'All Events' : eventType }}</span>
        <span *ngIf="favourites" class="text-truncate">My Favourites</span>
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.50019 7.00019C5.36858 7.00095 5.23812 6.97572 5.11628 6.92596C4.99444 6.87619 4.88363 6.80287 4.79019 6.71019L0.790185 2.71019C0.601882 2.52188 0.496094 2.26649 0.496094 2.00019C0.496094 1.73388 0.601882 1.47849 0.790185 1.29018C0.978489 1.10188 1.23388 0.996094 1.50019 0.996094C1.76649 0.996094 2.02188 1.10188 2.21019 1.29018L5.50019 4.59019L8.79019 1.29018C8.97849 1.10188 9.23388 0.996094 9.50019 0.996094C9.76649 0.996094 10.0219 1.10188 10.2102 1.29018C10.3985 1.47849 10.5043 1.73388 10.5043 2.00019C10.5043 2.26649 10.3985 2.52188 10.2102 2.71019L6.21019 6.71019C6.11675 6.80287 6.00593 6.87619 5.88409 6.92596C5.76225 6.97572 5.63179 7.00095 5.50019 7.00019Z"
                fill="CurrentColor"
            />
        </svg>
    </div>
</ng-template>

<ng-template #searchBarMenu>
    <div class="searcharea" *ngIf="!dashboard" aria-roledescription="Search Event" role="button">
        <app-search-filter [id]="'search_explore_events'" (onSearch)="handleSearchAction($event)"></app-search-filter>
    </div>
</ng-template>
<ng-template #createEventMenu>
    <div class="create_event">
        <app-create-event-options
            [id]="'createEvent'"
            (virtualEvt)="createEvent($event)"
            (preRecordEvt)="createPreRecord($event)"
            btnCustomClass="bg-primary dark-theme"
        ></app-create-event-options>
    </div>
</ng-template>
<ng-template #eventListMenu></ng-template>

<div
    class="add-to-cal-wrapper mobile-bottom-dropdown"
    *ngIf="showCalendar && utilService.isMobileView"
    (outside)="showCalendar = false"
>
    <app-add-to-calendar
        class=""
        [joinDetail]="currentMeeting"
        [showAddToCalBtn]="false"
        (click)="showCalendar = false; $event.stopPropagation()"
    ></app-add-to-calendar>
</div>
