import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-toggle-radio-btn',
    templateUrl: './toggle-radio-btn.component.html',
    styleUrls: ['./toggle-radio-btn.component.scss']
})
export class ToggleRadioBtnComponent implements OnInit {
    @Input()
    checked = false;

    @Output() onToggle = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}

    handleToggle(e) {
        this.onToggle.emit(e);
    }
}
