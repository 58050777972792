import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService, AuthService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {
    constructor(private userService: UserService, private authService: AuthService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
        if (this.authService.getIsAuthenticated()) {
            return new Observable<boolean>((observer) => {
                this.userService.getUser().subscribe((profile) => {
                    if (!profile.jioConf?.purpose?.length) {
                        this.router.navigateByUrl('/onboarding');
                        observer.next(false);
                        observer.complete();
                    } else {
                        observer.next(true);
                        observer.complete();
                    }
                });
            });
        }
        return of(false);
    }
}
