export const MAIL_TEMPLATES = {
    INVITATION: ({
        userName,
        topic,
        startTime,
        duration,
        meetingURL,
        meetingId,
        password,
        addToJmtUrl,
        legacyConnect
    }) => {
        let invite = `
${userName} has invited you to the below meeting.

Topic : ${topic}`;
        if (startTime) {
            invite += `
Start Time : ${startTime}`;
        }

        if (duration) {
            invite += `
${duration ? 'Duration : ' + duration + ' mins' : ''}
    `;
        }

        if (!startTime && !duration) {
            invite += `
  `;
        }

        invite += `
Join the JioMeet meeting
${meetingURL}

MeetingID : ${meetingId}
Password : ${password}`;

        if (addToJmtUrl) {
            invite += `

Use the link to add to JioMeet Calendar
${addToJmtUrl}`;
        }

        invite += `

How to join the meeting - 
Option 1:    Download JioMeet Mobile or Desktop App and use above Meeting ID and Password to join the meeting
Option 2:    Click here to join meeting using Chrome browser
    `;

        if (legacyConnect && legacyConnect.H323) {
            invite += `
Option 3:    To join through H.323 device –
                          Using intranet - Dial ${legacyConnect.H323.private} or
                          Using internet - Dial ${legacyConnect.H323.public} 
                  Enter the extension ${legacyConnect.H323.extension} followed by # key.
                  Enter the Room PIN: ${legacyConnect.H323.pin} to join the conference.
      `;
        }

        if (legacyConnect && legacyConnect.SIP) {
            invite += `
Option 4:    To join through SIP enter the URL ${legacyConnect.SIP.url} and then enter the Room PIN: ${legacyConnect.SIP.pin} to join the conference.
      `;
        }

        invite += `

Regards,
Team JioMeet`;

        return invite;
    }
};
