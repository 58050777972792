<div class="incoming-call-popup">
    <!-- <div id="incoming-call-popup-bg" class="bg"></div> -->
    <div #incomingCallPopup class="popup p-3" appDraggable>
        <div class="popup-header">
            <div class="logo">
                <img draggable="false" src="assets/img/jioevents/logos/logo_black.svg" />
            </div>
        </div>
        <div class="popup-body">
            <div class="avatar">
                <div class="circle" appAvatarWithInitials [name]="meeting?.owner" [style]="'circle'"></div>
            </div>
            <div class="name text-truncate">{{ meeting?.owner }}</div>
            <div class="text">is calling ...</div>
        </div>
        <div class="popup-footer text-center">
            <button type="button" class="custom-button bg-primary mr-3 decline" (click)="declineCall()">
                <span class="mr-2">
                    <img draggable="false" src="assets/img/call_decline_icn.svg" />
                </span>
                <span>Decline</span>
            </button>
            <button type="button" class="custom-button bg-primary accept" (click)="acceptCall()">
                <span class="mr-2">
                    <img draggable="false" src="assets/img/call_accept_icn.svg" />
                </span>
                <span>Accept</span>
            </button>
        </div>
    </div>
</div>
