<div [class.txt-mobile-view]="eventView == 'homeView'" #elementToObserve>
    <div
        [class.in-view]="isElementInView"
        class="text-decorator data-scroll-header"
        [ngClass]="eventView == 'homeView' ? 'host-home-text-view' : 'host-event-text-view'"
    >
        <span class="boujee-text audience-wrapper text-container-view"
            >{{ 'HOME_PAGE_EVENT_REVAMP.events_header' | translate }}
        </span>
    </div>
    <div class="text-wrapper text-dec data-scroll-sub-header" [class.in-view]="isElementInView">
        {{ 'HOME_PAGE_EVENT_REVAMP.events_sub_header' | translate }}
    </div>
</div>
<div class="event-button-control d-flex">
    <button
        id="eventCategories"
        *ngFor="let button of listButtons"
        class="custom-button-revamp sm w-auto button-indicator btn-in-mobile-view"
        [ngClass]="{ 'btn-primary': listType === button.type, 'btn-secondary': listType !== button.type }"
        (click)="displayEvent(button.type)"
    >
        {{ button.label }}
    </button>
</div>

<div *ngIf="eventsLoaded" [class.event-cards-wrapper]="!isMobile">
    <div
        class="cards-section d-flex justify-content-center"
        [@cardsAnim]="eventCount"
        *ngIf="showOngoingEvents; else noOngoingEvent"
    >
        <app-my-card
            *ngFor="let cardlist of eventList"
            [dashboard]="false"
            [previousWebinar]="false"
            [eventinfo]="cardlist"
            [sliderName]="listType"
            class="card-grid d-flex justify-content-center"
        >
        </app-my-card>
    </div>
    <ng-template #noOngoingEvent>
        <div class="no-events-view-wrapper d-flex justify-content-center" *ngIf="!showOngoingEvents">
            <img class="no-events-image-wrapper" src="assets/img/website/oneventscard.svg" alt="" />
            <div class="txt-view-container">
                <span class="custom-text-wrapper"> No events available at the moment </span>
                <span class="custom-sub-text"> Please visit here after a while </span>
            </div>
        </div>
    </ng-template>
</div>

<div
    class="d-flex justify-content-center view-btn-toggle"
    *ngIf="showOngoingEvents && moreEventsCard && showViewMoreButton"
>
    <button
        id="viewMoreToggle"
        [class.event-more-view-position]="eventView != 'homeView'"
        class="custom-button-revamp btn-primary w-auto btn-decorator-wrapper"
        (click)="toggleView()"
    >
        View More
    </button>
</div>
