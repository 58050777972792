import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AttendeeRcsService {
    roomInfo: any;

    constructor() {}

    setRoomInfo(info) {
        this.roomInfo = info;
    }

    getParticipantName(participantId) {
        return this.roomInfo?.participants?.find((participant) => participant.participantId === participantId)
            ?.participantName;
    }
}
