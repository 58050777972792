import { Component, Input, OnInit } from '@angular/core';
import { ExternalInterfaceService } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';
import { WebinarService } from 'src/app/dashboard-webinars/services';

@Component({
    selector: 'app-sponsors',
    templateUrl: './sponsors.component.html',
    styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {
    @Input() brandDetails: any;
    @Input() category: any;
    @Input() shouldBeInGrid: any;
    @Input() isMobileOS = false;
    brandDetailsSponsors: any = [];
    sponsorsToRender: any = [];
    categoryName = '';
    overallSponsorsLength = 0;
    isMyJio;

    constructor(
        private externalInterfaceService: ExternalInterfaceService,
        private activatedRoute: ActivatedRoute,
        private webinarService: WebinarService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.isMyJio = params?.isMyJio ? true : false;
        });
    }

    ngOnInit(): void {
        this.setSponsors();
    }

    ngOnChanges(changes) {
        if (changes?.brandDetails) {
            this.setSponsors();
        }
    }

    setSponsors() {
        this.brandDetailsSponsors = this.brandDetails?.sponsors || [];
        //filter if any empty lists are there in sponsors internal categories
        this.brandDetailsSponsors = this.brandDetailsSponsors.filter((sp) => sp.sponsors.length > 0);
        var sponsors = this.webinarService.getSponsorsData(this.brandDetailsSponsors);
        this.overallSponsorsLength = this.brandDetailsSponsors.length;
        if (this.overallSponsorsLength <= 0) return;
        switch (this.overallSponsorsLength) {
            case 1:
                let singleSponsor = this.brandDetailsSponsors[0];
                this.sponsorsToRender = singleSponsor.sponsors;
                this.categoryName = singleSponsor.title.length > 0 ? singleSponsor.title : singleSponsor.category;
                break;
            case 2:
            case 3:
                let sponsorDetails = this.brandDetailsSponsors.find((sp) => sp.category === this.category) || [];
                this.sponsorsToRender = sponsorDetails.sponsors;
                this.categoryName = sponsorDetails.title.length > 0 ? sponsorDetails.title : sponsorDetails.category;
                break;
            default:
                break;
        }
        this.sponsorsToRender.forEach((sponsor) => {
            sponsor.redirectUrl = this.webinarService.buildRedirectUrlWithRtmParameters(sponsor);
        });
    }

    sponsorRedirection(url) {
        if (this.isMyJio) {
            this.externalInterfaceService.launchBrowser(url);
        } else {
            window.open(url, '_blank');
        }
    }
}
