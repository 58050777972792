<div class="background-container-individual-wrapper">
    <div class="gradient"></div>
    <div>
        <div class="section-title">
            <h2 class="faq-title">Frequently Asked Questions</h2>
        </div>
        <ng-container *ngIf="isMobile && !isLandscape; else desktopView">
            <div class="container">
                <div class="faq-links" *ngIf="selectedLink === ''">
                    <app-faqs-links [faqs]="faqTitles" (selectedFaqlink)="selectedFaq($event)"></app-faqs-links>
                </div>
                <div class="faq-accordion" *ngIf="selectedLink && faqs">
                    <img src="assets/img/back_arrow.png" class="arrow-style" (click)="backarrow()" />
                    <app-faq-questions [faqs]="faqs" [selectedLink]="selectedLink"></app-faq-questions>
                </div>
            </div>
        </ng-container>
        <ng-template #desktopView>
            <div class="containers">
                <div class="faq-links">
                    <app-faqs-links [faqs]="faqTitles" (selectedFaqlink)="selectedFaq($event)"></app-faqs-links>
                </div>
                <div class="faq-accordion">
                    <app-faq-questions [faqs]="faqs" [selectedLink]="selectedLink"></app-faq-questions>
                </div>
            </div>
        </ng-template>
    </div>
</div>
