<div class="participants-input" outside (outside)="toggleMenu(false)">
    <div class="input-wrap" [class.select-menu]="openSelectMenu">
        <span class="participants">
            <ng-container *ngFor="let item of items | keyvalue: originalOrder">
                <span class="participant" [ngStyle]="{ background: item?.value?.bgColor?.rgba }">
                    <span class="text-lowercase"> {{ item?.value?.name }}</span>
                    <span
                        class="close"
                        (click)="removeItem($event, item?.value)"
                        *ngIf="item?.value?.id !== isAdHocUser?._id"
                    >
                        <img src="assets/img/close_icn.svg" alt="" [ngStyle]="{ width: '10px' }" />
                    </span>
                </span>
            </ng-container>
            <input
                type="text"
                class="input"
                [placeholder]="placeHolder"
                [(ngModel)]="searchTerm"
                #input
                (blur)="onBlur()"
                (focus)="onFocus()"
                [hidden]="disabled"
            />
        </span>
    </div>
    <div class="users bordered p-4" *ngIf="loading">
        <app-loader></app-loader>
    </div>
    <div class="suggestion-container" *ngIf="!loading && openSelectMenu">
        <div class="users bordered pointer">
            <ng-container *ngIf="itemsSuggestion?.length > 0">
                <div
                    class="user"
                    *ngFor="let item of itemsSuggestion; trackBy: trackByFunction"
                    (click)="selectItem(item)"
                >
                    <div class="info">
                        <div class="name text-lowercase">
                            <span class="checkbox-wrapper"
                                ><input
                                    type="checkbox"
                                    class="checkbox custom-control-input"
                                    [checked]="items.has(item.id)"
                                    (change)="handleCheckBoxToggle($event, item)"
                                    [disabled]="(!items.has(item.id) && disabled) || item.id === isAdHocUser?._id"
                            /></span>

                            <span class="profile-pic" *ngIf="item.profileUrl">
                                <img [src]="item.profileUrl" alt="" />
                            </span>
                            <span
                                class="profile-pic avatar"
                                appAvatarWithInitials
                                name="{{ item.name }} "
                                [userId]="item.id"
                                *ngIf="!item.profileUrl"
                            >
                            </span>
                            <span>{{ item.name }}</span
                            ><span class="identity">{{ item.identity }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="no-results" *ngIf="itemsSuggestion?.length === 0 && !isAdHocUser">
                <span class="heading">No team members found</span>
                <span class="subheading d-flex flex-column"
                    ><span>{{ this.searchTerm }} did not match any team member,</span>
                    <span>Would you like to add new {{ role | lowercase }}?</span></span
                >
                <span class="add-member" (click)="addNewMember()">+ Add {{ role }}</span>
            </div>
        </div>
        <div class="user add-more-member pointer" *ngIf="itemsSuggestion?.length > 0 && !isAdHocUser">
            <span (click)="addNewMember()">+ Add Members</span>
        </div>
    </div>
</div>
