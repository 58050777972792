import { HostListener, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as Bowser from 'bowser';

import { ClipboardService } from 'ngx-clipboard';

import { sha256 } from 'js-sha256';

import {
    isToday,
    isYesterday,
    isTomorrow,
    addMinutes,
    isBefore,
    isAfter,
    addDays,
    subDays,
    differenceInSeconds
} from 'date-fns';

import { AppService } from './app.service';

import { environment } from 'src/environments/environment';
import { LocalStorageService, RestService } from 'src/app/core';

import sanitizeHtml from 'sanitize-html';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService } from './event-emitter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStatus } from 'src/app/constants/webinar-enum';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';

declare const window: any;
declare const document: any;

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;
    pageResizeTimeoutRef;
    isMobileView = false;
    autoJoinInfo$;
    oneGBSizeInMB: any = 1024;
    divider: any = 100;
    hideHeaderView = false;
    isLandScapeMode = false;
    showRibbon = true;
    mobileBrowser: boolean = false;
    isMainStageAttendee;
    recorderToken;
    isLiveStreaming;
    isJioPc: boolean = false;
    private loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();
    constructor(
        private translateService: TranslateService,
        private appService: AppService,
        private clipboardService: ClipboardService,
        private sanitizer: DomSanitizer,
        private eventEmitterService: EventEmitterService,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private restService: RestService
    ) {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe((e) => {
            // trigger resize event
            if (!this.pageResizeTimeoutRef) this.emitPageResize();
            this.onResize(null);
        });
        this.emitPageResize();
        this.onResize(null);
        this.listenOrientationChange();
    }

    private specialCharacters = /([`~!@#$%^&*()_+=\[\]{}\\|'";:\/?.>,<])/g;
    private timer;
    public isOldVersion = true;
    getVersionStatus(): boolean {
        return this.isOldVersion;
    }

    setLoadingForLandingPage(loading: boolean) {
        this.loadingSubject.next(loading);
    }

    toggleVersion(): void {
        this.isOldVersion = !this.isOldVersion;
    }

    isDashboard(url): boolean {
        return url.includes('/dashboard');
    }

    emitPageResize() {
        this.pageResizeTimeoutRef = setTimeout(() => {
            this.eventEmitterService.emit({
                type: APP_EVENTS.PAGE_RESIZED,
                data: { innerWidth: window.innerWidth }
            });
            this.pageResizeTimeoutRef = 0;
        }, 100);
    }

    getParameterByName(name, url?) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    sanitize(input: string) {
        return input.replace(this.specialCharacters, '');
    }

    clone(val) {
        if (!val) return val;
        return JSON.parse(JSON.stringify(val));
    }

    parseJWT(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    isChrome() {
        // This method returns true if the running browser is Google Chrome version 42+
        // if (navigator.userAgent.toLowerCase().indexOf('chrome') !== -1 &&
        // 	navigator.userAgent.substr(navigator.userAgent.lastIndexOf('Chrome/') + 7, 10).split('.')[0] >= 42) {
        if (
            (navigator.appVersion.indexOf('Win') !== -1 &&
                navigator.userAgent.toLowerCase().indexOf('chrome') !== -1) ||
            (navigator.appVersion.indexOf('Mac') !== -1 &&
                navigator.userAgent.toLowerCase().indexOf('chrome') !== -1 &&
                +navigator.userAgent.substr(navigator.userAgent.lastIndexOf('Chrome/') + 7, 10).split('.')[0] >= 42)
        ) {
            return true;
        } else {
            return false;
        }
    }

    isToday(date: Date) {
        return isToday(date);
    }

    isYesterday(date: Date) {
        return isYesterday(date);
    }

    isEarlierThanYesterday(date: Date) {
        const yesterday = subDays(new Date(), 1);
        return isBefore(date, yesterday);
    }

    isTomorrow(date) {
        return isTomorrow(date);
    }

    isLaterThanTomorrow(date: Date) {
        const tomorrow = addDays(new Date(), 1);
        return isAfter(date, tomorrow);
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    dataURItoFile(dataURI): File {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const b: any = new Blob([ia], { type: mimeString });
        b.lastModifiedDate = new Date();
        b.name = 'profile.png';

        return b as File;
    }

    getOS() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    detectBrowser() {
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/ipad/i) !== null) {
            return 'ipad';
        } else if (ua.match(/iphone/i) !== null) {
            return 'iphone';
        } else if (ua.match(/android/i) !== null) {
            return 'android';
        } else if (ua.match(/mac/gi) !== null) {
            return 'mac';
        } else {
            return null;
        }
    }

    isMobileBrowser(): boolean {
        return ['iphone', 'android'].indexOf(this.detectBrowser()) !== -1;
    }

    isResponsiveMobileView(breakPoint = 820): boolean {
        // first check if browser is in mobile mode in web
        if (window.innerWidth <= breakPoint) {
            return true;
        }
        return false;
    }
    redirectToWhatsNew() {
        const whatsNewUrl = this.appService.getConfigVariable('WHATS_NEW_URL');
        if (whatsNewUrl) {
            window.open(whatsNewUrl, '_blank');
        } else {
            this.router.navigateByUrl('/comingsoon');
        }
    }

    listenOrientationChange() {
        let portrait = window.matchMedia('(orientation: portrait)');
        portrait.addEventListener('change', (e) => {
            if (e.matches) {
                // Portrait mode
                this.setScreenOrientation(true);
                // this.isMobileView = true;
            } else {
                // Landscape
                this.setScreenOrientation(false);
                // this.isMobileView = true;
            }
        });

        this.setScreenOrientation(portrait.matches);
    }

    setScreenOrientation(isPortrait = false) {
        this.isLandScapeMode = !isPortrait;
    }

    isMobileIOSBrowser(): boolean {
        return ['iphone'].indexOf(this.detectBrowser()) !== -1;
    }

    copy(text: string) {
        this.clipboardService.copyFromContent(text);
    }

    copyToClipboardFromHTMLElement(containerid) {
        console.log('element info, ', document.getElementById(containerid));
        if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(document.getElementById(containerid));
            range.select().createTextRange();
            document.execCommand('copy');
        } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            window.getSelection().removeAllRanges(range);
            window.getSelection().addRange(range);
            document.execCommand('copy');
            window.getSelection().removeRange(range);
        }
    }

    getTextFromHTMLElement(containerid) {
        if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(document.getElementById(containerid));
            range.select().createTextRange();
            document.execCommand('copy');
        } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            window.getSelection().removeAllRanges(range);
            window.getSelection().addRange(range);
            const string = window.getSelection().toString();
            return string;
        }
    }

    isIpad() {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('iPad') > -1) {
            return true;
        }
        if (ua.indexOf('Macintosh') > -1) {
            try {
                document.createEvent('TouchEvent');
                return true;
            } catch (e) {}
        }
        return false;
    }

    generateRandomAlphanumericString(length) {
        const charset = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    generatePassword(length) {
        // one small, one capital, one numeric
        const result =
            this.getRandomAlpha(1) +
            this.getRandomAlpha(1).toUpperCase() +
            (Math.floor(Math.random() * 9) + 1) +
            this.generateRandomAlphanumericString(length - 3);
        return this.shuffleString(result);
    }

    getWindowsClient() {
        if (navigator.userAgent.indexOf('WOW64') !== -1 || navigator.userAgent.indexOf('Win64') !== -1) {
            return this.appService.getConfigVariable('WINDOWS_INSTALLER_URL_64');
        } else {
            return this.appService.getConfigVariable('WINDOWS_INSTALLER_URL_32');
        }
    }

    private getRandomAlpha(length) {
        const charset = 'abcdefghjkmnpqrstuvwxyz';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    private shuffleString(str) {
        const a = str.split('');
        const n = a.length;

        for (let i = n - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join('');
    }

    isRTCSupportedBrowser() {
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        // const isFirefox = typeof window.InstallTrigger !== 'undefined';
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        return isChrome || isSafari;
    }

    isSafariBrowser() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    throttle(func, delay) {
        if (this.timer) {
            return;
        }
        this.timer = setTimeout(() => {
            func();
            this.timer = undefined;
        }, delay);
    }

    getSHA256(msg, key) {
        return sha256.hmac(key, msg).toString();
    }

    async createHmac(key, message) {
        const encoder = new TextEncoder();

        // Encode the key and message as Uint8Array
        const keyData = encoder.encode(key);
        const messageData = encoder.encode(message);

        // Import the key for HMAC
        const cryptoKey = await crypto.subtle.importKey('raw', keyData, { name: 'HMAC', hash: 'SHA-256' }, false, [
            'sign'
        ]);

        // Sign the message with the key
        const signature = await crypto.subtle.sign('HMAC', cryptoKey, messageData);

        // Convert ArrayBuffer to Hex string
        const hashArray = Array.from(new Uint8Array(signature));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

        return hashHex;
    }

    bypassSecurityTrustResourceUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getDomainFromEmail = (email) => {
        let emailDomain = null;
        const pos = email.search('@');
        if (pos > 0) {
            emailDomain = email.slice(pos + 1);
        }
        return emailDomain;
    };

    downloadJioMeet(redirect = false) {
        const os = this.getOS();
        const browser = this.detectBrowser();
        let supportedPlatform = false;
        switch (os) {
            case 'Windows':
                {
                    supportedPlatform = true;
                    const url = this.getWindowsClient();
                    if (redirect) {
                        location.href = url;
                        setTimeout(() => (location.href = environment.HOST_URL), 1000);
                    } else {
                        window.open(url, '_blank');
                    }
                    // window.open(this.getWindowsClient());
                }
                break;
            case 'Mac OS':
                {
                    supportedPlatform = true;
                    const url = this.appService.getConfigVariable('MAC_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('MAC_INSTALLER_URL'), '_blank');
                }
                break;
            case 'iOS':
                {
                    supportedPlatform = true;
                    const url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('IOS_INSTALLER_URL'), '_blank');
                }
                break;
            case 'Android':
                {
                    supportedPlatform = true;
                    const url = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('ANDROID_INSTALLER_URL'), '_blank');
                }
                break;
            default:
                let url;
                if (browser === 'android') {
                    supportedPlatform = true;
                    url = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('ANDROID_INSTALLER_URL'), '_blank');
                } else if (['ipad', 'iphone'].includes(browser)) {
                    supportedPlatform = true;
                    url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('IOS_INSTALLER_URL'), '_blank');
                }
                break;
        }
        return supportedPlatform;
    }

    scrollToAnchor(location: string, wait: number): void {
        const element = document.querySelector('#' + location);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth'
                    // block: 'start',
                    // inline: 'nearest',
                });
            }, wait);
        }
    }
    isEdgeBrowser() {
        const isEdge = /Edge/.test(navigator.userAgent) || window.navigator.userAgent.indexOf('EdgiOS') > -1;
        return isEdge;
    }

    isOlderSafari() {
        return Bowser.getParser(window.navigator.userAgent).satisfies({
            macos: {
                safari: '<=12'
            }
        });
    }

    diff(obj1, obj2) {
        const result = {};
        if (Object.is(obj1, obj2)) {
            return undefined;
        }
        if (!obj2 || typeof obj2 !== 'object') {
            return obj2;
        }
        Object.keys(obj1 || {})
            .concat(Object.keys(obj2 || {}))
            .forEach((key) => {
                if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                    result[key] = obj2[key];
                }
                if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                    const value = this.diff(obj1[key], obj2[key]);
                    if (value !== undefined) {
                        result[key] = value;
                    }
                }
            });
        return result;
    }

    encodeHtmlEntities(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }

    htmlDecode(input) {
        const e = document.createElement('textarea');
        e.innerHTML = input;
        // handle case of empty input
        return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
    }

    htmlDecodeTicker(input) {
        const e = document.createElement('textarea');
        e.innerHTML = String(input).replace(/p>/g, 'l> ');
        // handle case of empty input
        return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
    }

    getReadableFileSizeString = (fileSizeInBytes: any) => {
        let i = -1;
        const byteUnits = [' kbps', ' Mbps', ' Gbps', ' Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    sanitizeAndConvertEncodedHTML(text = '') {
        let convertedText = decodeURIComponent(
            new DOMParser().parseFromString(text, 'text/html').documentElement.textContent
        );
        let convertedAndSanitizedText = sanitizeHtml(convertedText, {
            allowedAttributes: {
                '*': ['href', 'align', 'alt', 'center', 'bgcolor', 'style', 'class'],
                a: ['href', 'name', 'target', 'style', 'rel']
            }
        });
        return convertedAndSanitizedText;
    }
    sanitizeHTML(message) {
        const AllowedTags = ['b', 'i', 'em', 'strong', 'a', 's', 'ul', 'ol', 'li'];
        let sanitizedMessage = sanitizeHtml(message, {
            allowedTags: AllowedTags
        });
        return sanitizedMessage;
    }

    ngOnDestroy() {
        this.resizeSubscription$.unsubscribe();
    }

    convertIntegerToShortFormat(num) {
        num = num.toString().replace(/[^0-9.]/g, '');
        if (num < 1000) {
            return num;
        }
        let si = [
            { v: 1e3, s: 'K' },
            { v: 1e6, s: 'M' },
            { v: 1e9, s: 'B' },
            { v: 1e12, s: 'T' },
            { v: 1e15, s: 'P' },
            { v: 1e18, s: 'E' }
        ];
        let index;
        for (index = si.length - 1; index > 0; index--) {
            if (num >= si[index].v) {
                break;
            }
        }
        return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
    }

    trackBy(item, index) {
        return index;
    }

    validatePassword(password) {
        password = (password || '').trim();
        let passwordErrors = '';
        const lengthRegex = new RegExp('(?=.{8,})');
        passwordErrors += lengthRegex.exec(password) ? '' : 'lengthError';

        const numberRegex = new RegExp('(?=.*?[0-9])');

        passwordErrors += numberRegex.exec(password) ? '' : 'numberError';

        const alphabetRegex = new RegExp('(?=.*[a-zA-Z])');
        passwordErrors += alphabetRegex.exec(password) ? '' : 'alphabetError';

        const dualRegex = new RegExp('(?=.*?[A-Z])(?=.*?[a-z])');
        passwordErrors += dualRegex.exec(password) ? '' : 'dualError';

        const specialCharsRegex = new RegExp('[!@#$%^&*]');
        passwordErrors += specialCharsRegex.exec(password) ? '' : 'specialCharsError';

        // for non repeating case
        const unique = new Set(password.split(''));
        passwordErrors += unique.size === 1 ? 'repeatingError' : '';

        // for consecutive case
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        const number = '0123456789';
        passwordErrors += alphabet.includes(password) || number.includes(password) ? 'consecutiveError' : '';
        return passwordErrors;
    }
    containsUrl(inputText) {
        const urlPatternInBlockOfText = /\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[A-Z0-9+&@#/%=~_|]/i;
        const regex = new RegExp(urlPatternInBlockOfText);
        return regex.test(inputText);
    }

    handleCharLimit(event, maxLength, type) {
        let limit = maxLength;
        //hack for mobile browsers max length
        if (window.innerWidth > 768) {
            return false;
        }
        if (event.target.value.length >= limit) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return true;
        }

        return false;
    }

    nearestQuarter() {
        const currentDate = new Date();
        let hours = currentDate.getHours();
        let minutes = currentDate.getMinutes();
        let today = currentDate.getDate();
        let minsFactor = Math.floor(minutes / 15);

        if (hours === 23 && minsFactor === 3) {
            today = today + 1;
            hours = 0;
            minutes = 0;
        } else if (minsFactor === 3) {
            hours = hours + 1;
            minutes = 0;
        } else {
            minutes = (minsFactor + 1) * 15;
        }

        return new Date(currentDate.getFullYear(), currentDate.getMonth(), today, hours, minutes, 0);
    }
    generateAttendeeJoinLink(webinarId: string, hash: string) {
        return `${this.appService.getBaseUrl()}attendee/${webinarId}/join/${hash}`;
    }
    convertMinToHourMin(min) {
        let hours: string | number = Math.floor(min / 60);
        let minutes: string | number = min % 60;
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        return { hours, minutes };
    }

    convertSecToHourMinSec(totalSeconds) {
        const totalMinutes = Math.floor(totalSeconds / 60);

        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
            seconds < 10 ? '0' + seconds : seconds
        }`;
    }

    getFullName(user = null) {
        return `${user?.name} ${user?.lname}`;
    }

    deviceType() {
        const ua = window.navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 'Tablet';
        } else if (
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return 'Mobile';
        }
        return 'Desktop';
    }

    toUpperCaseFirstChar(str = '') {
        return str.substr(0, 1).toUpperCase() + str.substr(1);
    }

    getParticipantTypeFromURL() {
        // check for attendee or speaker
        // let hash = this.activatedRoute.snapshot.params.hash;
        let hash = window.location.href.split('/').at(-1);
        return hash.length < 6 ? 'Speaker' : 'Attendee';
    }

    setAutoJoinInfo(autoJoinInfo) {
        this.autoJoinInfo$ = autoJoinInfo;
    }

    getAutoJoinInfo() {
        return this.autoJoinInfo$ || {};
    }
    isValidUrl(str) {
        const pattern = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        );
        return pattern.test(str);
    }

    convertMillseconds(value) {
        let hours = value / (1000 * 60 * 60);
        let absoluteHours = Math.floor(hours);
        let h = absoluteHours;

        //Get remainder from hours and convert to minutes
        let minutes = (hours - absoluteHours) * 60;
        let absoluteMinutes = Math.ceil(minutes);
        let m = absoluteMinutes;
        return {
            hour: h,
            minutes: m
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobileView = this.isResponsiveMobileView();
        this.mobileBrowser = this.isMobileBrowser();
    }
    getSessionsEndedList(sessionsList = []) {
        return sessionsList.filter((session) => session.status === EventStatus.ENDED);
    }

    fileSizeToText(fileSize) {
        return fileSize >= this.oneGBSizeInMB
            ? `${Math.round((fileSize / this.oneGBSizeInMB) * this.divider) / this.divider}GB`
            : `${fileSize}MB`;
    }

    markUrlAsSafe(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    holdExecution(ms) {
        return new Promise((res) => setTimeout(res, ms));
    }

    validateFileToUpload(file) {
        if (
            !(
                file.type === 'image/jpeg' ||
                file.type === 'image/jpg' ||
                file.type === 'image/png' ||
                file.type === 'image/gif'
            )
        ) {
            // this.errors.image = this.translateService.instant('FILE_UPLOAD_ERRORS.INCORRECT_FILE_IMG');
            this.toastrService.error(this.translateService.instant('FILE_UPLOAD_ERRORS.INCORRECT_FILE_IMG'));
            return false;
        }
        var size = Math.round(file.size / 1024);
        if (20 > size) {
            // this.errors.image = this.translateService.instant('FILE_UPLOAD_ERRORS.MIN_ERROR_IMG');
            this.toastrService.error(this.translateService.instant('FILE_UPLOAD_ERRORS.MIN_ERROR_IMG'));
            return false;
        } else if (size > 5000) {
            // this.errors.image = this.translateService.instant('FILE_UPLOAD_ERRORS.MAX_ERROR_IMG');
            this.toastrService.error(this.translateService.instant('FILE_UPLOAD_ERRORS.MAX_ERROR_IMG'));
            return false;
        }
        return true;
    }

    downloadFile(url, fileName) {
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }

    removeDuplicateArrayObj(array, uniqueKey) {
        return array.filter(
            (incomingAttendee, index, array) =>
                array.findIndex((t) => t[uniqueKey] == incomingAttendee[uniqueKey]) == index
        );
    }

    startCountdown(meeting) {
        const date = new Date(meeting.startTime);
        meeting.intervalId = setInterval(() => {
            this.calculateTimeLeft(meeting);
        }, 1000);
        this.calculateTimeLeft(meeting);
    }

    calculateTimeLeft(meeting) {
        const futureDate = new Date(meeting.startTime);
        const intervalId = meeting.intervalId;
        const diff = Math.floor((futureDate.valueOf() - new Date().valueOf()) / 1000);
        if (diff <= 0) {
            if (intervalId) {
                clearInterval(intervalId);
                meeting.countdown = {
                    // days: 0,
                    // hours: 0,
                    minutes: 0,
                    seconds: 0
                };
            }
        } else {
            meeting.countdown = this.convertMillisecondsToHMS(diff);
        }
    }

    private convertMillisecondsToHMS(millis) {
        const minutes = Math.floor(millis / 60);
        millis -= minutes * 60;
        const seconds = millis % 60;
        return {
            minutes,
            seconds
        };
    }

    joinEvent(event) {
        const url = event?.joinUrl || event?.link;
        if (this.isMobileBrowser()) {
            window.open(url, '_self');
            return;
        }
        window.open(url, '_blank');
    }
    inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    isAspectRatio16by9(width, height) {
        const aspectRatio = width / height;
        return Math.abs(aspectRatio - 16 / 9) < 0.001;
    }

    isJioPcCheck() {
        return this.isJioPc || this.localStorageService.getItem('source') === 'jiopc' ? true : false;
    }

    getAgoTimeStamp(time) {
        if (!time) return '';
        let seconds = Math.floor(differenceInSeconds(new Date(), new Date(time)));

        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + ' year' + `${Math.floor(interval) === 1 ? '' : 's'}` + ' ago';
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + ' month' + `${Math.floor(interval) === 1 ? '' : 's'}` + ' ago';
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + ' day' + `${Math.floor(interval) === 1 ? '' : 's'}` + ' ago';
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + ' hour' + `${Math.floor(interval) === 1 ? '' : 's'}` + ' ago';
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + ' min' + `${Math.floor(interval) === 1 ? '' : 's'}` + ' ago';
        }
        return 'just now';
    }

    /**
     * Loosely validate a URL `string`.
     *
     * @param {String} string
     * @return {Boolean}
     */

    isUrl(string) {
        var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
        var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
        var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
        if (typeof string !== 'string') {
            return false;
        }

        var match = string.match(protocolAndDomainRE);
        if (!match) {
            return false;
        }

        var everythingAfterProtocol = match[1];
        if (!everythingAfterProtocol) {
            return false;
        }

        if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)) {
            return true;
        }

        return false;
    }

    getFontByUrl(url) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        document.head.appendChild(link);
    }

    enablePaymentFeature(isOtt) {
        return this.appService.getConfigVariable('enablePaymentFeature') && isOtt;
    }

    getAddress(pincode: string) {
        var endPoint = `api/my_profile/address?pincode=${pincode}`;
        var HOST_URL = this.appService.getEnvVariable('HOST_URL');
        var user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.get(endPoint, { headers });
    }

    getOsVersion() {
        var osVersion = window.navigator.userAgent;
        return osVersion;
    }

    getSubscriptionsId() {
        var endPoint = `api/my_profile/subscriptions`;
        var user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.get(endPoint, { headers });
    }

    getDayWithSuffix(day) {
        let suffix = 'th';
        if (day === 1 || day === 21 || day === 31) {
            suffix = 'st';
        } else if (day === 2 || day === 22) {
            suffix = 'nd';
        } else if (day === 3 || day === 23) {
            suffix = 'rd';
        }
        return `${day}${suffix}`;
    }
}
