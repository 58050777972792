import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { SystemService } from './system.service';

interface Participant {
    userId;
    deviceId;
    participantUri;
    participantName;
}

@Injectable({
    providedIn: 'root'
})
export class BreakoutRoomService {
    constructor(
        private restService: RestService,
        private systemService: SystemService,
        private appService: AppService
    ) {}

    getRooms(historyId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout`);
    }

    createRooms(historyId, rooms: any[]) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout`, {
            breakoutRooms: rooms
        });
    }

    editRoom(historyId, brId, name) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/${brId}`,
            {
                name
            }
        );
    }

    deleteRoom(historyId, brId) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/${brId}`
        );
    }

    assignParticipantToRoom(historyId, brId, participants: Participant[]) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/${brId}/assign`,
            {
                participants
            }
        );
    }

    leaveFromBreakoutRoom(historyId, brId, participant: Participant) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/${brId}/leave`,
            {
                participant
            }
        );
    }

    moveParticipant(historyId, fromId, toId, participant: Participant) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/move`, {
            participant,
            fromId,
            toId
        });
    }

    startEndBreaoutRooms(historyId, bool) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/${bool ? 'start' : 'end'}`,
            {}
        );
    }

    unassignAllFromBreaoutRooms(historyId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/unassignall`,
            {}
        );
    }

    randomassignToBreaoutRooms(historyId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/randomassign`,
            {}
        );
    }

    leaveBreakoutRoom({
        participant,
        deviceId = this.systemService.getDeviceUUID(),
        historyId,
        brId,
        isLegacy = false
    }) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}/breakout/${brId}/leave`,
            {
                userId: participant.userId,
                deviceId,
                participantUri: participant.participantUri,
                participantName: participant.participantName
            }
        );
    }
}
