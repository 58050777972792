import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
    @Input() modalHeading: string = 'Modal Heading';
    @Input() iconName: string = '';
    @Output() closeModalEvent = new EventEmitter();

    closeModal() {
        this.closeModalEvent.emit();
    }
}
