import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-event-card-dates',
    templateUrl: './event-card-dates.component.html',
    styleUrls: ['./event-card-dates.component.scss']
})
export class EventCardDatesComponent implements OnInit {
    @Input() joinDetail;
    constructor(public utilservice: UtilService) {}

    ngOnInit(): void {}
}
