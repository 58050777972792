<div class="image-generate-container">
    <p-dialog [draggable]="false" [(visible)]="displayPopup" [closable]="false" (onHide)="togglePanel()">
        <div class="alignheader">
            <div class="image-generate-header">Generate Image with AI</div>
            <div class="close" (click)="cancel()">
                <img src="assets/img/close_icon_green.svg" alt="close" />
            </div>
        </div>
        <div class="image-generate-body">
            <label for="image-generate">Describe what you’d like to see in your image</label>
            <input
                type="text"
                [placeholder]="inputPlaceHolder"
                id="image-generate"
                minlength="1"
                maxlength="200"
                #inputText
            />
            <div class="image-submit-section">
                <div class="image-submit-left">
                    <img src="assets/images/ic_lightbulb_green.svg" alt="" />
                    More detailed description gives the best results!
                </div>
                <button
                    class="custom-button bg-primary"
                    [class.disabled]="loadingSuggestions"
                    (click)="generateImages()"
                >
                    {{ loadingSuggestions ? 'Please wait' : 'Generate' }}
                </button>
            </div>
        </div>
        <div></div>
        <div class="image-generate-suggestions" *ngIf="imageSuggestions?.length">
            <div *ngFor="let image of imageSuggestions" class="image-suggestion" (click)="selectImage($event)">
                <img src="{{ image }}" alt="" />
            </div>
        </div>
        <app-image-cropper
            *ngIf="showImageCropper"
            [imageTypeToBeCropped]="imageType"
            [imageURL]="imageURL"
            (close)="toggleCropper()"
            (croppedFileEmitter)="uploadCroppedImage($event)"
        >
        </app-image-cropper>
    </p-dialog>
</div>
