import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as _ from 'lodash-es';

import { RestService } from './rest.service';
import { AuthService } from './auth.service';
import { JiocloudService } from './jiocloud.service';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    private participantDDownNotification$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private datePipe = new DatePipe('en');

    constructor(
        private restService: RestService,
        private authService: AuthService,
        private jioCloudService: JiocloudService,
        private appService: AppService
    ) {}

    getRCThreadId(jiomeetId) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/rc/getrcthread`, {
            jiomeetId
        });
    }

    fetchAllMessages(jiomeetId) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/chat/getThreadForChat/messages${
                !isAuthenticated ? '/guest' : ''
            }?limit=100`,
            { jiomeetId }
        );
    }

    appendRecievedMessage(chatMessagesByDate: any[], chat): any[] {
        chatMessagesByDate = chatMessagesByDate || [];
        const key = this.datePipe.transform(chat.mOn, 'd MMM y');
        const filtered = chatMessagesByDate.filter((group) => group.key === key)[0];
        if (filtered) {
            filtered.value.unshift(chat);
        } else {
            chatMessagesByDate.push({ key: this.datePipe.transform(chat.mOn, 'd MMM y'), value: [chat] });
        }
        return chatMessagesByDate;
    }

    sendInCallChatMessage({ jiomeetId, messageComponent }) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/chat/getThreadForChat/postmessage${
                !isAuthenticated ? '/guest' : ''
            }`,
            {
                jiomeetId,
                messageComponent
            }
        );
    }

    getChatThreadId(jiomeetId): Observable<any> {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/chat/getThreadForChat${!isAuthenticated ? '/guest' : ''}`,
            {
                jiomeetId
            }
        );
    }

    getChatAttachments(chatThreadId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/chat/thread/${chatThreadId}/messages?hasAttachments=true`
        );
    }

    deleteFromJioMeetAndJioCloud(chatThreadId, messageId, fileId, jiomeetId) {
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/chat/thread/${chatThreadId}/messages/deletemessages`, {
                messageIds: [messageId]
            })
            .pipe(
                switchMap((res) => {
                    return this.jioCloudService.deleteAttachment(fileId, jiomeetId);
                })
            );
    }

    public getPrivateChatThread(members: string[], context: string, admins: string[]): Observable<any> {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/chat/thread`, {
            members,
            context,
            admins
        });
    }

    public getSortedMembers(chatThreadId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/chat/thread/${chatThreadId}/getsortedmembers`
        );
    }
    public getPrivateChatMessages(chatThreadId): Observable<any> {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/chat/thread/${chatThreadId}/messages`
        );
    }

    public sendPrivateMessage(chatThreadId, jiomeetId, text, attachments) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/chat/thread/${chatThreadId}/messages`,
            {
                jiomeetId,
                messageComponent: {
                    text,
                    attachments
                }
            }
        );
    }

    get notifiedParticipantsValue() {
        return this.participantDDownNotification$.value;
    }

    getNotifiedParticipants() {
        return this.participantDDownNotification$;
    }

    updateNotifiedParticipants(userId) {
        if (!this.notifiedParticipantsValue.includes(userId)) {
            this.participantDDownNotification$.next([...this.notifiedParticipantsValue, userId]);
        }
    }

    clearNotifiedParticipants(userId) {
        this.participantDDownNotification$.next(this.notifiedParticipantsValue.filter((id) => id !== userId));
    }

    groupChatMessagesByDate(chats) {
        if (!chats.length) {
            return [];
        }
        // group chats by date
        const chatGroups = _.groupBy(chats, (chat) => this.datePipe.transform(chat.cOn, 'd MMM y'));
        // sort date keys
        const ordered = _.orderBy(_.keys(chatGroups), [(key) => new Date(key)], ['asc']);
        // sorted map by dates
        const map: Map<string, any[]> = ordered.reduce((result, key) => {
            result.set(key, chatGroups[key]);
            return result;
        }, new Map());

        const array = [];
        map.forEach((value, key) => {
            array.push({ key, value });
        });
        return array;
    }
}
