import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UtilService, AppService, UserService, MeetingService, SystemService, RtcService } from 'src/app/core';

@Component({
    selector: 'app-downloads-page',
    templateUrl: './downloads-page.component.html',
    styleUrls: ['./downloads-page.component.scss']
})
export class DownloadsPageComponent implements OnInit, OnDestroy {
    supported = false;
    currentOS: 'Windows' | 'Mac OS' | 'Other';
    params = '';
    meetingId;
    hash;
    pwd;
    isMobileOS;
    isIOS;
    iosMacDeeplink;
    isHosting = false;
    currentUser;
    enableJoinWithBrowser = false;
    subscriptions: Subscription[] = [];
    type = 'shortener';
    browser;
    showCompatibilityMessage = true;
    isWebinar: boolean = false;
    loading: boolean = true;
    ADD_TO_JIOMEET = 'addtojiomeet';
    ADD_TO_JIOEVENTS = 'addtojioevents';
    autoJoin = 0;
    parentMeetingId = null;
    firstName: string;
    lastName: string;
    attendeeUserId: any;
    attendeeRedirectUrl: any;
    sessionId: string;
    sh: string; // sh represents short hash which is used for masking of url

    constructor(
        private utilService: UtilService,
        private appService: AppService,
        private activatedRoute: ActivatedRoute,
        private route: Router,
        private userService: UserService,
        private toastrService: ToastrService,
        private meetingService: MeetingService,
        private systemService: SystemService,
        private translateService: TranslateService,
        private rtcService: RtcService
    ) {}

    ngOnInit() {
        this.fetchParams();
        if (
            (!this.utilService.isMobileBrowser() ||
                (this.utilService.isMobileBrowser() && this.appService.getConfigVariable('enableMobileSpeaker')) ||
                this.autoJoin) &&
            this.activatedRoute.snapshot.data.type !== 'add'
        ) {
            this.navigateToJoin();
            return;
        }
        this.checkBrowserCompatibiity();
        // this.browser = this.utilService.detectBrowser();
        this.subscriptions.push(
            this.activatedRoute.data.subscribe((route) => {
                this.type = this.activatedRoute['_routerState'].snapshot.url.split('?')[0];
                if (this.type[0] === '/') {
                    this.type = this.type.slice(1);
                }
            })
        );
        this.enableJoinWithBrowser = this.appService.getConfigVariable('ENABLE_WEBRTC');
        this.currentUser = this.userService.getUserSync();
        this.supported = this.utilService.isRTCSupportedBrowser();
        this.setOS();
        this.activatedRoute.queryParams.subscribe((params) => {
            if (
                params.meetingId &&
                (this.route.url.indexOf(this.ADD_TO_JIOMEET) !== -1 ||
                    this.route.url.indexOf(this.ADD_TO_JIOEVENTS) !== -1)
            ) {
                this.addToJiomeet();
            } else {
                this.launch();
                this.checkMeetingType();
            }
        });
    }

    checkBrowserCompatibiity() {
        const { browser, version } = this.systemService.getSysytemInfo();
        const majorVersion = parseInt((version || '').split('.')[0], 10);
        if (browser === 'Safari' && majorVersion >= 13) {
            this.showCompatibilityMessage = false;
        }
        if (browser === 'Chrome' && majorVersion >= 83) {
            this.showCompatibilityMessage = false;
        }
    }

    setOS() {
        const os = this.utilService.getOS();
        switch (os) {
            case 'Windows':
                this.currentOS = 'Windows';
                break;
            case 'Mac OS':
                this.currentOS = 'Mac OS';
                break;
            default:
                this.currentOS = 'Other';
        }
    }

    launch() {
        const os = this.utilService.getOS();
        switch (os) {
            case 'Windows':
                location.href =
                    'jiomeet:' + this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchParams();
                break;
            case 'Mac OS':
                this.iosMacDeeplink = null;
                setTimeout(() => {
                    this.iosMacDeeplink =
                        'jhb://' + this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchParams();
                }, 100);
                break;
            case 'iOS':
                if (this.utilService.isEdgeBrowser()) {
                    if (this.utilService.isIpad()) {
                        this.openAppOrNavigateToStore();
                    } else {
                        // for iphone
                        this.iosMacDeeplink = null;
                        setTimeout(() => {
                            this.iosMacDeeplink =
                                'jhb://' + this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchParams();
                        }, 100);
                    }
                } else {
                    this.openAppOrNavigateToStore();
                }
                this.isMobileOS = true;
                break;
            case 'Android':
                this.openJiomeetOrPlaystore();
                this.isMobileOS = true;
                break;
            default:
                if (this.utilService.detectBrowser() === 'android') {
                    this.openJiomeetOrPlaystore();
                    this.isMobileOS = true;
                    return;
                }
                if (this.utilService.detectBrowser() === 'ipad' || this.utilService.detectBrowser() === 'iphone') {
                    const a = document.createElement('a');
                    a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                    a.download = 'Download';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.isMobileOS = true;
                    return;
                }
                break;
        }
    }

    fetchParams() {
        this.isIOS = this.activatedRoute.snapshot.queryParams.ios;
        this.meetingId = this.activatedRoute.snapshot.queryParams.meetingId;
        this.hash = this.activatedRoute.snapshot.queryParams.hash;
        this.sh = this.activatedRoute.snapshot.queryParams.sh;

        this.pwd = this.activatedRoute.snapshot.queryParams.pwd;
        this.isHosting = this.activatedRoute.snapshot.queryParams.host;
        this.autoJoin = +this.activatedRoute.snapshot.queryParams.autoJoin;
        this.firstName = this.activatedRoute.snapshot.queryParams.firstName;
        this.lastName = this.activatedRoute.snapshot.queryParams.lastName;
        this.attendeeUserId = this.activatedRoute.snapshot.queryParams.aid;
        this.attendeeRedirectUrl = this.activatedRoute.snapshot.queryParams.aurl;
        this.parentMeetingId = this.activatedRoute.snapshot.queryParams.parentMeetingId;
        this.sessionId = this.activatedRoute.snapshot.queryParams.sessionId;
        var deviceId = this.activatedRoute.snapshot.queryParams.deviceId;
        if (deviceId && this.autoJoin) {
            this.systemService.setDeviceId(deviceId);
        }
        let param = '';
        if (this.meetingId) {
            param = `?meetingId=${this.meetingId}`;
        } else if (this.hash) {
            param = `?hash=${this.hash}`;
        }
        param = `${param}${this.pwd ? '&pwd=' + this.pwd : ''}&isWebinar=true`;
        return param;
    }

    openAppOrNavigateToStore() {
        if (this.isIOS !== 'true') {
            window.location.href =
                'jhb://' + this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchParams();
            setTimeout(() => {
                window.location.replace(
                    this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchParams() + '&ios=true'
                );
            }, 2000);
        } else {
            const a = document.createElement('a');
            a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
            a.download = 'Download';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    openJiomeetOrPlaystore() {
        window.location.href = `intent://${this.appService.getEnvVariable('HOST_URL').slice('https://'.length)}${
            this.type
        }${this.fetchParams()}#Intent;package=com.jio.rilconferences;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=https;end`;

        setTimeout(() => {
            window.open(this.appService.getConfigVariable('ANDROID_INSTALLER_URL'), '_blank');
        }, 1000);
    }

    downloadJioMeet() {
        this.utilService.downloadJioMeet();
    }

    join() {
        if (!this.supported) {
            this.toastrService.warning('JioMeet is not supported on this browser. Please use Chrome or Safari. ');
            return;
        }
        if (!(this.enableJoinWithBrowser || this.currentUser?.nativeWebrtcEnabled)) {
            this.toastrService.warning("You don't have permission to join meeting in browser");
            return;
        }
        if (this.isHosting) {
            this.route.navigate(['host'], {
                queryParams: {
                    meetingId: this.meetingId
                }
            });
        } else {
            this.navigateToJoin();
        }
    }

    navigateToJoin() {
        // check if meeting url contains session id as query param then store it in app memory

        this.meetingService.setSessionToAutoJoin(this.sessionId || '');
        // check if meeting is session enabled then redirect to intermidiatry session list screen
        // const isSessionInfo = this.rtcService.getWebinarIsSessionInfo();
        // if (isSessionInfo?.sessions?.enabled && isSessionInfo?.meetingId) {
        //     this.navigateToSessionList(isSessionInfo);
        // } else {
        this.route.navigate(['join'], {
            queryParams: {
                meetingId: this.meetingId,
                hash: this.hash,
                sh: this.sh,
                pwd: this.pwd,
                autoJoin: this.autoJoin || 0,
                parentMeetingId: this.parentMeetingId,
                name: this.firstName,
                lname: this.lastName,
                aid: this.attendeeUserId,
                aurl: this.attendeeRedirectUrl
            }
        });
    }

    navigateToSessionList(meetingInfo) {
        this.route.navigate([`event/${meetingInfo.meetingId}/sessions`], {});
        return false;
    }

    addToJiomeet() {
        this.meetingService.addToJiomeet({ hash: this.hash, extension: this.meetingId, pin: this.pwd }).subscribe(
            (res) => {
                this.toastrService.success(this.translateService.instant('tostrs.event_added_success'));
                this.route.navigate(['']);
            },
            (err) => {
                this.toastrService.error(err.error.errors);
                this.route.navigate(['']);
            }
        );
    }

    checkMeetingType() {
        this.meetingService.getMeetingType({ jiomeetId: this.meetingId, pin: this.pwd }).subscribe(
            (res: any) => {
                this.isWebinar = res?.isWebinar || false;
                this.loading = false;
            },
            (err) => {
                this.isWebinar = err.error?.isWebinar || false;
                this.loading = false;
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.map((s) => s.unsubscribe());
    }
}
