import { Directive, Input, AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { UtilService, ProfileService } from 'src/app/core';

@Directive({
    selector: '[appAvatarWithInitials]'
})
export class AvatarWithInitialsDirective implements AfterViewInit, OnChanges {
    @Input() style = 'rectangle';
    @Input() name = '';
    @Input() userId = '';
    colors = {
        a: '#1abc9c',
        b: '#2ecc71',
        c: '#3498db',
        d: '#9b59b6',
        e: '#34495e',
        f: '#16a085',
        g: '#27ae60',
        h: '#2980b9',
        i: '#8e44ad',
        j: '#2c3e50',
        k: '#2DC4C9',
        l: '#e67e22',
        m: '#e74c3c',
        n: '#ecf0f1',
        o: '#95a5a6',
        p: '#f39c12',
        q: '#d35400',
        r: '#c0392b',
        s: '#bdc3c7',
        t: '#7f8c8d',
        u: '#1FB18A',
        v: '#2DC55E',
        w: '#2C84D2',
        x: '#8840A7',
        y: '#216CAA',
        z: '#1B9172'
    };

    constructor(private elRef: ElementRef, public utilService: UtilService, private profileService: ProfileService) {}

    ngAfterViewInit() {
        this.createAvatar();
    }

    createAvatar() {
        if (!this.name) {
            return;
        }
        const circleColorInfo = this.profileService.getColorForInitials(this.name, this.userId);
        const split = this.name.split(' ');
        let result = '';
        if (split[0]) {
            result += split[0][0];
        }
        if (split[1]) {
            result += split[1][0];
        }
        this.elRef.nativeElement.innerHTML = result;
        this.elRef.nativeElement.style.backgroundColor = circleColorInfo.bg;
        this.elRef.nativeElement.style.textTransform = 'uppercase';
        this.elRef.nativeElement.style.color = '#2B2B2B';
        // this.elRef.nativeElement.style.fontSize = '1rem';
        this.elRef.nativeElement.style.fontFamily = 'JioMedium';
        this.elRef.nativeElement.style.borderRadius = this.style === 'circle' ? '50%' : '3px';
        this.elRef.nativeElement.style.height = '100%';
        this.elRef.nativeElement.style.width = '100%';
        this.elRef.nativeElement.style.textAlign = 'center';
        this.elRef.nativeElement.style.display = 'flex';
        this.elRef.nativeElement.style.alignItems = 'center';
        this.elRef.nativeElement.style.justifyContent = 'center';
    }

    ngOnChanges(changes: SimpleChanges) {
        this.name = changes.name?.currentValue;
        this.createAvatar();
    }
}
