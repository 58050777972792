import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppService, DiagnosticService, RtcService, VideoWrapperService } from 'src/app/core';
import { VirtualBackgroundService } from 'src/app/core/services/virtual-background.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { NETWORKING_LOUNGE, PREPARATORY_SETTINGS } from 'src/app/constants';
@Component({
    selector: 'app-virtual-background',
    templateUrl: './virtual-background.component.html',
    styleUrls: ['./virtual-background.component.scss']
})
export class VirtualBackgroundComponent implements OnInit, OnDestroy {
    // ViewChild is used to access the input element.
    @ViewChild('fileInput', { static: false })
    // this InputVar is a reference to our input.
    fileInput: ElementRef;
    noneSrc = 'assets/images/none-unselected.svg';
    noneImageSrc = 'assets/images/none-unselected.svg';
    noneImageHoverSrc = 'assets/images/none-hovered.svg';
    blurSrc = 'assets/images/blur.png';
    blurImageSrc = 'assets/images/blur.png';
    blurImageHoverSrc = 'assets/images/blur-hovered.png';
    customSrc = 'assets/images/custom-unselected.svg';
    customImageSrc = 'assets/images/custom-unselected.svg';
    customImageHoverSrc = 'assets/images/custom-selected.svg';
    emptyUrl = 'https://images.jiomeet.com/empty-background.png';

    selectedImageElement: any;

    public images: any[] = [];

    imageChangedEvent: any = '';
    imagePath: any;
    url: string | ArrayBuffer;
    agoraRTC: any;
    videoElement: any;
    canvasElement: any;
    imageElement: any;
    canvasCtx: any;
    camera: any;
    control = window;
    selfieSegmentation: any;
    videoTrack: any;
    backgroundConfig = 'none';
    localImageSet = [];
    MAX_CUSTOM_TAGS = 5;
    videoService;
    showImagePanel = false;
    vBlistHeight: any = '100px';
    eventData = PREPARATORY_SETTINGS.SELECT_BACKGROUND;
    @ViewChild('videoPreview') video: ElementRef;
    constructor(
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private vbService: VirtualBackgroundService,
        private videoWrapperService: VideoWrapperService,
        private rtcService: RtcService,
        private diagnosticService: DiagnosticService,
        private appService: AppService,
        private googleTagMangerService: GoogleTagMangerService
    ) {
        this.videoService = this.videoWrapperService?.getVideoServiceForPrepScreen();
        this.images = this.appService.getConfigVariable('defaultsVirutalBackgroundImages')
            ? this.appService.getConfigVariable('defaultsVirutalBackgroundImages')
            : [];
    }

    ngOnInit(): void {
        this.localImageSet = this.vbService?.getBackgroundConfig()?.customImageArray || [];
        this.setVBListHeight();
    }

    async ngAfterViewInit() {
        // this.vbService.runCamera();
        this.startVideo();
    }

    setVBListHeight() {
        let vbAreaheight: any = document.querySelector('.vb-content-area').clientHeight;
        let height: any = vbAreaheight - 305;
        height = height < 120 ? 80 : height < 150 ? 100 : height;
        this.vBlistHeight = `${height}px`;
    }

    setBackgroundConfig({ type, url = null }) {
        const isVirtualBackgroundApplied = type !== 'none' ? true : false;
        this.videoService.isVirtualBackgroundApplied = isVirtualBackgroundApplied;
        this.rtcService.isVirtualBackgroundApplied = isVirtualBackgroundApplied;
        // this.vbService.setBgConfig({ type, imageUrl: url });
    }

    blurBackground() {
        this.backgroundConfig = 'blur';
        this.setBackgroundConfig({ type: this.backgroundConfig });
        this.videoService.setBackgroundBlurring();
        this.eventData.clicked_text = 'blur';
        this.googleTagMangerService.pushGoogleTagData(this.eventData);
    }

    clearBackground() {
        this.backgroundConfig = 'none';
        this.setBackgroundConfig({ type: this.backgroundConfig });
        this.videoService.disableBg();
        this.eventData.clicked_text = 'none';
        this.googleTagMangerService.pushGoogleTagData(this.eventData);
    }

    fileChangeEvent(event: any): void {
        if (!event.target.files?.length) {
            return;
        }
        if (this.localImageSet?.length >= this.MAX_CUSTOM_TAGS) {
            this.toastrService.error(
                `You can add a maximum of ${this.MAX_CUSTOM_TAGS} custom images. Please remove existing images before adding new ones`
            );
            return;
        }
        if (
            event.target.files[0] &&
            !(
                event.target.files[0].type === 'image/jpeg' ||
                event.target.files[0].type === 'image/jpg' ||
                event.target.files[0].type === 'image/png'
            )
        ) {
            this.toastrService.error(
                this.translateService.instant('tostrs.please_select_a_jpg_jpeg_or_png_image_file')
            );
            return;
        }
        if (event.target.files[0] && event.target.files[0].size / 1024 / 1024 >= 5) {
            this.toastrService.error(this.translateService.instant('tostrs.file_size_cannot_exceed_5mb'));
            return;
        }

        this.imageChangedEvent = event;

        const files = event.target.files;
        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);

        reader.onload = (_event) => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                const height = img.naturalHeight;
                const width = img.naturalWidth;
                if (width < 840 || height < 480) {
                    this.toastrService.error(this.translateService.instant('tostrs.minimum_resolution'));
                    return;
                }
                this.url = reader.result;
                if (this.localImageSet?.filter((uri) => uri === this.url)?.length) {
                    this.toastrService.error('Image already uploaded');
                    return;
                }
                this.localImageSet = [...this.localImageSet, this.url];
                this.sendGaEvent('Custom Image Applied');
                this.vbService.updateCustomImages(this.localImageSet);
                this.setBackgroundConfig({ type: 'image', url: this.url });
                this.videoService.setBackgroundImage(this.url);
            };
            this.eventData.clicked_text = 'custom';
            this.googleTagMangerService.pushGoogleTagData(this.eventData);
        };
        reader.onerror = () => {
            this.toastrService.error('File not supported. Please try again');
        };
    }

    onSelect(event: any, defaultImage = 'none', imageIndex = null): void {
        if (this.videoWrapperService.getVideoService()?.enableStreamMixer) {
            // videoService is prepScreen Media Engine.
            this.toastrService.info('Virtual Background Cannot be applied for custom stream');
            return;
        }
        if (defaultImage !== 'none') {
            this.sendGaEvent(
                defaultImage === 'default'
                    ? `Default Image ${imageIndex + 1} Applied`
                    : defaultImage === 'blur'
                    ? 'Blur Image Applied'
                    : defaultImage === 'customClicked'
                    ? 'Custom Image Clicked'
                    : 'Custom Image Applied'
            );
        }
        if (defaultImage !== 'customApplied' && defaultImage !== 'default' && defaultImage !== 'remove') {
            return;
        }
        if (defaultImage == 'default') {
            this.eventData.clicked_text = `Default_${imageIndex + 1}`;
            this.googleTagMangerService.pushGoogleTagData(this.eventData);
        }
        this.url = event.target.src;

        if (defaultImage === 'remove') {
            this.url = this.emptyUrl;
        } else {
            this.url = event.target.src;
        }

        this.selectedImageElement = event.target;
        this.backgroundConfig = 'image';
        this.setBackgroundConfig({ type: 'image', url: this.url });
        this.videoService.setBackgroundImage(this.url);
    }

    async startVideo() {
        // const videoElement = this.video.nativeElement;
        // videoElement.srcObject = this.vbService.startCapture();
        // videoElement.play();

        await this.videoService.toggleCameraForSetting('video-preview', true);
        this.showImagePanel = true;
    }
    deleteCustomImage(imgUri) {
        this.localImageSet.splice(this.localImageSet.indexOf(imgUri), 1);
        this.vbService.updateCustomImages(this.localImageSet);
        this.backgroundConfig = 'none';
        this.setBackgroundConfig({ type: this.backgroundConfig });
        this.videoService.disableBg();

        // We will clear the value of the input
        // field using the reference variable.

        this.fileInput.nativeElement.value = '';
    }

    async ngOnDestroy() {
        const closeVbSession =
            (!this.rtcService?.getConferenceInfo() && !this.rtcService?.getCameraMicPreference()?.cameraPrivacy) ||
            (!!this.rtcService?.getConferenceInfo() && this.videoService?.localParticipant?.cameraMute);
        if (closeVbSession) {
            //this.vbService.closeSession();
            if (!this.rtcService.isJMMeeting) await this.videoService?.localCameraTrack.setEnabled(false);
        }

        //handling inside call toggling scenarios
        if (this.videoService?.localParticipant && !this.videoService?.localParticipant?.cameraMute) {
            if (!this.rtcService.isJMMeeting) await this.videoService.toggleCameraForSetting('localVideo', true);
        }

        //handling preview screen toggling scenarios
        if (this.rtcService?.getCameraMicPreference()?.cameraPrivacy && !this.videoService?.localParticipant?.userId) {
            if (!this.rtcService.isJMMeeting) await this.videoService.toggleCameraForSetting('preview-renderer', true);
        }
        //setting local cloud video bg
        await this.videoService.confirmVbSetting();
        if (this.rtcService.isJMMeeting) await this.videoService.stopVirtualBackgroundInstance();
    }

    sendGaEvent(action) {
        this.diagnosticService
            .sendEvent({
                eventAction: action,
                eventCategory: 'JioEvents Virtual Background',
                eventType: 'app_event',
                status: 'success'
            })
            .subscribe();
    }
}
