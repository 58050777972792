import { Component, OnInit } from '@angular/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { AppService, ExternalInterfaceService } from 'src/app/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-tnc',
    templateUrl: './tnc.component.html',
    styleUrls: ['./tnc.component.scss']
})
export class TncComponent implements OnInit {
    websiteLink = location.origin;
    showStbChanges = false;
    isLangHindi = false;
    isMyJio;

    constructor(
        private gaService: GaDiagnosticService,
        private appService: AppService,
        private router: Router,
        private route: ActivatedRoute,
        private externalInterfaceService: ExternalInterfaceService
    ) {}

    ngOnInit() {
        // const ele = document.getElementsByClassName('app-container')[0];
        // if (ele) {
        //   ele.scrollTo(0, 0);
        // }

        this.route.queryParams.subscribe((params) => {
            if (params['isstb']) {
                this.showStbChanges = true;
            }
            if (params?.isMyJio) {
                this.isMyJio = params?.isMyJio ? true : false;
            }
            this.isLangHindi = params['lan'] === 'hi' ? true : false;
        });

        this.gaService.sendPageView({
            page_title: 'tnc_page',
            page_path: '/terms-condition-business'
        });
    }

    gotopp() {
        if (this.isMyJio) {
            this.router.navigate(['privacy-policy-business'], { queryParams: { isMyJio: true } });
        } else {
            this.router.navigate(['privacy-policy-business']);
        }
        // window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    sendEmail() {
        const email = 'enterpriseservices@jio.com';
        if (this.isMyJio) {
            this.externalInterfaceService.launchBrowser('mailto:' + email);
        } else {
            window.location.href = 'mailto:' + email;
        }
    }

    gototnc() {
        if (this.isMyJio) {
            this.router.navigate(['terms-condition-business'], { queryParams: { isMyJio: true } });
        } else {
            this.router.navigate(['terms-condition-business']);
        }
        // window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }
}
