import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(date: Date): string {
        const day = date.toLocaleDateString(undefined, { weekday: 'short' });
        const dayOfMonth = date.getDate();
        const month = date.toLocaleDateString(undefined, { month: 'long' });
        const year = date.getFullYear();

        const getDaySuffix = (day: number): string => {
            return day % 100 >= 11 && day % 100 <= 13
                ? 'th'
                : day % 10 === 1
                ? 'st'
                : day % 10 === 2
                ? 'nd'
                : day % 10 === 3
                ? 'rd'
                : 'th';
        };

        const daySuffix = getDaySuffix(dayOfMonth);
        return `${day}, ${dayOfMonth}${daySuffix} ${month} ${year}`;
    }
}
