import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private participants: Map<string, any> = new Map();
    private participants$: BehaviorSubject<Map<string, any>> = new BehaviorSubject(this.participants);

    private intialsBackgroundColors = [
        '#ADADFC',
        '#E3ADFC',
        '#FAADFC',
        '#FCADDC',
        '#FCADBB',
        '#FCBBAD',
        '#FCDCAD',
        '#FCF4AD',
        '#E7FCAD',
        '#C2FCAD',
        '#ADFCBE',
        '#ADFCD6',
        '#ADF7FC',
        '#ADE0FC',
        '#ADCDFC',
        '#ADBEFC',
        '#AFADFC'
    ];

    constructor(private appService: AppService) {}

    getInitials(name, userId) {
        const userInfo = this.participants.get(`${userId}-${name}`);
        if (userInfo?.profileInfo?.profileName) {
            return userInfo?.profileInfo?.profileName;
        }
        const profileName = name
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase()
            .substring(0, 2);
        const profileInfo = { ...userInfo?.profileInfo, profileName };
        if (userId) {
            this.participants.set(`${userId}-${name}`, { ...userInfo, ...{ profileInfo } });
        }
        return profileName;
    }

    getColorForInitials(participantName: string, userId: string = '', colorOpacity = 1) {
        let userInfo;
        if (userId) {
            userInfo = this.participants.get(userId);
            if (userInfo?.colorInfo) {
                return userInfo?.colorInfo;
            }
        }
        const name = participantName + (userId || '');
        let colorPosition = name?.split('').reduce((acc, val) => {
            return acc + val.charCodeAt(0);
        }, 0);
        colorPosition = colorPosition % this.intialsBackgroundColors.length;

        const colorInfo = {
            bg: this.intialsBackgroundColors[colorPosition],
            fontColor: '',
            rgba: this.convertHexToRGBA(this.intialsBackgroundColors[colorPosition], colorOpacity)
        };
        if (userId) {
            this.participants.set(userId, { ...userInfo, ...{ colorInfo } });
        }
        return colorInfo;
    }

    async checkIfProfilePhotoExists(userId) {
        if (!userId || userId?.includes('gu')) {
            this.setProfilePictureState(userId, null);
            return;
        }
        const userInfo = this.participants.get(userId);
        try {
            if (userInfo?.profileInfo?.profilePicUrl || userInfo?.profileInfo?.profilePicUrl === null) {
                this.emit();
            } else {
                (async (userId) => {
                    let profilePicUrl: any;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        profilePicUrl = reader.result;
                        const profilePhotoExists = !profilePicUrl.includes('image/svg+xml');
                        this.setProfilePictureState(userId, profilePhotoExists ? true : null);
                    };
                    const response = await fetch(`${this.appService.getEnvVariable('BASE_URL')}/profilepic/${userId}`);
                    if (response.ok) {
                        const imageBlob = await response.blob();
                        reader.readAsDataURL(imageBlob);
                    } else {
                        this.setProfilePictureState(userId, null);
                    }
                })(userId);
            }
        } catch (error) {
            this.setProfilePictureState(userId, null);
            return false;
        }
    }

    setProfilePictureState(userId, state) {
        const userInfo = this.participants.get(userId);
        this.participants.set(userId, {
            ...userInfo,
            ...{ profileInfo: { profilePicUrl: state } }
        });
        this.emit();
    }

    getParticipantsStatus$() {
        return this.participants$;
    }

    emit() {
        if (this.participants$.observers) {
            this.participants$.next(this.participants);
        }
    }

    removeParticipant(userId) {
        this.participants.delete(userId);
    }

    getProfilePicPath(userId) {
        return `${this.appService.getEnvVariable('BASE_URL')}/profilepic/${userId}/profile.png`;
    }

    getTopSpeakers(participants, topSpeakerCount) {
        return participants
            .slice()
            .filter((participant) => participant.volume > 3)
            .sort((a, b) => b.volume - a.volume)
            .slice(0, topSpeakerCount)
            .map((speaker) => speaker.id);
    }

    getFullName(fname, lname) {
        return `${fname?.trim()} ${lname?.trim()}`;
    }

    convertHexToRGBA(hexVal, opacity = 1) {
        hexVal = hexVal.replace('#', '');
        // extracting the hex values for RGB
        const red = hexVal.substr(0, 2),
            green = hexVal.substr(2, 2),
            blue = hexVal.substr(4, 2);

        // converting in decimal values
        const red10 = parseInt(red, 16),
            green10 = parseInt(green, 16),
            blue10 = parseInt(blue, 16);

        // stitching it together
        const rgb = red10 + ',' + green10 + ',' + blue10;

        // the final rgba CSS ouptut
        const rgba = `rgba(${rgb},${opacity})`;
        return rgba;
    }
}
