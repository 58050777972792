<div class="image-cropper-container" [class.inside-call-popup]="insideCall">
    <div class="cropper-popup d-flex flex-column justify-content-between">
        <div class="cropper-header d-flex justify-content-between">
            <div class="cropper-heading">
                <img src="" alt="" />
                Crop Image
            </div>
            <div class="cropper-close-btn" (click)="closeModal()">
                <img src="assets/images/close_icn_blue.svg" alt="" />
            </div>
        </div>
        <div class="cropper-body">
            <image-cropper
                *ngIf="imageURL"
                [imageURL]="imageURL"
                [aspectRatio]="ratioOptions ? selectedRatio : defaultRatio"
                [maintainAspectRatio]="true"
                (imageCropped)="imageCropped($event)"
                [resizeToWidth]="maxWidth"
                [resizeToHeight]="maxHeight"
                [onlyScaleDown]="true"
                format="jpeg"
            >
            </image-cropper>
            <image-cropper
                *ngIf="imageChangedEvent"
                [imageFile]="imageChangedEvent"
                [aspectRatio]="ratioOptions ? selectedRatio : defaultRatio"
                [maintainAspectRatio]="true"
                (imageCropped)="imageCropped($event)"
                [resizeToWidth]="maxWidth"
                [resizeToHeight]="maxHeight"
                [onlyScaleDown]="true"
                format="jpeg"
            >
            </image-cropper>
        </div>
        <div *ngIf="ratioOptions" class="radio-options">
            <div class="d-flex align-items-center">
                <input type="radio" name="ratio" (click)="selectRatio(1.77)" checked /><label style="color: black"
                    >Landscape</label
                >
                <app-info-icon
                    [tooltip]="'crops the image with an aspect ratio of 16:9'"
                    class="info-grey"
                ></app-info-icon>
            </div>
            <div class="d-flex align-items-center">
                <input type="radio" name="ratio" (click)="selectRatio(1)" /><label style="color: black">Square</label>
                <app-info-icon
                    [tooltip]="'crops the image with an aspect ratio of 1:1'"
                    class="info-grey"
                ></app-info-icon>
            </div>
        </div>
        <div class="cropper-action-btns d-flex justify-content-end">
            <button
                (click)="closeModal()"
                [ngClass]="{
                    'custom-button-revamp btn-secondary': insideCall,
                    'custom-button bg-secondary': !insideCall
                }"
            >
                Discard
            </button>
            <button
                class="btn-primary"
                (click)="onSubmit(false)"
                [ngClass]="{ 'custom-button-revamp': insideCall, 'custom-button': !insideCall }"
            >
                Save
            </button>
            <button class="custom-button btn-primary" (click)="onSubmit(true)" *ngIf="showKeepOriginalOptionInUi">
                Keep Original
            </button>
        </div>
    </div>
    <div class="cropper-bg"></div>
</div>
