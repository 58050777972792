import { Component, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from 'src/app/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

import { FOOTER } from '../../../constants/gtm-events';

@Component({
    selector: 'app-landing-footer',
    templateUrl: './landing-footer.component.html',
    styleUrls: ['./landing-footer.component.scss']
})
export class LandingFooterComponent {
    public socialMediaUrls: any = {};
    isElementInView: boolean = false;
    isMobile: boolean = false;
    footerLinks = [
        { label: 'Pricing', route: '/pricing', action: 'PRICING' },
        { label: 'Customers', route: '/customer', action: 'CLIENTELE' },
        { label: 'Contact Us', route: '/contactus', action: 'CONTACT_US' }
    ];
    socialMediaLinks = [
        { platform: 'facebook', iconSrc: 'assets/img/website/ic-facebook@2x.png' },
        { platform: 'twitter', iconSrc: 'assets/img/website/twitterIcon.png' },
        { platform: 'instagram', iconSrc: 'assets/img/website/ic-instagram@2x.png' },
        { platform: 'youtube', iconSrc: 'assets/img/website/ic-youtube@2x.png' },
        { platform: 'linkedIn', iconSrc: 'assets/img/website/ic-linkedIn@2x.png' }
    ];
    firstArray: any[];
    secondArray: any[];
    copyRight: string;
    constructor(
        private appService: AppService,
        private router: Router,
        private googleTagManager: GoogleTagMangerService,
        private utilService: UtilService
    ) {
        this.socialMediaUrls = this.appService.getConfigVariable('socialMediaUrls');
    }
    @ViewChild('elementToObserve') elementToObserve!: ElementRef;
    ngAfterViewInit() {
        const options = {
            root: null, // use the viewport as the root
            rootMargin: '0px',
            threshold: 0.05 // trigger when 50% of the element is visible
        };

        const observer = new IntersectionObserver(this.handleIntersection, options);
        observer.observe(this.elementToObserve.nativeElement);
    }
    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser();
        this.firstArray = this.socialMediaLinks.slice(0, 3);
        this.secondArray = this.socialMediaLinks.slice(3);
        this.copyRight = this.appService.getConfigVariable('copyRightText');
    }
    handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.isElementInView = true;
            } else {
                this.isElementInView = false;
            }
        });
    };
    navigateToPrivacy() {
        window.open(`${this.appService.getJioEventsBaseUrl()}privacy-policy-business`);
    }
    navigateToTnC() {
        window.open(`${this.appService.getJioEventsBaseUrl()}terms-condition-business`);
    }
    redirectToWhatsNew() {
        this.utilService.redirectToWhatsNew();
    }
    redirectToBlog() {
        if (this.appService.getConfigVariable('JIOEVENT_BLOG_URL')) {
            window.open(this.appService.getConfigVariable('JIOEVENT_BLOG_URL'));
        } else {
            this.router.navigateByUrl('/comingsoon');
        }
    }
    redirectToCaseStudies() {
        if (this.appService.getConfigVariable('JIOEVENT_CASE_STUDIES_URL')) {
            window.open(this.appService.getConfigVariable('JIOEVENT_CASE_STUDIES_URL'));
        } else {
            this.router.navigateByUrl('/comingsoon');
        }
    }
    redirectToFaqs() {
        this.router.navigateByUrl('/faqs');
    }

    footerlinksClick(event) {
        const url = this.socialMediaUrls[event];
        if (url) {
            window.location.href = url;
        } else {
            console.warn(`URL not found for ${event}`);
        }
        this.googleTagManager.pushGoogleTagData(FOOTER[event]);
    }
}
