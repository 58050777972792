<div
    class="flash-card-body"
    [class.dashboardStyle]="isDashboard"
    [class.disabled]="!isFlashCardEnabled && !isDashboard"
>
    <div class="no-flash-info-wrapper" *ngIf="flashcardCount === 0 && !isDashboard && !showCreateFlashCard">
        <img src="assets/images/flash-card/undraw_credit_card.svg" />
        <span>Enable flash card to display <br />links, products and more</span>
    </div>
    <div class="flash-switch" *ngIf="!isDashboard">
        <span class="mr-3">{{ isFlashCardEnabled ? 'Enabled' : 'Disabled' }}</span>
        <p-inputSwitch [(ngModel)]="isFlashCardEnabled" (onChange)="toggleFlashCard($event)"></p-inputSwitch>
    </div>
    <div *ngIf="flashcardCount > 0 && !showCreateFlashCard && !isDashboard" class="display-cards">
        <ng-container *ngFor="let card of flashCardsArrayIn; let i = index">
            <div class="flash-publish-container">
                <div class="flash-header d-flex justify-content-between">
                    Card {{ i + 1 }}
                    <span class="live-draft" [class.draft]="!card?.publish">{{
                        card?.publish ? 'Live' : 'Draft'
                    }}</span>
                </div>
                <div class="flash-card-preview-conatiner">
                    <div class="card-preview" [class.description]="card?.description?.length > 10">
                        <div
                            class="flash-img"
                            [ngStyle]="{ 'background-image': 'url(' + card?.imageUrl + ')' }"
                            *ngIf="card?.imageUrl"
                            [class.is-landscape]="card?.imageMode === 'landscape'"
                        ></div>
                        <div class="flash-description">
                            <span class="flash-title" *ngIf="card?.title">{{ card.title }}</span>
                            <span class="flash-text" *ngIf="card?.description">
                                <span [innerHTML]="sanitizeText(card?.description) | sanitizeHtml"></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flash-card-options d-flex">
                    <div class="publish-btn" *ngIf="card?.publish" (click)="unpublishFlashCard(card)">Unpublish</div>
                    <div class="publish-btn" (click)="publishFlashCard(card)" *ngIf="!card?.publish">Publish</div>
                    <div class="delete-btn" (click)="editCard(card)" *ngIf="!card?.publish">Edit</div>
                    <div class="delete-btn" (click)="deleteFlashCard(card?._id)">Delete</div>
                </div>
            </div>
        </ng-container>
        <div class="flash-publish-footer" *ngIf="flashCardsArrayIn.length !== 0">
            <button
                class="create-poll-btn custom-button bg-login"
                (click)="createFlashCard()"
                [class.disabledBtn]="flashcardCount >= maxFlashCardCount"
            >
                <span class="img-wrapper">
                    <img src="assets/images/speaker-call-wrapper/black_plus.svg" alt="" />
                </span>
                <span class="ml-2">Create Flash Card</span>
            </button>
        </div>
    </div>
    <div *ngIf="isDashboard" class="flash-card-header">
        Add Flash Card
        <div class="close-icn d-flex pointer" (click)="closePopup()">
            <img src="assets/img/close_icon_flash.svg" />
        </div>
    </div>
    <div class="create-flash-card" *ngIf="showCreateFlashCard || isDashboard">
        <div class="flash-card-form">
            <form [formGroup]="flashCardForm">
                <div class="title-details">
                    <div><label>Add title</label></div>
                    <input
                        type="text"
                        class="title-input"
                        formControlName="title"
                        placeholder="Add Title here"
                        maxlength="20"
                    />
                    <div class="char-limit">{{ flashCardForm?.value?.title?.length || 0 }}/20</div>
                </div>
                <div class="description-details">
                    <div><label>Add Description</label></div>
                    <div class="description-container">
                        <app-text-editor
                            #descTextEditor
                            [text]="cardDescription"
                            (textChanged)="changesInProgess = true"
                            [defaultPlaceholder]="'Add Description here'"
                            [maxLength]="100"
                            [disableShiftEnter]="true"
                            (updateChanges)="updateDescription()"
                            [blockEmojis]="true"
                            [disableEnter]="true"
                            [insideCall]="!isDashboard ? true : false"
                            [applyRevampStyles]="true"
                        ></app-text-editor>
                    </div>
                </div>
                <div class="upload-flash-card-info">
                    <div class="info-heading">
                        <span>Display Image</span>
                    </div>
                    <div
                        class="upload-section"
                        appDragDrop
                        (filesDropped)="handleDropFileEvent($event)"
                        *ngIf="!showProgressBar"
                        [class.active]="flashCardForm?.value?.imageUrl || cardDetails?.imageUrl"
                    >
                        <div class="upload-container" *ngIf="!cardDetails?.imageUrl && !flashCardForm?.value?.imageUrl">
                            <span>
                                <img src="assets/images/flash-card/upload_cloud.svg" alt="" />
                            </span>
                            <span class="drag-drop-text">Drag and Drop Here</span>
                            <span class="drag-drop-text separator">OR</span>
                            <span class="bottom-section">
                                <input
                                    #imageUpload
                                    type="file"
                                    (change)="onFileSelected($event)"
                                    accept="image/*"
                                    style="display: none"
                                    (click)="imageUpload.value = null"
                                />
                                <button class="custom-button upload-btn" (click)="imageUpload.click()">
                                    Upload Image
                                </button>
                            </span>
                        </div>
                        <div
                            class="upload-container uploaded-img"
                            [class.img-landscape]="
                                imageRatio ? imageRatio >= 1.7 : cardDetails?.imageMode === 'landscape'
                            "
                            *ngIf="cardDetails?.imageUrl || flashCardForm?.value?.imageUrl"
                        >
                            <img [src]="cardDetails?.imageUrl || flashCardForm?.value?.imageUrl" alt="Uploaded Image" />
                            <div class="uploaded-image-actions">
                                <img class="pointer" src="assets/images/flash-close.svg" (click)="discardFile()" />
                            </div>
                        </div>
                    </div>
                    <div class="upload-section progress-section upload-container" *ngIf="showProgressBar">
                        <span class="text-uploading">Uploading...</span>
                        <div class="d-flex w-100 justify-content-center align-items-center">
                            <div class="progress-bar">
                                <div class="progress" [style.width.%]="progress || 0"></div>
                            </div>
                            <div class="cancel-wrapper">
                                <img src="assets/img/close_icon_flash.svg" alt="Cancel Icon" (click)="discardFile()" />
                            </div>
                        </div>
                        <span class="progress-indicator">{{ progress || 0 }}% done</span>
                    </div>
                </div>
                <div class="file-type-info">
                    <span>• File Type: GIF, JPEG, PNG <br />• File Size: Min 20KB Max 2MB</span>
                </div>
            </form>
        </div>
        <div class="polls-create-footer" *ngIf="!isDashboard">
            <button
                class="custom-button-revamp btn-secondary sm"
                (click)="saveCard()"
                [class.disabled]="isUpdateDisabled || isFormEmpty"
            >
                Save as Draft
            </button>
            <button class="custom-button-revamp btn-primary sm" (click)="publishCard()" [class.disabled]="isFormEmpty">
                Publish
            </button>
        </div>
        <div class="polls-create-footer" *ngIf="isDashboard">
            <button
                class="custom-button-revamp btn-primary"
                (click)="saveCard()"
                [class.disabled]="isUpdateDisabled || isFormEmpty"
            >
                Save
            </button>
        </div>
    </div>
</div>
<app-image-cropper
    class="image-cropper"
    *ngIf="showImageCropper"
    [imageURL]="imageUrl"
    [ratioOptions]="true"
    (close)="toggleImageCropper()"
    (croppedFileEmitter)="uploadCroppedImage($event)"
    (selectedRatioEmitter)="getSelectedRatio($event)"
    [insideCall]="true"
></app-image-cropper>
