import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MEDIA_ENGINE_EVENTS } from 'src/app/constants/media-engine-events';
import { AppService, DiagnosticService, RoomConnectionService, VideoWrapperService } from 'src/app/core';
import { WebinarConferenceService } from '../../../conference/services';
import { ConfirmationService } from 'primeng/api';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { forkJoin } from 'rxjs';
import { MediaOrchestratorService } from 'src/app/shared/services/media-orchestrator.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

import { INSIDE_EVENT } from '../../../constants';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';

@Component({
    selector: 'app-mixed-stream-settings',
    templateUrl: './mixed-stream-settings.component.html',
    styleUrls: ['./mixed-stream-settings.component.scss']
})
export class MixedStreamSettingsComponent implements OnInit {
    @Input() meetingObj;
    @Output() afterClose = new EventEmitter();
    @Input() cloudPlayerConfig;

    mediaFiles = [];
    loading: boolean = false;
    mediaSelected;
    private videoService;
    cloudVideoMaxSize;
    uploadingFiles$: any = {};
    gtmMeetingInfo: any = {};
    firstRotationAngle = 0;
    secondRotationAngle = 90;
    showMiniBar: boolean = false;
    uploadInProgress: boolean = false;
    uploadingFiles = [];

    constructor(
        private webinarConfService: WebinarConferenceService,
        private activatedRoute: ActivatedRoute,
        private videoWrapperService: VideoWrapperService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private appService: AppService,
        private roomConnectionService: RoomConnectionService,
        private confirmationService: ConfirmationService,
        private diagnosticService: DiagnosticService,
        private appLoggerService: AppLoggerService,
        private mediaOrchestratorService: MediaOrchestratorService,
        private googleTagManager: GoogleTagMangerService,
        private callStateManagerService: CallStateManagerService,
        private callViewStateManagerService: CallViewStateManagerService
    ) {
        this.videoService = this.videoWrapperService.getVideoService();
        this.cloudVideoMaxSize = this.appService.getConfigVariable('jioConfConfig')?.cloudVideoMaxSize || 1024;
    }

    ngOnInit(): void {
        this.meetingObj = this.callStateManagerService.getCurrentMeetingObject();
        this.getMediaFiles();
        this.gtmMeetingInfo = {
            meeting_Id: this.meetingObj?.meetingInfo._id,
            Category: this.meetingObj?.meetingInfo?.jioConf?.eventType,
            expected_attendee_count: this.meetingObj?.meetingInfo?.jioConf?.expectedAttendeeCount,
            tags: this.meetingObj?.meetingInfo?.jioConf?.tags
        };
    }

    toggleMiniPreview() {
        if (this.uploadingFiles.length === 0) {
            this.closeModal(null);
            return;
        }
        this.showMiniBar = !this.showMiniBar;
    }

    confirmShareMediaRequest(selectedMediaId) {
        this.confirmationService.confirm({
            message: `
            <span class="uplod-cancel-container">
             <span class="cancelfile-header">Confirm Play?</span>
             <span class="cancel-txt">Are you sure you want to play this video.</span>
            </span>
            `,
            header: '',
            acceptLabel: 'Yes',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            accept: () => {
                this.shareMedia(selectedMediaId);
            }
        });
    }

    getMediaFiles() {
        const parentMeetingId = this.meetingObj?.meetingInfo?.parentMeetingId; // this only happens when this is a session

        const fetchMediaObservers = [this.webinarConfService.getMediaList(this.meetingObj?.meetingId)];

        if (parentMeetingId) {
            fetchMediaObservers.push(this.webinarConfService.getMediaList(parentMeetingId));
        }

        forkJoin(fetchMediaObservers).subscribe(([currentMeeting, parentMeeting = { list: [] }]) => {
            const totalMedia = [...currentMeeting.list, ...parentMeeting.list];

            this.mediaFiles = totalMedia.map((file) => {
                file.originalName = decodeURI(file.originalName);
                return { ...file, uploadSuccess: true };
            });
            this.loading = false;
        });
    }

    shareMedia(selectedMediaId) {
        const localVidyoUser = this.videoService.getLocalParticipant();
        const payload = {
            action: 'start',
            mediaId: selectedMediaId ?? this.mediaSelected,
            jiomeetId: this.meetingObj.jiomeetId,
            participantUri: localVidyoUser.id
        };
        this.cloudPlayerConfig.playWithoutSeek = true;
        this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.SHARE_CLOUD_PLAYER_START, this.gtmMeetingInfo);
        this.webinarConfService.shareMedia(payload).subscribe(() => {
            this.diagnosticService
                .sendEvent({
                    eventCategory: 'Jioconf Cloud video Inside Event',
                    eventAction: `Share`,
                    eventType: 'app_event',
                    endpoint: 'media/share',
                    status: 'success'
                })
                .subscribe();
            this.mediaOrchestratorService.softMuteAll(this.meetingObj, 'cloud-preview');
            this.closeModal(null);
        });
    }

    closeModal(event: MouseEvent) {
        if (event) {
            event.stopPropagation();
        }
        if (this.uploadInProgress) {
            this.confirmationService.confirm({
                message: `
                <span class="uplod-cancel-container">
                 <span class="cancelfile-header">Uploading in progress</span>
                 <span class="cancel-txt">Closing this window will stop the current upload. Do you still want to close the window?</span>
                </span>
                `,
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
                rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
                accept: () => {
                    // this.shareMedia(selectedMediaId);
                    this.uploadingFiles?.forEach((f) => {
                        this.cancelUpload(f);
                    });
                    this.closeModal(null);
                }
            });
            return;
        }
        this.showMiniBar = false;
        this.afterClose.emit();
    }

    // fileChangeEvent(event) {
    //     this.validateFileResolution(event.target.files[0]);
    // }
    async fileChangeEvent(event) {
        this.videoService.handleDropFileEvent(event);
        if (!this.videoService.cameraState) {
            this.startStreamWithPPT();
        }
    }

    async startStreamWithPPT() {
        this.videoService.streamMixerType = 'ppt';
        this.videoService.enableStreamMixer = true;
        try {
            await this.mediaOrchestratorService.toggleCameraStatus(
                this.callViewStateManagerService.roomStatus?.localParticipant,
                this.meetingObj
            );
        } catch (err) {
            throw err;
        }
    }

    handleDropFileEvent(event) {
        if (!event[0]?.name) {
            this.toastrService.error(this.translateService.instant('webinar-tostrs.try_uploading_msg'));
            return;
        }
        this.validateFileResolution(event[0]);
    }

    validateFileResolution(file) {
        if (file?.size / 1024 / 1024 > this.cloudVideoMaxSize) {
            this.toastrService.error(`File size cannot exceed  ${this.cloudVideoMaxSize}MB`);
            return;
        }
        // if (file && !(file.type === 'video/mp4')) {
        //     this.toastrService.error('Only .mp4 video files are allowed ');
        //     return;
        // }
    }

    uploadMedia(file) {
        this.uploadInProgress = true;
        let uploadedFile = {
            unique: new Date().valueOf(),
            originalName: file.name,
            uploadSuccess: false
        };
        this.uploadingFiles.push(uploadedFile);
        this.mediaFiles = [uploadedFile, ...this.mediaFiles];
        (this.uploadingFiles$[uploadedFile.unique] = this.webinarConfService
            .uploadMediaFile(file, this.meetingObj.meetingId || this.meetingObj._id)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.mediaFiles[this.mediaFiles.findIndex((f) => f.unique === uploadedFile.unique)].progress =
                            Math.round((event.loaded / event.total) * 100);
                        break;
                    case HttpEventType.Response:
                        this.uploadInProgress = false;
                        this.discardFromUploadingFiles(uploadedFile);
                        this.showMiniBar = false;
                        this.mediaFiles[this.mediaFiles.findIndex((f) => f.unique === uploadedFile.unique)].mediaId =
                            event.body.id;
                        this.mediaFiles[
                            this.mediaFiles.findIndex((f) => f.unique === uploadedFile.unique)
                        ].uploadSuccess = true;
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Jioconf Cloud video Inside Event',
                                eventAction: `Upload`,
                                eventType: 'app_event',
                                endpoint: 'media',
                                status: 'success'
                            })
                            .subscribe();
                        break;
                }
            })),
            (err) => {
                this.uploadInProgress = false;
                this.toastrService.error(
                    err.error.errors || this.translateService.instant('webinar-tostrs.went_wrong_msg')
                );
            };
    }

    discardFromUploadingFiles(uploadedFile) {
        this.uploadingFiles = this.uploadingFiles.filter((f) => f.unique !== uploadedFile.unique);
    }

    confirmCancelUpload(file) {
        this.confirmationService.confirm({
            message: `
            <span class="uplod-cancel-container">
             <span class="cancelfile-header">Uploading in progress</span>
             <span class="cancel-txt">Closing this window will stop the current upload. Do you still want to close the window?</span>
            </span>
            `,
            // header: 'Cancel Upload',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            acceptLabel: 'Yes',
            rejectLabel: `No`,
            accept: () => {
                this.cancelUpload(file);
            }
        });
    }

    cancelUpload(file) {
        this.uploadInProgress = false;
        this.uploadingFiles$[file.unique].unsubscribe();
        this.mediaFiles.splice(
            this.mediaFiles.findIndex((f) => file.unique === f.unique),
            1
        );
    }

    deleteMediaFile(file) {
        //file = this.mediaFileDelete;
        this.confirmationService.confirm({
            header: 'Delete Video?',
            message: 'Are you sure you want to delete the video?',
            acceptLabel: 'Delete',
            rejectLabel: "Don't Delete",
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            accept: () => {
                this.webinarConfService.deleteMediaFile(file.mediaId).subscribe(
                    (res) => {
                        this.mediaFiles.splice(
                            this.mediaFiles.findIndex((f) => file.mediaId === f.mediaId),
                            1
                        );
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Jioconf Cloud video Inside Event',
                                eventAction: `Delete`,
                                eventType: 'app_event',
                                endpoint: 'media',
                                status: 'success'
                            })
                            .subscribe();
                    },
                    (err) => {
                        this.toastrService.error(
                            err.error.errors || this.translateService.instant('webinar-tostrs.went_wrong_msg')
                        );
                    }
                );
            }
        });
    }
    ngOnDestroy() {
        Object.keys(this.uploadingFiles$).map((s) => {
            this.uploadingFiles$[s].unsubscribe();
        });
    }
}
