import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { RestService } from './rest.service';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class RCChatService {
    token;

    constructor(private restService: RestService, private appService: AppService) {}

    login() {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/rc/login`)
            .pipe(tap((res) => (this.token = res.loginToken)));
    }

    getRCThreadId(jiomeetId) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/rc/getrcthread`, {
            jiomeetId
        });
    }

    getToken() {
        return this.token;
    }
}
