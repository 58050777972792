<div class="livestream-controls-wrapper-component">
    <div
        class="livestream-icon"
        [class.active]="showLiveStreamPopUp"
        (click)="toggle()"
        pTooltip="Add New Stream"
        tooltipPosition="top"
        tooltipStyleClass="tooltip-revamp"
        id="livestreamToggleBtn"
    ></div>
    <div class="livestream-options-wrapper" *ngIf="showLiveStreamPopUp">
        <div class="livestream-container-header">
            <img
                class="mr-4 pointer"
                *ngIf="showStreamDetailsForm && streamingList.length !== 0"
                src="assets/images/livestream/chevron_left_mint.svg"
                (click)="discardFormPopUp()"
            />
            <div>
                <div *ngIf="showStreamDetailsForm">
                    {{ showStreamDetailsForm && selectedStream ? 'Edit Stream' : 'Add New Stream' }}
                </div>
                <div *ngIf="!showStreamDetailsForm">Live Streaming</div>
                <div>Go live on the popular streaming platforms</div>
            </div>
            <img
                id="toggleCloseBtn"
                class="pointer ml-auto"
                src="assets/images/livestream/close_mint_icon.svg"
                (click)="toggle()"
            />
        </div>
        <ng-container [ngTemplateOutlet]="showStreamDetailsForm ? creationForm : viewStreams"></ng-container>
        <div class="livestream-container-footer" *ngIf="showStreamDetailsForm">
            <button id="toggleCancelBtn" class="bg-login-invert" (click)="discardFormPopUp('cancel')">Cancel</button>
            <button
                class="bg-login"
                [class.disabled]="!liveStreamCreationForm.valid || liveStreamCreationForm.pristine"
                (click)="submitLiveStreamForm()"
                id="{{ selectedStream ? 'Update' : 'Add' }}_ToggleBtn"
            >
                {{ selectedStream ? 'Update' : 'Add' }} Stream
            </button>
        </div>
        <div class="livestream-view-list-footer" *ngIf="!showStreamDetailsForm">
            <button
                class="bg-login-invert d-flex align-items-center"
                [class.disabled]="false"
                (click)="addNewStreamForm()"
                id="add-new-stream-btn"
            >
                <img src="assets/images/speaker-call-wrapper/ic_add_stream.svg" class="mr-2" />
                Add Stream
            </button>
        </div>
    </div>
</div>

<ng-template #creationForm>
    <div class="livestream-container-body details-form" [formGroup]="liveStreamCreationForm">
        <div>Streaming Platform*</div>
        <p-dropdown
            [options]="liveStreamingPlatforms"
            placeholder="Select an option"
            formControlName="streamPlatform"
            panelStyleClass="category-panel"
            optionValue="name"
            optionLabel="name"
            id="streaming-platform-dropdown-btn"
        >
            <ng-template pTemplate="selectedItem" let-item>
                {{ item?.name }}
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div id="{{ item?.name }}-stream-btn">
                    <img [src]="item.iconUrl" [ngStyle]="{ 'margin-right': '12px' }" />
                    <span>{{ item?.name }}</span>
                </div>
            </ng-template>
        </p-dropdown>
        <!-- <div>Stream name <span class="je-red-asterisk">*</span></div>
        <input type="text" class="text-left" id="customTitle" formControlName="streamName" placeholder="Type Here" /> -->
        <div>Streaming URL <span class="je-red-asterisk">*</span></div>
        <input type="text" class="text-left" id="serverUrl" formControlName="serverUrl" placeholder="Enter Here" />
        <div>Streaming Key <span class="je-red-asterisk">*</span></div>
        <input type="text" class="text-left" id="streamKey" formControlName="streamKey" placeholder="Enter Here" />
        <div>Live Streaming Page URL</div>
        <input
            type="text"
            class="text-left"
            id="streamPageUrl"
            formControlName="streamPageUrl"
            placeholder="Enter Here"
        />
    </div>
</ng-template>

<ng-template #viewStreams>
    <div class="livestream-container-body stream-list">
        <div class="stream-list-item" *ngFor="let stream of streamingList; let i = index">
            <div class="stream-row">
                <div class="stream-item-bar-wrapper w-100">
                    <img
                        [src]="'assets/images/livestream/' + stream?.streamPlatform + '_listIcon.svg'"
                        [ngStyle]="{ 'margin-right': '12px' }"
                        height="16"
                        width="16"
                    />
                    <span>{{ stream.streamName || stream.customTitle || stream.title }}</span>
                    <img
                        src="assets/images/speaker-call-wrapper/chevron_up_arrow_call_controls.svg"
                        class="dropdown-toggle-chevron pointer"
                        (click)="toggleStreamInfo(i)"
                    />
                    <div class="live-stream-indicator" *ngIf="stream?.streaming">
                        <img src="assets/images/livestream/custom platform_listIcon.svg" />
                        Live
                    </div>
                </div>
                <img
                    class="mr-4 pointer"
                    src="assets/images/networking-rooms/delete_green_icon.svg"
                    alt="Delete Stream"
                    (click)="confirmDeleteStream(stream)"
                    id="delete-stream-btn"
                />
                <img
                    src="assets/images/livestream/ic_edit_pen.svg"
                    id="edit-stream-btn"
                    class="mr-4 pointer"
                    (click)="editStream(stream)"
                />
                <button
                    class="bg-login-invert no-border pointer"
                    id="{{ stream?.streaming ? 'stop-stream-btn' : 'start-stream-btn' }}"
                    (click)="handleStreamState(stream)"
                >
                    {{ stream?.streaming ? 'Stop' : 'Start' }}
                </button>
            </div>
            <div class="stream-info-dropdown" *ngIf="stream?.showStreamInfo">
                <div>
                    <span>Platform:</span>
                    <span>{{ stream.streamPlatform | titlecase }}</span>
                </div>
                <hr />
                <div>
                    <span>Streaming URL:</span>
                    <span>{{ stream.serverUrl }}</span>
                </div>
                <hr />
                <div>
                    <span>Page URL:</span>
                    <span>{{ stream.streamPageUrl }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>
