import { Injectable } from '@angular/core';
import { JMBitrateTest, JMRTCTest } from '@jiomeet/jm-test-rtc';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/core';

export const TEST_STATUS = {
    NOTCHECKED: 0,
    PASS: 1,
    FAILED: 2
};
@Injectable({
    providedIn: 'root'
})
export class PreCallTestingService {
    serverDetails;
    isOnboardingScreenLoaded = false;
    micTestStatus = TEST_STATUS.NOTCHECKED;
    speakerTestStatus = TEST_STATUS.NOTCHECKED;
    cameraTestStatus = TEST_STATUS.NOTCHECKED;
    udpTestStatus = TEST_STATUS.FAILED;
    tcpTestStatus = TEST_STATUS.FAILED;
    performanceResult;
    bitrateResult;
    private eventSubject = new Subject<string>();
    preCallTestError = false;

    constructor(private appService: AppService) {
        this.serverDetails = this.appService.getConfigVariable('TEST_RTC_DETAILS');
    }

    async startRTCTest() {
        try {
            if (!this.serverDetails) {
                return;
            }
            const JMRTCTestInstance = new JMRTCTest({
                duration: 10000,
                secretKey: this.serverDetails.mediaKey,
                serverUrl: this.serverDetails.mediaServerUrl
            });
            this.performanceResult = await JMRTCTestInstance.startTest();
            this.preCallTestError = false;
        } catch (error) {
            this.preCallTestError = true;
            this.emitEvent('Destroy');
        }
    }

    async startBitrateTest() {
        if (!this.serverDetails) {
            return;
        }
        const JMRTCTestInstance = new JMBitrateTest({
            duration: 10000,
            secretKey: this.serverDetails.mediaKey,
            serverUrl: this.serverDetails.mediaServerUrl
        });
        this.bitrateResult = await JMRTCTestInstance.startTest();
    }

    restartTest() {
        this.bitrateResult = undefined;
        this.performanceResult = undefined;
        this.micTestStatus = TEST_STATUS.NOTCHECKED;
        this.speakerTestStatus = TEST_STATUS.NOTCHECKED;
        this.cameraTestStatus = TEST_STATUS.NOTCHECKED;
        this.udpTestStatus = TEST_STATUS.FAILED;
        this.tcpTestStatus = TEST_STATUS.FAILED;
        this.startRTCTest();
        this.startBitrateTest();
        this.emitEvent('Restart');
    }

    resetTest() {
        this.isOnboardingScreenLoaded = false;
        this.bitrateResult = undefined;
        this.performanceResult = undefined;
    }

    emitEvent(data: string) {
        this.eventSubject.next(data);
    }
    getEvent() {
        return this.eventSubject.asObservable();
    }

    get deviceTestImg() {
        if (
            this.micTestStatus === TEST_STATUS.NOTCHECKED ||
            this.cameraTestStatus === TEST_STATUS.NOTCHECKED ||
            this.speakerTestStatus === TEST_STATUS.NOTCHECKED
        ) {
            return 'warning';
        } else if (
            this.micTestStatus === TEST_STATUS.FAILED ||
            this.cameraTestStatus === TEST_STATUS.FAILED ||
            this.speakerTestStatus === TEST_STATUS.FAILED
        ) {
            return 'failed';
        } else {
            return 'success';
        }
    }

    get connectivityTestImg() {
        if (this.udpTestStatus === TEST_STATUS.FAILED && this.tcpTestStatus === TEST_STATUS.FAILED) {
            return 'failed';
        } else if (this.udpTestStatus === TEST_STATUS.PASS && this.tcpTestStatus === TEST_STATUS.PASS) {
            return 'success';
        } else {
            return 'warning';
        }
    }

    get performanceTestImg() {
        if (this.performanceResult) {
            return 'success';
        } else {
            return 'failed';
        }
    }
}
