export const WEBINAR_ATTENDEE_SOCKET_EVENTS = {
    LIKES_COUNT: 'likesCount',
    VIEWS_COUNT: 'viewsCount',
    NEW_QUES: 'qna',
    QUES_ANSWERED: 'quesAnswered',
    QUES_REJECTED: 'quesRejected',
    TICKER: 'ticker',
    QUES_ANSWER_LIVE: 'quesAnswerLive',
    WEBINAR_STARTED: 'webinarstarted',
    WEBINAR_ENDED: 'webinarended',
    WEBINAR_STOPPED: 'webinarstopped',
    TIMER: 'attendeeTimer',
    MEDIA_SHARE: 'servermediashare',
    MEDIA_SHARE_SYNC: 'servermediashareSync',
    WEBINAR_BROADCAST_ERROR: 'webinarbroadcasterror',
    FLASH_CARDS: 'flashcards'
};
