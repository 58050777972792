import { Component, ComponentFactoryResolver, ComponentRef, Injectable, Type } from '@angular/core';
// import { PrivateChatComponent } from 'src/app/conference/components/chat-related';
import { HostDirective } from 'src/app/shared/directives/host.directive';

@Injectable({
    providedIn: 'root'
})
export class ComponentFactoryService {
    private privateChatRefs: ComponentRef<any>[] = [];

    constructor(private factoryResolver: ComponentFactoryResolver) {}
    public get privateChatInstances() {
        return this.privateChatRefs;
    }
    public updateInstanceCount(instance: ComponentRef<any>) {
        this.privateChatRefs.push(instance);
    }
    public destroyAllPrivateChatInstances() {
        this.privateChatRefs.map((instance) => instance.destroy());
    }
    public generateComponent(viewHost: HostDirective, component: Type<any>) {
        return viewHost.viewContainerRef.createComponent(this.factoryResolver.resolveComponentFactory(component));
    }
}
