<div class="pmisettings-container">
    <form class="custom-form" [formGroup]="form" (submit)="save()">
        <div class="form-group">
            <label>{{ 'user_dashboard_settings.pmi.personal_meeting_id' | translate }}</label>
            <input
                type="text"
                maxlength="100"
                placeholder="{{ 'user_dashboard_settings.pmi.personal_meeting_id' | translate }}"
                [value]="user.jiomeetId | meetingidFormat"
                readonly
            />
        </div>
        <div class="form-group">
            <label>{{ 'user_dashboard_settings.pmi.password' | translate }}</label>
            <div class="d-flex align-items-center">
                <input
                    placeholder="{{ 'user_dashboard_settings.pmi.password' | translate }}"
                    type="text"
                    [(ngModel)]="user.roomPIN"
                    [ngModelOptions]="{ standalone: true }"
                    maxlength="5"
                />
                <div class="ml-3 pointer" (click)="refreshRoomPin()">
                    <img class="pointer" src="assets/img/pin_refresh_icn.svg" alt="" />
                </div>
            </div>
        </div>
        <!-- <hr class="divider mt-4 mb-4" />
            <h3 class="sub-heading mb-3">Video</h3>
            <div class="form-group d-flex">
              <div class="w-50 pr-4">
                <label>Host</label>
                <div class="d-flex justify-content-start my-3">
                  <label for="hostVideoOn" class="pointer">
                    <input type="radio" formControlName="hostVideo" name="hostVideo" id="hostVideoOn" [value]="true" />
                    <span>On</span>
                  </label>
                  <label for="hostVideoOff" class="ml-3 pointer">
                    <input type="radio" formControlName="hostVideo" name="hostVideo" id="hostVideoOff" [value]="false" />
                    <span>Off</span>
                  </label>
                </div>
              </div>
              <div class="w-50 pr-4">
                <label>Participants</label>
                <div class="d-flex justify-content-start my-3">
                  <label for="participantsVideoOn" class="pointer">
                    <input type="radio" formControlName="participantVideo" name="participantVideo" id="participantsVideoOn" [value]="true" />
                    <span>On</span>
                  </label>
                  <label for="participantsVideoOff" class="ml-3 pointer">
                    <input type="radio" formControlName="participantVideo" name="participantVideo" id="participantsVideoOff" [value]="false" />
                    <span>Off</span>
                  </label>
                </div>
              </div>
            </div> -->
        <hr class="divider mt-4 mb-4" />
        <h4
            class="sub-heading mb-3 collapsible"
            [class.collapsed]="advancedOptionsCollapsed"
            (click)="toggleAdvancedOptions()"
        >
            {{ 'planmeeting_popup.meeting_settings' | translate }}
        </h4>
        <div *ngIf="!advancedOptionsCollapsed" class="ml-3">
            <label>{{ 'planmeeting_popup.meeting_security' | translate }}</label>
            <div class="checkbox-wrapper" *ngIf="!user?.isOTT">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultUnchecked4"
                        formControlName="colleagueOnly"
                    />
                    <label class="custom-control-label pointer" for="defaultUnchecked4"
                        >{{ 'planmeeting_popup.myorganization' | translate }}
                    </label>
                </div>
                <div class="help">{{ 'planmeeting_popup.myorganizationhelp' | translate }}</div>
            </div>
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultUnchecked2"
                        formControlName="loggedInOnly"
                    />
                    <label class="custom-control-label pointer" for="defaultUnchecked2">{{
                        'planmeeting_popup.noguest' | translate
                    }}</label>
                </div>
                <div class="help">{{ 'planmeeting_popup.noguesthelp' | translate }}</div>
            </div>
            <label>{{ 'planmeeting_popup.advancedoptions' | translate }}</label>
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultUnchecked3"
                        formControlName="waitingRoom"
                    />
                    <label class="custom-control-label pointer" for="defaultUnchecked3">{{
                        'planmeeting_popup.enablewaitingroom' | translate
                    }}</label>
                </div>
                <div class="help">{{ 'planmeeting_popup.admit' | translate }}</div>
            </div>
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultUnchecked5"
                        formControlName="joinBeforeHost"
                    />
                    <label class="custom-control-label pointer" for="defaultUnchecked5">{{
                        'planmeeting_popup.allowbeforehost' | translate
                    }}</label>
                </div>
                <div class="help">{{ 'planmeeting_popup.allowbeforehosthelp' | translate }}</div>
            </div>
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultUnchecked6"
                        formControlName="participantHardAudioMute"
                    />
                    <label class="custom-control-label pointer" for="defaultUnchecked6">{{
                        'planmeeting_popup.forcemute' | translate
                    }}</label>
                </div>
                <div class="help">{{ 'planmeeting_popup.forcemutehelp' | translate }}</div>
            </div>
            <!-- <div class="checkbox-wrapper">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="defaultUnchecked7" formControlName="participantAudio" />
          <label class="custom-control-label pointer" for="defaultUnchecked7">Soft mute participants on entry</label>
        </div>
        <div class="help">Participants are muted when they join the meeting, but they can un-mute themselves.</div>
      </div> -->
        </div>

        <div class="text-error my-3" *ngIf="errors.global">{{ errors.global }}</div>

        <div class="form-group d-flex justify-content-end mt-5 mr-5">
            <!-- <button type="button" class="custom-button bg-secondary mr-3" (click)="navigateToDashboard()">Cancel</button> -->
            <button
                type="submit"
                class="custom-button bg-primary"
                [class.disabled]="!form.valid || user?.roomPIN?.length !== 5"
            >
                {{ 'user_dashboard_settings.save' | translate }}
            </button>
        </div>
    </form>
</div>
