import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventEmitterService } from 'src/app/core/services';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() mediaFile: string = ''; // Default value
    @Input() shouldAutoPlay: boolean = true;
    @Input() playInLoop: boolean = true;
    @Input() sourceComponent: string = '';
    @Input() isAutoJoin: boolean = false;
    @Input() shouldMute: boolean = false;

    mediaContentId: string = '';
    player: any;
    muteSubscription: any;

    constructor(private emitterService: EventEmitterService) {
        this.muteSubscription = this.emitterService.subscribe((event) => {
            if (event.type === 'unmuteVideoPlayer' && this.player) {
                this.player.unmute();
                this.player.play();
            }
        });
    }

    ngOnInit(): void {
        this.mediaContentId = 'video-media-content-' + `${this.sourceComponent || ''}`;
    }

    async ngAfterViewInit() {
        if (this.mediaFile) {
            const mediaFileUrl = `${this.mediaFile}&ngsw-bypass=true`;
            await this.initializePlayer(mediaFileUrl);
        } else {
            console.error('No media file provided');
        }
    }

    async initializePlayer(sourceUrl: string) {
        try {
            const Clappr: any = await import('clappr');
            this.player = new Clappr.default.Player({
                source: sourceUrl,
                parentId: `#${this.mediaContentId}`,
                persistConfig: true,
                allowUserInteraction: true,
                border: '2px solid yellow',
                maxBufferLength: 5,
                mimeType: 'video/mp4',
                playInline: true,
                playbackNotSupportedMessage: 'Please try on a different browser',
                mute: this.shouldMute,
                autoPlay: this.shouldAutoPlay,
                loop: this.playInLoop,
                events: {
                    onEnded: () => {
                        console.log('Video playback ended');
                    },
                    onError: (error: any) => {
                        console.error('Playback error:', error);
                    }
                }
            });
        } catch (error) {
            console.error('Error loading Clappr player:', error);
        }
    }

    ngOnDestroy() {
        if (this.player) {
            this.player.stop();
            this.player.destroy();
        }
        if (this.muteSubscription) {
            this.muteSubscription.unsubscribe();
        }
    }
}
