<div
    class="profile-detail"
    [class.isMobile]="utilService.isMobileView"
    [class.is-old-version]="utilService.isOldVersion && utilService.showRibbon && !isDashboard"
>
    <div class="my-profile d-flex justify-content-between">
        <p>{{ title }}</p>
        <img
            class="img pointer"
            id="closeProfileButton"
            src="assets/img/jioconf/profile-close-icon.svg"
            (click)="closeProfile('close')"
        />
    </div>
    <div *ngIf="showform" class="details-container">
        <div class="profile-pic d-flex">
            <div class="profile-image d-flex">
                <div class="img-container">
                    <div class="img-wrap" (mouseover)="isHovered = true" (mouseleave)="isHovered = false">
                        <img class="imageCropped" [src]="profilePicPath" alt="" *ngIf="profilePicPath" />
                        <div *ngIf="!(isMobileDevice || isIpad)">
                            <ng-container *ngTemplateOutlet="profileButtons"></ng-container>
                        </div>
                    </div>
                    <button class="custom-button bg-secondary plan-secondary" *ngIf="eventsProStatus">
                        {{ eventsPlanName }}
                    </button>
                    <div *ngIf="isMobileDevice || isIpad">
                        <ng-container *ngTemplateOutlet="profileButtons"></ng-container>
                    </div>
                </div>
            </div>
            <div class="username d-flex flex-column justify-content-center text-truncate">
                <div class="login-name">{{ currentUser?.name || '' }} {{ currentUser?.lname || '' }}</div>
                <div class="login-email">
                    {{ currentUser?.email }}
                </div>
                <div class="profile-designation">
                    {{ currentUser?.jobTitle }} <span *ngIf="currentUser?.department">@</span>
                    {{ currentUser?.department }}
                </div>
                <div class="social-icon">
                    <ng-container *ngFor="let socialMedia of socialMediaAdded; let i = index">
                        <div class="{{ socialMedia }} social" (click)="openSocialMediaLink(socialMedia)"></div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div
            class="edit-profile"
            *ngIf="(!isMobileDevice && !isIpad) || (isIpad && isLandscapeMode && !utilService.isMobileView)"
            [class.ipad-landscape-edit-profile]="isIpad && isLandscapeMode"
        >
            <button
                class="Profile-btn d-flex flex-row justify-content-center align-items-center"
                id="editProfileButton"
                (click)="change()"
            >
                Edit Profile
            </button>
        </div>
        <hr />
        <ng-container *ngIf="!(this.interests.length == 0)">
            <div class="my-intrests">
                <div class="intrests">My Interests</div>
                <div class="icons d-flex flex-wrap justify-content-start">
                    <div class="d-flex align-items-center interest-buttons" *ngFor="let interest of interests">
                        <img src="assets/img/jioconf/interest-icons/{{ interest }}.svg" alt="{{ interest }}" />
                        <span class="title">{{ interest }}</span>
                    </div>
                </div>
            </div>
            <hr />
        </ng-container>

        <div class="here" *ngFor="let option of options">
            <div *ngIf="option.type == selectedPurpose">
                <img class="mr-2" src="../assets/img/jioconf/purpose.svg" alt="" />
                {{ option.title }}
            </div>
        </div>
        <div class="pro" *ngIf="eventsProStatus">
            <div class="profile-edit">
                <div class="profile-details">
                    <div class="activePlan">Active Plan</div>
                    <div class="eventsPlanName">{{ eventsPlanName }}</div>
                    <div class="expiresOn">Expires on {{ expiresOn | date: 'dd-MMM-yyyy' }}</div>
                    <!-- <div class="btn-wrapper d-flex">
                        <div>
                            <a href="/pricing" class="renew">Renew</a>
                        </div>
                        <div>
                            <button class="events-btn" (click)="navigateToDashboard()">
                                <div class="img-wrapper"></div>
                                Upgrade Plan
                            </button>
                        </div>
                    </div> -->
                    <div class="invoice">
                        <!-- <img src="assets/img/jioconf/invoice.svg" alt="" (click)="downloadInvoice()" /> -->
                        <img
                            src="assets/img/jioconf/viewInvoice.svg"
                            *ngIf="!showInvoices"
                            alt=""
                            (click)="toggleInvoices()"
                        />
                        <div *ngIf="showInvoices" class="invoice-list">
                            <div class="invoice-item" *ngFor="let invoice of invoices">
                                <div class="d-flex justify-content-between">
                                    <div class="invoice-name">Invoice_{{ invoice.cOn | date: 'dd-MMM-yyyy' }}</div>
                                    <img
                                        src="assets/img/jioconf/download.svg"
                                        (click)="downloadInvoiceById(invoice._id)"
                                    />
                                </div>
                                <div class="borders"></div>
                            </div>
                        </div>
                        <div class="hideInvoice">
                            <img
                                src="assets/img/jioconf/hideInvoice.svg"
                                *ngIf="showInvoices"
                                alt=""
                                (click)="toggleInvoices()"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="edit-profile"
        [class.mobile-edit-profile]="isMobileDevice || (isIpad && !isLandscapeMode)"
        *ngIf="showform && ((isMobileDevice && !isIpad) || (isIpad && utilService.isMobileView))"
    >
        <button
            class="Profile-btn d-flex flex-row justify-content-center align-items-center"
            id="editProfileButtonMobile"
            (click)="change()"
        >
            <img style="margin-right: 5px" src="assets/img/jioconf/edit-profile-icon.svg" alt="" />
            Edit Profile
        </button>
    </div>
    <div *ngIf="!showform" class="d-flex justify-content-between flex-column profile-container">
        <div class="form-wrapper">
            <div class="tabs d-flex justify-content-around">
                <div
                    class="profile"
                    (click)="changeprofile('profile')"
                    id="myProfileDetails"
                    [ngClass]="{ selected: selectedTab === 'profile' }"
                >
                    <img
                        class="mr-2"
                        src="{{
                            selectedTab === 'profile'
                                ? '../assets/img/jioconf/profile-icon-active.svg'
                                : 'assets/img/jioconf/profile-icon-inactive.svg'
                        }}"
                        alt=""
                        *ngIf="!(isMobileDevice || isIpad)"
                    />My Profile
                </div>
                <div
                    class="intrests"
                    id="myInterestsDetails"
                    (click)="changeprofile('interests')"
                    [ngClass]="{ selected: selectedTab === 'interests' }"
                >
                    <img
                        class="mr-2"
                        src="{{
                            selectedTab === 'interests'
                                ? 'assets/img/jioconf/favourite_active.svg'
                                : 'assets/img/jioconf/favourite-icon-inactive.svg'
                        }}"
                        alt=""
                        *ngIf="!(isMobileDevice || isIpad)"
                    />
                    My Interests
                </div>
            </div>
            <form class="custom-form scroll-area" *ngIf="showprofile" [formGroup]="form">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="label-required" for="inputEmail4">First Name</label>
                        <input
                            type="text"
                            class="form-control input"
                            id="inputEmail4"
                            placeholder="First Name"
                            formControlName="name"
                            [readonly]="!currentUser?.isOTT"
                            maxlength="50"
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label class="label-required" for="inputPassword4">Last Name</label>
                        <input
                            type="text"
                            class="form-control input"
                            id="inputPassword4"
                            placeholder="Last Name"
                            formControlName="lname"
                            [readonly]="!currentUser?.isOTT"
                            maxlength="50"
                        />
                    </div>
                </div>
                <div class="form-group" *ngIf="currentUser?.email">
                    <label for="email"> Email ID </label>
                    <input
                        type="text"
                        id="email"
                        placeholder="Enter Email Address"
                        maxlength="50"
                        formControlName="email"
                        [readonly]="true"
                    />
                </div>
                <div class="form-group" *ngIf="!currentUser?.email && currentUser?.phoneNo">
                    <label for="phone"> Phone No </label>
                    <input
                        type="text"
                        id="phone"
                        placeholder="Enter Phone No"
                        formControlName="phoneNo"
                        [readonly]="true"
                    />
                </div>
                <div class="form-group">
                    <label for="designation"> Designation </label>
                    <input
                        type="text"
                        id="designation"
                        placeholder="Enter Designation"
                        maxlength="50"
                        formControlName="jobTitle"
                        [class.is-invalid]="errors?.jobTitle"
                    />
                    <span class="text-error" *ngIf="errors?.jobTitle">{{ errors?.jobTitle }}</span>
                </div>
                <div class="form-group">
                    <label for="org_name"> Organisation Name </label>
                    <input
                        type="text"
                        id="org_name"
                        placeholder="Enter Organisation Name"
                        maxlength="50"
                        formControlName="department"
                        [class.is-invalid]="errors?.department"
                    />
                    <span class="text-error" *ngIf="errors?.department">{{ errors?.department }}</span>
                </div>
                <app-host-social-media-links
                    [socialLinks]="currentUser?.socialMediaLinks"
                    (socialMediaFormValue)="handleSocialMediaFormValue($event)"
                    (changesInProgressBoolean)="handlechangesInProgress($event)"
                >
                </app-host-social-media-links>
                <label class="label-required"> What are you here for? </label>
                <div
                    class="option-list"
                    *ngFor="let purpose of options; let i = index"
                    [class.selected]="purpose.type === selectedPurpose"
                    [id]="'selectPurpose_' + i"
                    (click)="selectPurpose(purpose)"
                >
                    <div class="option-content align-items-center">
                        <p>{{ purpose.title }}</p>
                        <img
                            *ngIf="purpose.type === selectedPurpose"
                            class="tick_icon"
                            src="assets/img/selected_tick.svg"
                            alt="Tick Icon"
                        />
                    </div>
                </div>
            </form>
            <div *ngIf="!showprofile" class="scroll-area">
                <section class="interest-section">
                    <div class="d-flex justify-content-start flex-wrap">
                        <div
                            class="d-flex align-items-center interest-buttons"
                            *ngFor="let interest of interestList"
                            [class.selected]="interests.includes(interest)"
                            [id]="'selectInterestButton_' + interest"
                            (click)="selectInterest(interest)"
                        >
                            <img src="assets/img/jioconf/interest-icons/{{ interest }}.svg" alt="{{ interest }}" />
                            <span class="title">{{ interest }}</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div
            *ngIf="socialMediaForm"
            class="button-section d-flex justify-content-center"
            [class.interest-active]="!showprofile"
        >
            <button
                type=" d-flex align-items-center button "
                id="cancelProfileChanges"
                (click)="closeProfile('cancel')"
                class="cancel"
            >
                <span>Cancel</span>
            </button>
            <button
                type="button"
                class="d-flex align-items-center save"
                id="saveProfileButton"
                (click)="submitForm()"
                [class.disable]="
                    !(
                        selectedPurpose !== previousPurpose ||
                        checkDisabled ||
                        (socialMediaForm?.valid && changesInProgress) ||
                        form?.dirty
                    )
                "
            >
                <span>Save</span>
            </button>
        </div>
    </div>
    <ng-template #profileButtons>
        <span class="centered-span">
            <input
                #profileUpload
                type="file"
                hidden
                accept="image/*"
                (click)="profileUpload.value = null"
                (change)="fileChangeEvent($event.target.files[0])"
            />
            <div class="profile-buttons" *ngIf="isHovered && !(isMobileDevice || isIpad)">
                <button class="pointer" (click)="profileUpload.click()">
                    <img
                        style="height: 24px; width: 24px"
                        [src]="
                            editButton
                                ? 'assets/img/jioconf/interest-icons/design.svg'
                                : 'assets/img/jioconf/interest-icons/photography.svg'
                        "
                        alt=""
                    />
                    {{ editButton ? '' : 'Add' }}
                </button>
                <button *ngIf="editButton" class="pointer" (click)="deletePicture()">
                    <img src="assets/img/jioconf/trash-icon.svg" alt="" />
                </button>
            </div>
            <div
                class="profile-buttons"
                *ngIf="isMobileDevice || isIpad"
                [class.ipad-landscape-profilebtn]="isIpad && isLandscapeMode"
            >
                <button class="pointer" (click)="profileUpload.click()" [id]="editButton ? 'editBtn' : 'addBtn'">
                    <img
                        style="height: 16px; width: 16px"
                        [src]="
                            editButton
                                ? 'assets/img/jioconf/interest-icons/design.svg'
                                : 'assets/img/jioconf/interest-icons/photography.svg'
                        "
                        alt=""
                    />
                    {{ editButton ? '' : 'Add' }}
                </button>
                <button *ngIf="editButton" class="pointer" (click)="deletePicture()" id="delete_pic">
                    <img style="height: 16px" src="assets/img/jioconf/trash-icon.svg" alt="" />
                </button>
            </div>
        </span>
    </ng-template>
</div>

<div class="popup-component" *ngIf="showCropper">
    <div class="bg"></div>
    <div class="popup">
        <div class="cropper-header d-flex justify-content-between">
            <div class="cropper-heading">
                <img src="" alt="" />
                Crop Image
            </div>
            <div class="cropper-close-btn pointer" (click)="toggleCropper()">
                <img src="assets/img/close_icon_green.svg" alt="" />
            </div>
        </div>
        <image-cropper
            [imageFile]="imageChangedEvent"
            [aspectRatio]="1 / 1"
            (imageCropped)="imageCropped($event)"
            format="png"
            [maintainAspectRatio]="true"
            [roundCropper]="true"
            [resizeToWidth]="256"
            [cropperMinWidth]="128"
            [transform]="transform"
            (imageLoaded)="imageLoaded()"
        ></image-cropper>

        <div class="zoom d-flex" *ngIf="!isMobileDevice">
            <img (click)="zoomOut()" src="assets/images/minus.svg" alt="" />
            <input
                class="mt-2"
                type="range"
                [(ngModel)]="scale"
                min="1"
                max="5"
                step="0.2"
                (input)="zoom($event)"
                id="range"
            />
            <img (click)="zoomIn()" src="assets/images/plus.svg" alt="" />
        </div>

        <div class="image-cropper-btn d-flex justify-content-end">
            <button class="discard-btn" btnText="Discard" id="discardProfilePic" (click)="toggleCropper()">
                Discard
            </button>
            <app-event-btn
                btnText="Save"
                [id]="saveProfilePic"
                (btnClick)="croppedFileEmitter()"
                [customClass]="btnCustomClass"
            >
            </app-event-btn>
        </div>
    </div>
</div>

<app-dialog
    headerText="Discard Changes?"
    (close)="showDiscardChangePopup = false"
    (acceptClick)="showDiscardChangePopup = false; handleAcceptDiscard(closeProfileFrom)"
    bodyText="The changes you've made will be discarded with this action. Do you want to continue?"
    acceptText="Yes"
    rejectText="No"
    *ngIf="showDiscardChangePopup"
>
</app-dialog>

<app-dialog
    headerText="Remove Profile Picture?"
    (close)="showDeleteProfilePicPopup = false"
    (acceptClick)="showDeleteProfilePicPopup = false; deleteProfilePic()"
    bodyText="Your current profile picture will be removed. Are you sure you want to remove the profile picture?"
    acceptText="Yes"
    rejectText="No"
    *ngIf="showDeleteProfilePicPopup"
>
</app-dialog>

<p-confirmDialog [transitionOptions]="'300ms'" acceptIcon="null" rejectIcon="null" key="myDialog"> </p-confirmDialog>
