import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';

import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
import { PRICING } from '../../../constants/gtm-events';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { Router } from '@angular/router';
import { oldPricingPlans } from 'src/app/constants/old-pricing-plans';

@Component({
    selector: 'app-pricing-o',
    templateUrl: './pricing-o.component.html',
    styleUrls: ['./pricing-o.component.scss']
})
export class PricingOComponent implements OnInit {
    loginPage: any = '/login';
    contactUsPage: any = '/contactus';
    individual = true;
    count = 1000;
    pricingPlans: any;
    pricePlanType: any = {
        lite: 'Lite',
        standard: 'Standard',
        premium: 'Premium'
    };
    constructor(
        private appService: AppService,
        private googleTagManager: GoogleTagMangerService,
        private router: Router
    ) {
        this.loginPage = this.appService.getLoginPageUrl();
    }

    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.PRICING_PAGE_VIEW);
        this.pricingPlans = oldPricingPlans;
    }

    toggleIndividual(plan: any) {
        this.individual = plan;
    }

    onNumberClick(attendees: any) {
        this.count = attendees;
    }

    navigateToContactUs(type, contactSale = false) {
        let eventLable = this.individual ? 'individual' : 'bundles';
        let eventGtmData: any;
        switch (type) {
            case this.pricePlanType.lite:
                eventGtmData = PRICING.LITE_PLAN;
                break;
            case this.pricePlanType.standard:
                eventGtmData = PRICING.STANDARD_PLAN;
                break;
            case this.pricePlanType.premium:
                eventGtmData = PRICING.PREMIUM_PLAN;
                break;
        }
        if (type == 'custom_plan' || contactSale) {
            this.googleTagManager.pushGoogleTagData(PRICING.CONTACT_SALES);
        } else {
            this.googleTagManager.pushGoogleTagData(Object.assign({}, eventGtmData, { event: eventLable }));
        }
        this.router.navigateByUrl('/contactus');
    }

    // TODO
    // need to add translations
    redirectToLogin() {
        window.location.href = this.loginPage;
    }
    redirectToContactUs() {
        window.location.href = this.contactUsPage;
    }
    getStartedNow() {
        this.googleTagManager.pushGoogleTagData(PRICING.GET_STARTED_FREE);
        this.redirectToContactUs();
    }
    exploreNow() {
        this.googleTagManager.pushGoogleTagData(PRICING.EXPLORE_NOW);
        this.redirectToLogin();
    }
}
