export const JIO_CLOUD = {
    SMALL_FILE_SIZE_LIMIT: 10 * 1024 * 1024,
    CHECKSUM_CALC_CHUNK_SIZE: 1024 * 1024,
    FIRST_CHUNK_SIZE: 128 * 1024,
    MAX_CHUNK_SIZE_IN_MB: 4,
    MAX_FILE_SIZE_ALLOWED_FOR_UPLOAD: 30 * 1024 * 1024
};

export const FILE_FORMATS = {
    AUDIO: ['.mp3', '.aa3', '.mpa', '.wav', '.mkv', '.ogg', '.flac', '.m4a', '.aac'],
    IMAGE: ['.tif', '.tiff', '.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.jfif'],
    VIDEO: ['.webm', '.mkv', '.flv', '.vob', '.avi', '.ogg', '.mp4', '.3gp', '.mov'],
    DOCUMENT: ['.doc', '.docx', '.odt'],
    EXCEL: ['.xlsx', '.csv', '.ods', '.xls'],
    PPT: ['.ppt', '.pptx', '.odp'],
    PDF: ['.pdf'],
    TXT: ['.txt']
};
