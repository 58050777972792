<div class="info-banner-popup d-flex justify-content-center">
    <div class="bubble d-flex" [class.error]="type != 'Success'">
        <div class="type d-flex justify-content-center align-items-center" *ngIf="type == 'Success'">
            <img src="assets/img/green-tick-functional-filled.svg" alt="" />
        </div>
        <div class="content" [class.error-content]="type != 'Success'">
            <div class="header">{{ headerText }}</div>
            <div class="topic" *ngIf="topic">“{{ topic }}”</div>
        </div>
        <div class="close d-flex justify-content-center align-items-center">
            <img src="assets/img/close_icn.svg" alt="" (click)="cancel()" />
        </div>
    </div>
</div>
