import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-video-result-modal',
    templateUrl: './video-result-modal.component.html',
    styleUrls: ['./video-result-modal.component.scss']
})
export class VideoResultModalComponent implements OnInit {
    @Input() cameraResult;
    @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {}
    closeVideoResultModal() {
        this.closeModalEvent.emit();
    }
}
