import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertHtmlToText'
})
export class ConvertHtmlToTextPipe implements PipeTransform {
    transform(value) {
        if (!value) {
            return '';
        }
        try {
            return new DOMParser().parseFromString(value, 'text/html').documentElement.textContent;
        } catch (err) {
            console.log('error parsing html', err);
            return '';
        }
    }
}
