<div [class.dashboard]="eventViewSection" class="fullheight" [class.isMobile]="utilService.isMobileView">
    <div
        (mouseleave)="dontShowCalendar()"
        class="event_card"
        [class.isMobile]="utilService.isMobileView"
        [class]="eventViewSection"
        tabindex="0"
        aria-label="event card"
    >
        <div (click)="gotoevent($event)" class="card_media" [id]="'jioEvents_' + eventinfo._id + '_' + id">
            <img
                appImagePlaceholder
                class="event_image"
                (imageLoadingStatus)="handleTileLoadingStatus($event)"
                [actualImageSrc]="eventCardImageUrl"
                alt="card image"
                [placeHolderImageSrc]="'./assets/img/event_banner_placeholder_potrait.svg'"
            />
            <!-- <div class="shade_area"></div> -->
            <ng-container *ngIf="hasCalIcon || hasFavIcon">
                <div class="action_area">
                    <ng-container *ngIf="hasCalIcon">
                        <span
                            (click)="addtocalender(); $event.stopPropagation()"
                            [id]="eventinfo._id + '_addToCal'"
                            [pTooltip]="!showCalendar ? 'Add to Calendar' : ''"
                            tooltipPosition="bottom"
                            showDelay="600"
                            [ngClass]="!isMobile ? 'calender' : 'opacity-1'"
                            class="calender"
                        >
                            <div class="calender-circle">
                                <img
                                    [src]="'assets/img/' + addToCalIcon + '.svg'"
                                    alt=""
                                    (mouseover)="addToCalIcon = 'add_to_cal_hover'"
                                    (mouseout)="addToCalIcon = 'add_to_cal'"
                                />
                            </div>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="hasFavIcon">
                        <span
                            pTooltip="Add to Favourites"
                            tooltipPosition="bottom"
                            showDelay="600"
                            [id]="eventinfo._id + '_addToFav'"
                            (click)="toggleFavourites(); $event.stopPropagation()"
                            [ngClass]="!isMobile ? 'favourite' : 'opacity-1'"
                            [class.opacity-1]="!!isFavouriteCard"
                        >
                            <div [class.favourite_bg]="!!isFavouriteCard" class="favourite-circle">
                                <img
                                    *ngIf="!isFavouriteCard"
                                    [src]="
                                        isMobile
                                            ? 'assets/img/add_to_favourite.svg'
                                            : 'assets/img/' + favouriteIcon + '.svg'
                                    "
                                    alt=""
                                    (mouseover)="favouriteIcon = 'add_to_favourite_hover'"
                                    (mouseout)="favouriteIcon = 'add_to_favourite'"
                                />
                                <img *ngIf="!!isFavouriteCard" src="assets/img/add_to_favourite_selected.svg" alt="" />
                            </div>
                        </span>
                    </ng-container>
                </div>
                <div *ngIf="showCalendar && !utilService.isMobileView">
                    <app-add-to-calendar
                        class="addtocal"
                        [alwaysShowSocialDropDown]="true"
                        [joinDetail]="eventinfo"
                        [showAddToCalBtn]="false"
                        (click)="dontShowCalendar(); $event.stopPropagation()"
                    ></app-add-to-calendar>
                </div>
            </ng-container>
            <app-event-card-date
                [joinDetail]="eventinfo"
                *ngIf="eventViewSection !== EventViewSection.CALENDARVIEW"
            ></app-event-card-date>

            <!-- //User Event Type -->
            <div
                *ngIf="eventViewSection === EventViewSection.CALENDARVIEW"
                class="user-event-type"
                [class.attending]="currentUser._id !== eventinfo.userId"
            >
                <span>{{
                    currentUser._id === eventinfo.userId || !eventinfo.isRegistered ? 'My Event' : 'Attending'
                }}</span>
            </div>
            <div
                class="option"
                *ngIf="rightMenuOptions?.length && !utilService.isMobileView && enableEventsReplay"
                (outside)="toggleClose()"
            >
                <div
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="img-container right-menu"
                    pTooltip="Event Options"
                    tooltipPosition="top"
                    [class.highlighted]="isHighlighted"
                    (click)="toggledotmenu()"
                    [id]="'cardDropdown_' + eventinfo._id"
                >
                    <img class="optionImg" src="/assets/img/dot_menu_icn_vertical.svg" alt="eventOption image" />
                </div>
                <!-- <div aria-haspopup="true" aria-expanded="false" class="img-container-mob d-flex align-items-center">
                    <div class="img-container-mobile"></div>
                    
                </div> -->
                <ng-container>
                    <div class="dropdown-menu">
                        <!-- Dropdown menu links -->
                        <ng-container [ngTemplateOutlet]="addToCalOptions"></ng-container>
                    </div>
                    <div class="dropdown-menu alwaysShow" *ngIf="rightMenuOptions?.length">
                        <!-- Dropdown menu links -->
                        <ng-container [ngTemplateOutlet]="addToCalOptions"></ng-container>
                    </div>
                </ng-container>
            </div>
            <div
                class="option"
                *ngIf="rightMenuOptions?.length && utilService.isMobileView && enableEventsReplay"
                (click)="openMyEventsMenuOptions($event)"
                [id]="'cardDropdown_' + eventinfo._id"
                [class.highlighted]="isHighlighted"
            >
                <img class="optionImg" src="/assets/img/dot_menu_icn_vertical.svg" alt="eventOption image" />
            </div>

            <ng-template #addToCalOptions>
                <app-dropdown-options
                    [id]="rightMenuOptions"
                    [options]="rightMenuOptions"
                    (onSelectedMenu)="handleActionMenuClick($event)"
                    [eventViewSection]="EventViewSection.PAST"
                    [eventId]="eventinfo._id"
                    [isMultiDay]="eventinfo.isMultiDay"
                    [isOngoing]="isOngoing"
                    [isAnalyticsEnabled]="isAnalyticsEnabled"
                ></app-dropdown-options>
                <!-- <a class="dropdown-item" (click)="editMeeting($event)">Edit</a>
                <a class="dropdown-item" (click)="previewEvent(); previewNavigate()">Preview</a>
                <a class="dropdown-item">Duplicate Event</a>
                <a class="dropdown-item" (click)="toggleMeetingSharePopup($event)">Share</a>
                <a class="dropdown-item" (click)="deleteMeeting($event)">Delete</a> -->
            </ng-template>
            <div class="d-flex justify-content-center">
                <div
                    class="card-details card-link d-flex flex-column"
                    [ngClass]="{ 'pre-recorded-container': isPrerecorded }"
                    (click)="$event.stopPropagation(); gotoevent($event)"
                    [class.explore]="!dashboard && !previousWebinar"
                >
                    <ng-container *ngIf="eventViewSection !== EventViewSection.CALENDARVIEW">
                        <span
                            *ngIf="!dashboard && !previousWebinar"
                            class="d-flex justify-content-between align-items-center event_type_wrapper"
                            ><span class="event_type text-truncate">{{
                                eventinfo.jioConf.eventType !== 'Custom'
                                    ? eventinfo.jioConf.eventType
                                    : (eventinfo.jioConf.customCategoryName | uppercase)
                            }}</span
                            ><span
                                ><img
                                    [src]="
                                        eventinfo.jioConf.publishType === 'Public'
                                            ? 'assets/img/public-category.svg'
                                            : eventinfo.jioConf.publishType === 'Private'
                                            ? 'assets/img/private-category.svg'
                                            : 'assets/img/organisation-category.svg'
                                    "
                                    alt="" /></span
                        ></span>
                        <ng-container *ngIf="eventViewSection === EventViewSection.UPCOMING">
                            <div
                                class="meeting-status default"
                                *ngIf="!isBefore(15) && isMeetingInFuture() && !ongoingCall"
                            >
                                {{
                                    'webinar-card.starts_in'
                                        | translate
                                            : {
                                                  value1: eventinfo?.countdown?.minutes | pad,
                                                  value2: eventinfo?.countdown?.seconds | pad
                                              }
                                }}
                            </div>
                            <div class="meeting-status" *ngIf="ongoingCall">
                                {{ 'webinar-card.ongoing' | translate }}
                            </div>
                        </ng-container>

                        <!-- <span class="publish_type" *ngIf="eventViewSection === EventViewSection.EXPLORE"
                            ><img
                                *ngIf="eventinfo.jioConf.publishType == 'Public'"
                                [src]="
                                    dashboard ? 'assets/img/globe_icon_dashboard.svg' : 'assets/img/explore-events.svg'
                                "
                                alt="" /><img
                                *ngIf="eventinfo.jioConf.publishType == 'Limited'"
                                [src]="
                                    dashboard
                                        ? 'assets/img/organisation_icon_dashboard.svg'
                                        : 'assets/img/website/organisation_icon.svg'
                                "
                                alt=""
                        /></span> -->
                    </ng-container>
                    <div
                        class="card_content d-flex flex-column flex-grow-1 justify-content-between"
                        *ngIf="tileBgImageLoaded"
                    >
                        <div
                            class="d-flex flex-column event-title-time"
                            [ngClass]="{
                                'date-position-down': !(!dashboard && !previousWebinar)
                            }"
                        >
                            <div
                                #markdown
                                (click)="$event.stopPropagation()"
                                [innerHTML]="markdowntext | sanitizeHtml"
                                class="event_title text-truncate"
                                pTooltip="{{ eventinfo.topic.length > 35 ? eventinfo.topic : '' }}"
                                tooltipPosition="{{ dashboard ? 'bottom' : 'top' }}"
                                tooltipStyleClass="{{ !dashboard ? 'white-bg-arrow' : '' }}"
                                showDelay="600"
                            ></div>
                            <!-- <span
                            *ngIf="dashboard"
                            class="dashboard_event_type"
                            pTooltip="{{ eventinfo.jioConf.eventType.length > 13 ? eventinfo.jioConf.eventType : '' }}"
                            tooltipPosition="{{ dashboard ? 'bottom' : 'top' }}"
                            tooltipStyleClass="{{ !dashboard ? 'white-bg' : '' }}"
                            showDelay="600"
                            >{{ eventinfo.jioConf.eventType | uppercase }}</span
                        > -->
                            <div>
                                <span
                                    class="event-timing"
                                    *ngIf="
                                        eventViewSection !== EventViewSection.CALENDARVIEW &&
                                        !eventinfo?.isMultiDay &&
                                        showDate
                                    "
                                    >{{ eventinfo.startTime | date: 'd MMM y' }} {{ !dashboard ? '•' : '|' }}
                                    {{ eventinfo.startTime | date: 'shortTime' }}
                                </span>
                                <span
                                    class="event-timing"
                                    *ngIf="
                                        eventViewSection !== EventViewSection.CALENDARVIEW &&
                                        !eventinfo?.isMultiDay &&
                                        showDate &&
                                        utilService.isMobileView
                                    "
                                >
                                    - {{ eventinfo.endTime | date: 'shortTime' }}</span
                                >
                                <span
                                    class="event-timing"
                                    *ngIf="
                                        eventViewSection !== EventViewSection.CALENDARVIEW &&
                                        eventinfo?.isMultiDay &&
                                        showDate
                                    "
                                >
                                    {{ eventinfo.startTime | date: 'd MMM y' }}
                                </span>
                                <span
                                    class="event-timing"
                                    *ngIf="
                                        eventViewSection !== EventViewSection.CALENDARVIEW &&
                                        eventinfo?.isMultiDay &&
                                        showDate &&
                                        !utilService.isMobileView
                                    "
                                >
                                    - {{ eventinfo.endTime | date: 'd MMM y' }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-end"
                            *ngIf="eventViewSection !== EventViewSection.CALENDARVIEW && !dashboard && !previousWebinar"
                        >
                            <span class="event_price">Free</span>
                            <span *ngIf="sliderName !== 'replay'" class="arrow-btn"></span>
                            <span *ngIf="sliderName == 'replay'" class="play-btn"> </span>
                        </div>
                    </div>
                    <div class="eventStartBtn mt-0" *ngIf="!(!dashboard && !previousWebinar)">
                        <button
                            class="custom-button mr-1 action-button bg-primary dark-theme"
                            [id]="'startButton_' + eventinfo?._id"
                            *ngIf="
                                allowStart &&
                                (eventViewSection === EventViewSection.UPCOMING ||
                                    eventViewSection === EventViewSection.CALENDARVIEW) &&
                                !utilService.isMobileView &&
                                !isPrerecorded
                            "
                            (click)="startMeeting($event, eventinfo)"
                        >
                            {{ 'webinar-card.start' | translate }}
                        </button>
                        <button
                            class="custom-button mr-1 action-button bg-primary dark-theme"
                            [id]="'joinButton_' + eventinfo._id"
                            *ngIf="
                                !allowStart &&
                                !isPrerecorded &&
                                (eventViewSection === EventViewSection.UPCOMING ||
                                    (!eventinfo.isRegistered && eventViewSection === EventViewSection.CALENDARVIEW)) &&
                                !utilService.isMobileView
                            "
                            (click)="startMeeting($event, eventinfo, 'join')"
                        >
                            {{ 'webinar-card.join' | translate }}
                        </button>
                        <button
                            class="custom-button mr-1 action-button bg-primary dark-theme"
                            [id]="'joinButton_' + eventinfo._id"
                            *ngIf="
                                isPrerecorded &&
                                (eventViewSection === EventViewSection.UPCOMING ||
                                    eventViewSection === EventViewSection.CALENDARVIEW)
                            "
                            (click)="navigateToEvent()"
                        >
                            {{ 'webinar-card.join' | translate }}
                        </button>

                        <button
                            class="custom-button mr-1 action-button bg-primary dark-theme"
                            [id]="'cardDetailButton_' + eventinfo._id"
                            *ngIf="
                                eventViewSection === EventViewSection.ATTENDING ||
                                (!allowStart &&
                                    eventinfo.isRegistered &&
                                    eventViewSection === EventViewSection.CALENDARVIEW)
                            "
                        >
                            {{ 'webinar-card.view_detail' | translate }}
                        </button>

                        <div
                            class="dropdown bottom-dropdown"
                            [id]="'reportAnalytics_' + eventinfo._id"
                            *ngIf="currentUser?._id === eventinfo?.userId"
                        >
                            <button
                                class="
                                    custom-button
                                    mr-1
                                    action-button
                                    d-flex
                                    align-items-center
                                    justify-content-center
                                    report-analytics-btn
                                    bg-primary
                                    dark-theme
                                "
                                *ngIf="eventViewSection === EventViewSection.PAST"
                                type="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="reportAnalytics"
                                (click)="openReportAnalytics($event)"
                            >
                                <span>Analytics</span
                                ><span class="img-wrapper"
                                    ><img src="assets/images/down-arrow-dark-blue.svg" alt=""
                                /></span>
                            </button>
                            <p-overlayPanel
                                #op
                                styleClass="report-analytics-options"
                                (outside)="reportAnalyticsOverlayPanelOpen = false"
                            >
                                <app-dropdown-options
                                    [options]="bottomMenuOptions"
                                    (onSelectedMenu)="handleActionMenuClick($event); op.toggle($event)"
                                    [eventId]="eventinfo._id"
                                    *ngIf="bottomMenuOptions.length"
                                    [eventViewSection]="EventViewSection.PAST"
                                    [isOngoing]="isOngoing"
                                ></app-dropdown-options>
                            </p-overlayPanel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [transitionOptions]="'300ms'" acceptIcon="null" rejectIcon="null"></p-confirmDialog>
<!-- <div class="popup-component delete-popup" *ngIf="showDeleteModal">
    <div class="bg"></div>
    <div class="popup" id="delete-event">
        <div class="popup-header">
            <span class="p-dialog-title">Cancel Event</span>
            <div class="close" (click)="toggleDeletePopup()">
                <img src="assets/img/close_icon.svg" />
            </div>
        </div>
        <div class="popup-body">
            <div class="p-confirm-dialog-message">This is one event in a series. Which one do you want to cancel?</div>
            <div class="custom-form options" *ngIf="eventinfo.repeatOptions?.repeat !== 'none'">
                <div class="custom-control custom-radio d-flex justify-content-start my-3">
                    <label for="current" class="pointer">
                        <input
                            type="radio"
                            name="deleteInSeries"
                            id="current"
                            value="current"
                            [(ngModel)]="deleteInSeries"
                        />
                        <span>Just this one</span>
                    </label>
                    <div>
                        <label for="all" class="d-flex ml-3 pointer">
                            <input
                                type="radio"
                                name="deleteInSeries"
                                id="all"
                                value="all"
                                [(ngModel)]="deleteInSeries"
                            />
                            <span>Entire series</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="actions mt-4 text-right">
                <button type="button" class="custom-button bg-secondary light-theme mr-2" (click)="toggleDeletePopup()">
                    Don't Cancel
                </button>
                <button type="submit" class="custom-button bg-primary light-theme mr-2" (click)="confirmDelete()">
                    Cancel Event
                </button>
            </div>
        </div>
    </div>
</div> -->

<div class="delete-myevent-popup" *ngIf="showDeleteSeriesModal">
    <app-dialog
        headerText="Cancel Event"
        (close)="showDeleteSeriesModal = false"
        (acceptClick)="confirmDelete()"
        bodyText="This is one event in a series. Which one do you want to cancel?"
        acceptText="Cancel Event"
        rejectText="Don't Cancel"
        [bodyTemplateRef]="deleteEventBody"
    >
    </app-dialog>
</div>

<div class="delete-myevent-popup" *ngIf="showConfirmDeleteModal">
    <app-dialog
        headerText="Cancel Event"
        (close)="showConfirmDeleteModal = false"
        (acceptClick)="processDelete()"
        [bodyText]="confirmDeleteMsg"
        acceptText="Cancel Event"
        rejectText="Don't Cancel"
    >
    </app-dialog>
</div>

<ng-template #deleteEventBody>
    <div class="select-type" *ngIf="eventinfo.repeatOptions?.repeat !== 'none'">
        <div class="radio-row d-flex justify-content-start my-3">
            <div class="custom-control custom-radio">
                <input
                    type="radio"
                    name="deleteInSeries"
                    id="current"
                    value="current"
                    [(ngModel)]="deleteInSeries"
                    class="custom-control-input"
                />
                <label for="current" class="custom-control-label">Just this one</label>
            </div>

            <div class="custom-control custom-radio">
                <input
                    type="radio"
                    name="deleteInSeries"
                    id="all"
                    value="all"
                    [(ngModel)]="deleteInSeries"
                    class="custom-control-input"
                />
                <label for="all" class="custom-control-label">Entire series</label>
            </div>
        </div>
    </div>
</ng-template>

<app-share-event-popup
    [eventDetails]="eventinfo"
    (close)="showMeetingSharePopup = false"
    *ngIf="showMeetingSharePopup && !(isMobile && isLandscape)"
    [useRevampStyling]="true"
></app-share-event-popup>
