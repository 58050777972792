import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MediaStreamRetrieverService } from 'src/app/core/services/media-stream-retriever.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';

@Component({
    selector: 'app-speaker-gallery-view',
    templateUrl: './speaker-gallery-view.component.html',
    styleUrls: ['./speaker-gallery-view.component.scss']
})
export class SpeakerGalleryViewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() source;
    participants: Array<any> = [];
    viewConfig;
    loading: boolean = true;
    tileCountPerView: number = 4;
    gridIndices: {
        start: number;
        end: number;
    } = {
        start: 0,
        end: 0
    };
    showSlideButton: {
        left: boolean;
        right: boolean;
    } = {
        left: false,
        right: false
    };
    subscriptions: any = [];
    /**
     * A gallery view contains the UI for all participants in the subscribed media engine channel
     * It might be video, audio, screen share and cloud video
     *
     * We need the list of participants in the channel
     * We should be able to differentiate each participant in the channel
     *
     * Once we have the differentiated participants, we set the config of UI of which ones to be shown.
     * Eg: 1 screen share and 4 speakers; 6 spotlighted speakers; n speakers depending on the view
     *
     * The config should contain the list of participants, and their position
     * We maintain the view configurations in an object and pick them based on the current context
     *
     * Configurations are changed based on the trigger to current view.
     * Eg: Screen share/Cloud video should be available to for screen share or cloud video with 4 speakers, etc
     * Eg: Spotlighted participants
     *
     * Each view configuration should consist
     * -> Where to position the participant (Dimensions will be relative)
     * -> participant: Whether to subscribe video or not
     * -> participant: Whether to subscribe audio or not
     * -> participant state: An object to convey the state of camera, mic, spotlight, volume..
     */

    constructor(
        private mediaStreamRetriever: MediaStreamRetrieverService,
        private callViewStateManager: CallViewStateManagerService,
        private appLoggerService: AppLoggerService
    ) {}

    ngOnInit(): void {
        this.getparticipantsList();
        this.mediaStreamRetriever.handleAgoraParticipantEvents();
        this.listenToGalleryViewUpdates();
        this.setViewConfig();
        this.setViewStyle();
        this.gridIndices = {
            start: 0,
            end: this.participants.length > 5 ? this.tileCountPerView + 1 : this.participants.length - 1
        };
        this.setTileCountPerView();
        this.listenToCallViewState();
        this.setSlideButtonState();
        this.loading = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if (changes.source) {
        //     this.getparticipantsList();
        // }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setTileCountPerView();
    }

    listenToCallViewState() {
        this.subscriptions.push(
            this.callViewStateManager.getViewStateObs().subscribe((e) => {
                if (e === 'callScreenState' || e === 'callOptionsPanelClose' || e === 'callOptionsPanelOpen') {
                    setTimeout(() => {
                        this.setTileCountPerView();
                    });
                }
            })
        );
    }

    setViewConfig() {
        this.viewConfig = {
            type: 'list'
        };
    }

    setTileCountPerView() {
        const galleryContainer = document.getElementById('SpeakerGalleryContainer');
        this.tileCountPerView = Math.floor((galleryContainer.clientWidth * 9) / (16 * galleryContainer.clientHeight));
        this.appLoggerService.log('Tile count per view', this.tileCountPerView);
    }

    setViewStyle() {
        // Participant tile style
    }

    listenToGalleryViewUpdates() {
        this.subscriptions.push(
            this.mediaStreamRetriever
                .getGalleryViewUpdatesEmitter()
                .subscribe((event) => this.handleGalleryViewUpdate(event))
        );
    }

    handleGalleryViewUpdate(event) {
        switch (event.type) {
            case 'participantAddition': {
                if (this.participants.findIndex((p) => p.uid == event.data.uid) == -1) {
                    this.participants.push(event.data);
                    this.setSlideButtonState();
                }
                break;
            }
            case 'participantExit': {
                this.removeParticipant(event.participantId);
                break;
            }
            case 'participantUpdate': {
                this.handleParticipantUpdate(event);
                break;
            }
        }
    }

    handleParticipantTileUpdates(event) {
        switch (event.type) {
            case 'removeParticipant': {
                this.removeParticipant(event.participantId);
                break;
            }
        }
    }

    removeParticipant(participantId) {
        let idxToRemove = this.participants.findIndex((participant) => participant.uid == participantId);
        if (idxToRemove === -1) return;
        this.participants.splice(idxToRemove, 1);
        this.setSlideButtonState();
    }

    handleParticipantUpdate(event) {
        if (event.type != 'userStreamPublished' && event.type != 'videoOff') return;
        let idxToUpdate = this.participants.findIndex((participant) => participant.uid == event.participantId);
        if (idxToUpdate === -1) return;
        this.participants[idxToUpdate] = event.data;
    }

    setSlideButtonState() {
        this.showSlideButton.left = this.gridIndices.start > 0;
        this.showSlideButton.right = this.gridIndices.start + this.tileCountPerView < this.participants.length;
    }

    getparticipantsList() {
        /**
         * TODO: Make Sure the we are joined the channel before we initialize this component
         */
        this.participants = this.mediaStreamRetriever.getRemoteParticipants(this.source);
    }

    trackByFunction(index, item) {
        return (item?.uid && item.hasVideo) ?? index;
    }

    slideLeft() {
        if (this.gridIndices.start <= 0) {
            return;
        }
        this.gridIndices.start -= 1;
        this.setSlideButtonState();
    }

    slideRight() {
        if (this.gridIndices.start + this.tileCountPerView >= this.participants.length) {
            return;
        }
        this.gridIndices.start += 1;
        this.setSlideButtonState();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
