import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { RestService } from './rest.service';
import { AuthService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class AppInfoService {
    private isAuthenticated;
    constructor(private appService: AppService, private restService: RestService, private authService: AuthService) {
        this.isAuthenticated = this.authService.getIsAuthenticated();
    }

    getReleaseNotesForOS(os) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/config?os_type=${os}`);
    }

    getFeedbackReasons() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/feedback/reasons`);
    }

    submitFeedback(payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/feedback/capture/v1${!this.isAuthenticated ? '/guest' : ''}`,
            payload
        );
    }
}
