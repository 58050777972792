<div *ngIf="!loader">
    <div
        class="app-container"
        [class.overflow-hidden]="isMobileMenuBarActive"
        [ngClass]="{ isIosMobile: utilService.isMobileView }"
        [class.dashboard]="currentPage === 'dashboard'"
    >
        <div class="top-nav-wrap" *ngIf="showHeader && !isMyJio" id="website-header" #header>
            <app-header-version2
                [hasTransparentHeader]="hasTransparentHeader"
                [isDashboard]="isDashboard"
            ></app-header-version2>
            <!-- <app-landing-header *ngIf="!isStaticPage && !isDashboard" [hasTransparentHeader]="hasTransparentHeader">
            </app-landing-header> -->
        </div>
        <div
            (scroll)="listScroll($event)"
            class="scrollable"
            id="scroll-area"
            [class.staticPage-container]="isStaticPage && !utilService?.isOldVersion && !isDashboard"
            [class.mobile-scroll]="utilService.isMobileView"
            [class.without-header]="!showHeader"
            [class.scrollWithFooter]="showFooter"
            [class.isAuthenticated]="!isStaticPage && showHeader"
            [class.isMobileOS]="utilService.isMobileView"
            [class.myJio]="appService?.isMyJio"
            [class.media-player]="isMediaPlayer"
            [class.isDashboard]="isDashboard"
        >
            <div class="content-wrap">
                <router-outlet></router-outlet>
            </div>
            <div class="footer-warp" *ngIf="showFooter && !isMyJio">
                <app-landing-footer *ngIf="!utilService?.isOldVersion"></app-landing-footer>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="loader"></app-loader>

<app-incoming-call-popup *ngIf="showIncomingCallPopup" (close)="toggleIncomingCallPopup(true)">
</app-incoming-call-popup>
<p-confirmDialog [transitionOptions]="'300ms'" acceptIcon="null" rejectIcon="null"></p-confirmDialog>
