import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { AppService, EventEmitterService, UserService } from 'src/app/core';
import { generateHS256Token } from 'src/app/core/helpers/jwt';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { UtilService, AuthService, GuestAuthService, ExternalInterfaceService } from 'src/app/core';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';

@Component({
    selector: 'app-webinar-games-panel',
    templateUrl: './webinar-games-panel.component.html',
    styleUrls: ['./webinar-games-panel.component.scss']
})
export class WebinarGamesPanelComponent implements OnInit {
    @Input() meetingObj;
    @Input() isMobileOS;
    @Input() isAttendee;
    @Output() close: EventEmitter<any> = new EventEmitter();
    public gamesLength = 0;
    gamesList = [];
    currentUser;
    gamesStarted = false;
    gamesrc = '';
    gameNameSelected = '';
    url = '';
    unscramblegamelink;
    wordsearchgameslink;
    jwtSecret = '';
    isAuthenticated: boolean = false;
    guestUser;
    quizgameslink;
    mywinninglink;
    winningStarted = false;
    gameLoaded = false;
    jwtDoc = {
        useridtype: 'uuid',
        useridvalue: '',
        device: '', // (smartphone, browser)
        platform: 'jioevents',
        isJioUser: false
    };

    constructor(
        private webinarService: WebinarService,
        private appLoggerService: AppLoggerService,
        private appService: AppService,
        private CallViewStateManagerService: CallViewStateManagerService,
        private eventEmitterService: EventEmitterService,
        private utilService: UtilService,
        private userService: UserService,
        private authService: AuthService,
        private guestAuthService: GuestAuthService,
        private externalInterfaceService: ExternalInterfaceService,
        private webinarAttendeeService: WebinarAttendeeService
    ) {}

    ngOnInit() {
        if (!this.isAttendee) {
            this.gamesList = this.meetingObj?.webinarOptions.customization?.isGamesTabEnabled?.gamesList || [];
            this.getGamesList(this.meetingObj?.webinarOptions.customization?.isGamesTabEnabled);
            this.gamesLength = this.gamesList.length;
        } else {
            this.gamesList = this.meetingObj?.customization?.isGamesTabEnabled?.gamesList || [];
            this.getGamesList(this.meetingObj?.customization?.isGamesTabEnabled);
            this.gamesLength = this.gamesList.length;
        }
        this.eventEmitterService.subscribe((event: any) => {
            switch (event.type) {
                case 'REQUEST_CLOSE_GAMES_DATA':
                    this.gamesStarted = false;
                    this.winningStarted = false;
                    break;
                case 'REQUEST_LOAD_GAMES_DATA':
                    this.gameLoaded = true;
                    break;
                case 'REQUEST_MYWINNING_DATA':
                    this.winningStarted = true;
                    break;
            }
        });
        this.games();
    }

    selectedGame(game) {
        this.gamesStarted = true;
        this.gameLoaded = true;
        this.gameNameSelected = game;
        switch (this.gameNameSelected) {
            case 'quiz':
                this.gamesrc = this.quizgameslink;
                break;
            case 'unscramble':
                this.gamesrc = this.unscramblegamelink;
                break;
            case 'wordsearch':
                this.gamesrc = this.wordsearchgameslink;
                break;
        }
    }

    async games() {
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.jwtDoc.device = this.isMobileOS ? 'smartphone' : 'browser';
        this.isAuthenticated = this.authService.getIsAuthenticated();
        if (this.isAuthenticated) {
            this.currentUser = this.userService.getUserSync();
            this.jwtDoc.useridvalue = this.currentUser?._id;
        } else {
            const guestUserId =
                this.userService.getUserSync()?._id ||
                this.guestAuthService.getAuthInfo()?.guestUserId ||
                this.webinarAttendeeService.getUserId();
            this.jwtDoc.useridvalue = guestUserId;
        }
        this.jwtSecret = this.appService.getConfigVariable('JWTSECRET');
        this.url = await generateHS256Token(this.jwtDoc, this.jwtSecret);
        this.unscramblegamelink = this.utilService.bypassSecurityTrustResourceUrl(
            this.appService.getConfigVariable('gamification').unscramble.gamelink + '&jwt=' + `${this.url}`
        );
        this.quizgameslink = this.utilService.bypassSecurityTrustResourceUrl(
            this.appService.getConfigVariable('gamification').quiz.gamelink + '&jwt=' + `${this.url}`
        );
        this.wordsearchgameslink = this.utilService.bypassSecurityTrustResourceUrl(
            this.appService.getConfigVariable('gamification').wordsearch.gamelink + '&jwt=' + `${this.url}`
        );
        this.mywinninglink = this.utilService.bypassSecurityTrustResourceUrl(
            this.appService.getConfigVariable('mywinninglink') + `${this.url}`
        );
    }

    gamesImage(game) {
        return `assets/images/event-call-options/inside_${game}.svg`;
    }

    navigateToGamesHome() {
        this.gamesStarted = false;
    }

    closeView() {
        this.close.emit();
        this.gamesStarted = false;
    }

    redirectToMyWinning() {
        this.winningStarted = true;
        this.gamesStarted = true;
    }

    getGamesList(path) {
        if (this.gamesList.length === 0) {
            for (let i = 0; i < 3; i++) {
                if (path[`gamesList[${i}]`]) {
                    this.gamesList.push(path[`gamesList[${i}]`]);
                }
            }
        }
    }
}
