import { Component, OnInit } from '@angular/core';

import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-get-client',
    templateUrl: './get-client.component.html',
    styleUrls: ['./get-client.component.scss']
})
export class GetClientComponent implements OnInit {
    constructor(private utilService: UtilService) {}

    ngOnInit() {
        this.utilService.downloadJioMeet(true);
    }
}
