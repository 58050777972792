import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestService } from './rest.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { ToastrService } from 'ngx-toastr';
import { EventEmitterService } from './event-emitter.service';
import { APP_EVENTS } from 'src/app/constants';
import { EventsPlusService } from 'src/app/core/services/events-plus.service';

export interface owner {
    name: string;
    jiomeet_user_id: string;
}

export interface studioTemplate {
    id: number;
    createdAt?: string;
    publishedAt?: string;
    updatedAt?: string;
    scenes: any;
    misc?: any;
    description?: string;
    studio_template_meta?: any;
    templateId?: number;
}

export interface studioTemplateMeta {
    id?: string;
    category: string;
    createdAt: string;
    is_free: boolean;
    is_published: boolean;
    owners: [owner];
    preview_img_low_res: Object;
    privacy_level: string;
    publishedAt: string;
    title: string;
    updatedAt: string;
    studio_template?: Object;
    scenes?: Array<any>;
}

export interface createTemplatePayload {
    title: string;
    description: string;
    scenes: object;
    misc?: object;
    category?: string;
    is_free: boolean;
    is_published: boolean;
    privacy_level: string;
    owners: owner[];
}

@Injectable({
    providedIn: 'root'
})
export class MarketplaceService {
    addonsHeaders;
    selectedTemplateId: string;
    eventTemplateId: string;
    constructor(
        private http: HttpClient,
        private appService: AppService,
        private restService: RestService,
        private router: Router,
        private userService: UserService,
        private confirmationService: ConfirmationService,
        private webinarService: WebinarService,
        private toastrService: ToastrService,
        private eventEmitterService: EventEmitterService,
        public eventsPlusService: EventsPlusService
    ) {
        this.addonsHeaders = new HttpHeaders();
        this.addonsHeaders = this.addonsHeaders.set('Origin', this.appService.getJioEventsBaseUrl());
    }

    requestTemplatesForMarketPlaceHome(defaultTemplate) {
        let uri = `${this.appService.getAddonsBaseUrl()}/studio-template-metas?populate=*&filters[is_published]=true`;
        uri += defaultTemplate ? '&filters[event_id]=default' : '';
        return this.http.get<any>(uri, {
            headers: this.addonsHeaders
        });
    }

    requestTemplatesMetaData() {
        return this.http.get<any>(
            `${this.appService.getAddonsBaseUrl()}/studio-template-metas?populate=*&sort[0]=createdAt:desc`
        );
    }

    getStudioTemplatesToBrowse(defaultTemplate = false): any {
        return new Promise((resolve, reject) => {
            this.requestTemplatesForMarketPlaceHome(defaultTemplate).subscribe(
                (res) => {
                    let templates = res?.data?.map((e) => {
                        return {
                            ...e.attributes,
                            preview_img_low_res: e.attributes.preview_img_low_res?.data?.attributes?.url,
                            id: e.id
                        };
                    });
                    resolve(templates);
                },
                (err) => {
                    resolve([]);
                }
            );
        });
    }

    getStudioTemplatesMetaData(): any {
        return new Promise((resolve, reject) => {
            this.requestTemplatesMetaData().subscribe(
                (res) => {
                    let templates = res?.data?.map((e) => {
                        return {
                            ...e.attributes,
                            preview_img_low_res: e.attributes.preview_img_low_res?.data?.attributes?.url,
                            id: e.id
                        };
                    });
                    resolve(templates);
                },
                (err) => {
                    resolve([]);
                }
            );
        });
    }

    getStudioTemplateById(templateId) {
        return this.restService
            .get(`/api/addons/studio-templates/${templateId}`)
            .pipe(
                map((res) => ({
                    id: res.data.id, // studio-template-id
                    ...res.data.attributes
                }))
            )
            .toPromise();
    }

    getStudioTemplateWithMetaById(templateId) {
        return this.restService
            .get(`/api/addons/studio-template-metas/${templateId}?populate=*`)
            .pipe(
                map((res) => ({
                    id: res.data.id, // studio-template-meta-id
                    ...res.data.attributes,
                    ...res.data.attributes.studio_template.data.attributes,
                    templateId: res.data.attributes.studio_template.data.id
                }))
            )
            .toPromise();
    }

    createNewStudioTemplate(payload: createTemplatePayload) {
        return this.restService
            .post(`/api/addons/studio-templates`, { data: payload })
            .pipe(
                map((res: any) => ({
                    id: res.data.id,
                    studioTemplateMetaId: res.studio_template_meta_id,
                    ...res.data.attributes
                }))
            )
            .toPromise();
    }

    updateStudioTemplate(payload) {
        return this.restService
            .put(`/api/addons/studio-templates/${payload.id}`, { data: payload })
            .pipe(
                map((res: any) => ({
                    id: res.data.id,
                    ...res.data.attributes
                }))
            )
            .toPromise();
    }

    updateStudioTemplateMeta(payload) {
        return this.restService
            .put(`/api/addons/studio-template-metas/${payload.id}`, { data: payload })
            .pipe(
                map((res: any) => ({
                    id: res.data.id,
                    ...res.data.attributes
                }))
            )
            .toPromise();
    }

    saveTemplateAsFormData(payload: FormData) {
        return this.restService.post(`/api/addons/studio-templates`, payload).toPromise();
    }

    handleCreateNewTemplate(data) {
        const user = this.userService.getUserSync();
        const payload = {
            title: data.title,
            description: 'This has no description',
            scenes: data.scenes,
            misc: [],
            tags: data.tags,
            category: data.category,
            is_free: false,
            is_published: false,
            privacy_level: 'Private',
            owners: [
                {
                    jiomeet_user_id: user._id, // Profile pic fetched from jiomeet server based on uid
                    name: user.name
                }
            ],
            event_id: data.event_id,
            parent_id: data.parent_id
        };
        return this.createNewStudioTemplate(payload);
    }

    navigateToEditorById(templateId: string, eventId: string = '', customize: boolean = false) {
        if (eventId) {
            this.router.navigateByUrl(
                `/events-studio/editor${
                    customize
                        ? `?customize=true&eventId=${eventId}&templateId=${templateId}`
                        : `/${templateId}?eventId=${eventId}`
                }`
            );
        } else {
            this.router.navigateByUrl(
                `/events-studio/editor${customize ? `?customize=true&templateId=${templateId}` : `/${templateId}`}`
            );
        }
    }

    navigateToEditor() {
        this.router.navigateByUrl('/events-studio/editor');
    }

    navigateToMarketPlaceHome() {
        this.router.navigateByUrl('/studio/home');
    }

    /**
     * Get Template Owned by User
     * Pull the relation of studio_template_metas in jiomeet-user table
     */
    async getTemplatesByUser(draftTemplates = false) {
        const userId = this.userService.getUserSync()._id;
        let templates = await this.restService
            .get(
                `${this.appService.getAddonsBaseUrl()}/ondemandaction/jiomeet-users?filters[jiomeet_user_id]=${userId}&populate[0]=studio_template_metas&time=${new Date()
                    .valueOf()
                    .toString()}`
            )
            .pipe(map((res) => res.data[0]?.attributes.studio_template_metas.data))
            .toPromise();
        return templates
            ?.map((t: any) => {
                return {
                    id: t.id,
                    ...t.attributes
                };
            })
            .filter((t) => (draftTemplates ? !t.is_published : t.is_published));
    }

    navigateToEditorByEventId(templateId: string, eventId: string, customize: boolean = false) {
        this.router.navigateByUrl(
            `/events-studio/editor${
                customize ? `?customize=true&eventId=${eventId}&templateId=${templateId}` : `/${templateId}`
            }`
        );
    }

    getStudioTemplatesToEvent(templateId): any {
        return new Promise((resolve, reject) => {
            this.requestTemplatesForEvent(templateId).subscribe(
                (res) => {
                    let templates = res?.data?.map((e) => {
                        return {
                            ...e.attributes,
                            preview_img_low_res: e.attributes.preview_img_low_res?.data?.attributes?.url,
                            id: e.id
                        };
                    });
                    resolve(templates);
                },
                (err) => {
                    resolve([]);
                }
            );
        });
    }

    requestTemplatesForEvent(templateId) {
        return this.http.get<any>(
            `${this.appService.getAddonsBaseUrl()}/studio-template-metas?populate=*&filters[id]=${templateId}`,
            {
                headers: this.addonsHeaders
            }
        );
    }

    async unlinkTemplateToEvent() {
        const eventId =
            this.webinarService.scheduleWebinarDetails._id || this.webinarService.scheduleWebinarDetails.meetingId;
        const webinarDetails = await this.webinarService.getWebinarDetails(eventId).toPromise();
        await this.eventsPlusService.saveCustomLayout({}, eventId).toPromise();

        const payload = {
            advancedOptions: webinarDetails.advancedOptions,
            endTime: webinarDetails.endTime,
            topic: webinarDetails.topic,
            isPinEnabled: true,
            startTime: webinarDetails.startTime,
            isDynamicRoom: true,
            bypassStartTimeCheck: true,
            webinar: webinarDetails.webinar
        };
        payload.webinar.options.customization = {
            ...payload.webinar.options.customization,
            studioTemplateId: null
        };
        this.webinarService.updateWebinar(eventId, payload, webinarDetails).subscribe(
            (res) => {
                console.log('Webinar details updated\n', webinarDetails);
                this.selectedTemplateId = null;
                this.eventTemplateId = null;
                this.toastrService.success('Template Unlinked to Event Successfully!');
                this.eventEmitterService.emit({ type: APP_EVENTS.REFRESH_TEMPLATES, data: null });
            },
            (err) => {}
        );
    }

    confirmTemplateUnLink() {
        this.confirmationService.confirm({
            message:
                'Unlinking template from the event will reset all the customisations you have done with this template. This event will be broadcasted with the default JioEvents template after unlinking. Would you like to proceed?',
            header: 'Unlink Template?',
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            acceptButtonStyleClass: 'custom-button bg-primary',
            rejectButtonStyleClass: 'custom-button bg-secondary',
            accept: () => {
                this.unlinkTemplateToEvent();
            },
            reject: () => {}
        });
    }
}
