import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { RestService } from './rest.service';
import { SystemService } from './system.service';

import * as _ from 'lodash-es';
import { AppService } from './app.service';
import { addMonths, differenceInMinutes, isAfter, isFuture, parseISO } from 'date-fns';
import { EventStatus } from 'src/app/constants/webinar-enum';
import { APP_CONSTANT } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class MeetingService {
    private scheduleMeetingDetails = null;
    public url = '';
    private datePipe = new DatePipe('en');
    private joinButtonEnableBefore =
        this.appService.getConfigVariable('ENABLE_ATTENDEE_JOIN_BEFORE_IN_MIN') ||
        APP_CONSTANT.DEFAULT_SESSION_JOIN_ENABLE;
    private sessionIdToAutoJoin = null;
    constructor(
        private restService: RestService,
        private systemService: SystemService,
        private appService: AppService
    ) {}

    getDashboardMeetings(date) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/dashboardmeetings?date=${date}`);
    }

    getMeetings({ userId, from, to, searchQuery = '' }) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${userId}?from=${from}&to=${to}&keyword=${searchQuery}&type=all`
        );
    }

    getPastMeetings(user, from = null, to = null, all = false) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${
                user._id
            }?from=${from}&to=${to}&past=true&limit=500`
        );
        // return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${user._id}?past=true`);
    }

    getRunningMeetings({ userId, searchQuery = '' }) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${userId}?running=true`);
    }

    deleteMeeting(meetingId, payload, cancel = null) {
        let body = {};
        if (cancel) {
            body = {
                cancel
            };
        }
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/meeting/cancel/${meetingId}`, body);
    }

    createMeeting(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/meeting`, payload);
    }

    updateMeeting(meetingId, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/meetingDetails/${meetingId}`,
            payload
        );
    }

    updateExternalCalendarDetails(meetingId, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/externalcalendar/${meetingId}`,
            payload
        );
    }

    scheduleMeeting(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/meeting`, payload);
    }

    addToJiomeet({ hash = '', extension = '', pin = '' }) {
        const payload: any = {
            ...(extension && { extension }),
            ...(pin && { pin }),
            ...(hash && { hash })
        };
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/shorturl/addtojiomeet`, payload);
    }

    cancelMeeting(meetingId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/cancel/${meetingId}`,
            payload
        );
    }

    getContacts(filters) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/contacts?keyword=${filters.keyword}`
        );
    }

    getMeetingDetails(meetingId) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/meetingDetails/${meetingId}?source=webrtc`)
            .pipe(map((details) => (Array.isArray(details) ? details[0] : details)));
    }

    getScheduleMeetingDetails() {
        return this.scheduleMeetingDetails;
    }

    setScheduleMeetingDetails(meeting) {
        this.scheduleMeetingDetails = meeting;
    }

    getRoomDetails(historyId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/calls/${historyId}`);
    }

    groupMeetingsByDate(meetings) {
        if (!meetings?.length) {
            return [];
        }
        // group chats by date
        const chatGroups = _.groupBy(meetings, (chat) => this.datePipe.transform(chat.startTime, 'd MMM y'));
        // sort date keys
        const ordered = _.orderBy(_.keys(chatGroups), [(key) => new Date(key)], ['desc']);
        // sorted map by dates
        const map: Map<string, any[]> = ordered.reduce((result, key) => {
            result.set(key, chatGroups[key]);
            return result;
        }, new Map());

        const array = [];
        map.forEach((value, key) => {
            array.push({ key, value });
        });
        return array;
    }

    addParticipantsInMeeting({
        users = [],
        ownerName = '',
        topic = '',
        historyId = '',
        isClassroomMode = false,
        webinar = null,
        webinarOptions = null
    }) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/call`, {
            data: {
                owner: ownerName,
                is_disconnect: 'false',
                group_id: '0',
                participantEndPointType: 'web',
                participantEndPointName: 'web',
                machineIp: this.systemService.getDeviceUUID(),
                historyId,
                users,
                time: +new Date() - +new Date('1970-01-01'),
                title: topic,
                isClassroomMode,
                webinar,
                webinarOptions
            },
            event: 'call'
        });
    }

    getMeetingType({ jiomeetId = '', pin = '' }) {
        const payload: any = {
            jiomeetId,
            ...(pin && { pin })
        };
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/meeting/isWebinar`, payload);
    }

    getActiveCalls() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/calls`);
    }

    setRegistrationFields(meetingId, fields) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/registrationfields`,
            { fields }
        );
    }

    getRegisteredEvents() {
        const to = addMonths(
            new Date(),
            this.appService.getConfigVariable('jioConfConfig')?.extendRegistrationInMonths || 1
        );
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/webinar/listregisteredevents?from=${new Date().toISOString()}&to=${to.toISOString()}`
        );
    }

    uploadBulkAttendeeForEvent(meetingId, file) {
        const formData = new FormData();
        formData.append('bulkAttendee', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendees/bulkinvite`,
            formData
        );
    }

    updateContactInfo(meetingId, userId, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/attendee/${userId}`,
            payload
        );
    }

    verifyInviteLinkHash(hash) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/verifyhash/${hash}`);
    }

    checkInviteLinkJoinHash(hash) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meetingInvite/joinHash/${hash}`);
    }

    getEventStatus(meetingInfo, webinarDetails?) {
        let currentStatus = '';
        if (meetingInfo?.broadcasting) {
            currentStatus = EventStatus.ONGOING;
        } else if (this.isMeetingEnded(meetingInfo, webinarDetails)) {
            currentStatus = EventStatus.COMPLETED;
        } else if (
            !isAfter(new Date(), parseISO(meetingInfo.startTime)) &&
            differenceInMinutes(parseISO(meetingInfo.startTime), new Date()) <= this.joinButtonEnableBefore - 1
        ) {
            currentStatus = EventStatus.ABOUT_TO_START;
        }
        return currentStatus;
    }

    isMeetingEnded(meetingInfo, webinarDetails?) {
        // var isRecurring = webinarDetails?.repeatOptions?.repeat === 'daily';
        // if (isRecurring) {
        //     // use end time for the day ?
        //     var today = new Date();
        //     var endDate = parseISO(meetingInfo.endTime);
        //     endDate.setDate(today.getDate());
        //     endDate.setMonth(today.getMonth());
        //     endDate.setFullYear(today.getFullYear());
        //     return isAfter(today, endDate) && !meetingInfo.broadcasting;
        // } else {
        return (
            meetingInfo.status === EventStatus.ENDED
            //||(isAfter(new Date(), parseISO(meetingInfo.endTime)) && !meetingInfo.broadcasting)
        );
        // }
    }

    isMeetingInFuture(meetingInfo) {
        return isFuture(parseISO(meetingInfo.startTime));
    }

    isBroadcasting(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/isbroadcasting`
        );
    }

    getVideoStream(hash) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/shorturl/getstreamurl?hash=${hash}`);
    }

    getRecordingDetailsByHash(hash) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/recording/byhash?hash=${hash}`);
    }

    setSessionToAutoJoin(sessionId = null) {
        this.sessionIdToAutoJoin = sessionId;
    }

    getSessionToAutoJoin() {
        return this.sessionIdToAutoJoin;
    }
}
