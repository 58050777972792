<div class="recording-list" [class.isMobile]="isMobile">
    <div class="popup-component">
        <div class="bg" (click)="cancel()"></div>
        <div class="popup">
            <div class="popup-header">
                <h3 class="header" *ngIf="type === 'recording'">Recording List</h3>
                <h3 class="header" *ngIf="type === 'reports'">Reports</h3>
                <div class="close">
                    <img src="assets/img/webinar/side-nav/icon_Close.svg" (click)="toggleRecordingListPopup()" alt="" />
                </div>
            </div>
            <span
                class="download-btn pointer mt-1 mb-1"
                (click)="downloadAllRecording()"
                *ngIf="
                    isSessionEnabled && parentMeetingRecordingInfo && type === 'recording' && recordinglist.length > 1
                "
                >Download All</span
            >
            <div class="popup-body">
                <div class="registration-table mt-3" *ngIf="!loadingRecordings">
                    <p-table [value]="recordinglist" tableStyleClass="webinar-table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 1%"></th>
                                <th [style.width]="isMobile ? '73%' : '45%'">Recording Name</th>
                                <th style="width: 35%" *ngIf="!isMobile">Time Stamp</th>
                                <th style="width: 25%"></th>
                                <th style="width: 1%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data>
                            <tr>
                                <td class="empty-space"></td>
                                <td
                                    class="recording-name row-content"
                                    *ngIf="type === 'recording'"
                                    pTooltip="{{ data.customName }}"
                                    tooltipPosition="bottom"
                                >
                                    {{ data.customName | slice: 0:33 }}...
                                </td>
                                <td class="recording-name row-content" *ngIf="type === 'reports'">
                                    {{ data.topic | slice: 0:33 }}...
                                </td>
                                <td class="row-content" *ngIf="!isMobile && type === 'recording'">
                                    {{ data.dateCreated | date: 'dd-MM-yyyy' }}
                                    {{ data.dateCreated | date: 'hh:mm a' }}
                                </td>
                                <td class="row-content" *ngIf="!isMobile && type === 'reports'">
                                    {{ data.startTime | date: 'dd-MM-yyyy' }} {{ data.startTime | date: 'hh:mm a' }}
                                </td>
                                <td class="row-content" *ngIf="type === 'recording'">
                                    <div class="download-btn pointer" (click)="downloadRecording(data.streamingURL)">
                                        Download
                                    </div>
                                    <div
                                        class="download-icon pointer"
                                        (click)="downloadRecording(data.streamingURL)"
                                    ></div>
                                </td>
                                <td class="row-content" *ngIf="type === 'reports'">
                                    <div class="download-btn pointer" (click)="downloadReport(data)">Download</div>
                                    <div class="download-icon pointer" (click)="downloadReport(data)"></div>
                                </td>
                                <td class="empty-space"></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="12" class="text-center">No Recordings Found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="footnote mt-3" *ngIf="recordinglist?.length">
                        {{ 'previous-webinar.recordings_note' | translate }}
                    </div>
                </div>
                <app-loader *ngIf="loadingRecordings"></app-loader>
            </div>
        </div>
    </div>
</div>
