import { transition, trigger, stagger, query, animateChild } from '@angular/animations';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ExploreEventsService } from 'src/app/core/services/explore-events.service';
import { APP_CONSTANT, HOME_PAGE_REVAMP } from 'src/app/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { UtilService } from 'src/app/core';
@Component({
    selector: 'app-home-event-view',
    templateUrl: './home-event-view.component.html',
    animations: [trigger('cardsAnim', [transition('* => *', [query(':enter', stagger(100, animateChild()))])])],
    styleUrls: ['./home-event-view.component.scss']
})
export class HomeEventViewComponent implements OnInit {
    @Input() eventView: string;
    eventListType: string;
    eventList: any = [];
    showOngoingEvents: boolean = false;
    isLoading; // Your data source
    currentPage = 1;
    itemsPerPage = APP_CONSTANT.EVENT_CARDS_PER_VIEW || 15;
    startIndex = 0;
    endIndex;
    showMore: boolean = true;
    eventCount: any;
    isElementInView = false;
    eventsLoaded: boolean = false;
    moreEventsCard: boolean = false;
    newEventsLoading: boolean = false;
    showSubHeader: boolean = false;
    offset = 0;
    inProgress = false;
    isMobile;
    showViewMoreButton: boolean;
    listButtons = [
        { type: 'trending', label: 'Trending' },
        { type: 'recommended', label: 'Recommended' },
        { type: 'ongoing', label: 'Ongoing' },
        { type: 'upcoming', label: 'Upcoming' },
        { type: 'replay', label: 'Watch-on-Demand' },
        { type: 'all', label: 'All' }
    ];
    listType = 'trending'; // Set the default value
    constructor(
        private exploreEventsService: ExploreEventsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private googleTagManger: GoogleTagMangerService,
        public utilService: UtilService
    ) {}
    goToPage(pageNumber: number): void {
        this.currentPage = pageNumber;
        this.startIndex = (this.currentPage - 1) * this.itemsPerPage;
    }
    @ViewChild('defaultButton') defaultButton!: ElementRef;
    @ViewChild('elementToObserve') elementToObserve!: ElementRef;

    ngAfterViewInit() {
        const options = {
            root: null, // use the viewport as the root
            rootMargin: '0px',
            threshold: 0.5 // trigger when 50% of the element is visible
        };

        const observer = new IntersectionObserver(this.handleIntersection, options);
        observer.observe(this.elementToObserve.nativeElement);
    }

    handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.isElementInView = true;
            }
        });
    };

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser();
        if (this.eventView == 'exploreEventsView' && !!this.exploreEventsService.viewMoreEventsState) {
            this.listType = this.exploreEventsService.viewMoreEventsState.type;
            this.eventList = this.exploreEventsService.viewMoreEventsState.existingEvents;
            this.eventCount = this.exploreEventsService.viewMoreEventsState.eventCount;
            this.moreEventsCard = true;
            this.getMoreEvents();
            this.exploreEventsService.setViewMoreEventsState(null);

            console.log('event count', this.eventCount);
            console.log('event list ', this.eventList);
        } else {
            this.displayEvent(this.listType);
        }
    }

    async eventCheckList() {
        this.eventsLoaded = false;
    }

    displayEvent(name: string = 'all') {
        this.isLoading = true;
        this.showViewMoreButton = false;
        this.listType = name;
        this.eventList = [];
        this.offset = 0;
        this.getMoreEvents(true);
        HOME_PAGE_REVAMP.SHOW_EVENTS.clicked_text = this.listType;
        if (this.eventView === 'homeView') {
            HOME_PAGE_REVAMP.SHOW_EVENTS.current_page = 'home_page_revamp';
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.SHOW_EVENTS);
        }
        if (this.eventView === 'exploreEventsView') {
            HOME_PAGE_REVAMP.SHOW_EVENTS.current_page = 'explore-events';
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.SHOW_EVENTS);
        }
    }

    toggleView() {
        if (this.eventView === 'homeView') {
            this.exploreEventsService.setViewMoreEventsState({
                viewMore: true,
                type: this.listType,
                existingEvents: this.eventList,
                eventCount: this.eventCount
            });
            this.router.navigate(['/explore-events']).then(() => {
                HOME_PAGE_REVAMP.VIEW_MORE.clicked_text = 'home_page_event_view_more';
                this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.VIEW_MORE);
            });
        } else {
            this.getMoreEvents();
            HOME_PAGE_REVAMP.VIEW_MORE.clicked_text = 'explore_events_view_more';
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.VIEW_MORE);
        }
    }

    getMoreEvents(initialize = false) {
        if (this.inProgress) return;
        this.inProgress = true;
        const limit = this.isMobile ? 4 : 15;
        if (!initialize) {
            if (!this.moreEventsCard) return;
            this.offset += this.isMobile ? 4 : 15;
        }

        // Fetch more events from the service
        this.exploreEventsService.getEventsList(this.listType, 'all', 'all', 'all', '', this.offset, limit).subscribe(
            (data) => {
                // Update event-related properties
                this.eventCount = data.totalCount;
                this.eventList.push(...data.resultMeetingDetails);
                this.showViewMoreButton = true;
                this.moreEventsCard = data.moreAvailable;
                this.showOngoingEvents = this.eventCount > 0;
                this.isLoading = false;
                this.inProgress = false;
            },
            (err) => {},
            () => {
                this.eventsLoaded = true;
                this.inProgress = false;
            }
        );
    }
}
