import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UtilService, ExternalInterfaceService } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-ticker-text',
    templateUrl: './ticker-text.component.html',
    styleUrls: ['./ticker-text.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TickerTextComponent implements OnInit {
    @Input() tickerText;
    @Input() mini;
    @Input() withSponsors;
    @Input() fixtobottom;
    @Input() isAttendee: boolean = false;

    animationDuration;
    private timeouts = [];
    isMobileOS: boolean = false;
    isMyJio;

    constructor(
        private utilService: UtilService,
        private activatedRoute: ActivatedRoute,
        private externalInterfaceService: ExternalInterfaceService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.isMyJio = params?.isMyJio ? true : false;
        });
    }

    ngOnInit(): void {
        this.isMobileOS = this.utilService?.isMobileBrowser();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.tickerText) {
            this.timeouts.push(
                setTimeout(() => {
                    document.querySelectorAll('.ticker-text a').forEach((a) => {
                        if (!this.isMyJio) {
                            a.setAttribute('target', '_blank');
                        } else {
                            a.addEventListener('click', (event) => {
                                let url = a.getAttribute('href');
                                event.preventDefault();
                                this.externalInterfaceService.launchBrowser(url);
                            });
                        }
                    });
                })
            );
            this.setAnimationDuration();
        }
    }
    setAnimationDuration() {
        this.timeouts.push(
            setTimeout(() => {
                let default_width = 1000;
                let default_duration = 20;

                let ticker = document.getElementById('ticker');
                let duration: any = (default_duration / default_width) * (ticker.offsetWidth || ticker.clientWidth);
                this.animationDuration = duration ? duration + 's' : '25s';
            })
        );
    }
    sanitizeText(message) {
        return this.utilService.sanitizeAndConvertEncodedHTML(message);
    }
    ngOnDestroy() {
        this.timeouts.forEach((id) => clearTimeout(id));
    }
}
