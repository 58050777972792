import { Component, OnInit, OnDestroy } from '@angular/core';
import { AddonsService, UtilService } from 'src/app/core';
import * as marked from 'marked';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit, OnDestroy {
    faqs: any[] = [];
    selectedLinks: boolean;
    selectedLink: string = '';
    isMobile = false;
    isLandscape: boolean;
    isMobileOrIpad = false;
    faqData: any;
    faqTitles: string[];

    private destroy$ = new Subject<void>();

    constructor(private utilService: UtilService, private addonsService: AddonsService) {}

    ngOnInit(): void {
        this.checkDeviceAndOrientation();
        this.getFaqData();

        // Listen for orientation changes
        if ('orientation' in screen) {
            fromEvent(screen.orientation, 'change')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.checkDeviceAndOrientation();
                });
        } else {
            // Fallback for browsers that don't support screen.orientation
            fromEvent(window, 'orientationchange')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.checkDeviceAndOrientation();
                });
        }

        // Listen for resize events
        fromEvent(window, 'resize')
            .pipe(debounceTime(250), takeUntil(this.destroy$))
            .subscribe(() => {
                this.checkDeviceAndOrientation();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkDeviceAndOrientation() {
        this.isMobile = this.utilService.isResponsiveMobileView();
        this.isMobileOrIpad = this.utilService.isMobileBrowser() || this.utilService.isIpad();
        this.isLandscape = window.matchMedia('(orientation: landscape)').matches;

        if ((!this.isMobile || this.isLandscape) && this.faqTitles && this.faqTitles.length > 0) {
            this.selectedFaq(this.faqTitles[0]);
        }
    }

    getFaqData() {
        this.addonsService.getFaq().subscribe((res) => {
            this.faqData = this.functionMapApiResponseToFaqData(res);
            this.faqTitles = Object.keys(this.faqData);
            this.checkDeviceAndOrientation(); // Call this again to set initial state correctly
        });
    }

    functionMapApiResponseToFaqData(apiResponse) {
        const faqData = {};
        apiResponse.data.forEach((item) => {
            const title = item.attributes.title;
            item.attributes.question_answers.data.sort((a, b) => a.attributes.order - b.attributes.order);
            const questions = item.attributes.question_answers.data.map((qa) => ({
                question: qa.attributes.question,
                answer: this.parseMarkdown(qa.attributes.answer)
            }));
            faqData[title] = questions;
        });
        return faqData;
    }

    parseMarkdown(markdown: string): string {
        return marked(markdown);
    }

    selectedFaq(faq: string) {
        this.selectedLinks = true;
        this.selectedLink = faq;
        this.faqs = this.faqData[faq];
    }

    backarrow() {
        this.selectedLink = '';
    }
}
