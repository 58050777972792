import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
    UserService,
    AppService,
    AuthService,
    GaDiagnosticService,
    UtilService,
    LocalStorageService
} from 'src/app/core';
import { REG_EX } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/integrations/modules/myjio/local.service';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
    hash;
    email;
    firstName;
    lastName;

    accountInfoForm: any;
    errors: any = {};
    validPassword = false;
    showPassword = false;
    showConfirmPassword = false;
    txParams = {
        characters: { value: 8 },
        alphabets: { value: 1 },
        numbers: { value: 1 }
    };

    showDialog = false;
    enableProceedBtn = true;
    userDetailsStage = false;
    isConfirmingPassword: boolean;
    isJioPc: boolean;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
        private appService: AppService,
        private userService: UserService,
        private gaService: GaDiagnosticService,
        private translateService: TranslateService,
        private utilService: UtilService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((queryParams) => {
            this.hash = queryParams.hash;
            this.email = queryParams.email;
            this.firstName = queryParams.name;
            this.lastName = queryParams.lname;
        });
        this.accountInfoForm = this.fb.group({
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });
        this.accountInfoForm.get('password').valueChanges.subscribe(() => {
            this.validPassword = this.validatePassword(this.accountInfoForm.get('password').value);
        });
        this.accountInfoForm.get('confirmPassword').valueChanges.subscribe(() => {
            this.isConfirmingPassword = true;
        });
        this.isJioPc = this.utilService.isJioPcCheck();
        this.gaService.sendPageView({
            page_title: 'activate_page',
            page_path: '/activate'
        });
        this.gaService.sendEvent({ action: 'Verify Email', category: 'Sign Up', label: 'Success' });
    }

    validateAccountInfoForm() {
        this.errors = {};
        if (this.accountInfoForm.value.password !== this.accountInfoForm.value.confirmPassword) {
            this.errors.confirmPassword = this.translateService.instant('tostrs.passwords_do_not_match_please_reenter');
            return false;
        }
        if (!REG_EX.NAME.test(this.accountInfoForm.value.firstName)) {
            this.errors.firstName = this.translateService.instant('tostrs.please_enter_a_valid_name');
            return false;
        }
        return true;
    }

    submit() {
        if (this.accountInfoForm.valid && this.validPassword) {
            if (this.validateAccountInfoForm()) {
                if ((this.accountInfoForm.value.password || '').indexOf(' ') > -1) {
                    this.errors.global = this.translateService.instant(
                        'tostrs.password_should_be_of_minimum_length_8_and_should_contains_atleast_1_uppercase_1_lowercase_1_number_and_1_special_character'
                    );
                    return;
                }
                this.enableProceedBtn = false;
                this.userService.setOTTPassword(this.accountInfoForm.get('password').value, this.hash).subscribe(
                    () => this.login(),
                    (err) => {
                        this.errors.global = err.error.message;
                        this.enableProceedBtn = true;
                    }
                );
            }
        }
    }

    login() {
        this.authService
            .login({
                email: this.email,
                password: this.accountInfoForm.get('password').value
            })
            .subscribe(
                () =>
                    this.userService.getUser().subscribe(() => {
                        this.goToOnboarding();
                    }),
                (err) => {
                    this.errors.global = err.error.message;
                    this.enableProceedBtn = true;
                }
            );
    }

    goToOnboarding() {
        this.router.navigate(['/onboarding']);
    }

    validatePassword(password) {
        this.errors.password = this.utilService.validatePassword(password);
        return !!this.errors.password ? false : true;
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
    }
}
