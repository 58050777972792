import { Injectable } from '@angular/core';
import { EventsPlusService } from './events-plus.service';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { textElementView } from 'src/app/events-studio/elements/views/textElementView';
import { imageElementView } from 'src/app/events-studio/elements/views/imageElementView';
import { userFeedElementView } from 'src/app/events-studio/elements/views/userFeedElementView';
import { imageAssetType } from 'src/app/events-studio/elements/models/elementTypesEnum';
import { UtilService } from './util.service';
import { da } from 'date-fns/locale';
import { AppLoggerService } from './app-logger.service';
import { MarketplaceService } from './marketplace.service';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { videoElement } from 'src/app/events-studio/elements/models/videoElement';
import { baseSceneView } from 'src/app/events-studio/scene/views/baseSceneView';
import { CallViewStateManagerService } from './call-view-state-manager.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './user.service';
import { CallStateManagerService } from '../classes/call-state-manager.service';
import { MediaStreamRetrieverService } from './media-stream-retriever.service';
import { MediaOrchestratorService } from 'src/app/shared/services/media-orchestrator.service';
import { AppService } from './app.service';
import { WebinarConferenceService } from 'src/app/conference/services';

@Injectable({
    providedIn: 'root'
})
export class CanvasManagerService {
    sceneData;
    selectedScene;
    canvasUpdates$ = new Subject();
    templateData;
    useDomEditor;
    selectedElementIdx;
    showRightPanel: boolean = false;
    showSlideShowControls: boolean = false;
    changesStack = [];
    currentStackIdx = -1;
    enableEditing: boolean = false;
    isInsideCall: any;
    showNoteOverlayIndex: number = -1;

    constructor(
        private eventsPlusService: EventsPlusService,
        private utilService: UtilService,
        private appLoggerService: AppLoggerService,
        private marketplaceService: MarketplaceService,
        private toasterService: ToastrService,
        private callViewStateManagerService: CallViewStateManagerService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private callStateManagerService: CallStateManagerService,
        private mediaStreamRetreiver: MediaStreamRetrieverService,
        private mediaOrchestratorService: MediaOrchestratorService,
        private appService: AppService,
        private webinarConferenceService: WebinarConferenceService
    ) {}

    setEditingAccess() {
        this.enableEditing =
            !this.templateData ||
            !this.templateData.id ||
            this.callViewStateManagerService.isHost ||
            this.templateData?.owners.findIndex((o) => o.jiomeet_user_id === this.userService.getUserSync()._id) > -1;
        this.isInsideCall = this.callStateManagerService.isUserInCall === 'mainstage';
    }

    initializeCanvas(templateData) {
        const shouldCustomize = this.activatedRoute.snapshot.queryParams.customize === 'true';
        if (shouldCustomize) {
            this.templateData = {
                scenes: templateData.scenes,
                owners: [
                    {
                        jiomeet_user_id: this.userService.getUserSync()._id
                    }
                ]
            };
        } else {
            this.templateData = templateData;
        }
        this.templateData.lastModified = new Date().getTime();
        this.addDocumentListeners();
        this.listenToSidePanelUpdates();
        this.setEditingAccess();
        this.selectSceneByNumber(0, false);
    }

    addDocumentListeners() {
        this.addOutsideClickListener();
        this.addListenerForUndo();
    }

    addListenerForUndo() {
        document.addEventListener('keydown', (event) => {
            if (event.ctrlKey || (event.metaKey && event.key === 'z' && !event.shiftKey)) {
                this.undoLastChange();
            } else if (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
                // Call the function to redo the last undone change
                this.redoLastChange();
            } else if (
                event.key == 'ArrowUp' ||
                event.key == 'ArrowDown' ||
                event.key == 'ArrowLeft' ||
                event.key == 'ArrowRight'
            ) {
                this.handleArrowsKey(event.key);
            }
        });
    }

    handleArrowsKey(key, fast = false) {
        const selectedElement = this.getSelectedElement();
        if (!selectedElement) return;
        let tx = 0;
        let ty = 0;
        switch (key) {
            case 'ArrowUp':
                this.appLoggerService.log('Up key pressed');
                tx = 0;
                ty = -1;
                break;
            case 'ArrowRight':
                this.appLoggerService.log('Right key pressed');
                tx = 1;
                ty = 0;
                break;
            case 'ArrowDown':
                this.appLoggerService.log('Down key pressed');
                tx = 0;
                ty = 1;
                break;
            case 'ArrowLeft':
                this.appLoggerService.log('Left key pressed');
                tx = -1;
                ty = 0;
                break;
            default:
                break;
        }
        this.updateElementPositionData(
            this.selectedElementIdx,
            this.eventsPlusService.percentageToPixel(
                selectedElement.position.left,
                this.getPlaygroundElement().clientWidth
            ),
            this.eventsPlusService.percentageToPixel(
                selectedElement.position.top,
                this.getPlaygroundElement().clientHeight
            ),
            tx,
            ty
        );
        this.reRenderElement(this.getSelectedElement(), this.selectedElementIdx);
    }

    listenToSidePanelUpdates() {
        this.eventsPlusService.togglePanelSubject.subscribe((data) => {
            this.canvasUpdates$.next({
                type: 'resizeElements'
            });
        });
    }

    addOutsideClickListener() {
        document.addEventListener('click', (e) => {
            let boundaryClassName = 'event-custom-body';
            let editorClass = 'playground-wrapper';
            let slideShowClass = 'slide-show-controls';
            let target: any = e.target;
            let search = true;

            while (target && search) {
                if (
                    !target.classList?.contains(boundaryClassName) &&
                    !target.classList?.contains(editorClass) &&
                    !target.classList?.contains(slideShowClass)
                ) {
                    target = target.parentNode;
                } else if (target.classList?.contains(editorClass) || target.classList?.contains(slideShowClass)) {
                    search = false;
                    break;
                } else if (target.classList?.contains(boundaryClassName)) {
                    this.deSelectAllElementsInCanvas();
                    search = false;
                    break;
                }
            }
        });
    }

    showLayersPanel() {
        this.eventsPlusService.togglePanel({
            type: 'layers'
        });
    }

    emitCanvasUpdate(event) {
        this.canvasUpdates$.next(event);
    }

    addEventListenerToDeselectElement() {
        const parentDiv = document.getElementsByClassName('event-custom-canvas')[0];
        parentDiv.addEventListener('click', (event) => {
            const { target } = event;
            if (target instanceof HTMLElement) {
                const targetClassName = target.className;
                if (targetClassName === 'upper-canvas ') {
                    event.stopPropagation();
                } else {
                    this.selectedElementIdx = null;
                }
            }
        });

        var target = document.getElementsByClassName('editable-view')[0];

        var observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation && mutation.addedNodes) {
                    mutation.addedNodes.forEach(function (elm: HTMLElement) {
                        if (elm && elm.className.split(' ').includes('slide-show-controls')) {
                            elm.addEventListener('click', (event) => {
                                event.stopPropagation();
                            });
                        }
                    });
                }
            });
        });

        observer.observe(target, {
            childList: true
        });
    }

    async selectSceneByNumber(sceneNumber, updateThumbnail) {
        // sceneNumber is sceneIndex
        this.appLoggerService.log('Saving existing changes to thumbnail');
        this.deSelectAllElementsInCanvas();
        if (this.changesStack.length > 0 && updateThumbnail) {
            this.emitCanvasUpdate({
                type: 'showLoader'
            });
            await this.utilService.holdExecution(0);
            await this.updateSelectedSceneThumbnail();
        }
        await this.utilService.holdExecution(0);
        this.removeExistingElementsInEditor();
        this.appLoggerService.log('Going to next scene');
        this.selectedScene = sceneNumber + 1;
        this.eventsPlusService.selectedScene = this.selectedScene;
        this.sceneData = this.templateData.scenes[this.selectedScene - 1];
        await this.loadAssets(this.sceneData.elements);
        this.canvasUpdates$.next({
            type: 'buildEditor',
            data: this.sceneData.elements
        });
        this.deSelectAllElementsInCanvas();
        this.emitCanvasUpdate({
            type: 'hideLoader'
        });
        this.changesStack = [];
    }

    removeExistingElementsInEditor() {
        // this.sceneData.elements = [];
        // return;
        // const playgroundContainer = document.getElementById('playgroundWrapper');
        // while (playgroundContainer?.firstChild) {
        //     playgroundContainer.removeChild(playgroundContainer.firstChild);
        // }
        this.canvasUpdates$.next({
            type: 'clearEditor'
        });
    }

    async updateThumbnailOnSave() {
        if (this.changesStack?.length < 1) return;
        this.deSelectAllElementsInCanvas();
        if (this.changesStack.length > 0) {
            this.emitCanvasUpdate({
                type: 'showLoader'
            });
            await this.utilService.holdExecution(0);
            await this.updateSelectedSceneThumbnail();
            this.emitCanvasUpdate({
                type: 'hideLoader'
            });
        }
    }

    async updateSelectedSceneThumbnail() {
        // if (this.callStateManagerService.isUserInCall) {
        //     return;
        // }
        const canvasConverterConfig = this.appService.getConfigVariableWithDefault('canvasConverter', {
            useCORS: true,
            allowTaint: true,
            foreignObjectRendering: true
        });
        try {
            const playgroundCanvas = await html2canvas(document.getElementById('playgroundWrapper'));
            const base64image = playgroundCanvas.toDataURL('image/png');
            const thumbnailBlob = await (await fetch(base64image)).blob();

            await this.eventsPlusService.updateSceneThumbnail(thumbnailBlob, this.selectedScene - 1);
        } catch (err) {
            this.appLoggerService.log('Error while capturing thumbnail', err);
        }
    }

    addOrUpdateElementToCanvas(data) {
        if (!this.canProceedWithAdditionOrUpdation(data)) {
            return;
        }
        let updatedObj;
        const prevObj = this.utilService.clone(this.getSelectedElement());
        if (this.selectedElementIdx && this.selectedElementIdx !== -1) {
            updatedObj = this.updateElementInCanvas(data);
        } else {
            updatedObj = this.addElementInCanvas(data);
        }
        this.addNewChangeToStack(prevObj, this.utilService.clone(updatedObj));
        if (this.selectedElementIdx === -1 || !this.selectedElementIdx) {
            this.selectElementInCanvas(this.sceneData.elements.length - 1);
        }
        this.rebuildEditor();
    }

    canProceedWithAdditionOrUpdation(data) {
        /**
         * Any restrictions for element addition by type will return null
         */
        let canProceed = false;
        switch (data.type) {
            case 'video': {
                const idxFound = this.sceneData.elements.findIndex((e) => e.type === data.type);
                if (idxFound > -1 && this.sceneData.elements[idxFound].uploadType == 'cloudvideo') {
                    this.toasterService.info(`Only one Cloud video allowed per scene`);
                    canProceed = false;
                } else {
                    canProceed = true;
                }
                break;
            }
            case 'screenshare': {
                if (this.sceneData.elements.findIndex((e) => e.type === data.type) > -1) {
                    this.toasterService.info(`Only one ${data.type} allowed per scene`);
                    canProceed = false;
                } else {
                    canProceed = true;
                }
                break;
            }
            default: {
                canProceed = true;
                break;
            }
        }
        return canProceed;
    }

    addNewChangeToStack(oldData, updatedEl) {
        // Whenever new change is made, there will be no redo option

        if (this.currentStackIdx !== this.changesStack.length - 1) {
            let itr = this.currentStackIdx;
            this.changesStack.splice(itr + 1, this.changesStack.length - 1 - itr);
        }
        this.changesStack.push({
            type: 'elementUpdate',
            newData: updatedEl,
            oldData: oldData,
            assetId: updatedEl?.id ?? oldData?.id,
            prevElIndex: oldData ? this.sceneData.elements.findIndex((e) => e.id === oldData?.id) : -1
        });
        this.currentStackIdx++;
    }

    undoLastChange() {
        if (this.changesStack.length > 0) {
            const currentChange = this.changesStack[this.currentStackIdx];
            // const elIndex = this.sceneData.elements.findIndex((e) => e.id === currentChange.assetId);
            const elIndex = currentChange.prevElIndex;
            if (!currentChange.oldData) {
                this.sceneData.elements.splice(elIndex, 1);
            } else if (!currentChange.newData) {
                this.sceneData.elements.splice(currentChange.prevElIndex, 0, currentChange.oldData);
            } else {
                this.sceneData.elements[elIndex] = currentChange.oldData;
            }
            this.reRenderElement(this.sceneData.elements[elIndex], elIndex);
            this.currentStackIdx--;
        }
    }

    redoLastChange() {
        if (this.changesStack.length > 0 && this.currentStackIdx < this.changesStack.length - 1) {
            const nextChange = this.changesStack[this.currentStackIdx + 1];
            let elIndex = nextChange.prevElIndex;
            if (!nextChange.oldData) {
                elIndex = nextChange.prevElIndex;
                this.sceneData.elements.splice(nextChange.prevElIndex, 0, nextChange.newData);
            } else if (!nextChange.newData) {
                this.sceneData.elements.splice(elIndex, 1);
            } else {
                elIndex = this.sceneData.elements.findIndex((e) => e.id === nextChange.assetId);
                this.sceneData.elements[elIndex] = nextChange.newData;
            }
            this.reRenderElement(this.sceneData.elements[elIndex], elIndex);
            this.currentStackIdx++;
        }
    }

    updateElementInCanvas(data) {
        let currentElement = this.getSelectedElement();
        switch (data.type) {
            case 'text':
                this.updateTextInCanvas(data);
                break;
            case 'image':
                currentElement.imageUrl = data.imageUrl;
                break;
            case 'userFeed':
                currentElement.placeholderImageUrl = data.placeholderImageUrl;
                // currentElement.clipPath = data.clipPath;
                currentElement.objectFit = data?.objectFit;
                currentElement.borderRadius = data?.borderRadius;
                currentElement.borderWidth = data?.borderWidth;
                currentElement.borderColor = data?.borderColor;
                currentElement.dimension.width = data.dimension.width;
                currentElement.dimension.height = data.dimension.height;
                currentElement.backgroundColor = data.backgroundColor;
                break;
        }
        this.reRenderElement(currentElement, this.selectedElementIdx, 'onlyCanvasUpdate');
        return this.utilService.clone(currentElement);
    }

    getCanvasUpdates() {
        return this.canvasUpdates$;
    }

    reRenderElement(asset, indexToRender = this.selectedElementIdx, updateType = 'both') {
        this.emitCanvasUpdate({
            type: 'rerender',
            assetIndex: indexToRender,
            data: asset,
            updateType
        });
    }

    updateTextInCanvas(data) {
        // Called from panel
        const currEl = this.getSelectedElement();
        currEl.text = data.text;
        currEl.fontSize = data.fontSize;
        currEl.opacity = data.opacity;
        currEl.fontFamily = data.fontFamily;
        currEl.textAlign = data.textAlign;
        currEl.fontColor = data.fontColor;
        currEl.fontStyle = data.fontStyle;
        currEl.enableTicker = data.enableTicker;
        currEl.padding = data.padding;
        currEl.fontWeight = data.fontWeight;
        currEl.backgroundColor = data.backgroundColor;
        currEl.gradient = data.gradient;
        currEl.effects = data.effects;
    }

    addElementInCanvas(data) {
        // TODO: Make sure the id of asset is unique to scenea and asset
        let newElement;
        switch (data.type) {
            case 'text':
                newElement = this.addText(data);
                break;
            case 'image':
                newElement = this.addImage(data);
                break;
            case 'userFeed':
                newElement = this.addUserFeed(data);
                break;
            case 'screenshare':
                newElement = this.addScreenShare(data);
                this.appLoggerService.log('Screen', newElement);
                break;
            case 'cloudvideo':
                newElement = this.addScreenShare(data);
                break;
            case 'slideshow':
                newElement = this.addImage(data);
                break;
            case 'video':
                newElement = this.addVideo(data);
        }
        this.addNewElementToScene(newElement);
        return newElement;
    }

    addNewElementToScene(newElement) {
        this.sceneData.elements.push(newElement);
    }

    addText(data?): textElementView {
        // let height = this.eventsPlusService.percentageToPixel(_.get(data, "dimension.height", 60), this.canvasHeight);
        // let width = this.eventsPlusService.percentageToPixel(_.get(data, "dimension.width", 60), this.canvasWidth);
        let height = _.get(data, 'dimension.height', 30);
        let width = _.get(data, 'dimension.width', 30);
        return new textElementView({
            id: data.id || this.getUniqueIdForEntity(this.sceneData.elements, 'id'),
            name: 'new text',
            type: 'text',
            visible: true,
            zIndex: 1,
            position: {
                left: data.position ? data.position.left : this.getRandomInt(15, 30),
                top: data.position ? data.position.top : this.getRandomInt(15, 30)
            },
            dimension: {
                height: data.fontSize,
                width
            },
            rotation: data?.rotation || 0,
            text: data.text || 'this is the text to be displayed',
            enableTicker: data.enableTicker,
            enableHyperLink: data.enableHyperLink,
            textAlign: data.textAlign,
            hyperLinkInput: data.hyperLinkInput,
            fontColor: data.fontColor,
            fontStyle: data.fontStyle,
            fontFamily: data.fontFamily,
            fontSize: data.fontSize,
            opacity: data.opacity,
            transitions: {
                onAdding: '',
                onMove: '',
                onRemove: ''
            },
            gradient: data?.gradient,
            fontFamilyUrl: data?.fontFamilyUrl,
            fontWeight: data?.fontWeight,
            padding: data?.padding,
            backgroundColor: data?.backgroundColor,
            effects: data?.effects
        });
    }

    addImage(data, imageAssetType: imageAssetType = 'normal'): imageElementView {
        // let height = this.eventsPlusService.percentageToPixel(_.get(data, "dimension.height", 60), this.canvasHeight);
        // let width = this.eventsPlusService.percentageToPixel(_.get(data, "dimension.width", 60), this.canvasWidth);

        let height = _.get(data, 'dimension.height', 60);
        let width = _.get(data, 'dimension.width', 60);
        return new imageElementView({
            id: data.id || this.getUniqueIdForEntity(this.sceneData.elements, 'id'),
            name: 'new image',
            type: data.type,
            visible: true,
            zIndex: 1,
            position: {
                left: data.position ? data.position.left : 20,
                top: data.position ? data.position.top : 10
            },
            dimension: {
                width,
                height
            },
            rotation: data?.rotation || 0,
            imageUrl: this.eventsPlusService.getUpdatedAkamaiUrl(data.imageUrl, 'feed'),
            urlList: data.urlList,
            isSlideShow: data.type === 'slideshow',
            placeholderImageUrl:
                'https://prestage.jiomeet.com/api/meeting/sm-5b9cbd3f-d62d-402d-ba66-37091468ccd1/webinar/welcomebanner.png?rnd=1695819806064',
            imageAssetType: imageAssetType,
            transitions: {
                onAdding: '',
                onMove: '',
                onRemove: ''
            },
            assetId: data?.assetId,
            isBackground: data?.isBackground
        });
    }

    addVideo(data): videoElement {
        /**
         * data Format
         * {
                type: 'video',
                mediaUrl: media.mediaUrl,
                urlList: [],
                mediaId: media.mediaId,
                uploadType: 'cloudvideo' // Either stored in server or stored in addons
            }

            uploadType is not yet being used
         */
        let height = _.get(data, 'dimension.height', 30);
        let width = _.get(data, 'dimension.width', 30);
        return {
            id: data.id || this.getUniqueIdForEntity(this.sceneData.elements, 'id'),
            name: 'Video',
            type: 'video',
            visible: true,
            zIndex: 2,
            removeBackground: false,
            mediaEngineId: '',
            userId: '',
            isCallAttached: false,
            position: {
                left: data.position ? data.position.left : 40,
                top: data.position ? data.position.top : 10
            },
            dimension: {
                width,
                height
            },
            rotation: data?.rotation || 0,
            placeholderImageUrl: this.eventsPlusService.getUpdatedAkamaiUrl(data.placeholderImageUrl),
            transitions: {
                onAdding: '',
                onMove: '',
                onRemove: ''
            },
            clipPath: data?.clipPath,
            mediaId: data?.mediaId,
            mediaUrl: data?.mediaUrl,
            uploadType: data?.uploadType,
            isBackground: data?.isBackground
        };
    }

    addScreenShare(data): userFeedElementView {
        /**
         * Both Screen share and cloud video placeholders handled in this block with different types
         */
        return new userFeedElementView({
            id: data.id || this.getUniqueIdForEntity(this.sceneData.elements, 'id'),
            name: 'new screen share feed',
            type: data.type,
            visible: true,
            zIndex: 2,
            removeBackground: false,
            mediaEngineId: '',
            userId: '',
            isCallAttached: false,
            position: {
                left: data.position ? data.position.left : 20,
                top: data.position ? data.position.top : 20
            },
            dimension: {
                height: data.dimension?.height || 40,
                width: data.dimension?.width || 40
            },
            rotation: data?.rotation || 0,
            placeholderImageUrl: this.eventsPlusService.getUpdatedAkamaiUrl(data.placeholderImageUrl),
            transitions: {
                onAdding: '',
                onMove: '',
                onRemove: ''
            },
            clipPath: data?.clipPath
        });
    }

    addUserFeed(data): userFeedElementView {
        let height = _.get(data, 'dimension.height', 30);
        let width = _.get(data, 'dimension.width', 30);
        return new userFeedElementView({
            id: data.id || this.getUniqueIdForEntity(this.sceneData.elements, 'id'),
            name: 'new user feed',
            type: 'userFeed',
            visible: true,
            zIndex: 2,
            removeBackground: false,
            mediaEngineId: '',
            userId: '',
            isCallAttached: false,
            position: {
                left: data.position ? data.position.left : 40,
                top: data.position ? data.position.top : 10
            },
            dimension: {
                width,
                height
            },
            rotation: data?.rotation || 0,
            placeholderImageUrl: this.eventsPlusService.getUpdatedAkamaiUrl(data.placeholderImageUrl),
            transitions: {
                onAdding: '',
                onMove: '',
                onRemove: ''
            },
            clipPath: data?.clipPath,
            borderRadius: data?.borderRadius,
            borderWidth: data?.borderWidth,
            borderColor: data?.borderColor,
            objectFit: data?.objectFit,
            backgroundColor: data?.backgroundColor
        });
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    stackUp(element, elIndex) {
        const sceneElements = this.getCurrentSceneElements();
        if (elIndex === sceneElements.length - 1) return; // Already at the top of stack
        const el1 = this.utilService.clone(element);
        const el2 = this.utilService.clone(sceneElements[elIndex + 1]);
        sceneElements[elIndex + 1] = el1;
        sceneElements[elIndex] = el2;
    }

    stackDown(element, elIndex) {
        const sceneElements = this.getCurrentSceneElements();
        if (elIndex === 0) return; // Already at the bottom of stack
        const el1 = this.utilService.clone(element);
        const el2 = this.utilService.clone(sceneElements[elIndex - 1]);
        sceneElements[elIndex - 1] = el1;
        sceneElements[elIndex] = el2;
    }

    reStackElement(byIndex, newIndex) {
        if (byIndex) {
        } else if (newIndex === '+1') {
        } else if (newIndex === '-1') {
        }
    }

    getCurrentSceneElements() {
        return this.sceneData.elements;
    }

    /**
     * Deletes an element from the current scene.
     * @param {number} elIndex - The index of the element to be deleted.
     */
    deleteElementInCurrentScene(elIndex) {
        // elIndex will be in range 0 to elts.length - 1
        const oldData = this.utilService.clone(this.sceneData.elements[elIndex]);
        this.addNewChangeToStack(oldData, null);
        const sceneElements = this.getCurrentSceneElements();
        sceneElements.splice(elIndex, 1);
        this.deSelectAllElementsInCanvas();
        this.rebuildEditor();
    }

    duplicateElement(elIndex) {
        const sceneElements = this.getCurrentSceneElements();
        const duplicateElementData = this.utilService.clone(this.getElementByAssetIndex(elIndex));
        sceneElements.push({
            ...duplicateElementData,
            id: this.getUniqueIdForEntity(this.sceneData.elements, 'id')
        });
    }

    getUniqueIdForEntity(entitiesList, key) {
        let uniqueId = this.getRandomInt(1, 10000);
        const foundId = entitiesList?.find((el) => _.get(el, key) == uniqueId);
        if (foundId) {
            return this.getUniqueIdForEntity(entitiesList, key);
        }
        return uniqueId;
    }

    rebuildEditor() {
        this.emitCanvasUpdate({
            type: 'rebuildEditor',
            data: this.sceneData.elements
        });
    }

    toggleVisibility(elIndex) {
        const sceneElements = this.getCurrentSceneElements();
        sceneElements[elIndex].visible = !sceneElements[elIndex].visible;
    }

    getScenesListObj() {
        /**
         * TODO: Clone sceneData instead of assigning the source key in templateData
         */
        // const scenesData = this.utilService.clone(this.templateData.scenes);
        // scenesData[this.selectedScene - 1] = this.sceneData;
        return this.templateData.scenes;
    }

    getSelectedElement() {
        if (!this.selectedElementIdx) return null;
        return this.sceneData.elements[this.selectedElementIdx];
    }

    getElementByAssetIndex(assetIndex) {
        if (typeof assetIndex == 'object' || assetIndex < 0 || assetIndex >= this.sceneData.elements.length) {
            return;
        }
        return this.sceneData.elements[assetIndex];
    }

    getDimensionRelativeToPlayground(value, direction = 'y', containerId = 'playgroundWrapper') {
        const playgroundContainer = document.getElementById(containerId);
        return this.eventsPlusService.pixelToPercentage(
            value,
            direction === 'x' ? playgroundContainer.clientWidth : playgroundContainer.clientHeight
        );
    }

    getAbsoluteDimensionWrtoPlayground(value, direction = 'y', containerId = 'playgroundWrapper') {
        const playgroundContainer = document.getElementById(containerId);
        return this.eventsPlusService.percentageToPixel(
            value,
            direction === 'x' ? playgroundContainer.clientWidth : playgroundContainer.clientHeight
        );
    }

    async selectElementInCanvas(elIndex) {
        const elementData = this.getElementByAssetIndex(elIndex);
        if (this.callViewStateManagerService.calloptionsPanelOpenState) {
            this.callViewStateManagerService.toggleCallOptionsPanel();
            await this.utilService.holdExecution(0);
        }
        this.eventsPlusService.togglePanel(elementData);
        this.deSelectAllElementsInCanvas(elIndex);
        this.selectedElementIdx = elIndex;
        this.elementControls(elementData);
    }

    elementControls(elementData) {
        if (elementData?.type === 'slideshow') {
            this.showSlideShowControls = true;
        }
    }

    deSelectAllElementsInCanvas(elIndex = null) {
        this.selectedElementIdx = null;
        this.showSlideShowControls = false;
        this.canvasUpdates$.next({
            type: 'deselectAll',
            id: elIndex
        });
        // if (!elIndex) {
        //     this.showLayersPanel();
        // }
    }

    assignUserToFeed(pUid) {
        const selEl = this.getSelectedElement();
        if (!selEl) {
            this.appLoggerService.log('No element is selected to map the user to feed');
        }
        if (selEl.type !== 'userFeed') {
            this.appLoggerService.log('The selected Element is not an User Feed');
        }
        selEl.mediaEngineId = pUid;
        this.canvasUpdates$.next({
            type: 'userMapToFeed',
            assetIndex: this.selectedElementIdx,
            participantId: pUid // Storing userId for jm-media
        });
        this.eventsPlusService.showSpeakersListOverlay = false;
    }

    addNewScene() {
        const newSceneId = this.getUniqueIdForEntity(this.templateData.scenes, 'sceneId');
        let scene = new baseSceneView({
            sceneNumber: this.eventsPlusService.scenesList.length + 1,
            thumbnail: null,
            elements: [],
            aspectRatio: 16 / 9,
            inTransition: '',
            outTransition: '',
            notes: null,
            sceneId: newSceneId,
            parentSceneId: null,
            title: `New Scene ${newSceneId}`
        });
        // this.eventsPlusService.scenesList.push(scene);
        this.templateData.scenes.push(scene);
    }

    duplicateScene(sceneIndex) {
        const clonedSceneData = this.utilService.clone(this.eventsPlusService.scenesList[sceneIndex]);
        this.eventsPlusService.scenesList.splice(sceneIndex + 1, 0, {
            ...clonedSceneData,
            sceneNumber: sceneIndex + 2,
            sceneId: this.getUniqueIdForEntity(this.templateData.scenes, 'id'),
            parentSceneId: clonedSceneData.parentSceneId ?? clonedSceneData.sceneId
        });
        this.updateSceneNumbers();
    }

    updateSceneNumbers() {
        this.eventsPlusService.scenesList.forEach((scene, idx) => {
            scene.sceneNumber = idx + 1;
        });
    }

    updateSceneNumber(sceneIndex, sceneNumber) {
        this.eventsPlusService.scenesList[sceneIndex].sceneNumber = sceneNumber;
    }

    deleteScene(sceneIndex) {
        this.eventsPlusService.scenesList.splice(sceneIndex, 1);
        this.updateSceneNumbers();
    }

    changeSlide(value) {
        const selectedEl = this.getSelectedElement();
        const idx = selectedEl.urlList.findIndex((url) => url.url === selectedEl.imageUrl);
        selectedEl.imageUrl =
            selectedEl.urlList[(idx + value + selectedEl.urlList.length) % selectedEl.urlList.length].url;
        this.reRenderElement(selectedEl);
    }

    publishChanges() {}

    removeCameraInput(assetIdx) {
        (this.eventsPlusService.scenesList[this.selectedScene - 1].elements[assetIdx] as any).mediaEngineId = '';
        this.canvasUpdates$.next({
            type: 'userFeedRemoved',
            assetIndex: this.selectedElementIdx
        });
        // assetIdx and selectedElementIdx should be same
    }

    async updateThumbnailInMetaData(thumbnailId) {
        const payload = {
            id: this.templateData.id,
            preview_img_low_res: thumbnailId
        };
        await this.marketplaceService.updateStudioTemplateMeta(payload);
    }

    updateElementDimensionData(assetIndex, width, height, playgroundContainer) {
        const prevObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        this.sceneData.elements[assetIndex].dimension.width = this.eventsPlusService.pixelToPercentage(
            width,
            playgroundContainer.clientWidth
        );
        this.sceneData.elements[assetIndex].dimension.height = this.eventsPlusService.pixelToPercentage(
            height,
            playgroundContainer.clientHeight
        );
        const updatedObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        this.addNewChangeToStack(prevObj, updatedObj);
        this.reRenderElement(updatedObj, assetIndex, 'onlyPanelUpdate');
    }

    updateElementPositionData(
        assetIndex,
        left,
        top,
        tx,
        ty,
        playgroundContainer = document.getElementById('playgroundWrapper')
    ) {
        const prevObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        this.sceneData.elements[assetIndex].position.left = this.eventsPlusService.pixelToPercentage(
            parseFloat(left) + tx,
            playgroundContainer.clientWidth
        );
        this.sceneData.elements[assetIndex].position.top = this.eventsPlusService.pixelToPercentage(
            parseFloat(top) + ty,
            playgroundContainer.clientHeight
        );
        const updatedObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        this.addNewChangeToStack(prevObj, updatedObj);
    }

    updateElementRotation(assetIndex, rotation) {
        const prevObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        this.sceneData.elements[assetIndex].rotation = rotation;
        const updatedObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        this.addNewChangeToStack(prevObj, updatedObj);
    }

    updateFontSize(assetIndex, fontSize) {
        const prevObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        const container = document.getElementById('playgroundWrapper');
        this.sceneData.elements[assetIndex].fontSize = this.eventsPlusService.pixelToPercentage(
            parseFloat(fontSize),
            container.clientHeight
        );
        const updatedObj = this.utilService.clone(this.sceneData.elements[assetIndex]);
        this.addNewChangeToStack(prevObj, updatedObj);
    }

    updateHeightWithPadding(assetIndex, padding) {
        /**
         * Unused Function
         */
        const sceneElement = this.getElementByAssetIndex(assetIndex);
        if (sceneElement.type !== 'text') return;
        sceneElement.padding = padding;
    }

    getPlaygroundElement() {
        const playgroundContainer = document.getElementById('playgroundWrapper');
        return playgroundContainer;
    }

    async loadAssets(elements) {
        // Pass elements array
        const _loaders = [];
        elements.forEach((e) => {
            switch (e.type) {
                case 'image': {
                    _loaders.push(this.loadImage(e.imageUrl));
                    break;
                }
                case 'slideshow': {
                    _loaders.push(this.loadImage(e.imageUrl));
                    e.urlList.forEach((url) => {
                        this.loadImage(url);
                    });
                }
                case 'video': {
                    if (e.uploadType == 'background') {
                        _loaders.push(this.loadImage(e.placeholderImageUrl));
                    } else if (this.callViewStateManagerService.isHost) {
                        _loaders.push(this.eventsPlusService.getVideoDetails(e.mediaId));
                    }
                    break;
                }
            }
        });
        await Promise.all(_loaders);
    }

    loadImage(url) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                resolve(image);
            };
            image.onerror = (e) => {
                // TODO: Load default Image on error
                this.appLoggerService.error('Error while loading image', e);
                resolve(null);
            };
            image.src = url;
        });
    }

    updateScenesList(savedTemplate) {
        const remoteScenes = savedTemplate.scenesList;
        // const currentUpdateAvailable = !_.isEqual(this.templateData.scenes[this.selectedScene - 1], remoteScenes[this.selectedScene - 1]);
        const currentUpdateAvailable = savedTemplate?.lastModified > this.templateData?.lastModified;
        if (currentUpdateAvailable) {
            this.templateData.scenes = remoteScenes;
            this.sceneData = this.templateData.scenes[this.selectedScene - 1];
            this.templateData.lastModified = savedTemplate.lastModified;
            this.eventsPlusService.scenesList = remoteScenes;
            this.emitCanvasUpdate({
                type: 'rebuildEditor',
                data: remoteScenes[this.selectedScene - 1].elements
            });
        }
    }

    playSceneById(sceneToBePlayed) {
        this.stopCloudVideoOnSceneChange();
        this.eventsPlusService
            .publishCustomLayout(this.callStateManagerService.getCurrentMeetingId(), sceneToBePlayed + 1)
            .subscribe(
                (res) => {
                    this.eventsPlusService.scenePlayingNow = sceneToBePlayed + 1;
                    this.sendRtmMessageOnSceneUpdate();
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    async stopCloudVideoOnSceneChange() {
        if (
            this.callViewStateManagerService?.roomStatus?.isSharing &&
            this.mediaStreamRetreiver.videoService.cloudVideoStream
        ) {
            const cloudVideoPayload = {
                action: 'stop',
                mediaId: this.callViewStateManagerService.roomStatus.mediaInfo.mediaId,
                jiomeetId: this.callStateManagerService.meetingObj.jiomeetId,
                participantUri: this.mediaStreamRetreiver.getLocalParticipantId()
            };
            await this.webinarConferenceService.shareMedia(cloudVideoPayload).toPromise();
        }
    }

    stopScreenShareOnScenePlay() {
        if (
            this.callViewStateManagerService.roomStatus?.isSharing &&
            this.mediaStreamRetreiver.videoService.screenSharing
        ) {
            this.mediaOrchestratorService.handleStopScreenShare(this.callStateManagerService.meetingObj);
        }
    }

    setBackgroundStatus(assetIndex, value) {
        this.sceneData.elements[assetIndex].isBackground = value;
    }

    sendRtmMessageOnSceneUpdate() {
        this.mediaStreamRetreiver.sendChatMessage({
            type: 'PublicChat',
            message: 'STUDIO_SCENE_UPDATE'
        });
    }
}
