import {
    AfterViewInit,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { HostDirective } from 'src/app/shared/directives/host.directive';
import { AudioSettingsComponent } from './audio-settings/audio-settings.component';
import { VideoSettingsComponent } from './video-settings/video-settings.component';
import { ProfilesettingsComponent } from './profilesettings/profilesettings.component';
import { PmisettingsComponent } from './pmisettings/pmisettings.component';
import { LanguagesettingsComponent } from './languagesettings/languagesettings.component';
import { AppService, RtcService, UserService } from 'src/app/core';
import { LivestreamingSettingsComponent } from './livestreaming-settings/livestreaming-settings.component';
import { VirtualBackgroundComponent } from './virtual-background/virtual-background.component';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { PREPARATORY_SETTINGS } from 'src/app/constants';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { TestContainerComponent } from 'src/app/pre-call-testing/components/test-container/test-container.component';
import { ConnectivityTestComponent } from 'src/app/pre-call-testing/components/connectivity-test/connectivity-test.component';
import { PerformanceTestComponent } from 'src/app/pre-call-testing/components/performance-test/performance-test.component';
import { ResultComponent } from 'src/app/pre-call-testing/components/result/result.component';
import { PreCallTestingService } from 'src/app/pre-call-testing/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-call-settings',
    templateUrl: './call-settings.component.html',
    styleUrls: ['./call-settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CallSettingsComponent implements AfterViewInit {
    @Input() selectedLabel: string;
    @Input() initializeConnector = false;
    @ViewChild(HostDirective) viewHost: HostDirective;
    @Output() afterClose: EventEmitter<any> = new EventEmitter();
    @Input() isNetworkingRoomCall: boolean = false;
    @Input() isPreCallTest: boolean = false;

    public factoryComponentRef: ComponentRef<
        | AudioSettingsComponent
        | VideoSettingsComponent
        | ProfilesettingsComponent
        | PmisettingsComponent
        | LanguagesettingsComponent
        | LivestreamingSettingsComponent
    >;
    disableVirtualBackground: boolean = false;

    public tabs = [];
    currentUser: any = {};
    private eventSubscription: Subscription;
    constructor(
        private compFactory: ComponentFactoryResolver,
        private appService: AppService,
        private userService: UserService,
        private rtcService: RtcService,
        private googleTagManager: GoogleTagMangerService,
        public preCallTestingService: PreCallTestingService,
        public callViewStateManagerService: CallViewStateManagerService
    ) {
        this.currentUser = this.userService?.getUserSync();
        this.disableVirtualBackground = this.rtcService?.disableVirtualBackgroundSupport();
        this.tabs = [
            {
                name: 'Audio',
                label: 'audio',
                tkey: 'joinmeeting_page_or_joincall.audio',
                img: 'assets/images/settings/incall_audio_setting.svg',
                altImg: 'assets/images/settings/incall_audio_setting_active.svg',
                settingRef: (): ComponentFactory<AudioSettingsComponent> =>
                    this.compFactory.resolveComponentFactory(AudioSettingsComponent)
            },
            {
                name: 'Video',
                label: 'video',
                tkey: 'joinmeeting_page_or_joincall.video',
                img: 'assets/images/settings/incall_video_setting.svg',
                altImg: 'assets/images/settings/incall_video_setting_active.svg',
                settingRef: (): ComponentFactory<VideoSettingsComponent> =>
                    this.compFactory.resolveComponentFactory(VideoSettingsComponent)
            },
            {
                name: 'Background',
                label: 'background',
                tkey: 'joinmeeting_page_or_joincall.background',
                img: 'assets/images/settings/incall_background_setting.svg',
                altImg: 'assets/images/settings/incall_background_setting_active.svg',
                settingRef: (): ComponentFactory<VirtualBackgroundComponent> =>
                    this.compFactory.resolveComponentFactory(VirtualBackgroundComponent),
                hideTab: this.hideVirtualBackground(),
                disabled: this.disableVirtualBackground
            },
            {
                name: 'Device Setup',
                label: 'devicesetup',
                tkey: 'joinmeeting_page_or_joincall.devicesetup',
                img: 'assets/images/settings/deviceSetup.svg',
                altImg: 'assets/images/settings/devicesetup_active.svg',
                visited: true,
                settingRef: (): ComponentFactory<TestContainerComponent> =>
                    this.compFactory.resolveComponentFactory(TestContainerComponent)
            },
            {
                name: 'Connectivity',
                label: 'connectivity',
                tkey: 'joinmeeting_page_or_joincall.connectivity',
                img: 'assets/images/settings/connectivity.svg',
                altImg: 'assets/images/settings/connectivity_active.svg',
                visited: false,
                settingRef: (): ComponentFactory<ConnectivityTestComponent> =>
                    this.compFactory.resolveComponentFactory(ConnectivityTestComponent)
            },
            {
                name: 'Performance',
                label: 'performance',
                tkey: 'joinmeeting_page_or_joincall.performance',
                img: 'assets/images/settings/performance.svg',
                altImg: 'assets/images/settings/performance_active.svg',
                visited: false,
                settingRef: (): ComponentFactory<PerformanceTestComponent> =>
                    this.compFactory.resolveComponentFactory(PerformanceTestComponent)
            },
            {
                name: 'Results',
                label: 'result',
                tkey: 'joinmeeting_page_or_joincall.results',
                img: 'assets/images/settings/result_icon.svg',
                altImg: 'assets/images/settings/results_active.svg',
                visited: false,
                settingRef: (): ComponentFactory<ResultComponent> =>
                    this.compFactory.resolveComponentFactory(ResultComponent)
            }
        ];
    }
    get isMobile() {
        return window.innerWidth < 768;
    }
    ngAfterViewInit() {
        if (this.selectedLabel) {
            setTimeout(
                () =>
                    this.openSetting(
                        this.tabs.find((tab) => tab.label === this.selectedLabel),
                        true
                    ),
                0
            );
        }
    }
    ngOnInit(): void {
        this.eventSubscription = this.preCallTestingService.getEvent().subscribe((data: string) => {
            if (data === 'Restart') {
                this.selectedLabel = 'devicesetup'; // Update selected tab
                this.tabs.forEach((tab, i) => {
                    if (i > 3) tab.visited = false;
                });
                setTimeout(
                    () =>
                        this.openSetting(
                            this.tabs.find((tab) => tab.label === this.selectedLabel),
                            true
                        ),
                    0
                );
            }
            if (data === 'Destroy') {
                this.afterClose.emit();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }

    openSetting(tab, onload = false) {
        if (onload || this.selectedLabel !== tab?.label) {
            this.factoryComponentRef?.destroy();
            this.selectedLabel = tab.label;
            let index = this.tabs.findIndex((tab) => tab.label === this.selectedLabel);
            this.tabs[index].visited = true;
            const component = tab.settingRef();
            this.factoryComponentRef = this.viewHost.viewContainerRef.createComponent(component);
            let eventDataAudio = PREPARATORY_SETTINGS.AUDIO;
            let eventDataVideo = PREPARATORY_SETTINGS.VIDEO;
            let eventDataBg = PREPARATORY_SETTINGS.VIRTUAL_BACKGROUND;
            switch (tab.label) {
                case 'audio':
                    this.googleTagManager.pushGoogleTagData(eventDataAudio);
                    break;
                case 'video':
                    this.googleTagManager.pushGoogleTagData(eventDataVideo);
                    break;
                case 'background':
                    this.googleTagManager.pushGoogleTagData(eventDataBg);
                    break;
            }
        }
    }

    close() {
        this.afterClose.emit();
    }

    hideVirtualBackground() {
        if (!!this.rtcService?.getConferenceInfo()) {
            if (!this.rtcService.isVirtualBackgroundEnabledForRoom()) {
                return true;
            }
        } else
            return !this.currentUser?._id ||
                (this.appService.enableVirtualBackground &&
                    (this.userService.getUserSync()?.webinarMediaEnginePreference === 'agora' ||
                        this.userService.getUserSync()?.webinarMediaEnginePreference === 'jm-media'))
                ? false
                : true;
    }
}
