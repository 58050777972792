import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService, LocalStorageService, AuthService, AppService, ExternalInterfaceService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class LocalService {
    meetingId;
    pwd;
    jwtToken;
    redirectUrl;
    totalCount = 0;
    viewAllData = [];
    hideSearchPageItems = false;
    upcomingEventsData = [];
    upcomingCount = 0;
    trendingEventsData = [];
    trendingCount = 0;
    recommendedEventsData = [];
    recommendedCount = 0;
    watchOnDemandEventsData = [];
    watchOnDemandCount = 0;

    constructor(
        private restService: RestService,
        private localStorageService: LocalStorageService,
        private authService: AuthService,
        private appService: AppService,
        private externalInterfaceService: ExternalInterfaceService
    ) {}

    getFilteredData(search, value = []) {
        const regexp = new RegExp(search, 'i');
        let result = [];
        result = [
            ...value.filter((item) => {
                return regexp.test(item.topic);
            })
        ];
        return of(result);
    }

    //(setUpcomingEvents) and (getUpcomingEvents) for carousel redirection
    setUpcomingEvents(data, totalCount) {
        this.upcomingEventsData = data;
        this.upcomingCount = totalCount;
    }
    getUpcomingEvents() {
        return { data: this.upcomingEventsData, totalCount: this.upcomingCount };
    }

    setTrendingEvents(data, totalCount) {
        this.trendingEventsData = data;
        this.trendingCount = totalCount;
    }
    getTrendingEvents() {
        return { data: this.trendingEventsData, totalCount: this.trendingCount };
    }

    setRecommendedEvents(data, totalCount) {
        this.recommendedEventsData = data;
        this.recommendedCount = totalCount;
    }
    getRecommendedEvents() {
        return { data: this.recommendedEventsData, totalCount: this.recommendedCount };
    }

    setWatchOnDemandEvents(data, totalCount) {
        this.watchOnDemandEventsData = data;
        this.watchOnDemandCount = totalCount;
    }
    getWatchOnDemandEvents() {
        return { data: this.watchOnDemandEventsData, totalCount: this.watchOnDemandCount };
    }

    setViewAllData(data, totalCount) {
        this.viewAllData = data;
        this.totalCount = totalCount;
    }

    getViewAllData() {
        return { data: this.viewAllData, totalCount: this.totalCount };
    }

    setJwtToken(jwtToken) {
        this.jwtToken = jwtToken;
    }

    setRedirectUrl(redirectUrl) {
        this.redirectUrl = redirectUrl;
    }

    getCarouselData() {
        window['profilingInfo']['carouselStartTime'] = Date.now();
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/carousels/myjio`, {
                headers: { Authorization: this.getUserToken() }
            })
            .pipe(
                map((res) => {
                    window['profilingInfo']['carouselLeaveTime'] = Date.now();
                    return res.carousels;
                })
            );
    }

    getEvents(listType, eventType, offset, limit) {
        window['profilingInfo']['getEventsStartTime'] = Date.now();
        return this.restService
            .get(
                `${this.appService.getEnvVariable(
                    'BASE_URL'
                )}/meeting/explore/general?listType=${listType}&eventType=${eventType}&offset=${offset}&limit=${limit}&source=myjio`
            )
            .pipe(
                map((res) => {
                    window['profilingInfo']['getEventsLeaveTime'] = Date.now();
                    return {
                        total: res.totalCount,
                        res: res.resultMeetingDetails.map((value) => {
                            return {
                                id: value._id,
                                startTime: new Date(value.startTime),
                                endTime: new Date(value.endTime),
                                currentTime: new Date(),
                                topic: value.topic,
                                isLive: value?.ongoing,
                                isRegistered: value.isRegistered,
                                backgroundImage: value.thumbnailLowRes,
                                host: value?.hostInfo?.name
                                    ? `${value?.hostInfo?.name} ${value?.hostInfo?.lname}`
                                    : 'JioEvents',
                                description: value.description,
                                markdownTopic: value.topicMarkdown?.text,
                                // backgroundVideo: value.welcomeBannerVideo?.uri,
                                registration: value.webinar.registration,
                                meetingUrl: value.webinar.activation?.url,
                                recordings: value?.recordings,
                                publishType: value.jioConf?.publishType,
                                sessionsEnabled: value?.webinar?.sessions?.enabled
                            };
                        })
                    };
                })
            );
    }

    getMyEvents(id) {
        return this.restService
            .get(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${id}?from=&to=&keyword=&type=all&source=myjio`,
                {
                    headers: { Authorization: this.getUserToken() }
                }
            )
            .pipe(
                map((res) =>
                    res.meetingDetails.map((value) => {
                        return {
                            id: value._id,
                            startTime: new Date(value.startTime),
                            endTime: new Date(value.endTime),
                            currentTime: new Date(),
                            topic: value.topic,
                            isLive: value.ongoing,
                            isRegistered: value.isRegistered,
                            backgroundImage: value.thumbnailLowRes,
                            host: value?.hostInfo?.name
                                ? `${value?.hostInfo?.name} ${value?.hostInfo?.lname}`
                                : 'JioEvents',
                            description: value.description,
                            markdownTopic: value.topicMarkdown?.text,
                            // backgroundVideo: value.welcomeBannerVideo?.uri,
                            registration: value.webinar.registration,
                            meetingUrl: value.webinar.activation?.url,
                            recordings: value?.recordings,
                            publishType: value.jioConf?.publishType,
                            type: value?.type,
                            webinar: value?.webinar
                        };
                    })
                )
            );
    }

    setMeetingId(meetingId) {
        this.meetingId = meetingId;
    }

    setPwd(pwd) {
        this.pwd = pwd;
    }

    getMeetingId() {
        return this.meetingId;
    }

    getPwd() {
        return this.pwd;
    }

    getUserToken() {
        return this.authService.getAuthInfo()?.jwt;
    }

    toggleHeaders(showhide) {
        this.externalInterfaceService.headerToggleAndroid(showhide);
        this.externalInterfaceService.headerToggleIOS(showhide);
    }
}
