<div
    class="schedule-main-container"
    [class.break]="session.type === 'break'"
    [class.ongoing-event]="status === 'Ongoing'"
    [class.session-hover]="status !== 'Completed' && session?.type !== 'break'"
    [class.completed-event]="status === 'Completed' && session?.type !== 'break'"
>
    <ng-container *ngIf="!isMobileOS">
        <div class="schedule-timing-section" [class.ongoing-time]="status === 'Ongoing'">
            <div class="time-status-container">
                <ng-container [ngTemplateOutlet]="event_time"></ng-container>
                <ng-container [ngTemplateOutlet]="event_status" *ngIf="session?.type !== 'break'"></ng-container>
            </div>
        </div>
        <div class="break-line" *ngIf="session?.type == 'break'"></div>
        <div class="schedule-detail-section" [class.hidemoreview]="hideViewMoreFlag">
            <div class="schedule-heading">
                <div class="heading-text">
                    <img
                        *ngIf="session?.type == 'break'"
                        src="assets/img/break_icn.svg"
                        alt=""
                        class="break-default-img"
                    />

                    <p *ngIf="session?.topic" class="heading" tabindex="0" aria-roledescription="">
                        <img
                            *ngIf="session?.preRecordedVideo?.enabled"
                            [src]="
                                isMyJio ? 'assets/images/icon-prerecord.svg' : 'assets/images/Pre-recorded-white.svg'
                            "
                            alt=""
                            class="break-default-img"
                        />
                        {{ session?.topic }}
                    </p>
                    <ng-container [ngTemplateOutlet]="event_desc"></ng-container>
                    <ng-container [ngTemplateOutlet]="event_speakers"></ng-container>
                </div>
                <div
                    class="d-flex action-area"
                    [class.justify-content-center]="!session?.showDetails && !session?.description"
                    [class.justify-content-end]="session?.showDetails && session?.description"
                    [class.align-items-center]="session?.showDetails && session?.description"
                >
                    <ng-container
                        [ngTemplateOutlet]="event_replay"
                        *ngIf="
                            status === 'Completed' &&
                            !webinarDetails.inviteOnly &&
                            webinarDetails.repeatOptions.repeat == 'none' &&
                            (webinarDetails.isReplayEnabled || replayDetails?.isReplayEnabled) &&
                            !isEmbedInMobile
                        "
                    ></ng-container>
                    <ng-container [ngTemplateOutlet]="event_details" *ngIf="status === 'Completed'"></ng-container>
                    <ng-container [ngTemplateOutlet]="event_action" *ngIf="status !== 'Completed'"></ng-container>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isMobileOS">
        <div class="schedule-timing-section-mobile d-flex align-items-center" *ngIf="session.type !== 'break'">
            <ng-container [ngTemplateOutlet]="event_time"></ng-container>
            <ng-container [ngTemplateOutlet]="event_status"></ng-container>
        </div>

        <div class="schedule-detail-section w-100">
            <div class="schedule-heading w-100" *ngIf="session.type !== 'break'">
                <div
                    class="heading-text d-flex"
                    [class.completed]="status === 'Completed' && session?.type !== 'break'"
                >
                    <img
                        *ngIf="session?.type == 'break'"
                        src="assets/img/general-interest-category-functional-filled.svg"
                        alt=""
                        class="break-default-img"
                    />
                    <img
                        *ngIf="session?.preRecordedVideo?.enabled"
                        [src]="isMyJio ? 'assets/images/icon-prerecord.svg' : 'assets/images/Pre-recorded-white.svg'"
                        alt=""
                        class="break-default-img"
                    />
                    <p
                        *ngIf="session?.topic"
                        class="heading"
                        [innerHTML]="sanitizeText(session.topicMarkdown?.text || session?.topic || '') | sanitizeHtml"
                    ></p>
                </div>
            </div>

            <ng-container *ngIf="session.type !== 'break'">
                <ng-container>
                    <ng-container [ngTemplateOutlet]="event_desc"></ng-container>
                    <ng-container [ngTemplateOutlet]="event_speakers"></ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="session.type === 'break'">
                <ng-container [ngTemplateOutlet]="event_desc_break"></ng-container>
            </ng-container>
            <div
                class="d-flex action-area"
                [class.justify-content-center]="!session?.showDetails && !session?.description"
                [class.justify-content-end]="session?.showDetails && session?.description"
                [class.align-items-center]="session?.showDetails && session?.description"
            >
                <ng-container
                    [ngTemplateOutlet]="event_replay"
                    *ngIf="
                        status === 'Completed' &&
                        !webinarDetails.inviteOnly &&
                        webinarDetails.repeatOptions.repeat == 'none' &&
                        (webinarDetails.isReplayEnabled || replayDetails?.isReplayEnabled) &&
                        !isEmbedInMobile
                    "
                ></ng-container>
                <ng-container [ngTemplateOutlet]="event_details" *ngIf="status === 'Completed'"></ng-container>
                <ng-container [ngTemplateOutlet]="event_action" *ngIf="status !== 'Completed'"></ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #event_status>
    <app-event-status
        [eventInfo]="session"
        [webinarDetails]="webinarDetails"
        [selectedDate]="selectedDate"
    ></app-event-status>
</ng-template>
<ng-template #event_time>
    <div tabindex="0" class="timings">
        <span aria-roledescription="">
            {{ session?.startTime | date: 'shortTime' }}
        </span>

        <span aria-roledescription="">
            to
            {{ session?.endTime | date: 'shortTime' }}
        </span>
    </div>
</ng-template>

<ng-template #event_details>
    <ng-container *ngIf="session.type !== 'break'">
        <div
            tabindex="0"
            aria-roledescription=""
            class="view-details-wrapper d-flex"
            [class.justify-content-center]="!session?.showDetails && !session?.description"
            [class.justify-content-end]="session?.showDetails && session?.description"
            [class.align-items-center]="session?.showDetails && session?.description"
        >
            <div class="view">
                <button
                    tabindex="0"
                    aria-roledescription=""
                    class="custom-button"
                    (click)="toggleSessionDetails(session)"
                >
                    {{ session?.showDetails ? 'Hide Details' : 'View Details' }}
                </button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #event_replay>
    <ng-container *ngIf="session.type !== 'break'">
        <div
            class="view-replay-wrapper d-flex"
            [class.justify-content-center]="!session?.showDetails && !session?.description"
            [class.justify-content-end]="session?.showDetails && session?.description"
            [class.align-items-center]="session?.showDetails && session?.description"
        >
            <div class="view replay">
                <button class="custom-button" (click)="checkReplayDetails(session)">Watch Replay</button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #event_action>
    <ng-container *ngIf="session.type !== 'break'">
        <div class="action-wrapper d-flex align-items-center">
            <div
                class="join"
                *ngIf="userRole === 'Attendee' || (userRole == 'Speaker' && session?.preRecordedVideo?.enabled)"
            >
                <button
                    tabindex="0"
                    aria-roledescription="join now"
                    role="button"
                    [attr.aria-label]="joinNowBtnLabel"
                    class="custom-button"
                    (click)="navigateToWebinarSession(status, session)"
                    [class.disabled]="isSpectrum ? false : useSessionJoinState ? !session.enableJoin : true"
                    (keyup.enter)="navigateToWebinarSession(status, session)"
                    *ngIf="!showRemindMe && !showRegister && userRole != 'Speaker'"
                    id="session-join-now-btn"
                >
                    Join Now
                </button>
                <!-- <button tabindex="0" aria-roledescription="join now" role="button" [attr.aria-label]="joinNowBtnLabel"
                class="custom-button"
                (click)="navigateToWebinarSession(status)"
                (keyup.enter)="enterPressed = true; navigateToWebinarSession(status)"
                [disabled]="(useSessionJoinState ? !session.enableJoin : true) || enterPressed"
                *ngIf="!showRemindMe && !showRegister">
            Join Now
        </button> -->
                <!-- <button tabindex="0" aria-roledescription="join now" role="button" [attr.aria-label]="joinNowBtnLabel"
        class="custom-button"
        (click)="navigateToWebinarSession(status)"
        (keyup.enter)="disableButton()"
        [disabled]="useSessionJoinState ? !session.enableJoin : true"
        *ngIf="!showRemindMe && !showRegister">
    Join Now
</button> -->

                <button
                    class="custom-button btn-remind-me"
                    [style.background-color]="brandDetails?.welcomeTheme?.background"
                    (click)="handleRemindMe()"
                    *ngIf="showRemindMe"
                >
                    {{ isSpectrum ? 'Join Now' : 'Remind Me' }}
                </button>
                <button
                    class="custom-button btn-remind-me"
                    [style.background-color]="brandDetails?.welcomeTheme?.background"
                    (click)="handleRemindMe()"
                    [class.disabled]="(isMyJio && !userHasRegistered) || session?.status === 'ended'"
                    *ngIf="showRegister && userRole != 'Speaker'"
                >
                    Register
                </button>
            </div>
            <div class="join speaker" *ngIf="userRole === 'Speaker'">
                <button
                    class="custom-button"
                    (click)="navigateToWebinarSession()"
                    *ngIf="
                        selectedDate && !isSessionOnSameDay ? isRecurring || this.webinarDetails.isMultiDay : enableJoin
                    "
                    [class.disabled]="!isSessionOnSameDay && isRecurring"
                    id="joinToggleBtn"
                >
                    Join {{ status === 'Ongoing' ? 'Session' : isRecurring ? 'Now' : 'Backstage' }}
                </button>
            </div>
            <div class="heading-icons" *ngIf="status !== 'Ongoing'">
                <app-add-to-calendar
                    [joinDetail]="session"
                    *ngIf="session"
                    [showSocialDropDown]="!isMobileOS"
                    [userRole]="userRole"
                    [isSessionsEnabled]="true"
                    [isSessionPage]="true"
                ></app-add-to-calendar>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #event_desc>
    <div class="schedule-description-wrapper" *ngIf="!!session.showDetails || status !== 'Completed'">
        <div class="schedule-description" *ngIf="session?.description">
            <div class="hideviewmore" *ngIf="hideViewMoreFlag">
                <p
                    [innerHTML]="
                        session.descriptionMarkdown?.text || session?.description || '' | sanitizeText | sanitizeHtml
                    "
                ></p>
            </div>
            <div *ngIf="!hideViewMoreFlag">
                <app-read-more
                    *ngIf="session?.description"
                    [noOfLines]="isMobileOS ? 4 : 3"
                    [lineHeight]="isMobileOS ? 19 : 20"
                >
                    <p
                        [innerHTML]="
                            session.descriptionMarkdown?.text || session?.description || ''
                                | sanitizeText
                                | sanitizeHtml
                        "
                    ></p>
                </app-read-more>
            </div>
        </div>
        <div
            class="schedule-description"
            *ngIf="!session?.description && session?.type !== 'break' && session.showDetails"
        >
            No description provided by the host
        </div>
    </div>
</ng-template>

<ng-template #event_desc_break>
    <div class="break-description-wrapper d-flex">
        <img src="assets/img/break_icn.svg" alt="" class="break-default-img" />
        <div class="break-container">
            <p
                *ngIf="session?.topic"
                [innerHTML]="sanitizeText(session.topicMarkdown?.text || session?.topic || '') | sanitizeHtml"
            ></p>

            <div class="timings">
                <span>
                    {{ session?.startTime | date: 'shortTime' }}
                </span>

                <span>
                    to
                    {{ session?.endTime | date: 'shortTime' }}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #event_speakers>
    <ng-container *ngIf="(session.speakers.length && session.showDetails) || status !== 'Completed'">
        <div class="d-flex align-items-center speaker-wrapper">
            <div class="speaker-list">
                <ng-container *ngFor="let speaker of session.speakers; let i = index; trackBy: trackByFunction">
                    <div *ngIf="i < maxNumberOfVisibleSpeakers" class="speaker-container">
                        <app-speaker-tile
                            [speaker]="speaker"
                            [isSessionSpeaker]="true"
                            showOnlySummary="true"
                            MaxCharBeforeTruncateWeb="12"
                            MaxCharBeforeTruncateMob="9"
                        ></app-speaker-tile>
                    </div>
                </ng-container>
            </div>

            <div
                class="speaker-list-toggle"
                *ngIf="session.speakers.length - maxNumberOfVisibleSpeakers > 0"
                (click)="toggleSpeakerList(true)"
            >
                + {{ session.speakers.length - maxNumberOfVisibleSpeakers }} More
            </div>
            <div
                class="speaker-list-toggle"
                *ngIf="
                    !isMobileOS &&
                    session.speakers.length === maxNumberOfVisibleSpeakers &&
                    maxNumberOfVisibleSpeakers > defaultMaxVisibleSpeakers
                "
                (click)="toggleSpeakerList(false)"
            >
                View Less
            </div>
        </div>
        <div
            class="speaker-list-toggle"
            style="margin-top: 12px"
            *ngIf="
                isMobileOS &&
                session.speakers.length === maxNumberOfVisibleSpeakers &&
                maxNumberOfVisibleSpeakers > defaultMaxVisibleSpeakers
            "
            (click)="toggleSpeakerList(false)"
        >
            View Less
        </div>
    </ng-container>
</ng-template>
