import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {
    RtcService,
    UserService,
    UtilService,
    VideoWrapperService,
    AppService,
    EventEmitterService,
    DiagnosticService,
    AgoraService
} from 'src/app/core';
import { forkJoin, from, Subscription } from 'rxjs';
import { VirtualBackgroundService } from 'src/app/core/services/virtual-background.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { APP_EVENTS, NETWORKING_LOUNGE, PREPARATORY } from 'src/app/constants';
import { NetworkingRoomsService } from 'src/app/core/services/networking-rooms.service';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';

declare const navigator: any;

@Component({
    selector: 'app-call-preview',
    templateUrl: './call-preview.component.html',
    styleUrls: ['./call-preview.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CallPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
    micPrivacy = false;
    cameraPrivacy = false;
    cameraStarting = false;
    showSettingsPopup;
    @Input() hostMeeting;
    @Input() networkingRoom: any;
    @Output() showsettings = new EventEmitter();
    @Input() isprerecordedSession: any = false;
    videoService: any;
    currentUser;
    enableVirtualBackground: boolean = false;
    showControls: boolean = false;
    private subscriptions: Subscription[] = [];
    disableVirtualBackground: boolean = false;
    isNetworkingRoom: boolean = true;
    constructor(
        public rtcService: RtcService,
        private userService: UserService,
        private utilService: UtilService,
        private videoWrapperService: VideoWrapperService,
        private appService: AppService,
        private zone: NgZone,
        private vbService: VirtualBackgroundService,
        private diagnosticService: DiagnosticService,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private googleTagMangerService: GoogleTagMangerService,
        private networkingRoomsService: NetworkingRoomsService,
        private callStateManagerService: CallStateManagerService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPrepScreen();
        this.currentUser = this.userService?.getUserSync();
        this.appService.canEnableVirtualBackground().then((canEnable) => {
            this.enableVirtualBackground = canEnable;
            if (this.enableVirtualBackground) {
                this.vbService.initializeVirtualBackgroundService();
            }
        });
        this.disableVirtualBackground = this.rtcService?.disableVirtualBackgroundSupport();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.videoService.getMeetingObs().subscribe((event) => {
                this.zone.run(() => {
                    if (!event) {
                        return;
                    }
                    this.handleConferenceEvents(event);
                });
            })
        );
        if (this.enableVirtualBackground) {
            this.vbService.initializeVirtualBackgroundService();
        }
        this.activatedRoute.queryParams.subscribe((queryParams) => {
            this.cameraPrivacy = queryParams.cameraPrivacy === 'true' ? true : false;
            if (this.cameraPrivacy) {
                this.toggleVideo(true);
            }
        });
    }

    ngAfterViewInit() {
        this.rtcService.setCameraMicPreference(this.cameraPrivacy, false);
        setTimeout(() => {
            this.setupConnector();
            // this.checkPreferencesAndInitiate();
        }, 30);
    }

    setupConnector() {
        this.videoService.setJoinData({
            host: null,
            displayName: null,
            roomKey: null,
            roomPin: null,
            micPrivacy: this.micPrivacy,
            cameraPrivacy: this.cameraPrivacy,
            autoJoin: false,
            viewId: 'preview-renderer'
        });
        /**
         * For preview screen, relying on agora service
         * Directly Checking the sdk initialization from agora service
         * Pexip RTC is created during call Joining flow
         */
        if (this.rtcService.getIsJMMeeting()) {
            if (!this.videoService?.jmMediaClient) this.videoService.initializeVidyoConnector();
            else this.showControls = true;
        } else {
            if (!this.videoService?.agoraClient) this.videoService.initializeVidyoConnector();
            else this.showControls = true;
        }
    }

    handleConferenceEvents(event) {
        switch (event.type) {
            case 'SHOW_PREVIEW_CNTROLS':
                this.showControls = true;
                break;
        }
    }

    async toggleSettingsPopup(event, setting) {
        if (this.videoService?.cameras?.length === 0 || this.videoService?.microphones?.length === 0) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.SHOW_PERMISSION_UI,
                data: { show: true, skipOption: true }
            });
            return;
        }
        if (setting === 'background' && this.disableVirtualBackground) {
            this.googleTagMangerService.pushGoogleTagData(PREPARATORY.VIRTUAL_BACKGROUND);
            event?.preventDefault();
            event?.stopPropagation();
            event?.stopImmediatePropagation();
        } else {
            let eventTypeMain = setting == 'background' ? PREPARATORY.VIRTUAL_BACKGROUND : PREPARATORY.SETTINGS;
            let eventTypeNw =
                setting == 'background' ? NETWORKING_LOUNGE.VIRTUAL_BACKGROUND : NETWORKING_LOUNGE.SETTINGS;
            this.networkingRoom
                ? this.googleTagMangerService.pushGoogleTagData(eventTypeNw)
                : this.googleTagMangerService.pushGoogleTagData(eventTypeMain);
            this.videoService.selectDevices();
            this.showsettings.emit(setting);
            // this.sendVbBackGroundGAEvent();
        }
    }

    async toggleVideo(queryParams = false) {
        if (!queryParams) {
            let eventTypeMain = this.cameraPrivacy ? PREPARATORY.STOP_VIDEO : PREPARATORY.START_VIDEO;
            let eventTypeNw = this.cameraPrivacy ? NETWORKING_LOUNGE.STOP_VIDEO : NETWORKING_LOUNGE.START_VIDEO;
            this.networkingRoom
                ? this.googleTagMangerService.pushGoogleTagData(eventTypeNw)
                : this.googleTagMangerService.pushGoogleTagData(eventTypeMain);
        }
        try {
            this.cameraPrivacy = queryParams ? true : !this.cameraPrivacy;
            this.cameraStarting = this.cameraPrivacy;
            const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
            stream.getVideoTracks().forEach((track) => track.stop());
            if (this.videoService.cameras.length === 0) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SHOW_PERMISSION_UI,
                    data: { show: true, skipOption: true }
                });
                return;
            }
            await this.videoService.toggleCameraForSetting('preview-renderer', this.cameraPrivacy);
            this.cameraStarting = false;
            this.rtcService.setCameraMicPreference(this.cameraPrivacy, this.micPrivacy);
        } catch (err) {
            this.cameraStarting = false;
            this.cameraPrivacy = !this.cameraPrivacy;
            this.eventEmitterService.emit({
                type: APP_EVENTS.SHOW_PERMISSION_UI,
                data: { show: true, skipOption: true }
            });
        }
    }

    async toggleAudio() {
        let eventTypeMain = this.videoService?.micState ? PREPARATORY.MUTE : PREPARATORY.UNMUTE;
        let eventTypeNw = this.videoService?.micState ? NETWORKING_LOUNGE.MUTE : NETWORKING_LOUNGE.UNMUTE;
        this.networkingRoom
            ? this.googleTagMangerService.pushGoogleTagData(eventTypeNw)
            : this.googleTagMangerService.pushGoogleTagData(eventTypeMain);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            stream.getAudioTracks().forEach((track) => track.stop());
            if (this.videoService.microphones.length === 0) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SHOW_PERMISSION_UI,
                    data: { show: true, skipOption: true }
                });
                return;
            }

            if (!this.rtcService.isJMMeeting) this.videoService.toggleMicPrivacy(this.micPrivacy);
            this.micPrivacy = !this.micPrivacy;
            this.rtcService.setCameraMicPreference(this.cameraPrivacy, this.micPrivacy);
        } catch (err) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.SHOW_PERMISSION_UI,
                data: { show: true, skipOption: true }
            });
        }
    }

    checkPreferencesAndInitiate() {
        if (this.hostMeeting) {
            this.userService.getMeetingPrefernces().subscribe((preferences) => {
                this.micPrivacy = preferences.hostAudio;
                this.cameraPrivacy = preferences.hostVideo;
                this.setupConnector();
            });
        } else {
            this.micPrivacy = !this.userService.getUserSync()?.microphoneOff;
            this.cameraPrivacy = !this.userService.getUserSync()?.videoOff;
            this.setupConnector();
        }
    }

    sendVbBackGroundGAEvent() {
        this.diagnosticService
            .sendEvent({
                eventAction: 'Background Option Clicked through Preparatory Screen',
                eventCategory: 'JioEvents Virtual Background',
                eventType: 'app_event',
                status: 'success'
            })
            .subscribe();
    }

    async ngOnDestroy() {
        await this.videoService.toggleCameraPrivacy(true);
        await this.videoService.toggleMicPrivacy(true);
        if (this.rtcService.isJMMeeting) await this.videoService.stopPreviewVb();
        this.videoService.releaseDevices();
        this.subscriptions.map((s) => s.unsubscribe());
    }
}
