import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { VideoWrapperService } from 'src/app/core';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { EventsPlusService } from 'src/app/core/services/events-plus.service';
import { MediaStreamRetrieverService } from 'src/app/core/services/media-stream-retriever.service';

@Component({
    selector: 'app-studio-speaker-feed',
    templateUrl: './studio-speaker-feed.component.html',
    styleUrls: ['./studio-speaker-feed.component.scss']
})
export class StudioSpeakerFeedComponent implements OnInit, OnDestroy {
    showSceneSpecificSpeakers: boolean = false;
    galleryViewSource;
    updateVersion: number = new Date().getTime();
    isLiveAvailable = true;
    isUpcomingAvailable = true;
    @Input() isPipMode: boolean;
    constructor(
        public mediaStreamRetriever: MediaStreamRetrieverService,
        public eventsPlusService: EventsPlusService,
        public callViewStateManager: CallViewStateManagerService,
        private videoWrapperService: VideoWrapperService
    ) {}

    @HostListener('window:resize', ['$event'])
    updateVersionOnChange() {
        this.updateVersion = new Date().getTime();
    }

    ngOnInit(): void {
        this.mediaStreamRetriever.updateVideoService();
        this.galleryViewSource = 'complete';
        this.videoWrapperService.getVideoService().isStudioEvent = true;
        this.callViewStateManager.getViewStateObs().subscribe((event) => {
            switch (event.type) {
                case 'callOptionsPanelOpen': {
                    this.updateVersionOnChange();
                    break;
                }
                case 'callOptionsPanelClose': {
                    this.updateVersionOnChange();
                    break;
                }
            }
        });

        this.eventsPlusService.getCustomLayoutDetails().subscribe((customLayout) => {
            if (!customLayout) {
                this.isLiveAvailable = false;
                this.isUpcomingAvailable = true;
            } else if (!customLayout.publishingState) {
                this.isLiveAvailable = false;
                this.isUpcomingAvailable = true;
            } else {
                this.isLiveAvailable = true;
                const newSceneNumber = customLayout.lastPublishedLayout.sceneNumber;
                if (newSceneNumber < customLayout?.lastSavedLayout.scenesList.length - 1) {
                    this.isUpcomingAvailable = true;
                } else {
                    this.isUpcomingAvailable = false;
                }
            }
        });
    }

    toggleSpeakersView() {
        this.showSceneSpecificSpeakers = !this.showSceneSpecificSpeakers;
        this.galleryViewSource = this.galleryViewSource === 'sceneSpecific' ? 'complete' : 'sceneSpecific';
        this.mediaStreamRetriever.setGalleryFilters(this.galleryViewSource, this.getSpeakerIdsInCurrentScene());
    }

    getSpeakerIdsInCurrentScene() {
        const layoutDetails = this.eventsPlusService.layoutDetails;
        const publishedScene = layoutDetails.lastPublishedLayout.sceneNumber;
        const totalScenesCount = layoutDetails.lastSavedLayout.scenesList.length;
        const currentSceneSpeakerIds = [];
        layoutDetails.lastPublishedLayout.elements.forEach((e) => {
            if (e.type === 'userFeed' && e.mediaEngineId && e.mediaEngineId !== '') {
                currentSceneSpeakerIds.push(e.mediaEngineId);
            }
        });
        return currentSceneSpeakerIds;
    }

    ngOnDestroy(): void {
        this.videoWrapperService.getVideoService().isStudioEvent = false;
    }
}
