import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService, AuthService, EventData, EventEmitterService, UserService, UtilService } from 'src/app/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { DASHBOARD, HOME_PAGE_REVAMP } from '../../../constants/gtm-events';
import { EVENT_SETTINGS } from '../../../constants/gtm-events';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from 'src/app/constants';
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
    selector: 'app-my-card',
    templateUrl: './my-card.component.html',
    styleUrls: ['./my-card.component.scss'],
    providers: [ConfirmationService],
    animations: [
        trigger('animate', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate('500ms', style({ opacity: 1, transform: 'translateY(0%)' }))
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None
})
export class MyCardComponent implements OnInit, AfterViewInit {
    @Input() dashboard;
    @Input() previousWebinar;
    @Input() eventinfo;
    @Input() rightMenuOptions: any[] = [];
    @Input() sliderName;
    favourites;
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() actionMenuClick: EventEmitter<any> = new EventEmitter();
    @ViewChild('markdown') markdown;
    @HostBinding('@animate') animate = true;
    defaultimageurl;
    cardLink;
    showCalendar = false;
    markdowntext;
    pastEvent = false;
    hash;
    currentPage: string = 'explore_events_page';
    currentUser;
    isCoHost;
    isAdHocCoHost;
    useOldFlow: boolean = true;
    showDeleteModal: boolean = false;
    deleteInSeries: 'current' | 'all' = 'current';
    showMeetingSharePopup: boolean = false;
    isPrerecorded: boolean = false;
    selectedOption: any;
    activeDropDown: string = '';
    tileBgImageLoaded: boolean = false;
    eventCardImageUrl;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private googleTagManager: GoogleTagMangerService,
        public utilService: UtilService,
        private userService: UserService,
        private webinarService: WebinarService,
        private appService: AppService,
        private eventEmitterService: EventEmitterService
    ) {
        this.activatedRoute.data.subscribe((route) => {
            this.dashboard =
                this.activatedRoute['_routerState'].snapshot.url.split('/')[2] === 'my-events' || this.dashboard;
        });
    }

    ngOnInit(): void {
        this.eventCardImageUrl= this.eventinfo?.thumbnailLowRes ?
        (this.eventinfo?.thumbnailLowRes?.includes('/events-category-thumbnails/') ? ( this.eventinfo.welcomeThumbnailUrl
        ? this.eventinfo.welcomeThumbnailUrl
        : this.defaultimageurl): this.eventinfo?.thumbnailLowRes) :
        (this.eventinfo.welcomeThumbnailUrl
        ? this.eventinfo.welcomeThumbnailUrl
        : this.defaultimageurl)
        this.currentUser = this.userService.getUserSync();
        this.fetchEventInfo();
        this.checkIfPastEvent();

        if (this.dashboard) {
            this.currentPage = 'dashboard_explore_events';
        }

        if (this.isAdHocCoHost) {
            this.isCoHost = false;
        }

        this.subscribeToEventEmitter();
    }
    fetchEventInfo() {
        this.markdowntext = this.eventinfo?.topicMarkdown ? this.sanitizeText(this.eventinfo?.topicMarkdown.text) : '';
        this.defaultimageurl = `assets/img/jioconf/welcome-slates-plain/${this.eventinfo?.jioConf.eventType}.png`;
        this.favourites = this.eventinfo?.favouriteId ? true : false;
        this.cardLink = this.eventinfo?.webinar.registration.enabled
            ? this.eventinfo?.webinar.registration.url
            : this.eventinfo?.webinar.activation.url;
        this.isCoHost = this.webinarService.checkForCoHost(this.eventinfo?.participants || [], this.currentUser);
        this.isAdHocCoHost = this.webinarService.checkForAdHocCoHost(
            this.eventinfo?.participants || [],
            this.currentUser
        );
        this.isPrerecorded = this.eventinfo?.webinar?.preRecordedVideo?.enabled
            ? this.eventinfo?.webinar?.preRecordedVideo?.enabled
            : false;
        if (this.pastEvent && this.sliderName != 'replay') {
            if (this.eventinfo?.recordings) {
                this.cardLink = this.router.serializeUrl(
                    this.router.createUrlTree(['media-recordings', this.eventinfo?._id])
                );
            }
        }
    }
    ngOnChanges(changes) {
        this.fetchEventInfo();
    }

    prepareWatchReplayMenus() {
        this.rightMenuOptions = [];
        if (!this.eventinfo?.webinar?.registration?.inviteOnly && this.eventinfo?.repeatOptions.repeat == 'none') {
            this.rightMenuOptions = [
                { label: 'Watch Replay', value: 'watchReplay' },
                { label: this.eventinfo?.isReplayEnabled ? 'Edit Replay' : 'Enable Replay', value: 'toggleReplayPopup' }
            ];
            if (this.eventinfo?.isReplayEnabled) {
                this.rightMenuOptions.push({ label: 'Disable Replay', value: 'disableReplay' });
            }
        }
    }

    subscribeToEventEmitter() {
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.MYEVENT_ACTION_MENU_CLICK) {
                if (event.data?.meetingId === this.eventinfo?._id) this.handleActionMenuClick(event.data?.event);
            }
        });
    }

    get allowStart() {
        return (
            (this.eventinfo.userId || this.eventinfo.hostInfo?._id) === this.currentUser._id ||
            this.isCoHost ||
            this.isAdHocCoHost
        );
    }
    startMeeting(event, eventinfo, from = 'start') {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        if (this.utilService.isMobileBrowser()) {
            return;
        }
        let eventType = from == 'join' ? DASHBOARD.JOIN_EVENT : DASHBOARD.START_EVENT;
        if (this.useOldFlow) {
            this.googleTagManager.pushGoogleTagData(
                Object.assign({}, eventType, {
                    meeting_Id: this.eventinfo._id,
                    Category: this.eventinfo?.jioConf?.eventType,
                    expected_attendee_count: this.eventinfo?.jioConf?.expectedAttendeeCount,
                    tags: this.eventinfo?.jioConf?.tags
                })
            );
            window.open(
                `${this.appService.getBaseUrl()}join?meetingId=${eventinfo.jiomeetId}&pwd=${
                    eventinfo.pin || eventinfo.roomPIN
                }`
            );
        } else {
            this.googleTagManager.pushGoogleTagData(
                Object.assign({}, DASHBOARD.START_EVENT, {
                    meeting_Id: this.eventinfo._id,
                    Category: this.eventinfo?.jioConf?.eventType,
                    expected_attendee_count: this.eventinfo?.jioConf?.expectedAttendeeCount,
                    tags: this.eventinfo?.jioConf?.tags
                })
            );
            this.navigateToEvent(eventinfo.jiomeetId, eventinfo.pin || eventinfo.roomPIN, true);
        }
    }
    navigateToEvent(jiomeetId: String = null, roomPin: String = null, asSpeaker: boolean = false) {
        const AttendeeUrl = this.eventinfo.webinar?.activation?.url || this.eventinfo.webinar?.registration?.url;
        window.open(AttendeeUrl, '_blank');
    }
    previewEvent() {
        this.googleTagManager.pushGoogleTagData(
            Object.assign({}, DASHBOARD.PREVIEW_EVENT, {
                meeting_Id: this.eventinfo._id,
                Category: this.eventinfo?.jioConf?.eventType,
                expected_attendee_count: this.eventinfo?.jioConf?.expectedAttendeeCount,
                tags: this.eventinfo?.jioConf?.tags
            })
        );

        this.previewNavigate();
        this.actionMenuClick.emit(this.selectedOption);
    }
    previewNavigate() {
        if (this.isPrerecorded) {
            const previewUrl = `events/in-session/preview/${this.eventinfo._id}?isFromDashboard=true`;
            window.open(previewUrl, '_blank');
        } else {
            this.navigateToEvent();
        }
    }
    checkIfPastEvent() {
        let currentTime = new Date();
        let endTime = new Date(this.eventinfo.endTime);
        this.pastEvent = endTime < currentTime ? true : false;
    }

    ngAfterViewInit(): void {}

    sanitizeText(text) {
        return this.utilService.sanitizeAndConvertEncodedHTML(text);
    }
    handleActionMenuClick(event) {
        this.selectedOption = event.option;
        const selectedOption = this.selectedOption;
        if (this[selectedOption.value] && typeof this[selectedOption.value] === 'function') {
            this[selectedOption.value](event.event);
        } else {
            this.actionMenuClick.emit(selectedOption);
        }
    }

    gotoEvent() {
        this.eventClick.emit({ name: this.markdown?.nativeElement?.firstChild?.textContent, link: this.cardLink });
        window.open(this.cardLink);
        this.googleTagManager.pushGoogleTagData(HOME_PAGE_REVAMP.EVENTS_CARD_CLICK);
    }

    openMyEventsMenuOptions(e) {
        e.preventDefault();
        e.stopPropagation();
        this.actionMenuClick.emit({ rightMenuOptionClick: true, menuOptions: this.rightMenuOptions });
    }

    openReportAnalytics(e) {
        this.actionMenuClick.emit({ reportAnalyticClick: true });
    }

    handleTileLoadingStatus(event) {
        this.tileBgImageLoaded = event.success && !event.loading;
    }
}
