import { Component, Input, OnInit } from '@angular/core';
import { PreCallTestingService } from '../../services';
import { QualityScore } from '@jiomeet/jm-test-rtc';
import { es } from 'date-fns/locale';

@Component({
    selector: 'app-performance-test',
    templateUrl: './performance-test.component.html',
    styleUrls: ['./performance-test.component.scss']
})
export class PerformanceTestComponent implements OnInit {
    performanceResult;
    bitrateResult;

    constructor(public preCallTestingService: PreCallTestingService) {
        this.performanceResult = preCallTestingService.performanceResult;
        this.bitrateResult = preCallTestingService.bitrateResult;
    }

    ngOnInit(): void {}

    getImageSource(value: QualityScore): string {
        return value === QualityScore.Bad
            ? 'assets/images/pre-call-testing/warning.svg'
            : 'assets/images/pre-call-testing/success.svg';
    }
}
