<div
    class="leaderboard-overlay-container no-select"
    [class.isMobileOS]="isMobileOS"
    [class.insideTable]="isSourceTable"
    *ngIf="!loading"
>
    <div class="header" *ngIf="!isSourceTable">Congratulations to the Winners</div>
    <div class="winners" *ngIf="!isSourceTable && winners.length === 3">
        <ng-container *ngFor="let idx of [1, 0, 2]">
            <div class="winner-info winner-{{ idx + 1 }}">
                <img class="crown" src="assets/images/leaderboard/leaderboard_crown.svg" *ngIf="idx === 0" />
                <div class="profile-pic-wrapper">
                    <div
                        class="card-profile rounded-circle profile-image"
                        appAvatarWithInitials
                        [name]="winners[idx].name"
                        [userId]="winners[idx]?.userId"
                        *ngIf="winners[idx]?.userId?.includes('gu')"
                    ></div>
                    <img
                        draggable="false"
                        [src]="getProfilePic(winners[idx]?.userId)"
                        *ngIf="!winners[idx]?.userId?.includes('gu')"
                        class="profile-pic profile-image"
                    />
                </div>
                <div class="rank-info">
                    <img class="mini-prize-icon" src="assets/images/leaderboard/winner_cup.svg" />
                    <div class="rank-number">{{ idx + 1 }}</div>
                </div>
                <div class="user-name description-text">{{ winners[idx].name }}</div>
                <div class="score-info description-text">{{ winners[idx].score }} Pts</div>
            </div>
        </ng-container>
    </div>
    <div class="winners-inside-table" *ngIf="isSourceTable">
        <ng-container *ngFor="let idx of [1, 0, 2]">
            <div class="winner-mini-tile winner-inside-{{ idx + 1 }}">
                <img class="crown" src="assets/images/leaderboard/leaderboard_crown.svg" *ngIf="idx === 0" />
                <div class="profile-pic-wrapper" pBadge [value]="idx + 1">
                    <div
                        class="card-profile rounded-circle profile-image"
                        appAvatarWithInitials
                        [name]="winners[idx].name"
                        [userId]="winners[idx]?.userId"
                        *ngIf="winners[idx]?.userId?.includes('gu')"
                    ></div>
                    <img
                        draggable="false"
                        [src]="getProfilePic(winners[idx]?.userId)"
                        *ngIf="!winners[idx]?.userId?.includes('gu')"
                        class="profile-pic profile-image"
                    />
                </div>
                <div class="user-name description-text">{{ winners[idx].name }}</div>
                <div class="score-info description-text">{{ winners[idx].score }} Pts</div>
            </div>
        </ng-container>
    </div>
</div>
