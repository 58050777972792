import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as Clappr from 'clappr';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-prerecorded-cloud-player',
    templateUrl: './prerecorded-cloud-player.component.html',
    styleUrls: ['./prerecorded-cloud-player.component.scss']
})
export class PrerecordedCloudPlayerComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() cloudPlayerConfig;
    @Input() webinarEnded: boolean = false;
    @Output() close = new EventEmitter();

    cloudPlayer;
    isMobileOS;
    isIOS;
    isPlaying;

    constructor(private utilService: UtilService) {
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.isIOS = this.utilService.isMobileIOSBrowser();
    }

    ngOnInit(): void {
        console.log('prerecorded cloud player');
    }

    ngAfterViewInit() {
        this.initCloudMediaPlayer();
    }

    ngOnChanges(changes: SimpleChanges) {
        const cloudConfig = changes['cloudPlayerConfig']?.currentValue;
        const webinarEnded = changes['webinarEnded']?.currentValue;
        if (webinarEnded && this.cloudPlayer) {
            this.cloudPlayer?.pause();
            return;
        }
        if (this.cloudPlayer) {
            this.cloudPlayer.seek(cloudConfig?.seek);
            cloudConfig.paused ? this.cloudPlayer.pause() : this.cloudPlayer.play();
        }
    }

    initCloudMediaPlayer() {
        //loading cloudVideo when another is cloudVideo is already playing .
        const config = {
            parentId: '#cloud-video-container',
            source: this.cloudPlayerConfig?.uri,
            width: '100%',
            height: '100%',
            muted: false,
            mute: false,
            autoPlay: true,
            mimeType: 'video/mp4 ',
            playback: {
                playInline: true
            },
            disableErrorScreen: true,
            events: {
                onReady: () => {
                    this.onCloudPlayerReady();
                },
                onPlay: () => {
                    this.onCloudPlayerReady();
                },
                onError: (e) => {
                    this.stopCloudPlayer();
                }
            }
        };
        if (!this.cloudPlayer) {
            this.cloudPlayer = new Clappr.Player(config);
        }
    }

    onCloudPlayerReady() {
        if (!this.cloudPlayerConfig.isPlaying) {
            this.cloudPlayer.seek(this.cloudPlayerConfig.seek);
            this.cloudPlayerConfig.paused ? this.cloudPlayer.pause() : this.cloudPlayer.play();
            this.cloudPlayerConfig.isPlaying = true;
            this.isPlaying = true;
        }
    }

    stopCloudPlayer() {
        this.close.emit();
    }

    ngOnDestroy() {
        if (this.cloudPlayer) {
            this.cloudPlayer = null;
            this.cloudPlayerConfig.isPlaying = false;
        }
    }
}
