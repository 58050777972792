import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PREPARATORY, NETWORKING_LOUNGE, PREPARATORY_SETTINGS } from 'src/app/constants';
import { UserService } from 'src/app/core';
import { VideoWrapperService } from 'src/app/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { NetworkingRoomsService } from 'src/app/core/services/networking-rooms.service';

@Component({
    selector: 'app-video-settings',
    templateUrl: './video-settings.component.html',
    styleUrls: ['./video-settings.component.scss']
})
export class VideoSettingsComponent implements OnInit, OnDestroy {
    private videoService;
    public selectedCameraId;
    public selectedCamera;
    public cameras = [];
    private timeoutId;
    turnOffVideo;
    @Input() route: String = 'dashboard';
    @Input() networkingRoom: any;
    @Input() settingPopup: string = 'popup';
    subsciption: Subscription[] = [];
    constructor(
        private videoServiceWrapper: VideoWrapperService,
        private userService: UserService,
        private googleTagManager: GoogleTagMangerService,
        private networkingRoomsService: NetworkingRoomsService
    ) {
        this.videoService = this.videoServiceWrapper.getVideoServiceForPrepScreen();
    }

    ngOnInit(): void {
        this.turnOffVideo = this.userService.getUserSync()?.videoOff;
        this.subsciption.push(
            this.videoService.getParticipantStatus().subscribe((status) => {
                if (!status) {
                    this.cameras.push({ name: 'Default Camera' });
                    return;
                }
                this.timeoutId = setTimeout(() => {
                    this.selectedCameraId = this.videoService.selectedLocalCamera;
                    this.cameras = this.videoService.cameras;
                    this.selectedCamera = this.cameras.find((camera) => camera.id === this.selectedCameraId);
                }, 100);
            })
        );
    }

    selectCamera() {
        if (this.settingPopup == 'popup') {
            let eventTypePrepSettings = PREPARATORY_SETTINGS.MIC_SELECT;
            eventTypePrepSettings.clicked_text = this.selectedCamera?.name
                ? this.selectedCamera?.name
                : 'Camera selected';
            this.googleTagManager.pushGoogleTagData(eventTypePrepSettings);
        } else {
            let eventTypeMain = PREPARATORY.CAMERA_SELECT;
            let eventTypeNw = NETWORKING_LOUNGE.CAMERA_SELECT;
            eventTypeMain.clicked_text = this.selectedCamera?.name ? this.selectedCamera?.name : 'Camera selected';
            eventTypeNw.clicked_text = this.selectedCamera?.name ? this.selectedCamera?.name : 'Camera selected';
            this.networkingRoom
                ? this.googleTagManager.pushGoogleTagData(eventTypeNw)
                : this.googleTagManager.pushGoogleTagData(eventTypeMain);
        }
        this.videoService.manuallyChangeCamera(this.selectedCamera);
    }

    onTurnOffVideo(value) {
        const isTurnOffVideo = value.checked;
        this.userService
            .updateProfile({
                videoOff: isTurnOffVideo
            })
            .subscribe(() => {});
    }

    ngOnDestroy() {
        this.subsciption.map((s) => s.unsubscribe());
        clearTimeout(this.timeoutId);
    }
}
