<div class="website" id="website-top">
    <section class="top-section">
        <div class="content mb-3">
            <div class="info-text">
                <div>Meet all your conferencing needs virtually in JioConf</div>
            </div>
        </div>
        <div class="img-content">
            <img src="assets/img/jioconf/landing/landing-img1.png" />
        </div>
    </section>
    <section class="website-content row">
        <div class="middle-section-wrapper">
            <div class="outer-wrapper">
                <div class="text-content col-xl-6 col-lg-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="section-header">Reaching your audience, Simplified.</div>
                    <div class="side-note mt-3 mb-4">
                        The best and simplest way to hold conferences and events from the safety of your home.
                    </div>
                    <div class="content-list">
                        <div class="content-card">
                            <div class="image-wrapper mr-3">
                                <img src="assets/img/jioconf/landing/create_icn.svg" alt="create" />
                            </div>
                            <div class="content">
                                <div class="title mb-1">Create Events of any Kind Easily</div>
                                <div class="text">
                                    From your first one day workshop or webinar to large conferences over several days.
                                    We help you manage them.
                                </div>
                            </div>
                        </div>
                        <div class="content-card">
                            <div class="image-wrapper mr-3">
                                <img src="assets/img/jioconf/landing/initiate_icn.svg" alt="initiate" />
                            </div>
                            <div class="content">
                                <div class="title mb-1">Initiate Rich Audience Engagements</div>
                                <div class="text">
                                    Make the most of Online polls, Q&A’s, live reactions to engage with your audiences.
                                </div>
                            </div>
                        </div>
                        <div class="content-card">
                            <div class="image-wrapper mr-3">
                                <img src="assets/img/jioconf/landing/create_icn.svg" alt="create" />
                            </div>
                            <div class="content">
                                <div class="title mb-1">Amplify your brand</div>
                                <div class="text">
                                    Customize every aspect of your event and highlight your brand logos, themes,
                                    background image, watermark, and much more
                                </div>
                            </div>
                        </div>
                        <div class="content-card">
                            <div class="image-wrapper mr-3">
                                <img src="assets/img/jioconf/landing/initiate_icn.svg" alt="initiate" />
                            </div>
                            <div class="content">
                                <div class="title mb-1">Measure your growth with Analytics</div>
                                <div class="text">
                                    Measure ROI and generate leads, with our well-defined KPIs, deep dive into the event
                                    data to get insights and use them for future improvements.
                                </div>
                                <div class="coming-soon-text info-section">coming soon</div>
                            </div>
                        </div>
                        <div class="button-wrapper" *ngIf="!isAuthenticated">
                            <button class="custom-button bg-primary" (click)="navigateToSignIn()">Sign In now</button>
                        </div>
                    </div>
                </div>
                <div class="img-content col-xl-6 col-lg-6 col-lg-6 col-md-12 col-sm-12">
                    <img src="assets/img/jioconf/landing/landing-img2.png" />
                </div>
            </div>
        </div>
    </section>
    <section class="website-content connections row">
        <div class="background-grey"></div>
        <div class="background-image">
            <img src="assets/img/jioconf/landing/landing-img4.svg" />
        </div>
        <!-- <div class="wrapper"> -->
        <div class="img-content col-xl-6 col-lg-6 col-lg-6 col-md-12 col-sm-12">
            <div class="image-wrapper">
                <img src="assets/img/jioconf/landing/landing-img3.png" />
            </div>
        </div>
        <div class="content col-xl-6 col-lg-6 col-lg-6 col-md-12 col-sm-12">
            <div class="text-content">
                Connect with the World! Unlimited attendees
                <div class="button-wrapper" *ngIf="!isAuthenticated">
                    <button class="custom-button bg-primary" (click)="navigateToSignIn()">Sign In now</button>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </section>

    <section class="use-cases" id="use-cases">
        <div class="title">Tailored for every use case</div>
        <div class="card-list">
            <div class="cards">
                <img src="assets/img/jioconf/landing/conference_icn.svg" alt="Conference" />
                <div class="card-title">Conference</div>
                <div class="card-content">
                    Hold large conferences with unlimited access to features like lounges, backstage etc
                </div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/trade_show_icn.svg" alt="Trade Show" />
                <div class="card-title">Trade show</div>
                <div class="card-content">Exhibition booths, sponsors, branding we got it all.</div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/workshop_icn.svg" alt="Workshops" />
                <div class="card-title">Workshops</div>
                <div class="card-content">Yoga or Cooking, pick up a new hobby with interactive audiences</div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/online_courses_icn.svg" alt="Online Courses" />
                <div class="card-title">Online courses</div>
                <div class="card-content">Learning made easy in this virtual environment.</div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/summits_icn.svg" alt="summits" />
                <div class="card-title">Summits</div>
                <div class="card-content">The best quality video conf expereince at no charge for a large crowd</div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/award_ceremonies_icn.svg" alt="Award ceremonies" />
                <div class="card-title">Award ceremonies</div>
                <div class="card-content">Stream live the most memorable moments</div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/networking_icn.svg" alt="Networking Events" />
                <div class="card-title">Networking Events</div>
                <div class="card-content">
                    Try our lounge and speed networking feature to make professional connections
                </div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/job_fairs_icn.svg" alt="Job Fairs" />
                <div class="card-title">Job Fairs</div>
                <div class="card-content">Our exhibition booth area is convinient and easy</div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/product_launch_icn.svg" alt="Product launches" />
                <div class="card-title">Product launches</div>
                <div class="card-content">Streaming capabilites offer that allows you to reach a wider audience</div>
            </div>
            <div class="cards">
                <img src="assets/img/jioconf/landing/summits_icn.svg" alt="Hackathons" />
                <div class="card-title">Hackathons</div>
                <div class="card-content">Virtual meet for virtual events</div>
            </div>
        </div>
    </section>

    <section class="features">
        <div class="title">Everything you need, All in one place.</div>
        <div class="card-list">
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/backstage_icn.svg" alt="Backstage" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">Backstage</div>
                    <div class="card-content">Backstage ready for speakers, panels, hosts and event organizers</div>
                    <div class="coming-soon-text">coming soon</div>
                </div>
            </div>
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/invite_icn.svg" alt="Invite" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">Invite</div>
                    <div class="card-content">Distinguishing features for smooth running of the conference.</div>
                </div>
            </div>
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/branding_icn.svg" alt="Branding and Sponsorship" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">Branding and Sponsorship</div>
                    <div class="card-content">Upload logos, Themification available to reflect your brand</div>
                </div>
            </div>
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/hd_icn.svg" alt="High quality Audio & Video" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">High quality Audio & Video</div>
                    <div class="card-content">All your events are broadcasted in HD real time.</div>
                </div>
            </div>
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/network_icn.svg" alt="Networking" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">1 on 1 networking and chat</div>
                    <div class="card-content">The true essence of forming connections is retained.</div>
                    <div class="coming-soon-text">coming soon</div>
                </div>
            </div>
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/schedule_icn.svg" alt="Schedule Management" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">Schedule management</div>
                    <div class="card-content">Your Itenary is sorted with us and our easy schedule management.</div>
                </div>
            </div>
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/schedule_icn.svg" alt="Schedule Management" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">Parallel sessions</div>
                    <div class="card-content">Conduct a full on workshop or conference with as many sessions.</div>
                    <div class="coming-soon-text">coming soon</div>
                </div>
            </div>
            <div class="cards">
                <div class="img-wrapper">
                    <img src="assets/img/jioconf/landing/interaction_icn.svg" alt="Interactivity during sessions" />
                </div>
                <div class="card-content-wrap">
                    <div class="card-title">Interactivity during sessions</div>
                    <div class="card-content">Question & Answering, raise hand, polls</div>
                </div>
            </div>
        </div>
    </section>
    <section class="website-content bottom-section" style="padding-top: 8rem">
        <div>Get onboard with JioConf today!</div>
    </section>
</div>
