import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgAddToCalendarService, ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { IcsGeneratorService } from 'src/app/core/services/ics-generator.service';
import { AuthService, ExternalInterfaceService, UserService } from 'src/app/core';
import { EventEmitterService, UtilService, AppService } from 'src/app/core';
import { APP_EVENTS } from 'src/app/constants';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { EventUserRole } from 'src/app/constants/webinar-enum';
import { GA_EVENTS } from 'src/app/integrations/modules/myjio/ga-events';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
declare const $: any;

@Component({
    selector: 'app-add-to-calendar',
    templateUrl: './add-to-calendar.component.html',
    styleUrls: ['./add-to-calendar.component.scss']
})
export class AddToCalendarComponent implements OnInit {
    @Input() joinDetail;
    @Input() brandDetails;
    @Input() showAddToCalBtn = true;
    @Input() isSessionsEnabled;
    @Input() isSessionPage;
    @Input() showSocialDropDown = true;
    @Input() userRole = EventUserRole.ATTENDEE;
    @Input() alwaysShowSocialDropDown = false;

    public googleCalendarEventUrl: SafeUrl;
    public outlookCalendarEventUrl: SafeUrl;
    public outlookLiveCalendarEventUrl: SafeUrl;
    public outlookOffice365CalendarEventUrl: SafeUrl;
    public yahooCalendarEventUrl: SafeUrl;
    public newEvent: ICalendarEvent;
    isMobileOS = false;
    DATE_POCTUATION_REGEX = /-|\/|:|\.\d+/g;
    isIOSOS = false;
    joinHash;
    isMyJio;
    constructor(
        private _addToCalendarService: NgAddToCalendarService,
        private _sanitizer: DomSanitizer,
        private icsGeneratorService: IcsGeneratorService,
        public utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private externalInterfaceService: ExternalInterfaceService,
        private googleTagManager: GoogleTagMangerService,
        private router: Router
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.isMyJio = params?.isMyJio ? true : false;
            if (params?.calendarUrl) {
                this.onClick(params?.calendarUrl);
            }
        });
    }

    ngOnInit(): void {
        this.joinHash = this.activatedRoute.snapshot.params.hash;
        this.isMobileOS = this.utilService.isResponsiveMobileView(1024) || this.utilService.isIpad();
        this.isIOSOS = this.utilService.isMobileIOSBrowser();
        this.prepareSocialLinks();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['joinDetail']) {
            this.prepareSocialLinks();
        }
    }

    prepareSocialLinks() {
        let meetingUrl = this.joinDetail.parentMeetingUrl ?? this.joinDetail.meetingUrl;
        if (this.userRole === EventUserRole.ATTENDEE) {
            meetingUrl = `${this.appService.getBaseUrl()}attendee/${
                this.joinDetail.parentMeetingId ?? this.joinDetail.meetingId
            }/join/${this.activatedRoute?.snapshot?.params?.hash}`;
        } else {
            meetingUrl = `${this.appService.getBaseUrl()}attendee/${
                this.activatedRoute.parent.snapshot.params?.id
            }/join/${this.activatedRoute?.snapshot?.params?.hash}`;
        }
        if (this.alwaysShowSocialDropDown) {
            meetingUrl = this.joinDetail.webinar.registration.enabled
                ? this.joinDetail.webinar.registration.url
                : this.joinDetail.webinar.activation.url;
        }
        this.newEvent = {
            // Event title
            title: this.joinDetail.topic,
            // Event start date
            start: new Date(this.joinDetail.startTime || this.joinDetail.scheduledStartTime),
            // Event duration (IN MINUTES)
            duration: this.joinDetail.duration / (60 * 1000),
            // If an end time is set, this will take precedence over duration (optional)
            end: new Date(this.joinDetail.endTime || this.joinDetail.scheduledEndTime),
            // Event Address (optional)
            address: meetingUrl,
            // Event Description (optional)
            description: this.joinDetail.description,
            url: meetingUrl
        };

        this.googleCalendarEventUrl = this._addToCalendarService.getHrefFor(
            this._addToCalendarService.calendarType.google,
            this.newEvent
        );
        this.outlookCalendarEventUrl = this._addToCalendarService.getHrefFor(
            this._addToCalendarService.calendarType.outlook,
            this.newEvent
        );
        let outlookLiveUrl = this._addToCalendarService.getHrefFor(
            this._addToCalendarService.calendarType.outlookLive,
            this.newEvent
        );

        outlookLiveUrl = this.updateDTFormat(outlookLiveUrl, 'outlook');
        this.outlookLiveCalendarEventUrl = outlookLiveUrl;

        // for making office365 url have to convert outlook.live.com to outlook.office.com
        const strReplaceObj = {
            'outlook.live.com': 'outlook.office.com',
            'realm=live.com': 'realm=office.com',
            'whr=live.com': 'whr=office.com'
        };
        const outlookOfficeUrl = outlookLiveUrl.replace(
            /outlook.live.com|realm=live.com|whr=live.com/g,
            (matched: string) => {
                return strReplaceObj[matched];
            }
        );
        this.outlookOffice365CalendarEventUrl = outlookOfficeUrl;

        //  this.newEvent.start = new Date(this.newEvent.start.toString())
        this.yahooCalendarEventUrl = this.updateDTFormat(
            this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.yahoo, this.newEvent),
            'yahoo'
        );
    }

    updateDTFormat(CalendarUrl: string, calType: string) {
        const url = new URL(CalendarUrl);
        if (calType === 'outlook') {
            url.searchParams.set('startdt', this.joinDetail.startTime || this.joinDetail.scheduledStartTime);
            url.searchParams.set('enddt', this.joinDetail.endTime || this.joinDetail.scheduledEndTime);
        } else if (calType === 'yahoo') {
            url.searchParams.set(
                'st',
                this.toDatetimeLocal(this.newEvent.start).replace(this.DATE_POCTUATION_REGEX, '')
            );
        }
        return url.href;
    }

    toDatetimeLocal(localDateObj) {
        const date = localDateObj,
            ten = (i) => (i < 10 ? '0' : '') + i,
            YYYY = date.getFullYear(),
            MM = ten(date.getMonth() + 1),
            DD = ten(date.getDate()),
            HH = ten(date.getHours()),
            II = ten(date.getMinutes());
        return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':00';
    }

    onBtnClick(e, action = 'open') {
        if (this.isMobileOS) {
            e?.stopPropagation();
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.ADD_TO_CAL_CLICK,
            data: { meetingDetails: this.joinDetail }
        });

        if (action === 'close') {
            this.eventEmitterService.emit({
                type: APP_EVENTS.CLOSE_ADD_TO_CAL_CLICK,
                data: { meetingDetails: this.joinDetail }
            });
        }
    }

    onBtnGA() {
        this.googleTagManager.pushGoogleTagData(GA_EVENTS.REGISTER_CALENDAR_CLICK);
    }

    onSessioGA() {
        this.googleTagManager.pushGoogleTagData(GA_EVENTS.REGISTER_CALENDAR);
    }

    closeView() {
        $('.dropdown-menu').on('click.bs.dropdown', function (e) {
            e.stopPropagation();
            e.preventDefault();
            $('.dropdown-menu').removeClass('show');
        });
    }

    onClick(url) {
        if (this.isMyJio) {
            if (url === this.outlookCalendarEventUrl) {
                url = window.location.href + `&calendarUrl=${url}`;
            }
            this.externalInterfaceService.launchBrowser(url);
        } else {
            url = this._sanitizer.bypassSecurityTrustUrl(url);
            window.open(url?.changingThisBreaksApplicationSecurity, '_blank');
        }
    }
}
