import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ChatMarkdownComponent } from 'src/app/shared';
import { AppService, ProfileService } from 'src/app/core';
import { LiveChatService } from 'src/app/core/services/live-chat.service';
import { UtilService } from 'src/app/core/services/util.service';

declare const TurndownService: any;
@Component({
    selector: 'app-chat-bubble',
    templateUrl: './chat-bubble.component.html',
    styleUrls: ['./chat-bubble.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatBubbleComponent implements OnInit {
    @Input() ownMsg: boolean = false;
    @Input() messageData: any;
    showMoreOptions: boolean = false;
    @Input() isHost: boolean = false;
    @Input() pinnedMessageLength = 0;
    @Input() meetingId: String = '';
    @Input() isLongPressedSelected: boolean = false;

    @Output() moreOptionEvent: EventEmitter<any> = new EventEmitter();
    @ViewChild(ChatMarkdownComponent) private chatMarkdownComponent: ChatMarkdownComponent;
    isEditMessgae: boolean = false;
    turnDownService = new TurndownService();
    @Input() isPined: boolean = false;
    maxLength: any = 200;
    hideMoreOptions: boolean = false;
    isLiveMessgaePined: boolean = false;
    showMoreShiftRights: boolean = false;
    container;
    shouldShowLiveChatMoreOptions: boolean = false;
    profilePicUrl: any;
    public islongPressed: boolean = false;
    public isMobileOS: boolean = false;
    public settingPostion: any = {
        top: 0,
        left: 0,
        right: 0
    };
    constructor(
        private utilService: UtilService,
        private appService: AppService,
        private liveChatService: LiveChatService,
        private confirmationService: ConfirmationService,
        private toastService: ToastrService,
        private profileService: ProfileService
    ) {
        this.isMobileOS = this.utilService.isMobileBrowser();
    }

    ngOnInit(): void {
        this.hideMoreOptions = !this.isHost && !this.ownMsg;
        this.shouldShowLiveChatMoreOptions = this.appService.getConfigVariableWithDefault(
            'shouldShowLiveChatMsgMoreOptions',
            true
        );
        this.profileService.getParticipantsStatus$().subscribe((data) => {
            const profileInfo = data.get(this.messageData?.createdBy)?.profileInfo;
            this.profilePicUrl = profileInfo?.profilePicUrl; // checking if profile url exist
        });
    }

    showChatsettings(msgContainer, ev: any = '') {
        if (!ev) {
            this.showMoreOptions = !this.showMoreOptions;
            return;
        }
        let elementEdges = ev.target.getBoundingClientRect();
        let parentElementEdges = msgContainer?.parentElement?.getBoundingClientRect();
        let top = 0;
        let left = elementEdges.left - parentElementEdges.left + 30;
        let right = parentElementEdges.right - elementEdges.right + 30;
        console.log('data needed', right);
        if (msgContainer.clientWidth > msgContainer?.parentElement?.offsetWidth / 2 - 10) {
            this.showMoreShiftRights = true;
            if (this.ownMsg) {
                this.settingPostion.right = `unset !important`;
                this.settingPostion.left = `${left}px !important`;
                this.settingPostion.top = `${top}px !important`;
            } else {
                this.settingPostion.left = `unset !important`;
                this.settingPostion.top = `${top}px !important`;
                this.settingPostion.right = `${right}px !important`;
            }
        } else {
            if (this.ownMsg) {
                this.settingPostion.left = `unset !important`;
                this.settingPostion.top = `${top}px !important`;
                this.settingPostion.right = `${right}px !important`;
            } else {
                this.settingPostion.right = `unset !important`;
                this.settingPostion.left = `${left}px !important`;
                this.settingPostion.top = `${top}px !important`;
            }
        }
        if (!this.isPined) {
            this.isLiveMessgaePined = this.liveChatService.checkIsPinedMessage(this.messageData._id);
        }
        this.showMoreOptions = !this.showMoreOptions;
    }

    hideSettings() {
        this.showMoreOptions = false;
    }

    unpinMessageConfirmation() {
        this.confirmationService.confirm({
            message: `
            <span class="uplod-cancel-container">
             <span class="livechat-popup-header">Unpin Message?</span>
             <span class="livechat-popup-txt">The message will be unpinned for all users. Do you want to continue?</span>
            </span>
            `,
            // message: 'The message will be unpinned for all users. Do you want to continue?',
            header: '',
            acceptLabel: 'Confirm',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            key: 'callOptionsConfimDialog',
            accept: () => {
                this.unpinMessage();
            }
        });
    }

    unpinMessage() {
        let messgaedata = {
            messageId: this.messageData._id,
            meetingId: this.meetingId
        };
        this.liveChatService.UnpinMessage(messgaedata).subscribe(
            (res: any) => {
                if (res.success) {
                    this.toastService.success('Message Unpinned Successfully.');
                } else {
                    this.toastService.error('Failed to Unpin Message.');
                }
                let data = {
                    type: 'Pin Message',
                    data: this.messageData
                };
                this.moreOptionEvent.emit(data);
            },
            (err) => {
                this.toastService.error('Failed to Unpin Message.');
            }
        );
    }

    pinMessageConfirmation() {
        if (this.pinnedMessageLength == 0) {
            this.pinMessage();
            return;
        }
        if (this.liveChatService.pinedmessages.length >= 3) {
            this.toastService.error(`You've already pinned 3 messages, please unpin one of the messages`);
            return;
        }
        this.confirmationService.confirm({
            message: `
            <span class="uplod-cancel-container">
             <span class="livechat-popup-header">Pin Multiple Messages?</span>
             <span class="livechat-popup-txt">The message will be pinned for all users. Do you want to continue?</span>
            </span>
            `,
            header: '',
            acceptLabel: 'Confirm',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            key: 'callOptionsConfimDialog',
            dismissableMask: true,
            accept: () => {
                this.pinMessage();
            }
        });
    }

    pinMessage() {
        let messgaedata = {
            meetingId: this.meetingId,
            messageId: this.messageData._id
        };
        this.liveChatService.pinMessage(messgaedata).subscribe(
            (res: any) => {
                if (res.success) {
                    this.toastService.success('Message Pinned Successfully.');
                } else {
                    this.toastService.error('Failed to Pin Message.');
                }
                let data = {
                    type: 'Pin Message',
                    data: this.messageData
                };
                this.moreOptionEvent.emit(data);
            },
            (err) => {
                this.toastService.error('Failed to Pin Message.');
            }
        );
    }

    editMessage() {
        this.isEditMessgae = true;
        setTimeout(() => {
            this.chatMarkdownComponent?.quill.on('text-change', (olddata, newdata) => {
                let length = this.chatMarkdownComponent?.quill?.getText().replace('\n', '').length;
                if (this.maxLength < length) {
                    this.chatMarkdownComponent?.quill.setContents(newdata);
                }
            });
        }, 2000);
    }

    showAllPinedMessage() {
        let data = {
            type: 'showall Pined Message'
        };
        this.moreOptionEvent.emit(data);
    }

    updateMessage() {
        let message = '';
        if (this.chatMarkdownComponent?.quill?.container?.firstChild?.innerText?.trim()) {
            message = this.turnDownService.turndown(
                this.chatMarkdownComponent?.quill?.container?.firstChild?.innerHTML
            );
            message = this.utilService.sanitizeHTML(message);
            message = message.replace('[](about:blank)', '').trim();
            message = message.replace(/\\_/g, '_');
            message = message.replace('\\>', '>');
            message = message.replace('&amp;', '&');
        }
        if (message.trim().length) {
            let localMsg = Object.assign({}, this.messageData);
            localMsg.text = message;
            let data = {
                type: 'Update Message',
                data: localMsg
            };
            this.liveChatService.updateMessage(this.meetingId, localMsg).subscribe((res) => {
                this.isEditMessgae = false;
                this.moreOptionEvent.emit(data);
            });
        }
    }

    deleteMessageConfirmation() {
        this.isLiveMessgaePined = this.liveChatService.checkIsPinedMessage(this.messageData._id);
        let confirmationMessage = this.isLiveMessgaePined
            ? `<span class="uplod-cancel-container">
        <span class="livechat-popup-header">Delete Pinned Message?</span>
        <span class="livechat-popup-txt">The message has been pinned for all users. Are you sure you want to delete it?</span>
       </span>`
            : `<span class="uplod-cancel-container">
        <span class="livechat-popup-header">Delete this message?</span>
        <span class="livechat-popup-txt">This will delete the message permanently for all users.</span>
       </span>`;
        let acceptlabel = this.isLiveMessgaePined ? 'Confirm' : 'Delete';
        let popupOption: any = {
            message: confirmationMessage,
            header: '',
            acceptLabel: acceptlabel,
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            dismissableMask: true,
            accept: () => {
                this.deleteMessage();
            }
        };

        popupOption.key = this.isMobileOS ? 'mobileDeleteOptionsConfimDialog' : 'callOptionsConfimDialog';

        this.confirmationService.confirm(popupOption);
    }

    reportMessageConfirmation() {
        this.confirmationService.confirm({
            message: `
            <span class="uplod-cancel-container">
             <span class="livechat-popup-header">Report this message?</span>
             <span class="livechat-popup-txt">The host will review the message. Host can delete the message or block the user if it violates the community guidelines.</span>
            </span>
            `,
            header: '',
            acceptLabel: 'Report',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            key: 'callOptionsConfimDialog',
            accept: () => {
                this.reportMessgae();
            }
        });
    }

    reportMessgae() {
        let data = {
            type: 'Report Message',
            data: Object.assign({}, this.messageData)
        };
        this.moreOptionEvent.emit(data);
    }

    deleteMessage() {
        let messgaedata = {
            messageId: this.messageData._id,
            meetingId: this.meetingId
        };
        this.liveChatService.deleteMessage(messgaedata).subscribe((res: any) => {
            let data = {
                type: 'Delete Message',
                data: this.messageData
            };
            if (res.success) {
                this.toastService.success('Message Deleted Successfully');
                this.moreOptionEvent.emit(data);
            } else {
                this.toastService.error('Message Not Deleted ');
            }
        });
    }

    blockUserConfirmation() {}

    blockerUser() {}

    hideEditMessgae() {
        this.isEditMessgae = false;
    }

    longPressChat() {
        if (this.ownMsg) {
            this.moreOptionEvent.emit({ data: this.messageData._id, type: 'LongPressChat' });
        }
    }
}
