import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import {
    ValidationService,
    AppService,
    AuthService,
    UtilService,
    GaDiagnosticService,
    DiagnosticService
} from 'src/app/core';
import { REG_EX } from 'src/app/constants';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
    signupForm: FormGroup;
    ottForm: FormGroup;
    errors: any = {};
    ottLogin = false;
    invitecode = '';
    verifyingOTP = false;
    signingUp = false;
    mailSent = false;
    newOTPSent = false;
    unVerifiedAccount = false;
    loading = true;
    alreadyRegistered = false;

    constructor(
        private fb: FormBuilder,
        private toastrService: ToastrService,
        private validationService: ValidationService,
        private appService: AppService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private gaService: GaDiagnosticService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        if (this.authService.getIsAuthenticated()) {
            this.router.navigate(['/dashboard']);
            return;
        }

        this.signupForm = this.fb.group({
            emailId: [null, [Validators.required]],
            name: [
                '',
                [
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9\s]*$/)
                    // this.validationService.noWhitespaceValidator,
                ],
                ,
            ],
            lname: [
                '',
                [
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9\s]*$/)
                    // this.validationService.noWhitespaceValidator,
                ],
                ,
            ]
        });
        this.activatedRoute.queryParams.subscribe((params) => {
            this.signupForm.patchValue({ emailId: params.email });
            this.invitecode = params.invitecode;
        });
        this.ottForm = this.fb.group({
            phoneNo: [null, [Validators.required, Validators.pattern(/^\d{10}$/)]],
            otp: [null, [Validators.required]]
        });
        this.gaService.sendPageView({
            page_title: 'signup_page',
            page_path: '/signup'
        });

        this.loading = false;
    }

    validate() {
        this.errors = {};
        if (
            !(
                this.validationService.isEmail(this.signupForm.value.emailId) ||
                this.signupForm.value.emailId.match(/^\d{10}$/)
            )
        ) {
            this.errors.emailId = this.translateService.instant('tostrs.invalid_email_address_or_phone_number');
            return false;
        }

        if (
            this.appService
                .getConfigVariable('SAML_DOMAINS')
                .includes(this.utilService.getDomainFromEmail(this.signupForm.value.emailId))
        ) {
            this.errors.emailId = this.translateService.instant(
                'tostrs.your_company_is_already_onboarded_with_jiomeet_please_sign_in_using_your_company_id'
            );
            return false;
        }

        if (!/^[a-zA-Z0-9]{2,50}$/.test(this.signupForm.value?.name?.trim())) {
            this.errors.name = this.translateService.instant(
                'tostrs.please_enter_a_valid_name_greater_than_1_character'
            );
            return false;
        }
        if (!/^[a-zA-Z0-9]{1,50}$/.test(this.signupForm.value?.lname?.trim())) {
            this.errors.lname = this.translateService.instant('tostrs.please_enter_a_valid_last_name');
            return false;
        }
        return true;
    }

    submit() {
        this.errors = {};
        if (!this.validate()) {
            return;
        }

        if (this.signupForm.value.emailId.match(/^\d{10}$/)) {
            this.authService.isOTTUser({ phoneNo: this.signupForm.value.emailId }).subscribe(
                (res: any) => {
                    if (res.isOTT) {
                        this.alreadyRegistered = true;
                        // this.errors.global = 'You are already registered. Please login with your credentials.';
                    }
                    // } else {
                    this.sendOTP(this.signupForm.value.emailId);
                    // }
                },
                (err) => {
                    if (err.error.message === 'USERNOTFOUND') {
                        this.sendOTP(this.signupForm.value.emailId);
                    } else {
                        this.errors.global = err.error.errors;
                    }
                }
            );
            return;
        }

        this.authService.isOTTUser({ email: this.signupForm.value.emailId }).subscribe(
            (res: any) => {
                // if (res.isOTT) {
                // this.handleUnverifiedAccount();
                this.signup();
                // }
            },
            (error) => {
                if (error.error.message === 'USERNOTFOUND') {
                    // Needs to be handled
                    this.signup();
                } else {
                    this.errors.global = error.error.errors;
                }
            }
        );
    }

    signup() {
        this.signingUp = true;
        this.authService
            .signup({
                emailId: this.signupForm.value.emailId,
                name: this.signupForm.value.name?.trim(),
                lname: this.signupForm.value.lname?.trim(),
                code: this.invitecode
            })
            .subscribe(
                (res: any) => {
                    this.mailSent = true;
                    this.signingUp = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Sign Up with Email',
                            eventType: 'app_event',
                            endpoint: '/api/sign/signup',
                            status: 'success',
                            data: { email: res.email }
                        })
                        .subscribe();
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Sign Up with Email',
                            eventType: 'api_failure',
                            endpoint: '/api/sign/signup',
                            status: 'failed',
                            data: err.error
                        })
                        .subscribe();

                    this.signingUp = false;
                    if (err.error?.customCode === 421) {
                        this.handleUnverifiedAccount();
                    } else {
                        if (err.error && err.error.errors) {
                            this.errors.global = err.error.errors;
                        } else {
                            this.errors.global = this.translateService.instant(
                                'tostrs.something_went_wrong_please_try_again'
                            );
                        }
                    }
                }
            );
    }

    handleUnverifiedAccount() {
        this.unVerifiedAccount = true;
        // this.resendEmail();
        this.toastrService.info(
            this.translateService.instant('tostrs.account_verification_link_has_been_sent_to_your_email')
        );
        this.mailSent = true;
    }

    getOTP(newOTP = false) {
        this.errors = {};
        if (!this.ottForm.value.phoneNo.match(/^\d{10}$/)) {
            this.errors.phoneNo = this.translateService.instant('tostrs.please_enter_valid_10_digit_mobile_no');
            return;
        }

        this.sendOTP(this.ottForm.value.phoneNo);
        this.newOTPSent = true;
    }

    private sendOTP(phoneNo) {
        this.authService
            .getOtp({
                phoneNo,
                code: this.invitecode
            })
            .subscribe(
                (res: any) => {
                    if (res.status === 'success') {
                        if (this.alreadyRegistered) {
                            this.newOTPSent = true;
                        }
                        this.ottLogin = true;
                        this.ottForm.patchValue({
                            phoneNo
                        });
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign Up',
                                eventAction: 'Sign Up with Mobile',
                                eventType: 'app_event',
                                endpoint: '/api/getotp',
                                status: 'success',
                                data: { phoneNo: res.phoneNo }
                            })
                            .subscribe();
                    }
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Sign Up with Mobile',
                            eventType: 'api_failure',
                            endpoint: '/api/getotp',
                            status: 'failed',
                            data: err.error
                        })
                        .subscribe();
                    if (err.error.customCode === 400) {
                        this.errors.maxOTPLimitReached =
                            'The Maximum OTP limit has been reached, Please try again in 15:00 minutes';
                        return;
                    }
                    if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = err.error;
                    }
                }
            );
    }

    disableOttLoginForm() {
        if (!(this.ottForm.value.otp && (this.ottForm.value.otp || '').match(/^\d{6}$/))) {
            return true;
        }
        return false;
    }

    verifyOTP() {
        if (this.verifyingOTP) {
            return;
        }
        this.verifyingOTP = true;
        this.authService
            .verifyOTP({
                phoneNo: this.ottForm.value.phoneNo,
                otp: this.ottForm.value.otp,
                code: this.invitecode,
                username: this.signupForm.value.name?.trim(),
                lname: this.signupForm.value.lname?.trim()
            })
            .subscribe(
                (res: any) => {
                    this.verifyingOTP = false;
                    if (res.success === false) {
                        res.message === 'OTPAUTHERROR'
                            ? (this.errors.otp = 'Entered OTP is incorrect')
                            : (this.errors.global = res.errors);
                        this.verifyingOTP = false;
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign In',
                                eventAction: 'Verify OTP',
                                eventType: 'api_failure',
                                endpoint: '/api/verifyotp',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Verify OTP',
                            eventType: 'app_event',
                            endpoint: '/api/verifyotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();

                    if (res.token) {
                        this.authService
                            .verifyOtpDualLogin({
                                phoneNo: this.ottForm.value.phoneNo,
                                otp: this.ottForm.value.otp,
                                token: res.token
                            })
                            .subscribe(() => {
                                if (this.alreadyRegistered) {
                                    this.router.navigate(['/dashboard']);
                                } else {
                                    this.router.navigate(['/onboarding']);
                                }
                            });
                    } else {
                        if (this.alreadyRegistered) {
                            this.router.navigate(['/dashboard']);
                        } else {
                            this.router.navigate(['/onboarding']);
                        }
                    }
                },
                (err) => {
                    this.errors.global = 'Entered OTP is incorrect';
                    this.ottLogin = true;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Verify OTP',
                            eventType: 'api_failure',
                            endpoint: '/api/verifyotp',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
    }

    changePhoneNo() {
        this.ottLogin = false;
    }

    navigateToLogin() {
        this.router.navigate(['login']);
    }

    launchTnc() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Terms and Conditions',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }

    launchPrivacy() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Privacy Policy',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    loginWithSSO(providerName) {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Sign In',
                eventType: 'app_event',
                eventAction: `Sign In with ${providerName}`,
                endpoint: `/api/authorize/${providerName}`,
                status: 'success'
            })
            .subscribe();
        this.authService.doSSOLogin({ providerName }).subscribe((res) => {
            // this.localStorageService.addItem('samlInfo', res);
            window.location.href = res.authUrl;
        });
    }

    validateErrors(control: string, formGroup: FormGroup = this.signupForm): string {
        const formControl = formGroup.get(control);
        if (!formControl || !formControl.errors) {
            return;
        }
        return Object.keys(formControl.errors)[0];
    }

    resendEmail() {
        this.authService.resendEmail(this.signupForm.value.emailId).subscribe(
            () =>
                this.toastrService.info(
                    this.translateService.instant(
                        'tostrs.we_have_sent_a_verification_link_to_your_registered_email_please_verify_to_login'
                    )
                ),
            () => this.toastrService.error(this.translateService.instant('tostrs.resend_failed_please_try_again_later'))
        );
    }

    userAnotherEmail() {
        this.mailSent = false;
        this.ottLogin = false;
        this.signupForm.reset();
        this.ottForm.reset();
    }
}
