<ng-container *ngIf="!isloading">
    <div class="landing-page-wrapper" id="landingPageWrapper">
        <section id="hero" class="d-flex align-items-center">
            <div class="show-on-scroll wow bounceInLeft w-100 visible-section">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner desktop-view" *ngIf="homePageBanner?.data">
                        <owl-carousel [options]="carouselOptions">
                            <ng-container *ngFor="let itemUrl of homePageBanner?.data; let i = index">
                                <div class="desktop-container">
                                    <img
                                        appImagePlaceholder
                                        class="desktop-banner"
                                        [useBackgroundStyling]="'default'"
                                        [useImgAsOverlayBg]="true"
                                        [actualImageSrc]="itemUrl?.attributes?.webBannerUrl?.data?.attributes?.url"
                                        alt="Event Banner"
                                    />
                                    <div class="overlay_1"></div>
                                    <div class="image-banner-text">
                                        <div class="d-flex">
                                            <p class="tag-indicator tag-text-wrapper">
                                                {{ itemUrl?.attributes?.category }}{{ ' ' }}
                                            </p>
                                            <div
                                                class="status-content-wrapper"
                                                *ngIf="itemUrl?.attributes?.status == 'Live'"
                                            >
                                                <div class="tag-text-devider">{{ '|' }}</div>
                                                <div class="live-event-indicator">
                                                    <div class="blink"></div>
                                                </div>
                                                <p class="live-text-indicator tag-text-wrapper">
                                                    {{ itemUrl?.attributes?.status }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="event-title-length">
                                            <p class="boujee-text event-title">{{ itemUrl?.attributes?.eventTitle }}</p>
                                        </div>
                                        <div
                                            class="speaker-top-view"
                                            *ngIf="
                                                itemUrl?.attributes?.event_speakers &&
                                                itemUrl?.attributes?.event_speakers?.data?.length > 0
                                            "
                                        >
                                            <p class="jiotype-black-white-24px">Speakers</p>
                                            <div class="d-flex">
                                                <div
                                                    *ngFor="
                                                        let speaker of itemUrl?.attributes?.event_speakers?.data
                                                            | slice: startIndex:startIndex + itemsPerPage
                                                    "
                                                >
                                                    <div class="speaker-position">
                                                        <img
                                                            src="{{
                                                                speaker?.attributes?.profilePic?.data?.attributes?.url
                                                            }}"
                                                            alt=""
                                                            class="speaker-image-desktop-view"
                                                        />
                                                        <div class="speaker-name-text">
                                                            {{ speaker?.attributes?.name }}
                                                        </div>
                                                        <p class="speaker-text">
                                                            {{ truncatedName(speaker?.attributes?.designation) }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p
                                                    *ngIf="itemUrl?.attributes?.event_speakers?.data?.length > 3"
                                                    class="jiotype-black-white-24px speaker-count"
                                                >
                                                    +{{ itemUrl?.attributes?.event_speakers?.data?.length - 3 }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="button-position-view">
                                            <button
                                                class="
                                                    custom-button-revamp
                                                    btn-primary btn-height
                                                    w-auto
                                                    button-indicator
                                                    regitration-button-view
                                                "
                                                (click)="redirectToAttendeeLink(itemUrl?.attributes?.attendeeUrl)"
                                                *ngIf="itemUrl?.attributes?.registrationEnabled"
                                            >
                                                Register Now
                                            </button>
                                            <button
                                                class="
                                                    custom-button-revamp
                                                    btn-height
                                                    w-auto
                                                    button-indicator button-view
                                                "
                                                [ngClass]="
                                                    itemUrl?.attributes?.registrationEnabled
                                                        ? 'btn-secondary'
                                                        : 'btn-primary'
                                                "
                                                (click)="redirectToAttendeeLink(itemUrl?.attributes?.attendeeUrl)"
                                            >
                                                {{ itemUrl?.attributes?.ctaButtonText || 'View Details' }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </owl-carousel>
                    </div>

                    <div class="row mobile-sec-view" *ngIf="homePageBanner?.data">
                        <owl-carousel [options]="carouselOptions">
                            <ng-container *ngFor="let itemUrl of homePageBanner?.data">
                                <div
                                    class="mobile-container"
                                    [ngClass]="{
                                        'without-speakers':
                                            !itemUrl?.attributes?.event_speakers ||
                                            itemUrl?.attributes?.event_speakers?.data?.length === 0
                                    }"
                                >
                                    <img
                                        appImagePlaceholder
                                        class="w-100"
                                        [useBackgroundStyling]="'default'"
                                        [actualImageSrc]="itemUrl?.attributes?.mobileBannerUrl?.data?.attributes?.url"
                                        alt="Event Banner"
                                    />
                                    <div class="overlay_1"></div>
                                    <div class="image-banner-text">
                                        <div class="d-flex">
                                            <p class="tag-indicator tag-text-mobile-view">
                                                {{ itemUrl?.attributes?.category }}{{ ' ' }}
                                            </p>
                                            <div
                                                class="status-content-wrapper"
                                                *ngIf="itemUrl?.attributes?.status == 'Live'"
                                            >
                                                <div class="tag-text-devider">{{ '|' }}</div>
                                                <div class="live-event-indicator">
                                                    <div class="blink"></div>
                                                </div>
                                                <p class="live-text-indicator tag-text-mobile-view">
                                                    {{ itemUrl?.attributes?.status }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="event-title-length">
                                            <span class="boujee-text event-title">
                                                {{ itemUrl?.attributes?.eventTitle }}
                                            </span>
                                        </div>
                                        <div
                                            class="speaker-top-view"
                                            *ngIf="
                                                itemUrl?.attributes?.event_speakers &&
                                                itemUrl?.attributes?.event_speakers?.data?.length > 0
                                            "
                                        >
                                            <p class="speaker-text-mobile">Speakers</p>
                                            <div class="d-flex">
                                                <div
                                                    *ngFor="
                                                        let speaker of itemUrl?.attributes?.event_speakers?.data
                                                            | slice: startIndex:startIndex + itemsPerPage
                                                    "
                                                >
                                                    <div class="speaker-position">
                                                        <img
                                                            src="{{
                                                                speaker?.attributes?.profilePic?.data?.attributes?.url
                                                            }}"
                                                            alt=""
                                                            class="speaker-image-mobile-view"
                                                        />
                                                        <div class="speaker-text-mobile text-alignment">
                                                            {{ truncatedName(speaker?.attributes?.name) }}
                                                        </div>
                                                        <p class="speaker-text text-alignment">
                                                            {{ truncatedName(speaker?.attributes?.designation) }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p
                                                    *ngIf="itemUrl?.attributes?.event_speakers?.data?.length > 3"
                                                    class="speaker-count-mobile"
                                                >
                                                    +{{ itemUrl?.attributes?.event_speakers?.data?.length - 3 }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="btn-view">
                                            <button
                                                class="
                                                    custom-button-revamp
                                                    btn-primary btn-height
                                                    w-auto
                                                    button-indicator button-position-wrapper
                                                "
                                                (click)="redirectToAttendeeLink(itemUrl?.attributes?.attendeeUrl)"
                                                *ngIf="itemUrl?.attributes?.registrationEnabled"
                                            >
                                                Register Now
                                            </button>
                                            <button
                                                class="
                                                    custom-button-revamp
                                                    btn-height
                                                    w-auto
                                                    button-indicator button-position-wrapper
                                                "
                                                (click)="redirectToAttendeeLink(itemUrl?.attributes?.attendeeUrl)"
                                                [ngClass]="
                                                    itemUrl?.attributes?.registrationEnabled
                                                        ? 'btn-secondary'
                                                        : 'btn-primary'
                                                "
                                            >
                                                {{ itemUrl?.attributes?.ctaButtonText || 'View Details' }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </owl-carousel>
                    </div>
                </div>
            </div>
        </section>
        <section id="services" class="services section-bg show-on-scroll">
            <app-home-event-view [eventView]="homePageView"></app-home-event-view>
        </section>
        <section id="faq" class="faq section-bg show-on-scroll">
            <div class="intro-video-section-wrapper">
                <div
                    class="section-title text-decorator-view data-scroll-header"
                    id="videoText"
                    [class.in-view]="showVideoText"
                >
                    <span class="audience_heading boujee-text audience-wrapper text-wrapper-view text-position-view">
                        {{ 'HOME_PAGE_REVAMP.event_video_header' | translate }}
                    </span>
                    <span class="audience_heading boujee-text audience-wrapper text-wrapper-view">
                        {{ 'HOME_PAGE_REVAMP.event_video_sub_header' | translate }}
                    </span>
                </div>
                <div class="video-bg-wrapper" id="videoSection">
                    <div class="video-player-area view-control-wrapper">
                        <div *ngIf="!isVideoPlaying">
                            <img class="play-button-container" src="assets/img/video_playing_blur.svg" alt="" />
                            <div class="play-button-view grow grow1"></div>
                            <div class="play-button-view" (click)="playVideo()">
                                <img src="assets/img/video_player_icon.svg" alt="" />
                            </div>
                        </div>
                        <video #videoPlayer playsinline class="intro-video-element" autoplay loop [muted]="'muted'">
                            <source src="{{ videoSource }}" type="video/mp4" />
                        </video>
                        <button class="mute-button" (click)="toggleMute()" *ngIf="isVideoPlaying">
                            <ng-container *ngIf="isMuted; else unmuteIcon">
                                <div (click)="muteVideo()">
                                    <img src=" assets/img/revamp/unmute_video.svg" class="unmute-img" alt="" />
                                </div>
                            </ng-container>
                            <ng-template #unmuteIcon>
                                <div (click)="unmuteVideo()">
                                    <img src="assets/img/revamp/mute_video.svg" class="mute-img" alt="" />
                                </div>
                            </ng-template>
                        </button>
                    </div>
                    <img class="video-background" src="assets/img/website/image_background.png" />
                </div>
                <app-event-clients></app-event-clients>
                <div
                    id="videoCard"
                    class="boujee-text audience-wrapper text-decorator-view text-wrapper-view data-scroll-header"
                    [class.in-view]="showVideoCard"
                >
                    {{ 'HOME_PAGE_REVAMP.cards_desktop_header' | translate }}
                </div>
                <div
                    id="videoSubCard"
                    class="d-flex justify-content-center align-items-center text-message data-scroll-sub-header"
                    [class.in-view]="showVideoSubCard"
                >
                    {{ 'HOME_PAGE_REVAMP.cards_desktop_descp' | translate }}
                </div>
            </div>
        </section>
        <section class="background-view-container">
            <div class="background-view">
                <div class="card-ticker">
                    <div class="card-ticker-container">
                        <div
                            *ngFor="let card of cards; let i = index"
                            (mouseenter)="handleMouseEnter(i)"
                            (mouseleave)="handleMouseEnter(i)"
                        >
                            <div class="card-view card-area-wrapper">
                                <div
                                    [ngStyle]="
                                        isHovered[i]
                                            ? { background: card['hovercolor'] }
                                            : { background: card['color'] }
                                    "
                                    class="img-icon-wrapper-view"
                                >
                                    <img src="{{ card.image }}" alt="" class="img-icon-wrapper" />
                                </div>
                                <h3 class="text-control-wrapper">
                                    {{ card.title }}
                                </h3>
                                <p class="description-text">{{ card.description }}</p>
                            </div>
                        </div>
                        <div
                            *ngFor="let card of cards; let i = index"
                            (mouseenter)="handleMouseEnter(i)"
                            (mouseleave)="handleMouseEnter(i)"
                        >
                            <div class="card-view card-area-wrapper">
                                <div
                                    [ngStyle]="
                                        isHovered[i]
                                            ? { background: card['hovercolor'] }
                                            : { background: card['color'] }
                                    "
                                    class="img-icon-wrapper-view"
                                >
                                    <img src="{{ card.image }}" alt="" class="img-icon-wrapper" />
                                </div>
                                <h3 class="text-control-wrapper">
                                    {{ card.title }}
                                </h3>
                                <p class="description-text">{{ card.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-ticker-rev">
                    <div class="card-ticker-container-rev">
                        <div
                            *ngFor="let card of reverseCard.slice().reverse(); let j = index"
                            (mouseenter)="handleReverseCard(j)"
                            (mouseleave)="handleReverseCard(j)"
                        >
                            <div class="card-view card-area-wrapper">
                                <div
                                    [ngStyle]="
                                        isHoveredCard[j]
                                            ? { background: card['hovercolor'] }
                                            : { background: card['color'] }
                                    "
                                    class="img-icon-wrapper-view"
                                >
                                    <img src="{{ card.image }}" alt="" class="img-icon-wrapper" />
                                </div>
                                <h3 class="text-control-wrapper">
                                    {{ card.title }}
                                </h3>
                                <p class="description-text">{{ card.description }}</p>
                            </div>
                        </div>
                        <div
                            *ngFor="let card of reverseCard.slice().reverse(); let j = index"
                            (mouseenter)="handleReverseCard(j)"
                            (mouseleave)="handleReverseCard(j)"
                        >
                            <div class="card-view card-area-wrapper">
                                <div
                                    [ngStyle]="
                                        isHoveredCard[j]
                                            ? { background: card['hovercolor'] }
                                            : { background: card['color'] }
                                    "
                                    class="img-icon-wrapper-view"
                                >
                                    <img src="{{ card.image }}" alt="" class="img-icon-wrapper" />
                                </div>
                                <h3 class="text-control-wrapper">
                                    {{ card.title }}
                                </h3>
                                <p class="description-text">{{ card.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="section-wrapper">
            
            <app-event-testimonial-card></app-event-testimonial-card>
        </section> -->
        <section class="section-bg">
            <app-trial-event></app-trial-event>
        </section>
    </div>
</ng-container>
<app-loader *ngIf="isloading"></app-loader>
