import { Injectable } from '@angular/core';
import { SystemService } from '.';
import { AppService } from './app.service';
import { UserService } from './user.service';
import { UtilService } from './util.service';
declare var window: any;
@Injectable({
    providedIn: 'root'
})
export class GoogleTagMangerService {
    currentUser;
    clientId: String;
    device_id: String;
    env: String;
    deviceType: String;
    OS: String;
    jio_platform: String;
    os_version: String;
    constructor(
        private systemService: SystemService,
        private utilService: UtilService,
        private userService: UserService,
        private appService: AppService
    ) {
        this.clientId = this.getCookie('_ga').split('.').pop();
        this.device_id = this.systemService.getDeviceUUID();
        this.env = this.appService.getConfigVariable('production') ? 'prod' : 'dev';
        this.OS = this.utilService.getOS();
        this.deviceType = this.utilService.deviceType();
        this.jio_platform = 'jioevents';
        this.os_version = navigator.appVersion;
    }

    getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    // encrypt(value) {
    //     return shajs('sha256').update(value).digest('hex');
    // }

    pushGoogleTagData(data) {
        this.currentUser = this.userService.getUserSync();
        const userId = this.currentUser._id ? this.currentUser._id : 'Guest';
        const isLoggedIn = this.currentUser._id ? true : false;
        let gtmObj = {};
        let commonData = {
            env: this.env,
            platform: 'website',
            device: this.deviceType,
            os: this.OS,
            os_version: this.os_version,
            jio_platform: this.jio_platform,
            user_id: userId,
            client_id: this.clientId,
            device_id: this.device_id,
            loggedin_status: isLoggedIn
        };
        gtmObj = Object.assign({}, commonData, data);
        window.dataLayer.push(gtmObj);
    }

    addMeetingBasicInfo(eventData, meetingInfo) {
        this.pushGoogleTagData(Object.assign({}, eventData, meetingInfo));
    }
}
