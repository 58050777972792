import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isSameDay } from 'date-fns';
import { MeetingService } from 'src/app/core';

@Component({
    selector: 'app-event-status',
    templateUrl: './event-status.component.html',
    styleUrls: ['./event-status.component.scss']
})
export class EventStatusComponent implements OnInit, OnChanges {
    @Input()
    eventInfo: any = {};
    @Input()
    webinarDetails: any = {};
    status = '';
    @Input()
    selectedDate: any;
    constructor(private meetingService: MeetingService) {}

    ngOnInit(): void {
        this.setSessionStatus();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['eventInfo'] || changes['webinarDetails'] || changes['selectedDate']) {
            this.setSessionStatus();
        }
    }

    setSessionStatus() {
        const isRecurring = this.webinarDetails?.repeatOptions?.repeat !== 'none';
        var isDateSelected = !!this.selectedDate;
        const isSessionOnSameDay = isRecurring
            ? isDateSelected
                ? isSameDay(this.selectedDate, new Date(this.eventInfo.startTime))
                : true
            : true;
        if (isSessionOnSameDay) {
            this.status = this.meetingService.getEventStatus(this.eventInfo, this.webinarDetails);
        } else {
            this.status = '';
        }
    }
}
