<div class="video-test-container">
    <div class="video-test-top">
        <div class="video-info-section">
            <div class="video-test-camera-selection">
                <div class="video-feed-section">
                    <div class="video-container">
                        <div class="video-info">
                            <div class="video-img">
                                <img
                                    [src]="
                                        'assets/images/pre-call-testing/' +
                                        (cameraFailed ? 'camera-not-working.svg' : 'camera-grey.svg')
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="video-text">
                                {{ videoMessage }}
                            </div>
                        </div>
                        <video class="video" id="video-feed"></video>
                    </div>
                </div>
                <div class="device-heading">Camera</div>
            </div>
        </div>
    </div>
    <div class="device-dropdown">
        <select class="device-dropdown-select" (change)="selectCamera($event)">
            <option *ngFor="let cam of availableCameras" [value]="cam.id">{{ cam.name }}</option>
        </select>
    </div>
    <div class="video-test-bottom">
        <div class="test-button-container">
            <div class="start-button" [class.test-in-progress]="camTestInProgress" (click)="startTest()">
                <span *ngIf="!camTestInProgress" class="button-text">
                    <span> Start Video Test </span>
                    <!-- <span *ngIf="cameraResult">
                        <img src="assets/images/pre-call-testing/reset.svg" alt="" />
                    </span> -->
                </span>
                <span *ngIf="camTestInProgress"> Test in progress ({{ this.countDown }}s) </span>
            </div>
            <div class="view-result-button" *ngIf="cameraResult" (click)="viewResult()">View Results</div>
        </div>
    </div>
</div>
<app-video-result-modal
    *ngIf="isModalOpen"
    (closeModalEvent)="onCloseModalEvent()"
    [cameraResult]="cameraResult"
></app-video-result-modal>
