<div class="call-settings-component-wrapper" *ngIf="callViewStateManagerService.callScreenState !== 'pip'">
    <div class="call-settings-container" [class.networking-room]="isNetworkingRoomCall">
        <div class="header">
            <span>Settings</span>

            <img class="close" src="assets/img/chat_close.svg" alt="" (click)="close()" id="closeTabIcon" />
        </div>
        <div class="tab-wrapper" [class.mobile]="isMobile">
            <div class="tabs">
                <div class="mobile-view"></div>
                <ng-container *ngFor="let tab of tabs; let i = index">
                    <div
                        *ngIf="isPreCallTest ? i >= 3 : i < 3"
                        class="tab"
                        [class.selected]="selectedLabel === tab.label"
                        (click)="openSetting(tab)"
                        [class.hide]="tab.hideTab"
                        [class.disabled]="tab?.disabled"
                        id="{{ tab.label }}"
                    >
                        <img src=" {{ selectedLabel === tab.label ? tab.altImg : tab.img }}" alt="" />
                        <span>{{ tab.tkey | translate }}</span>
                    </div>
                </ng-container>
            </div>
            <div class="content vb-content-area" [class.vb-content]="selectedLabel === 'background'">
                <ng-template viewHost></ng-template>
            </div>
        </div>
    </div>
</div>

<div class="video" id="preview-renderer" style="display: none"></div>
