import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNumber } from 'util';
import { AppService, UtilService, ValidationService, RtcService, AuthService, UserService } from 'src/app/core';
import { JsonPipe } from '@angular/common';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { DiagnosticService } from 'src/app/core/services/diagnostic.service';
@Component({
    selector: 'app-join-meeting',
    templateUrl: './join-meeting.component.html',
    styleUrls: ['./join-meeting.component.scss']
})
export class JoinMeetingComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private router: Router,
        private appService: AppService,
        private utilService: UtilService,
        private rtcService: RtcService,
        private authService: AuthService,
        private userService: UserService,
        private validdatorService: ValidationService,
        private gaService: GaDiagnosticService,
        private diagnosticService: DiagnosticService
    ) {}

    joinMeetingForm: FormGroup;
    errors: any = {};
    meetingIdData;
    hashData;
    isMeetingIdFlow = false;
    isHashFlow = false;
    room;
    working = false;
    isAuthenticated = false;

    ngOnInit() {
        this.gaService.sendPageView({
            page_title: 'join_a_meeting_page',
            page_path: '/join-meeting'
        });
        this.joinMeetingForm = this.fb.group({
            meetingId: [null, [Validators.required, this.validdatorService.noWhitespaceValidator]]
        });
    }

    collectVariables() {
        if (this.joinMeetingForm.value.meetingId.trim().startsWith(this.appService.getEnvVariable('HOST_URL'))) {
            const url = this.joinMeetingForm.value.meetingId;
            if (this.utilService.getParameterByName('meetingId', url)) {
                this.isMeetingIdFlow = true;
                this.meetingIdData = {
                    meetingId: this.utilService.getParameterByName('meetingId', url),
                    pwd: this.utilService.getParameterByName('pwd', url)
                };
                return true;
            }

            if (this.utilService.getParameterByName('hash', url)) {
                this.isHashFlow = true;
                this.hashData = {
                    hash: this.utilService.getParameterByName('hash', url),
                    pwd: this.utilService.getParameterByName('pwd', url),
                    isPinEnabled: this.utilService.getParameterByName('isPinEnabled', url)
                };
                return true;
            }
            return false;
        } else {
            this.joinMeetingForm.value.meetingId = this.joinMeetingForm.value.meetingId.replace(/-|\s/g, '');
            if (this.joinMeetingForm.value.meetingId.length === 10) {
                this.isMeetingIdFlow = true;
                this.meetingIdData = {
                    meetingId: this.joinMeetingForm.value.meetingId
                };
                return true;
            }
            return false;
        }
    }

    validate() {
        this.errors = {};
        if (!this.joinMeetingForm.value.meetingId) {
            this.errors.meetingId = 'Please enter a valid Meeting ID or Meeting Link';
            return false;
        }

        if (!this.collectVariables()) {
            this.errors.meetingId = 'Please enter a valid Meeting ID or Meeting Link';
            return false;
        }
        return true;
    }

    submit() {
        if (!this.validate()) {
            return;
        }

        if (this.isMeetingIdFlow) {
            this.working = true;
            this.rtcService.isValidRoomAndPinFlag({ extension: this.meetingIdData.meetingId }).subscribe(
                (res: any) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: this.isAuthenticated ? 'Join a Meeting' : 'Join as a Guest',
                            eventAction: this.isAuthenticated
                                ? 'Join with Meeting ID'
                                : 'Join as a Guest with Meeting ID',
                            eventType: 'app_event',
                            endpoint: '/api/shorturl/roomdetails/ispin',
                            status: 'success'
                        })
                        .subscribe();

                    this.router.navigate(['launch'], {
                        queryParams: { meetingId: this.meetingIdData.meetingId, pwd: this.meetingIdData.pwd }
                    });
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: this.isAuthenticated ? 'Join a Meeting' : 'Join as a Guest',
                            eventAction: this.isAuthenticated
                                ? 'Join with Meeting ID'
                                : 'Join as a Guest with Meeting ID',
                            eventType: 'api_failure',
                            endpoint: '/api/shorturl/roomdetails/ispin',
                            status: 'failed'
                        })
                        .subscribe();
                    this.working = false;
                    this.errors.global = err.error.errors;
                }
            );
        } else if (this.isHashFlow) {
            this.working = true;
            this.rtcService.isValidRoomAndPinFlag({ hash: this.hashData.hash }).subscribe(
                (res: any) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: this.isAuthenticated ? 'Join a Meeting' : 'Join as a Guest',
                            eventAction: this.isAuthenticated ? 'Join with URL' : 'Join as a Guest with URL',
                            eventType: 'app_event',
                            endpoint: '/api/shorturl/roomdetails/ispin',
                            status: 'success'
                        })
                        .subscribe();
                    this.router.navigate(['launch'], {
                        queryParams: {
                            hash: this.hashData.hash,
                            isPinEnabled: this.hashData.isPinEnabled,
                            pwd: this.hashData.pwd
                        }
                    });
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: this.isAuthenticated ? 'Join a Meeting' : 'Join as a Guest',
                            eventAction: this.isAuthenticated ? 'Join with URL' : 'Join as a Guest with URL',
                            eventType: 'api_failure',
                            endpoint: '/api/shorturl/roomdetails/ispin',
                            status: 'failed'
                        })
                        .subscribe();
                    this.working = false;
                    this.errors.global = err.error.errors;
                }
            );
        }
    }

    onPaste(event: ClipboardEvent) {
        setTimeout(() => {
            const value = (event.target as HTMLInputElement).value;
            if (value.includes('-')) {
                (event.target as HTMLInputElement).value = value.replace(/-/g, ' ');
            } else if (!/\s/.test(value)) {
                if (/^[1-9]\d*$/.test(value)) {
                    return;
                }
                (event.target as HTMLInputElement).value = `${value.substring(0, 3)} ${value.substring(
                    3,
                    6
                )} ${value.substring(6)}`;
            }
        }, 0);
    }

    handleBackspace(event: ClipboardEvent) {
        event.stopPropagation();
        setTimeout(() => {
            const value = (event.target as HTMLInputElement).value;
            if (value) {
                if (value[value.length - 1] === ' ') {
                    (event.target as HTMLInputElement).value = value.substr(0, value.length - 1);
                } else {
                    // (event.target as HTMLInputElement).value = value.substr(0, value.length - 1);
                }
            }
        }, 0);
    }

    validateNumberPattern(event) {
        const value = (event.target as HTMLInputElement).value;
        if (/^[0-9,\s]*$/.test(value)) {
            if (value.length === 3 || value.length === 7) {
                (event.target as HTMLInputElement).value = value + ' ';
            }
        }
    }
}
