import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
import { SUPPORT } from '../../../constants/gtm-events';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
    public SUPPORT: any = SUPPORT;
    constructor(private googleTagManager: GoogleTagMangerService, private router: Router) {}

    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.SUPPORT_PAGR_VIEW);
    }

    featureBoxClick(event) {
        this.googleTagManager.pushGoogleTagData(event);
        this.navigateComingSoon();
    }

    supportCentre() {
        this.googleTagManager.pushGoogleTagData(SUPPORT.SUPPORT_CENTRE);
        this.navigateComingSoon();
    }

    navigateComingSoon() {
        this.router.navigateByUrl('/comingsoon');
    }
}
