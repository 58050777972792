<div class="test-container">
    <div class="info-section">
        <div class="test-subheading">
            Your audio and video appear to be functioning properly. If the problem persists, please contact your network
            administrator.
        </div>
    </div>
    <div class="result-container">
        <div class="result-parameter">
            <div class="parameter-status">
                <img [src]="'assets/images/pre-call-testing/' + preCallTestingService.deviceTestImg + '.svg'" alt="" />
            </div>
            <div class="parameter-name">
                Device Setup
                <img
                    src="assets/images/information-icon.svg"
                    class="i-icon"
                    *ngIf="
                        preCallTestingService.deviceTestImg === 'failed' ||
                        preCallTestingService.deviceTestImg === 'warning'
                    "
                    [pTooltip]="
                        preCallTestingService.deviceTestImg === 'failed' ||
                        preCallTestingService.deviceTestImg === 'warning'
                            ? ('joinmeeting_page_or_joincall.precalltest_devicesetup' | translate)
                            : null
                    "
                    tooltipStyleClass="tooltip-revamp"
                />
            </div>
        </div>
        <div class="result-parameter">
            <div class="parameter-status">
                <img
                    [src]="'assets/images/pre-call-testing/' + preCallTestingService.connectivityTestImg + '.svg'"
                    alt=""
                />
            </div>
            <div class="parameter-name">
                Connectivity
                <img
                    src="assets/images/information-icon.svg"
                    class="i-icon"
                    *ngIf="
                        preCallTestingService.connectivityTestImg === 'failed' ||
                        preCallTestingService.connectivityTestImg === 'warning'
                    "
                    [pTooltip]="
                        preCallTestingService.connectivityTestImg === 'success'
                            ? null
                            : ('joinmeeting_page_or_joincall.precalltest_connectivity' | translate)
                    "
                    tooltipStyleClass="tooltip-revamp"
                />
            </div>
        </div>

        <div class="result-parameter">
            <div class="parameter-status">
                <img
                    [src]="'assets/images/pre-call-testing/' + preCallTestingService.performanceTestImg + '.svg'"
                    alt=""
                />
            </div>
            <div class="parameter-name">Performance</div>
        </div>
    </div>
    <div class="button-container">
        <div class="test-again-button" (click)="restartTest()">Restart Test</div>
    </div>
</div>
