<div [class.visibility-hidden]="autoJoin && !waitingRoomDetails?.waitingRoom" *ngIf="!loading">
    <div
        class="page container-fluid"
        *ngIf="!joinBeforeHost && !waitingRoomDetails?.waitingRoom && !allowAccessUI"
        [class.guest-speaker-container]="!isAuthenticated"
    >
        <div class="header-section" *ngIf="isAuthenticated">
            <div class="title">You’re about to join {{ isSession ? 'a Session...' : 'an Event...' }}</div>
            <div class="side-note mt-1">
                {{ 'joinmeeting_page_or_joincall.pleasemsg' | translate }}
            </div>
        </div>
        <div class="d-flex justify-content-center" [class.guest-speaker]="!isAuthenticated">
            <div class="preview-container">
                <div class="call-preview">
                    <app-call-preview
                        (showsettings)="openSettingsPopup($event)"
                        [hostMeeting]="false"
                    ></app-call-preview>
                    <div class="header-section" *ngIf="!isAuthenticated">
                        <div class="side-note mt-1">
                            {{ 'joinmeeting_page_or_joincall.pleasemsg' | translate }}
                        </div>
                    </div>
                </div>

                <div class="col p-0 w-100 mt-5 text-center" *ngIf="isAuthenticated" [class.disabled]="showPermissionUI">
                    <div class="text-error mb-4" *ngIf="errors.global">{{ errors.global }}</div>
                    <button
                        type="button"
                        (click)="join()"
                        class="custom-button bg-primary join-btn"
                        [class.disabled]="joining || showPermissionUI"
                    >
                        {{
                            (joining ? 'joinmeeting_page_or_joincall.please_wait' : 'joinmeeting_page_or_joincall.join')
                                | translate
                        }}
                    </button>
                </div>
            </div>
            <div class="right-content" *ngIf="!isAuthenticated">
                <div class="component-wrap">
                    <div class="form-wrap">
                        <section *ngIf="!loggedInOnly">
                            <h3 class="mb-4">{{ 'joinmeeting_page_or_joincall.title' | translate }}</h3>
                            <form [formGroup]="form" (submit)="join()">
                                <div class="form-group">
                                    <label for="fname" class="m-0 mb-2"
                                        >{{ 'joinmeeting_page_or_joincall.fname' | translate
                                        }}<span class="text-error">*</span></label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="fname"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="fname"
                                        placeholder="{{ 'joinmeeting_page_or_joincall.fnameplaceholder' | translate }}"
                                        [appInputMaxLength]="30"
                                        customClass="limit-count"
                                        [class.disabled]="isinvitedUser"
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="lname" class="m-0 mb-2"
                                        >{{ 'joinmeeting_page_or_joincall.lname' | translate
                                        }}<span class="text-error">*</span></label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="lname"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="lname"
                                        placeholder="{{ 'joinmeeting_page_or_joincall.lnameplaceholder' | translate }}"
                                        [appInputMaxLength]="30"
                                        customClass="limit-count"
                                        [class.disabled]="isinvitedUser"
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="userIdentity" class="m-0 mb-2">{{
                                        'joinmeeting_page_or_joincall.userIdentity' | translate
                                    }}</label>
                                    <input
                                        class="form-control"
                                        id="userIdentity"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="userIdentity"
                                        placeholder="{{
                                            'joinmeeting_page_or_joincall.userIdentityplaceholder' | translate
                                        }}"
                                        [appInputMaxLength]="50"
                                        customClass="limit-count"
                                        [class.disabled]="isinvitedUser"
                                    />
                                </div>
                                <span class="is-invalid" *ngIf="form?.controls.userIdentity.errors?.pattern">
                                    {{ 'joinmeeting_page_or_joincall.errors.invalid_userIdentity' | translate }}
                                </span>
                                <span class="text-error" *ngIf="errors.global">{{ errors.global }}</span>
                                <div class="col p-0 w-100 mt-4">
                                    <button
                                        type="submit"
                                        class="btn primary"
                                        [class.disabled]="!form.valid || joining || showPermissionUI"
                                    >
                                        {{
                                            (joining
                                                ? 'joinmeeting_page_or_joincall.please_wait'
                                                : isHost
                                                ? 'joinmeeting_page_or_joincall.start'
                                                : 'joinmeeting_page_or_joincall.join'
                                            ) | translate
                                        }}
                                    </button>
                                </div>
                            </form>
                        </section>
                        <section *ngIf="loggedInOnly" class="signin-container">
                            <h2 class="text-center mb-2 text-color">Please login.</h2>
                            <div class="d-flex justify-content-center sub-title mt-3">
                                Host has restricted only logged-in users to join this meeting
                            </div>
                            <div class="col p-0 w-100 mt-5 d-flex justify-content-center">
                                <button class="btn primary w-50 button-color" (click)="navigateToLogin()">
                                    <a class="text-white">Sign In</a>
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="allowAccessUI" class="page container-fluid" [class.guest-speaker-container]="!isAuthenticated">
        <div class="d-flex justify-content-center access-info-container" [class.guest-speaker]="!isAuthenticated">
            <div class="access-info-preview">
                <img
                    *ngIf="isNonSafariBrowserFromMacOrIos"
                    class="stepsImage"
                    src="assets/img/access_info_mac_chrome.png"
                    alt="media access info"
                />
                <img
                    *ngIf="isNonSafariBrowserFromWindowsOrAndroid"
                    class="stepsImage"
                    src="assets/img/access_info_win_chrome.png"
                    alt="media access info"
                />
                <img
                    *ngIf="isSafari"
                    class="stepsImage"
                    src="assets/img/access_info_safari.svg"
                    alt="media access info"
                />
            </div>
            <div class="access-info-text">
                <div class="access-info-heading">Allow access to camera and mic</div>
                <div class="access-info-subheading">
                    To enable your audio and video, click <b>Allow</b> in the browser pop-up above. You may still turn
                    them off later at any time
                </div>
            </div>
        </div>
    </div>

    <section *ngIf="joinBeforeHost && !waitingRoomDetails?.waitingRoom" class="err-scenario">
        <h3>{{ 'joinmeeting_page_or_joincall.waiting_for_host_to_start' | translate }}</h3>
        <div class="loader my-5">
            <app-loader></app-loader>
        </div>
    </section>

    <section *ngIf="waitingRoomDetails?.waitingRoom" class="err-scenario">
        <h3>{{ 'joinmeeting_page_or_joincall.please_wait_while' | translate }}</h3>
        <div class="loader my-5">
            <app-loader></app-loader>
        </div>
        <button type="button" class="btn primary" (click)="leaveWaitingRoom()">
            {{ 'joinmeeting_page_or_joincall.leave' | translate }}
        </button>
    </section>

    <ng-template viewHost></ng-template>
    <app-permission-denied *ngIf="showPermissionUI && !allowAccessUI" [showSkipOption]="true"></app-permission-denied>
</div>

<div class="auto-join-view" *ngIf="autoJoin && joining && !waitingRoomDetails?.waitingRoom">
    <app-conference-preloader title="Joining Main Room"></app-conference-preloader>
</div>

<div class="loader" *ngIf="loading">
    <app-loader></app-loader>
</div>
