import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    @Output() close = new EventEmitter();
    @Output() acceptClick = new EventEmitter();
    @Input() type;
    @Input() bodyText = '';
    @Input() bodyTemplateRef: TemplateRef<any> = null;
    @Input() headerText = '';
    @Input() acceptText;
    @Input() rejectText;
    @Input() theme = 'light';
    @Input()
    showFooter = true;

    isMobile = false;

    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isResponsiveMobileView();
    }

    cancel() {
        this.close.emit();
    }

    handleAccept() {
        this.acceptClick.emit();
    }
}
