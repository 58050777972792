import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-host-social-media-links',
    templateUrl: './host-social-media-links.component.html',
    styleUrls: ['./host-social-media-links.component.scss']
})
export class HostSocialMediaLinksComponent implements OnInit, OnChanges {
    @Input() socialLinks;
    @Output() socialMediaFormValue = new EventEmitter<any>();
    @Output() changesInProgressBoolean = new EventEmitter<boolean>();
    socialMediaOptions = [
        { name: 'Website', icon: 'assets/img/website_black.svg', selected_icon: 'assets/img/website.svg' },
        { name: 'Instagram', icon: 'assets/img/instagram_black.svg', selected_icon: 'assets/img/instagram.svg' },
        { name: 'Facebook', icon: 'assets/img/facebook_black.svg', selected_icon: 'assets/img/facebook.svg' },
        { name: 'Twitter', icon: 'assets/img/twitter_black.svg', selected_icon: 'assets/img/twitter.svg' },
        { name: 'Linkedin', icon: 'assets/img/linkedin_black.svg', selected_icon: 'assets/img/linkedin.svg' },
        { name: 'Youtube', icon: 'assets/img/youtube-icon.svg', selected_icon: 'assets/img/youtube-white.svg' }
    ];
    availableSocialMediaOptions = [];
    addedSocialMedia = [];
    selectedSocialMedia;
    changesInProgress = false;
    errors: any = {};
    form;
    socialMediaForm: FormArray;
    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {}

    ngOnChanges() {
        console.log(this.socialLinks);
        this.form = this.fb.group({
            socialMediaLinks: this.fb.array([])
        });
        this.socialMediaForm = this.form.get('socialMediaLinks') as FormArray;
        this.setDefaultValue();
        this.socialMediaFormValue.emit(this.socialMediaForm);
    }
    setDefaultValue() {
        if (this.socialLinks) {
            Object.keys(this.socialLinks).forEach((key) => {
                if (key === 'website') {
                    const itemForm = this.fb.group({
                        text: [
                            this.socialLinks[key],
                            Validators.pattern(/^https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/)
                        ],
                        dropdown: key
                    });
                    this.socialMediaForm?.push(itemForm);
                } else {
                    const itemForm = this.fb.group({
                        text: [
                            this.socialLinks[key],
                            Validators.pattern(
                                '^(https?://)(?:www.)?(?:facebook.com|x.com|instagram.com|linkedin.com|youtube.com)/.+$'
                            )
                        ],
                        dropdown: key
                    });
                    this.socialMediaForm.push(itemForm);
                }
            });
        }
        this.addedSocialMedia = [];
        if (this.socialLinks) {
            Object.keys(this.socialLinks).forEach((key) => {
                const socialDetail = this.socialMediaOptions.find(
                    (media) => media.name.toLowerCase() === key.toLowerCase().trim()
                );
                this.addedSocialMedia.push(socialDetail);
            });
        }
        this.updateDropdownOptions();
        if (
            this.selectedSocialMedia == undefined ||
            !this.availableSocialMediaOptions.includes(this.selectedSocialMedia)
        ) {
            this.selectedSocialMedia = this.availableSocialMediaOptions[0];
        }
        this.addSelectedSocialInForm();
        this.listenToFormValueChange();
    }
    addSelectedSocialInForm() {
        if (this.socialMediaForm.value.length < 6) {
            if (this.selectedSocialMedia.name == 'Website') {
                const itemForm = this.fb.group({
                    text: ['', Validators.pattern(/^https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/)],
                    dropdown: this.selectedSocialMedia.name.toLowerCase()
                });
                this.socialMediaForm.push(itemForm);
            } else {
                const itemForm = this.fb.group({
                    text: [
                        '',
                        Validators.pattern(
                            '^(https?://)(?:www.)?(?:facebook.com|x.com|instagram.com|linkedin.com|youtube.com)/.+$'
                        )
                    ],
                    dropdown: this.selectedSocialMedia.name.toLowerCase()
                });
                this.socialMediaForm.push(itemForm);
            }
        }
    }

    listenToFormValueChange() {
        this.socialMediaForm.valueChanges.subscribe((res) => {
            this.changesInProgress = true;
            this.socialMediaFormValue.emit(this.socialMediaForm);
            this.changesInProgressBoolean.emit(this.changesInProgress);
            const socialRegex = new RegExp(
                '^(https?://)(?:www.)?(?:facebook.com|x.com|instagram.com|linkedin.com|youtube.com)/.+$'
            );

            Object.entries<any>(res).forEach(([index, entry]) => {
                const platform = entry.dropdown;
                const url = entry.text;
                switch (platform.toLowerCase()) {
                    case 'website':
                        const websiteRegex = new RegExp(/^https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/);
                        this.errors.website = !websiteRegex.exec(url)
                            ? 'Invalid URL format detected. Please review and enter a valid web address.'
                            : false;
                        break;
                    case 'facebook':
                    case 'twitter':
                    case 'instagram':
                    case 'linkedin':
                    case 'youtube':
                        this.errors[platform] = !socialRegex.exec(url)
                            ? 'Invalid URL format detected. Please review and enter a valid web address.'
                            : false;
                        break;
                    default:
                        break;
                }
            });
        });
    }

    addSocialMedia() {
        if (this.socialMediaForm.value[this.selectedSocialMedia.name] != '') {
            if (this.selectedSocialMedia) this.addedSocialMedia.push(this.selectedSocialMedia);
            this.updateDropdownOptions();
            if (this.availableSocialMediaOptions.length > 0)
                this.selectedSocialMedia = this.availableSocialMediaOptions[0];
            this.addSelectedSocialInForm();
        }
    }
    updateDropdownOptions() {
        this.availableSocialMediaOptions = [];
        if (this.socialMediaForm) {
            this.socialMediaOptions.forEach((i) => {
                const social = i.name;
                const exists = this.addedSocialMedia.some((media) => media.name == social);
                if (!exists) {
                    this.availableSocialMediaOptions.push(i);
                }
            });
        } else {
            this.availableSocialMediaOptions = this.socialMediaOptions;
        }
    }

    onDropdownChange(e) {
        this.selectedSocialMedia = e.value;
        const lastIndex = this.socialMediaForm.length - 1; // Get the index of the last element
        this.socialMediaForm.at(lastIndex).get('dropdown').patchValue(this.selectedSocialMedia.name.toLowerCase());
        if (this.selectedSocialMedia.name == 'Website') {
            this.socialMediaForm
                .at(lastIndex)
                .get('text')
                .patchValue('', Validators.pattern(/^https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/));
        } else {
            this.socialMediaForm
                .at(lastIndex)
                .get('text')
                .patchValue(
                    '',
                    Validators.pattern(
                        '^(https?://)(?:www.)?(?:facebook.com|x.com|instagram.com|linkedin.com|youtube.com)/.+$'
                    )
                );
        }
    }
    deleteSocialMedia(i) {
        this.changesInProgress = true;
        this.addedSocialMedia.splice(i, 1);
        this.socialMediaForm.removeAt(i);
        this.updateDropdownOptions();
        if (this.availableSocialMediaOptions.length == 1) {
            this.selectedSocialMedia = this.availableSocialMediaOptions[0];
            if (this.selectedSocialMedia.name == 'Website') {
                const itemForm = this.fb.group({
                    text: ['', Validators.pattern(/^https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/)],
                    dropdown: this.selectedSocialMedia.name.toLowerCase()
                });
                this.socialMediaForm.push(itemForm);
            } else {
                const itemForm = this.fb.group({
                    text: [
                        '',
                        Validators.pattern(
                            '^(https?://)(?:www.)?(?:facebook.com|x.com|instagram.com|linkedin.com|youtube.com)/.+$'
                        )
                    ],
                    dropdown: this.selectedSocialMedia.name.toLowerCase()
                });
                this.socialMediaForm.push(itemForm);
            }
        }
        this.socialMediaFormValue.emit(this.socialMediaForm);
        this.changesInProgressBoolean.emit(this.changesInProgress);
    }
}
