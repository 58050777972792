import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { DiagnosticService, LocalStorageService, UserService, UtilService } from 'src/app/core';

@Component({
    selector: 'app-onboarding-v1',
    templateUrl: './onboarding-v1.component.html',
    styleUrls: ['./onboarding-v1.component.scss']
})
export class OnboardingV1Component implements OnInit {
    interestList = [
        'art',
        'volunteering',
        'design',
        'education',
        'tech',
        'sports',
        'music',
        'food',
        'marketing',
        'travel',
        'money',
        'lifestyle',
        'causes',
        'health',
        'gaming'
    ];

    options = [
        { type: 'host', title: 'I’m here to host an event' },
        { type: 'attend', title: 'I’m here to attend events' },
        { type: 'both', title: 'I’m here to host and attend an event' }
    ];
    selectedInterests = [];
    showInterestSection: boolean = false;
    form: FormGroup;
    currentUser;
    selectedPurpose;
    errors: any = {};
    isJioPc: boolean = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private fb: FormBuilder,
        private diagnosticService: DiagnosticService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private utilService:UtilService
    ) {}

    ngOnInit() {
        this.currentUser = this.userService.getUserSync();
        this.isJioPc = this.utilService.isJioPcCheck();

        this.form = this.fb.group({
            name: [
                (this.currentUser?.name === 'default' || this.currentUser?.name === 'JioMeet'
                    ? ''
                    : this.currentUser?.name) || '',
                [Validators.required, Validators.minLength(2)]
            ],
            department: [this.currentUser?.department || ''],
            jobTitle: [this.currentUser?.jobTitle || '']
        });
        this.selectedPurpose = this.currentUser?.jioConf?.purpose[0] || 'attend';
        this.selectedInterests = this.currentUser?.jioConf?.interestAreas || [];

        this.listenToFormChanges();
    }

    listenToFormChanges() {
        this.form.valueChanges.subscribe((res) => {
            const charRegex = new RegExp('^[a-zA-Z0-9_@ -]*$');
            this.errors.fullName = !charRegex.exec(res.name) ? 'Only -, @ and _ special characters are allowed' : false;
            this.errors.department = !charRegex.exec(res.department)
                ? 'Only -, @ and _ special characters are allowed'
                : false;
            this.errors.jobTitle = !charRegex.exec(res.jobTitle)
                ? 'Only -, @ and _ special characters are allowed'
                : false;
        });
    }

    selectInterest(interest) {
        if (!this.selectedInterests.includes(interest)) {
            this.selectedInterests.push(interest);
        } else {
            this.selectedInterests.splice(this.selectedInterests.indexOf(interest), 1);
        }
    }

    updateProfile() {
        let payload;
        if (!this.showInterestSection) {
            payload = {
                ...this.form.value
            };
            payload.name = this.form.value.name.split(' ')[0];
            let lastName = this.form.value.name.split(' ')[1];
            if (lastName?.length > 0) {
                payload.lname = lastName?.trim();
            }
        }
        if (this.showInterestSection) {
            payload = {
                jioConf: { purpose: [this.selectedPurpose], interestAreas: this.selectedInterests }
            };
        }

        this.userService.updateProfile(payload).subscribe(
            () => {
                if (!this.showInterestSection) {
                    this.showInterestSection = true;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Welcome Screen',
                            eventAction: `${this.selectedPurpose}`,
                            eventType: 'app_event',
                            endpoint: '/api/my_profile/update',
                            status: 'success'
                        })
                        .subscribe();
                } else {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Area of interest',
                            eventAction: `${this.selectedInterests.join(',')}`,
                            eventType: 'app_event',
                            endpoint: '/api/my_profile/update',
                            status: 'success'
                        })
                        .subscribe();
                    this.navigateToDashboard();
                }
            },
            (err) => {
                this.toastrService.error(this.translateService.instant('tostrs.something_went_wrong_please_try_again'));
            }
        );
    }

    navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }

    selectPurpose(purpose) {
        this.selectedPurpose = purpose.type;
    }
}
