import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { th } from 'date-fns/locale';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';

@Component({
    selector: 'app-generate-image',
    templateUrl: './generate-image.component.html',
    styleUrls: ['./generate-image.component.scss']
})
export class GenerateImageComponent implements OnInit {
    webinarId: any;
    uploadprogress: number;

    constructor(
        private webinarService: WebinarService,
        private activatedRoute: ActivatedRoute,
        private appLoggerService: AppLoggerService,
        private appService: AppService,
        private toastrService: ToastrService
    ) {}

    imageSuggestions: any[];
    logoSuggestions: any;
    loadingSuggestions = false;
    generateError: String = '';
    inputPlaceHolder: String = 'A painting of an old bus submerged in a flooded city in the early 80s';
    displayPopup = true;
    showImageCropper: boolean = false;
    imageURL: String = '';
    @Input() imageType;
    @Output() croppedFileEmitter: EventEmitter<any> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();

    @ViewChild('inputText') inputText: ElementRef;

    ngOnInit(): void {
        this.activatedRoute.parent.params.subscribe((param) => {
            this.webinarId = param?.id;
        });
    }

    cancel(): void {
        this.displayPopup = false;
    }
    generateImages() {
        let searchTerm;
        this.imageSuggestions = [];
        searchTerm = this.inputText.nativeElement.value;

        this.loadingSuggestions = true;
        this.webinarService
            .generateImage(this.webinarId, { searchTerm: searchTerm, imageCount: 1, dimensions: '512x512' })
            .subscribe(
                (res: any) => {
                    if (res.error) {
                        this.toastrService.error('Please enter a valid prompt or please try after some time');
                        return;
                    }
                    if (res && res.length) {
                        res.forEach((imgObj) => {
                            this.imageSuggestions.push(imgObj.url);
                        });
                    }
                    this.loadingSuggestions = false;
                },
                (err) => {
                    this.appLoggerService.error(err);
                    this.toastrService.error('Please enter a valid prompt or please try after some time');
                    this.loadingSuggestions = false;
                }
            );
    }

    togglePanel() {
        this.close.emit();
    }

    selectImage(event) {
        this.imageURL = event.target.src;
        this.toggleCropper();
    }

    toggleCropper() {
        this.showImageCropper = !this.showImageCropper;
    }

    uploadCroppedImage(event) {
        this.croppedFileEmitter.emit({
            croppedFile: event.croppedFile,
            imageTypeToBeCropped: event.imageTypeToBeCropped
        });
        this.togglePanel();
    }
}
