<div class="warning-popup" *ngIf="!isAuthenticated">
    <div class="bg"></div>
    <div class="popup">
        <div class="header">
            <div class="title">{{ 'restricted-meeting-join.restricted_meeting' | translate }}</div>
            <div class="close" (click)="closeWarning()">
                <img src="assets/img/icon_close_blue.svg" />
            </div>
        </div>
        <div class="content">
            <div class="img-wrapper">
                <img src="assets/img/lock_icn_orange.svg" />
            </div>
            <div class="text">{{ 'restricted-meeting-join.you_can_only_enter' | translate }}</div>
        </div>
        <div class="footer">
            <button type="button" (click)="navigateToSignIn()">
                {{ 'restricted-meeting-join.signin' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="warning-inline" *ngIf="isAuthenticated">
    <div class="bg"></div>
    <div class="popup">
        <div class="header">
            <div class="title">{{ 'restricted-meeting-join.this_is_a_restricted_meeting' | translate }}</div>
            <div class="close" (click)="closeWarning()">
                <img src="assets/img/icon_close_white.svg" />
            </div>
        </div>
        <div class="content">
            <div>{{ 'restricted-meeting-join.looks_like' | translate }}</div>
            <div>{{ 'restricted-meeting-join.please_ask_host' | translate }}</div>
            <div>{{ 'restricted-meeting-join.please_check' | translate }}</div>
        </div>
        <div class="footer">
            <button type="button" (click)="closeWarning()">{{ 'restricted-meeting-join.ok' | translate }}</button>
        </div>
    </div>
</div>
