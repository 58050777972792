import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterSessionsByDay'
})
export class FilterSessionsByDayPipe implements PipeTransform {
    transform(allSessions: any[], searchDate: any): any {
        const currentDate = this.getSessionDate(new Date(searchDate));
        const filteredSessions = allSessions.filter((session) => {
            const sessionStartDate = this.getSessionDate(new Date(session.startTime));
            return sessionStartDate === currentDate;
        });

        return filteredSessions;
    }

    getSessionDate(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toDateString();
    }
}
