<div class="popup-component" [class.isMobile]="isMobile">
    <div class="bg"></div>
    <div class="popup">
        <div class="popup-header">
            <div class="title" [innerHTML]="headerText | sanitizeText | sanitizeHtml"></div>
            <div class="close" (click)="cancel()">
                <img src="assets/img/close_icon_green.svg" alt="close" />
            </div>
        </div>
        <div class="popup-body">
            <div class="body">
                <ng-container *ngIf="bodyText">
                    <span class="sub-title text" [innerHTML]="bodyText"></span>
                </ng-container>
                <ng-container *ngIf="bodyTemplateRef">
                    <ng-container *ngTemplateOutlet="bodyTemplateRef"> </ng-container>
                </ng-container>
            </div>
            <div class="actions" *ngIf="!!showFooter">
                <div class="select-buttons d-flex justify-content-end">
                    <button class="{{ 'custom-button ' + theme + '-theme bg-secondary' }}" (click)="cancel()">
                        {{ rejectText }}
                    </button>
                    <button class="{{ 'custom-button ' + theme + '-theme bg-primary' }}" (click)="handleAccept()">
                        {{ acceptText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
