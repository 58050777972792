import { Pipe, PipeTransform } from '@angular/core';
declare const showdown: any;

@Pipe({
    name: 'showdown'
})
export class ShowdownPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        return new showdown.Converter({ strikethrough: true, underline: true, emoji: true }).makeHtml(value);
    }
}
