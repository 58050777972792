import { Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { of, Subscription } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { APP_EVENTS } from 'src/app/constants';

import {
    RtcService,
    DiagnosticService,
    UserService,
    EventEmitterService,
    UtilService,
    AppService,
    VideoWrapperService
} from 'src/app/core';
import { CallSettingsComponent, HostDirective, MeetingidFormatPipe } from 'src/app/shared';

@Component({
    selector: 'app-host-meeting',
    templateUrl: './host-meeting.component.html',
    styleUrls: ['./host-meeting.component.scss'],
    providers: [MeetingidFormatPipe]
})
export class HostMeetingComponent implements OnInit, OnDestroy {
    @ViewChild(HostDirective) viewHost: HostDirective;
    settingsComponentRef: ComponentRef<CallSettingsComponent>;

    meetingId;
    roomType = 'personal';
    currentUser;
    room: any = {
        personal: null,
        dynamic: null
    };
    shareInfo;
    showMeetingDetailsPopup = false;
    copiedInviteToClipboard = false;
    showPermissionUI: boolean = false;
    subscriptions: Subscription[] = [];

    private timeoutIds = [];
    private subject = 'Please join JioMeet meeting';
    private mailBody;
    private roomInfo;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private rtcService: RtcService,
        private utilService: UtilService,
        private appService: AppService,
        private diagnosticService: DiagnosticService,
        private compFactory: ComponentFactoryResolver,
        private meetingidFormatPipe: MeetingidFormatPipe,
        private eventEmitterService: EventEmitterService,
        private videoWrapperService: VideoWrapperService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.eventEmitterService.subscribe((event: any) => {
                if (event?.type === APP_EVENTS.SHOW_PERMISSION_UI) {
                    this.showPermissionUI = event?.data?.show;
                }
            })
        );
        this.currentUser = this.userService.getUserSync();
        this.activatedRoute.queryParams.subscribe((queryParams) => (this.meetingId = queryParams.meetingId));
    }

    getRoom() {
        return (
            this.room[this.roomType]
                ? of(this.room[this.roomType])
                : this.rtcService.getRoom(this.roomType === 'personal')
        ).pipe(tap((room) => (this.room[this.roomType] = room)));
    }

    shareInvite() {
        this.getRoom().subscribe((room) => {
            this.roomInfo = room;
            this.rtcService.getCallShareUrl(room).subscribe((shareInfo: any) => {
                this.shareInfo = shareInfo;
                this.shareInfo.topic = `${this.currentUser.name} ${this.currentUser.lname}'s JioMeet Meeting`;
                this.shareInfo.jiomeetId = this.room[this.roomType].jiomeetId;
                const invitation = this.rtcService.getInvitation(
                    `${this.currentUser.name}  ${this.currentUser.lname ? this.currentUser.lname?.trim() : ''}`,
                    shareInfo.topic,
                    null,
                    null,
                    shareInfo.url,
                    this.meetingidFormatPipe.transform(shareInfo.jiomeetId),
                    shareInfo.pin || '',
                    null,
                    this.roomInfo.legacyConnect
                );
                this.mailBody = encodeURIComponent(invitation);
                this.toggleMeetingDetailsPopup();
            });
        });
    }

    hostMeeting() {
        this.getRoom().subscribe((room) => this.start(room));
    }

    start(room) {
        if (!this.appService.getConfigVariable('ENABLE_CALLS_MOBILE_DEVICES') && this.utilService.isMobileBrowser()) {
            this.router.navigate(['shortener'], {
                queryParams: {
                    meetingId: this.meetingId
                }
            });
            return;
        }
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Start a Meeting',
                eventAction: 'New Meeting Start',
                eventType: 'app_event',
                status: 'success'
            })
            .subscribe();

        // if(this.rtcService.getCreateACSMeeting()){
        //   room.isAcs = true;
        // }

        this.rtcService.getCallShareUrl(room).subscribe((shareInfo) => {
            this.rtcService.setShareUrlInfo(shareInfo);

            const commonConferenceInfo = {
                isInitiater: true,
                joiningName: this.currentUser.name,
                room,
                ownerDetails: {
                    isHostRight: true,
                    lname: this.currentUser.lname,
                    name: this.currentUser.name,
                    userId: this.currentUser._id,
                    _id: this.currentUser._id
                },
                hideMoreCallControls: false,
                hideAllCallControls: false,
                isFullScreenShare: false,
                meetingInfo: null
            };
            var useHd = commonConferenceInfo?.meetingInfo?.webinar?.attendeeFeed === 'rtmp';
            const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, false, useHd);
            const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
            this.rtcService.setConferenceInfo(conferenceInfo);
            this.router.navigate(['conference/call']);
        });
    }

    toggleMeetingDetailsPopup() {
        this.showMeetingDetailsPopup = !this.showMeetingDetailsPopup;
    }

    copyInvitation() {
        this.utilService.copyToClipboardFromHTMLElement('meeting-invitation');
        this.copiedInviteToClipboard = true;
        this.timeoutIds.push(
            setTimeout(() => {
                this.copiedInviteToClipboard = false;
            }, 2000)
        );
    }

    sendOutlookInvite() {
        const inviteURL = `https://outlook.office.com/?path=/mail/action/compose&subject=${this.subject}&body=${this.mailBody}`;
        window.open(inviteURL, '_blank');
    }

    sendGmailInvite() {
        const inviteURL = `https://mail.google.com/mail/?view=cm&fs=1&su=${this.subject}&body=${this.mailBody}`;
        window.open(inviteURL, '_blank');
    }

    sendYahooInvite() {
        const inviteURL = `https://compose.mail.yahoo.com?subject=${this.subject}&body=${this.mailBody}`;
        window.open(inviteURL, '_blank');
    }

    openSettingsPopup(e) {
        if (!this.settingsComponentRef?.instance) {
            const settings = this.compFactory.resolveComponentFactory(CallSettingsComponent);
            this.settingsComponentRef = this.viewHost.viewContainerRef.createComponent(settings);
            this.settingsComponentRef.instance.selectedLabel = 'video';
            this.settingsComponentRef.instance.afterClose.subscribe((res) => {
                this.settingsComponentRef.destroy();
                this.settingsComponentRef = undefined;
            });
        }
    }
    closePermissiondialog() {
        this.showPermissionUI = false;
    }

    navigateToDashboard() {
        this.router.navigate(['/dashboard']);
    }

    ngOnDestroy() {
        this.timeoutIds.forEach((id) => clearTimeout(id));
    }
}
