<div
    [ngClass]="
        !isMyJio
            ? 'speaker-container-tile d-flex flex-column justify-content-center'
            : infoClicked
            ? 'speaker-container-tile host-container host d-flex flex-column justify-content-center infoClicked-speaker-container-tile'
            : 'speaker-container-tile host-container host d-flex flex-column justify-content-center'
    "
    [class.display-summary]="showOnlySummary"
    [class.my-jio-host-container]="isMyJio"
    *ngIf="!isHost && !isSessionSpeaker"
>
    <div class="info d-flex flex-center align-items-center" [class.info-myJio]="!infoClicked && isMyJio">
        <div
            class="left-content"
            [ngStyle]="{
                width: isMyJio && infoClicked ? '40px' : '100px',
                'align-items': isMyJio && infoClicked ? 'flex-start' : 'initial',
                'column-gap.px': isMyJio && infoClicked ? 8 : 16
            }"
        >
            <div
                class="initials d-flex"
                [class.initials-video]="infoClicked"
                [ngStyle]="{
                    'background-color': !speaker?.profileUrl ? colorChosen : 'initial',
                    'width.px': isMyJio && infoClicked ? 40 : 100,
                    'height.px': isMyJio && infoClicked ? 40 : 124
                }"
            >
                <ng-container *ngIf="!speaker?.profileUrl; else elseTemplate">
                    <span class="textinitials" [ngStyle]="{ 'font-size.px': isMyJio && infoClicked ? 12 : 24 }">{{
                        participantInitials
                    }}</span>
                </ng-container>
                <ng-template #elseTemplate>
                    <div
                        class="profileimage"
                        [class.profileimage-mobile]="!isHost && !infoClicked"
                        [ngStyle]="{
                            'background-image': 'url(' + speaker?.profileUrl + '?rand=' + randomNumber + ')',
                            'border-radius': isMyJio && !infoClicked && !isHost ? '8px !important' : '10rem !important',
                            'border-radius': !isMyJio && !isHost ? '8px' : '10rem'
                        }"
                    ></div>
                </ng-template>
            </div>
        </div>
        <div class="right-content d-flex flex-column">
            <div
                class="name text-truncate"
                pTooltip="{{ speaker?.fullName?.length > MaxCharBeforeTruncateName ? speaker.fullName : '' }}"
                tooltipPosition="bottom"
                *ngIf="speaker?.fullName"
            >
                {{ speaker.fullName }}
            </div>
            <div
                class="designation"
                [class.not-present-speaker-attributes]="
                    speaker?.socialMediaLinks?.length == 0 && !speaker?.description && !speaker?.department
                "
                pTooltip="{{ speaker.jobTitle?.length > MaxCharBeforeTruncate ? speaker.jobTitle : '' }}"
                tooltipPosition="bottom"
                *ngIf="speaker?.jobTitle"
            >
                {{ speaker.jobTitle | truncate: MaxCharBeforeTruncate }}
            </div>
            <div
                class="company"
                [class.not-present-speaker-attributes]="speaker?.socialMediaLinks?.length == 0 && !speaker?.description"
                pTooltip="{{ speaker.department?.length > MaxCharBeforeTruncate ? speaker.department : '' }}"
                tooltipPosition="bottom"
                *ngIf="speaker.department"
            >
                {{ speaker.department | truncate: MaxCharBeforeTruncate }}
            </div>
            <div
                class="about-speaker"
                *ngIf="!showOnlySummary && speaker?.description"
                [class.not-present-speaker-attributes]="speaker?.socialMediaLinks?.length == 0"
            >
                {{ speaker.description }}
            </div>
            <div
                class="social-share"
                [ngStyle]="{ 'column-gap': infoClicked ? '3px' : '8px' }"
                *ngIf="speaker?.socialMediaLinks?.length && !showOnlySummary"
            >
                <ng-container *ngFor="let socialMedia of speaker.socialMediaLinks">
                    <div class="image-wrapper" (click)="openSocialMediaLink(socialMedia.link)">
                        <div
                            *ngIf="socialMedia.platform === 'website'"
                            [ngClass]="isMyJio && !infoClicked ? 'website' : 'website_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'instagram'"
                            [ngClass]="isMyJio && !infoClicked ? 'instagram' : 'instagram_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'linkedIn'"
                            [ngClass]="isMyJio && !infoClicked ? 'linkedin' : 'linkedin_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'twitter'"
                            [ngClass]="isMyJio && !infoClicked ? 'twitter' : 'twitter_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'facebook'"
                            [ngClass]="isMyJio && !infoClicked ? 'facebook' : 'facebook_white'"
                        ></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div
    class="speaker-container-tile host-container host d-flex flex-column justify-content-center"
    [class.infoClicked-speaker-container-tile]="infoClicked"
    [class.my-jio-host-container]="isMyJio"
    [class.host-section]="isHost && !infoClicked && isMyJio"
    [class.display-summary]="showOnlySummary"
    [class.video-info-host]="infoClicked"
    [class.host-in-events]="!isMyJio"
    *ngIf="isHost"
>
    <div class="info d-flex flex-center align-items-center" [class.info-myJio]="!infoClicked && isMyJio">
        <div
            class="left-content-host"
            [class.left-content-host-events]="!isMyJio"
            [ngStyle]="{
                'align-items': isMyJio && infoClicked ? 'flex-start' : 'initial',
                'column-gap.px': isMyJio && infoClicked ? 8 : 16
            }"
        >
            <div
                class="initials d-flex initials-events"
                [class.profile-pic-absent]="!speaker.profileUrl"
                [ngStyle]="{
                    'background-color': !speaker?.profileUrl ? colorChosen : 'initial',
                    'height.px': isMyJio && infoClicked ? 40 : 124
                }"
            >
                <ng-container *ngIf="!speaker?.profileUrl; else elseTemplate">
                    <span class="textinitials" [ngStyle]="{ 'font-size.px': isMyJio && infoClicked ? 12 : 24 }">{{
                        participantInitials
                    }}</span>
                </ng-container>
                <ng-template #elseTemplate>
                    <div
                        class="profileimage"
                        [class.profileimage-mobile-host]="!infoClicked && isHost"
                        [ngStyle]="{
                            'background-image': 'url(' + speaker?.profileUrl + '?rand=' + randomNumber + ')',
                            'border-radius': isMyJio && !infoClicked && !isHost ? '8px' : '10rem'
                        }"
                    ></div>
                </ng-template>
            </div>
        </div>
        <div class="right-content d-flex flex-column" [class.right-content-myjio]="isMyJio">
            <div class="hosted-by-text" *ngIf="!isMyJio">Host</div>
            <div class="name text-truncate" pTooltip="{{ speaker?.fullName }}" tooltipPosition="bottom">
                {{ speaker.fullName }}
            </div>
            <div
                class="designation text-truncate"
                pTooltip="{{ speaker?.department }}"
                tooltipPosition="bottom"
                *ngIf="speaker?.department"
            >
                {{ speaker.department }}
            </div>
            <div
                class="designation text-truncate"
                pTooltip="{{ speaker.jobTitle?.length > MaxCharBeforeTruncateHost ? speaker.jobTitle : '' }}"
                tooltipPosition="bottom"
                *ngIf="speaker?.jobTitle"
            >
                {{ speaker.jobTitle | truncate: MaxCharBeforeTruncateHost }}
            </div>
            <div
                class="social-share"
                [ngStyle]="{ 'column-gap': infoClicked ? '3px' : '8px' }"
                *ngIf="speaker?.socialMediaLinks?.length && !showOnlySummary"
            >
                <ng-container *ngFor="let socialMedia of speaker.socialMediaLinks">
                    <div class="image-wrapper" (click)="openSocialMediaLink(socialMedia.link)">
                        <div
                            *ngIf="socialMedia.platform === 'website'"
                            [ngClass]="isMyJio && !infoClicked ? 'website' : 'website_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'instagram'"
                            [ngClass]="isMyJio && !infoClicked ? 'instagram' : 'instagram_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'linkedin'"
                            [ngClass]="isMyJio && !infoClicked ? 'linkedin' : 'linkedin_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'twitter'"
                            [ngClass]="isMyJio && !infoClicked ? 'twitter' : 'twitter_white'"
                        ></div>
                        <div
                            *ngIf="socialMedia.platform === 'facebook'"
                            [ngClass]="isMyJio && !infoClicked ? 'facebook' : 'facebook_white'"
                        ></div>
                        <div *ngIf="socialMedia.platform === 'youtube'" class="youtube"></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<p-overlayPanel #op appendTo="body">
    <ng-template pTemplate>
        <div class="session-speaker-overlay">
            <div
                class="initials d-flex"
                [ngStyle]="{
                    'background-color': !speaker?.profileUrl ? colorChosen : 'initial'
                }"
            >
                <ng-container *ngIf="!speaker?.profileUrl; else elseTemplate">
                    <span class="textinitials">{{ participantInitials }}</span>
                </ng-container>
                <ng-template #elseTemplate>
                    <div
                        class="profileimage"
                        [ngStyle]="{
                            'background-image': 'url(' + speaker?.profileUrl + '?rand=' + randomNumber + ')'
                        }"
                    ></div>
                </ng-template>
            </div>
            <div class="name" pTooltip="{{ speaker?.fullName }}" tooltipPosition="bottom" *ngIf="speaker?.fullName">
                {{ speaker.fullName }}
            </div>
            <div
                class="designation"
                pTooltip="{{ speaker.jobTitle?.length > MaxCharBeforeTruncate ? speaker.jobTitle : '' }}"
                tooltipPosition="bottom"
                *ngIf="speaker?.jobTitle"
            >
                {{ speaker.jobTitle | truncate: MaxCharBeforeTruncate }}
            </div>
            <div
                class="company"
                pTooltip="{{ speaker.department?.length > MaxCharBeforeTruncate ? speaker.department : '' }}"
                tooltipPosition="bottom"
                *ngIf="speaker?.department"
            >
                {{ speaker.department | truncate: MaxCharBeforeTruncate }}
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<div
    *ngIf="isSessionSpeaker"
    class="session-speakers"
    (mouseenter)="toggle($event, true)"
    (mouseleave)="toggle($event, false)"
>
    <div
        class="initials d-flex"
        [ngStyle]="{
            'background-color': !speaker?.profileUrl ? colorChosen : 'initial'
        }"
    >
        <ng-container *ngIf="!speaker?.profileUrl; else elseTemplate">
            <span class="textinitials">{{ participantInitials }}</span>
        </ng-container>
        <ng-template #elseTemplate>
            <div
                class="profileimage"
                [ngStyle]="{
                    'background-image': 'url(' + speaker?.profileUrl + '?rand=' + randomNumber + ')'
                }"
            ></div>
        </ng-template>
    </div>
</div>
