<div class="page">
    <div class="container">
        <div class="layout">
            <div class="wlc-title text-center">Welcome!</div>
            <div class="sub-header" *ngIf="!showInterestSection">
                Hi {{ currentUser?.name }}! We need some more information before starting off
            </div>
            <div class="sub-header" *ngIf="showInterestSection">Please select your area of interest</div>
            <section *ngIf="!showInterestSection" class="info-section">
                <form class="custom-form" [formGroup]="form">
                    <div class="form-group">
                        <label for="org_name"> Organisation Name </label>
                        <input
                            type="text"
                            id="org_name"
                            placeholder="Enter Organisation Name"
                            maxlength="50"
                            formControlName="department"
                            [class.is-invalid]="errors?.department"
                        />
                        <span class="text-error" *ngIf="errors?.department">{{ errors?.department }}</span>
                    </div>
                    <div class="form-group">
                        <label for="designation"> Designation </label>
                        <input
                            type="text"
                            id="designation"
                            placeholder="Enter Designation"
                            maxlength="50"
                            formControlName="jobTitle"
                            [class.is-invalid]="errors?.jobTitle"
                        />
                        <span class="text-error" *ngIf="errors?.jobTitle">{{ errors?.jobTitle }}</span>
                    </div>
                    <div class="purpose-title">What are you here for?</div>
                    <div
                        class="option-list"
                        *ngFor="let purpose of options"
                        (click)="selectPurpose(purpose)"
                        [class.selected]="purpose.type === selectedPurpose"
                    >
                        {{ purpose.title }}
                    </div>
                    <button
                        class="custom-button bg-primary w-100 mt-3"
                        [class.disabled]="!(form.valid && selectedPurpose && !errors?.department && !errors?.jobTitle)"
                        type="button"
                        (click)="updateProfile()"
                    >
                        Continue
                    </button>
                </form>
            </section>
            <section *ngIf="showInterestSection" class="interest-section">
                <div class="grid-container">
                    <div
                        class="interest-buttons"
                        *ngFor="let interest of interestList"
                        (click)="selectInterest(interest)"
                        [class.selected]="selectedInterests.includes(interest)"
                    >
                        <img src="assets/img/jioconf/interest-icons/{{ interest }}.png" alt="{{ interest }}" />
                        <span class="title">{{ interest }}</span>
                    </div>
                </div>
                <div class="btn-actions">
                    <span class="text-primary pointer mr-4" (click)="navigateToDashboard()">Skip</span>
                    <button
                        (click)="updateProfile()"
                        class="custom-button bg-primary"
                        [class.disabled]="!selectedInterests.length"
                    >
                        Done
                    </button>
                </div>
            </section>
        </div>
    </div>
</div>
