<div class="chat-markdown inside-call-chat" [class.insideChatPanel]="insideChatPanel">
    <div class="emojis-block" *ngIf="selectedEmoji">
        <app-emoji-box (onEmojiSelect)="insertEmoji($event)"></app-emoji-box>
    </div>
    <div
        #content
        class="content article demo"
        [class.live-chat-markdown]="isusedLiveChat"
        (drop)="onDrop($event)"
        [ngClass]="{ increase: showMarkdown, 'content-mobile': isMobileDevice && !isusedLiveChat }"
    ></div>
</div>
