import { Directive, ElementRef, EventEmitter, HostBinding, Input, Output, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
    selector: '[appImagePlaceholder]'
})
export class ImagePlaceholderDirective {
    /**
     * TODO:
     * Provide Option to have plain color Background(Parent) as placeholder
     */

    /**
     * Path to placeholder Image
     */
    @Input() placeHolderImageSrc = './assets/img/event_banner_placeholder_rect.svg';

    /**
     * To Use Image element as placeholder overlay which comes on top of the actual Image Element
     */
    @Input() useImgAsOverlayBg: boolean = false;

    /**
     * Path/Url to Actual/Original background Image
     */
    @Input() actualImageSrc = '';

    /**
     * Instead of updating the src of image element, add a overlay on top of image element
     * This is helpful in case of carousel, where for auto play, dom elements of slides are cloned
     */
    @Input() useBackgroundStyling;

    /**
     * Event Emitter to update the Image Loading Status
     */
    @Output() imageLoadingStatus: EventEmitter<any> = new EventEmitter();

    @HostBinding('src') currentSrc: string;

    /**
     * Element created which acts as an overlay on top of the Image element
     */
    gradientBgElement: HTMLElement;

    constructor(private el: ElementRef, private renderer: Renderer2, private toasterService: ToastrService) {}

    ngOnChanges(change) {
        this.currentSrc = this.actualImageSrc;
    }

    ngOnInit() {
        if (this.useBackgroundStyling) {
            this.setBackgroundStyle();
        } else {
            this.currentSrc = this.placeHolderImageSrc;
        }
        this.loadImage(this.actualImageSrc);
    }

    /**
     * Background Style Set for the overlay element created
     * Created Element: <img> or <div>
     */
    setBackgroundStyle() {
        if (this.useImgAsOverlayBg) {
            this.gradientBgElement = this.renderer.createElement('img');
            this.renderer.setAttribute(this.gradientBgElement, 'src', this.placeHolderImageSrc);
        } else {
            this.gradientBgElement = this.renderer.createElement('div');
            this.renderer.setStyle(
                this.gradientBgElement,
                'background',
                'linear-gradient(270deg, rgba(87, 87, 87, 0.05) 0%, #7C7C7C 100%), linear-gradient(270deg, #777676 0%, rgba(220, 220, 220, 0.05) 66.67%)'
            );
        }
        this.renderer.addClass(this.gradientBgElement, 'w-100');
        this.renderer.addClass(this.gradientBgElement, 'placeholder-bg');
        this.renderer.setStyle(this.gradientBgElement, 'width', '100%');
        this.renderer.setStyle(this.gradientBgElement, 'aspect-ratio', '1');
        // Custom Height should be handled from component level

        this.renderer.setStyle(this.gradientBgElement, 'display', 'block');
        this.renderer.setStyle(this.gradientBgElement, 'animation', 'gradientAnimation 2s linear infinite');
        this.el.nativeElement.insertAdjacentElement('afterend', this.gradientBgElement);

        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }

    setAnimationStyle() {
        this.renderer.setStyle(this.el.nativeElement, 'animation', 'fadeIn 1s');
    }

    private loadImage(src: string) {
        const image = new Image();
        image.onload = () => {
            this.handleImageOnLoad();
        };
        image.onerror = () => {
            // TODO: Load default Image on error
            this.imageLoadingStatus.emit({ loading: false, success: false });
            // this.updateSrc(this.placeholderSrc); // Display placeholder on error
        };
        image.src = src;
    }

    /**
     * On Image asset load
     * Remove the overlay dom Element created
     * Update the src of Image tag to actual one (as it is loaded)
     * Update Image loading Status to parent
     */
    handleImageOnLoad() {
        this.undoBackgroundStyle();
        this.setAnimationStyle();
        this.currentSrc = this.actualImageSrc;
        this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
        this.imageLoadingStatus.emit({ loading: false, success: true });
    }

    undoBackgroundStyle() {
        if (!this.useBackgroundStyling) return;
        this.gradientBgElement.remove();
    }
}
