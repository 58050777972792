import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
    hash: any;
    loading: boolean = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private toasterService: ToastrService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((queryParams) => {
            this.hash = queryParams.hash;
            if (!!this.hash) {
                this.verifyMail();
            } else {
                this.navToLogin();
            }
        });
    }

    verifyMail() {
        this.authService.verifyEmailUser(this.hash).subscribe(
            (res) => {
                // No need to handle response, will redirect to response url
            },
            (err) => {
                console.log(err);
                this.navToLogin();
            }
        );
    }

    navToLogin() {
        this.toasterService.error(this.translateService.instant('Invalid verification link'));
        this.router.navigate(['/login']);
    }
}
