import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation, ViewChild, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ProfileService, UserService, UtilService, ExternalInterfaceService, AppService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { EventUserRole } from 'src/app/constants/webinar-enum';

interface Speaker {
    profileUrl?: string;
    socialMediaLinks?: Array<any>;
    firstName: string;
    lastName?: string;
    jobTitle?: string;
    department?: string;
    description?: string;
    userId: string;
    fullName: string;
}

@Component({
    selector: 'app-speaker-tile',
    templateUrl: './speaker-tile.component.html',
    styleUrls: ['./speaker-tile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SpeakerTileComponent implements OnInit {
    @ViewChild('op', { static: false }) op: OverlayPanel;
    @Input() speaker: Speaker = { firstName: '', userId: null, fullName: '' };
    @Input() MaxCharBeforeTruncateWeb = 25;
    @Input() MaxCharBeforeTruncateMob = 25;
    @Input() MaxCharBeforeTruncateHostWeb = 40;
    subscriptions: Subscription[] = [];
    profilePicUrl = false;
    colorChosen = '';
    participantInitials = '';
    @Input() showOnlySummary = false;
    @Input() isHost = false;
    @Input() isSessionSpeaker = false;
    @Input() infoClicked;
    MaxCharBeforeTruncateName = 17;
    MaxCharBeforeTruncate;
    MaxCharBeforeTruncateHost;
    isMobileOS = false;
    isMyJio;

    constructor(
        private profileService: ProfileService,
        private utilService: UtilService,
        private externalInterfaceService: ExternalInterfaceService,
        private activatedRoute: ActivatedRoute,
        private appService: AppService
    ) {}

    ngOnInit(): void {
        this.MaxCharBeforeTruncate = this.MaxCharBeforeTruncateWeb;
        this.MaxCharBeforeTruncateHost = this.MaxCharBeforeTruncateHostWeb;
        this.isMobileOS = this.utilService.isResponsiveMobileView(1024) || this.utilService.isIpad();
        if (this.isMobileOS) {
            this.MaxCharBeforeTruncate = this.MaxCharBeforeTruncateMob;
            this.MaxCharBeforeTruncateHost = this.MaxCharBeforeTruncateMob;
        }
        this.processSpeakerTileData();
        this.isMyJio = this.appService.isMyJio;
    }

    processSpeakerTileData() {
        if (!this.speaker.profileUrl) {
            this.participantInitials = this.profileService.getInitials(
                `${this.speaker.firstName} ${this.speaker.lastName}`,
                this.speaker?.userId
            );
            const circleColorInfo = this.profileService.getColorForInitials(
                `${this.speaker.firstName} ${this.speaker.lastName}`,
                this.speaker?.userId
            );
            this.colorChosen = circleColorInfo?.bg;
        }
        this.speaker.fullName = this.profileService.getFullName(this.speaker.firstName, this.speaker.lastName);
    }

    openSocialMediaLink(link) {
        if (link.indexOf('http://') !== 0 && link.indexOf('https://') !== 0) {
            link = 'https://' + link;
        }
        if (this.appService.isMyJio) {
            this.externalInterfaceService.launchBrowser(link);
        } else {
            window.open(link, '_blank');
        }
    }

    ngOnDestroy() {
        this.subscriptions.map((s) => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        this.processSpeakerTileData();
    }
    toggle(event, isVisible) {
        if (isVisible) {
            this.op.show(event);
        } else {
            this.op.hide();
        }
    }
}
