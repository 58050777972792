<section id="explore" class="explore section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-8 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-1 order-md-1 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1 class="jiotype-black-white-88px Support-main-txt">Whiteglove services for our VIP clients</h1>
                </div>
                <div>
                    <button
                        tabindex="0"
                        aria-roledescription="Whiteglove services for our VIP clients"
                        role="button"
                        (click)="contactUS()"
                        (keyup.enter)="contactUS()"
                        routerLink="/contactus"
                        class="support-btn"
                    >
                        <span class="support-btn-txt">Contact Us</span>
                    </button>
                </div>
            </div>
            <div
                class="col-lg-4 col-md-6 order-2 order-md-2 order-lg-2 explore-img-container aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img src="assets/img/website/Group_139279.png" class="img-fluid explore-img" alt="" />
            </div>
        </div>
    </div>
</section>
<section id="customize" class="customize section-bg">
    <div class="container">
        <div class="row customize-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    customize-left-area
                    pt-4 pt-lg-0
                    order-2 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="customize-main-txt">
                    <h1
                        tabindex="0"
                        role="button"
                        aria-roledescription="Custom layouts, Manage and customize a stream in real time which looks more professional,Customised Registration forms,Customize the registration form to collect attendee details relevant to your event.Webpage Branding and theme,Customize your events with a banner, logo, theme, watermark and a branded event layout."
                        class="jiotype-black-white-40px customize-text"
                    >
                        Customized solutions
                    </h1>
                </div>
                <div>
                    <ul class="timeline">
                        <li (click)="changeCustomizeIndex(0)" [ngClass]="custzidx == 0 ? 'active' : ''">
                            <span class="jiotype-bold-downy-24px timline-text">Custom layouts</span>
                            <p class="jiotype-bold-star-dust-14px">
                                Manage and customize a stream in real time which looks more professional.
                            </p>
                        </li>
                        <li (click)="changeCustomizeIndex(1)" [ngClass]="custzidx == 1 ? 'active' : ''">
                            <span class="jiotype-bold-white-24px-2 timline-text">Customised Registration forms</span>
                            <p class="jiotype-bold-star-dust-14px">
                                Customize the registration form to collect attendee details relevant to your event.
                            </p>
                        </li>
                        <li (click)="changeCustomizeIndex(2)" [ngClass]="custzidx == 2 ? 'active' : ''">
                            <span class="jiotype-bold-white-24px-2 timline-text">Webpage Branding and theme</span>
                            <p class="jiotype-bold-star-dust-14px">
                                Customize your events with a banner, logo, theme, watermark and a branded event layout.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    align-items-center
                    order-1 order-md-2 order-lg-2
                    customize-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139238-C61RwL">
                    <img src="assets/img/website/Group_139238.png" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="support-call" class="support-call section-bg">
    <div class="container">
        <div class="row support-call-explore-left-area">
            <div
                class="
                    col-lg-8 col-md-6 col-12
                    d-flex
                    flex-column
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="support-call-explore-main-txt">
                    <!-- whats app logo  -->
                    <h1 class="jiotype-black-white-40px support-call-txt">Dedicated support team for all your need</h1>
                    <p class="contact-whatsapp-num">
                        <img class="whatsapp-icon" src="assets/img/website/whatsapp-icon.svg" /><span
                            class="whatsapp-num jiotype-bold-white-24px-2"
                            >+91 8369 100 100
                        </span>
                    </p>
                </div>
            </div>
            <div
                class="
                    col-lg-4 col-md-6 col-12
                    d-flex
                    align-items-center
                    order-1 order-md-2 order-lg-2
                    customize-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img
                    src="assets/img/website/mask-group-17@1x.png"
                    class="img-fluid support-call-customize-img"
                    alt=""
                />
            </div>
        </div>
    </div>
</section>
<section id="customize" class="customize section-bg">
    <div class="container">
        <div class="row customize-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    customize-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="customize-main-txt">
                    <h1 class="jiotype-black-white-40px customize-text">Engage & reach without interruptions</h1>
                </div>
                <div>
                    <ul class="engaged-timeline">
                        <li (click)="engageChangeIndex(0)" [ngClass]="engaeidx == 0 ? 'active' : ''">
                            <span class="jiotype-bold-downy-24px timline-text"
                                >HD media Sharing + Pre-recorded videos</span
                            >
                            <p class="jiotype-bold-star-dust-14px">
                                Manage and customize a stream in real time which looks more professional.
                            </p>
                        </li>
                        <li (click)="engageChangeIndex(1)" [ngClass]="engaeidx == 1 ? 'active' : ''">
                            <span class="jiotype-bold-white-24px-2 timline-text">Stream everywhere</span>
                            <p class="jiotype-bold-star-dust-14px">
                                Stream directly to Facebook, YouTube, LinkedIn, and other platforms.
                            </p>
                        </li>
                        <li (click)="engageChangeIndex(2)" [ngClass]="engaeidx == 2 ? 'active' : ''">
                            <span class="jiotype-bold-white-24px-2 timline-text">Live ticker with hyperlinks</span>
                            <p class="jiotype-bold-star-dust-14px">Publish information in the form of ticker text.</p>
                        </li>
                        <li (click)="engageChangeIndex(3)" [ngClass]="engaeidx == 3 ? 'active' : ''">
                            <span class="jiotype-bold-white-24px-2 timline-text"
                                >Real time attendee and reactions counters</span
                            >
                            <p class="jiotype-bold-star-dust-14px">Get real time attendee insights from the event.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    align-items-center
                    order-1 order-md-2 order-lg-2
                    customize-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139245-C61RwL">
                    <img src="assets/img/website/Group_139245.png" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="reports" class="reports section-bg">
    <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title report-title jiotype-black-white-40px">
            <p tabindex="0" aria-label="Reliable, useful and safe">Reliable, useful and safe</p>
        </div>
        <div class="row">
            <ng-container *ngFor="let report of reports">
                <div
                    class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                >
                    <div class="icon-box feature-box" (click)="reportsClick(report)">
                        <div class="icon icon-circle">
                            <img class="icon-feature-img" src="assets/img/website/{{ report.img }}" />
                        </div>
                        <h4>
                            <label tabindex="0" class="jiotype-bold-white-18px"> {{ report.title }} </label>
                        </h4>
                        <p tabindex="0" class="jiotype-medium-white-14px">{{ report.des }}</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
