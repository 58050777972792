import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { differenceInSeconds } from 'date-fns';

import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-call-duration',
    templateUrl: './call-duration.component.html',
    styleUrls: ['./call-duration.component.scss']
})
export class CallDurationComponent implements OnInit, OnDestroy {
    @Input() callJoinTime;
    intervalSubscription: Subscription;
    duration = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    constructor() {}

    ngOnInit() {
        let seconds = this.callJoinTime ? differenceInSeconds(new Date(), new Date(this.callJoinTime)) : 0;
        this.intervalSubscription = interval(1000).subscribe(() => {
            seconds++;
            // this.duration.days = Math.floor(seconds / (24 * 60 * 60));
            // seconds = seconds % (24 * 60 * 60);

            // this.duration.hours = Math.floor(seconds / (60 * 60));
            // seconds = seconds % (60 * 60);

            // this.duration.minutes = Math.floor(seconds / 60);
            // this.duration.seconds = seconds % 60;
            this.duration.hours = this.pad(Math.floor(seconds / 60 / 60), 2);
            this.duration.minutes = this.pad(Math.floor(seconds / 60) % 60, 2);
            this.duration.seconds = this.pad(Math.floor(seconds - this.duration.minutes * 60), 2);
        });
    }

    pad(num, size) {
        return parseInt(('000' + num).slice(size * -1));
    }

    ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
            this.intervalSubscription = null;
            this.duration = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    }
}
