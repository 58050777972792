<div class="header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <img src="assets/img/myjio/back.svg" routerLink="../integrations/myjio/profile" />
    </button>
    <div class="title">Privacy Policy</div>
</div>
<div class="privacy" [class.myJio]="isMyJio">
    <div class="gray__bg">
        <div class="container" *ngIf="!isLangHindi">
            <div class="row">
                <div class="col-12 col-lg-10 m-auto pt-4">
                    <div class="legal-terms">LEGAL TERMS</div>
                    <h2 class="pb-2 text-left page-title">Privacy Policy</h2>

                    <div class="card radius-card card-format">
                        <div class="card-body">
                            <ol>
                                <li>
                                    <strong>Scope</strong>
                                    <ol>
                                        <li>
                                            JioEvents Platform (hereinafter referred to as “App”, “we”, “our”, “us”) is
                                            committed to protecting and respecting your privacy.
                                        </li>
                                        <li>
                                            This policy (this “Privacy Policy”), together with the Terms and Conditions
                                            of the App outlines the App’s privacy practices regarding the collection,
                                            use and safeguard of your information through the App and the services
                                            offered thereupon including both JioMeet and JioMeet Pro.
                                        </li>
                                        <li>
                                            By using the App or registering or signing up for the App and submission of
                                            personal information; or if you are a minor, the submission of your personal
                                            information either by your parents (or your guardian) or by you with the
                                            permission of your parents (or guardian) to the App will be deemed as your
                                            acceptance of this Privacy Policy.
                                        </li>
                                        <li>
                                            The Terms are an electronic record in terms of the Information Technology
                                            Act, 2000 (as amended / re-enacted) (“IT Act") and rules thereunder, and is
                                            published in accordance with the provisions of Rule 3 (1) of the of the
                                            Information Technology (Intermediaries Guidelines) Rules, 2011, which
                                            mandates for publishing of rules and regulations, privacy policy and terms
                                            of use for access or usage of Platform. This electronic record is generated
                                            by a computer system and does not require any physical or digital
                                            signatures.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Changes to the Privacy Policy</strong>
                                    <ol>
                                        <li>
                                            We are obligated to protect your information in accordance with applicable
                                            laws, and regulations.
                                        </li>
                                        <li>
                                            This Privacy Policy is subject to modification based on changes in the
                                            business, legal and regulatory requirements and will be updated online. We
                                            will make all efforts to communicate any significant changes to this Privacy
                                            Policy to you. You are encouraged to periodically visit this page to review
                                            the Privacy Policy and any changes to it.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Third Party Services</strong>
                                    <ol>
                                        <li>
                                            Please note that your mobile service provider, mobile operating system
                                            provider, third party applications on your device, social media platforms
                                            and websites that you access, third party operators may also collect, use
                                            and share information about you and your usage. We cannot control how these
                                            third parties collect, use, share or secure this information. For
                                            information about third-party privacy practices, please consult their
                                            respective privacy policies.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Personal Information we collect</strong>
                                    <ol>
                                        <li>
                                            Personal information is defined as information that can be used to identify
                                            you and may include details such as your name, age, gender, contact
                                            information, products and services you are interested in or require more
                                            information about. Insofar as sensitive personal information is concerned,
                                            it will carry the meaning as may be defined by applicable laws from time to
                                            time.
                                        </li>
                                        <li>
                                            The following is the manner in which we collect, use, share and retain
                                            personal information:
                                            <div>&nbsp;</div>
                                            <ol>
                                                <li>
                                                    Collection:
                                                    <ol>
                                                        <li>
                                                            You agree that the App may collect such personal
                                                            information, whenever relevant, to help providing you with
                                                            information and to complete any transaction or provide any
                                                            product you have requested or authorized. You also consent
                                                            to the collection of certain personal information in the
                                                            course of your applying for the products and/or services.
                                                        </li>
                                                        <li>
                                                            You agree that the App allows you to share your screen with
                                                            other users. The users with whom your screen has been shared
                                                            would be able to take screenshots of your shared screen
                                                            (“Screenshot”) without any notification to you.
                                                        </li>
                                                        <li>
                                                            You agree that the App allows the Host to record the video
                                                            or audio conferencing (“Recording”). Once the recording has
                                                            started you would be able to see the recording text on your
                                                            App conference screen.
                                                        </li>
                                                        <li>
                                                            The Recording shall be made available to the Host via email
                                                            and/or a text message.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div>&nbsp;</div>
                                                <li>
                                                    Usage:
                                                    <ol>
                                                        <li>
                                                            The App seeks this information either to (i) validate and
                                                            process your request for the services of the App or
                                                            information; (ii) to improve the quality of the App; (iii)
                                                            to assist you to determine which services best meet your
                                                            needs; or (iv) to facilitate our internal business
                                                            operations, including the fulfilment of any legal and
                                                            regulatory requirements; or (v) to provide you with
                                                            recommendation about services you may be interested in,
                                                            based on your use of the App; or (vi) to provide you with
                                                            marketing communications and advertising that the App
                                                            believe may be of interest of you ;or (vii) to facilitate
                                                            your purchases .
                                                        </li>
                                                        <li>
                                                            The App does not use any information pertaining to the
                                                            Screenshot and the Recording. The Screenshot available on
                                                            the device of the user who has taken the Screenshot. The
                                                            Recording is available with the Host.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div>&nbsp;</div>
                                                <li>
                                                    Sharing:
                                                    <ol>
                                                        <li>
                                                            We may disclose personal information to our affiliates when
                                                            necessary to perform services on our behalf or on your
                                                            behalf, to provide display advertising and promotional
                                                            services, providing search results and links (including paid
                                                            listings and links), processing credit card payments,
                                                            providing customer service etc.
                                                        </li>
                                                        <li>
                                                            We may also share personal information with external
                                                            organizations or individuals if we believe that access, use,
                                                            preservation or disclosure of the information is reasonably
                                                            necessary to: (a)meet any applicable law, regulation, legal
                                                            process or enforceable governmental request; (b)detect,
                                                            prevent or otherwise address fraud, security or technical
                                                            issues; (c)protect against harm to the rights, property or
                                                            safety of our customers or the public, as required or
                                                            permitted by law.
                                                        </li>
                                                        <li>
                                                            The user who takes the Screenshot cannot share the
                                                            Screenshot with any third party.
                                                        </li>
                                                        <li>
                                                            The Host with whom the Recording is shared can share the
                                                            Recording with any third party and/or user(s).
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div>&nbsp;</div>
                                                <li>
                                                    Retention:
                                                    <ol>
                                                        <li>
                                                            The information so collected shall be retained only for a
                                                            limited duration necessary to fulfil the purposes outlined
                                                            herein unless a longer retention period is required or
                                                            permitted by law and only for the purposes defined above.
                                                            Once the purpose is achieved, all personal information is
                                                            deleted in a safe and secure mode.
                                                        </li>
                                                        <li>
                                                            The Screenshot is not stored in our servers and are stored
                                                            in the device of the user who has taken the screenshot.
                                                        </li>
                                                        <li>
                                                            The Recording is stored on our servers for a period of 7
                                                            days.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>

                                <li>
                                    <strong>Non-Personal Information</strong>
                                    <ol>
                                        <li>
                                            Non-personal information is defined as any data that does not personally
                                            identify you and may include unique system or hardware identifiers, system
                                            or application software, and peripherals.
                                        </li>
                                        <li>
                                            Any non-personal information, when clubbed with personal information shall
                                            be treated as personal information.
                                        </li>
                                        <li>
                                            The following is the manner in which we collect, use, share and retain
                                            nonpersonal information.
                                            <div>&nbsp;</div>
                                            <ol>
                                                <li>
                                                    Collection:
                                                    <ol>
                                                        <li>
                                                            You agree that the App may collect diagnostic, technical,
                                                            usage related information, for the usage purposes described
                                                            below.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div>&nbsp;</div>
                                                <li>
                                                    Usage:
                                                    <ol>
                                                        <li>
                                                            The above mentioned information is gathered periodically to
                                                            provide and improve the App and services therein, facilitate
                                                            the provision of software updates, product support and other
                                                            services to you (if any) and to verify compliance with the
                                                            terms of this Policy.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div>&nbsp;</div>
                                                <li>
                                                    Sharing:
                                                    <ol>
                                                        <li>
                                                            To enable the App partners and third party developers to
                                                            improve their software, hardware and services designed for
                                                            use with the App, we may also provide any such partner or
                                                            third party developer with a subset of diagnostic
                                                            information that is relevant to that partner’s or
                                                            developer’s software, hardware and/or services, as long as
                                                            the diagnostic information is in a form that does not
                                                            personally identify you.
                                                        </li>
                                                    </ol>
                                                </li>

                                                <div>&nbsp;</div>
                                                <li>
                                                    Retention:
                                                    <ol>
                                                        <li>
                                                            The information so collected shall be retained only for a
                                                            limited duration necessary to fulfil the purposes outlined
                                                            herein unless a longer retention period is required or
                                                            permitted by law and only for the purposes defined above.
                                                            Once the purpose is achieved, all information is deleted in
                                                            a safe and secure mode.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Location Services</strong>
                                    <ol>
                                        <li>
                                            The App, its licensees and agents, may access, collect and analyse your
                                            usage of the App and other information related to your location on a regular
                                            basis through Bluetooth and Wi-Fi signals and other technologies and data
                                            for the performance of the services provided by the App and to help improve
                                            the design, functionality, performance, and content of the App. In order to
                                            collect geo-location information, the location settings must be enabled on
                                            your device. Therefore, in some cases, the App may request that you enable
                                            the location settings.
                                        </li>
                                        <li>
                                            Except in cases where any specific services requested by you require or
                                            involve personal identification, the App collects location data anonymously
                                            and in a form that does not personally identify you. By using the App, you
                                            agree and consent to transmission, collection, maintenance, processing and
                                            use of your location data and queries to provide and improve such services
                                            of the App.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Cookies</strong>
                                    <ol>
                                        <li>
                                            Information collected by cookies and other technologies are treated as
                                            nonpersonal information. However, to the extent that IP addresses or similar
                                            identifiers are considered personal information by local law, we treat any
                                            information linked to such identifiers as personal information. Similarly,
                                            to the extent that non-personal information is combined with personal
                                            information, we will treat the combined information as personal information.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Other Information the App collects</strong>
                                    <ol>
                                        <li>
                                            The App also may collect other information about you in ways that the App
                                            describe to you or otherwise with your consent. You can choose not to
                                            provide us with certain types of information, but doing so may affect your
                                            ability to avail and/or use some services.
                                        </li>
                                        <li>
                                            By clicking “I Accept” button or by usage of the App you permit to enable
                                            certain functions of the device to find the location of your device, modify
                                            or delete SD card contents, read phone statistics and identity, mount and
                                            unmount file systems, contacts saved in your device, conferencing logs and
                                            history etc.
                                        </li>
                                        <li>
                                            The features of the App may allow you to post content such as profile
                                            information, status update, comments, and other content and/or information
                                            (User Content). The ownership over the User Content created by you remains
                                            with you; however, by sharing User Content through the App, you agree to
                                            allow others to view and/or share your User Content in accordance with this
                                            Privacy Policy.
                                        </li>
                                        <li>
                                            We use TrueDepth API technologies (ARKit, with automatic estimation of the
                                            real-world directional lighting environment) to create AR effects within the
                                            App. The only use of this information is to provide valuable user features.
                                            None of the information collected by the TrueDepth API ever leaves the
                                            user’s device. We do not share information with third parties, do not store
                                            or process in any other way the data which we accessed and used via
                                            TrueDepth API. For more information about TrueDepth API technologies, you
                                            may visit
                                            <a href="https://support.apple.com/en-us/HT208108" target="_blank"
                                                >https://support.apple.com/en-us/HT208108</a
                                            >
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Third Party Platforms, Websites &amp; Services</strong>
                                    <ol>
                                        <li>
                                            The App may include links to other websites/applications or may display
                                            advertisements from third parties and other content that links to third
                                            party websites. Such websites/applications are governed by their respective
                                            privacy policies, which are out of the App’s control. Use of any information
                                            you provide while using a third party website or application through the
                                            App, is governed by the privacy policy of the operator of the website /
                                            application / operator you are using/ visiting. That policy may differ from
                                            that of the App. If you can't find the privacy policy of any of such
                                            websites /application via a link from the website's homepage, you should
                                            contact the relevant website /application operator directly for more
                                            information.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Access, Correction and Deletion</strong>
                                    <ol>
                                        <li>
                                            The App strives hard to keep its records updated and accurate with your
                                            latest information. You shall be responsible to ensure that the information
                                            or data you provide from time to time is and shall be correct, current and
                                            updated and you have all the rights, permissions and consents to provide
                                            such information or data.
                                        </li>
                                        <li>
                                            You may note that deletion of certain information or withdrawal of consent
                                            may lead to cancellation of your access to the App or your access to certain
                                            features and services of the App. Additionally, we may not be able to
                                            process your request of correction, updating or deletion, in case the same
                                            is not supported by valid documents or data retention is required by the
                                            applicable law or law enforcement requests or under any judicial proceedings
                                            or it is extremely difficult to implement (such as requests related to
                                            backup copies or if a new system is required to process the request or
                                            change of technical design) or risks the privacy of other users.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Queries and Complaints</strong>
                                    <ol>
                                        <li>
                                            We are committed to protect your personal information collected and
                                            processed by us and look forward to your continued support for the same. In
                                            case of any feedback or concern regarding protection of your personal
                                            information, or any privacy-related feedback or concerns you may contact:
                                            <a (click)="sendEmail()">grievance.officer@jio.com</a>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="isLangHindi">
            <div class="row">
                <div class="col-12 col-lg-10 m-auto pt-4">
                    <h2 class="pb-2 text-center" style="color: rgb(0, 0, 0)">जियोमीट के लिए गोपनीयता नीति</h2>
                    <div class="card radius-card card-format">
                        <div class="card-body">
                            <p>
                                जियोमीट का उपयोग करने के लिए धन्यवाद! आपकी गोपनीयता हमारे लिए महत्वपूर्ण है। आपकी
                                गोपनीयता की बेहतर सुरक्षा, और सभी विज़िटर के लिए वीडियो कॉन्फ्रेंसिंग के अनुभव को बढ़ाने
                                के लिए, हम यह गोपनीयता नीति प्रदान करते हैं।
                            </p>
                            <ol>
                                <li>
                                    <strong>स्कोप:</strong>
                                    <ol>
                                        <li>
                                            जियोमीट ऐप्लिकेशन (आगे चलकर “ ऐप ”, “हम”, “हमारा”, “हमें” के रूप में जाना
                                            जायेगा) आपकी गोपनीयता की सुरक्षा और सम्मान के लिए प्रतिबद्ध है।
                                        </li>
                                        <li>
                                            यह नीति (यह “गोपनीयता नीति”), ऐप के नियमों और शर्तों सहित, ऐप के माध्यम से
                                            आपकी संग्रह, उपयोग और सुरक्षा से सम्बंधित जानकारी के बारे में इस ऐप की
                                            गोपनीयता प्रक्रियाओं को रेखांकित करती है और इसमें जियोमीट और जियोमीट प्रो
                                            सहित सभी सेवाएँ शामिल हैं।
                                        </li>
                                        <li>
                                            ऐप का उपयोग या ऐप के लिए पंजीकरण या साइन अप करना और व्यक्तिगत जानकारी जमा
                                            करना; या फिर यदि आप नाबालिग हैं, तो अपनी व्यक्तिगत जानकारी अपने माता-पिता
                                            (या आपके अभिभावक) या आपके द्वारा आपके माता-पिता (या अभिभावक) की अनुमति से ऐप
                                            में जमा करना इस गोपनीयता नीति को आपके द्वारा स्वीकृत होना माना जायेगा।
                                        </li>
                                        <li>
                                            ये शर्तें, सूचना प्रौद्योगिकी अधिनियम, 2000 ( यथा संशोधित / पुनः अधिनियम के
                                            रूप में ) (“आई.टी अधिनियम&quot;) और नियमों के अनुसार एक इलेक्ट्रॉनिक रिकॉर्ड
                                            हैं, और सूचना प्रौद्योगिकी ( मध्यस्थ दिशा निर्देशों ) नियम, 2011 के तहत, जो
                                            पॉलिसी और विनियम के प्रकाशन के लिए नीति और विनियम के उपयोग ऐप्लिकेशन तक
                                            पहुँच के लिए अनिवार्य है, के नियम 3 (1) के अनुसार प्रकाशित की जाती हैं। यह
                                            इलेक्ट्रॉनिक रिकॉर्ड एक कंप्यूटर सिस्टम द्वारा बनाया गया है जिसे किसी भी
                                            भौतिक या डिजिटल हस्ताक्षर की आवश्यकता नहीं है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>गोपनीयता नीति में परिवर्तन:</strong>
                                    <ol>
                                        <li>
                                            हम लागू कानूनों और नियमों के अनुसार आपकी जानकारी की सुरक्षा, के लिए बाध्य
                                            हैं।
                                        </li>
                                        <li>
                                            यह गोपनीयता नीति व्यवसाय में परिवर्तन, कानूनी और नियामक आवश्यकताओं के आधार
                                            पर संशोधन के अधीन है और इसे ऑनलाइन अपडेट किया जाएगा। हम आपको इस गोपनीयता
                                            नीति में होने वाले किसी भी तरह महत्वपूर्ण बदलाव के लिए सूचित करने के सभी
                                            प्रयास करेंगे। आपको गोपनीयता नीति और इसमें किसी भी बदलाव की समीक्षा करने के
                                            लिए समय-समय पर इस पृष्ठ पर जाने के लिए प्रोत्साहित किया जाता है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>थर्ड पार्टी की सेवाएँ:</strong>
                                    <ol>
                                        <li>
                                            कृपया ध्यान दें कि आपका मोबाइल सेवा प्रदाता, मोबाइल ऑपरेटिंग सिस्टम प्रदाता,
                                            आपके डिवाइस पर थर्ड पार्टी ऐप्लिकेशन , सोशल मीडिया प्लेटफ़ॉर्म और आपके द्वारा
                                            एक्सेस की जाने वाली वेबसाइट, थर्ड पार्टी ऑपरेटर भी आपके और आपके उपयोग के
                                            बारे में जानकारी एकत्र, उपयोग और शेयर कर सकते हैं। हम यह नियंत्रित नहीं कर
                                            सकते कि ये थर्ड पार्टी इस जानकारी को कैसे एकत्रित, उपयोग, शेयर या सुरक्षित
                                            करती हैं। थर्ड-पार्टी गोपनीयता प्रथाओं के बारे में जानकारी के लिए, कृपया
                                            उनकी संबंधित गोपनीयता नीतियों से परामर्श करें।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>व्यक्तिगत जानकारी जो हम एकत्र करते हैं:</strong>
                                    <ol>
                                        <li>
                                            व्यक्तिगत जानकारी को ऐसी जानकारी के रूप में परिभाषित किया जाता है जिसका
                                            उपयोग आपकी पहचान करने के लिए किया जा सकता है और इसमें आपका नाम, आयु, लिंग,
                                            संपर्क की जानकारी, आपकी रुचि अनुसार उत्पादों और सेवाओं जैसे विवरण या जिनके
                                            बारे में अधिक जानकारी की आवश्यकता होती है, शामिल हो सकते हैं। जहाँ तक
                                            संवेदनशील व्यक्तिगत जानकारी का संबंध है, इसका अर्थ समय-समय पर लागू कानूनों
                                            के अनुसार परिभाषित किया जा सकता है।
                                        </li>
                                        <li>
                                            व्यक्तिगत जानकारी को एकत्र करने, उपयोग करने, शेयर करने और बनाए रखने के हमारे
                                            निम्नलिखित तरीके हैं:
                                            <div></div>
                                            <ol>
                                                <li>
                                                    संग्रह:
                                                    <ol>
                                                        <li>
                                                            आप इस बात से सहमत हैं कि ऐप आपको जानकारी प्रदान करने और किसी
                                                            भी लेन-देन को पूरा करने या आपके द्वारा अनुरोध या अधिकृत किए
                                                            गए किसी भी उत्पाद को पूरा करने में मदद करने के लिए, जब भी
                                                            प्रासंगिक हो, ऐसी व्यक्तिगत जानकारी एकत्र कर सकता है। आप
                                                            उत्पादों और/या सेवाओं के लिए अपने आवेदन के दौरान कुछ
                                                            व्यक्तिगत जानकारी के संग्रह के लिए भी सहमति देते हैं।
                                                        </li>
                                                        <li>
                                                            आप सहमत हैं कि ऐप आपको अन्य उपयोगकर्ताओं के साथ अपनी स्क्रीन
                                                            शेयर करने की अनुमति देता है। जिन उपयोगकर्ताओं के साथ आपकी
                                                            स्क्रीन शेयर की गई है, वे बिना किसी सूचना के आपकी शेयर
                                                            स्क्रीन (“स्क्रीनशॉट”) के स्क्रीनशॉट ले पाएंगे।
                                                        </li>
                                                        <li>
                                                            आप सहमत हैं कि ऐप, होस्ट को वीडियो या ऑडियो कॉन्फ्रेंसिंग
                                                            (“रिकॉर्डिंग”) रिकॉर्ड करने की अनुमति देता है। एक बार
                                                            रिकॉर्डिंग शुरू हो जाने के बाद आप अपने ऐप कॉन्फ्रेंस स्क्रीन
                                                            पर रिकॉर्डिंग टेक्स्ट देख पाएंगे।
                                                        </li>
                                                        <li>
                                                            रिकॉर्डिंग को ईमेल और/या टेक्स्ट संदेश के माध्यम से होस्ट को
                                                            उपलब्ध कराया जाएगा।
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div></div>
                                                <li>
                                                    उपयोग:
                                                    <ol>
                                                        <li>
                                                            ऐप इस जानकारी द्वारा या तो (i) ऐप या सूचना की सेवाओं के लिए
                                                            आपके अनुरोध को मान्य और संसाधित करना चाहता है; (ii) ऐप की
                                                            गुणवत्ता में सुधार करना चाहता है; (iii) यह निर्धारित करना
                                                            चाहता है कि कौन सी सेवाएँ आपकी आवश्यकताओं को पूरा करती हैं;
                                                            या (iv) किसी भी कानूनी और नियामक आवश्यकताओं की पूर्ति सहित
                                                            हमारे आंतरिक व्यापार के संचालन को, सुविधाजनक बनाने के लिए;
                                                            या (v) ऐप के उपयोग के आधार पर आपको उन सेवाओं के बारे में
                                                            अनुशंसा प्रदान करने के लिए जिनमें आपकी रुचि हो, सकती है; या
                                                            (vi) आपको विपणन संचार और विज्ञापन प्रदान करने के लिए जो ऐप
                                                            का मानना है कि यह आपकी रुचि का हो सकता है; या (vii) आपकी
                                                            खरीदारी को सुविधाजनक बनाना चाहता है।
                                                        </li>
                                                        <li>
                                                            ऐप, स्क्रीनशॉट और रिकॉर्डिंग से संबंधित किसी भी जानकारी का
                                                            उपयोग नहीं करता। स्क्रीनशॉट उस उपयोगकर्ता के डिवाइस पर
                                                            उपलब्ध होगा जिसने स्क्रीनशॉट लिया है। रिकॉर्डिंग होस्ट के
                                                            पास उपलब्ध होगी।
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div></div>
                                                <li>
                                                    शेयर करना:
                                                    <ol>
                                                        <li>
                                                            हम अपनी ओर से या आपकी ओर से सेवाओं का प्रदर्शन, विज्ञापन और
                                                            प्रचार सेवाएँ प्रदान करने, खोज परिणाम और लिंक (भुगतान
                                                            सूचियों और लिंक सहित) प्रदान करने, क्रेडिट कार्ड भुगतानों को
                                                            संसाधित करने, ग्राहक सेवा प्रदान करने आदि के लिए आवश्यक हो
                                                            तो हम अपने सहयोगियों से व्यक्तिगत जानकारी का खुलासा कर सकते
                                                            हैं।
                                                        </li>
                                                        <li>
                                                            हम बाहरी संगठनों या व्यक्तियों के साथ व्यक्तिगत जानकारी भी
                                                            शेयर कर सकते हैं यदि हम मानते हैं कि जानकारी तक पहुँच,
                                                            उपयोग, संरक्षण या प्रकटीकरण निम्न के लिए आवश्यक है: (a) किसी
                                                            भी लागू कानून, विनियमन, कानूनी प्रक्रिया या लागू करने योग्य
                                                            सरकारी अनुरोध को पूरा करने के लिए; (b) किसी प्रकार की
                                                            धोखाधड़ी, सुरक्षा या तकनीकी समस्या का पता लगाने या रोकने के
                                                            लिए; (c) कानून द्वारा आवश्यक या अनुमति के अनुसार, हमारे
                                                            ग्राहकों या जनता के अधिकारों, संपत्ति या सुरक्षा को नुकसान
                                                            से बचाने के लिए।
                                                        </li>
                                                        <li>
                                                            स्क्रीनशॉट लेने वाला उपयोगकर्ता किसी भी थर्ड पार्टी के साथ
                                                            स्क्रीनशॉट शेयर नहीं कर सकता।
                                                        </li>
                                                        <li>
                                                            जिस होस्ट के साथ रिकॉर्डिंग शेयर की जाती है, वह रिकॉर्डिंग
                                                            को किसी भी थर्ड पार्टी और/या उपयोगकर्ता(ओं) के साथ शेयर कर
                                                            सकता है।
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div></div>
                                                <li>
                                                    अवधारण::
                                                    <ol>
                                                        <li>
                                                            एकत्र की गई जानकारी को केवल एक सीमित अवधि, जो कि उल्लिखित
                                                            उद्देश्यों को पूरा करने के लिए आवश्यक है, जब तक कि एक लंबी
                                                            अवधारण अवधि या कानून द्वारा अनुमति की आवश्यक नहीं है और केवल
                                                            ऊपर उल्लिखित उद्देश्यों के लिए ही रखा जाएगा। उद्देश्य पूरा
                                                            हो जाने के बाद, सभी व्यक्तिगत सूचनाओं को एक सुरक्षित और
                                                            भरोसेमंद तरीके से डिलीट कर दिया जाता है।
                                                        </li>
                                                        <li>
                                                            स्क्रीनशॉट हमारे सर्वर में संग्रहित न होकर, उस उपयोगकर्ता के
                                                            डिवाइस में संग्रहित किये जाते हैं जिन्होंने स्क्रीनशॉट लिए
                                                            हैं।
                                                        </li>
                                                        <li>
                                                            हमारे सर्वर पर रिकॉर्डिंग, 7 दिनों की अवधि के लिए संग्रहित
                                                            की जाती है।
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>गैर-व्यक्तिगत जानकारी:</strong>
                                    <ol>
                                        <li>
                                            गैर-व्यक्तिगत जानकारी को किसी भी डेटा के रूप में परिभाषित किया जाता है जो
                                            व्यक्तिगत रूप से आपकी पहचान नहीं करता है और इसमें युनीक सिस्टम या हार्डवेयर
                                            आइडेंटिफायर, सिस्टम या ऐप्लिकेशन सॉफ़्टवेयर , और बाह्य उपकरण शामिल हो सकते
                                            हैं।
                                        </li>
                                        <li>
                                            कोई भी गैर-व्यक्तिगत जानकारी, जब व्यक्तिगत जानकारी के साथ संयोजित की जाती है
                                            तो उसे व्यक्तिगत जानकारी माना जाएगा।
                                        </li>
                                        <li>
                                            निम्नलिखित तरीके हैं जिनके द्वारा हम गैर-कानूनी जानकारी एकत्र करते हैं, उनका
                                            उपयोग करते हैं, शेयर करते हैं और उन्हें बनाए रखते हैं।
                                            <div></div>
                                            <ol>
                                                <li>
                                                    संग्रह:
                                                    <ol>
                                                        <li>
                                                            आप सहमत हैं कि नीचे वर्णित उद्देश्यों के लिए ऐप नैदानिक,
                                                            तकनीकी, उपयोग संबंधी जानकारी, एकत्र कर सकता है।
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div></div>
                                                <li>
                                                    उपयोग:
                                                    <ol>
                                                        <li>
                                                            उपर्युक्त जानकारी, ऐप और सेवाओं को प्रदान करने तथा उन्हें
                                                            सुधारने के लिए समय-समय पर एकत्र की जाती है, जो आपको
                                                            सॉफ़्टवेयर अपडेट करने, उत्पाद समर्थन और अन्य सेवाओं के
                                                            प्रावधान की सुविधा देती है (यदि कोई हो) और इस नीति की शर्तों
                                                            के अनुपालन को सत्यापित करने हेतु उपयोग जाती है।
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div></div>
                                                <li>
                                                    शेयर करना:
                                                    <ol>
                                                        <li>
                                                            ऐप साझेदारों और थर्ड पार्टी डेवलपर्स को अपने सॉफ़्टवेयर,
                                                            हार्डवेयर और ऐप के साथ उपयोग के लिए डिज़ाइन की गई सेवाओं को
                                                            बेहतर बनाने में सक्षम करने के लिए, हम ऐसे किसी भी साथी या
                                                            थर्ड पार्टी के डेवलपर को नैदानिक जानकारी का सबसेट प्रदान कर
                                                            सकते हैं जो उस भागीदार या डेवलपर के सॉफ़्टवेयर के लिए
                                                            प्रासंगिक हो, हार्डवेयर और/या सेवाएँ, जब तक कि वे नैदानिक
                                                            जानकारी एक ऐसे रूप में होती है जो व्यक्तिगत रूप से आपकी
                                                            पहचान नहीं करती।
                                                        </li>
                                                    </ol>
                                                </li>
                                                <div></div>
                                                <li>
                                                    अवधारण::
                                                    <ol>
                                                        <li>
                                                            एकत्र की गई जानकारी को केवल एक सीमित अवधि, जो कि उल्लिखित
                                                            उद्देश्यों को पूरा करने के लिए आवश्यक है, जब तक कि एक लंबी
                                                            अवधारण अवधि या कानून द्वारा अनुमति की आवश्यक नहीं है और केवल
                                                            ऊपर उल्लिखित उद्देश्यों के लिए ही रखा जाएगा। एक बार उद्देश्य
                                                            पूरा हो जाने के बाद, सभी सूचनाओं को एक सुरक्षित और भरोसेमंद
                                                            तरीके से डिलीट कर दिया जाता है।
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>लोकेशन सेवाएँ:</strong>
                                    <ol>
                                        <li>
                                            ऐप, इसके लाइसेंसधारी और एजेंट, ऐप और आपके लोकेशन से संबंधित अन्य जानकारी का
                                            उपयोग, नियमित रूप से ब्लूटूथ और वाई-फाई सिग्नल तथा अन्य तकनीकों एवं डेटा के
                                            माध्यम से सेवाओं के प्रदर्शन के लिए ऐप और ऐप का डिज़ाइन, कार्यक्षमता,
                                            प्रदर्शन एवं सामग्री को बेहतर बनाने में मदद के लिए, उपयोग कर सकते हैं।
                                            भू-स्थान की जानकारी एकत्र करने के लिए, आपके डिवाइस पर लोकेशन सेटिंग्स सक्षम
                                            होनी चाहिए। इसलिए, कुछ मामलों में, ऐप अनुरोध कर सकता है कि आप लोकेशन
                                            सेटिंग्स को सक्षम करें।
                                        </li>
                                        <li>
                                            ऐसे मामलों को छोड़कर जहां आपके द्वारा अनुरोध की गई कोई विशिष्ट सेवाओ में
                                            व्यक्तिगत पहचान की आवश्यकता होती है या व्यक्तिगत पहचान शामिल होती है, ऐप
                                            गुमनाम और ऐसे रूप में लोकेशन डेटा एकत्र करता है जो व्यक्तिगत रूप से आपकी
                                            पहचान नहीं करता है। ऐप का उपयोग करके, आप ऐप की ऐसी सेवाओं को प्रदान करने और
                                            सुधारने के लिए अपने लोकेशन डेटा के ट्रांसमिशन, संग्रह, रखरखाव, प्रसंस्करण और
                                            उपयोग के लिए स्वीकृति और सहमति देते हैं।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>कुकीज़:</strong>
                                    <ol>
                                        <li>
                                            कुकीज़ और अन्य तकनीकों द्वारा एकत्रित जानकारी को गैर व्यक्तिगत जानकारी के
                                            रूप में माना जाता है। हालाँकि, जिस हद तक आई.पी एड्रेस या समान आइडेंटिफायर्स
                                            को स्थानीय कानून द्वारा व्यक्तिगत जानकारी माना जाता है, हम भी ऐसे
                                            आइडेंटिफायर्स से जुड़ी किसी भी तरह की जानकारी को व्यक्तिगत जानकारी ही मानते
                                            हैं। इसी तरह, जिस हद तक गैर-व्यक्तिगत जानकारी व्यक्तिगत जानकारी के साथ
                                            संयुक्त है, हम संयुक्त जानकारी को व्यक्तिगत जानकारी के रूप में मानेंगे।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>ऐप द्वारा एकत्र की जाने वाली अन्य जानकारी:</strong>
                                    <ol>
                                        <li>
                                            ऐप आपको जानकारी देकर या आपकी सहमति से, आपके बारे में अन्य जानकारी भी एकत्र
                                            कर सकता है। आप हमें कुछ प्रकार की जानकारी नहीं प्रदान करने का विकल्प चुन
                                            सकते हैं, लेकिन ऐसा करने से आपकी सेवाओं का लाभ उठाने और/या उपयोग करने की
                                            आपकी क्षमता प्रभावित हो सकती है।
                                        </li>
                                        <li>
                                            “मैं स्वीकार करता हूँ” बटन पर क्लिक करके या ऐप के उपयोग से आप अपनी डिवाइस के
                                            कुछ कार्यों को सक्षम करने के लिए डिवाइस के लोकेशन को खोजने की, एस.डी कार्ड
                                            सामग्री को संशोधित या डिलीट करने की, फोन के आँकड़े और पहचान, फाइल सिस्टम को
                                            माउंट और अनमाउंट करने की, अपने डिवाइस, कॉन्फ्रेंसिंग लॉग और इतिहास आदि में
                                            सहेजे गए संपर्क पढ़ने की अनुमति देते हैँ।
                                        </li>
                                        <li>
                                            ऐप की विशेषताएं आपको प्रोफ़ाइल जानकारी, स्थिति अपडेट , टिप्पणियां और अन्य
                                            कंटेंट और / या जानकारी (उपयोगकर्ता सामग्री) जैसी कंटेंट, पोस्ट करने की
                                            अनुमति दे सकती हैं। आपके द्वारा बनाई गई उपयोगकर्ता सामग्री पर स्वामित्व आपके
                                            साथ रहता है; हालाँकि, ऐप के माध्यम से उपयोगकर्ता सामग्री शेयर करके, आप
                                            दूसरों को इस गोपनीयता नीति के अनुसार अपनी उपयोगकर्ता सामग्री को देखने और /
                                            या शेयर करने की अनुमति देने के लिए सहमत हैं।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>थर्ड पार्टी ऐप्लिकेशन, वेबसाइट और सेवाएँ:</strong>
                                    <ol>
                                        <li>
                                            ऐप में अन्य वेबसाइटों / ऐप्लिकेशन के लिंक शामिल हो सकते हैं या थर्ड पार्टी
                                            और अन्य सामग्री से विज्ञापन प्रदर्शित हो सकते हैं जो थर्ड पार्टी वेबसाइटों
                                            से लिंक करते हैं। ऐसी वेबसाइट/ऐप्लिकेशन उनकी संबंधित गोपनीयता नीतियों द्वारा
                                            संचालित होती हैं, जो ऐप के नियंत्रण से बाहर हैं। ऐप के माध्यम से किसी थर्ड
                                            पार्टी की वेबसाइट या ऐप्लिकेशन का उपयोग करते समय आपके द्वारा प्रदान की जाने
                                            वाली किसी भी जानकारी का उपयोग, उस वेबसाइट / ऐप्लिकेशन / जिस ऑपरेटर का आप
                                            उपयोग/विज़िट कर रहे हैं, उस ऑपरेटर की गोपनीयता नीति द्वारा संचालित होता है ।
                                            यह नीति ऐप से भिन्न हो सकती है। यदि आपको वेबसाइट के होमपेज से लिंक के माध्यम
                                            से ऐसी किसी भी वेबसाइट / ऐप्लिकेशन की गोपनीयता नीति नहीं मिल रही है, तो आपको
                                            अधिक जानकारी के लिए संबंधित वेबसाइट / ऐप्लिकेशन ऑपरेटर से सीधे संपर्क करना
                                            चाहिए।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>एक्सेस, सुधार और डिलीट करना:</strong>
                                    <ol>
                                        <li>
                                            ऐप आपकी नवीनतम जानकारी के साथ अपने रिकॉर्ड को अपडेट और सटीक रखने के लिए कड़ा
                                            प्रयास करता है। आप यह सुनिश्चित करने के लिए ज़िम्मेदार होंगे कि आपके द्वारा
                                            समय-समय पर प्रदान की जाने वाली जानकारी या डेटा सही, नवीनतम और अपडेटेड होगा
                                            और आपके पास ऐसी जानकारी या डेटा प्रदान करने के सभी अधिकार, अनुमतियां और
                                            सहमति होंगी।
                                        </li>
                                        <li>
                                            आप यह नोट कर सकते हैं कि कुछ जानकारी को हटाने या सहमति की वापसी से ऐप तक
                                            आपकी पहुंच या ऐप की कुछ सुविधाओं और सेवाओं तक आपकी पहुंच रद्द हो सकती है।
                                            इसके अतिरिक्त, यदि हम मान्य दस्तावेजों या डेटा प्रतिधारण द्वारा समर्थित नहीं
                                            है, तो लागू कानून या कानून प्रवर्तन अनुरोधों या किसी न्यायिक कार्यवाही के
                                            तहत आवश्यक नहीं होने पर, हम आपके सुधार अद्यतन या विलोपन के आपके अनुरोध को
                                            संसाधित करने में सक्षम नहीं हो सकते हैं या इसे कार्यान्वित करना बहुत मुश्किल
                                            है (जैसे बैकअप प्रतियों से संबंधित अनुरोध या यदि एक नए सिस्टम के लिए अनुरोध
                                            या तकनीकी डिज़ाइन को बदलने की आवश्यकता है) या अन्य उपयोगकर्ताओं की गोपनीयता
                                            को जोखिम में डालती है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>प्रश्न और शिकायतें:</strong>
                                    <ol>
                                        <li>
                                            हम आपके द्वारा एकत्रित और संसाधित की गई आपकी व्यक्तिगत जानकारी की सुरक्षा के
                                            लिए प्रतिबद्ध हैं और उसी के लिए आपके निरंतर समर्थन हेतु तत्पर हैं। आपकी
                                            व्यक्तिगत जानकारी की सुरक्षा से सम्बंधित किसी भी प्रतिक्रिया या चिंता के
                                            मामले में, या किसी भी गोपनीयता-संबंधी प्रतिक्रिया या चिंता के बारे में आप इस
                                            पर संपर्क कर सकते है:
                                            <a href="mailto:grievance.officer@jio.com">grievance.officer@jio.com</a>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
