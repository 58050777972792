import { Injectable, NgZone } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { MeetingService, SocketService, SocketEvent, RtcService } from 'src/app/core';

import { SOCKET_EVENTS } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AddParticipantsService {
    private participants: Map<string, any> = new Map();
    private participants$: BehaviorSubject<Map<string, any>> = new BehaviorSubject(this.participants);
    private meetingObj;
    private timeouts = [];

    constructor(
        private zone: NgZone,
        private meetingService: MeetingService,
        private socketService: SocketService,
        private rtcService: RtcService,
        private toastrService: ToastrService,
        private translate: TranslateService
    ) {}

    setMeetingObject(meetingObj) {
        this.meetingObj = meetingObj;

        this.socketService.dataEvents$.subscribe(this.handleSocketDataEvents.bind(this));
    }

    handleSocketDataEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case SOCKET_EVENTS.CALL_ACCEPT:
                    if (
                        event.data.historyId === this.meetingObj.historyId &&
                        this.participants.get(event.data.userId)
                    ) {
                        this.removeParticipant({ _id: event.data.userId });
                        this.emit();
                    }
                    break;
                case SOCKET_EVENTS.GENERIC:
                    if (event.data.historyId === this.meetingObj.historyId) {
                        if (
                            event.data.eventType === SOCKET_EVENTS.CALL_CUT_NOTIFICATION &&
                            this.participants.get(event.data.userId)
                        ) {
                            this.setParticipantStatus(event.data.ownId, 'declined');
                            this.emit();
                        } else if (
                            event.data.eventType === SOCKET_EVENTS.CALL_NOT_ANSWERED_NOTIFICATION &&
                            this.participants.get(event.data.ownId)
                        ) {
                            this.setParticipantStatus(event.data.ownId, 'missed');
                            this.emit();
                        } else if (
                            event.data.eventType === SOCKET_EVENTS.IN_ANOTHER_CALL_NOTIFICATION &&
                            this.participants.get(event.data.ownId)
                        ) {
                            this.removeParticipant({ _id: event.data.userId });
                            this.toastrService.info(
                                this.translate.instant('tostrs.busy_on_another_call', {
                                    value: event.data.name
                                })
                            );
                            // this.setParticipantStatus(event.data.ownId, 'inAnotherCall');
                            this.emit();
                        }
                    }
                    break;
            }
        });
    }

    setParticipantStatus(participantId, status) {
        const participant = this.participants.get(participantId);
        participant.status = status;
        clearTimeout(participant.missedCallTimeoutId);
        this.timeouts.push(
            setTimeout(() => {
                participant.status = null;
                this.emit();
            }, 2000)
        );
    }

    getParticipants(): Subject<Map<string, any>> {
        return this.participants$;
    }

    addParticipant(participants, type) {
        const room = this.rtcService.getRoomInfo();
        if (type === 'vc') {
            this.addVC(participants[0]);
        } else {
            const users = participants.map((participant) => {
                let id = participant._id || participant.userId;
                if (['contact', 'contact_ex'].includes(type)) {
                    id = participant.userId;
                }
                return { ...participant, finalId: id };
            });

            this.meetingService
                .addParticipantsInMeeting({
                    users: users.map((user) => ({ userId: user.finalId, phoneNo: user.phoneNo || '' })),
                    ownerName: `${this.meetingObj.owner_name} ${
                        this.meetingObj.owner_lname ? this.meetingObj.owner_lname : ''
                    }`,
                    topic: this.meetingObj.topic,
                    historyId: this.meetingObj.historyId,
                    isClassroomMode: false,
                    webinar: room?.webinar,
                    webinarOptions: room?.webinarOptions
                })
                .subscribe(() => {
                    users.forEach((user) => {
                        user.status = 'calling';
                        user.missedCallTimeoutId = setTimeout(() => {
                            this.setParticipantStatus(user.finalId, 'missed');
                        }, 40 * 1000);
                        this.participants.set(user.finalId, { ...user, type });
                    });
                    this.emit();
                });
        }
    }

    addVC(vc) {
        let obj = {
            roomID: this.meetingObj.room_id,
            type: null,
            ipaddress: null,
            vcIds: [],
            manualIps: [
                {
                    type: vc.type,
                    ipaddress: vc.ipaddress
                }
            ],
            historyId: this.meetingObj.historyId
        };

        this.rtcService.inviteVC(obj).subscribe(
            () => {},
            () => this.toastrService.error('Error adding VC, please try again')
        );
    }

    removeParticipant(participant) {
        const id = participant._id || participant.userId;
        if (this.participants.has(id)) {
            this.participants.delete(id);
            this.emit();
        }
    }

    emit() {
        this.participants$.next(this.participants);
    }

    dispose() {
        this.timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
    }
}
