import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PreCallTestingService } from 'src/app/pre-call-testing/services';

@Component({
    selector: 'app-devicemodal-error',
    templateUrl: './devicemodal-error.component.html',
    styleUrls: ['./devicemodal-error.component.scss']
})
export class DevicemodalErrorComponent implements OnInit {
    @Output() closeModalEvent = new EventEmitter();
    @Output() restartClicked = new EventEmitter<void>();

    constructor(public preCallTestingService: PreCallTestingService) {}

    ngOnInit(): void {}

    closeModal() {
        this.closeModalEvent.emit();
    }

    restartTest() {
        this.restartClicked.emit();
    }
}
