import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {
    constructor(private appService: AppService, private restService: RestService) {}

    getDirectoryContacts({ offset = 0, limit = 500, query = '' }) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/contacts?keyword=${query}&offset=${offset}&limit=${limit}`
        );
    }

    getRecentContacts() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/calls`);
    }

    getFavouriteResources(resourceType) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/favourites/${resourceType}`);
    }

    addResourceToFavourites(resource, resourceType: 'contact' | 'group' | 'vc') {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/favourites/${resourceType}`, {
            resourceId: resource._id
        });
    }

    deleteResourceFromFavourites(resource, resourceType: 'contact' | 'group' | 'vc') {
        return this.restService.delete(`${this.appService.getEnvVariable('BASE_URL')}/favourites/${resourceType}`, {
            body: { resourceId: resource.fabId }
        });
    }
}
