import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService, MeetingService, RtcService, UserService, UtilService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class CheckForSpeakerEntryGuard implements CanActivate {
    state: RouterStateSnapshot;
    isLoggedIn = false;
    recorderToken = null;
    meetingIDAndPin: any;
    sh: string;
    constructor(
        private meetingService: MeetingService,
        private userService: UserService,
        private utilService: UtilService,
        private authService: AuthService,
        private router: Router,
        private rtcService: RtcService
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.sh = route.queryParams.sh;
        if (!this.sh) {
            const currentUrl = window.location.href;
            const urlParts = currentUrl.split('/');
            const shortHash = urlParts[4];
            if (!urlParts[6]) {
                this.sh = shortHash;
            }
        }
        if (this.sh && this.sh?.length > 15) {
            return this.getPinAndId();
        }
        this.state = state;
        this.isLoggedIn = this.authService.getIsAuthenticated();
        this.recorderToken = route?.queryParams?.recorderToken;

        if (this.authService.getIsAuthenticated()) {
            return this.hasProfile();
        }
        let jiomeetId;
        let pin;
        if (route.routeConfig.path == 'attendee' || route.routeConfig.path == 'webinar') {
            let routeElements = state.url.toString().split('/');
            jiomeetId = this.meetingIDAndPin?.meetingId ?? routeElements[2];
            pin = this.meetingIDAndPin?.meetingPin ?? routeElements[4];
            if (pin.length > 6) {
                return of(true);
            }
        } else {
            jiomeetId = this.meetingIDAndPin?.meetingId ?? route?.queryParams?.meetingId;
            pin = this.meetingIDAndPin?.meetingPin ?? route?.queryParams?.pwd;
        }
        if (!jiomeetId) {
            return of(false);
        }

        return this.getMeetingType(jiomeetId, pin);
    }

    getPinAndId(): Observable<boolean> {
        return this.rtcService.getRoomPinAndId(this.sh).pipe(
            map(
                (res) => {
                    this.meetingIDAndPin = res;
                    return true;
                },
                (error) => {
                    return false;
                }
            )
        );
    }

    getMeetingType(jiomeetId, pin) {
        const isMobileOS = this.utilService.isMobileBrowser();

        return new Observable<boolean>((observer) => {
            this.meetingService.getMeetingType({ jiomeetId, pin }).subscribe(
                (res: any) => {
                    this.rtcService.setWebinarIsSessionInfo(res);
                    if (!this.isLoggedIn) {
                        let returnResp = false;
                        if (res?.advancedOptions?.loggedInOnly && !this.recorderToken) {
                            this.router.navigate(['login'], {
                                queryParams: {
                                    returnUrl: this.state.url
                                }
                            });
                        } else {
                            returnResp = true;
                        }

                        observer.next(returnResp);
                        observer.complete();
                    } else {
                        this.hasProfile().subscribe((res) => {
                            observer.next(res);
                            observer.complete();
                        });
                    }
                },
                (err) => {
                    console.log(err, err);
                    this.navigateToDashboard();
                    observer.next(false);
                    observer.complete();
                }
            ),
                catchError((err, caught) => {
                    console.log(err, caught);
                    this.navigateToDashboard();
                    return of(false);
                });
            // );
        });
    }

    navigateToDashboard() {
        this.router.navigate(['/'], {});
        return false;
    }

    hasProfile() {
        return this.userService.getUser().pipe(
            map((res) => {
                if (res?._id) {
                    return true;
                }
                return false;
            })
        );
    }
}
