<div class="people-container">
    <div class="sub-section speakers mb-100" id="speakers" #speakersContainer>
        <div class="people-tab-wrapper d-flex align-items-center">
            <div
                class="about-speakers d-flex align-items-center justify-content-center"
                [class.active]="showSpeakers"
                (click)="switchFromSpeakersView()"
                id="speakers-btn"
            >
                Speakers ({{ eventSpeakers.length }})
            </div>
            <div
                class="about-attendees d-flex align-items-center justify-content-center"
                [class.active]="!showSpeakers"
                (click)="switchFromSpeakersView()"
                id="attendees-btn"
            >
                Attendees ({{ attendeesCount }})
            </div>
        </div>
        <cdk-virtual-scroll-viewport
            #attendeesScrollViewport
            itemSize="50"
            class="speaker-virtual-scroll-viewport"
            *ngIf="showSpeakers && eventSpeakers.length"
        >
            <div *cdkVirtualFor="let speaker of eventSpeakers" class="listitem">
                <app-speaker-tile [speaker]="speaker"></app-speaker-tile>
            </div>
        </cdk-virtual-scroll-viewport>
        <div class="default-screen-container" *ngIf="!loading && !eventSpeakers.length && showSpeakers">
            <div class="default-speakers-screen">
                <div class="speakers-screen-image">
                    <img src="assets/img/default_speakers_screen.svg" alt="" />
                </div>
                <div class="speakers-screen-heading">
                    <span>No speakers added yet</span>
                </div>
            </div>
        </div>
        <cdk-virtual-scroll-viewport
            #attendeesScrollViewport
            itemSize="50"
            class="speaker-virtual-scroll-viewport"
            (scrolledIndexChange)="nextAttendeesList($event)"
            *ngIf="!showSpeakers && eventAttendees.length"
        >
            <div *cdkVirtualFor="let attendee of eventAttendees" class="listitem">
                <app-speaker-tile [speaker]="attendee"></app-speaker-tile>
            </div>
        </cdk-virtual-scroll-viewport>
        <div class="default-screen-container" *ngIf="!loading && !eventAttendees.length && !showSpeakers">
            <div class="default-speakers-screen">
                <div class="speakers-screen-image">
                    <img src="assets/img/default_attendee_screen.svg" alt="" />
                </div>
                <div class="speakers-screen-heading">
                    <span>No attendee joined yet</span>
                </div>
            </div>
        </div>
        <div *ngIf="loading" class="loader-mask d-flex justify-content-center align-items-center">
            <img src="assets/img/jioevents-loader.gif" width="88px" alt="" />
        </div>
    </div>
</div>
