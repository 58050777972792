import { Component, Input, OnInit } from '@angular/core';

import { differenceInMinutes } from 'date-fns';

@Component({
    selector: 'app-meeting-invitation',
    templateUrl: './meeting-invitation.component.html',
    styleUrls: ['./meeting-invitation.component.scss']
})
export class MeetingInvitationComponent implements OnInit {
    @Input() id;
    @Input() host;
    @Input() meeting;

    duration;

    ngOnInit() {
        if (this.meeting.startTime && this.meeting.endTime) {
            this.duration = differenceInMinutes(new Date(this.meeting.endTime), new Date(this.meeting.startTime));
        }
    }
}
