<div class="dropdown-popup">
    <!-- Desktop view - Dropdown -->
    <div class="popup-container" *ngIf="!utilService.isMobileView">
        <div
            class="pointer"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            id="navbarUserDropdown"
            tooltipPosition="bottom"
        >
            <div class="dropdown-button p-0" aria-labelledby="navbarUserDropdown">
                <app-event-btn
                    btnText="Create Event"
                    [id]="id"
                    imgPath="assets/img/cal-icon.svg"
                    [customClass]="btnCustomClass"
                ></app-event-btn>
            </div>
        </div>
        <div class="dropdown-menu dropdown-menu-right event-type-dropdown">
            <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
        </div>
        <!-- <div class="popup">
            <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
        </div> -->
    </div>
    <!-- Mobile view - Popup -->
    <div class="popup-container" *ngIf="utilService.isMobileView">
        <app-event-btn
            btnText="Create Event"
            [id]="id"
            imgPath="assets/img/cal-icon.svg"
            (btnClick)="togglePopup($event)"
            [customClass]="btnCustomClass"
        >
        </app-event-btn>
        <div class="popup-overlay" *ngIf="popupOpen"></div>
        <div class="popup" *ngIf="popupOpen">
            <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
        </div>
    </div>

    <ng-template #dropdownTemplate>
        <div class="create-container d-flex justify-content-center" (outside)="onOutSideClick($event)">
            <div class="create-event-button pointer virtual-action-btn">
                <button class="create-button d-flex" [id]="id + '_virtual'" (click)="createEvent($event)">
                    <div class="button-content d-flex">
                        <div class="img-container"></div>
                        <div class="place-button-content d-flex">
                            <div class="button-title">Virtual Event</div>
                            <div class="button-about">The event will be attended by a live audience</div>
                        </div>
                    </div>
                </button>
            </div>
            <div
                id="mobile-create-prerecorded"
                class="view pointer eventTypeItem prerecord-action-btn"
                *ngIf="preRecordedFeatureEnabled || currentUser.tenantId"
            >
                <button class="create-button d-flex" [id]="id + '_prerecord'" (click)="createPreRecordedEvent($event)">
                    <div class="button-content d-flex">
                        <div class="img-container"></div>
                        <div class="place-button-content d-flex">
                            <div class="button-title">Pre Recorded Event</div>
                            <div class="button-about">The video will autoplay at a specific time</div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </ng-template>
</div>
