<div class="gallery-container" id="SpeakerGalleryContainer">
    <div class="participants-grid" id="galleryParticipantsGrid" *ngIf="!loading">
        <ng-container
            *ngFor="let participant of participants | slice: gridIndices.start:gridIndices.start + tileCountPerView"
        >
            <app-gallery-participant-tile
                class="speaker-participant-tile"
                [participantData]="participant"
                [id]="'tile' + participant.uid"
                (participantTileUpdates)="handleParticipantTileUpdates($event)"
            ></app-gallery-participant-tile>
        </ng-container>
    </div>
    <img
        class="left-arrow pointer"
        *ngIf="showSlideButton.left"
        src="assets/studio-assets/ic_chevron_right.svg"
        (click)="slideLeft()"
    />
    <img
        class="right-arrow pointer"
        *ngIf="showSlideButton.right"
        src="assets/studio-assets/ic_chevron_right.svg"
        (click)="slideRight()"
    />
</div>
