import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-empty-message',
    templateUrl: './user-empty-message.component.html',
    styleUrls: ['./user-empty-message.component.scss']
})
export class UserEmptyMessageComponent implements OnInit {
    @Input() messageData: any;
    constructor() {}

    ngOnInit(): void {}
}
