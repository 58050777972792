import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AppService } from './app.service';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { switchMap, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { UtilService } from './util.service';
import { SystemService } from './system.service';
import { EventData, EventEmitterService } from './event-emitter.service';
import { APP_EVENTS } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class JiocloudAuthService {
    private isAuthenticated = false;
    private authInfo;
    deviceID;

    constructor(
        private restService: RestService,
        private appService: AppService,
        private localStorageService: LocalStorageService,
        private userService: UserService,
        private utilService: UtilService,
        private systemService: SystemService,
        private eventEmitterService: EventEmitterService
    ) {
        this.deviceID = this.systemService.getDeviceUUID();

        this.eventEmitterService.subscribe((eventData: EventData) => {
            if (eventData.type === APP_EVENTS.LOGOUT) {
                this.logout();
            }
            if (eventData.type === APP_EVENTS.SESSION_EXPIRED) {
                this.cleanup();
            }
        });
    }

    login() {
        return this.encdec({
            mode: 'enc',
            text: `${this.userService.getUserSync().jcData.bpUserId}#${Date.now()}#Y`
        }).pipe(
            switchMap((res: any) => {
                const payload = {
                    userId: this.userService.getUserSync().jcData.jcId,
                    deviceType: 'W',
                    deviceInfo: {
                        deviceKey: this.deviceID
                    }
                };
                let headers: HttpHeaders = new HttpHeaders();
                headers = headers.set('X-User-Id', this.userService.getUserSync().jcData.jcId);
                headers = headers.set('X-Device-Key', this.deviceID);
                headers = headers.set('X-Enc-Token', res.result);
                headers = headers.set('X-API-Key', `${this.appService.getEnvVariable('JIO_CLOUD').API_KEY}`);
                headers = headers.set('X-App-Secret', `${this.appService.getEnvVariable('JIO_CLOUD').APP_SECRET}`);
                headers = headers.set('X-Client-Details', 'clientType:web;appVersion:1.0.0');
                return this.restService
                    .post(`${this.appService.getEnvVariable('JIO_CLOUD').HOST}/account/external/login`, payload, {
                        headers
                    })
                    .pipe(
                        tap((info) => {
                            this.setAuthInfo(info);
                        })
                    );
            })
        );
    }

    refreshToken() {
        return this.encdec({
            mode: 'enc',
            text: `${this.userService.getUserSync()._id}#${Date.now()}#Y`
        }).pipe(
            switchMap((res: any) => {
                const payload = {
                    refreshToken: this.getAuthInfo().authToken.refreshToken,
                    deviceType: 'W'
                };
                let headers: HttpHeaders = new HttpHeaders();
                headers = headers.set('X-User-Id', this.userService.getUserSync().jcData.jcId);
                headers = headers.set('X-Device-Key', this.deviceID);
                headers = headers.set('X-Enc-Token', res.result);
                headers = headers.set('X-API-Key', `${this.appService.getEnvVariable('JIO_CLOUD').API_KEY}`);
                headers = headers.set('X-App-Secret', `${this.appService.getEnvVariable('JIO_CLOUD').APP_SECRET}`);
                headers = headers.set('X-Client-Details', 'clientType:web;appVersion:1.0.0');

                return this.restService
                    .put(`${this.appService.getEnvVariable('JIO_CLOUD').HOST}/account/token/refresh`, payload, {
                        headers
                    })
                    .pipe(
                        tap((info) => {
                            this.setAuthInfo(info);
                        })
                    );
            })
        );
    }

    encdec({ mode, text }) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/encdec`, {
            mode,
            text
        });
    }

    getIsAuthenticated(): boolean {
        return this.isAuthenticated || !!this.getAuthInfo();
    }

    getAuthInfo() {
        return this.authInfo || this.localStorageService.getItem('jioCloudInfo');
    }

    setAuthInfo(info) {
        if (info.authToken) {
            this.authInfo = info;
            this.localStorageService.addItem('jioCloudInfo', info);
            this.isAuthenticated = true;
        }
    }

    getDeviceId() {
        return this.deviceID || this.localStorageService.getItem('deviceId');
    }

    logout(): Observable<any> {
        if (!this.getIsAuthenticated()) {
            return of(true);
        }
        const payload = {
            devices: [
                {
                    deviceKey: this.deviceID,
                    appName: 'JioMeet'
                }
            ],
            revokeAll: 'N',
            forceLogout: 'N'
        };
        const currentTimeInMills = +new Date() - +new Date('1970-01-01');
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('X-User-Id', this.userService.getUserSync().jcData.jcId);
        headers = headers.set('X-Device-Key', this.deviceID);
        headers = headers.set(
            'X-Request-Hash',
            this.utilService.getSHA256(
                `${this.userService.getUserSync().jcData.jcId}#${currentTimeInMills}`,
                this.getAuthInfo().authToken.sessionToken
            )
        );
        headers = headers.set('X-Current-Timestamp', `${currentTimeInMills}`);
        headers = headers.set('X-API-Key', `${this.appService.getEnvVariable('JIO_CLOUD').API_KEY}`);
        headers = headers.set('Authorization', `Basic ${window.btoa(this.getAuthInfo().authToken.accessToken)}`);
        headers = headers.set('X-API-Key', `${this.appService.getEnvVariable('JIO_CLOUD').API_KEY}`);
        headers = headers.set('X-App-Secret', `${this.appService.getEnvVariable('JIO_CLOUD').APP_SECRET}`);
        headers = headers.set('X-Client-Details', 'clientType:WEB;appVersion:1.0.0');
        return this.restService
            .put(`${this.appService.getEnvVariable('JIO_CLOUD').HOST}/security/users/logout`, payload, {
                headers
            })
            .pipe(
                tap(() => {
                    this.cleanup();
                })
            );
    }

    isJioCloudRequest(request) {
        if (
            [
                '/upload/files',
                '/upload/files/chunked/initiate',
                '/share/meeting',
                '/download/meeting/',
                '/download/files/',
                '/media/auth/meeting/',
                '/media/hls/',
                '/media/stream',
                '/security/users/logout',
                '/account/external/login',
                '/account/token/refresh',
                '/nms/meeting/trash'
            ].filter((path) => request.url.indexOf(path) > -1).length
        ) {
            return true;
        }
        return false;
    }

    getCommonHeaders(headers: HttpHeaders, meetingId) {
        const currentTimeInMills = +new Date() - +new Date('1970-01-01');
        headers = headers.set('X-User-Id', this.userService.getUserSync().jcData.jcId);
        headers = headers.set('X-Device-Key', this.systemService.getDeviceUUID());
        headers = headers.set('X-Device-Type', 'W');
        headers = headers.set(
            'X-Request-Hash',
            this.utilService.getSHA256(
                `${this.userService.getUserSync().jcData.jcId}#${meetingId}#${currentTimeInMills}`,
                this.getAuthInfo().authToken.sessionToken
            )
        );
        headers = headers.set('X-Meeting-Id', meetingId);
        headers = headers.set('X-Current-Timestamp', `${currentTimeInMills}`);
        headers = headers.set('Authorization', `Basic ${window.btoa(this.getAuthInfo().authToken.accessToken)}`);
        headers = headers.set('X-API-Key', `${this.appService.getEnvVariable('JIO_CLOUD').API_KEY}`);
        headers = headers.set('X-App-Secret', `${this.appService.getEnvVariable('JIO_CLOUD').APP_SECRET}`);
        return headers;
    }

    cleanup() {
        this.isAuthenticated = false;
        this.authInfo = null;
        this.localStorageService.removeItem('jioCloudInfo');
    }
}
