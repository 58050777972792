import { Component, OnInit } from '@angular/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HOME_PAGE_REVAMP, homePage } from '../../../constants/gtm-events';
import { UtilService, AuthService, UserService, LocalStorageService } from 'src/app/core';

import { HEADER } from '../../../constants';

@Component({
    selector: 'app-trial-event',
    templateUrl: './trial-event.component.html',
    styleUrls: ['./trial-event.component.scss']
})
export class TrialEventComponent implements OnInit {
    currentUser;
    homePageGtmEvents: any = homePage;
    zoomState = 'out';
    prevZoomIntersection = 0;
    intersectionToScale = 1;
    isAuthenticated = false;
    headerEvents: any = HEADER;
    onIntersection(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry) => {
            this.intersectionToScale = 1 - 0.25 * entry.intersectionRatio;
            const doc = document.getElementById('viewSection');
            doc.style.transform = `scale(${this.intersectionToScale})`;
            this.prevZoomIntersection = entry.intersectionRatio;
        });
    }
    constructor(
        private googleTagManger: GoogleTagMangerService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private utilService: UtilService,
        private authService: AuthService,
        private userService: UserService,
        private localstoreService: LocalStorageService
    ) {}
    ngOnInit(): void {
        this.currentUser = this.userService.getUserSync();
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
    }

    homePageClickevent(eventData) {
        this.googleTagManger.pushGoogleTagData(eventData);
    }
    redirectToLogin() {
        this.router.navigate(['/login']);
    }
    redirectToContactUs() {
        this.router.navigate(['/contactus']).then(() => {
            this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.BANNER_SCHEDULE_DEMO);
        });
    }
    createEvent() {
        this.router
            .navigate(['/dashboard'], {
                relativeTo: this.activatedRoute,
                queryParams: { create: true }
            })
            .then(() => {
                this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.CREATE_EVENT);
            });
    }
}
