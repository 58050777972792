import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService, UtilService, DiagnosticService } from 'src/app/core';

@Component({
    selector: 'app-change-profile-picture',
    templateUrl: './change-profile-picture.component.html',
    styleUrls: ['./change-profile-picture.component.scss']
})
export class ChangeProfilePictureComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() progress: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('fileUpload ') fileUpload: ElementRef;

    currentUser;
    image;
    imgurl;
    uploadForm: FormGroup;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    originalImage: any = '';
    isDefaultPic = false;
    uploading = false;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private utilService: UtilService,
        private toastrService: ToastrService,
        private diagnosticService: DiagnosticService,
        private imageCompress: NgxImageCompressService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.currentUser = this.userService.getUserSync();
        this.uploadForm = this.formBuilder.group({
            profile: ['']
        });
        this.userService.getUser().subscribe((user) => {
            this.isDefaultPic = !user.imageMeta && !user.imageMeta?.path;
            this.originalImage = this.userService.getProfilePicPath(user._id);
        });

        setTimeout(() => {
            this.fileUpload.nativeElement.click();
        }, 200);
    }

    onSubmit() {
        if (this.isDefaultPic && !this.imageChangedEvent) {
            this.closeModal();
            return;
        }

        const formData = new FormData();
        formData.append('file', this.uploadForm.get('profile').value);

        this.progress.emit(true);

        this.uploading = true;
        this.userService.uploadProfilePicture(formData).subscribe(
            (res: any) => {
                if (res.success) {
                    this.progress.emit(false);
                    this.uploading = false;

                    this.diagnosticService
                        .sendEvent({
                            eventAction: 'Choose Photo',
                            eventCategory: 'Web Profile',
                            eventType: 'app_event',
                            endpoint: '/api/my_profile/uploadPic',
                            status: 'success'
                        })
                        .subscribe();
                } else {
                    this.toastrService.error(this.translateService.instant('tostrs.failed_to_update_profile_picture'));
                    this.uploading = false;
                }
                this.progress.emit(false);
                this.closeModalAndReturnPicture();
            },
            (err) => {
                this.progress.emit(false);
                this.uploading = false;
                this.diagnosticService
                    .sendEvent({
                        eventAction: 'Choose Photo',
                        eventCategory: 'Web Profile',
                        eventType: 'api_failure',
                        endpoint: '/api/my_profile/uploadPic',
                        status: 'failed',
                        data: err.error
                    })
                    .subscribe();
                console.log(err);
            }
        );
    }

    fileChangeEvent(event: any): void {
        if (
            event.target.files[0] &&
            !(
                event.target.files[0].type === 'image/jpeg' ||
                event.target.files[0].type === 'image/jpg' ||
                event.target.files[0].type === 'image/png'
            )
        ) {
            this.toastrService.error(
                this.translateService.instant('tostrs.please_select_a_jpg_jpeg_or_png_image_file')
            );
            return;
        }
        if (event.target.files[0] && event.target.files[0].size / 1024 / 1024 >= 2) {
            this.toastrService.error(this.translateService.instant('tostrs.file_size_cannot_exceed_2mb'));
            return;
        }
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        // this.uploadForm.get('profile').setValue(this.utilService.dataURItoFile(this.croppedImage));
        if (4 * (this.croppedImage.length / 3) > 2 * 1000 * 1000) {
            this.compressFile(this.croppedImage);
        } else {
            this.uploadForm.get('profile').setValue(this.utilService.dataURItoFile(this.croppedImage));
        }
    }

    compressFile(image) {
        const orientation = -1;
        console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

        this.imageCompress.compressFile(image, orientation, 50, 50).then((result) => {
            console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
            this.uploadForm.get('profile').setValue(this.utilService.dataURItoFile(result));
        });
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });
        return blob;
    }

    closeModal() {
        this.close.emit();
    }

    closeModalAndReturnPicture() {
        this.userService.getUser().subscribe((user) => {
            this.isDefaultPic = !user.imageMeta && !user.imageMeta?.path;
            this.originalImage = this.userService.getProfilePicPath(user._id);
            this.close.emit(this.originalImage);
        });
    }
}
