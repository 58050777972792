import { Component, OnInit, ViewChild, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { HOME_PAGE_REVAMP, homePage } from '../../../constants/gtm-events';
import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
import { AddonsService, MeetingService } from 'src/app/core';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UtilService } from 'src/app/core';
declare var $: any;
@Component({
    selector: 'app-landing-pageversion2',
    templateUrl: './landing-pageversion2.component.html',
    styleUrls: ['./landing-pageversion2.component.scss']
})
export class LandingPageversion2Component implements OnInit {
    @Input() dashboard;
    @Input() previousWebinar;
    @Input() eventinfo;
    @Input() id;
    @Input() sliderName;
    loginPage: any = '/login';
    homePageGtmEvents: any = homePage;
    homePageView: string = 'homeView';
    zoomLevel = 1;
    video_Container: HTMLElement;
    carouselOptions: OwlOptions = {
        items: 1,
        loop: true, // Adjust margin as needed
        autoplay: true,
        autoplayTimeout: 24000, // Adjust to your preferred duration
        autoplayHoverPause: true,
        lazyLoad: true,
        dots: true
    };
    showVideoText: boolean = false;
    showVideoCard: boolean = false;
    showVideoSubCard: boolean = false;
    constructor(
        private router: Router,
        private appService: AppService,
        private googleTagManger: GoogleTagMangerService,
        private meetingService: MeetingService,
        private webinarService: WebinarService,
        private renderer: Renderer2,
        private utilService: UtilService,
        private el: ElementRef,
        private addonsService: AddonsService
    ) {
        this.loginPage = this.appService.getLoginPageUrl();
    }

    videoSource: any;
    meetingDetailsId;
    isMobile;
    activeCarouselItemIndex: number = 0;
    subscribetion: any[] = [];
    featureIdx: any = 0;
    width: any = 0;
    homePageBanner;
    bannerImage: any[] = [];
    meetingId: any[] = [];
    isMuted = false;
    isloading = true;
    showSpeakersCount: boolean = false;
    cards: any;
    reverseCard: any;
    enableNewSignIn = this.appService.getConfigVariable('enableNewSignIn');
    itemsPerPage = 3;
    startIndex = 0;
    currentPage = 1;
    endIndex;
    isElementInView = false;
    isElementInViews = false;
    isVideoPlaying: boolean = false;
    goToPage(pageNumber: number): void {
        this.currentPage = pageNumber;
        this.startIndex = (this.currentPage - 1) * this.itemsPerPage;
    }
    @ViewChild('videoPlayer') videoPlayer: ElementRef;
    @ViewChild('videoPlay') videoPlay: ElementRef;
    @ViewChild('videoContainer') videoContainer: ElementRef;
    ngAfterViewInit() {
        $(document).ready(() => {
            $('.owl-carousel').owlCarousel(this.carouselOptions);
        });
        const video: HTMLVideoElement = this.videoPlayer.nativeElement;
        const videoSection: HTMLElement = document.getElementById('videoSection');
        const videoText: HTMLElement = document.getElementById('videoText');
        const videoCard: HTMLElement = document.getElementById('videoCard');
        const videoSubCard: HTMLElement = document.getElementById('videoSubCard');

        const observer_video = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0.1) {
                        video
                            .play()
                            .then(() => {
                                this.isVideoPlaying = true;
                                this.renderer.addClass(videoSection, 'scale-in-center');
                                this.renderer.addClass(videoSection, 'video-view-container');
                            })
                            .catch((error) => {
                                this.isVideoPlaying = false;
                            });
                    } else {
                        video.pause();
                    }
                });
            },
            { root: null, rootMargin: '0px', threshold: 0.1 }
        );

        const observer_text = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.showVideoText = true;
                    }
                });
            },
            { root: null, rootMargin: '0px', threshold: 0.1 }
        );
        const observer_card = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.showVideoCard = true;
                    }
                });
            },
            { root: null, rootMargin: '0px', threshold: 0.1 }
        );
        const observer_sub_card = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.showVideoSubCard = true;
                    }
                });
            },
            { root: null, rootMargin: '0px', threshold: 0.1 }
        );
        if (videoSection) observer_video.observe(videoSection);
        if (videoText) observer_text.observe(videoText);
        if (videoCard) observer_card.observe(videoCard);
        if (videoSubCard) observer_sub_card.observe(videoSubCard);
    }
    playVideo() {
        const video: HTMLVideoElement = this.videoPlayer.nativeElement;
        video
            .play()
            .then(() => {
                this.isVideoPlaying = true;
                this.renderer.addClass(document.getElementById('videoSection'), 'scale-in-center');
                this.renderer.addClass(document.getElementById('videoSection'), 'video-view-container');
            })
            .catch((error) => {
                console.error('Failed to play video:', error);
            });
    }
    showMore: boolean = true;
    isHovered = [];
    isHoveredCard = [];

    ngOnInit(): void {
        this.isloading = true;
        this.cards = this.appService.getConfigVariable('cardDetail');
        this.reverseCard = this.appService.getConfigVariable('cardDetails');
        this.meetingDetailsId = this.appService.getConfigVariable('meetingId_Details');
        this.googleTagManger.pushGoogleTagData(GA_PAGEVIEW.HOME_PAGE_REVAMP_VIEW);
        // this.homePageBanner = this.appService.getConfigVariable('homePageBanners');
        this.addonsService.getHomeBanner().subscribe(
            (res) => {
                this.homePageBanner = res;
                this.isloading = false;
                this.utilService.setLoadingForLandingPage(true);
            },
            (err) => {
                this.isloading = false;
                this.utilService.setLoadingForLandingPage(true);
            }
        );
        this.isMobile = this.utilService.isMobileBrowser();
        this.videoSource = this.appService.getConfigVariable('home_page_video_url');
    }

    handleMouseEnter(index): void {
        this.isHovered[index] = !this.isHovered[index];
    }
    handleReverseCard(index): void {
        this.isHoveredCard[index] = !this.isHoveredCard[index];
    }
    videoLoaded() {
        this.isloading = false;
    }
    redirectToAttendeeLink(url: any) {
        window.open(url, '_blank');
        for (const obj of this.homePageBanner.data) {
            obj?.attributes?.registrationEnabled
                ? this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.REGISTER_NOW)
                : this.googleTagManger.pushGoogleTagData(HOME_PAGE_REVAMP.VIEW_DETAILS);
        }
    }
    muteVideo() {
        const videoElement = this.videoPlayer.nativeElement as HTMLVideoElement;
        videoElement.muted = true;
    }

    unmuteVideo() {
        const videoElement = this.videoPlayer.nativeElement as HTMLVideoElement;
        videoElement.muted = false;
    }
    toggleMute() {
        this.isMuted = !this.isMuted;
    }
    childParentchange(tabidx) {
        this.featureIdx = tabidx;
    }
    toggleView() {
        this.showMore = !this.showMore;
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    redirectToLogin() {
        this.router.navigate(['/login']);
    }

    redirectToContactUs() {
        this.router.navigate(['/contactus']);
    }

    homePageClickevent(eventData) {
        this.googleTagManger.pushGoogleTagData(eventData);
    }

    selectCarousel(idx = 0) {
        this.activeCarouselItemIndex = idx;
    }

    truncatedName(name: string): string {
        var limit = 15;
        if (this.isMobile) {
            limit = 10;
            if (name && name.length > limit) {
                return name.substr(0, limit) + '...';
            } else {
                return name;
            }
        } else {
            if (name && name.length > limit) {
                return name.substr(0, limit) + '...';
            } else {
                return name;
            }
        }
    }
}
