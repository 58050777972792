<div class="share-media-controls-wrapper-component">
    <div
        id="shareMediaIconView"
        class="share-media-icon"
        [class.disable]="showStream && shouldPlayRTMPInStream"
        [ngClass]="{
            active: showMediaSharePopup,
            sharing:
                ((mediaShared && this.callViewStateManagerService.isHost) || screenSharing) &&
                !!callViewStateManagerService.roomStatus?.isSharing
        }"
        (click)="handleShareMediaControlClick()"
        pTooltip="{{
            ((mediaShared && this.callViewStateManagerService.isHost) || screenSharing) &&
            !!callViewStateManagerService.roomStatus?.isSharing
                ? 'Stop Share'
                : 'Share'
        }}"
        pTooltip="Share"
        tooltipPosition="top"
        tooltipStyleClass="tooltip-revamp"
    ></div>
    <div
        class="share-media-options-wrapper"
        [class.jio-pc-share-options-wrapper]="isJioPc"
        *ngIf="showMediaSharePopup && !(showStream && shouldPlayRTMPInStream)"
    >
        <div class="share-media-container-header" [class.jio-pc-share-header]="isJioPc">
            <div>
                <div>{{ showStreamDetailsForm ? 'Share' : 'Share' }}</div>
                <div>
                    {{
                        isJioPc
                            ? 'Share video to display in your event'
                            : 'Share Screen or video to display in your event'
                    }}
                </div>
            </div>
            <img class="pointer" src="assets/images/close_icon_opaque.svg" (click)="toggle()" id="shareCloseTabIcon" />
        </div>
        <div class="dual-option-panel" [class.jio-pc-option-panel]="isJioPc">
            <div class="media-option-wrapper" (click)="startShare()" id="screenShareIconView" *ngIf="!isJioPc">
                <div class="screen-share"></div>
                Screen Share
            </div>
            <div
                class="media-option-wrapper cloud-video-wrapper"
                [class.disabled]="!callViewStateManagerService.isHost"
                *ngIf="callViewStateManagerService.isHost"
                (click)="shareCloudVideo()"
                id="videoShareIconView"
            >
                <div class="cloud-video"></div>
                Video Share
            </div>
        </div>
    </div>
    <app-cloud-video-preview
        *ngIf="showCloudVideoPreviewPopup"
        (afterClose)="showCloudVideoPreviewPopup = false"
        [cloudPlayerConfig]="cloudPlayerConfig"
    ></app-cloud-video-preview>
    <app-mixed-stream-settings
        *ngIf="showStreamMixerPreviewPopup || mediaOrchestratorService.showStreamMixerPreviewPopup"
        (afterClose)="showStreamMixerPreviewPopup = false"
    ></app-mixed-stream-settings>
</div>
