import { Injectable } from '@angular/core';
import { addDays } from 'date-fns/esm';

@Injectable({
    providedIn: 'root'
})
export class RememberMeService {
    set(cookieName, value) {
        if (this.get(cookieName)) {
            this.remove(cookieName);
        }
        document.cookie = `${cookieName}=${JSON.stringify(value)}; expires=${addDays(
            new Date(),
            30
        )}; path=/; secure; domain=${location.hostname};`;
    }

    get(cookieName) {
        const name = cookieName + '=';
        const cookies = document.cookie.split(';');
        for (let cookie of cookies) {
            cookie = cookie.replace(/^\s+/g, '');
            if (cookie.indexOf(cookieName) === 0) {
                return JSON.parse(cookie.substring(name.length, cookie.length));
            }
        }
        return '';
    }

    remove(cookieName) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure; domain=${location.hostname};`;
    }
}
