import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-browser-test',
    templateUrl: './browser-test.component.html',
    styleUrls: ['./browser-test.component.scss']
})
export class BrowserTestComponent implements OnInit {
    isBrowserSupported = false;
    constructor(public utilService: UtilService) {
        this.isBrowserSupported = this.utilService.isRTCSupportedBrowser();
    }

    ngOnInit(): void {}
}
