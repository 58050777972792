import { Injectable } from '@angular/core';
import { VideoWrapperService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';

@Injectable({
    providedIn: 'root'
})
export class CallManager {
    videoService;
    callStateManager;
    mediaEngine;

    constructor(
        private videoWrapperService: VideoWrapperService,
        private callStateManagerService: CallStateManagerService
    ) {
        this.videoService = this.videoWrapperService;
        this.callStateManager = this.callStateManagerService;
        this.mediaEngine = this.callStateManagerService.currentMeeting.mediaEngine;
    }

    getVideoService() {
        return this.videoService;
    }

    getCallStateManager() {
        return this.callStateManager;
    }
}
