import { Injectable } from '@angular/core';
import { JmMediaService } from './jm-media.service';
import { MediaStreamRetrieverService } from './media-stream-retriever.service';
import { JmTranslateService } from './jm-translate.service';

declare const window;

@Injectable({
    providedIn: 'root'
})
export class SpeechRecognitionService {
    speechRecognitionInst: any;

    constructor(private jmMediaService: JmMediaService, private jmTranslateService: JmTranslateService) {}

    captureAudioFromMicrophone() {
        try {
            const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
            recognition.lang = 'en-US';
            recognition.interimResults = false;
            recognition.maxAlternatives = 1;
            recognition.lang = navigator.language || 'en-US';
            // recognition.continuous = true;
            // Add support for Hindi language
            if (navigator.language === 'hi-IN') {
                recognition.lang = 'hi-IN';
            }

            let startTime: number;
            let endTime: number;

            recognition.onstart = () => {
                console.log('Speech recognition service has started');
            };
            recognition.onend = () => {
                recognition.start();
                console.log('Speech recognition service has ended');
            };
            recognition.onerror = (event) => {
                console.error('Error occurred in recognition: ', event.error);
            };
            recognition.onspeechend = () => {
                // recognition.stop();
                endTime = new Date().getTime();
                console.log('Speech ended');
            };
            recognition.onspeechstart = () => {
                console.log('Speech detected');
                startTime = new Date().getTime();
            };

            recognition.onresult = (event) => {
                const speechResult = event.results[0][0].transcript;
                // endTime = new Date().getTime();
                this.handleSpeechResult({
                    transcription: speechResult,
                    startTime: startTime,
                    endTime: endTime || new Date().getTime()
                });
            };

            recognition.onaudiostart = () => {
                console.log('Audio capturing started');
            };

            recognition.onaudioend = () => {
                // recognition.start();
                console.log('Audio capturing ended');
            };

            recognition.onsoundstart = () => {
                console.log('Sound detected');
            };

            recognition.onsoundend = () => {
                endTime = new Date().getTime();
                console.log('Sound ended');
            };

            recognition.onnomatch = (event) => {
                console.log('No match found');
            };

            recognition.start();

            this.speechRecognitionInst = recognition;
        } catch (error) {
            console.error('Error initializing speech recognition: ', error);
        }
    }

    handleSpeechResult(speechResult) {
        console.log('Speech result: ', speechResult);
        this.jmMediaService.sendChatMessage({
            type: 'PublicChat',
            message: 'SPEAKER_TRANSCRIPTION',
            targetParticipantId: '',
            targetParticipantName: '',
            agoraShareUid: '',
            data: speechResult
        });
    }

    startSpeechRecognition() {
        if (this.speechRecognitionInst) {
            this.handleStartRecognitionOnEnd(true);
            this.speechRecognitionInst.start();
        } else {
            this.captureAudioFromMicrophone();
        }
    }

    stopSpeechRecognition() {
        if (this.speechRecognitionInst) {
            this.handleStartRecognitionOnEnd(false);
            this.speechRecognitionInst.stop();
            console.log('Speech recognition service has been Ended on Stop');
        }
    }

    handleStartRecognitionOnEnd(isStart) {
        this.speechRecognitionInst.onend = (event) => {
            if (isStart) {
                this.speechRecognitionInst.start();
                console.log('Speech recognition service has ended');
            } else {
                console.log('Speech recognition service has Ended on Stop');
            }
        };
    }
}
