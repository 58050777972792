import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DevicesManagerService {
    isMicPermission = false;
    isCameraPermission = false;
    deviceLoaded = false;
    cameraFailed;
    microphonesData;
    speakersData;
    cameras = [];
    microphones = [];
    speakers = [];
    AgoraRTC;
    constructor() {}
    async initializeAgoraRTC() {
        return import('agora-rtc-sdk-ng').then(async (m) => {
            this.AgoraRTC = m.default;
        });
    }

    async initiateMediaDevices() {
        try {
            await this.initializeAgoraRTC();
            await this.updateAllDevices();
            this.onDeviceChangeListener();
            this.deviceLoaded = true;
        } catch (error) {}
    }

    updateAllDevices() {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.AgoraRTC.getCameras().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.AgoraRTC.getMicrophones().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.AgoraRTC.getPlaybackDevices().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                })
            ])
                .then((data) => {
                    if (data[0]?.hasError) {
                        if (data[0]?.error?.code) {
                            this.cameraFailed = data[0]?.error?.code;
                        }

                        if (data[0]?.error?.code === 'PERMISSION_DENIED') {
                            this.isCameraPermission = false;
                        }
                    } else {
                        this.isCameraPermission = true;

                        this.cameras = data[0];
                    }
                    if (data[1]?.hasError) {
                        if (data[1]?.error?.code === 'PERMISSION_DENIED') {
                            this.isMicPermission = false;
                        }
                    } else {
                        this.isMicPermission = true;

                        this.microphonesData = data[1];
                        this.microphones = [];
                        this.microphonesData.forEach((microphone) => {
                            this.microphones.push(microphone);
                        });
                    }
                    if (data[2]?.hasError) {
                    } else {
                        this.speakersData = data[2];
                        this.speakers = [];
                        this.speakersData.forEach((speaker) => {
                            this.speakers.push(speaker);
                        });
                    }
                    this.cameras = this.mapDeviceCollection(this.cameras);
                    this.microphones = this.mapDeviceCollection(this.microphones);
                    this.speakers = this.mapDeviceCollection(this.speakers);
                })
                .catch((e) => {
                    resolve();
                })

                .finally(() => {
                    resolve();
                });
        });
    }

    onDeviceChangeListener() {
        this.AgoraRTC.onCameraChanged = async (info) => {
            this.cameras = this.mapDeviceCollection(await this.AgoraRTC.getCameras());
        };

        this.AgoraRTC.onMicrophoneChanged = async (info) => {
            this.microphones = [];
            this.microphonesData = this.mapDeviceCollection(await this.AgoraRTC.getMicrophones());
            this.microphonesData.forEach((microhpone) => {
                this.microphones.push(microhpone);
            });
        };

        this.AgoraRTC.onPlaybackDeviceChanged = async (info) => {
            this.speakers = [];
            this.speakersData = this.mapDeviceCollection(await this.AgoraRTC.getPlaybackDevices());
            this.speakersData.forEach((speaker) => {
                this.speakers.push(speaker);
            });
        };
    }

    mapDeviceCollection(arr) {
        return arr
            .map((device) => {
                return { ...device, id: device.deviceId, name: device.label, groupId: device.groupId };
            })
            .sort((device1, device2) => {
                let isDevice1Virtual = device1?.name?.toLowerCase().endsWith('(virtual)');
                let isDevice2Virtual = device2?.name?.toLowerCase().endsWith('(virtual)');
                if (isDevice1Virtual && !isDevice2Virtual) {
                    return 1;
                } else if (!isDevice1Virtual && isDevice2Virtual) {
                    return -1;
                } else return 0;
            });
    }

    get isMediaPermission() {
        return this.isCameraPermission && this.isMicPermission;
    }
}
