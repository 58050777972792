<div class="event-btn-wrapper" [class.isMobile]="isMobile">
    <button
        class="custom-button btn d-flex align-items-center {{ customClass }}"
        [id]="id"
        [class.bg-secondary]="!isPrimary"
        (click)="handleBtnClick($event)"
        [class.disabledBtn]="isDisabled"
    >
        <img [src]="imgPath" *ngIf="imgPath && prefixBtnIcon" alt="" class="icon" /><span>{{ btnText }}</span
        ><img [src]="imgPath" *ngIf="imgPath && !prefixBtnIcon" alt="" class="icon" />
    </button>
</div>
