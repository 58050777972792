import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ListboxModule } from 'primeng/listbox';
import { ConfirmationService } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { ProgressBarModule } from 'primeng/progressbar';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { SidebarModule } from 'primeng/sidebar';
import { InputNumberModule } from 'primeng/inputnumber';
import { UiScrollModule } from 'ngx-ui-scroll';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AngularResizedEventModule } from 'angular-resize-event';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { ScrollingModule } from '@angular/cdk/scrolling';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgOtpInputModule } from 'ng-otp-input';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgxColorsModule } from 'ngx-colors';

const primengModules = [
    DialogModule,
    ConfirmDialogModule,
    CalendarModule,
    DropdownModule,
    RatingModule,
    CheckboxModule,
    TableModule,
    TooltipModule,
    NgxColorsModule,
    OverlayPanelModule,
    ListboxModule,
    TabViewModule,
    AvatarModule,
    AvatarGroupModule,
    ProgressBarModule,
    PaginatorModule,
    ProgressSpinnerModule,
    SidebarModule,
    TabViewModule,
    BadgeModule,
    InputSwitchModule,
    InputNumberModule,
    MultiSelectModule
];

import * as fromPipes from './pipes';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { VirtualBackgroundComponent } from './components/call-settings/virtual-background/virtual-background.component';
import { CanvasBackdropComponent } from './components/canvas/canvas.component';
import { CompactViewFormatPipe } from './pipes/compact-view-format.pipe';
import { FloatingReactionService } from './services/floating-reaction.service';
import { NetworkingRoomAddParticipantsComponent } from './components/networking-room-add-participants/networking-room-add-participants.component';
import { NetworkingRoomTileComponent } from './components/networking-room-tile/networking-room-tile.component';
import { JoinCallComponent } from './components/join-call/join-call.component';
import { CountDownTimerPipe } from './pipes/count-down-timer.pipe';
import { EventStatsWrapperComponent } from './components/event-stats-wrapper/event-stats-wrapper.component';
import { DurationPipe } from './pipes/duration.pipe';
import { VideosecurePipe } from './pipes/videosecure.pipe';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { EventCardDateComponent } from './components/event-card-date/event-card-date.component';
import { MixedStreamSettingsComponent } from './components/mixed-stream-settings/mixed-stream-settings.component';
import { UserEmptyMessageComponent } from './components/user-empty-message/user-empty-message.component';
import { BoothTileComponent } from './components/booth-tile/booth-tile.component';
import { BoothAnalyticsPopupComponent } from './components/booth-analytics-popup/booth-analytics-popup.component';
import { LeaderboardWrapperComponent } from './components/leaderboard-wrapper/leaderboard-wrapper.component';
import { LeaderboardShareAchievementComponent } from './components/leaderboard-share-achievement/leaderboard-share-achievement.component';
import { RecurringEventsPopupComponent } from './components/recurring-events-popup/recurring-events-popup.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FlashCardsPopupComponent } from './components/flash-cards-popup/flash-cards-popup.component';
import { FlashCardComponent } from './components/flash-card/flash-card.component';
import { DevicemodalErrorComponent } from './components/devicemodal-error/devicemodal-error.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ViewallComponent } from './components/viewall/viewall.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            closeButton: true,
            includeTitleDuplicates: true,
            maxOpened: 1
        }),
        ToastContainerModule,
        ...primengModules,
        ImageCropperModule,
        ClipboardModule,
        AngularResizedEventModule,
        TranslateModule,
        ScrollingModule,
        DragDropModule,
        NgAddToCalendarModule,
        NgOtpInputModule,
        SwiperModule,
        UiScrollModule,
        CarouselModule
    ],
    declarations: [
        ...fromPipes.pipes,
        ...fromComponents.components,
        ...fromDirectives.directives,
        VirtualBackgroundComponent,
        CanvasBackdropComponent,
        CompactViewFormatPipe,
        NetworkingRoomAddParticipantsComponent,
        NetworkingRoomTileComponent,
        JoinCallComponent,
        CountDownTimerPipe,
        EventStatsWrapperComponent,
        DurationPipe,
        VideosecurePipe,
        ProfileDetailsComponent,
        EventCardDateComponent,
        MixedStreamSettingsComponent,
        MixedStreamSettingsComponent,
        UserEmptyMessageComponent,
        BoothTileComponent,
        BoothAnalyticsPopupComponent,
        LeaderboardWrapperComponent,
        LeaderboardShareAchievementComponent,
        RecurringEventsPopupComponent,
        RadioButtonComponent,
        SearchBarComponent,
        FlashCardsPopupComponent,
        FlashCardComponent,
        DevicemodalErrorComponent,
        DateFormatPipe,
        ViewallComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule,
        ToastContainerModule,
        ...fromPipes.pipes,
        ...fromComponents.components,
        ...fromDirectives.directives,
        ...primengModules,
        ImageCropperModule,
        ClipboardModule,
        AngularResizedEventModule,
        TranslateModule,
        DragDropModule,
        NgOtpInputModule,
        UiScrollModule,
        ScrollingModule
    ],
    providers: [NgxImageCompressService, FloatingReactionService, DatePipe, ConfirmationService]
})
export class SharedModule {}
