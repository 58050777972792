<div class="post-container d-flex flex-column">
    <div *ngIf="postDetails?.active" class="post-created-overlay"></div>
    <div class="post-header d-flex justify-space-between">
        <div class="post-header-left d-flex align-items-center w-100">
            <div class="participant-profile-pic">
                <div class="profile-wrap">
                    <div
                        *ngIf="!postCreator?.profilePicPath"
                        class="card-profile rounded-circle"
                        appAvatarWithInitials
                        [name]="postCreator?.creatorName"
                        [userId]="postCreator?.userId"
                    ></div>
                    <img
                        *ngIf="postCreator?.profilePicPath"
                        class="card-profile"
                        [src]="postCreator?.profilePicPath"
                        alt=""
                    />
                </div>
            </div>
            <div class="participant-creator-container d-flex flex-column">
                <div class="post-creator-name">
                    {{ postCreator?.creatorName }}
                </div>
                <div class="post-created-time" *ngIf="!isGetEvent || (isGetEvent && !isWinner)">
                    {{ timeTobeDisplayed }}
                </div>
                <div class="post-creator-details" *ngIf="isGetEvent && location && businessUnit && isWinner">
                    {{ businessUnit }} | {{ location }}
                </div>
            </div>
            <div
                (click)="showDeleteDialog()"
                class="delete-post"
                *ngIf="postCreator?.userId === currentUser._id && !isWinner && !isEnded"
                [class.mobile]="isMobile"
                pTooltip="Delete"
                tooltipPosition="top"
            >
                <img src="assets/img/delete-icn.svg" alt="" />
            </div>
            <div *ngIf="isWinner && rank < 4" class="rank rank-{{ rank }}">
                {{ rank }}
            </div>
            <div *ngIf="isWinner && rank >= 4" class="rank rank-4">
                {{ rank }}
            </div>
        </div>
    </div>
    <div class="post-body d-flex flex-column">
        <div class="post-caption mt-2 mb-2" [innerHtml]="messageComponent?.postMessage?.trim() | linkify"></div>
        <div *ngIf="mediaUrlList.length" class="post-media-wrapper grid--{{ mediaUrlList.length }}">
            <div
                *ngFor="
                    let media of mediaUrlList | slice: 0:(!showAllUploadedMedia ? 2 : mediaUrlList.length);
                    let i = index
                "
                class="media-container mt-2"
            >
                <img
                    *ngIf="media.attributes.mediaType == 'image'"
                    [src]="media.attributes.url"
                    alt=""
                    [class.show-opacity]="!showAllUploadedMedia && mediaUrlList.length > 2 && i == 1"
                    (click)="toggleDisplayMedia(media)"
                />
                <video
                    *ngIf="media.attributes.mediaType == 'video'"
                    [class.show-opacity]="!showAllUploadedMedia && mediaUrlList.length > 2 && i == 1"
                    controls
                    (click)="toggleDisplayMedia(media, $event); arguments[0].preventDefault()"
                >
                    <source [src]="media.attributes.url" />
                </video>
                <div
                    *ngIf="!showAllUploadedMedia && mediaUrlList.length > 2 && i == 1"
                    class="expand-images d-flex justify-content-between align-items-center"
                    (click)="showAllUploadedMedia = true"
                >
                    + {{ mediaUrlList.length - 2 }} more
                </div>
            </div>
        </div>
        <div
            *ngIf="showAllUploadedMedia && mediaUrlList.length > 2"
            class="d-flex justify-content-end view-less"
            (click)="showAllUploadedMedia = false"
        >
            <img src="assets/images/speaker-call-wrapper/chevron_up_arrow_call_controls.svg" alt="" />&nbsp;
            <span>View Less</span>
        </div>
    </div>
    <div class="post-footer d-flex justify-content-start mt-2">
        <div class="d-flex entry-margin">
            <div class="like-entry d-flex" (click)="toggleLike()">
                <img *ngIf="!postLiked" src="assets/images/like-button.png" alt="" />
                <img *ngIf="postLiked" src="assets/images/liked-button.svg" alt="" />
                <div *ngIf="messageComponent.likesCount > 0">
                    {{ messageComponent.likesCount }}
                </div>
                {{ messageComponent.likesCount > 1 ? 'Likes' : 'Like' }}
            </div>
            <div class="comments-entry d-flex" (click)="toggleCommentsSection()">
                <img src="assets/img/comment_icn.svg" alt="" />
                <div *ngIf="messageComponent.commentsCount > 0">
                    {{ messageComponent.commentsCount }}
                </div>
                {{ messageComponent.commentsCount > 1 ? 'Comments' : 'Comment' }}
            </div>
        </div>
        <form [formGroup]="commentsForm" (submit)="submitComment()" *ngIf="!isWinner && !isEnded">
            <div class="d-flex textarea-wrap">
                <div
                    class="img-wrap"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="navbarUserDropdown"
                >
                    <img [src]="commentProfilePicPath" alt="" *ngIf="profilePicPath" />
                    <div
                        *ngIf="!profilePicPath"
                        class="card-profile rounded-circle"
                        appAvatarWithInitials
                        [name]="currentUser?.firstName + ' ' + currentUser?.lastName"
                        [userId]="currentUser?._id"
                    ></div>
                </div>
                <textarea
                    id="commentTextarea"
                    appTextareaAutosize
                    rows="1"
                    [minRows]="1"
                    [maxRows]="4"
                    class="text-area"
                    placeholder="Add a comment"
                    formControlName="comments"
                    maxlength="256"
                    (focus)="commentsEditorFocusChanged()"
                    (keyup)="checkCharLimit($event)"
                    #commentTextarea
                >
                </textarea>
                <img
                    class="ml-2 pointer delete-img"
                    (click)="showDeleteDialogForComment()"
                    [class.moreThanOneLine]="inputRows > 1"
                    src="assets/img/delete-icn.svg"
                    height="16"
                    width="16"
                    alt=""
                    *ngIf="commentsForm.value.comments.length > 0"
                />
            </div>
            <div class="justify-content-end align-items-center comments-char-container">
                <div class="chars-left">{{ commentsForm.value.comments.length }} / {{ maxCharLimit }}</div>
                <img
                    class="d-flex ml-2 pointer send-img"
                    (click)="submitComment($event)"
                    [class.moreThanOneLine]="inputRows > 1"
                    src="assets/img/jioevents/send_btn_dark_theme.svg"
                    height="16"
                    width="16"
                    alt=""
                />
            </div>
        </form>
    </div>
    <div *ngIf="showCommentSection && messageComponent.commentsCount > 0" class="comment-section-wrapper">
        <app-feed-comment-section
            [webinarId]="webinarId"
            [contestId]="contestId"
            [parentData]="postDetails"
            [currentUser]="currentUser"
            [isEnded]="isEnded"
            (hideComments)="toggleCommentsSection($event)"
        ></app-feed-comment-section>
    </div>
    <div *ngIf="showOverlay" class="feed-overlay">
        <app-loader></app-loader>
    </div>
    <div class="delete-entry-popup" *ngIf="showDeletePopup" [class.mobile]="isMobile">
        <div class="bg"></div>
        <div class="content">
            <div class="header d-flex justify-content-end">
                <span class="close pointer">
                    <img src="assets/img/revamp/pop_cross.svg" alt="" (click)="showDeletePopup = false" />
                </span>
            </div>
            <div class="body">
                <div class="title">{{ dialogData.title }}</div>
                <div class="subtitle">{{ dialogData.message }}</div>
            </div>
            <div class="footer">
                <div class="options d-flex justify-content-end">
                    <button class="btn custom-button bg-secondary" (click)="showDeletePopup = false">Cancel</button>
                    <button class="btn custom-button bg-primary" (click)="deleteEntry(dialogData.type)">
                        {{ dialogData.buttonText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
