import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DiagnosticService } from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import * as _ from 'lodash';
import { interval } from 'rxjs/internal/observable/interval';
import { WebinarService } from 'src/app/dashboard-webinars/services/webinar.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { DASHBOARD } from '../../../constants/gtm-events';

@Component({
    selector: 'app-previous-events-download-popup',
    templateUrl: './past-events-download-popup.component.html',
    styleUrls: ['./past-events-download-popup.component.scss']
})
export class PastEventsDownloadPopupComponent implements OnInit {
    @Output() toggle: EventEmitter<boolean> = new EventEmitter();
    @Input() recordinglist;
    @Input() meeting;
    @Input() isMobile;
    @Input() type;
    @Input() parentMeetingRecordingInfo;
    @Input() loadingRecordings = false;
    sessionDetails;
    interval$: any = {};
    tableData;
    isSessionEnabled = false;
    constructor(
        private diagnosticService: DiagnosticService,
        private webinarService: WebinarService,
        private appLoggerService: AppLoggerService,
        private gooleTagMangerService: GoogleTagMangerService
    ) {}

    ngOnInit(): void {
        this.isSessionEnabled = _.get(this.meeting, 'webinar.sessions.enabled');
        // this.getSessionsDetails(this.meeting?._id);
    }

    getSessionsDetails(meetingId) {
        if (this.isSessionEnabled) {
            this.webinarService.getWebinarSessionsInfo(meetingId).subscribe(
                (res: any) => {
                    this.sessionDetails = res?.sessions;
                    this.tableData = this.type === 'reports' ? this.sessionDetails : this.recordinglist;
                },
                (err) => {
                    this.appLoggerService.error('Error while fetching the session details', err);
                }
            );
        } else {
            this.tableData = this.recordinglist;
        }
        // this.loadingRecordings = false;
    }

    downloadReport(session) {
        const timestamp = new Date().valueOf();
        this.interval$[timestamp] = interval(1 * 1000).subscribe(() => {
            this.webinarService.getDownloadPollsReport(session.meetingId, this.meeting.startTime).subscribe((res) => {
                if (res.downloadUrl) {
                    this.downloadFile(res.downloadUrl);
                    this.interval$[timestamp].unsubscribe();
                }
            });
        });
    }

    downloadFile(url) {
        const a = document.createElement('a');
        a.href = encodeURI(url);
        a.download = 'details.csv';
        a.click();
    }

    downloadRecording(url) {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Jioconf Past Event',
                eventAction: 'Download Recording',
                eventType: 'app_event',
                endpoint: 'recording/bymeeting',
                status: 'success'
            })
            .subscribe();

        this.gooleTagMangerService.pushGoogleTagData(DASHBOARD.DOWNLOAD_RECORDINGS);
        window.open(url, '_blank');
    }

    downloadAllRecording() {
        this.downloadRecording(this.parentMeetingRecordingInfo.url);
    }

    toggleRecordingListPopup() {
        this.toggle.emit();
    }

    cancel() {
        this.toggle.emit();
    }
}
