import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { VIRTUALEVENTS } from '../../../constants/gtm-events';
import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';

@Component({
    selector: 'app-virtual-events',
    templateUrl: './virtual-events.component.html',
    styleUrls: ['./virtual-events.component.scss']
})
export class VirtualEventsComponent implements OnInit {
    loginPage: any = '/login';
    contactUsPage: any = '/contactus';
    constructor(private appService: AppService, private googleTagManager: GoogleTagMangerService) {
        this.loginPage = this.appService.getLoginPageUrl();
    }
    useCases: any = [
        {
            img: 'ic-subscriptions@2x.png',
            title: 'Registrations',
            eventKey: 'REGISTRATIONS'
        },
        {
            img: 'ic-voucher-cashback@2x.png',
            title: 'Ticketing',
            eventKey: 'TICKETING'
        },
        {
            img: 'ic-tv-plus@2x.png',
            title: 'HD media sharing',
            eventKey: 'MEDIASHARING'
        },
        {
            img: 'ic-graph-table@2x.png',
            title: 'Polls',
            eventKey: 'POLLS'
        },
        {
            img: 'ic-faq@2x.png',
            title: 'Q&A',
            eventKey: 'QANDA'
        },
        {
            img: 'ic-chat@2x.png',
            title: 'Chat',
            eventKey: 'CHAT'
        },
        {
            img: 'ic-smss@2x.png',
            title: 'Comments',
            eventKey: 'COMMENTS'
        },
        {
            img: 'ic-like@2x.png',
            title: 'Reactions',
            eventKey: 'REACTIONS'
        },
        {
            img: 'ic-gaming-controllers-1@2x.png',
            title: 'Apps',
            eventKey: 'APPS'
        },
        {
            img: 'ic-warehouse@2x.png',
            title: 'Booths',
            eventKey: 'BOOTHS'
        },
        {
            img: 'ic-sofa@2x.png',
            title: 'Virtual Lounge',
            eventKey: 'LOUNGE'
        },
        {
            img: 'ic-armed@2x.png',
            title: 'Security',
            eventKey: 'SECURITY'
        },
        {
            img: 'ic-analytics@2x.png',
            title: 'Analytics',
            eventKey: 'ANALYTICS'
        }
    ];
    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.VIRTUAL_EVENT_PAGE_VIEW);
    }
    redirectToLogin() {
        window.location.href = this.loginPage;
    }
    redirectToContactUs() {
        window.location.href = this.contactUsPage;
    }
    getstarted() {
        this.googleTagManager.pushGoogleTagData(VIRTUALEVENTS.GET_STARTED);
        this.redirectToContactUs();
    }
    exploreNow() {
        this.googleTagManager.pushGoogleTagData(VIRTUALEVENTS.EXPLORE_NOW);
        this.redirectToLogin();
    }
    usecaseClick(item) {
        this.googleTagManager.pushGoogleTagData(VIRTUALEVENTS[item.eventKey]);
    }
}
