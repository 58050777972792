import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';

import { interval, Subscription } from 'rxjs';
import LazyLoad from 'vanilla-lazyload';

declare const window: any;

import {
    EventEmitterService,
    EventData,
    AppService,
    LoggerService,
    SocketService,
    SocketEvent,
    AuthService,
    UserService,
    GuestAuthService,
    RouterService,
    RouteInfo,
    UtilService,
    RtcService,
    FullscreenService,
    ExternalInterfaceService,
    LocalStorageService
} from './core';

import { APP_EVENTS, SOCKET_EVENTS, WEB_CONSTANT } from './constants';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { SocketEventsHandlerService } from './core/services/socket-events-handler.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('header') headerElement: ElementRef;

    showSessionExpiryDialog = false;

    showHeader = false;
    showFooter = false;
    showInvite = false;
    queryParams: any = {};
    loader = true;
    disableSocketConnection = false;
    showIncomingCallPopup = false;
    enableJoinWithBrowser = false;
    addParticipantsInCallEnabled;
    isMobileOS: boolean = false;
    hasTransparentHeader: boolean = false;
    isAuthenticated: boolean = false;
    isAttendeePath = false;
    isStaticPage: boolean = false;
    isMyJio: boolean;
    // staticPages: any = WEB_CONSTANT.STATIC_PAGES;
    useOldHeaderVersion: boolean = false;
    scroll: number = 0;
    lastscrollTop: number = 0;

    staticPages: any = [...WEB_CONSTANT.STATIC_PAGES];
    isMobileMenuBarActive = false;
    isIosMobile = false;
    currentPage = 'home';
    isMediaPlayer: boolean;
    isDashboard: boolean = false;
    private isLandingPageLoaded: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private authService: AuthService,
        private logger: LoggerService,
        private socketService: SocketService,
        private eventEmitterService: EventEmitterService,
        private userService: UserService,
        private guestAuthService: GuestAuthService,
        private routerService: RouterService,
        public utilService: UtilService,
        private translateService: TranslateService,
        private rtcService: RtcService,
        private fullscreenService: FullscreenService,
        private externalInterfaceService: ExternalInterfaceService,
        private toastrService: ToastrService,
        private titleService: Title,
        private metaService: Meta,
        private localStorageService: LocalStorageService,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this.titleService.setTitle(this.appService.getAppTitle());
        if (this.appService.isJioEvents()) {
            this._document
                .getElementById('appFavicon')
                .setAttribute('href', './assets/img/jioevents/logos/logo_favicon.png');
        }

        this.translateService.setDefaultLang('en');

        this.isMobileOS = this.utilService.isResponsiveMobileView();
        this.activatedRoute.queryParams.subscribe((params) => {
            this.isMyJio = params?.isMyJio ? true : false;
        });
        window.lazyLoadInstance = new LazyLoad();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.isDashboard = this.utilService.isDashboard(event.url);
            this.utilService.showRibbon = !this.isBetaRoute(event.url);
            this.utilService.isOldVersion = !this.isBetaRoute(event.url);
        });
    }
    private isBetaRoute(url: string): boolean {
        return (
            url.includes('/dashboard') ||
            url.includes('/home') ||
            url.includes('/pricing') ||
            url.includes('/contactus') ||
            url.includes('/explore-events') ||
            url.includes('/faqs') ||
            url.includes('/solutions') ||
            url.includes('/customer') ||
            url.includes('/profile') ||
            url.includes('/attendee') ||
            url.includes('/terms-condition-business') ||
            url.includes('/privacy-policy-business') ||
            url.includes('/events-studio') ||
            url.includes('/marketplace') ||
            url.includes('/studio')
        );
    }
    listScroll(event) {
        let currentScrollTop = event.target.scrollTop;
        var navbar = document.getElementById('navbar1');
        this.scroll = window.pageYOffset;
        if (
            window.location.pathname === '/terms-condition-business' ||
            window.location.pathname === '/privacy-policy-business'
        ) {
            navbar?.classList.add('darkHeader');
            this.utilService.hideHeaderView = false;
        } else {
            if (currentScrollTop <= 0 || this.lastscrollTop > currentScrollTop) {
                navbar?.classList.add('darkHeader');
                this.utilService.hideHeaderView = false;
            } else {
                navbar?.classList.remove('darkHeader');
                this.utilService.hideHeaderView = true;
            }
        }
        this.lastscrollTop = currentScrollTop;
    }

    ngOnInit() {
        this.isLandingPageLoaded = this.utilService.loading$.subscribe((loading) => {
            this.showFooter = loading;
        });
        if (window.location.pathname === '/home') {
            this.useOldHeaderVersion = false;
        } else {
            this.useOldHeaderVersion = true;
        }
        const currentHref = window.location.href;
        if (currentHref.includes('/myjio') || currentHref.includes('isMyJio')) {
            window.profilingInfo.enteredAppCompTime = Date.now();
            this.isMyJio = true;
            this.appService.isMyJio = true;
        }
        if (window.location.href.includes('/mediaplayer')) {
            this.isMediaPlayer = true;
        }
        if (window.location.href.includes('jiopc')) {
            this.localStorageService.addItem('source', 'jiopc');
            this.utilService.isJioPc = true;
        }

        this.addParticipantsInCallEnabled = this.appService.getConfigVariable('ENABLE_ADD_PARTICIPANTS_IN_CALL');
        // this.useOldHeaderVersion = this.appService.getConfigVariable('USE_OLD_HEADER_VERSION');
        this.enableJoinWithBrowser = this.appService.getConfigVariable('ENABLE_WEBRTC');
        this.activatedRoute.queryParams.subscribe((params) => (this.queryParams = params));
        this.appService.setAppQueryParams(new URLSearchParams(location.search));
        this.logger.setLogging(this.appService.getConfigVariable('LOGS_ENABLED'));
        this.externalInterfaceService.setupCallbacksFromNative();
        this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe((route) => {
            if (route.url.includes('attendee')) {
                this.isAttendeePath = true;
                // TODO: change the speaker url link, currently speaker's url has attendee in it
            }
            this.setHeaderOptions(route.url);
        });

        this.disableSocketConnection =
            new URLSearchParams(window.location.search).get('disableSocketConnection') === 'true' ? true : false;

        if (window.location.href.includes('/outlook/')) {
            this.disableSocketConnection = true;
        }

        const lang = new URLSearchParams(window.location.search).get('lan');
        // this.translateService.use(this.appService.getAndSaveUserPreferredLang(lang));
        this.translateService.use('en');
        this.eventEmitterService.subscribe((event: EventData) => {
            if (this.disableSocketConnection) {
                return;
            }
            if (event.type === APP_EVENTS.SESSION_EXPIRED || event.type === APP_EVENTS.LOGOUT) {
                // this.showSessionExpiryDialog = true;
                this.socketService.dispose();
                if (this.appService.isMyJio) {
                    this.router.navigate(['/integrations/myjio/home']);
                } else if (event.data.navigateToLogin && !event.data.persistPage) {
                    this.router.navigate(['/login'], { queryParams: this.queryParams });
                }
            } else if (event.type === APP_EVENTS.AUTHORIZED) {
                // We have to delay it for sometime because this event is called before navigation end event.
                setTimeout(() => {
                    if (this.authService.getAuthInfo()?.jwt || this.guestAuthService.getAuthInfo()?.jwt) {
                        // sockets should be enabled for attendees also, for the purpose of networking rooms
                        // or control the number of socket requests?
                        // TODO: or make this available based on networking rooms toggle
                        this.socketService.connect({
                            url: this.appService.getConfigVariable('WS_URL'),
                            jwt: this.authService.getAuthInfo()?.jwt || this.guestAuthService.getAuthInfo()?.jwt,
                            heartBeatRate: this.appService.getConfigVariable('PRESENCE_INTERVAL')
                        });
                    }
                }, 2000);
            } else if (event.type === APP_EVENTS.MOBILE_HEADER_MENU_CLICK) {
                this.isMobileMenuBarActive = !this.isMobileMenuBarActive;
            }
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.setHeaderOptions(this.router.routerState.snapshot.url);
            }
        });

        // this.socketEventsHandlerService.handleSocketEvents();

        const authInfo = this.authService.getAuthInfo();
        if (authInfo) {
            this.authService.setAuthInfo(authInfo);
            this.userService.getUser().subscribe(
                (res) => {
                    this.loader = false;
                },
                (err) => {
                    this.loader = false;
                    if (err.status === 401) {
                        this.authService.cleanup();
                        if (this.appService.isMyJio) {
                            this.router.navigate(['/integrations/myjio/home']);
                        } else {
                            this.router.navigate(['/login']);
                        }
                    }
                }
            );
        } else {
            this.loader = false;
        }

        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.SHOW_INVITE_POPUP) {
                this.showInvite = true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.routerService.listen();
        this.routerService.getRouterState().subscribe((state: RouteInfo) => {
            const ele = document.getElementsByClassName('app-container')[0];
            this.showHeader = typeof state.data.showHeader === 'undefined' ? true : state.data.showHeader;
            this.showFooter = typeof state.data.showFooter === 'undefined' ? false : state.data.showFooter;
            this.hasTransparentHeader =
                typeof state?.data?.hasTransparentHeader === 'undefined' ? false : state?.data?.hasTransparentHeader;
            if (ele) {
                ele.scrollTo(0, 0);
                const scrollable = ele.querySelector('.scrollable');
                if (scrollable) {
                    scrollable.scrollTo(0, 0);
                }
            }
        });

        this.fullscreenService.registerWindowEvents();
        this.handleWindowEvents();
        this.addMetaTags();
        this.isIosMobile = this.utilService.isMobileIOSBrowser();
    }

    addMetaTags() {
        let metaTags = this.appService.getConfigVariable('META_TAGS')
            ? this.appService.getConfigVariable('META_TAGS')
            : WEB_CONSTANT.METATAGS;
        let metaTagDescription = this.appService.getConfigVariable('META_DESCRIPTION')
            ? this.appService.getConfigVariable('META_DESCRIPTION')
            : WEB_CONSTANT.META_DESCRIPTION;
        this.metaService.updateTag({ name: 'keywords', content: metaTags });
        this.metaService.updateTag({
            name: 'description',
            content: metaTagDescription
        });
    }

    handleWindowEvents() {
        window.addEventListener('online', (event) => {
            this.socketService.reconnect();
        });
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if (event.key === 'jmc.userLang') {
                // this.translateService.use(this.appService.getAndSaveUserPreferredLang());
                this.translateService.use('en');
            }
        });

        window.addEventListener('offline', (e) => {
            if (window.location.href.includes('jioconf/attendee')) {
                this.toastrService.info('Oops! There seems to be a network error. Please try again', '', {
                    positionClass: 'toast-top-center'
                });
            }
            if (window.location.href.includes('conference/call')) {
                this.toastrService.info('Looks like your internet is down! Trying to reconnect', '', {
                    positionClass: 'toast-top-center'
                });
            }
        });
    }

    handleSocketEvents(event: SocketEvent) {
        //incoming call is currently being handled in webrtc repo..
        if (
            event.event === SOCKET_EVENTS.CALL &&
            this.enableJoinWithBrowser &&
            this.addParticipantsInCallEnabled &&
            (!this.utilService.isMobileBrowser() || this.appService.getConfigVariable('ENABLE_CALLS_MOBILE_DEVICES')) &&
            this.appService.getConfigVariable('DISPLAY_INCOMING_POPUP')
        ) {
            const alreadyHaveAnIncomingCall = !!this.rtcService.getIncomingCallMeeting();
            if (alreadyHaveAnIncomingCall || this.router.url === '/conference/call') {
                this.rtcService.sendInAnotherCallNotification(event.data).subscribe();
                return;
            }
            this.rtcService.setIncomingCallMeeting(event.data);
            this.showIncomingCallPopup = true;
        }
    }

    handleCloseInviteModal() {
        this.showInvite = false;
    }

    navigateToLogin() {
        this.showSessionExpiryDialog = false;
        this.router.navigate(['login']);
    }

    toggleIncomingCallPopup(forceClose = false) {
        if (forceClose) {
            this.showIncomingCallPopup = false;
        } else {
            this.showIncomingCallPopup = !this.showIncomingCallPopup;
        }
    }

    reveal() {
        console.log('scroll event');
    }

    setHeaderOptions(route) {
        this.currentPage = '';
        if (route.includes('dashboard') || window.location.href.includes('dashboard')) {
            // this.isStaticPage = this.utilService.isResponsiveMobileView(1180);
            this.currentPage = 'dashboard';
            return;
        }
        if (this.staticPages.includes(route)) {
            this.isStaticPage = true;
        } else {
            this.isStaticPage = false;
        }
        console.log('this', this.isStaticPage, route);
    }

    ngOnDestroy() {
        this.socketService.dispose();
        this.showIncomingCallPopup = false;
        this.fullscreenService.unregisterDocumentListener();
        this.isLandingPageLoaded.unsubscribe();
    }
}
