import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { RestService } from './rest.service';
import { AppService } from './app.service';
import { UserService } from './user.service';
import { GaDiagnosticService } from './ga-diagnostic.service';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticService {
    currentUser;
    constructor(
        private restService: RestService,
        private appService: AppService,
        private userService: UserService,
        private gaService: GaDiagnosticService
    ) {}

    sendEvent({ eventCategory = '', eventAction = '', eventType = '', endpoint = '', status = '', data = {} }) {
        this.currentUser = this.userService.getUserSync();
        let headers: HttpHeaders = new HttpHeaders();

        const api_key = this.appService.getEnvVariable('DIGNOSTIC_API_KEY');
        const api_secret = this.appService.getEnvVariable('DIGNOSTIC_API_SECRET');

        headers = headers.set('X-Jm-ApiKey', api_key);
        headers = headers.set('X-Jm-ApiSecret', api_secret);

        this.gaService.sendEvent({ action: eventAction, category: eventCategory });

        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/diagnostic`,
            {
                eventCategory,
                eventAction,
                eventType,
                userId: this.currentUser._id ? this.currentUser._id : 'Guest',
                endpoint,
                status,
                data
            },
            { headers }
        );
    }

    // eventType should be app_event app_exception api_failure  (in case of api success it will be considered in app_event)
    // enpoint is api end point
    // data will be key value pair
    // status will be success/error in case of api and empty in case of app events
}
