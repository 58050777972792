<div
    class="loader-component"
    [ngClass]="{
        'position-relative': position == 'relative',
        'position-fixed': position == 'fixed',
        'mobile-loader': mobileLoader
    }"
>
    <img src="assets/img/events_loader.gif" alt="" />
</div>
