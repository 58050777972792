import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/core';

@Component({
    selector: 'app-languagesettings',
    templateUrl: './languagesettings.component.html',
    styleUrls: ['./languagesettings.component.scss']
})
export class LanguagesettingsComponent implements OnInit {
    lang;
    constructor(private appService: AppService, private translate: TranslateService) {}

    ngOnInit(): void {
        this.lang = this.appService.getAndSaveUserPreferredLang();
    }

    setLan(lang) {
        this.appService.getAndSaveUserPreferredLang(lang);
        this.translate.use(lang);
    }
}
