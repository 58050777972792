import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { EventEmitterService } from './event-emitter.service';
import { RC_EVENTS } from './../../constants';
import { LocalStorageService } from './local-storage.service';

import { APP_EVENTS } from '../../constants';
import { UtilService } from './util.service';

declare const window: any;
declare const webkit: any;

@Injectable({
    providedIn: 'root'
})
export class ExternalInterfaceService {
    public subject$: Subject<any> = new Subject();
    public isEmbibe = false;
    public cameraLabel = '';
    public micLabel = '';
    public speakerLabel = '';

    constructor(
        private zone: NgZone,
        private router: Router,
        private authService: AuthService,
        private logger: LoggerService,
        private eventEmitterService: EventEmitterService,
        private localStorageService: LocalStorageService,
        private utilService: UtilService
    ) {}

    share(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'share',
                    desc: data
                })
            )
        );
    }

    help(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'help',
                    desc: data
                })
            )
        );
    }

    playSound(audioObj) {
        this.stopSound();
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'playinternalsound',
                    path: audioObj.path || null,
                    loop: audioObj.loop || false,
                    name: audioObj.name || null
                })
            )
        );
    }

    stopSound() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'stopsound'
                })
            )
        );
    }

    launchBrowser(url) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'launchbrowser',
                    value: url
                })
            )
        );
    }

    screenOrientation(orientation) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'screen_orientation',
                    value: orientation
                })
            )
        );
    }

    eventRegisteredMyjio(webinarDetail) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'CleverTapEventTrigger',
                    value: {
                        eventName: 'events_registered',
                        eventPrameters: {
                            action: webinarDetail.eventType,
                            label: '',
                            additionalInfo: ''
                        }
                    }
                })
            )
        );
    }

    eventRegisteredMyjioGA(webinarDetail) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'web_ga',
                    value: 'click',
                    gaTag: {
                        category: 'events_registered',
                        action: webinarDetail.eventType,
                        label: '',
                        additional_info: ''
                    }
                })
            )
        );
    }

    joinEventMyjio(webinarDetail) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'CleverTapEventTrigger',
                    value: {
                        eventName: 'events_attended',
                        eventPrameters: {
                            action: webinarDetail.eventType,
                            label: '',
                            additionalInfo: ''
                        }
                    }
                })
            )
        );
    }

    joinEventMyjioGA(webinarDetail) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'web_ga',
                    value: 'click',
                    gaTag: {
                        category: 'events_attended',
                        action: webinarDetail.eventType,
                        label: '',
                        additional_info: ''
                    }
                })
            )
        );
    }

    headerToggleAndroid(showhide) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'header_VISIBILITY',
                    value: showhide
                })
            )
        );
    }

    headerToggleIOS(showhide) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'header_visibility',
                    value: showhide
                })
            )
        );
    }

    sendLoadComplete() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'loadingCompleted'
                })
            )
        );
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('loadingCompleted', '*');
        }
    }

    close() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'close'
                })
            )
        );
    }

    requestJWT() {
        console.log('requested jwt');
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'jwt'
                })
            )
        );
    }

    requestMicrophone() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'microphone',
                    config: {
                        type: 'speech',
                        language: 'en'
                    }
                })
            )
        );
    }

    makeCall(value) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'makeCall',
                    value
                })
            )
        );
    }

    requestScreenshot() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'screenshot'
                })
            )
        );
    }

    requestForUserPermission(permission) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'requestForUserPermission',
                    value: permission
                })
            )
        );
    }

    requestAdParams() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'adparams'
                })
            )
        );
    }

    getNativeVersion(permission) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'getNativeVersion'
                })
            )
        );
    }

    shareReports() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'shareReports'
                })
            )
        );
    }

    showMyReports() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'showMyReports'
                })
            )
        );
    }

    showMyDetails() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'showMyDetails'
                })
            )
        );
    }

    showParticipants() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'showParticipants'
                })
            )
        );
    }

    requestForCameraAndMicrophone() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'requestForCameraAndMicrophone'
                })
            )
        );
    }

    requestForCreateWatchParty() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'requestForCreateWatchParty'
                })
            )
        );
    }

    sendEventToRefreshJWT() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'refreshJWT'
                })
            )
        );
    }

    sendVolumeDetails(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'userVolumeStatus',
                    data
                })
            )
        );
    }

    private externalCall(data) {
        try {
            if (window.chrome?.webview) {
                // windows
                window.chrome?.webview?.postMessage(data);
            } else {
                if (window.android && window.android.__externalCall) {
                    // android
                    window.android.__externalCall(data);
                }
                if (
                    window.webkit &&
                    window.webkit.messageHandlers &&
                    window.webkit.messageHandlers.toggleMessageHandler
                ) {
                    window.webkit.messageHandlers.toggleMessageHandler.postMessage(data);
                }
                webkit.messageHandlers.callback.postMessage(data);
            }
            if (window.__externalCall) {
                // iOS
                window.__externalCall(data);
            }
        } catch (e) {
            this.logger.error('external call failed');
        }
    }

    sendNativeBackControl(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'handleWebviewBack',
                    value: data
                })
            )
        );
    }

    endCallFunctionality() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'endmeeting'
                })
            )
        );
        window.onCallEnd();
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('callEnded', '*');
        }
    }

    sendCallEnd() {
        this.endCallFunctionality();
    }

    sendParticipantListUpdatedEvent() {
        console.log('participant list updated ');
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('participantListUpdated', '*');
        }
    }

    sendMicStatus(micStatus) {
        console.log('micstatus  updated ');
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ micStatus }), '*');
        }
    }

    sendCameraStatus(cameraStatus) {
        console.log('camera status updated ');
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ cameraStatus }), '*');
        }
    }

    sendHardMuteAudiostatus(isForceMute) {
        console.log('force mute status updated');
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ isForceMute }), '*');
        }
    }

    sendErrorOccuredOnJoined(error) {
        console.log('error Occured');
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('errorOccurred', '*');

            window.parent.postMessage(JSON.stringify({ error }), '*');
        }
    }

    sendLocalParticipantUserId(jiomeetUserId) {
        console.log('local participant details sent');
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ jiomeetUserId }), '*');
        }
    }

    sendChatMessageRecieved() {
        console.log('chat message recieved');
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('chatMessageRecieved', '*');
        }
    }

    sendCallEndWatchParty(meetingId, password) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'endmeeting',
                    data: { meetingId: meetingId, password: password }
                })
            )
        );
        window.onCallEnd();
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('callEnded', '*');
        }
    }

    sendCallStarted(userId = '') {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'callstarted'
                })
            )
        );
        window.onCallJoined();
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('callStarted', '*');
            if (userId !== '') {
                window.parent.postMessage(
                    JSON.stringify({
                        type: 'userIdJoined',
                        userId: userId
                    }),
                    '*'
                );
            }
        }
    }

    sendInvite(meetingId, password) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'invite',
                    data: { meetingId: meetingId, password: password }
                })
            )
        );
    }

    cycleNativeSpeaker() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'cyclespeaker'
                })
            )
        );
    }
    toggleFullScreen() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'togglefullscreen'
                })
            )
        );
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('togglefullscreen', '*');
        }
    }

    blockGuestUsers() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'blockguestusers'
                })
            )
        );
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('blockguestusers', '*');
        }
    }

    bindEvent(element, eventName, eventHandler) {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }

    sendUserStatus(isAudience) {
        console.log('status of the user is ', {
            type: 'userStatus',
            status: isAudience ? 'audience' : 'speaker'
        });
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'userStatus',
                    status: isAudience ? 'audience' : 'speaker'
                })
            )
        );
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(
                JSON.stringify({
                    type: 'userStatus',
                    status: isAudience ? 'audience' : 'speaker'
                }),
                '*'
            );
        }
    }

    public setupCallbacksFromNative() {
        window.windowsTest = () => {
            this.externalCall(
                btoa(
                    JSON.stringify({
                        type: 'windowsTest'
                    })
                )
            );
        };

        window.sendJwt = (jwt) => {
            this.zone.run(() => {
                console.log('jwt', jwt);
                let payload = jwt;
                // if (typeof jwt === 'string') payload = JSON.parse(jwt);
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JWT_TOKEN,
                    data: payload
                });
            });
        };

        window.sendJWT = (jwt) => {
            this.zone.run(() => {
                console.log('jwt', jwt);
                let payload = jwt;
                // if (typeof jwt === 'string') payload = JSON.parse(jwt);
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JWT_TOKEN,
                    data: payload
                });
            });
        };

        window.hhMeetingDetails = (data) => {
            console.log(data);
            //alert(data);
            // take "phoneNo", "historyId" and "token"
            // send token for login
            // then historyId and phoneNo to API call
            this.eventEmitterService.emit({
                type: 'JOIN_WITH_HISTORY_ID',
                data: JSON.parse(data)
            });
        };

        window.joinMeetingWithMeetingId = (data) => {
            console.log(data);
            this.eventEmitterService.emit({
                type: 'JOIN_WITH_MEETING_ID',
                data: JSON.parse(data)
            });
        };

        window.permissionGranted = () => {
            console.log('received user permission granted');
            this.eventEmitterService.emit({
                type: 'USER_PERMISSION_GRANTED',
                data: {}
            });
        };

        window.printAgoraDetails = () => {
            console.log('received print agora details');
            this.eventEmitterService.emit({
                type: 'PRINT_AGORA_DETAIL',
                data: {}
            });
        };

        window.toggleCameraAndAudioPrivacyForJanus = () => {
            console.log('toggle Camera Privacy for Janus');
            this.eventEmitterService.emit({
                type: 'JANUS_TOGGLE_CAMERA_AND_AUDIO_PRIVACY',
                data: {}
            });
        };

        window.sendJwtWebinar = (jwt) => {
            this.authService.setAuthInfo(JSON.parse(JSON.stringify(jwt)));
            this.eventEmitterService.emit({
                type: 'WEBINAR_JWT_UPDATED',
                data: {}
            });
        };
        window.leaveWebinar = () => {
            this.eventEmitterService.emit({
                type: 'LEAVE_WEBINAR',
                data: {}
            });
        };
        window.clearJwt = () => {
            if (!this.utilService.inIframe()) {
                this.localStorageService.removeItem('userInfo');
            }
        };

        window.reloadPage = () => {
            window.location.reload();
        };

        window.receiveNativeNavigation = (data) => {
            const { navigationType } = JSON.parse(data);
            switch (navigationType) {
                case 'FORWARD': {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NATIVE_FORWARD_BUTTON,
                        data: JSON.parse(data)
                    });
                    break;
                }
                default: {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NATIVE_BACK_BUTTON,
                        data: JSON.parse(data)
                    });
                }
            }
        };

        window.receiveAppInBackground = (data) => {
            this.eventEmitterService.emit({
                type: APP_EVENTS.APP_IN_BACKGROUND,
                data
            });
        };

        window.sendJwtWithoutNavigation = (data) => {
            let payload = data;
            if (typeof data === 'string') payload = JSON.parse(data);
            this.eventEmitterService.emit({
                type: APP_EVENTS.JWT_TOKEN,
                data: payload
            });
        };

        window.ADSDKEXT = {};
        window.ADSDKEXT.click_handler = (url) => this.launchBrowser(url);
        window.onCallEnd = () => {};
        window.onCallJoined = () => {};
        window.sendRCEvent = (event) => this.handleRCEvents(event);

        this.bindEvent(window, 'message', (e) => {
            let data: any = {};
            try {
                data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
                console.log('[JSON]data received over postMessage: ', data);
            } catch (error) {
                console.log('[NON-JSON]data received over postMessage ', e.data);
            }

            if (this.handleRCMessage(data)) {
                return;
            }

            if (data.type === 'SEND_JWT') {
                this.authService.setAuthInfo({ jwt: data.jwt });
            } else if (data.type === 'JOIN_WITH_MEETING_ID') {
                this.eventEmitterService.emit({
                    type: 'JOIN_WITH_MEETING_ID',
                    data: data
                });
            } else if (data.type === 'toggleAudio') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_AUDIO',
                    data: {}
                });
            } else if (data.type === 'toggleVideo') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_VIDEO',
                    data: {}
                });
            } else if (data.type === 'showSelfView') {
                this.eventEmitterService.emit({
                    type: 'SHOW_SELF_VIEW',
                    data: data.show
                });
            } else if (data.type === 'toggleParticipantList') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_PARTICIPANT_LIST',
                    data: {}
                });
            } else if (data.type === 'toggleChat') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_CHAT',
                    data: {}
                });
            } else if (data.type === 'startScreenShare') {
                this.eventEmitterService.emit({
                    type: 'START_SCREEN_SHARE',
                    data: {}
                });
            } else if (data.type === 'startWhiteboardShare') {
                this.eventEmitterService.emit({
                    type: 'START_WHITEBOARD_SHARE',
                    data: {}
                });
            } else if (data.type === 'stopSharing') {
                this.eventEmitterService.emit({
                    type: 'STOP_SHARING',
                    data: {}
                });
            } else if (data.type === 'toggleHand') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_HAND',
                    data: {}
                });
            } else if (data.type === 'leaveMeeting') {
                this.eventEmitterService.emit({
                    type: 'LEAVE_MEETING',
                    data: {}
                });
            } else if (data.type === 'endMeeting') {
                this.eventEmitterService.emit({
                    type: 'END_MEETING',
                    data: {}
                });
            } else if (data.type === 'makeSpeaker') {
                this.eventEmitterService.emit({
                    type: 'MAKE_SPEAKER',
                    data: data.participant
                });
            } else if (data.type === 'makeAudience') {
                this.eventEmitterService.emit({
                    type: 'MAKE_AUDIENCE',
                    data: data.participant
                });
            } else if (data.type === 'openIframe') {
                this.eventEmitterService.emit({
                    type: 'OPEN_IFRAME',
                    data: data.url
                });
            } else if (data.type === 'closeIframe') {
                this.eventEmitterService.emit({
                    type: 'CLOSE_IFRAME',
                    data: data.url
                });
            } else if (data.type === 'toggleParticipantsView') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_PARTICIPANT_VIEW',
                    data: data.show
                });
            } else if (data.type === 'requestLocalParticipantData') {
                this.eventEmitterService.emit({
                    type: 'REQUEST_LOCAL_PARTICIPANT_DATA',
                    data: {}
                });
            } else if (data.type === 'close') {
                this.eventEmitterService.emit({
                    type: 'REQUEST_CLOSE_GAMES_DATA',
                    data: {}
                });
            } else if (data.type === 'load') {
                this.eventEmitterService.emit({
                    type: 'REQUEST_LOAD_GAMES_DATA',
                    data: {
                        status: 'completed'
                    }
                });
            } else if (data.type === 'action') {
                this.eventEmitterService.emit({
                    type: 'REQUEST_MYWINNING_DATA',
                    data: {
                        event: 'redirection',
                        page: 'winnings'
                    }
                });
            }
        });

        const isOutlookOrTeamsOrWB =
            window.location.href.includes('/outlook') ||
            window.location.href.includes('/mst') ||
            window.location.href.includes('/whiteboard');
        if (!isOutlookOrTeamsOrWB) {
            if (window.location !== window.parent.location) {
                //clear user info from local storage if it is inside an iframe
                window.onbeforeunload = () => {
                    this.localStorageService.removeItem('userInfo');
                };
            }
        }

        if (window.chrome?.webview) {
            this.bindEvent(window.chrome?.webview, 'message', (e) => {
                this.handleRCEvents(e.data);
            });
        }
        this.sendNativeBackControl(true);
    }

    private handleRCMessage(data) {
        // check for message types and return true if handled
        if (
            [
                'unread-changed-by-subscription',
                'unread-changed',
                'startup',
                'status-changed',
                'Custom_Script_Logged_In'
            ].includes(data.eventName)
        ) {
            return true;
        }
        return false;
    }

    private handleRCEvents(e) {
        let data: any = {};
        try {
            data = typeof e === 'string' ? JSON.parse(e) : e;
            console.log('[JSON]data received over native bridge: ', data);
        } catch (error) {
            console.log('[NON-JSON]data received over native bridge ', e.data);
        }

        switch (data.type) {
            case RC_EVENTS.RC_INIT_CONFIG: // { type: 'RC_INIT_CONFIG', data: { jwt, embedded, threadId } }
                this.authService.setAuthInfo({ jwt: data.data?.jwt });
                this.eventEmitterService.emit({
                    type: RC_EVENTS.RC_INIT_CONFIG,
                    data: data.data
                });
                break;
        }
    }
}
