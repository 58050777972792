import { Directive, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

@Directive({
    selector: '[appDragDrop]'
})
export class DragDropDirective {
    @HostBinding('class.hover') fileOver: boolean;
    @Output() filesDropped = new EventEmitter();

    constructor() {}

    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.fileOver = false;
    }

    @HostListener('drop', ['$event'])
    onDrop($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.fileOver = false;
        this.filesDropped.emit($event.dataTransfer.files);
    }
}
