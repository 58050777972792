<div class="searchbar">
    <div class="search-icon">
        <img draggable="false" src="assets/img/website/search_icn_white.svg" />
    </div>
    <input
        type="text"
        #searchText="ngModel"
        placeholder="{{ id === 'search_past_event' ? 'Search Past Event' : 'Search Event' }}"
        [(ngModel)]="searchQueryEvents"
        (ngModelChange)="searchQueryHandler($event)"
        class="events-search"
        [id]="id"
    />
    <div class="close_icon" (click)="closeSearchQueryEvents()" *ngIf="!!searchQueryEvents">
        <img draggable="false" src="assets/img/close_circle_white.svg" />
    </div>
</div>
