<div class="create-view">
    <div
        class="create-event-view zoomed-view"
        id="viewSection"
        appIntersectionObserver
        (intersection)="onIntersection($event)"
    >
        <div class="view-control row justify-content-end">
            <div class="col-lg-6 d-flex flex-column pt-4 order-2 order-lg-1">
                <span class="event-text-wrapper"> Experience the Future of Events & Increase Productivity </span>

                <div class="explore-link-area cus-point">
                    <button
                        (click)="homePageClickevent(homePageGtmEvents.EXPLORE_NOW); redirectToContactUs()"
                        class="custom-button-revamp btn-primary w-auto btn-height button-indicator"
                        *ngIf="!isAuthenticated"
                    >
                        Schedule A Demo
                    </button>
                    <button
                        (click)="createEvent()"
                        class="custom-button-revamp btn-primary w-auto btn-height button-indicator"
                        *ngIf="isAuthenticated"
                    >
                        Create Event
                    </button>
                </div>
            </div>

            <div class="col-lg-6 order-1 order-lg-2 event-image-div">
                <img src="assets/img/website/laptop_image.png" alt="" class="event-image-view" />
            </div>
        </div>
    </div>
</div>
