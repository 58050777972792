<div class="audio-test-container">
    <div class="device-test-section mic-test-section">
        <div class="device-info-section">
            <div class="device-selection-container">
                <div class="device-heading">Microphone</div>
                <div class="device-section">
                    <div class="device-dropdown">
                        <select class="device-dropdown-select" (change)="selectMicrophone($event)">
                            <option *ngFor="let mic of availableMics" [value]="mic.id">{{ mic.name }}</option>
                        </select>
                    </div>
                    <div class="device-button-section">
                        <div class="test-button-container">
                            <div
                                class="start-button"
                                [class.test-in-progress]="micTestInProgress"
                                (click)="startMicTest()"
                            >
                                <span *ngIf="!micTestInProgress" class="button-text">
                                    <img src="assets/images/settings/test_mic.svg" />
                                    <span> Test </span>
                                    <span *ngIf="microphoneResult">
                                        <img src="assets/images/pre-call-testing/reset.svg" alt="" />
                                    </span>
                                </span>

                                <span *ngIf="micTestInProgress"> ({{ micCountDown }}s) </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="device-level-container">
            <div class="device-level">
                <div class="unit-input">
                    <span class="input-level">{{ 'user_dashboard_settings.audio.input_level' | translate }}:</span>
                    <span class="pids-wrapper" [class.disabled]="!micTestInProgress">
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="device-test-section speaker-test-section" [class.inactive]="!microphoneResult">
        <div class="device-info-section">
            <div class="device-selection-container">
                <div class="device-heading">Speaker</div>
                <div class="device-section">
                    <div class="device-dropdown">
                        <select class="device-dropdown-select" (change)="selectSpeaker($event)">
                            <option *ngFor="let speaker of availableSpeakers" [value]="speaker.id">
                                {{ speaker.name }}
                            </option>
                        </select>
                    </div>
                    <div class="device-button-section">
                        <div class="test-button-container">
                            <div
                                class="start-button"
                                [class.test-in-progress]="speakerTestInProgress"
                                (click)="startSpeakerTest()"
                            >
                                <span *ngIf="!speakerTestInProgress" class="button-text">
                                    <img src="assets/images/settings/test_speaker.svg" />
                                    <span> Test </span>
                                    <span *ngIf="speakerResult">
                                        <img src="assets/images/pre-call-testing/reset.svg" alt="" />
                                    </span>
                                </span>
                                <span *ngIf="speakerTestInProgress">({{ speakerCountDown }}s) </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="device-level-container">
            <div class="device-level">
                <div class="unit-input">
                    <span class="input-level">{{ 'user_dashboard_settings.audio.output_level' | translate }}:</span>
                    <span class="pids-wrapper" [class.disabled]="!speakerTestInProgress">
                        <div #audioOutputLevel class="pid"></div>
                        <div #audioOutputLevel class="pid"></div>
                        <div #audioOutputLevel class="pid"></div>
                        <div #audioOutputLevel class="pid"></div>
                        <div #audioOutputLevel class="pid"></div>
                    </span>
                </div>
            </div>
        </div>

        <div class="view-result-button" *ngIf="speakerResult" (click)="viewResult()">View Results</div>
    </div>

    <app-audio-result-modal
        *ngIf="isModalOpen"
        (closeModalEvent)="onCloseModalEvent()"
        [microphoneResult]="microphoneResult"
        [speakerResult]="speakerResult"
    ></app-audio-result-modal>
</div>
