import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-event-btn',
    templateUrl: './event-btn.component.html',
    styleUrls: ['./event-btn.component.scss']
})
export class EventBtnComponent implements OnInit {
    @Input() btnText;
    @Input() prefixBtnIcon = true;
    @Input() imgPath = '';
    @Input() isPrimary = true;
    @Input() customClass = '';
    @Input() id;
    @Input() isDisabled = false;
    @Output() btnClick: EventEmitter<any> = new EventEmitter();

    isMobile = false;

    constructor(private utilService: UtilService) {
        this.isMobile = this.utilService.isResponsiveMobileView();
    }

    ngOnInit(): void {}

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = this.utilService.isResponsiveMobileView();
    }

    handleBtnClick($event) {
        if (this.isDisabled) return;
        this.btnClick.emit($event);
    }
}
