import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    forwardRef,
    Input,
    SimpleChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyValue, TitleCasePipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import { AppService, TagsService } from 'src/app/core';

@Component({
    selector: 'app-tags-input',
    templateUrl: './tags-input.component.html',
    styleUrls: ['./tags-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagsInputComponent),
            multi: true
        },
        TitleCasePipe
    ]
})
export class TagsInputComponent implements OnInit, ControlValueAccessor {
    @ViewChild('input') input: ElementRef<HTMLSpanElement>;
    @Input() category;
    @Input() enableSuggestions: boolean = true;
    loading = false;

    tagsCollection: any = {};
    initialTagsSuggestion: any = [];
    tagsSuggestion: any = [];
    searchTerm = '';
    tags = new Map();
    openSelectMenu: boolean = false;
    tagsSelectionInProgress: boolean = false;
    maxTags = 5;
    defaultTag = '#Jio Events';
    MAX_TAG_LENGTH = 31;

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };

    private onChange: any = () => {};
    private onTouch: any = () => {};

    constructor(
        private toastrService: ToastrService,
        private tagService: TagsService,
        private titleCase: TitleCasePipe,
        private appService: AppService
    ) {}

    ngOnInit() {
        this.getTagsCollection();
        this.maxTags = this.appService.getConfigVariable('jioConfConfig')?.maxTags || 5;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!Object.keys(this.tagsCollection)?.length) {
            this.getTagsCollection();
        }
        if (changes.category?.currentValue) {
            this.tags = new Map();
            this.initialTagsSuggestion = this.tagsCollection[changes.category?.currentValue];
            // add default tag jio events
            if (!this.initialTagsSuggestion?.includes(this.defaultTag))
                this.initialTagsSuggestion?.push(this.defaultTag);
            this.tagsSuggestion = this.initialTagsSuggestion;
            // auto populate the default tag
            this.selectTag(this.defaultTag);
        }
    }

    getTagsCollection() {
        setTimeout(() => {
            this.tagService.getTagsCollection().subscribe((res) => {
                this.tagsCollection = res;
                this.initialTagsSuggestion = this.tagsCollection[this.category];
            });
        }, 200);
    }

    set value(val) {
        this.tags = val;
        this.onChange(val);
        this.onTouch(val);
    }

    writeValue(value) {
        this.tags = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    selectTag(tag) {
        this.searchTerm = '';
        tag = tag.trim();
        if (tag == '#') {
            return;
        }
        if (!this.tags.has(tag)) {
            this.tags.set(tag, { tag: tag, valid: true });
            this.value = this.tags;
            this.setTagsArray();
            this.tagsSelectionInProgress = true;
        }
        if (this.tags.size >= this.maxTags) {
            this.openSelectMenu = false;
        }
    }

    removeTag(tag) {
        this.tags.delete(tag.key);
        this.setTagsArray();
        this.value = this.tags;
    }

    toggleMenu(state) {
        if (this.tagsSelectionInProgress && !state) {
            this.tagsSelectionInProgress = false;
            return;
        }

        if (this.tags.size >= this.maxTags) {
            return;
        }
        this.openSelectMenu = state;
        if (state) {
            this.setTagsArray();
        } else {
            if (!this.isCustomTagInvalid()) {
                this.addTagsWhenDropdownCloses();
            } else {
                this.searchTerm = '';
            }
        }
    }

    addTagsWhenDropdownCloses() {
        if (this.isCustomTagInvalid()) return;

        let tag = this.titleCase.transform(this.searchTerm);
        if (
            this.tagsSuggestion.filter(
                (tag) => tag?.toLowerCase().replace('#', '') === this.searchTerm?.toLowerCase().replace('#', '')
            )[0]
        ) {
            if (!this.tags.has(tag)) {
                this.tags.set(tag, { tag: tag, valid: true });
            }
        }
        this.value = this.tags;
        this.searchTerm = '';
    }

    setTagsArray() {
        this.tagsSuggestion = this.initialTagsSuggestion?.filter((tag) => ![...this.tags.keys()].includes(tag));
        if (this.searchTerm) {
            this.searchTags();
        }
    }

    searchTags() {
        this.tagsSuggestion = this.initialTagsSuggestion.filter(
            (tag) => tag.toLowerCase().includes(this.searchTerm?.toLowerCase()) && ![...this.tags.keys()].includes(tag)
        );
        if (!this.isCustomTagInvalid()) {
            // push custom tag as suggestion
            this.tagsSuggestion.push(this.searchTerm);
        }
    }

    isCustomTagInvalid() {
        // first check if the tag is already been added

        return (
            this.isAlreadySelected() !== -1 ||
            !this.searchTerm.trim() ||
            (this.searchTerm.includes('#') && this.searchTerm.length === 1) ||
            this.searchTerm.length > this.MAX_TAG_LENGTH
        );
    }

    isAlreadySelected() {
        this.transformCustomTag();
        const searchTerm = this.searchTerm?.trim().toLowerCase();
        return [...this.tags.keys()].findIndex((tag) => tag.toLowerCase() === searchTerm);
    }

    transformCustomTag() {
        this.searchTerm = this.searchTerm.indexOf('#') !== 0 ? `#${this.searchTerm}` : this.searchTerm;
    }
}
