import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';

@Pipe({
    name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
    constructor(private utilService: UtilService) {}
    urlRegex =
        /(((http|ftp|https):\/{2})+(([0-9a-z_-]+\.)+(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa)(:[0-9]+)?((\/([~0-9a-zA-Z\#\+\%@?=:;&\.\/_-]+))?(\?[0-9a-zA-Z\+\%@?=:;&\/&\[\];=_-]+)?)?))\b/;
    tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
    };

    public instanceValue(value): string {
        return value.includes('http') || value.includes('https') ? value : `http://${value}`;
    }
    public transformer(value: string, replaceProtocol): string {
        if (this.urlRegex.test(this.instanceValue(value))) {
            value = this.instanceValue(value).replace(this.urlRegex, (url: string) => {
                return (
                    '<a href="' +
                    url +
                    '" target="_blank">' +
                    (replaceProtocol ? url.replace(/^(http|https):\/\//, '') : url) +
                    '</a>'
                );
            });
        }
        return value;
    }
    transform(value: string, replaceProtocol = false): any {
        //to prevent html tags from escaping in innerHtml attr
        value = this.utilService.htmlDecode(value);
        if (value.includes(' ')) {
            const valueArr = value.split(' ');
            return valueArr
                .map((value) => {
                    return this.transformer(value, replaceProtocol);
                })
                .join(' ');
        } else {
            if (/[&<>]/g.test(value)) {
                value = value.replace(/[&<>]/g, (tag) => {
                    return this.tagsToReplace[tag] || tag;
                });
            }
            // detect url in string
            return this.transformer(value, replaceProtocol) || '';
        }
    }
}
