<div class="card-panel" *ngIf="!showMiniBar">
    <div class="call-info-header">
        <div>
            <div>Stream Mixer Settings</div>
            <div>Upload PPT Images to present</div>
        </div>
        <img
            class="pointer"
            [class.invert-icon]="!showMiniBar"
            src="assets/images/speaker-call-wrapper/chevron_up_arrow_call_controls.svg"
            (click)="toggleMiniPreview()"
        />
        <img class="pointer" src="assets/images/close_icon_opaque.svg" (click)="closeModal($event)" />
    </div>
    <section class="content">
        <div class="controls-container">
            <div
                class="upload-section pointer"
                appDragDrop
                (filesDropped)="handleDropFileEvent($event)"
                (click)="fileUpload.click()"
            >
                <img src="assets/images/share-media/upload_cloud_icon.svg" alt="Upload Image " />
                <span>
                    <input
                        #fileUpload
                        type="file"
                        (click)="fileUpload.value = null"
                        style="display: none"
                        accept="image/*"
                        (change)="fileChangeEvent($event)"
                    />
                    <div class="upload-btn-text">
                        {{ 'webinar-content.upload' | translate }}
                    </div>
                </span>
            </div>
            <div
                class="upload-section pointer upload-btn-text"
                (click)="startStreamWithPPT()"
                [class.disabled]="videoService?.cameraState || !videoService?.pptSlideImages?.length"
            >
                Start Stream
            </div>
        </div>
        <div class="video-list mt-2" *ngIf="videoService?.pptSlideImages?.length; else noImagesTemplate">
            <ng-container *ngFor="let media of videoService?.pptSlideImages; let i = index">
                <div class="video" [class.show-top-border]="i !== 0">
                    <img [src]="media" />
                </div>
            </ng-container>
        </div>

        <div class="video-list text-center py-3" *ngIf="!mediaFiles?.length">No Videos to Select</div>
    </section>
</div>
<ng-template #noImagesTemplate>
    <div class="controls-container">No Images are uploaded to Present</div>
</ng-template>
