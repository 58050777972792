<div
    class="fixed-container"
    [class.is-mobile]="isMobileView"
    *ngIf="showBanner || showCalendar"
    [class.visibility-hidden]="webinarAttendeeService.autoJoinInProgress"
>
    <div class="left-tile d-flex" *ngIf="showBanner && !webinarDetails?.sessionRegistrationEnabled">
        <div class="media-wrapper">
            <img
                class="banner recurring-event-banner"
                *ngIf="!videoUrl"
                [src]="
                    brandDetails?.welcomeBanner
                        ? brandDetails?.welcomeBanner
                        : 'assets/img/jioconf/welcome-slates-plain/' + webinarDetails?.eventType + '.png'
                "
                alt=""
                oncontextmenu="return false;"
            />
            <div class="video-wrapper" *ngIf="videoUrl">
                <app-video-player
                    [mediaFile]="videoUrl"
                    [sourceComponent]="'attendee-landing'"
                    oncontextmenu="return false;"
                ></app-video-player>
            </div>
            <!-- <div class="add-to-cal-wrapper pointer" *ngIf="isMobileOS && enableAddtoCalendar" (click)="openAddToCal()">
                <img src="assets/img/add_to_cal_mob.svg" alt="Add to calendar" />
            </div>
    
            <div class="share-wrapper pointer" (click)="shareOnMobileDevices()" *ngIf="isMobileOS && !videoUrl">
                <img src="assets/img/share_mobile_icn.svg" alt="share" />
            </div> -->
        </div>
        <div class="event-title-time-wrapper">
            <div
                class="event-info d-flex justify-content-between"
                *ngIf="webinarDetails?.topicMarkdown?.text || webinarDetails?.topic"
            >
                <!-- <p
                class="title event-topic"
                style="color: #fff;"
                [innerHTML]="
                    webinarDetails?.topicMarkdown?.text || webinarDetails?.topic || '' | sanitizeText | sanitizeHtml
                "
            ></p> -->
                <div
                    [ngClass]="enableMarkdown ? 'topic-section-markdown-form' : 'topic-section'"
                    [innerHTML]="
                        webinarDetails?.topicMarkdown?.text || webinarDetails?.topic || '' | sanitizeText | sanitizeHtml
                    "
                    pTooltip="{{ webinarDetails?.topic }}"
                    tooltipPosition="bottom"
                ></div>
            </div>
            <div class="event-time-wrapper d-flex" *ngIf="!isMobileView">
                <div class="timeInfo d-flex flex-column">
                    <span class="title">START DATE & TIME</span>
                    <span class="time"
                        >{{ webinarDetails?.scheduledStartTime | date: 'dd LLL' }},
                        {{ webinarDetails?.scheduledStartTime | date: 'hh:mm a' }} (IST)</span
                    >
                </div>
                <div class="timeInfo d-flex flex-column">
                    <span class="title">END DATE & TIME</span>
                    <span class="time"
                        >{{
                            (webinarDetails?.repeatOptions?.repeat !== 'none' &&
                                webinarDetails?.repeatOptions?.endDate) ||
                                webinarDetails?.scheduledEndTime | date: 'dd LLL'
                        }}, {{ webinarDetails?.scheduledEndTime | date: 'hh:mm a' }} (IST)</span
                    >
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="schedule-heading" *ngIf="!isMobileView && showBanner">Schedule</div> -->
    <div class="calendar-wrapper" *ngIf="showCalendar && !isRecurring">
        <je-calendar-week-view
            [viewDate]="viewDate"
            [viewStart]="viewDate"
            [viewEnd]="viewEndDate"
            [daysInWeek]="daysInWeek"
            [events]="events"
            (dayHeaderClicked)="handleDaySelection($event)"
        >
        </je-calendar-week-view>
    </div>
    <div class="recurring-wrapper" *ngIf="isRecurring">
        <p-tabView
            [(activeIndex)]="activeIndex"
            [scrollable]="true"
            (click)="updateButtonState()"
            (scroll)="updateButtonState()"
            (onChange)="onTabChange(activeIndex)"
            #tabMenuRef
        >
            <p-tabPanel *ngFor="let occurrence of occurrences" [header]="occurrence | date: 'dd LLL'"> </p-tabPanel>
        </p-tabView>
    </div>
</div>

<div
    class="page event-sessions-container"
    [class.is-mobile]="isMobileView"
    [class.recurring-event]="showCalendar && showBanner"
    [class.recurring-event-without-cal]="showBanner && !showCalendar"
    [class.recurring-event-without-banner]="showCalendar && !showBanner"
    [class.visibility-hidden]="webinarAttendeeService.autoJoinInProgress"
>
    <div class="content">
        <div *ngIf="webinarDetails.isMultiDay && webinarDetails.repeatOptions.repeat === 'none'">
            <ng-container [ngTemplateOutlet]="session_dates"></ng-container>
        </div>
        <div class="schedule-container">
            <ng-container [ngTemplateOutlet]="event_sessions" *ngIf="sessionsInfoLocal.length"></ng-container>
            <ng-container
                [ngTemplateOutlet]="event_speakers"
                *ngIf="!webinarDetails.customization?.enablePeopleTab"
            ></ng-container>
        </div>
    </div>
</div>

<ng-template #session_dates>
    <div class="d-flex w-100 session-tabs-container" id="session-tabs">
        <div
            *ngFor="let date of sessionDates"
            class="sessions-date-tab d-flex flex-column"
            (click)="selectedEventDate = date"
        >
            <span class="pr-3 pl-3 pb-2 d-flex justify-content-center session-date">{{
                date | date: 'EEE MMM dd'
            }}</span>
            <span class="tab-border w-100" *ngIf="date === selectedEventDate"></span>
        </div>
    </div>
</ng-template>

<ng-template #event_sessions>
    <div
        class="flex-wrap d-flex flex-wrap"
        [class.mb-48]="!isMobileView"
        id="schedules"
        *ngIf="isRecurring || sessionsInfoLocal.length"
        #scheduleContainer
    >
        <div class="sub-heading w-100 mb-3 d-flex justify-content-end" *ngIf="!showBanner || !isMobileView">
            <span
                class="filter d-flex align-items-center"
                *ngIf="
                    webinarDetails &&
                    currentUser?._id &&
                    webinarDetails?.hostInfo?.userId !== currentUser?._id &&
                    userSessions.length &&
                    !isAllSessionsPreRecorded
                "
            >
                <span>Only My Sessions</span>
                <app-toggle-radio-btn
                    [checked]="showMySessions"
                    (onToggle)="handleToggle($event)"
                ></app-toggle-radio-btn
            ></span>
        </div>
        <div class="d-flex flex-wrap w-100" style="row-gap: 16px">
            <ng-container
                *ngFor="
                    let session of showMySessions
                        ? userSessions
                        : !webinarDetails?.isMultiDay
                        ? sessionsInfoLocal
                        : (sessionsInfoLocal | filterSessionsByDay: selectedEventDate);
                    trackBy: trackByFunction
                "
            >
                <app-event-session-info
                    [session]="session"
                    [isInvalidMeeting]="isInvalidMeeting"
                    [webinarDetails]="webinarDetails"
                    [brandDetails]="brandDetails"
                    [replayDetails]="replayDetails"
                    [sessionsToRemind]="sessionsToRemind"
                    [userHasRegistered]="userHasRegistered"
                    [selectedDate]="selectedDate"
                    [enableJoin]="
                        !webinarDetails?.registrationEnabled ||
                        (webinarDetails?.registrationEnabled && userHasRegistered) ||
                        (webinarDetails?.inviteOnly && webinarDetails?.sessionsEnabled)
                    "
                    (joinClick)="navigateToWebinarSession($event)"
                    class="w-100 session-wrapper"
                    [userRole]="isSpeaker && !sessions?.preRecordedVideo?.enabled ? 'Speaker' : 'Attendee'"
                ></app-event-session-info>
            </ng-container>
        </div>

        <div
            class="no-sessions d-flex flex-wrap w-100 flex-column align-items-center justify-content-center"
            *ngIf="showMySessions && !userSessions.length"
        >
            <span>There aren’t any sessions</span>
            <span>assigned to you</span>
        </div>
    </div>
</ng-template>

<ng-template #event_speakers>
    <div class="sub-section flex-wrap speakers d-flex flex-wrap flex-column" id="speakers" *ngIf="eventSpeakers.length">
        <div class="about sub-heading">Speakers</div>
        <div class="d-flex flex-wrap speakers-wrapper" *ngIf="!isMobileOS; else speakerMobileView">
            <ng-container *ngFor="let speaker of eventSpeakers">
                <app-speaker-tile [speaker]="speaker"></app-speaker-tile>
            </ng-container>
        </div>
        <ng-template #speakerMobileView>
            <swiper
                id="sponsors-section"
                class="swiper-container sponsors-section d-flex justify-content-center"
                [config]="speakersSwiperConfig"
            >
                <ng-container *ngFor="let speaker of eventSpeakers">
                    <div class="swiper-slide sponsor-bar d-flex justify-content-center">
                        <app-speaker-tile [speaker]="speaker"></app-speaker-tile>
                    </div>
                </ng-container> </swiper
        ></ng-template>
    </div>
</ng-template>

<app-loader *ngIf="loading || webinarAttendeeService.autoJoinInProgress"></app-loader>
