import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DiagnosticService, UserService } from 'src/app/core';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
    interestList = [
        'art',
        'music',
        'design',
        'education',
        'tech',
        'sports',
        'food',
        'health',
        'money',
        'travel',
        'gaming',
        'lifestyle',
        'causes',
        'blogging',
        'volunteering',
        'marketing',
        'podcast',
        'photography'
    ];

    options = [
        { type: 'host', title: 'I’m here to host an event' },
        { type: 'attend', title: 'I’m here to attend events' },
        { type: 'both', title: 'I’m here to do both' }
    ];
    selectedInterests = [];
    showInterestSection: boolean = false;
    form: FormGroup;
    currentUser;
    selectedPurpose;
    errors: any = {};

    constructor(
        private router: Router,
        private userService: UserService,
        private fb: FormBuilder,
        private diagnosticService: DiagnosticService
    ) {}

    ngOnInit() {
        this.currentUser = this.userService.getUserSync();

        this.form = this.fb.group({
            department: [this.currentUser?.department || '', Validators.required],
            jobTitle: [this.currentUser?.jobTitle || '', Validators.required]
        });
        this.selectedPurpose = this.currentUser?.jioConf?.purpose[0] || 'attend';
        this.selectedInterests = this.currentUser?.jioConf?.interestAreas || [];

        this.listenToFormChanges();
    }

    listenToFormChanges() {
        this.form.valueChanges.subscribe((res) => {
            const charRegex = new RegExp('^[a-zA-Z0-9_,.@ -]*$');
            this.errors.department = !charRegex.exec(res.department)
                ? 'Only -, @ and _ special characters are allowed'
                : false;
            this.errors.jobTitle = !charRegex.exec(res.jobTitle)
                ? 'Only -, @ and _ special characters are allowed'
                : false;
        });
    }

    selectInterest(interest) {
        if (!this.selectedInterests.includes(interest)) {
            this.selectedInterests.push(interest);
        } else {
            this.selectedInterests.splice(this.selectedInterests.indexOf(interest), 1);
        }
    }

    updateProfile() {
        const payload = {
            ...this.form.value,
            jioConf: { purpose: [this.selectedPurpose], interestAreas: this.selectedInterests }
        };

        this.userService.updateProfile(payload).subscribe(() => {
            if (!this.showInterestSection) {
                this.showInterestSection = true;
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Jioconf Welcome Screen',
                        eventAction: `${this.selectedPurpose}`,
                        eventType: 'app_event',
                        endpoint: '/api/my_profile/update',
                        status: 'success'
                    })
                    .subscribe();
            } else {
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Jioconf Area of interest',
                        eventAction: `${this.selectedInterests.join(',')}`,
                        eventType: 'app_event',
                        endpoint: '/api/my_profile/update',
                        status: 'success'
                    })
                    .subscribe();
                this.navigateToDashboard();
            }
        });
    }

    navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }

    selectPurpose(purpose) {
        this.selectedPurpose = purpose.type;
    }
}
