import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core';

@Pipe({
    name: 'videosecure'
})
export class VideosecurePipe implements PipeTransform {
    constructor(private authService: AuthService) {}

    transform(str): any {
        let userinfo = this.authService.getAuthInfo();
        let token = userinfo?.jwt;
        let url = new URL(str);
        url.searchParams.append('tempAuth', token);

        return url.toString();
    }
}
