<div
    class="info-icon"
    *ngIf="!isMobile && !isRevampIcon"
    [pTooltip]="tooltip"
    tooltipStyleClass="white-bg  info-icon-tooltip  custom-tooltip"
    tooltipPosition="bottom"
>
    i
</div>

<div
    src="assets/images/info_icon_default.svg"
    *ngIf="!isMobile && isRevampIcon"
    [pTooltip]="tooltip"
    tooltipStyleClass="white-bg  info-icon-tooltip  custom-tooltip"
    tooltipPosition="bottom"
    class="info-icon-img pointer"
></div>

<div
    class="nav-item profile"
    [class.edit-event-form]="isFromEditEvent"
    [class.dropdown]="!dropright"
    [class.dropright]="dropright"
    *ngIf="isMobile"
>
    <div
        class="info-icon pointer"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        id="navbarUserDropdown"
    >
        i
    </div>
    <div class="dropdown-menu dropdown-menu-right dropdown-menu-top drop-menu" aria-labelledby="navbarUserDropdown">
        <div class="profile">{{ tooltip }}</div>
    </div>
</div>
