import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { RestService } from './rest.service';
declare const window: any;

@Injectable({
    providedIn: 'root'
})
export class LiveChatService {
    public message$: BehaviorSubject<string> = new BehaviorSubject('');
    socket: any;
    lastPrevSeqId: any;
    latestSeqId: any;
    lastReadMessageId: any;
    lastReadMessageCount: any;
    public $messages: BehaviorSubject<any> = new BehaviorSubject('');
    perBatchMessageCount: any = 10;
    totalMessages: any = [];
    newMessageSCount: any = 0;
    finalMessageCountOnClose: any = 0;
    startNewMesageCount: boolean = false;
    lastMessageMeetinId: any = '';
    pinedmessages: any = [];
    public broadCastStatus$: BehaviorSubject<any> = new BehaviorSubject(false);
    enableLiveChat: boolean = false;
    previousLiveChatStatus: boolean = false;
    liveChatMessagesSequenceInfo: any = {};
    messageContainerStartSeq;
    latestMessageSeq;
    lastMessageReadAt: any = new Date();
    lastestMessages: any = [];
    constructor(private restService: RestService, private appService: AppService, private authService: AuthService) {
        this.perBatchMessageCount = this.appService.getConfigVariable('MessagePerPagecount')
            ? this.appService.getConfigVariable('MessagePerPagecount')
            : 10;
    }

    getminMessageToShow() {
        return this.appService.getConfigVariable('DefaultshowMessagecount');
    }

    getMessagePollinTime() {
        return this.appService.getConfigVariable('liveChatPollingTime');
    }

    setNewMessages(data, seqId, meeting_Id, updateNewMsgCount = false) {
        if (this.checkMeetingChanges(meeting_Id)) {
            return;
        }
        let objKey = `seq_${seqId}`;
        if (this.messageContainerStartSeq == undefined) {
            return this.createMessagesSequenceInfo(data, seqId, updateNewMsgCount);
        }
        if (updateNewMsgCount) {
            this.updatenewMessagesCount(objKey, data);
        }
        return this.updateNewMessagesInSequenceInfo(objKey, data, seqId, updateNewMsgCount);
    }

    updateNewMessagesInSequenceInfo(objKey, data, seqId, updateNewMsgCount) {
        let newMessages;
        if (this.liveChatMessagesSequenceInfo[objKey]) {
            let temArray = JSON.parse(JSON.stringify(data));
            let sizeDiff = this.liveChatMessagesSequenceInfo[objKey].length - data.length;
            if (sizeDiff != 0) {
                newMessages = temArray.slice(sizeDiff);
            } else {
                newMessages = [];
            }
        } else {
            newMessages = data;
        }
        this.liveChatMessagesSequenceInfo[objKey] = data;
        this.latestMessageSeq = Number(seqId);
        return updateNewMsgCount ? this.newMessageSCount : newMessages;
    }

    createMessagesSequenceInfo(data, seqId, updateNewMsgCount) {
        let objKey = `seq_${seqId}`;
        this.messageContainerStartSeq = seqId;
        if (data.length > 0) {
            let updatelastMessages =
                new Date(data[data.length - 1].cOn).getTime() > new Date(this.lastMessageReadAt).getTime();
            if (updatelastMessages) this.newMessageSCount = updateNewMsgCount ? data.length : 0;
        }
        this.liveChatMessagesSequenceInfo[objKey] = data;
        this.latestMessageSeq = Number(seqId);
        return updateNewMsgCount ? this.newMessageSCount : data;
    }

    updatenewMessagesCount(objKey, data) {
        if (this.liveChatMessagesSequenceInfo[objKey]) {
            this.newMessageSCount += data.length - this.liveChatMessagesSequenceInfo[objKey].length;
        } else {
            this.newMessageSCount += data.length;
        }
    }

    updateLastReadTime() {
        this.lastMessageReadAt = new Date();
    }

    getLatestMessagesForContainer() {
        this.newMessageSCount = 0;
        let data = [];
        if (!this.latestMessageSeq) {
            return {
                messages: [],
                currentSeq: '',
                oldMessageSeq: ''
            };
        }
        let seq;
        if (
            this.liveChatMessagesSequenceInfo[`seq_${this.latestMessageSeq}`] &&
            this.liveChatMessagesSequenceInfo[`seq_${this.latestMessageSeq - 1}`] &&
            this.liveChatMessagesSequenceInfo[`seq_${this.latestMessageSeq}`].length < 14
        ) {
            data.push(...this.liveChatMessagesSequenceInfo[`seq_${this.latestMessageSeq - 1}`]);
            data.push(...this.liveChatMessagesSequenceInfo[`seq_${this.latestMessageSeq}`]);
            seq = this.latestMessageSeq - 1;
        } else {
            data.push(...this.liveChatMessagesSequenceInfo[`seq_${this.latestMessageSeq}`]);
            seq = this.latestMessageSeq;
        }
        return { messages: data, currentSeq: this.latestMessageSeq, oldMessageSeq: seq };
    }

    isSeqAvailable(seqId) {
        return this.liveChatMessagesSequenceInfo.hasOwnProperty(`seq_${seqId}`);
    }

    getSeqMessages(seqId) {
        return this.liveChatMessagesSequenceInfo[`seq_${seqId}`];
    }

    updateOldMessagesInSequenceInfo(seqId, messages) {
        let objKey = `seq_${seqId}`;
        this.messageContainerStartSeq = seqId;
        this.liveChatMessagesSequenceInfo[objKey] = messages;
    }

    resetMessageData() {
        this.liveChatMessagesSequenceInfo = {};
        this.messageContainerStartSeq = '';
        this.latestMessageSeq = '';
        this.totalMessages = [];
        this.finalMessageCountOnClose = 0;
        this.lastPrevSeqId = '';
        this.latestSeqId = '';
    }

    checkMeetingChanges(meeting_Id) {
        if (!this.lastMessageMeetinId) {
            this.lastMessageMeetinId = meeting_Id;
            return false;
        } else {
            if (this.lastMessageMeetinId != meeting_Id) {
                this.lastMessageMeetinId = meeting_Id;
                this.resetMessageData();
                return true;
            }
        }
        return false;
    }

    getAllMessages(meeting_Id, seqId = null) {
        let endpoint = this.authService.getIsAuthenticated() ? `${meeting_Id}` : `${meeting_Id}/guest`;
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/livechat/getmessages/${endpoint}${
                seqId ? '?seqId=' + seqId : ''
            }`
        );
    }

    sendMessage(data) {
        let endpoint = this.authService.getIsAuthenticated() ? `` : `/guest`;
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/livechat/messages${endpoint}`,
            data
        );
    }

    pinMessage(data) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/livechat/pinmessage`, data);
    }

    UnpinMessage(data) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/livechat/unpinmessage`, data);
    }

    updateMessage(meetindId, data) {
        return this.restService.post(`http://localhost:3000/editmessage/${meetindId}`, data);
    }

    deleteMessage(data) {
        let endpoint = this.authService.getIsAuthenticated() ? `` : `/guest`;
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/livechat/deletemessage${endpoint}`,
            data
        );
    }

    getArrayLastElements(arr) {
        if (arr.length) {
            return arr[arr.length - 1];
        } else {
            return null;
        }
    }

    checkIsPinedMessage(id) {
        return this.pinedmessages.includes(id);
    }

    setBroadCastStatus(status) {
        this.broadCastStatus$.next(status);
    }

    getBroadCastStatus() {
        return this.broadCastStatus$;
    }
}
