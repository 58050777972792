<div class="profile-settings-container">
    <div *ngIf="showProfile" class="profile-container">
        <div class="profile-setting row">
            <div class="profile-setting-left col-md-5">
                <img src="{{ originalImage }}" class="profile-image" alt="" (click)="toggleChangeProfilePicture()" />
                <div class="dropdown">
                    <div class="pointer dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <a class="blue mr-2">{{ 'user_dashboard_settings.profile.change' | translate }}</a>
                        <span>
                            <img src="assets/img/arrow_down_icn_blue.svg" />
                        </span>
                    </div>
                    <ul class="dropdown-menu">
                        <li>
                            <a (click)="toggleChangeProfilePicture()">{{
                                'user_dashboard_settings.profile.change_picture' | translate
                            }}</a>
                        </li>
                        <li *ngIf="user.imageMeta?.path">
                            <a (click)="removeProfilePicture()">{{
                                'user_dashboard_settings.profile.remove_picture' | translate
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="profile-setting-right name col-md-7" *ngIf="!isNameBeingEdited">
                <h3 class="full-name" title="{{ fullName }}">{{ fullName }}</h3>
                <div
                    class="edit-icn-fullName"
                    alt=""
                    *ngIf="user?.isOTT"
                    (click)="toggleNameEdit()"
                    pTooltip="Edit profile"
                    tooltipPosition="bottom"
                ></div>
            </div>
            <div class="profile-setting-right col-md-7" *ngIf="isNameBeingEdited">
                <form action="" [formGroup]="form">
                    <div class="input-field mb-3">
                        <label class="first-name">{{ 'user_dashboard_settings.profile.first_name' | translate }}</label>
                        <i aria-label="Required information">*</i>
                        <input type="text" maxlength="20" formControlName="firstName" />
                    </div>
                    <div class="input-field mb-3">
                        <label class="first-name">{{ 'user_dashboard_settings.profile.last_name' | translate }}</label>
                        <i aria-label="Required information">*</i>
                        <input type="text" maxlength="20" formControlName="lastName" />
                    </div>
                    <div class="mt-2 text-error" *ngIf="errors.global">{{ errors.global }}</div>
                    <div class="button-group">
                        <button class="custom-button bg-secondary cancel" (click)="toggleNameEdit()">
                            {{ 'user_dashboard_settings.cancel' | translate }}
                        </button>
                        <button
                            type="button"
                            class="custom-button bg-primary save"
                            [disabled]="!form.dirty"
                            (click)="changeName()"
                        >
                            {{ 'user_dashboard_settings.save' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <hr class="divider mt-4 mb-4" />
        <div class="profile-setting row" *ngIf="user?.email">
            <div class="profile-setting-left col-md-5">
                {{ 'user_dashboard_settings.profile.sign_in_email' | translate }} -
            </div>
            <div class="profile-setting-right col-md-7">
                {{ user.email }}
            </div>
        </div>
        <div class="profile-setting row" *ngIf="user?.phoneNo">
            <div class="profile-setting-left col-md-5">
                {{ 'user_dashboard_settings.profile.sign_in_mobile' | translate }} -
            </div>
            <div class="profile-setting-right col-md-7">
                {{ user.phoneNo }}
            </div>
        </div>
        <div class="profile-setting row" *ngIf="user?.source === 'email'">
            <div class="profile-setting-left col-md-5 pb-4">
                {{ 'user_dashboard_settings.profile.sign_in_password' | translate }} -
            </div>
            <div class="profile-setting-right col-md-7 pt-2" *ngIf="user?.source === 'email'">
                ****************
                <div class="change-password" *ngIf="user?.source === 'email'" (click)="toggleChangePassword()">
                    {{ 'user_dashboard_settings.profile.change_password' | translate }}
                </div>
            </div>
        </div>
    </div>

    <app-change-profile-picture
        *ngIf="isChangeProfilePicture"
        (close)="toggleChangeProfilePicture()"
    ></app-change-profile-picture>
    <app-change-password *ngIf="isChangePassword" (close)="toggleChangePassword()"></app-change-password>
</div>
