import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WaitingRoomService {
    private users$: BehaviorSubject<any> = new BehaviorSubject(null);
    users = {};

    constructor() {}

    getWaitingRoomUsers$() {
        return this.users$;
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.users[user.waitingRoomId] = user;
            this.sendStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.users[user.waitingRoomId];
        this.sendStatus();
    }

    resetWaitingRoomUsers() {
        this.users = {};
        this.sendStatus();
    }

    private sendStatus() {
        this.users$.next({
            users: this.users
        });
    }
}
