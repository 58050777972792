import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { NETWORKING_LOUNGE } from 'src/app/constants';
import { AppService, ProfileService, RtcService, UserService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { NetworkingRoomsService } from 'src/app/core/services/networking-rooms.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';

@Component({
    selector: 'app-networking-room-tile',
    templateUrl: './networking-room-tile.component.html',
    styleUrls: ['./networking-room-tile.component.scss']
})
export class NetworkingRoomTileComponent implements OnInit {
    @Input() networkingRoom: any;
    @Input() roomsClosedStatus: boolean = false;
    @Input() networkingRooms = [];
    @Output() networkingTileActions = new EventEmitter();
    showDuplicateRoomPopup = false;
    showCloseRoomsPopup = false;
    @Input() usageArea;
    @Input() isUserHost; // Or check it here? // DEfault true from networking webinar settings
    @Input() showEscapeRooms: boolean = false;
    callStateManagerRef;
    errors;
    isWebinarSettings: boolean = true;
    maxParticipantLimit: any;
    duplicateRoomsCount = 1;
    networkingRoomsConfigLimit = this.appService.getConfigVariable('max_networking_rooms_limit') || 25;
    constructor(
        private callStateManagerService: CallStateManagerService,
        private appService: AppService,
        private confirmationService: ConfirmationService,
        private networkingRoomsService: NetworkingRoomsService,
        private toastrService: ToastrService,
        private appLoggerService: AppLoggerService,
        private profileService: ProfileService,
        private googleTagMangerService: GoogleTagMangerService,
        public callViewStateManagerService: CallViewStateManagerService,
        private rtcService: RtcService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.callStateManagerRef = this.callStateManagerService;
        this.maxParticipantLimit = this.rtcService.getMaxParticipantInCall();
        this.isWebinarSettings = this.usageArea === 'settings';

        // add jobTitle to networking room participants if exist
        const joinDetails = this.callStateManagerService.getParentWebinarDetails();
        this.networkingRoom.participants = this.networkingRoom.participants.map((participant) => {
            const speakerInfo = joinDetails?.speakers.find((speaker) => speaker.userId === participant.userId);
            participant.jobTitle = speakerInfo?.jobTitle ?? '';
            participant.department = speakerInfo?.department ?? '';
            participant.tooltipContent = this.attachTooltipContent(participant);
            return participant;
        });
    }

    attachTooltipContent(participant) {
        return `<div class="d-flex flex-column"><span>${
            participant?.participantName
        }</span><span style="color: #767676;">${`${participant.jobTitle}${
            participant.department ? ',' : ''
        }`}</span><span style="color: #767676;">${participant.department}</span></div>`;
    }

    editRoom(nroom = null) {
        this.emitTileAction({
            event: 'editRoom',
            data: this.networkingRoom
        });
    }

    handleDeleteRoomRequest(nroom = null) {}

    showDuplicateRoomRequest(nroom = null) {
        this.showDuplicateRoomPopup = true;
        this.duplicateRoomsCount = 1;
    }

    closeDuplicateRoomRequestPopup() {
        this.showDuplicateRoomPopup = false;
    }

    duplicateRoom() {
        this.showDuplicateRoomPopup = false;
        let duplicationCount = this.duplicateRoomsCount;
        if (duplicationCount > 10) {
            this.toastrService.error('Cannot duplicate more than 10 rooms');
            return;
        }
        const enablePaymentFeature = this.appService.getConfigVariable('enablePaymentFeature');
        if (
            enablePaymentFeature &&
            this.networkingRooms?.length + duplicationCount >
                this.userService.getUserSync()?.eventsFeaturesEnabled?.networkingRoomsCount
        ) {
            this.toastrService.error('Networking Rooms Limit Reached');
            return;
        } else if (
            !enablePaymentFeature &&
            this.networkingRooms?.length + duplicationCount > this.networkingRoomsConfigLimit
        ) {
            this.toastrService.error('Networking Rooms limit has been reached');
            return;
        }
        this.createDuplicateRoomsIndividually(duplicationCount);
        let evenData = NETWORKING_LOUNGE.DUPLICATE_ROOM;
        evenData.clicked_text = `${duplicationCount}`;
        this.googleTagMangerService.pushGoogleTagData(evenData);
    }

    createDuplicateRoomsIndividually(duplicationCount) {
        let newRoomNames = this.networkingRoomsService.getDuplicateRoomNames(
            duplicationCount,
            this.networkingRoom?.topic
        );
        forkJoin(newRoomNames.map((name) => this.sendDuplicateCreateRoomRequest(name))).subscribe(
            (respArr) => {
                this.toastrService.info(
                    `Successfully duplicated the ${
                        this.networkingRoom?.networking?.isEscapeRoom ? 'escape' : 'networking'
                    } room`
                );
                this.emitTileAction({ event: 'fetchRooms' });
            },
            (errors) => {
                this.appLoggerService.error('Failed to duplicate the networking room', errors);
                this.toastrService.error('Something went wrong');
            }
        );
    }

    sendDuplicateRoomsRequest(duplicationCount, topic = this.networkingRoom?.topic) {
        this.networkingRoomsService
            .createRoom(
                this.networkingRoom?.networking.parentId,
                this.networkingRoomsService.getCreateRoomPayload(
                    topic,
                    this.networkingRoom?.jioConf.eventType,
                    this.networkingRoom?.networking.imageUrl,
                    parseInt(duplicationCount),
                    false
                )
            )
            .subscribe(
                (res) => {
                    this.toastrService.info('Successfully duplicated the networking room');
                    this.emitTileAction({ event: 'fetchRooms' });
                },
                (err) => {
                    this.appLoggerService.error('Failed to duplicate the networking room', err);
                }
            );
    }

    sendDuplicateCreateRoomRequest(topic = this.networkingRoom?.topic) {
        return this.networkingRoomsService.createRoom(
            this.networkingRoom?.networking.parentId,
            this.networkingRoomsService.getDuplicateRoomPayload(
                topic,
                this.networkingRoom?.jioConf.eventType,
                this.networkingRoom?.networking.imageUrl,
                1,
                this.networkingRoom?.networking?.isEscapeRoom,
                this.networkingRoom?.startTime,
                this.networkingRoom?.endTime
            )
        );
    }

    deleteRoom(nRoom) {
        this.confirmationService.confirm({
            message: `Are you sure you want to delete the <b>${nRoom?.topic}</b> ${
                this.networkingRoom?.networking?.isEscapeRoom ? 'escape' : 'networking'
            } room?`,
            header: 'Delete Room',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: this.isWebinarSettings
                ? 'custom-button bg-primary'
                : 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: this.isWebinarSettings
                ? 'custom-button bg-secondary light-theme'
                : 'custom-button-revamp btn-confirm-secondary lg light-theme',
            accept: () => {
                this.networkingRoomsService.deleteRoom(nRoom.networking.parentId, nRoom.networking._id).subscribe(
                    (res) => {
                        this.emitTileAction({ event: 'fetchRooms' });
                        this.toastrService.info(
                            `Successfully deleted a single ${
                                this.networkingRoom?.networking?.isEscapeRoom ? 'escape' : 'networking'
                            } room`
                        );
                    },
                    (err) => {
                        this.appLoggerService.error('Failed to delete a single networking room', err);
                    }
                );

                let evenData = NETWORKING_LOUNGE.DELETE_ROOM;
                evenData.outcome = 'delete';
                this.googleTagMangerService.pushGoogleTagData(evenData);
            },
            reject: () => {
                let evenData = NETWORKING_LOUNGE.DELETE_ROOM;
                evenData.outcome = 'cancel';
                this.googleTagMangerService.pushGoogleTagData(evenData);
            }
        });
    }
    requestCloseRoomsPopUp(room = null) {
        this.emitTileAction({
            event: 'closeRoom',
            data: this.networkingRoom
        });
    }

    showAddParticipantsPanel(room = null) {
        this.emitTileAction({
            event: 'addParticipants',
            data: this.networkingRoom
        });
        this.googleTagMangerService.pushGoogleTagData(NETWORKING_LOUNGE.ADD_PARTICIPANTS);
    }

    onNetworkingRoomClicked(nroom = null) {
        if (
            (this.roomsClosedStatus && !this.networkingRoom?.networking?.isEscapeRoom) ||
            this.callStateManagerRef.meetingObj?.meetingId === this.networkingRoom?._id ||
            this.networkingRoom.participants?.length >= this.maxParticipantLimit
        )
            return;
        this.emitTileAction({
            event: 'enterRoom',
            data: this.networkingRoom
        });
        this.googleTagMangerService.pushGoogleTagData(NETWORKING_LOUNGE.ENTER_ROOM);
    }

    closeAllRooms() {}

    emitTileAction(event = null) {
        this.networkingTileActions.emit(event);
    }

    onInputKeyPress(event) {
        let duplicationCount = (document.getElementById('duplicate-count') as HTMLInputElement).value;
        if (parseInt(duplicationCount) > 10) {
            (document.getElementById('duplicate-count') as HTMLInputElement).value = '10';
        }
    }

    onInputKeyDown(e) {
        let duplicationCount = (document.getElementById('duplicate-count') as HTMLInputElement).value;
        if (parseInt(duplicationCount) > 10 && e.keyCode !== 8) {
            e.stopPropagation();
            e.preventDefault();
        }
    }
}
