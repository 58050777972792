import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { WebinarConferenceService } from 'src/app/conference/services';
import { APP_EVENTS, EVENT_SETTINGS, INSIDE_EVENT } from 'src/app/constants';
import { DiagnosticService, LiveStreamService, RoomConnectionService, UserService, UtilService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

@Component({
    selector: 'app-livestream-controls-wrapper',
    templateUrl: './livestream-controls-wrapper.component.html',
    styleUrls: ['./livestream-controls-wrapper.component.scss']
})
export class LivestreamControlsWrapperComponent implements OnInit {
    // ADD error toast to broadcast before livestreaming.
    showLiveStreamPopUp: boolean = false;
    showStreamDetailsForm: boolean = false;
    liveStreamCreationForm: any;
    meetingObj: any;
    liveStreamingPlatforms = [
        {
            name: 'youtube',
            iconUrl: 'assets/images/livestream/youtube_stream_icon.svg',
            listIcon: 'assets/images/livestream/youtube_stream_icon.svg'
        },
        {
            name: 'facebook',
            iconUrl: 'assets/images/livestream/facebook_stream_icon.svg',
            listIcon: 'assets/images/livestream/facebook_stream_icon.svg'
        },
        {
            name: 'custom platform',
            iconUrl: 'assets/images/livestream/custom_stream_icon.svg',
            listIcon: 'assets/images/livestream/custom_listIcon.svg'
        }
    ];
    streamList = [];
    errors: any = {};
    subscriptions: any = [];
    // streamsList: any;
    selectedStream: any;
    streamingList: any[];
    currentUser: any;
    gtmMeetingInfo: any;
    constructor(
        private fb: FormBuilder,
        private webinarConfService: WebinarConferenceService,
        private callStateManagerService: CallStateManagerService,
        private toastrService: ToastrService,
        private diagnosticService: DiagnosticService,
        private roomConnectionService: RoomConnectionService,
        private liveStreamService: LiveStreamService,
        private utilService: UtilService,
        public callViewStateManagerService: CallViewStateManagerService,
        private confirmationService: ConfirmationService,
        private userService: UserService,
        private googleTagManager: GoogleTagMangerService
    ) {}

    ngOnInit(): void {
        this.currentUser = this.userService.getUserSync();
        this.createLiveStreamFormv2();
        if (!this.callViewStateManagerService.eventUpdatesObj) {
            this.callViewStateManagerService.getEventUpdates();
        }
        this.checkStreamingOptions();
        this.callViewStateManagerService.getViewStateObs().subscribe((event) => {
            if (event === 'callOptionsPanelOpen' && this.showLiveStreamPopUp) {
                this.toggle();
            }
        });
    }

    getAllLiveStreams() {
        this.subscriptions.push(
            this.liveStreamService
                .getLiveStreamsByJiomeetId(this.callStateManagerService.meetingObj.jiomeetId)
                .subscribe(
                    (data) => {
                        if (this.streamingList.length === 0 && data?.length !== 0) {
                            this.showStreamDetailsForm = false;
                        }
                        this.streamingList = data;
                        this.updateListWithStatus();
                    },
                    (err) => {
                        // this.loggerService.log('Unable to fetch all live streams', err);
                        this.toastrService.error('Something Went Wrong. Please try again');
                    }
                )
        );
    }

    updateListWithStatus() {
        this.meetingObj = this.callStateManagerService.getCurrentMeetingObject();
        this.gtmMeetingInfo = {
            meeting_Id: this.meetingObj?.meetingInfo._id,
            Category: this.meetingObj?.meetingInfo?.jioConf?.eventType,
            expected_attendee_count: this.meetingObj?.meetingInfo?.jioConf?.expectedAttendeeCount,
            tags: this.meetingObj?.meetingInfo?.jioConf?.tags
        };
        ['customlivestream', 'fblivestream', 'ytlivestream'].forEach((opt) => {
            if (this.meetingObj[opt].streamKey) {
                let idx = this.streamingList.findIndex((stream) => stream.streamKey === this.meetingObj[opt].streamKey);
                if (idx >= 0) {
                    this.streamingList[idx].streaming = this.meetingObj[opt].streaming;
                }
            }
        });
    }

    createLiveStreamFormv2(stream = null) {
        this.liveStreamCreationForm = this.fb.group({
            enabled: [false],
            streamPlatform: [stream?.streamPlatform || '', [Validators.required]],
            serverUrl: [stream?.serverUrl || '', [Validators.required]],
            streamKey: [stream?.streamKey || '', [Validators.required]],
            streamPageUrl: [stream?.streamPageUrl || '']
        });
    }

    checkStreamingOptions() {
        this.streamingList = [];
        let streamingObj = {
            youtube: this.callStateManagerService.meetingObj.ytlivestream,
            facebook: this.callStateManagerService.meetingObj.fblivestream,
            'custom platform': this.callStateManagerService.meetingObj.customlivestream
        };
        Object.entries(streamingObj).forEach(([key, value]) => {
            if (streamingObj[key].enabled) {
                this.streamingList.push({
                    streamName: key,
                    streamPageUrl: value.streamPageUrl,
                    streamKey: value.streamKey,
                    serverUrl: value.serverUrl,
                    streamPlatform: key,
                    streaming: false
                });
            }
        });
        this.updateStreamingStatus();
    }

    addNewStreamForm() {
        this.createLiveStreamFormv2();
        this.showStreamDetailsForm = true;
    }

    createLiveStreamForm(stream = null) {
        // check for jiomeetId
        // Red asterisks for form fields
        this.liveStreamCreationForm = this.fb.group({
            streamKey: [stream?.streamKey || '', [Validators.required]],
            streamName: [stream?.streamName || '', [Validators.required]],
            streamPageUrl: [stream?.streamPageUrl || '', [Validators.required]],
            streamPlatform: [stream?.streamPlatform || '', [Validators.required]],
            streamingUrl: [stream?.serverUrl || '', [Validators.required]],
            meetingId: [
                this.callStateManagerService.meetingObj._id || this.callStateManagerService.meetingObj.meetingId
            ],
            jiomeetId: [this.callStateManagerService.meetingObj.jiomeetId]
            // streaming: [stream?.streaming || false]
        });
    }

    confirmDeleteStream(stream) {
        this.confirmationService.confirm({
            message: `Are you sure you want to delete the ${stream?.streamName} Stream?`,
            header: 'Delete Stream',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            accept: () => {
                // this.deleteStream(stream);
                this.deleteLivestreamInMeetingObj(stream);
            }
        });
    }

    deleteStream(stream) {
        this.liveStreamService.deleteLiveStream(stream._id).subscribe(
            (res) => {
                this.streamingList = this.streamingList.filter((s) => s._id !== stream._id);
            },
            () => {
                this.toastrService.error('Something went wrong. Please try again.');
            }
        );
    }

    toggle(value = null) {
        if (!this.showLiveStreamPopUp) {
            this.callViewStateManagerService.hideCalloptions();
        }
        if (this.showStreamDetailsForm && this.streamingList.length !== 0) {
            this.discardFormPopUp();
            return;
        }
        this.showLiveStreamPopUp = !this.showLiveStreamPopUp;
        if (!this.streamingList || this.streamingList.length === 0) {
            this.showStreamDetailsForm = true;
        }
        if (!this.showLiveStreamPopUp) {
            this.checkStreamingOptions();
            let eventData = INSIDE_EVENT.LIVE_STREAMING_POP_UP;
            eventData.clicked_text = 'close';
            this.googleTagManager.pushGoogleTagData(eventData);
            // this.updateStreamingStatus();
        } else {
            let eventData = INSIDE_EVENT.LIVE_STREAMING_POP_UP;
            eventData.clicked_text = 'live_stream';
            this.googleTagManager.pushGoogleTagData(eventData);
        }
    }

    updateStreamingStatus() {
        let streamingStatusObj = this.callViewStateManagerService.eventUpdatesObj || {};
        this.streamingList.forEach((s) => {
            if (streamingStatusObj[this.getLongServerStreamName(s.streamPlatform)]?.streaming) {
                s.streaming = true;
            }
        });
    }

    getLongServerStreamName(name) {
        switch (name) {
            case 'youtube':
                return 'ytlivestream';
            case 'facebook':
                return 'fblivestream';
            case 'custom platform':
                return 'customlivestream';
            default:
                return 'youtube';
        }
    }

    constructstreamingList() {
        this.streamingList = [];
        // ({showStreamInfo: false, ...this.meetingObj.ytlivestream});
        if (this.meetingObj.ytlivestream.streamKey) {
            this.streamingList.push({
                showStreamInfo: false,
                ...this.meetingObj.ytlivestream,
                streamCategory: 'yt',
                title: 'Youtube Streaming'
            });
        }
        if (this.meetingObj.fblivestream.streamKey) {
            this.streamingList.push({
                showStreamInfo: false,
                ...this.meetingObj.fblivestream,
                streamCategory: 'fb',
                title: 'Facebook Streaming'
            });
        }
        if (this.meetingObj.customlivestream.streamKey) {
            this.streamingList.push({
                showStreamInfo: false,
                ...this.meetingObj.customlivestream,
                streamCategory: 'custom',
                title: 'Custom Streaming'
            });
        }
    }

    discardFormPopUp(from = null) {
        this.selectedStream = null;
        this.liveStreamCreationForm.reset();
        if (this.streamingList.length === 0) {
            this.showLiveStreamPopUp = false;
        } else {
            this.showStreamDetailsForm = false;
        }
        let eventData = INSIDE_EVENT.LIVE_STREAMING_POP_UP;
        eventData.clicked_text = from ? 'cancel' : 'close';
        this.googleTagManager.pushGoogleTagData(eventData);
    }

    submitLiveStreamForm() {
        let payload = this.liveStreamCreationForm.value;
        let streamType = Object.assign({}, INSIDE_EVENT.LIVE_STREAMING_POP_UP);
        streamType.clicked_text =
            payload.streamPlatform == 'youtube'
                ? 'platform_youtube'
                : payload.streamPlatform == 'facebook'
                ? 'platform_facebook'
                : 'platform_custom';
        if (this.isStreamUrlValid(payload)) {
            this.errors.global = 'Invalid URL';
            this.toastrService.warning('Invalid URL');
            if (!this.selectedStream) {
                let eventData = INSIDE_EVENT.LIVE_STREAMING_POP_UP;
                eventData.clicked_text = 'add_stream';
                eventData.outcome = 'failed';
                streamType.outcome = 'failed';
                this.googleTagManager.pushGoogleTagData(eventData);
                this.googleTagManager.pushGoogleTagData(streamType);
            }
            return;
        }

        let streamCategory = payload.streamType;

        delete payload.streamType;
        if (payload.streamPageUrl === '') {
            delete payload.streamPageUrl;
        }
        // if (this.selectedStream) {
        //     this.updateLiveStream();
        // } else {
        //     // this.createLiveStream();
        //     this.updateLiveStreamInMeetingObject(this.liveStreamCreationForm.value);
        // }
        if (!this.selectedStream) {
            let eventData = INSIDE_EVENT.LIVE_STREAMING_POP_UP;
            eventData.clicked_text = 'add_stream';
            eventData.outcome = 'succeeded';
            streamType.outcome = 'succeeded';
            this.googleTagManager.pushGoogleTagData(eventData);
            this.googleTagManager.pushGoogleTagData(streamType);
        }
        this.updateLiveStreamInMeetingObject(this.liveStreamCreationForm.value);
    }

    deleteLivestreamInMeetingObj(stream) {
        this.webinarConfService
            .updateLiveStream(
                this.callStateManagerService.meetingObj._id || this.callStateManagerService.meetingObj.meetingId,
                this.getStreamTypeShortForm(stream.streamPlatform),
                { enabled: false }
            )
            .subscribe(async () => {
                let eventData = INSIDE_EVENT.LIVE_STREAMING_POP_UP;
                eventData.clicked_text = 'delete';
                this.googleTagManager.pushGoogleTagData(eventData);
                // this.DeletePopup();
                await this.callStateManagerService.getLatestMeetingObj();
                this.checkStreamingOptions();
            });

        // if (stream.streamPlatform === 'youtube') {
        //     let eventData = EVENT_SETTINGS.DELETE_YOUTUBE;
        //     eventData.event_label = 'delete';
        //     this.googleTagManager.pushGoogleTagData(eventData);
        // }
        // if (stream.streamPlatform === 'facebook') {
        //     let eventData = EVENT_SETTINGS.DELETE_FACEBOOK;
        //     eventData.event_label = 'delete ';
        //     this.googleTagManager.pushGoogleTagData(eventData);
        // }
        // if (stream.streamPlatform === 'custom platform') {
        //     let eventData = EVENT_SETTINGS.DELETE_CUSTOM;
        //     eventData.event_label = 'delete ';
        //     this.googleTagManager.pushGoogleTagData(eventData);
        // }
    }

    isStreamUrlValid(payload) {
        return (
            (payload.streamPlatform === 'youtube' && !payload?.serverUrl?.toLowerCase().includes('youtube')) ||
            (payload.streamPlatform === 'facebook' && !payload?.serverUrl?.toLowerCase().includes('facebook')) ||
            (payload.streamPlatform === 'custom platform' && !this.utilService.isValidUrl(payload?.serverUrl))
        );
    }

    updateLiveStreamInMeetingObject(stream) {
        return new Promise((resolve, reject) => {
            let payload = {
                enabled: true,
                serverUrl: stream.serverUrl,
                streamKey: stream.streamKey,
                streamPageUrl: stream.streamPageUrl
                // streaming: src === 'start'
            };
            this.webinarConfService
                .updateLiveStream(
                    this.callStateManagerService.getCurrentMeetingId(),
                    this.getStreamTypeShortForm(stream.streamPlatform),
                    payload
                )
                .subscribe(
                    async (res) => {
                        this.showStreamDetailsForm = false;
                        await this.callStateManagerService.getLatestMeetingObj();
                        this.checkStreamingOptions();
                        resolve(res);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }

    getStreamTypeShortForm(type) {
        return type === 'youtube' ? 'yt' : type === 'facebook' ? 'fb' : 'custom';
    }

    async startLiveStream(stream) {
        // await this.updateLiveStreamInMeetingObject(stream, 'start');
        let eventType =
            stream.streamPlatform === 'youtube'
                ? INSIDE_EVENT.LIVE_ON_YOUTUBE_POPUP
                : stream.streamPlatform === 'facebook'
                ? INSIDE_EVENT.LIVE_ON_FACEBOOK_POPUP
                : INSIDE_EVENT.LIVE_ON_CUSTOM_RTMP_POPUP;
        eventType.clicked_text = 'start';
        await this.callStateManagerService.getLatestMeetingObj();
        if (!this.callStateManagerService.meetingObj.webinar.broadcasting) {
            eventType.outcome = 'failed';
            this.googleTagManager.addMeetingBasicInfo(eventType, this.gtmMeetingInfo);
            this.toastrService.error('Please broadcast first before live streaming');
            return;
        }

        this.webinarConfService
            .startLivestreaming(
                this.callStateManagerService.getCurrentMeetingId(),
                this.getStreamTypeShortForm(stream.streamPlatform)
            )
            .subscribe(
                async (res: any) => {
                    eventType.outcome = 'succeeded';
                    this.googleTagManager.addMeetingBasicInfo(eventType, this.gtmMeetingInfo);
                    if (!res.success || res.errors) {
                        this.toastrService.error(res?.errors || 'Something went wrong');
                        return;
                    }
                    this.updateListWithStatus();
                    this.callStateManagerService.getRoomConnectionStatus();
                    await this.callViewStateManagerService.getEventUpdates();
                    this.updateStreamingStatus();
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Live Stream',
                            eventAction: `${
                                stream.streamCategory === 'yt'
                                    ? 'Start on Youtube'
                                    : stream.streamCategory === 'fb'
                                    ? 'Start on Facebook'
                                    : 'Start on Custom RTMP'
                            }`,
                            eventType: 'app_event',
                            endpoint: 'webinarId/livestream/livestreamType/start',
                            status: 'success'
                        })
                        .subscribe();
                    // this.toggle(true);
                },
                (err) => {
                    eventType.outcome = 'failed';
                    this.googleTagManager.addMeetingBasicInfo(eventType, this.gtmMeetingInfo);
                    if (err?.error?.errors) {
                        this.toastrService.error(err?.error?.errors || 'Something went wrong');
                        return;
                    }
                    this.errors.global = 'Invalid URL';
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Live Stream',
                            eventAction: `${
                                stream.streamCategory === 'yt'
                                    ? 'Start on Youtube'
                                    : stream.streamCategory === 'fb'
                                    ? 'Start on Facebook'
                                    : 'Start on Custom RTMP'
                            }`,
                            eventType: 'app_event',
                            endpoint: 'webinarId/livestream/livestreamType/start',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
    }

    async stopLiveStreaming(stream) {
        // await this.updateLiveStreamInMeetingObject(stream, 'stop');
        this.webinarConfService
            .stopLivestreaming(
                this.callStateManagerService.getCurrentMeetingId(),
                this.getStreamTypeShortForm(stream.streamPlatform)
            )
            .subscribe(
                async (res: any) => {
                    if (!res.success) {
                        this.toastrService.error(res?.message || 'Something went wrong');
                        return;
                    }
                    this.updateListWithStatus();
                    this.callStateManagerService.getRoomConnectionStatus();
                    await this.callViewStateManagerService.getEventUpdates();
                    this.updateStreamingStatus();
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Live Stream',
                            eventAction: `${
                                stream.streamCategory === 'yt'
                                    ? 'Start on Youtube'
                                    : stream.streamCategory === 'fb'
                                    ? 'Start on Facebook'
                                    : 'Start on Custom RTMP'
                            }`,
                            eventType: 'app_event',
                            endpoint: 'webinarId/livestream/livestreamType/start',
                            status: 'success'
                        })
                        .subscribe();
                    // this.toggle(true);
                },
                (err) => {
                    this.errors.global = 'Invalid URL';
                    if (err?.error?.errors) {
                        this.toastrService.error(err?.error?.errors || 'Something went wrong');
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Live Stream',
                            eventAction: `${
                                stream.streamCategory === 'yt'
                                    ? 'Start on Youtube'
                                    : stream.streamCategory === 'fb'
                                    ? 'Start on Facebook'
                                    : 'Start on Custom RTMP'
                            }`,
                            eventType: 'app_event',
                            endpoint: 'webinarId/livestream/livestreamType/start',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
    }

    editStream(stream) {
        this.selectedStream = stream;
        this.createLiveStreamFormv2(stream);
        let eventData = INSIDE_EVENT.LIVE_STREAMING_POP_UP;
        eventData.clicked_text = 'edit';
        this.googleTagManager.pushGoogleTagData(eventData);
        this.showStreamDetailsForm = true;
        this.liveStreamCreationForm.streamType = stream?.streamCategory
            ? stream?.streamCategory === 'fb'
                ? 'Facebook'
                : stream?.streamCategory === 'yt'
                ? 'Youtube'
                : 'Custom'
            : '';
    }

    createLiveStream() {
        this.errors = {};
        let streamObj: any = {};
        streamObj = this.liveStreamCreationForm?.value;
        if (!streamObj.streamPageUrl || !streamObj?.streamPageUrl?.length) {
            streamObj.streamPageUrl = 'www.jioevents.com';
        }
        if (!streamObj.jiomeetId) streamObj.jiomeetId = this.callStateManagerService?.meetingObj?.jiomeetId;
        this.subscriptions.push(
            this.liveStreamService.createLiveStream(streamObj).subscribe(
                (data) => {
                    this.checkStreamingOptions();
                    if (this.streamingList.length !== 0) this.discardFormPopUp();
                    this.toastrService.info('Streaming channel added/created successfully');
                },
                (err) => {
                    let errorMeg = '';
                    if ([400, 412].includes(err?.error?.customCode)) {
                        if (err?.error?.errorsArray?.length) {
                            errorMeg = err.error.errorsArray[0]?.message;
                        } else if (err?.error?.errors) {
                            errorMeg = err.error.errors;
                        } else {
                            errorMeg = 'Something went wrong';
                        }
                    }
                    this.toastrService.error(errorMeg);
                    // this.loggerService.log('Unable to create live stream', err);
                }
            )
        );
    }

    updateLiveStream() {
        this.errors = {};
        let streamObj: any = {};
        streamObj = this.liveStreamCreationForm?.value;

        this.subscriptions.push(
            this.liveStreamService.updateLiveStream(this.selectedStream?._id, streamObj).subscribe(
                (data) => {
                    this.checkStreamingOptions();
                    this.discardFormPopUp();
                    this.toastrService.info(
                        "Live Stream Updated Successfully. This won't affect your ongoing streaming, your current streaming will continue to stream."
                    );
                },
                (err) => {
                    let errorMeg = '';
                    if ([400, 412].includes(err?.error?.customCode)) {
                        if (err?.error?.errorsArray?.length) {
                            errorMeg = err.error.errorsArray[0]?.message;
                        } else {
                            errorMeg = 'Something went wrong';
                        }
                    }
                }
            )
        );
    }

    getStreamCategory() {}

    stopLiveStream(stream) {
        let payload = {
            enabled: false,
            serverUrl: stream.serverUrl,
            streamKey: stream.streamKey,
            streamPageUrl: stream.streamPageUrl
        };
        this.liveStreamService
            .updateSingleLiveStream(
                this.roomConnectionService.getRoomInfo().jiomeetId,
                this.getStreamTypeShortForm(stream.streamCategory),
                payload
            )
            .subscribe(
                (res: any) => {
                    if (!res.success) {
                        this.toastrService.error(res?.message || 'Something went wrong');
                        return;
                    }
                    this.toggle(true);

                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Live Stream',
                            eventAction: `${
                                stream.streamCategory === 'yt'
                                    ? 'Start on Youtube'
                                    : stream.streamCategory === 'fb'
                                    ? 'Start on Facebook'
                                    : 'Start on Custom RTMP'
                            }`,
                            eventType: 'app_event',
                            endpoint: 'webinarId/livestream/livestreamType/stop',
                            status: 'success'
                        })
                        .subscribe();
                },
                (err) => {
                    this.toastrService.error(err.error.errors || 'Something went wrong. Please try again');
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Jioconf Live Stream',
                            eventAction: `${
                                stream.streamCategory === 'yt'
                                    ? 'Start on Youtube'
                                    : stream.streamCategory === 'fb'
                                    ? 'Start on Facebook'
                                    : 'Start on Custom RTMP'
                            }`,
                            eventType: 'app_event',
                            endpoint: 'webinarId/livestream/livestreamType/stop',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
    }

    handleStreamState(stream) {
        console.log(stream);
        if (!stream.streaming) {
            this.startLiveStream(stream);
        } else {
            this.stopLiveStreaming(stream);
        }
    }

    toggleStreamInfo(i) {
        this.streamingList[i].showStreamInfo = !this.streamingList[i].showStreamInfo;
    }
}
