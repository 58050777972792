<div class="header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <img src="assets/img/myjio/back.svg" routerLink="../integrations/myjio/profile" />
    </button>
    <div class="title">T&C</div>
</div>
<div class="tnc" [class.myJio]="isMyJio">
    <div class="gray__bg">
        <div class="container" *ngIf="!isLangHindi">
            <div class="row">
                <div class="col-12 col-lg-10 m-auto pt-4">
                    <div class="legal-terms">LEGAL TERMS</div>
                    <h2 class="pb-2 text-left page-title">Terms of Service</h2>
                    <div class="card radius-card card-format">
                        <div class="card-body">
                            <ol>
                                <li>
                                    <strong>SCOPE </strong>

                                    <ol>
                                        <li>
                                            This Platform known as "JioEvents" (“Platform”) is a multiplatform video
                                            conferencing Platform and portal, provided by Jio Platforms Limited
                                            (“Company”, “we” or “us”), located at Office- 101, Saffron, Near Centre
                                            Point, Panchwati 5 Rasta, Ambawadi, Ahmedabad.
                                        </li>
                                        <li>
                                            For the purposes of these Terms (herein defined below), “Event” shall mean a
                                            presentation, lecture, workshop or seminar that is transmitted over the web
                                            using video conferencing software, “Participant” shall mean a user who
                                            agrees to join and view the Event via the Platform, “Host” shall mean a
                                            person under whom the Event is scheduled, who is responsible to start and
                                            end the Event, remove, add, mute, unmute, prevent a Participant from joining
                                            the Events, lock the Event room as well as record the Event and download the
                                            Event analytics.
                                        </li>
                                        <li>
                                            The Platform provides to its users, the following services: (i) multi-party
                                            HD quality video conferencing; (ii) instant and scheduled invites to video
                                            conferencing; (iii) invite by various means such as (a) email; (b) SMS; (c)
                                            third party messaging services installed in the Participant’s Device (herein
                                            after defined); (iv) allows invitees to join the conference through the
                                            Platform installed in any compatible Device or through any compatible web
                                            browser installed in a laptop/desktop; (v) host annual general meeting
                                            (“AGM”) of a company; (vi) rich communication services involving (a)
                                            messaging services, where a Host can simultaneously send messages and also
                                            display images, contacts and location through screen sharing services; (b)
                                            file sharing services, where a Host can share files with third parties; and
                                            (c) chat services, including group chatting (collectively “Rich
                                            Communication Services” or “RCS”); and (vii) any other service as may be
                                            provided by the Company from time to time (collectively known as “Services”)
                                            on any compatible smart phones powered by Android, Microsoft windows or iOS
                                            and any compatible desktop; (“Device”).
                                        </li>
                                        <li>
                                            Your (“you”, “your” or “user”, “Participant”) use of the Platform is subject
                                            to these Terms and Conditions (“Terms”).
                                        </li>
                                        <li>
                                            Your use of the Platform on the Device requires that you agree to these
                                            Terms and the privacy policy of the Platform. By downloading the Platform
                                            and by registering or signing up to the Platform, or otherwise having access
                                            to, receiving, and/or using the Platform, you acknowledge to have read,
                                            understood and you consent to be governed and bound by these Terms and the
                                            privacy policy of the Platform. If you do not understand the Terms or the
                                            privacy policy of the Platform, or do not accept any part of them, then you
                                            should not use the Platform.
                                        </li>
                                        <li>
                                            In addition to these Terms, the terms and conditions of JioMeet (available
                                            at [*]) shall also apply in respect of the Event. By agreeing to these
                                            Terms, you acknowledge and agree to have read, understood and you consent to
                                            be governed by the terms of JioMeet for the purposes of using the Platform.
                                        </li>
                                        <li>
                                            The Terms are an electronic record in terms of the Information Technology
                                            Act, 2000 (as amended / re-enacted) (“IT Act") and rules thereunder, and is
                                            published in accordance with the provisions of Rule 3 (1) of the Information
                                            Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules,
                                            2021, which mandates for publishing of rules and regulations, privacy policy
                                            and terms of use for access or usage of Platform. This electronic record is
                                            generated by a computer system and does not require any physical or digital
                                            signatures.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>PRIVACY AND PROTECTION OF PERSONAL INFORMATION </strong>
                                    <ol>
                                        <li>
                                            Please refer to the privacy policy of the Platform available
                                            <a class="pointer" (click)="gotopp()">here.</a>
                                        </li>
                                        <li>
                                            Please note that your use of the Platform or Services on your Device and
                                            download thereof from Google play store/Apple app store may be subject to
                                            the respective privacy policies as may be published or made available by the
                                            Device or the Set-top Box and Google play store/Apple app store.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>MODIFICATIONS </strong>
                                    <ol>
                                        <li>
                                            Modifications of the Terms: The Company reserves the right to change the
                                            Terms under which the Services are offered, including but not limited to the
                                            charges, if any, associated with the use of the Services. The Terms may be
                                            further modified based on changes in the business, legal and regulatory
                                            requirements and will be updated online. We will make all efforts to
                                            communicate any significant changes to these Terms to you. You are
                                            encouraged to periodically visit this page to review these Terms and any
                                            changes to it.
                                        </li>
                                        <li>
                                            Modification of Services: The Company reserves the right to add, modify or
                                            delete any content or features available in the Services at any time at its
                                            sole discretion.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>LINKS TO THIRD PARTY SITES AND APPLICATIONS </strong>
                                    <ol>
                                        <li>
                                            The Platform may contain links to other web sites/applications ("Linked
                                            Sites"). The Linked Sites are not under the control of the Company and the
                                            Company is not responsible for the contents of any Linked Site, including
                                            without limitation any link contained in a Linked Site, or any changes or
                                            updates to a Linked Site or any information transmitted on a Linked Site.
                                            The inclusion of any link does not imply endorsement by the Company of the
                                            site or any association with its operators.
                                        </li>
                                        <li>
                                            Any dealings with third parties (including advertisers) included within or
                                            available via a link from the Platform or participation in promotions,
                                            including the delivery of and the payment for goods and services, and any
                                            other terms, conditions, warranties or representations associated with such
                                            dealings or promotions, are solely between you and the advertiser or other
                                            third party. The Company shall not be responsible or liable for any part of
                                            any such dealings or promotions.
                                        </li>
                                        <li>
                                            You acknowledge and agree that the use of any Linked Sites is governed by
                                            such third party’s terms of use, license agreement, privacy policy, or other
                                            such agreement. THE COMPANY DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY
                                            DISCLOSURE OF INFORMATION OR ANY OTHER PRACTICES OF ANY THIRD PARTY. THE
                                            COMPANY EXPRESSLY DISCLAIMS ANY WARRANTY WITH RESPECT TO YOUR PERSONAL OR
                                            OTHER INFORMATION THAT MAY BE COLLECTED, PROCESSED, SHARED OR RETAINED BY
                                            ANY THIRD PARTY.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>INTELLECTUAL PROPERTY RIGHTS </strong>
                                    <ol>
                                        <li>
                                            The Company shall own title, interest and rights, including all related
                                            intellectual property rights, in/of the Platform and/or your Device
                                            including but not limited to object code of the Platform, features of
                                            services, which is/are protected by the applicable laws except the content
                                            owned by the user. You acknowledge that such ownership shall include all
                                            intellectual property rights arising from any suggestions, enhancement
                                            requests, recommendations or other information provided by the user, whether
                                            in the course of use of the Platform, your Device or otherwise.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>GRANT OF RIGHTS </strong>
                                    <ol>
                                        <li>
                                            The Company allows the user(s) to share their screen while conducting video
                                            or audio conferencing with other user(s). The privacy policy for such
                                            sharing is available at <a class="pointer" (click)="gotopp()">here</a>.
                                        </li>
                                        <li>
                                            The Company allows the user who initiates the video or audio conferencing
                                            (“Host”) to record the video or audio conferencing. The privacy policy for
                                            such sharing is available at <a class="pointer" (click)="gotopp()">here</a>.
                                            Once the Host starts recording a video you would be able to see the
                                            recording sign. Your continued use of the Platform even after seeing the
                                            recording sign would be taken as your deemed consent. In case you do not
                                            wish for the audio or video conference to be recorded you may leave the
                                            conference at any time after you have noticed that the conference is being
                                            recorded.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>REGISTRATION</strong>
                                    <ol>
                                        <li>
                                            To join and/or view a Event you must do the following:
                                            <br />
                                            a. [*]
                                            <br />
                                            Below are the steps for joining the webinar:
                                            <br />
                                            a) Click on the given URL, link (by Host organization) or type given URL (by
                                            Host organization) in Web browser. Participant will get the login screen on
                                            browser.
                                            <br />
                                            b) Click on the login button
                                            <br />
                                            c) A participant may need to provide a user id and meeting PIN to login to
                                            the Webinar.
                                            <br />
                                            d) After successful login participant will be automatically redirected to
                                            the webinar webcast page
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>USE LIMITATIONS </strong>
                                    <ol>
                                        <li>
                                            The Platform is merely intended as a means for video or audio conferencing
                                            on multiple platforms.
                                        </li>
                                        <li>
                                            As a condition of your use of the Platform, you warrant to the Company that
                                            you will not use the Platform for any purpose that is unlawful or prohibited
                                            by these Terms. You may not use the Platform in any manner which could
                                            damage, disable, overburden or impair the Services or interfere with any
                                            other party's use and enjoyment of the Platform.
                                        </li>
                                        <li>
                                            You are not entitled to use any kind of measures, mechanisms or tools
                                            (software or hardware) that could interfere with the functioning of the
                                            Platform or any of its Services.
                                        </li>
                                        <li>
                                            You may not use the Services and/or Platform if (i) you have been convicted
                                            of any offence under applicable law; and/or (ii) if you are under the age of
                                            18 years.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>GENERAL </strong>
                                    <ol>
                                        <li>
                                            Use Restriction: You must not access the Platform if you are a person who is
                                            either barred or otherwise legally prohibited from receiving or using the
                                            Service or any Services under the laws of India.
                                            <br />

                                            a. You shall not host, display, upload, modify, publish, transmit, update or
                                            share any information which:
                                            <br />
                                            i. Belongs to another person and to which you do not have any right to;
                                            <br />
                                            ii. Is grossly harmful, harassing, blasphemous, defamatory, obscene,
                                            pornographic, paedophilic, libellous, invasive of another’s privacy,
                                            hateful, or racially, ethnically objectionable, disparaging, relating or
                                            encouraging money laundering or gambling or otherwise unlawful in any manner
                                            whatsoever; or unlawfully threatening or unlawfully harassing including but
                                            not limited to “indecent representation of women” within the meaning of the
                                            Indecent Representation of Women (Prohibition) Act 1986;
                                            <br />
                                            iii. Is misleading in any way;
                                            <br />
                                            iv. Is patently offensive to the online community, such as sexually explicit
                                            content, or content that promotes obscenity, paedophilia, racism, bigotry,
                                            hatred or physical harm of any kind against any group or individual;
                                            <br />
                                            v. Harassing or advocates harassment of another person;
                                            <br />
                                            vi. Involves the transmission of “junk mail”, “chained letters”, or
                                            unsolicited mass mailing or “spamming”;
                                            <br />
                                            vii. Promotes illegal activities or conduct that is abusive, threatening,
                                            obscene, defamatory or libellous;
                                            <br />
                                            viii. Infringes upon or violates any third party’s rights (including, but
                                            not limited to, intellectual property rights, rights of privacy or rights of
                                            publicity);
                                            <br />
                                            ix. Promotes an illegal or unauthorized copy of another person’s copyrighted
                                            work;
                                            <br />
                                            x. Provides instructional information about illegal activities such as
                                            making and/ or buying illegal weapons, violating someone’s privacy, or
                                            providing or creating computer viruses;
                                            <br />
                                            xi. Solicits gambling or engages in any gambling activity which we in our
                                            sole discretion believe is or could be construed to be as illegal;
                                            <br />
                                            xii. Harms minors in any way;
                                            <br />
                                            xiii. Violates any law for the time being in force;
                                            <br />
                                            xiv. Impersonates another person;
                                            <br />
                                            xv. Contains software viruses or any other computer code, files or programs
                                            designed to interupt, destroy or limit the functionality of any computer
                                            resource; or contains any trojan horses, worms, time bombs, cancelbots,
                                            easter eggs or other computer programming routines that may damage,
                                            detrimentally interfere with, diminish value of, surreptiously intercept or
                                            expropriate any system, data or personal information;
                                            <br />
                                            xvi. Threatens the unity, integrity, defence, security or sovereignty of
                                            India, friendly relations with foreign states, or public order or causes
                                            incitement to the commission of any cognizable offense or prevents
                                            investigation of any offense or is insulting any other nation;
                                            <br />
                                        </li>
                                        <li>
                                            Services may be made available for free of cost or for a specified cost as
                                            may be decided by the Company from time to time.
                                            <br />
                                        </li>
                                        <li>
                                            Cancellation: The host may cancel the Event at anytime and a notification
                                            via email will be sent to the Participants.
                                            <br />
                                        </li>
                                        <li>
                                            Recording: The Event may be recorded by the Host and such a recording may be
                                            sent to the Participants for their personal/non-personal use as stated
                                            above. By joining a Event, the Participant automatically consents to such
                                            recordings. If the Participant does not wish to consent to the recording, he
                                            is requested to contact the Host prior to the Event date to discuss his/her
                                            concerns.
                                            <br />
                                        </li>

                                        <li>
                                            Pricing and availability of the Services are subject to change any time
                                            prior to the purchase.
                                            <br />
                                        </li>
                                        <li>
                                            Third-Party Fees: You may incur access or data fees from third parties (such
                                            as your internet provider or mobile carrier) in connection with your use of
                                            Services and of Platform. You are solely responsible for all such fees.
                                            <br />
                                        </li>
                                        <li>
                                            Updates: You may need to install updates to the Platform or related software
                                            that we introduce from time to time to use of the Platform. Services
                                            originating from the Platform may communicate with the servers from time to
                                            time to check for available updates to the Services and to the functionality
                                            of the Platform, such as bug fixes, patches, enhanced functions, missing
                                            plug-ins and new versions (collectively, "Updates"). Your use of the
                                            Services you have installed requires that you have agreed to receive such
                                            automatically requested Updates.
                                            <br />
                                        </li>
                                        <li>
                                            Accuracy of Information: You agree that the information entered by you while
                                            profile creation/updation or using any of Services is true, current,
                                            complete and accurate in all respects. The Company is not responsible for
                                            any losses arising out of discrepancy in your data. You are solely
                                            responsible for maintaining confidentiality and security of your user
                                            account and for all activities that occur on or through your user account.
                                            The Company is not responsible for any losses arising out of the
                                            unauthorized use of your user account.
                                            <br />
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>LIMITATION OF LIABILITY </strong>
                                    <ol>
                                        <li>
                                            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE
                                            COMPANY BE LIABLE TO YOU FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE OR
                                            CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
                                            LOSS OF USE, LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF
                                            INFORMATION, OR ANY OTHER PECUNIARY LOSS) ARISING OUT OF THE USE OF, OR
                                            INABILITY TO USE OR ACCESS, THE APPLICATION OR FOR ANY SECURITY BREACH OR
                                            ANY VIRUS, BUG, UNAUTHORIZED INTERVENTION, DEFECT, OR TECHNICAL
                                            MALFUNCTIONING OF THE APPLICATION, WHETHER OR NOT FORESEEABLE OR WHETHER OR
                                            NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR
                                            BASED ON ANY THEORY OF LIABILITY, INCLUDING BREACH OF CONTRACT OR WARRANTY,
                                            NEGLIGENCE OR OTHER TORTIOUS ACTION, OR ANY OTHER CLAIM ARISING OUT, OF OR
                                            IN CONNECTION WITH, YOUR USE OF, OR ACCESS TO, THE APPLICATION OR THE
                                            CONTENT. FURTHER, THE COMPANY SHALL NOT BE LIABLE TO YOU FOR ANY TEMPORARY
                                            DISABLEMENT, PERMANENT DISCONTINUANCE OR MODIFICATION OF THE APPLICATION BY
                                            THE COMPANY OR FOR ANY CONSEQUENCES RESULTING FROM SUCH ACTIONS.
                                        </li>
                                        <li>
                                            YOU SHALL BE SOLELY RESPONSIBLE FOR OBTAINING AND MAINTAINING ANY EQUIPMENT
                                            AND ANCILLARY SERVICES (INCLUDING THE PAYMENT OF ANY ADDITIONAL FEES
                                            THEREFORE) REQUIRED TO CONNECT TO, ACCESS OR OTHERWISE USE THE SERVICES,
                                            INCLUDING, WITHOUT LIMITATION, VIDEO-ENABLED DEVICES, VIDEO COMMUNICATION
                                            SERVICES, MODEMS, HARDWARE, SERVERS, SOFTWARE, OPERATING SYSTEMS,
                                            NETWORKING, WEB SERVERS, INTERNET AND TELEPHONE SERVICE (COLLECTIVELY,
                                            “EQUIPMENT”). YOU WILL ALSO BE RESPONSIBLE FOR MAINTAINING THE SECURITY OF
                                            THE EQUIPMENT AND FOR ALL USES OF THE EQUIPMENT WITH OR WITHOUT YOUR
                                            KNOWLEDGE OR CONSENT.
                                        </li>
                                        <li>
                                            YOU ARE SOLELY RESPONSIBLE FOR THE CONTENT AND OTHER MATERIALS YOU TRANSMIT
                                            THROUGH THE SERVICE OR SHARE WITH OTHER USERS OR RECIPIENTS. YOU WILL NOT
                                            TRANSMIT ANY CONTENT THAT YOU DID NOT CREATE OR THAT YOU DO NOT OWN ALL
                                            RIGHT, TITLE AND INTEREST IN AND TO, INCLUDING, WITHOUT LIMITATION, ALL
                                            COPYRIGHT AND RIGHTS OF PUBLICITY CONTAINED THEREIN.
                                        </li>
                                        <li>
                                            THE COMPANY AND/OR ITS RESPECTIVE AFFILIATES MAKES NO REPRESENTATIONS ABOUT
                                            THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS AND ACCURACY OF THE
                                            INFORMATION, SOFTWARE AND SERVICES AND RELATED CONTENT CONTAINED ON THE
                                            APPLICATION FOR ANY PURPOSE. ALL SUCH INFORMATION, SOFTWARE AND SERVICES AND
                                            RELATED CONTENT ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. THE
                                            COMPANY AND/OR ITS RESPECTIVE SUPPLIERS HEREBY DISCLAIMS ALL WARRANTIES AND
                                            CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, AND SERVICES AND
                                            RELATED CONTENT, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF
                                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                                            NON-INFRINGEMENT
                                        </li>
                                        <li>
                                            YOU SPECIFICALLY AGREE THAT THE COMPANY SHALL NOT BE RESPONSIBLE FOR
                                            UNAUTHORISED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, ANY
                                            MATERIAL OR DATA SENT OR RECEIVED OR NOT SENT OR RECEIVED, OR ANY
                                            TRANSACTIONS ENTERED INTO THROUGH THE APPLICATION. YOU SPECIFICALLY AGREE
                                            THAT THE COMPANY IS NOT RESPONSIBLE OR LIABLE FOR ANY THREATENING,
                                            DEFAMATORY, OBSCENE, OFFENSIVE OR ILLEGAL SERVICES OR CONDUCT OF ANY OTHER
                                            PARTY OR ANY INFRINGEMENT. OF ANOTHER'S RIGHTS, INCLUDING INTELLECTUAL
                                            PROPERTY RIGHTS. YOU SPECIFICALLY AGREE THAT THE COMPANY IS NOT RESPONSIBLE
                                            FOR ANY SERVICES SENT USING AND/OR INCLUDED IN THE APPLICATION BY ANY THIRD
                                            PARTY.
                                        </li>
                                        <li>
                                            IN NO EVENT SHALL THE COMPANY AND/OR ITS AFFILIATES BE LIABLE FOR ANY
                                            DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
                                            ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR OR IN
                                            RELATION TO: (A) LOSS OF USE, DATA OR PROFITS ARISING OUT OF OR IN ANY WAY
                                            CONNECTED WITH THE USE OR PERFORMANCE OF APPLICATION; (B) ANY DELAY OR
                                            INABILITY TO USE THE APPLICATION OR SERVICES; (C) THE PROVISION OF OR
                                            FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION OR SERVICES OBTAINED
                                            THROUGH THE APPLICATION; (D) OR OTHERWISE ARISING OUT OF THE USE OF THE
                                            APPLICATION OR THE SERVICES OF THE COMPANY; WHETHER OR NOT ALL OF THE ABOVE
                                            LOSSES, CLAIMS OR DAMAGES ARE BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                                            LIABILITY, EVEN IF THE COMPANY AND/OR ITS AFFILIATES AND/OR ITS ASSOCIATES
                                            HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSSES, CLAIMS OR DAMAGES.
                                        </li>
                                    </ol>
                                </li>

                                <li>
                                    <strong>TERMINATION (TEMPORARY AND PERMANENT DISABLEMENT) </strong>
                                    <ol>
                                        <li>
                                            Unless otherwise provided in these Terms, the Services offered to you may be
                                            terminated by the Company without assigning any reasons at any time at the
                                            sole discretion of the Company. Upon termination / suspension, you shall not
                                            have the right to use or access the Platform/Services.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>GOVERNING LAW AND DISPUTE RESOLUTION </strong>
                                    <ol>
                                        <li>
                                            These terms and conditions are governed and construed in accordance with the
                                            laws of India. The courts in Mumbai shall have exclusive jurisdiction to
                                            hear disputes arising out of these terms.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>FORCE MAJEURE </strong>
                                    <ol>
                                        <li>
                                            The Company shall be under no liability whatsoever in the event of
                                            non-availability of any portion of the Platform or subscribed content
                                            occasioned by act of God, war, disease, revolution, riot, civil commotion,
                                            strike, lockout, flood, fire, failure of any public utility, manmade
                                            disaster, infrastructure failure or any other cause whatsoever beyond the
                                            control of the Company.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>SEVERABILITY </strong>
                                    <ol>
                                        <li>
                                            If any of the provisions of these Terms are deemed invalid, void, or for any
                                            reason unenforceable, that part of these Terms will be deemed severable and
                                            will not affect the validity and enforceability of any remaining provisions
                                            of these Terms.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>CONTACT DETAILS </strong>
                                    <ol>
                                        <li>
                                            Address: Office- 101, Saffron, Near Centre Point, Panchwati 5 Rasta,
                                            Ambawadi, Ahmedabad
                                            <br />Email:
                                            <a (click)="sendEmail()">enterpriseservices@jio.com</a>
                                            <br />
                                            Website:
                                            <a target="_blank" [href]="websiteLink">{{ websiteLink }}</a>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="isLangHindi">
            <div class="row">
                <div class="col-12 col-lg-10 m-auto pt-4">
                    <h2 class="pb-2 text-center" style="color: rgb(0, 0, 0)">जियोमीट के लिए नियम एवं शर्तें</h2>
                    <div class="card radius-card card-format">
                        <div class="card-body">
                            <ol>
                                <li>
                                    <strong>स्कोप</strong>
                                    <ol>
                                        <li>
                                            &quot;जियो प्लेटफ़ॉर्म लिमिटेड (“कंपनी”, “हम” या “हमें”) द्वारा प्रदान की गई
                                            &quot;&quot;जियोमीट&quot;&quot; (“ऐप्लिकेशन”) एक मल्टीप्लेटफ़ॉर्म वीडियो
                                            कॉन्फ्रेंसिंग ऐप्लिकेशन है, जिसका कार्यालय-101, सैफ़्रन, सेंटर पॉइंट के पास,
                                            पंचवटी 5 रास्ता, अम्बावाड़ी, अहमदाबाद में स्थित है।
                                        </li>
                                        <li>
                                            ऐप्लिकेशन अपने उपयोगकर्ताओं को, निम्नलिखित सेवाएं प्रदान करती है: (i)
                                            कई-लोगों के साथ एच.डी गुणवत्ता वीडियो कॉन्फ्रेंसिंग; (ii) वीडियो
                                            कॉन्फ्रेंसिंग के लिए त्वरित और अनुसूचित इन्वाइट; (iii) विभिन्न माध्यमों से
                                            इन्वाइट करना जैसे (क) ईमेल; (ख) एस.एम.एस; (ग) उपयोगकर्ता के डिवाइस में
                                            स्थापित थर्ड पार्टी के लिए संदेश सेवा (यहां परिभाषित करने के बाद); (iv)
                                            इन्वाइट किए गए लोगों को किसी भी उपयुक्त डिवाइस में स्थापित एप्लिकेशन के
                                            माध्यम से या लैपटॉप/डेस्कटॉप में स्थापित किसी भी उपयुक्त वेब ब्राउज़र के
                                            माध्यम से कॉन्फ्रेंस में शामिल होने की अनुमति देता है; (v) समृद्ध संचार
                                            सेवाएं जिसमें शामिल हैं (क) संदेश सेवाऍं, जहां उपयोगकर्ता एक साथ संदेश भेज
                                            सकते हैं और स्क्रीन शेयर करने जैसी सेवाओं के माध्यम से चित्र, संपर्क और
                                            स्थान भी दिखा सकते हैं; (ख) फ़ाइल शेयर करने जैसी सेवाएँ, जहाँ कोई उपयोगकर्ता
                                            थर्ड पार्टी के साथ फ़ाइलें शेयर कर सकता है; और (ग) ग्रुप चैट, (सामूहिक रूप
                                            से “रिच कम्युनिकेशन सर्विसेज़” या “आर.सी.एस”) सहित चैट सेवाएं; और (vi)
                                            एंड्रॉइड, माइक्रोसॉफ्ट विंडोज़ या आई.ओ.एस और किसी भी उपयुक्त सेट टॉप बॉक्स
                                            (“डिवाइस”) द्वारा संचालित किसी भी उपुक्त स्मार्ट फोन पर कंपनी द्वारा समय-समय
                                            पर प्रदान की जाने वाली कोई भी अन्य सेवा (जिसे सामूहिक रूप से “सेवाओं” के रूप
                                            में जाना जाता है)।
                                        </li>
                                        <li>
                                            आपके (“आप”, “आपका” या “उपयोगकर्ता”) ऐप्लिकेशन का उपयोग इन
                                            <a class="pointer">नियमों और शर्तों</a> (“नियम”) के अधीन है।
                                        </li>
                                        <li>
                                            डिवाइस पर इस ऐप्लिकेशन का उपयोग करने के लिए आवश्यक है कि आप इन शर्तों और
                                            ऐप्लिकेशन की <a class="pointer">गोपनीयता नीति</a> से सहमत हों। ऐप्लिकेशन को
                                            डाउनलोड करने और ऐप्लिकेशन में पंजीकृत करने या हस्ताक्षर करने से, या अन्यथा
                                            इसका एक्सेस प्राप्त करने, और/या ऐप्लिकेशन का उपयोग करने से, आप इस बात को
                                            स्वीकार करते हैं कि आपने इसे पढ़ा है और समझा है और इन नियमों और आवेदन की
                                            गोपनीयता नीति द्वारा नियंत्रित और बाध्य होने की सहमति दी है। यदि आप
                                            ऐप्लिकेशन की शर्तों या गोपनीयता नीति को नहीं समझते हैं, या उनमें से किसी भी
                                            भाग को स्वीकार नहीं करते हैं, तो आपको ऐप्लिकेशन का उपयोग नहीं करना चाहिए।
                                        </li>
                                        <li>
                                            ये शर्तें, सूचना प्रौद्योगिकी अधिनियम, 2000 ( यथा संशोधित / पुनः अधिनियम के
                                            रूप में ) (“आई.टी अधिनियम&quot;) और नियमों के अनुसार एक इलेक्ट्रॉनिक रिकॉर्ड
                                            हैं, और सूचना प्रौद्योगिकी ( मध्यस्थ दिशा निर्देशों ) नियम, 2011 के तहत, जो
                                            पॉलिसी और विनियम के प्रकाशन के लिए नीति और विनियम के उपयोग ऐप्लिकेशन तक
                                            पहुँच के लिए अनिवार्य है, के नियम 3 (1) के अनुसार प्रकाशित की जाती हैं। यह
                                            इलेक्ट्रॉनिक रिकॉर्ड एक कंप्यूटर सिस्टम द्वारा बनाया गया है जिसे किसी भी
                                            भौतिक या डिजिटल हस्ताक्षर की आवश्यकता नहीं है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>व्यक्तिगत जानकारी की गोपनीयता और सुरक्षा:</strong>
                                    <ol>
                                        <li>कृपया यहां उपलब्ध ऐप्लिकेशन की गोपनीयता नीति देखें।</li>
                                        <li>
                                            कृपया ध्यान दें कि आपके डिवाइस पर ऐप्लिकेशन या सेवाओं का उपयोग और उसके बाद
                                            गूगल प्ले स्टोर/एप्पल ऐप स्टोर से डाउनलोड करना संबंधित गोपनीयता नीतियों के
                                            अधीन हो सकता है जैसा कि डिवाइस द्वारा या सेट-टॉप बॉक्स और गूगल प्ले
                                            स्टोर/एप्पल ऐप स्टोर से प्रकाशित या उपलब्ध कराया जा सकता हो।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>संशोधन</strong>
                                    <ol>
                                        <li>
                                            शर्तों का संशोधन: कंपनी उन शर्तों को बदलने का अधिकार सुरक्षित रखती है जिनके
                                            तहत सेवाएं प्रदान की जाती हैं, ये शर्तें सेवाओं के उपयोग से जुड़े शुल्क, यदि
                                            कोई हो, तक ही सीमित नहीं हैं। व्यापार, कानूनी और नियामक आवश्यकताओं में
                                            परिवर्तन के आधार पर शर्तों को और संशोधित किया जा सकता है और इन्हें ऑनलाइन
                                            अपडेट कर दिया जाएगा। हम इन शर्तों में किसी भी महत्वपूर्ण परिवर्तन हेतु आप के
                                            लिए सभी प्रयास करेंगे। आपको इन शर्तों और इसमें किसी भी बदलाव की समीक्षा करने
                                            के लिए समय-समय पर इस पेज पर जाने के लिए प्रोत्साहित किया जाता है।
                                        </li>
                                        <li>
                                            सेवाओं का संशोधन: कंपनी अपने स्वविवेक के अनुसार किसी भी समय सेवाओं में
                                            उपलब्ध किसी भी कंटेंट या फ़ीचर को जोड़ने, संशोधित करने या डिलीट करने का
                                            अधिकार सुरक्षित रखती है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>थर्ड पार्टी की साइट और ऐप्लिकेशन के लिंक :</strong>
                                    <ol>
                                        <li>
                                            ऐप्लिकेशन में अन्य वेब साइट/ऐप्लिकेशन (&quot;लिंक्ड साइट्स&quot;) के लिंक हो
                                            सकते हैं। लिंक की गई साइट कंपनी के नियंत्रण में नहीं होती हैं और कंपनी किसी
                                            भी लिंक की गई साइट के कंटेंट के लिए ज़िम्मेदार नहीं है, जिसमें लिंक की गई
                                            साइट में निहित किसी भी लिंक को, या लिंक की गई साइट पर किसी भी परिवर्तन या
                                            अपडेट या लिंक की गई साइट पर प्रेषित किसी भी जानकारी को सीमित नहीं किया जाता
                                            है। किसी भी लिंक को शामिल करने का आशय साइट की कंपनी या इसके प्रचालकों के साथ
                                            कोई संबंध नहीं है।
                                        </li>
                                        <li>
                                            ऐप्लिकेशन या विज्ञापन के प्रसार के लिंक के माध्यम से उपलब्ध थर्ड पार्टी
                                            (विज्ञापनदाताओं सहित) के साथ कोई भी डील, या इस तरह की डील या प्रचार-प्रसार
                                            से जुड़ी वस्तुओं और सेवाओं के लिए पेमेंट, और ऐसी डील से संबंधित कोई भी अन्य
                                            नियम, शर्तें, वॉरंटी, केवल आपके और विज्ञापनदाता या अन्य थर्ड पार्टी के बीच
                                            होती हैं। कंपनी ऐसी किसी भी डील या प्रचार के लिए जिम्मेदार या उत्तरदायी नहीं
                                            होगी।
                                        </li>
                                        <li>
                                            आप स्वीकार करते हैं और सहमत होते हैं कि किसी भी लिंक की गई साइट का उपयोग ऐसे
                                            थर्ड पार्टी के उपयोग की शर्तों, लाइसेंस एग्रीमेंट, गोपनीयता नीति, या इस तरह
                                            के अन्य एग्रीमेंट से नियंत्रित होते हैं। किसी भी जानकारी के सार्वजनिक होने
                                            या किसी थर्ड पार्टी द्वारा होने वाले अन्य कायों के लिए कंपनी किसी भी प्रकार
                                            की जिम्मेदारी नहीं लेती है। कंपनी आपकी ऐसी किसी भी व्यक्तिगत या अन्य
                                            जानकारी, जो किसी थर्ड पार्टी द्वारा एकत्रित, संसाधित, शेयर की या रखी जा सकती
                                            है, के संबंध में स्पष्ट रूप से किसी भी वारंटी को अस्वीकार करती है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>बौद्धिक संपदा अधिकार:</strong>
                                    <ol>
                                        <li>
                                            ऐप्लिकेशन और/या आपके डिवाइस में/से संबंधित सभी बौद्धिक संपदा अधिकारों सहित,
                                            शीर्षक, हित और अधिकारों का स्वामित्व कंपनी के पास होगा, जिसमें उपयोगकर्ता के
                                            स्वामित्व वाले कंटेंट को छोड़कर कानून द्वारा संरक्षित ऐप्लिकेशन के ऑब्जेक्ट
                                            कोड, सेवाओं के फ़ीचर शामिल है/हैं, लेकिन सीमित नहीं हैं। आप स्वीकार करते हैं
                                            कि इस तरह के स्वामित्व में किसी भी सुझाव, वृद्धि अनुरोधों, सुझावों या
                                            उपयोगकर्ता द्वारा प्रदान की गई अन्य जानकारी से उत्पन्न सभी बौद्धिक संपदा
                                            अधिकार शामिल होंगे, चाहे वे ऐप्लिकेशन, आपके डिवाइस के उपयोग के दौरान या
                                            अन्यथा हों।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>अधिकारों का लाभ:</strong>
                                    <ol>
                                        <li>
                                            कंपनी उपयोगकर्ता(उपयोगकर्ताओं) को अन्य उपयोगकर्ता(उपयोगकर्ताओं) के साथ
                                            वीडियो या ऑडियो कॉन्फ्रेंसिंग करते हुए अपनी स्क्रीन शेयर करने की अनुमति देती
                                            है। इस प्रकार स्क्रीन शेयर करने के लिए गोपनीयता नीति
                                            <a class="pointer"> यहां </a> उपलब्ध है।
                                        </li>
                                        <li>
                                            कंपनी उस उपयोगकर्ता को वीडियो या ऑडियो कॉन्फ्रेंसिंग रिकॉर्ड करने की अनुमति
                                            देती है जो वीडियो या ऑडियो कॉन्फ्रेंसिंग (“होस्ट”) शुरू करता है। इस प्रकार
                                            स्क्रीन शेयर करने के लिए गोपनीयता नीति <a class="pointer"> यहां </a> उपलब्ध
                                            है। एक बार होस्ट वीडियो रिकॉर्ड करना शुरू कर देगा, तब आप रिकॉर्डिंग के साइन
                                            को देख पाएंगे। रिकॉर्डिंग का साइन देखने के बाद भी आपके द्वारा ऐप्लिकेशन का
                                            उपयोग जारी रखना, आपकी सहमति के रूप में लिया जाएगा। यदि आप ऑडियो या वीडियो
                                            कॉन्फ्रेंस रिकॉर्ड नहीं करना चाहते हैं, तो कॉन्फ्रेंस रिकॉर्ड हो रही है यह
                                            देखने के बाद आप किसी भी समय कॉन्फ्रेंस छोड़ सकते हैं।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>सीमाओं का उपयोग करेंः</strong>
                                    <ol>
                                        <li>
                                            ऐप्लिकेशन कई प्लेटफ़ॉर्म पर वीडियो या ऑडियो कॉन्फ्रेंस के लिए मात्र एक साधन
                                            के रूप में नियत है।
                                        </li>
                                        <li>
                                            ऐप्लिकेशन उपयोग करने की शर्त के रूप में, आप कंपनी को इस बात का विश्वास
                                            दिलाते हैं कि आप किसी भी ऐसे उद्देश्य के लिए ऐप्लिकेशन का उपयोग नहीं करेंगे
                                            जो गैरकानूनी या इन शर्तों के तहत निषिद्ध है। आप एप्लिकेशन का उपयोग इस प्रकार
                                            से नहीं कर सकते हैं, जिससे सेवाओं को नुकसान पहुंचे, वे अक्षम हो जायें, इन पर
                                            अतिभार आ जाए या वे कार्य करना बंद कर दें या जिससे किसी अन्य पार्टी द्वारा
                                            उपयोग और ऐप्लिकेशन के लाभ प्राप्त करने में बाधा उत्पन्न हो।
                                        </li>
                                        <li>
                                            आप किसी भी प्रकार के मापदंडों, तंत्रों या उपकरणों (सॉफ़्टवेयर या हार्डवेयर)
                                            का उपयोग करने के हकदार नहीं हैं जो ऐप्लिकेशन या इसकी किसी भी सेवा के कार्य
                                            में बाधा डाल सकते हैं।
                                        </li>
                                        <li>
                                            आप सेवाओं और/या ऐप्लिकेशन का उपयोग नहीं कर सकते हैं यदि (i) आपको लागू कानून
                                            के तहत किसी अपराध का दोषी ठहराया गया है; और/या (ii) यदि आप 18 वर्ष से कम आयु
                                            के हैं।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>जियोमीट (इंटरप्राइज़ वर्ज़न):</strong>
                                    <ol>
                                        <li>
                                            इंटरप्राइज़, बतौर व्यक्ति, कंपनी, सीमित देयता भागीदारी फर्म, साझेदारी फर्म,
                                            पंजीकृत सोसाइटी, ट्रस्ट और/या व्यक्तियों के संघठन (“एंटरप्राइज”) द्वारा अपने
                                            कर्मचारियों के माध्यम से इस ऐप्लिकेशन के उपयोग के लिए निम्नलिखित शर्तें
                                            अतिरिक्त रूप से लागू होंगी:
                                            <div></div>
                                            <ol>
                                                <li>
                                                    पंजीकरण: ऐप्लिकेशन के माध्यम से सेवाओं का उपयोग करने के लिए, अपने
                                                    कर्मचारियों द्वारा उपयोग के उद्देश्य से ऐप्लिकेशन के लाभ प्राप्त
                                                    करने के लिए इंटरप्राइज़ को एडमिनिस्ट्रेटर अकाउंट (“एडमिनिस्ट्रेटर
                                                    अकाउंट”) के लिए साइनअप करना होगा, जो वाणिज्यिक शर्तों और कर्मचारी
                                                    खाता सीमा के अधीन होगा, जैसा कि कंपनी द्वारा निर्दिष्ट किया जा सकता
                                                    है, और समय-समय पर कंपनी द्वारा अपडेट और/या संशोधित किया जा सकता है।
                                                </li>
                                                <li>
                                                    सफलतापूर्वक बनाए जाने के बाद एडमिनिस्ट्रेटर अकाउंट का उपयोग
                                                    इंटरप्राइज़ द्वारा ऐसे कर्मचारी खाते खोलने या बनाने के लिए किया जाएगा
                                                    जिसे धारा 7.1.1 (प्रत्येक “कर्मचारी खाता”) के अनुसार इंटरप्राइज़
                                                    द्वारा प्राप्त किया जा सकता है।
                                                </li>
                                                <li>
                                                    बनाए गए कर्मचारी अकाउंट स्थानांतरित नहीं किए जाएंगे और इंटरप्राइज़
                                                    द्वारा ऐसे उपयोग के लिए अधिकृत किए जा सकेंगे। पूर्वगामी के बावजूद
                                                    लेकिन अनुपालन सुनिश्चित करने के लिए एंटरप्राइज़ के पूर्ण दायित्व को
                                                    प्रभावित किये बिना, एंटरप्राइज़ के आंतरिक व्यावसायिक उद्देश्यों के
                                                    लिए कर्मचारियों सहित एंटरप्राइज़ द्वारा अपने ऐप्लिकेशन का उपयोग सख्त
                                                    रूप से किया जाएगा, और हर समय, कंपनी द्वारा समय समय पर निर्धारित
                                                    सीमाओं सहित ऐसे नियमों और शर्तों के अनुसार होगा।
                                                </li>
                                                <li>
                                                    इंटरप्राइज़ हमें अपने या आपकी कंपनी के अधिकृत कर्मियों के बारे में
                                                    सही, पूर्ण, और अपडेट की हुई पंजीकरण जानकारी प्रदान करने का आश्वासन
                                                    देता है।
                                                </li>
                                                <li>
                                                    एडमिनिस्ट्रेटर अकाउंट और प्रत्येक कर्मचारी खाते को इन शर्तों के
                                                    प्रयोजनों के लिए एक उपयोगकर्ता के रूप में माना जाएगा और जहाँ भी
                                                    उपयोगकर्ता नियोजित होगा, वहां वो मान्य होगा और एडमिनिस्ट्रेटर अकाउंट
                                                    के साथ साथ प्रत्येक कर्मचारी अकाउंट इसमें शामिल होगा।
                                                </li>
                                                <li>
                                                    किसी इंटरप्राइज़ द्वारा एडमिनिस्ट्रेटर अकाउंट के एक्टिवेट होने पर,
                                                    इंटरप्राइज़ को मासिक सदस्यता के साथ इंटरप्राइज़ व कंपनी के बीच हुई
                                                    सहमति के आधार पर शुल्क देना होगा। संदेह से बचने के लिए, इंटरप्राइज़
                                                    से मासिक सदस्यता शुल्क लिया जाएगा भले ही एडमिनिस्ट्रेटर अकाउंट या
                                                    संबंधित उपयोगकर्ता अकाउंट सक्रिय हों या नहीं।
                                                </li>
                                                <li>
                                                    एडमिनिस्ट्रेटर अकाउंट या संबंधित उपयोगकर्ता अकाउंट एक्टिव हो जाएंगे
                                                    और इस तरह की सदस्यता हर बिलिंग महीने में स्वचालित रूप से नवीनीकृत हो
                                                    जाएगी, जब तक कि एंटरप्राइज़ समय-समय पर कंपनी द्वारा निर्धारित रूप से
                                                    सदस्यता को रद्द नहीं करता है।
                                                </li>
                                                <li>
                                                    कंपनी सेवा की आंशिक अवधि, या जिन महीनों में उपयोग नहीं किया गया है
                                                    उसके लिए रिफ़ंड या क्रेडिट नहीं देती है। कृपया ध्यान दें कि यदि
                                                    एंटरप्राइज़ एडमिनिस्ट्रेटर अकाउंट या किसी उपयोगकर्ता अकाउंट को रद्द
                                                    कर देता है, तब भी एंटरप्राइज़ को पिछले महीने के दौरान खर्च किए गए
                                                    किसी भी शुल्क के लिए अगले भुगतान चक्र में बिल भेजा जाएगा।
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>सामान्य:</strong>
                                    <ol>
                                        <li>
                                            प्रतिबंध का उपयोग करें: यदि आप ऐसे व्यक्ति हैं, जो भारत के कानूनों के तहत
                                            किसी भी सेवा को प्राप्त करने या उसका उपयोग करने से प्रतिबंधित हैं या कानूनी
                                            रूप से निषिद्ध हैं, तो आपको ऐप्लिकेशन का उपयोग नहीं करना चाहिए।
                                        </li>
                                        <li>
                                            समय-समय पर कंपनी द्वारा नि:शुल्क या निर्धारित लागत पर सेवाएं उपलब्ध कराई जा
                                            सकती हैं।
                                        </li>
                                        <li>
                                            सेवाओं की कीमत और उपलब्धता खरीद से पहले किसी भी समय परिवर्तन के अधीन हैं
                                        </li>
                                        <li>
                                            तीसरे-पक्ष के शुल्क: आप तीसरे पक्ष से अपनी सेवाओं और ऐप्लिकेशन के उपयोग से
                                            जुड़े (जैसे कि आपका इंटरनेट प्रदाता या मोबाइल वाहक) एक्सेस या डेटा शुल्क
                                            प्राप्त कर सकते हैं। आप इस तरह के सभी शुल्क के लिए स्वयं पूरी तरह जिम्मेदार
                                            हैं।
                                        </li>
                                        <li>
                                            अपडेट: आपको एप्लिकेशन या संबंधित सॉफ़्टवेयर के लिए अपडेट इंस्टॉल करने की
                                            आवश्यकता हो सकती है जिसे हम समय-समय पर ऐप्लिकेशन के उपयोग के लिए प्रस्तुत
                                            करते हैं। सेवाओं से उपलब्ध अपडेट देखने के लिए और बग फिक्स, पैचेस, बढ़ाए गए
                                            फ़ंक्शन, अनुपस्थित प्लग-इन और नए वर्ज़न (सामूहिक, &quot;अपडेट&quot;) जैसी
                                            ऐप्लिकेशन की कार्यक्षमता को देखने के लिए ऐप्लिकेशन से शुरू होने वाली सेवाएं
                                            समय-समय पर सर्वर से सम्पर्क कर सकती हैं । आपके द्वारा इंस्टॉल की गई सेवाओं
                                            के उपयोग के लिए आवश्यक है कि आप ऐसे स्वचालित रूप से अनुरोध किए गए अपडेट
                                            प्राप्त करने के लिए सहमत हैं।
                                        </li>
                                        <li>
                                            सूचना की सटीकता: आप सहमत हैं कि प्रोफ़ाइल बनाते/अपडेट करते समय या किसी
                                            सेवाओं का उपयोग करते समय आपके द्वारा दर्ज की गई जानकारी सभी तरह से सही,
                                            वर्तमान की, पूर्ण और सटीक है। आपके डेटा में अंतर से उत्पन्न होने वाले किसी
                                            भी नुकसान के लिए कंपनी जिम्मेदार नहीं है। आप अपने उपयोगकर्ता अकाउंट की
                                            गोपनियता और उसे सुरक्षित बनाए रखने और अपने उपयोगकर्ता अकाउंट पर या उसके
                                            माध्यम से होने वाली सभी गतिविधियों के लिए पूरी तरह से स्वयं जिम्मेदार हैं।
                                            कंपनी आपके उपयोगकर्ता अकाउंट के अनाधिकृत उपयोग से उत्पन्न होने वाले किसी भी
                                            नुकसान के लिए जिम्मेदार नहीं है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>दायित्व की सीमा :</strong>
                                    <ol>
                                        <li>
                                            लागू होने वाले कानून द्वारा निर्धारित अधिकतम सीमा तक किसी भी विशेष, आकस्मिक,
                                            अप्रत्यक्ष, दंडनीय, या निरंतर नुकसान के लिए, कंपनी आपके प्रति उत्तरदायी नहीं
                                            होगी (बिना किसी सीमा के, उपयोग न करने के नुकसान, व्यवसाय लाभ प्राप्त नहीं कर
                                            पाने से नुकसान, व्यवसाय में बाधा, जानकारी के नुकसान, या किसी भी अन्य गुप्त
                                            हानि सहित) जो इस ऐप्लिकेशन के उपयोग, या उसे एक्सेस करने में अक्षमता, या किसी
                                            भी प्रकार के सुरक्षा नियमों को तोड़े जाने, या किसी वायरस, या बग, अनाधिकृत
                                            हस्तक्षेप, खराबी, या ऐप्लिकेशन के तकनीकी विश्लेषण, एप्लीकेशन में गड़बड़ी, चाहे
                                            वो पहले से देखी गई हो या नहीं, या कंपनी ने किसी क्षति के बारे में पहले से
                                            बताया हो, या अनुबंध अथवा वारंटी को तोड़ने के उत्तरदायित्व, या लापरवाही या
                                            अनुचित कार्य या एप्लीकेशन अथवा इसके कंटेंट के उपयोग से सम्बंधित अन्य दावे के
                                            कारण हुआ हो। इसके इलावा, कंपनी आप के द्वारा किसी भी अस्थायी अयोग्यता, स्थायी
                                            रूप से बंद करने या कंपनी द्वारा ऐप्लिकेशन के संशोधन या इस तरह के कार्यों के
                                            परिणामस्वरूप होने वाले प्रभाव के लिए उत्तरदायी नहीं होगी।
                                        </li>
                                        <li>
                                            आप किसी भी उपकरण और कनेक्ट करने के लिए, सेवाओं के उपयोग या उसके एक्सेस के
                                            लिए आवश्यक सहायक सेवाओं (किसी भी अतिरिक्त शुल्क का भुगतान शामिल है) इसमें
                                            शामिल हैं, बिना किसी सीमा के, वीडियो-सक्षम डिवाइस, वीडियो संचार सेवाएं,
                                            मॉडेम, हार्डवेयर, सर्वर, सॉफ़्टवेयर, ऑपरेटिंग सिस्टम, नेटवर्क, वेब सर्वर,
                                            इंटरनेट और टेलीफ़ोन सेवा (सामूहिक, “उपकरण”) को प्राप्त करने और उसके रखरखाव
                                            के लिए पूरी तरह से स्वयं जिम्मेदार होंगे। आप आपनी जानकारी या सहमति के साथ या
                                            उसके बिना उपकरण की सुरक्षा बनाए रखने और उपकरण के सभी उपयोगों के लिए भी
                                            जिम्मेदार होंगे।
                                        </li>
                                        <li>
                                            सेवा के माध्यम से या अन्य उपयोगकर्ताओं या प्राप्तकर्ताओं के साथ आपके द्वारा
                                            शेयर किए गए या संचारित किए गए कंटेंट और अन्य चीज़ों के लिए आप पूरी तरह से
                                            स्वयं जिम्मेदार हैं। आप ऐसे किसी भी कंटेंट का संचार नहीं करेंगे जो आपने नहीं
                                            बनाया है या जो आपके सभी अधिकार, शीर्षक और हित में नहीं आते हों, जिसमें उसमें
                                            निहित प्रचार के सभी कॉपीराइट और अधिकार शामिल हैं।
                                        </li>
                                        <li>
                                            कंपनी और/या उससे संबन्धित संबद्ध किसी प्रयोजन के लिए ऐप्लिकेशन पर निहित
                                            सूचना, सॉफ़्टवेयर और सेवाओं और उससे संबंधित कंटेंट की उपयुक्तता, विश्वसनीयता,
                                            उपलब्धता, समयबद्धता के बारे में नहीं बताते हैं। ऐसी सभी जानकारी, सॉफ़्टवेयर
                                            और सेवाएँ और उनसे संबंधित कंटेंट किसी भी प्रकार की वारंटी के बिना &quot;जैसी
                                            हैं&quot; प्रदान की जाती हैं। कंपनी और/या इससे संबंधित आपूर्तिकर्ता इस
                                            सूचना, सॉफ्टवेयर और सेवाओं और इससे संबंधित कंटेंट से जुड़े सभी वारंट और
                                            शर्तों को अस्वीकार करते हैं, जिसमें व्यापारी योग्यता की सभी अंतर्निहित
                                            वारंटियां और शर्तें, किसी विशेष प्रयोजन के लिए उपयुक्तता, शीर्षक और उल्लंघन
                                            नहीं करना शामिल है।
                                        </li>
                                        <li>
                                            आप विशेष रूप से सहमत हैं कि कंपनी आपके संचारण या डेटा, किसी भी चीज़ या भेजे
                                            गए या प्राप्त किए गए या नहीं भेजे गए या नहीं प्राप्त किए गए डेटा या
                                            ऐप्लिकेशन के माध्यम से होने वाले किसी भी लेन-देन के अनधिक्रत एक्सेस या बदलाव
                                            के लिए जिम्मेदार नहीं होगी। आप विशेष रूप से स्वीकार करते हैं कि किसी भी
                                            धमकी, निंदा, बहिष्कार, अप्रिय या अवैध सेवाओं या किसी अन्य पक्ष या बौद्धिक
                                            संपदा अधिकारों सहित किसी अन्य के अधिकारों के उल्लंघन के लिए कंपनी किसी भी
                                            तरह से जिम्मेदार नहीं है। आप विशेष रूप से यह स्वीकार करते हैं कि कंपनी किसी
                                            भी थर्ड पार्टी द्वारा ऐप्लिकेशन का उपयोग करके भेजी गई सेवा और/या उसमें
                                            सम्मिलित सेवा के लिए जिम्मेदार नहीं है।
                                        </li>
                                        <li>
                                            किसी भी हालत में कंपनी और/या उससे संबद्ध इकाइयों में कोई भी प्रत्यक्ष,
                                            अप्रत्यक्ष, दंडनीय, आकस्मिक, विशेष, परिणामी नुकसान या ऐसा कोई भी नुक्सान
                                            जिसकी कोई सीमा निर्धारित नहीं है, निम्न से सम्बन्धी नुक्सान के लिए उत्तरदायी
                                            नहीं होंगी : (A) ऐप्लिकेशन के उपयोग या उसके प्रदर्शन के कारण नहीं कर पाने,
                                            डेटा इनमें से किसी के भी कारण होने वाले नुक्सान या उभरने वाला लाभ या
                                            ऐप्लिकेशन के उपयोग या उसके साथ जुड़े होने की संभावना; (B) ऐप्लिकेशन या
                                            सेवाओं का उपयोग करने के लिए कोई भी देरी या अक्षमता; (C) सेवाओं को प्रदान
                                            करने के लिए या किसी भी जानकारी या सेवाओं के लिए लागू की जाने वाली सुविधा जो
                                            ऐप्लिकेशन लागू की गई है; (D) या कंपनी की ऐप्लिकेशन या सेवाओं के उपयोग से
                                            उत्पन्न; भले ही उपरोक्त सभी नुकसान, दावे या क्षति अनुबंध पर आधारित हों या
                                            नहीं, अपकृत्य, लापरवाही, सख्त देयता, भले ही कंपनी और/या इससे संबद्ध और/या
                                            इसके सहयोगियों को इस तरह के नुकसान, दावे या क्षति की संभावना होने की सलाह दी
                                            गई हो।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>बंद करना (अस्थायी और स्थायी रूप से बंद करना):</strong>
                                    <ol>
                                        <li>
                                            इन शर्तों में नहीं दिए जाने के बावजूद, आपको दी जाने वाली सेवाएं कंपनी द्वारा
                                            किसी भी समय बिना किसी कारण को बताए कंपनी के पूर्ण अधिकार स्वरुप समाप्त की जा
                                            सकती हैं। बंद करने/समाप्त करने पर, आपको ऐप्लिकेशन/सेवाओं का उपयोग करने या
                                            उनके एक्सेस का अधिकार नहीं होगा।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>कानून और विवाद समाधान:</strong>
                                    <ol>
                                        <li>
                                            ये नियम और शर्तें भारत के कानून के अनुसार बनाई गई हैं और उसके अनुसार
                                            नियंत्रित हैं। इन शर्तों से उत्पन्न विवादों को सुनने के लिए मुंबई की अदालतों
                                            में विशेष क्षेत्राधिकार है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>अप्रत्याशित घटना:</strong>
                                    <ol>
                                        <li>
                                            किसी दैवीय घटना, युद्ध, बीमारी, क्रांति, दंगा, शहर में हंगामा, हड़ताल,
                                            तालाबंदी, बाढ़, आग, किसी भी सार्वजनिक उपयोगिता की विफलता, मानव निर्मित आपदा,
                                            बुनियादी ढांचे की विफलता या किसी अन्य कारण से जो कंपनी के नियंत्रण में नहीं
                                            है, के कारण ऐप्लिकेशन या सब्सक्राइब किये हुए कंटेंट के किसी भाग की
                                            अनुपलब्ध्ता के लिए कंपनी उत्तरदायी नहीं होगी।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>गंभीरता:</strong>
                                    <ol>
                                        <li>
                                            यदि इन शर्तों के किसी भी प्रावधान को अमान्य, शून्य, या किसी भी कारण से
                                            अप्राप्य माना जाता है, तो शर्तों के उस भाग को गंभीर माना जाएगा और यह किसी भी
                                            शेष प्रावधान की मान्यता और प्रवर्तनीयता को प्रभावित नहीं करेगा।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>संपर्क विवरण::</strong>
                                    <ol>
                                        <li>
                                            पता: कार्यालय- 101, सैफ़्रन, सेंटर पॉइंट के पास, पंचवटी 5 रास्ता, अंबावाड़ी,
                                            अहमदाबाद <br />
                                            ईमेल:
                                            <a href="mailto:enterpriseservices@jio.com">enterpriseservices@jio.com</a
                                            ><br />
                                            वेबसाइट:
                                            <a target="_blank" href="https://jiomeetpro.jio.com/"
                                                >https://jiomeetpro.jio.com</a
                                            >
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                        <a
                            class="monthly-report"
                            target="_blank"
                            href="assets/public/monthly-reports/Monthly report to Meity_June21.pdf"
                            >Monthly compliance report</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
