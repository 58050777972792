<app-info-modal
    [modalHeading]="'Audio Results'"
    [iconName]="'microphone-blue'"
    (closeModalEvent)="closeAudioResultModal()"
>
    <!-- Your login-specific content goes here -->
    <div class="result-container">
        <div class="device-section input">
            <div class="device-heading">Input</div>
            <div class="single-info">
                <div class="info-heading">Test Duration</div>
                <div class="info-result">{{ microphoneResult.testTiming.duration / 1000 }} Seconds</div>
            </div>
            <div class="single-info">
                <div class="info-heading">Device ID</div>
                <div class="info-result">{{ microphoneResult.deviceId }}</div>
            </div>
        </div>
        <div class="vertical-line"></div>
        <div class="device-section output">
            <div class="device-heading">Output</div>
            <div class="single-info">
                <div class="info-heading">Test Duration</div>
                <div class="info-result">{{ speakerResult.testTiming.duration / 1000 }} Seconds</div>
            </div>
            <div class="single-info">
                <div class="info-heading">Device ID</div>
                <div class="info-result">{{ speakerResult.deviceId }}</div>
            </div>
        </div>
    </div>
</app-info-modal>
