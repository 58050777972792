<div class="call-schedule" *ngIf="!loading && !isMobile">
    <div class="header px-3 py-2">
        <div class="title d-flex">
            <div class="heading">Schedule</div>
        </div>
        <div (click)="closeView()" class="close pointer">
            <img src="assets/img/icon_Close.svg" alt="" />
        </div>
    </div>
    <div class="body d-flex flex-column">
        <div class="schedule-title d-flex justify-content-between">
            <span>Sessions</span
            ><span
                class="filter d-flex align-items-center"
                *ngIf="
                    userRole !== 'Attendee' &&
                    currentUser?._id &&
                    joinDetails &&
                    joinDetails?.ownerDetails?.userId !== currentUser?._id &&
                    userSessions.length
                "
            >
                <span>Only My Sessions</span>
                <app-toggle-radio-btn [checked]="showMySessions" (onToggle)="handleToggle(event)"></app-toggle-radio-btn
            ></span>
        </div>
        <div *ngFor="let session of showMySessions ? userSessions : sessionDetails; trackBy: trackByFunction">
            <div class="session-status d-flex align-items-center">
                <app-event-status [eventInfo]="session" *ngIf="session.status"></app-event-status>
                <div
                    class="inside-session d-flex align-items-center"
                    *ngIf="joinDetails.meetingId === session.meetingId && session.status !== 'Completed'"
                >
                    You are in this session
                </div>
            </div>
            <div
                class="session-wrapper d-flex flex-column"
                [class.no-border]="session.showSessionDetails"
                [class.session-break]="session?.type == 'break'"
            >
                <div class="session-time-wrapper d-flex" *ngIf="session?.type != 'break'">
                    <div class="session-date">{{ session.date }}</div>
                    <div class="session-start-time">{{ session.sTime }}</div>
                    <div>
                        → <span class="session-end-time">{{ session.eTime }}</span>
                    </div>
                </div>
                <div
                    class="session-body-short d-flex justify-content-between align-items-center"
                    *ngIf="session?.type != 'break'"
                >
                    <div
                        class="session-topic-name"
                        *ngIf="
                            session.topic.length > 64 && joinDetails.meetingId !== session.meetingId;
                            else fullTopicLength
                        "
                        pTooltip="{{ session.topic }}"
                        tooltipPosition="bottom"
                    >
                        {{ session.topic | slice: 0:61 }}...
                    </div>
                    <ng-template #fullTopicLength>
                        <div class="session-topic-name">
                            {{ session.topic }}
                        </div>
                    </ng-template>
                    <div class="action-wrapper d-flex">
                        <ng-container
                            *ngIf="joinDetails.meetingId !== session.meetingId && session.status !== 'Completed'"
                        >
                            <div class="join" *ngIf="userRole === 'Speaker'; else attendeeJoin">
                                <button
                                    class="custom-button btn bg-primary"
                                    (click)="navigateToWebinarSession(session)"
                                >
                                    Join {{ session.status === 'Ongoing' ? ' Session' : ' Backstage' }}
                                </button>
                            </div>
                            <ng-template #attendeeJoin>
                                <div class="join">
                                    <button
                                        class="custom-button btn bg-primary"
                                        (click)="navigateToWebinarSession(session)"
                                        [class.disabled]="!session.enableJoin"
                                    >
                                        Join
                                    </button>
                                </div>
                            </ng-template>
                        </ng-container>
                        <div class="session-expansion-wrapper pointer">
                            <img
                                class="session-expansion-btn"
                                [class.expanded]="session.showSessionDetails"
                                src="assets/img/drop-down-arrow.svg"
                                alt=""
                                (click)="showHideSessionDetails(session)"
                            />
                        </div>
                    </div>
                </div>
                <div class="d-flex" *ngIf="session?.type == 'break'">
                    <div class="break-date-details">
                        <div class="break-start-time">{{ session.sTime }}</div>
                        <div class="break-duration">{{ session.breakTime }} Min</div>
                    </div>
                    <div class="break-topic-name">
                        <img src="assets/img/general-interest-category-functional-filled.svg" alt="" />{{
                            session.topic
                        }}
                    </div>
                </div>
            </div>
            <div *ngIf="session.showSessionDetails" class="attendee-schedule-details">
                <p-tabView>
                    <p-tabPanel header="About" class="session-description">
                        <div class="session-description-text" *ngIf="session.description != ''; else noDescData">
                            <p
                                [innerHTML]="
                                    session.descriptionMarkdown?.text || session?.description || ''
                                        | sanitizeText
                                        | sanitizeHtml
                                "
                            ></p>
                        </div>
                        <ng-template #noDescData>
                            <div class="no-data">No description available</div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel header="Speakers ({{ session.speakers?.length }})" class="session-speaker-details">
                        <div
                            class="speakers d-flex flex-wrap mb-100"
                            id="hosted-by"
                            *ngIf="session.speakers.length > 0; else noSpeakerData"
                        >
                            <ng-container *ngFor="let speaker of session.speakers; trackBy: trackByFunction">
                                <div
                                    class="speaker-container-tile d-flex flex-column justify-content-center"
                                    [class.flex-grow-1]="session.speakers.length === 1"
                                >
                                    <div class="info d-flex flex-center">
                                        <div class="left-content">
                                            <div
                                                class="initials d-flex"
                                                [ngStyle]="{
                                                    'background-color': !speaker?.profileUrl
                                                        ? speaker?.colorChosen
                                                        : 'initial'
                                                }"
                                            >
                                                <ng-container *ngIf="!speaker?.profileUrl; else elseTemplate">
                                                    <span class="textinitials">{{ speaker.participantInitials }}</span>
                                                </ng-container>
                                                <ng-template #elseTemplate>
                                                    <div
                                                        class="profileimage"
                                                        [ngStyle]="{
                                                            'background-image':
                                                                'url(' +
                                                                speaker?.profileUrl +
                                                                '?rand=' +
                                                                randomNumber +
                                                                ')'
                                                        }"
                                                    ></div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="right-content d-flex flex-column">
                                            <div
                                                class="name text-truncate"
                                                pTooltip="{{ speaker.firstName + ' ' + speaker.lastName }}"
                                                tooltipPosition="bottom"
                                            >
                                                {{ speaker.firstName }} {{ speaker.lastName }}
                                            </div>
                                            <div
                                                class="designation text-truncate"
                                                *ngIf="speaker.jobTitle"
                                                pTooltip="{{ speaker.jobTitle }}"
                                                tooltipPosition="bottom"
                                            >
                                                {{ speaker.jobTitle }},
                                            </div>
                                            <div
                                                class="company text-truncate"
                                                pTooltip="{{ speaker.department }}"
                                                tooltipPosition="bottom"
                                            >
                                                {{ speaker.department }}
                                            </div>
                                            <div
                                                class="about"
                                                pTooltip="{{ speaker.description }}"
                                                tooltipPosition="bottom"
                                            >
                                                {{ speaker.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #noSpeakerData>
                            <div class="no-data" #noSpeakerData>No speakers assigned to this session</div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel header="Co-Host ({{ session.coHosts?.length }})" class="session-co-hosts-details">
                        <div
                            class="co-hosts d-flex flex-wrap mb-100"
                            id="hosted-by"
                            *ngIf="session.coHosts?.length > 0; else noCoHostData"
                        >
                            <ng-container *ngFor="let coHost of session.coHosts; trackBy: trackByFunction">
                                <div
                                    class="co-hosts-container-tile d-flex flex-column justify-content-center"
                                    [class.flex-grow-1]="session.coHosts.length === 1"
                                >
                                    <div class="info d-flex flex-center">
                                        <div class="left-content">
                                            <div
                                                class="initials d-flex"
                                                [ngStyle]="{
                                                    'background-color': !coHost?.profileUrl
                                                        ? coHost?.colorChosen
                                                        : 'initial'
                                                }"
                                            >
                                                <ng-container *ngIf="!coHost?.profileUrl; else elseTemplate">
                                                    <span class="textinitials">{{ coHost.participantInitials }}</span>
                                                </ng-container>
                                                <ng-template #elseTemplate>
                                                    <div
                                                        class="profileimage"
                                                        [ngStyle]="{
                                                            'background-image':
                                                                'url(' +
                                                                coHost?.profileUrl +
                                                                '?rand=' +
                                                                randomNumber +
                                                                ')'
                                                        }"
                                                    ></div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="right-content d-flex flex-column">
                                            <div
                                                class="name text-truncate"
                                                pTooltip="{{ coHost.firstName + ' ' + coHost.lastName }}"
                                                tooltipPosition="bottom"
                                            >
                                                {{ coHost.firstName }} {{ coHost.lastName }}
                                            </div>
                                            <div
                                                class="designation text-truncate"
                                                *ngIf="coHost.jobTitle"
                                                pTooltip="{{ coHost.jobTitle }}"
                                                tooltipPosition="bottom"
                                            >
                                                {{ coHost.jobTitle }},
                                            </div>
                                            <div
                                                class="company text-truncate"
                                                pTooltip="{{ coHost.department }}"
                                                tooltipPosition="bottom"
                                            >
                                                {{ coHost.department }}
                                            </div>
                                            <div class="about">
                                                {{ coHost.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #noCoHostData>
                            <div class="no-data">No co-host assigned to this session</div>
                        </ng-template>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
        <div
            class="no-sessions d-flex flex-wrap w-100 flex-column align-items-center justify-content-center"
            *ngIf="showMySessions && !userSessions.length"
        >
            <span>There aren’t any sessions</span>
            <span>assigned to you</span>
        </div>
    </div>
</div>
<div *ngIf="!loading && isMobile" class="call-schedule-mobile">
    <div class="mobile-header d-flex align-items-center">
        <img class="schedule-close-btn" src="assets/img/icon_arrow.svg" alt="" (click)="hideSchedule()" />
        <div class="schedule-title d-flex justify-content-between">
            <span>Schedule</span
            ><span class="filter d-flex align-items-center" *ngIf="userRole !== 'Attendee'">
                <span>Only My Sessions</span>
                <app-toggle-radio-btn [checked]="false" (onToggle)="handleToggle(event)"></app-toggle-radio-btn
            ></span>
        </div>
    </div>
    <div class="schedule-list">
        <div *ngFor="let session of sessionDetails; trackBy: trackByFunction" class="schedule-container">
            <div
                class="session-wrapper d-flex"
                [class.no-border]="session.showSessionDetails"
                [ngClass]="{
                    'flex-column': session.status !== 'Completed',
                    'flex-row-reverse': session.status === 'Completed'
                }"
            >
                <div
                    class="session-status d-flex align-items-center"
                    *ngIf="
                        session.status ||
                        (joinDetails.meetingId === session.meetingId && session.status !== 'Completed')
                    "
                >
                    <app-event-status [eventInfo]="session" *ngIf="session.status"></app-event-status>
                    <div
                        class="inside-session d-flex align-items-center"
                        *ngIf="joinDetails.meetingId === session.meetingId && session.status !== 'Completed'"
                    >
                        You are in this session
                    </div>
                </div>
                <div class="session-time-wrapper">
                    <div class="session-time">{{ session.sTime }} To {{ session.eTime }}</div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <div class="session-topic-name">
                    <img
                        *ngIf="session?.type == 'break'"
                        src="assets/img/general-interest-category-functional-filled.svg"
                        alt=""
                    />
                    {{ session.topic }}
                </div>
                <ng-container
                    *ngIf="
                        session?.type !== 'break' &&
                        joinDetails.meetingId !== session.meetingId &&
                        session.status !== 'Completed'
                    "
                >
                    <div class="join">
                        <button
                            class="custom-button btn bg-primary"
                            (click)="navigateToWebinarSession(session)"
                            [class.disabled]="!session.enableJoin"
                        >
                            Join
                        </button>
                    </div>
                </ng-container>
            </div>
            <div
                class="session-expansion-wrapper d-flex align-items-center pointer"
                *ngIf="
                    !session.showSessionDetails &&
                    session?.type != 'break' &&
                    (session.description?.trim() || session.speakers?.length > 0)
                "
            >
                <div class="view-session-details">View Details</div>
                <img
                    class="session-expansion-btn"
                    src="assets/img/down_arrow.svg"
                    alt=""
                    (click)="showHideSessionDetails(session)"
                />
            </div>
            <div *ngIf="session.showSessionDetails" class="attendee-schedule-details">
                <div class="session-description-text">
                    <div *ngIf="session.description">
                        <app-read-more
                            *ngIf="session?.description"
                            [noOfLines]="isMobile ? 4 : 3"
                            [lineHeight]="isMobile ? 17 : 20"
                        >
                            <p
                                [innerHTML]="
                                    session.descriptionMarkdown?.text || session?.description || ''
                                        | sanitizeText
                                        | sanitizeHtml
                                "
                            ></p>
                        </app-read-more>
                    </div>
                </div>
                <div
                    class="session-expansion-wrapper d-flex align-items-center pointer"
                    *ngIf="session?.type != 'break' && session.speakers.length > 0 && !session.speakersInMobile"
                >
                    <div class="view-session-details">{{ session.speakers?.length }} Speakers</div>
                    <img
                        class="session-expansion-btn"
                        src="assets/img/down_arrow.svg"
                        alt=""
                        (click)="showSpeakersInMobile(session)"
                    />
                </div>
                <div class="speakers-title" *ngIf="session.speakersInMobile">Speakers:</div>
                <div class="speakers d-flex flex-wrap mb-100" *ngIf="session.speakersInMobile">
                    <ng-container *ngFor="let speaker of session.speakers; trackBy: trackByFunction">
                        <div
                            class="speaker-container-tile d-flex flex-column justify-content-center"
                            style="margin: 10px 5px 10px 0"
                            [class.width-48]="session.speakers.length > 1"
                        >
                            <div class="info d-flex flex-center">
                                <div class="left-content">
                                    <div
                                        class="initials d-flex"
                                        [ngStyle]="{
                                            'background-color': !speaker?.profileUrl ? speaker?.colorChosen : 'initial'
                                        }"
                                    >
                                        <ng-container *ngIf="!speaker?.profileUrl; else elseTemplate">
                                            <span class="textinitials">{{ speaker.participantInitials }}</span>
                                        </ng-container>
                                        <ng-template #elseTemplate>
                                            <div
                                                class="profileimage"
                                                [ngStyle]="{
                                                    'background-image':
                                                        'url(' + speaker?.profileUrl + '?rand=' + randomNumber + ')'
                                                }"
                                            ></div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="right-content d-flex flex-column">
                                    <div class="name text-truncate" style="font-size: 12px">
                                        {{ speaker.firstName }} {{ speaker.lastName }}
                                    </div>
                                    <div class="designation" style="font-size: 10px" *ngIf="speaker.jobTitle">
                                        {{ speaker.jobTitle }},
                                    </div>
                                    <div class="company" style="font-size: 10px">{{ speaker.department }}</div>
                                    <div class="about" style="width: 80px; font-size: 10px; font-family: JioLight">
                                        {{ speaker.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div
                    class="session-expansion-wrapper d-flex align-items-center pointer"
                    *ngIf="session.speakersInMobile"
                >
                    <div class="view-session-details">View Less</div>
                    <img
                        class="session-expansion-btn"
                        src="assets/img/down_arrow.svg"
                        alt=""
                        style="transform: rotate(180deg)"
                        (click)="hideSpeakersInMobile(session)"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>
