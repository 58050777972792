import { Component, OnInit, Output, Input, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-faqs-links',
    templateUrl: './faqs-links.component.html',
    styleUrls: ['./faqs-links.component.scss']
})
export class FaqsLinksComponent implements OnInit {
    @Input() faqs: any[] = [];
    @Output() selectedFaqlink = new EventEmitter();
    isMobile = false;

    activeLink: string = '';

    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isResponsiveMobileView();
        this.isMobile ? (this.activeLink = '') : this.setInitialLink();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.faqs && changes.faqs.currentValue) {
            if (!this.isMobile) {
                if (changes.faqs.currentValue.length > 0 && !this.activeLink) {
                    this.activeLink = changes.faqs.currentValue[0];
                }
            }
        }
    }

    setInitialLink() {
        if (this.faqs && this.faqs.length > 0) {
            this.activeLink = this.faqs[0];
            this.selectedFaqLink(this.activeLink);
        }
    }

    selectedFaqLink(faqlink: string) {
        this.activeLink = faqlink;
        this.selectedFaqlink.emit(faqlink);
    }
}
