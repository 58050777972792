import { baseScene } from '../models/baseScene';
import { elementTypes, thumbnailInfo } from '../../elements/models/elementTypesEnum';

export class baseSceneView implements baseScene {
    sceneNumber: number;
    thumbnail: thumbnailInfo;
    elements: elementTypes[];
    aspectRatio: number;
    inTransition: string;
    outTransition: string;
    notes: {
        text: string;
        color: string;
    };
    sceneId: number;
    parentSceneId: number;
    title: string;

    constructor(props: baseScene) {
        this.sceneNumber = props.sceneNumber;
        this.thumbnail = props.thumbnail;
        this.elements = props.elements;
        this.aspectRatio = props.aspectRatio;
        this.inTransition = props.inTransition;
        this.outTransition = props.outTransition;
        this.notes = props.notes;
        this.sceneId = props.sceneId;
        this.parentSceneId = props.parentSceneId;
        this.title = props.title;
    }
}
