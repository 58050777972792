<div
    [class.networking-room-tile-settings]="isWebinarSettings"
    [class.networking-room-tile-landing]="!isWebinarSettings"
    [class.localUserPresence]="callStateManagerRef.meetingObj?.meetingId === networkingRoom?._id"
>
    <div
        class="network-room-image-wrapper d-flex justify-content-center align-items-center flex-column"
        [ngStyle]="{
            'background-image': networkingRoom?.networking?.imageUrl
                ? 'url(' + networkingRoom?.networking?.imageUrl + ')'
                : 'url(' + 'assets/images/networking-rooms/default_networking_room.svg' + ')'
        }"
        *ngIf="isWebinarSettings"
    ></div>
    <div class="network-room-title" *ngIf="isWebinarSettings">
        {{ networkingRoom.topic }}
    </div>
    <div class="d-flex align-items-center" [class.notWebinarSettings]="!isWebinarSettings" *ngIf="!isWebinarSettings">
        <div
            class="network-room-title"
            pTooltip="{{ networkingRoom.topic }}"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip-revamp"
        >
            {{ networkingRoom.topic }}
        </div>
        <span class="flex-grow-1"></span>
        <div
            class="status d-flex align-items-center mr-1"
            *ngIf="networkingRoom.participants?.length >= maxParticipantLimit"
        >
            <span>Full</span>
        </div>
        <div
            class="status d-flex align-items-center live-status"
            *ngIf="
                ((!roomsClosedStatus && !networkingRoom?.networking?.isEscapeRoom) ||
                    (networkingRoom?.networking?.isEscapeRoom && showEscapeRooms)) &&
                networkingRoom.participants?.length < maxParticipantLimit
            "
        >
            <!-- <span class="live-status-icon mr-1" style="color: red"></span> -->
            <img src="assets/images/networking-rooms/nwroom_live_dot.svg" />
            <span>LIVE</span>
        </div>
        <!-- <div class="status escape d-flex align-items-center" *ngIf="isUserHost && networkingRoom?.networking?.isEscapeRoom">Escape Room</div> -->
        <div
            class="participant-count d-flex align-items-center justify-content-center"
            [class.localUserPresence]="callStateManagerRef.meetingObj?.meetingId === networkingRoom?._id"
        >
            <img class="mr-1" src="assets/images/networking-rooms/participants_icon_white.svg" height="16" width="16" />
            <span
                *ngIf="
                    !(
                        networkingRoom.participants?.length < 1 &&
                        callStateManagerRef.meetingObj?.meetingId === networkingRoom?._id
                    )
                "
                >{{
                    callStateManagerRef.meetingObj?.meetingId === networkingRoom?._id &&
                    networkingRoom.participants?.length < 1
                        ? networkingRoom.participants?.length + 1
                        : networkingRoom.participants?.length
                }}
                <span *ngIf="callStateManagerRef.meetingObj?.meetingId !== networkingRoom?._id"
                    >/ {{ maxParticipantLimit }}
                </span>
            </span>
            <span *ngIf="callStateManagerRef.meetingObj?.meetingId === networkingRoom?._id"
                >/{{ maxParticipantLimit }}</span
            >
        </div>
    </div>
    <div class="tile-actions justify-content-center align-items-center position-absolute" *ngIf="isWebinarSettings">
        <div
            class="edit-room mx-2 pointer"
            pTooltip="Edit"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip-revamp"
            (click)="editRoom(networkingRoom)"
            id="editNetworkingRoomBtn"
        >
            <img src="assets/images/networking-rooms/edit_icon.svg" height="44" />
        </div>
        <div
            class="delete-room mr-2 pointer"
            pTooltip="Delete"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip-revamp"
            (click)="deleteRoom(networkingRoom)"
            id="deleteNetworkingRoomBtn"
        >
            <img src="assets/images/networking-rooms/delete_icon.svg" height="44" />
        </div>
        <div
            class="duplicate-room mr-2 pointer"
            pTooltip="Duplicate"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip-revamp"
            (click)="showDuplicateRoomRequest(networkingRoom)"
            id="showDuplicateRoomBtn"
        >
            <img src="assets/images/networking-rooms/duplicate_icon.svg" height="44" />
        </div>
    </div>

    <div
        class="network-room-image-wrapper d-flex justify-content-center align-items-center flex-column mt-3"
        [class.noLocalUserPresence]="callStateManagerRef.meetingObj?.meetingId !== networkingRoom?._id"
        [class.isHost]="callViewStateManagerService.isHost"
        [ngStyle]="{
            'background-image': networkingRoom?.networking?.imageUrl
                ? 'url(' + networkingRoom?.networking?.imageUrl + ')'
                : 'url(' + 'assets/images/networking-rooms/default_networking_room.svg' + ')'
        }"
        *ngIf="!isWebinarSettings"
    >
        <!-- <img src="assets/img/networking/networking-room-default.png" /> -->
        <div
            class="status escape-tag d-flex align-items-center"
            *ngIf="isUserHost && networkingRoom?.networking?.isEscapeRoom"
            id="escapeRoomBtnControl"
        >
            Escape Room
        </div>
    </div>
    <div class="participant-images" *ngIf="!isWebinarSettings && networkingRoom.participants?.length">
        <div class="participant-images-stack">
            <div
                class="participant-image-wrapper"
                *ngFor="let participant of networkingRoom.participants | slice: 0:8; let i = index"
                [ngStyle]="{
                    'z-index': 350 - i,
                    transform: 'translateX(-' + 4 * i + 'px)'
                }"
            >
                <div
                    class="initials"
                    [class.p-0]="participant?.image"
                    [class.shiftLeft]="i > 0"
                    [ngStyle]="{
                        'background-color': !participant?.image
                            ? profileService.getColorForInitials(participant?.participantName, participant?.userId).bg
                            : 'initial'
                    }"
                    [pTooltip]="participant.tooltipContent"
                    tooltipPosition="bottom"
                    tooltipStyleClass="tooltip-revamp"
                    [escape]="false"
                >
                    <ng-container *ngIf="!participant?.image; else elseTemplate">
                        <span class="textinitials">{{
                            profileService.getInitials(participant?.participantName, participant?.userId)
                        }}</span>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div
                            class="profileimage"
                            *ngIf="participant?.userId"
                            [ngStyle]="{
                                content:
                                    'url(' +
                                    profileService.getProfilePicPath(participant?.userId) +
                                    '?rand=' +
                                    randomNumber +
                                    ')'
                            }"
                        ></div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="more-participants-text" *ngIf="networkingRoom.participants.length - 8 > 0">
            + {{ networkingRoom.participants.length - 8 }} More
        </div>
    </div>
    <div
        class="d-flex justify-content-between my-3 no-select"
        *ngIf="!isWebinarSettings"
        [class.my-5]="!networkingRoom.participants?.length"
    >
        <div class="tile-actions justify-content-center align-items-center" *ngIf="isUserHost">
            <div
                class="action no-select edit-room pointer"
                (click)="editRoom(networkingRoom)"
                pTooltip="Edit"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-revamp"
                id="editRoomByHost"
            >
                <img src="assets/images/networking-rooms/edit_green_icon.svg" />
            </div>
            <div
                class="action delete-room pointer"
                (click)="deleteRoom(networkingRoom)"
                pTooltip="Delete"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-revamp"
                id="deleteRoomByhost"
            >
                <img class="pointer" src="assets/images/networking-rooms/delete_green_icon.svg" />
            </div>
            <div
                class="action duplicate-room pointer"
                (click)="showDuplicateRoomRequest(networkingRoom)"
                pTooltip="Duplicate"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-revamp"
                id="duplicateByHost"
            >
                <img src="assets/images/speaker-call-wrapper/copy_invitation_share_info.svg" />
            </div>
            <div
                class="action no-select add-participant pointer"
                [class.disabled]="networkingRoom?.networking?.isEscapeRoom ? !showEscapeRooms : roomsClosedStatus"
                (click)="showAddParticipantsPanel(networkingRoom)"
                pTooltip="Invite Speaker"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-revamp"
                id="inviteSpeakerByHost"
            >
                <img src="assets/images/speaker-call-wrapper/add_participants_icon.svg" />
            </div>
            <div
                class="stop-timer-btn pointer"
                *ngIf="false"
                (click)="requestCloseRoomsPopUp(networkingRoom)"
                pTooltip="Close Room"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-revamp"
                id="closeRoomByHost"
            >
                <img src="assets/images/networking-rooms/timer_icon.svg" />
            </div>
        </div>
        <button
            class="custom-button-revamp btn-primary lg enter-btn justify-content-center align-items-center"
            (click)="onNetworkingRoomClicked(networkingRoom)"
            *ngIf="callStateManagerRef.meetingObj?.meetingId !== networkingRoom?._id"
            [ngClass]="{
                'disabled-primary':
                    (networkingRoom?.networking?.isEscapeRoom ? !showEscapeRooms : roomsClosedStatus) ||
                    callStateManagerRef.meetingObj?.meetingId === networkingRoom?._id ||
                    networkingRoom.participants?.length >= maxParticipantLimit,
                'w-100': !isUserHost,
                fit: isUserHost
            }"
            id="enterRoomBtn"
        >
            Enter {{ isUserHost ? '' : 'Room' }}
        </button>
    </div>
</div>

<div class="popup-component" *ngIf="false">
    <div class="bg"></div>
    <div class="popup popup-simple">
        <div class="popup-header position-relative">
            <div class="title font-weight-bold">Duplicate Room?</div>
            <div class="close" (click)="closeDuplicateRoomRequestPopup()">
                <img [src]="isWebinarSettings ? 'assets/img/close_icon.svg' : 'assets/img/close_icon.svg'" alt="" />
            </div>
        </div>
        <div class="popup-body">
            <div>{{ networkingRoom.topic }} will be duplicated</div>
            <div class="custom-form">
                <div class="form-group my-3">
                    <label for="su" class="text-dark">Number of Rooms</label>
                    <!-- <span class="text-error">*</span> -->
                    <input
                        type="number"
                        class="form-control duplicate-room-count"
                        placeholder="0"
                        id="duplicate-count"
                        autocomplete="off"
                        value="1"
                        min="1"
                        max="10"
                        maxlength="2"
                        (keydown)="onInputKeyDown($event)"
                        (keypress)="onInputKeyPress($event)"
                    />
                </div>
                <div class="error-code text-danger" *ngIf="errors?.global">
                    {{ errors?.global }}
                </div>
                <div class="button-group d-flex justify-content-end">
                    <button
                        class="custom-button bg-secondary cancel ml-5"
                        (click)="closeDuplicateRoomRequestPopup()"
                        id="cancelBtnNtwRoom"
                    >
                        {{ 'webinar-settings-streaming.popup.cancel' | translate }}
                    </button>
                    <button
                        type="button"
                        class="custom-button bg-primary save ml-4"
                        (click)="duplicateRoom()"
                        [class.disabled]="false"
                        id="confirmButtonDuplicateRoom"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog
    [(visible)]="showDuplicateRoomPopup"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    class="close-rooms-dialog-popup"
>
    <ng-template pTemplate="header">
        <div class="popup-header position-relative">
            <div class="title">Duplicate Room?</div>
            <!-- <div class="close" (click)="closeDuplicateRoomRequestPopup()">
                <img src="assets/images/close_icon_opaque.svg" alt="" />
            </div> -->
        </div>
    </ng-template>

    <div class="popup-body">
        <div class="action-description">{{ networkingRoom.topic }} will be duplicated</div>
        <div class="custom-form my-4">
            <div class="form-item">
                <label for="horizontal" class="text-dark">Number of Rooms</label>
                <!-- minus to be disabled on 0/1 -->
                <p-inputNumber
                    class="stop-timer-count no-select"
                    [(ngModel)]="duplicateRoomsCount"
                    [showButtons]="true"
                    [min]="1"
                    [max]="10"
                    buttonLayout="horizontal"
                    inputId="duplicate-count"
                    spinnerMode="horizontal"
                    [step]="1"
                    decrementButtonClass="input-strip-wrapper input-chevron-left"
                    incrementButtonClass="input-strip-wrapper input-chevron-right"
                    mode="decimal"
                >
                </p-inputNumber>
            </div>
            <div class="error-code text-danger" *ngIf="errors?.global">
                {{ errors?.global }}
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="button-group d-flex justify-content-end">
            <button
                class="cancel ml-5"
                (click)="closeDuplicateRoomRequestPopup()"
                [ngClass]="
                    isWebinarSettings
                        ? 'custom-button bg-secondary light-theme'
                        : 'custom-button-revamp btn-confirm-secondary light-theme'
                "
                id="{{ 'webinar-settings-streaming.popup.cancel' | translate }}_toogleBtnView"
            >
                {{ 'webinar-settings-streaming.popup.cancel' | translate }}
            </button>
            <button
                type="button"
                class="save ml-4"
                [ngClass]="isWebinarSettings ? 'custom-button bg-primary' : 'custom-button-revamp btn-confirm-primary'"
                (click)="duplicateRoom()"
                [class.disabled]="false"
                id="ConfirmBtnControlView"
            >
                Confirm
            </button>
        </div>
    </ng-template>
</p-dialog>
