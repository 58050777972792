import { Injectable } from '@angular/core';
import * as uuidv4 from 'src/assets/lib/uuid/uuid-v4.js';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';

declare const DeviceUUID: any;

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    private deviceUUID;
    private sessionID;
    constructor(
        private authService: AuthService,
        private appLoggerService: AppLoggerService,
        private localStorageService: LocalStorageService
    ) {}

    setDeviceId(deviceId: any) {
        this.deviceUUID = deviceId;
    }

    getDeviceUUID() {
        let uID;
        if (!this.localStorageService.getItem('userDeviceId')) {
            this.deviceUUID = uuidv4();
            this.localStorageService.addItem('userDeviceId', this.deviceUUID);
        }
        this.deviceUUID = this.localStorageService.getItem('userDeviceId') || this.deviceUUID;
        uID = this.deviceUUID;
        this.appLoggerService.setDeviceUID(uID);
        return uID;
    }

    getSessionID() {
        if (!this.sessionID) {
            this.sessionID = uuidv4();
        }
        return this.sessionID;
    }

    getUniqueBrowserID() {
        const du = new DeviceUUID().parse();
        const dua = [du.browser, du.os, du.version];
        const uniqueBrowserId = du.hashMD5(dua.join(':'));
        this.appLoggerService.setBrowserUID(uniqueBrowserId);
        return uniqueBrowserId;
    }

    getSysytemInfo() {
        return new DeviceUUID().parse();
    }
}
