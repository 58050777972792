<div class="sponsors-component-wrapper" *ngIf="overallSponsorsLength > 0 && isMobileOS">
    <div class="sponsors-container-mobile">
        <div
            [ngClass]="{
                'platinum-container-mobile': category === 'Platinum',
                'gold-container-mobile': category === 'Gold',
                'silver-container-mobile': category === 'Silver'
            }"
        >
            <div class="mobile-category-header">{{ categoryName }} SPONSORS</div>
            <div
                class="sponsors-body"
                [ngClass]="{
                    isGrid: category === 'Silver' && sponsorsToRender?.length > 4 && shouldBeInGrid
                }"
            >
                <div class="sponsor-wrapper pointer" *ngFor="let sponsor of sponsorsToRender">
                    <a (click)="sponsorRedirection(sponsor.redirectUrl)" target="_blank">
                        <div class="sponsor-logo-wrapper">
                            <img class="sponsor-logo" src="{{ sponsor.logo }}" alt="{{ sponsor.name }}" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="sponsors-component-wrapper" *ngIf="overallSponsorsLength > 0 && !isMobileOS">
    <div
        class="sponsors-container left-container right-container"
        [ngClass]="{
            'right-container-with-alignment': overallSponsorsLength === 3 && category != 'Platinum',
            'right-bottom-container': overallSponsorsLength === 3 && category === 'Silver'
        }"
    >
        <div
            [ngClass]="{
                'platinum-container': category === 'Platinum',
                'gold-container': category === 'Gold',
                'silver-container': category === 'Silver'
            }"
        >
            <div class="category-header">{{ categoryName }} SPONSORS</div>
            <div
                class="sponsors-body"
                [ngClass]="{
                    isGrid:
                        category === 'Silver' &&
                        sponsorsToRender?.length > 3 &&
                        shouldBeInGrid &&
                        overallSponsorsLength < 3,
                    rightBottom: overallSponsorsLength == 3
                }"
            >
                <div
                    class="sponsor-wrapper"
                    [class.rightBottomItem]="overallSponsorsLength == 3"
                    *ngFor="let sponsor of sponsorsToRender"
                >
                    <a (click)="sponsorRedirection(sponsor.redirectUrl)" target="_blank">
                        <div class="sponsor-logo-wrapper">
                            <img class="sponsor-logo" src="{{ sponsor.logo }}" alt="{{ sponsor.name }}" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
