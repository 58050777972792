<div class="video-settings-container" [class.callSettings]="route === 'call-settings'">
    <section></section>
    <section>
        <div class="unit d-flex">
            <span *ngIf="route === 'dashboard'">{{ 'user_dashboard_settings.video.camera' | translate }}:</span>
            <select (change)="selectCamera()" [(ngModel)]="selectedCamera">
                <option *ngFor="let cam of cameras" [ngValue]="cam">{{ cam.name }}</option>
            </select>
        </div>
    </section>
    <!-- <div class="camera-input mt-5">
    <span class="meeting">{{'user_dashboard_settings.video.meeting' | translate}}:</span>
    <input type="checkbox" class="mr-3" [(ngModel)]="turnOffVideo" (change)="onTurnOffVideo($event.target)">
    <span class="checkbox-text">{{'user_dashboard_settings.video.turn_off_video' | translate}}</span>
  </div> -->
</div>
