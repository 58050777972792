<div class="faq-links">
    <ul>
        <li *ngFor="let links of faqs" (click)="selectedFaqLink(links)" [class.active]="links === activeLink">
            <div class="links-style">
                <div class="faq-link-style">{{ links }}</div>
                <img class="arrow" src="assets/img/arrow_icon.png" />
            </div>
        </li>
    </ul>
</div>
