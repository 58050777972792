<div class="popup-component" *ngIf="!insideCall">
    <div class="bg" style="height: 100vw"></div>
    <div class="popup">
        <div class="popup-header d-flex align-items-center">
            <h3 class="header">{{ 'webinar-settings-post-session.add-link-popup.add_link' | translate }}</h3>
            <div class="close">
                <img src="assets/img/close_icon_green.svg" (click)="cancel()" />
            </div>
        </div>
        <div class="popup-body">
            <form class="custom-form" [formGroup]="form">
                <div class="form-group">
                    <div class="topic my-2">
                        {{ 'webinar-settings-post-session.add-link-popup.text' | translate }}
                    </div>
                    <div class="input-wrapper">
                        <input
                            type="text"
                            class="form-control"
                            formControlName="text"
                            placeholder="Enter Text"
                            maxlength="{{ maxLength - wordCount }}"
                            id="text-input"
                            autofocus
                        />
                        <div *ngIf="form?.value?.text?.length > 0" class="close-icon-wrapper">
                            <img
                                class="pointer"
                                src="assets/img/webinar/close-circle-large.svg"
                                (click)="clear('text')"
                            />
                        </div>
                    </div>
                    <div class="topic my-2">
                        {{ 'webinar-settings-post-session.add-link-popup.link' | translate }}
                    </div>
                    <div class="input-wrapper">
                        <div class="placeholder-link">https://</div>
                        <input type="text" class="form-control link" formControlName="link" placeholder="Enter Link" />
                        <div *ngIf="form?.value?.link?.length > 0" class="close-icon-wrapper">
                            <img
                                class="pointer"
                                src="assets/img/webinar/close-circle-large.svg"
                                (click)="clear('link')"
                            />
                        </div>
                    </div>
                    <span *ngIf="errors?.link" class="in-valid">{{ errors?.link }}</span>
                </div>
                <div class="actions mt-5 text-right">
                    <button type="button" class="custom-button bg-secondary mr-2" (click)="remove()" *ngIf="editMode">
                        {{ 'webinar-settings-post-session.add-link-popup.remove' | translate }}
                    </button>
                    <button type="button" class="custom-button bg-secondary mr-2" (click)="cancel()" *ngIf="!editMode">
                        {{ 'webinar-settings-post-session.add-link-popup.cancel' | translate }}
                    </button>
                    <button
                        type="submit"
                        class="custom-button btn-primary light-theme bg-primary mr-2"
                        (click)="sendMessage()"
                        [class.disabled]="isDisabled()"
                    >
                        {{
                            (editMode
                                ? 'webinar-settings-post-session.add-link-popup.update'
                                : 'webinar-settings-post-session.add-link-popup.save'
                            ) | translate
                        }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<p-dialog
    [(visible)]="insideCall"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    class="close-rooms-dialog-popup add-link-popup-dialog"
>
    <ng-template pTemplate="header">
        <div class="popup-header position-relative">
            <div class="title">Add link</div>
            <div class="pointer" style="width: 16px" (click)="cancel()" id="hyperTextPageViewIcon">
                <img src="assets/img/chat_close.svg" alt="" />
            </div>
        </div>
    </ng-template>

    <div class="popup-body">
        <form class="custom-form" [formGroup]="form">
            <div class="form-group">
                <!-- <div class="topic my-2">
                    {{ 'webinar-settings-post-session.add-link-popup.text' | translate }}
                </div> -->
                <div class="input-wrapper">
                    <input
                        type="text"
                        class="form-control"
                        formControlName="text"
                        placeholder="Enter Text"
                        maxlength="{{ maxLength - wordCount }}"
                        id="text-input"
                        autofocus
                    />
                    <div *ngIf="form?.value?.text?.length > 0" class="close-icon-wrapper" id="clearTextIconView">
                        <img
                            class="pointer"
                            src="assets/images/event-call-options/grey_solid_close_icon.svg"
                            (click)="clear('text')"
                        />
                    </div>
                </div>
                <!-- <div class="topic my-2">
                    {{ 'webinar-settings-post-session.add-link-popup.link' | translate }}
                </div> -->
                <div class="input-wrapper">
                    <input
                        type="text"
                        class="form-control link"
                        formControlName="link"
                        placeholder="https://"
                        id="input-link"
                    />
                    <div *ngIf="form?.value?.link?.length > 0" class="close-icon-wrapper">
                        <img
                            class="pointer"
                            src="assets/images/event-call-options/grey_solid_close_icon.svg"
                            (click)="clear('link')"
                        />
                    </div>
                </div>
                <span *ngIf="errors?.link" class="in-valid">{{ errors?.link }}</span>
            </div>
        </form>
    </div>

    <ng-template pTemplate="footer">
        <div class="button-group d-flex justify-content-end">
            <button
                *ngIf="editMode"
                class="custom-button-revamp btn-confirm-secondary cancel ml-5"
                (click)="cancel()"
                id="removeButtonControlToggle"
            >
                Remove
            </button>
            <button
                *ngIf="!editMode"
                class="custom-button-revamp btn-confirm-secondary cancel ml-5"
                (click)="cancel()"
                id="cancelButtonToggleControl"
            >
                Cancel
            </button>
            <button
                type="button"
                class="custom-button-revamp btn-confirm-primary save ml-4"
                (click)="sendMessage()"
                [class.disabled]="isDisabled()"
                id="{{ editMode ? 'Update' : 'Save' }}_buttonControlToggle"
            >
                {{ editMode ? 'Update' : 'Save' }}
            </button>
        </div>
    </ng-template>
</p-dialog>
