<!-- <div class="dial-vc-room" *ngIf="enableVCDialog">
    <div class="vc-dialog-header">
        <span>JioEvents</span>
        <span (click)="closeVCDialog()" class="close-icon"
            ><img src="assets/images/close_icn.svg" alt="close icon"
        /></span>
    </div>
    <div class="vc-dialog-body">
        <br />
        <form class="custom-form vc-invite-form" [formGroup]="VCInviteForm" (ngSubmit)="onVCAdded()">
            <div class="call-options" [class.hide]="isAgoraMeeting">
                <h5>{{ 'inside_call.participants.type_of_vc_room' | translate }}</h5>
                <label class="pointer pr-5">
                    <input type="radio" value="SIP" formControlName="VCOption" [attr.checked]="true" />
                    <span>SIP</span>
                </label>
                &nbsp;&nbsp;&nbsp;
                <label class="pointer pr-5">
                    <input type="radio" value="H.323" formControlName="VCOption" />
                    <span>H.323</span>
                </label>
            </div>
            <input
                type="text"
                placeholder="{{ 'inside_call.participants.enter_ip_address' | translate }}"
                formControlName="IPAddress"
                required
            />
            <br />
            <button type="submit" class="custom-button bg-primary mr-2" [class.disabled]="!VCInviteForm.valid">
                {{ 'inside_call.participants.start_call' | translate }}
            </button>
        </form>
    </div>
</div> -->
<div [class.vc-dialog-bg-mask]="enableVCDialog"></div>

<div class="vc-rooms-dialog-wrapper">
    <p-dialog
        [(visible)]="enableVCDialog"
        [modal]="true"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
        (onHide)="closeVCDialog()"
    >
        <div class="vc-dialog-body">
            <br />
            <form class="custom-form vc-invite-form" [formGroup]="VCInviteForm">
                <div class="call-options" [class.hide]="isAgoraMeeting || isJMMeeting">
                    <h5>{{ 'inside_call.participants.type_of_vc_room' | translate }}</h5>
                    <label class="pointer pr-5">
                        <input type="radio" value="SIP" formControlName="VCOption" [attr.checked]="true" />
                        <span>SIP</span>
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label class="pointer pr-5">
                        <input type="radio" value="H.323" formControlName="VCOption" />
                        <span>H.323</span>
                    </label>
                </div>
                <div class="font-16-24-700 color-grey-100" style="margin-bottom: 12px">Dial VC Room</div>
                <input
                    type="text"
                    placeholder="{{ 'inside_call.participants.enter_ip_address' | translate }}"
                    formControlName="IPAddress"
                    required
                />
            </form>
        </div>

        <ng-template pTemplate="footer">
            <div class="button-group d-flex justify-content-end">
                <button
                    type="button"
                    class="custom-button-revamp btn-confirm-primary save ml-4"
                    [class.disabled]="!VCInviteForm.valid"
                    (click)="onVCAdded()"
                >
                    {{ 'inside_call.participants.start_call' | translate }}
                </button>
            </div>
        </ng-template>
    </p-dialog>
</div>
