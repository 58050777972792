import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-emoji-box',
    templateUrl: './emoji-box.component.html',
    styleUrls: ['./emoji-box.component.scss']
})
export class EmojiBoxComponent implements OnInit {
    @Output() onEmojiSelect: EventEmitter<any> = new EventEmitter();
    @Input() isNewLayout: boolean = false;
    public emojis = [];
    public emojiSearch: String = '';
    public emojiCategory: any = {};
    public categorysArray = [];
    public selectedCategoryType: any = '';
    public showArrayEmojiList: any = [];
    public peopleCategory: any = 'People & Body';
    // ["Smileys & Emotion","People & Body","Animals & Nature","Food & Drink","Travel & Places","Activities","Objects","Symbols","Flags"]
    public ModifiedChar: any = {
        'Smileys & Emotion': '😀',
        'People & Body': '👋',
        'Animals & Nature': '🐵',
        'Food & Drink': '🍇',
        'Travel & Places': '🌍',
        Activities: '⚽',
        Objects: '👓',
        Symbols: '✅',
        Flags: '🏁'
    };
    heartOnFireCode = '2764 200D 1F525';
    isWindows: boolean = false;
    constructor(private utilsService: UtilService) {
        this.getEmoji();
        this.isWindows = this.utilsService.getOS() == 'Windows';
    }
    async getEmoji() {
        let emojis = await import('emoji.json');
        this.emojis = (emojis as any).default;
        let skinArray = [];
        this.emojis.forEach((el) => {
            if (el.group !== 'Component') {
                if (this.emojiCategory.hasOwnProperty(el.group)) {
                    this.emojiCategory[el.group].push(el);
                } else {
                    this.categorysArray.push(el.group);
                    this.emojiCategory[el.group] = [el];
                }
            }
        });
        this.emojiCategory[this.peopleCategory].forEach((el, idx, arr) => {
            if (!el.name.includes('skin tone')) {
                skinArray.push(el);
            }
        });
        this.emojiCategory[this.peopleCategory] = skinArray;
        this.selectedCategoryType = this.categorysArray[0];
        this.showArrayEmojiList = this.emojiCategory[this.selectedCategoryType];
    }

    ngOnInit() {}

    selectEmoji(emoji) {
        this.onEmojiSelect.emit(emoji);
    }

    selectCategory(type) {
        this.selectedCategoryType = type;
        this.showArrayEmojiList = this.emojiCategory[this.selectedCategoryType];
    }

    clearSearch() {
        this.emojiSearch = '';
    }
}
