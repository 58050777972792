<form [formGroup]="form">
    <div formArrayName="socialMediaLinks" *ngIf="socialMediaForm && socialMediaForm.length">
        <div class="form-group socialMediaLinks">
            <label for="social-link"> Website and Social Links</label>
            <div *ngFor="let social of socialMediaForm.controls; let i = index" [formGroupName]="i">
                <div class="social-link" *ngIf="socialMediaForm.value.length - 1 > i || addedSocialMedia.length == 6">
                    <div class="social">
                        <img class="add-social-media-icon" [src]="addedSocialMedia[i].selected_icon" />
                        <input
                            type="text"
                            id="social-link"
                            placeholder="Add Link Here"
                            maxlength="100"
                            formControlName="text"
                        />
                    </div>
                    <button class="delete" id="delete-social-media" (click)="deleteSocialMedia(i)">
                        <img src="assets/img/delete.svg" />
                    </button>
                </div>
                <div
                    class="text-error"
                    *ngIf="
                        errors[socialMediaForm.value[i].dropdown] &&
                        socialMediaForm.value[i].text != '' &&
                        (socialMediaForm.value.length - 1 > i || addedSocialMedia.length == 6)
                    "
                >
                    {{ errors[socialMediaForm.value[i].dropdown] }}
                </div>
                <div
                    class="social-link"
                    *ngIf="availableSocialMediaOptions.length > 0 && socialMediaForm.value.length - 1 == i"
                >
                    <div class="social">
                        <p-dropdown [options]="availableSocialMediaOptions" (onChange)="onDropdownChange($event)">
                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                <img [src]="selectedSocialMedia.selected_icon" />
                            </ng-template>
                            <ng-template pTemplate="item" let-media>
                                <img [src]="media.icon" />
                                {{ media.name }}
                            </ng-template>
                        </p-dropdown>
                        <input
                            type="text"
                            id="{{ selectedSocialMedia.name }}"
                            placeholder="Add Link Here"
                            maxlength="100"
                            formControlName="text"
                        />
                    </div>
                    <button
                        class="add"
                        id="add-social-media"
                        [class.disable]="socialMediaForm.value[socialMediaForm.value.length - 1].text == ''"
                        (click)="addSocialMedia()"
                    >
                        Add
                    </button>
                </div>
                <div
                    class="text-error"
                    *ngIf="
                        socialMediaForm.value.length - 1 == i &&
                        addedSocialMedia.length != 6 &&
                        errors[socialMediaForm.value[socialMediaForm.value.length - 1].dropdown] &&
                        socialMediaForm.value[socialMediaForm.value.length - 1].text != ''
                    "
                >
                    {{ errors[socialMediaForm.value[socialMediaForm.value.length - 1].dropdown] }}
                </div>
            </div>
        </div>
    </div>
</form>
