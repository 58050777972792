import { Component, Input, OnInit } from '@angular/core';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService } from 'src/app/core';

@Component({
    selector: 'app-permission-denied',
    templateUrl: './permission-denied.component.html',
    styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent implements OnInit {
    pageNumber = 1;
    tabNumber = 1;
    pageInsideTab = 1;
    @Input() showSkipOption;
    @Input() screenShare = false;
    constructor(private eventEmitterService: EventEmitterService) {}

    ngOnInit(): void {
        // TODO: Ask cam and mic access in same popup
    }

    closePermissiondialog() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_PERMISSION_UI,
            data: { show: false, skipOption: false }
        });
    }

    increasePageNumber() {
        this.pageNumber = this.pageNumber + 1;
        if (this.pageNumber === 2) {
            this.tabNumber = 1;
            this.pageInsideTab = 1;
        }
    }

    decreasePageNumber() {
        this.pageNumber = this.pageNumber - 1;
        if (this.pageNumber === 2) {
            this.tabNumber = 1;
            this.pageInsideTab = 1;
        }
    }

    toggleTab(tabNumber) {
        this.tabNumber = tabNumber;
        this.pageInsideTab = 1;
    }

    changePageInsideTab(value) {
        this.pageInsideTab = this.pageInsideTab + value;
    }
}
