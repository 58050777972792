import { trigger, transition, group, query, style, animate } from '@angular/animations';

export class RouterAnimations {
    static routeSlide = trigger('routeSlide', [
        transition('* <=> *', [
            group([
                query(
                    ':enter',
                    [
                        style({
                            transform: 'translateY({{offsetEnter}})',
                            opacity: '0'
                        }),
                        animate(
                            '0.2s 100ms ease',
                            style({
                                transform: 'translateY(0%)',
                                opacity: 1
                            })
                        )
                    ],
                    { optional: true }
                ),
                query(
                    ':leave',
                    [
                        style({
                            transform: 'translateY(0%)',
                            opacity: 1
                        }),
                        animate(
                            '0.2s ease',
                            style({
                                transform: 'translateY({{offsetLeave}})',
                                opacity: 0
                            })
                        )
                    ],
                    { optional: true }
                )
            ])
        ])
    ]);
}
