export const MEDIA_ENGINE_EVENTS = {
    HOST_IDENTITY: 'HOSTURIEWBUBBWBWEFWEJBJKBBHJKJANRD@HOST_URI',

    HOST_START_VIDEO: 'ASDBSAHJDKDMNASBMASHWQEQWOECBEIWURGEWFBDSN@START',
    HOST_STOP_VIDEO: 'ASDBSAHJDKDMNASBMASHWQEQWOECBEIWURGEWFBDSN@STOP',
    HOST_START_AUDIO: 'CVXVFGRGKMWQEWQIEJUIIOIQWEJOIQWJEOIJQWIEJQ@START',
    HOST_STOP_AUDIO: 'CVXVFGRGKMWQEWQIEJUIIOIQWEJOIQWJEOIJQWIEJQ@STOP',
    HOST_STOP_AUDIO_CALL: 'QWEJKQKWEBKJCBXFCJDFKJSDSFDSFDSFDSFDS@ENDCALL',
    HOST_LOCK_ROOM: 'ASFSDFASIJOIIKIJINBNMBLESIFJDBHHJRBMBNXXZC@LOCK',
    HOST_UNLOCK_ROOM: 'POIYGNSGJLEGTBSKDBPEBHFTEKSSALND@UNLOCK',
    HOST_DISCONNECT_ALL: 'QWEJKQKWEBKJCBXFCJDFKJSDSFDSFDSFDSFDS@ENDCALL',
    HOST_START_RECORDING: 'RECSTAHJDKDMNASBMASHWQEQWOECBEIWURGEWFBDSN@START',
    HOST_STOP_RECORDING: 'RECSTAHJDKDMNASBMASHWQEQWOECBEIWURGEWFBDSN@STOP',
    HOST_STOP_SHARE: 'KJHEGUYGTUYGJHGBMABJIHJKJANMB@STOP_SHARE',
    HOST_START_CALL: 'CONSTAHJDKDMNASBMASHWQEQWOECBEIWURGEWFBDSN@STARTCALL',
    HOST_START_WHITEBOARD: 'WHHEGUYGTUYGJHGBMABJIHJKJANRD@START_WHITEBOARD',
    HOST_STOP_WHITEBOARD: 'WHHEGUYGTUYGJHGBMABJIHJKJANRD@STOP_WHITEBOARD',
    HOST_FORCE_HARD_MUTE: 'SAGHKGHJAGJHGAYWTYDTDUY@FORCE_HARD_MUTE',
    HOST_FORCE_HARD_UNMUTE: 'SAGHKGHJAGJHGAYWTYDTDUY@FORCE_HARD_UNMUTE',
    HOST_LOWER_HAND: 'QSGXVGHSVXHSGVGVSDJHBSOUYIUY@HOST_LOWER_HAND',
    HOST_LOWER_ALL_HANDS: 'QSGXVGHSVXHSGVGVSDJHBSOUYIUY@LOWER_ALL_HANDS',

    PARTICIPANT_START_VIDEO: 'MOKMJBNJDCBDSNFBSDFMNDSBFNMASDEIWEHDSA@START',
    PARTICIPANT_STOP_VIDEO: 'MOKMJBNJDCBDSNFBSDFMNDSBFNMASDEIWEHDSA@STOP',
    PARTICIPANT_START_AUDIO: 'ZFDSRTTOERJJDFBNDJKSFBNDFMNSDBBGNAERQW@START',
    PARTICIPANT_STOP_AUDIO: 'ZFDSRTTOERJJDFBNDJKSFBNDFMNSDBBGNAERQW@STOP',
    PARTICIPANT_DISCONNECT_CALL: 'BODSRTTOERJJDFBNDJKSFBNDFMNSDBBGNBRYERQW@END',
    PARTRICIPANT_START_SHARE: 'KJHEGUYGTUYGJHGBMABJIHJKJANMB@START_SHARE',
    PARTRICIPANT_STOP_SHARE: 'KJHEGUYGTUYGJHGBMABJIHJKJANMB@STOP_SHARE',
    PARTICIPANT_HAND_RAISE: 'QSGXVGHSVXHSGVGVSDJHBSOUYIUY@HAND_RAISE',
    PARTICIPANT_COHOST_GRANT: 'COHEGUYGTUYGJHGBMABJIHJKJANMB@COHOST_GRANT',
    PARTICIPANT_COHOST_REVOKE: 'COHEGUYGTUYGJHGBMABJIHJKJANMB@COHOST_REVOKE',
    PROMOTE: 'promote',

    CONFERENCE_LOCKED: 'ConferenceLocked',
    CAMERA_MICROPHONE_PERMISSION: 'Camera/Microphone access failed',
    CONFERENCE_END_ALL: 'QWEJKQKWEBKJCBXFCJDFKJSDSFDSFDSFDSFDS@ENDCALL',

    DISABLE_SPEAKER: 'ASPJHJKHAXZQOWQEQWOECBEIWURGEWFBDSN_DISABLE_SPEAKER ',

    PARTICIPANT_REJECT_CAMERA_REQUEST: 'ZFDSRTTOERJJDFBNDNDFMNSDBBG@VIDEO_DECLINE',
    PARTICIPANT_REJECT_MIC_REQUEST: 'ZFDSRTTOERJJDFBNDNDFMNSDBBG@MIC_DECLINE',
    PARTICIPANT_MIC_PERMISSION_DENIED: 'ZFDSRTTOERJJDFBNDNDFMNSDBBG@NO_MIC_PERMISSION',
    PARTICIPANT_CAMERA_PERMISSION_DENIED: 'ZFDSRTTOERJJDFBNDNDFMNSDBBG@NO_CAM_PERMISSION',

    UPGRADE_AUDIENCE: 'HOSTSTASDDAHJDKDMNASBMADASHWE@UPGRADE_AUDIENCE',
    DOWNGRADE_AUDIENCE: 'HOSTSTASDDAHJDKDMNASBMADASHW@DOWNGRADE_PARTICIPANT',
    SEND_VC_PIN: 'CAKASNKADNIHIHDAKNKAKDKSOWYURWGTWEBMHVB@DTMF',
    ENABLE_CHROMA_EFFECT: 'enableChromaEffect'
};
