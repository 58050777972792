export const GA_PAGEVIEW = {
    HOME_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'home_page',
        virtualPageURL: '/home'
    },
    WEBINARS_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'products_virtualevents',
        virtualPageURL: '/webinars'
    },
    VIRTUAL_EVENT_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'products_virtualevents',
        virtualPageURL: '/virtual-events'
    },
    WHITE_GLOVE_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'products_whiteglove',
        virtualPageURL: '/whiteglove'
    },
    CUSTOMERS_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'customers',
        virtualPageURL: '/customer'
    },
    SOLUTIONS_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'solutions',
        virtualPageURL: '/solutions'
    },
    PRICING_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'pricing',
        virtualPageURL: '/pricing'
    },
    CONTACT_US_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'contact_us',
        virtualPageURL: '/contactus'
    },
    SUPPORT_PAGR_VIEW: {
        event: 'pageload',
        current_page: 'support',
        virtualPageURL: '/support'
    },
    SIGNIN_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'signin',
        virtualPageURL: 'Page URL'
    },
    DASHBOARD_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'Jio event dashboard',
        virtualPageURL: '/dashboard'
    },
    EVENT_SETTING_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'jio events setting',
        virtualPageURL: '/basic'
    },
    EVENT_TEAMS_AND_ROLES_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'teams_and_roles',
        virtualPageURL: '/team-roles'
    },
    REGISTRATION_SETTING_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'Registration',
        virtualPageURL: '/registration'
    },
    IN_EVENT_SETTING_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'insession_settings',
        virtualPageURL: '/in-event'
    },
    LIVE_STREAMING_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'live_streaming',
        virtualPageURL: '/streaming'
    },
    ADDITIONAL_SETTING_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'additional_settings',
        virtualPageURL: '/additional_settings'
    },
    WELCOME_PAGE_SETTING_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'welcome_page',
        virtualPageURL: '/welcome-page'
    },
    IN_SESSION_SCREEN_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'in-session_screen',
        virtualPageURL: '/in-session'
    },
    POST_SESSION_SCREEN_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'post-session_screen',
        virtualPageURL: '/post-session'
    },
    EVENT_CONTENT_PAGE_VIEW: {
        event: 'pageload',
        current_page: 'Content',
        virtualPageURL: '/events/content'
    },
    HOME_PAGE_REVAMP_VIEW: {
        event: 'pageload',
        current_page: 'home_page_revamp',
        virtualPageURL: '/home'
    },
    PRICING_PAGE_REVAMP_VIEW: {
        event: 'pageload',
        current_page: 'pricing',
        virtualPageURL: '/pricing'
    },
    CONTACT_US_PAGE_REVAMP_VIEW: {
        event: 'pageload',
        current_page: 'contact_us',
        virtualPageURL: '/contactus'
    },
    EVENTS_PAGE_REVAMP_VIEW: {
        event: 'pageload',
        current_page: 'events',
        virtualPageURL: '/explore-events'
    }
};
