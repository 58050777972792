import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INSIDE_EVENT } from 'src/app/constants';
import { UtilService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

@Component({
    selector: 'app-event-stats-wrapper',
    templateUrl: './event-stats-wrapper.component.html',
    styleUrls: ['./event-stats-wrapper.component.scss']
})
export class EventStatsWrapperComponent implements OnInit {
    @Input() webinarOptions;
    @Input() duration = { hour: 0, minute: 0, second: 0, active: false };
    @Input() viewsCount = 0;
    @Input() likesCount = 0;
    @Input() likeOptions;
    @Input() mobile = false;
    @Input() callJoinTime = new Date();
    @Input() isPreRecordedSession;
    @Output() updateLikeDetails: EventEmitter<any> = new EventEmitter();
    showViewReactions: boolean = false;
    showReactionsCountDetails = false;
    isIosMobileBrowser = false;

    constructor(
        public callStateManagerService: CallStateManagerService,
        public callViewStateManagerService: CallViewStateManagerService,
        private cd: ChangeDetectorRef,
        private googleTagManager: GoogleTagMangerService,
        private utilService: UtilService
    ) {}

    ngOnInit(): void {
        this.callViewStateManagerService.getLikeDetails();
        this.isIosMobileBrowser = this.utilService.isMobileIOSBrowser();
    }

    async getLikeDetails(event) {
        await this.callViewStateManagerService.getLikeDetails();
        this.showReactionsCountDetails = !this.showReactionsCountDetails;
        this.cd.detectChanges();
    }

    viewReactionClick() {
        this.showViewReactions = !this.showViewReactions;
        if (this.showViewReactions) {
            this.googleTagManager.pushGoogleTagData(INSIDE_EVENT.VIEWS_AND_REACTIONS);
        }
    }

    clickOutside(e?) {}
}
