export const SOCKET_EVENTS = {
    CALL: 'call',
    CALL_ACCEPT: 'callAccept',
    JOIN_CALL: 'joinCall',
    JOIN_FROM_HISTORY: 'joinFromHistory',
    NOTIFY_HISTORY_TO_OWNER: 'notifyHistoryToOwner',
    GENERIC: 'generic',
    UPDATE_PARTICIPANT_TYPE: 'updateParticipantType',
    REMOVED_BY_HOST: 'removedByHost',
    CALL_FINISH: 'callFinish',
    CALL_NOT_ANSWERED_NOTIFICATION: 'CallNotAnswerNotification',
    CALL_CUT_NOTIFICATION: 'CallCutNotification',
    IN_ANOTHER_CALL_NOTIFICATION: 'InAnotherCallNotification',
    CALL_DROP_TOAST_NOTIFICATION: 'CallDropToastNotification',
    NOTIFICATION_ADDED: 'notificationAdded',
    GROUP_ADD_OR_EDIT: 'GroupAddOrEdit',
    GROUP_DELETE: 'GroupDelete',
    DISCONNECT_ACTIVE_CALL: 'disconnectActiveCall',
    PARTICIPANT_ADDED: 'participantAdded',
    PARTICIPANT_LEFT: 'participantLeft',
    IS_ONLINE: 'isonline',
    JOINING_INVITATION: 'joiningInvitation',
    JOINING_INVITATION_STOPPED: 'joiningInvitationWaitingStopped',
    WAITING_ROOM_ALLOW_ACTION: 'waitingRoomJoiningInvitationAction',
    REFRESH_MEETINGS: 'refreshmeetings',
    ROOM_CONNECTION_STATUS_UPDATE: 'roomconnectionstatusupdate',
    CHAT_MESSAGE: 'chatMessage',
    BREAKOUT_ROOM_START: 'breakoutRoomStart',
    BREAKOUT_ROOM_MOVE: 'breakoutRoomMove',
    BREAKOUT_ROOM_UPDATE: 'breakoutRoomUpdate',
    BREAKOUT_ROOM_END: 'breakoutRoomEnd',
    BR_RO0M_END_CALL: 'breakoutRoomEndCall',
    NORECORDERATTACHED: 'NORECORDERATTACHED',
    POST_TO_MEETING_PARTICIPANTS: 'posttomeetingparticipants',
    END_ALL: 'endCall',
    MOVE_TO_WAITING_ROOM: 'moveToWaitingRoom',
    COHOST_ADDED: 'coHostAdded',
    COHOST_REVOKED: 'coHostRevoked',
    MEETING_DELETE: 'MeetingDelete',
    ATTENDEE_REGISTERED: 'attendeeRegistered',
    NETWORKROOM_INVITATION: 'networkingRoomInvitation',
    ELEMENTS_CUSTOM_EVENT: 'elementCustomEvent'
};
