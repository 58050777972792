import { EventUserRole } from 'src/app/constants/webinar-enum';

export const CheckJoinAsCoHost = (conferenceInfo, currentUser: any = {}, MAX_COHOST_LIMIT) => {
    //const MAX_COHOST = this.appService.getConfigVariableWithDefault('WEBINAR_MAX_COHOST', APP_CONSTANT.WEBINAR_MAX_COHOST);
    // if the event is session enabled and current user is event level cohost then check whether the user can join as co-host or audience
    const sessionsList = conferenceInfo?.meetingInfo?.sessionsList;
    const sessionSpeakers = conferenceInfo?.meetingInfo?.sessionSpeakers;
    let canJoinAsCoHost = true;
    // max 4 cohost can join the call as cohost
    if (conferenceInfo?.webinar?.sessions?.enabled) {
        // check if current user is cohost
        const coHost = conferenceInfo?.meetingInfo?.cohosts?.find((cohost) => cohost.userId === currentUser._id);
        if (coHost) {
            // check if this cohost is invited to the current session or not
            const currentSession = sessionsList.find((session) => session.meetingId === conferenceInfo?.meetingId);

            const sessionSpeaker = sessionSpeakers.find((speaker) => speaker.userId === coHost.userId);
            const isInvited = currentSession?.invites.findIndex(
                (userInvitedId) => userInvitedId === sessionSpeaker?._id
            );
            if (isInvited === -1) {
                // check how many cohost have been invited to this session
                const invitedCoHostCount = sessionSpeakers.reduce((prevCount, speaker) => {
                    return speaker.role.indexOf(EventUserRole.COHOST) !== -1 &&
                        currentSession?.invites.includes(speaker._id)
                        ? ++prevCount
                        : prevCount;
                }, 0);

                const nonInvitedCoHostCount = conferenceInfo?.meetingInfo?.participants.reduce(
                    (prevCount, participant) => {
                        const isInvitedIndex = sessionSpeakers.findIndex(
                            (speaker) => speaker.userId === participant.userId
                        );
                        return participant.isCoHost &&
                            (isInvitedIndex === -1 ||
                                !currentSession?.invites.includes(sessionSpeakers[isInvitedIndex]._id))
                            ? ++prevCount
                            : prevCount;
                    },
                    0
                );

                if (nonInvitedCoHostCount + invitedCoHostCount >= MAX_COHOST_LIMIT) {
                    canJoinAsCoHost = false;
                }
            }
        }
    }

    return canJoinAsCoHost;
};

export const CheckJoinAsSpeaker = (
    conferenceInfo,
    currentUser: any = {},
    whiteListTenant,
    isAttendee,
    SPEAKER_LIMIT = 25,
    MAX_COHOST_LIMIT
) => {
    // check based on role whether a user can join as speaker/cohost
    let canJoinAsSpeaker = false;
    //Only for specific tenant non-invited speakers should go to audience mode
    const whitelistedTenantForSpeakerEntry = whiteListTenant; //this.appService.getConfigVariable('whitelistedTenantForSpeakersEntry');
    if (
        whitelistedTenantForSpeakerEntry === conferenceInfo?.ownerDetails?.tenantId &&
        !conferenceInfo?.currentUserInfo?.isInvited
    ) {
        canJoinAsSpeaker = false;
        return canJoinAsSpeaker;
    }

    const HOST_JOINED_COUNT = conferenceInfo?.currentUserInfo?.isHostJoined ? 1 : 0;

    const Max_USERS_TO_JOIN_AS_SPEAKER = SPEAKER_LIMIT - 1; // Excluding host invite count as it's reseved
    const TOTAL_INVITED_COUNT = conferenceInfo?.currentUserInfo?.inviteCount - 1; // Excluding host invite count as it's reseved
    const TOTAL_ACTIVE_SPEAKER_COUNT = conferenceInfo?.agoraSpeakerCount - HOST_JOINED_COUNT; // Excluding host invite count as it's reseved
    const Max_INVITED_USERS_TO_JOIN_AS_SPEAKER =
        TOTAL_INVITED_COUNT > Max_USERS_TO_JOIN_AS_SPEAKER ? Max_USERS_TO_JOIN_AS_SPEAKER : TOTAL_INVITED_COUNT;
    const ACTIVE_INVITED_USERS_IN_CALL = conferenceInfo?.currentUserInfo?.invitedParticipantIncall - HOST_JOINED_COUNT;

    if (!conferenceInfo?.currentUserInfo?.isInvited && !isAttendee) {
        //CASE:1 If the user is non invited then check max non invited user limit to join as speaker
        const Max_NON_INVITED_USERS_TO_JOIN_AS_SPEAKER = Max_USERS_TO_JOIN_AS_SPEAKER - TOTAL_INVITED_COUNT;

        // if invited count is more than the max participant and user is non invited then return false
        if (Max_NON_INVITED_USERS_TO_JOIN_AS_SPEAKER < 1) return false;

        // check the current count of non invited users in call
        const activeNonInvitedSpeakers = TOTAL_ACTIVE_SPEAKER_COUNT - (ACTIVE_INVITED_USERS_IN_CALL || 0);

        //remaining noninvited users
        const remainingNonInvitedCount = Max_NON_INVITED_USERS_TO_JOIN_AS_SPEAKER - activeNonInvitedSpeakers;
        if (remainingNonInvitedCount > 0) return true;
        return false;
    } else if (
        conferenceInfo?.currentUserInfo?.isInvited &&
        !isAttendee &&
        ACTIVE_INVITED_USERS_IN_CALL < Max_INVITED_USERS_TO_JOIN_AS_SPEAKER
    ) {
        // // case1: if user is invited user then user can join as speaker else invite count should be less than the max limit
        // if (
        //     conferenceInfo?.currentUserInfo?.isInvited ||
        //     conferenceInfo?.currentUserInfo?.inviteCount < SPEAKER_LIMIT
        // ) {
        //     canJoinAsSpeaker = true;
        // }

        canJoinAsSpeaker = true;
    }

    return canJoinAsSpeaker;
};
