import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-audio-result-modal',
    templateUrl: './audio-result-modal.component.html',
    styleUrls: ['./audio-result-modal.component.scss']
})
export class AudioResultModalComponent implements OnInit {
    @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();
    @Input() speakerResult;
    @Input() microphoneResult;

    constructor() {}

    ngOnInit(): void {}

    closeAudioResultModal() {
        this.closeModalEvent.emit();
    }
}
