<section id="explore" class="explore section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-8 col-md-6
                    d-flex
                    flex-column
                    explore-sec-one explore-left-area
                    pt-4 pt-lg-0
                    order-1 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1 class="jiotype-black-white-88px Support-main-txt">
                        A virtual events platform that meets your needs
                    </h1>
                </div>
                <div class="cus-point">
                    <!-- <label tabindex="0" aria-label="A virtual events platform that meets your needs,Get Started"> -->
                    <button
                        tabindex="0"
                        aria-roledescription="A virtual events platform that meets your needs,"
                        role="button"
                        (click)="getstarted()"
                        (keyup.enter)="getstarted()"
                        class="support-btn"
                    >
                        <span class="support-btn-txt">Schedule A Demo</span>
                    </button>
                </div>
            </div>
            <div
                class="col-lg-4 col-md-6 order-2 order-md-2 order-lg-2 explore-img-container aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img src="assets/img/website/thumb-concept.png" class="img-fluid explore-img virtual-img-one" alt="" />
            </div>
        </div>
    </div>
</section>
<section id="usecase-one" class="usecase-one section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1
                        tabindex="0"
                        aria-roledescription="Host customized AGMs with DPID integration, e-voting integration, upload shareholder data,
                    enterprise grade security, HD Screenshare, AGM customization"
                        role="button"
                        class="jiotype-black-white-40px"
                    >
                        AGMs
                    </h1>
                    <p class="jiotype-bold-white-24px-2">
                        Host customized AGMs with DPID integration, e-voting integration, upload shareholder data,
                        enterprise grade security, HD Screenshare, AGM customization
                    </p>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    order-1 order-md-2 order-lg-2
                    d-flex
                    align-items-center
                    usecase-one-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139239-ffYflB">
                    <img src="assets/img/website/Group_139239.png" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="usecase-two" class="usecase-two section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-2 order-lg-2
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1
                        tabindex="0"
                        aria-roledescription="Host concerts, performances and shows in High definition with premium sound, perfect
                    synchronization with multiple participants"
                        role="button"
                        class="jiotype-black-white-40px"
                    >
                        Concerts
                    </h1>
                    <p class="jiotype-bold-white-24px-2">
                        Host concerts, performances and shows in High definition with premium sound, perfect
                        synchronization with multiple participants
                    </p>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    order-1
                    d-flex
                    align-items-center
                    order-md-1 order-lg-1
                    usecase-one-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139184-04nNR6">
                    <img src="assets/img/website/Group_139184.png" class="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="usecase-one" class="usecase-one section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1
                        tabindex="0"
                        aria-roledescription="Exciting platform for exciting products!"
                        role="button"
                        class="jiotype-black-white-40px"
                    >
                        Product Launch Events
                    </h1>
                    <p class="jiotype-bold-white-24px-2">Exciting platform for exciting products!</p>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    align-items-center
                    order-1 order-md-2 order-lg-2
                    usecase-one-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139185-SE6VOV">
                    <img src="assets/img/website/Group_139185.png" class="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="usecase-four" class="usecase-four section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-2 order-lg-2
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1
                        tabindex="0"
                        aria-roledescription="Virtual meet for virtual events with enterprise grade security, broadcast to your favorite
                    social media, play HD videos"
                        role="button"
                        class="jiotype-black-white-40px"
                    >
                        Hackathons
                    </h1>
                    <p class="jiotype-bold-white-24px-2">
                        Virtual meet for virtual events with enterprise grade security, broadcast to your favorite
                        social media, play HD videos
                    </p>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    align-items-center
                    order-1 order-md-1 order-lg-1
                    usecase-one-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="user-case-four-stack">
                    <img src="assets/img/website/Group_139186.png" class="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="support-list" class="support-list section-bg">
    <div class="container">
        <div class="row">
            <div
                class="col-xl-3 feature-box col-md-6 d-flex align-items-stretch aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="100"
            >
                <div class="row text-box">
                    <h1 class="jiotype-black-white-40px">Features Tailored for every use case</h1>
                </div>
            </div>
            <ng-container *ngFor="let item of useCases">
                <div
                    class="col-xl-3 feature-box col-md-6 d-flex align-items-stretch aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                >
                    <div tabindex="0" class="icon-box" (click)="usecaseClick(item)" (keyup.enter)="usecaseClick(item)">
                        <div class="icon icon-circle">
                            <img class="icon-feature-img" src="assets/img/website/{{ item.img }}" />
                        </div>
                        <h4 class="support-type-text">
                            <a class="jiotype-bold-white-18px">{{ item.title }}</a>
                        </h4>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<section id="virtual-explore" class="virtual-explore section-bg">
    <div class="container">
        <div class="row virtual-explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    virtual-explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="rainbow-shape"></div>
                <div class="virtual-explore-main-txt">
                    <h1 class="jiotype-black-te-papa-green-64px">Plan your next event with JioEvents</h1>
                </div>
                <div class="explore-link-area cus-point">
                    <label
                        tabindex="0"
                        aria-label="Plan your next event with JioEvents"
                        (click)="exploreNow()"
                        (keyup.enter)="exploreNow()"
                        class="jiotype-medium-white-ice-24px cursor-pointer"
                        >Explore now</label
                    >
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    order-1
                    d-flex
                    order-md-2 order-lg-2
                    virtual-explore-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img src="assets/img/website/explor-image-two.png" class="img-fluid explore-img" alt="" />
            </div>
        </div>
    </div>
</section>
