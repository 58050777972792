<div class="fullHeight" [class.isMobile]="utilService.isMobileView">
    <div class="event-card" [class.isMobile]="utilService.isMobileView">
        <div (click)="gotoEvent()" class="card-media">
            <img
                appImagePlaceholder
                class="event-image"
                (imageLoadingStatus)="handleTileLoadingStatus($event)"
                [actualImageSrc]="eventCardImageUrl"
                alt="card image"
                [placeHolderImageSrc]="'./assets/img/event_banner_placeholder_potrait.svg'"
            />
            <app-event-card-dates [joinDetail]="eventinfo" *ngIf="tileBgImageLoaded"></app-event-card-dates>
            <div class="d-flex justify-content-center" *ngIf="tileBgImageLoaded">
                <div class="card-details card-link d-flex flex-column" (click)="gotoEvent()">
                    <div class="card-content d-flex flex-column flex-grow-1 justify-content-between">
                        <div class="d-flex flex-column event-title-time">
                            <div
                                #markdown
                                (click)="$event.stopPropagation()"
                                [innerHTML]="eventinfo?.topic | sanitizeHtml"
                                class="card-event-title"
                                pTooltip="{{ eventinfo.topic.length > 35 ? eventinfo.topic : '' }}"
                                tooltipPosition="{{ dashboard ? 'bottom' : 'top' }}"
                                tooltipStyleClass="{{ !dashboard ? 'white-bg-arrow' : '' }}"
                                showDelay="600"
                            ></div>
                        </div>
                        <div class="item-alignment">
                            <span *ngIf="!dashboard && !previousWebinar" class="event-price"
                                >By {{ eventinfo.hostInfo.name }} {{ eventinfo.hostInfo.lname }}</span
                            >
                            <span *ngIf="sliderName !== 'replay'" class="arrow-btn"></span>
                            <span *ngIf="sliderName == 'replay'" class="play-btn"> </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
