import { Injectable } from '@angular/core';
import { AuthService, UserService, UtilService } from '.';
import { AgoraService } from './agora.service';
import { AppService } from './app.service';
import { RtcService } from './rtc.service';
import { JmMediaService } from './jm-media.service';

@Injectable({
    providedIn: 'root'
})
export class VideoWrapperService {
    constructor(
        private rtcService: RtcService,
        private appService: AppService,
        private agoraService: AgoraService,
        private userService: UserService,
        private jmMediaService: JmMediaService,
        private authService: AuthService,
        private utilService: UtilService
    ) {}

    getVideoService(): AgoraService | JmMediaService | any {
        if (this.rtcService.getIsAgoraMeeting()) {
            return this.agoraService;
        } else if (this.rtcService.getIsJMMeeting()) {
            return this.jmMediaService;
        }
        return this.agoraService;
    }

    getVideoServiceForPrepScreen() {
        return this.getVideoService();
    }

    get networkStats() {
        return this.jmMediaService.signalStrengthData;
    }

    getAgoraBandwidthConfig(room, useHd) {
        var agoraBandwidthConfig = this.appService?.getConfigVariable('agoraBandwidthConfig');
        var enableHdForLargeScaleEvents = this.appService?.getConfigVariable('enableHdForLargeScaleEvents');
        if (useHd && enableHdForLargeScaleEvents) {
            agoraBandwidthConfig = {
                ...agoraBandwidthConfig,
                ...{
                    video: agoraBandwidthConfig,
                    lowQualityVideo: agoraBandwidthConfig.lowQualityVideo.hd
                }
            };
        }
        var webinarOptions = room.roomDetails?.webinarOptions;
        if (webinarOptions?.videoQuality) {
            var videoConfig = agoraBandwidthConfig.video[webinarOptions.videoQuality];
            var lowQualityVideoConfig = agoraBandwidthConfig.lowQualityVideo[webinarOptions.videoQuality];
            if (videoConfig && lowQualityVideoConfig) {
                agoraBandwidthConfig = {
                    ...agoraBandwidthConfig,
                    ...{
                        video: videoConfig,
                        lowQualityVideo: lowQualityVideoConfig
                    }
                };
            }
        }

        if (webinarOptions?.screenShareQuality) {
            var screenShareConfig = agoraBandwidthConfig.screenShare[webinarOptions.screenShareQuality];
            if (screenShareConfig) {
                agoraBandwidthConfig = {
                    ...agoraBandwidthConfig,
                    ...{
                        screenShare: screenShareConfig
                    }
                };
            }
        }
        return agoraBandwidthConfig;
    }

    getAdditionalConferenceInfo(room, fromJoinCall: boolean, useHd = false) {
        switch (fromJoinCall ? room.roomDetails.mediaEngine : room.mediaEngine) {
            case 'acs':
                this.rtcService.isACSMeeting = true;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isJMMeeting = false;
                return {
                    acsToken: room.acsToken,
                    acsUserId: room.acsUserId
                };

            case 'agora':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = true;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isJMMeeting = false;
                var agoraBandwidthConfig = this.getAgoraBandwidthConfig(room, useHd);

                return {
                    agoraToken: room.agoraToken,
                    agoraUid: room.agoraUid,
                    agoraChannel: fromJoinCall ? room.roomDetails.roomID : room.roomID,
                    agoraAppId: this.appService.getConfigVariable('agoraAppID'),
                    agoraVideoEncoderConfig: this.appService.getConfigVariable('agoraVideoEncoderConfig'),
                    agoraScreenShareEncoderConfig: this.appService.getConfigVariable('agoraScreenShareEncoderConfig'),
                    agoraMaxParticipants: this.appService.getMaxParticipantInCall(room),
                    agoraMaxParticipantsForRIL: this.appService.getMaxParticipantInCall(room),
                    agoraBandwidthConfig: agoraBandwidthConfig,
                    agoraScreenShareInfo: room?.agoraScreenShareInfo,
                    agoraRtmToken: room?.agoraRtmToken,
                    agoraSpeakerCount: room?.speakerCount,
                    currentUserInfo: room?.currentUserInfo,
                    ownerDetails: room?.ownerDetails
                };

            case 'jm-media':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isJMMeeting = true;

                return {
                    jmMediaMaxParticipants: room?.agoraMaxParticipants,
                    jmMediaMaxParticipantsForRIL: room?.agoraMaxParticipants,
                    agoraSpeakerCount: room?.speakerCount,
                    currentUserInfo: room?.currentUserInfo,
                    ownerDetails: room?.ownerDetails,
                    webinarOptions: room?.roomDetails?.webinarOptions
                };

            case 'janus':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = true;
                this.rtcService.isJMMeeting = false;
                return {};

            case 'pexip':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isPexipMeeting = true;
                this.rtcService.isJMMeeting = false;
                return {};

            default:
                return {};
        }
    }
}
