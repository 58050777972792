import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import {
    AppService,
    MeetingService,
    ParticipantsControlsService,
    RoomConnectionService,
    RtcService,
    UserService,
    UtilService,
    GaDiagnosticService
} from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { NetworkingRoomsService } from 'src/app/core/services/networking-rooms.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { EventSessionsService } from '../../services/event-sessions.service';
import { NETWORKING_LOUNGE } from 'src/app/constants';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';

@Component({
    selector: 'app-networking-room-add-participants',
    templateUrl: './networking-room-add-participants.component.html',
    styleUrls: ['./networking-room-add-participants.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NetworkingRoomAddParticipantsComponent implements OnInit {
    @Input() webinarId: string;
    @Input() networkingRoom: any;
    @Input() webinarDetails: any; // Not being used
    webinarRoomDetails: any;
    @Output() close = new EventEmitter();
    @ViewChild('searchText') searchText: ElementRef;
    @ViewChild('searchQuery') searchQuery: ElementRef;
    searchQueryAudience;
    usersInCall = [];
    filteredUsersInCall = [];
    roomStatus; // remove this if not required, just maintaining
    loading: boolean = true;
    @ViewChild('participantScrollViewport')
    private participantCdkVirtualScrollViewport;
    userIdsSelected = [];
    networkingRooms: any;
    maxParticipantLimit = 0;
    currentUser: any;

    constructor(
        private roomConnectionService: RoomConnectionService,
        private rtcService: RtcService,
        private toasterService: ToastrService,
        private translateService: TranslateService,
        private networkingRoomsService: NetworkingRoomsService,
        public callStateManagerService: CallStateManagerService,
        private utilService: UtilService,
        private userService: UserService,
        private meetingService: MeetingService,
        private webinarService: WebinarService,
        private appService: AppService,
        private zone: NgZone,
        private participantControlService: ParticipantsControlsService,
        private googleTagMangerService: GoogleTagMangerService
    ) {
        this.currentUser = this.userService.getUserSync();
    }

    ngOnInit(): void {
        this.maxParticipantLimit = this.rtcService.getMaxParticipantInCall();
        this.getCurrentNetworkingRoom();
        // this.networkingRoomsService.getNetworkingRoomsObservable().subscribe((res) => {
        //     this.zone.run(() => {
        //         if (!res) {
        //             return;
        //         }
        //         this.networkingRoom = res?.rooms?.find((r) => r._id === this.networkingRoom._id);
        //     });
        // });
        this.getCurrentNetworkingRoomParticipants();
        var isSessionEnabled =
            this.webinarDetails?.sessionsEnabled ||
            this.webinarDetails?.webinar?.sessions?.enabled ||
            this.callStateManagerService.getParentWebinarDetails()?.webinar?.sessions?.enabled;
        if (isSessionEnabled) {
            this.getCurrentParticipantsFromAllSessions();
        } else {
            this.webinarRoomDetails = this.callStateManagerService.getParentEventRoomDetails()?.roomDetails;
            this.getMeetingParticipants(
                this.webinarRoomDetails.jiomeetId,
                this.webinarRoomDetails.roomId || this.webinarRoomDetails.roomID,
                this.webinarRoomDetails.pin || this.webinarRoomDetails.roomPIN
            );
        }
        // this.usersInCall = this.networkingRoom?.participants || [];
        // this.loading = false;
        this.updateCDKViewPortSize();
    }

    getCurrentNetworkingRoomParticipants() {
        this.getCurrentNetworkingRoom();
        this.networkingRoomsService.getRooms(this.callStateManagerService.getParentMeetingId()).subscribe(
            (res) => {
                this.networkingRoom.participants =
                    res?.rooms?.find((r) => r._id === this.networkingRoom?._id)?.participants || [];
            },
            (err) => {
                this.toasterService.error('Something went wrong');
            }
        );
    }

    getCurrentNetworkingRoom() {
        if (!this.networkingRoom) {
            this.networkingRoom = this.callStateManagerService.getNetworkingRoom();
        }
    }

    updateCDKViewPortSize() {
        setTimeout(() => {
            // this.audienceCdkVirtualScrollViewport?.checkViewportSize();
            this.participantCdkVirtualScrollViewport?.checkViewportSize();
        }, 500);
    }

    getMeetingParticipants(jiomeetId, roomId, roomPIN) {
        this.participantControlService.getMeetingParticipants(jiomeetId, roomId, roomPIN).subscribe(
            (res: any) => {
                this.usersInCall = res.participants || [];
                this.filterParticipantsList();
                this.loading = false;
            },
            (err) => {
                this.toasterService.error(
                    this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                );
            }
        );
    }

    async getCurrentParticipantsFromAllSessions() {
        this.webinarService
            .getWebinarSessionsInfo(this.rtcService.webinarIsSessionInfo.meetingId, !this.userService.getUserSync()._id)
            .subscribe(
                (res) => {
                    let sessions = res?.sessions.filter((s) => s.type !== 'break') || [];
                    forkJoin(sessions.map((s) => this.getMeetingDetailsObs(s.meetingId))).subscribe(
                        (meetingRespArr) => {
                            forkJoin(
                                meetingRespArr.map((m: any) =>
                                    this.participantControlService.getMeetingParticipants(
                                        m.jiomeetId,
                                        m.roomId || m.roomID,
                                        m.pin
                                    )
                                )
                            ).subscribe(
                                (participantsResp: any) => {
                                    this.usersInCall = [];
                                    participantsResp.forEach((pArr) => {
                                        this.usersInCall = this.usersInCall.concat(pArr.participants || []);
                                    });
                                    this.filterParticipantsList();
                                    this.loading = false;
                                },
                                (err) => {
                                    this.toasterService.info('Unable to get all the participants');
                                }
                            );
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    getMeetingDetailsObs(meetingId) {
        return this.meetingService.getMeetingDetails(meetingId);
    }

    isItMe() {
        return false;
    }

    submitParticipantsAddition() {
        if (this.userIdsSelected.length === 0) {
            this.toasterService.info('Please select any speaker to add');
            return;
        }
        this.googleTagMangerService.pushGoogleTagData(NETWORKING_LOUNGE.CONFIRM_ADD_PARTICIPANTS);
        const payload = {
            meetingId: this.webinarRoomDetails?.meetingId || this.rtcService.webinarIsSessionInfo.meetingId,
            source: {
                jiomeetId: this.webinarRoomDetails?.jiomeetId,
                roomId: this.webinarRoomDetails?.roomId,
                pin: this.webinarRoomDetails?.pin,
                senderName: this.utilService.getFullName(this.userService.getUserSync())
            },
            // destination: {
            //     jiomeetId: this.networkingRoom.jiomeetId,
            //     roomId: this.networkingRoom.roomId,
            //     pin: this.networkingRoom.pin
            // },
            destination: this.networkingRoom,
            userIdsToBeMoved: this.userIdsSelected
        };
        this.networkingRoomsService.sendNetworkRoomInvitation(payload).subscribe(
            (res) => {
                this.close.emit();
            },
            (err) => {
                this.close.emit();
                this.toasterService.error(
                    this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                );
            }
        );
    }

    searchQueryHandler(event) {
        this.filteredUsersInCall = this.usersInCall;
        this.filteredUsersInCall = this.filteredUsersInCall.filter((p) =>
            p.participantName.toLowerCase().includes(this.searchQueryAudience.toString().toLowerCase())
        );
        this.googleTagMangerService.pushGoogleTagData(NETWORKING_LOUNGE.SEARCH_PARTICIPANTS);
    }

    closeFilterByNameAudience() {
        this.filteredUsersInCall = this.usersInCall;
        this.searchQueryAudience = '';
    }

    closeFormSidePanel() {
        this.close.emit();
    }

    trackByFuntion(index, item) {
        return index;
    }

    selectSpeaker(event, participant) {
        const pIdx = this.usersInCall.findIndex((p) => p.participantId === participant.participantId);
        this.usersInCall[pIdx].selected = !this.usersInCall[pIdx]?.selected;
        if (this.usersInCall[pIdx].selected) {
            this.userIdsSelected.push(this.usersInCall[pIdx].userId);
        } else {
            const removal_idx = this.userIdsSelected.indexOf(this.usersInCall[pIdx].userId);
            if (removal_idx > -1) {
                this.userIdsSelected.splice(removal_idx, 1);
            }
        }
    }

    filterParticipantsList() {
        this.usersInCall = this.usersInCall.filter(
            (p) =>
                p.userId !== this.currentUser._id &&
                p?.deviceType?.toLowerCase() !== 'android' &&
                p?.deviceType?.toLowerCase() !== 'ios'
        );
        this.filteredUsersInCall = this.usersInCall;
    }
}
