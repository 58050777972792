<div class="leaderboard-share-achievement-wrapper" [class.isMobile]="isMobileOS">
    <img
        class="close-icon pointer"
        [src]="isMobileOS ? 'assets/img/close_icon_green.svg' : 'assets/images/leaderboard/ic_close.svg'"
        alt=""
        (click)="close()"
    />
    <div class="upper-gradient" *ngIf="!isMobileOS"></div>
    <div class="lower-gradient" *ngIf="!isMobileOS"></div>
    <div class="share-header">Share Achievement</div>
    <!-- <div class="certificate"></div> -->
    <img class="certificate" [src]="prizeAsset" />
    <div class="footer">
        <div class="share-text" *ngIf="!isMobileOS">Share your achievement with your friends!</div>
        <img
            class="download-icon action-icon pointer"
            src="assets/images/leaderboard/ic_download.svg"
            (click)="onAcheivementActionClick('download')"
        />
        <div class="vertical-divider"></div>
        <img
            class="facebook-icon action-icon pointer"
            src="assets/images/leaderboard/fb_icon.svg"
            (click)="onAcheivementActionClick('facebook')"
        />
        <img
            class="twitter-icon action-icon pointer"
            src="assets/images/leaderboard/ic_twitter.svg"
            (click)="onAcheivementActionClick('twitter')"
        />
        <img
            class="linked-icon action-icon pointer"
            src="assets/images/leaderboard/ic_linkedin.svg"
            (click)="onAcheivementActionClick('linkedin')"
        />
    </div>
</div>
