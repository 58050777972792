import { Pipe, PipeTransform } from '@angular/core';
import { isAfter } from 'date-fns';

@Pipe({
    name: 'isAfterEndTime'
})
export class IsAfterEndTimePipe implements PipeTransform {
    transform(dateToCompare: Date): boolean {
        return isAfter(new Date(), new Date(dateToCompare));
    }
}
