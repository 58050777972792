import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EXPLORE_EVENTS } from 'src/app/constants';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
    searchQueryEvents = '';
    search = '';
    searchModelChanged: Subject<string> = new Subject<string>();
    @Input() id;
    @Output() onSearch = new EventEmitter();
    constructor() {}

    ngOnInit(): void {
        this.searchModelChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((model) => {
            this.onSearch.emit(model);
        });
    }

    searchQueryHandler(event) {
        this.searchModelChanged.next(event);
    }

    closeSearchQueryEvents() {
        this.searchQueryEvents = '';
        this.searchModelChanged.next('');
    }
}
