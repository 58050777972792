import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { map, tap } from 'rxjs/operators';
import { differenceInMinutes } from 'date-fns';

import { RestService } from './rest.service';
import { UserService } from './user.service';
import { SocketService } from './socket.service';
import { MAIL_TEMPLATES } from 'src/app/constants/mail-templates';
import { SOCKET_EVENTS } from 'src/app/constants';
import { AppService } from './app.service';
import { UtilService } from './util.service';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class RtcService {
    private room;
    private breakoutRoomInfo;
    private isConferenceActive;
    private isInitiater = false;
    private shareUrlInfo: any = {};
    private conferenceInfo;
    private chimeAudioEnabledForHost = false;
    private cameraMicUserPreference = {
        cameraPrivacy: false,
        micPrivacy: false
    };
    isJMMeeting = false;
    isACSMeeting = false;
    isJanusMeeting = false;
    isCreateACSMeeting = true;
    isAgoraMeeting = false;
    private incomingCallObject: any = null;
    isPreparatorySetupDone = false;
    isVirtualBackgroundApplied: boolean = false;
    webinarIsSessionInfo: any = { enabled: false };
    isAuthenticated = false;
    isPexipMeeting = false;
    attendeeUserId: any;
    attendeeRedirectUrl: any;
    constructor(
        private datePipe: DatePipe,
        private restService: RestService,
        private socketService: SocketService,
        private userService: UserService,
        private appService: AppService,
        private utilService: UtilService,
        private authService: AuthService,
        private localStorageService: LocalStorageService
    ) {
        this.isAuthenticated = this.authService.getIsAuthenticated();
    }

    getConferenceInfo() {
        return this.conferenceInfo;
    }

    setConferenceInfo(info) {
        this.isPreparatorySetupDone = true;
        this.conferenceInfo = info;
        if (info?.room?.mediaEngine === 'agora') {
            this.setIsAgoraMeeting(true);
        }
        if (info?.room?.mediaEngine === 'jm-media') {
            this.setIsJMMeeting(true);
        }
    }

    getCameraMicPreference() {
        return this.cameraMicUserPreference;
    }

    setCameraMicPreference(cameraPrivacy, micPrivacy) {
        this.cameraMicUserPreference = {
            cameraPrivacy,
            micPrivacy
        };
    }

    setIsConferenceActive(bool) {
        this.isConferenceActive = bool;
    }

    getIsConferenceActive() {
        return this.isConferenceActive;
    }

    setIsInitiater(bool) {
        this.isInitiater = bool;
    }

    getIsInitiater() {
        return this.isInitiater;
    }

    sendEvent(event) {
        this.socketService.send(event);
    }

    makeCall(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/call`, payload);
    }

    joinCall(payload, guest = false) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/joinCall${guest ? '/guest' : ''}`,
            payload
        );
    }

    userEngagedInCall(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/userEngagedInCall`, payload);
    }

    setRoomInfo(room) {
        this.room = room;
    }

    getRoomInfo() {
        return this.room;
    }

    setBreakoutRoomInfo(info) {
        this.breakoutRoomInfo = info;
    }

    getBreakoutRoomInfo() {
        return this.breakoutRoomInfo;
    }

    setShareUrlInfo(info) {
        this.shareUrlInfo = info;
    }

    getShareUrlInfo() {
        return this.shareUrlInfo;
    }

    setWebinarIsSessionInfo(info) {
        this.webinarIsSessionInfo = info;
    }

    getWebinarIsSessionInfo() {
        return this.webinarIsSessionInfo;
    }

    getCallShareUrl(room) {
        let payload = {
            roomID: (room.roomid || room.roomID).toString(),

            room_url: room.roomurl || room.room_url,
            extension: (room.extension || '').toString(),
            gateway_ip: room.gatewayip || room.gateway_ip || room.gatewayIp,
            jiomeetId: room.jiomeetId
        };
        if (room.roomkey || room.room_key) {
            payload['room_key'] = room.roomkey || room.room_key;
        }
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/shorturl/getshareurlhash`, payload);
    }

    isValidRoomAndPinFlag({ extension = '', hash = '', userId = '' }) {
        const payload: any = {
            ...(extension && { extension }),
            ...(hash && { hash }),
            ...(userId && { userId })
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/roomdetails/ispin`,
            payload
        );
    }

    getRoom(usePersonal: boolean) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/getroom`, {
            usePersonal
        });
    }

    getRoomDetailsGuest({
        extension = '',
        pin = '',
        hash = '',
        memberName = '',
        userId = '',
        isAuthenticated = false,
        captchaText = '',
        captchaId = '',
        breakoutpoll = false,
        roomID = null,
        identity = null,
        recorderToken = '',
        isLiveStreaming = false
    }) {
        const payload: any = {
            ...(extension && { extension }),
            ...(pin && { pin }),
            ...(hash && { hash }),
            ...(memberName && { memberName }),
            ...(userId && { userId }),
            ...(roomID && { roomID }),
            ...(identity && { identity }),
            ...(recorderToken && { recorderToken }),
            ...(isLiveStreaming && { isLiveStreaming })
        };

        let headers: HttpHeaders = new HttpHeaders();
        if (captchaId && captchaText) {
            headers = headers.set('captcha-id', captchaId);
            headers = headers.set('captcha-text', captchaText);
        }

        console.log(payload);

        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/roomdetails${isAuthenticated ? '/host' : ''}${
                breakoutpoll ? '?breakoutpoll=true' : ''
            }`,
            payload,
            { headers }
        );
    }

    joinWithTV({ meetingHash, memberName, extension, pin }) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/shorturl/joinwithtv`, {
            meetingHash,
            memberName,
            extension,
            pin
        });
    }

    decodeUrl(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/shorturl/decode`, payload);
    }

    getInvitation(userName, topic, startTime, endTime, meetingURL, meetingId, password, addToJmtUrl, legacyConnect) {
        return MAIL_TEMPLATES.INVITATION({
            userName,
            topic,
            startTime: startTime ? this.datePipe.transform(new Date(startTime), 'EEEE, MMMM d HH:mm a') : null,
            duration: startTime && endTime ? differenceInMinutes(endTime, startTime) : null,
            meetingURL,
            meetingId,
            password,
            addToJmtUrl,
            legacyConnect
        });
    }

    inviteVC(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/vc/invite`, payload);
    }

    leaveRoom(payload, guest = false) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/leaveall${guest ? '/guest' : ''}`,
            payload
        );
    }

    endParticipant(participantID) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/endparticipant`, {
            roomID: '' + (this.room.roomid || this.room.roomID || this.room.room_id),
            participantID
            // meetingId
        });
    }

    toggleLegacyVideo(participantID, isMute) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/stopVideo`, {
            roomID: '' + (this.room.roomid || this.room.roomID || this.room.room_id),
            participantID,
            mute: isMute
            // meetingId
        });
    }

    toggleLegacyAudio(participantID, isMute) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/muteAudio`, {
            roomID: '' + (this.room.roomid || this.room.roomID || this.room.room_id),
            participantID,
            mute: isMute
            // meetingId
        });
    }

    getParticipantsDetails(roomID) {
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/getparticipantsdetails`, {
                roomID: '' + roomID
            })
            .pipe(
                map((res: any) => res.Entity),
                map((entities) => (Array.isArray(entities) ? entities : [entities]))
            );
    }

    lockRoom(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/lock-room`, payload);
    }

    unlockRoom(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/unlock-room`, payload);
    }

    toggleChimeAudio() {
        this.chimeAudioEnabledForHost = !this.chimeAudioEnabledForHost;
    }

    isChimeAudioEnabledForHost() {
        return this.chimeAudioEnabledForHost;
    }

    getActiveCallDetails() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/calls/inAnActiveCall`);
    }

    toggletRecording(bool, historyId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/recording/${bool ? 'start' : 'stop'}`,
            {
                historyId
            }
        );
    }

    startRecording(historyId) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/recording/start`, {
            historyId
        });
    }

    stopRecording(historyId) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/recording/stop`, {
            historyId
        });
    }

    dispose() {
        this.chimeAudioEnabledForHost = false;
        this.isConferenceActive = false;
        this.breakoutRoomInfo = null;
        this.cameraMicUserPreference = {
            cameraPrivacy: false,
            micPrivacy: false
        };
    }

    getWhiteboardUrl(jiomeetId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/rooms/${jiomeetId}/whiteboard`);
    }

    startWhiteboard(jiomeetId, guest = false) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/rooms/${jiomeetId}/whiteboard/startsharing${
                guest ? '/guest' : ''
            }`,
            {}
        );
    }
    setIsJMMeeting(isJMMeeting: boolean) {
        this.isJMMeeting = isJMMeeting;
    }

    getIsJMMeeting() {
        return this.isJMMeeting;
    }

    setIsACSMeeting(isACSMeeting: boolean) {
        this.isACSMeeting = isACSMeeting;
    }

    getIsACSMeeting() {
        return this.isACSMeeting;
    }

    setIsJanusMeeting(val: boolean) {
        this.isJanusMeeting = val;
    }

    getIsJanusMeeting() {
        return this.isJanusMeeting;
    }

    setCreateACSMeeting(isCreateACSMeeting: boolean) {
        this.isCreateACSMeeting = isCreateACSMeeting;
    }

    setIsAgoraMeeting(isAgoraMeeting: boolean) {
        this.isAgoraMeeting = isAgoraMeeting;
    }

    getIsAgoraMeeting() {
        return this.isAgoraMeeting;
    }

    setIsPexipMeeting(isPexipMeeting: boolean) {
        this.isPexipMeeting = isPexipMeeting;
    }

    getIsPexipMeeting() {
        return this.isPexipMeeting;
    }

    getCreateACSMeeting() {
        return this.isCreateACSMeeting;
    }

    sendCallAcceptNotification() {
        const meeting = this.incomingCallObject;
        const user = this.userService.getUserSync();
        this.setIncomingCallMeeting(null);
        return this.restService.post('/api/callAccept', {
            event: SOCKET_EVENTS.CALL_ACCEPT,
            data: {
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                status: 'true',
                historyId: meeting.historyId
            }
        });
    }

    sendCallRejectNotification() {
        const meeting = this.incomingCallObject;
        const user = this.userService.getUserSync();
        this.setIncomingCallMeeting(null);
        return this.restService.post('/api/generic', {
            event: SOCKET_EVENTS.GENERIC,
            data: {
                eventType: SOCKET_EVENTS.CALL_CUT_NOTIFICATION,
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                ownId: user._id,
                roomKey: meeting.room,
                name: `${user.name} ${user.lname}`,
                phoneNo: user.phoneNo || '0123456789',
                historyId: meeting.historyId
            }
        });
    }

    sendCallNotAnsweredNotification() {
        const meeting = this.incomingCallObject;
        const user = this.userService.getUserSync();
        this.setIncomingCallMeeting(null);
        return this.restService.post('/api/generic', {
            event: SOCKET_EVENTS.GENERIC,
            data: {
                eventType: SOCKET_EVENTS.CALL_NOT_ANSWERED_NOTIFICATION,
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                ownId: user._id,
                roomKey: meeting.room,
                name: `${user.name} ${user.lname}`,
                phoneNo: user.phoneNo || '0123456789',
                historyId: meeting.historyId
            }
        });
    }

    sendInAnotherCallNotification(callObj?) {
        const meeting = callObj || this.incomingCallObject;
        const user = this.userService.getUserSync();
        if (!callObj) {
            this.setIncomingCallMeeting(null);
        }
        return this.restService.post('/api/generic', {
            event: SOCKET_EVENTS.GENERIC,
            data: {
                eventType: SOCKET_EVENTS.IN_ANOTHER_CALL_NOTIFICATION,
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                ownId: user._id,
                roomKey: meeting.room,
                name: `${user.name} ${user.lname}`,
                phoneNo: user.phoneNo || '0123456789',
                historyId: meeting.historyId
            }
        });
    }

    setIncomingCallMeeting(meeting) {
        this.incomingCallObject = meeting;
    }

    getIncomingCallMeeting() {
        return this.incomingCallObject;
    }

    setPreparatorySetupDone(state: boolean) {
        this.isPreparatorySetupDone = state;
    }

    getPreparatorySetupDone() {
        return this.isPreparatorySetupDone;
    }

    isVirtualBackgroundEnabledForRoom() {
        return (this.isAgoraMeeting || this.isJMMeeting) &&
            (this.userService.getUserSync()?.webinarMediaEnginePreference === 'agora' ||
                this.userService.getUserSync()?.webinarMediaEnginePreference === 'jm-media') &&
            this.appService.enableVirtualBackground
            ? true
            : false;
    }

    disableVirtualBackgroundSupport() {
        return this.utilService.isSafariBrowser() || this.utilService.isJioPcCheck();
    }

    getMaxParticipantInCall() {
        return this.conferenceInfo?.agoraMaxParticipants || this.appService.getConfigVariable('agoraMaxParticipants');
    }

    getRoomPinAndId(sh) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/meetingCred/${sh}`);
    }
}
