import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from './../services/auth.service';
import { AppService } from './../services/app.service';

import { TokenInterceptor } from './token.interceptor';
import { JiocloudService } from '../services/jiocloud.service';
import { JiocloudAuthService } from '../services/jiocloud-auth.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private authService: AuthService,
        private appService: AppService,
        private tokenInterceptor: TokenInterceptor,
        private jiocloudAuthService: JiocloudAuthService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let queryParams = {};
        this.activatedRoute.queryParams.subscribe((params) => (queryParams = params));
        return next.handle(request).pipe(
            catchError((response: any) => {
                // jio clod
                if (
                    [
                        '/upload/files',
                        '/upload/files/chunked/initiate',
                        '/share/meeting',
                        '/download/meeting/',
                        '/download/files/',
                        '/media/auth/meeting/',
                        '/media/hls/',
                        '/media/stream',
                        '/nms/meeting/trash/'
                    ].filter((path) => request.url.indexOf(path) > -1).length &&
                    response.status === 401
                ) {
                    // return this.jiocloudAuthService.refreshToken().pipe(
                    //   catchError((e) => throwError(e)),
                    //   mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    // );
                    return this.jiocloudAuthService.login().pipe(
                        catchError((e) => throwError(e)),
                        mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    );
                }
                if (request.url.includes('/account/token/refresh') && response.status === 401) {
                    return this.jiocloudAuthService.login().pipe(
                        catchError((e) => throwError(e)),
                        mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    );
                }

                if (
                    !request.url.includes('login') &&
                    !request.url.includes('logout') &&
                    !request.url.includes('refreshtoken') &&
                    response.status === 401
                ) {
                    // extra condition ?
                    return this.authService.refreshToken().pipe(
                        catchError((e) => {
                            this.authService.cleanup();
                            // this.router.navigate(['/login']);
                            return throwError(e);
                        }),
                        mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    );
                }
                // else if (response.status === 403) {

                // // forbidden
                // if (!this.authService.sessionTimeout) {
                //   this.authService.sessionTimeout = true;
                //   this.toastrService.error(
                //     typeof response.error === 'string' ? response.error : response.error.errors
                //   );
                //   this.authService.cleanup();
                //   this.router.navigate(['/login']);
                // }
                // return throwError(response);
                // }

                if (request.url.includes('refreshtoken') && response.status === 401) {
                    if (window.location.href.includes('/myjio')) {
                        this.router.navigate(['/integrations/myjio/home']);
                    } else {
                        // forbidden
                        if (!this.authService.sessionTimeout) {
                            this.authService.sessionTimeout = true;
                            if (!this.appService.isMyJio) {
                                this.toastrService.error(
                                    typeof response.error === 'string' ? response.error : response.error.errors
                                );
                            }
                            this.authService.cleanup();
                            if (
                                this.router.url.includes('mst') ||
                                this.router.url.includes('outlook') ||
                                this.router.url.includes('oauth')
                            ) {
                                window.location.reload();
                            } else {
                                this.router.navigate(['/login'], {
                                    queryParams
                                });
                            }
                        } else {
                            this.router.navigate(['/login']);
                        }
                        return throwError(response);
                    }
                }

                if (response.status === 429 && response.error.message === 'LOGIN_LIMITS_REACHED') {
                    return this.authService.switch(response.error.token).pipe(
                        catchError((e) => throwError(e)),
                        mergeMap((res) => {
                            return of(new HttpResponse({ body: res }));
                        })
                    );
                }

                if (
                    response.status === 412 &&
                    response.error.message === 'TOKEN_EXPIRED' &&
                    ['/api/verifyotp', '/api/authorize/google', '/api/authorize/facebook', '/api/sign/signup'].filter(
                        (path) => request.url.indexOf(path) > -1
                    ).length
                ) {
                    this.authService.cleanup();
                    this.router.navigate(['/']);
                }

                if (
                    (response.status === 502 || response.status === 503) &&
                    !this.jiocloudAuthService.isJioCloudRequest(request)
                ) {
                    this.authService.isServerDown(true);
                    // this.router.navigate(['/server-down']);
                }

                return throwError(response);
            })
        );
    }
}
