import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, UtilService } from 'src/app/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    email;
    hash;

    changePasswordForm: FormGroup;
    errors: any = {};
    toggleUI = true;
    showPassword = false;

    validPassword = false;
    @Output() close: EventEmitter<any> = new EventEmitter();
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private utilService: UtilService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.email = params.email;
            this.hash = params.hash;
        });
        this.changePasswordForm = this.fb.group({
            oldPassword: [null, [Validators.required]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });
        this.changePasswordForm.get('password').valueChanges.subscribe(() => {
            this.validPassword = this.validatePassword(this.changePasswordForm.get('password').value);
        });
    }

    cancel() {
        this.close.emit();
    }

    validate() {
        this.errors = {};
        const password = (this.changePasswordForm.get('password').value || '').trim();
        const confirmPassword = (this.changePasswordForm.get('confirmPassword').value || '').trim();
        if (password !== confirmPassword) {
            this.errors.global = this.translateService.instant('tostrs.passwords_do_not_match_please_reenter');
            return false;
        }
        return true;
    }

    submit() {
        // this.errors = {};
        if (!this.changePasswordForm.valid || !this.validPassword) {
            return;
        }
        if (!this.validate()) {
            return;
        }

        this.authService
            .changePassword({
                oldPassword: this.changePasswordForm.value.oldPassword,
                newPassword: this.changePasswordForm.value.password
            })
            .subscribe(
                () => {
                    this.toastrService.success(this.translateService.instant('tostrs.passwords_changed'));
                    this.authService.cleanup();
                    this.router.navigate(['/login']);
                },
                (err) => {
                    if (err?.error?.customCode === 400) {
                        this.errors.global =
                            err.error?.errors ||
                            'Change Password Failed! Please make sure you have entered correct details.';
                    }
                }
            );
    }

    validatePassword(password) {
        this.errors.password = this.utilService.validatePassword(password);
        return !!this.errors.password ? false : true;
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
    }
}
