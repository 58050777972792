<div class="games-panel">
    <div class="games-main-container" *ngIf="!gamesStarted && !winningStarted">
        <div class="header" *ngIf="!isMobileOS">
            <div class="call-options-header">
                <div class="title heading">Games</div>
                <div class="myWinning-wrapper">
                    <button class="custom-button-revamp btn-secondary sm w-auto m-0" (click)="redirectToMyWinning()">
                        My Winnings
                    </button>
                    <div (click)="closeView()" class="close-icon pointer"></div>
                </div>
            </div>
        </div>
        <div class="play-info">
            <div class="play-mobile">
                <div class="play-text">Play & Win</div>
                <span class="play-subtext"> Win exciting prizes everyday </span>
            </div>
            <button
                class="custom-button-revamp btn-secondary sm w-auto m-0"
                (click)="redirectToMyWinning()"
                *ngIf="isMobileOS"
            >
                My Winnings
            </button>
        </div>
        <ng-container>
            <div class="no-games-info-wrapper" *ngIf="!gamesLength">
                <img src="assets/images/event-call-options/no_games.svg" />
                <span class="no-games-subtext">Sorry, the host has not added games yet.</span>
            </div>
            <div class="games-body" *ngIf="gamesLength">
                <div class="grid-container">
                    <div
                        class="games-buttons"
                        *ngFor="let game of gamesList"
                        (click)="selectedGame(game)"
                        [checked]="gamesList.includes(game)"
                        [class.selected]="true"
                    >
                        <img class="img-games" [src]="gamesImage(game)" alt="" />
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="iframe-container" *ngIf="gamesStarted && !winningStarted">
        <app-loader *ngIf="!gameLoaded"></app-loader>
        <iframe width="100%" style="height: 100%; border: none" [src]="gamesrc"> </iframe>
    </div>

    <div class="iframe-container" *ngIf="winningStarted">
        <iframe width="100%" style="height: 100%; border: none" [src]="mywinninglink"> </iframe>
    </div>
</div>
