import { Injectable } from '@angular/core';
import { RtcService } from './rtc.service';
import { AgoraService } from './agora.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { RoomConnectionService } from './room-connection.service';
import * as _ from 'lodash';
import { UtilService } from './util.service';
import { AppLoggerService } from './app-logger.service';
import { VideoWrapperService } from './video-wrapper.service';
import { JmTranslateService } from './jm-translate.service';

@Injectable({
    providedIn: 'root'
})
export class MediaStreamRetrieverService {
    videoService;
    galleryFilters: any = {};
    playAsStream: boolean = true;
    rcsParticipants = [];
    constructor(
        private roomConnectionService: RoomConnectionService,
        private utilService: UtilService,
        private appLoggerService: AppLoggerService,
        private rtcService: RtcService,
        private videoWrapperService: VideoWrapperService,
        private jmTranslateService: JmTranslateService
    ) {}
    galleryViewUpdates$ = new Subject();

    getRemoteParticipants(type = 'complete') {
        if (!type || type === 'complete') {
            return this.utilService.clone(
                _.uniqBy(this.videoWrapperService.getVideoService().galleryParticipants, 'uid')
            );
        } else {
            return _.uniqBy(this.videoWrapperService.getVideoService().galleryParticipants, 'uid').filter((p) => {
                const filterIds = _.get(this.galleryFilters, type, []);
                return filterIds.includes(p.uid.toString());
            });
        }
    }

    setGalleryFilters(type, participantIds) {
        _.set(this.galleryFilters, type, participantIds);
    }

    updateVideoService() {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    getGalleryViewUpdatesEmitter() {
        this.updateVideoService();
        return this.galleryViewUpdates$;
    }

    handleAgoraParticipantEvents() {
        this.videoWrapperService.getVideoService().participantAdded.subscribe((participantData) => {
            this.handleParticipantAdded(participantData);
        });
        this.videoWrapperService.getVideoService().participantRemoved.subscribe((participantData) => {
            this.handleParticipantExit({
                type: 'participantRemoved',
                data: participantData,
                participantId: participantData.uid
            });
            this.roomConnectionService.syncParticipantsWithAgoraUsers();
        });
        this.videoWrapperService.getVideoService().participantStatusUpdated.subscribe((data) => {
            this.handleParticipanUpdate(data);
        });
        this.roomConnectionService.getRoomConnectionStatus$().subscribe((res) => {
            this.handleParticipantsListUpdate(res?.participants);
        });
    }

    handleParticipantAdded(participantData) {
        this.galleryViewUpdates$.next({
            type: 'participantAddition',
            participantId: participantData.uid,
            data: participantData
        });
    }

    handleParticipantExit(participantData) {
        this.galleryViewUpdates$.next({
            type: 'participantExit',
            participantId: participantData.participantId,
            data: participantData
        });
    }

    handleParticipanUpdate(participantData) {
        /**
         * In Agora, following events are being listened in this flow
         * -> screenSharingStarted
         * -> screenSharingStopped
         * -> isVolumeChanged
         * -> userStreamPublished
         * -> spotlight
         */
        this.galleryViewUpdates$.next({
            type: 'participantUpdate',
            participantId: participantData?.data?.uid,
            data: participantData
        });
    }

    async getVideoStream(participant, type = 'userFeed') {
        if (participant.hasVideo && participant.videoTrack && type == 'userFeed') {
            this.appLoggerService.log('Video Track already exists');
            return;
        }
        try {
            if (this.rtcService.isJMMeeting) {
                if (type == 'screenshare') {
                    await this.videoWrapperService
                        .getVideoService()
                        .jmMediaClient.subscribe(participant, 'screenVideo');
                } else {
                    await this.videoWrapperService
                        .getVideoService()
                        .jmMediaClient.subscribe(participant, 'cameraVideo');
                }
            } else {
                await this.videoWrapperService.getVideoService().agoraClient.subscribe(participant, 'video');
            }
        } catch (err) {
            this.appLoggerService.error('Error subscribing to video stream', err);
        }
    }

    getAudioStream(participant, type) {
        try {
            if (this.rtcService.isJMMeeting) {
                // if (type == 'screenshare' || type == 'cloudvideo') {
                if (type == 'screenshare') {
                    return this.videoWrapperService
                        .getVideoService()
                        .jmMediaClient.subscribe(participant, 'screenAudio');
                } else {
                    return this.videoWrapperService
                        .getVideoService()
                        .jmMediaClient.subscribe(participant, 'microphoneAudio');
                }
            } else {
                return this.videoWrapperService.getVideoService().agoraClient.subscribe(participant, 'audio');
            }
        } catch (err) {
            this.appLoggerService.error('Error subscribing to audio stream', err);
        }
    }

    async getOrCreateMediaStreamClone(participantId, type = 'userFeed') {
        this.appLoggerService.log('participant Id media stream clone', participantId);
        try {
            if (
                this.videoWrapperService.getVideoService().localParticipant &&
                participantId === this.videoWrapperService.getVideoService().localParticipant?.id
            ) {
                return this.getLocalCameraTrackClone();
            }
            let participant;
            // if (this.isScreenShareUid(participantId)) {
            if (type == 'screenshare') {
                participant = this.getRemoteParticipatById(participantId);
                if (!participant || (this.rtcService.isJMMeeting && !participant.hasScreenShareVideo)) return;
            }
            // else if (this.videoWrapperService.getVideoService().isCloudVideoUid(participantId)) {
            else if (type == 'cloudvideo') {
                // participant = this.videoWrapperService.getVideoService().nonGalleryParticipants.find((p) => p.uid == participantId);
                participant = this.videoWrapperService.getVideoService().cloudVideoStream;
                if (!participant) return;
            } else {
                participant = this.getRemoteParticipatById(participantId);
                if (!participant) return;
            }

            if (participant.hasAudio || participant?.hasScreenShareAudio) {
                this.playAudioTrack(participant, type);
            }
            if (
                type == 'screenshare' && this.rtcService.isJMMeeting
                    ? !participant.hasScreenShareVideo
                    : !participant.hasVideo
            )
                return;
            this.appLoggerService.log('Getting Video Stream to clone');
            const videoTrack = await this.playVideoTrack(participant, type);
            if (!videoTrack) {
                this.appLoggerService.log('No video track found, ', participant);
                return;
            }
            if (this.rtcService.isJMMeeting && !this.playAsStream) {
                return videoTrack;
            } else {
                return new MediaStream([(videoTrack || participant.videoTrack)?.getMediaStreamTrack()]);
            }
        } catch (err) {
            this.appLoggerService.log(`Error Get or create media stream clone`, err);
        }
    }

    getRemoteParticipatById(participantId) {
        return this.videoWrapperService.getVideoService().galleryParticipants.find((p) => p.uid == participantId);
    }

    async playVideoTrack(participant, type = 'userFeed', retry = 1) {
        if (retry > 3) {
            this.appLoggerService.log('Retry exceeded, returning null');
            return;
        }
        await this.getVideoStream(participant, type);
        let videoTrack;
        if (type == 'screenshare') {
            videoTrack = participant.screenShareVideoTrack;
        } else {
            videoTrack = participant.videoTrack;
        }
        if (!videoTrack && (type == 'userFeed' ? participant.hasVideo : participant.hasScreenShareVideo)) {
            this.appLoggerService.log('Retrying', participant);
            await this.utilService.holdExecution(500);
            return this.playVideoTrack(participant, type, retry + 1);
        }
        this.appLoggerService.log('Got a video track', videoTrack);
        return videoTrack;
    }

    async playAudioTrack(participant, type = 'userFeed') {
        try {
            await this.getAudioStream(participant, type);
            let audioTrack;
            // if (type == 'screenshare' || type == 'cloudvideo') {
            if (type == 'screenshare') {
                audioTrack = participant.screenShareAudioTrack;
            } else {
                audioTrack = participant.audioTrack;
            }
            // Play the audio
            audioTrack.play();
        } catch (err) {
            this.appLoggerService.log('Failed to play audio in stream retriever', err);
        }
    }

    async getLocalCameraTrackClone() {
        if (!this.videoWrapperService.getVideoService().cameraState) return;
        return new MediaStream([this.videoWrapperService.getVideoService().localCameraTrack.getMediaStreamTrack()]);
    }

    getCloudVideoUid() {
        this.videoService = this.videoWrapperService.getVideoService();
        if (this.rtcService.isJMMeeting) {
            return this.videoService.nonGalleryParticipants.find((p) => this.videoService.isCloudVideoUid(p))?.uid;
        } else {
            return this.videoWrapperService
                .getVideoService()
                .nonGalleryParticipants.find((p) => this.videoWrapperService.getVideoService().isCloudVideoUid(p.uid))
                ?.uid;
        }
    }

    getScreenshareUid() {
        if (this.rtcService.isJMMeeting) {
            return this.videoService.isScreenShareAvaiable ? this.videoService.screenSharingStream?.uid : null;
        } else {
            return this.videoWrapperService
                .getVideoService()
                .nonGalleryParticipants.find((p) => this.videoWrapperService.getVideoService().isScreenShareUid(p.uid))
                ?.uid;
        }
    }

    getLocalParticipantId() {
        return this.videoWrapperService.getVideoService().getLocalParticipant()?.id;
    }

    isCloudVideoUid(uid) {
        if (this.rtcService.isJMMeeting) {
            return uid == this.getCloudVideoUid();
        } else {
            return this.videoService.isCloudVideoUid(uid);
        }
    }

    isScreenShareUid(uid) {
        if (this.rtcService.isJMMeeting) {
            return uid == this.getScreenshareUid();
        } else {
            return this.videoService.isScreenShareUid(uid);
        }
    }

    handleParticipantsListUpdate(participants) {
        const hasUpdate = this.checkParticipantIdUpdate(participants);
        this.setParticipants(participants);
        if (hasUpdate) {
            this.galleryViewUpdates$.next({
                type: 'playFeedElements',
                data: {
                    type: 'playFeedElements'
                }
            });
        }
    }

    checkParticipantIdUpdate(participants) {
        if (!this.rcsParticipants || this.rcsParticipants.length == 0) return false;
        let participantIdUpdated = false;
        this.rcsParticipants.forEach((p) => {
            const newObject = participants.find((p2) => p2.userId === p.userId);
            if (!newObject) {
                this.appLoggerService.log(`Participant left ,`, p);
            } else if (p.participantId != newObject?.participantId) {
                this.appLoggerService.log(
                    `Participant Id updated \n New Id: ${newObject.participantId} \n Old Id: ${p.participantId}`
                );
                this.galleryViewUpdates$.next({
                    type: 'participantIdUpdate',
                    data: {
                        newId: newObject.participantId,
                        oldId: p.participantId,
                        userId: p.userId,
                        type: 'participantIdUpdate'
                    }
                });
                participantIdUpdated = true;
            }
        });
        return participantIdUpdated;
    }

    setParticipants(participants) {
        this.rcsParticipants = participants.map((p) => {
            return {
                participantId: p.participantId,
                userId: p.userId
            };
        });
    }

    getMediaEngineIdByUserId(userId) {
        if (!userId) return;
        return this.rcsParticipants.find((p) => p.userId == userId)?.participantId;
    }

    getUserIdByMediaEngineId(uid) {
        if (!uid) return;
        return this.rcsParticipants.find((p) => p.participantId == uid)?.userId;
    }

    unsubscribeStreamByUid(uid) {
        const remoteParticipant = this.videoService.galleryParticipants.find((p) => p.uid == uid);
        if (!remoteParticipant) return;
        try {
            this.videoService.jmMediaClient.unsubscribe(remoteParticipant, 'cameraVideo');
        } catch (err) {
            this.appLoggerService.log('Error occured while unsubscribing video');
        }
    }

    unsubscribeScreenshare() {
        const remoteParticipant = this.videoService.screenSharingStream;
        if (!remoteParticipant) return;
        try {
            this.videoService.jmMediaClient.unsubscribe(remoteParticipant, 'screenVideo');
        } catch (err) {
            this.appLoggerService.log('Error occured while unsubscribing video');
        }
    }

    unsubscribeCloudvideo() {
        const remoteParticipant = this.videoService.cloudVideoStream;
        if (!remoteParticipant) return;
        try {
            this.videoService.jmMediaClient.unsubscribe(remoteParticipant, 'cameraVideo');
        } catch (err) {
            this.appLoggerService.log('Error occured while unsubscribing video');
        }
    }

    sendChatMessage(data) {
        this.videoWrapperService.getVideoService().sendChatMessage(data);
    }

    getChatMessages() {
        return this.videoWrapperService
            .getVideoService()
            .getChatMessages()
            .subscribe((event) => {
                switch (event?.message) {
                    case 'SPEAKER_TRANSCRIPTION': {
                        this.handleSpeakerTranscription(event?.data);
                    }
                }
            });
    }

    handleSpeakerTranscription(data) {
        if (this.jmTranslateService.enableJmTranslateService) {
            this.jmTranslateService.processIncomingTranscription(data.transcription);
        }
    }
}
