import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-dashboard-data',
    templateUrl: './no-dashboard-data.component.html',
    styleUrls: ['./no-dashboard-data.component.scss']
})
export class NoDashboardDataComponent implements OnInit {
    @Input() imgPath;
    @Input() msg;

    constructor() {}

    ngOnInit(): void {}
}
