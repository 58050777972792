export const WEB_CONSTANT = {
    STATIC_PAGES: [
        '/home',
        '/home/beta',
        '/webinars',
        '/virtual-events',
        '/whiteglove',
        '/solutions',
        '/solutions/beta',
        '/customer',
        '/customer/beta',
        '/pricing',
        '/pricing/beta',
        '/contactus',
        '/contactus/beta',
        '/faqs',
        '/faqs/beta',
        '/support',
        '/comingsoon',
        '/explore-events',
        '/eventview',
        '/explore-events/beta'
    ],
    METATAGS:
        'Virtual Events, Hybrid Events, Online Events, Events, Event Marketing, Event Management, Conferencing, Made In India, Jio, Jio Products, JioEvents, Virtual AGMs, JioMeet, Virtual Meetings, Virtual Conferencing, Webinars, online workshops, live streams, Sponsorship, spotlight, event customization',
    META_DESCRIPTION: 'Host delightful virtual and hybrid events across platforms with JioEvents.'
};

export const DASHBOARD_NAV_LINKS = [
    {
        title: 'Home',
        // tkey: this.translateService.instant('dashboard-sidenav.home'),
        route: 'dashboard',
        active: true,
        icon: 'home.svg',
        icon_active: 'home_active.svg',
        icon_hover: 'home_hover.svg'
    },
    {
        title: 'Explore Events',
        // tkey: this.translateService.instant('dashboard-sidenav.explore'),
        route: 'explore',
        active: false,
        icon: 'explore.svg',
        icon_active: 'explore_active.svg',
        icon_hover: 'explore_hover.svg'
    },
    {
        title: 'My Events',
        //tkey: this.translateService.instant('dashboard-sidenav.my_events'),
        route: 'my-events',
        active: false,
        defaultRoute: 'welcome-page',
        icon: 'events.svg',
        icon_active: 'events_active.svg',
        icon_hover: 'events_hover.svg'
    }
    // {
    //     title: 'Events Studio',
    //     isCustomRoute: true,
    //     //tkey: this.translateService.instant('dashboard-sidenav.my_events'),
    //     route: '/studio/home',
    //     active: false,
    //     defaultRoute: '/studio/home',
    //     icon: 'events.svg',
    //     icon_active: 'events_active.svg',
    //     icon_hover: 'events_hover.svg',
    //     disabled: true
    // }
    // {
    //     title: 'Team',
    //     tkey: this.translateService.instant('dashboard-sidenav.team'),
    //     route: 'team',
    //     active: false,
    //     icon: 'team.svg',
    //     icon_active: 'team_active.svg'
    // },
    // {
    //     title: 'Invitation',
    //     tkey: this.translateService.instant('dashboard-sidenav.invitation'),
    //     route: 'invitation',
    //     active: false,
    //     icon: 'invitation.svg',
    //     icon_active: 'invitation_active.svg'
    // },
    // {
    //     title: 'Live Streaming',
    //     tkey: this.translateService.instant('dashboard-sidenav.live_streaming'),
    //     route: 'live-streaming',
    //     active: false,
    //     icon: 'livestream.svg',
    //     icon_active: 'livestream_active.svg'
    // },
    // {
    //     title: 'My Library',
    //     tkey: this.translateService.instant('dashboard-sidenav.my_library'),
    //     route: 'my-library',
    //     active: false,
    //     icon: 'mylibrary.svg',
    //     icon_active: 'mylibrary_active.svg'
    // }
];
