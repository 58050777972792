<div
    class="flash-publish-container"
    [class.call-options]="mobCallOptions"
    [class.preview-mobile]="isMobile"
    [class.is-landscape]="isLandscape"
    [cdkDragBoundary]="dragBoundarySelector"
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    (cdkDragStarted)="onDragStarted($event)"
>
    <div class="flash-card-preview-conatiner" *ngIf="flashcardService.showCardPreview">
        <div [ngClass]="{ 'flash-con': isMobile }">
            <div
                class="card-preview"
                [class.description]="card?.description || card?.title"
                [class.no-description]="!card?.description && !card?.title"
            >
                <div
                    class="flash-img"
                    [ngStyle]="{ 'background-image': 'url(' + card?.imageUrl + ')' }"
                    *ngIf="card?.imageUrl"
                    [class.is-landscape]="card?.imageMode === 'landscape'"
                    (click)="showFlashCardImg()"
                ></div>
                <div
                    [ngClass]="{ 'flash-description': card?.description || card?.title }"
                    [class.no-image]="!card.imageUrl"
                >
                    <span class="flash-title" *ngIf="card?.title">{{ card.title }}</span>
                    <span class="flash-text" *ngIf="card?.description">
                        <span [innerHTML]="sanitizeText(card?.description) | sanitizeHtml"></span>
                    </span>
                </div>
            </div>
            <div *ngIf="flashcardService.showCardPreview && isMobile" class="close-icon" (click)="toggleCardPreview()">
                <img src="assets/images/flash-close.svg" />
            </div>
        </div>
    </div>
    <div *ngIf="flashcardService.showCardPreview && !isMobile" class="close-icon pointer" (click)="toggleCardPreview()">
        <img src="assets/images/flash-close.svg" />
    </div>
    <div class="circle-card" *ngIf="!flashcardService.showCardPreview" (click)="toggleCardPreview()">
        <img src="assets/images/flash-icon.svg" />
    </div>
</div>
