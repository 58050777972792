<div
    class="call-preview-wrap"
    [class.hidden]="!showControls"
    [class.new-call-preview]="isNetworkingRoom"
    *ngIf="!isprerecordedSession"
>
    <div class="call-preview">
        <div class="video-wrap">
            <div class="camera-icn" *ngIf="!cameraPrivacy">
                <img src="assets/images/camera-off.svg" alt="camera off icon" /><br />
                {{
                    (cameraStarting
                        ? 'joinmeeting_page_or_joincall.camera_starting'
                        : !cameraPrivacy
                        ? 'joinmeeting_page_or_joincall.camera_off'
                        : ''
                    ) | translate
                }}
            </div>
            <div
                class="video"
                id="preview-renderer"
                [class.transformY]="(rtcService.isJMMeeting || rtcService.isAgoraMeeting) && !disableVirtualBackground"
            ></div>
        </div>
        <div class="control-strip">
            <div
                class="icon-wrap settings"
                title="{{ 'joinmeeting_page_or_joincall.settings' | translate }}"
                [class.toggled]="showSettingsPopup"
                (click)="toggleSettingsPopup($event, 'audio')"
            >
                <img src="assets/images/joining-preview/settings_icon.svg" />
            </div>
            <div
                class="icon-wrap background"
                (click)="toggleSettingsPopup($event, 'background')"
                *ngIf="
                    (!currentUser._id || rtcService.isJMMeeting || rtcService.isAgoraMeeting) && enableVirtualBackground
                "
                [class.disabled]="disableVirtualBackground"
                title="{{
                    (disableVirtualBackground
                        ? 'joinmeeting_page_or_joincall.disableBackground'
                        : 'joinmeeting_page_or_joincall.background'
                    ) | translate
                }}"
            >
                <img src="assets/images/joining-preview/vb_icon.svg" />
            </div>
            <div
                class="icon-wrap video"
                title="{{ 'joinmeeting_page_or_joincall.togglevideo' | translate }}"
                [class.toggled]="cameraPrivacy"
                (click)="toggleVideo()"
            >
                <img
                    [src]="
                        cameraPrivacy
                            ? 'assets/images/speaker-call-wrapper/participant_camera_state_on_green.svg'
                            : 'assets/images/joining-preview/preview_camera_off.svg'
                    "
                />
            </div>
            <div
                class="icon-wrap audio"
                title="{{ 'joinmeeting_page_or_joincall.toggleaudio' | translate }}"
                [class.toggled]="videoService?.micState"
                (click)="toggleAudio()"
            >
                <img
                    [src]="
                        micPrivacy
                            ? 'assets/images/speaker-call-wrapper/participant_mic_state_on_green.svg'
                            : 'assets/images/joining-preview/preview_mic_off.svg'
                    "
                />
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="!showControls || isprerecordedSession"></app-loader>
