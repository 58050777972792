import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare const $: any;
declare var window: any;
const reactionUpdateSubject = new BehaviorSubject({});
@Injectable({
    providedIn: 'root'
})
export class FloatingReactionService {
    reactionsUpdate = reactionUpdateSubject;
    reactionDetails: any = {};
    attendeeReactions: any = {};
    speakerHeightDetails = {
        primaryHeight: 50,
        secondaryHeight: 30
    };
    attendesideheight: any = {
        wihoutFullscreen: 50,
        fullscreenmode: 65,
        secondaryHeight: 50
    };
    showReactions: boolean = false;
    reactionSide: any = false;
    private characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    constructor() {}
    setReactionsDetails(reactions) {
        let reactionObj = {};
        if (Object.keys(this.reactionDetails).length) {
            let tempKeys = {};
            reactions.forEach((item) => {
                let key = item._id;
                tempKeys[key] = 0;
                if (
                    this.reactionDetails.hasOwnProperty(key) &&
                    this.reactionDetails[key] != item.count &&
                    this.reactionDetails[key] < item.count
                ) {
                    reactionObj[key] = item.count - this.reactionDetails[key];
                }
                if (this.reactionDetails.hasOwnProperty(key) && this.reactionDetails[key] > item.count) {
                    this.reactionDetails[key] = item.count;
                }
                if (!this.reactionDetails.hasOwnProperty(key)) {
                    reactionObj[key] = item.count;
                }
                this.reactionDetails[key] = item.count;
            });
            let reactionsType = Object.keys(this.reactionDetails);
            reactionsType.forEach((type) => {
                if (!tempKeys.hasOwnProperty(type)) {
                    delete this.reactionDetails[type];
                }
            });
        } else {
            reactions.forEach((item) => {
                let key = item._id;
                if (item.count > 0) reactionObj[key] = item.count;
            });
            this.reactionDetails = Object.assign({}, reactionObj);
        }
        if (this.showReactions && !window.inactiveTab) {
            let sum = 0;
            for (let emoKey in reactionObj) {
                if (sum < 500) {
                    sum += reactionObj[emoKey];
                } else {
                    delete reactionObj[emoKey];
                }
            }
            this.reactionsUpdate.next(reactionObj);
        } else {
            if (!document.hidden) {
                window.inactiveTab = false;
            }
            this.showReactions = true;
        }
    }

    addUserReactions(type) {
        if (this.attendeeReactions.hasOwnProperty(type)) {
            this.attendeeReactions[type] += 1;
        } else {
            this.attendeeReactions[type] = 1;
        }
    }

    animateReaction(particle, idx, fullscreenMode, userEnd) {
        let prevanimaPlace = 0;
        let startScreenPercentage = 0.1;
        let endScreenPercentage = 0.8;
        let delay = 50;
        var getRandomPlace = function (min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        let bounds = getRandomPlace(
            $('.reactions-area').width() * startScreenPercentage,
            $('.reactions-area').width() * endScreenPercentage
        );
        console.log(`>>>>>animate reaction....${bounds}`);
        if (Math.abs(prevanimaPlace - bounds) < 100) {
            bounds =
                $('.reactions-area').width() < bounds + Math.abs(prevanimaPlace - bounds)
                    ? $('.reactions-area').width() - 50
                    : bounds + Math.abs(prevanimaPlace - bounds);
        }
        let randomHeight = getRandomPlace(1, 10);
        console.log(`>>>bounds......${bounds}`);
        bounds = this.reactionSide ? bounds : -bounds;
        this.reactionSide = !this.reactionSide;
        $(particle).animate({ left: bounds }, delay + randomHeight, () => {
            let top;
            let secondaryHeight;
            if (userEnd == 'attendee') {
                top = fullscreenMode
                    ? this.attendesideheight.fullscreenmode + randomHeight + '%'
                    : this.attendesideheight.wihoutFullscreen + randomHeight + '%';
                secondaryHeight = this.attendesideheight.secondaryHeight + '%';
            } else {
                top = `${this.speakerHeightDetails.primaryHeight}%`;
                secondaryHeight = `${this.speakerHeightDetails.secondaryHeight}%`;
            }
            $(particle).animate({ top: top, opacity: 1 }, getRandomPlace(1500, 2500), function () {
                $(particle).animate({ top: secondaryHeight, opacity: 0 }, getRandomPlace(1500, 2500), () => {
                    $(particle).remove();
                });
            });
        });
    }

    controlBarReactionAnimation(type, height) {
        $('.user-reactions-area').css('width', '65%');
        $('.inside-area').css('width', '100%');
        this.addUserReactions(type);
        var getRandomPlace = function (min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        let unqkey = this.uniqstring(5);
        $('.inside-area').append(
            `<img class="userparticle ${type} ${unqkey}" src="assets/images/attendee_emojis/animate/${type}.webp" />`
        );
        $(`.${unqkey}`)
            .toArray()
            .forEach((particle) => {
                let topheight = `${height}%`;
                $(particle).animate({ top: topheight, opacity: 1, scale: 2 }, getRandomPlace(1500, 2500), () => {
                    $(particle).remove();
                    $('.user-reactions-area').css('width', '0px');
                    $('.inside-area').css('width', '0px');
                });
            });
    }

    uniqstring(length) {
        var result = '';
        var charactersLength = this.characters.length;
        for (var i = 0; i < length; i++) {
            result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}
