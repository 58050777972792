import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2,
    Optional,
    OnDestroy
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appInputMaxLength]'
})
export class InputMaxLengthDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input() appInputMaxLength: number;
    @Input() customClass: string;
    private div: HTMLDivElement;
    private destroyed$ = new Subject();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Optional() private ngModel: NgModel,
        private ngControl: NgControl
    ) {}

    @HostListener('input', ['$event']) onChange(event) {
        if (!this.ngModel) {
            this.update(event.target.value.length);
        }
    }

    @HostListener('focus', ['$event']) onFocus(event) {
        if (!this.ngModel) {
            this.update(event.target.value.length);
        }
    }

    @HostListener('focusout', ['$event.target.value'])
    onBlur(value) {
        this.renderer.setProperty(this.div, 'innerText', '');
    }

    ngOnInit() {
        this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this.appInputMaxLength.toString());
    }

    ngAfterViewInit() {
        if (this.ngModel) {
            this.ngModel.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
                this.update(value.length);
            });
        }
        this.div = this.renderer.createElement('span');
        if (this.customClass) {
            this.div.classList.add(this.customClass);
        } else this.div.classList.add('count');
        this.renderer.insertBefore(this.el.nativeElement.parentNode, this.div, this.el.nativeElement.nextSibling);
        // this.update(this.el.nativeElement.value.length);
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        if (this.div) {
            this.div.remove();
        }
    }

    private update(length: number) {
        this.renderer.setProperty(this.div, 'innerText', length ? `${length}/${this.appInputMaxLength}` : '');
    }
}
