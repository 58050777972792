import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { LeaderboardService } from 'src/app/core/services/leaderboard.service';

@Component({
    selector: 'app-leaderboard-share-achievement',
    templateUrl: './leaderboard-share-achievement.component.html',
    styleUrls: ['./leaderboard-share-achievement.component.scss']
})
export class LeaderboardShareAchievementComponent implements OnInit {
    prizeAsset: any;
    isMobileOS: boolean = false;
    constructor(
        private leaderboardService: LeaderboardService,
        private callViewStateManagerService: CallViewStateManagerService,
        private utilService: UtilService
    ) {}

    ngOnInit(): void {
        this.isMobileOS = this.utilService.isMobileBrowser();
        // this.prizeAsset = this.leaderboardService.leaderboardObj.prizes.find((p) => p.prizeIndex === this.leaderboardService.localUserRank)?.customPrizeUrl;
        this.prizeAsset =
            this.leaderboardService.leaderboardObj.prizes[this.leaderboardService.achievedPrizeIndex].customPrizeUrl;
    }

    close() {
        this.callViewStateManagerService.showLeaderboardAchievement = false;
    }

    onAcheivementActionClick(action) {
        const encodedUrl = encodeURIComponent(this.prizeAsset);
        switch (action) {
            case 'download': {
                // this.utilService.downloadFile(this.prizeAsset, 'Leaderboard Winner Certificate.jpg');
                window.open(this.prizeAsset, '_blank');
                break;
            }
            case 'facebook': {
                const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
                window.open(facebookShareUrl, '_blank');
                break;
            }
            case 'twitter': {
                const twitterShareUrl = `https://twitter.com/intent/tweet?text=Check%20out%20this%20prize!&url=${encodedUrl}`;
                window.open(twitterShareUrl, '_blank');
                break;
            }
            case 'linkedin': {
                const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
                window.open(linkedinShareUrl, '_blank');
                break;
            }
        }
    }
}
