<div [id]="id || 'meeting-invitation'">
    <p>{{ host?.name }}{{ ' ' + host?.lname }} {{ 'meeting_invitation.hasinvited' | translate }}</p>

    <div id="topic">
        {{ 'meeting_invitation.topic' | translate }} <span>{{ meeting?.topic }}</span>
    </div>

    <div *ngIf="meeting.startTime && duration">
        {{ 'meeting_invitation.starttime' | translate }} {{ meeting.startTime | date: 'hh:mm a, d MMM y' }}
    </div>
    <p *ngIf="meeting.startTime && !duration">
        {{ 'meeting_invitation.starttime' | translate }} {{ meeting.startTime | date: 'hh:mm a, d MMM y' }}
    </p>
    <p *ngIf="duration">{{ 'meeting_invitation.duration' | translate }} {{ duration }} mins</p>

    <p *ngIf="!meeting.startTime || !meeting.endTime || !duration"></p>

    <div>{{ 'meeting_invitation.jointhe' | translate }}</div>
    <p>
        <a class="pointer" target="_blank" href="{{ meeting?.url || meeting?.meetingUrl }}"
            >{{ meeting?.url || meeting?.meetingUrl }}
        </a>
    </p>
    <div>{{ 'meeting_invitation.meetingid' | translate }} {{ meeting?.jiomeetId | meetingidFormat }}</div>
    <p *ngIf="meeting?.pin">{{ 'meeting_invitation.password' | translate }} {{ meeting?.pin }}</p>

    <div *ngIf="meeting?.addToJmtUrl">
        <div>{{ 'meeting_invitation.usethe' | translate }}</div>
        <p>
            <a class="pointer" target="_blank" href="{{ meeting?.addToJmtUrl }}">{{ meeting?.addToJmtUrl }}</a>
        </p>
    </div>
    <p *ngIf="!meeting?.addToJmtUrl"></p>

    <div>
        <strong>{{ 'meeting_invitation.howto' | translate }}</strong>
    </div>
    <div>
        {{ 'meeting_invitation.option1' | translate }} &nbsp;&nbsp;{{ 'meeting_invitation.download' | translate }}
    </div>
    <div>
        {{ 'meeting_invitation.option2' | translate }} &nbsp;&nbsp;<a
            class="pointer"
            target="_blank"
            href="{{ meeting?.url || meeting?.meetingUrl }}"
            >{{ 'meeting_invitation.clickhere' | translate }}</a
        >
        {{ 'meeting_invitation.tojoinchrome' | translate }}
    </div>
    <div *ngIf="!meeting?.legacyConnect?.H323">
        {{ 'meeting_invitation.option3' | translate }} &nbsp;&nbsp;{{
            'meeting_invitation.tojoinsiporh323' | translate
        }}
    </div>
    <div *ngIf="meeting?.legacyConnect?.H323">
        <div>
            {{ 'meeting_invitation.option3' | translate }} &nbsp;&nbsp;{{
                'meeting_invitation.tojointhrough' | translate
            }}<strong>{{ 'meeting_invitation.h323' | translate }}</strong> –
        </div>
        <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Using
            {{ 'meeting_invitation.intranet' | translate }}{{ meeting?.legacyConnect?.H323?.private }} or
        </div>
        <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Using
            {{ 'meeting_invitation.internet' | translate }} {{ meeting?.legacyConnect?.H323?.public }}
        </div>
        <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                'meeting_invitation.extension' | translate
            }}
            {{ meeting?.legacyConnect?.H323?.extension }}{{ 'meeting_invitation.followedby' | translate }}
        </div>
        <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                'meeting_invitation.enterroompin' | translate
            }}
            {{ meeting?.legacyConnect?.H323?.pin }} {{ 'meeting_invitation.conference' | translate }}
        </div>
    </div>
    <div *ngIf="meeting?.legacyConnect?.SIP">
        <div>
            {{ 'meeting_invitation.option4' | translate }} &nbsp;&nbsp;{{
                'meeting_invitation.tojointhrough' | translate
            }}<strong>{{ 'meeting_invitation.sip' | translate }}</strong>
            {{ 'meeting_invitation.enterurl' | translate }} {{ meeting?.legacyConnect?.SIP?.url }}
            {{ 'meeting_invitation.andenterroompin' | translate }} {{ meeting?.legacyConnect?.SIP?.pin }}
            {{ 'meeting_invitation.conference' | translate }}
        </div>
    </div>
    <br />
    <div>{{ 'meeting_invitation.regards' | translate }}</div>
    <p>{{ 'meeting_invitation.jiomeetteam' | translate }}</p>
</div>
