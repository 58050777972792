import { Injectable } from '@angular/core';

import * as shajs from 'sha.js';

import { UserService } from './user.service';
import { LoggerService } from './logger.service';
import { AppService } from './app.service';
import { SystemService } from './system.service';

declare const gtag: any;

@Injectable({
    providedIn: 'root'
})
export class GaDiagnosticService {
    currentUser;
    clientId;

    constructor(
        private userService: UserService,
        private logger: LoggerService,
        private appService: AppService,
        private systemService: SystemService
    ) {
        this.clientId = this.getCookie('_ga').split('.').pop();
    }

    sendPageView({ page_title = '', page_path = '' }) {
        try {
            if (!this.appService.getConfigVariable('ENABLE_GA')) {
                return;
            }
            this.currentUser = this.userService.getUserSync();
            gtag('config', 'UA-166634384-2', {
                custom_map: {
                    dimension1: 'client_id',
                    dimension2: 'user_id',
                    dimension3: 'meeting_id',
                    dimension4: 'device_id'
                },
                page_title,
                page_path,
                user_id: this.currentUser._id ? this.encrypt(this.currentUser._id) : 'Guest',
                client_id: this.clientId,
                device_id: this.encrypt(this.systemService.getDeviceUUID())
            });
        } catch (e) {}
    }

    sendEvent({ action = '', category = '', label = '' }) {
        try {
            if (!this.appService.getConfigVariable('ENABLE_GA')) {
                return;
            }

            this.currentUser = this.userService.getUserSync();
            gtag('event', action, {
                event_category: category,
                event_label: label,
                user_id: this.currentUser._id ? this.encrypt(this.currentUser._id) : 'Guest',
                client_id: this.clientId,
                meeting_id: this.appService.getCurrentCallJiomeetID()
                    ? this.encrypt(this.appService.getCurrentCallJiomeetID())
                    : '',
                device_id: this.encrypt(this.systemService.getDeviceUUID())
            });
        } catch (e) {
            this.logger.error(e);
        }
    }

    private getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    private encrypt(value) {
        return shajs('sha256').update(value).digest('hex');
    }
}
