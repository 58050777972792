import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { LocalStorageService } from './local-storage.service';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class BillingService {
    constructor(
        private restService: RestService,
        private localStorageService: LocalStorageService,
        private appService: AppService
    ) {}

    createOrder(payload) {
        const user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/jiochargeit/createorder`, payload, {
            headers
        });
    }

    getPricingPlansList() {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/jiochargeit/getplans?productName=jioevents&offset=0&limit=10`
        );
    }

    downloadInvoice() {
        const user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.get('api/my_profile/invoice/download?productName=jioevents', { headers });
    }

    downloadInvoiceById(id) {
        const endPoint = `api/my_profile/invoice/download?productName=jioevents&subscriptionId=${id}`;
        const user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.get(endPoint, { headers });
    }

    getGstinDetails(gstin: string) {
        const endPoint = `api/jiochargeit/gstin?productName=jioevents&gstinno=${gstin}`;
        const user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.get(endPoint, { headers });
    }

    getOtp(payload) {
        const endPoint = `api/jiochargeit/getotp`;
        const user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.post(endPoint, payload, { headers });
    }

    verifyOtp(payload) {
        const endPoint = `api/jiochargeit/verifyotp`;
        const user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.post(endPoint, payload, { headers });
    }
}
