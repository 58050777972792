import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { RestService } from './rest.service';
import { HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ParticipantsControlsService {
    private headers: HttpHeaders = new HttpHeaders();
    private sortKey;
    private micVisibilitySubject = new BehaviorSubject<boolean>(false);

    constructor(
        private appService: AppService,
        private restService: RestService,
        private appLoggerService: AppLoggerService
    ) {
        this.headers = this.headers.set('isPortal', 'true');
    }

    setSortKeys(key) {
        this.sortKey = key;
    }

    setMicVisibility(visible: boolean) {
        this.micVisibilitySubject.next(visible);
    }

    getMicVisibility() {
        return this.micVisibilitySubject.asObservable();
    }

    getVCParticipants(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/getparticipants?vc=true`,
            { jiomeetId },
            { headers: this.headers }
        );
    }

    getMeetingParticipantsFromPortal(jiomeetId, { keyword = '', start = 0, limit = 10 }) {
        return this.restService.post(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/portal/getparticipants?limit=${limit}&start=${start}&keyword=${keyword}`,
            { jiomeetId },
            { headers: this.headers }
        );
    }

    getMeetingParticipantsForEventControls(jiomeetId, { keyword = '', start = 0, limit = 10 }) {
        return this.restService.post(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/eventcontrols/getparticipants?limit=${limit}&start=${start}&keyword=${keyword}`,
            { jiomeetId },
            { headers: this.headers }
        );
    }

    handleVideoSoftMute(jiomeetId, roomID, isSoftVideoMute) {
        if (isSoftVideoMute) {
            this.softUnmuteVideoForAll(jiomeetId, roomID).subscribe(
                () => {},
                (err) => {
                    this.appLoggerService.error('Error in Soft Video Mute', err);
                }
            );
        }
    }

    handleAudioSoftMute(jiomeetId, roomID, isSoftMute) {
        if (isSoftMute) {
            this.softUnmuteAudioForAll(jiomeetId, roomID).subscribe(
                () => {},
                (err) => {
                    this.appLoggerService.error('Error in Soft Audio Mute', err);
                }
            );
        }
    }

    getMeetingParticipants(jiomeetId, roomID, roomPin) {
        const payload = {
            jiomeetId,
            roomID,
            roomPin,
            ...(this.sortKey && { sortkey: this.sortKey })
        };
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/get`, payload, {
                headers: this.headers
            })
            .pipe(
                tap(
                    (res: any) => {
                        if (!res || res?.success === false) {
                            throw res;
                        }
                        this.handleVideoSoftMute(jiomeetId, roomID, res.isSoftVideoMute);
                        this.handleAudioSoftMute(jiomeetId, roomID, res.isSoftMute);
                    },
                    (err) => {
                        this.appLoggerService.error('Something Went Wrong', err);
                    }
                )
            );
    }

    softMuteAudioForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/portal/muteaudio`, {
                jiomeetId,
                participantID: participantId
            });
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audio`,
            {
                jiomeetId,
                roomID,
                participantId,
                audio: false
            }
        );
    }

    hardMuteAudioForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/portal/muteaudio`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audio`,
            {
                jiomeetId,
                roomID,
                participantId,
                audio: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteAudioForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/portal/unmuteaudio`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audio`,
            { jiomeetId, roomID, participantId, audio: true },
            { headers: this.headers }
        );
    }
    changeParticipantType({ jiomeetId, roomID, roomPin, participantIds = [], participantType }) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/eventcontrols/participantType`, {
            jiomeetId,
            roomID,
            roomPin,
            participantIds,
            participantType
        });
    }

    softMuteVideoForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/portal/mutevideo`, {
                jiomeetId,
                participantID: participantId
            });
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/video`,
            {
                jiomeetId,
                roomID,
                participantId,
                video: false
            }
        );
    }

    hardMuteVideoForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/portal/mutevideo`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/video`,
            {
                jiomeetId,
                roomID,
                participantId,
                video: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteVideoForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `${this.appService.getEnvVariable('BASE_URL')}/portal/unmutevideo`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/video`,
            { jiomeetId, roomID, participantId, video: true },
            { headers: this.headers }
        );
    }

    hardMuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audioall`,
            {
                jiomeetId,
                roomID,
                hard: true,
                audio: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audioall`,
            { jiomeetId, roomID, hard: true, audio: true },
            { headers: this.headers }
        );
    }

    hardMuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/videoall`,
            {
                jiomeetId,
                roomID,
                hard: true,
                video: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/videoall`,
            { jiomeetId, roomID, hard: true, video: true },
            { headers: this.headers }
        );
    }

    softMuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/videoall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                video: false
            },
            { headers: this.headers }
        );
    }

    softUnmuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/videoall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                video: true
            },
            { headers: this.headers }
        );
    }

    softMuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audioall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                audio: false
            },
            { headers: this.headers }
        );
    }

    softUnmuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audioall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                audio: true
            },
            { headers: this.headers }
        );
    }

    lockRoom(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/lockroom`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    unlockRoom(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/unlockroom`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    getLockStatus(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/roomstatus`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    getLockStatusForEventControls(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/eventcontrols/roomstatus`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    removeParticipant(jiomeetId, participantID) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/removeparticipant`,
            {
                jiomeetId,
                participantID
            },
            { headers: this.headers }
        );
    }

    removeAllParticipants(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/endall`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    startEvent(jiomeetId, meetingId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/eventcontrols/startEvent`,
            {
                jiomeetId,
                meetingId
            },
            { headers: this.headers }
        );
    }

    startRecording(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/startrec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    stopRecording(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/stoprec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    pauseRecording(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/pauserec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    resumeRecording(jiomeetId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/portal/resumerec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    lowerParticipantHands(jiomeetId, roomID, roomPin, handsDownParticipantIds) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomConnectionStatus/hosts`,
            {
                jiomeetId,
                roomID,
                roomPin,
                handsDownParticipantIds
            },
            { headers: this.headers }
        );
    }

    moveParticipantsToWaitingRoom(jiomeetId, participants) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/moveToWaitingRoom`, {
            participants,
            jiomeetId
        });
    }
}
