import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { AuthService } from './../services/auth.service';
import { Observable } from 'rxjs';
import { UserService, AppService, SystemService } from '../services';
import { JiocloudAuthService } from '../services/jiocloud-auth.service';
import { GuestAuthService } from '../services/guest-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
import { AppLoggerService } from '../services/app-logger.service';
import { JmTranslateService } from '../services/jm-translate.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    private deviceId;
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private jiocloudAuthService: JiocloudAuthService,
        private guestAuthService: GuestAuthService,
        private systemService: SystemService,
        private translate: TranslateService,
        private webinarAttendeeService: WebinarAttendeeService,
        private appLoggerService: AppLoggerService,
        private appService: AppService,
        private jmTranslateService: JmTranslateService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.jmTranslateService.isTranslateUrl(request.url)) {
            return this.handleTranslateUrl(request, next);
        }

        if (this.appLoggerService.isCloudLoggingUrl(request.url)) {
            return next.handle(request);
        }

        if (this.jiocloudAuthService.isJioCloudRequest(request) && !request.url.includes('addons')) {
            return this.handleJioCloudRequest(request, next);
        }

        if (this.isJiomeetApiRequest(request)) {
            return this.handleJiomeetApiRequest(request, next);
        }

        return next.handle(request);
    }

    private handleTranslateUrl(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: this.jmTranslateService.getToken()
            }
        });
        return next.handle(request);
    }

    private handleJioCloudRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.jiocloudAuthService.getAuthInfo() && !this.isExcludedJioCloudPath(request.url)) {
            request = request.clone({
                headers: this.jiocloudAuthService.getCommonHeaders(request.headers, request.headers.get('X-Meeting-Id'))
            });
        }
        return next.handle(request);
    }

    private isExcludedJioCloudPath(url: string): boolean {
        const excludedPaths = ['/security/users/logout', '/account/external/login', '/account/token/refresh'];
        return excludedPaths.some((path) => url.includes(path));
    }

    private isJiomeetApiRequest(request: HttpRequest<any>): boolean {
        return (
            request.url.includes('/api') &&
            (!request.url.includes('addons') || this.appService.getConfigVariable('useAuthTokenForAddonsApi'))
        );
    }

    private handleJiomeetApiRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authInfo = this.authService.getAuthInfo();
        if (authInfo) {
            request = this.addAuthHeaders(request, authInfo.jwt);
            if (this.userService.getUserSync().email || this.userService.getUserSync().phoneNo) {
                request = this.addIdentityHeader(request);
            }
            if (this.isPublicStreamUrl(request.url)) {
                request = this.addNullAuthorizationHeader(request);
            }
        } else if (this.guestAuthService.getAuthInfo() && this.guestAuthService.isGuestRequest(request)) {
            request = this.addGuestAuthHeaders(request);
            return next.handle(request);
        }

        request = this.addDefaultHeaders(request);

        const rilAuthInfo = this.authService.getRILAuthInfo();
        if (rilAuthInfo && rilAuthInfo.jwt && this.isRilAuthPath(request.url)) {
            request = this.addRilAuthHeader(request, rilAuthInfo.jwt);
        }

        if (this.shouldAddWebinarAttendeeToken(request)) {
            request = this.addWebinarAttendeeTokenHeader(request);
            return next.handle(request);
        }

        return next.handle(request);
    }

    private addAuthHeaders(request: HttpRequest<any>, jwt: string): HttpRequest<any> {
        this.deviceId = request.url.includes('streamurl')
            ? `attendee${this.systemService.getDeviceUUID()}`
            : this.systemService.getDeviceUUID();

        return request.clone({
            setHeaders: {
                Authorization: jwt,
                deviceid: this.deviceId,
                'ngsw-bypass': 'true',
                'accept-language': this.translate.currentLang || 'en'
            }
        });
    }

    private addIdentityHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'X-Identity': this.userService.getUserSync().email || this.userService.getUserSync().phoneNo
            }
        });
    }

    private isPublicStreamUrl(url: string): boolean {
        return url.includes('getstreamurl') && url.includes('public=true');
    }

    private addNullAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: 'null'
            }
        });
    }

    private addGuestAuthHeaders(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: this.guestAuthService.getAuthInfo().jwt,
                deviceid: this.systemService.getDeviceUUID(),
                'accept-language': this.translate.currentLang || 'en'
            }
        });
    }

    private addDefaultHeaders(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                deviceid: this.deviceId || this.systemService.getDeviceUUID(),
                'accept-language': this.translate.currentLang || 'en'
            }
        });
    }

    private isRilAuthPath(url: string): boolean {
        const rilAuthPaths = ['/api/verifyotp', '/api/authorize/google', '/api/authorize/facebook', '/api/sign/signup'];
        return rilAuthPaths.some((path) => url.includes(path));
    }

    private addRilAuthHeader(request: HttpRequest<any>, rilJwt: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: rilJwt
            }
        });
    }

    private shouldAddWebinarAttendeeToken(request: HttpRequest<any>): boolean {
        return (
            (this.webinarAttendeeService.isInviteOnlyEvent ||
                !this.authService.getAuthInfo() ||
                this.webinarAttendeeService.getIsIFrameAutoJoinAttendee()) &&
            this.webinarAttendeeService.getGuestAttendeeToken()
        );
    }

    private addWebinarAttendeeTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: this.webinarAttendeeService.getGuestAttendeeToken().jwt,
                deviceid: this.systemService.getDeviceUUID(),
                'accept-language': this.translate.currentLang || 'en'
            }
        });
    }
}
