import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { WEBINARS } from '../../../constants/gtm-events';
import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';

@Component({
    selector: 'app-webinars',
    templateUrl: './webinars.component.html',
    styleUrls: ['./webinars.component.scss']
})
export class WebinarsComponent implements OnInit {
    loginPage: any = '/login';
    contactUsPage: any = '/contactus';
    constructor(private appService: AppService, private googleTagManager: GoogleTagMangerService) {
        this.loginPage = this.appService.getLoginPageUrl();
    }

    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.WEBINARS_PAGE_VIEW);
    }
    redirectToLogin() {
        window.location.href = this.loginPage;
    }
    redirectToContactUs() {
        window.location.href = this.contactUsPage;
    }
    getStarted() {
        this.googleTagManager.pushGoogleTagData(WEBINARS.GET_STARTED);
        this.redirectToContactUs();
    }
    exploreNow() {
        this.googleTagManager.pushGoogleTagData(WEBINARS.EXPLORE_NOW);
        this.redirectToLogin();
    }
}
