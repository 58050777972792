import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class NavigationGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (route.queryParamMap.get('navigateTo') === 'webprec') {
            var meetingId = route.queryParamMap.get('meetingId');
            var hash = route.queryParamMap.get('hash');
            if (meetingId && hash) {
                this.router.navigate([`/attendee/${meetingId}/join/${hash}`], { queryParams: route.queryParams });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
}
