import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SocketEvent, SocketService } from 'src/app/core';
import { FlashcardService } from 'src/app/core/services/flashcard.service';
import { UtilService } from 'src/app/core/services/util.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { RichTextEditorComponent } from 'src/app/shared/components/text-editor/text-editor.component';

@Component({
    selector: 'app-flash-cards-popup',
    templateUrl: './flash-cards-popup.component.html',
    styleUrls: ['./flash-cards-popup.component.scss']
})
export class FlashCardsPopupComponent implements OnInit {
    @ViewChild(RichTextEditorComponent) private richTextEditorComponent: RichTextEditorComponent;
    @Input() isDashboard;
    @Input() webinarDetails;
    @Input() cardDetails;
    @Input() parentId;
    @Output() close = new EventEmitter();
    @Output() cardSaved = new EventEmitter();
    flashCardForm: FormGroup;
    showCreateFlashCard = false;
    cardDescription: any;
    showImageCropper;
    file: File;
    errors;
    blobURLref;
    fileUploadSuccess: boolean = false;
    imageUrl: string | ArrayBuffer | null = null;
    selectedFile: File | null = null;
    flashCardsArrayIn: any;
    imageRatio: any;
    isImageSet: boolean;
    isUpdateDisabled: boolean = true;
    isFlashCardEnabled;
    FILE_UPLOAD_ERRORS = {
        MIN_ERROR: 'Please upload a gif /.jpg/ .png file with minimum 20KB.',
        MAX_ERROR: 'Please upload a gif /.jpg/ .png file less than 2MB.',
        INCORRECT_FILE: 'Please upload a gif /.jpg/ .png file.'
    };
    showProgressBar: boolean;
    progress: number = 0;
    isFormEmpty: boolean;
    flashcardCount: any;
    maxFlashCardCount;

    constructor(
        private fb: FormBuilder,
        private flashcardService: FlashcardService,
        private toastrService: ToastrService,
        private utilService: UtilService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private zone: NgZone,
        private socketService: SocketService,
        private confirmationService: ConfirmationService,
        private webinarService: WebinarService
    ) {
        this.maxFlashCardCount = this.flashcardService.maxFlashCardsAllowed();
    }

    ngOnInit() {
        this.setFlashCardForm(this.cardDetails);
        this.socketService.dataEvents$.subscribe(this.handleSocketEvents.bind(this));
        this.flashcardService.getFlashCards(this.webinarDetails._id || this.parentId).subscribe(
            (res) => {
                this.flashCardsArrayIn = res.flashcards;
                this.flashcardCount = res.count;
                this.isFlashCardEnabled = res.enabled;
                this.flashCardsArrayIn.sort((a, b) => b.publish - a.publish);
                if (!this.flashCardsArrayIn.length) {
                    if (this.isFlashCardEnabled) {
                        this.createFlashCard();
                    } else {
                        this.showCreateFlashCard = false;
                    }
                }
            },
            (error) => {
                console.error('Error fetching flashcards:', error);
            }
        );
    }

    handleSocketEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case 'flashcard':
                    if (!event.data?.enabled) {
                        this.getFlashCards();
                    } else {
                        this.setFlashCardData(event.data);
                    }
                    break;
            }
        });
    }

    setFlashCardForm(cardDetails) {
        this.cardDescription = !cardDetails?.description
            ? ''
            : this.utilService.sanitizeAndConvertEncodedHTML(cardDetails?.description);
        this.flashCardForm = this.fb.group({
            title: cardDetails?.title || '',
            description: cardDetails?.description || '',
            imageUrl: cardDetails?.imageUrl || ''
        });
        this.listenToFormValueChanges();
    }

    listenToFormValueChanges() {
        this.flashCardForm.valueChanges.subscribe((v) => {
            this.isUpdateDisabled = !this.flashCardForm.valid;
            this.isFormEmpty = this.checkEmptyForm();
        });
    }

    checkEmptyForm() {
        return (
            !this.flashCardForm.value.title &&
            !this.flashCardForm.value.description &&
            !this.flashCardForm.value.imageUrl
        );
    }

    createFlashCard() {
        this.showCreateFlashCard = true;
        this.cardDetails = null;
        this.setFlashCardForm(null);
        this.discardFile();
    }

    closePopup() {
        this.webinarService.setEventControlsPopupFlag(false);
        this.close.emit();
    }

    onFileSelected(event: any) {
        this.handleDropFileEvent(event.target.files);
    }

    handleDropFileEvent(event) {
        const file = event[0];
        var isFileValid = this.validateFile(file);
        if (isFileValid) {
            this.selectedFile = file;
            const reader = new FileReader();
            reader.onload = (e) => {
                this.imageUrl = e.target?.result;
            };
            reader.readAsDataURL(file);
            this.showImageCropper = true;
        }
    }

    validateFile(file) {
        if (
            !(
                file.type === 'image/jpeg' ||
                file.type === 'image/jpg' ||
                file.type === 'image/png' ||
                file.type === 'image/gif'
            )
        ) {
            this.errors = this.FILE_UPLOAD_ERRORS.INCORRECT_FILE;
            this.toastrService.error(this.translateService.instant(this.FILE_UPLOAD_ERRORS.INCORRECT_FILE));
            return false;
        }
        var size = Math.round(file.size / 1024);
        if (20 > size) {
            this.errors = this.FILE_UPLOAD_ERRORS.MIN_ERROR;
            this.toastrService.error(
                this.translateService.instant('FILE_UPLOAD_ERRORS.CUSTOM_MIN_ERROR_IMG', {
                    value: 20
                })
            );
            return false;
        } else if (size > 2048) {
            this.errors = this.FILE_UPLOAD_ERRORS.MAX_ERROR;
            this.toastrService.error(
                this.translateService.instant('FILE_UPLOAD_ERRORS.CUSTOM_MAX_ERROR_IMG', { value: 2 })
            );
            return false;
        }
        return true;
    }

    uploadCroppedImage(event) {
        const croppedFile = event.croppedFile;
        this.uploadFlashCardImage(croppedFile);
    }

    getSelectedRatio(event) {
        this.imageRatio = event.imageRatio;
    }

    toggleImageCropper() {
        this.showImageCropper = false;
    }

    toggleFlashCard(event) {
        const expectedState = event.checked;
        if (!expectedState) {
            this.confirmationService.confirm({
                header: 'Disable Flash Card?',
                message: 'Are you sure you want to disable flashcard?',
                acceptLabel: 'Disable',
                rejectLabel: 'Cancel',
                acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
                rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
                key: 'callOptionsConfimDialog',
                accept: () => {
                    this.updateFlashcardFeatureState(expectedState);
                },
                reject: () => {
                    this.isFlashCardEnabled = !expectedState;
                }
            });
        } else {
            this.updateFlashcardFeatureState(expectedState);
        }
    }

    updateFlashcardFeatureState(expectedState: boolean) {
        this.isFlashCardEnabled = expectedState;
        this.flashcardService.toggleFlashCard(this.webinarDetails._id || this.parentId, expectedState).subscribe({
            next: (res) => {
                if (this.isFlashCardEnabled && this.flashcardCount === 0) {
                    this.createFlashCard();
                }
            },
            error: (error) => {
                this.isFlashCardEnabled = !expectedState;
                this.toastrService.error('Failed to toggle flashcard feature. Please try again.');
            }
        });
    }

    updateDescription() {
        if (this.richTextEditorComponent?.quill?.container?.innerText?.trim()) {
            let message = this.richTextEditorComponent?.quill?.container.firstElementChild.innerHTML;
            let parsedContent = new DOMParser().parseFromString(message, 'text/html').documentElement.innerHTML;
            if (!parsedContent.includes('<html>')) {
                parsedContent = `<html>${parsedContent}</html>`;
            }
            const desc = encodeURI(parsedContent);
            this.flashCardForm.get('description').setValue(desc);
        } else {
            this.flashCardForm.get('description').setValue('');
        }
    }

    getFlashCards() {
        this.flashcardService.getFlashCards(this.webinarDetails._id || this.parentId).subscribe(
            (res) => {
                this.setFlashCardData(res);
            },
            (error) => {
                console.error('Error fetching flashcards:', error);
            }
        );
    }

    setFlashCardData(data) {
        this.flashCardsArrayIn = data.flashcards;
        this.flashcardCount = data.count;
        this.isFlashCardEnabled = data.enabled;
        this.flashCardsArrayIn.sort((a, b) => b.publish - a.publish);
        if (this.flashcardCount === 0 && data.enabled) {
            this.showCreateFlashCard = true;
        }
    }

    uploadFlashCardImage(file) {
        this.flashcardService
            .uploadFlashCardImage(this.webinarDetails?._id || this.parentId, file)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.showProgressBar = true;
                        this.progress = Math.round((event.loaded / event.total) * 100);
                        break;
                    case HttpEventType.Response:
                        this.showProgressBar = false;
                        this.isImageSet = true;
                        this.flashCardForm.get('imageUrl').setValue(event.body.flashCardImage);
                        break;
                }
            });
        (err) => {
            this.showProgressBar = false;
        };
    }

    discardFile() {
        this.isImageSet = false;
        if (this.cardDetails) {
            this.cardDetails.imageUrl = null;
        }
        this.flashCardForm.get('imageUrl').setValue('');
        this.showProgressBar = false;
    }

    sanitizeText(text) {
        return this.utilService.sanitizeAndConvertEncodedHTML(text);
    }

    createCard(publish = false) {
        const payload = {
            title: this.flashCardForm.value.title,
            description: this.flashCardForm.value.description,
            imageUrl: this.flashCardForm.value.imageUrl,
            imageMode: this.flashCardForm.value.imageUrl ? this.getImageMode() : '',
            publish: publish
        };
        this.flashcardService.createFlashCard(this.webinarDetails._id || this.parentId, payload).subscribe(
            (res) => {
                this.getFlashCards();
                this.cardSaved.emit();
                this.closePopup();
                this.createCardToasters(publish);
            },
            (err) => {
                this.toastrService.error(err?.error?.errors);
            }
        );
    }

    createCardToasters(publishCard) {
        if (publishCard) {
            this.toastrService.info('Flashcard saved and published');
        } else {
            this.toastrService.info('Flashcard created successfully');
        }
    }

    updateCardToasters(publishCard) {
        if (publishCard) {
            this.toastrService.info('Flashcard modified and published');
        } else {
            this.toastrService.info('Flashcard updated successfully');
        }
    }

    getImageMode() {
        if (this.imageRatio === 1.77) {
            return 'landscape';
        }
        return 'portrait';
    }

    updateFlashCard(flashcardId, publish = false) {
        const payload = {
            title: this.flashCardForm.value.title,
            description: this.flashCardForm.value.description,
            imageUrl: this.flashCardForm.value.imageUrl,
            imageMode: this.isImageSet ? this.getImageMode() : this.cardDetails?.imageMode,
            publish: publish
        };
        this.flashcardService.updateFlashCard(this.webinarDetails._id || this.parentId, flashcardId, payload).subscribe(
            (res) => {
                this.getFlashCards();
                this.cardSaved.emit();
                this.closePopup();
                this.updateCardToasters(publish);
            },
            (err) => {
                this.toastrService.error(err?.error?.errors);
            }
        );
    }

    deleteFlashCard(flashcardId) {
        this.confirmationService.confirm({
            header: 'Delete Flash Card?',
            message: 'Are you sure you want to delete flashcard?',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            key: 'callOptionsConfimDialog',
            accept: () => {
                this.flashcardService
                    .deleteFlashCard(this.webinarDetails._id || this.parentId, flashcardId)
                    .subscribe((res) => {
                        this.getFlashCards();
                        this.toastrService.info('Successfully deleted the flashcard');
                    });
            },
            reject: () => {}
        });
    }

    editCard(cardDetails) {
        this.cardDetails = cardDetails;
        this.setFlashCardForm(cardDetails);
        this.showCreateFlashCard = true;
    }

    publishFlashCard(card) {
        const payload = {
            flashCardId: card?._id
        };
        this.flashcardService.publishFlashCard(this.webinarDetails._id || this.parentId, payload).subscribe((res) => {
            this.getFlashCards();
            setTimeout(() => {
                this.toastrService.info('Flashcard published');
            }, 3000);
        });
    }

    unpublishFlashCard(card) {
        const payload = {
            flashCardId: card?._id
        };
        this.flashcardService.unpublishFlashCard(this.webinarDetails._id || this.parentId, payload).subscribe((res) => {
            this.getFlashCards();
            setTimeout(() => {
                this.toastrService.info('Flashcard unpublished');
            }, 3000);
        });
    }

    saveCard(publish) {
        if (
            !this.flashCardForm.value.title &&
            !this.flashCardForm.value.description &&
            !this.flashCardForm.value.imageUrl
        ) {
            this.toastrService.error('Cannot Create an Empty Flash Card');
            return;
        }
        if (this.cardDetails?._id) {
            this.updateFlashCard(this.cardDetails._id, publish);
        } else {
            this.createCard(publish);
        }
        this.showCreateFlashCard = false;
        this.discardFile();
    }

    publishCard() {
        this.saveCard(true);
    }
}
