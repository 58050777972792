import { Component, ViewChild, ElementRef, Input, OnDestroy, AfterViewInit } from '@angular/core';

declare const lottie: any;

@Component({
    selector: 'app-lottie-renderer',
    templateUrl: './lottie-renderer.component.html',
    styleUrls: ['./lottie-renderer.component.scss']
})
export class LottieRendererComponent implements AfterViewInit, OnDestroy {
    @ViewChild('renderer') renderer: ElementRef;

    @Input() path;

    private animation;

    ngAfterViewInit() {
        this.animation = lottie.loadAnimation({
            container: this.renderer.nativeElement,
            path: this.path,
            renderer: 'svg',
            autoplay: true,
            loop: true
        });
    }

    ngOnDestroy() {
        this.animation.stop();
        this.animation.destroy();
    }
}
