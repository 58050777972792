import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
    @Input() imageChangedEvent;
    @Input() imageTypeToBeCropped;
    @Input() imageURL;
    @Input() ratioOptions;
    @Input() defaultRatio = 16/9;
    @Input() insideCall;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() croppedFileEmitter: EventEmitter<any> = new EventEmitter();
    @Output() selectedRatioEmitter: EventEmitter<any> = new EventEmitter();
    croppedImage: any;
    uploadForm: FormGroup;
    maxWidth = 1280;
    maxHeight = this.maxWidth * (9 / 16);
    showKeepOriginalOptionInUi = false;
    selectedRatio = 1.77;

    constructor(private formBuilder: FormBuilder, private utilService: UtilService) {}

    ngOnInit(): void {
        console.log(this.imageTypeToBeCropped);
        this.uploadForm = this.formBuilder.group({
            profile: ['']
        });
    }

    closeModal() {
        this.close.emit();
    }

    selectRatio(ratio) {
        this.selectedRatio = ratio;
    }

    imageCropped(event) {
        this.showKeepOriginalOptionInUi =
            this.utilService.isAspectRatio16by9(event.width, event.height) &&
            (this.imageURL === undefined || this.imageURL === '');
        this.croppedImage = event.blob;
        this.uploadForm.get('profile').setValue(this.croppedImage);
    }

    onSubmit(isFromKeepOriginal) {
        if (this.imageChangedEvent || this.imageURL) {
            const croppedFile = this.uploadForm.get('profile').value;
            this.croppedFileEmitter.emit({
                croppedFile: isFromKeepOriginal ? this.imageChangedEvent || this.imageURL : croppedFile,
                imageTypeToBeCropped: this.imageTypeToBeCropped
            });
        }
        if (this.ratioOptions) {
            this.selectedRatioEmitter.emit({ imageRatio: this.selectedRatio });
        }
        this.closeModal();
        return;
    }
}
