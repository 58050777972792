<div
    class="sliderarea"
    [class.dashboard]="dashboard"
    [class.isMobile]="utilservice.isMobileView"
    [class.dashboard-public-events]="sliderHeading === 'Public'"
>
    <div class="slider-header">
        <h4>{{ sliderHeading }}</h4>
        <p *ngIf="eventsList.length > 4 || (isMobile && eventsList.length > 1)" (click)="viewAllEvents()">
            {{ viewAll ? 'View Less' : 'View All' }}
        </p>
    </div>
    <div class="slider-container">
        <div *ngIf="!isLoading">
            <div *ngIf="eventsList.length">
                <div *ngIf="!viewAll && !isLoading" class="slider">
                    <swiper id="client-section-right" [config]="config">
                        <ng-container *ngFor="let event of eventsList; let i = index; trackBy: trackByFunction">
                            <div class="swiper-slide">
                                <app-event-card
                                    [eventinfo]="event"
                                    [dashboard]="dashboard"
                                    [sliderName]="sliderName"
                                    (favouriteEvent)="markFavourite($event, i)"
                                    class="ecard"
                                    (eventClick)="eventClicked($event, i)"
                                    [eventViewSection]="EventViewSection.DASHBOARD"
                                    [hasCalIcon]="hasCalIcon"
                                    [hasFavIcon]="hasFavIcon"
                                    [id]="sliderHeading"
                                ></app-event-card>
                            </div>
                        </ng-container>
                    </swiper>
                    <div id="slideNext" class="next-button {{ nextButton }}">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.24507 6.36208L1.99709 1.11409C1.83161 0.957185 1.6114 0.871071 1.38338 0.874101C1.15536 0.877131 0.937539 0.969018 0.776292 1.13027C0.615044 1.29151 0.523127 1.50936 0.520097 1.73738C0.517067 1.9654 0.603181 2.18562 0.760087 2.35109L5.39009 6.98103L0.774064 11.5971C0.617158 11.7625 0.531075 11.9828 0.534104 12.2108C0.537134 12.4388 0.629052 12.6566 0.790299 12.8178C0.951547 12.9791 1.16937 13.071 1.39739 13.0741C1.6254 13.0771 1.84559 12.991 2.01106 12.8341L7.24507 7.60005C7.32641 7.51879 7.39093 7.42233 7.43495 7.31612C7.47898 7.20991 7.50163 7.09601 7.50163 6.98103C7.50163 6.86606 7.47898 6.75222 7.43495 6.64601C7.39093 6.5398 7.32641 6.44334 7.24507 6.36208Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <div id="slidePrevious" class="prev-button {{ prevButton }}">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.755416 6.36208L6.0034 1.11409C6.16888 0.957185 6.38909 0.871071 6.61711 0.874101C6.84513 0.877131 7.06295 0.969018 7.2242 1.13027C7.38544 1.29151 7.47736 1.50936 7.48039 1.73738C7.48342 1.9654 7.39731 2.18562 7.2404 2.35109L2.6104 6.98103L7.22642 11.5971C7.38333 11.7625 7.46941 11.9828 7.46638 12.2108C7.46335 12.4388 7.37144 12.6566 7.21019 12.8178C7.04894 12.9791 6.83112 13.071 6.6031 13.0741C6.37508 13.0771 6.1549 12.991 5.98942 12.8341L0.755416 7.60005C0.67408 7.51879 0.609558 7.42233 0.565536 7.31612C0.521513 7.20991 0.498855 7.09601 0.498855 6.98103C0.498855 6.86606 0.521513 6.75222 0.565536 6.64601C0.609558 6.5398 0.67408 6.44334 0.755416 6.36208Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
                <cdk-virtual-scroll-viewport class="virtual-scroll-viewport" *ngIf="viewAll" itemSize="90">
                    <app-infinite-scroll class="view_all" (scrolled)="getMoreEvents()">
                        <ng-container *ngFor="let event of eventsList; let i = index; trackBy: trackByFunction">
                            <app-event-card
                                [eventinfo]="event"
                                [dashboard]="dashboard"
                                [sliderName]="sliderName"
                                (favouriteEvent)="markFavourite($event, i)"
                                class="ecard"
                                [hasCalIcon]="hasCalIcon"
                                [hasFavIcon]="hasFavIcon"
                                (eventClick)="eventClicked($event, i)"
                            ></app-event-card>
                        </ng-container>
                        <div *ngIf="newEventsLoading" class="d-flex justify-content-center w-100">
                            <app-loader [position]="'relative'"></app-loader>
                        </div>
                    </app-infinite-scroll>
                </cdk-virtual-scroll-viewport>
            </div>
            <div *ngIf="!eventsList.length">
                <div class="no_events">
                    <img src="assets/img/no_events_icon.svg" />
                    <div class="no_events_text">
                        <div class="no_events_text_1">
                            No
                            {{
                                sliderName == 'replay'
                                    ? 'Watch Now'
                                    : sliderName == 'recommended'
                                    ? 'World Cup Fever 🏏'
                                    : sliderName
                            }}
                            events available at the moment
                        </div>
                        <div class="no_events_text_2">Please visit here after a while</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isLoading" class="loader">
            <img src="assets/img/jioevents-loader.gif" width="88px" height="88px" alt="" />
        </div>
    </div>
</div>
