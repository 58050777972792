import { textElement } from '../models/textElement';
import { fabric } from 'fabric';

export class textElementView implements textElement {
    // base model properties
    id: number;
    name: string;
    type: string;
    visible: boolean;
    zIndex: number;
    position: { left: number; top: number };
    dimension: { height: number; width: number };
    rotation: number;
    transitions: { onAdding: string; onMove: string; onRemove: string };

    // text element properties
    text: string;
    enableTicker: boolean;
    fontSize: number;
    fontStyle: string;
    fontColor: string;
    fontFamily: string;
    opacity: number;
    enableHyperLink: boolean;
    hyperLinkInput: string;
    textAlign: string;
    fabricObject;
    gradient;
    fontFamilyUrl: string;
    fontWeight: string;
    padding: any;
    backgroundColor: any;
    effects: any;

    constructor(props: textElement) {
        this.id = props.id;
        this.name = props.name;
        this.type = props.type;
        this.visible = props.visible;
        this.zIndex = props.zIndex;
        this.position = props.position;
        this.dimension = props.dimension;
        this.transitions = props.transitions;
        this.text = props.text || '';
        this.fontSize = props.fontSize || 8;
        this.fontStyle = props.fontStyle || 'bold';
        this.fontColor = props.fontColor || '#000';
        this.gradient = props.gradient || {
            enable: false,
            color: '#fff'
        };
        this.fontFamily = props.fontFamily || 'Arial';
        this.opacity = props.opacity || 100;
        this.enableTicker = props.enableTicker;
        this.enableHyperLink = props.enableHyperLink;
        this.textAlign = props.textAlign;
        this.hyperLinkInput = props.hyperLinkInput;
        this.fontFamilyUrl = props.fontFamilyUrl;
        this.fontWeight = props.fontWeight;
        this.padding = props.padding;
        this.backgroundColor = props.backgroundColor;
        this.effects = props.effects;
    }

    async createFabricElement() {
        this.fabricObject = new fabric.IText(this.text || '', {
            fill: this.fontColor,
            fontFamily: this.fontFamily,
            opacity: this.opacity * 0.01,
            fontStyle: this.fontStyle,
            left: this.percentageToPixel(this.position.left, 1920),
            top: this.percentageToPixel(this.position.top, 1080),
            id: this.id,
            visible: this.visible,
            cornerSize: 25,
            editable: false
        });
        const width = this.percentageToPixel(30, 1080);
        this.fabricObject.scaleToHeight(width, false);
        this.fabricObject.setControlsVisibility({ mtr: false, mt: false, ml: false, mr: false, mb: false });
        return this.fabricObject;
    }

    pixelToPercentage(pixelLength, parentLength) {
        return (pixelLength / parentLength) * 100;
    }

    percentageToPixel(percentage, parentLength) {
        return (percentage * parentLength) / 100;
    }
}
