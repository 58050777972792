<div class="video-container" [class.issafarimobile]="isSafariMobile">
    <div id="video-media" class="media-content"></div>
</div>

<app-loader *ngIf="loading"></app-loader>

<div
    class="playlist-container hide"
    [class.open]="showPlaylist"
    (mouseenter)="setHover(true)"
    (mouseleave)="setHover(false)"
>
    <div class="playlist-heading" (click)="togglePlaylist()">
        <img class="playlist-icon" src="assets/img/playlist_icon.svg" alt="playlist" />
        <div class="heading-text">Video {{ currentRecording + 1 }}/{{ totalRecordings }}</div>
        <img
            class="arrow-icon"
            [src]="!showPlaylist ? 'assets/img/ic_chevron_down.svg' : 'assets/img/ic_chevron_up.svg'"
            alt="arrow"
        />
    </div>
    <div *ngIf="showPlaylist" class="playlist-body">
        <div class="videos-container">
            <ng-container *ngFor="let video of recordingDetails; let i = index">
                <div [class.current]="currentRecording == i" class="video-item" (click)="playVideo(i)">
                    <div>
                        <div class="video-number" *ngIf="currentRecording != i">{{ i + 1 }}</div>
                        <img class="play-icon" *ngIf="currentRecording == i" src="assets/img/play_button.svg" alt="" />
                    </div>
                    <img src="{{ playlistImg }}" class="thumbnail" />
                    <div class="video-name">
                        <div class="name">Video - {{ i + 1 }}</div>
                        <div class="duration">
                            {{ video.newDuration || video.duration }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
