<div class="landing" [class.mobile]="isMobile">
    <div
        class="userDetails"
        [class.isIosMobile]="isIosMobile"
        [class.isMobile]="!isIosMobile && isMobile"
        *ngIf="!submittedForm"
    >
        <!-- <div class="heading">GET 2024</div> -->
        <form class="form-container" [formGroup]="userDetailsForm" (ngSubmit)="onSubmit()">
            <div class="form-header">
                <img class="form-banner" src="assets/img/get_banner.svg" />
            </div>
            <div class="form-body">
                <div>
                    <label class="label-required" for="name">Please enter your full name</label>
                    <input
                        class="inputclass"
                        [readonly]="userDetailsForm.get('name').value"
                        id="name"
                        formControlName="name"
                    />
                    <div *ngIf="userDetailsForm.get('name').invalid && userDetailsForm.get('name').touched">
                        <small *ngIf="userDetailsForm.get('name').hasError('required')">Name is required</small>
                        <small *ngIf="userDetailsForm.get('name').hasError('minlength')"
                            >Name must be at least 2 characters long</small
                        >
                    </div>
                </div>

                <div>
                    <label class="label-required" for="phoneNo"
                        >Please enter your phone number. (Enter the number you have shared with RIL earlier)</label
                    >
                    <input
                        id="phoneNo"
                        class="inputclass"
                        [readonly]="userDetailsForm.get('phoneNo').value"
                        formControlName="phoneNo"
                        type="tel"
                        pattern="[0-9]*"
                    />
                    <div *ngIf="userDetailsForm.get('phoneNo').invalid && userDetailsForm.get('phoneNo').touched">
                        <small *ngIf="userDetailsForm.get('phoneNo').hasError('required')"
                            >Phone number is required</small
                        >
                        <small *ngIf="userDetailsForm.get('phoneNo').hasError('pattern')"
                            >Phone number must be 10 digits</small
                        >
                    </div>
                </div>

                <div>
                    <label class="label-required" for="email"
                        >Please enter your personal email ID (This should be the same email ID on which you are
                        receiving all information from RIL)</label
                    >
                    <input
                        id="email"
                        class="inputclass"
                        [readonly]="userDetailsForm.get('email').value"
                        formControlName="email"
                    />
                    <div *ngIf="userDetailsForm.get('email').invalid && userDetailsForm.get('email').touched">
                        <small *ngIf="userDetailsForm.get('email').hasError('required')">Email is required</small>
                        <small *ngIf="userDetailsForm.get('email').hasError('email')">Email is invalid</small>
                    </div>
                </div>

                <div>
                    <label class="label-required" for="location">Work Location</label>
                    <input
                        id="location"
                        class="inputclass"
                        [attr.readonly]="profileDetails?.location ? true : null"
                        formControlName="location"
                        placeholder="Enter Work Location"
                        [attr.maxlength]="workLocationChar"
                    />
                    <div *ngIf="userDetailsForm.get('location').invalid && userDetailsForm.get('location').touched">
                        <small *ngIf="userDetailsForm.get('location').hasError('required')">Location is required</small>
                        <small *ngIf="userDetailsForm.get('location').hasError('email')">Location is invalid</small>
                    </div>
                </div>

                <div>
                    <label class="label-required" for="businessName">Business Unit</label>
                    <input
                        id="businessName"
                        class="inputclass"
                        [attr.readonly]="userDetailsStrapi?.data?.attributes?.businessName ? true : null"
                        formControlName="businessName"
                        placeholder="Enter Business Unit"
                        [attr.maxlength]="businessUnitChar"
                    />
                    <div
                        *ngIf="
                            userDetailsForm.get('businessName').invalid && userDetailsForm.get('businessName').touched
                        "
                    >
                        <small *ngIf="userDetailsForm.get('businessName').hasError('required')"
                            >Business Name is required</small
                        >
                    </div>
                </div>

                <div>
                    <label class="label-required" for="collegeName">College Name</label>
                    <input
                        id="collegeName"
                        class="inputclass"
                        [attr.readonly]="userDetailsStrapi?.data?.attributes?.collegeName ? true : null"
                        formControlName="collegeName"
                        placeholder="Enter College Name"
                        [attr.maxlength]="collegeNameChar"
                    />
                    <div
                        *ngIf="userDetailsForm.get('collegeName').invalid && userDetailsForm.get('collegeName').touched"
                    >
                        <small *ngIf="userDetailsForm.get('collegeName').hasError('required')"
                            >College Name is required</small
                        >
                    </div>
                </div>

                <div>
                    <label class="label-required" for="funFact">
                        Please enter a fun fact about you. Be as creative as you can!
                    </label>
                    <input
                        id="funFact"
                        class="inputclass"
                        [attr.maxlength]="funFactChar"
                        formControlName="funFact"
                        placeholder="Enter Fun Fact"
                        pattern="^[a-zA-Z0-9, ]*$"
                        (input)="onInputFunfact($event)"
                    />
                    <div *ngIf="userDetailsForm.get('funFact').invalid && userDetailsForm.get('funFact').touched">
                        <small *ngIf="userDetailsForm.get('funFact').hasError('required')">Fun fact is required</small>
                        <small *ngIf="userDetailsForm.get('funFact').hasError('pattern')">
                            Only alphanumeric characters are allowed
                        </small>
                    </div>
                </div>

                <div>
                    <label class="label-required" for="hobbies">Tell us about your hobbies</label>
                    <input
                        id="hobbies"
                        class="inputclass"
                        [attr.maxlength]="hobbiesChar"
                        formControlName="hobbies"
                        placeholder="Enter Hobbies"
                        pattern="^[a-zA-Z0-9, ]*$"
                        (input)="onInputHobbies($event)"
                    />
                    <div *ngIf="userDetailsForm.get('hobbies').invalid && userDetailsForm.get('hobbies').touched">
                        <small *ngIf="userDetailsForm.get('hobbies').hasError('required')">Hobbies are required</small>
                        <small *ngIf="userDetailsForm.get('funFact').hasError('pattern')">
                            Only alphanumeric characters and comma are allowed
                        </small>
                    </div>
                </div>

                <div>
                    <label class="" for="linkedinProfile">Please share the link to your LinkedIn profile</label>
                    <input
                        id="linkedinProfile"
                        class="inputclass"
                        formControlName="linkedinProfile"
                        placeholder="Enter Linkedin Profile"
                    />
                    <div
                        *ngIf="
                            userDetailsForm.get('linkedinProfile').invalid &&
                            userDetailsForm.get('linkedinProfile').touched
                        "
                    >
                        <small *ngIf="userDetailsForm.get('linkedinProfile').hasError('required')"
                            >LinkedIn profile is required</small
                        >
                        <small *ngIf="userDetailsForm.get('linkedinProfile').hasError('pattern')"
                            >LinkedIn profile URL is invalid</small
                        >
                    </div>
                </div>

                <div>
                    <label class="label-required" for="profilePicture">
                        Please upload your profile photo. This photo should be of maximum 2 MB.
                    </label>
                    <input type="file" id="profilePicture" accept="image/*" (change)="onFileChange($event)" />
                    <div
                        *ngIf="
                            userDetailsForm.get('profilePicture').invalid &&
                            userDetailsForm.get('profilePicture').touched
                        "
                    >
                        <small *ngIf="userDetailsForm.get('profilePicture').hasError('required')"
                            >Profile picture is required</small
                        >
                        <small *ngIf="userDetailsForm.get('profilePicture').hasError('invalidFileType')"
                            >Only JPEG, JPG, and PNG files are allowed.</small
                        >
                        <small *ngIf="userDetailsForm.get('profilePicture').hasError('invalidFile')"
                            >Invalid file: must be less than 2MB</small
                        >
                    </div>
                </div>

                <div>
                    <label class="label-required" for="captcha">CAPTCHA</label>
                    <div class="d-flex align-items-center" style="gap: 10px; padding-bottom: 3px">
                        <input
                            class="inputclass"
                            formControlName="captchaInput"
                            id="captcha"
                            name="captcha"
                            (input)="checkCaptcha()"
                            required
                            placeholder="Enter Captcha"
                        />
                        <img src="{{ captchaImage }}" alt="CAPTCHA" />
                        <img src="assets/img/refresh_icn.svg" (click)="refreshCaptcha()" class="captcha" />
                    </div>
                    <div *ngIf="captchaError" class="error">CAPTCHA does not match.</div>
                </div>
                <div class="disclaimer-text">Disclaimer: Data once submitted cannot be edited later.</div>
                <div class="btn-container justify-content-end">
                    <button [class.disable]="!captchaValid || !userDetailsForm.valid" class="submit-btn" type="submit">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="submittedForm" class="submittedForm">
        <div><img src="assets/img/jioconf/misc/success.svg" /></div>
        <div>Form Submitted Successfully</div>
    </div>
</div>
