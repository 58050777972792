import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ExternalInterfaceService, UtilService } from 'src/app/core';
import { FlashcardService } from 'src/app/core/services/flashcard.service';

@Component({
    selector: 'app-flash-card',
    templateUrl: './flash-card.component.html',
    styleUrls: ['./flash-card.component.scss']
})
export class FlashCardComponent implements OnInit {
    dragPosition = { x: 0, y: 0 };
    @Input() card;
    @Input() isMobile;
    @Input() isPortrait;
    @Input() isLandscape;
    @Input() mobCallOptions;
    @Input() isMyJio;
    @Input() isSpeakerSide;
    @Output() flashImgClick = new EventEmitter();
    @Output() closeFlashImg = new EventEmitter();
    aspectRatio: Promise<number>;
    imagePopup: boolean;
    isDragging: boolean;
    private timeouts = [];
    activatedRoute: any;
    constructor(
        private fb: FormBuilder,
        public flashcardService: FlashcardService,
        private toastrService: ToastrService,
        private utilService: UtilService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private externalInterfaceService: ExternalInterfaceService
    ) {}

    ngOnInit(): void {
        this.setEventListenersFlashCard();
    }

    get dragBoundarySelector() {
        if (this.isSpeakerSide) {
            return '.call-gallery-view-wrapper';
        }
        if (this.mobCallOptions) {
            return '.flash-card-area';
        }
        if (this.isMobile) {
            return '.conference-body';
        }
        return '.event-conference-view';
    }

    sanitizeText(text) {
        return this.utilService.sanitizeAndConvertEncodedHTML(text);
    }

    ngOnChanges(changes: SimpleChanges) {
        // this.OnTimmerPositionChange();
        if (changes['card']) {
            const prevValue = changes['card'].previousValue;
            const currentValue = changes['card'].currentValue;
            const firstChange = changes['card'].firstChange;
            if (firstChange) {
                return;
            }
            if (!this.isEqual(prevValue, currentValue)) {
                this.flashcardService.showCardPreview = true;
                this.resetToInitialPosition();
                this.setEventListenersFlashCard();
                this.closeFlashImg.emit();
            }
        }
        if (changes['isLandscape'] || changes['mobCallOptions'] || changes['isPortrait']) {
            this.resetToInitialPosition();
        }
    }

    isEqual(objA: any, objB: any): boolean {
        return JSON.stringify(objA) === JSON.stringify(objB);
    }

    OnTimmerPositionChange() {
        const localContainer = document.getElementsByClassName(
            'flash-publish-container'
        ) as HTMLCollectionOf<HTMLElement>;

        let ytransform = localContainer[0].style?.transform?.split(',')[1]?.slice(0, -2);
        if (Number(ytransform) > 0) {
            this.dragPosition = {
                x: this.dragPosition.x,
                y: Number(ytransform) - 45
            };
        }
    }

    resetToInitialPosition() {
        this.dragPosition = { x: 0, y: 0 };
        this.updateContainerPosition();
    }

    updateContainerPosition() {
        // Update the position of the flash-publish-container based on this.dragPosition
        const localContainer = document.getElementsByClassName(
            'flash-publish-container'
        ) as HTMLCollectionOf<HTMLElement>;
        if (localContainer.length > 0) {
            localContainer[0].style.transform = `translate(${this.dragPosition.x}px, ${this.dragPosition.y}px)`;
        }
    }

    onDragStarted(event: CdkDragStart): void {
        this.isDragging = true;
    }

    toggleCardPreview() {
        if (this.isDragging) {
            this.isDragging = false;
            return;
        }
        if (!this.isDragging) {
            this.flashcardService.toggleFlashCardPreview();
        }
    }

    showFlashCardImg() {
        this.flashImgClick.emit();
    }

    setEventListenersFlashCard() {
        this.timeouts.push(
            setTimeout(() => {
                document.querySelectorAll('.flash-text a').forEach((a) => {
                    if (!this.isMyJio) {
                        a.setAttribute('target', '_blank');
                    } else {
                        a.addEventListener('click', (event) => {
                            let url = a.getAttribute('href');
                            event.preventDefault();
                            this.externalInterfaceService.launchBrowser(url);
                        });
                    }
                });
            })
        );
    }

    ngOnDestroy() {
        this.timeouts.forEach((id) => clearTimeout(id));
    }
}
