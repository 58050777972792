<div class="audio-settings-container" *ngIf="route == 'dashboard'">
    <section class="speaker-section">
        <div class="unit">
            <span>{{ 'user_dashboard_settings.audio.speaker' | translate }}</span>
        </div>
        <div class="unit">
            <audio id="testAudio">
                <source src="./assets/audio/call.mp3" type="audio/mpeg" />
            </audio>
            <select
                #audioOutput
                (change)="selectSpeaker()"
                [(ngModel)]="selectedSpeaker"
                *ngIf="availableAudio?.speakers?.length > 0"
            >
                <option *ngFor="let speaker of availableAudio?.speakers" [ngValue]="speaker">{{ speaker.name }}</option>
            </select>
            <span class="speakers-blocked pl-2" *ngIf="availableAudio?.speakers?.length === 0">
                Speaker selection is blocked
            </span>
            <button
                class="
                    custom-button-revamp
                    btn-confirm-secondary
                    sm
                    d-flex
                    align-items-center
                    justify-content-center
                    m-0
                "
                (click)="testSpeaker()"
                id=" {{
                    (testingControl.speaker
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_speaker'
                    ) | translate
                }}"
            >
                <img src="assets/images/settings/test_speaker.svg" />
                {{
                    (testingControl.speaker
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_speaker'
                    ) | translate
                }}
            </button>
        </div>
        <!-- <div class="unit-input">
            <span class="input-level">{{ 'user_dashboard_settings.audio.output_level' | translate }}:</span>
            <span class="pids-wrapper" [class.disabled]="!testingControl.speaker">
                <div #audioOutputLevel class="pid"></div>
                <div #audioOutputLevel class="pid"></div>
                <div #audioOutputLevel class="pid"></div>
                <div #audioOutputLevel class="pid"></div>
                <div #audioOutputLevel class="pid"></div>
            </span>
        </div> -->
    </section>
    <section class="mic-section" *ngIf="rtcService.isACSMeeting || rtcService.isAgoraMeeting || true">
        <div class="unit">
            <span>{{ 'user_dashboard_settings.audio.microphone' | translate }}</span>
        </div>
        <div class="unit">
            <div class="volume-parent-wrapper">
                <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
                    <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic" id="{{ mic.name }}">
                        {{ mic.name }}
                    </option>
                </select>
                <div class="unit-input">
                    <span class="input-level">{{ 'user_dashboard_settings.audio.input_level' | translate }}:</span>
                    <span class="pids-wrapper" [class.disabled]="!testingControl.mic">
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                        <div #level class="pid"></div>
                    </span>
                </div>
            </div>
            <button
                class="
                    custom-button-revamp
                    btn-confirm-secondary
                    sm
                    d-flex
                    align-items-center
                    justify-content-center
                    m-0
                "
                (click)="testMic()"
                id="  {{
                    (testingControl.mic
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_mic'
                    ) | translate
                }}_toggle"
            >
                <img src="assets/images/settings/test_mic.svg" />
                {{
                    (testingControl.mic
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_mic'
                    ) | translate
                }}
            </button>
        </div>
        <div class="mic-input mt-5" *ngIf="!this.isGuestUser">
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="audiosettingscheck"
                        [(ngModel)]="turnOffAudio"
                        (change)="onTurnOffAudio($event)"
                    />
                    <label class="custom-control-label pointer pl-2" for="audiosettingscheck">
                        {{ 'user_dashboard_settings.audio.turn_off_audio' | translate }}
                    </label>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="mic-section" *ngIf="rtcService.isACSMeeting || rtcService.isAgoraMeeting">
        <div class="unit d-flex">
            <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
                <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic">{{ mic.name }}</option>
            </select>
        </div>
    </section> -->
</div>

<div class="audio-settings-container call-preview-settings" *ngIf="route == 'call-preview'">
    <div class="unit d-flex">
        <!-- <span>{{ 'user_dashboard_settings.audio.microphone' | translate }}</span> -->
        <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
            <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic">{{ mic.name }}</option>
        </select>
    </div>
</div>
