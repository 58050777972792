import { Component, OnDestroy, OnInit } from '@angular/core';
import { VideoWrapperService } from 'src/app/core';
import { DevicesManagerService, PreCallTestingService, TEST_STATUS } from '../../services';
import { JMVideoInputTest } from '@jiomeet/jm-test-rtc';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-video-test',
    templateUrl: './video-test.component.html',
    styleUrls: ['./video-test.component.scss']
})
export class VideoTestComponent implements OnInit, OnDestroy {
    testVideoInputDevice;
    availableCameras;
    isModalOpen = false;
    cameraResult;
    selectedCamera;
    camTestInProgress = false;
    countDown = 6;
    countDownId?: NodeJS.Timeout;
    videoMessage = 'Click on video test to see the video';
    private eventSubscription: Subscription;
    public selectedMic: any;
    cameraFailed: boolean = false;

    constructor(
        private deviceManagerService: DevicesManagerService,
        private preCallTestingService: PreCallTestingService
    ) {}

    ngOnInit(): void {
        this.registerRestartEvent();
        this.getDevices();
    }

    registerRestartEvent() {
        this.eventSubscription = this.preCallTestingService.getEvent().subscribe((data: string) => {
            if (data === 'Restart') {
                this.cameraResult = null;
                this.videoMessage = 'Click on video test to see the video';
                this.camTestInProgress = false;
                this.isModalOpen = false;
                this.resetCountdown();
                this.testVideoInputDevice.stop();
                this.cameraFailed = false;
            }
        });
    }
    getDevices() {
        this.availableCameras = this.deviceManagerService.cameras.map((camera, idx) => {
            if (!camera.name) camera.name = `Camera ${idx + 1}`;
            return camera;
        });
        this.selectedCamera = this.availableCameras[0]?.id;
    }

    async startTest() {
        const videoContainer: any = document.getElementById('video-feed');

        this.testVideoInputDevice = new JMVideoInputTest({
            element: videoContainer,
            duration: 5000,
            deviceId: this.selectedCamera
        });
        try {
            this.camTestInProgress = true;
            this.resetCountdown();
            this.updateCountdown();
            this.cameraResult = await this.testVideoInputDevice.startTest();
            this.preCallTestingService.cameraTestStatus = TEST_STATUS.PASS;
            this.videoMessage = 'Click on video test to see the video';
            this.cameraFailed = false;
        } catch (error) {
            this.videoMessage = 'Camera is not working properly. Please check your device.';
            this.preCallTestingService.cameraTestStatus = TEST_STATUS.FAILED;
            this.cameraFailed = true;
        } finally {
            this.resetCountdown();

            this.camTestInProgress = false;
        }
    }

    updateCountdown() {
        if (this.countDown > 0) {
            // Decrease the countdown value
            this.countDown--;

            // Update the countdown every second (1000 milliseconds)
            this.countDownId = setTimeout(() => {
                this.updateCountdown();
            }, 1000);
        } else {
            if (this.countDownId) {
                clearTimeout(this.countDownId);
            }
        }
    }

    resetCountdown() {
        if (this.countDownId) {
            clearTimeout(this.countDownId);
        }
        this.countDown = 6;
    }

    onCloseModalEvent() {
        this.isModalOpen = false;
    }

    viewResult() {
        this.isModalOpen = true;
    }

    selectCamera(event: any) {
        this.selectedCamera = event.target.value;
    }

    ngOnDestroy(): void {
        this.eventSubscription.unsubscribe();
    }
}
