import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppService } from 'src/app/core';

@Injectable()
export class WebinarAttendeeGuard implements CanActivate {
    constructor(private router: Router, private appService: AppService) {}

    canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot) {
        if (route.params && route.params.shID) {
            if (this.appService.getConfigVariable('ENABLE_WEBINAR_ATTENDEE_FLOW')) {
                return true;
            }
            this.router.navigate(['/']);
            return false;
        }
        this.router.navigate(['/']);
        return false;
    }
}
