import { Directive, ElementRef, EventEmitter, HostListener, Input, Optional, Output, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appResetInput]'
})
export class ResetInputDirective {
    @Input() resetInputClass: string;
    private img: HTMLImageElement;
    private destroyed$ = new Subject();
    private resetImgIcon = 'assets/img/jioconf/reset-circle.svg';
    @Output() onReset: EventEmitter<any> = new EventEmitter();

    constructor(private el: ElementRef, private renderer: Renderer2, @Optional() private ngModel: NgModel) {}

    @HostListener('input', ['$event']) onChange(event) {
        if (!this.ngModel) {
            this.update(event.target.value.length);
        }
    }

    @HostListener('focus', ['$event']) onFocus(event) {
        if (!this.ngModel) {
            this.update(event.target.value.length);
        }
    }

    @HostListener('focusout', ['$event'])
    onBlur(event) {
        setTimeout(() => {
            this.renderer.setAttribute(this.img, 'src', '');
        }, 300);
    }

    ngOnInit() {}

    ngAfterViewInit() {
        this.img = this.renderer.createElement('img');
        if (this.resetInputClass) {
            this.img.classList.add(this.resetInputClass);
        } else this.img.classList.add('reset-input');
        this.renderer.insertBefore(this.el.nativeElement.parentNode, this.img, this.el.nativeElement.nextSibling);
        this.img.addEventListener('click', this.reset.bind(this));

        // this.update(this.el.nativeElement.value.length);
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        if (this.img) {
            this.img.remove();
        }
    }

    private reset(event) {
        this.onReset.emit(this.el.nativeElement.id);
        this.update(0);
    }

    private update(length: number) {
        this.renderer.setAttribute(this.img, 'src', length > 0 ? this.resetImgIcon : '');
    }
}
