import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService, DiagnosticService, UtilService } from 'src/app/core';

@Component({
    selector: 'app-pmisettings',
    templateUrl: './pmisettings.component.html',
    styleUrls: ['./pmisettings.component.scss']
})
export class PmisettingsComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();

    user;
    form: FormGroup;
    advancedOptionsCollapsed = false;
    errors: any = {};
    loading;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private userService: UserService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.user = this.utilService.clone(this.userService.getUserSync());

        this.createForm();
        this.getPreferences();
    }

    createForm() {
        this.form = this.fb.group({
            hostAudio: [false],
            hostVideo: [false],
            participantAudio: [false],
            participantVideo: [true],
            waitingRoom: [false],
            participantHardAudioMute: [false],
            colleagueOnly: [false],
            loggedInOnly: [false],
            joinBeforeHost: [false]
        });
        this.listenToFormValueChanges();
    }

    listenToFormValueChanges() {
        this.form.get('waitingRoom').valueChanges.subscribe((waitingRoom) => {
            if (waitingRoom) {
                this.form.patchValue({ joinBeforeHost: false });
            }
        });

        this.form.get('joinBeforeHost').valueChanges.subscribe((joinBeforeHost) => {
            if (joinBeforeHost) {
                this.form.patchValue({ waitingRoom: false });
            }
        });

        this.form.get('participantAudio').valueChanges.subscribe((participantAudio) => {
            if (participantAudio && this.form.value.participantHardAudioMute) {
                this.form.patchValue({ participantHardAudioMute: false });
            }
        });

        this.form.get('participantHardAudioMute').valueChanges.subscribe((participantHardAudioMute) => {
            if (participantHardAudioMute && this.form.value.participantAudio) {
                this.form.patchValue({ participantAudio: false });
            }
        });

        this.form.get('colleagueOnly').valueChanges.subscribe((colleagueOnly) => {
            if (colleagueOnly && this.form.value.loggedInOnly) {
                this.form.patchValue({ loggedInOnly: false });
            }
        });

        this.form.get('loggedInOnly').valueChanges.subscribe((loggedInOnly) => {
            if (loggedInOnly && this.form.value.colleagueOnly) {
                this.form.patchValue({ colleagueOnly: false });
            }
        });
    }

    getPreferences() {
        this.userService
            .getMeetingPrefernces()
            .subscribe((preferences) =>
                this.form.patchValue({ ...preferences, participantAudio: !preferences.participantAudio })
            );
    }

    saveUserPreferences() {
        return this.userService
            .updatePersonalRoomPreferences({
                isPinEnabled: !!this.user.roomPIN,
                roomPIN: this.user.roomPIN
            })
            .pipe(
                tap(() => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Web Profile',
                            eventType: 'app_event',
                            eventAction: 'Change PMI Password',
                            status: 'success'
                        })
                        .subscribe();
                })
            );
    }

    saveMeetingPreferences() {
        const { ...payload } = this.utilService.clone(this.form.value);
        payload.participantAudio = !payload.participantAudio;

        return this.userService.updateMeetingPrefernces(payload).pipe(
            tap(() => {
                this.diagnosticService
                    .sendEvent({
                        eventAction: 'Web Settings Updated',
                        eventCategory: 'Web Settings',
                        eventType: 'app_event',
                        endpoint: 'api/user/meetingpreferences',
                        status: 'success',
                        data: payload
                    })
                    .subscribe();

                this.toastrService.clear();
                this.toastrService.success(
                    this.translateService.instant('tostrs.your_settings_have_been_updated'),
                    '',
                    {
                        positionClass: 'toast-top-center',
                        timeOut: 3000
                    }
                );
            })
        );
    }

    save() {
        if (!this.validatePin()) {
            return;
        }
        forkJoin([this.saveUserPreferences(), this.saveMeetingPreferences()]).subscribe();
    }

    validatePin() {
        if (
            !(
                /[a-z]/.test(this.user.roomPIN) &&
                /[A-Z]/.test(this.user.roomPIN) &&
                /[0-9]/.test(this.user.roomPIN) &&
                !this.user.roomPIN.includes('I') &&
                !this.user.roomPIN.includes('l') &&
                !this.user.roomPIN.includes('o') &&
                !this.user.roomPIN.includes('O') &&
                !this.user.roomPIN.includes('0') &&
                /^[0-9a-zA-Z]+$/.test(this.user.roomPIN)
            )
        ) {
            this.toastrService.error(this.translateService.instant('tostrs.password_should_be'));
            return false;
        }
        return true;
    }

    refreshRoomPin() {
        this.user.roomPIN = this.utilService.generatePassword(5);
    }

    toggleAdvancedOptions() {
        this.advancedOptionsCollapsed = !this.advancedOptionsCollapsed;
    }

    navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }
}
