<div class="label d-flex mb-2 align-tems-center">
    <span class="mr-3">Backdrop</span>
    <div class="d-flex align-items-center">
        <app-info-icon [tooltip]="'A backdrop will appear as a stage background during a live event.'"></app-info-icon>
    </div>
</div>
<div class="image-container">
    <img
        [src]="defaultSrc"
        alt=""
        (click)="onSelect($event, 'default')"
        class="image-icon"
        [class.active]="defaultSrc === url"
    />
    <label for="file"><img [src]="customSrc" alt="" class="image-icon" /></label>
    <input
        type="file"
        id="file"
        (change)="fileChangeEvent($event)"
        (click)="onSelect($event, 'customClicked')"
        hidden
        accept="image/*"
    />
    <div *ngFor="let img of localImageSet" class="custom-image-holder">
        <img
            src="{{ img }}"
            alt="bg-image"
            class="image-icon"
            [class.active]="img === url"
            (click)="onSelect($event, 'customApplied')"
        />
        <span (click)="deleteCustomImage(img)" class="delete-icn-wrapper">
            <img src="assets/images/attendee_emojis/new_icons/close_filled_icon_green.svg" alt="" />
        </span>
    </div>
    <div *ngFor="let img of backdropOptions; let i = index">
        <img
            src="{{ img.src }}"
            alt="bg-image"
            class="image-icon"
            [class.active]="img.src === url"
            (click)="onSelect($event, 'defaultOptions', i)"
        />
    </div>
</div>
<span class="note">• For Custom Backdrop, File Type: GIF, JPEG, PNG • File Size: 638x392 px, {{ maxFileSize }}MB</span>
