import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    Component,
    ElementRef,
    EventEmitter,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { AppService, AuthService, GuestAuthService, UserService, UtilService, VideoWrapperService } from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { LeaderboardService } from 'src/app/core/services/leaderboard.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
const LEADERBOARD_TABLE_POLLING_INTERVAL = 30000;

@Component({
    selector: 'app-leaderboard-wrapper',
    templateUrl: './leaderboard-wrapper.component.html',
    styleUrls: ['./leaderboard-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LeaderboardWrapperComponent implements OnInit, OnDestroy {
    @ViewChild(CdkVirtualScrollViewport) public leaderboardScrollViewport: CdkVirtualScrollViewport;
    loading: boolean = true;
    leaderboard: any = {};
    currentUserId;
    dummy = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    attendeeScores: any = [];
    showWinnersSpotlight = true;
    actionIcons = {
        Reactions: 'assets/images/speaker-call-controls/call_control_reaction_hover.svg',
        Comments: 'assets/img/jioevents/dark_theme/white-comment-icon.svg',
        'Poll Responses': 'assets/img/jioevents/dark_theme/polls_white_icon.svg',
        'Q&A Participation': 'assets/img/jioevents/dark_theme/qna_white_icon.svg',
        'Event Entry': 'assets/images/leaderboard/event_entry_icon.svg',
        'Networking Lounge Participation': 'assets/images/leaderboard/networking_room_participation.svg',
        default: 'assets/images/leaderboard/default_action_icon.svg'
    };
    pointsMap = {
        Reactions: 'Give Reactions',
        Comments: 'Post comments',
        'Poll Responses': 'Answer Polls',
        'Q&A Participation': 'Participate in Q&A',
        'Event Entry': 'Event/Session Entry',
        'Networking Lounge Participation': 'Participate in Networking Rooms'
    };
    filteredRewardActions = [];
    overlayDisplayState: boolean = false;
    showShareAchievementButton: boolean = false;

    @Output() close: EventEmitter<any> = new EventEmitter();
    showScoreDistributionInfoIdx: any = -1;
    scoreDistribution: any;
    showScoringInfoTable: boolean = false;
    randomNumber: any = '12345';
    prizePreviewIndex = -1;
    isMobileOS: boolean = false;
    leaderboardScoresInterval$;
    @ViewChild('leaderboardTableContainer') tableContainer: ElementRef;

    constructor(
        public leaderboardService: LeaderboardService,
        public callStateManagerService: CallStateManagerService,
        private toastrService: ToastrService,
        private videoWrapperService: VideoWrapperService,
        public callViewStateManagerService: CallViewStateManagerService,
        private appLoggerService: AppLoggerService,
        private userService: UserService,
        private authService: AuthService,
        private guestAuthService: GuestAuthService,
        private webinarService: WebinarAttendeeService,
        private confirmationService: ConfirmationService,
        private appService: AppService,
        private utilService: UtilService,
        private zone: NgZone
    ) {}

    ngOnInit(): void {
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.currentUserId =
            this.userService.getUserSync()?._id ||
            this.guestAuthService.getAuthInfo()?.guestUserId ||
            this.webinarService.getUserId();
        this.initializeLeaderboardWrapper();
    }

    async initializeLeaderboardWrapper() {
        this.leaderboard = await this.leaderboardService.getLeaderboardInfoObj(
            this.callStateManagerService.getParentMeetingId()
        );
        this.filteredRewardActions = this.leaderboardService.getfilteredRewardActions(this.leaderboard);
        await this.getLeaderboardScores();
        this.pollLeaderboardScoresUpdate();
        // this.getCurrentUserScoreDistribution();
        await this.getLeaderboardOverlayState();
        this.loading = false;
    }

    pollLeaderboardScoresUpdate() {
        this.leaderboardScoresInterval$ = setInterval(() => {
            this.getLeaderboardScores();
        }, LEADERBOARD_TABLE_POLLING_INTERVAL);
    }

    setLocalUserRankOnResults(scoresList = []) {
        if (!this.leaderboard.resultsPublished) return;
        let localUserRank = scoresList.find((u) => u.userId === this.currentUserId)?.rank;
        this.leaderboardService.localUserRank = localUserRank <= 3 && localUserRank !== -1 ? localUserRank : -1;
    }

    async getLeaderboardOverlayState() {
        if (!this.callViewStateManagerService.isSpeaker) return;
        await this.callViewStateManagerService.getEventUpdates();
        this.overlayDisplayState = this.callViewStateManagerService.eventUpdatesObj?.displayLeaderboardResults;
    }

    syncLocalUserWithDistribution() {
        const localUserIndex = this.getLocalUserIndex(this.attendeeScores);
        if (localUserIndex === -1) return;
        const updatedScore = this.getScoreFromDistribution();
        this.attendeeScores[localUserIndex].score = updatedScore;
        this.leaderboardService.localUserLatestScore = updatedScore;
    }

    getCurrentUserScoreDistribution() {
        return new Promise((resolve, reject) => {
            this.leaderboardService
                .getScoreDistributionByUser(this.callStateManagerService.getParentMeetingId())
                .subscribe(
                    (res) => {
                        this.scoreDistribution = res.filter((e) => e.score !== '0');
                        this.syncLocalUserWithDistribution();
                        resolve(true);
                    },
                    (err) => {
                        this.appLoggerService.error('Failed to get User Score Distribution', err);
                        reject();
                    }
                );
        });
    }

    confirmPublishResults() {
        this.confirmationService.confirm({
            message:
                'Once the results are published, attendee activities will not be recorded for leaderboard. Are you sure you want to publish?',
            header: 'Publish Results?',
            acceptLabel: 'Confirm',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            accept: () => {
                this.publishResults();
            },
            reject: () => {}
        });
    }

    publishResults() {
        // this.videoWrapperService
        //     .getVideoService()
        //     .sendChatMessage({ type: 'PublicChat', message: 'displayLeaderboardResults' });
        this.leaderboardService.publishResults(this.callStateManagerService.getParentMeetingId()).subscribe(
            (res) => {
                // this.leaderboardService.hideLeaderboardOverlayAfterTimeOut(
                //     this.callStateManagerService.getParentMeetingId()
                // );
                this.leaderboard.resultsPublished = true;
                this.toastrService.success('Leaderboard results published succesfully');
            },
            (err) => {}
        );
    }

    handleLeaderboardOverlayClick() {
        this.leaderboardService
            .handleLeaderboardOverlay(this.callStateManagerService.getParentMeetingId(), !this.overlayDisplayState)
            .subscribe(
                (res) => {
                    this.overlayDisplayState = !this.overlayDisplayState;
                    this.callViewStateManagerService.eventUpdatesObj.displayLeaderboardResults =
                        this.overlayDisplayState;
                },
                (err) => {}
            );
    }

    getLeaderboardInfo() {
        /**
         * Get the leaderboard with list of attendees with their info and score
         * Start polling the cache when the leaderboard panel is open
         * End the polling when the panel is closed: ToBe handled in ngOnDestroy
         */
        this.leaderboardService.getLeaderboardInfo(this.callStateManagerService.getParentMeetingId()).subscribe(
            (res) => {
                this.leaderboard = res;
            },
            (err) => {
                this.toastrService.error('Failed to get Leaderboard');
            }
        );
    }

    async getLeaderboardScores() {
        return new Promise((resolve, reject) => {
            this.leaderboardService.getScore(this.callStateManagerService.getParentMeetingId()).subscribe(
                async (res) => {
                    this.attendeeScores = await this.orderLocalUserAtTop2(res);
                    this.setLocalUserRankOnResults(res);
                    this.checkForShareAchievement();
                    resolve(res);
                },
                (err) => {
                    this.toastrService.error('Failed to get leaderboard scores');
                    reject(err);
                }
            );
        });
    }

    checkForShareAchievement() {
        if (this.leaderboardService.localUserRank === -1 || !this.leaderboard?.resultsPublished) return;
        let achievedPrizeIndex = this.leaderboard.prizes.findIndex(
            (p) => p.prizeIndex === this.leaderboardService.localUserRank - 1
        );
        if (achievedPrizeIndex !== -1) {
            this.showShareAchievementButton = true;
            this.leaderboardService.achievedPrizeIndex = achievedPrizeIndex;
        }
    }

    orderLocalUserAtTop(_scoresList = []) {
        return new Promise(async (resolve, reject) => {
            if (_scoresList.length === 0) resolve(_scoresList);
            /**
             * Even if speaker joins as an attendee, then they'll be shown in the leaderboard.
             * Placing the local User entry at top of the list gives better UX.
             */
            const localUserIndex = this.getLocalUserIndex(_scoresList);
            if (localUserIndex !== -1) {
                const userScore = _scoresList[localUserIndex];
                userScore.score = Math.max(userScore.score, this.leaderboardService.localUserLatestScore);
                _scoresList.splice(localUserIndex, 1);
                _scoresList.splice(0, 0, userScore);
            } else if (_scoresList.length >= 200 && !this.callViewStateManagerService.isSpeaker) {
                await this.getCurrentUserScoreDistribution();
                const userScore = {
                    userId: this.currentUserId,
                    rank: `${this.getNonExistentUserRank(_scoresList.length)}`,
                    score: this.getScoreFromDistribution(),
                    name: `${this.userService.getUserNameFromUserInfo()}`
                };
                _scoresList.splice(0, 0, userScore);
            }
            resolve(_scoresList);
        });
    }

    getNonExistentUserRank(scoresLength = 0) {
        return scoresLength < 200 ? scoresLength + 1 : '200+';
    }

    getLocalUserIndex(_scoresList) {
        return _scoresList.findIndex((s) => s.userId === this.currentUserId);
    }

    getScoreFromDistribution() {
        let score = 0;
        this.scoreDistribution.forEach((a) => {
            score += +a.score;
        });
        return score;
    }

    async orderLocalUserAtTop2(list = []) {
        list = list.map((u, index) => {
            u.rank = index + 1;
            return u;
        });
        return this.orderLocalUserAtTop(list);
    }

    closeView() {
        this.close.emit({});
    }

    toggleScoreDistribution(idx) {
        if (this.showScoreDistributionInfoIdx === -1) {
            this.getCurrentUserScoreDistribution();
        }
        this.showScoreDistributionInfoIdx = this.showScoreDistributionInfoIdx === -1 ? idx : -1;
    }

    async handleScoringInfoClick() {
        this.leaderboard = await this.leaderboardService.getLeaderboardInfoObj(
            this.callStateManagerService.getParentMeetingId()
        );
        const scoringSystem = this.leaderboard.rewardActions;

        scoringSystem.map((a, idx) => {
            scoringSystem[idx] = {
                action: a.title,
                score: a.points
            };
        });
        this.leaderboard.rewardActions = scoringSystem;
        this.showScoringInfoTable = !this.showScoringInfoTable;
        // this.leaderboardService.getActionScores(this.callStateManagerService.getParentMeetingId()).subscribe((res) => {

        // }, (err) => {

        // });
    }

    goBack() {
        this.showScoringInfoTable = false;
    }

    bottomFooterButtonClicked() {
        if (!this.leaderboard?.resultsPublished) {
            this.confirmPublishResults();
        } else {
            this.handleLeaderboardOverlayClick();
        }
    }

    getProfilePic(userId) {
        return `${this.appService.getEnvVariable('BASE_URL')}/profilepic/${userId}/profile.png?random=${
            this.randomNumber
        }`;
    }

    onShareAchievementClick() {
        this.callViewStateManagerService.toggleCallOptionsPanel();
        this.callViewStateManagerService.showLeaderboardAchievement = true;
    }

    togglePrizeLargePreview(i = -1) {
        this.prizePreviewIndex = i;
    }

    ngOnDestroy() {
        clearInterval(this.leaderboardScoresInterval$);
    }

    nextLeaderboardScoresList() {
        const end = this.leaderboardScrollViewport?.getRenderedRange().end;
        const total = this.leaderboardScrollViewport?.getDataLength();
    }
}
