import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RecurringService {
    private previousFormData;
    constructor() {}

    setPreviousFormData(formData) {
        this.previousFormData = formData;
        console.log(this.previousFormData);
    }

    getPreviousFormData() {
        return this.previousFormData;
    }
}
