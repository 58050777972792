<div class="speaker-feed-studio" [class.speaker-studio-pip]="isPipMode">
    <div class="feed-section">
        <div class="live-feed" [class.h-100]="true" *ngIf="callViewStateManager.isBroadcasting">
            <app-studio-attendee-feed [isUpcoming]="false" [updateVersion]="updateVersion"></app-studio-attendee-feed>
        </div>
        <div class="upcoming-feed" [class.h-100]="true" *ngIf="!callViewStateManager.isBroadcasting">
            <app-studio-attendee-feed [isUpcoming]="true" [updateVersion]="updateVersion"></app-studio-attendee-feed>
        </div>
    </div>
    <!-- <div *ngIf="eventsPlusService.isPaused || eventsPlusService.isStopped" class="paused-section">
        <img
            class="paused-screen"
            [class.isPortrait]="isPortrait"
            *ngIf="eventsPlusService.isPaused"
            src="assets/img/jioconf/Session paused/Technology.png"
        />
        <img
            class="paused-screen"
            [class.isPortrait]="isPortrait"
            *ngIf="eventsPlusService.isStopped"
            src="assets/img/jioconf/welcome-slates/Technology.png"
        />
    </div> -->
    <div class="speaker-list" *ngIf="mediaStreamRetriever.videoService?.galleryParticipants.length > 0">
        <div class="speakers-view-header">
            <span class="title">Speakers In This Event</span>
            <!-- <span class="scene-speakers-cta-text">Show only speaker in this scene</span>
            <label class="toggle-switch">
                <input
                    type="checkbox"
                    [checked]="showSceneSpecificSpeakers"
                    (click)="toggleSpeakersView()"
                    id="toggleSpeakersViewSlider"
                />
                <span class="slider round" [class.checked]="showSceneSpecificSpeakers"></span>
            </label> -->
        </div>
        <app-speaker-gallery-view [source]="galleryViewSource"></app-speaker-gallery-view>
    </div>
</div>
