import { AfterViewInit, Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { AppService, RtcService, UtilService, VideoWrapperService } from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { CanvasManagerService } from 'src/app/core/services/canvas-manager.service';
import { EventsPlusService } from 'src/app/core/services/events-plus.service';
import { MarketplaceService } from 'src/app/core/services/marketplace.service';
import { MediaStreamRetrieverService } from 'src/app/core/services/media-stream-retriever.service';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';

@Component({
    selector: 'app-studio-attendee-feed',
    templateUrl: './studio-attendee-feed.component.html',
    styleUrls: ['./studio-attendee-feed.component.scss']
})
export class StudioAttendeeFeedComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() sceneData;
    @Input() isUpcoming = false;
    @Input() source;
    @Input() updateVersion;
    layoutData;
    loading: boolean = true;
    feedContainerId: string = '';
    wrapperId;
    subscriptions: any = [];
    videoService: any;
    cloudVideoPlaying: boolean;
    intevals: any[] = [];
    tickerMap: Map<string, any> = new Map();
    isMobileScreen: boolean = false;
    resizer = null;
    screenShareRetryCount: number;
    cloudVideoElementId: any;
    screenShareElementId: any;
    roomConnectionStatusInterval;
    constructor(
        public eventsPlusService: EventsPlusService,
        public callViewStateManagerService: CallViewStateManagerService,
        private appLoggerService: AppLoggerService,
        private videoWrapperService: VideoWrapperService,
        private mediaStreamRetreiver: MediaStreamRetrieverService,
        private utilService: UtilService,
        private canvasManagerService: CanvasManagerService,
        private webinarService: WebinarAttendeeService,
        private appService: AppService,
        private rtcService: RtcService
    ) {
        this.videoService = this.videoWrapperService.getVideoService();
        this.mediaStreamRetreiver.videoService = this.videoService;
    }

    async ngOnInit() {
        this.isMobileScreen = this.utilService.isResponsiveMobileView();
        this.setFeedContainerId();
        this.initializeSubscriptions();
        this.resizer = this.debounceResize(500);
    }

    ngAfterViewInit(): void {
        this.resizeLayout();
    }

    ngOnChanges(changes) {
        if (changes?.updateVersion && this.resizer) {
            this.resizer();
        }
    }

    setDimensionsOfContainer() {
        let wrapperElement = document.getElementById(this.wrapperId);
        let containerElement = document.getElementById(this.feedContainerId);
        // Check if we can occupy full height
        if (wrapperElement.clientHeight * (16 / 9) < wrapperElement.clientWidth) {
            containerElement.style.height = '100%';
            containerElement.style.maxWidth = '100%';
            containerElement.style.width = 'unset';
            containerElement.style.maxHeight = 'unset';
        } else {
            containerElement.style.width = '100%';
            containerElement.style.maxHeight = '100%';
            containerElement.style.height = 'unset';
            containerElement.style.maxWidth = 'unset';
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.resizer();
    }

    resizeLayout() {
        setTimeout(() => {
            this.setDimensionsOfContainer();
            this.updateElementStylesOnResize();
        });
    }

    debounceResize(duration) {
        let timer = null;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                // this.resizeLayout();
                this.setDimensionsOfContainer();
                this.updateElementStylesOnResize();
            }, duration);
        };
    }

    updateElementStylesOnResize() {
        this.sceneData?.elements.forEach((elementData, index) => {
            if (elementData.type === 'text') {
                // const feedElement = document.getElementById(`esf-${elementData.type}-${elementData.id}`);
                const feedElement = document.getElementById(`ese-${elementData.id}`);
                if (!feedElement) {
                    this.appLoggerService.log('Element not found');
                } else {
                    this.setElementPadding(feedElement, elementData);
                    this.setElementFontSize(feedElement, elementData);
                    // feedElement.style.fontSize = `${(elementData.dimension.height / 100) * parentContainer.clientHeight}px`;
                }
            } else if (elementData.type === 'userFeed') {
                const feedElements = document.getElementsByClassName(`ese-${elementData.id}`);
                this.setElementBorderStyle(feedElements[0], elementData);
            }
        });
    }

    initializeSubscriptions() {
        this.subscriptions.push(
            this.eventsPlusService.getCustomLayoutDetails().subscribe((customLayout) => {
                if (customLayout) {
                    if (
                        !this.layoutData ||
                        this.layoutData.lastPublishedTimeStamp !== customLayout?.lastPublishedTimeStamp
                    ) {
                        this.layoutData = customLayout;
                        console.log(this.layoutData);
                        let latestSceneData;

                        if (!this.isUpcoming) {
                            if (this.layoutData.publishingState) {
                                latestSceneData = this.layoutData.lastPublishedLayout;
                            }
                        } else {
                            latestSceneData = this.layoutData?.lastSavedLayout.scenesList[0];
                        }
                        this.handleSceneUpdate(latestSceneData);
                        // TODO: Only Change specific dom element if updated
                        // Store the last changed time of asset too?
                    }
                    // this.publishingState = customLayout.publishingState;
                    this.layoutData = customLayout;
                }
            }),
            this.videoService.participantStatusUpdated.subscribe((data) => {
                this.handleControlChange(data);
            }),
            this.videoService.getChatMessages().subscribe((event) => {
                switch (event?.message) {
                    case 'STUDIO_SCENE_UPDATE': {
                        this.callViewStateManagerService.getLatestEventUpdates();
                        break;
                    }
                }
            }),
            this.videoService.participantRemoved.subscribe((data) => {
                this.clearStreamElementByParticipant(data);
            }),
            this.mediaStreamRetreiver.getGalleryViewUpdatesEmitter().subscribe((event: any) => {
                switch (event.type) {
                    case 'participantAddition':
                        this.appLoggerService.log('participantAddition called', event.participantId);
                        // this.createMissingVideoElement(event.participantId);
                        this.playUserFeedById(event.participantId);
                        break;
                    case 'participantIdUpdate': {
                        this.clearStreamElementByUidType(event.data.userId, 'userFeed');
                        break;
                    }
                    case 'playFeedElements': {
                        this.playFeedElements();
                        break;
                    }
                    default:
                        break;
                }
            }),
            this.callViewStateManagerService.getViewStateObs().subscribe((viewState) => {
                this.resizeLayout();
            })
        );
        this.fetchRoomConnectionStatus();
    }

    fetchRoomConnectionStatus() {
        if (this.callViewStateManagerService.isSpeaker) return;
        this.getRoomConnectionStatus();
        this.roomConnectionStatusInterval = setInterval(async () => {
            await this.getRoomConnectionStatus();
        }, this.appService.getConfigVariable('FETCH_ATTENDEE_INTERVAL') || 5000);
    }

    async getRoomConnectionStatus() {
        const res = await this.webinarService
            .getRoomInfoForAttendee(this.callViewStateManagerService.webinarId)
            .toPromise();
        this.mediaStreamRetreiver.handleParticipantsListUpdate(res?.participants);
    }

    async handleSceneUpdate(latestSceneData) {
        await this.canvasManagerService.loadAssets(latestSceneData?.elements);
        if (!this.sceneData || this.sceneData.sceneNumber !== latestSceneData.sceneNumber) {
            this.handleStreamSubscriptions();
            this.sceneData = this.utilService.clone(latestSceneData);
            this.clearElements();
            this.appendElementsToDom();
        } else {
            this.sceneData?.elements.forEach((el) => {
                if (latestSceneData.elements.findIndex((element) => element.id === el.id) === -1) {
                    this.clearElementById(el);
                }
            });
            latestSceneData.elements.forEach((element) => {
                if (this.sceneData?.elements.findIndex((el) => el.id === element.id) === -1) {
                    this.appendDomElementByIndex(element);
                } else {
                    this.updateAssetElementStyles(element);
                }
            });
            this.sceneData = this.utilService.clone(latestSceneData);
            this.playFeedElements();
        }
        this.sceneData = this.utilService.clone(latestSceneData);
    }

    updateAssetElementStyles(elementData) {
        const elements = document.getElementsByClassName(`ese-${elementData.id}`);
        if (!elements || elements.length < 1) return;
        if (elements[0]) {
            this.addStylingToElement(elements[0], elementData);
            this.updateStreamFeed(elements[0], elementData);
            this.setElementContent(elements[0], elementData);
        }
    }

    setElementContent(domElement, elementData) {
        if (!domElement || !elementData) return;
        switch (elementData.type) {
            case 'text': {
                domElement.textContent = elementData.text;
                if (elementData.enableHyperLink) {
                    domElement.href = 'https://' + elementData.hyperLinkInput;
                    domElement.rel = 'noopener noreferrer';
                    domElement.target = '_blank';
                }
                break;
            }
            case 'image': {
                domElement.src = this.eventsPlusService.getUpdatedAkamaiUrl(elementData.imageUrl);
                break;
            }
            case 'video': {
                if (elementData.uploadType == 'background') {
                    domElement.src = this.eventsPlusService.getUpdatedAkamaiUrl(elementData.mediaUrl);
                }
            }
        }
    }

    updateStreamFeed(domElement, elementData) {
        if (
            elementData.type == 'userFeed' ||
            elementData.type == 'screenshare' ||
            elementData.uploadType == 'cloudvideo'
        ) {
            const newMediaEngineId = elementData.mediaEngineId;
            const type =
                elementData.type == 'video' && elementData.uploadType == 'cloudvideo' ? 'cloudvideo' : elementData.type;
            const oldMediaEngineId = this.getMediaEngineIdByAssetElement(domElement, type);
            if (!oldMediaEngineId || oldMediaEngineId == '' || newMediaEngineId == oldMediaEngineId) return;
            this.clearStreamElementByUidType(oldMediaEngineId, type);
        }
    }

    getMediaEngineIdByAssetElement(domElement: HTMLElement, type) {
        const childEl = domElement.firstElementChild;
        if (!childEl) return null;
        // stream-${participantId}-${type}
        const regxPattern = new RegExp(`^stream-(.*)-${type}$`);
        return childEl.id?.match(regxPattern)[1];
    }

    clearTickerMap() {
        this.tickerMap.clear();
    }

    clearElementById(elementData) {
        try {
            if (elementData.type == 'text' && elementData.enableTicker && this.tickerMap.has(elementData.id)) {
                this.tickerMap.delete(elementData.id);
            }
            if (elementData.type == 'userFeed') {
                this.mediaStreamRetreiver.unsubscribeStreamByUid(
                    this.mediaStreamRetreiver.getMediaEngineIdByUserId(elementData.mediaEngineId)
                );
            } else if (elementData.type == 'screenshare') {
                this.mediaStreamRetreiver.unsubscribeScreenshare();
            } else if (elementData.type == 'cloudvideo') {
                this.mediaStreamRetreiver.unsubscribeCloudvideo();
            }
            const elToRemove = document.getElementById(`ese-${elementData.id}`);
            if (!elToRemove) {
                this.appLoggerService.log('Trying to element which is not found', elementData.id);
                return;
            }
            elToRemove.remove();
        } catch (err) {
            this.appLoggerService.log('Error: ClearElementById', err);
        }
    }

    async playFeedElements() {
        this.appLoggerService.log('playFeedElements called');
        /**
         * UserFeed, Screen Share, and cloud video streams need to be subscribed
         * After the dom elements are appended to container
         */
        if (!this.layoutData) return;
        this.sceneData?.elements.forEach(async (el) => {
            if (el.type === 'userFeed') {
                this.playUserFeedById(this.mediaStreamRetreiver.getMediaEngineIdByUserId(el.mediaEngineId));
            } else if (el.type === 'screenshare') {
                const screenshareUid = this.mediaStreamRetreiver.getScreenshareUid();
                this.playUserFeedById(screenshareUid, 'screenshare');
            } else if ((el.type === 'video' && el.uploadType === 'cloudvideo') || el.type === 'cloudvideo') {
                const cloudVideoId = this.mediaStreamRetreiver.getCloudVideoUid();
                this.playUserFeedById(cloudVideoId, 'cloudvideo');
            }
        });
    }

    async stopFeedElementByMediaEngineId(id) {
        /**
         * Check if any user feeds are playing
         * Check if screen share or cloud video is being played
         */
    }

    async handleControlChange(event) {
        console.log('Control Change Event ', event);
        switch (event.type) {
            case 'userStreamPublished':
                this.appLoggerService.log('userStreamPublished called');
                this.playUserFeedById(
                    event.data.uid,
                    // this.mediaStreamRetreiver.isCloudVideoUid(event.data.uid) ? 'cloudvideo' : 'userFeed'
                    event?.data?.role == 'CPBot' ? 'cloudvideo' : 'userFeed'
                );
                break;
            case 'localUserStreamPublished':
                this.appLoggerService.log('localUserStreamPublished called');
                this.playUserFeedById(event.data.id, 'userFeed');
                break;
            case 'locaUserCameraChange': {
                const videoFeedEl = document.getElementById(
                    `stream-${this.mediaStreamRetreiver.getUserIdByMediaEngineId(event.data.id)}-userFeed`
                );
                if (videoFeedEl) {
                    videoFeedEl.remove();
                    await this.utilService.holdExecution(0);
                    this.playUserFeedById(event.data.id, 'userFeed');
                }
                break;
            }
            case 'localUserStreamUnPublished':
            case 'videoOff':
                this.clearStreamElementByParticipant(event.data);
                break;
            case 'screenSharingStarted':
                this.handleScreenShareStarted(event);
                break;
            case 'screenSharingStopped':
                this.clearStreamElementByParticipant(event.data, 'screenshare');
                break;
        }
    }

    handleScreenShareStarted(event) {
        if (this.rtcService.isJMMeeting ? event?.data?.hasScreenShareVideo : event?.data?.hasVideo) {
            this.playUserFeedById(event.data.uid, event?.data?.role == 'CPBot' ? 'cloudvideo' : 'screenshare');
        } else {
            this.playUserFeedById(event.data.uid, 'screenshare');
        }
    }

    clearStreamElementByParticipant(user, type = null) {
        try {
            const uid = user.uid || user.id;
            const feedType = type ?? (user.role == 'CPBot' ? 'cloudvideo' : 'userFeed');
            this.clearStreamElementByUidType(this.mediaStreamRetreiver.getUserIdByMediaEngineId(uid), feedType);
            if (type == 'screenshare') {
                this.mediaStreamRetreiver.unsubscribeScreenshare();
            } else if (type == 'cloudvideo') {
                this.mediaStreamRetreiver.unsubscribeCloudvideo();
            } else {
                this.mediaStreamRetreiver.unsubscribeStreamByUid(uid);
            }
        } catch (err) {
            this.appLoggerService.log('Error while clearing stream element', user, type);
        }
    }

    clearStreamElementByUidType(userId, feedType) {
        const streamElement = document.getElementById(
            `stream-${feedType == 'userFeed' ? `${userId}-` : ''}${feedType}`
        );
        if (!streamElement) {
            this.appLoggerService.log('Stream Element not found,', userId, feedType);
            return;
        }
        streamElement.parentElement.style.display = 'none !important';
        // this.mediaStreamRetreiver.unsubscribeStreamById(uid);
        streamElement.remove();
    }

    handleVideoOff(event) {
        const uid = event.data.uid || event.data.id;
        const pVidEl = document.getElementById(`stream-${uid}`);
        pVidEl.remove();
    }

    handleEventStatusUpdates(updates) {
        // if (updates.cloudVideoMediaPayload) {
        //     this.playCloudVideo();
        // } else {
        //     this.cloudVideoPlaying = false;
        // }
    }

    setFeedContainerId() {
        this.feedContainerId = `attendeeStudioFeedContainer-${this.sceneData?.sceneNumber || 1}-${
            this.isUpcoming ? 'up' : 'li'
        }`;
        this.wrapperId = `attendeeFeedWrapper-${this.sceneData?.sceneNumber || 1}-${this.isUpcoming ? 'up' : 'li'}`;
        this.cloudVideoElementId = `cloud-video-feed-ol-${this.isUpcoming ? 'up' : 'li'}`;
        this.screenShareElementId = `screen-share-feed-ol-${this.isUpcoming ? 'up' : 'li'}`;
        // User Feed or cloud vi `uf-${elementData.mediaEngineId}-${this.isUpcoming ? 'up' : 'li'}`
    }

    clearElements() {
        this.clearTickerMap();
        let parentNode = document.getElementById(this.feedContainerId);
        while (parentNode?.firstChild) {
            parentNode.removeChild(parentNode.firstChild);
        }
    }

    async appendElementsToDom() {
        const canvasContainer = document.getElementById(this.feedContainerId);
        if (!canvasContainer) {
            setTimeout(() => {
                this.appendElementsToDom();
            }, 1000);
            return;
        }
        this.sceneData?.elements.forEach((elementData) => {
            this.appendDomElementByIndex(elementData);
        });
        this.playFeedElements();
    }

    appendDomElementByIndex(elementData) {
        const canvasContainer = document.getElementById(this.feedContainerId);
        // if (!this.shouldSkipElement(elementData) && elementData.visible) {
        if (elementData.visible) {
            const newFeedElement = this.getNewHtmlElementByAsset(elementData);
            newFeedElement.classList.add(`ese-${elementData.id}`);
            canvasContainer.appendChild(newFeedElement);
        }
    }

    shouldSkipElement(element) {
        if (element.type === 'userFeed' && element.mediaEngineId === '') return true;
    }

    getNewHtmlElementByAsset(elementData) {
        let newFeedElement;
        let elementType = elementData.type;
        if (elementType === 'image' || elementType === 'slideshow') {
            newFeedElement = document.createElement('img');
            newFeedElement.src = this.eventsPlusService.getUpdatedAkamaiUrl(elementData.imageUrl);
            // newFeedElement.setAttribute('id', `esf-${elementData.type}-${elementData.id}`);
        } else if (elementType === 'text') {
            newFeedElement = document.createElement('a');
            newFeedElement.textContent = elementData.text;
            if (elementData.enableHyperLink) {
                newFeedElement.href = 'https://' + elementData.hyperLinkInput;
                newFeedElement.rel = 'noopener noreferrer';
                newFeedElement.target = '_blank';
            }
            // newFeedElement.setAttribute('id', `esf-${elementData.type}-${elementData.id}`);
        } else if (elementType === 'userFeed') {
            newFeedElement = this.createUserFeedElement(elementData);
        } else if (elementType === 'screenshare') {
            // newFeedElement = this.createScreenShareElement();
            newFeedElement = this.createUserFeedElement(elementData);
        } else if (elementType === 'video' && elementData.uploadType === 'cloudvideo') {
            newFeedElement = this.createUserFeedElement(elementData);
            // newFeedElement = document.createElement('video');
            // newFeedElement.setAttribute('id', this.cloudVideoElementId);
        } else if (elementType === 'video' && elementData.uploadType === 'background') {
            newFeedElement = this.createVideoElement(elementData);
        }
        if (!newFeedElement) {
            this.appLoggerService.log('Unknown Feed Element');
            return;
        }
        this.addStylingToElement(newFeedElement, elementData);
        return newFeedElement;
    }

    createScreenShareElement() {
        const newFeedElement = document.createElement('video');
        // newFeedElement.setAttribute('id', this.screenShareElementId);
        return newFeedElement;
    }

    createVideoElement(elementData) {
        const newFeedElement = document.createElement('video');
        return newFeedElement;
    }

    createUserFeedElement(elementData) {
        const newFeedElement = document.createElement('div');
        // newFeedElement.setAttribute('id', `uf-${elementData.mediaEngineId}-${this.isUpcoming ? 'up' : 'li'}`);
        return newFeedElement;
    }

    addStylingToElement(newFeedElement, elementData) {
        newFeedElement.setAttribute('id', `ese-${elementData.id}`);
        const { width, height } = elementData.dimension;
        const { top, left } = elementData.position;
        newFeedElement.style.position = 'absolute';

        newFeedElement.style.top = `${top}%`;
        newFeedElement.style.left = `${left}%`;

        newFeedElement.style.maxWidth = 'unset';
        newFeedElement.style.maxHeight = 'unset';

        this.addStylingBasedOnType(newFeedElement, elementData);

        if (elementData.type !== 'text') {
            newFeedElement.style.width = `${width}%`;
            newFeedElement.style.height = `${height}%`;
        }
        newFeedElement.style.transform = `translate(0, 0) rotate(${elementData.rotation}deg)`;
    }

    addStylingBasedOnType(newFeedElement, elementData) {
        switch (elementData.type) {
            case 'text': {
                this.addFontStyleToTextElement(newFeedElement, elementData);
                break;
            }
            case 'video': {
                if (elementData.uploadType == 'cloudvideo') {
                    newFeedElement.style.objectFit = 'cover';
                    newFeedElement.style.overflow = 'hidden';
                    newFeedElement.style.display = 'none !important';
                    this.setElementBorderStyle(newFeedElement, elementData);
                } else {
                    // newFeedElement.style.borderRadius = `${elementData?.borderRadius || 0}px`;
                    newFeedElement.style.objectFit = 'cover';
                    newFeedElement.setAttribute('playsinline', '');
                    newFeedElement.poster = this.eventsPlusService.getUpdatedAkamaiUrl(elementData.placeholderImageUrl);
                    newFeedElement.src = this.eventsPlusService.getUpdatedAkamaiUrl(elementData.mediaUrl);
                    newFeedElement.autoplay = true;
                    newFeedElement.loop = true;
                    newFeedElement.muted = true;
                    newFeedElement.play();
                }
            }
            case 'screenshare':
            case 'userFeed': {
                newFeedElement.style.objectFit = 'cover';
                newFeedElement.style.overflow = 'hidden';
                newFeedElement.style.display = 'none !important';
                this.setElementBorderStyle(newFeedElement, elementData);
                break;
            }
            case 'image':
            case 'slideshow': {
                newFeedElement.style.padding = '1px';
                break;
            }
        }
    }

    setElementBorderStyle(newFeedElement, elementData) {
        if (elementData.type !== 'userFeed') return;
        if (!elementData.borderColor?.includes('gradient')) {
            newFeedElement.style.border = `${elementData.borderWidth}px solid ${elementData.borderColor}`;
        } else if (elementData.borderColor?.includes('gradient')) {
            newFeedElement.style.background = elementData.borderColor;
        }
        if (elementData.borderWidth) {
            newFeedElement.style.padding = `${this.canvasManagerService.getAbsoluteDimensionWrtoPlayground(
                elementData.borderWidth,
                'y',
                this.feedContainerId
            )}px`;
        }
        if (elementData.borderRadius) {
            newFeedElement.style.borderRadius = `${
                this.canvasManagerService.getAbsoluteDimensionWrtoPlayground(
                    elementData.borderRadius,
                    'y',
                    this.feedContainerId
                ) || 0
            }px`;
        }
    }

    addFontStyleToTextElement(newFeedElement, elementData) {
        if (elementData.fontFamilyUrl) {
            this.utilService.getFontByUrl(elementData.fontFamilyUrl);
        }
        if (elementData.gradient.enable) {
            newFeedElement.style.background = elementData.gradient.color;
            newFeedElement.style.webkitTextFillColor = 'transparent';
            newFeedElement.style.backgroundClip = 'text';
            newFeedElement.style.webkitBackgroundClip = 'text';
        }
        if (elementData.effects?.filters) {
            newFeedElement.style.filter = elementData.effects?.filters;
        }
        if (elementData.backgroundColor) {
            newFeedElement.style.background = elementData.backgroundColor;
        }
        this.setElementPadding(newFeedElement, elementData);
        newFeedElement.style.fontWeight = elementData.fontWeight;
        newFeedElement.style.fontFamily = elementData.fontFamily;
        newFeedElement.style.opacity = elementData.opacity / 100;

        this.setElementFontSize(newFeedElement, elementData);
        newFeedElement.style.color = elementData.fontColor;
        newFeedElement.style.width = `${elementData.dimension.width}%`;

        if (elementData.enableTicker && !elementData.tickerEnabled) {
            // const interval = setInterval(() => {
            //     if (newFeedElement.getBoundingClientRect().right < 0) {
            //         newFeedElement.style.left = `100%`;
            //     } else {
            //         newFeedElement.style.left = `${parseFloat(newFeedElement.style.left) - 0.5}%`;
            //     }
            // }, 100);

            // this.tickerMap.set(elementData.id, interval);

            // Function to animate the element
            const animateElement = (element) => {
                const moveElement = () => {
                    if (element.getBoundingClientRect().right < 0) {
                        element.style.left = `100%`;
                    } else {
                        element.style.left = `${parseFloat(element.style.left) - 0.1}%`;
                    }
                    if (this.tickerMap.has(elementData.id)) {
                        requestAnimationFrame(moveElement);
                    }
                };
                moveElement();
            };

            if (!this.tickerMap.has(elementData.id)) {
                if (!newFeedElement.style.left) {
                    newFeedElement.style.left = '100%';
                }
                this.tickerMap.set(elementData.id, true);
                animateElement(newFeedElement);
            }
        }
        if (elementData.enableHyperLink) {
            newFeedElement.style.textDecoration = 'underline';
            newFeedElement.style.cursor = 'pointer';
        }
        newFeedElement.style.textAlign = elementData.textAlign;
    }

    setElementFontSize(newFeedElement, elementData) {
        if (elementData.type !== 'text') return;
        const parentContainer = document.getElementById(this.feedContainerId);
        // const parentContainer = document.getElementById(`ese-${elementData.id}`);
        newFeedElement.style.fontSize = `${(elementData.fontSize / 100) * parentContainer.clientHeight}px`;
    }

    setElementPadding(newFeedElement, elementData) {
        if (!elementData.padding) return;
        newFeedElement.style.padding = `${this.getRelativePadding(
            'top',
            elementData.padding
        )}px ${this.getRelativePadding('right', elementData.padding)}px ${this.getRelativePadding(
            'bottom',
            elementData.padding
        )}px ${this.getRelativePadding('left', elementData.padding)}px `;
    }

    getRelativePadding(side, padding) {
        return this.canvasManagerService.getAbsoluteDimensionWrtoPlayground(padding[side], 'y', this.feedContainerId);
    }

    async playUserFeedById(participantId: string, type = 'userFeed') {
        this.appLoggerService.log('Gallery Participants', this.mediaStreamRetreiver.videoService.galleryParticipants);
        this.appLoggerService.log('playUserFeedById called', participantId);
        if (participantId === '' || !participantId) {
            this.appLoggerService.log('Empty Participant Id');
            return;
        }
        const videoFeedEl = document.getElementById(
            `stream-${this.mediaStreamRetreiver.getUserIdByMediaEngineId(participantId)}-${type}`
        );
        if (videoFeedEl) {
            this.appLoggerService.log('Duplicate: Trying to play existing stream');
            return;
        }
        try {
            const feedElementData = this.getElementByMediaEngineId(participantId, type);
            this.appLoggerService.log('Feed Element Data', feedElementData);
            if (!feedElementData) return;
            var remoteVideoElement = document.getElementById(`ese-${feedElementData.id}`) as HTMLVideoElement;
            this.appLoggerService.log('Is remote Video Element present', !!remoteVideoElement);
            var remoteVideoStream = await this.mediaStreamRetreiver.getOrCreateMediaStreamClone(participantId, type);
            this.appLoggerService.log('Got remoteVideo Stream', remoteVideoStream);
            if (!remoteVideoStream) return;
            const videoEl = this.getVideoContainerElement(feedElementData, participantId, type);
            remoteVideoElement.appendChild(videoEl);
            this.playVideoStream(videoEl, remoteVideoStream);
            this.appLoggerService.log('Played the element');
            remoteVideoElement.style.display = 'block !important';
        } catch (err) {
            this.appLoggerService.error('Error while playing user feed', err);
        }
    }

    getVideoContainerElement(feedElementData, participantId, type) {
        const videoEl = document.createElement(
            this.mediaStreamRetreiver.playAsStream || !this.rtcService.isJMMeeting ? 'video' : 'div'
        );
        videoEl.style.width = '100%';
        videoEl.style.height = '100%';
        videoEl.style.borderRadius = `${
            this.canvasManagerService.getAbsoluteDimensionWrtoPlayground(
                feedElementData.borderRadius,
                'y',
                this.feedContainerId
            ) || 0
        }px`;
        videoEl.setAttribute('playsinline', '');
        if (type == 'userFeed') {
            videoEl.setAttribute('id', `stream-${feedElementData.mediaEngineId}-${type}`);
            videoEl.style.objectFit = 'cover';
        } else {
            videoEl.setAttribute('id', `stream-${type}`);
            videoEl.style.objectFit = 'contain';
        }
        return videoEl;
    }

    playVideoStream(videoEl, remoteVideoStream) {
        if (this.rtcService.isJMMeeting && !this.mediaStreamRetreiver.playAsStream) {
            remoteVideoStream.play(videoEl, { mirror: false, fit: 'contain' });
        } else {
            videoEl.srcObject = remoteVideoStream;
            videoEl.play().catch((err) => {
                try {
                    videoEl.remove();
                    this.appLoggerService.log('Got Error while playing stream element', err);
                } catch (err) {
                    this.appLoggerService.log('Failed to remove the element', err);
                }
            });
        }
    }

    getElementByMediaEngineId(mEId, type) {
        if (type == 'cloudvideo') {
            return this.sceneData?.elements.find((el) => el.type === 'video' && el.uploadType === 'cloudvideo');
        } else if (type == 'screenshare') {
            return this.sceneData?.elements.find((el) => el.type === 'screenshare');
        } else {
            return this.sceneData?.elements.find(
                (el) => this.mediaStreamRetreiver.getMediaEngineIdByUserId(el.mediaEngineId) == mEId
            );
        }
    }

    handleStreamSubscriptions() {}

    ngOnDestroy() {
        this.intevals.forEach((inteval) => {
            clearInterval(inteval);
        });
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
