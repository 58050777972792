import { OnInit, Component, ElementRef, Input, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { VideoWrapperService, RtcService } from 'src/app/core';
import { Microphone, Speaker } from 'src/app/shared/interfaces/audio-settings';
import DecibelMeter from 'decibel-meter';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { PREPARATORY, NETWORKING_LOUNGE, PREPARATORY_SETTINGS } from 'src/app/constants';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { NetworkingRoomsService } from 'src/app/core/services/networking-rooms.service';

export enum DEFAULT_IO {
    DEFAULT = 'default',
    COMMUNICATIONS = 'communications'
}
@Component({
    selector: 'app-audio-settings',
    templateUrl: './audio-settings.component.html',
    styleUrls: ['./audio-settings.component.scss']
})
export class AudioSettingsComponent implements OnInit, OnDestroy {
    @Input() isMobile;
    @Input() route: String = 'dashboard';
    @Input() networkingRoom: any;
    @Input() settingPopup: string = 'popup';
    private videoService;
    public availableAudio: {
        speakers: Speaker[];
        mics: Microphone[];
    } = {
        speakers: [],
        mics: []
    };
    public selectedSpeakerId;
    public selectedMicId;
    public selectedSpeaker: Speaker;
    public selectedMic: Microphone;
    public meter = new DecibelMeter();
    public meterForSpeaker = new DecibelMeter();

    public testingControl = {
        speaker: false,
        mic: false
    };

    private timeoutId;
    turnOffAudio = this.userService.getUserSync()?.microphoneOff;

    @ViewChild('audioOutput') audioOutputSelect: ElementRef<HTMLSelectElement>;
    @ViewChildren('level') micLevels: QueryList<ElementRef>;
    @ViewChildren('audioOutputLevel') audioLevels: QueryList<ElementRef>;
    subsciption: Subscription[] = [];
    constructor(
        private videoWrapperService: VideoWrapperService,
        public rtcService: RtcService,
        private userService: UserService,
        private toasterService: ToastrService,
        private googleTagManager: GoogleTagMangerService,
        private networkingRoomsService: NetworkingRoomsService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPrepScreen();
    }

    ngOnInit() {
        this.subsciption.push(
            this.videoService.getParticipantStatus().subscribe((status) => {
                if (!status) return;
                this.timeoutId = setTimeout(() => {
                    this.availableAudio = {
                        speakers: this.videoService.speakers,
                        mics: this.videoService.microphones
                    };
                    this.selectedMicId = this.videoService.selectedLocalMicrophone;
                    this.selectedSpeakerId = this.videoService.selectedLocalSpeaker;
                    this.selectedSpeaker = this.availableAudio.speakers.find(
                        (speaker) => speaker.id === this.selectedSpeakerId
                    );
                    this.selectedMic = this.availableAudio.mics.find((mic) => mic.id === this.selectedMicId);
                }, 100);
            })
        );
    }

    inputVolume(state) {
        let amountOfLevels;
        let range;
        this.micLevels.map((level) => {
            level.nativeElement.style.backgroundColor = '#e6e7e8';
        });
        amountOfLevels = Math.round(state / 10);
        range = this.micLevels.toArray().slice(0, amountOfLevels);
        range.map((el: ElementRef) => {
            el.nativeElement.style.backgroundColor = '#69ce2b';
        });
    }
    outputSpeakerVolume(state) {
        let amountOfLevels;
        let range;
        this.audioLevels.map((level) => {
            level.nativeElement.style.backgroundColor = '#e6e7e8';
        });
        amountOfLevels = Math.round(state / 10);
        range = this.audioLevels.toArray().slice(0, amountOfLevels);
        range.map((el: ElementRef) => {
            el.nativeElement.style.backgroundColor = '#69ce2b';
        });
    }

    async selectSpeaker() {
        try {
            var audio = document.getElementById('testAudio') as any;
            await audio?.setSinkId(this.selectedSpeaker.id);
            this.videoService.manuallyChangeSpeaker(this.selectedSpeaker);
            let eventData = PREPARATORY_SETTINGS.SPEAKER_SELECT;
            eventData.clicked_text = this.selectedSpeaker.name;
            this.googleTagManager.pushGoogleTagData(eventData);
        } catch (err) {
            this.toasterService.error('Error in switching device.');
        }
    }
    selectMicrophone() {
        if (this.settingPopup == 'popup') {
            let eventTypePrepSettings = PREPARATORY_SETTINGS.MIC_SELECT;
            eventTypePrepSettings.clicked_text = this.selectedMic?.name ? this.selectedMic?.name : 'Mic Selected';
            this.googleTagManager.pushGoogleTagData(eventTypePrepSettings);
        } else {
            let eventTypeMain = PREPARATORY.MIC_SELECT;
            let eventTypeNw = NETWORKING_LOUNGE.MIC_SELECT;
            eventTypeMain.clicked_text = this.selectedMic?.name ? this.selectedMic?.name : 'Mic Selected';
            eventTypeNw.clicked_text = this.selectedMic?.name ? this.selectedMic?.name : 'Mic Selected';
            this.networkingRoom
                ? this.googleTagManager.pushGoogleTagData(eventTypeNw)
                : this.googleTagManager.pushGoogleTagData(eventTypeMain);
        }
        this.testingControl = { ...this.testingControl, mic: false };
        this.videoService.manuallyChangeMicrophone(this.selectedMic);
    }
    testMic() {
        let eventData = PREPARATORY_SETTINGS.TEST_MIC;
        if (this.testingControl.mic) {
            this.testingControl = { ...this.testingControl, mic: false };
            eventData.clicked_text = 'stop_mic';
            this.googleTagManager.pushGoogleTagData(eventData);
            return;
        }
        this.meter.stopListening();
        this.testingControl = { ...this.testingControl, mic: true };
        let decibals = 0;
        this.meter = new DecibelMeter('mictest');
        this.meter.connectTo(this.selectedMic.id);
        this.meter.listenTo(this.selectedMic.id, (dB, percent) => {
            decibals = Number(`${dB}`) + 100;
            this.inputVolume(decibals);
        });
        eventData.clicked_text = 'test_mic';
        this.googleTagManager.pushGoogleTagData(eventData);
    }

    testSpeaker() {
        var audio = document.getElementById('testAudio') as HTMLAudioElement;
        let eventData = PREPARATORY_SETTINGS.TEST_SPEAKER;
        if (this.testingControl.speaker) {
            audio.pause();
            this.testingControl.speaker = false;
            eventData.clicked_text = 'stop_speaker';
            this.googleTagManager.pushGoogleTagData(eventData);
            return;
        }
        audio.play();
        this.testingControl.speaker = true;
        this.meterForSpeaker.stopListening();
        this.testingControl = { ...this.testingControl, speaker: true };
        let decibals = 0;
        this.meterForSpeaker = new DecibelMeter('speakertest');
        this.meterForSpeaker.connectTo(this.selectedMic.id);
        this.meterForSpeaker.listenTo(this.selectedSpeaker.id, (dB, percent) => {
            decibals = Number(`${dB}`) + 110;
            this.outputSpeakerVolume(decibals);
        });
        eventData.clicked_text = 'start_speaker';
        this.googleTagManager.pushGoogleTagData(eventData);
    }

    onTurnOffAudio(value) {
        const isTurnOffAudio = value.checked;
        let eventData = PREPARATORY_SETTINGS.MIC_OFF_INCOMING_CALL;
        value.target.checked ? (eventData.outcome = 'enabled') : (eventData.outcome = 'disabled');
        this.googleTagManager.pushGoogleTagData(eventData);
        this.userService
            .updateProfile({
                microphoneOff: isTurnOffAudio
            })
            .subscribe(() => {});
    }

    ngOnDestroy() {
        this.meter.stopListening();
        this.subsciption.map((s) => s.unsubscribe());
        clearTimeout(this.timeoutId);
    }
}
