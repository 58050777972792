import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-conference-preloader',
    templateUrl: './conference-preloader.component.html',
    styleUrls: ['./conference-preloader.component.scss']
})
export class ConferencePreloaderComponent implements OnInit {
    @Input() title;
    constructor() {}

    ngOnInit(): void {}

    getTitle() {
        return this.title || 'Joining Event....';
    }
}
