import { Injectable } from '@angular/core';

import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService } from '.';
import { genericRetryStrategy } from '../helpers/generic-retry-strategy';
import { LoggerService } from './logger.service';

export interface SocketEvent {
    event: string;
    data?: any;
}
export interface SocketConfig {
    url: string;
    jwt: string;
    heartBeatRate: number;
}

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private socket: WebSocket = null;
    private state: 'connected' | 'none' = 'none';

    private data$: Subject<SocketEvent> = new Subject();

    private isManualDisconnect = false;
    private presenceIntervalSubscription: Subscription;
    private socketSubscriber;
    private config: SocketConfig;
    private newSocketConnectionEstablished: boolean = false;

    constructor(private loggerService: LoggerService, private eventEmitterService: EventEmitterService) {}

    connect({ url, jwt, heartBeatRate }) {
        this.disconnect(false);
        this.isManualDisconnect = false;
        this.config = { url, jwt, heartBeatRate };
        this.socketSubscriber = new Observable((observer) => {
            if (this.config?.url) {
                this.socket = new WebSocket(this.config?.url);
                this.socket.onopen = this.handler.bind(this, observer);
                this.socket.onmessage = this.handler.bind(this, observer);
                this.socket.onerror = this.handler.bind(this, observer);
                this.socket.onclose = this.handler.bind(this, observer);
            }
        })
            .pipe(
                catchError((err) => {
                    if (this.isManualDisconnect) {
                        return of(err);
                    } else {
                        throw err;
                    }
                }),
                retryWhen(genericRetryStrategy({ maxRetryAttempts: 13 }))
            )
            .subscribe(
                (res) => {},
                (err) => {
                    this.loggerService.error('SocketService - socket dead');
                    this.state = 'none';
                },
                () => {
                    this.state = 'none';
                    this.loggerService.warn('SocketService : Socket completed triggered');
                }
            );
    }

    send(data: SocketEvent) {
        try {
            if (this.socket) {
                this.socket.send(JSON.stringify(data));
            }
        } catch (e) {
            if (this.socket) {
                this.reconnect();
            }
        }
    }

    reconnect() {
        if (this.state === 'none' && this.config) {
            this.connect(this.config);
        }
    }

    disconnect(manually = true) {
        this.isManualDisconnect = manually;
        if (this.socketSubscriber) {
            this.socketSubscriber.unsubscribe();
        }
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
        this.stopHeartbeat();
    }

    get dataEvents$() {
        return this.data$;
    }

    private handler(observer, message: MessageEvent) {
        if (['open', 'close', 'error'].includes(message.type)) {
            if (message.type === 'open' && this.config?.jwt) {
                this.isManualDisconnect = false;
                this.send({
                    event: 'login',
                    data: {
                        token: this.config?.jwt
                    }
                });
                this.newSocketConnectionEstablished = true;
            } else if (message.type === 'close' || message.type === 'error') {
                this.newSocketConnectionEstablished = false;
                observer?.error(message);
            }
        } else if (message.data) {
            const data = JSON.parse(message.data);
            this.send({
                event: 'ack',
                data: {
                    originalEvent: data.event,
                    timestamp: new Date().getTime()
                }
            });

            if (data.event === 'login') {
                if (data.data.message === 'Authorized') {
                    this.state = 'connected';
                    if (this.newSocketConnectionEstablished) {
                        this.eventEmitterService.emit({ type: APP_EVENTS.SOCKET_RECONNECTED, data: null });
                        this.newSocketConnectionEstablished = false;
                    }
                    this.sendHeartbeat();
                } else {
                    observer?.error(message);
                }
            } else {
                this.data$.next(data);
            }
        }
    }

    private sendHeartbeat() {
        if (this.presenceIntervalSubscription || !this.config?.jwt) {
            return;
        }
        this.presenceIntervalSubscription = interval(this.config?.heartBeatRate || 120000).subscribe(() => {
            this.send({
                event: 'amAlive',
                data: {
                    token: this.config?.jwt
                }
            });
        });
    }

    private stopHeartbeat() {
        if (this.presenceIntervalSubscription) {
            this.presenceIntervalSubscription.unsubscribe();
            this.presenceIntervalSubscription = null;
        }
    }

    dispose() {
        this.disconnect();
        this.config = null;
    }
}
