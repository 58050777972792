<div
    class="participants-input"
    outside
    (outside)="toggleMenu(false)"
    (click)="toggleMenu(true)"
    [class.disabled]="!category"
    (keydown.enter)="toggleMenu(false)"
>
    <div class="input-wrap">
        <span class="participants">
            <span
                class="participant"
                *ngFor="let tag of tags | keyvalue: originalOrder"
                [class.is-invalid]="!tag?.value?.valid"
            >
                <span class="text-lowercase"> {{ tag?.value?.tag }}</span>
                <span class="close" (click)="removeTag(tag)">
                    <img src="assets/img/webinar/close-tag.svg" *ngIf="tag?.value?.valid" />
                    <img src="assets/img/webinar/close-circle-red.svg" *ngIf="!tag?.value?.valid" />
                </span>
            </span>
            <!-- <span class="participant is-invalid" *ngIf="invalidTag">
        <span class="text-lowercase text-danger"> {{invalidTag}}</span>
        <span class="close" (click)="removeInvalidTag()">
        </span>
      </span> -->
            <input
                type="text"
                class="input tags-new-input"
                placeholder="Add tags"
                [(ngModel)]="searchTerm"
                (input)="searchTags()"
                *ngIf="!(tags?.size >= maxTags)"
            />
        </span>
    </div>
    <div class="users bordered p-4" *ngIf="loading">
        <app-loader></app-loader>
    </div>
    <div class="users bordered pointer" *ngIf="!loading && tagsSuggestion?.length > 0 && openSelectMenu">
        <div class="user" *ngFor="let tag of tagsSuggestion" (click)="selectTag(tag)">
            <div class="info">
                <div class="name text-lowercase">{{ tag }}</div>
            </div>
        </div>
    </div>
</div>
