import { Injectable } from '@angular/core';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { EventUserRole } from 'src/app/constants/webinar-enum';
@Injectable({
    providedIn: 'root'
})
export class EventSessionsService {
    sessionsInfo = [];
    webinarDetails: any;
    recurrenceType: string = 'daily';
    repeatOptions: any;
    recurrenceInterval: number = 1;
    occurrences: Date[] = [];
    targetDate: Date | null = null;
    viewDate = new Date();
    viewEndDate: Date;
    isRecurring: boolean;
    selectedDaysIndexes: number[];

    constructor(private webinarService: WebinarService) {}

    async getSessionsInfo(webinarId, userId) {
        this.webinarService.getWebinarSessionsInfo(webinarId, !userId).subscribe(
            (res) => {
                if (res?.sessions?.length) {
                    console.log(res, 'sessions list');
                    this.sessionsInfo = res?.sessions || [];
                    // get host info of this webinar
                    const hostDetail = res.speakers?.find((speaker) => speaker.role === EventUserRole.HOST);
                    this.webinarDetails = {
                        ...res.meetingDetails,
                        hostInfo: hostDetail,
                        speakers: res.speakers
                    };
                    // this.prepareSpeakersDetails();
                    // filter my sessions
                    // first check if i m invited as speaker
                    // const meAsSpeakerInfo = this.webinarDetails?.speakers?.find(
                    //     (speaker) => speaker.userId && speaker.userId === this.currentUser._id
                    // );
                    // if (meAsSpeakerInfo) {
                    //     this.userSessions = this.sessionsInfo.filter((session) => {
                    //         return session.invites?.indexOf(meAsSpeakerInfo._id) !== -1;
                    //     });

                    //     if (this.userSessions?.length) {
                    //         this.handleToggle();
                    //     }
                    // }

                    // res?.sessions.forEach((session, index) => {
                    //     // add speakers info to the session
                    //     session.speakers = [];
                    //     session.parentMeetingUrl = `${this.appService.getBaseUrl()}event/${
                    //         session.parentMeetingId
                    //     }/sessions`;
                    //     if (this.eventSpeakers.length && session.invites.length) {
                    //         session.invites.forEach((speakerId) => {
                    //             const speakerDetail = this.eventSpeakers.find(
                    //                 (speaker) =>
                    //                     speaker._id === speakerId &&
                    //                     speaker.role &&
                    //                     speaker.role?.indexOf(EventUserRole.SPEAKER) !== -1
                    //             );
                    //             if (speakerDetail) {
                    //                 session.speakers.push(speakerDetail);
                    //             }
                    //         });
                    //     }
                    // });
                    return this.sessionsInfo;
                }
                // this.loading = false;
            },
            (err) => {
                // this.toasterService.error(err?.error?.errors || err?.error?.message || 'Something went wrong');
                // this.route.navigate(['dashboard'], {});
                console.log(err);
            }
        );
    }

    calculateIsRecurring(repeatOptions: { repeat?: string }): boolean {
        return (
            repeatOptions?.repeat === 'daily' ||
            repeatOptions?.repeat === 'weekly' ||
            repeatOptions?.repeat === 'monthly'
        );
    }
    getSelectedDaysIndexes(webinarDetails: any): number[] {
        const daysOfWeek = webinarDetails?.repeatOptions?.daysOfWeek;
        const selectedDays: number[] = [];

        if (daysOfWeek) {
            daysOfWeek.forEach((isSelected: boolean, index: number) => {
                if (isSelected) {
                    selectedDays.push(index);
                }
            });
        }

        return selectedDays;
    }
    getDateForWeekdayInMonth(date: Date, weekIndex: number, dayIndex: number): Date {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const firstWeekday = firstDayOfMonth.getDay();
        const offset = dayIndex - firstWeekday;
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            1 + (weekIndex - 1) * 7 + (offset >= 0 ? offset : 7 + offset)
        );
    }
    getRecurringDates(webinarDetails): Date[] {
        const avoidDaysOfWeek = [];
        const avoidDates = [];
        this.recurrenceType = this.getRecurrenceType(webinarDetails?.repeatOptions?.repeat);
        const recurrenceInterval = this.recurrenceInterval || 1;
        var eventStartDate = new Date(webinarDetails?.scheduledStartTime.substring(0, 10));
        this.viewDate = eventStartDate;
        this.viewEndDate = new Date(webinarDetails?.repeatOptions?.endDate.substring(0, 10));
        let occurrences: Date[] = [];
        switch (this.recurrenceType) {
            case 'daily':
            case 'weekly':
                this.selectedDaysIndexes = this.getSelectedDaysIndexes(webinarDetails);
                occurrences = this.getNextOccurrences(
                    eventStartDate,
                    this.recurrenceType,
                    recurrenceInterval,
                    this.selectedDaysIndexes,
                    avoidDates,
                    webinarDetails
                );
                break;
            case 'monthly':
                occurrences = this.getNextOccurrences(
                    eventStartDate,
                    this.recurrenceType,
                    recurrenceInterval,
                    avoidDaysOfWeek,
                    avoidDates,
                    webinarDetails
                );
                this.targetDate = occurrences.length > 0 ? occurrences[0] : null;
                break;
            default:
                throw new Error('Invalid recurrence type');
        }
        return occurrences;
    }
    getMonthlyOccurrences(
        eventStartDate: Date,
        endDateUTC: Date,
        selectedDayOfWeek: number,
        selectedWeekOfMonth: number
    ): Date[] {
        const occurrences: Date[] = [];
        let currentMonthCounter = 0;
        let currentMonth = eventStartDate.getMonth();
        let currentYear = eventStartDate.getFullYear();

        while (
            (currentYear < endDateUTC.getFullYear() ||
                (currentYear === endDateUTC.getFullYear() && currentMonth <= endDateUTC.getMonth())) &&
            currentMonthCounter < 12
        ) {
            let targetDate: Date;

            if (selectedWeekOfMonth === 4) {
                if (selectedWeekOfMonth === 4) {
                    // For the last week of the month
                    let lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
                    let lastWeekdayOfMonth = new Date(currentYear, currentMonth, lastDayOfMonth).getDay();
                    let diff =
                        lastWeekdayOfMonth >= selectedDayOfWeek
                            ? lastWeekdayOfMonth - selectedDayOfWeek
                            : 7 + lastWeekdayOfMonth - selectedDayOfWeek;

                    targetDate = new Date(currentYear, currentMonth, lastDayOfMonth - diff);
                }
            } else {
                targetDate = this.getDateForWeekdayInMonth(
                    new Date(currentYear, currentMonth, 1),
                    selectedWeekOfMonth + 1,
                    selectedDayOfWeek
                );
            }

            // Check if the target date is within the specified range
            if (targetDate >= eventStartDate && targetDate <= endDateUTC) {
                occurrences.push(new Date(targetDate));
            }

            currentMonth++;
            if (currentMonth === 12) {
                currentMonth = 0;
                currentYear++;
            }

            currentMonthCounter++;
        }

        return occurrences;
    }
    getRecurrenceType(repeatOptions) {
        switch (repeatOptions) {
            case 'daily':
            case 'weekly':
            case 'monthly':
                return repeatOptions;
            default:
                return 'Custom';
        }
    }
    getNextOccurrences(
        eventStartDate: Date,
        frequency: string,
        interval: number,
        avoidDaysOfWeek: number[],
        avoidDates: Date[],
        webinarDetails: any
    ): Date[] {
        const occurrences: Date[] = [];
        let currentDate = new Date(eventStartDate);
        const endDateUTC = new Date(this.viewEndDate.toUTCString());
        const weeksInMonth = webinarDetails?.repeatOptions?.weeksInMonth;

        const daysOfWeek = webinarDetails?.repeatOptions?.daysOfWeek;
        const dateInMonth = webinarDetails?.repeatOptions?.dateInMonth;

        switch (frequency) {
            case 'daily':
                while (currentDate <= endDateUTC) {
                    occurrences.push(new Date(currentDate));
                    currentDate.setDate(currentDate.getDate() + interval);
                }
                break;
            case 'weekly':
                while (currentDate <= endDateUTC) {
                    if (this.selectedDaysIndexes.includes(currentDate.getDay())) {
                        occurrences.push(new Date(currentDate));
                    }
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                break;
            case 'monthly':
                if (dateInMonth === 0) {
                    const selectedDayOfWeek = daysOfWeek.findIndex((isSelected: any) => isSelected);
                    const selectedWeekOfMonth = weeksInMonth.findIndex((isSelected: any) => isSelected);

                    if (selectedDayOfWeek !== -1 && selectedWeekOfMonth !== -1) {
                        occurrences.push(
                            ...this.getMonthlyOccurrences(
                                eventStartDate,
                                endDateUTC,
                                selectedDayOfWeek,
                                selectedWeekOfMonth
                            )
                        );
                    }
                } else {
                    occurrences.push(new Date(eventStartDate));
                    currentDate.setDate(dateInMonth);

                    while (currentDate <= endDateUTC) {
                        currentDate.setMonth(currentDate.getMonth() + interval);
                        if (currentDate.getDate() != dateInMonth) {
                            currentDate.setMonth(currentDate.getMonth(), 0);
                        }
                        if (currentDate <= endDateUTC) {
                            occurrences.push(new Date(currentDate));
                        }
                    }
                }
                break;
            default:
                throw new Error('Invalid frequency');
        }

        return occurrences;
    }
}
