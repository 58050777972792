import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService, LocalStorageService, RestService, UserService, UtilService } from 'src/app/core';

import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';
import { CONTACTUS, HOME_PAGE_REVAMP } from '../../../constants/gtm-events';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';

import { APP_CONSTANT } from 'src/app/constants';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    animations: [
        trigger('bottomToTopAnimations', [
            transition(':enter', [
                style({ transform: 'translateY(20px)', opacity: 0 }),
                animate('2s', style({ transform: 'translateY(0)', opacity: 1 }))
            ])
        ])
    ]
})
export class ContactComponent implements OnInit, OnDestroy {
    currentOS: 'Windows' | 'Mac OS' | 'Other';
    showMailContext: boolean = false;

    constructor(
        private toastService: ToastrService,
        private restService: RestService,
        private localstoreService: LocalStorageService,
        private googleTagManger: GoogleTagMangerService,
        private appService: AppService,
        private utilService: UtilService,
        private renderer: Renderer2,
        private router: Router,
        private userService: UserService
    ) {}
    formInfo: any = {
        interestedProducts: ['jiomeetEvents']
    };
    cardList: any;
    companySizes = [
        {
            name: 'Upto 300',
            value: '<300'
        },
        { name: 'Upto 1000', value: '<1000' },
        { name: 'Upto 3000', value: '<3000' },
        { name: 'Upto 5000', value: '<5000' },
        { name: 'Custom', value: 'Custom' }
    ];
    countryCode = APP_CONSTANT.COUNTRY_CODE;
    selectedOption: string = '';
    userFrom: any = 'default';
    formSubmited: boolean = false;
    isChecked: boolean = false;
    isMobileOS: boolean = false;
    isElementInView: boolean = false;
    selectedAttendeeNumber: string = ''; // Default selection
    customAttendeeNumber: number;
    @ViewChild('elementToObserve') elementToObserve!: ElementRef;
    ngAfterViewInit() {
        const options = {
            root: null, // use the viewport as the root
            rootMargin: '0px',
            threshold: 0.25 // trigger when 50% of the element is visible
        };

        const observer = new IntersectionObserver(this.handleIntersection, options);
        observer.observe(this.elementToObserve.nativeElement);
        observer.observe(document.getElementById('contactMailSection'));
    }
    handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.isElementInView = true;
            }
            if (entry.target.id === 'contactMailSection') {
                this.showMailContext = true;
            }
        });
    };
    ngOnInit(): void {
        this.googleTagManger.pushGoogleTagData(GA_PAGEVIEW.CONTACT_US_PAGE_REVAMP_VIEW);
        this.contactUsSource();
        this.cardList = this.appService.getConfigVariable('cardList');
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.setOS();
        this.enableDisableScrolling();
        const user = this.userService.getUserSync();
        if (user.subscriptionInfo.reSeller == 'jiobundle') {
            this.formInfo.name = user?.name + ' ' + user?.lname || '';
            this.formInfo.email = user?.email || '';
            this.formInfo.phone = user?.phoneNo || '';
            this.formInfo.interestedProducts = ['accopsEvents'];
        }
    }
    onSubmit(f) {
        if (f.valid) {
            if (this.formInfo.companySize === 'Custom') {
                this.formInfo.companySize = '<' + this.customAttendeeNumber;
            }
            this.contactSales();
            f.resetForm();
        }
    }
    enableDisableScrolling() {
        if (this.formSubmited == false) {
            this.renderer.setStyle(document.body, 'overflow-y', 'hidden');
        } else {
            this.renderer.removeStyle(document.body, 'overflow-y');
        }
    }
    navigateToPrivacy() {
        window.open(`${this.appService.getJioEventsBaseUrl()}privacy-policy-business`);
    }
    navigateToTnC() {
        window.open(`${this.appService.getJioEventsBaseUrl()}terms-condition-business`);
    }
    toggleCheckbox() {
        this.isChecked = !this.isChecked;
    }
    contactUsSource() {
        if (this.localstoreService.getItem(`userFrom`)) {
            this.userFrom = 'Dashboard';
        }
    }

    contactSales() {
        let formSubmitEvent = HOME_PAGE_REVAMP.SEND_MESSAGE;
        this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/contacts/contactsales`, this.formInfo)
            .subscribe(
                (data: any) => {
                    if (data.success) {
                        this.formSubmited = true;
                        this.toastService.success(`Message sent.`);
                        formSubmitEvent.outcome = 'succeeded';
                        this.googleTagManger.pushGoogleTagData(formSubmitEvent);
                    }
                },
                (err) => {
                    formSubmitEvent.outcome = 'failed';
                    this.googleTagManger.pushGoogleTagData(formSubmitEvent);
                    console.log('failed to sent customer data', err);
                }
            );
    }

    setOS() {
        const os = this.utilService.getOS();
        switch (os) {
            case 'Windows':
                this.currentOS = 'Windows';
                break;
            case 'Mac OS':
                this.currentOS = 'Mac OS';
                break;
            default:
                this.currentOS = 'Other';
        }
    }
    ngOnDestroy(): void {
        this.localstoreService.removeItem('userFrom');
    }
}
