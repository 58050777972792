export const WEBINAR_CONFERENCE_EVENTS = {
    QA_PANEL: 'QA_PANEL',
    SHOW_POLLS_PANEL: 'SHOW_POLLS_PANEL',
    TICKER_PANEL: 'TICKER_PANEL',
    COMMENT_PANEL: 'COMMENT_PANEL',
    LIVESTREAM_POPUP: 'LIVESTREAM_POPUP',
    CLOUD_PLAYER_PREVIEW: 'CLOUD_PLAYER_PREVIEW',
    UNSEEN_COMMENTS_COUNT: 'UNSEEN_COMMENTS_COUNT',
    HIDE_QA_PANEL: 'HIDE_QA_PANEL',
    HIDE_POLLS_PANEL: 'HIDE_POLLS_PANEL',
    HIDE_COMMENT_PANEL: 'HIDE_COMMENT_PANEL',
    HIDE_TICKER_PANEL: 'HIDE_TICKER_PANEL',
    SHOW_ADD_LINK_POPUP: 'SHOW_ADD_LINK_POPUP',
    ADD_HYPERLINK: 'ADD_HYPERLINK',
    REMOVE_HYPERLINK: 'REMOVE_HYPERLINK',
    TIMER: 'TIMER',
    VIEW_COUNT: 'VIEW_COUNT',
    STOP_TIMER: 'STOP_TIMER',
    SELECTED_QA_TIMELIMIT: 'SELECTED_QA_TIMELIMIT',
    SHOW_SCHEDULE_PANEL: 'SHOW_SCHEDULE_PANEL',
    HIDE_SCHEDULE_PANEL: 'HIDE_SCHEDULE_PANEL',
    MEDIA_SHARE_SYNCED: 'MEDIA_SHARE_SYNCED',
    ATTENDEE_PARTICIPATED: 'ATTENDEE_PARTICIPATED',
    CLOSE_SHAREPOPUP: 'CLOSE_SHAREPOPUP'
};
