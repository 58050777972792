<section id="explore" class="explore section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-7 col-md-6
                    d-flex
                    flex-column
                    webinar-sec-one
                    explore-left-area
                    pt-4 pt-lg-0
                    order-1 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1 class="jiotype-black-white-88px Support-main-txt">How can we change the way you think?</h1>
                </div>
                <div class="cus-point">
                    <!-- <label tabindex="0" aria-label="How can we change the way you think?,Get Started "> -->
                    <!-- <span   aria-roledescription="How can we change the way you think?" -->

                    <button
                        tabindex="0"
                        aria-roledescription="How can we change the way you think?"
                        role="button"
                        (keyup.enter)="getStarted()"
                        (click)="getStarted()"
                        class="support-btn"
                    >
                        <span class="support-btn-txt">Schedule A Demo</span>
                    </button>
                </div>
            </div>
            <div
                class="col-lg-5 col-md-6 order-2 order-md-2 order-lg-2 explore-img-container aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="thumb-concept-C61RwL">
                    <img src="assets/img/website/thumb-concept_two.png" />
                    <!-- <img class="rectangle-G3doJA" src="assets/img/website/rectangle@2x.png">
            <img class="vector-G3doJA" src="assets/img/website/vector-1@2x.png">
            <div class="group-139259-G3doJA">
              <img class="rectangle-147949-UmTaS8" src="assets/img/website/rectangle-147949@2x.png">
              <img class="rectangle-147950-UmTaS8" src="assets/img/website/rectangle-147950@2x.png">
              <img class="mask-group-UmTaS8" src="assets/img/website/mask-group@2x.png">
            </div>
            <img class="vector-nUQ7sA" src="assets/img/website/vector-2@2x.png">
            <img class="vector-UMjwSU" src="assets/img/website/vector-3@2x.png">
            <img class="vector-KXLWtp" src="assets/img/website/vector-4@2x.png">
            <img class="vector-x0jfGj" src="assets/img/website/vector-5@2x.png"> -->
                </div>
                <!-- <div class="image-stack">
            <img  src="assets/img/website/rectangle@2x.png" class="img-fluid explore-img" alt="">
            <img  src="assets/img/website/mask-group@2x.png" class="img-fluid explore-img" alt="">
          </div> -->
            </div>
        </div>
    </div>
</section>
<section id="usecase-one" class="usecase-one section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    align-items-center
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <!-- <p class="jiotype-bold-white-12px">IMPROVE THIS</p> -->
                    <h1
                        tabindex="0"
                        role="button"
                        aria-roledescription="Build your communities on JioEvents with rich interactive experiences like Broadcast, Polls,
                Chats, Q&A, Comments, and a host of other features with that personal touch."
                        class="jiotype-black-white-40px"
                    >
                        Social Webinar
                    </h1>
                    <p class="jiotype-bold-white-24px-2">
                        Build your communities on JioEvents with rich interactive experiences like Broadcast, Polls,
                        Chats, Q&A, Comments, and a host of other features with that personal touch.
                    </p>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    order-1
                    d-flex
                    align-items-center
                    order-md-2 order-lg-2
                    usecase-one-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139248-C61RwL">
                    <img src="assets/img/website/Group_139248.png" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="usecase-two" class="usecase-two section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-md-2 order-2 order-lg-2
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <!-- <p class="jiotype-bold-white-12px">IMPROVE THAT</p> -->
                    <h1
                        tabindex="0"
                        role="button"
                        aria-roledescription="JioEvents is the perfect tool to hold workshops and training events. Collaborate with
                whiteboards, booth, virtual lounge, recording, breakout rooms, etc."
                        class="jiotype-black-white-40px"
                    >
                        Workshops and trainings
                    </h1>
                    <p class="jiotype-bold-white-24px-2">
                        JioEvents is the perfect tool to hold workshops and training events. Collaborate with
                        whiteboards, booth, virtual lounge, recording, breakout rooms, etc.
                    </p>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    align-items-center
                    order-1 order-md-1 order-lg-1
                    usecase-one-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139251-C61RwL row">
                    <img class="mask-group-48qK29" src="assets/img/website/mask-group-4@2x.png" />
                    <img class="mask-group-xEam0x" src="assets/img/website/mask-group-3@2x.png" />
                </div>
            </div>
        </div>
    </div>
</section>
<section id="usecase-one" class="usecase-one section-bg">
    <div class="container">
        <div class="row explore-section">
            <div
                class="
                    col-lg-6 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <!-- <p class="jiotype-bold-white-12px">IMPROVE THIS</p> -->
                    <h1
                        aria-hidden="true"
                        tabindex="0"
                        role="button"
                        aria-roledescription="Create rich, engaging events with HD quality, interactive features like broadcast, Polls, Q&A,
                Comments, Recording etc."
                        class="jiotype-black-white-40px"
                    >
                        Engage with your audience
                    </h1>
                    <p class="jiotype-bold-white-24px-2">
                        Create rich, engaging events with HD quality, interactive features like broadcast, Polls, Q&A,
                        Comments, Recording etc.
                    </p>
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6
                    order-1 order-md-2 order-lg-2
                    d-flex
                    align-items-center
                    usecase-one-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="group-139250-C61RwL">
                    <img src="assets/img/website/Group_139250.png" />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section id="usecase-four" class="usecase-four section-bg" >
    <div class="container">
      <div class="row explore-section">

        <div class="col-lg-6 d-flex flex-column explore-left-area pt-4 pt-lg-0 order-2 order-lg-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <div class="explore-main-txt">
              <p class="jiotype-bold-white-12px">USE CASE</p>
            <h1 class="jiotype-black-white-88px Support-main-txt">AGMS</h1>
            <p class="jiotype-bold-white-24px-2">Host customized AGMs with DPID integration, e-voting integration, upload shareholder data, enterprise grade security, HD Screenshare, multiple sign-in options with SSO, AGM customization</p>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-1 usecase-one-img-container aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
            <img  src="assets/img/website/image-29@2x.png" class="img-fluid usecase-one-img" alt="">
          </div>
      </div>
    </div>
  </section> -->
<section id="virtual-explore" class="virtual-explore section-bg">
    <div class="container">
        <div class="row virtual-explore-section">
            <div
                class="
                    col-lg-6 col-md-6 col-12
                    d-flex
                    flex-column
                    virtual-explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="rainbow-shape"></div>
                <div class="virtual-explore-main-txt">
                    <h1 class="jiotype-black-te-papa-green-64px">Get Started with JioEvents</h1>
                </div>
                <div class="explore-link-area cus-point">
                    <a
                        tabindex="0"
                        role="button"
                        aria-roledescription="Get Started with JioEvents"
                        (click)="exploreNow()"
                        (keyup.enter)="exploreNow()"
                        class="jiotype-medium-white-ice-24px cursor-pointer"
                        >Explore now</a
                    >
                </div>
            </div>
            <div
                class="
                    col-lg-6 col-md-6 col-12
                    order-1 order-md-2 order-lg-2
                    virtual-explore-img-container
                    aos-init aos-animate
                "
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img src="assets/img/website/explore-promo-one.png" class="img-fluid virtual-explore-img" alt="" />
            </div>
        </div>
    </div>
</section>
