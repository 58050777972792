import { Directive, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appIntersectionObserver]'
})
export class IntersectionObserverDirective {
    @Output() intersection = new EventEmitter<IntersectionObserverEntry[]>();
    private observer: IntersectionObserver;
    constructor(private elementRef: ElementRef) {}

    generateArray(start: number, end: number, interval: number): number[] {
        const array: number[] = [];
        for (let i = start; i <= end; i += interval) {
            array.push(i);
        }
        return array;
        // [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
    }
    ngOnInit() {
        this.observer = new IntersectionObserver((entries) => this.intersection.emit(entries), {
            threshold: this.generateArray(0, 1, 0.001)
        });
        this.observer.observe(this.elementRef.nativeElement);
    }
}
