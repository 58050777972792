import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RestService } from './rest.service';
import { RtcService } from './rtc.service';

@Injectable({
    providedIn: 'root'
})
export class LiveStreamService {
    activeLiveStreams: any = [];
    isLiveStreamGoingOn = false;
    liveStreamType = '';
    constructor(private restService: RestService, private rtcService: RtcService, private appService: AppService) {}

    getAllLiveStreams() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/livestream`);
    }

    getLiveStreamsByJiomeetId(id) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/livestream?jiomeetId=${id}&isWebinar=true`
        );
    }

    createLiveStream(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/livestream/create`, payload);
    }

    updateLiveStream(id, payload) {
        return this.restService.put(`${this.appService.getEnvVariable('BASE_URL')}/livestream/${id}`, payload);
    }

    deleteLiveStream(liveStreamId) {
        return this.restService.delete(`${this.appService.getEnvVariable('BASE_URL')}/livestream/${liveStreamId}`);
    }

    startLiveStream(meetingId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/livestream/multi/start`,
            payload
        );
    }

    stopLiveStream(meetingId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/livestream/multi/stop`,
            payload
        );
    }

    updateSingleLiveStream(jiomeetId, livestreamType, payload) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/livestream/${jiomeetId}/${livestreamType}`,
            payload
        );
    }

    startSingleLivestreaming(jiomeetId, livestreamType, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/livestream/${jiomeetId}/${livestreamType}/start`,
            payload
        );
    }

    stopSingleLivestreaming(jiomeetId, livestreamType, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/livestream/${jiomeetId}/${livestreamType}/stop`,
            payload
        );
    }

    setStreamingStatus() {
        const room = this.rtcService.getRoomInfo();
        this.isLiveStreamGoingOn =
            room?.ytlivestreamByJiomeetId?.streaming ||
            room?.fblivestreamByJiomeetId?.streaming ||
            room?.customlivestreamByJiomeetId?.streaming;
        this.liveStreamType = room?.ytlivestreamByJiomeetId?.streaming
            ? 'yt'
            : room?.fblivestreamByJiomeetId?.streaming
            ? 'fb'
            : room?.customlivestreamByJiomeetId?.streaming
            ? 'custom'
            : '';
    }

    setLiveStreamType(type) {
        this.liveStreamType = type;
    }
}
