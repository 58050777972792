import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { AppService } from './app.service';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    hasCameraPermissions = true;
    hasMicrophonePermissions = true;
    hasCameraPermissionDenied = false;
    hasMicPermissionDenied = false;
    permissionState: string = 'granted';
    isInitialCamera = true;
    isInitialMic = true;
    permissionStatusUpdates = new BehaviorSubject<any>({
        hasCameraPermissions: this.hasCameraPermissions,
        hasMicrophonePermissions: this.hasMicrophonePermissions,
        hasCameraPermissionDenied: this.hasCameraPermissionDenied,
        hasMicPermissionDenied: this.hasMicPermissionDenied,
        isInitialCamera: true,
        isInitialMic: true,
        permissionState: this.permissionState
    });
    canRegisterForUpdates: boolean;
    constructor(
        private appService: AppService,
        private userService: UserService,
        private router: Router,
        private utilService: UtilService
    ) {
        this.registerForMicAndCameraPermissionStatus();
    }

    checkForUser(permissions: any[]) {
        if (this.appService.getConfigVariable('BYPASS_PERMISSIONS_CHECK') || !permissions.length) {
            return of(true);
        }
        return this.userService.getUser().pipe(
            map((user) => {
                if (user && user.roles.length) {
                    const userRoles = user.roles.map((role) => role.name);
                    return permissions.some((grants) => this.existPermission(userRoles, grants));
                }
                return false;
            })
        );
    }

    private existPermission(userRoles, permissions): boolean {
        // assuming single role, change incase of multi role in future
        return userRoles.find((x) => x.toUpperCase() === permissions.toUpperCase());
    }

    async registerForMicAndCameraPermissionStatus() {
        if (navigator.permissions) {
            if (this.utilService.isChrome()) {
                this.canRegisterForUpdates = true;
            } else {
                this.canRegisterForUpdates = false;
            }
            this.registerForPermissionUpdates('camera', (updates) => {
                this.hasCameraPermissions = updates.hasPermissions;
                this.hasCameraPermissionDenied = updates.hasPermissionDenied;
                this.isInitialCamera = updates.isInitial;
                this.permissionState = updates.state;
                this.permissionStatusUpdates.next({
                    hasCameraPermissions: this.hasCameraPermissions,
                    hasMicrophonePermissions: this.hasMicrophonePermissions,
                    hasCameraPermissionDenied: this.hasCameraPermissionDenied,
                    hasMicPermissionDenied: this.hasMicPermissionDenied,
                    isInitialCamera: this.isInitialCamera,
                    isInitialMic: this.isInitialMic,
                    permissionState: this.permissionState
                });
            });
            this.registerForPermissionUpdates('microphone', (updates) => {
                this.hasMicrophonePermissions = updates.hasPermissions;
                this.hasMicPermissionDenied = updates.hasPermissionDenied;
                this.isInitialMic = updates.isInitial;
                this.permissionState = updates.state;
                this.permissionStatusUpdates.next({
                    hasCameraPermissions: this.hasCameraPermissions,
                    hasMicrophonePermissions: this.hasMicrophonePermissions,
                    hasCameraPermissionDenied: this.hasCameraPermissionDenied,
                    hasMicPermissionDenied: this.hasMicPermissionDenied,
                    isInitialCamera: this.isInitialCamera,
                    isInitialMic: this.isInitialMic,
                    permissionState: this.permissionState
                });
            });
        } else {
            return this.permissionStatusUpdates.next({ noPermissionListener: true });
        }
    }

    async registerForPermissionUpdates(name, listener) {
        var permissionStatus = await navigator.permissions.query({ name: name });
        if (listener) {
            listener({
                name: name,
                state: permissionStatus.state,
                hasPermissions: permissionStatus.state === 'granted',
                hasPermissionDenied: permissionStatus.state === 'denied',
                isInitial: true
            });
        }
        permissionStatus.onchange = function () {
            var hasPermissions = this.state === 'granted';
            var hasPermissionDenied = this.state === 'denied';
            if (listener) {
                listener({ name: name, state: this.state, hasPermissions, isInitial: false, hasPermissionDenied });
            }
        };
    }
}
