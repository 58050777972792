import { DatePipe } from '@angular/common';
import { ComponentFactoryResolver, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { AddParticipantsService, WebinarConferenceService } from 'src/app/conference/services';
import { APP_CONSTANT } from 'src/app/constants';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { FloatingReactionService } from 'src/app/shared/services/floating-reaction.service';
import { MediaOrchestratorService } from 'src/app/shared/services/media-orchestrator.service';
import { CallStateManagerService } from '../classes/call-state-manager.service';
import { AppLoggerService } from './app-logger.service';
import { AppService } from './app.service';
import { AudioService } from './audio.service';
import { AuthService } from './auth.service';
import { BreakoutRoomService } from './breakout-room.service';
import { CallViewStateManagerService } from './call-view-state-manager.service';
import { ChatService } from './chat.service';
import { DiagnosticService } from './diagnostic.service';
import { EventEmitterService } from './event-emitter.service';
import { ExternalInterfaceService } from './external-interface.service';
import { FullscreenService } from './fullscreen.service';
import { GuestAuthService } from './guest-auth.service';
import { LocalStorageService } from './local-storage.service';
import { MeetingService } from './meeting.service';
import { NetworkingRoomsService } from './networking-rooms.service';
import { PollsService } from './polls.service';
import { RememberMeService } from './remember-me.service';
import { RoomConnectionService } from './room-connection.service';
import { RtcService } from './rtc.service';
import { SocketService } from './socket.service';
import { SystemService } from './system.service';
import { TimerService } from './timer.service';
import { UserService } from './user.service';
import { UtilService } from './util.service';
import { VideoWrapperService } from './video-wrapper.service';
import { WaitingRoomService } from './waiting-room.service';
import { WhiteboardService } from './whiteboard.service';

@Injectable({
    providedIn: 'root'
})
export class ConferenceEventsHandlerService {
    videoService: any;

    constructor(
        private zone: NgZone,
        private datePipe: DatePipe,
        private appService: AppService,
        private userService: UserService,
        public rtcService: RtcService,
        private eventEmitterService: EventEmitterService,
        private socketService: SocketService,
        private toasterService: ToastrService,
        private timerService: TimerService,
        private audioService: AudioService,
        private authService: AuthService,
        private guestAuthService: GuestAuthService,
        private router: Router,
        private confirmationService: ConfirmationService,
        private diagnosticService: DiagnosticService,
        private roomConnectionService: RoomConnectionService,
        private waitingRoomService: WaitingRoomService,
        private compFactory: ComponentFactoryResolver,
        private externalInterfaceService: ExternalInterfaceService,
        private whiteboardService: WhiteboardService,
        private rememberService: RememberMeService,
        private utilService: UtilService,
        private guestUserService: GuestAuthService,
        private breakoutRoomService: BreakoutRoomService,
        private videoWrapperService: VideoWrapperService,
        private systemService: SystemService,
        private addParticipantService: AddParticipantsService,
        private translateService: TranslateService,
        private chatService: ChatService,
        private webinarConfService: WebinarConferenceService,
        private pollsService: PollsService,
        private localStorageSession: LocalStorageService,
        private appLoggerService: AppLoggerService,
        private localStorageService: LocalStorageService,
        private floatingService: FloatingReactionService,
        private mediaOrchestratorService: MediaOrchestratorService,
        private webinarService: WebinarService,
        private meetingService: MeetingService,
        private callStateManagerService: CallStateManagerService,
        private fullscreenService: FullscreenService,
        private networkingRoomsService: NetworkingRoomsService,
        public callViewStateManagerService: CallViewStateManagerService
    ) {}

    //   handleConferenceEvents(event) {
    //     switch (event.type) {
    //         case 'VC_CREATED':
    //             if (this.callViewStateManagerService.currentUser && Object.keys(this.callViewStateManagerService.currentUser).length !== 0) {
    //                 // Update user call status to true
    //                 this.sendUserEngagedInCall();
    //                 // Notify other devices where user logged in.
    //                 this.sendCallJoined();
    //             }
    //             break;
    //         case 'ACTIVE':
    //             this.videoService = this.videoWrapperService.getVideoService();
    //             this.mediaShared = false;
    //             this.screenShareInfo = {
    //                 host: '',
    //                 isShared: false,
    //                 id: ''
    //             };
    //             this.loading = false;
    //             this.rtcService.setIsConferenceActive(true);
    //             this.appService.setCurrentCallJiomeetID(this.meetingObj.jiomeetId);
    //             this.meetingObj.active = true;
    //             this.showCallControls = true;
    //             if (
    //                 this.meetingObj.isInitiater &&
    //                 this.currentUser.jiomeetId === this.meetingObj.jiomeetId // host a meeting case
    //             ) {
    //                 this.diagnosticService
    //                     .sendEvent({
    //                         eventCategory: 'Start a Meeting',
    //                         eventAction: 'New Meeting Started',
    //                         eventType: 'app_event',
    //                         status: 'success'
    //                     })
    //                     .subscribe();
    //             }

    //             if (this.authService.getIsAuthenticated()) {
    //                 this.userService.getConferenceDetails(this.meetingObj.historyId).subscribe((res) => {
    //                     this.meetingObj.isRecordingAllowedForRoom = res?.isRecordingAllowedForRoom;
    //                     this.videoWrapperService.getVideoService().toggleRecordingStatus(res.currentlyRecording);
    //                 });
    //             }
    //             this.sendJoinSocketEvent();

    //             this.getLikeDetails();
    //             this.externalInterfaceService.sendCallStarted(this.userID());

    //             if (this.isVidyoMeeting && this.rtcService?.isVirtualBackgroundApplied) {
    //                 this.toasterService.info('This Event will not support virtual background');
    //             }
    //             break;
    //         case 'LOCAL_PARTICIPANT_CONNECTED':
    //             this.roomConnectionService.updateVideoServiceInstance();
    //             const conference = this.rtcService.getConferenceInfo();
    //             if (!this.breakoutRoomInfo && conference.room.roomStatus === 'Locked') {
    //                 this.rtcService
    //                     .lockRoom({
    //                         roomID: this.meetingObj.room_id.toString(),
    //                         meetingId: this.meetingObj.meetingId,
    //                         historyId: this.meetingObj.historyId
    //                     })
    //                     .subscribe();
    //             }
    //             if (this.breakoutRoomInfo?.roomInfo?.roomStatus === 'Locked' && this.meetingObj?.isInitiater) {
    //                 this.rtcService
    //                     .lockRoom({
    //                         roomID: this.meetingObj.room_id.toString(),
    //                         meetingId: this.meetingObj.meetingId,
    //                         historyId: this.meetingObj.historyId
    //                     })
    //                     .subscribe();
    //             }
    //             this.initiateRoomConnectionStatusUpdate();
    //             // make polling at host end configurable
    //             const enablePolling = this.appService?.getWebinarPollingState(APP_CONSTANT.WEBINAR_HOST_POLLING_STATE);
    //             if (enablePolling) {
    //                 this.initiateEventStatePolling();
    //             }
    //             this.subscribeToAudioLevel();
    //             break;
    //         case 'FAILURE':
    //             if (this.disconnetedFromMeetingControl) {
    //                 return;
    //             }
    //             this.handleVidyoFailure(event);
    //             break;
    //         case 'INACTIVE':
    //             this.toasterService.warning(
    //                 this.translateService.instant(
    //                     'tostrs.oops_we_couldnt_connect_to_the_meeting_please_refresh_page_and_try_again'
    //                 ),
    //                 '',
    //                 {
    //                     positionClass: 'toast-top-center',
    //                     timeOut: 60 * 60 * 1000,
    //                     extendedTimeOut: 60 * 60 * 1000
    //                 }
    //             );
    //             break;
    //         case 'SHARE_STOP':
    //             this.stopShare(this.screenSharing);

    //             break;
    //         case 'END_CALL':
    //             this.disconnetedFromMeetingControl = true;
    //             this.toasterService.info(this.translateService.instant('tostrs.host_ended'));
    //             this.endCall();
    //             break;
    //         case 'RE_CONNECTED':
    //             this.handleReconnection();
    //             break;
    //         case 'CONNECTION_LOST':
    //             this.toasterService.clear();
    //             this.confirmationService.confirm({
    //                 message: this.translateService.instant(
    //                     'tostrs.oops_please_check_your_internet_connectivity_and_try_again'
    //                 ),
    //                 header: this.translateService.instant('tostrs.connection_lost'),
    //                 acceptLabel: this.translateService.instant('tostrs.ok'),
    //                 acceptButtonStyleClass: 'custom-button bg-login',
    //                 rejectVisible: false,
    //                 accept: () => {
    //                     location.href = this.appService.getBaseUrl() + 'dashboard';
    //                 },
    //                 reject: () => {
    //                     location.href = this.appService.getBaseUrl() + 'dashboard';
    //                 }
    //             });
    //             break;
    //         case 'WHITEBOARD_STOP':
    //             this.whiteboardShared = false;
    //             if (this.isWhiteBoardHost) {
    //                 this.stopWhiteBoard();
    //             }
    //             break;
    //         case 'RECORD_STATUS':
    //             if (event.data) {
    //                 this.handleVidyoRecordingEvents(event);
    //             }
    //             break;
    //         case 'SEND_RECORDING_NOTIFICATION_TO_NEWLY_JOINED':
    //             if (!this.breakoutRoomInfo?.breakoutRoomId) {
    //                 // this.videoWrapperService.getVideoService().sendChatMessage({
    //                 //   type: 'PublicChat',
    //                 //   message: MEDIA_ENGINE_EVENTS.HOST_START_RECORDING
    //                 // });
    //             }
    //             break;

    //         case 'SEND_CONNECTION_STATUS':
    //             this.lowerAudienceMemberHand(true);
    //             break;

    //         case 'SHOW_DOWNGRADE_AUDIENCE_TOAST':
    //             this.showDowngradeAudienceToast('order');
    //             break;

    //         case 'SHOW_DOWNGRADE_AUDIENCE_INFO':
    //             this.showDowngradeAudienceToast('info');
    //             break;

    //         case 'DOWNGRADE_CO_HOST':
    //             this.downGradeCohost(event.data.userId);
    //             break;
    //         case 'SHOW_LARGE_MEETINGINFO': {
    //             // this.showLargeMeetingToast(); //As discussed with Shubham, We no need to show this msg anymore
    //         }
    //     }
    // }

    sendUserEngagedInCall() {
        this.rtcService
            .userEngagedInCall({
                event: 'userEngagedInCall',
                data: {
                    userId:
                        this.callViewStateManagerService.currentUser.userId ||
                        this.callViewStateManagerService.currentUser._id,
                    historyId: this.callViewStateManagerService.meetingObj.historyId || '',
                    participantEndPointType: 'web',
                    participantEndPointName: 'web',
                    machineIp: this.systemService.getDeviceUUID()
                }
            })
            .subscribe();
    }

    sendCallJoined(isDisconnect = false) {
        this.rtcService.sendEvent({
            event: 'disconnectActiveCall',
            data: {
                userId:
                    this.callViewStateManagerService.currentUser.userId ||
                    this.callViewStateManagerService.currentUser._id,
                sessionId: this.systemService.getSessionID(),
                uniqueBrowserId: this.systemService.getUniqueBrowserID(),
                isDisconnect
            }
        });
    }
}
