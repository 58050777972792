<div class="backbutton" (click)="cancel()"><img src="assets/images/arrow_back_blue.svg" />Back</div>
<div class="change-password-container">
    <h2 class="mt-5">Change password</h2>
    <form class="custom-form" [formGroup]="changePasswordForm">
        <div class="input-field">
            <div class="input-label">Current password</div>
            <div class="input-text">
                <input type="password" placeholder="Enter your current password" formControlName="oldPassword" />
            </div>
        </div>
        <div class="input-field">
            <div class="input-label">New password</div>
            <div class="passwordicon input-text">
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    placeholder="Enter new password"
                    formControlName="password"
                />
                <img
                    src="{{ showPassword ? 'assets/img/hide_password.svg' : 'assets/img/show_password.svg' }}"
                    (click)="togglePassword()"
                    class="eye"
                />
            </div>
        </div>
        <div class="password-info">
            <!-- <ng-container *ngIf="changePasswordForm.value.password"> -->
            <ng-container>
                <div class="password-hint">
                    <p class="password-info">Your password must have</p>
                    <ul>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('lengthError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('lengthError')
                            }"
                        >
                            At least 8 characters
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('alphabetError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('alphabetError')
                            }"
                        >
                            At least 1 letter
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('numberError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('numberError')
                            }"
                        >
                            At least 1 number
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('dualError'),
                                valid:
                                    changePasswordForm.get('password').value && !errors?.password?.includes('dualError')
                            }"
                        >
                            Include both UPPER and lower case
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('specialCharsError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('specialCharsError')
                            }"
                        >
                            At least one symbol
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
        <div class="input-field">
            <div class="input-label">Confirm password</div>
            <div class="input-text">
                <input type="password" placeholder="Confirm new password" formControlName="confirmPassword" />
            </div>
        </div>
        <div class="mt-2 text-error" *ngIf="errors.global">{{ errors.global }}</div>
        <div class="button-group">
            <button type="submit" class="custom-button bg-primary mr-5" (click)="submit()">Save</button>
            <button type="button" class="custom-button bg-secondary" (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>
