import { Component, OnInit } from '@angular/core';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { WHITEGLOVE } from '../../../constants/gtm-events';
import { GA_PAGEVIEW } from '../../../constants/gtm-pageview';

@Component({
    selector: 'app-whiteglove-service',
    templateUrl: './whiteglove-service.component.html',
    styleUrls: ['./whiteglove-service.component.scss']
})
export class WhitegloveServiceComponent implements OnInit {
    custzidx: any = 0;
    engaeidx: any = 0;
    constructor(private googleTagManager: GoogleTagMangerService) {}
    reports: any = [
        {
            img: 'swap-me-16@2x.png',
            title: 'Support large attendees',
            des: 'We support more than 100,000+ attendees.',
            eventKey: 'SUPPORT'
        },
        {
            img: 'swap-me-15@2x.png',
            title: 'Locked events',
            des: 'Host a private event to avoid entry of unknown speakers.',
            eventKey: 'LOCKED'
        },
        {
            img: 'swap-me-17@2x.png',
            title: 'Download analytics reports',
            des: 'Registration page availability with manual and automatic approval for attendees.',
            eventKey: 'ANALYTICS_REPORT'
        }
    ];
    ngOnInit(): void {
        this.googleTagManager.pushGoogleTagData(GA_PAGEVIEW.WHITE_GLOVE_PAGE_VIEW);
    }

    reportsClick(report) {
        this.googleTagManager.pushGoogleTagData(WHITEGLOVE[report.eventKey]);
    }

    changeCustomizeIndex(idx) {
        this.custzidx = idx;
    }

    engageChangeIndex(idx) {
        this.engaeidx = idx;
    }
    contactUS() {
        this.googleTagManager.pushGoogleTagData(WHITEGLOVE.CONTACT_US);
    }
}
