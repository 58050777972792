<div class="leaderboard-scroll-container" [class.isMobileOS]="isMobileOS">
    <div class="call-option-panel leaderboard-panel" *ngIf="!loading">
        <div class="call-options-header">
            <!-- <button class="back-button mr-1" (click)="goBack()"> -->
            <img
                class="back-button mr-1 pointer"
                *ngIf="showScoringInfoTable"
                (click)="goBack()"
                src="assets/img/jioconf/chevron_active.svg"
            />
            <!-- </button> -->
            <div class="title heading">{{ showScoringInfoTable ? 'Points' : 'Leaderboard' }}</div>
            <button
                class="custom-button-revamp btn-secondary xsm w-auto"
                (click)="handleScoringInfoClick()"
                *ngIf="!showScoringInfoTable"
            >
                Points
            </button>
            <div (click)="closeView()" class="close-icon pointer" *ngIf="!isMobileOS"></div>
        </div>
        <ng-container *ngIf="!showScoringInfoTable && attendeeScores?.length !== 0">
            <div class="winners-spotlight px-3" *ngIf="leaderboard?.resultsPublished">
                <app-leaderboard-winners-overlay
                    *ngIf="showWinnersSpotlight && attendeeScores?.length > 2"
                    [isSourceTable]="true"
                ></app-leaderboard-winners-overlay>
            </div>
            <button
                class="custom-button-revamp btn-secondary sm w-auto my-3 share-achivement-btn"
                (click)="onShareAchievementClick()"
                *ngIf="showShareAchievementButton && !callViewStateManagerService.isSpeaker"
            >
                <img src="assets/images/leaderboard/share_ach_icon.svg" />
                <span>Share Achievement</span>
            </button>
            <div class="leaderboard-table-container" #leaderboardTableContainer>
                <div class="leaderboard-table" #leaderboardTable>
                    <cdk-virtual-scroll-viewport
                        #leaderboardScrollViewport
                        itemSize="30"
                        class="speaker-virtual-scroll-viewport"
                        (scrolledIndexChange)="nextLeaderboardScoresList($event)"
                    >
                        <div
                            class="leaderboard-row listitem"
                            [class.local-user]="currentUserId === attendee?.userId"
                            *cdkVirtualFor="let attendee of attendeeScores; let i = index"
                        >
                            <div class="attendee-info-wrapper no-select">
                                <div class="profile-pic-wrapper" pBadge [value]="attendee.rank">
                                    <div
                                        class="card-profile rounded-circle profile-image"
                                        appAvatarWithInitials
                                        [name]="attendee.name"
                                        [userId]="attendee?.userId"
                                        *ngIf="attendee?.userId?.includes('gu')"
                                    ></div>
                                    <img
                                        draggable="false"
                                        [src]="getProfilePic(attendee?.userId)"
                                        *ngIf="!attendee?.userId?.includes('gu')"
                                        class="profile-pic profile-image"
                                    />
                                </div>
                                <div class="user-details-wrapper">
                                    <div class="user-info-primary-text user-name">{{ attendee?.name }}</div>
                                    <!-- <div class="user-info-secondary-text">Manager, RIL</div> -->
                                </div>
                                <div class="user-score">
                                    <img class="leaderboard-icon" src="assets/images/leaderboard/winner_cup.svg" />
                                    <div class="user-info-primary-text">{{ attendee?.score }}</div>
                                    <!-- <div class="user-info-secondary-text">Points</div> -->
                                </div>
                                <div
                                    class="chevron-image pointer"
                                    [class.chevron-image-down]="showScoreDistributionInfoIdx === -1"
                                    *ngIf="
                                        currentUserId === attendee?.userId &&
                                        leaderboard?.accumulateStatus !== 'prebroadcast'
                                    "
                                    (click)="toggleScoreDistribution(i)"
                                ></div>
                            </div>
                            <div
                                class="attendee-score-breakdown no-select"
                                *ngIf="showScoreDistributionInfoIdx === i && scoreDistribution?.length !== 0"
                            >
                                <!-- <ng-container [ngTemplateOutlet]="userScoreDistribution"></ng-container> -->
                                <div class="breakdown-header user-info-primary-text">Points you’ve earned so far</div>
                                <ng-container *ngFor="let actionRow of scoreDistribution">
                                    <div class="action-score-row" *ngIf="actionRow.score !== 0">
                                        <img
                                            class="action-icon"
                                            [src]="actionIcons[actionRow?.action] || actionIcons['default']"
                                        />
                                        <div class="action user-info-secondary-text">{{ actionRow.action }}</div>
                                        <div class="score user-info-secondary-text">{{ actionRow.score }} Pts</div>
                                    </div>
                                </ng-container>
                            </div>
                            <div
                                class="leaderboard-shoutout user-info-tertiary-text"
                                *ngIf="showScoreDistributionInfoIdx === i"
                            >
                                <ng-container *ngIf="scoreDistribution?.length !== 0">Keep it up!</ng-container> Keep
                                participating in the activities to earn more points.
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
            <button
                class="custom-button-revamp lg w-auto footer"
                [ngClass]="{
                    disabled: attendeeScores.length < 3,
                    'btn-primary': !leaderboard?.resultsPublished || !overlayDisplayState,
                    'btn-secondary': leaderboard?.resultsPublished && overlayDisplayState
                }"
                (click)="bottomFooterButtonClicked()"
                *ngIf="callViewStateManagerService.isOnlyHost || callViewStateManagerService.isEventCoHost"
                id="{{
                    !leaderboard?.resultsPublished
                        ? 'publish-results-btn'
                        : overlayDisplayState
                        ? 'hide-results-btn'
                        : 'display-results-btn'
                }}"
            >
                {{
                    !leaderboard?.resultsPublished
                        ? 'Publish Results'
                        : overlayDisplayState
                        ? 'Hide Results From Stage'
                        : 'Display Results On Stage'
                }}
            </button>
        </ng-container>
        <div class="no-ticker-info-wrapper" *ngIf="!attendeeScores?.length && !showScoringInfoTable">
            <img src="assets/images/leaderboard/leaderboard_empty_state_icon.svg" />
            <span>No attendee joined yet</span>
        </div>
        <ng-container *ngIf="showScoringInfoTable">
            <div class="attendee-score-breakdown points-system">
                <div class="scoring-info-header">
                    Earn points by participants in following activities during the event/session
                </div>
                <ng-container *ngFor="let actionRow of filteredRewardActions">
                    <div class="action-score-row" *ngIf="actionRow.score !== 0">
                        <img class="action-icon" [src]="actionIcons[actionRow?.title] || actionIcons['default']" />
                        <div class="action user-info-secondary-text">
                            {{ pointsMap[actionRow.title] || actionRow.title }}
                        </div>
                        <div class="score user-info-secondary-text">{{ actionRow.points }} Pts</div>
                    </div>
                </ng-container>
            </div>
            <div class="leaderboard-prizes-info">
                <ng-container *ngFor="let prize of leaderboard?.prizes; let i = index">
                    <div class="prize-info-container">
                        <div class="prize-rank user-info-primary-text">
                            {{ i + 1 }}<sup>{{ i === 0 ? 'st' : i === 1 ? 'nd' : 'rd' }}</sup> Prize
                        </div>
                        <div
                            class="prize-image-wrapper"
                            [ngClass]="{
                                pointer: prizePreviewIndex === -1,
                                isMobileOS: isMobileOS
                            }"
                        >
                            <div class="prize-container">
                                <img
                                    class="prize-image"
                                    [src]="prize?.customPrizeUrl"
                                    (click)="togglePrizeLargePreview(i)"
                                />
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="prize-info-container large-preview" *ngIf="prizePreviewIndex !== -1">
                    <div
                        class="prize-image-wrapper"
                        [ngClass]="{
                            isMobileOS: isMobileOS
                        }"
                    >
                        <div class="prize-container">
                            <img
                                class="prize-image"
                                [src]="leaderboard?.prizes[prizePreviewIndex]?.customPrizeUrl"
                                (click)="togglePrizeLargePreview(prizePreviewIndex)"
                            />
                            <img
                                class="close-icon pointer"
                                src="assets/images/leaderboard/ic_close.svg"
                                alt=""
                                (click)="togglePrizeLargePreview()"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div
            class="share-achievement-wrapper"
            *ngIf="callViewStateManagerService.showLeaderboardAchievement && isMobileOS"
        >
            <app-leaderboard-share-achievement></app-leaderboard-share-achievement>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>
