<div
    class="permissionDeniedDialog"
    [class.permissionOutsideEvent]="showSkipOption"
    [class.occupyFull]="!screenShare"
    [class.screenshare]="screenShare"
>
    <img
        *ngIf="!showSkipOption"
        class="closeIcon"
        [src]="!screenShare ? 'assets/img/icon_search_close.svg' : 'assets/img/close_icn.svg'"
        alt="Close icon"
        (click)="closePermissiondialog()"
    />
    <div class="permissionInfo">
        <h4 class="permissionHeading">{{ !screenShare ? 'Camera and Microphone' : 'Screenshare' }} access blocked</h4>
        <div class="permissionSubHeading">
            You need to allow access to your {{ !screenShare ? 'camera and mic' : 'screen sharing' }} on browser
        </div>
        <h5>Try {{ !screenShare ? 'one of these' : 'the following' }} steps to resolve this:</h5>
        <div *ngIf="screenShare" class="permissionSubHeading setting_path">
            Open System Preferences > Security & Privacy > Screen Recording
        </div>
        <div *ngIf="!screenShare" class="pageNumber">{{ pageNumber }}</div>
        <div *ngIf="screenShare" class="permissionSteps">
            Click on the lock icon <img src="assets/img/lock_icon.png" alt="lock icon" /> and toggle ON screen
            recording<br />
            <img
                class="stepsImage screenshare_img"
                width="706px"
                src="assets/img/screen_share_mac_permission.png"
                alt="permission steps"
            />
        </div>
        <div *ngIf="!screenShare">
            <div class="permissionSteps" *ngIf="pageNumber === 1">
                Click on the lock icon <img src="assets/img/lock_icon.png" alt="lock icon" /> and toggle ON camera and
                microphone <br />
                <img class="stepsImage" src="assets/img/permission_steps.png" alt="permission steps" />
            </div>
            <div class="permissionSteps" *ngIf="pageNumber === 2">
                Allow camera and mic permission for your browser in system preferences.
                <div class="osTabs">
                    <span [class.tab]="tabNumber === 1" (click)="toggleTab(1)" [ngStyle]="{ cursor: 'pointer' }"
                        >Mac</span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span [class.tab]="tabNumber === 2" (click)="toggleTab(2)" [ngStyle]="{ cursor: 'pointer' }"
                        >Windows</span
                    >
                </div>
                <div class="imageWrapper">
                    <img
                        class="changeImageButton"
                        src="assets/img/arrow_left_active.svg"
                        alt=""
                        *ngIf="pageInsideTab === 2"
                        (click)="changePageInsideTab(-1)"
                    />
                    <img
                        class="changeImageButton"
                        src="assets/img/arrow_left_disabled.svg"
                        alt=""
                        *ngIf="pageInsideTab === 1"
                    />
                    <img
                        src="assets/img/mac_permission_2.png"
                        alt="os permission steps"
                        *ngIf="tabNumber === 1 && pageInsideTab === 1"
                    />
                    <img
                        src="assets/img/apple_mic.png"
                        alt="os permission steps"
                        *ngIf="tabNumber === 1 && pageInsideTab === 2"
                    />
                    <img
                        src="assets/img/windows_permission_2.png"
                        alt="os permission steps"
                        *ngIf="tabNumber === 2 && pageInsideTab === 1"
                    />
                    <img
                        src="assets/img/windows_mic.png"
                        alt="os permission steps"
                        *ngIf="tabNumber === 2 && pageInsideTab === 2"
                    />
                    <img
                        class="changeImageButton"
                        src="assets/img/arrow_right_active.svg"
                        alt=""
                        *ngIf="pageInsideTab === 1"
                        (click)="changePageInsideTab(1)"
                    />
                    <img
                        class="changeImageButton"
                        src="assets/img/arrow_right_disabled.svg"
                        alt=""
                        *ngIf="pageInsideTab === 2"
                    />
                </div>
                <div class="bottomBarsWrapper">
                    <span class="bottomBars" [class.active]="pageInsideTab === 1"></span>
                    <span class="bottomBars" [class.active]="pageInsideTab === 2"></span>
                </div>
            </div>
            <div class="permissionSteps" *ngIf="pageNumber === 3">
                Close other apps those may be using camera and mic already<br />
                <img src="assets/img/other_apps.png" alt="other steps" />
            </div>
            <div class="permissionSteps" *ngIf="pageNumber === 4">
                Clear site cache and refresh this tab <br />
                <img src="assets/img/refresh_tabs.png" alt="refresh tabs" />
            </div>
        </div>
    </div>
    <div *ngIf="!screenShare" class="bottomNumber">{{ pageNumber }}/4</div>
    <div *ngIf="!screenShare" class="mt-3 mb-3">
        <button
            class="custom-button bg-secondary pageTogglebuttons"
            (click)="decreasePageNumber()"
            [class.disabled]="pageNumber === 1"
        >
            Previous</button
        >&nbsp;&nbsp;<button
            class="custom-button bg-primary pageTogglebuttons"
            (click)="increasePageNumber()"
            [class.disabled]="pageNumber === 4"
        >
            Next
        </button>
    </div>
    <div *ngIf="showSkipOption && !screenShare" class="skipButton" (click)="closePermissiondialog()">
        <div class="d-flex flex-column align-items-center">
            <div class="skipText"><a>Skip and Join Event</a></div>
            <div class="skipSubheading">If you skip, you won't be able to speak or enable camera during this event</div>
        </div>
    </div>
</div>
<div class="permissionMask" [class.bg]="screenShare"></div>
