import { Component, Input } from '@angular/core';
import { CalendarDateFormatter, CalendarWeekViewComponent } from 'angular-calendar';
import { WeekDay } from 'calendar-utils';
import { isSameDay } from 'date-fns';
import { CustomDateFormatter } from './event-week-view-date-formatter';

@Component({
    selector: 'je-calendar-week-view',
    template: `
        <div class="cal-week-view je-cal-week-view" role="grid">
            <mwl-calendar-week-view-header
                [days]="days"
                [locale]="locale"
                [customTemplate]="headerTemplateComp"
                (dayHeaderClicked)="dayHeaderClicked.emit($event)"
                (eventDropped)="eventDropped({ dropData: $event }, $event.newStart, true)"
                (dragEnter)="dateDragEnter($event.date)"
            >
            </mwl-calendar-week-view-header>
        </div>
        <ng-template
            #headerTemplateComp
            let-days="days"
            let-locale="locale"
            let-dayHeaderClicked="dayHeaderClicked"
            let-eventDropped="eventDropped"
            let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
            let-dragEnter="dragEnter"
        >
            <div class="cal-day-headers" role="row">
                <div
                    class="cal-header"
                    *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
                    [class.cal-past]="day.isPast"
                    [class.cal-today]="day.isToday"
                    [class.cal-future]="day.isFuture"
                    [class.cal-weekend]="day.isWeekend"
                    [class.cal-selected]="shouldSelect(day)"
                    [ngClass]="day.cssClass"
                    (mwlClick)="handleMwlClick($event, day)"
                    mwlDroppable
                    dragOverClass="cal-drag-over"
                    (drop)="
                        eventDropped.emit({
                            event: $event.dropData.event,
                            newStart: day.date
                        })
                    "
                    (dragEnter)="dragEnter.emit({ date: day.date })"
                    tabindex="0"
                    role="columnheader"
                >
                    <!-- <b>{{ day.date | calendarDate: 'weekViewColumnHeader':locale | uppercase }}</b
                    ><br /> -->
                    <span class="cal-header-date">{{ day.date | date: 'd' }}</span>
                    <br />
                    <span class="cal-header-date-month">{{ day.date | date: 'LLL':locale | uppercase }}</span>
                </div>
            </div>
        </ng-template>
    `,
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter
        }
    ],
    styleUrls: ['./event-week-view.component.scss']
})
export class CustomWeekView extends CalendarWeekViewComponent {
    selectedDate: WeekDay;

    handleMwlClick($event, day) {
        this.selectedDate = day;
        this.dayHeaderClicked.emit({ day: day, sourceEvent: $event });
    }

    shouldSelect(day) {
        return this.selectedDate
            ? day === this.selectedDate
            : this.viewDate
            ? isSameDay(day.date, this.viewDate)
            : day.isToday;
    }
}
