import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TEST_STATUS, DevicesManagerService, PreCallTestingService } from '../../services';
import { JMAudioInputTest, JMAudioOutputTest } from '@jiomeet/jm-test-rtc';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-audio-test',
    templateUrl: './audio-test.component.html',
    styleUrls: ['./audio-test.component.scss']
})
export class AudioTestComponent implements OnInit, OnDestroy {
    testAudioOutputDevice;
    testAudioInputDevice;
    availableMics;
    availableSpeakers;
    recordedAudioUrl;
    yourCurrentValue = 50;
    microphoneLevel = 0;
    speakerLevel = 0;
    microphoneResult;
    speakerResult;
    isModalOpen: boolean = false;
    selectedMicrophone;
    selectedSpeaker;
    micTestInProgress = false;
    speakerTestInProgress = false;
    micCountDown: number = 6;
    speakerCountDown: number = 6;
    speakerCountDownId?: NodeJS.Timeout;
    micCountDownId?: NodeJS.Timeout;
    private eventSubscription: Subscription;

    constructor(
        private deviceManagerService: DevicesManagerService,
        private preCallTestingService: PreCallTestingService
    ) {}

    ngOnInit(): void {
        this.registerRestartEvent();
        this.getDevices();
    }
    registerRestartEvent() {
        this.eventSubscription = this.preCallTestingService.getEvent().subscribe((data: string) => {
            if (data === 'Restart') {
                this.microphoneResult = null;
                this.speakerResult = null;
                this.speakerTestInProgress = false;
                this.micTestInProgress = false;
                this.isModalOpen = false;
                this.resetMicCountdown();
                this.resetSpeakerCountdown();
                this.testAudioInputDevice?.stop();
                this.testAudioOutputDevice?.stop();
            }
        });
    }
    getDevices() {
        this.availableSpeakers = this.deviceManagerService.speakers.map((speaker, idx) => {
            if (!speaker.name) speaker.name = `Speaker ${idx + 1}`;
            return speaker;
        });
        this.availableMics = this.deviceManagerService.microphones.map((mic, idx) => {
            if (!mic.name) mic.name = `Microphone ${idx + 1}`;
            return mic;
        });
        this.selectedMicrophone = this.availableMics[0]?.id;
        this.selectedSpeaker = this.availableSpeakers[0]?.id;
    }
    async startMicTest() {
        this.testAudioInputDevice = new JMAudioInputTest({
            deviceId: this.selectedMicrophone,
            duration: 5000,
            enableRecording: true
        });
        this.testAudioInputDevice.on('volume', (level) => {
            this.microphoneLevel = (level / 150) * 100;
            this.inputVolume(this.microphoneLevel);
        });
        try {
            this.micTestInProgress = true;
            this.resetMicCountdown();
            this.updateMicCountdown();
            this.microphoneResult = await this.testAudioInputDevice.startTest();
            this.preCallTestingService.micTestStatus = TEST_STATUS.PASS;
            this.microphoneLevel = 0;
        } catch (error) {
            this.preCallTestingService.micTestStatus = TEST_STATUS.FAILED;
        } finally {
            this.resetMicCountdown();
            this.micTestInProgress = false;
        }
    }

    async startSpeakerTest() {
        this.testAudioOutputDevice = new JMAudioOutputTest({
            deviceId: this.selectedSpeaker,
            testURI: this.microphoneResult.recordingUrl,
            doLoop: false
        });
        this.testAudioOutputDevice.on('volume', (level) => {
            this.speakerLevel = (level / 150) * 100;
            this.outputVolume(this.speakerLevel);
        });
        try {
            this.speakerTestInProgress = true;
            this.resetSpeakerCountdown();
            this.updateSpeakerCountdown();
            this.speakerResult = await this.testAudioOutputDevice.startTest();
            this.preCallTestingService.speakerTestStatus = TEST_STATUS.PASS;
            this.speakerLevel = 0;
        } catch (error) {
            this.preCallTestingService.speakerTestStatus = TEST_STATUS.FAILED;
        } finally {
            this.resetSpeakerCountdown();
            this.speakerTestInProgress = false;
        }
    }
    @ViewChildren('level') micLevels: QueryList<ElementRef>;
    @ViewChildren('audioOutputLevel') audioLevels: QueryList<ElementRef>;
    inputVolume(state) {
        let amountOfLevels;
        let range;
        this.micLevels.map((level) => {
            level.nativeElement.style.backgroundColor = '#e6e7e8';
        });
        amountOfLevels = Math.round(state / 10);
        range = this.micLevels.toArray().slice(0, amountOfLevels);
        range.map((el: ElementRef) => {
            el.nativeElement.style.backgroundColor = '#25AB21';
        });
    }
    outputVolume(state) {
        let amountOfLevels;
        let range;
        this.audioLevels.map((level) => {
            level.nativeElement.style.backgroundColor = '#e6e7e8';
        });
        amountOfLevels = Math.round(state / 10);
        range = this.audioLevels.toArray().slice(0, amountOfLevels);
        range.map((el: ElementRef) => {
            el.nativeElement.style.backgroundColor = '#25AB21';
        });
    }
    updateMicCountdown() {
        if (this.micCountDown > 0) {
            // Decrease the micCountDown value
            this.micCountDown--;

            // Update the micCountDown every second (1000 milliseconds)
            this.micCountDownId = setTimeout(() => {
                this.updateMicCountdown();
            }, 1000);
        } else {
            if (this.micCountDownId) {
                clearTimeout(this.micCountDownId);
            }
        }
    }
    updateSpeakerCountdown() {
        if (this.speakerCountDown > 0) {
            // Decrease the speakerCountDown value
            this.speakerCountDown--;

            // Update the speakerCountDown every second (1000 milliseconds)
            this.speakerCountDownId = setTimeout(() => {
                this.updateSpeakerCountdown();
            }, 1000);
        } else {
            if (this.speakerCountDownId) {
                clearTimeout(this.speakerCountDownId);
            }
        }
    }

    resetMicCountdown() {
        if (this.micCountDownId) {
            clearTimeout(this.micCountDownId);
        }
        this.micCountDown = 6;
    }
    resetSpeakerCountdown() {
        if (this.speakerCountDownId) {
            clearTimeout(this.speakerCountDownId);
        }
        this.speakerCountDown = 6;
    }
    onCloseModalEvent() {
        this.isModalOpen = false;
    }
    viewResult() {
        this.isModalOpen = true;
    }
    selectMicrophone(event: any) {
        this.selectedMicrophone = event.target.value;
    }
    selectSpeaker(event: any) {
        this.selectedSpeaker = event.target.value;
    }

    ngOnDestroy(): void {
        this.eventSubscription.unsubscribe();
    }
}
