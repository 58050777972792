import { Injectable } from '@angular/core';

import isEmail from 'validator/lib/isEmail';
import ipValidator from 'ip-validator/lib/validator';
import { FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    isEmail(email, options = {}) {
        return isEmail(email || '', options);
    }

    isPhoneNumber(phone) {
        return /^\d{10}$/.test(phone);
    }

    isIpAddress(ipAddress) {
        return ipValidator.ipv4(ipAddress) || ipValidator.ipv6(ipAddress);
    }

    noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    isFormControlValueAnEmail(control: FormControl) {
        return isEmail(control.value || '');
    }

    isFormControlValueAPhoneNumber(control: FormControl) {
        return /^\d{10}$/.test(control.value);
    }
}
