<section id="signup-area" class="signup-area section-bg">
    <div class="container">
        <div class="row desktop-sol-view">
            <div
                class="
                    col-lg-8 col-md-6
                    d-flex
                    flex-column
                    justify-content-center
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <h1 class="jiotype-black-white-64px">We have something for everybody!</h1>
                <p class="jiotype-medium-white-16px-2">
                    Whether you are a creator, an influencer, blogger, artist or are simply trying to reach a large
                    audience, JioEvents has a solution for you
                </p>
                <div class="d-flex justify-content-center justify-content-lg-start cus-point">
                    <label
                        aria-label="We have something for everybody!,Whether you are a creator, an influencer, blogger, artist or are simply trying to reach a large
                    audience, JioEvents has a solution for you, Get Started"
                        tabindex="0"
                        (click)="tryForFree()"
                        (keyup.enter)="tryForFree()"
                        class="btn-sign-up scrollto jiotype-bold-te-papa-green-16px cursor-pointer"
                        >Schedule A Demo</label
                    >
                </div>
            </div>
            <div
                class="col-lg-4 col-md-6 order-1 order-md-2 order-lg-2 hero-img aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <div class="image-stack">
                    <img
                        src="assets/img/website/rectangle-7@2x.png"
                        class="img-fluid solution-sign-img stack-img-one"
                        alt=""
                    />
                    <img
                        src="assets/img/website/vector-8-1@2x.png"
                        class="img-fluid solution-sign-img stack-img-two"
                        alt=""
                    />
                    <img
                        src="assets/img/website/pexels-ketut-subiyanto-4584566-removebg-preview-1@2x.png "
                        class="img-fluid solution-sign-img stack-img-three"
                        alt=""
                    />
                </div>
            </div>
        </div>
        <div class="row mobile-sol-view">
            <div class="col-12 d-flex flex-column justify-content-center pt-4">
                <h1 class="jiotype-black-white-64px title-text-sol">We have something for everybody!</h1>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center pt-4 img-sol-first">
                <div class="image-stack">
                    <img src="assets/img/website/Group_139277.png" />
                </div>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center pt-4">
                <div class="d-flex justify-content-center justify-content-lg-start cus-point try-btn-contain">
                    <label
                        aria-label="Get Started"
                        tabindex="0"
                        (click)="tryForFree()"
                        (keyup.enter)="tryForFree()"
                        class="btn-sign-up scrollto jiotype-bold-te-papa-green-16px cursor-pointer"
                        >Schedule A Demo</label
                    >
                </div>
                <p class="jiotype-medium-white-16px-2">
                    Whether you are a creator, an influencer, blogger, artist or are simply trying to reach a large
                    audience, JioEvents has a solution for you
                </p>
            </div>
        </div>
    </div>
</section>
<section id="features" class="features section-bg">
    <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="row">
            <ng-container *ngFor="let solution of solutionsArray">
                <div
                    class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                >
                    <div class="icon-box feature-box" (click)="solutionClick(solution)">
                        <div class="icon icon-circle">
                            <img class="icon-feature-img" src="assets/img/website/{{ solution.image }}" />
                        </div>
                        <h4>
                            <p tabindex="0" class="jiotype-bold-white-18px">{{ solution.title }}</p>
                        </h4>
                        <p tabindex="0" class="jiotype-medium-white-14px">{{ solution.description }}</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<!-- <section id="reports" class="reports section-bg">
    <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title report-title jiotype-black-white-40px">
            <p aria-label="Understand your audience with data" tabindex="0">Understand your audience with data</p>
        </div>
        <div class="row">
            <ng-container *ngFor="let report of reports">
                <div
                    class="col-xl-4 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                >
                    <div class="icon-box feature-box" (click)="reportsCliked(report)">
                        <div class="icon icon-circle">
                            <img class="icon-feature-img" src="assets/img/website/{{ report.image }}" />
                        </div>
                        <h4>
                            <span routerLink="/report" outerLinkActive="active-link" class="jiotype-bold-white-18px">{{
                                report.title
                            }}</span>
                        </h4>
                        <p routerLink="/solution" class="jiotype-medium-white-14px">{{ report.description }}</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section> -->
<section id="call-to-action" class="call-to-action section-bg">
    <div class="container">
        <div class="row explore-total-area">
            <div
                class="
                    col-lg-8 col-md-6
                    d-flex
                    flex-column
                    explore-left-area
                    pt-4 pt-lg-0
                    order-2 order-md-1 order-lg-1
                    aos-init aos-animate
                "
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div class="explore-main-txt">
                    <h1 class="jiotype-black-white-40px join-digital-txt">
                        Join the digital revolution and start your creator journey with the right platform!
                    </h1>
                </div>
                <div class="cus-point">
                    <!-- link sing in page  -->
                    <label
                        aria-label=" Join the digital revolution and start your creator journey with the right platform!, Explore JioEvents"
                        tabindex="0"
                        (click)="exploreJioevents()"
                        (keyup.enter)="exploreJioevents()"
                        class="btn-sign-up scrollto jiotype-bold-te-papa-green-16px cursor-pointer"
                        >Explore JioEvents</label
                    >
                </div>
            </div>
            <div
                class="col-lg-4 col-md-6 order-1 order-md-2 order-lg-2 customize-img-container aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="200"
            >
                <img src="assets/img/website/explore_img_3.png" class="img-fluid support-call-customize-img" alt="" />
            </div>
        </div>
    </div>
</section>
