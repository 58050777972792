<div
    class="chat-bubble-container"
    [class.mobile-chat-bubble-container]="isMobileOS"
    [class.right-aligned]="ownMsg && !isPined"
    [class.center-aligned]="isPined"
    [class.topMsg]="messageData.isTop"
    longPress
    (mouseLongPress)="longPressChat()"
>
    <ng-container *ngIf="!isPined">
        <div class="msg-info-contanier" [class.flex-end]="ownMsg" [class.topMsg]="messageData.isTop">
            <div *ngIf="!ownMsg" class="msg-sender-avatar">
                <div appAvatarWithInitials [name]="messageData?.creatorsName"></div>
            </div>
            <div class="sender-info">
                <span *ngIf="!ownMsg" [class.sendnamewithType]="messageData?.userType" class="sender-name">{{
                    messageData?.creatorsName
                }}</span
                ><span *ngIf="!ownMsg && messageData?.userType" class="sender-type">{{
                    messageData?.userType | titlecase
                }}</span>
                <span *ngIf="ownMsg" class="sender-name">You</span>
            </div>
            <div class="msg-time">• {{ messageData?.cOn | date: 'HH:mm' | convert24to12format }}</div>
        </div>
        <div class="msg-option-container" [class.ownmsg-option-container]="ownMsg">
            <div
                class="msg-content"
                [class.own-msg]="ownMsg"
                [class.other-user-msg]="!ownMsg"
                [class.editMessageContent]="isEditMessgae"
                #msg_container
            >
                <div
                    *ngIf="!isEditMessgae"
                    class="msg-text"
                    [innerHTML]="messageData?.messageComponent?.text?.trim() | linkify | newline"
                ></div>
                <ng-container *ngIf="isEditMessgae">
                    <div class="chat-input-area">
                        <app-chat-markdown
                            [showMarkdown]="showMarkdown"
                            [selectedEmoji]="selectedEmoji"
                            (sendmessage)="updateMessage()"
                            [insideChatPanel]="'true'"
                            [initalText]="messageData?.messageComponent?.text"
                        >
                        </app-chat-markdown>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="isEditMessgae && this.chatMarkdownComponent?.quill" class="text-right text-count-area limit">
                {{ this.chatMarkdownComponent?.quill.getText().replace('\n', '')?.length }}/200
            </div>
            <p-overlayPanel
                #op
                appendTo="body"
                my="left"
                [styleClass]="'chat-more-options'"
                [style]="{ width: 'auto', 'max-width': '200px' }"
            >
                <ng-template pTemplate>
                    <div class="overlaySettings">
                        <div
                            *ngIf="isHost && !isLiveMessgaePined"
                            class="overlay-setting-item"
                            (click)="pinMessageConfirmation(); op.hide($event)"
                        >
                            Pin
                        </div>
                        <div
                            *ngIf="isHost && isLiveMessgaePined"
                            class="overlay-setting-item"
                            (click)="unpinMessageConfirmation(); op.hide($event)"
                        >
                            Unpin
                        </div>
                        <div
                            *ngIf="isHost || ownMsg"
                            class="overlay-setting-item"
                            (click)="deleteMessageConfirmation(); op.hide($event)"
                        >
                            Delete For All
                        </div>
                    </div>
                </ng-template>
            </p-overlayPanel>
            <ng-container *ngIf="!isEditMessgae && shouldShowLiveChatMoreOptions">
                <div
                    class="more-setting-icon"
                    [class.own-msg-moreIcon]="ownMsg"
                    [class.other-msg-moreIcon]="!ownMsg"
                    *ngIf="!hideMoreOptions && !isLongPressedSelected && !isMobileOS"
                    [class.moreSettingShow]="showMoreOptions"
                    (click)="showChatsettings(msg_container, $event); op.toggle($event)"
                >
                    <div class="more-icon" (outside)="hideSettings()">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </div>
                </div>
                <div
                    *ngIf="isLongPressedSelected && ownMsg"
                    class="delete-container"
                    (click)="deleteMessageConfirmation()"
                >
                    <span class="delete-icon-container">
                        <img src="assets/images/event-call-options/mobile_delete_icon.svg" />
                    </span>
                    <span class="delete-text">Delete</span>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="isPined">
        <div class="pined-viewall">
            <div class="more-pined-message" *ngIf="pinnedMessageLength > 1">
                <div class="viewall-textarea" (click)="showAllPinedMessage()">
                    <span>View all ({{ pinnedMessageLength }})</span
                    ><img src="assets/images/event-call-options/pined_icon.svg" />
                </div>
            </div>
            <div class="msg-info-contanier flex-end" [class.pined-info-container]="pinnedMessageLength > 1">
                <div class="msg-sender-avatar pin-icon">
                    <img src="assets/images/event-call-options/pined_down-arrow.svg" />
                </div>
                <div class="sender-info">
                    <span *ngIf="!ownMsg" class="sender-name">{{ messageData?.creatorsName }}</span>
                    <span *ngIf="ownMsg" class="sender-name">You</span>
                </div>
                <div class="msg-time">• {{ messageData?.cOn | date: 'HH:mm' | convert24to12format }}</div>
            </div>
        </div>

        <div class="msg-content pinedMessage" #msg_container>
            <div *ngIf="isHost" class="more-setting-icon pined-more-icon" [class.moreSettingShow]="showMoreOptions">
                <div class="more-icon" (click)="showChatsettings(msg_container)" (outside)="hideSettings()">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </div>
            </div>
            <div
                class="setting-container pin-more-container"
                [class.single-msg-container-pin]="pinnedMessageLength > 0"
                *ngIf="showMoreOptions"
            >
                <div *ngIf="isHost" class="setting-item" (click)="unpinMessageConfirmation()">Unpin Message</div>
            </div>
            <div
                class="msg-text"
                [class.pin-msg-ecl]="pinnedMessageLength > 0"
                [innerHTML]="messageData?.messageComponent?.text?.trim() | linkify | newline"
            ></div>
        </div>
    </ng-container>
</div>
