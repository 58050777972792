<div class="popup-component" [class.old-share-event-popup]="!useRevampStyling">
    <div class="bg" (click)="cancel()"></div>
    <div class="popup">
        <div class="popup-header">
            <div class="action-btns">
                <button class="back-button mb-1" (click)="goBack()" *ngIf="showBackButton">
                    <img src="assets/img/webinar/back_icn.svg" />
                </button>
                <div class="close ml-auto" *ngIf="useRevampStyling">
                    <img src="assets/img/webinar/side-nav/icon_Close.svg" (click)="cancel()" />
                </div>
            </div>
            <h3 class="header" [class.m-0]="useRevampStyling">
                {{
                    'webinar-share-details.share_details'
                        | translate: { meetingType: isSessionEnabled ? 'Session' : 'Event' }
                }}
            </h3>
            <div class="close ml-auto" *ngIf="!useRevampStyling">
                <img src="assets/img/webinar/side-nav/icon_Close.svg" (click)="cancel()" />
            </div>
        </div>
        <div class="popup-body">
            <div class="topic mt-2">{{ isSessionEnabled ? 'Session' : 'Event' }} Title</div>
            <div
                [ngClass]="enableMarkdown ? 'topic-text-markdown' : 'topic-text'"
                [class.mt-1]="!useRevampStyling"
                [innerHTML]="sanitizeText(eventDetails?.topicMarkdown?.text || eventDetails?.topic) | sanitizeHtml"
            >
                <!-- {{eventDetails?.topic}} -->
            </div>
            <div class="link-section">
                <div class="info-share-link-wrapper" *ngIf="useRevampStyling; else oldShareEventInfo">
                    <div class="event-link-detail" *ngFor="let idx of shareLinsArray">
                        <div class="right-section">
                            <div class="role-text">{{ idx === 0 ? 'Speaker' : 'Attendee' }}</div>
                            <button
                                class="
                                    custom-button-revamp
                                    btn-confirm-primary
                                    sm
                                    m-0
                                    w-auto
                                    invitation-btn
                                    d-flex
                                    align-items-center
                                "
                                id="copyEventInvite-btn"
                                *ngIf="isMobile && idx === 1 && attendeeShortUrlExists"
                                (click)="copyAttendeeShortenedLink()"
                            >
                                <img
                                    src="assets/images/share-event-popup/copy-link.svg"
                                    alt=""
                                    *ngIf="idx === 1"
                                    #attendeeShortLinkIcon0
                                />
                                <span *ngIf="idx === 1" #attendee_short_text class="pl-2">Copy Shortened Link</span>
                            </button>
                            <div class="links-wrapper">
                                <button
                                    class="
                                        custom-button-revamp
                                        btn-confirm-primary
                                        sm
                                        m-0
                                        w-auto
                                        invitation-btn
                                        d-flex
                                        align-items-center
                                        shorten-btn-browser
                                    "
                                    id="copyEventInvite-btn"
                                    *ngIf="!isMobile && idx === 1 && attendeeShortUrlExists"
                                    (click)="copyAttendeeShortenedLink()"
                                >
                                    <img
                                        src="assets/images/share-event-popup/copy-link.svg"
                                        alt=""
                                        *ngIf="idx === 1"
                                        #attendeeShortLinkIcon0
                                    />
                                    <span *ngIf="idx === 1" #attendee_short_text class="pl-2">Copy Shortened Link</span>
                                </button>
                                <button
                                    class="
                                        custom-button-revamp
                                        btn-confirm-primary
                                        sm
                                        m-0
                                        w-auto
                                        link-btn
                                        d-flex
                                        align-items-center
                                        shorten-btn-browser
                                    "
                                    [attr.id]="idx === 0 ? speakerLink : attendeeInviteLink"
                                    (click)="idx === 0 ? copy() : copyAttendeeLink()"
                                >
                                    <img
                                        src="assets/images/share-event-popup/copy-link.svg"
                                        alt=""
                                        #copyLinkIcon0
                                        *ngIf="idx === 0"
                                    />
                                    <img
                                        src="assets/images/share-event-popup/copy-link.svg"
                                        alt=""
                                        #copyLinkIcon1
                                        *ngIf="idx === 1"
                                    />
                                    <span *ngIf="idx === 0" #url_text class="pl-2">Copy Link</span>
                                    <span *ngIf="idx === 1" #url_attendee class="pl-2">Copy Link</span>
                                </button>
                                <button
                                    class="
                                        custom-button-revamp
                                        btn-confirm-secondary
                                        sm
                                        m-0
                                        w-auto
                                        invitation-btn
                                        d-flex
                                        align-items-center
                                        shorten-btn-browser
                                    "
                                    id="copyEventInvite-btn"
                                    [ngClass]="{
                                        speakerInvite: speakerInvitationActive && idx === 0,
                                        attendeeInvite: attendeeInvitationActive && idx === 1
                                    }"
                                    (click)="copyInvitation(idx === 0 ? 'speaker' : 'attendee')"
                                >
                                    <img
                                        src="assets/images/share-event-popup/copied_dark_green.svg"
                                        alt=""
                                        *ngIf="idx === 0"
                                        #attendeeLinkIcon0
                                    />
                                    <img
                                        src="assets/images/share-event-popup/copied_dark_green.svg"
                                        alt=""
                                        *ngIf="idx === 1"
                                        #attendeeLinkIcon1
                                    />
                                    <span *ngIf="idx === 0" #invitation_text class="pl-2">Copy Invitation</span>
                                    <span *ngIf="idx === 1" #invitation_attendee class="pl-2">Copy Invitation</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="enableSocialShare && !isRestrictedEvent">
                    <div class="topic mt-3">Share via</div>
                    <div class="link-wrapper mt-1">
                        <div class="social-share-wrapper d-flex">
                            <div
                                class="image-wrapper pointer fb-share"
                                (click)="shareOnMail()"
                                pTooltip="E-Mail"
                                tooltipPosition="bottom"
                                tooltipStyleClass="tooltip-revamp"
                            >
                                <img src="assets/images/share-event-popup/email.svg" alt="" />
                            </div>
                            <div
                                class="image-wrapper pointer fb-share"
                                (click)="shareOnWhatsapp()"
                                pTooltip="Whatsapp"
                                tooltipPosition="bottom"
                                tooltipStyleClass="tooltip-revamp"
                            >
                                <img src="assets/images/share-event-popup/whatsapp.svg" alt="" />
                            </div>
                            <div
                                class="image-wrapper pointer fb-share"
                                (click)="shareOnFacebook()"
                                pTooltip="Facebook"
                                tooltipPosition="bottom"
                                tooltipStyleClass="tooltip-revamp"
                            >
                                <img src="assets/images/share-event-popup/facebook-dark-icn.svg" alt="" />
                            </div>
                            <div
                                class="image-wrapper pointer twitter-share"
                                (click)="shareOnTwitter()"
                                pTooltip="Twitter"
                                tooltipPosition="bottom"
                                tooltipStyleClass="tooltip-revamp"
                            >
                                <img src="assets/images/share-event-popup/twitter-logo-new.svg" alt="" />
                            </div>
                            <div
                                class="image-wrapper pointer linkedin-share"
                                (click)="shareOnLinkedin()"
                                pTooltip="LinkedIn"
                                tooltipPosition="bottom"
                                tooltipStyleClass="tooltip-revamp"
                            >
                                <img src="assets/images/share-event-popup/linkedin-dark-icn.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="invitation">
        <app-event-invitation
            [id]="'event-invitation'"
            [meeting]="eventDetails"
            [invitationType]="invitationType"
        ></app-event-invitation>
    </div>
</div>

<ng-template #oldShareEventInfo>
    <ng-container *ngIf="(isRestrictedEvent && isHostCoHost) || !isRestrictedEvent">
        <div class="topic mt-3">
            {{ 'webinar-share-details.attendee_link' | translate }}
        </div>
        <div class="link-wrapper mt-1">
            <div class="resize">
                <button
                    class="custom-button-revamp btn-confirm-primary sm m-0 w-auto link-btn d-flex align-items-center"
                    [attr.id]="attendeeInviteLink"
                    (click)="copyAttendeeLink()"
                >
                    <span #url_text class="pl-2">Copy Link</span>
                </button>
            </div>
            <div class="d-flex">
                <button
                    class="
                        custom-button-revamp
                        btn-confirm-secondary
                        sm
                        m-0
                        w-auto
                        invitation-btn
                        d-flex
                        align-items-center
                    "
                    id="copyEventInvite-btn"
                    [ngClass]="{
                        attendeeInvite: attendeeInvitationActive
                    }"
                    (click)="copyInvitation('attendee')"
                >
                    <img
                        src="assets/images/share-event-popup/copied_dark_green.svg"
                        alt=""
                        *ngIf="idx === 1"
                        #attendeeLinkIcon1
                    />
                    <span #invitation_attendee class="pl-2">Copy Invitation</span>
                </button>
            </div>
        </div>
    </ng-container>
    <div
        class="topic mt-3"
        *ngIf="(eventDetails?.meetingUrl || eventDetails?.meetingInfo?.meetingUrl) && !isPreRecorded"
    >
        {{ 'webinar-share-details.speaker_link' | translate }}
    </div>
    <div
        class="link-wrapper mt-1"
        *ngIf="(eventDetails?.meetingUrl || eventDetails?.meetingInfo?.meetingUrl) && !isPreRecorded"
    >
        <div class="resize">
            <a
                class="shareLink"
                href="{{ shareUrlInfo?.url || eventDetails?.meetingUrl || eventDetails?.meetingInfo?.meetingUrl }}"
                target="_blank"
            >
                {{ shareUrlInfo?.url || eventDetails?.meetingUrl || eventDetails?.meetingInfo?.meetingUrl }}
            </a>
        </div>
        <div class="d-flex position-relative">
            <span class="copy-tooltip" *ngIf="showSpeakerCopiedTooltip">Copied!</span>
            <span
                class="pointer copy-icon"
                (click)="copyInvitation('speaker')"
                pTooltip="Copy invitation"
                tooltipPosition="bottom"
            ></span>
        </div>
    </div>
</ng-template>
