<div class="logos-ticker logo-container-wrapper py-4">
    <div class="logos-ticker-container">
        <div *ngFor="let itemUrl of colouredImage">
            <img src="{{ itemUrl }}" alt="" class="logos-height logoImage" />
        </div>
        <div *ngFor="let itemUrl of colouredImage">
            <img src="{{ itemUrl }}" alt="" class="logos-height logoImage" />
        </div>
    </div>
</div>
