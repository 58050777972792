import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthService, ValidationService, GaDiagnosticService, DiagnosticService } from './../../core';
import { REG_EX } from 'src/app/constants';

@Component({
    selector: 'app-forgot-password-v1',
    templateUrl: './forgot-password-v1.component.html',
    styleUrls: ['./forgot-password-v1.component.scss']
})
export class ForgotPasswordV1Component implements OnInit {
    resetpasswordForm: FormGroup;
    errors: any = {};
    loading = false;
    mailSent = false;
    showUI = false;
    enableProceedBtn: boolean = false;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private validationService: ValidationService,
        private gaService: GaDiagnosticService,
        private diagnosticService: DiagnosticService
    ) {}

    ngOnInit() {
        this.resetpasswordForm = this.fb.group({
            email: [null, [Validators.required]]
        });
        this.gaService.sendPageView({
            page_title: 'forgot_password_page',
            page_path: '/forgot-password'
        });
        this.resetpasswordForm
            .get('email')
            .valueChanges // .pipe(pairwise())
            .subscribe((next) => {
                this.enableProceedBtn = this.validateUsername(next, false);
            });

        if (this.authService.getIsAuthenticated()) {
            this.router.navigate(['/dashboard']);
            return;
        }

        this.showUI = true;
    }

    validate() {
        this.errors = {};
        if (!this.validationService.isEmail(this.resetpasswordForm.value.email)) {
            this.errors.email = 'Please enter a valid Email';
            return false;
        }
        return true;
    }

    validateUsername(value = '', reportError = true) {
        const val = value || this.resetpasswordForm.value.email;
        if (!(this.validationService.isEmail(val) || val.match(/^\d{10}$/))) {
            if (reportError) {
                this.errors.username = 'Please enter valid email or phoneNo.';
            }
            return false;
        }
        return true;
    }

    submitUsername() {
        if (!this.validate() || this.loading) {
            return;
        }
        this.sendEmail();
    }

    sendEmail() {
        this.loading = true;
        this.authService.forgotPassword({ emailId: this.resetpasswordForm.value.email }).subscribe(
            () => {
                this.loading = false;
                this.mailSent = true;
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Sign In',
                        eventAction: 'Forgot Password',
                        eventType: 'app_event',
                        endpoint: '/api/invokeResetPassword',
                        status: 'success',
                        data: { email: this.resetpasswordForm.value.email }
                    })
                    .subscribe();
            },
            (err) => {
                this.errors.global = err.error && err.error.errors;
                this.loading = false;
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Sign In',
                        eventAction: 'Forgot Password',
                        eventType: 'api_failure',
                        endpoint: '/api/invokeResetPassword',
                        status: 'failed',
                        data: err.error
                    })
                    .subscribe();
            }
        );
    }

    navigateToSignUp() {
        this.router.navigate(['/signup']);
    }

    navigateToSignIn() {
        this.mailSent = false;
        this.router.navigate(['/login']);
    }

    reset() {
        this.mailSent = false;
    }

    editEmail() {
        this.mailSent = false;
    }
}
