import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { UserService, DiagnosticService, UtilService, EventEmitterService } from 'src/app/core';
import { APP_EVENTS } from 'src/app/constants/app-events';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-profilesettings',
    templateUrl: './profilesettings.component.html',
    styleUrls: ['./profilesettings.component.scss']
})
export class ProfilesettingsComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();

    user;
    form: FormGroup;
    advancedOptionsCollapsed = false;
    errors: any = {};
    loading;
    isDefaultPic;
    originalImage;
    userId;
    fullName;
    isNameBeingEdited = false;
    isChangePassword = false;
    isChangeProfilePicture = false;
    showProfile = true;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private userService: UserService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.userService.getUser().subscribe((user) => {
            this.isDefaultPic = !user?.imageMeta && !user?.imageMeta?.path;
            this.user = user;
            this.originalImage = this.userService.getProfilePicPath(user._id);
            this.fullName = `${this.user.name} ${this.user.lname}`;
        });

        this.form = this.fb.group({
            firstName: this.user.name || '',
            lastName: this.user.lname || ''
        });
    }

    navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }

    toggleNameEdit() {
        this.isNameBeingEdited = !this.isNameBeingEdited;
        this.fullName = `${this.user.name} ${this.user.lname}`;
        this.form.reset();
        this.errors.global = '';
    }

    getUpdatedUserName() {
        this.userService.getUser().subscribe((user) => {
            this.isDefaultPic = !user?.imageMeta && !user?.imageMeta?.path;
            this.user = user;
            this.originalImage = this.userService.getProfilePicPath(user._id);
            this.fullName = `${this.user.name} ${this.user.lname}`;
        });
    }

    changeName() {
        this.errors = {};
        const formValue = this.form.value;
        let fname = formValue.firstName.trim();
        if (fname.length === 0) fname = this.user.name;
        let lname = formValue.lastName.trim();
        if (lname.length === 0) lname = this.user.lname;

        if (
            this.form.value.firstName.length < 2 ||
            this.form.value.firstName.length > 20 ||
            !/^[0-9a-zA-Z]+$/.test(this.form.value.firstName)
        ) {
            this.errors.global = this.translateService.instant(
                'tostrs.first_name_should_contain_only_lowercase_uppercase_or_numeric_digit_with_minimum_2_and_maximum_20_characters'
            );
            return;
        }

        if (
            this.form.value.lastName.length < 2 ||
            this.form.value.lastName.length > 20 ||
            (this.form.value.lastName && !/^[0-9a-zA-Z]+$/.test(this.form.value.lastName))
        ) {
            this.errors.global = this.translateService.instant(
                'tostrs.last_name_should_contain_only_lowercase_uppercase_or_numeric_digit_with_minimum_2_and_maximum_20_characters'
            );
            return;
        }

        const fullName = `${fname} ${lname}`;
        this.userService
            .updateProfile({
                name: fname,
                lname: lname
            })
            .subscribe(
                () => {
                    this.toastrService.success(
                        this.translateService.instant('tostrs.user_profile_updated_successfully')
                    );
                    // Instantly updating name in UI without waiting for API response
                    this.fullName = fullName;
                    this.toggleNameEdit();
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NAME_CHANGED,
                        data: null
                    });
                },
                (err) => {
                    if (err?.error?.customCode) {
                        this.errors.global =
                            err.error?.errors ||
                            this.translateService.instant(
                                'tostrs.profile_update_failed_please_try_again_after_some_time'
                            );
                    }
                }
            );
    }

    toggleChangePassword() {
        this.isChangePassword = !this.isChangePassword;
        this.showProfile = !this.showProfile;
    }

    toggleChangeProfilePicture() {
        this.isChangeProfilePicture = !this.isChangeProfilePicture;
        this.getUpdatedUserName();
        this.showProfile = !this.showProfile;
    }

    removeProfilePicture() {
        this.userService.deleteProfilePicture().subscribe(
            () => {
                this.toastrService.success(
                    this.translateService.instant('tostrs.profile_picture_deleted_successfully')
                );
                this.getUpdatedUserName();
            },
            () => {
                this.toastrService.error(
                    this.translateService.instant('tostrs.error_deleting_profile_pic_please_try_again_later')
                );
            }
        );
    }
}
