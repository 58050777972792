interface Scripts {
    name: string;
    src: string;
    loaded: false;
}
export const VirtualBackgroundScripts: Scripts[] = [
    {
        name: 'camera',
        src: 'assets/lib/mediapipe/camera.js',
        loaded: false
    },
    {
        name: 'control_util',
        src: 'https://cdn.jsdelivr.net/npm/@mediapipe/control_utils/control_utils.js',
        loaded: false
    },
    {
        name: 'drawing_util',
        src: 'https://cdn.jsdelivr.net/npm/@mediapipe/drawing_utils/drawing_utils.js',
        loaded: false
    },
    {
        name: 'selfie_segmentation',
        src: 'https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/selfie_segmentation.js',
        loaded: false
    }
];
