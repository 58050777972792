export const environment = {
    production: false,
    local: false,
    HOST_URL: 'https://stage2.jioevents.com/',
    BASE_URL: 'https://stage2.jioevents.com/api',
    CONF_URL: 'https://stage2.jioevents/jioconf',
    JIO_CLOUD: {
        HOST: 'https://pp-apis.tejdrive.com',
        HOST_UPLOAD: 'https://pp-upload.tejdrive.com',
        API_KEY: '0b374bf6-26b8-4b5d-ba17-5167ce583853',
        APP_SECRET: 'YjczODIzYzctNTAyYS00YmJjLTkzYTUtYWMzMjVjYmM1MmQz'
    },
    DIGNOSTIC_API_KEY: 'fc0db939-e7ae-4d91-9ede-8c0f6670fccb',
    DIGNOSTIC_API_SECRET: '6a43dfab88a1626ba233581610cf1c5b87d4546d83e86d85fb9f52512f54',
    HAPTIC_CLIENT_ID: '6c6b13304bbfa790d48abf449984b853b6d377ba'
};
