import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppService } from './app.service';
import { RestService } from './rest.service';
import pica from 'pica';
import * as _ from 'lodash';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { baseSceneView } from 'src/app/events-studio/scene/views/baseSceneView';
import { AppLoggerService } from './app-logger.service';
import { WebinarConferenceService } from 'src/app/conference/services';

type sidePanelEmitType = {
    type: string;
    event: string;
};
@Injectable({
    providedIn: 'root'
})
export class EventsPlusService {
    layoutDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedTemplate = 1;
    layoutDetails;
    canvasWidth = 900;
    canvasHeight = 506.25;
    showCustomAttendeeLayout: boolean = false;
    showCustonLayoutSpeakerSide: boolean = false;
    showStudioHost = false;
    disableLayoutSwitch: boolean = false;
    scenePlayingNow = 0;
    UITypeChanged: Subject<any> = new Subject();
    broadcastingState = 'webinarnotstarted';
    templateInfo;
    scenesList: baseSceneView[] = [];
    isPublishing = false;
    processingUnpublish = false;
    selectedScene: number = 1;
    processingBroadcast = false;
    public isPaused = true;
    public isStopped = true;
    private pica = pica();
    assets = {
        images: [],
        slideShow: [],
        videos: []
    };
    studioFeatureConfig;
    showRightPanel: boolean = true;
    togglePanelSubject: BehaviorSubject<sidePanelEmitType> = new BehaviorSubject({
        type: 'layers',
        event: 'showPanel'
    });
    showSpeakersListOverlay: boolean;
    meetingId: string;

    constructor(
        private restService: RestService,
        private appService: AppService,
        private webinarService: WebinarService,
        private http: HttpClient,
        private userService: UserService,
        private appLoggerService: AppLoggerService,
        private webinarConfService: WebinarConferenceService
    ) {
        this.studioFeatureConfig = this.appService.getConfigVariable('EVENTS_STUIO');
    }

    toggleCustomUI() {
        this.showStudioHost = !this.showStudioHost;
        this.UITypeChanged.next(true);
    }

    setBroadcastingState(state) {
        this.broadcastingState = state;
        this.isPaused = this.broadcastingState === 'webinarstopped' || this.broadcastingState === 'webinarpaused';
        this.isStopped = this.broadcastingState === 'webinarended' || this.broadcastingState === 'webinarnotstarted';
    }

    publishCustomLayout(meetingId, sceneNumber) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/customLayout/publish/${meetingId}/${sceneNumber}`
        );
    }

    saveCustomLayout(data, meetingId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/customLayout/save/${meetingId}`,
            data
        );
    }

    unpublishLayout(meetingId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/customLayout/unpublish/${meetingId}`
        );
    }

    getTogglePanelSubject() {
        return this.togglePanelSubject;
    }

    togglePanel(selectedElement) {
        // if (selectedElement.type === 'screenshare') {
        //     this.hideRightPanel();
        //     return;
        // }
        this.showRightPanel = true;
        this.togglePanelSubject.next({
            type: this.getPanelTypeByElement(selectedElement),
            event: 'showPanel'
        });
    }

    getPanelTypeByElement(element) {
        let panelType = '';
        let elementType = element?.type;
        if (elementType === 'image' || elementType === 'video' || elementType === 'slideshow') {
            panelType = 'media';
        } else {
            panelType = elementType;
        }
        return panelType;
    }

    setCustomLayoutDetails(layoutDetails) {
        if (!layoutDetails) {
            this.showStudioHost = false;
            return;
        }
        if (layoutDetails.publishingState === true && !this.isPaused && !this.isStopped) {
            if (!this.showCustomAttendeeLayout) {
                this.UITypeChanged.next(true);
            }
            this.showCustomAttendeeLayout = true;
            this.showCustonLayoutSpeakerSide = true;
            this.disableLayoutSwitch = true;
        } else {
            if (this.showCustomAttendeeLayout) {
                this.UITypeChanged.next(true);
            }
            this.showCustomAttendeeLayout = false;
            this.showCustonLayoutSpeakerSide = false;
            this.disableLayoutSwitch = false;
        }

        this.scenePlayingNow = layoutDetails?.lastPublishedLayout?.sceneNumber;
        this.layoutDetails = layoutDetails;
        this.showStudioHost = layoutDetails?.lastSavedLayout?.scenesList?.length > 0;
        this.layoutDetails$.next(layoutDetails);
    }

    setStudioLayout(layoutDetails) {}

    pauseBroadcasting(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/pausebroadcasting`,
            {}
        );
    }

    async processImages(file) {
        const imageElement = await createImageBitmap(file);
        const resizedCanvas = document.createElement('canvas');

        resizedCanvas.height = imageElement.height;
        resizedCanvas.width = imageElement.width;

        if (file?.size / 1024 / 1024 > 5) {
            resizedCanvas.height = imageElement.height / 2;
            resizedCanvas.width = imageElement.width / 2;
        }

        await this.pica.resize(imageElement, resizedCanvas, {
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
            alpha: true
        });

        return new Promise((resolve, reject) => {
            resizedCanvas.toBlob((result) => {
                resolve(result);
            });
        });
    }

    getVideoDetails(mediaId): Promise<any> {
        if (!mediaId) return;
        return new Promise((resolve, reject) => {
            this.webinarService.getMediaLink(mediaId).subscribe(
                (res) => {
                    resolve({
                        mediaUrl: this.getUpdatedCloudVideoUrl(res.uri),
                        mediaId: mediaId
                    });
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    getCustomLayoutDetails() {
        return this.layoutDetails$;
    }

    getMediaByIdStrapi(id) {
        return this.http.get<any>(`${this.appService.getAddonsBaseUrl()}/upload/files/${id}`);
    }

    uploadImage(data): Observable<any> {
        const { media } = data;
        const formData = new FormData();
        formData.append('files', media.blob, media.name);
        return this.http.post<any>(`${this.appService.getAddonsBaseUrl()}/upload`, formData);
    }

    uploadVideo(data) {
        let uploadObs;
        if (data.uploadType == 'cloudvideo') {
            uploadObs = this.uploadCloudVideo(data);
        } else if (data.uploadType == 'background') {
            // uploadObs = this.uploadVideoToStrapi(data);
            uploadObs = this.uploadStudioAsset(data);
        }
        return uploadObs;
    }

    uploadVideoToStrapi(data) {
        this.uploadStudioAsset(data);
        const { media, name } = data;
        const formData = new FormData();
        formData.append('files', media, name);
        return this.http.post<any>(`${this.appService.getAddonsBaseUrl()}/upload`, formData);
    }

    uploadStudioAsset(data) {
        const { media } = data;
        const formData = new FormData();
        formData.append(`files.asset`, media.file, media.name);
        formData.append(
            'data',
            JSON.stringify({
                user_id: this.userService.getUserSync()._id,
                template_id: this.templateInfo?.id
            })
        );
        return this.http.post<any>(`${this.appService.getAddonsBaseUrl()}/studio-assets?populate=*`, formData);
    }

    uploadCloudVideo(data) {
        const formData = new FormData();
        const { media } = data;
        formData.append('file', media.file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/media?meetingid=${this.meetingId}`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    pixelToPercentage(pixelLength, parentLength) {
        return (pixelLength / parentLength) * 100;
    }

    percentageToPixel(percentage, parentLength) {
        return (percentage * parentLength) / 100;
    }

    getScenesListObj() {
        const scenes: any = [];
        // TODO: In BaseSceneView, add a function to get Object of all the properties
        const getSceneObject = (sE) =>
            Object.entries(sE).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
        this.scenesList.forEach((sE) => {
            scenes.push(getSceneObject(sE));
        });
        return scenes;
    }

    uploadImageStrapi() {}

    getScenesListToSave(scenesList) {
        const prevScenesListObj = this.getScenesListObj();
        const updatedScenesList = prevScenesListObj.map((sc) => {
            return {
                ...sc,
                elements: sc.elements.map((e) => {
                    return e.type === 'userFeed' || e.type === 'screenshare'
                        ? {
                              ...e,
                              position: {
                                  left: this.pixelToPercentage(e.position.left, 1920),
                                  top: this.pixelToPercentage(e.position.top, 1080)
                              }
                          }
                        : e;
                })
            };
        });
        return updatedScenesList;
    }

    getModifiedScenesListFromRedis() {
        const prevScenesListObj = this.getScenesListObj();
        const updatedScenesList = prevScenesListObj.map((sc) => {
            return {
                ...sc,
                elements: sc.elements.map((e) => {
                    return e.type === 'userFeed' || e.type === 'screenshare'
                        ? {
                              ...e,
                              position: {
                                  left: this.percentageToPixel(e.position.left, 1920),
                                  top: this.percentageToPixel(e.position.top, 1080)
                              }
                          }
                        : e;
                })
            };
        });
        return updatedScenesList;
    }

    getUpdatedAkamaiUrl(url: string, source = 'feed') {
        if (source == 'feed') return url;
        let newUrl = url;
        try {
            newUrl = url.replace(
                `${new URL(url).origin}/`,
                `${this.appService.getJioEventsBaseUrl()}api/akamai-images/`
            );
        } catch (e) {
            this.appLoggerService.log('Error occured while replacing url', e);
        }
        return newUrl;
    }

    getUpdatedCloudVideoUrl(url) {
        /**
         * This should be called only for cloud video and not videos uploaded in addons
         */
        let newUrl = url;
        try {
            newUrl = url.replace(`${new URL(newUrl).origin}/`, this.appService.getEnvVariable('HOST_URL'));
        } catch (e) {
            this.appLoggerService.log('Error occured while replacing url for cloud video', e);
        }
        return newUrl;
    }

    getStudioAssetsForTemplate(templateData) {
        /**
         * Images and slideshow assets are stored in the same addons server.
         */
        let imageAssets = [];
        let slideShowAssets = [];
        let videoAssets = [];
        templateData.scenes.forEach((t) => {
            t.elements.forEach((e) => {
                if (e.type === 'image') {
                    imageAssets.push({
                        assetId: e.assetId,
                        imageUrl: this.getUpdatedAkamaiUrl(e.imageUrl),
                        id: e.assetId,
                        url: this.getUpdatedAkamaiUrl(e.imageUrl),
                        mediaType: 'image'
                    });
                } else if (e.type === 'slideshow') {
                    slideShowAssets.push({
                        mediaType: 'slideshow',
                        urls: this.getUpdatedAkamaiUrl(e.urlList)
                    });
                } else if (e.type === 'video' && e.uploadType === 'background') {
                    videoAssets.push({
                        mediaType: 'video',
                        urls: this.getUpdatedAkamaiUrl(e.urlList),
                        mediaId: e.mediaId,
                        uploadType: 'background',
                        mediaUrl: this.getUpdatedAkamaiUrl(e.mediaUrl),
                        placeholderImageUrl: this.getUpdatedAkamaiUrl(e.placeholderImageUrl)
                    });
                }
            });
        });
        imageAssets = imageAssets.filter((a, index) => imageAssets.findIndex((ia) => ia.url === a.url) === index);
        this.assets.images = imageAssets;
        this.assets.slideShow = slideShowAssets;
        this.assets.videos = videoAssets.filter(
            (a, index) => videoAssets.findIndex((el) => el.mediaId == a.mediaId) === index
        );
        /**
         * Videos are stored in the server
         * mediaList contains count, list[], nextPage
         */
        if (!this.meetingId) {
            return;
        }
        this.webinarConfService.getMediaList(this.meetingId).subscribe((mediaList) => {
            let cloudvideos = [];
            mediaList.list.forEach((e) => {
                cloudvideos.push({
                    mediaType: 'video',
                    urls:
                        `${this.appService.getEnvVariable('BASE_URL')}/media/mediaplay/` +
                        e.mediaId +
                        '?type=video/mp4',
                    mediaId: e.mediaId,
                    uploadType: 'cloudvideo',
                    mediaUrl:
                        `${this.appService.getEnvVariable('BASE_URL')}/media/mediaplay/` + e.mediaId + '?type=video/mp4'
                });
            });
            this.assets.videos.push(
                ...cloudvideos.filter((a, index) => cloudvideos.findIndex((el) => el.mediaId == a.mediaId) === index)
            );
        });
    }
    addNewScene() {
        let scene = new baseSceneView({
            sceneNumber: this.scenesList.length + 1,
            thumbnail: null,
            elements: [],
            aspectRatio: 16 / 9,
            inTransition: '',
            outTransition: ''
        });

        this.scenesList.push(scene);
        return scene;
    }

    duplicateScene() {
        this.scenesList.push(this.scenesList[this.selectedScene - 1]);
    }

    deleteScene(index) {
        this.scenesList.splice(index, 1);
    }

    hideRightPanel() {
        this.showRightPanel = false;
        this.togglePanelSubject.next({
            event: 'hide',
            type: null
        });
    }

    showSpeakersList() {
        this.showSpeakersListOverlay = true;
    }

    async updateSceneThumbnail(thumbnailBlob, sceneIndexToUpload) {
        // Puligattu S - TODO: Store Blob and upload it only when template is saved
        const uploadedThumbnail = await this.uploadImage({
            media: { blob: thumbnailBlob, name: 'Thumbnail' }
        }).toPromise();
        this.scenesList[sceneIndexToUpload].thumbnail = {
            id: uploadedThumbnail[0].id,
            url: uploadedThumbnail[0].url
        };
    }
}
