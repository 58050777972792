import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, RestService } from 'src/app/core';

@Component({
    selector: 'app-external-login',
    templateUrl: './external-login.component.html',
    styleUrls: ['./external-login.component.scss']
})
export class ExternalLoginComponent implements OnInit {
    sessionId;
    token;
    success;

    source;
    providerName;
    iosMacDeeplink;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private restService: RestService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.token = params.token;
            this.source = params.source;
        });

        this.login();
    }

    login() {
        if (this.token && this.source) {
            this.loginUsingShortToken(this.token)
                .then((result) => {
                    this.handleLoginV2(result, this.token);
                })
                .catch(() => {
                    window.location.href = window.location.origin + '/login';
                });
            return;
        }
    }

    loginUsingShortToken(token) {
        return this.restService
            .post(
                'api/externalservertp/get-token',
                {
                    jwt: token
                },
                { headers: { 'x-source': this.source } }
            )
            .toPromise();
    }

    handleLoginV2(result, token) {
        // this.localService.setJwtToken(result.jwt);
        if (result?.jwt && result?.refToken) {
            this.authService.setAuthInfo(result);
            window.location.href = window.location.origin + '/dashboard/explore';
        }
    }
}
