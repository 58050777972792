<div class="nr-room-add-participants-wrapper">
    <div class="main-container no-select">
        <!-- <div class="heading mb-4 white-18-700-24"></div> -->
        <div class="heading white-18-700-24">
            <span>Invite Participants</span>
            <img
                class="pointer"
                src="assets/images/close_icon_opaque.svg"
                draggable="false"
                (click)="closeFormSidePanel()"
                id="CloseBtnIcons"
            />
        </div>
        <!-- <div class="section-desc">Add Participants to this room</div> -->
        <!-- <div class="search-bar"></div> -->
        <!-- Currently not showing the search bar -->
        <div class="room-and-count-info">
            <span class="room-name">{{ networkingRoom?.topic || 'Name' }}</span>
            <div
                class="participant-count d-flex align-items-center justify-content-center"
                [class.localUserPresence]="callStateManagerService.meetingObj?.meetingId === networkingRoom?._id"
            >
                <img
                    class="mr-1"
                    src="assets/images/networking-rooms/participants_icon_white.svg"
                    height="16"
                    width="16"
                />
                <span
                    *ngIf="
                        !(
                            networkingRoom.participants?.length < 2 &&
                            callStateManagerService.meetingObj?.meetingId === networkingRoom?._id
                        )
                    "
                    >{{
                        callStateManagerService.meetingObj?.meetingId === networkingRoom?._id &&
                        networkingRoom.participants?.length > 1
                            ? networkingRoom.participants?.length - 1
                            : networkingRoom.participants?.length
                    }}
                    <span *ngIf="callStateManagerService.meetingObj?.meetingId !== networkingRoom?._id"
                        >/ {{ maxParticipantLimit }}
                    </span>
                </span>
                <span
                    *ngIf="
                        callStateManagerService.meetingObj?.meetingId === networkingRoom?._id &&
                        networkingRoom.participants?.length > 1
                    "
                    >&nbsp;+&nbsp;</span
                >
                <span *ngIf="callStateManagerService.meetingObj?.meetingId === networkingRoom?._id">You</span>
            </div>
        </div>
        <div class="je-global-search-wrapper type-2 mb-3">
            <div class="je-search-icon">
                <img
                    class="default-image"
                    draggable="false"
                    src="assets/images/speaker-call-wrapper/grey_search_icon.svg"
                />
                <img
                    class="active-image"
                    draggable="false"
                    src="assets/images/event-call-options/green_search_icon.svg"
                />
            </div>
            <input
                type="text"
                #searchText="ngModel"
                placeholder="Search Speaker"
                [(ngModel)]="searchQueryAudience"
                (ngModelChange)="searchQueryHandler($event)"
                class="je-attendees-search"
            />
            <img
                draggable="false"
                src="assets/images/event-call-options/grey_solid_close_icon.svg"
                (click)="closeFilterByNameAudience()"
                *ngIf="!!searchQueryAudience"
                class="pointer"
            />
        </div>
        <div class="participants-list-body h-100">
            <div class="card-body">
                <!-- <div class="search position-relative" *ngIf="showSearch">
                    <input type="text" placeholder="Search by Name" [(ngModel)]="searchQuery" />
                    <div class="close pointer" (click)="closeFilterByName()">
                        <img draggable="false" src="assets/images/close_icn.svg" />
                    </div>
                </div> -->
                <div class="card-list px-2 py-2 h-100" *ngIf="filteredUsersInCall?.length">
                    <cdk-virtual-scroll-viewport
                        #participantScrollViewport
                        itemSize="40"
                        class="pc-virtual-scroll-viewport"
                    >
                        <div
                            class="d-flex justify-content-between pb-1"
                            *ngFor="let participant of filteredUsersInCall; let i = index; trackBy: trackByFuntion"
                        >
                            <div class="d-flex card-list-item">
                                <div
                                    class="checkbox-wrapper d-flex pointer"
                                    id="regfieldcheck"
                                    [class.disabled]="false"
                                >
                                    <!-- <input
                                        type="checkbox"
                                        [checked]="participant?.selected"
                                        (click)="selectSpeaker($event, participant)"
                                    /> -->
                                    <p-checkbox
                                        [value]="participant?.selected"
                                        [binary]="true"
                                        (onChange)="selectSpeaker($event, participant)"
                                        class="p-checkbox-revamp"
                                    ></p-checkbox>
                                </div>
                                <!-- For Host -->
                                <div class="pl-2 user-name text-truncate d-flex" *ngIf="participant?.isHost">
                                    <!-- <div class="text-truncate" title="{{ participant.participantName }}">
                                        {{ participant.participantName }}
                                    </div> -->
                                    <div class="text-truncate" title="{{ participant.participantName }}">
                                        {{ participant.participantName }}
                                    </div>
                                    <div class="px-1">
                                        ({{
                                            'inside_call.participants_controls.host'
                                                | translate
                                                    : {
                                                          value: isItMe(participant) ? ', ' + isItMe(participant) : ''
                                                      }
                                        }})
                                    </div>
                                </div>
                                <!-- For CoHost -->
                                <div
                                    class="pl-2 user-name text-truncate d-flex"
                                    *ngIf="participant?.isCoHost && !participant?.isHost"
                                >
                                    <div class="text-truncate" title="{{ participant.participantName }}">
                                        {{ participant.participantName }}
                                    </div>
                                    <div class="px-1">
                                        ({{
                                            'inside_call.participants_controls.cohost'
                                                | translate
                                                    : {
                                                          value: isItMe(participant) ? ', ' + isItMe(participant) : ''
                                                      }
                                        }})
                                    </div>
                                </div>
                                <!-- For Speaker -->
                                <div
                                    class="pl-2 user-name text-truncate d-flex"
                                    *ngIf="!participant?.isHost && !participant?.isCoHost"
                                >
                                    <div class="text-truncate" title="{{ participant.participantName }}">
                                        {{ participant.participantName }}
                                    </div>
                                    <div class="px-1">
                                        {{ isItMe(participant) ? '(' + isItMe(participant) + ')' : '' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
                <!-- <ng-container
                            *ngIf="usersInCall | filterContactByKey: searchQuery:['participantName'] as filteredUsers"
                        >
                            <div class="text-center p-3" *ngIf="!filteredUsers?.length">
                                <span>
                                    {{ 'inside_call.participants_controls.noresults' | translate }}
                                </span>
                            </div>
                        </ng-container> -->
            </div>
        </div>
        <div
            class="d-flex justify-content-center align-items-center selection-limit-alert error-text"
            *ngIf="networkingRoom?.participants?.length + userIdsSelected?.length >= maxParticipantLimit + 1"
        >
            You can select only {{ maxParticipantLimit - networkingRoom?.participants?.length }} participants to add in
            this room
        </div>
        <button
            class="custom-button-revamp btn-confirm-primary lg networking-room-save"
            [class.disabled]="
                !userIdsSelected.length ||
                networkingRoom?.participants?.length + userIdsSelected?.length >= maxParticipantLimit + 1
            "
            (click)="submitParticipantsAddition()"
            id="inviteSpeakerBtnControl"
        >
            Invite
        </button>
    </div>

    <app-loader *ngIf="loading"></app-loader>
</div>
