import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    addDays,
    addHours,
    addMonths,
    isAfter,
    isBefore,
    isPast,
    isWithinInterval,
    parseISO,
    subDays,
    subHours,
    subMinutes
} from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { RestService } from './rest.service';
import * as _ from 'lodash';
import { UserService } from './user.service';
import { UtilService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class NetworkingRoomsService {
    isAuthenticated: boolean = false;
    networkingRooms = [];
    canEnableEscapeRooms: any;
    constructor(
        private restService: RestService,
        private router: Router,
        private appService: AppService,
        private authService: AuthService,
        private userService: UserService,
        private utilService: UtilService
    ) {
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
    }
    networkingRooms$: BehaviorSubject<any> = new BehaviorSubject(null);

    createRoom(meetingId: string, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms`,
            payload
        );
    }

    editRoom(parentId: string, payload: any, displayImageUrl: string, networkingRoomId: string) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${parentId}/networking-rooms/${networkingRoomId}`,
            {
                meetingDetails: payload,
                logoInfo: this.extractLogoIdAndExtension(displayImageUrl)
            }
        );
    }

    // createRoomWithImage(meetingId: string, name: string, category: string, displayImage: File) {
    //     const formData = new FormData();
    //     formData.append('file', displayImage);
    //     formData.append('name', name);
    //     formData.append('caterory', category);
    //     return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms`, formData, {
    //         observe: 'events',
    //         reportProgress: true
    //     });
    // }

    createRoomWithImage(meetingId, data) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms`,
            data,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    uploadDisplayImage(meetingId: string, file): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms/logo`,
            formData,
            {
                observe: 'events',
                reportProgress: true
            }
        );
    }

    getRooms(meetingId: string) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms`)
            .pipe(
                tap((res) => {
                    this.networkingRooms = res?.rooms || [];
                    this.sendNetworkingRoomsUpdate(res);
                })
            );
    }

    getNetworkingRoomsOfWebinar(meetingId: string) {
        return this.restService
            .get(
                `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/${
                    this.isAuthenticated ? 'networking-rooms' : 'networkingloungedetails'
                }`
            )
            .pipe(
                tap((res) => {
                    this.networkingRooms = res?.rooms || [];
                    this.sendNetworkingRoomsUpdate(res);
                })
            );
    }

    getNetworkingRoomsWithoutMeetingObj(meetingId: string) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms${
                this.isAuthenticated ? '' : '/guest'
            }?compact=true`
        );
    }

    deleteAllRooms(meetingId: string) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms`
        );
    }

    deleteRoom(meetingId: string, networkingRoomId: string) {
        return this.restService.delete(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms/${networkingRoomId}`
        );
    }

    updateRoomPrivacy(meetingId, networkingRoom) {
        return this.restService.put(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${meetingId}/networking-rooms/${
                networkingRoom._id
            }`,
            networkingRoom
        );
    }

    sendNetworkRoomInvitation(payload: any) {
        // payload contains jiomeetId, roomId, roomPin, array of participant Ids, networking Room ID (webinar Id, jiomeetId, roomId, roomPin) to be joined
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${payload.meetingId}/networking-rooms/invite`,
            payload
        );
    }

    closeRoom(payload) {
        // if parent details are sent then close all rooms, should be validated in backend for networking rooms
        // close only that networking room if child details are sent
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meetings/${payload.meetingId}/close`,
            payload
        );
    }

    getRoomsClosureStatus(selectedEventAvailablityOptionType, webinarDetails) {
        if (!webinarDetails.scheduledStartTime && !webinarDetails.startTime) return true;
        switch (selectedEventAvailablityOptionType) {
            case 'always':
                // It should be open from 1hr before the event to 1hr after the end time of the event
                return !isWithinInterval(new Date(), {
                    start: subHours(parseISO(webinarDetails.scheduledStartTime || webinarDetails.startTime), 1),
                    end: addHours(parseISO(webinarDetails.scheduledEndTime || webinarDetails.endTime), 1)
                });
                return false;
            case 'notDuring':
                // return differenceInHours(webinarDetails.scheduledStartTime,new Date()) < 1 || differenceInHours(new Date(), webinarDetails.scheduledEndTime) < 1;
                return !(
                    isWithinInterval(new Date(), {
                        start: subHours(parseISO(webinarDetails.scheduledStartTime || webinarDetails.startTime), 1),
                        end: parseISO(webinarDetails.scheduledStartTime || webinarDetails.startTime)
                    }) ||
                    isWithinInterval(new Date(), {
                        start: parseISO(webinarDetails.scheduledEndTime || webinarDetails.endTime),
                        end: addHours(parseISO(webinarDetails.scheduledEndTime || webinarDetails.endTime), 1)
                    })
                );
            case 'during':
                return !isWithinInterval(new Date(), {
                    start: parseISO(webinarDetails.scheduledStartTime || webinarDetails.startTime),
                    end: parseISO(webinarDetails.scheduledEndTime || webinarDetails.endTime)
                });
            case 'duringBreak':
            // return this.isSessionBreak;
            default:
                return false;
        }
    }

    getLatestCloseRoomTimer(closeTime, EndTime, source = null) {
        return (closeTime && closeTime?.length > 0) || source
            ? isAfter(new Date(closeTime), addHours(new Date(EndTime), 1))
                ? new Date(EndTime)
                : new Date(closeTime)
            : null;
    }

    getRoomClosureStatusWithTimer(time) {
        return time?.length > 0 && isPast(new Date(time));
    }

    getNetworkingRoomsObservable() {
        return this.networkingRooms$;
    }

    sendNetworkingRoomsUpdate(value) {
        this.networkingRooms$.next(value);
    }

    getDuplicateRoomNames(count, baseName) {
        let newNames = [];
        let lastSequenceNumber = 0;
        let existingNames = this.networkingRooms.map((r) => r?.topic);
        while (count--) {
            let nextSequenceNumber = this.getNextNumberInSequence(baseName, existingNames, lastSequenceNumber + 1);
            newNames.push(baseName + ` ${nextSequenceNumber}`);
            existingNames.push(baseName + ` ${nextSequenceNumber}`);
        }
        return newNames;
    }

    getNextNumberInSequence(baseName: String, existingNames: Array<String>, lastSequenceNumber) {
        while (existingNames.includes(baseName + ` ${lastSequenceNumber}`)) lastSequenceNumber++;
        return lastSequenceNumber;
    }

    getUpdateRoomPayload(networkingRoom) {
        const payload = {
            advancedOptions: {
                hostAudio: false,
                participantAudio: true,
                hostVideo: false,
                participantVideo: true,
                joinBeforeHost: true,
                waitingRoom: false,
                loggedInOnly: false,
                colleagueOnly: false,
                participantHardAudioMute: false,
                participantHardVideoMute: false,
                isRestrictedMeeting: false,
                isClassroomMode: false
            },
            descriptionMarkdown: networkingRoom.descriptionMarkdown,
            endTime: networkingRoom.endTime,
            isDynamicRoom: networkingRoom.isDynamicRoom,
            isPinEnabled: true,
            isNetworkingRoom: true,
            jioConf: networkingRoom.jioConf,
            participants: _.map(networkingRoom.participants, (p) => p.userId),
            startTime: networkingRoom.startTime,
            topic: networkingRoom?.topic,
            topicMarkdown: networkingRoom?.topicMarkdown,
            webinar: networkingRoom.webinar,
            meetingId: networkingRoom.networking.meetingId
        };
        return payload;
    }

    extractLogoIdAndExtension(url) {
        if (!url) return null;
        let idExtensionArr = url.split('/').at(-1)?.split('?')[0].split('.');
        if (idExtensionArr.length < 2) return null;
        return {
            id: idExtensionArr[0],
            extension: idExtensionArr[1]
        };
    }

    getCreateRoomPayload(
        name: string,
        category: string,
        displayImageUrl: string,
        duplicateCount: number = 1,
        isEscapeRoom: boolean = false,
        parentStartTime = null,
        parentEndTime = null
    ) {
        let payload = {
            name,
            category,
            logoInfo: this.extractLogoIdAndExtension(displayImageUrl),
            duplicateCount,
            isEscapeRoom
        };
        if (parentStartTime && isEscapeRoom) {
            const maxStartTimeDate = subDays(new Date(parentStartTime), 15);
            _.assign(payload, {
                startTime: isBefore(new Date(), maxStartTimeDate) ? maxStartTimeDate : new Date(parentStartTime),
                endTime: addDays(new Date(parentEndTime), 15)
            });
        }
        return payload;
    }

    getDuplicateRoomPayload(
        name: string,
        category: string,
        displayImageUrl: string,
        duplicateCount: number = 1,
        isEscapeRoom: boolean = false,
        networkingRoomStartTime,
        networkingRoomEndTime = null
    ) {
        // pending
        let payload = {
            name,
            category,
            logoInfo: this.extractLogoIdAndExtension(displayImageUrl),
            duplicateCount,
            isEscapeRoom
        };
        if (networkingRoomStartTime && isEscapeRoom) {
            _.assign(payload, {
                startTime: networkingRoomStartTime,
                endTime: networkingRoomEndTime
            });
        }
        return payload;
    }

    checkEscapeRoomsFeatureAccess() {
        const enableEscapeRooms = this.utilService.enablePaymentFeature(this.userService.getUserSync()?.isOTT)
            ? this.userService.getUserSync()?.eventsFeaturesEnabled?.enableEscapeRooms
            : this.appService.getConfigVariable('enableEscapeRoomsForAll') ||
              this.userService.getUserSync()?.webinarBetaAccess?.enableEscapeRooms;
        const _canEnableEscapeRooms =
            enableEscapeRooms && !this.appService.getConfigVariable('disableEscapeRoomsFeature');
        this.canEnableEscapeRooms = _canEnableEscapeRooms;
    }
}
