export const CUSTOM_STREAM_CONSTANT = {
    availableLayoutOptions: [
        {
            title: 'Default',
            desc: 'Layout with Screen Share along with Camera feed Overlap',
            iconUrl: 'assets/images/main-stage/custom_feed_layouts/right_bottom_mixed_layout.svg',
            tooltip: 'Studio Mode'
        },
        {
            title: 'Screen',
            desc: 'Layout with only Screen Share',
            iconUrl: 'assets/images/main-stage/custom_feed_layouts/only_screen_share.svg',
            tooltip: 'Only Screenshare'
        },
        {
            title: 'Twin Clear',
            desc: 'Layout with Screen Share along with Camera feed without Overlap',
            iconUrl: 'assets/images/main-stage/custom_feed_layouts/only_camera_feed_layout.svg',
            tooltip: 'Only Video Feed'
        }
        // {
        //     title: 'Camera',
        //     desc: 'Layout with only Camera feed'
        // }
    ],
    cameraPipConfig: {
        top: 0.65,
        left: 0.65,
        width: 0.3,
        height: 0.3
    },
    canvas: {
        width: 1280,
        height: 720
    }
};
