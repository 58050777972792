import { fabric } from 'fabric';
import { userFeedElement } from '../models/userFeedElement';
import { CallManager } from '../../call-manager/callManager';
export class userFeedElementView implements userFeedElement {
    // base model properties
    id: number;
    name: string;
    type: string;
    visible: boolean;
    zIndex: number;
    position: { left: number; top: number };
    dimension: { height: number; width: number };
    rotation: number;
    transitions: { onAdding: string; onMove: string; onRemove: string };

    // image element properties
    userId: string;
    mediaEngineId: string;
    removeBackground: boolean;
    placeholderImageUrl: string;
    isCallAttached: boolean;
    canv: HTMLCanvasElement;
    canv2: HTMLCanvasElement;
    ctx1: CanvasRenderingContext2D;
    ctx2: CanvasRenderingContext2D;
    participant: any;
    callManager;
    fabricObject;
    clipPath: string;
    borderRadius: number;
    objectFit: string;
    aspectRatio: number;
    backgroundColor: string;
    borderWidth: number;
    borderColor: string;

    constructor(props: userFeedElement) {
        this.id = props.id;
        this.name = props.name;
        this.type = props.type;
        this.visible = props.visible;
        this.zIndex = props.zIndex;
        this.position = props.position;
        this.dimension = props.dimension;
        this.transitions = props.transitions;
        this.userId = props.userId;
        this.mediaEngineId = props.mediaEngineId;
        this.placeholderImageUrl = props.placeholderImageUrl;
        this.removeBackground = props.removeBackground;
        this.callManager = CallManager;
        this.clipPath = props.clipPath;
        this.borderRadius = props.borderRadius;
        this.borderWidth = props.borderWidth;
        this.borderColor = props.borderColor;
        this.objectFit = props.objectFit;
        this.aspectRatio = props.aspectRatio;
        this.backgroundColor = props.backgroundColor;
    }

    async addPlaceholder() {
        const imageObj = await new Promise((resolve, reject) => {
            fabric.Image.fromURL(this.placeholderImageUrl, (oImg) => {
                oImg.setControlsVisibility({ mtr: false, mt: false, ml: false, mr: false, mb: false });
                // oImg.scaleToWidth(this.dimension.width, false);
                oImg.scaleX = 1;
                oImg.scaleY = 1;
                oImg.scaleToWidth(this.percentageToPixel(this.dimension.width, 1920), false);
                oImg.top = this.percentageToPixel(this.position.top, 1080);
                oImg.left = this.percentageToPixel(this.position.left, 1920);
                oImg.cornerSize = 25;
                oImg.visible = true;
                oImg.id = this.id;
                oImg.visible = this.visible;
                resolve(oImg);
            });
        });
        this.fabricObject = imageObj;

        return imageObj;
    }

    pixelToPercentage(pixelLength, parentLength) {
        return (pixelLength / parentLength) * 100;
    }

    percentageToPixel(percentage, parentLength) {
        return (percentage * parentLength) / 100;
    }

    async createFabricElement() {
        if (!this.isCallAttached) {
            return this.addPlaceholder();
        } else {
            await this.addUserFeed();
        }
    }

    setParticipant() {
        this.participant = this.callManager.getVideoService().agorautility.remoteParticipants.find((participant) => {
            return String(participant.uid) === String(this.mediaEngineId);
        });
    }

    async addUserFeedDiv() {
        const width = this.dimension.width;
        const height = this.dimension.height;
        let streamElement = document.createElement('div');
        streamElement.setAttribute('style', `height: ${height}%; width: ${width}%;`);
        streamElement.id = `video-${this.mediaEngineId}-1`;
        streamElement.style.position = 'absolute';
        streamElement.style.display = 'none';
        document.getElementsByClassName('canvas-container')[0].appendChild(streamElement);
        await this.callManager.getVideoService().localScreenTrack.play('');
    }

    async subscribeUserFeed() {
        if (this.callManager.mediaEngine === 'agora') {
            await this.callManager.getVideoService().agoraClient.subscribe(this.participant, 'video');
            const videoTrack = this.participant.videoTrack;
            await videoTrack.play(`video-${this.mediaEngineId}-1`);
        }
    }

    async playUserAudio() {
        if (this.callManager.mediaEngine === 'agora') {
            if (this.participant._audio_added_) {
                await this.callManager.getVideoService().agoraClient.subscribe(this.participant, 'audio');
                const audioTrack = this.participant.audioTrack;
                await audioTrack.play();
            }
        }
    }

    appendElementToCanvas(newElement) {
        newElement.style.position = 'absolute';
        document.getElementsByClassName('canvas-container')[0].appendChild(newElement);
    }

    async addUserFeedCanvas(canvasType) {
        this.canv = document.createElement('canvas');
        this.canv.id = `c1-${this.mediaEngineId}${canvasType === 'live' ? '-live' : ''}`;
        this.canv.style.display = 'none';
        this.canv.style.position = 'absolute';
        this.appendElementToCanvas(this.canv);

        this.canv2 = document.createElement('canvas');
        this.canv2.id = `c2-${this.mediaEngineId}${canvasType === 'live' ? '-live' : ''}`;
        this.canv2.style.position = 'absolute';
        this.appendElementToCanvas(this.canv2);

        this.ctx1 = this.canv.getContext('2d', { willReadFrequently: true });
        this.ctx2 = this.canv2.getContext('2d', { willReadFrequently: true });

        this.canv2.setAttribute(
            'style',
            `top:${top}%; left: ${this.position.left}%; width: ${this.dimension.width}%; height: ${this.dimension.height}%; position: absolute; display: none`
        );

        this.canv.height = this.dimension.height;
        this.canv.width = this.dimension.width;
        this.canv2.height = this.dimension.height;
        this.canv2.width = this.dimension.width;

        // this.removeVideoBg();
    }

    removeVideoBg() {
        this.computeFrame();
        setTimeout(() => {
            this.removeVideoBg();
        }, 40);
    }

    computeFrame() {
        // ctx1.drawImage(video, 0, 0, widthInPixel, heightInPixel);
        // const frame = ctx1.getImageData(0, 0, widthInPixel, heightInPixel);
        // const data = frame.data;
        // if (removeBackground) {
        //     for (let i = 0; i < data.length; i += 4) {
        //         const red = data[i + 0];
        //         const green = data[i + 1];
        //         const blue = data[i + 2];
        //         if (green >= 100 && green <= 255 && red >= 0 && red <= 100 && blue >= 0 && blue <= 100) {
        //             data[i + 3] = 0;
        //         }
        //     }
        // }
        // ctx2.putImageData(frame, 0, 0);
        // canvas.renderAll();
    }

    async addUserFeed() {
        this.setParticipant();
        await this.addUserFeedDiv();
        await this.subscribeUserFeed();
        // await this.addUserFeedCanvas('live');
        await this.playUserAudio();
    }
}
