<footer>
    <div class="footer">
        <div class="row data-scroll-header" [class.in-view]="isElementInView" #elementToObserve>
            <div class="desktop-footer col-12">
                <diV class="main-footer d-flex items-start w-full">
                    <div class="menu-list-one d-flex flex-col justify-between">
                        <div (click)="footerlinksClick('FOOTER_LOGO')" class="logo d-flex items-center">
                            <div [class.in-view]="isElementInView">
                                <img class="logo-wrapper-view" src="assets/img/website/mobile_website_icon.svg" />
                            </div>
                        </div>
                        <div *ngFor="let link of footerLinks" (click)="footerlinksClick(link.action)">
                            <span
                                [routerLink]="link.route"
                                class="valign-text-middle footer-content-wrapper cus-point text-animation"
                            >
                                {{ link.label }}
                            </span>
                        </div>
                    </div>

                    <div class="menu-list-one d-flex flex-col justify-between">
                        <div (click)="footerlinksClick('PRODUCTS')" (keyup.enter)="footerlinksClick('PRODUCTS')">
                            <span
                                tabindex="0"
                                aria-roledescription="Products"
                                role="button"
                                class="footer-header-wrapper text-animation header-text"
                                >Products</span
                            >
                        </div>

                        <div (click)="footerlinksClick('VIRTUAL_EVENTS')">
                            <span
                                routerLink="/solutions"
                                class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                >JioEvents Platform</span
                            >
                        </div>
                        <div (click)="footerlinksClick('PRE_RECORDED')">
                            <span class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                >JioEvents Studio</span
                            >
                        </div>
                    </div>
                    <div class="menu-list-two d-flex flex-col justify-between">
                        <div class="footer-header-wrapper cus-point">Solutions</div>

                        <div class="valign-text-middle footer-content-wrapper cus-point">Virtual/Hybrid Events</div>
                        <div class="valign-text-middle footer-content-wrapper cus-point">Automated Events</div>
                        <div class="valign-text-middle footer-content-wrapper cus-point">White Glove Services</div>
                    </div>
                    <div class="menu-list-three d-flex flex-col justify-between">
                        <div>
                            <span
                                tabindex="0"
                                aria-roledescription="Resources"
                                role="button"
                                class="footer-header-wrapper text-animation header-text"
                                >Resources</span
                            >
                        </div>
                        <div (click)="footerlinksClick('WHATS_NEW')">
                            <span
                                (click)="redirectToWhatsNew()"
                                class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                >What's New</span
                            >
                        </div>
                        <div (click)="footerlinksClick('SUPPORT')">
                            <span
                                (click)="redirectToBlog()"
                                class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                >Blogs</span
                            >
                        </div>
                        <div (click)="footerlinksClick('CASE_STUDY')">
                            <span
                                (click)="redirectToCaseStudies()"
                                class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                >Case Studies</span
                            >
                        </div>
                        <div (click)="footerlinksClick('FAQS')">
                            <span
                                (click)="redirectToFaqs()"
                                class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                >FAQ's
                            </span>
                        </div>
                    </div>
                    <div class="footer-contact-us tab-view">
                        <div class="social-media">
                            <div>
                                <span class="footer-header-wrapper text-animation header-text">Social</span>
                            </div>
                            <div class="d-flex icon-row-area cus-point">
                                <div
                                    *ngFor="let link of firstArray"
                                    (click)="footerlinksClick(link.platform)"
                                    (keydown.enter)="footerlinksClick(link.platform)"
                                    class="social-icon-circle d-flex justify-content-center align-items-center"
                                >
                                    <a
                                        tabindex="0"
                                        class="circle_a_img"
                                        [aria-roledescription]="link.platform"
                                        role="button"
                                        [href]="socialMediaUrls[link.platform]"
                                    >
                                        <img
                                            class="
                                                footer-social-img
                                                cus-point
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                            "
                                            [src]="link.iconSrc"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex icon-row-area cus-point">
                                <div
                                    *ngFor="let link of secondArray"
                                    (click)="footerlinksClick(link.platform)"
                                    (keydown.enter)="footerlinksClick(link.platform)"
                                    class="social-icon-circle d-flex justify-content-center align-items-center"
                                >
                                    <a
                                        tabindex="0"
                                        class="circle_a_img"
                                        [aria-roledescription]="link.platform"
                                        role="button"
                                        [href]="socialMediaUrls[link.platform]"
                                    >
                                        <img
                                            class="
                                                footer-social-img
                                                cus-point
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                            "
                                            [src]="link.iconSrc"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-contact-us desktop-footer-view">
                        <div class="social-media">
                            <div>
                                <span class="footer-header-wrapper text-animation header-text">Social</span>
                            </div>
                            <div class="d-flex icon-row-area cus-point">
                                <div
                                    *ngFor="let link of socialMediaLinks"
                                    (click)="footerlinksClick(link.platform)"
                                    (keydown.enter)="footerlinksClick(link.platform)"
                                    class="social-icon-circle d-flex justify-content-center align-items-center"
                                >
                                    <a
                                        tabindex="0"
                                        class="circle_a_img"
                                        [aria-roledescription]="link.platform"
                                        role="button"
                                        [href]="socialMediaUrls[link.platform]"
                                    >
                                        <img
                                            class="
                                                footer-social-img
                                                cus-point
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                            "
                                            [src]="link.iconSrc"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </diV>
            </div>
            <div class="mobile-footer col-12">
                <diV class="main-footer d-flex items-start w-full">
                    <div class="menu-list-one d-flex flex-col justify-between">
                        <div
                            (click)="footerlinksClick('FOOTER_LOGO')"
                            class="logo d-flex items-center events-logo-view"
                        >
                            <div class="video-circle">
                                <img src="assets/img/website/logo_icon.svg" />
                            </div>
                            <div class="ml-2 footer-header-wrapper">
                                <span>JioEvents</span>
                            </div>
                        </div>
                        <div class="menu-list-view">
                            <div *ngFor="let link of footerLinks" (click)="footerlinksClick(link.action)">
                                <span
                                    [routerLink]="link.route"
                                    class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                >
                                    {{ link.label }}
                                </span>
                            </div>
                        </div>
                        <div class="menu-list-two d-flex flex-col justify-between">
                            <div class="text-position-view">
                                <span class="footer-header-wrapper">Solution</span>
                            </div>
                            <div class="valign-text-middle footer-content-wrapper cus-point">Virtual/Hybrid Events</div>

                            <div class="valign-text-middle footer-content-wrapper cus-point">Automated Events</div>
                            <div class="valign-text-middle footer-content-wrapper cus-point">White Glove Services</div>
                        </div>
                    </div>

                    <div class="flex-col product-container-view">
                        <div (click)="footerlinksClick('PRODUCTS')" class="product-view-wrapper">
                            <span class="footer-header-wrapper">Products</span>
                        </div>

                        <div (click)="footerlinksClick('VIRTUAL_EVENTS')">
                            <span routerLink="/solutions" class="valign-text-middle footer-content-wrapper cus-point"
                                >JioEvents Platform</span
                            >
                        </div>

                        <div class="valign-text-middle footer-content-wrapper cus-point">JioEvents Studio</div>

                        <div class="menu-list-three d-flex flex-col justify-between">
                            <div class="text-position-view">
                                <span class="footer-header-wrapper">Resources</span>
                            </div>
                            <div (click)="footerlinksClick('WHATS_NEW')">
                                <span
                                    (click)="redirectToWhatsNew()"
                                    class="valign-text-middle jiotype-medium-white-12px cus-point"
                                    >What's New</span
                                >
                            </div>
                            <div (click)="footerlinksClick('BLOGS')">
                                <span
                                    (click)="redirectToBlog()"
                                    class="valign-text-middle jiotype-medium-white-12px cus-point"
                                    >Blogs</span
                                >
                            </div>
                            <div (click)="footerlinksClick('CASE_STUDY')" class="case-study-wrapper">
                                <span
                                    (click)="redirectToCaseStudies()"
                                    class="valign-text-middle jiotype-medium-white-12px cus-point"
                                    >Case Studies</span
                                >
                            </div>
                            <div (click)="footerlinksClick('FAQS')">
                                <span
                                    (click)="redirectToFaqs()"
                                    class="valign-text-middle footer-content-wrapper cus-point text-animation"
                                    >FAQ's
                                </span>
                            </div>
                        </div>
                        <div class="footer-contact-us">
                            <div class="social-media">
                                <div>
                                    <span class="footer-header-wrapper">Social</span>
                                </div>
                                <div class="cus-point footer-mobile-wrapper">
                                    <div
                                        *ngFor="let link of firstArray"
                                        (click)="footerlinksClick(link.platform)"
                                        (keydown.enter)="footerlinksClick(link.platform)"
                                        class="social-icon-circle d-flex justify-content-center align-items-center"
                                    >
                                        <a
                                            tabindex="0"
                                            class="circle_a_img"
                                            [aria-roledescription]="link.platform"
                                            role="button"
                                            [href]="socialMediaUrls[link.platform]"
                                        >
                                            <img
                                                class="
                                                    footer-social-img
                                                    cus-point
                                                    mobile
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                "
                                                [src]="link.iconSrc"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div class="cus-point footer-mobile-wrapper">
                                    <div
                                        *ngFor="let link of secondArray"
                                        (click)="footerlinksClick(link.platform)"
                                        (keydown.enter)="footerlinksClick(link.platform)"
                                        class="social-icon-circle d-flex justify-content-center align-items-center"
                                    >
                                        <a
                                            tabindex="0"
                                            class="circle_a_img"
                                            [aria-roledescription]="link.platform"
                                            role="button"
                                            [href]="socialMediaUrls[link.platform]"
                                        >
                                            <img
                                                class="
                                                    footer-social-img
                                                    cus-point
                                                    mobile
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                "
                                                [src]="link.iconSrc"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </diV>
            </div>
        </div>
        <div class="divider"></div>
        <div class="footer-info d-flex mobile-base-footer justify-between items-start w-auto pt-4 mr-10 footer-margin">
            <div class="footer-text-view">{{ copyRight }}</div>
            <div class="d-flex bas-footer-right base-footer-container space-x-2">
                <!-- <div routerLink="/comingsoon" class="cus-point">
                    <span class="jiotype-medium-white-12px">Regulatory</span>
                </div>
                <div class="footer-bas-divide">|</div> -->
                <div (click)="footerlinksClick('POLICIES')" (click)="navigateToPrivacy()">
                    <span class="footer-text-right-view" [class.text-animation]="!isMobile">Privacy Policy</span>
                </div>

                <div class="footer-bas-divide">|</div>
                <div (click)="footerlinksClick('TERMS_CONDITIONS'); navigateToTnC()">
                    <span class="footer-text-right-view" [class.text-animation]="!isMobile">Terms & Conditions</span>
                </div>
            </div>
        </div>
    </div>
</footer>
