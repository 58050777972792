import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core';

@Component({
    selector: 'app-event-clients',
    templateUrl: './event-clients.component.html',
    styleUrls: ['./event-clients.component.scss']
})
export class EventClientsComponent implements OnInit {
    bnwImage: any[] = [];
    colouredImage: any[] = [];
    clientLogos;
    constructor(private appService: AppService) {}

    ngOnInit(): void {
        this.clientLogos = this.appService.getConfigVariable('clientLogo');
        this.clientLogos.colouredLogo.forEach((url) => {
            this.colouredImage.push(url);
        });
    }
}
